<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{taskDetails.task_name}}</h2>
    </div>
    <div class="body-section w-100">
      <div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <img [src]="photo" style="height:450px; margin: auto;"/>
        </div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <div class="col-md-12 pr-30">
          <div class="row">
            <label class="detaillabel1">Brand : </label>
            <span class="dialogspan" style="margin-left:5px">{{taskDetails.brand}}</span>
          </div>
          </div>
          <div class="col-md-12 pad0">
            <div class="col-md-6 pr-30">
                <div class="row">
                  <label class="detaillabel1">Code : </label>
                  <span class="dialogspan" style="margin-left:5px">{{taskDetails.code}}</span>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <label class="detaillabel1">Type : </label>
                  <span class="dialogspan" style="margin-left:5px">{{taskDetails.type_name}}</span>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <label class="detaillabel1">Status : </label>
                  <span class="dialogspan" style="margin-left:5px">{{taskDetails.status}}</span>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <label class="detaillabel1">Date : </label>
                  <span class="dialogspan" style="margin-left:5px">{{taskDetails.date}}</span>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <label class="detaillabel1">Cluster : </label>
                  <span class="dialogspan" style="margin-left:5px">{{taskDetails.cluster}}</span>
                </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Beat : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.beat}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Class : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.class_type}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Remarks : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.comments}}</span>
              </div>
            </div>
            <div class="col-md-6 pr-30">
              <div class="row">
                <label class="detaillabel1">Retailer Code : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.retailer_code}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Retailer : </label>
                <span class="dialogspan" style="overflow-wrap: break-word; width: 188px;margin-left:5px;">{{taskDetails.retailer}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Territory Code: </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.tcode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Territory : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.territory}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Salesman Code : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.scode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Salesman : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.user}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Town Tier : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.program_type}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Reviewed By : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.reviewed_by}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Reviewed On : </label>
                <span class="dialogspan" style="margin-left:5px">{{taskDetails.reviewed_on}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
