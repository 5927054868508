export class TaxCalculator {

  constructor(cartItems, taxMasterList, prodList) {
    this.cartItems = cartItems;
    this.taxMasterList = taxMasterList;
    this.prodList = prodList;
  }

  cartItems = [];
  taxMasterList = [];
  prodList = [];

  static CalculateLineTaxsAndTotal(activeObject, taxMasters) {

    activeObject.amount_untaxed = 0;
    activeObject.amount_tax = 0;
    activeObject.amount_total = 0;
    activeObject.scheme_discount = 0;
    activeObject['tax_notes'] = {};
    activeObject['lines'].forEach(row => {

      const tax_master = taxMasters.find(item => item.id === row['taxmaster_id']);
      const tax_notes = {};
      row['tax_notes'] = '';
      if (tax_master) {
        if (tax_master.tax_lines && tax_master.tax_lines.length > 0) {
          tax_master.tax_lines.forEach(line => {
            tax_notes[line.name] = line.amount * row['price_subtotal'];
            tax_notes[line.name] = tax_notes[line.name] + '(' + line.amount + '%)';

            if (activeObject['tax_notes'].hasOwnProperty(line.name)) {
              activeObject['tax_notes'][line.name] = (activeObject['tax_notes'][line.name]) + line.amount * row['price_subtotal'];
            } else {
              activeObject['tax_notes'][line.name] = line.amount * row['price_subtotal'];
            }
          });
        }
        row['tax_notes'] = tax_notes;
        row['price_tax'] = (tax_master.amount * row['price_subtotal']) / 100;
        row['price_total'] = row['price_subtotal'] + row['price_tax'];
        row['tax_name'] = tax_master.name;

        activeObject.amount_untaxed += row['price_subtotal'];
        activeObject.amount_tax += row['price_tax'];
        activeObject.amount_total += row['price_total'];
        activeObject.scheme_discount += row['scheme_discount'];
      }
    });

    return activeObject;
  }


  CalculateLineTax() {

    const order_tax_notes = {};
    this.cartItems.forEach(row => {
      let tax_master;
      if (row['taxmaster_id']) {
        tax_master = this.taxMasterList.find(item => item.id === row['taxmaster_id']);
      } else {
        const prod = this.prodList.find(item => item.p_id === row['product_id']);
        if (prod && prod['taxmaster_id']) {
          tax_master = this.taxMasterList.find(item => item.id === prod['taxmaster_id']);
        }
      }
      const tax_notes = {};
      row['tax_notes'] = '';
      if (tax_master) {
        if (tax_master['tax_lines'] && tax_master['tax_lines'].length > 0) {
          tax_master['tax_lines'].forEach(line => {
            tax_notes[line.name] = ((line.amount * row['price_subtotal']) / 100).toFixed(2);
            tax_notes[line.name] = tax_notes[line.name] + '(' + line.amount + '%)';

            if (order_tax_notes.hasOwnProperty(line.name)) {
              order_tax_notes[line.name] = Number(order_tax_notes[line.name]) + Number((line.amount * row['price_subtotal'] / 100).toFixed(2));
            } else {
              order_tax_notes[line.name] = Number((line.amount * row['price_subtotal'] / 100).toFixed(2));
            }
          });
        }

        row['tax_notes'] = tax_notes;
        row['price_tax'] = (tax_master['amount'] * row['price_subtotal']) / 100;
        row['price_total'] = row['price_subtotal'] + row['price_tax'];
        row['tax_name'] = tax_master['name'];
      } else {
        row['tax_notes'] = {};
        row['price_tax'] = 0;
        row['price_total'] = row['price_subtotal'];
        row['tax_name'] = '';
      }
    });

    return order_tax_notes;
  }

}
