import {NgModule, ANALYZE_FOR_ENTRY_COMPONENTS} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {GridComponent} from './grid.component';
import {CellComponent} from './cell.component';
import {GadgetInstanceService} from './grid.service';
import {HttpClientModule} from '@angular/common/http';
import {ToastModule} from '../toast/toast.module';
import {AddGadgetModule} from "../add-gadget/add-gadget.module";
import {CustomConfigService} from "../../_services/CustomConfigService";
import {BoardMenuEventService} from "./menu-service";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ToastModule,
        HttpClientModule,
        AddGadgetModule,
        // DndModule.forRoot()
    ],
    declarations: [
        GridComponent,
        CellComponent
    ],
    exports: [
        GridComponent
    ],
    providers: [
        GadgetInstanceService,
        CustomConfigService,
        BoardMenuEventService
        // AddGadgetService,
        // NewsService,
        // JobAnalysisService,
        // TrendLineService,
        // TrendService,
        // EdgeService,
        // CPUService,
        // DonutService,
        // ConnectionService,
        // TodoService  // todo gadget
        // ,BubbleService,
        // BarChartService,
        // PieChartService
    ]
})
export class GridModule {
    static withComponents(components: any[]) {
        return {
            ngModule: GridModule,
            providers: [
                {provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: components, multi: true}
            ]
        };
    }
}
