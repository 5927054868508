import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-raw-visits-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RawVisitComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = false;
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('RAW Visits Report');

    // this.showableFilters.cluster = true;
    // this.showableFilters.territory = true;
    // this.showableFilters.salesman = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    this.showDate = true;

    this.loadInBatches(25000, 'visit_id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_v2_reports/getRawVisits';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Partner Details",
        children: [
          {headerName: 'PARTNER ID', field: 'entity_id', width: 80},
          {headerName: 'PARTNER NAME', field: 'outlet_name', enableRowGroup: true, pinned: 'left', width: 80},
          {headerName: 'TYPE', field: 'entity_type', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'CODE', field: 'outlet_code', width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'outlet_class', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'ZIP', field: 'outlet_zip', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'CITY', field: 'outlet_city', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'partner_state', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'TERRITORY', field: 'partner_territory', enableRowGroup: true, hide: true, width: 100},
          {headerName: "REGION", field: "partner_region", enableRowGroup: true, hide: true, width: 100},
          {headerName: "CLUSTER", field: "partner_cluster", enableRowGroup: true, hide: true, width: 100},
        ]
      }, {
        headerName: "Salesman Details",
        children: [
          {headerName: 'VISITED EMP', field: 'visited_user', enableRowGroup: true, width: 80},
          {headerName: 'EMP CODE', field: 'visited_emp_code', width: 80},
          {headerName: "ROLE", field: "user_role", enableRowGroup: true, hide: true, width: 100},
          {headerName: "DESIGNATION", field: "designation", enableRowGroup: true, hide: true, width: 100},
          {headerName: "TEAM", field: "team_name", width: 100, hide: true, enableRowGroup: true},
          {headerName: "TEAM CODE", field: "team_code", width: 100, hide: true, enableRowGroup: true},
          {headerName: "PARENT TEAM", field: "parent_team", width: 100, hide: true, enableRowGroup: true},
          {headerName: "BEAT", field: "beat_plan", enableRowGroup: true, width: 100},
          {headerName: "BEAT CODE", field: "beat_code", hide: true, width: 80},
          {headerName: 'REPORTING MANAGER', field: 'manager', hide: true, enableRowGroup: true, width: 150},
          {headerName: 'RM CODE', field: 'manager_code', hide: true, enableRowGroup: true, width: 150},
        ]
      }, {
        headerName: "Visit Details",
        children: [
          {
            headerName: 'VISIT TYPE', field: 'is_virtual', enableRowGroup: true, width: 80,
            valueGetter(params) {
              if (params.data.is_virtual) {
                if (params.data.virtual_verified && params.data.virtual_verified === 'Verified') {
                  return 'Virtual Call';
                } else {
                  return 'Unverified Call';
                }
              } else {
                return 'Physical Visit';
              }
            }
          },
          {headerName: 'VIRTUAL TYPE', field: 'virtual_type', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'PURPOSE', field: 'purpose', enableRowGroup: true, width: 80},
          {
            headerName: 'STATUS', field: 'is_complete', enableRowGroup: true, width: 80,
            valueGetter(params) {
              if (params.data.is_complete) {
                return 'Completed';
              } else {
                return 'Incomplete';
              }
            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'Completed') {
                  displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value + '</span>';
                } else {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value + '</span>';
                }
                return displayElem;
              }
            }
          },
          {headerName: 'PROGRESS', field: 'visit_progress', width: 80},
          {
            headerName: 'PLANNED TIME', field: 'planned_time', width: 80,
            valueGetter(params) {
              if (params.data.planned) {
                if (params.data.planned_time) {
                  return inThis.datePipe.transform((new Date(params.data.planned_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
                } else {
                  return 'NA';
                }
              } else {
                return 'Not Planned';
              }
            }
          },
          {
            headerName: 'UNSCHEDULED', field: 'uv', width: 80,
            valueGetter(params) {
              if (params.data.uv) {
                return 'Scheduled';
              } else {
                return 'Unscheduled';
              }
            }
          },
          {
            headerName: 'VISIT START', field: 'visit_start', width: 80,
            valueGetter: params => {
              if (params.data.visit_start) {
                return inThis.datePipe.transform((new Date(params.data.visit_start + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {
            headerName: 'VISIT END', field: 'visit_end', width: 80,
            valueGetter: params => {
              if (params.data.visit_end) {
                return inThis.datePipe.transform((new Date(params.data.visit_end + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {
            headerName: 'DURATION', field: 'visit_duration', width: 80,
            valueGetter(params) {
              if (params.data.visit_duration) {
                return (params.data.visit_duration / 60).toFixed(1) + " mins";
              } else {
                return 'NA';
              }
            }
          },
          {headerName: 'START COMMENT', field: 'start_comment', width: 80},
          {headerName: 'END COMMENT', field: 'end_comment', width: 80},
          {
            headerName: 'ORDER', field: 'visit_actions', width: 80,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action === 'Order') {
                    if (action_row.value) {
                      return action_row.value;
                    } else {
                      return action_row.comments;
                    }
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                } else {
                  displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value + '</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'NO ORDER', field: 'visit_actions', width: 80,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action === 'No Order') {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#379862; font-weight: 500;"></span>';
                } else {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value + '</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'AUDIT', field: 'visit_actions', width: 80,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action.includes('Retail Audit')) {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                } else {
                  displayElem = '<span style="color:#379862; font-weight: 500;">✅</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'KYC', field: 'visit_actions', width: 80, hide: true,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action.includes('Profiling')) {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value.includes('KYC')) {
                  displayElem = '<span style="color:#379862; font-weight: 500;">✅</span>';
                } else {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'SURVEY', field: 'visit_actions', width: 80, hide: true,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action.includes('Profiling')) {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value.includes('Survey')) {
                  displayElem = '<span style="color:#379862; font-weight: 500;">✅</span>';
                } else {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'TRAX', field: 'visit_actions', hide: true, width: 80,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action.includes('Trax')) {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                } else {
                  displayElem = '<span style="color:#379862; font-weight: 500;">✅</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'TASK', field: 'visit_actions', hide: true, width: 80,
            valueGetter(params) {
              if (params.data.visit_actions) {
                for (const action_row of params.data.visit_actions) {
                  if (action_row.action.includes('Task')) {
                    return action_row.name;
                  }
                }
                return 'NA';
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">❌</span>';
                } else {
                  displayElem = '<span style="color:#379862; font-weight: 500;">✅</span>';
                }
                return displayElem;
              }
            }
          },
          {
            headerName: 'INCOMPLETE', field: 'inc_actions', width: 80,
            valueGetter(params) {
              let inc_list = '';
              if (params.data.inc_actions) {
                const keys = Object.keys(params.data.inc_actions);
                for (let i = 0; i < keys.length; i++) {
                  const key = keys[i];
                  inc_list += key.toUpperCase() + ', ';
                }
              }
              if (inc_list.length > 0) {
                return inc_list.substr(0, inc_list.length - 2);
              } else {
                return 'NA';
              }

            }, cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params.value === 'NA') {
                  displayElem = '<span style="color:#379862; font-weight: 500;">❎</span>';
                } else {
                  displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value + '</span>';
                }
                return displayElem;
              }
            }
          },
          {headerName: 'SYNC TIME', field: 'sync_time', hide: true, width: 80},
          {headerName: 'UPDATED ON', field: 'update_time', hide: true, width: 80},
          {headerName: 'ID', field: 'visit_id', hide: true, width: 80},
          {headerName: 'ADDRESS', field: 'visit_addr', hide: true, width: 80},
          {
            headerName: "LOCATION", field: "url", maxWidth: 100, menuTabs: [],
            valueGetter(params) {
              if (params.data) {
                if (params.data.r_lat != null && params.data.r_lat !== "0.0") {
                  return 'http://www.google.com/maps/dir/' + params.data.r_lat + ',' + params.data.r_long + '/'
                    + params.data.a_lat + ',' + params.data.a_long;
                } else {
                  return 'http://maps.google.com/maps?q=' + params.data.a_lat + ',' + params.data.a_long;
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                if (params.data.a_lat != null && params.data.a_lat !== "0.0") {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                } else {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                }
              }
            }
          },
          {
            headerName: "DEVIATION", field: "visit_deviation", hide: true, width: 100,
            valueGetter(params) {
              if (!params.data.visit_deviation || params.data.visit_deviation === -1) {
                return 'NA';
              } else {
                return params.data.visit_deviation;
              }
            },
            cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params._self.checkInDistance == null && params.value > 300) {
                  displayElem = '<div style=" background-color:#ff866c;  text-align: center; padding: 0px;">' + params.value + 'm</div>';
                } else if (params._self.checkInDistance && params._self.checkInDistance !== null && params.value !== null && params._self.checkInDistance > 0 && params.value > params._self.checkInDistance) {
                  //if (params.value > 300) {
                  displayElem = '<div style=" background-color:#ff866c;  text-align: center;">' + params.value + 'm</div>';
                  // return {textAlign: 'center', backgroundColor: '#F8D1C8'};
                } else if (params.value === 'NA') {
                  displayElem = '<div style=" text-align: center;">❌</div>';
                  //return {textAlign: 'center'};
                } else {
                  displayElem = '<div style=" background-color:#379862;  text-align: center;">' + params.value + 'm</div>';
                  //return {textAlign: 'center'};
                }
                return displayElem;
              }
            },
            cellRendererParams: {
              _self: this
            }
          }
        ]
      }
    ];


  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'url') {

      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('a_lat') && rows[0].a_lat != null && rows[0].a_lat !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '70%',
            maxHeight: '95%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '65%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        }


      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
