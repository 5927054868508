<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h3>{{title}}</h3>
    </div>
    <div class="body-section">
      <div class="mt-3" [innerHTML]="description">

      </div>
    </div>
  </div>
</section>
