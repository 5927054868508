import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../_services/shared_service';
import {Router} from '@angular/router';
import {MatMenuTrigger} from "@angular/material";
import {DashboardFilterService} from "../services/dashboard-filter.service";
import {BoardMenuEventService} from "../grid/menu-service";
import {LocalforageService} from "../../_services/localforage.service";

@Component({
  selector: 'app-board-one',
  templateUrl: './board_one.html'
})
export class BoardOneComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showableFilters = {
    salesman: true,
    region: true,
    cluster: true,
    team: true,
    territory: true,
    state: true
  };

  filterData = {
    period: 'TM',
    region_id: 0,
    cluster_id: 0,
    team_id: 0,
    territory_id: 0,
    salesman_id: 0
  };
  showAddRows: boolean;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private dashboardFilterService: DashboardFilterService,
              private boardMenuEventService: BoardMenuEventService,
              protected _localforageService: LocalforageService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.sharedService.setHeader('Dashboard');

    //TODO: needs better implementation for predefined dashboard
    if (localStorage.getItem('board_diageo_manager') === 'true') {
      this.showAddRows = false;
    } else {
      this.showAddRows = true;
    }
  }

  quickSearch() {

  }

  setFilter(filteredValues) {

    this.filterData.region_id = 0;
    if (filteredValues.hasOwnProperty('region_id')) {
      this.filterData.region_id = parseInt(filteredValues.region_id, 10);
    }
    this.filterData.cluster_id = 0;
    if (filteredValues.hasOwnProperty('cluster_id')) {
      this.filterData.cluster_id = parseInt(filteredValues.cluster_id, 10);
    }
    this.filterData.team_id = 0;
    if (filteredValues.hasOwnProperty('team_id')) {
      this.filterData.team_id = parseInt(filteredValues.team_id, 10);
    }
    this.filterData.territory_id = 0;
    if (filteredValues.hasOwnProperty('territory_id')) {
      this.filterData.territory_id = parseInt(filteredValues.territory_id, 10);
    }
    this.filterData.salesman_id = 0;
    if (filteredValues.hasOwnProperty('salesman_id')) {
      this.filterData.salesman_id = parseInt(filteredValues.salesman_id, 10);
    }

    this.dashboardFilterService.sendMessage(this.filterData);
    this.trigger.closeMenu();
  }

  changePeriod(value) {
    this.filterData.period = value;
    this.dashboardFilterService.sendMessage(this.filterData);
  }


  clearFilter(): void {
    // clear messages
    this.dashboardFilterService.clearMessages();
  }

  add_grid_row(col) {
    this.boardMenuEventService.raiseMenuEvent({name: 'boardAddRowEvent', data: col});
  }

  delete_last_row() {
    this.boardMenuEventService.raiseMenuEvent({name: 'boardRowDeleteEvent', data: {}});
  }

  refreshBoard() {
    this._localforageService.DELETE_ALL();
    this.dashboardFilterService.sendMessage(this.filterData);
  }
}
