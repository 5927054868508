import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {TaskPlannerCreateUploadComponent} from "./task_planner_create_upload.component";
import {TaskPlannerDetailsUploadComponent} from "./task_planner_details_upload.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {jsonToTableHtmlString} from "json-table-converter";

@Component({
  selector: 'app-task',
  templateUrl: '../../pagebase/pagebasev2.html',
  styleUrls: ['./task_planner.component.scss']
})
export class TaskPlannerComponent extends PageBase implements OnInit, IPage {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: '',
    values: [],
    show: true
  };

  showCustomFilter1 = true;
  customFilter1: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [
    {name: 'Confirm Task', feature: 'feature_confirm_task_plan', type: 'approve'},
    {name: 'Submit Task', feature: 'feature_submit_task_plan', type: 'approve'},
    {name: 'Approve Task', feature: 'feature_approve_task_plan', type: 'approve'},
    {name: 'Reject Task', feature: 'feature_approve_task_plan', type: 'reject'}
  ];
  enableConfirm = false;
  enableApprove = false;
  enableSubmit = false;
  task_types = [];
  task_type: any = {};
  reject_reason = '';
  approve_comments = '';


  ngOnInit(): void {
    this.sharedService.setHeader('Task Planner');
    this.getAuthorizedActions();
    if (this.matAuthorizedActionMenuItems && this.matAuthorizedActionMenuItems.length > 0) {
      this.showActionButton = true;
    }
    this.enableConfirm = this.sharedService.ACLcheck('feature_confirm_task_plan');
    this.enableApprove = this.sharedService.ACLcheck('feature_approve_task_plan');
    this.enableSubmit = this.sharedService.ACLcheck('feature_submit_task_plan');
    this.gridOptions.isRowSelectable = params => {
      if ((this.enableConfirm && params.data.status === 'draft') ||
        (this.enableSubmit && params.data.status === 'updated') || (this.enableApprove && params.data.status === 'submitted')) {
        return true;
      } else {
        return false;
      }
    };
    this.gridOptions.getRowStyle = params => {
      if (params.data && params.data.reject_reason && params.data.reject_reason !== 'null') {
        return {background: '#F8D1C8'};
      }
    };
    if (this.sharedService.ACLcheck('feature_upload_task_plan_master')) {
      this.showCreate = true;
    }
    if (this.sharedService.ACLcheck('feature_upload_task_plan_details')) {
      this.showBaseUpload = true;
    }
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_types = this.task_types.filter(x => x.type === 'spl');
    this.task_type = this.task_types.find(x => x.type === 'spl');

    this.customFilter1.values = [
      {id: "", itemName: "All Status"},
      {id: "draft", itemName: "Draft"},
      {id: "new", itemName: "New"},
      {id: "updated", itemName: "Updated"},
      {id: "submitted", itemName: "Submitted"},
      {id: "approved", itemName: "Approved"},
      {id: "published", itemName: "Published"},
    ];
    if (this.sharedService.ACLcheck('feature_upload_task_plan_master')) {
      this.customFilter1.value = 'draft';
    } else if (this.enableSubmit) {
      this.customFilter1.value = 'new';
    } else if (this.enableApprove) {
      this.customFilter1.value = 'submitted';
    } else {
      this.customFilter1.value = 'approved';
    }
    if (this.task_types && this.task_types.length > 0) {
      this.task_types.forEach(type => {
        this.customFilter.values.push({id: type.id, itemName: type.itemname});
      });
      this.userData.customFilter['type'] = this.customFilter.values[0].id;
      this.customFilter.value = this.customFilter.values[0].id;
      this.statusConfig.success = 'approved';
      this.statusConfig.warning = 'updated';
      this.statusConfig.error = 'rejected';
      this.statusConfig.new = 'new';
      this.statusConfig.processed = 'published';

      this.configureGrid();
      this.loadServerData();
    } else {
      this.toastr.error("Special Task Types not Configured!!");
    }
  }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/tasks/get_task_plan_list';
    this.columnDefs = [
      {
        headerName: "Task ID", field: "id", width: 150,
        headerCheckboxSelection: (this.enableApprove),
        headerCheckboxSelectionFilteredOnly: (this.enableApprove),
        checkboxSelection: params => {
          if (params.data) {
            if ((params.context.componentParent.enableConfirm && params.data.status === 'draft') ||
              (params.context.componentParent.enableSubmit && params.data.status === 'updated')
              || (params.context.componentParent.enableApprove && params.data.status === 'submitted')) {
              return true;
            } else {
              return false;
            }
          }
        }
      },
      {headerName: "Region", field: "region", width: 150},
      {headerName: "Region Head", field: "region_mgr", width: 150},
      {headerName: "State", field: "state", width: 150},
      {headerName: "Cluster", field: "cluster", width: 150},
      {headerName: "Cluster Head", field: "cluster_mgr", width: 150},
      {headerName: "Category", field: "category", width: 100},
      {headerName: "Brand", field: "brand", width: 100},
      {headerName: "Channel", field: "channel", width: 100},
      {headerName: "Task Code", field: "code", width: 250},
      {headerName: "Task Name", field: "name", width: 150},
      {headerName: "Description", field: "description", width: 150},
      {headerName: "Period", field: "period", width: 100},
      {headerName: "Start Date", field: "start_date", width: 100},
      {headerName: "End Date", field: "end_date", width: 100},
    ];
    this.task_type = this.task_types.find(x => x.id.toString() === this.userData.customFilter['type'].toString());
    if (this.task_type && this.task_type?.param_json?.config) {
      this.task_type.param_json.config.forEach(param => {
        inThis.columnDefs.push({headerName: param.name, field: param.field, width: 100});
      });
    }
    if (this.task_type && this.task_type?.param_json?.params) {
      this.task_type.param_json.params.forEach(param => {
        inThis.columnDefs.push({headerName: param.name, field: param.field, width: 100});
      });
    }
    this.columnDefs.push({headerName: "No Of Outlet", field: "line_count", cellStyle: {textAlign: 'right'}, width: 100},
      {headerName: "Reject Reason", field: "reject_reason", width: 100},
      {headerName: "Approve Comments", field: "approve_comments", width: 100},
      {
        headerName: "Create Date", field: "create_date", width: 150, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Created By", field: "created_by", width: 150, hide: true},
      {
        headerName: "Details Submitted Date", field: "submitted_at", width: 150, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['submitted_at']) {
              return inThis.datePipe.transform((new Date(params.data.config_json['submitted_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Details Submitted By", field: "submitted_by", width: 150, hide: true},
      {
        headerName: "Reviewed Date", field: "reviewed_at", width: 150, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['reviewed_at']) {
              return inThis.datePipe.transform((new Date(params.data.config_json['reviewed_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Reviewed By", field: "reviewed_by", width: 150, hide: true},
      {
        headerName: "Retailers Added Date", field: "retailer_uploaded_at", width: 150, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['retailer_uploaded_at']) {
              return inThis.datePipe.transform((new Date(params.data.config_json['retailer_uploaded_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Retailers Added By", field: "ret_added_by", width: 150, hide: true},
      {headerName: "Status", field: "status", minWidth: 120, cellRenderer: inThis.sharedService.statusCellRenderer, pinned: 'right'});
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
    this.filteredData = this.rowData;
  }

  configureData() {
    const inThis = this;
    this.rowData = [];
    this.task_type = this.task_types.find(x => x.id.toString() === this.userData.customFilter['type'].toString());
    this.apiData.forEach(row => {
      const indrow = inThis.constructRow(row);
      this.rowData.push(indrow);
    });
  }

  constructRow(row) {
    if (row.config_json) {
      if (row.config_json.task_config) {
        /*row.cluster = row.config_json.task_config['cluster'];
        row.category = row.config_json.task_config['category'];
        row.channel = row.config_json.task_config['channel'];
        row.activity_code = row.config_json.task_config['activity_code'];
        row.growth_driver = row.config_json.task_config['growth_driver'];
        row.quarter = row.config_json.task_config['quarter'];*/

        /*for (const [key, value] of Object.entries(row.config_json.task_config)) {
          if (key !== 'cluster_id' && key !== 'category_id' && key !== 'channel_id' && key !== 'brand_id') {
            row[key] = value;
          }
        }*/
        if (this.task_type && this.task_type?.param_json?.config) {
          this.task_type.param_json.config.forEach(param => {
            row[param.field] = row.config_json.task_config[param.field];
          });
        }
      }
      if (row.config_json.params) {
        if (this.task_type && this.task_type?.param_json?.params) {
          this.task_type.param_json.params.forEach(param => {
            row[param.field] = row.config_json.params[param.field];
          });
        }
      }
      if (row.config_json.reject_reason && row.config_json.reject_reason !== 'null') {
        row.reject_reason = row.config_json.reject_reason;
      }
      if (row.config_json.approve_comments && row.config_json.approve_comments !== 'null') {
        row.approve_comments = row.config_json.approve_comments;
      }
    }
    return row;
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('taskid', event.data.id);
    this.router.navigate([this.apiService.redirect_url + '/task/task_detail'], {
      state: {
        viewtype: 'detail',
        taskid: event.data.id
      }
    });
  }

  /*onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }*/
  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (menuitem.name === 'Confirm Task') {
      if (this.validateAction(rows, 'save')) {
        this.openConfirmTaskDialog(rows);
      } else {
        this.toastr.error('Please Select only Draft Status Data');
      }
    } else if (menuitem.name === 'Submit Task') {
      if (this.validateAction(rows, 'submit')) {
        this.openSubmitDialog(rows);
      } else {
        this.toastr.error('Please Select only Updated Status Data');
      }
    } else if (menuitem.name === 'Approve Task') {
      if (this.validateAction(rows, 'approve')) {
        //this.openConfirmDialog(rows);
        this.openApproveRejectPopup(rows, 'approve');
      } else {
        this.toastr.error('Please Select only Submitted Status Data');
      }
    } else if (menuitem.name === 'Reject Task') {
      if (this.validateAction(rows, 'reject')) {
        this.openApproveRejectPopup(rows, 'reject');
      } else {
        this.toastr.error('Please Select only Submitted Status Data');
      }
    }
  }

  validateAction(rows, action) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (action === 'save' && rows[i].status !== 'draft') {
        valid = false;
        break;
      }
      if (action === 'submit' && rows[i].status !== 'updated') {
        valid = false;
        break;
      }
      if ((action === 'approve' || action === 'reject') && rows[i].status !== 'submitted') {
        valid = false;
        break;
      }
    }
    return valid;
  }

  openCreateDialog() {
    this.openTaskPlannerCreateUpload();
  }

  showUploadPopup() {
    this.openTaskPlannerDetailsUpload();
  }

  openTaskPlannerCreateUpload() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }
      };
      creatediaeref = this.dialog.open(TaskPlannerCreateUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }
      };
      creatediaeref = this.dialog.open(TaskPlannerCreateUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  openTaskPlannerDetailsUpload() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "90vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }
      };
      creatediaeref = this.dialog.open(TaskPlannerDetailsUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }

      };
      creatediaeref = this.dialog.open(TaskPlannerDetailsUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  openConfirmDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Approve Task",
        message1: "You have selected " + rows.length + " Task(s) for APPROVAL.",
        message2: "Are you sure you want to approve these Tasks?",
        showNoButton: true,
        yesButton: "APPROVE",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.approveRejectTasks(rows, 'approve', null);
      } else {

      }
    });
  }

  fetchUsersFromProfile(rows, stage) {
    let profiles: any = [];
    const cluster: any = [];
    let subject = '';
    const message = '';
    let htlmtext = '';
    const create_email_ids = [];
    const inThis = this;
    const username = JSON.parse(localStorage.getItem('user')).user_name;
    const date = this.datePipe.transform(new Date(), 'yyyyMMdd');
    if (stage === 'create') {
      subject = 'New Task Plan Created' + ' - ' + date.toString();
      htlmtext = '<span style="display:block;">Hi,</span><span style="display:block;margin-bottom: 20px;">HO Shopper Marketing has shared TTL guidelines for your state/cluster. The guidelines are attached here.\n' +
        'Regional CM team kindly complete the next steps to update required details.</span>';
      profiles = ['RCMH', 'RCMM', 'SCMM', 'SCME'];
    } else if (stage === 'submit') {
      subject = 'Task Plan Details Submitted' + ' - ' + date.toString();
      htlmtext = '<span style="display:block;">Hi,</span><span style="display:block;margin-bottom: 20px;">Plan details have been uploaded for against the HO TTL guidelines. The updated plans are attached here.\n' +
        'Kindly approve the plan for the State CM team to proceed with retailer-wise planning</span>';
      profiles = ['RCMH'];
    } else if (stage === 'approve') {
      subject = 'Task Plan Approved' + ' - ' + date.toString();
      htlmtext = '<span style="display:block;">Hi,</span><span style="display:block;margin-bottom: 20px;">Below TTL Plan details have been approved.SCMM/SCME can now proceed with upload of outlet level planning.</span>';
      profiles = ['RCMM', 'SCMM', 'SCME'];
    } else if (stage === 'reject') {
      subject = 'Task Plan Rejected' + ' - ' + date.toString();
      htlmtext = '<span style="display:block;">Hi,</span><span style="margin-bottom: 20px;">Below TTL Plan details have been Rejected with reason - <span style="font-weight: bold;color:red;">' + this.reject_reason.toString() + '</span>. Please refer to the portal for more details.</span><span style="display: block;margin-bottom: 20px;"></span>';
      profiles = ['RCMM'];
    }
    const uqClusterRows = this.sharedService.unique(rows, ['cluster']);
    uqClusterRows.forEach(row => {
      cluster.push(row['cluster']);
    });
    const email_ids = [];
    const uqCreatedby_email = this.sharedService.unique(rows, ['create_email']);
    if (uqCreatedby_email) {
      uqCreatedby_email.forEach(row => {
        if (row['create_email']) {
          create_email_ids.push(row['create_email']);
        }
      });
    }
    const uploadedData = [];
    for (const key in rows) {
      const indrow = {};
      for (const [key1, value] of Object.entries(rows[key])) {
        if (key1 !== 'config_json' && key1 !== 'type_id' && key1 !== 'status' && key1 !== 'valid' && key1 !== 'errorMsg') {
          if (!value) {
            indrow[this.sharedService.capitalize(key1)] = '';
          } else {
            if (key1.toString().toLowerCase().includes('date')) {
              if (key1.toString().toLowerCase() === 'start_date' || key1.toString().toLowerCase() === 'end_date') {
                indrow[this.sharedService.capitalize(key1)] = inThis.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd');
              } else {
                indrow[this.sharedService.capitalize(key1)] = inThis.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              }
            } else {
              indrow[this.sharedService.capitalize(key1)] = value;

            }
          }
        }
      }
      if (stage === 'submit') {
        indrow['Submitted By'] = username;
      } else if (stage === 'approve' || stage === 'reject') {
        indrow['Reviewed By'] = username;
      }
      uploadedData.push(indrow);
    }
    const html = jsonToTableHtmlString(uploadedData, {});
    htlmtext = htlmtext + html;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken')
    paramObject.data = {
      cluster,
      profiles
    };
    this.apiService.post('/api/res_users/get_cluster_users_from_profile', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          const data = res.results.data;
          if (data && data.length > 0) {
            data.forEach(row => {
              if (row.user_email) {
                email_ids.push(row.user_email);
              }
            });
            this.sendEmail(email_ids, create_email_ids, subject, message, htlmtext);
          }
        } else {
          this.toastr.error("Failed to fetch Cluster Users");
        }
      });
  }

  openConfirmTaskDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Confirm Task",
        message1: "You have selected " + rows.length + " Task(s) to Confirm.",
        message2: "Are you sure you want to Confirm these Tasks?",
        showNoButton: true,
        yesButton: "Confirm",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.confirmTasks(rows);
      } else {

      }
    });
  }

  openSubmitDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Submit Task",
        message1: "You have selected " + rows.length + " Task(s) to Submit.",
        message2: "Are you sure you want to submit these Tasks?",
        showNoButton: true,
        yesButton: "SUBMIT",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.submitTasks(rows);
      } else {

      }
    });
  }

  openApproveRejectPopup(rows, type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'reject') {
      title = "Reject Task Plan";
      message1 = "Are you sure you want to Reject " + rows.length + ' Task Plan(s)?';
      message2 = "Please provide a detailed reason for the Rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Task Plan";
      message1 = "Are you sure you want to Approve " + rows.length + ' Task Plan(s)?';
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "380px",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectTasks(rows, 'reject', data);
        } else {
          this.approveRejectTasks(rows, 'approve', data);
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectTasks(rows, 'reject', data);
        } else {
          this.approveRejectTasks(rows, 'approve', data);
        }
      });
    }
  }

  confirmTasks(rows) {
    const ids = [];
    rows.forEach(row => {
      ids.push(row.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids
    };
    this.apiService.post('/api/tasks/confirm_task_plan', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(rows.length + " Task(s) Confirmed Successfully");
          this.fetchUsersFromProfile(rows, 'create');
          this.loadServerData();
        } else {
          this.toastr.error("Failed to Confirm Tasks");
        }
      });
  }

  submitTasks(rows) {
    const ids = [];
    rows.forEach(row => {
      ids.push(row.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids
    };
    this.apiService.post('/api/tasks/submit_task_plan', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(rows.length + " Task(s) Submitted Successfully");
          this.fetchUsersFromProfile(rows, 'submit');
          this.loadServerData();
        } else {
          this.toastr.error("Failed to Submit Tasks");
        }
      });
  }

  approveRejectTasks(rows, state, reason) {
    const ids = [];
    rows.forEach(row => {
      ids.push(row.id);
    });
    let status = '';
    if (state === 'approve') {
      status = 'approved';
      this.approve_comments = reason;
    } else {
      status = 'updated';
      this.reject_reason = reason;
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids,
      reason,
      status
    };
    this.apiService.post('/api/tasks/review_task_plan', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(rows.length + " Task(s) " + state.toUpperCase() + " Successfully");
          this.fetchUsersFromProfile(rows, state);
          this.loadServerData();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Tasks");
        }
      });
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config_json && params.data.config_json.task_config) {
          if (params.data.config_json.task_config[field]) {
            return params.data.config_json.task_config[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  configDetailValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config_json && params.data.config_json.params) {
          if (params.data.config_json.params[field]) {
            return params.data.config_json.params[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  sendEmail(emailids, cc_email, subject, message, htmlText) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      emailids,
      cc_email,
      subject,
      message,
      htmlText
    };
    this.apiService.post('/api/res_users/sendEmail', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          console.log("Email sent successfully");
        } else {
          console.log("Failed to send email");
        }
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
      }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }
}
