<div [@error]="errorExists" *ngIf="errorExists" class="alert alert-danger alert-dismissible" role="alert">
    <i class="close icon" (click)="closeMessage()"></i>
    <div class="alert-heading">
        {{errorObject.summary}}
    </div>
    <p>
        {{errorObject.detail}}
    </p>

    <p *ngif="!errorObject.resource">
        resource: {{errorObject.resource}}
    </p>

    <div *ngif="errorObject.solutions">
      <span class="alert-heading"> Potential solutions</span>
      <hr style="color:rgba(194,197,200,0.37)">
      <div style="text-align: left !important" *ngFor="let solution of errorObject.solutions">
          <br><span class="alert-heading">article:&nbsp;</span> {{solution.link}}
          <br><span class="alert-heading">summary:&nbsp;</span> {{solution.summary}}
      </div>
    </div>
</div>
