import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-distributor-audit',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: []
})
export class DistributorAuditComponent extends PageBase implements OnInit {


  ngOnInit() {
    this.sharedService.setHeader('Distributor Audit');
    this.gridOptions.context = this;
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/audits/getDistributorAudit';
    this.columnDefs = [
      {headerName: 'id', field: 'id', hide: true, width: 150},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'DIST CODE', field: 'dist_code', width: 80, enableRowGroup: true},
      {headerName: 'DISTRIBUTOR', field: 'dist', enableRowGroup: true, width: 180},
      /* {headerName: 'START DATE', field: 'start_date', width: 100, enableRowGroup: true},*/
      {
        headerName: 'AUDIT DATE', field: 'end_date', menuTabs: [], width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_date) {
              return params.context.datePipe.transform((new Date(params.data.end_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.end_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'PREVIOUS AUDIT DATE', field: 'last_audit_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.last_audit_date) {
              if (params.data.last_audit_date !== '1990-01-01') {
                return params.context.datePipe.transform((new Date(params.data.last_audit_date + ' UTC')).toString(), 'yyyy-MM-dd');
              } else {
                return '--';
              }
            } else {
              return params.data.last_audit_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'PERIOD', field: 'period', enableRowGroup: true, width: 70},
      {headerName: 'STATUS', field: 'status', width: 100},
      /* {headerName: 'STOCK TYPE', field: 'stock_type', width: 150},*/
      {headerName: 'APPROVED BY', field: 'approved_by', enableRowGroup: true, width: 150},
      {
        headerName: 'APPROVED DATE', field: 'approved_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.approved_date) {
              return params.context.datePipe.transform((new Date(params.data.approved_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.approved_date;
            }
          } else {
            return '';
          }
        }
      },
    ];
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('DAid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/distributor_audit/detail'], {
      state: {
        viewtype: 'detail',
        DAid: rows[0].id
      }
    });
  }

}
