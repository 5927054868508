import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-grid',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
      <div class="cardgriddash">
        <ng-container *ngFor="let data of widget.data.data; let i = index">
          <div>
            <span class="value">{{widget.data.value[data.key]}}</span>
            <span class="gridtext">{{data.label}}</span>
          </div>
        </ng-container>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GridWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  value;
  resizeSub: Subscription;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.widget.data.data.forEach(data => {
      if (!this.widget.data.value[data.key]) {
        this.widget.data.value[data.key] = 0;
      } else {
        //TODO code change by Hemanth. Review pending by Nivil.
        if (data.value_type === 'string') {
          this.widget.data.value[data.key] = this.widget.data.value[data.key].toString();
        } else {
          if (Number(this.widget.data.value[data.key]) % 1 !== 0 || this.widget.data.value[data.key].toString().includes('.')) {
            this.widget.data.value[data.key] = parseFloat(this.widget.data.value[data.key]).toFixed(2);
            this.widget.data.value[data.key] = this.formatNUmber(this.widget.data.value[data.key], data.value_type);
            if (this.widget.data.value[data.key] === '0.00') {
              this.widget.data.value[data.key] = 0;
            }
          } else {
            this.widget.data.value[data.key] = this.formatNUmber(this.widget.data.value[data.key], data.value_type);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  formatNUmber(value, value_type) {
    if (value) {
      if (value_type && value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else if (value_type && value_type === 'string') {
        return value.toString();
      } else {
        return value;
      }
    } else {
      return 0;
    }
  }
}
