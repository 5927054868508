import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SalesmanPerformanceMeasureReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Salesman Performance Measure Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'salesman_id');
    this.showProgressCustomText = true;
    // this.multiInstance = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Salesman',
      field: 'salesman',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSalesmanPerformanceMeasureReport';

    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['sales', 'order_count', 'line_count', 'uq_lines', 'uq_billed'];

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance',  width: 80, enableRowGroup: true },
      {headerName: 'REGION', field: 'region', enableRowGroup: true,  width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true,  width: 80},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'ROLE', field: 'role', enableRowGroup: true,  width: 80},
      {headerName: 'STATUS', field: 'status', width: 80},
      {headerName: 'OUTLETS COUNT', field: 'retailer_count',  width: 80},
      {headerName: 'CATEGORY', field: 'categ', width: 80, enableRowGroup: true},
      {headerName: 'BRAND', field: 'brand', width: 80, enableRowGroup: true},
      {headerName: 'ORDER COUNT', field: 'order_count',  width: 80, enableRowGroup: true, aggFunc: 'sum'},
      {headerName: 'SALES', field: 'sales',  width: 80, enableRowGroup: true, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value),
        valueGetter: params => {
          if (params.data) {
            if (params.data.sales) {
              return params.data.sales;
            } else {
              return 0;
            }
          }
        }},
      {headerName: 'SKU COUNT', field: 'line_count',  width: 80, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'UNIQUE SKU', field: 'uq_lines',  width: 80, aggFunc: this.avgAggFunction, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'AVG BILL VALUE', field: 'avg_sales',  width: 80, aggFunc: this.avgAggFunction, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'AVG LINE SOLD', field: 'avg_lines_bill',  width: 80, aggFunc: this.avgAggFunction, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'UQ BILLED', field: 'uq_billed',  width: 80, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'UQ BILLED PCT', field: 'uq_billed_pct',  width: 80, aggFunc: this.avgAggFunction, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'AVG BEAT SALES', field: 'avg_beat_sales',  width: 80, aggFunc: this.avgAggFunction, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
    ];

  }



}
