import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-finance-verify-popup',
  templateUrl: './finance-verify-popup.component.html',
  styleUrls: ['./finance-verify-popup.component.scss']

})

export class FinanceVerifyPopupComponent implements OnInit {

  public credit_limit = 0;
  public credit_days;
  public payment_mode;
  public verify_json;
  public partner_id;
  public refreshDlg = false;
  public enable_finance = false;
  public pricelist = null;
  public chain_pricelist = null;
  public pricelists = [{id: 0, name: 'No pricelist specified'}];
  public selectedPrice = [];
  public pricelist_id = null;
  paramcredit = 0;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.verify_json = dialogdata.params.verify;
    this.partner_id = dialogdata.params.id;
    this.pricelist = dialogdata.params.pricelist;
    this.chain_pricelist = dialogdata.params.chain_pricelist;
    this.payment_mode = dialogdata.params.mode;
    if (this.payment_mode !== null && this.payment_mode !== "") {
      this.payment_mode = this.payment_mode
        .replace(/_/g, ' ')
        .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }
    if (this.payment_mode.toLowerCase() === 'credit') {
      this.credit_limit = dialogdata.params.credit_limit;
      this.paramcredit = dialogdata.params.credit_limit;
    }
    this.pricelists = this.pricelists.concat(JSON.parse(localStorage.getItem('pricelists')));
    this.selectedPrice = [this.pricelists[0]];
    this.pricelist_id = this.selectedPrice[0]['id'];
    let localPricelist = '';
    if (this.chain_pricelist) {
      //this.pricelists = [{id: 0, name: this.chain_pricelist}].concat(this.pricelists);
      localPricelist = this.chain_pricelist;
    } else if (this.pricelist) {
      //this.pricelists = [{id: 0, name: this.chain_pricelist}].concat(this.pricelists);
      localPricelist = this.pricelist;
    }
    const found = this.pricelists.filter(obj => {
      return (obj.name === localPricelist);
    });
    if (found !== null) {
      this.selectedPrice = [found[0]];
      this.pricelist_id = this.selectedPrice[0]['id'];
    }
    if (this.verify_json.chain.verified === true || this.verify_json.chain.verified === 'true') {
      this.enable_finance = true;
    }
  }

  ngOnInit(): void {
  }

  priceChange(event) {
    debugger;
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.pricelist_id = event[0].id;
      } else if (event[0].name === 'No pricelist specified') {
        this.pricelist_id = null;
      }
      this.selectedPrice = event;
    }
  }

  verifyFinance() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.verify_json.finance.verified = true.toString();
    this.verify_json.finance.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.verify_json.finance.by = JSON.parse(localStorage.getItem('user')).user_name;
    let mode = 'cash';
    if (this.payment_mode === 'Credit') {
      mode = 'credit';
    }
    const userData = {
      access_token: localStorage.getItem('resfreshToken'),
      data: {
        payment_mode: mode,
        credit_limit: this.credit_limit,
        cl_days: this.credit_days,
        verification_json: this.verify_json,
        partner_id: this.partner_id,
        pricelist_id: this.pricelist_id
      }
    };
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Approval Request');
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error updating Approval Request');
        });

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  paymentModeChange() {
    if (this.payment_mode.toLowerCase() === 'credit') {
      this.credit_limit = this.paramcredit;
    } else {
      this.credit_limit = 0;
    }
  }

}
