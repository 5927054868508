<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue"
                   class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
                         placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;">
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="run()"
                  >GO
                  </button>
                </div>
              </li>
              <li class="margLR" style="margin-top: 0px;">
                <app-excelicon [gridOption]="gridOptions" [title]="'Growth Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="col-md-8 pad0">
            <div class="growth-only">
              <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 81vh" class="ag-theme-balham"

                               [columnDefs]="columnDefs"
                               [gridOptions]="gridOptions"
                               (gridReady)="onGridReady($event)"
                               [frameworkComponents]="frameworkComponents"
                               [suppressRowClickSelection]="true"
                               [suppressAggFuncInHeader]="true"
                               (cellClicked)="onCellClicked($event)"
                               (rowSelected)="onRowSelected()"
                               (selectionChanged)="onSelectionChanged($event)"
                               [context]="context">
              </ag-grid-angular>
              <div *ngIf="preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="emptyState">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="dataLoaded" style="height: 80vh;
  overflow-y: scroll; ">
            <div class="row chartcard">
              <span class="chartheading">Target VS Achievement</span>
              <ngx-charts-bar-horizontal
                [view]="view2"
                [scheme]="colorScheme1"
                [results]="tVSaChartData"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [xAxisTickFormatting]="formatNumber"
                [barPadding]="1"
                [legend]="showLegend"
                [legendTitle]="''"
                [legendPosition]="'below'"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [animations]="animations"
                (select)="onSelect($event)"
              >
              </ngx-charts-bar-horizontal>
            </div>
            <div class="row chartcard">
              <span class="chartheading">Channel Projection</span>
              <ngx-charts-bar-horizontal-2d
                [view]="view"
                [scheme]="colorScheme"
                [results]="channelChartData"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [xAxisTickFormatting]="formatNumber"
                [barPadding]="1"
                [legend]="showLegend"
                [legendTitle]="''"
                [legendPosition]="'below'"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [animations]="animations"
                (select)="onSelect($event)"
              >
              </ngx-charts-bar-horizontal-2d>
              <div style="clear: both">
                <div class="row">
                  <div class="input-color">
                    <span class="input-color color-box" style="background-color: #F3622D"></span>
                    <span class="input-color color-box-text">LMTD</span>
                  </div>
                  <div class="input-color">
                    <span class="input-color color-box" style="background-color: #57B757"></span>
                    <span class="input-color color-box-text">MTD</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row chartcard" >
              <span class="chartheading">{{categoryChartTitle}}</span>
              <ngx-charts-bar-horizontal-2d
                [view]="view1"
                [scheme]="colorScheme"
                [results]="categoryChartData"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [xAxisTickFormatting]="formatNumber"
                [barPadding]="1"
                [legend]="showLegend"
                [legendTitle]="''"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [animations]="animations"
              >
              </ngx-charts-bar-horizontal-2d>
              <div style="clear: both">
                <div class="row">
                  <div class="input-color">
                    <span class="input-color color-box" style="background-color: #F3622D"></span>
                    <span class="input-color color-box-text">LMTD</span>
                  </div>
                  <div class="input-color">
                    <span class="input-color color-box" style="background-color: #57B757"></span>
                    <span class="input-color color-box-text">MTD</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
