import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-delivery-plan-popup',
  templateUrl: './delivery_plan_popup.component.html',
  styleUrls: ['./delivery_plan_popup.component.scss']

})

export class DeliveryPlanPopupComponent implements OnInit {

  dp_mode = "create";
  deliveryPlanData: any = {};
  myDate;
  userList: any = [];
  API_URL = '';
  invoiceData = [];
  dpList = [];
  invoicecount = 0;
  weight = 420;
  volume = 400;
  beat;
  USER;
  mid;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.USER = JSON.parse(localStorage.getItem('user'));
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.deliveryPlanData.date = this.myDate;
    //this.deliveryPlanData.invoices = dialogdata.invoices;
    this.invoiceData = dialogdata.invoices;
    const invoiceids = [];
    // tslint:disable-next-line:forin
    for (const key in dialogdata.invoices) {
      invoiceids.push(dialogdata.invoices[key].id);
    }
    this.deliveryPlanData.invoices = invoiceids;
    if (this.userList !== null && this.userList !== undefined && this.userList.length > 0) {
      this.deliveryPlanData.del_user_id = this.userList[0].id;
    }
    this.fetchUserandBeats();
    this.fetchDeliveryPlans();
  }

  ngOnInit(): void {

  }

  get_mid(object_id) {
    if (object_id) {
      return this.USER.id + '_' +  object_id + '_' + (new Date()).getTime();
    } else {
      return this.USER.id + '_' + (new Date()).getTime();
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  changeDate(dt) {
    this.deliveryPlanData.date = this.datePipe.transform(this.deliveryPlanData.date, 'yyyy-MM-dd');
  }

  changeDate1(dt) {
    this.deliveryPlanData.date = this.datePipe.transform(this.deliveryPlanData.date, 'yyyy-MM-dd');
    this.fetchDeliveryPlans();
  }

  selectTodaysDate() {
    this.deliveryPlanData.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  selectTommorowDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    this.deliveryPlanData.date = this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  fetchUserandBeats() {
    this.API_URL = '/api/res_users/fetchBeatsandDelExec';
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    const beat_ids = [];
    for (const key in this.invoiceData) {
      beat_ids.push(this.invoiceData[key].beat_id);
    }
    paramObject.beat_ids = beat_ids;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.beat = res.results.data.beats;
          this.deliveryPlanData.beats = res.results.data.beats;
          this.userList = res.results.data.users;
          this.deliveryPlanData.del_user_id = this.userList[0].id;
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("User fetch failed");
        }
      });
  }

  fetchDeliveryPlans() {
    this.dpList = [];
    this.deliveryPlanData.reference = null;
    this.API_URL = '/api/inv_delivery_plans/getInvoiceDeliveryPlans';
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.start_date = this.deliveryPlanData.date;
    paramObject.end_date = this.deliveryPlanData.date;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dpList = res.results.data;
          /*if (this.dpList !== null && this.dpList !== undefined && this.dpList.length > 0) {
            this.deliveryPlanData = this.dpList[0];
          }*/
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Delivery Plan fetch Failed");
        }
      });
  }

  updateDeliveryPlan() {
    this.API_URL = '/api/inv_delivery_plans/save_delivery_plan';
    if (this.dp_mode === 'add') {
      this.deliveryPlanData.mid = this.get_mid('');
      const invoiceids = [];
     /* for (const key in this.deliveryPlanData.invoices) {
        if (this.deliveryPlanData.invoices[key].inv_sid !== null) {
          invoiceids.push(this.deliveryPlanData.invoices[key].inv_sid);
        }
      }*/
      // tslint:disable-next-line:forin
      for (const key in this.invoiceData) {
        this.deliveryPlanData.invoices.push(this.invoiceData[key].id);
      }

      // if(this.deliveryPlanData)
      if (this.deliveryPlanData.del_user_id !== null && this.userList !== undefined && this.userList.length > 0) {
        const selectedUser = this.userList.find(item => item.id === this.deliveryPlanData.del_user_id);
        this.deliveryPlanData.del_person = selectedUser.itenName;
      }

    }
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.deliveryPlanData;
    // paramObject.data.push);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            if (this.dp_mode === 'create') {
              this.toastr.success('Delivery Plan created Successfully');
            } else {
              this.toastr.success('Delivery Plan updated Successfully');
            }
            this.dialog.closeAll();
          } else if (res.hasOwnProperty('results')) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error("Delivery Plan creation Failed");
          }
        },
        error => {
          console.log(error);
          this.toastr.error("Delivery Plan creation Failed");
        });
  }

  changeSelection() {
    if (this.dp_mode === 'create') {
      this.deliveryPlanData.beats = this.beat;
      const invoiceids = [];
      for (const key in this.invoiceData) {
        invoiceids.push(this.invoiceData[key].id);
      }
      this.deliveryPlanData.type = 'S';
      this.deliveryPlanData.invoices = invoiceids;
    } else {
      if (this.dpList !== null && this.dpList !== undefined && this.dpList.length > 0) {
        this.deliveryPlanData = this.dpList[0];
      }
    }
  }

  populateData() {
    this.deliveryPlanData = this.dpList.find(existed => existed.reference = this.deliveryPlanData.reference);
    this.invoicecount = this.deliveryPlanData.invoices.length;
  }
}
