<section class="dialog-section" style="height: 100%;">
  <div *ngIf="dataLoaded" class="container-padding" style="height: 74%;">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{surveyQuestions[0].survey}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;
  height: 100%;overflow-y: auto;">
      <form novalidate id="ngForm" #surveyform="ngForm">
        <ng-container *ngFor="let question of surveyQuestions; let i = index">
          <div style="margin-top: 15px;">
            <p class="dialogspan" style="display: inline;">{{i + 1}}. {{question.question}}</p>
            <span *ngIf="question.mandatory" class="requiredspan"> *</span>
          </div>
          <div *ngIf="!edit">
            <p *ngIf="question.type !=='image'" class="dialogspanbold">{{question.answer_text}}</p>
            <img *ngIf="question.type ==='image'" [src]="question.photo" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"
            (click)="showImagePopup(question.photo)"/>
          </div>
          <div *ngIf="edit">
            <input
              *ngIf="question.type === 'text'"
              id="{{question.question_id}}"
              name="{{question.question_id}}"
              class="forminputwhitebg"
              type="text"
              [(ngModel)]="question.answer_text"
              [required]="question.mandatory"
              (ngModelChange)="textAnswerChange($event)"
              style="display: block;  font-size: 14px; margin-top: 5px;width: 100%;"
            >
            <input
              *ngIf="question.type === 'number'"
              id="{{question.question_id}}"
              name="{{question.question_id}}"
              class="forminputwhitebg"
              type="number"
              [(ngModel)]="question.answer_text"
              [required]="question.mandatory"
              (ngModelChange)="textAnswerChange($event)"
              style="display: block;  font-size: 14px; margin-top: 5px;width: 100%;"
            >
            <div *ngIf="question.type === 'date'" class="date-picker-form">
              <input id="{{question.question_id}}"
                     [selectMode]="'single'"
                     [readonly]="true" [(ngModel)]="question.answer_text"
                     [owlDateTimeTrigger]="dt"
                     [owlDateTime]="dt"
                     placeholder="Date"
                     name="{{question.question_id}}"
                     [required]="question.mandatory"
                     style="margin-top: 5px;width: 100%;">
              <span class="datepicker-icon"><i class="far fa-calendar-alt"></i></span>
              <owl-date-time (afterPickerClosed)="setDate(dt, question)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
            <div *ngIf="question.type === 'image'" style="cursor: pointer;">
              <div (click)="fileUp.click()" style=" display: inline-flex;align-items: center;">
                <input
                  id="{{question.question_id}}"
                  name="{{question.question_id}}"
                  type="file"
                  #fileUp
                  [required]="question.mandatory"
                  style="display:none"
                  accept="image/png, image/jpeg"
                  (change)="handleFileSelect($event, question)"/>
                <img *ngIf="!question.photo" src="assets/upload_picture.svg" width="40px"/>
                <span *ngIf="!question.photo" style=" display: inline-flex;align-items: center;margin-left: 10px;">Upload Picture</span>
                <img *ngIf="question.photo" [src]="question.photo"
                     style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
              </div>
            </div>
            <div *ngIf="question.type === 'single choice'">
              <mat-radio-group id="{{question.question_id}}" name="{{question.question_id}}" aria-label="Select an option"  [required]="question.mandatory"
                               [(ngModel)]="question.answer_id" (change)="radioChange($event)">
                <ng-container *ngFor="let answer of question.ans;">
                  <mat-radio-button style="display: block; margin-top: 5px;" value="{{answer.id}}">{{answer.name}}</mat-radio-button>
                </ng-container>
              </mat-radio-group>
            </div>
            <div *ngIf="question.type === 'yes/no'">
              <mat-radio-group id="{{question.question_id}}" name="{{question.question_id}}" aria-label="Select an option"  [required]="question.mandatory"
                               [(ngModel)]="question.answer_text" (change)="radioChange($event)">
                <mat-radio-button style="margin-top: 5px;" value="yes">Yes</mat-radio-button>
                <mat-radio-button style="margin-left: 5px;" value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <!--<span *ngIf="question.mandatory" class="text-danger">Mandatory Field</span>-->
        </ng-container>
      </form>
    </div>
    <div class="row centeralign" style="justify-content: space-between;  margin: 15px 30px 15px 0px;">
      <div class="centeralign" style="margin-right: auto">
        <div class="button-row" style="margin-left: 15px;">
          <span [ngClass]="{'progressTextComplete': progress === 100,'progressText': progress < 100}" style="">{{completedQuestions}}</span>
          <span style="font-size: 14px;">/{{surveyQuestions.length}}</span>
          <span [ngClass]="{'progressTextComplete': progress === 100,'progressText': progress < 100}"
                style="float: right;">{{progress}}%</span>
          <div [ngClass]="{
            'warn-bar': progress > 0,
            'ok-bar': progress === 100
          }">
            <mat-progress-bar style="height: 8px;border-radius: 10px;" class="progressindicator" [mode]="'determinate'"
                              [value]="progress"></mat-progress-bar>
          </div>
        </div>
      </div>
      <div *ngIf="edit">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn confirm-blue-btn"
          form="ngForm"
          [disabled]="surveyform.form.invalid"
          (click)="submitSurvey()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</section>
