<div class="common-wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="common-container">
    <div class="main-wrapper">
      <div style="padding: 10px;">
        <div class="cardbox" style="width: 100%">
          <div class="row">
            <div style="width: 100%;">
              <h3>{{serviceDetails.serviceno}}</h3>
              <div class="row centeralign">
                <span class="detaillabel1 mr-2">Asset Type</span>
                <span class="detailvalue1">{{serviceDetails['asset_master']}}</span>

                <span class="greyindicator" style="margin-left: 30px;"></span>
                <span class="detaillabel1 mr-2">Ticket Number</span>
                <span class="detailvalue1">{{serviceDetails['asset_code']}}</span>
              </div>
              <div class="mt-5" style="display: flex">
                <div style="flex: 1">
                  <label class="detaillabel">Requested By</label>
                  <p class="detailvalue1">{{serviceDetails['service_engineer']}}</p>
                  <p class="detailvaluedesc">Requested Date : {{serviceDetails['requested_date']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Brand</label>
                  <p class="detailvalue1">{{brand}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">PO Number</label>
                  <p class="detailvalue1">{{serviceDetails?.config_json?.po_no}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">PO Date</label>
                  <p class="detailvalue1">{{serviceDetails?.config_json?.po_date}}</p>
                  <p class="detailvaluedesc">PO amount : {{serviceDetails?.config_json?.po_amount}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Outlet</label>
                  <p class="detailvalue1">{{serviceDetails['customer_name']}}</p>
                  <p class="detailvaluedesc">Code : {{serviceDetails['customer_code']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Outlet Type</label>
                  <p class="detailvalue1">{{serviceDetails['customer_type']}}</p>
                </div>
              </div>
            </div>

            <div style="position: absolute;right: 30px;">
              <div class="button-row centeralign">
                <div style="display: inline-block; margin-right: 20px;">
                  <span class="detaillabel" style="display: block">Estimated Budget</span>
                  <span class="detailvalue1" style="font-size: 18px;float: right;">{{serviceDetails['budget']}}</span>
                </div>
                <div style="display: inline-block; margin-right: 20px;">
                  <span (click)="fetchPreviousData()"><i
                    [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                    class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 14px;">{{rowIndex + 1}}
                    /{{asset_ids.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === asset_ids.length-1,
                     'enabledspan': rowIndex < asset_ids.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
                <div *ngIf="serviceDetails['service_status'] !== 'REJECTED'" style="display: inline-block;"
                     class="btn light-blue-btn">
                  <span
                    style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                  style="color: #2D2D2D">{{serviceDetails['service_status']}}</span>
                </div>
                <!-- <div *ngIf="serviceDetails['service_status'] === 'REJECTED'" style="display: inline-block;background-color:#FBE3E7;" class="btn light-blue-btn">
                   <span
                     style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span
                   style="color: #E24260">{{serviceDetails['service_status']}}</span>
                 </div>-->
              </div>


            </div>
          </div>
        </div>
        <div *ngIf="serviceDetails['service_status'] === 'REJECTED'" class="mt-4 centeralign"
             style="border: 1px solid #E24260; border-radius: 5px;padding: 10px;background: #FBE3E7;">
          <div class="row centeralign">
           <span
             style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span
            style="color: #E24260">{{serviceDetails['service_status']}}</span>
            <span
              style="position: absolute;right: 30px;color:#5D5D5D; font-size: 14px; font-weight: 400;">{{serviceDetails['reject_comments']}}</span>
          </div>
        </div>
        <div *ngIf="dataLoaded" class="cardbox mt-4" style="width: 100%">
          <div class="row">
            <div class="col-md-12 pad0">
              <div class="col-md-2 pl-0 pr-10" style="border-right: 1px solid #D7DDE6;">
                <ol class="steps" style="height: 500px;">
                  <ng-container *ngFor="let item of stageConfig; let i = index">
                    <li id="{{item.id}}" class="todo"><span>{{item.stage}}</span></li>
                  </ng-container>
                </ol>
                <div *ngIf="showMarkComplete">
                  <button
                    class="btnsmall confirm-blue-btn"
                    style="width: 100%; margin-top: 15px;padding: 10px 30px;"
                    (click)="openConfirmDialog()"
                  >
                    Mark Complete
                  </button>
                </div>
              </div>
              <div class="col-md-10 pl-10 pr-10">
                <ng-container *ngFor="let item of stageConfig; let i = index">
                  <div *ngIf="item.completed || item.active" class="mt-4">
                    <div class="row centeralign" style="justify-content: space-between">
                      <div class="centeralign" style="margin-right: auto">
                        <p class="detailvaluebold">{{item.stage}}</p>
                        <img *ngIf="item.completed" style="margin-left: 5px;height: 12px;"
                             src="./../../../assets/tickgreen.svg"/>
                      </div>
                      <div *ngIf="item.completed">
                        <p class="detaillabel1" style="display: inline;">Completed On: </p>
                        <p *ngIf="item.surveyDetails" class="detailvalue1"
                           style="display: inline;">{{item?.surveyDetails?.completion_date}}</p>
                        <p *ngIf="item.review_details" class="detailvalue1"
                           style="display: inline;">{{item?.review_details?.date}}</p>
                      </div>
                    </div>
                    <div *ngIf="item.completed" class="mt-2"
                         style="border: 1px solid #D7DDE6; border-radius: 10px;padding: 20px;">
                      <div *ngIf="item.surveyDetails" class="row centeralign">
                        <span class="detaillabel1 mr-2">Survey Date</span>
                        <span class="detailvalue1">{{item?.surveyDetails?.completion_date}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Survey Status</span>
                        <span class="detailvalue1">{{item?.surveyDetails?.status}}</span>

                        <span class="detailvaluedesc" style="margin-left: 30px; cursor: pointer;"
                              (click)="openTakeSurveyPopup(item['survey_id'], false)">View Survey</span>
                      </div>
                      <div *ngIf="item.review_details" class="row centeralign">
                        <span class="detaillabel1 mr-2">Date</span>
                        <span class="detailvalue1">{{item?.review_details?.date}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Status</span>
                        <span class="detailvalue1">{{item?.review_details?.status}}</span>
                      </div>
                    </div>
                    <div *ngIf="!item.completed && item.show_survey && !item.mobile_app" class="mt-3">
                      <span class="detaillabel1" style="font-size: 12px;">Click ‘Take Survey’ to take {{item.stage}}
                        survey.</span>
                      <div class="mt-2">
                        <button class="btn confirm-blue-btn" style="margin:5px;"
                                (click)="openTakeSurveyPopup(item['survey_id'], true)">Take Survey
                        </button>
                      </div>
                    </div>
                    <div *ngIf="!item.completed && item.show_approve && item.survey_id" class="row mt-3">
                      <span class="detailvaluedesc" style="cursor: pointer;"
                            (click)="openTakeSurveyPopup(item['survey_id'], false)">View Survey</span>
                    </div>
                    <div *ngIf="!item.completed && item.show_approve && !item.mobile_app" class="row mt-3">
                      <button class="btn confirm-green-btn" style="margin:5px;" (click)="openApproveDialog(item)">
                        Approve
                      </button>
                      <button class="btn reject-btn" style="margin:5px;" (click)="openRejectPopup(item)">Reject</button>
                    </div>
                    <div *ngIf="!item.completed && item.mobile_app && serviceDetails['service_status'] !== 'REJECTED'"
                         class="mt-2 centeralign" style="border-radius: 5px;padding: 10px;background: #DAE8F3;">
                      <div class="row centeralign">
                        <span style="color: #0762AD; font-size: 12px; font-weight: 400;">Mobile step in progress... Awaiting confirmation!</span>
                      </div>
                    </div>
                    <div *ngIf="!item.completed && !item.show_approve && !item.show_survey && item.waiting_for_approval"
                         class="mt-2 centeralign" style="border-radius: 5px;padding: 10px;background: #DAE8F3;">
                      <div class="row centeralign">
                        <span style="color: #0762AD; font-size: 12px; font-weight: 400;">{{item.waiting_text}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
