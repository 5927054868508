import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Output() selectedAction: EventEmitter<any> = new EventEmitter<any>();
  title = "";
  message1 = "";
  message2 = "";
  showNoButton = true;
  yesButton = "";
  noButton = "";
  deleteButton = false;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.title = dialogdata.title;
    this.message1 = dialogdata.message1;
    this.message2 = dialogdata.message2;
    this.showNoButton = dialogdata.showNoButton;
    this.yesButton = dialogdata.yesButton;
    this.noButton = dialogdata.noButton;
    if (dialogdata.hasOwnProperty('deleteButton')) {
      this.deleteButton = dialogdata.deleteButton;
    } else {
      this.deleteButton = false;
    }

  }

  ngOnInit(): void {

  }

  performAction(action) {
    this.selectedAction.emit(action);
    this.dialog.closeAll();
  }
}
