<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{expenseData['Date'] | date : "dd MMM yyyy"}}</h2>
      <div class="row" style="padding-top: 10px;">
        <span style="font-size: 16px;">Total Claim amount</span><span style="color: #0762AD; font-weight: 600; padding-left: 10px; font-size: 16px;">{{totalClaimAmt}}</span>
      </div>
    </div>
    <div class="body-section">
      <div class="mat-tab">
        <mat-tab-group (selectedTabChange)="tabClick($event)" [(selectedIndex)]="tabIndex">
          <mat-tab >
            <ng-template mat-tab-label>
              <span style="font-size: 13px;">Claim</span>
            </ng-template>
            <mat-accordion id="myMat">
              <div *ngFor="let expense of expenseDetailsData">
                <mat-expansion-panel hideToggle (opened)="openGroup($event,expense)" (closed)="closeGroup($event,expense)">
                  <mat-expansion-panel-header>
                    <mat-panel-description>
                      <div class="row" style="width: 100%">
                        <div class="col-sm-4 col-xs-6" style="margin-top: 10px;">
                          <img *ngIf="expense.photo" class="img-responsive" [src]="expense.photo" style="
                                     height: 150px;
                                     background-color: #F5F7F9"/>
                          <img *ngIf="!expense.photo" class="img-responsive" src="assets/no-image-icon.png" style="
                                      height: 150px;display: block; margin: 0 auto;"/>
                        </div>
                        <div class="col-sm-8 col-xs-6"  style="margin-top: 10px;">
                          <div >
                            <label style="font-size: 16px;color:#2D2D2D;font-weight: 400;">{{to_upperCase(expense.exp_type)}}</label>

                            <span class="ml-1 status2" style="float:right" [ngClass]="{'success-state': expense.state === 'approved',
                           'error-state': expense.state === 'rejected',
                           'new-state': expense.state === 'new',
                           'settle-grey': expense.state === 'settled',
                           'warning-state': expense.state === 'processing'}">{{to_upperCase(expense.state)}}</span>
                          </div>
                          <div>
                            <div  style=" display: block;align-items: center;">
                              <label *ngIf="expense.state === 'rejected'" style="font-size: 28px; font-weight: bold; color:#4D4D4D; text-decoration: line-through red;">{{expense.amount}}</label>
                              <label *ngIf="expense.state != 'rejected'" style="font-size: 28px; font-weight: bold; color:#0762AD;">{{expense.amount}}</label>
                              <img *ngIf="expense.state === 'approved' || expense.state === 'settled'" style="height: 20px; margin-left: 5px; margin-bottom: 10px;" src="assets/green_checked.svg"/>
                            </div>
                          </div>
                          <div class="row" style="width: 100%; display: inline-block; margin-top: 20px;">

                            <label style="font-size: 14px;color:#7D7D7D">Approved Amount </label>
                            <label style="font-size: 14px;margin-left: 5px;"
                                   [ngClass]="{'color-green': expense.amount === expense.app_amount,
                                    'color-red': expense.amount > expense.app_amount}">
                              {{expense.app_amount}}</label>

                            <label style="font-size: 14px;color:#7D7D7D; margin-left: 15px;margin-right: 5px;">Max </label>
                            <label style="font-size: 14px;color:#2D2D2D">{{expense.max_claim_amount}}</label>

                            <label style="font-size: 14px;color:#7D7D7D; margin-left: 15px; margin-right: 5px;">Type </label>
                            <label *ngIf="expense.exp_type == 'travel'" style="font-size: 14px;color:#2D2D2D">{{display_name(expense.travel_type)}}</label>
                            <label *ngIf="expense.exp_type == 'stay'" style="font-size: 14px;color:#2D2D2D">{{display_name(expense.stay_type)}}</label>
                            <label *ngIf="expense.exp_type == 'allowance'" style="font-size: 14px;color:#2D2D2D">{{display_name(expense.allowance_type)}}</label>
                            <label *ngIf="expense.exp_type == 'others' || expense.exp_type == 'food'" style="font-size: 14px;color:#4D4D4D">{{display_name(expense.exp_type)}}</label>



                          </div>
                          <div  *ngIf="dialogType!='admin' && (expense.state == 'new')" style="margin-top: 10px;">
                            <a
                              (click)="cancelSales(expense)" style="color: red !important;
                      border: 1px solid red;padding: 6px 15px;" class="btn white-btn">Cancel</a>
                          </div>
                          <div class="row" style="width: 100%; display: inline-block; margin-top: 15px;">
                            <label *ngIf="!expense.expansionText" style="font-size: 12px;color:#0762AD;float: left">Show More ..</label>
                            <div  *ngIf="dialogType=='admin'" style="float: right">
                              <a  *ngIf=" expense.state == 'new' || (expense.state === 'processing' && apprtype==='appr1' && expense.app1_state!='rejected') || (expense.state === 'processing' && apprtype==='appr2') ||(expense.state === 'approved' && apprtype==='appr2') "
                                  (click)="rejectPopup(expense)" style="font-size: 12px;padding: 6px 24px;text-transform: capitalize !important;" class="btn red-btn">Reject</a>
                              <a  *ngIf="expense.state == 'new' || (expense.state === 'processing' && apprtype==='appr2') "
                                  (click)="approvePopup(expense)" style="font-size: 12px;padding: 6px 20px;margin-left: 10px; text-transform: capitalize !important;" class="btn confirm-green-btn">Approve</a>
                            </div>
                          </div>

                          <label style="font-size: 12px;color:#0762AD;margin-top: 10px;">{{expense.expansionText}} ..</label>
                        </div>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div style="display: inline-block;">
                      <h1 style="font-size: 160%;">{{expense.name}}</h1>
                    </div>
                  </div>

                  <div class="col-md-6 pl-0 pt-5" style="margin-top:3%;">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">SalesPerson Details</label>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Salesperson: </label><span style="margin-left: 3%">{{expense.salesman}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Employee Code: </label><span style="margin-left: 3%">{{expense.emp_code}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Team: </label><span style="margin-left: 3%">{{expense.team}}</span>
                    </div>
                  </div>
                  <div class="col-md-6 pl-0 pt-5">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Approver Details</label>
                    <div style="margin-top:3%;">
                      <div>
                        <label style="font-weight: 600;">Approver 1: </label><span style="margin-left: 3%">{{expense.appr1}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Approver1 Status: </label><span style="margin-left: 3%">{{expense.app1_state}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Approver 2: </label><span style="margin-left: 3%">{{expense.appr2}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Approver2 Status: </label><span style="margin-left: 3%">{{expense.app2_state}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Approved Amount: </label><span style="margin-left: 3%">{{expense.app_amount}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Approver Comments: </label><span style="margin-left: 3%">{{expense.reason}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 pl-0 pt-5" style="margin-top:4%;">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Expense Details</label>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Expense Date: </label><span style="margin-left: 3%">{{expense.expense_date}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Submitted Date: </label><span style="margin-left: 3%">{{expense.submitted_date}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Expense Policy: </label><span style="margin-left: 3%">{{expense.policy_name}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Expense Policy Line: </label><span style="margin-left: 3%">{{expense.name}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Expense Type: </label><span style="margin-left: 3%">{{expense.exp_type}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Policy Claim Amount: </label><span style="margin-left: 3%">{{expense.policy_claim_amount}}</span>
                    </div>
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Comments: </label><span style="margin-left: 3%">{{expense.note}}</span>
                    </div>
                  </div>
                  <div *ngIf="expense.exp_type == 'travel' || expense.exp_type == 'stay'" class="col-md-6 pl-0 pt-5" style="margin-top:4%;">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Travel/Stay</label>
                    <div style="margin-top:3%;">
                      <div>
                        <label style="font-weight: 600;">Travel Type: </label><span style="margin-left: 3%">{{expense.travel_type}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Zone: </label><span style="margin-left: 3%">{{expense.zone}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Area: </label><span>{{expense.area}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Destination Zone: </label><span>{{expense.end_zone}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Destination Area: </label><span style="margin-left: 3%">{{expense.end_area}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Distance(KM): </label><span style="margin-left: 3%">{{expense.distance}}</span>
                      </div>

                    </div>
                  </div>
                  <div *ngIf="expense.exp_type == 'allowance'"  class="col-md-6 pl-0 pt-5" style="margin-top:4%;">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Allowance</label>
                    <div style="margin-top:3%;">
                      <div>
                        <label style="font-weight: 600;">Allowance Type: </label><span style="margin-left: 3%">{{expense.allowance_type}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="expense.exp_type == 'food'" class="col-md-6 pl-0 pt-5" style="margin-top:4%;">
                    <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Food</label>
                    <div style="margin-top:3%;">
                      <div>
                        <label style="font-weight: 600;">Bill Uploaded: </label><span>{{expense.bill_uploaded}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="expense.exp_type == 'travel'" class="col-md-12 pl-0 pt-5" style="margin-top: 20px; display: block;">
                    <div style="margin-top:3%;">
                      <label style="font-weight: 600;">Travel Path: </label><span style="margin-left: 3%">{{expense.travelpath}}</span>
                    </div>
                  </div>
                  <div *ngIf="expense.has_gst" class="col-md-12 pl-0 pt-5" style="margin-top: 20px; display: block;">
                    <div >
                      <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">GST Details</label>
                    </div>
                    <div style="margin-top:3%;">
                      <div>
                        <label style="font-weight: 600;">Invoice No: </label><span style="margin-left: 3%">{{expense.inv_no}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Invoice Date: </label><span style="margin-left: 3%">{{expense.inv_date}}</span>
                      </div>
                      <div style="margin-top:3%;">
                        <label style="font-weight: 600;">Company GSTN: </label><span style="margin-left: 3%">{{expense.company_tax_id}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12 pl-0 pt-5" style="margin-top: 20px; display: block;">
                    <div >
                      <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Bills</label>
                    </div>
                    <div style="margin-top: 10px;">
                      <div style="display: inline;" *ngFor="let data of expense['gallery']">
                        <img *ngIf="!data.type || data.type !== 'pdf'" (click)="showImagePopup(data.photo)" style="width: 20%;" [src]="data.photo"/>
                        <img *ngIf="data.type === 'pdf'" (click)="openPDFinNewTab(data.photo)" style="width: 20%;" src="./../../../assets/pdf_icon.png" />
                        <!--  <a href="photo" data-gallery="multiimages" data-toggle="lightbox">
                            <img style="width: 20%;" [src]=" photo " />
                          </a>-->
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <span style="font-size: 13px;">Invoices</span>
            </ng-template>
            <div class="eod-table" style="height: 100%;width:100%">
              <div style="height: 100%;width:100%">
                <ag-grid-angular id="myGrid1" *ngIf="loaded2" style="width: 100%;height:50vh;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"  (gridReady)="onGridReady2($event)">
                </ag-grid-angular>
                <div *ngIf="preloader1">
                  <app-loadingstate></app-loadingstate>
                </div>
                <div *ngIf="empty_state1">
                  <app-emptystate></app-emptystate>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div #galleryModal class="pol-modal pol-send invoice-img" id="galleryModal" [hidden]="isModelOpened">
    <div class="modal-dialog" style="z-index: 10000000000;">
      <!-- Modal content-->
      <div class="modal-content" style="width:70%;margin-left: 10%;margin-top: -40%;">
        <span class="close" (click)="close()">
          <img src="../../assets/close.png">
        </span>
        <div class="modal-body">
          <img style="width: 100%;" [src]="photo">
        </div>
        <!--      <div class="modal-body">-->
        <!--        <img style="width: 100%;" [src]="photo2">-->
        <!--      </div>-->
      </div>
    </div>
  </div>

  <div #partialapproveModel class="approve-modal modal" id="partialapproveModel" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
        <div class="modal-body">
          <div class="approve-modal-heading">
            <label>Approve Expense</label>
          </div>
          <div class="approve-modal-content">
            <div class="user-input-wrp" style="margin-top: 10px;">
              <br/>
              <input
                type="text"
                class="inputText"
                [(ngModel)]="exp.app_amount"
                (input)="onAmountChange()"
                required/>
              <span class="floating-label">Approved Amount</span>
            </div>
            <span *ngIf="amountExceeded" class="text-danger">Amount exceeds Claim amount</span>
            <div style="margin-top: 20px;">
              <button [disabled]="amountExceeded" (click)="approveSales(exp)" data-dismiss="modal" class="btn blue-btn">Yes</button>
              <button data-dismiss="modal" class="btn white-btn" style="margin-left: 10px;">No</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div #rejectCommentModal class="approve-modal modal" id="rejectCommentModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
        <div class="modal-body">
          <div class="approve-modal-heading">
            <label>Comments</label>
          </div>
          <div class="approve-modal-content">
            <!-- <div class="user-input-wrp" style="margin-top: 10px;">
               <br/>
               <input
                 type="text"
                 class="inputText"
                 [(ngModel)]="exp.note"
                 required/>
               <span class="floating-label">Enter Comments</span>
             </div>-->
            <select name="comments"
                    id="comments"
                    [(ngModel)]="exp.reason_id"
                    #comments="ngModel">
              <option
                *ngFor="let item of beatActivityData"
                value="{{ item.id }}"
              > {{ item.name }}</option>
            </select>
            <div >
              <button (click)="rejectSales(exp)" data-dismiss="modal" class="btn blue-btn">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>















