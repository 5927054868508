import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-outlet-stock-ageing-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class OutletStockAgeingReportComponent extends ReportBase implements IReport, OnInit {


  showCustomFilter = true;
  customFilter: any = {
    name: 'Partner Type',
    key: 'partner_type',
    value: '',
    values: [],
    show: true
  };
  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Stock Ageing Report');

    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    // this.showableFilters.cluster = true;
    // this.showableFilters.region = true;


    this.showProgress = true;
    this.showProgressCustomText = true;

    /*   this.userData.offsetID = 0;
       this.userData.offset = 5000;*/
    this.loadInBatches(5000, 'id');
    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "supplier", itemName: "Supplier"},
      {id: "retailer", itemName: "Retailer"}
    ];

    this.API_URL = '/api/pwa_v2_reports/getOutletStockAgeing';
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'AUDIT ID', field: 'audit_id', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'AUDIT NAME', field: 'audit_name', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'SESSION ID', field: 'session_id', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'LINE ID', field: 'id', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'REGION', field: 'region', width: 80, enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80, enableRowGroup: true},
      {headerName: 'TEAM', field: 'team', width: 80, enableRowGroup: true},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'CHAIN CODE', field: 'p_code', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'CHAIN NAME', field: 'p_name', resizable: true, enableRowGroup: true, width: 80, hide: true},
      {headerName: 'OUTLET ID', field: 'retailer_id', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'OUTLET CODE', field: 'o_code', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'OUTLET', field: 'o_name', enableRowGroup: true, width: 120},
      {headerName: 'STREET', field: 'street', enableRowGroup: true, width: 120, hide: true},
      {headerName: 'CITY', field: 'city', enableRowGroup: true, width: 120},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 120, hide: true},
      {headerName: 'ZIP', field: 'zip', enableRowGroup: true, width: 120, hide: true},
      {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'PROGRAM', field: 'program', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'CLASS', field: 'class', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'DATE', field: 'date', enableRowGroup: true, width: 80},
      {headerName: 'CATEGORY', field: 'category', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'BRAND', field: 'brand', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'FAMILY', field: 'family', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'CODE', field: 'prd_code', width: 80, enableRowGroup: true},
      {headerName: 'PRODUCT', field: 'product', width: 120, enableRowGroup: true},
      {headerName: 'SCORE', field: 'score', width: 120, enableRowGroup: true, hide: true},
      {headerName: 'EXPIRY DAYS', field: 'expiry_days', width: 120, hide: true},
      {headerName: 'MBQ', field: 'mbq', width: 50, cellStyle: {textAlign: 'left'},
        valueGetter(params) {
          if (params.data) {
            if (params.data.mbq === 0) {
              return "NA";
            } else {
              return params.data.mbq;
            }
          }
        }},
      {headerName: 'LOW STOCK', field: 'low_stock', width: 50, cellStyle: {textAlign: 'left'}},
      {headerName: 'REPLENISH', field: 'replenish', width: 50, cellStyle: {textAlign: 'left'}},
      {
        headerName: 'Availability %', field: 'avail', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: this.avgAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.avail === 100) {
              return 100;
            } else {
              return "Out of Stock";
            }
          }
        }
      },
      {headerName: 'FACING', field: 'f_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: this.avgAggFunction},
      {headerName: 'OPENING', field: 'o_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'INWARD', field: 'i_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {
        headerName: 'OFFTAKE', field: 'o_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.o_qty && params.data.c_qty) {
              const opening_stock = params.data.o_qty + params.data.i_qty;
              if (opening_stock > params.data.c_qty) {
                return opening_stock - params.data.c_qty;
              } else {
                return 0;
              }

            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'CLOSING', field: 'c_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'EXPIRED', field: 'already_expired', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'WITHIN 15 DAYS', field: 'below_15_days', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: '15 - 30 DAYS', field: 'days_15_30', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: '31 - 60 DAYS', field: 'days_31_60', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'AFTER 60 DAYS', field: 'above_60_days', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'}
    ];
  }

  configureData() {
    this.rowData = [];
    this.apiData.forEach(obj => {
      if (!obj.expiry_days) {
        obj.expiry_days = 0;
      }
      obj.already_expired = 0;
      obj.below_15_days = 0;
      obj.days_15_30 = 0;
      obj.days_31_60 = 0;
      obj.above_60_days = 0;
      if (obj.batch_json && obj.batch_json.length > 0) {

        for (let i = 0; i < obj.batch_json.length; i++) {
          const batch_obj = obj.batch_json[i];
          // console.log(batch_obj);
          const batch_expiry_date = new Date(batch_obj.batch_date);
          batch_expiry_date.setDate(batch_expiry_date.getDate() + obj.expiry_days);
          console.log(batch_expiry_date);
          console.log(obj.expiry_days);
          const days_to_expiry = Math.round((batch_expiry_date.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
          // console.log(days_to_expiry);
          if (days_to_expiry <= 0) {
            obj.already_expired += batch_obj.qty;
          } else if (days_to_expiry > 0 && days_to_expiry <= 15) {
            obj.below_15_days += batch_obj.qty;
          } else if (days_to_expiry > 15 && days_to_expiry <= 30) {
            obj.days_15_30 += batch_obj.qty;
          } else if (days_to_expiry > 30 && days_to_expiry <= 60) {
            obj.days_31_60 += batch_obj.qty;
          } else if (days_to_expiry > 60) {
            obj.above_60_days += batch_obj.qty;
          }
        }
      }
      this.rowData.push(obj);
    });
  }


  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }


}
