<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" >
      <div id="overlay"></div>
      <div  class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                  <div class="row">
                    <div  *ngIf="!editMode" class="button-row">
                      <button
                        class="btn grey-button"
                        (click)="view_invoices()"
                      >VIEW INVOICES
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['state'] === 'sale' && activeObject['d_status'] !== 'delivered'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="showUpdateStatusPopup()"
                      >UPDATE STATUS
                      </button>
                    </div>

                    <div *ngIf="!editMode && feature_multi_level_order_approve && show_approve_button" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="openApproveRejectPopup('approve')"
                      >APPROVE ORDER
                      </button>
                    </div>

                    <div  *ngIf="!editMode && activeObject['state'] === 'cancel'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="action_reorder()"
                      >RE ORDER
                      </button>
                    </div>
<!--                    <div  *ngIf="!editMode" class="button-row">-->
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                      >CONFIRM ORDER-->
<!--                      </button>-->
<!--                    </div>-->
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="discard()"
                      >
                        DISCARD
                      </button>
                    </div>
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="saveCart()"
                      >
                        SAVE
                      </button>
                    </div>
                    <div style="position: absolute;right: 30px;">
                      <div class="button-row" style="display: flex;align-items: center">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">
              <div class="col-md-9 pt-3 pad0">
                <div class="col-md-12 pt-3 pad0">

                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">CUSTOMER</label>
                    <div  style="margin-top: 5px;" class="formselect">
                      <div style="width: 90%">
                        <ng-select *ngIf="newMode"
                                   [items]="customerList"
                                   bindLabel="name"
                                   bindValue="id"
                                   (ngModelChange)="customerChangeEvent($event)"
                                   [(ngModel)]="activeObject['partner_id']">
                          >
                        </ng-select>
                      </div>
                      <p class="lbl3" *ngIf="!newMode">{{activeObject['customer_name']}}</p>
                    </div>
                    <div style="display: inline" *ngIf="!newMode">
                      <p style="display: inline" *ngIf="activeObject['customer_code']" class="lbl2">Code : {{activeObject['customer_code']}}</p>
                      <p style="display: inline" *ngIf="activeObject['customer_gst_id']" class="lbl2 ml-3">Tax Id : {{activeObject['customer_gst_id']}}</p>
                    </div>
                    <div>
                      <p *ngIf="activeObject['pricelist_name']" class="lbl2">Pricelist : {{activeObject['pricelist_name']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BILL TO/SUPPLIER</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <select
                        *ngIf="newMode"
                        name="suppname"
                        [(ngModel)]="activeObject['supplier_id']"
                        (ngModelChange)="supplierChangeEvent($event)"
                        #suppname="ngModel"
                        style="width: 90%;"
                      >
                        <option
                          *ngFor="let item of supplierList"
                          value="{{ item.id }}"
                        >
                          {{ item.name }}
                      </select>
                      <div *ngIf="!newMode">
                        <div>
                          <p class="lbl3" *ngIf="activeObject['supplier_name']">{{activeObject['supplier_name']}}</p>
                          <p *ngIf="activeObject['supplier_gst_id']" class="lbl2">Tax Id : {{activeObject['supplier_gst_id']}}</p>
                          <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{activeObject['supplier_code']}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">SALESMAN</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="userList && userList.length > 0 && editMode"
                                 [items]="userList"
                                 bindLabel="itemname"
                                 bindValue="id"
                                 (ngModelChange)="salesmanChange($event)"
                                 [(ngModel)]="activeObject['user_id']">
                      >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['salesman'] && !editMode">{{activeObject['salesman']}}</p>
                        <p *ngIf="activeObject['emp_code']" class="lbl2">Code : {{activeObject['emp_code']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BEAT</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <p class="lbl3">{{activeObject['beat_name']}}</p>
                    </div>
                  </div>

                </div>
                <div class="col-md-12 pt-2 pad0" style="margin-top: 12px;">
                  <div class="col-md-3 pl-0 pr-30">
<!--                    <label class="lbl">SHIP TO</label>-->
<!--                    <div *ngIf="shipto_adrr_list" class="formselect">-->
<!--                      <select-->
<!--                        *ngIf="shipto_adrr_list.length > 0 && editMode"-->
<!--                        name="suppaddrss"-->
<!--                        [(ngModel)]="activeObject['shipping_partner_id']"-->
<!--                        (ngModelChange)="shipaddrssChange($event)"-->
<!--                        #suppaddrss="ngModel"-->
<!--                        class="address-sel"-->
<!--                      >-->
<!--                        <option-->
<!--                          *ngFor="let item of shipto_adrr_list"-->
<!--                          [ngValue]="item"-->
<!--                        >-->
<!--                          {{ item.itemname }}-->

<!--                      </select>-->
<!--                      <div *ngIf="activeObject.shipto_address && (!editMode) ">-->
<!--                        <p class="lbl3" *ngIf="activeObject.shipto_address">{{activeObject.shipto_address}}</p>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
<!--                    <label class="lbl">BILL TO</label>-->
<!--                    <div class="formselect"> &lt;!&ndash; *ngIf="billto_adrr_list" &ndash;&gt;-->
<!--                      &lt;!&ndash;                      <select&ndash;&gt;-->
<!--                      &lt;!&ndash;                        *ngIf="billto_adrr_list.length > 1 && editMode"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        name="custaddrss"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        [(ngModel)]="selected_billto_addr_id"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        (ngModelChange)="billaddrssChange($event)"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        #custaddrss="ngModel"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        class="address-sel"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      >&ndash;&gt;-->
<!--                      &lt;!&ndash;                        <option&ndash;&gt;-->
<!--                      &lt;!&ndash;                          *ngFor="let item of billto_adrr_list"&ndash;&gt;-->
<!--                      &lt;!&ndash;                          [ngValue]="item"&ndash;&gt;-->
<!--                      &lt;!&ndash;                        >&ndash;&gt;-->
<!--                      &lt;!&ndash;                          {{ item.itemName }}&ndash;&gt;-->

<!--                      &lt;!&ndash;                      </select>&ndash;&gt;-->
<!--                      <div *ngIf="activeObject">-->
<!--                        <p class="lbl3" *ngIf="activeObject.billto_address">{{activeObject.billto_address}}</p>-->
<!--                        &lt;!&ndash;                        <p *ngIf="customer_billto_addr_code" class="lbl2">Code : {{customer_billto_addr_code}}</p>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>

                  <div class="col-md-3 pl-0 pr-30"  *ngIf="showPlantField">
                    <label class="lbl">PLANT</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="plantList && plantList.length > 0 && editMode"
                                 [items]="plantList"
                                 bindLabel="name"
                                 bindValue="code"
                                 (ngModelChange)="plantChangeEvent($event)"
                                 [(ngModel)]="this.activeObject['config_json']['plant']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="this.activeObject['config_json'] && !editMode">{{this.activeObject['config_json']['plant_name']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30"  *ngIf="showIncoTerms">
                    <label class="lbl">INCO TERMS</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="inco_terms_list && inco_terms_list.length > 0 && editMode"
                                 [items]="inco_terms_list"
                                 bindLabel="code"
                                 bindValue="code"
                                 [(ngModel)]="this.activeObject['config_json']['inco_term']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="this.activeObject['config_json'] && !editMode">{{this.activeObject['config_json']['inco_term']}}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-3 pt-3 pad0">
                <div class="col-md-12 pl-0 pr-0" *ngIf="newMode">
                  <label class="lbl">DATE</label>
                  <div>
                    <div>
                      <input
                        class="forminput"
                        type="text"
                        name="date"
                        style="width:60%"
                        [disabled]="dateDisable"
                        [readonly]="true"
                        [(ngModel)]="activeObject['order_date']"
                        #date="ngModel"
                        [owlDateTime]="dt"
                        [owlDateTimeTrigger]="dt"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
                    <!--                    <p class="lbl3" [hidden]="sharedService.dmsParams.new_mode || (sharedService.dmsParams.edit_mode && !invEnable &&-->
                    <!--                             dmsStatus === 'confirm')">{{poDate}}</p>-->
                  </div>
                </div>
                <div class="col-md-12 pl-0 pr-0 shadow-box" *ngIf="!newMode && activeObject['id']">
                  <label class="lbl4" style="margin-left: 35%;margin-top:3%;">ORDER INFORMATION</label>
                  <div style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Order ID &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['id']}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;">Order Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['name']}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Order Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black" >{{activeObject['date_order'] + 'Z' | date:'yyyy-MM-dd HH:mm:ss'}}</span></label><br/>
                    <label *ngIf="activeObject['confirmation_date']" class="lbl4" style="margin-left: 4%;margin-top: 3%;">Confirmation Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black" >{{activeObject['confirmation_date'] + 'Z' | date:'yyyy-MM-dd HH:mm:ss'}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Status     &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['state']}}</span></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0" *ngIf="editMode && !reorder_mode">
              <div class="col-md-4 pl-0 pr-30">
                <label class="lbl">PRODUCT</label>
                <div class="formselect">
                  <ng-select
                             #productSelector
                             [items]="prodList"
                             bindLabel="product"
                             bindValue="p_id"
                             [virtualScroll]="true"
                             [(ngModel)]="selectedProduct"
                             (ngModelChange)="productSelectorChange()"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">QTY</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="qty"
                      onlyNumber
                      [(ngModel)]="enteredQty"
                      #qty="ngModel"
                      style="width: 100%;"
                      (keydown.enter)="addProductClick()"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30" *ngIf="UOMList">
                <label class="lbl">UOM</label>
                  <div class="formselect">
                    <ng-select
                      #productSelectorUOM
                      [items]="UOMList"
                      bindLabel="itemName"
                      bindValue="id"
                      (ngModelChange)="productUOMChange()"
                      [(ngModel)]="selectedUOM_id"
                    >
                    </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">STOCK</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="stck"
                      [(ngModel)]="stock"
                      [disabled]="true"
                      style="width: 100%;"
                      #stck="ngModel"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pt-4 pl-0 pr-30">
                <button class="teal-btn2"
                        (click)="addProductClick()"
                >
                  ADD
                </button>
              </div>
            </div>

            <div *ngIf="!editMode" class="col-md-12 pad0 mt-3">
              <div style="border: 1px solid #D7DDE6; border-radius: 5px;padding: 15px;background: #FFFFFF;">
                <div class="row centeralign" style="cursor: pointer" (click)="detailsClick()">
                  <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Approval Status</span>
                  <span [ngClass]="{'success': orderStatus === 'true', 'new': orderStatus !== 'true'}"
                        class="ml-1 status2" style="margin-left: 20px !important;" >{{orderStatus}}</span>
                  <img *ngIf="!showApproverDetails" style="position: absolute; right: 30px;height: 8px;"
                       alt="Expand" src="../../../../assets/images/down_arrow_black.svg"/>
                  <img *ngIf="showApproverDetails" style="position: absolute; right: 30px;height: 8px;"
                       alt="Collapse" src="../../../../assets/images/up_arrow.svg"/>
                </div>
                <div class="mt-3" *ngIf="showApproverDetails">
                  <ng-container *ngFor="let item of approverDetails">
                    <div class="row centeralign mt-3">
                      <span class="detaillabel1 mr-2">Approved By</span>
                      <span class="detailvaluebold">{{item.app_by}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Role</span>
                      <span class="detailvaluebold">{{item.role_name}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Approved On</span>
                      <span class="detailvaluebold">{{item.app_on}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Comments</span>
                      <span class="detailvaluebold">{{item.comments}}</span>

                      <span *ngIf="item.status === true"
                            class="ml-1 status2 success" style="position: absolute; right: 30px;" >Approved</span>
                      <span *ngIf="item.status === false"
                            class="ml-1 status2 warning" style="position: absolute; right: 30px;" >Pending</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 [context]="context"
                                 [isExternalFilterPresent]="isExternalFilterPresent"
                                 [doesExternalFilterPass]="doesExternalFilterPass"
                                 (rowEditingStarted)="onRowEditingStarted($event)"
                                 (rowEditingStopped)="onRowEditingStopped($event)"
                                 (cellEditingStarted)="onCellEditingStarted($event)"
                                 (cellEditingStopped)="onCellEditingStopped($event)"
                >
                </ag-grid-angular>
<!--                <div *ngIf="preloader">-->
<!--                  <app-loadingstate></app-loadingstate>-->
<!--                </div>-->
<!--                <div *ngIf="emptyState">-->
<!--                  <app-emptystate></app-emptystate>-->
<!--                </div>-->
              </div>
            </div>
            <div  class="col-md-4 mt-2" style="margin-top: 20px; !important">
              <div *ngIf="activeObject['activity_json']">
                <label class="lbl">ACTIVITIES</label>
                <div class="history-tl-container">
                  <ul class="tl">
                    <li *ngFor="let activity of activeObject['activity_json']" ng-repeat="item in retailer_history">
                      <div class="item-title">{{activity.s}}</div>
                      <div class="item-detail">{{activity.t + 'Z' | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                      <div class="lbl2">{{activity.m}}</div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div  class="col-md-4 mt-2" style="margin-top: 20px; !important">
              <label class="lbl">INSTRUCTIONS</label>
              <div class="formselect">
                <div *ngIf="newMode">
                    <textarea style="background-color: #F5F5FF" class="form-control"
                              rows="4" id="comment" [(ngModel)]="this.activeObject['note']"></textarea>
                </div>
                <p class="lbl3" *ngIf="!newMode">{{this.activeObject['note']}}</p>
              </div>
            </div>
            <div class="col-md-4 mt-2" *ngIf="activeObject" >
              <table style="float: right;">
                <tbody>
                <tr>
                  <td ><p class="p-class">Taxable Amount</p></td>
                  <td ><p class="p-class1">{{math.abs(activeObject['amount_untaxed']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Taxes</p></td>
                  <td ><p class="p-class1">{{math.abs(activeObject['amount_tax']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                <tr *ngIf="showTotalWeight">
                  <td ><p class="p-class">Total Weight</p></td>
                  <td ><p class="p-class1">{{totalWeightFieldValue}}</p></td>
                </tr>
                <tr *ngIf="showTotalMeasure">
                  <td ><p class="p-class">Total Measure</p></td>
                  <td ><p class="p-class1">{{totalMeasureFieldValue}}</p></td>
                </tr>
                <tr style=" border-top: 2px solid #f3f3f3;">
                  <td ><p class="p-class3">GRAND TOTAL</p></td>
                  <td ><p class="p-class4">{{math.abs(activeObject['amount_total']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


