export const MenuMasterList = [
  {
    name: "Beats",
    feature: "module_beats",
    icon: "beat",
    route: "",
    sub_menu: [
      {
        name: "Beat Plan",
        route: "/beats/beat_plan_list",
        feature: "",
        icon: ""
      },
      {
        name: "Beat Retailer",
        route: "/beats/beat_retailer",
        feature: "",
        icon: ""
      },
      {
        name: "Territory",
        route: "/beats/territory",
        feature: "",
        icon: ""
      },
      {
        name: "Beat Schedule",
        route: "/beats/beat_schedule",
        feature: "",
        icon: ""
      },
    ]
  },
  {
    name: "Customers",
    feature: "module_retailers",
    icon: "beat",
    route: "",
    sub_menu: [
      {
        name: "Retailers",
        route: "/customers/retailers",
        feature: "",
        icon: ""
      },
      {
        name: "Influencers",
        route: "/customers/influencers",
        feature: "feature_influencers",
        icon: ""
      },
      {
        name: "Projects",
        route: "/customers/projects",
        feature: "feature_projects",
        icon: ""
      },
      {
        name: "Leads",
        route: "/customers/leads",
        feature: "feature_leads",
        icon: ""
      },
      {
        name: "Recommendations",
        route: "/customers/outlet_product_recommendation",
        feature: "feature_outlet_prod_reco",
        icon: ""
      },
      {
        name: "Change Request",
        route: "/customers/request",
        feature: "feature_change_request",
        icon: ""
      },
      {
        name: "New Outlets",
        route: "/customers/new_outlet",
        feature: "feature_approve_outlet",
        icon: ""
      },
      {
        name: "Approve partner",
        route: "/customers/partner_approve",
        feature: "feature_partner_approve",
        icon: ""
      },
      {
        name: "Approve Retailer",
        route: "/customers/retailer_approve",
        feature: "feature_retailer_approve",
        icon: ""
      },
    ]
  },
  {
    name: "Orders",
    feature: "module_direct_primary_orders",
    icon: "",
    route: "/dms/direct_primary_orders",
  },
  {
    name: "Invoices",
    feature: "module_direct_primary_orders",
    icon: "",
    route: "/dms/direct_primary_invoices",
  },
  {
    name: "Distributor Audit",
    feature: "module_distributorAudit",
    icon: "",
    route: "/distributor_audit",
  },
  {
    name: "Retail Audit",
    feature: "module_retailAudit",
    icon: "",
    route: "/retail_audit",
  },
  {
    name: "Retail Survey",
    feature: "module_retail_survey",
    icon: "",
    route: "",
    sub_menu: [
      {
        name: "Market",
        route: "/retail_survey/market_intelligence",
        feature: "",
        icon: ""
      },
      {
        name: "Outlet KYC",
        route: "/retail_survey/kyc",
        feature: "",
        icon: ""
      },
    ]
  },
  {
    name: "Tasks",
    feature: "module_tasks",
    icon: "",
    route: "",
    sub_menu: [
      {
        name: "Task",
        route: "/task",
        feature: "",
        icon: ""
      },
      {
        name: "TTL",
        route: "/task_planner",
        feature: "",
        icon: ""
      },
      {
        name: "Survey",
        route: "/task/survey",
        feature: "",
        icon: ""
      },
      {
        name: "User Tasks",
        route: "/user_tasks",
        feature: "",
        icon: ""
      },
    ]
  },
  {
    name: "Assets",
    feature: "feature_retail_request_asset",
    icon: "",
    route: "assets/retail_request",
  },
  {
    name: "Assets",
    feature: "module_asset",
    icon: "",
    route: "",
    sub_menu: [
      {
        name: "Request",
        route: "/assets/request",
        feature: "",
        icon: ""
      },
      {
        name: "Deployed",
        route: "/assets/tagged",
        feature: "",
        icon: ""
      },
    ]
  },
  {
    name: "User Survey",
    feature: "module_user_survey",
    icon: "",
    route: "/user_survey/user_survey_list",
  },
  {
    name: "Distributors",
    feature: "module_distributors",
    icon: "",
    route: "/retailer_list",
  },
  {
    name: "Settlement",
    feature: "module_settelment",
    icon: "",
    route: "/dcvanlist",
  },
  {
    name: "Production Order",
    feature: "module_production",
    icon: "",
    route: "/dcvanliststatus"
  },
  {
    name: "Pre Orders",
    feature: "mt_module",
    icon: "",
    route: "/mtsalesdc",
  },
  {
    name: "Purchase Order",
    feature: "mt_module",
    icon: "",
    route: "/po",
  },
  {
    name: "Purchase",
    feature: "module_po",
    icon: "purchase",
    sub_menu: [
      {
        name: "PO",
        route: "/po_po",
        feature: "module_dmsv2",
        icon: "sales"
      },
      {
        name: "Receipts",
        route: "/receipts",
        feature: "module_dmsv2",
        icon: ""
      },
      {
        name: "Returns",
        route: "/returns",
        feature: "module_dmsv2",
        icon: ""
      }
    ]
  },
  {
    name: "Partners",
    feature: "module_dmsv2_customers",
    icon: "customer",
    sub_menu: [
      {
        name: "Customers",
        route: "/dms/customers",
        feature: "",
        icon: ""
      },
      {
        name: "Suppliers",
        route: "/dms/suppliers",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Sales",
    feature: "module_dmsv2",
    icon: "sales",
    route: "",
    action: {
      name: "Create Order",
      feature: "feature_quick_order",
      icon: "predefined_icon_identifier",
      type: "route/popup",
      value: "optional"
    },
    sub_menu: [
      {
        name: "Orders",
        route: "/dms/sec_sale_orders",
        feature: "",
        icon: "sales"
      },
      {
        name: "Delivery Plan",
        route: "/dms/delivery_plans",
        feature: "",
        icon: ""
      },
      {
        name: "Invoices",
        route: "/dms/sec_sale_invoices",
        feature: "",
        icon: "sales"
      },
      {
        name: "Return Invoice",
        route: "/dms/sec_return_invoices",
        feature: "",
        icon: "sales"
      },
      {
        name: "Scheme",
        route: "/dms/sec_schemes",
        feature: "",
        icon: "sales"
      },
      {
        name: "Credit Notes",
        route: "/dms/credit_notes",
        feature: "",
        icon: "sales"
      },
      {
        name: "Pay Receipts",
        route: "/dms/pay_receipts",
        feature: "",
        icon: "sales"
      },
      {
        name: "Ledger",
        route: "/dms/ledger",
        feature: "",
        icon: "ledger"
      }
    ]
  },
  {
    name: "Purchase",
    feature: "module_dmsv2_purchase",
    icon: "purchase",
    action: {
      name: "Create Order",
      feature: "feature_quick_order",
      icon: "predefined_icon_identifier",
      type: "route/popup",
      value: "optional"
    },
    sub_menu: [
      {
        name: "Orders",
        route: "/dms/purchase_orders",
        feature: "",
        icon: "sales"
      },
      {
        name: "Goods Received",
        route: "/dms/purchase_invoices",
        feature: "",
        icon: ""
      },
      {
        name: "Scheme",
        route: "/dms/p_schemes",
        feature: "",
        icon: ""
      },
      {
        name: "My Stock",
        route: "/dms/purchase_invoices",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Claims",
    feature: "module_claims",
    icon: "purchase",
    sub_menu: [
      {
        name: "My Claims",
        route: "/dms/claims",
        feature: "",
        icon: "sales"
      },
      {
        name: "Scheme Claims",
        route: "/dms/raise_scheme_claims",
        feature: "",
        icon: ""
      },
      {
        name: "Return Claims",
        route: "/dms/raise_sale_return_claims",
        feature: "",
        icon: ""
      },
      {
        name: "Multi Bill Claims",
        route: "/dms/raise_multi_bill_claims",
        feature: "",
        icon: ""
      },
      {
        name: "To Approve",
        route: "/dms/supplier_claims",
        feature: "",
        icon: ""
      },
      {
        name: "Debit Notes",
        route: "/dms/debit_notes",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Van Sales",
    feature: "module_vansales",
    route: "settlement",
    icon: "",
  },
  {
    name: "Sales",
    feature: "module_dms",
    icon: "sales",
    route: "",
    sub_menu: [
      {
        name: "Orders",
        route: "/orders",
        feature: "",
        icon: ""
      },
      {
        name: "Invoices",
        route: "/order_invoices",
        feature: "",
        icon: ""
      },
      {
        name: "Returns",
        route: "/order_invoices",
        feature: "",
        icon: ""
      },
      {
        name: "Schemes",
        route: "/dms/sec_invoice_claims",
        feature: "",
        icon: ""
      },
      {
        name: "Payments",
        route: "/dms_payments",
        feature: "",
        icon: ""
      },
      {
        name: "Credit Notes",
        route: "/credit_notes",
        feature: "",
        icon: ""
      },
      {
        name: "Delivery Plan",
        route: "/delivery_plan",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Purchase",
    feature: "module_dms",
    icon: "purchase",
    sub_menu: [
      {
        name: "Orders",
        route: "/orders",
        feature: "",
        icon: "sales"
      },
      {
        name: "Invoices",
        route: "/order_invoices",
        feature: "",
        icon: ""
      },
      {
        name: "Return Invoices",
        route: "/order_invoices",
        feature: "",
        icon: ""
      },
      {
        name: "Payments",
        route: "/dms_payments",
        feature: "",
        icon: ""
      },
      {
        name: "Debit Notes",
        route: "/credit_notes",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Stock",
    feature: "module_dms",
    route: "/livestockreport",
    icon: ""
  },
  {
    name: "Expense",
    feature: "module_expense",
    icon: "",
    sub_menu: [
      {
        name: "Approve",
        route: "/expense",
        feature: "",
        icon: ""
      },
      {
        name: "Settle",
        route: "/expense/my_expense",
        feature: "feature_expense_settle",
        icon: ""
      },
      {
        name: "My Expenses",
        route: "/expense/settle_expense",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Products",
    feature: "module_products",
    icon: "",
    sub_menu: [
      {
        name: "Products",
        route: "/products",
        feature: "",
        icon: ""
      },
      {
        name: "Pricelist",
        route: "/products/pricelist",
        feature: "",
        icon: ""
      },
      {
        name: "Stock",
        route: "/products/stock",
        feature: "",
        icon: ""
      },
      {
        name: "Schemes",
        route: "/products/schemes",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Users",
    feature: "module_users",
    icon: "",
    sub_menu: [
      {
        name: "Users",
        route: "/users",
        feature: "",
        icon: ""
      },
      {
        name: "Team",
        route: "/users/team",
        feature: "",
        icon: ""
      }
    ]
  },
  {
    name: "Invoices",
    feature: "module_sales",
    route: "/sales",
    icon: ""
  },
  {
    name: "Master",
    feature: "module_master",
    icon: "",
    sub_menu: [
      {
        name: "Schemes",
        route: "/master/schemes",
        feature: "",
        icon: ""
      },
      {
        name: "Pricelist",
        route: "/master/pricelist",
        feature: "",
        icon: ""
      }
    ]
  },
];
