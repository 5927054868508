export const MTManagerBoardCollection = {
  boards: [{
    title: 'MT Dashboard',
    structure: '4-4-4-4/8-8/8-8/16',
    id: 8,
    boardInstanceId: 2,
    rows: [
      {
        columns: [
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'card_secondary_sales',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 101,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'instanceId',
                          value: 1,
                          required: true,
                          order: -1,
                          controlType: 'hidden'
                        },
                        {
                          key: 'title',
                          value: 'Secondary Sales',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "card_secondary_sales",
                          options: [{
                            key: "card_secondary_sales",
                            value: "card_secondary_sales"
                          }, {
                            key: "user_attendance",
                            value: "user_attendance"
                          }, {
                            key: "retail_universe",
                            value: "retail_universe"
                          }, {
                            key: "primary_sales",
                            value: "primary_sales"
                          }, {
                            key: "tertiary_sales",
                            value: "tertiary_sales"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'user_attendance',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 102,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'instanceId',
                          value: 2,
                          required: true,

                          order: -1,
                          controlType: 'hidden'
                        },
                        {
                          key: 'title',
                          value: 'Users',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "user_attendance",
                          options: [{
                            key: "card_secondary_sales",
                            value: "card_secondary_sales"
                          }, {
                            key: "user_attendance",
                            value: "user_attendance"
                          }, {
                            key: "retail_universe",
                            value: "retail_universe"
                          }, {
                            key: "primary_sales",
                            value: "primary_sales"
                          }, {
                            key: "tertiary_sales",
                            value: "tertiary_sales"
                          }]

                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'retail_universe',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 103,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'instanceId',
                          value: 3,
                          required: true,
                          order: -1,
                          controlType: 'hidden'
                        },
                        {
                          key: 'title',
                          value: 'Retailers',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "retail_universe",
                          options: [{
                            key: "card_secondary_sales",
                            value: "card_secondary_sales"
                          }, {
                            key: "user_attendance",
                            value: "user_attendance"
                          }, {
                            key: "retail_universe",
                            value: "retail_universe"
                          }, {
                            key: "primary_sales",
                            value: "primary_sales"
                          }, {
                            key: "tertiary_sales",
                            value: "tertiary_sales"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'tertiary_sales',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 104,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'instanceId',
                          value: 4,
                          required: true,
                          order: -1,
                          controlType: 'hidden'
                        },
                        {
                          key: 'title',
                          value: 'Tertiary Sales',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "tertiary_sales",
                          options: [{
                            key: "card_secondary_sales",
                            value: "card_secondary_sales"
                          }, {
                            key: "user_attendance",
                            value: "user_attendance"
                          }, {
                            key: "retail_universe",
                            value: "retail_universe"
                          }, {
                            key: "primary_sales",
                            value: "primary_sales"
                          }, {
                            key: "tertiary_sales",
                            value: "tertiary_sales"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [

          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'BarGraphGadgetComponent',
                name: 'Target vs Achivement',
                description: 'Current Month target vs Achivement',
                icon: 'images/trend-line.png',
                instanceId: 5334,
                tags: [
                  {
                    facet: 'Performance',
                    name: 'target'
                  },
                  {
                    facet: 'Chart',
                    name: 'bar'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Outlet Coverage",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "run",
                          required: true,
                          order: -1
                        },
                        {
                          controlType: "hidden",
                          key: "instanceId",
                          label: "",
                          value: 999,
                          required: true,
                          order: -2
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "outlet_coverage_with_order_count",
                          options: [{
                            key: "state_wise_planned_target_ach",
                            value: "state_wise_planned_target_ach"
                          },
                            {
                              key: "team_wise_planned_target_ach",
                              value: "team_wise_planned_target_ach"
                            },
                            {
                              key: "salesman_wise_planned_target_ach",
                              value: "salesman_wise_planned_target_ach"
                            },
                            {
                              key: "outlet_coverage_with_order_count",
                              value: "outlet_coverage_with_order_count"
                            },
                            {
                              key: "stock_distribution_compliance",
                              value: "stock_distribution_compliance"
                            }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "dropdown",
                          type: "dropdown",
                          key: "barChartType",
                          label: "Bar Chart Type",
                          value: "stackedHorizontal",
                          required: true,
                          order: 1,
                          options: [
                            {
                              key: "Normalized Horizontal",
                              value: "normalizedHorizontal"
                            },
                            {
                              key: "Normalized Vertical",
                              value: "normalizedVertical"
                            },
                            {
                              key: "Stacked Horizontal",
                              value: "stackedHorizontal"
                            },
                            {
                              key: "Stacked Vertical",
                              value: "stackedVertical"
                            },
                            {
                              key: "Grouped Horizontal",
                              value: "groupedHorizontal"
                            },
                            {
                              key: "Grouped Vertical",
                              value: "groupedVertical"
                            }
                          ]
                        },
                        {
                          controlType: "textbox",
                          key: "yAxisLabel",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showDataLabel",
                          label: "Show data label",
                          value: true,
                          required: false,
                          order: 5
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: true,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'BarGraphGadgetComponent',
                name: 'Outlet Type Productivity',
                description: 'Outlet Type wise Current Month Productivity',
                icon: 'images/donut.png',
                instanceId: 15002534413123,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "bar"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Distribution Health",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "run",
                          required: true,
                          order: -1
                        },
                        {
                          controlType: "hidden",
                          key: "instanceId",
                          label: "",
                          value: 999,
                          required: true,
                          order: -2
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "stock_distribution_compliance",
                          options: [
                          {
                            key: "outlet_coverage_with_order_count",
                            value: "outlet_coverage_with_order_count"
                          },
                          {
                            key: "stock_distribution_compliance",
                            value: "stock_distribution_compliance"
                          }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "dropdown",
                          type: "dropdown",
                          key: "barChartType",
                          label: "Bar Chart Type",
                          value: "stackedHorizontal",
                          required: true,
                          order: 1,
                          options: [
                            {
                              key: "Normalized Horizontal",
                              value: "normalizedHorizontal"
                            },
                            {
                              key: "Normalized Vertical",
                              value: "normalizedVertical"
                            },
                            {
                              key: "Stacked Horizontal",
                              value: "stackedHorizontal"
                            },
                            {
                              key: "Stacked Vertical",
                              value: "stackedVertical"
                            },
                            {
                              key: "Grouped Horizontal",
                              value: "groupedHorizontal"
                            },
                            {
                              key: "Grouped Vertical",
                              value: "groupedVertical"
                            }
                          ]
                        },
                        {
                          controlType: "textbox",
                          key: "yAxisLabel",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showDataLabel",
                          label: "Show data label",
                          value: true,
                          required: false,
                          order: 5
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: true,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'PieGraphGadgetComponent',
                name: 'Pie Chart',
                description: 'Outlet Type wise Current Month Productivity',
                icon: 'images/donut.png',
                instanceId: 150022534413123,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "bar"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Inward Sales By Category",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "run",
                          required: true,
                          order: -1
                        },
                        {
                          controlType: "hidden",
                          key: "instanceId",
                          label: "",
                          value: 999,
                          required: true,
                          order: -2
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "secondary_sales_by_category",
                          options: [{
                            key: "secondary_sales_by_category",
                            value: "secondary_sales_by_category"
                          }, {
                            key: "tertiary_sales_by_category",
                            value: "tertiary_sales_by_category"
                          }, {
                            key: "primary_sales_by_category",
                            value: "primary_sales_by_category"
                          }, {
                            key: "secondary_sales_by_brand",
                            value: "secondary_sales_by_brand"
                          }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "dropdown",
                          type: "dropdown",
                          key: "pieChartType",
                          label: "Pie Chart Type",
                          value: "pieChartAdvanced",
                          required: true,
                          order: 1,
                          options: [
                            {
                              key: "pieChart",
                              value: "pieChart"
                            },
                            {
                              key: "pieChartAdvanced",
                              value: "pieChartAdvanced"
                            }]
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 2
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show Legend",
                          value: true,
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLabels",
                          label: "Show Labels",
                          value: true,
                          required: false,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "isDoughnut",
                          label: "is Doughnut",
                          value: true,
                          required: false,
                          order: 5
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'TreeGraphGadgetComponent',
                name: 'Pie Chart',
                description: 'Secondary Sales By Regions',
                icon: 'images/donut.png',
                instanceId: 15223534413123,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "tree"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Sales By Regions",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "stop",
                          required: true,
                          order: -1
                        },
                        {
                          controlType: "hidden",
                          key: "instanceId",
                          label: "",
                          value: 91293,
                          required: true,
                          order: -2
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "secondary_sales_by_regions",
                          options: [{
                            key: "secondary_sales_by_regions",
                            value: "secondary_sales_by_regions"
                          },
                          {
                            key: "primary_sales_by_regions",
                            value: "primary_sales_by_regions"
                          },
                          {
                            key: "tertiary_sales_by_regions",
                            value: "tertiary_sales_by_regions"
                          }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 2
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "animation",
                          label: "Animations",
                          value: true,
                          required: false,
                          order: 3
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            styleClass: 'sixteen wide',
            gadgets: [
              {
                componentType: 'HeatGraphGadgetComponent',
                name: 'Heat Chart',
                description: 'Activities',
                icon: 'images/donut.png',
                instanceId: 15222534413123,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "tree"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          key: "title",
                          controlType: "textbox",
                          label: "Title",
                          value: "Activities",
                          required: true,
                          order: 1
                        },
                        {
                          key: "state",
                          controlType: "hidden",
                          label: "",
                          value: "stop",
                          required: true,
                          order: -1
                        },
                        {
                          key: "instanceId",
                          controlType: "hidden",
                          label: "",
                          value: 9129,
                          required: true,
                          order: -2
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "visits_by_date",
                          options: [{
                            key: "productivity_by_date",
                            value: "productivity_by_date"
                          },
                          {
                            key: "visits_by_date",
                            value: "visits_by_date"
                          },
                          {
                            key: "stock_audit_activity",
                            value: "stock_audit_activity"
                          }
                          ]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          key: "yAxisLabel",
                          controlType: "textbox",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: false,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
  ],
  reports: [
    {
      title: 'Secondary Sales',
      role_feature: 'report_outlet_sales',
      id: 3,
      code: 'R003',
      category: 'Sales',
      route: '/reports/outlet_sales',
      config: [{
        name : 'fav 1',
        columnState : [],
        defaut : true
      }, {
        name : 'fav 2',
        columnState : [],
        defaut : false
      }]
    },
    {
      title: 'Shelf Analysis',
      role_feature: 'report_shelf_analysis',
      id: 2,
      code: 'R002',
      category: 'Retail Audit',
      route: '/reports/shelf_analysis',
      config: [{
        name : 'fav 1',
        columnState : [],
        default : true
      }, {
        name : 'fav 2',
        columnState : [],
        default : false
      }]

    },
  ]
};

