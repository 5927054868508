// @ts-ignore
// @ts-ignore

import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-new-bulk-suppliers-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class BulkSuppliersUploadComponent extends BaseUpload {
  async setViewParams() {
    this.header = 'New Suppliers bulk Upload';
    this.sampleText1 = 'DOWNLOAD TEMPLATE';
    this.sampleText2 = '';

    this.excel_columns = [{C: 'A', H: 'Code', V: 'ES2355', required: false},
      {C: 'B', H: 'Supplier Name', V: 'Shobha Stores Pvt Ltd', required: true},
      {C: 'C', H: 'Type', V: 'Wholesale', required: true, T: 'FK', field: 'type', DBF: 'supplier_type', validation: {null_check: true}},
      {C: 'D', H: 'Mobile', V: '9480439477', required: true, T: 'int', field: 'mobile', DBF: 'mobile', validation: {null_check: true, regex: '^[6-9]\\d{9}$'}},
      {C: 'E', H: 'GST Id', V: '29AALCA496', required: false, T: 'text', field: 'gst_id', DBF: 'gst_id', validation: {null_check: false}},
      {C: 'F', H: 'Street 1', V: '365, 2nd Cross', required: false, T: 'text', field: 'street', DBF: 'street'},
      {C: 'G', H: 'Street 2', V: 'MG Road', required: false, T: 'text', field: 'street2', DBF: 'street2'},
      {C: 'H', H: 'Zip', V: '560043', required: false, T: 'int', field: 'zip', DBF: 'zip'},
      {C: 'I', H: 'City', V: 'Bengaluru', required: false, T: 'text', field: 'city', DBF: 'city'},
      {C: 'J', H: 'State', V: 'Karnataka', required: true, T: 'FK', field: 'state', DBF: 'state_id'},
      {C: 'K', H: 'Balance', V: '5000', required: false, T: 'int', field: 'balance', DBF: 'balance'},
      {C: 'L', H: 'Supplier', V: 'Harrison Suppliers', required: true, T: 'text', field: 'supplier', DBF: 'supplier'},
      {C: 'M', H: 'Credit Limit', V: '10000', required: false, T: 'numeric', field: 'credit_limit', DBF: 'credit_limit'},
      {C: 'N', H: 'PAN No.', V: 'AALCA496', required: true, T: 'text', field: 'pan_no', DBF: 'pan_no'},
      {C: 'O', H: 'Cluster', V: 'Harrison Suppliers', required: false, T: 'text', field: 'cluster', DBF: 'cluster'},
      {C: 'P', H: 'Region', V: 'Kalyan Nagar', required: false, T: 'text', field: 'region', DBF: 'region'},
    ];
    this.step1Text = 'Download all suppliers list or sample template for reference.';
    this.step2Text = 'Required fields are Name, Mobile, Type, Suppliers, State, PAN No.';
    this.actionText = 'Fill excel with new suppliers';
    await this.fetchSupplierData();
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData('new_supplier_bulk_upload');
  }

  configureGrid() {
    this.API_URL = '';
    this.columnDefs = [
      {headerName: 'ID', field: 'uid', width: 100, hide: true},
      {headerName: 'Code', field: 'code', width: 100},
      {headerName: 'Supplier Name', field: 'name', width: 200},
      {headerName: 'Type', field: 'type', width: 100},
      {headerName: 'Supplier', field: 'supplier', width: 100},
      {headerName: 'Mobile', field: 'mobile', width: 100},
      {headerName: 'GST Id', field: 'gst_id', width: 100},
      {headerName: 'Street 1', field: 'street', width: 100},
      {headerName: 'Street 2', field: 'street2', width: 100},
      {headerName: 'City', field: 'city', width: 100},
      {headerName: 'Zip', field: 'zip', width: 100},
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'PAN No.', field: 'pan_no', width: 100},
      {headerName: 'Region', field: 'region', width: 100},
      {headerName: 'Cluster', field: 'cluster', width: 100},
      {headerName: 'Balance', field: 'balance', width: 100},
      {headerName: 'Credit Limit', field: 'credit_limit', width: 100},
    ];
  }

  async fetchSupplierData() {
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/get_supplier_detail_for_dms', this.userData);
    if (res && res.results.status === 200) {
      this.supplierList = res.results.data;
    } else {
      this.toastr.error('Error fetching supplier master');
    }
  }



  validate() {
    this.validRecords = 0;
    this.errorRecords = 0;
    let index = 0;


    this.rowData = this.uploaded_data.map(data => {
      const indrow: any = this.initializeIndRow(data);

      this.checkDuplicateCode(indrow);
      this.checkDuplicateName(indrow);
      this.validateType(indrow);
      this.validateState(indrow);
      this.validateBalance(indrow);
      this.validateMobile(indrow);
      this.validateGST(indrow);
      this.validateBalance(indrow);

      indrow.valid === 'Invalid' ? this.errorRecords++ : this.validRecords++;
      indrow['uid'] = ++index;
      return indrow;
    });

    this.totalRecords = this.rowData.length;
    if (this.gridOptions?.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
    this.setCellClassRulesAndTooltips();
  }

  initializeIndRow(data) {
    return {
      code: this.getExcelFieldValue(data, ['code', 'Code']),
      name: this.getExcelFieldValue(data, ['name', 'Name', 'Supplier Name']),
      type: this.getExcelFieldValue(data, ['Type', 'type']),
      supplier: this.getExcelFieldValue(data, ['Supplier', 'supplier']),
      mobile: this.getExcelFieldValue(data, ['Mobile', 'mobile']),
      gst_id: this.getExcelFieldValue(data, ['GST Id', 'Tax Id', 'tax id', 'TAN', 'GST', 'gst']),
      street: this.getExcelFieldValue(data, ['Street 1', 'street1', 'street', 'street 1']),
      street2: this.getExcelFieldValue(data, ['Street 2', 'street2', 'street 2']),
      city: this.getExcelFieldValue(data, ['City', 'city']),
      state: this.getExcelFieldValue(data, ['State', 'state']),
      zip: this.getExcelFieldValue(data, ['Zip', 'zip', 'Zip Code']),
      pan_no: this.getExcelFieldValue(data, ['PAN', 'pan', 'PAN No.']),
      region: this.getExcelFieldValue(data, ['Region', 'region']),
      cluster: this.getExcelFieldValue(data, ['Cluster', 'cluster']),
      balance: this.getExcelFieldValue(data, ['Balance', 'balance']),
      credit_limit: this.getExcelFieldValue(data, ['Credit Limit', 'credit limit', 'creditlimit']),
      errorDetails: {}
    }
  }

  validateMobile(indrow) {
    //Mobile No. Validation
    if (!indrow.mobile || indrow.mobile === '') {
      this.SetInvalidStatus(indrow, 'Mobile No. is mandatory Field', 'mobile');
    } else {
      const mobRegEx = /^[6-9]\d{9}$/;
      const isMobile = mobRegEx.test(indrow.mobile);
      if (!isMobile) {
        this.SetInvalidStatus(indrow, 'Mobile No. is invalid', 'mobile');
      }
    }
  }

  checkDuplicateCode(indrow) {
    if (indrow.code) {
      const supplierObject = this.supplierList.find(x => x.code === indrow.code);
      if (supplierObject) {
        this.SetInvalidStatus(indrow, `Record exists with same Code, ID: ${supplierObject.id}`, 'code');
      }
    }
  }

  checkDuplicateName(indrow) {
    if (indrow.name) {
      const supplierObject = this.supplierList.find(x => x.name?.toLowerCase() === indrow.name.toLowerCase());
      if (supplierObject) {
        this.SetInvalidStatus(indrow, `Record exists with same Name, ID: ${supplierObject.id}`, 'name');
      }
    } else {
      this.SetInvalidStatus(indrow, 'Name is a mandatory field', 'name');
    }
  }

  validateBalance(indrow) {
    if (indrow.balance && isNaN(Number(indrow.balance))) {
      this.SetInvalidStatus(indrow, 'Balance has to be numeric', 'balance');
    }
  }

  validateState(indrow) {
    if (!indrow.state) {
      this.SetInvalidStatus(indrow, 'State is a mandatory field', 'state');
    } else {
      indrow.state_id = this.getLookupId('state_id', indrow.state);
      if (!indrow.state_id) {
        this.SetInvalidStatus(indrow, 'State Not Found', 'state');
      }
    }
  }

  validateType(indrow) {
    if (!indrow.type || indrow.type === '') {
      this.SetInvalidStatus(indrow, 'Type is mandatory Field', 'type');
    } else {
      indrow.supplier_type = this.getLookupId('supplier_type', indrow.type);
      if (!indrow.supplier_type) {
        this.SetInvalidStatus(indrow, 'Type not found', 'type');
      }
    }
  }

  validateGST(indrow) {
    if (indrow.gst_id && indrow.gst_id !== '') {
      const gstinRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/;
      const isValidgst = gstinRegex.test(indrow.gst_id);
      if (isValidgst !== true) {
        this.SetInvalidStatus(indrow, 'GSTIN is invalid', 'gst_id');
      }
    }
  }

  downloadDataWithErrors() {
    super.downloadDataWithErrors();
  }
}
