import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from '../../../node_modules/@ag-grid-enterprise/all-modules';
import { ApiService} from '../_services/api.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from '../colorCell.component';
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from '../components/loadingstate/loadingstate.component';
import {timeout} from 'rxjs/operators/timeout';
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-sale-return-report',
  templateUrl: './sale-return-report.component.html',
  styleUrls: ['./sale-return-report.component.scss']
})
export class SaleReturnReportComponent implements OnInit {
  @ViewChild('dp') dp: ElementRef<HTMLElement>;

  user;
  res_data;
  user_data = {
    start_date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    dcs: [],
    zip_flag: 'normal'
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public itemList = [{id: 0, itemName: 'DC'}];
  public selectedItems = [{id: 0, itemName: 'DC'}];
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ["", 0];
  public selectedZipDc = "0";
  public zip_url = "";
  public all_dc_flag = false;
  public zipDate = [];
  public role_features = [];
  public all_dc = [];
  public dms_enable = false;
  private API_URL: string;

  constructor(private apiService: ApiService,
              private router: Router,
              private sharedService: SharedService,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe) {

    this.zipDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.zipDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.start_date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.start_date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_dcs')));
    if (localStorage.getItem('dms_module') === 'true' || localStorage.getItem('module_dmsv2') === 'true') {
      this.dms_enable = true;
    }
    if (this.sharedService.ACLcheck('feature_all_dc_filter')) {
      this.all_dc_flag = true;
    }
    if (this.all_dc_flag) { this.all_dc = [{id: 0, itemName: "All DC", checked: false}]; }
    this.all_dc = this.all_dc.concat(JSON.parse(localStorage.getItem('all_dcs')));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.gridOptions = { rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }} as GridOptions;

  }

  run() {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 30) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.salereturnreport();
    }
  }

  dcChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.dcs = [event[0].id];
      } else {
        this.user_data.dcs = [];
      }
    }
  }

  dropDown() {
    const el: HTMLElement = this.dp.nativeElement;
    if (el.classList.contains('visible')) {
      this.renderer.removeClass(el, 'visible');
    } else {
      this.renderer.addClass(el, 'visible');
    }
  }

  close() {
    this.zip_loader = false;
    this.zip_ready = false;
  }

  generateZip() {
    this.zip_loader = true;
    this.zip_ready = false;
    this.user_data.zip_flag = 'zip';
    this.user_data.start_date = this.datePipe.transform(this.zipDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.zipDate[1], 'yyyy-MM-dd');
    if (this.all_dc_flag) {
      this.user_data.dcs = this.all_dc.reduce((a, o) => (o.checked && a.push(o.id), a), []);
    } else {
      this.user_data.dcs = [parseInt(this.selectedZipDc, 10)];
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.zipDate[0];
    const secondDate = this.zipDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    if (this.all_dc_flag && diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (this.user_data.dcs.length === 0) {
      window.alert('Please Select 1 DC');
      this.zip_loader = false;
    } else if (!this.all_dc_flag && parseInt(this.selectedZipDc, 10) === 0 && diffDays > 7) {
      window.alert('Please Select 1 week data for all DC');
      this.zip_loader = false;
    } else {
      this.res_data = this.apiService.saleReturnReport(this.user_data);
      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.zip_ready = true;
        }
        this.zip_loader = false;
      }, error => {
        console.log(error);
        this.zip_loader = false;
      });
    }
  }

  goToLog() {
    localStorage.setItem('log_params', JSON.stringify({start_date: this.user_data.start_date, last_date: this.user_data.last_date}));
    this.router.navigate([this.apiService.redirect_url + "/logs"] );
  }

  salereturnreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const datePipe = this.datePipe;
    if (this.dms_enable) {
      this.API_URL = '/api/dms_connects/sale_return_report';
    } else {
      this.API_URL = '/api/pwa_connects/sale_return_report';
    }
    this.apiService.post(this.API_URL, this.user_data)
    .subscribe(res => {
        console.log(res);
        const inthis = this;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(data => {
            if (data.net_sale === null) { data.net_sale = 0; }
            if (data.gross_sale === null) { data.gross_sale = 0; }
            const pushData = {
              'Line ID': data.line_id,
              Ref: data.order_ref,
              State: data.dc_state,
              Region: data.region,
              DC: data.dc,
              Van: data.van,
              Zone: data.zone,
              Beat: data.beat,
              Outlet: data.outlet,
              'Outlet ID': data.outlet_id,
              Code: data.outlet_code,
              Date: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'dd/MM/yy'),
              Time: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
              'Invoice No': data.invoice_no,
              Qty: parseInt(data.qty, 10),
              Product: data.product,
              'Product Code': data.default_code,
              Category: data.category,
              Brand: data.brand,
              GRV: data.grv,
              PO: data.po,
              'Net Return': parseFloat(Math.abs(parseFloat(data.net_sale)).toFixed(inthis.apiService.decimalPolicy)),
              'Gross Return': Math.abs(parseFloat(data.gross_sale)),
              'Outlet Type': data.outlet_type,
              'Approval Status': data.approval_status,
              Area: data.area,
              Type: data.type
            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;
          const inThis = this;
          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'Outlet') {
                this.columnDefs.push({ headerName: key, field: key, width: 200, aggFunc: 'sum' });
              } else if (key === 'Beat') {
                this.columnDefs.push({ headerName: key, field: key, width: 130,   aggFunc: 'sum' });
              } else if (key === 'Approval Status') {
                this.columnDefs.push({ headerName: key, field: key, width: 130,   aggFunc: 'sum', hide: inthis.dms_enable});
              } else if (key === 'Product') {
                this.columnDefs.push({ headerName: key, field: key, width: 200,   aggFunc: 'sum' });
              } else if (key === 'Qty' || key === 'Net Return' || key === 'Gross Return' || key === 'Line ID' || key === 'Product Code' || key === 'Outlet Code') {
                this.columnDefs.push({ cellStyle: {textAlign: 'right'}, headerName: key, field: key, width: 100,   aggFunc: 'sum' });
              } else if (key === 'DC' || key === 'State' || key === 'Region' || key === 'GRV' || key === 'PO') {
                this.columnDefs.push({ headerName: key, field: key,  width: 100,   aggFunc: 'sum', hide: inThis.dms_enable });
              } else if (key === 'Ref' || key === 'Invoice No') {
                this.columnDefs.push({ headerName: key, field: key, width: 230,   aggFunc: 'sum'});
              } else if (key === 'Van') {
                let head = key;
                if (inthis.dms_enable) { head = 'Salesman'; }
                this.columnDefs.push({ headerName: head, field: key, width: 120,   aggFunc: 'sum' });
              } else {
                this.columnDefs.push({ headerName: key, field: key, width: 120,   aggFunc: 'sum' });
              }
            }
          }
          this.loaded = true;

        } else {
          this.preloader = false;
          this.empty_state = true;
        }
      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    if (screen.width > 991) {
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }
}
