<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Change Request Action</h2>
    </div>
    <div class="body-section w-100">
      <div *ngIf="changeRequestData.lead_id === null" class="rc-card">
        <label class="rc-card-header-label"> Retailer Details</label>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Name</label>
            <span class="rc-card-detail-span">{{changeRequestData.partner_name}}</span>
            <div *ngIf="changeRequestData.is_verified">
              <span style="color:#379862;font-size: 9px; font-weight: 500;">VERIFIED</span>
              <span style="margin-left:5px;display:inline;"> <i class="fas fa-check-circle " style="color:#379862"></i></span>
            </div>
            <div *ngIf="!changeRequestData.is_verified">
              <span style="color:#B82525; font-size: 9px; font-weight: 500;">NOT VERIFIED</span>
              <span style="margin-left:5px;display:inline;"> <i class="fas fa-times-circle " style="color:#B82525"></i></span>
            </div>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Code</label>
            <span class="rc-card-detail-span">{{changeRequestData.code}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Contact</label>
            <span class="rc-card-detail-span">{{changeRequestData.mobile}}</span>
          </div>
        </div>
        <div class="row"  style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Contact Name</label>
            <span class="rc-card-detail-span">{{changeRequestData.contact_name}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Cluster</label>
            <span class="rc-card-detail-span">{{changeRequestData.cluster}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Beat</label>
            <span class="rc-card-detail-span">{{changeRequestData.beat}}</span>
          </div>
        </div>
        <div class="row"  style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Territory</label>
            <span class="rc-card-detail-span">{{changeRequestData.territory}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">GSTN</label>
            <span class="rc-card-detail-span">{{changeRequestData.gst_id}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Address</label>
            <span class="rc-card-detail-span">{{changeRequestData.outlet_address}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="changeRequestData.lead_id !== null" class="rc-card">
        <label class="rc-card-header-label"> Lead Details</label>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Name</label>
            <span class="rc-card-detail-span">{{changeRequestData.partner_name}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Partner Type</label>
            <span class="rc-card-detail-span">{{changeRequestData.lead_type}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Description</label>
            <span class="rc-card-detail-span">{{changeRequestData.description}}</span>
          </div>
        </div>
        <div class="row"  style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Customer</label>
            <span class="rc-card-detail-span">{{changeRequestData.lead_customer}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Salesperson</label>
            <span class="rc-card-detail-span">{{changeRequestData.lead_salesman}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Contact Person</label>
            <span class="rc-card-detail-span">{{changeRequestData.contact_name}}</span>
          </div>
        </div>
        <div class="row"  style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Email</label>
            <span class="rc-card-detail-span">{{changeRequestData.email_from}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Mobile</label>
            <span class="rc-card-detail-span">{{changeRequestData.mobile}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Address</label>
            <span class="rc-card-detail-span">{{changeRequestData.lead_address}}</span>
          </div>
        </div>
      </div>
      <div class="rc-card">
        <label class="rc-card-header-label"> Request Details</label>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Requested By</label>
            <span class="rc-card-detail-span">{{changeRequestData.req_by}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Requested Date</label>
            <span class="rc-card-detail-span">{{changeRequestData.req_date}}</span>
          </div>
          <div class="col-md-4 pad0">
            <label class="rc-card-detail-label">Request Type</label>
            <span class="rc-card-detail-span">{{request_type_text}}</span>
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4 pad0" style="cursor: pointer" (click)="goToLink('old')" title="Click to view in Google Maps">
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #D97C0E"></span>
              <span  class="input-color color-box-text" style="color: #D97C0E">Old Value</span>
              <span *ngIf="changeRequestData.request_type.toLowerCase().includes('location')" class="input-color color-box-icon"><i class="fad fa-map-marked-alt" style="color:#183153"></i></span>
            </div>
            <span class="rc-card-detail-span">{{changeRequestData.old_val_text}}</span>
          </div>
          <div class="col-md-4 pad0" style="cursor: pointer" (click)="goToLink('new')" title="Click to view in Google Maps">
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #3A6C87"></span>
              <span  class="input-color color-box-text" style="color: #3A6C87">New Value</span>
              <span *ngIf="changeRequestData.request_type.toLowerCase().includes('location')" class="input-color color-box-icon"><i class="fad fa-map-marked-alt" style="color:#183153"></i></span>
            </div>
            <span class="rc-card-detail-span">{{changeRequestData.new_val_text}}</span>
          </div>
          <div class="col-md-4 pad0">

          </div>
        </div>
      </div>
      <div *ngIf="showMap" class="rc-card">
            <div class="map" id="map" style="margin-top: 5px;" (click)="showLocationPopup()">
              <agm-map
                [latitude]="end_lat"
                [longitude]="end_lng"
                [zoom]="zoom"
                (mapReady)="mapReady($event)"
              >
                <agm-marker *ngIf="start_lat!=null && start_lat!=undefined &&start_lat!=0"
                            [iconUrl]="starticon"
                            [latitude]="start_lat"
                            [longitude]="start_lng">
                </agm-marker>
                <agm-marker
                  [iconUrl]="endicon"
                  [latitude]="end_lat"
                  [longitude]="end_lng">
                </agm-marker>
              </agm-map>
            </div>
            <div id="infowindow-content">
              <span id="place-name" class="title"></span><br />
              <span id="place-address"></span>
            </div>
      </div>
    </div>
    <div  *ngIf="approveAccess && enableApprove" class="button-row" style="float: right; margin: 25px 3px 10px 0px;">
      <button class="btn reject-btn" style="margin:5px; " (click)="performAction('rejected')">Reject</button>
      <button class="btn confirm-green-btn" (click)="performAction('approved')">Approve</button>
    </div>
  </div>
</section>
