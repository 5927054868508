<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-3 pad0">
          <div class="search">
            <span class="search-control"><img src="../../../assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search">
          </div>
        </div>
        <div class="col-md-4 pad0 nopadding">

          <div class="row">
            <div class="input-color" style="margin-left:20px;">
              <span class="input-color color-box" style="background-color: #2D2D2D"></span>
              <span  class="input-color color-box-text" >Settled</span>
            </div>
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #379862"></span>
              <span  class="input-color color-box-text" >Approved</span>
            </div>


          </div>
        </div>
        <div class="col-md-5 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li [hidden]="show_approve" style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li *ngIf="apprtype==='appr2'" class="margLR">
                <button [hidden]="!show_approve" (click)="commentDlg('settle')" class="btn blue-btn" style="margin:5px;">Settle</button>
              </li>
              <li class="margLR">
                <div style="background-color: white;width:45px;height:45px;display: flex; align-items: center; justify-content: center;cursor:pointer; ">
                  <span title="Add Filter" >
                    <img
                      style="height: 20px;"
                      src="../../../assets/filter.svg"
                      [matMenuTriggerFor]="filterdropdown"
                    >
                  </span>
                  <mat-menu #filterdropdown="matMenu">
                    <app-components-filters  [matMenuTriggerFor]="filterdropdown" [showableFilters]="showableFilters"  (onsetFilter)="setFilter($event)" (onGridSearch)="gridSearch($event)"  ></app-components-filters>
                  </mat-menu>
                </div>
              </li>
              <li class="margLR">
                <span [hidden]="!show_approve"
                      style="cursor:pointer;"
                      title="Download Files"
                      (click)="downloadExpenseFiles()" >
                  <img src="../../../assets/download%20.svg">
                </span>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Expense Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="width: 100%;height: 80vh" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             [context]="context" (rowSelected)="onSelectionChanged($event)" (cellClicked)="onCellClicked($event)"
                             (filterChanged)="onFilterChanged($event)"
                             (filterModified)="onFilterModified($event)">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #settleModel class="approve-modal modal" id="settleModel" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div *ngIf="expense_act === 'settle'" class="approve-modal-heading">
          <h1> Are You Sure You Want To Settle Expenses?</h1>
        </div>
        <div class="approve-modal-content">
          <a (click)="settle()" *ngIf="expense_act === 'settle'" data-dismiss="modal" class="btn blue-btn">Yes</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">No</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div #detailModel class="pol-modal pol-send invoice modal" id="detailModel" role="dialog">

  <div class="modal-dialog" style="z-index: 1;">
    <!-- Modal content-->
    <div class="modal-content" style="width:700px;">
          <span class="close" data-dismiss="modal">
            <img src="../../../assets/close.png">
          </span>
      <div class="billing-details">
        <div class="billing-name">
          <ul class="list-unstyled">
            <li>
              <h1>{{expenseData['Date']}}</h1>
            </li>
            <li>
              <h1 style="font-size: 16px;font-weight: 600;">{{expenseData['Name']}} : {{expenseData['Policy']}}</h1>
              <h1 style="font-size: 15px;font-weight: 300;">Beat : {{expenseData['Beat']}}   City Class : {{expenseData['City Class']}}</h1>
            </li>
            <li>
              <label>{{expenseData['Comment']}}</label>
            </li>
            <li *ngIf="expenseData['Status'] == 'Approved'" style="margin-left: 150%;margin-top: -75px;">
              <button (click)="approveDlg('settle')" class="btn blue-btn">Settle</button>
            </li>
          </ul>
        </div>
        <div class="billing-order">
          <ul class="list-unstyled" style="margin-left: 20%;">
            <li>
              <h1>{{expenseData['Total']}}</h1>
            </li>
          </ul>
        </div>

      </div>
      <div>
        <div class="mat-tab">
          <mat-tab-group dynamicHeight (selectedTabChange)="tabClick($event)" [(selectedIndex)]="tabIndex">
            <mat-tab>
              <ng-template mat-tab-label>
                <span style="font-weight: bolder;color: black;padding-left: 4%;">EXPENSE</span>
              </ng-template>
              <mat-accordion id="myMat">
                <div *ngFor="let expense of expenseDetailsData" style="border-bottom: solid 1px;">
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-description>
                        <img *ngIf="expense.photo" [src]="expense.photo" style="width: 45%;
                                     height: 164px;
                                     border-left: solid 3px lightgrey;
                                     border-right: solid 3px lightgrey;
                                     border-top: solid 3px lightgrey;
                                     border-bottom: solid 3px lightgrey;"/>
                        <img *ngIf="!expense.photo" src="../../../assets/no-image-icon.png" style="width: 45%;
                                     height: 164px;
                                  border-left: solid 3px lightgrey;
                                     border-right: solid 3px lightgrey;
                                     border-top: solid 3px lightgrey;
                                     border-bottom: solid 3px lightgrey;"/>
                        <div style="height:190px;">
                          <div style="position: relative;
                           left: 25px;">
                            <label [ngClass]="{'max-exceed': expense.amount > expense.max_claim_amount}"
                                   class="expense-label">{{to_upperCase(expense.exp_type)}}</label>
                          </div>
                          <div style="position: relative;top: -25px;
                           left: 290px;
                           ;width:fit-content;" [ngClass]="{'approve-green': expense.state === 'approved',
                           'reject-red': expense.state === 'rejected',
                           'new-blue': expense.state === 'new',
                           'settle-grey': expense.state === 'settled',
                           'process-orange': expense.state === 'processing'}">
                            <label style="font-size: 140%;font-weight: 400;">{{to_upperCase(expense.state)}}</label>
                          </div>
                          <div style="position: relative;
                                  left: 25px;
                                  top:-20px;
                                  display: inline-block;">
                            <input *ngIf="expenseData['Status'] == 'new'"  [ngClass]="{'max-exceed': expense.amount > expense.max_claim_amount}" class="expense-label"
                                   style="font-weight: 600;width:70px;" [(ngModel)]="expense.amount"
                            >
                            <label *ngIf="expenseData['Status'] != 'new'" [ngClass]="{'max-exceed': expense.amount > expense.max_claim_amount}" class="expense-label"
                                   style="font-weight: 600;"
                            >{{expense.amount}}</label>
                          </div>
                          <div *ngIf="expense.exp_type != 'food' && expense.exp_type != 'others'" class="circle" style="top: 2px;"></div>
                          <div class="circle" style="left: 208px;top:-5px"></div>
                          <div style="position: relative;
                                  left: 220px;
                                  top: -21px;
                                  width: 125px;
                                  display: inline-block;">
                            <label class="expense-label1" style="white-space: nowrap;
                              ">Max: {{expense.max_claim_amount}}</label>
                          </div>
                          <div *ngIf="expense.exp_type == 'travel'" style="position: relative;
                            left: 40px;
                            top: -46px;
                            width: 125px;margin-bottom: -30px;">
                            <label class="expense-label1">{{display_name('travel_'+expense.travel_type)}}</label>
                          </div>
                          <div *ngIf="expense.exp_type == 'travel'" class="circle" style="left: 208px;"></div>
                          <div *ngIf="expense.exp_type == 'travel'" style="position: relative;
                                                    left: 220px;top:-13px;
                                                    width: 125px;">
                            <label class="expense-label1">{{expense.distance}} Km</label>
                          </div>
                          <div *ngIf="expense.exp_type == 'stay'" style="position: relative;
                            left: 40px;
                            top: -46px;
                            width: 125px;">
                            <label class="expense-label1">{{display_name('stay_'+expense.stay_type)}}</label>
                          </div>
                          <div *ngIf="expense.exp_type == 'allowance'" style="position: relative;
                            left: 40px;
                            top: -46px;
                            width: 125px;">
                            <label class="expense-label1">{{display_name(expense.allowance_type)}}</label>
                          </div>
                          <div *ngIf="expense.exp_type != 'food' && expense.exp_type != 'others'" class="circle" style="top: -29px;"></div>
                          <div *ngIf="expense.exp_type == 'food' || expense.exp_type == 'others'" class="circle" style="top: -35px;"></div>
                          <div *ngIf="expense.exp_type == 'travel'" style="position: relative;
                                  left: 40px;
                                  top: -46px;
                                  width: 125px;">
                            <label class="expense-label1">{{expense.name}}</label>
                          </div>
                          <div *ngIf="expense.exp_type != 'food' && expense.exp_type != 'others' && expense.exp_type != 'travel'" style="position: relative;
                                  left: 40px;
                                  top: -46px;
                                  width: 125px;">
                            <label class="expense-label1">{{expense.name}}</label>
                          </div>
                          <div *ngIf="expense.exp_type == 'food' || expense.exp_type == 'others'" style="position: relative;
                                  left: 40px;
                                  top: -52px;
                                  width: 125px;">
                            <label class="expense-label1">{{expense.name}}</label>
                          </div>
                          <div style="position: relative;left: 25px;top: -40px;
                            border-bottom: solid 1px #0762AD;
                            width: fit-content;">
                            <label style="font-size: 130%;font-weight: 400;color: '#0762AD';">Show More</label>
                          </div>
                          <div>
                            <img style="width: 8%;
                                  top: -30px;
                                  left: 25px;
                                  position: relative;" src="../../../assets/check_circle_grey_192x192.png"/>
                            <p style="position: relative;
                                  top: -47px;
                                  left: 45px;
                                  font-size: 140%;
                                  color: black;
                                  font-weight: 400;">Review Status : </p>
                            <label [ngClass]="{'color-green': expense.app1_state === 'approved',
                           'color-red': expense.app1_state === 'rejected',
                           'color-blue': expense.app1_state === 'new',
                           'color-grey': expense.app1_state === 'settled',
                           'color-orange': expense.app1_state === 'pending'}"
                                   style="font-size: 140%;font-weight: 400;font-size: 140%;
                                      font-weight: 400;
                                      position: relative;
                                      top: -78px;
                                      left: 145px;">{{to_upperCase(expense.app1_state)}}</label>
                          </div>
                          <div style="position: relative;
                                  top: -110px;
                                  left: 60px;">
                            <a *ngIf="expense.state == 'new' || expense.state === 'processing'" (click)="rejectSales(expense)" style="margin-left: 85%;color: red !important;
                      border: 1px solid red;padding: 6px 15px;" class="btn white-btn">Reject</a>
                          </div>
                        </div>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                      <div style="display: inline-block;">
                        <h1 style="font-size: 160%;">{{expense.name}}</h1>
                      </div>
                    </div>
                    <div style="margin-top:3%;">
                      <div style="display: inline-block;
                       vertical-align: top;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">SalesPerson Details</label>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Salesperson: </label><span style="margin-left: 3%">{{expense.salesman}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Employee Code: </label><span style="margin-left: 3%">{{expense.emp_code}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Team: </label><span style="margin-left: 3%">{{expense.team}}</span>
                        </div>
                      </div>
                      <div style="margin-left: 25%;display: inline-block;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Approver Details</label>
                        <div style="margin-top:3%;">
                          <div>
                            <label style="font-weight: 600;">Approver 1: </label><span style="margin-left: 3%">{{expense.appr1}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Approver1 Status: </label><span style="margin-left: 3%">{{expense.app1_state}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Approver 2: </label><span style="margin-left: 3%">{{expense.appr2}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Approver2 Status: </label><span>{{expense.app2_state}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Approved Amount: </label><span style="margin-left: 3%">{{expense.app_amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top:3%;">
                      <div style="display: inline-block;
                       vertical-align: top;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Expense Details</label>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Expense Date: </label><span style="margin-left: 3%">{{expense.expense_date}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Submitted Date: </label><span>{{expense.submitted_date}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Expense Policy: </label><span style="margin-left: 3%">{{expense.policy_name}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Expense Policy Line: </label><span style="margin-left: 3%">{{expense.name}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Expense Type: </label><span style="margin-left: 3%">{{expense.exp_type}}</span>
                        </div>
                        <div style="margin-top:3%;">
                          <label style="font-weight: 600;">Policy Claim Amount: </label><span style="margin-left: 3%">{{expense.policy_claim_amount}}</span>
                        </div>
                      </div>
                      <div *ngIf="expense.exp_type == 'travel' || expense.exp_type == 'stay'" style="margin-left: 17%;display: inline-block;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Travel/Stay</label>
                        <div style="margin-top:3%;">
                          <div>
                            <label style="font-weight: 600;">Travel Type: </label><span style="margin-left: 3%">{{expense.travel_type}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Zone: </label><span style="margin-left: 3%">{{expense.zone}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Area: </label><span>{{expense.area}}</span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Destination Zone: </label><span></span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Destination Area: </label><span style="margin-left: 3%"></span>
                          </div>
                          <div style="margin-top:3%;">
                            <label style="font-weight: 600;">Distance: </label><span style="margin-left: 3%">{{expense.distance}}</span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="expense.exp_type == 'allowance'" style="margin-left: 17%;display: inline-block;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Allowance</label>
                        <div style="margin-top:3%;">
                          <div>
                            <label style="font-weight: 600;">Allowance Type: </label><span>{{expense.allowance_type}}</span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="expense.exp_type == 'food'" style="margin-left: 17%;display: inline-block;">
                        <label style="font-size: 140%;
                                color: gray;
                                font-weight: 600;">Food</label>
                        <div style="margin-top:3%;">
                          <div>
                            <label style="font-weight: 600;">Bill Uploaded: </label><span>{{expense.bill_uploaded}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <mat-tab-group dynamicHeight style="margin-top: 2%;">
                      <mat-tab>
                        <ng-template mat-tab-label>
                          <span style="font-weight: bolder;color: black;padding-left: 4%;">Gallery</span>
                        </ng-template>
                        <div>
                          <div style="margin: 10px;display: inline;" *ngFor="let photo of expense['gallery']">
                            <img (click)="view_gallery(photo)" style="width: 20%;" [src]="photo"/>
                          </div>
                        </div>
                      </mat-tab>
                    </mat-tab-group>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <span style="font-weight: bolder;color: black;padding-left: 4%;">TRACK</span>
              </ng-template>
              <div *ngIf="mapHide">
                <agm-map #AgmMap [mapTypeControl]="true" (mapReady)="foo($event)">
                  <agm-marker *ngIf="map_loader" [iconUrl]="'../../assets/images/loader_icon.gif'" [latitude]="0" [longitude]="0">
                  </agm-marker>
                  <agm-marker  *ngFor="let marker of activity"
                               [latitude]="marker.lat"
                               [longitude]="marker.lng"
                               [iconUrl]="icon_url"
                  >
                    <agm-info-window
                      [latitude]="marker.lat"
                      [longitude]="marker.lng">
                      <span style="font-size: large;float: left"><b>Retailer:</b> {{marker.retailer}}</span><br/><br/>
                      <span style="float: left"><b>Actions:</b> {{marker.activity}}</span><br/>
                      <span style="float: left"><b>Device Time:</b> {{marker.device_time}}</span><br/>
                      <!--<span style="float: left"><b>Comments:</b> {{marker.comments}}</span>-->
                    </agm-info-window>
                  </agm-marker>
                  <agm-marker *ngIf="is_act"
                              [iconUrl]="start_url"
                              [latitude]="start_act.lat"
                              [longitude]="start_act.lng"
                  >
                    <agm-info-window
                      [latitude]="start_act.lat"
                      [longitude]="start_act.lng">
                      <span style="font-size: large;float: left"><b>Retailer:</b> {{start_act.retailer}}</span><br/><br/>
                      <span style="float: left"><b>Actions:</b> {{start_act.activity}}</span><br/>
                      <span style="float: left"><b>Device Time:</b> {{start_act.device_time}}</span><br/>
                      <!--<span style="float: left"><b>Comments:</b> {{start_act.comments}}</span>-->
                    </agm-info-window>
                  </agm-marker>
                  <agm-marker *ngIf="is_act"
                              [iconUrl]="end_url"
                              [latitude]="end_act.lat"
                              [longitude]="end_act.lng"
                  >
                    <agm-info-window
                      [latitude]="end_act.lat"
                      [longitude]="end_act.lng">
                      <span style="font-size: large;float: left"><b>Retailer:</b> {{end_act.retailer}}</span><br/><br/>
                      <span style="float: left"><b>Actions:</b> {{end_act.activity}}</span><br/>
                      <span style="float: left"><b>Device Time:</b> {{end_act.device_time}}</span><br/>
                      <!--<span style="float: left"><b>Comments:</b> {{end_act.comments}}</span>-->
                    </agm-info-window>
                  </agm-marker>
                  <agm-polyline  [strokeColor]="'#2196f3'">
                    <ng-container *ngFor="let log of track">
                      <agm-polyline-point [latitude]="log.lat" [longitude]="log.lng">
                      </agm-polyline-point>
                    </ng-container>
                  </agm-polyline>
                </agm-map>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span style="font-weight: bolder;color: black;padding-left: 4%;">ACTIVITY</span>
              </ng-template>
              <div class="eod-table">
                <div>
                  <ag-grid-angular id="myGrid1" *ngIf="loaded1" style="width: 100%;height:35vh;" class="ag-theme-balham"
                                   [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"  (gridReady)="onGridReady1($event)">
                  </ag-grid-angular>
                  <div *ngIf="preloader1">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state1">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<div #galleryModal class="pol-modal pol-send invoice-img" id="galleryModal" [hidden]="isModelOpened">
  <div class="modal-dialog" style="z-index: 10000000000;">
    <!-- Modal content-->
    <div class="modal-content" style="width:70%;margin-left: 10%;margin-top: -40%;">
        <span class="close" (click)="close()">
          <img src="../../../assets/close.png">
        </span>
      <div class="modal-body">
        <img style="width: 100%;" [src]="photo">
      </div>
    </div>
  </div>
</div>

<div #policyModal class="pol-modal pol-send invoice-img modal" id="policyModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" style="width:70%;margin-left: 10%;">
        <span class="close" (click)="close()" data-dismiss="modal">
          <img src="../../../assets/close.png">
        </span>
      <div class="modal-body">
        <div [innerHTML]="policy_html"></div>
      </div>
    </div>
  </div>
</div>

<button #galleryClick id="openImage2" style="margin-left: -14%;" [hidden]="true" data-toggle="modal" data-target="#policyModal">Open Modal</button>
<button #imageClick id="openImage1" style="margin-left: -14%;" [hidden]="true" data-toggle="modal" data-target="#approveModel">Open Modal</button>
<!--<button #detailClick id="openDetail" style="margin-left: -14%;" [hidden]="true" data-toggle="modal" data-target="#detailModel">
Open Modal</button>-->
<button #detailClick id="openDetail" style="margin-left: -14%;" [hidden]="true" (click)="openDetailDialog()">Open Modal</button>

<div #commentsModel class="approve-modal modal" id="commentsModel" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">

        <div class="approve-modal-heading">
          <label>Payment Reference</label>
        </div>
        <div class="col-md-12 pl-0 pr-5" >
          <input  class="forminput" type="text" name="pay_ref" [(ngModel)]="user_data.pay_ref" #pay_ref="ngModel" required>
          <span class="highlight"></span>
          <span class="bar"></span>
        </div>

        <div style="margin-top: 20px;">
          <button style="margin-top: 20px" (click)="settle()" data-dismiss="modal" class="btn blue-btn">{{commentstate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

