import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {interval} from 'rxjs';

@Injectable()
export class UpdateService {
  /* constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar) {
     this.swUpdate.available.subscribe(evt => {
       const snack = this.snackbar.open('Update Available', 'Reload');
       snack
         .onAction()
         .subscribe(() => {
           window.location.reload();
         });
       snack.setTimeout(() => {
         snack.dismiss();
       }, 6000);
     });*/
  snackBar;

  constructor(public updates: SwUpdate) {
   // this.snackBar = snackbar;
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('checking for updates')));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    console.log('updating to latest version');
    this.updates.activateUpdate().then(() => {
      /*const snack = this.snackBar.open('Update Available', 'Reload');
      snack
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });*/
      window.location.reload();
      console.log('updated to latest version');
    });
   /* const snack = this.snackBar.open('Update Available', 'Reload');
    snack
      .onAction()
      .subscribe(() => {
        window.location.reload();
      });*/
  }
}
