import {Component} from "@angular/core";
import {IHeaderAngularComp} from "@ag-grid-community/angular";
import {ApiService} from './_services/api.service' ;
import {SharedService} from './_services/shared_service';
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'outs-show',
  template: `<span>{{value}}<img (click)="toLedger()" style="width: 5%;
    margin-left: 1%;
    height: 10px;" src="./../assets/info-icon.svg"></span>`,
  styles: []
})
export class OutstandingShowComponent implements IHeaderAngularComp {
  public params: any;
  public value = 0;

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.outstanding) {
      this.value = this.params.data.outstanding;
    } else {
      this.value = this.params.data['OUTSTANDING'];
    }
  }

  refresh(): boolean {
    return false;
  }

  toLedger() {
    this.params.context.componentParent.toLedger(this.params.data);
  }
}
