import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-user-alert-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatPlanningSalesComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  // showCustomFilter = true;
  // customFilter: any = {
  //   name: 'Active',
  //   key: 'active',
  //   value: 'true',
  //   values: [],
  //   show: true
  // };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Planning and Sales Report');

    // this.showableFilters.cluster = true;
    // this.showableFilters.territory = true;
    // this.showableFilters.region = true;
    // this.showableFilters.salesman = true;
    this.showDate = true;
    // this.customFilter.values = [
    //   {id: true, itemName: 'Active'},
    //   {id: false, itemName: 'Inactive'}
    // ];

    this.loadInBatches(5000, 'bpr_id');
    this.showProgressCustomText = true;

    this.API_URL = '/api/pwa_v2_reports/getBeatPlanningSales';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Beat",
        children: [
          {headerName: 'BEAT ID', field: 'beat_id', width: 80},
          {headerName: 'BEAT NAME', field: 'beat_name', enableRowGroup: true, width: 80},
          {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
          {headerName: 'EMP CODE', field: 'emp_code', width: 100},
          {headerName: 'DISTRIBUTOR', field: 'dist_name', enableRowGroup: true, width: 100},
          {headerName: 'TERRITORY ID', field: 'territory_id', enableRowGroup: true, width: 100},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 100}
        ]
      }, {
        headerName: "Partner Details",
        children: [
          {headerName: 'BPR ID', field: 'bpr_id', enableRowGroup: true, width: 80},
          {headerName: 'PARTNER ID', field: 'retailer_id', enableRowGroup: true, width: 80},
          {headerName: 'PARTNER NAME', field: 'outlet_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'outlet_code', width: 80},
          {headerName: 'PARTNER TYPE', field: 'partner_type', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 80},
          {headerName: 'CREATE DATE', field: 'outlet_create_date', enableRowGroup: true, width: 80},
          {headerName: 'BEAT LINK DATE', field: 'beat_link_date', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'outlet_class', enableRowGroup: true, width: 80},
          {headerName: 'CITY', field: 'outlet_city', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET ACTIVE', field: 'outlet_active', enableRowGroup: true, width: 80},
          {
            headerName: 'OUTLET SCHEDULE', field: 'outlet_active', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data && params.data.beat_link_date) {
                if (new Date(params.data.beat_link_date) < new Date(this.userData.start_date)) {
                  return 'PLANNED';
                } else {
                  return 'NEW';
                }
              } else {
                return 'NA';
              }
            }
          }
        ]
      }, {
        headerName: "Status",
        children: [
          {headerName: 'ATTENDANCE DAYS', field: 'attendnace_count', width: 80},
          {headerName: 'LAST ATTENDANCE', field: 'last_attendnace', width: 80},
          {headerName: 'BEAT VISIT PLANNED', field: 'planned_count', width: 80},
          {headerName: 'LAST PLANNED', field: 'last_planned', width: 80},
          {headerName: 'OUTLET VISITS', field: 'visit_count', width: 80},
          {headerName: 'LAST VISIT', field: 'last_visit', width: 80},
          {headerName: 'ORDER COUNT', field: 'order_count', width: 80},
          {headerName: 'ORDER AMOUNT', field: 'order_amt', width: 80},
          {headerName: 'LAST ORDER', field: 'last_order', width: 80}
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
