<div class="filterdropdown">
  <form (click)="stopPropagation($event)">
    <!-- Your content and inputs -->
    <div class="row" style="background-color: #F7FAFC; align-items: center;
  justify-content: center; border-bottom: 2px solid #f3f3f3;">
      <div class="col-lg-12" style="line-height: 50px;vertical-align: middle;">
        <span   (click)="clearFilters()" style="cursor:pointer;font-size: 12px;">Clear</span>
        <button class="btn teal-border-btn float-right" style=" justify-content: center;
  align-items: center;margin-top: 12px;padding: 5px 20px;" (click)="setFilter()">Done </button>
      </div>
    </div>
    <div *ngIf="false" class="searchfilter" style="display: inline-block;">
      <span class="search-control"><img src="assets/searchicon.png"/></span>
      <input  id="filter-text-box" type="text"
              placeholder="filter"
              class="search-control-area"
              (input)= "quickSearch()"
              placeholder="Search">
    </div>
    <div  *ngIf="showableFilters.suggestedfilters">
      <div style="margin:10px;">
        <span>Suggested Filters</span>
      </div>
      <div style=" border-bottom: 2px solid #f3f3f3;">
        <div style="margin: 10px;">
          <div  *ngIf="showableFilters.max_claim">
            <div class="row" style="margin-top: 5px;">
              <input
                type="checkbox"
                [checked]="selectedData.max_claim"
                name = "max_claim"
                [(ngModel)] = "selectedData.max_claim"
                #max_claim = ngModel
                style="width:10px;height:10px;display: inline-block; "
              />
              <p style="margin-left: 5px;margin-top:2px;"> Expenses exceeding Max Claim</p>
            </div>
          </div>
          <div  *ngIf="showableFilters.trax_outlet">
            <div class="row" style="margin: 5px;">
              <input
                type="checkbox"
                [checked]="selectedData.trax_outlet"
                name = "trax_outlet"
                [(ngModel)] = "selectedData.trax_outlet"
                #trax_outlet = ngModel
                style="width:10px;height:10px;display: inline-block; "
              />
              <p style="margin-left: 5px;margin-top:2px;"> Trax Outlet Only</p>
            </div>
          </div>
          <div  *ngIf="showableFilters.non_trax_outlet">
            <div class="row" style="margin: 5px;">
              <input
                type="checkbox"
                [checked]="selectedData.non_trax_outlet"
                name = "non_trax_outlet"
                [(ngModel)] = "selectedData.non_trax_outlet"
                #non_trax_outlet = ngModel
                style="width:10px;height:10px;display: inline-block; "
              />
              <p style="margin-left: 5px;margin-top:2px;">Non Trax Outlet Only</p>
            </div>
          </div>
          <div  *ngIf="showableFilters.exclude_trax_audit">
            <div class="row" style="margin: 5px;">
              <input
                type="checkbox"
                [checked]="selectedData.exclude_trax_audit"
                name = "exclude_trax_audit"
                [(ngModel)] = "selectedData.exclude_trax_audit"
                #exclude_trax_audit = ngModel
                style="width:10px;height:10px;display: inline-block; "
              />
              <p style="margin-left: 5px;margin-top:2px;">Exclude Trax Audit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.policy">
      <div style="margin:10px;">
        <div (click)="showPolicy=!showPolicy">
          <span>Policy</span>
          <span *ngIf="selectedData.policyID!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showPolicy" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showPolicy" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showPolicy" style="margin-top: 10px;" class="formselect">
          <select name="policyID" id=""
                  [(ngModel)]="selectedData.policyID"
                  #policyID="ngModel">
            <option
              *ngFor="let item of filterData.policy"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.beat">
      <div style="margin:10px;">
        <div (click)="showBeat=!showBeat" >
          <span>Beat</span>
          <span *ngIf="selectedData.travel_type_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showBeat" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showBeat" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showBeat">

          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="radio-group"
            name="travel_type"
            [(ngModel)] = "selectedData.travel_type_id"
            #travel_type="ngModel">
            <mat-radio-button class="radio-button" *ngFor="let radioBut of filterData.beatTravelTypes" [value]="radioBut.id">
              {{radioBut.name}}
            </mat-radio-button>
          </mat-radio-group>

        </div>
      </div>
    </div >
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.region">
      <div style="margin:10px;">
        <div (click)="showRegion=!showRegion">
           <!-- <span style="margin-right:5px;display:inline" >
                 <i class="far fa-angle-right fa-2x" ></i>
             </span>-->
          <span>Region</span>
          <span *ngIf="selectedData.region_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showRegion" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showRegion" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showRegion" style="margin-top: 10px;" class="formselect">
          <select name="policyID" id=""
                  [(ngModel)]="selectedData.region_id"
                  name = "region_id"
                  #region_id="ngModel"
                  (change)="onRegionChange()"
          >
            <option
              *ngFor="let item of filterData.region"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.state">
      <div style="margin:10px;">
        <div (click)="showState=!showState">
          <span>State</span>
          <span *ngIf="selectedData.state_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showState" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showState" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showState" style="margin-top: 10px;" class="formselect">
          <select
            [(ngModel)]="selectedData.state_id"
            name = "state_id"
            #state_id="ngModel"
          >
            <option
              *ngFor="let item of filterData.state"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.cluster">
      <div style="margin:10px;">
        <div (click)="showCluster=!showCluster">
          <span>Cluster</span>
          <span *ngIf="selectedData.cluster_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showCluster" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showCluster" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showCluster" style="margin-top: 10px;" class="formselect">
          <select name="policyID" id=""
                  [(ngModel)]="selectedData.cluster_id"
                  name = "cluster_id"
                  #cluster_id="ngModel"
                  (change)="onClusterChange()"
          >
            <option
              *ngFor="let item of filterData.cluster"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.territory">
      <div style="margin:10px;">
        <div (click)="showTerritory=!showTerritory">
          <span>Territory</span>
          <span *ngIf="selectedData.territory_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showTerritory" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showTerritory" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showTerritory" style="margin-top: 10px;" class="formselect">
          <select
            [(ngModel)]="selectedData.territory_id"
            name = "territory_id"
            #territory_id="ngModel"
          >
            <option
              *ngFor="let item of filterData.territory"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.team">
      <div style="margin:10px;">
        <div (click)="showTeam=!showTeam">
          <span>Team</span>
          <span *ngIf="selectedData.team_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showTeam" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showTeam" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showTeam" style="margin-top: 10px;" class="formselect">
          <select name="teamID" id=""
                  [(ngModel)]="selectedData.team_id"
                  #teamID="ngModel"
                  (change)="onTeamChange()"
          >
            <option
              *ngFor="let item of filterData.team"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.salesman">
      <div style="margin:10px;">
        <div (click)="showSalesman=!showSalesman">
          <span>Salesman</span>
          <span *ngIf="selectedData.salesman_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showSalesman" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showSalesman" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showSalesman" style="margin-top: 10px;" class="formselect">
          <select name="salesmanID"
                  id="salesmanID"
                  [(ngModel)]="selectedData.salesman_id"
                  #salesmanID="ngModel">
            <option
              *ngFor="let item of filterData.salesman"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.class">
      <div style="margin:10px;">
        <div (click)="showClass=!showClass">
          <span>Class</span>
          <span *ngIf="selectedData.class_type!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showClass" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showClass" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showClass">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="radio-group"
            name="class_type"
            [(ngModel)] = "selectedData.class_type"
            #class_type="ngModel">
            <mat-radio-button class="radio-button" value="a">
              A
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="b">
              B
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="c">
              C
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="d">
              D
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.dc">
      <div style="margin:10px;">
        <div (click)="showDC=!showDC">
          <span>DC</span>
          <span *ngIf="selectedData.dc_id!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showDC" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showDC" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showDC" style="margin-top: 10px;" class="formselect">
          <select
            id=""
            [(ngModel)]="selectedData.dc_id"
            name = "dc_id"
            #dc_id="ngModel">
            <option
              *ngFor="let item of filterData.dc"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.outlet_type">
      <div style="margin:10px;">
        <div (click)="showOutletType=!showOutletType">
          <span>Outlet Type</span>
          <span *ngIf="selectedData.outlet_type!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showOutletType" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showOutletType" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showOutletType" style="margin-top: 10px;" class="formselect">
          <select name="outletType"
                  id=""
                  [(ngModel)]="selectedData.outlet_type"
                  #outletType="ngModel">
            <option
              *ngFor="let item of filterData.outlet_type"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.program_type">
      <div style="margin:10px;">
        <div (click)="showProgramType=!showProgramType">
          <span>Program Type</span>
          <span *ngIf="selectedData.program_type!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showProgramType" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showProgramType" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showProgramType" style="margin-top: 10px;" class="formselect">
          <select name="program_type"
                  id=""
                  [(ngModel)]="selectedData.program_type"
                  #program_type="ngModel">
            <option
              *ngFor="let item of filterData.program_type"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.payment_mode">
      <div style="margin:10px;">
        <div (click)="showPaymentMode=!showPaymentMode">
          <span>Payment Mode</span>
          <span *ngIf="selectedData.program_type!=''" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showPaymentMode" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px" />
                            </span>
          <span *ngIf="!showPaymentMode" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showPaymentMode" style="margin-top: 10px;" class="formselect">
          <select name="payment_mode"
                  id="payment_mode"
                  [(ngModel)]="selectedData.payment_mode"
                  #payment_mode="ngModel">
            <option
              *ngFor="let item of filterData.payment_mode"
              value="{{ item.id }}"
            > {{ item.itemName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.status">
      <div style="margin:10px;">
        <div (click)="showStatus=!showStatus">
          <span>Status</span>
          <span *ngIf="selectedData.status!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showStatus" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px"/>
                            </span>
          <span *ngIf="!showStatus" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showStatus" style="margin-top: 10px;" class="formselect">
          <select name="status"
                  id="status"
                  [(ngModel)]="selectedData.status"
                  #status="ngModel">
            <option
              *ngFor="let item of filterData.status"
              value="{{ item.id }}"
            > {{ item.itemname }}</option>
          </select>
        </div>
        <!--  <div *ngIf="showStatus">

            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="radio-group"
              name="status"
              [(ngModel)] = "selectedData.status"
              #status="ngModel">
              <mat-radio-button class="radio-button" value="new">
                New
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="pending">
                Pending
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="approved">
                Approved
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="settled">
                Settled
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="rejected">
                Rejected
              </mat-radio-button>
            </mat-radio-group>
          </div>-->
      </div>
    </div>
    <div style=" border-bottom: 2px solid #f3f3f3;" *ngIf="showableFilters.active">
      <div style="margin:10px;">
        <div (click)="showActive=!showActive">
          <span>Active</span>
          <span *ngIf="selectedData.status!=0" style="margin-left:5px;display:inline;height:12px;width:12px;" >
                <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
          <span *ngIf="showActive" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="10px"/>
                            </span>
          <span *ngIf="!showActive" style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="10px" />
                            </span>
        </div>
        <div *ngIf="showActive" style="margin-top: 10px;" class="formselect">
          <select name="active"
                  id="active"
                  [(ngModel)]="selectedData.active"
                  #active="ngModel">
            <option
              *ngFor="let item of filterData.active"
              value="{{ item.value }}"
            > {{ item.name }}</option>
          </select>
        </div>
        <!--  <div *ngIf="showStatus">

            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="radio-group"
              name="status"
              [(ngModel)] = "selectedData.status"
              #status="ngModel">
              <mat-radio-button class="radio-button" value="new">
                New
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="pending">
                Pending
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="approved">
                Approved
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="settled">
                Settled
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="rejected">
                Rejected
              </mat-radio-button>
            </mat-radio-group>
          </div>-->
      </div>
    </div>
  </form>
</div>

