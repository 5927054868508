<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div *ngFor="let survey of usersurveydata; let i = index" class="col-md-3 pr-20 eod-width" >
        <app-survey_card [title]="survey.name"
                         [id]="survey.id"
                         [code]="survey.code"
                         [type]="survey.code"
                         (reportClick)="reportClick()"
                         (listClick)="listClick()">

        </app-survey_card>
      </div>
    </div>
  </div>
</div>
