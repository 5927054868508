import {Component, ElementRef, Inject, Injector, OnInit, Renderer2} from '@angular/core';
import {ApiService} from '../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {environment} from '../../environments/environment';
import {OktaAuthService} from '@okta/okta-angular';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {trace} from 'firebase/performance';
import {logEvent, setUserProperties} from 'firebase/analytics';
import {setUserId} from '@firebase/analytics';
import {SharedService} from '../_services/shared_service';
import {LAZY_MAPS_API_CONFIG} from '@agm/core';
import {LocalforageService} from "../_services/localforage.service";
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler, BasicQueryStringUtils,
  DefaultCrypto,
  GRANT_TYPE_AUTHORIZATION_CODE,
  LocalStorageBackend,
  RedirectRequestHandler,
  StringMap,
  TokenRequest
} from "@openid/appauth";

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parse(input, useHash) {
    return super.parse(input, false);
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
// tslint:disable-next-line:component-class-suffix



export class LoginComponent implements OnInit {


  role = '';
  refresh_token;
  // public url;
  public currentApplicationVersion = environment.appVersion;
  company_name = 'assets/images/sd-logo.png';
  company_bg = 'assets/images/sales.png';
  company_data = {};
  device_info = null;
  result;
  instance;
  company_key_input: string;
  username: string;
  password: string;
  phone: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  userdata = {
    instance: '',
    ckey: '',
    url: '',
    token: null,
    login_type: null,
    user_name: null,
    password: null,
    phone: null,
    otp: null,
    device_name: '',
    device_info: null,
    pwa_version: 0,
    access_token: null,
    sso_name: '',
    user: null
  };
  public error_msg = '';
  public web_module_ids;
  public loader = false;
  public subdomain: string;
  public login_mobileotp_show = false;
  public login_usrpwd_show = false;
  public login_credentials_show = true;
  public login_okta_show = false;
  public login_otp_show = false;
  public login_options_show = true;
  public prgs_msg = '';
  private oktaToken: any;
  public instanceUrls: any = [];
  public ssoEnabled = false;
  public ssoConfigs: any = [];
  notifier;
  authorizationHandler;
  code;
  ssoConfiguration;
  tokenHandler;
  code_verifier;

  //googleMapsConfig;
  private tokenResponse: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private apiService: ApiService,
    private router: Router,
    public route: ActivatedRoute,
    public oktaAuth: OktaAuthService,
    private injector: Injector,
    private http: HttpClient,
    public toastr: ToastrService,
    public sharedService: SharedService,
    private _localforageService: LocalforageService,
    //private config: LazyMapsAPILoaderConfigLiteral,
    @Inject(LAZY_MAPS_API_CONFIG) public lmac,
    private deviceService: DeviceDetectorService) {
    this.device_info = this.deviceService.getDeviceInfo();
    console.log(this.device_info, this.deviceService.isDesktop());
    //this.googleMapsConfig = config;


    this.ssocheck();
  }

  async ssocheck() {
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
// set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
// set a listener to listen for authorization responses
    this.notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        this.code = response.code;
      }
      if (request && request.hasOwnProperty('internal') && request.internal && request.internal.hasOwnProperty('code_verifier')) {
        this.code_verifier = request.internal.code_verifier;
      }
    });
    await this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }

  getSubdomain() {

    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'app' || domain.split('.')[0] === '0' || domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === 'app1' || domain.split('.')[0] === 'pwauat'
      || domain.split('.')[0] === 'asplpwa' || domain.split('.')[0] === 'vajra' || domain.split('.')[0] === 'dms') {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    console.log(this.subdomain);
  }

  redirectToDefaultPage() {
    // if (localStorage.getItem('board_gt_manager') === 'true' || localStorage.getItem('board_mt_manager') === 'true' || localStorage.getItem('board_diageo_manager') === 'true') {
    if (this.route.snapshot.queryParamMap.has('route') && this.route.snapshot.queryParamMap.has('access_token')) {
      this.router.navigate([this.apiService.redirect_url + this.route.snapshot.queryParamMap.get('route')]);
    } else {
      if (this.sharedService.role_features) {
        if (this.sharedService.ACLcheck('feature_custom_dashboard')) {
          this.router.navigate([this.apiService.redirect_url + '/dash']);
        } else {
          this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
        }
      } else {
        this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
      }
    }

    // } else {
    //   this.router.navigate([this.apiService.redirect_url + '/dashboard']);
    // }
  }



  ngOnInit() {

    // this.getSubdomain();
    //debugger;
    this.refresh_token = localStorage.getItem('resfreshToken');
    this.subdomain = localStorage.getItem('company_key');
    if (this.route.snapshot.queryParamMap.has('route')
      && this.route.snapshot.queryParamMap.has('access_token')
      && this.route.snapshot.queryParamMap.has('company_key')) {
      this.userdata.token = this.route.snapshot.queryParamMap.get('access_token');
      this.userdata.access_token = this.route.snapshot.queryParamMap.get('access_token');
      this.fetchCompanyDetails(this.route.snapshot.queryParamMap.get('company_key'));
    } else if (this.subdomain && this.route.snapshot.queryParamMap.has('access_token')) {
      this.userdata.token = this.route.snapshot.queryParamMap.get('access_token');
      this.userdata.access_token = this.route.snapshot.queryParamMap.get('access_token');
      this.fetchCompanyDetails(this.subdomain);
    } else if (this.subdomain && this.route.snapshot.queryParamMap.has('code')) {
      if (this.route.snapshot.queryParamMap.get('company_key')) {
        this.subdomain = this.route.snapshot.queryParamMap.get('company_key');
      }
      this.fetchCompanyDetails(this.subdomain);
    } else {
      if (this.route.snapshot.queryParamMap.has('companyKey')) {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
        this.subdomain = this.route.snapshot.queryParamMap.get('companyKey');
        localStorage.setItem('company_key', this.subdomain);
        // this.refresh_token = null;
      } else {
        this.route.fragment.subscribe(fragment => {
          this.userdata.token = new URLSearchParams(fragment).get('code');
          //this.userdata.login_type = 'okta_pwa';
          if (new URLSearchParams(fragment).get('company_key')) {
            this.subdomain = new URLSearchParams(fragment).get('company_key');
          }
          if (this.userdata.token) {
            this.refresh_token = null;
          } else {
            this.userdata.token = new URLSearchParams(fragment).get('access_token');
            if (this.userdata.token) {
              this.userdata.login_type = 'okta_pwa';
              this.refresh_token = null;
            }
          }
          console.log(this.userdata.token);
        });
      }
      this.showLoginButtons();
      // this.url = localStorage.getItem('url');

      if ((this.refresh_token != null) && (this.refresh_token !== 'null')) {
        this.redirectToDefaultPage();
        // this.router.navigate([this.apiService.redirect_url + '/dashboard']);
      } else {

        // this.url = window.location.href.split('/')[3];  // local
        if (this.subdomain !== '') {
          this.fetchCompanyDetails(this.subdomain);
        }
      }
    }
  }

  fetchCompanyDetails(subdomain) {
    // this.company_data = {companyKey: compKey};
    this.subdomain = subdomain;
    this.userdata.instance = this.subdomain;
    this.apiService.findCompanyInstance({companyKey: this.subdomain})
      .subscribe(async res => {
          //debugger;
          if (res.hasOwnProperty('success')) {
            if (res.success.logo_url != null) {
              this.company_name = res.success.logo_url;
              localStorage.setItem('company_logo', this.company_name);
            }

            if (res.success.background_url != null) {
              this.company_bg = res.success.background_url;
            }
            this.userdata.instance = this.subdomain;
            this.userdata.ckey = this.subdomain;
            // res.success.url = 'http://localhost:3000';
            this.userdata.url = res.success.url;
            localStorage.setItem('private_url', res.success.url);
            if (res.success.hasOwnProperty('config') && res.success.config != null) {
              if (res.success.config.hasOwnProperty('login') && res.success.config.login != null) {
                localStorage.setItem('logindata', JSON.stringify(res.success.config.login));
                if (res.success.config.login['sso_pwa'] && res.success.config.hasOwnProperty('sso') && res.success.config['sso'] != null) {
                  this.ssoEnabled = true;
                  this.ssoConfigs = res.success.config.sso.filter(x => x.client === 'sso_pwa');
                } else {
                  this.ssoEnabled = false;
                }
              }
              if (res.success.config.hasOwnProperty('auth_provider') && res.success.config.auth_provider != null &&
                res.success.config.auth_provider.okta_pwa.domain != null && res.success.config.auth_provider.okta_pwa.clientId !== null) {
                // localStorage.setItem('domain',res.success.config.auth_provider.okta_pwa.domain);
                // localStorage.setItem('clientid',res.success.config.auth_provider.okta_pwa.clientid);
                environment.oktaConfig.issuer = res.success.config.auth_provider.okta_pwa.domain + '/oauth2/default';
                environment.oktaConfig.clientId = res.success.config.auth_provider.okta_pwa.clientid;
              }
            }

            localStorage.setItem('company_key', this.subdomain);
            if (this.route.snapshot.queryParamMap.has('route') && this.route.snapshot.queryParamMap.has('access_token')) {
              this.reAuthenticateUser();
            } else {
              if (this.userdata.token &&  this.userdata.login_type === 'okta_pwa') {
                this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
                this.http.get(environment.oktaConfig.issuer + '/v1/userinfo', {
                  headers: {
                    Authorization: 'Bearer ' + this.userdata.token,
                  }
                }).subscribe((data: any) => {
                  // Use the data returned by the API
                  console.log('user_data....', data);
                  this.userdata.user_name = data.preferred_username;
                  this.perform_login();
                }, (err) => {
                  console.error(err);
                });
              } else if (localStorage.getItem('sso_config') && this.route.snapshot.queryParamMap.has('code')) {
                this.ssoConfiguration = JSON.parse(localStorage.getItem('issuer_config'));
                const config: any = JSON.parse(localStorage.getItem('sso_config'));

                this.tokenHandler = new BaseTokenRequestHandler();

                //this.code = this.userdata.token;
                let request1: TokenRequest | null = null;
                let extras: StringMap | undefined = undefined;
                extras = {};
                extras['code_verifier'] = this.code_verifier;
                // use the code to make the token request.
                request1 = new TokenRequest({
                  client_id: config.clientid,
                  redirect_uri: config.redirect_url,
                  grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                  code: this.code,
                  refresh_token: undefined,
                  extras
                });
                this.tokenHandler.performTokenRequest(this.ssoConfiguration, request1)
                  .then(response => {
                    // ... do something with token response
                    console.log(response);
                    this.userdata.token = response.accessToken;
                    this.userdata.login_type = 'sso_pwa';
                    this.userdata.sso_name = config.name;
                    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
                    this.http.get(this.ssoConfiguration.userInfoEndpoint, {
                      headers: {
                        Authorization: 'Bearer ' + this.userdata.token,
                      }
                    }).subscribe((data: any) => {
                      // Use the data returned by the API
                      console.log('user_data....', data);
                      this.userdata.user_name = data.email;
                      this.perform_login();
                    }, (err) => {
                      console.error(err);
                    });
                  });
              } else {
                if (this.route.snapshot.queryParamMap.has('companyKey') &&
                  this.route.snapshot.queryParamMap.has('auth') && this.route.snapshot.queryParamMap.get('auth') === 'okta_pwa') {
                  this.login_okta_user();
                } else if (this.route.snapshot.queryParamMap.has('companyKey') &&
                  this.route.snapshot.queryParamMap.has('auth') && this.route.snapshot.queryParamMap.get('auth')) {
                  const ssotype = this.route.snapshot.queryParamMap.get('auth');
                  const foundconfig = this.ssoConfigs.find(x => x.type === ssotype);
                  if (foundconfig) {
                    this.login_sso(foundconfig);
                  }
                }
              }
            }
            this.showLoginButtons();
            localStorage.setItem('private_url', res.success.url);

          }
        },
        error => {
          console.log(error);
          this.company_name = 'assets/images/sd-logo.png';
          localStorage.setItem('company_logo', this.company_name);
        });
  }

  showLoginButtons() {
    if (localStorage.getItem('logindata') != null && localStorage.getItem('logindata') !== 'null') {
      const logindata = JSON.parse(localStorage.getItem('logindata'));
      if (logindata.hasOwnProperty('okta_pwa')) {
        this.login_okta_show = logindata.okta_pwa;
      }
      if (logindata.hasOwnProperty('user')) {
        this.login_credentials_show = logindata.user;
      }

      if (logindata.hasOwnProperty('user_otp')) {
        this.login_otp_show = logindata.user_otp;
      }

    }

    const domain = window.location.hostname;
    let subdomain = '';

    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'app' || domain.split('.')[0] === '0' || domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === 'app1' || domain.split('.')[0] === 'pwauat') {
      subdomain = '';
    } else {
      subdomain = domain.split('.')[0];
    }
    //subdomain = '';

    if (subdomain !== '' && this.login_credentials_show && !this.login_okta_show) {
      this.login_usrpwd_show = true;
      this.login_options_show = false;
    } else {
      this.login_usrpwd_show = false;
      this.login_options_show = true;
    }
  }

  async login_sso(config) {
    if (config.hasOwnProperty('issuer') && config.issuer && config.issuer !== "") {
      //fetch config
      localStorage.setItem('sso_config', JSON.stringify(config));
      await AuthorizationServiceConfiguration.fetchFromIssuer(config.issuer)
        .then(response => {
          console.log('Fetched service configuration', response);
          this.ssoConfiguration = response;
          localStorage.setItem('issuer_config', JSON.stringify(this.ssoConfiguration));
          console.log('Completed fetching configuration');
        })
        .catch(error => {
          console.log('Something bad happened', error);
          console.log(`Something bad happened ${error}`);
        });

      this.notifier = new AuthorizationNotifier();
// uses a redirect flow
      this.authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
// set notifier to deliver responses
      this.authorizationHandler.setAuthorizationNotifier(this.notifier);
// set a listener to listen for authorization responses
      // tslint:disable-next-line:no-shadowed-variable
      this.notifier.setAuthorizationListener((request, response, error) => {
        console.log('Authorization request complete ', request, response, error);
        if (response) {
          localStorage.setItem('sso_auth_req', JSON.stringify(request));
          localStorage.setItem('sso_auth_resp', JSON.stringify(response));
          this.code = response.code;
          console.log(`Authorization Code ${response.code}`);
        }
      });
// create a request
      const request = new AuthorizationRequest({
        client_id: config.clientid,
        redirect_uri: config.redirect_url,
        scope: config.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined,
        extras: {prompt: 'login', access_type: 'offline'}
      });

// make the authorization request
      this.authorizationHandler.performAuthorizationRequest(this.ssoConfiguration, request);

      /* this.tokenHandler = new BaseTokenRequestHandler();

       let request1: TokenRequest|null = null;

       if (this.code) {
         let extras: StringMap|undefined = undefined;
         /!*if (this.request && this.request.internal) {
           extras = {};
           extras['code_verifier'] = this.request.internal['code_verifier'];
         }*!/
         // use the code to make the token request.
         request1 = new TokenRequest({
           client_id: config.clientid,
           redirect_uri: config.redirect_url,
           grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
           code: this.code,
           refresh_token: undefined,
           extras
         });
       } else if (this.tokenResponse) {
         // use the token response to make a request for an access token
         request1 = new TokenRequest({
           client_id: config.clientid,
           redirect_uri: config.redirect_url,
           grant_type: GRANT_TYPE_REFRESH_TOKEN,
           code: undefined,
           refresh_token: this.tokenResponse.refreshToken,
           extras: undefined
         });
       }

       await this.tokenHandler.performTokenRequest(this.ssoEnabled, request1)
         .then(response => {
           // ... do something with token response
           console.log(response);
         });
 */
    }
  }

  async login_okta_user() {
    //Todo : add prompt for first time login, dont show if session active
    try {
      await this.oktaAuth.token.getWithRedirect({scopes: ['openid', 'email', 'profile']});
      //  await this.oktaAuth.token.getWithRedirect({ scopes: ['openid', 'email', 'profile'], prompt:"login consent" });
    } catch (err) {
      console.error(err);
    }
  }

  login_user() {

    this.loader = true;
    this.error_msg = '';
    this.userdata.user_name = this.username;
    this.userdata.password = this.password;
    this.userdata.phone = this.phone;
    this.userdata.otp = this.otp1 + this.otp2 + this.otp3 + this.otp4;
    this.userdata.device_info = {
      ip_address: '0.0.0.0',
      name: '12345678',
      browser: this.device_info.browser,
      os: this.device_info.os,
      device_token: JSON.parse(localStorage.getItem(this.userdata.ckey + '_token'))
    };
    if (this.subdomain !== '') {
      this.userdata.ckey = this.subdomain;
      this.userdata.instance = this.subdomain;
      this.perform_login();

    } else {

      this.apiService.findCompanyInstance({companyKey: this.company_key_input})
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('success')) {

              this.userdata.instance = this.company_key_input;
              this.userdata.ckey = this.company_key_input;
              //res.success.url = 'http://localhost:3000';
              this.userdata.url = res.success.url;
              localStorage.setItem('private_url', res.success.url);
              if (res.success.hasOwnProperty('config') && res.success.config != null) {
                if (res.success.config.hasOwnProperty('login') && res.success.config.login != null) {
                  localStorage.setItem('logindata', JSON.stringify(res.success.config.login));
                }
                if (res.success.config.hasOwnProperty('auth_provider') && res.success.config.auth_provider != null &&
                  res.success.config.auth_provider.okta_pwa.domain != null && res.success.config.auth_provider.okta_pwa.clientId !== null) {
                  // localStorage.setItem('domain',res.success.config.auth_provider.okta_pwa.domain);
                  // localStorage.setItem('clientid',res.success.config.auth_provider.okta_pwa.clientid);
                  environment.oktaConfig.issuer = res.success.config.auth_provider.okta_pwa.domain + '/oauth2/default';
                  environment.oktaConfig.clientId = res.success.config.auth_provider.okta_pwa.clientid;
                }

              }

              this.perform_login();
            }
          },
          error => {
            if (error.error.error === undefined) {
              this.error_msg = 'Connection Error';
            } else {
              console.log(error.error.error.message);
              this.error_msg = 'Invalid credentials';
            }
            this.loader = false;
          });

    }


  }

  logincredentialsClick() {

    this.login_usrpwd_show = true;
    this.login_options_show = false;

  }

  reAuthenticateUser() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.prgs_msg = 'Authenticating User...';
    const t = trace(this.sharedService.perf, 'login');
    t.start();
    t.putAttribute('companyKey', localStorage.getItem('company_key'));
    this.userdata.pwa_version = require('../../../package.json').version.split('.')[0];
    this.result = this.apiService.reAuthenticateUser(this.userdata);
    this.result.subscribe(res => {
        console.log(res);
        if (res !== null && res !== undefined && res.hasOwnProperty('result')) {
          if (res.result !== null && res.result !== undefined && res.result.hasOwnProperty('user')) {
            res.result.user.access_token = this.userdata.access_token;
          }
        }
        this.postLoginDataFetch(res, t);
      },
      error => {
        this.prgs_msg = '';
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        if (error.error.error === undefined) {
          this.error_msg = 'Connection Error';
        } else if (error.error.error.message.includes('upgrade_pwa')) {
          this.error_msg = 'Please upgrade : Press Ctrl+Shift+R';
        } else {
          this.error_msg = error.error.error.message;
        }
        this.toastr.error(this.error_msg);
        this.loader = false;
      });
  }

  perform_login() {
    /*  const perf = firebase.performance();
      const analytics = firebase.analytics();*/
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.prgs_msg = 'Authenticating User...';
    const t = trace(this.sharedService.perf, 'login');
    t.start();
    t.putAttribute('companyKey', localStorage.getItem('company_key'));
    this.userdata.pwa_version = require('../../../package.json').version.split('.')[0];
    this.result = this.apiService.authenticateUser(this.userdata);
    this.result.subscribe(res => {
        console.log(res);
        this.postLoginDataFetch(res, t);
        this.checkOtherInstances();
      },
      error => {
        this.prgs_msg = '';
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        if (error.error.error === undefined) {
          this.error_msg = 'Connection Error';
        } else if (error.error.error.message.includes('upgrade_pwa')) {
          this.error_msg = 'Please upgrade : Press Ctrl+Shift+R';
        } else {
          this.error_msg = error.error.error.message;
        }
        this.toastr.error(this.error_msg);
        this.loader = false;
      });
  }

  postLoginDataFetch(res, t) {
    let app_object: any = {};
    if (res.hasOwnProperty('result')) {

      this._localforageService.DELETE_ALL(); //Clear dashboard Cache
      try {
        localStorage.setItem('decimal_policy', res.result.app.decimal_policy);
        if (res.result.app.map_key) {
          localStorage.setItem('map_key', res.result.app.map_key);
        }
        this.apiService.decimalPolicy = parseInt(res.result.app.decimal_policy, 10);
        localStorage.setItem('resfreshToken', res.result.user.access_token);
        localStorage.setItem('token_time', new Date().getTime().toString());
        // localStorage.setItem('url', this.url);
        localStorage.setItem('user', JSON.stringify(res.result.user));
        app_object = res.result.app;
        localStorage.setItem('app', JSON.stringify(res.result.app));
        localStorage.setItem('role_features', JSON.stringify(res.result.user.role));
        localStorage.setItem(this.userdata.ckey + '_token', JSON.stringify(res.result.device.token));
        localStorage.setItem('logged_in', 'true');
        this.sharedService.setApiKey(localStorage.getItem('map_key'));
        this.sharedService.setRoleFeatures();

        const accessJSON = {};
        res.result.user.role.web_module_ids.forEach(feature => {
          accessJSON[feature.name] = true;
        });
        // this._localforageService.set('AccessControlLogic', aclList).then(r => {});
        this._localforageService.setAcessControl(accessJSON);
        this._localforageService.setAppConfig(res.result.app).then(r => {
        });

        //agmConfigFactory(this.lmac, this.sharedService);
        this.prgs_msg = 'Authenticated User...';
      } catch (e) {
        console.log('error');
        localStorage.removeItem(this.userdata.ckey + '_token');
        localStorage.setItem(this.userdata.ckey + '_token', JSON.stringify(res.result.device.token));
      }
      const userid = JSON.parse(localStorage.getItem('user')).cid + '_' + JSON.parse(localStorage.getItem('user')).id;
      setUserId(this.sharedService.analytics, userid);
      setUserProperties(this.sharedService.analytics, {
        companyKey: localStorage.getItem('company_key'),
        user_role: JSON.parse(localStorage.getItem('user')).role.name,
        user_name: JSON.parse(localStorage.getItem('user')).login
      });

      logEvent(this.sharedService.analytics, 'screen_view', {
        firebase_screen: 'Login',
        firebase_screen_class: 'Login',
        appVersion: environment.appVersion,
        appName: 'Salesdiary PWA',
        appId: window.location.hostname,
        app_version: environment.appVersion
      });
      t.putAttribute('user', JSON.parse(localStorage.getItem('user')).login);
      t.putAttribute('user_role', JSON.parse(localStorage.getItem('user')).role.name);
      //setCurrentScreen(this.appModule.analytics, 'Login');
      /* logEvent(this.appModule.analytics, 'screen_view', {
         firebase_screen: 'Login',
         firebase_screen_class: 'random',
         appVersion: environment.appVersion
       });*/
      t.stop();
      const router = this.router;
      const reportsList = {};
      localStorage.setItem('already_logged', false.toString());

      if (res.result.hasOwnProperty('app') && res.result.app.hasOwnProperty('labels')) {
        console.log(res.result.app.labels);
        localStorage.setItem('custom_labels', JSON.stringify(res.result.app.labels));
      }
      if (res.result.hasOwnProperty('app') && res.result.app.hasOwnProperty('templates')) {
        localStorage.setItem('custom_templates', JSON.stringify(res.result.app.templates));
      }
      let ledger = false;
      res.result.user.role.web_module_ids.forEach(feature => {

        switch (feature.name) {
          case 'module_settelment':
            localStorage.setItem('dc_head', true.toString());
            break;
          case 'module_production':
            localStorage.setItem('pro_head', true.toString());
            break;
          case 'module_sales':
            localStorage.setItem('sales_head', true.toString());
            break;
          case 'module_dc_report':
            localStorage.setItem('dc_report', true.toString());
            break;
          case 'module_team_report':
            localStorage.setItem('team_report', true.toString());
            break;
          case 'module_user_collection':
            localStorage.setItem('user_report', true.toString());
            break;
          case 'module_product_collection':
            localStorage.setItem('prod_report', true.toString());
            break;
          case 'module_loadin_report':
            localStorage.setItem('loadin_report', true.toString());
            break;
          case 'module_pricelist_report':
            localStorage.setItem('pricelist_report', true.toString());
            break;
          case 'module_return_report':
            localStorage.setItem('return_report', true.toString());
            break;
          case 'module_growth_report':
            localStorage.setItem('growth_report', true.toString());
            break;
          case 'module_outlet_report':
            localStorage.setItem('outlet_report', true.toString());
            break;
          case 'module_livestock_report':
            localStorage.setItem('livestock_report', true.toString());
            break;
          case 'module_productivity_report':
            localStorage.setItem('van_report', true.toString());
            break;
          case 'module_scheme_report':
            localStorage.setItem('scheme_report', true.toString());
            break;
          case 'module_cancel_report':
            localStorage.setItem('cancel_report', true.toString());
            break;
          case 'module_beat_report':
            localStorage.setItem('beat_report', true.toString());
            break;
          case 'module_ti_report':
            localStorage.setItem('ti_report', true.toString());
            break;
          case 'module_btl_report':
            localStorage.setItem('btl_report', true.toString());
            break;
          case 'module_outlet_agg_report':
            localStorage.setItem('outlet_agg_report', true.toString());
            break;
          case 'module_outlet_prod_report':
            localStorage.setItem('outlet_prod_report', true.toString());
            break;
          case 'module_mt_module':
            localStorage.setItem('mt_module', true.toString());
            break;
          case 'module_daily_collection':
            localStorage.setItem('daily_collection', true.toString());
            break;
          case 'module_po':
            localStorage.setItem('po_module', true.toString());
            break;
          case 'module_stock_in_hand':
            localStorage.setItem('stockhand_module', true.toString());
            break;
          case 'module_einvoice_report':
            localStorage.setItem('einvoice_report_module', true.toString());
            break;
          case 'module_dms':
            localStorage.setItem('dms_module', true.toString());
            break;
          case 'report_payment_ageing':
            localStorage.setItem('payment_ageing', true.toString());
            break;
          case 'report_outlet_overdue':
            localStorage.setItem('outlet_overdue', true.toString());
            break;
          case 'report_cheque_approval':
            localStorage.setItem('cheque_approval', true.toString());
            break;
          case 'module_expense':
            localStorage.setItem('expense_module', true.toString());
            break;
          case 'feature_expense_approve1':
            localStorage.setItem('feature_expense_approve1', true.toString());
            break;
          case 'feature_expense_approve2':
            localStorage.setItem('feature_expense_approve2', true.toString());
            break;
          case 'feature_expense_settle':
            localStorage.setItem('feature_expense_settle', true.toString());
            break;
          case 'module_retailers':
            localStorage.setItem('module_retailers', true.toString());
            break;
          case 'module_tasks':
            localStorage.setItem('module_tasks', true.toString());
            break;
          case 'module_retailAudit':
            localStorage.setItem('module_retailAudit', true.toString());
            break;
          case 'module_asset':
            localStorage.setItem('module_asset', true.toString());
            break;
          case 'report_attendance':
            localStorage.setItem('report_attendance', true.toString());
            break;
          case 'board_gt_manager':
            localStorage.setItem('board_gt_manager', true.toString());
            break;
          case 'board_mt_manager':
            localStorage.setItem('board_mt_manager', true.toString());
            break;
          case 'board_diageo_manager':
            localStorage.setItem('board_diageo_manager', true.toString());
            break;
          case 'board_hil_manager':
            localStorage.setItem('board_hil_manager', true.toString());
            break;
          case 'board_vansales_manager':
            localStorage.setItem('board_vansales_manager', true.toString());
            break;
          case 'module_retail_survey':
            localStorage.setItem('module_retail_survey', true.toString());
            break;
          case 'module_user_survey':
            localStorage.setItem('module_user_survey', true.toString());
            break;
          case 'module_users':
            localStorage.setItem('module_users', true.toString());
            break;
          case 'module_distributors':
            localStorage.setItem('module_distributors', true.toString());
            break;
          case 'feature_approve_outlet':
            localStorage.setItem('feature_approve_outlet', true.toString());
            break;
          case 'feature_change_request':
            localStorage.setItem('feature_change_request', true.toString());
            break;
          case 'report_customer_ledger':
            ledger = true;
            break;
          case 'feature_show_all_outlets':
            localStorage.setItem('feature_show_all_outlets', true.toString());
            break;
          case 'module_beats':
            localStorage.setItem('module_beats', true.toString());
            break;
          default:
            if (feature.name.startsWith('report_')) {

              // localStorage.setItem(feature.name.substr(7), true.toString());

              // reportsList[feature.name.substr(7)] = {code: 'R127',
              //   name: feature.name.substr(7),
              //   path: 'reports/' + feature.name.substr(7),
              //   desc: feature.desc,
              //   displayName: feature.display
              // };
            } else {
              localStorage.setItem(feature.name, true.toString());
            }
        }
      });
      localStorage.setItem('print-format', 'A4');

      // localStorage.setItem('reports_list', JSON.stringify(reportsList));

      // localStorage.setItem('board_gt_manager', true.toString());

      const inData = {
        access_token: res.result.user.access_token,
        // url: this.url
      };

      // debugger;

      this.prgs_msg = 'Fetching Datas...';
      //this.result = this.apiService.getFilters(inData);
      this.apiService.getFilters(inData)
        .subscribe(res1 => {
          console.log(res1);
          if (res1.hasOwnProperty('results') && (res1.results.status === 200)) {
            try {
              let classes = [];
              if (app_object.config_json && app_object.config_json.hasOwnProperty('class') && app_object.config_json.class) {
                for (const item of app_object.config_json.class) {
                  classes.push({class_type: item});
                }
              } else {
                classes = [{class_type: "A"}, {class_type: "B"}, {class_type: "C"}, {class_type: "D"}];
              }

              if (res1.results.hasOwnProperty('content_url')) {
                localStorage.setItem('content_url', res1.results.content_url);
              }
              localStorage.setItem('all_dcs', JSON.stringify(res1.results.dcs));
              localStorage.setItem('all_vans', JSON.stringify(res1.results.vans));
              localStorage.setItem('all_out_types', JSON.stringify(res1.results.out_types));
              localStorage.setItem('all_out_classes', JSON.stringify(classes));
              localStorage.setItem('all_exp_users', JSON.stringify(res1.results.exp_users));
              localStorage.setItem('all_expenses', JSON.stringify(res1.results.expenses));
              localStorage.setItem('all_transp', JSON.stringify(res1.results.transp));
              localStorage.setItem('all_dist_types', JSON.stringify(res1.results.dist_types));
              localStorage.setItem('all_program_types', JSON.stringify(res1.results.program_type));
              // localStorage.setItem('all_beats', JSON.stringify(res1.results.user_beats));
              localStorage.setItem('all_travel_types', JSON.stringify(res1.results.travel_types));
              localStorage.setItem('terr_beats', JSON.stringify(res1.results.terr_beats));
              if (res1.results.states) {
                //TODO: remove this and use user_states
                localStorage.setItem('all_states', JSON.stringify(res1.results.states));
              }
              if (res1.results.user_states) {
                localStorage.setItem('user_states', JSON.stringify(res1.results.user_states));
              }
              if (res1.results.regions) {
                localStorage.setItem('all_regions', JSON.stringify(res1.results.regions));
              }
              if (res1.results.clusters) {
                localStorage.setItem('all_clusters', JSON.stringify(res1.results.clusters));
              }
              if (res1.results.roles) {
                localStorage.setItem('all_roles', JSON.stringify(res1.results.roles));
              }
              if (res1.results.teams) {
                localStorage.setItem('all_teams', JSON.stringify(res1.results.teams));
              }
              if (res1.results.territories) {
                localStorage.setItem('all_territories', JSON.stringify(res1.results.territories));
              }
              if (res1.results.salesmans) {
                localStorage.setItem('all_salesmans', JSON.stringify(res1.results.salesmans));
              }
              if (res1.results.territories) {
                localStorage.setItem('team_territories', JSON.stringify(res1.results.territories));
              }
              localStorage.setItem('user_territories', JSON.stringify(res1.results.user_terr));
              localStorage.setItem('jc_periods', JSON.stringify(res1.results.JC_periods));
              localStorage.setItem('jc_months', JSON.stringify(res1.results.JC_months));
              localStorage.setItem('jc_quarter', JSON.stringify(res1.results.JC_quarter));
              localStorage.setItem('task_types', JSON.stringify(res1.results.task_types));
              localStorage.setItem('beat_activity_types', JSON.stringify(res1.results.beat_activity_types));
              localStorage.setItem('integrations', JSON.stringify(res1.results.integrations));
              localStorage.setItem('pricelists', JSON.stringify(res1.results.pricelists));
              localStorage.setItem('all_attributes', JSON.stringify(res1.results.attributes));
              localStorage.setItem('all_uoms', JSON.stringify(res1.results.uom));
              localStorage.setItem('all_brands', JSON.stringify(res1.results.brands));
              localStorage.setItem('sources', JSON.stringify(res1.results.sources));
              localStorage.setItem('sources', JSON.stringify(res1.results.sources));
              if (res1.results.company_config) {
                localStorage.setItem('company_config', JSON.stringify(res1.results.company_config));
              } else {
                localStorage.setItem('company_config', JSON.stringify([]));
              }
              if (localStorage.getItem('feature_approve_outlet') === 'true') {
                const inData2 = {
                  access_token: res.result.user.access_token,
                  data: {
                    territory_ids: []
                  }
                };
                const team_territories_list = res1.results.territories;
                if (team_territories_list !== null) {
                  for (let i = 0; i < team_territories_list.length; i++) {
                    inData2.data.territory_ids.push(team_territories_list[i].id);
                  }
                }
                this.apiService.post('/api/res_partners/get_chains', inData2)
                  .subscribe(resp => {
                    localStorage.setItem('chain_list', JSON.stringify(resp['results'].data));
                  }, error => {
                    console.log(error);
                  });
              }
            } catch (e) {
              if (e) {
                console.log(e);
              }
            }
            this.prgs_msg = 'Fetching Data Completed...';
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.loader = false;
            this.redirectToDefaultPage();
            this.prgs_msg = '';
          }

        }, error => {
          this.prgs_msg = '';
          console.log(error);
        });

      if (res.result.user.profile_name === 'Distributor') {
        const inData2 = {
          access_token: res.result.user.access_token,
          data: {
            start_date: null,
            end_date: null,
            territory_ids: [],
            showAll: false
          }
        };
        if (localStorage.getItem('feature_show_all_outlets') === 'true') {
          inData2.data.showAll = true;
        }
        if (localStorage.getItem('dms_module') === 'true') {
          this.apiService.post('/api/audit_masters/get_customers', inData2)
            .subscribe(resp => {
              const inData3 = {
                access_token: localStorage.getItem('resfreshToken'),
                supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
                // url: this.url
              };
              this.apiService.post('/api/dms_connects/get_dms_retailers', inData3)
                .subscribe(resp1 => {
                  localStorage.setItem('customers_list', JSON.stringify(resp['results'].data.concat(resp1['results'].data)));
                  localStorage.setItem('dist_list', JSON.stringify(resp1['results'].data));
                });
            }, error => {
              console.log(error);
            });
        }
      } else if (ledger) {
        const inData2 = {
          access_token: res.result.user.access_token,
        };
        this.apiService.post('/api/pwa_connects/get_vansale_customers', inData2)
          .subscribe(resp => {
            localStorage.setItem('customers_list', JSON.stringify(resp['results'].data));
          }, error => {
            console.log(error);
          });
      }

      const inData3 = {
        access_token: res.result.user.access_token,
        supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
        // url: this.url
      };
      this.apiService.getDmsUsers(inData3)
        .subscribe(resp => {
          localStorage.setItem('dms_users', JSON.stringify(resp['results'].data));
        }, error => {
          console.log(error);
        });

      const inData4 = {
        access_token: res.result.user.access_token
        // url: this.url
      };
      this.apiService.post('/api/dms_connects/get_addressses', inData4)
        .subscribe(res1 => {
          console.log(res1);
          localStorage.setItem('cust_addresses', JSON.stringify(res1['results'].data));
        }, error => {
          console.log(error);
        });
      // this.router.navigate(['dashboard']);
    }
  }

  async checkOtherInstances() {
    if (localStorage.getItem('user') !== null
      && JSON.parse(localStorage.getItem('user')).hasOwnProperty('instance_json')
      && JSON.parse(localStorage.getItem('user')).instance_json !== null
      && JSON.parse(localStorage.getItem('user')).instance_json.length > 0) {

      this.instanceUrls = JSON.parse(localStorage.getItem('user')).instance_json;
      await this.authenticateOtherAccounts();
      localStorage.setItem('account_urls', JSON.stringify(this.instanceUrls));
    }
  }

  async authenticateOtherAccounts() {
    for (const item in this.instanceUrls) {
      /* this.userdata.instance = this.instanceUrls[item].key;
       this.userdata.url = this.instanceUrls[item].url;*/
      this.instanceUrls[item].instance = this.instanceUrls[item].key;
      this.instanceUrls[item].user_name = this.userdata.user_name;
      this.instanceUrls[item].password = this.userdata.password;
      this.instanceUrls[item].login_type = '';
      this.instanceUrls[item].phone = this.phone;
      this.instanceUrls[item].otp = this.otp1 + this.otp2 + this.otp3 + this.otp4;
      this.instanceUrls[item].device_name = '';
      this.instanceUrls[item].token = null;
      this.instanceUrls[item].pwa_version = this.userdata.pwa_version;
      this.instanceUrls[item].device_info = {
        ip_address: '0.0.0.0',
        name: '12345678',
        browser: this.device_info.browser,
        os: this.device_info.os,
        device_token: this.instanceUrls[item].key + '_token'
      };
      //this.authenticateOtherAccounts(this.instanceUrls[item]);
      const res = await this.apiService.authenticateUserPromise(this.instanceUrls[item]);
      if (res.hasOwnProperty('result')) {
        this.instanceUrls[item].access_token = res['result'].user.access_token;
      }
    }
  }

  loginwithOTPClick() {
    this.login_options_show = false;
    this.error_msg = '';
    this.login_mobileotp_show = true;
    this.renderer.removeClass(this.elRef.nativeElement.querySelector('.getotp'), 'hide');
  }

  otpClick() {
    this.userdata.phone = this.phone;
    this.userdata.user = null;
    if (this.phone === undefined) {
      this.error_msg = 'Enter Mobile Number';
    } else {
      this.toastr.success("OTP Sent");
      this.result = this.apiService.sendotp(this.userdata);
      this.result.subscribe(res => {
          if (res.result.hasOwnProperty('success')) {
            console.log(res.result.otp);
            this.renderer.removeClass(this.elRef.nativeElement.querySelector('.verifyotp'), 'hide');
            this.renderer.addClass(this.elRef.nativeElement.querySelector('.getotp'), 'hide');
          } else {
            this.error_msg = res.result.failed;
          }
        },
        error => {
          console.log(error);
          if (error.error.error.message === undefined) {
            this.error_msg = 'Authentication Error';
          } else {
            this.error_msg = error.error.error.message;
          }
          this.toastr.error(this.error_msg);
        }
      );
    }
  }
  focusNextOTPInput(event) {
    console.log(event);
    if (event.key && event.key.length === 1) {
      if (event.srcElement.nextElementSibling) {
        event.srcElement.nextElementSibling.focus();
      } else {
        document.getElementById('otpverify').focus();
      }
    }
  }
}


