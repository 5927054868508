<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dcvanlist">
            <div class="search-list">
              <div class="col-md-6 pad0">
                  <div class="search">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                    placeholder="Search for DC">
                  </div>
              </div>
              <div class="col-md-6 pad0">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR">
                        <div class="date-picker">
                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                            <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                    </li>
<!--                      <li class="margLR">-->
<!--                          <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--                      </li>-->
                      <li class="margLR">
                          <app-excelicon [gridOption]="gridOptions" [title]="'DC List'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
              </div>
              <div class="col-md-12 pad0">
                <div class="my-table dcvanlist">
                  <div #chart1 *ngIf="loaded" id="chart1" style="width: 100%;height: 50vh;margin-top: 2%;">
                                    </div>
                  <div #chart2 *ngIf="loaded" id="chart2" style="width: 100%;height: 50vh;">
                  </div>
                  <ag-grid-angular id="myGrid" *ngIf="loaded" style="width: 50%;height: 0vh;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                   (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
                  </ag-grid-angular>
                  <div *ngIf="preloader">
                    <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
                      <!--<img src="../../assets/images/preloader.gif"/>-->
                    <!--</div>-->
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

