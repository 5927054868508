<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div  class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                  <div class="row">
                    <div  *ngIf="!editMode" class="button-row">
                      <button
                        class="btn grey-button"
                        (click)="editBeatPlan()"
                      >EDIT
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['active'] === true" class="button-row">
                      <button (click)="openChangeBeatStatusPopup(false)"
                        class="btn grey-button"
                      > DE-ACTIVATE
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['active'] === false" class="button-row">
                      <button (click)="openChangeBeatStatusPopup(true)"
                              class="btn grey-button"
                      > ACTIVATE
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['active'] === true" class="button-row">
                      <button (click)="openChangeSalesmanDialog()"
                              class="btn grey-button"
                      > CHANGE SALESMAN
                      </button>
                    </div>
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="discard()"
                      >
                        DISCARD
                      </button>
                    </div>
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="saveBeatPlan()"
                      >
                        SAVE
                      </button>
                    </div>
                    <div style="position: absolute;right: 30px;">
                      <div class="button-row" style="display: flex;align-items: center">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === 100-1,
                         'enabledspan': rowIndex < 100-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">

                <div class="col-md-12 pt-3 pad0">

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">BEAT CODE</label>
                    <div style="margin-top: 5px;">
                      <input
                        *ngIf="editMode"
                        class="forminput"
                        type="text"
                        name="code"
                        [(ngModel)]="activeObject['code']"
                        #code="ngModel"
                        style="width: 80%;"
                        required
                      >
                      <div>
                        <p class="lbl3" *ngIf="activeObject['code'] && !editMode">{{activeObject['code']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">BEAT NAME</label>
                    <div style="margin-top: 5px;">
                      <input
                        *ngIf="editMode"
                        class="forminput"
                        type="text"
                        name="name"
                        [(ngModel)]="activeObject['name']"
                        #name="ngModel"
                        style="width: 95%;"
                        required
                      >
                      <div>
                        <p class="lbl3" *ngIf="activeObject['name'] && !editMode">{{activeObject['name']}}</p>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">TERRITORY</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="territoriesList && territoriesList.length > 0 && false"
                                 [items]="territoriesList"
                                 bindLabel="itemname"
                                 bindValue="id"
                                 style="width: 95%;"
                                 (ngModelChange)="changeTerritory($event)"
                                 [(ngModel)]="activeObject['territory_id']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['territory_name']">{{activeObject['territory_name']}}</p>
                        <p *ngIf="activeObject['territory_code']" class="lbl2">Code : {{activeObject['territory_code']}}</p>
                        <p *ngIf="activeObject['territory_manager']" class="lbl2">Manager : {{activeObject['territory_manager']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">SALESMAN</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="salesmenList && salesmenList.length > 0 && false"
                                 [items]="salesmenList"
                                 bindLabel="itemname"
                                 bindValue="id"
                                 style="width: 95%;"
                                 (ngModelChange)="salesmanChange($event)"
                                 [(ngModel)]="activeObject['salesman_id']">
                      >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['salesman']">{{activeObject['salesman']}}</p>
                        <p *ngIf="activeObject['emp_code']" class="lbl2">Code : {{activeObject['emp_code']}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">SCHEDULE TYPE</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="schedule_type_list && editMode"
                                 [items]="schedule_type_list"
                                 bindLabel="label"
                                 bindValue="value"
                                 style="width: 80%;"
                                 [(ngModel)]="activeObject['schedule_type']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['schedule_type'] && !editMode">{{activeObject['schedule_type']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">TRAVEL TYPE</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="beatTravelTypes && editMode"
                                 [items]="beatTravelTypes"
                                 bindLabel="itemName"
                                 bindValue="id"
                                 style="width: 80%;"
                                 [(ngModel)]="activeObject['travel_type_id']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['travel_type'] && !editMode">{{activeObject['travel_type']}}</p>
                      </div>
                    </div>
                  </div>
                </div>

              <div class="col-md-12 pt-3 pad0">
                <div class="col-md-8 pl-0 pr-30">

                </div>
                <div class="col-md-4 pl-0 pr-30" *ngIf="activeObject['schedule_type']==='recurring'">
                    <label class="lbl">RECURRING SCHEDULE CONFIG</label>
                    <div>
                      <textarea [(ngModel)]="activeObject['schedule_config']"
                                name="CONFIG" class="forminput"
                                *ngIf="editMode"
                                placeholder="RECURRING CONFIG" rows="3"></textarea>
                      <p class="lbl3" *ngIf="activeObject['schedule_config'] && !editMode">{{activeObject['schedule_config']}}</p>
                    </div>
                </div>
              </div>


            </div>

            <div class="col-md-12 pt-4 pad0">
              <div>
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked($event)"
                                 [context]="context"

                >
                </ag-grid-angular>
              </div>
            </div>
            <div *ngIf="editMode" class="col-md-12 pt-4 pad0">
              <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="addBeatRetailers()">
                <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add Retailers</span>
              </div>
              <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="deleteBeatRetailer()">
                <i class="fas fa-trash delete" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Remove Retailers</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


