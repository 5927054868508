import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AdDirective} from "../ad.directive";
import {AdDirective3} from "../ad3.directive";
import {TypeDropdownComponent} from "../components/type_dropdown/type-dropdown.component";
import {SupplierDropdownComponent} from "../components/supplier_dropdown/supplier-dropdown.component";

@Component({
  selector: 'app-product-aging-report',
  templateUrl: './product-ageing-report.component.html'
})
export class ProductAgingReportComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  user;
  res_data;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    out_type: 0,
    van_id: 0
  };
  private gridApi;
  private gridColumnApi;
  public all_types;
  public all_vans;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name = "DC LIST";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_dc = [];
  public limit = 10;
  public default = true;
  public type_default = true;
  public limit_only = true;
  public pinnedTotalData = [
    {
      DC: null,
      Van: "TOTAL QTY"
    }];
  public dms_enable = false;
  public API_URL = '';

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    this.all_types = [{id: 0, itemName: "All Types"}];
    this.all_vans = [{id: 0, itemName: "All Vans"}];
    this.all_types = this.all_types.concat(JSON.parse(localStorage.getItem('all_out_types')));
    this.all_vans = this.all_vans.concat(JSON.parse(localStorage.getItem('all_vans')));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    if (localStorage.getItem('dms_module') === 'true' || localStorage.getItem('module_dmsv2') === 'true') {
      this.dms_enable = true;
    }
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

  }

  usercollectionreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const limit = this.limit;
    let range = '';
    const datePipe = this.datePipe;
    if (!this.limit_only) {
      if (this.dms_enable) {
        this.API_URL = '/api/dms_connects/product_aging_report';
      } else {
        this.API_URL = '/api/pwa_connects/product_aging_report';
      }
      this.apiService.post(this.API_URL, this.user_data)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
              res['results'].data.forEach(data => {
                const pushData = {
                  'Order Date': data.order_date,
                  Outlet: data.outlet_name,
                  'OUT ID': data.outlet_id,
                  Code: data.outlet_code,
                  Type: data.outlet_type,
                  Area: data.area,
                  Zone: data.zone,
                  Order: data.order_ref,
                  Invoice: data.invoice_no,
                  Product: data.product,
                  'Prod Code': data.default_code,
                  Category: data.categ,
                  Brand: data.brand,
                  Beat: data.beat_name,
                  Salesman: data.salesman_name,
                  Mobile: data.outlet_mobile,
                  'Credit Limit': data.credit_limit,
                  'Order Outstanding': data.outstanding_amount,
                  'Current Outstanding': data.current_outstanding,
                  'Overdue Date': datePipe.transform(data.overdue_date, 'dd/MM/yyyy'),
                  days_pending: data.days_pending
                };
                range = '<= ' + limit.toString();
                pushData[range] = 0;
                range = limit.toString() + '-' + (2 * limit).toString();
                pushData[range] = 0;
                range = (2 * limit).toString() + '-' + (3 * limit).toString();
                pushData[range] = 0;
                range = (3 * limit).toString() + '-' + (4 * limit).toString();
                pushData[range] = 0;
                range = (4 * limit).toString() + '-' + (5 * limit).toString();
                pushData[range] = 0;
                range = '> ' + (5 * limit).toString();
                pushData[range] = 0;

                if (parseFloat(data.days_pending) <= limit) {
                  pushData['<= ' + limit.toString()] = data.outstanding_amount;
                } else if (parseFloat(data.days_pending) < (2 * limit)) {
                  pushData[limit.toString() + '-' + (2 * limit).toString()] = data.outstanding_amount;
                } else if (parseFloat(data.days_pending) < (3 * limit)) {
                  pushData[(2 * limit).toString() + '-' + (3 * limit).toString()] = data.outstanding_amount;
                } else if (parseFloat(data.days_pending) < (4 * limit)) {
                  pushData[(3 * limit).toString() + '-' + (4 * limit).toString()] = data.outstanding_amount;
                } else if (parseFloat(data.days_pending) < (5 * limit)) {
                  pushData[(4 * limit).toString() + '-' + (5 * limit).toString()] = data.outstanding_amount;
                } else if (parseFloat(data.days_pending) > (5 * limit)) {
                  pushData['> ' + (5 * limit).toString()] = data.outstanding_amount;
                }
                rowdata.push(pushData);
              });


              this.data = rowdata[0];
              this.rowData = rowdata;

              for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                  if (key === 'Credit Limit' || key === 'Order Outstanding' || key === 'Days Pending' ||
                    key === 'Current Outstanding') {
                    this.columnDefs.push({
                      headerName: key, field: key, cellStyle: {textAlign: "right"}, aggFunc: 'sum', width: 150
                    });
                  } else if (key === 'Code' || key === 'Beat' || key === 'Mobile' || key === 'Type' || key === 'Order' ||
                    key === 'Zone' || key === 'Area' || key === 'Invoice' || key === 'Product' || key === 'Prod Code' ||
                    key === 'Category' || key === 'Brand') {
                    this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', width: 200});
                  } else if (key === 'Outlet' || key === 'Order Date' || key === 'Salesman') {
                    this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', pinned: 'left'});
                  } else if (key === 'days_pending') {
                    this.columnDefs.push({headerName: key, field: key, hide: true});
                  } else {
                    this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}, width: 100});
                  }
                }
              }
              this.loaded = true;
            } else {
              this.preloader = false;
              this.empty_state = true;
            }
          },
          error => {
            console.log(error);
            this.preloader = false;
          });
    } else {
      const upd_rowdata = [];
      const limit = this.limit;
      let range;
      this.rowData.forEach(data => {
        const pushData = {
          'Order Date': data['Order Date'],
          Salesman: data['Salesman'],
          Outlet: data['Outlet'],
          Code: data['Code'],
          Type: data['Type'],
          Order: data['Order'],
          Beat: data['Beat'],
          Mobile: data['Mobile'],
          'Credit Limit': data['Credit Limit'],
          'Pending Amount': data['Pending Amount'],
          'Overdue Date': datePipe.transform(data.overdue_date, 'dd/MM/yyyy')
        };
        range = '< ' + limit.toString();
        pushData[range] = 0;
        range = limit.toString() + '-' + (2 * limit).toString();
        pushData[range] = 0;
        range = (2 * limit).toString() + '-' + (3 * limit).toString();
        pushData[range] = 0;
        range = '> ' + (3 * limit).toString();
        pushData[range] = 0;

        if (parseFloat(data.days_pending) < limit) {
          pushData['< ' + limit.toString()] = data['Pending Amount'];
        } else if (parseFloat(data.days_pending) < (2 * limit)) {
          pushData[limit.toString() + '-' + (2 * limit).toString()] = data['Pending Amount'];
        } else if (parseFloat(data.days_pending) < (3 * limit)) {
          pushData[(2 * limit).toString() + '-' + (3 * limit).toString()] = data['Pending Amount'];
        } else if (parseFloat(data.days_pending) > (3 * limit)) {
          pushData['> ' + (3 * limit).toString()] = data['Pending Amount'];
        }
        upd_rowdata.push(pushData);
      });
      this.rowData = upd_rowdata;
      this.gridOptions.api.setRowData(this.rowData);
      this.loaded = true;
      this.preloader = false;
    }
  }

  typeList() {
    const viewContainerRef3 = this.adHost3.viewContainerRef;
    viewContainerRef3.clear();
    const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(TypeDropdownComponent);
    const componentRef3 = viewContainerRef3.createComponent<TypeDropdownComponent>(componentFactory3);
    componentRef3.instance.itemList = this.all_types;
    if (this.type_default) {
      componentRef3.instance.selectedItems = [this.all_types[0]];
      localStorage.setItem('selected_type', JSON.stringify(componentRef3.instance.selectedItems));
    } else {
      componentRef3.instance.selectedItems = JSON.parse(localStorage.getItem('selected_type'));
    }
    componentRef3.instance.statusChange.subscribe(res => {
      if (res && res.length > 0) {
        this.type_default = false;
        this.user_data.out_type = res[0].id;
        localStorage.setItem('selected_type', JSON.stringify(res));
      }
    });
  }

  vanList() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);
    const componentRef = viewContainerRef.createComponent<SupplierDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.all_vans;
    if (this.default) {
      componentRef.instance.selectedItems = [this.all_vans[0]];
      localStorage.setItem('selected_van', JSON.stringify(componentRef.instance.selectedItems));
    } else {
      componentRef.instance.selectedItems = JSON.parse(localStorage.getItem('selected_van'));
    }
    componentRef.instance.statusChange.subscribe(res => {
      if (res && res.length > 0) {
        this.default = false;
        this.user_data.van_id = res[0].id;
        localStorage.setItem('selected_van', JSON.stringify(res));
      }
    });
  }

  run() {
    if (this.rowData.length === 0) {
      this.limit_only = false;
    }
    this.user_data.flag = false;
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.usercollectionreport();
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    if (screen.width > 991) {
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.typeList();
    this.vanList();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.limit_only = false;
    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }
}
