import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-gallerycard',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss']
})
export class GalleryCardComponent implements OnInit {

  @Input() id: number;
  @Input() image: string;
  @Input() name: string;
  @Input() field1: string;
  @Input() field2: string;
  @Input() field3: string;
  @Input() status: string;
  @Input() reviewed: string;
  @Input() selected: boolean;
  @Input() galleryData = {};
  @Output() galleryClick: EventEmitter<any> = new EventEmitter<any>();

  statusTypes: [];
  container;
  statusType = 'success';
  types = [];
  photo;
  API_URL = '/api/pwa_connects/fetch_file';
  preventSingleClick = false;
  timer: any;
  selectionCriteria: any = {};

  constructor(private apiService: ApiService,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.statusTypes = this.galleryData['statusTypes'];
    this.container = this.galleryData['container'];
    this.selectionCriteria = this.galleryData['selectionCriteria'];
    this.types = this.statusTypes;
    if (this.status && this.types) {
      const foundstatus = this.types.find(x => x['name'].toLowerCase() === this.status.toLowerCase());
      if (foundstatus) {
        this.statusType = foundstatus.type;
      }
    }
    if (this.image && this.image !== "") {
      if (!this.photo) {
        this.fetchImage();
      }
    } else {
      this.photo = './../../../assets/no-image-set.svg';
    }

  }

  checkIfSelectAllowed(selectionCriteria) {
    if (selectionCriteria.matches) {
      if (this[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!this[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    }
  }
  checkSelectionCriteria() {
    if (this.selectionCriteria) {
      let matches = true;
      for (const item of this.selectionCriteria) {
        if (!this.checkIfSelectAllowed(item)) {
          matches = false;
          return false;
        }
      }
      return matches;
    } else {
      return true;
    }
  }

  singleClick(event) {
    //if (this.checkSelectionCriteria()) {
      this.preventSingleClick = false;
      const delay = 200;
      this.timer = setTimeout(() => {
        if (!this.preventSingleClick) {
          //Navigate on single click
          console.log(event);
          if (this.selected === null) {
            this.selected = true;
          } else {
            this.selected = !this.selected;
          }
          const item = {
            id: this.id,
            selected: this.selected,
            click: 'single'
          };
          this.galleryClick.emit(item);
        }
      }, delay);
   // }
  }

  doubleCLick(event) {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
    const item = {
      id: this.id,
      selected: this.selected,
      photo: this.photo,
      click: 'double'
    };
    this.galleryClick.emit(item);
  }

  fetchImage() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.image;
    paramObject.container = this.container;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('result') && res.result.status === 200 && res.result.hasOwnProperty('data')) {
            this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {
            this.photo = './../../../assets/no-image-set.svg';
          }
        },
        error => {
          console.log(error);
        });
  }
}

