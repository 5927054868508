import {Component, OnInit} from '@angular/core';
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-beat-productivity-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class BeatReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Beat Productivity Report');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/beat_productivity_report';
    const inThis = this;
    this.columnDefs = [{
      headerName: 'Date', field: 'schedule_date', width: 100,
      valueGetter(params) {
        if (params.data) {
          if (params.data.schedule_date) {
            return inThis.datePipe.transform((new Date(params.data.schedule_date + ' UTC')).toString(), 'yyyy-MM-dd');
          } else {
            return params.data.schedule_date;
          }
        } else {
          return '';
        }
      }
    },
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Van', field: 'van', width: 100},
      {headerName: 'Loadout QTY', field: 'qty', width: 100},
      {headerName: 'Loadout Value', field: 'loadout', width: 100},
      {
        headerName: 'Cash', field: 'cash', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.cash) {
              return parseFloat(params.data.cash).toFixed(3);
            }
          }
        }
      },
      {
        headerName: 'Credit', field: 'credit', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.credit) {
              return parseFloat(params.data.credit).toFixed(3);
            }
          }
        }
      },
      {
        headerName: 'Total', field: 'total_sale', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.total_sale) {
              return parseFloat(params.data.total_sale).toFixed(3);
            }
          }
        }
      },
      {headerName: 'Replace', field: 'damage_sale', width: 100},
      {
        headerName: 'Replace%', field: 'rep_perc', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.gross_sale && params.data.damage_sale) {
              return parseFloat(((parseFloat(params.data.damage_sale) / parseFloat(params.data.gross_sale)) * 100).toFixed(inThis.apiService.decimalPolicy));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'Return', field: 'return_sale', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.return_sale) {
              return parseFloat(params.data.return_sale).toFixed(3);
            }
          }
        }
      },
      {
        headerName: 'Return%', field: 'ret_perc', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.gross_sale && params.data.return_sale) {
              return parseFloat(((parseFloat(params.data.return_sale) / parseFloat(params.data.gross_sale)) * 100).toFixed(inThis.apiService.decimalPolicy));
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'TC', field: 'tc', width: 100},
      {headerName: 'PC', field: 'pc', width: 100},
      {headerName: 'Coverage', field: 'cov_pct', width: 100}
    ];
  }
}
