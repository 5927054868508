import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {ErrorHandler} from "../../../error/error-handler";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";
import {BulkProductSelectPopupComponent} from "../../../components/bulk_product_select_popup/bulk_product_select_popup.component";


@Component({
  selector: 'app-load-out',
  templateUrl: 'load-out.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class LoadOutOrderComponent extends DMSBasePage implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;
  OPERATION_MODE = 'L';

  dateDisable;
  myDate;
  public addressLibrary;
  public inco_terms_list;

  public priceEdited = false;
  public uomEdited = false;

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;


  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  showPlantField = false;
  showIncoTerms = false;
  showTotalWeight = false;
  showTotalMeasure = false;

  plantFieldValue = '';
  incoTermsFieldValue = '';
  totalMeasureFieldValue = '';
  totalWeightFieldValue = '';

  reference_order_mode = false;

  feature_disable_supplier_live_stock = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_order_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Van Load Out');

    this.configureGrid();

    this.initialize_default_values();

    this.readPageParms(params => {

      if (params.get('reference_order') === 'true') {

        const ref_order_object = JSON.parse(localStorage.getItem('reference_order'));
        if (ref_order_object !== undefined) {

          this.prepare_referance_object(ref_order_object);
          this.pageReady = true;

        }
      } else {

        if (this.activeID > 0) {
          this.newMode = false;
          this.editMode = false;
          //   View Mode
          //   Page Can be ready only after detail Object is fetched
          this.loadPageForView(() => {
            this.pageReady = true;
            this.enableDisableGridEdit();
          });
        } else {
          this.newMode = true;
          this.editMode = true;
          //   Page Can be ready only after detail Object is fetched
          this.loadMasterDataForEdit( () => {
            this.pageReady = true;
            this.enableDisableGridEdit();
          });
        }
      }
    });
  }

  // async loadMasterDataForEdit(callback) {
  //   const userData = {};
  //   userData['access_token'] = this.userContext.access_token;
  //
  //   const salesmanProfiles = ['Manager', 'Executive', 'Delivery Executive'];
  //   const userMasterList = JSON.parse(localStorage.getItem('all_salesmans'));
  //   this.userList = [];
  //   userMasterList.forEach(user => {
  //     if (salesmanProfiles.includes(user.profile)) {
  //       this.userList.push(user);
  //     }
  //   });
  //   if (this.userList.length === 0 && userMasterList.length > 0) {
  //     this.userList = userMasterList;
  //   }
  //
  //   const res = await this.apiService.postPromise('/api/pwa_van_sales_connects/get_partners_for_load_out', userData);
  //   if (res) {
  //     this.customerList = res['results'].data['vans'];
  //     this.supplierMasterList = res['results'].data['suppliers'];
  //     return callback(null, null);
  //   } else {
  //     return callback('Error', null);
  //   }
  // }

  enableDisableGridEdit() {
    this.gridOptions.suppressClickEdit = !this.editMode;
    this.gridOptions.singleClickEdit = this.editMode;
  }

  loadPage() {
    this.pageReady = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  //Page Initilizefunctions
  prepare_referance_object(ref_order_object) {

    this.activeObject = {...ref_order_object}; //Shallow Copy the reference Object.
    this.activeObject.order_id = this.activeObject.id;
    this.activeObject.reference = ref_order_object.name;
    this.activeObject.supplier_id = ref_order_object.distributor_id;

    this.activeObject.id = 0;
    this.activeObject.name = '';
    this.activeObject['state'] = 'draft';

    this.activeObject.lines = [];
    this.rowData = [];

    let uid = 10;
    ref_order_object.lines.forEach( item => {
      const ret_items = {...item};
      ret_items.uid = uid;
      ret_items.quantity = item.product_uom_qty;
      ret_items.uom_id = item.uom_id;
      uid = uid + 10;
      this.rowData.push(ret_items);
    });

    this.activeID = 0;
    this.newMode = false;
    this.editMode = true;
    this.reference_order_mode = true;
  }
  initialize_default_values() {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.discount = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.activeObject.date_order = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.partner_tcs = 0;

    this.feature_disable_supplier_live_stock = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems, invoices) {
      const rowData = [];
      let unique_id = 1;

      if (lineItems) {
        lineItems.forEach(line => {

          if (line.quantity > 0) {
            line['uid'] = unique_id;
            line.inv_qty = 0;
            // line.quantity = line.product_uom_qty;
            // line.uom_id = line.product_uom;

            // if (invoices) {
            //   invoices.forEach(inv => {
            //     inv.lines.forEach(inv_line => {
            //       if (line.product_id === inv_line.product_id) {
            //         line.inv_qty = line.inv_qty + inv_line.quantity;
            //       }
            //     });
            //   });
            // }
            rowData.push(line);
            unique_id++;
          }
        });
      }
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['partner_id'] === undefined) {
        this.toastr.error('invalid state: partner_id not found');
      }

      if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
        this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
      }

      if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = prepareLineData(this.activeObject.lines, this.activeObject.invoices);
        // this.gridOptions.api.setRowData(this.rowData);
      }

      if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json !== null && this.activeObject.config_json !== undefined) {
        if (this.activeObject.config_json.hasOwnProperty('plant') && this.activeObject.config_json.plant !== null && this.activeObject.config_json.plant !== undefined) {
          this.showPlantField = true;
          this.plantFieldValue = this.activeObject.config_json.plant_name;
        }
        if (this.activeObject.config_json.hasOwnProperty('inco_term') && this.activeObject.config_json.inco_term !== null && this.activeObject.config_json.inco_term !== undefined) {
          this.showIncoTerms = true;
          this.incoTermsFieldValue = this.activeObject.config_json.inco_term;
        }
        if (this.activeObject.config_json.hasOwnProperty('totalWeight') && this.activeObject.config_json.totalWeight !== null && this.activeObject.config_json.totalWeight !== undefined) {
          this.showTotalWeight = true;
          this.totalWeightFieldValue = parseFloat(this.activeObject.config_json.totalWeight).toFixed(2);
        }
        if (this.activeObject.config_json.hasOwnProperty('totalMeasure') && this.activeObject.config_json.totalMeasure !== null && this.activeObject.config_json.totalMeasure !== undefined) {
          this.showTotalMeasure = true;
          this.totalMeasureFieldValue = parseFloat(this.activeObject.config_json.totalMeasure).toFixed(2);
        }
      }

      if (this.activeObject.amount_tax) {
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
      }
      if (this.activeObject.amount_total) {
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
      }
      if (this.activeObject.amount_untaxed) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
      }
      if (this.activeObject.tax_source) {
        this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
      } else if (this.activeObject.charges) {
        this.activeObject.charges = parseFloat(this.activeObject.charges);
      }

      this.compute_totals();
      this.get_supplier_available_stocks([this.activeObject], (err, result) => {
        if (err) {
          this.handleError(err);
        } else if (result) {
          this.rowData.forEach(line => {
            result.forEach(tp => {
              if (line.product_id === tp.product_id) {
                line.stock = tp.stock;
              }
            });
          });
        }
        this.gridOptions.api.setRowData(this.rowData);
        callback();
      });
    });

  }


  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.colDef.field === 'quantity') {
      event.data['reusable_qty'] = event.newValue;
      event.data['price_tax'] = 0;
      event.data['price_subtotal'] = parseInt(event.newValue, 10) * parseFloat(event.data['price_unit']);
      if (event.data['tax_amount'] !== undefined && event.data['tax_amount'] > 0) {
        event.data['price_tax'] = (event.data['price_subtotal'] * parseFloat(event.data['tax_amount'])) / 100;
      }
      event.data['price_total'] = parseFloat(event.data['price_subtotal']) + parseFloat(event.data['price_tax']) ;

      // this.compute_scheme_and_tax();
    }
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }

  //Page Events

  customerChangeEvent(selected_customer_id) {
    const data = this.customerList.find(item => item.id === selected_customer_id);
    // this.changeCustomer(selected_customer);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['partner_invoice_id'] = data.id;
      this.activeObject['partner_shipping_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;

      this.activeObject['area_id'] = data.area_id;
      this.activeObject['zone_id'] = data.zone_id;
      this.activeObject['state_id'] = data.state_id;

      if (data.hasOwnProperty('partner_type') && data['partner_type'] === 'supplier') {
        this.OPERATION_MODE = 'P';
        this.activeObject['type'] = 'P';
      } else {
        this.OPERATION_MODE = 'S';
        this.activeObject['type'] = 'S';
      }

      this.activeObject['tax_source'] = data.tax_source;
      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist_name;
      this.activeObject['shipto_address'] = data.address;
      // this.supplierList = [];
      this.beatList = [];

      // if (data['beats']) {
      //   this.beatList = data['beats'];
      //   if (this.beatList.length > 0) {
      //     this.activeObject['beat_plan_id'] = this.beatList[0].id;
      //     this.activeObject['territory_id'] = this.beatList[0].t_id;
      //     this.activeObject['user_id'] = this.beatList[0].sm_id;
      //     this.activeObject['beat_name'] = this.beatList[0].name;
      //     this.salesmanChange(this.activeObject['user_id']);
      //   }
      // }
      if (data['user_id']) {
        this.activeObject['user_id'] = data.user_id;
        this.activeObject['salesman'] = data.salesman;
        // this.activeObject['emp_code'] = data.salesman;
      }
      if (!this.activeObject['territory_id'] && data.territory_id) {
        this.activeObject['territory_id'] = data.territory_id;
      }

      // Check if the selected Customer has direct supplier mapping, Like The DC for van-sales
      // if (data.supplier_id > 0) {
      //   const supplier = this.supplierMasterList.find(item => item.id === data.supplier_id);
      //   this.supplierList.push(supplier);
      // }
      //
      // // If the supplier list for the selected logged in user, Like The territory Distributor, Then it should be added as immediate supplier
      // const defaultSuppliers = this.supplierMasterList.filter(item => item.default === true);
      // defaultSuppliers.forEach(supplier => {
      //   this.supplierList.push(supplier);
      // });

      // If so, Add to the Top
      // if (data['suppliers']) {
      //   data['suppliers'].forEach(supplier_id => {
      //     if (supplier_id !== data.supplier_id) {
      //       const supplier = this.supplierMasterList.find(item => item.id === supplier_id);
      //       this.supplierList.push(supplier);
      //     }
      //   });
      // }

      if (this.supplierList.length === 0) {
        this.toastr.error('Unable to load suppliers');
      } else {
        this.populateVanSuppliers(data);
        if (this.activeSupplierList && this.activeSupplierList.length > 0) {
          this.supplierChangeEvent(this.activeSupplierList[0].id);
        }
      }
    }
  }

  populateVanSuppliers(vanData) {
    this.activeSupplierList = [];
    if (vanData.suppliers && vanData.suppliers.length > 0) {
      vanData.suppliers.forEach((supplier) => {
        const found = this.supplierList.find(item => item.id === supplier);
        if (found) {}
        this.activeSupplierList.push(found);
      });
    } else {
      this.activeObject['distributor_id'] = 0;
      this.toastr.error('No Suppliers mapped to Van');
    }
  }

  supplierChangeEvent(event) {
    if (event) {
      if (this.activeObject['distributor_id'] !== event) {
        this.activeObject['distributor_id'] = event;
        //Supplier Change Detected
        if (this.populateSupplierAttributes(this.activeObject['distributor_id'])) {
          this.loadProducts(this.activeObject['partner_id'], this.activeObject['distributor_id'], this.activeObject['supplier_territory_id'], this.activeObject['pricelist_id'], () => {
          });
        }
      }
    }
  }

  plantChangeEvent(plant_code) {
    if (plant_code) {
      this.activeObject['config_json']['plant'] = plant_code;

      const select_plan_obj = this.plantList.find(item => item.code = plant_code);
      if (select_plan_obj) {
        this.activeObject['config_json']['plant_name'] = select_plan_obj.name;
      }
    }
  }

  beatChange(beat_id) {
    this.activeObject['beat_plan_id'] = beat_id;
    const selected_beat = this.beatList.find(item => item.id === beat_id);
    if (selected_beat) {
      this.activeObject['beat_name'] = selected_beat.name;
      this.activeObject['user_id'] = selected_beat.sm_id;
      this.activeObject['team_id'] = selected_beat.team_id;
      this.activeObject['salesman'] = selected_beat.sm;
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.find(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['salesman'] = selecteduser.itemname;
        this.activeObject['emp_code'] = selecteduser.emp_code;
        this.activeObject['team_id'] = selecteduser.sale_team_id;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.find(item => item.p_id === this.selectedProduct);
    if (prod) {
      this.UOMList = this.UOMMasterList.filter(item => item.category_id === prod.uom_category_id);
      this.selectedUOM_id = prod.uom_id;
      this.stock = prod.stock;
    }
    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange(uom_id) {
    console.log(this.selectedUOM_id);
  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.enableDisableGridEdit();
      this.loadPage();
    }
  }

  action_edit() {
    this.editMode = true;
    this.pageReady = false;
    this.loadMasterDataForEdit( () => {
      this.loadProducts(this.activeObject['partner_id'], this.activeObject['distributor_id'], this.activeObject['supplier_territory_id'], this.activeObject['pricelist_id'], () => {
        this.pageReady = true;
        this.enableDisableGridEdit();
      });
    });
    this.gridApi.redrawRows();
  }


  gotoinvoice(invoice_id) {

    const order = this.activeObject;
    order['mid'] = undefined;
    localStorage.setItem('order_to_invoice', JSON.stringify(order));

    this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'],
      {
        relativeTo: this.route,
        queryParams: {id: invoice_id}
      });
  }

  //GRID Functions
  configureGrid() {

    this.columnDefs = [
      {headerName: 'UID', field: 'uid', hide: true},
      {headerName: 'CODE', field: 'code', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 120},
      {headerName: 'STOCK', field: 'stock', width: 50},
      {headerName: 'QTY', field: 'quantity', width: 40, editable: true, cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7'};
            } else {
              return {backgroundColor: '#cae4f8'};
            }
          }
        }
      },
      {headerName: 'UOM', field: 'uom_name', width: 40},
      {headerName: 'INV QTY', field: 'inv_qty', width: 40, hide: true},
      {headerName: 'PRICE', field: 'price_unit', width: 60, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'DISC%', field: 'discount', width: 50, editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'TRADE DISC', field: 'trade_disc', width: 50, editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'CHARGES', field: 'fixed_cost', width: 50, hide: true},
      {headerName: 'SCHEME', field: 'scheme_code', width: 80, hide: true},
      {headerName: 'SCHEME DIS', field: 'scheme_discount', width: 50, valueFormatter: params => this.formatNumber(params.data.scheme_discount), hide: true},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', width: 50, valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'TAX', field: 'price_tax', width: 50, valueFormatter: params => this.formatNumber(params.data.price_tax), hide: true},
      {headerName: 'TAX Breakup', field: 'tax_notes', width: 50, hide: true},
      {headerName: 'TOTAL', field: 'price_total', width: 50, valueFormatter: params => this.formatNumber(params.data.price_total)},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 40},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
    };
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  }

  openBulkSelectPopup() {
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      this.toastr.error('Invalid Customer for invoice');
      return;
    }
    // if (this.activeObject['user_id'] === undefined || this.activeObject['user_id'] === 0) {
    //   this.toastr.error('Please select the salesman');
    //   return;
    // }
    const bulkProdList = [];
    const inThis = this;
    if (this.prodList) {
      let uom_obj = {};
      let uom_name = '';
      let scheme_code = '';
      let scheme_discount = 0;
      let discount = 0;
      let price_subtotal = 0;
      let price_total = 0;
      let quantity = 0;
      let reusable_qty = 0;
      let units = 0;
      let line_id;
      let mid;
      this.prodList.forEach(prod => {
        uom_obj = inThis.UOMMasterList.find(item => item.category_id === prod.uom_category_id);
        if (uom_obj) {
          uom_name = uom_obj['name'];
        } else {
          uom_name = '';
        }
        const row = inThis.rowData.find(x => x.product_id === prod.p_id);
        if (row) {
          quantity = row.quantity;
          units = row.quantity;
          reusable_qty = row.reusable_qty;
          scheme_code = row.scheme_code;
          scheme_discount = row.scheme_discount;
          discount = row.discount;
          price_subtotal = row.price_subtotal;
          price_total = row.price_total;
          line_id = row.line_id;
          mid = row.mid;
        } else {
          quantity = 0;
          units = 0;
          reusable_qty = 0;
          scheme_code = '';
          scheme_discount = 0;
          discount = parseFloat(prod['margin']);
          price_subtotal = 0;
          price_total = 0;
        }
        bulkProdList.push({
          code: prod.code,
          name: prod.product,
          stock: prod.stock,
          type: 'normal',
          quantity,
          reusable_qty,
          pack: 0,
          units,
          uom_name,
          price_unit: parseFloat(prod['unit_price']),
          scheme_code,
          scheme_discount,
          discount,
          price_subtotal,
          price_total,
          line_id,
          mid,
          tax_name: prod['tax_name'],
          taxmaster_id: prod['taxmaster_id'],
          factor_inv: prod['factor_inv'],
          factor: prod['factor'],
          p_id: prod['p_id'],
          product_id: prod['p_id'],
          tax_amount: parseFloat(prod['tax']),
          categ_id: prod['categ_id'],
          category: prod['category'],
          brand_id: prod['brand_id'],
          brand: prod['brand'],
          family: prod['family'],
          priority: prod['priority'],
          uom_id: prod['uom_id'],
          uom_category_id: prod['uom_category_id'],
          attribute_json: prod['attribute_json']
        });
      });
      console.log(bulkProdList);
      let createDiaRef;
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "85vh",
          maxWidth: "90%",
          maxHeight: "100%",
          panelClass: 'custom-dialog-container',
          data: {
            prodlist: bulkProdList,
            prodLines: this.rowData,
            activeObject: this.activeObject,
            OPERATION_MODE: this.OPERATION_MODE
          }
        };
        createDiaRef = this.dialog.open(BulkProductSelectPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "90%",
          maxHeight: "65%",
          panelClass: 'custom-dialog-container',
          data: {
            prodlist: bulkProdList,
            prodLines: this.rowData,
            activeObject: this.activeObject,
            OPERATION_MODE: this.OPERATION_MODE
          }
        };
        createDiaRef = this.dialog.open(BulkProductSelectPopupComponent, config);
      }

      createDiaRef.componentInstance.submit.subscribe(data => {
        this.rowData = data;
        this.gridApi.setRowData(this.rowData);
        this.compute_totals();
        this.dialog.closeAll();
      });
    }
  }

  addProductClick() {

    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      this.toastr.error('Invalid Customer for invoice');
      return;
    }
    // if (this.activeObject['user_id'] === undefined || this.activeObject['user_id'] === 0) {
    //   this.toastr.error('Please select the salesman');
    //   return;
    // }

    this.newMode = false;

    const new_line_uid = this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.stock, this.enteredQty, undefined);

    // this.compute_scheme_and_tax();
    this.compute_totals();
    this.gridApi.setRowData(this.rowData);

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.productSelector.focus();
  }

  removeProduct(index) {
    if (this.editMode) {
      const old_line = this.rowData[index];
      if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0) {
        this.rowData[index]['deleted'] = true;
        this.rowData[index]['quantity'] = 0;
        this.rowData[index]['reusable_qty'] = 0;

        this.gridApi.onFilterChanged();
      } else {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.compute_totals();
    }
  }



  //Page core functions
  saveCart() {


    //TODO: Complete basic validation of page and line items
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      this.toastr.error('Invalid Customer for invoice');
      return;
    }
    if (this.activeObject['user_id'] === undefined || this.activeObject['user_id'] === 0) {
      this.toastr.error('Please select the salesman');
      return;
    }
    this.rowData.forEach(line => {
      if (line['mid'] === undefined || line['mid'].length === 0) {
        this.toastr.error('Invalid mid for line');
        return;
      }
    });

    if (this.activeID <= 0) {
      this.activeObject.id = 0;
      this.activeObject.order_policy = 'manual';
      this.activeObject.type = 'P';
      this.activeObject.state = 'draft';
      this.activeObject.order_type = 'sale';
      this.activeObject.d_status = 'new';
      this.activeObject.p_status = 'new';
      this.activeObject.invoice_status = 'to invoice';
      this.activeObject.invoice_no = '';
      this.activeObject.po = '';
      this.activeObject.payment_mode = 'credit';
      this.activeObject.has_einvoice = false;
      this.activeObject.date_delivery = this.activeObject.date_order;
      this.activeObject.approved = false;
      this.activeObject.mid = this.get_mid(this.activeObject.distributor_id);
      this.activeObject.period_id = DetailPageBase.get_current_period_id(this.activeObject.date_order);
    }

    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {
      line.product_uom_qty = line.quantity;
      line.product_uom = line.uom_id;
      this.activeObject['lines'].push(line);
    });

    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_order";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {

        this.editMode = false;
        this.enableDisableGridEdit();
        this.loadPage();
      }
    });
  }


  action_dispatch_loadOut() {
    //
    // if (this.activeObject.lines === null || this.activeObject.lines.length === 0) {
    // this.userContext.data = to_invoice_obj;
    //
    // }
    let inthis = this;
    this.userContext.data['order_id'] = this.activeObject.id;
    this.userContext.data['schedule_date'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userContext.data['action'] = 'dispatched';

    this.apiService.post('/api/pwa_van_sales_connects/van_sale_dispatch', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.hasOwnProperty('id')) {
              const invoice_id = res.results.data['id'];
              // navigate to invoice.

              if (res.results.data.length > 0) {
                inthis.activeObject.d_status = res.results.data[0]['d_status'];

                inthis.toastr.success('Load Out Dispatched');
              }

            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  action_confirm_order() {
    let inthis = this;
    this.ConfirmOrders([this.activeObject.id], function(err, res) {
      if (res.hasOwnProperty('results') && res.results.status === 200) {

        if (res.results.data.length > 0) {
          inthis.activeObject.state = res.results.data[0]['state'];

          inthis.toastr.success('Order Confirmed');
        }

      } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
        this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      } else {
        this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
      }
    });
  }

  action_cancel() {
    let inThis = this;
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Cancel Order",
        message1: "Do you wish to cancel the order?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "NO",
        deleteButton: true
      }
    };
    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      if (data === 'yes') {
        this.CancelOrders([this.activeObject.id], function(err, res) {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            inThis.activeObject['state'] = 'cancel';
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        });
      } else {
        this.dialog.closeAll();
      }
    });



  }
}
