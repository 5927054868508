import {Component, OnInit} from "@angular/core";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {DeleteComponent} from "../../../deleteCell.component";
import {GetRowIdFunc, GetRowIdParams} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {ApproveRejectCommentsPopupComponent} from "../../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-payment-detail',
  templateUrl: 'payment_detail.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class PaymentDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  public show_approve_button = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_payment_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Payment Receipt');

    this.show_approve_button = this.sharedService.ACLcheck('feature_payment_approve');
    this.configureGrid();

    this.readPageParms(params => {

      if (this.activeID > 0) {
        this.newMode = false;
        this.editMode = false;
        //   View Mode
        //   Page Can be ready only after detail Object is fetched
        this.loadPageForView(() => {
          this.pageReady = true;
        });
      } else {
        this.newMode = true;
        this.editMode = true;
        //   Page Can be ready only after detail Object is fetched
        //    Throw Error
      }
    });
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'ORDER', field: 'order_no', width: 50},
      {headerName: 'INVOICE NO', field: 'invoice_no', width: 80},
      {headerName: 'CUSTOMER CODE', field: 'c_code', width: 50},
      {headerName: 'CUSTOMER NAME', field: 'c_name', width: 80},
      {headerName: 'AMOUNT', field: 'amount_total', width: 50},
      {headerName: 'DATE', field: 'date', width: 50},
    ];
  }


  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = lineItems;
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['supplier_id'] === undefined) {
        this.toastr.error('invalid state: supplier_id not found');
      }

      if (this.activeObject && this.activeObject['entries'] && this.activeObject.entries.length > 0) {
        this.rowData = this.activeObject.entries;
        this.gridOptions.api.setRowData(this.rowData);
      }

    });

  }

  action_edit() {

  }

  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'rejected') {
      title = "Reject Payment Receipt";
      message1 = "Are you sure you want to Reject Payment entry?";
      message2 = "Please provide a reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Payment Clearance";
      message1 = "Are you sure you want to Approve Payment Clearance?";
      message2 = "Please provide Comments if any";
      buttonText = "Approve";
    }

    let maxWidth = "450px";
    let maxHeight = "380px";

    if (screen.width > 991) {
      maxWidth = "90%";
      maxHeight = "65%";
    } else {

    }
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "90%",
      maxHeight: "65%",
      panelClass: 'custom-dialog-container',
      data: {
        title,
        message1,
        message2,
        type,
        buttonText
      }
    };
    const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
    creatediaeref.componentInstance.submitClicked.subscribe(comments => {
      this.approveRejectPayment( type, comments);
    });
  }

  approveRejectPayment(action, comments) {
    this.userContext['payment_id'] = this.activeID;
    this.userContext['action'] = action;
    this.userContext['comments'] = comments;
    this.apiService.post('/api/pwa_dms_connects/update_payment_status', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length === 1) {
              this.activeObject['state'] = res.results.data[0]['state'];
              // navigate to invoice.

              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  action_cancel() {

  }



  action_approve_claim() {

  }


  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }

}
