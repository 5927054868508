import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {GadgetInstanceService} from '../../grid/grid.service';
import {GadgetPropertyService} from '../_common/gadget-property.service';
import {GadgetBase} from '../_common/gadget-base';
import {Router} from '@angular/router';
import {OptionsService} from '../../configuration/tab-options/service';
import {ApiService} from '../../../_services/api.service';
import {ErrorHandler} from '../../../error/error-handler';
import {KpiHelper} from '../../services/kpi-helper';
import {DashboardFilterService} from "../../services/dashboard-filter.service";
import {LocalforageService} from "../../../_services/localforage.service";
import {MatDialog} from "@angular/material/dialog";
import {CustomConfigService} from "../../../_services/CustomConfigService";

declare var jQuery: any;

@Component({
    selector: 'app-dynamic-component',
    moduleId: module.id,
    templateUrl: './view.html',
    styleUrls: ['../_common/styles-gadget.css',
      '../_common/styles-gadget-common.scss']
})

// To Be Declared in following places
// 1. GadgetFactory.ts
// 2. Gadget module Declarations & Exports section
// 3. app.module.ts under GridModule
export class TreeGraphGadgetComponent extends GadgetBase {

    @ViewChild('chartOptionsSideBar_tag', {static: false}) chartOptionsSideBarRef: ElementRef;
    chartOptionsSideBar: any;

    treeData: any[] = [];
    state: string;

    RUN_STATE = 'run';
    STOP_STATE = 'stop';
    POLL_INTERVAL = 15000;

    // options
  gradient = false;
  animations = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  dataUpdated = false;
  treemap1: any[];
  treemapPath: any[] = [];

  constructor(protected _apiService: ApiService,
              protected _gadgetInstanceService: GadgetInstanceService,
              protected _propertyService: GadgetPropertyService,
              protected _changeDetectionRef: ChangeDetectorRef,
              protected _optionsService: OptionsService,
              protected _configService: CustomConfigService,
              protected _dashboardFilterService: DashboardFilterService,
              protected _router: Router,
              protected _localforageService: LocalforageService,
              protected _dialog: MatDialog,

  ) {
    super(_apiService,
      _gadgetInstanceService,
      _propertyService,
      _changeDetectionRef,
      _optionsService,
      _configService,
      _dashboardFilterService,
      _router,
      _localforageService,
      _dialog);

    this.treemap1 = [];
  }


  public preRun() {

        /**
         * the base class initializes the common property gadgets. Prerun gives
         * us a chance to initialize any of the gadgets unique properties.
         */

        this.dataUpdated = false;
        this.setAPIPath('/api/pwa_boards/get_static_kpi');
        this.initializeTheRemainderOfTheProperties();
        if (this.getPropFromPropertyPages('state') === this.RUN_STATE) {
            this.run();
        } else {
          this.showOperationControls = true;
        }
    }

    initializeTheRemainderOfTheProperties() {

        this.gradient = this.getPropFromPropertyPages('gradient');
        this.animations = this.getPropFromPropertyPages('animations');
        this.kpiName = this.getPropFromPropertyPages('kpi_name');

        // If not set in XML config set defults from Helper
        const kpiParams = KpiHelper.getKpiProperties(this.kpiName);
        if (kpiParams.hasOwnProperty('colorScheme')) {
          this.colorScheme = kpiParams.colorScheme;
        } else {
          // @ts-ignore
          this.colorScheme = 'cool';
        }
    }


    public run() {

        this.clearChartData();
        this.initializeRunState(true);
        this.updateData(null);
        // this.saveState(this.RUN_STATE);

    }

    clearChartData() {
        this.treemap1 = [];
    }


    public stop() {

        this.stopWithoutStateSave();
        this.saveState(this.STOP_STATE);
    }

    /**
     * The state is being saved to allow the board to load with the last state. Also, when the gadget is moved
     * within the board we need to carry the gadget's state along.
     */
    public saveState(state: string) {

        this.updateProperties('{\"state\":\"' + state + '\"}');
        // this.persistTheChangeInInternalState();

    }

    /**
     * When the gadget is destroyed (see ngOnDestroy) there is no need to
     * save the state. We just want to stop any API calls.
     */
    public stopWithoutStateSave() {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
        // const data = [];
        // Object.assign(this, {data});
        this.setStopState(false);

    }

    public updateData(someData: any[]) {
      const paramObject: any = {};
      paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
      paramObject.access_token = localStorage.getItem('resfreshToken');
      paramObject.kpi_name = this.kpiName;
      if (this.filterData != null) {
        paramObject.filterData = this.filterData;
      }
      this.dataUpdated = false;
      this.apiService.post(this.getAPIPath(), paramObject)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.treemapProcess(res.results.data);
            this.dataUpdated = true;
            this.stop();
            this.changeDetectionRef.detectChanges();
          } else {
            this.handleError(ErrorHandler.getErrorObject('Internal Error'));
          }
        }, error => this.handleError(ErrorHandler.getErrorObject(error)));
    }

    public drillDown(data) {

        this.stopWithoutStateSave();

        this._router.navigate(['/detail'], {
            queryParams:
                {
                    chartType: 'bar',
                    chartSeries: data.series,
                    chartMetric: data.name
                }
        });
    }


    private setInternalProperties(updatedPropsObject: any) {

        this.state = updatedPropsObject.state;

        if (updatedPropsObject.title !== undefined) {

            this.title = updatedPropsObject.title;
            this.kpiName = updatedPropsObject.kpi_name;
            this.gradient = updatedPropsObject.gradient;
            this.animations = updatedPropsObject.animations;
            this.showOperationControls = true;

            const kpiParams = KpiHelper.getKpiProperties(this.kpiName);
            if (kpiParams.hasOwnProperty('colorScheme')) {
              this.colorScheme = kpiParams.colorScheme;
            } else {
              // @ts-ignore
              this.colorScheme = 'cool';
            }
        }
    }

    /**
     * todo
     *  This is called from the dynamic property page form or when the internal running state changes
     *  A similar operation exists on the procmman-config-service
     *  whenever the property page form is saved, the in memory board model
     *  is updated as well as the gadget instance properties
     *  which is what the code below does. This can be eliminated with code added to the
     *  config service or the property page service.
     *
     */
    public updateProperties(updatedProperties: any) {
        const updatedPropsObject = JSON.parse(updatedProperties);


        /**
         * update this tools property pages
         */
        this.propertyPages.forEach(propertyPage => {
          // tslint:disable-next-line:prefer-for-of
            for (let x = 0; x < propertyPage.properties.length; x++) {
                for (const prop in updatedPropsObject) {
                    if (updatedPropsObject.hasOwnProperty(prop)) {
                        if (prop === propertyPage.properties[x].key) {
                            propertyPage.properties[x].value = updatedPropsObject[prop];
                        }

                    }
                }
            }
        });

        /**
         * update the tools internal state
         */
        this.setInternalProperties(updatedPropsObject);

    }

  public ngOnDestroy = () => {
      this.stopWithoutStateSave();
  }


    /**
     * todo - need to improve how internal state is saved to persistant store
     */
    private persistTheChangeInInternalState() {
        // const payLoad =
        //     '{"instanceId":' + this.instanceId
        //     + ',"title":"' + this.title
        //     + '","state":"' + this.state
        //     + '","gradient":' + this.gradient
        //     + '","gradient":' + this.animations
        //     + '"}';
        const payLoad =
        "{\"instanceId\":" + this.instanceId
        + ",\"title\":\"" + this.title
        + "\"}";


        this._configService.notifyGadgetOnPropertyChange(payLoad, this.instanceId);

    }

    toggleChartProperties() {

        if (this.globalOptions.displayGadgetOptionsInSideBar === false) {
            this.toggleConfigMode();
            return;
        }
        // this.chartOptionsSideBar = jQuery(this.chartOptionsSideBarRef.nativeElement);
        // this.chartOptionsSideBar.sidebar('setting', 'transition', 'overlay');
        // this.chartOptionsSideBar.sidebar('toggle');

    }



  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  labelFormatting(c) {
    return `${(c.label)} Sales`;
  }

  treemapProcess(data: any) {
    console.log('updating');
    this.treemap1 = data;
    // this.treemap1 = [children];
    this.treemapPath = [{ name: 'Top', children: [data], value : 1}];
  }

  treemapSelect(item) {
    let node;
    if (item.children) {
      const idx = this.treemapPath.indexOf(item);
      this.treemapPath.splice(idx + 1);
      this.treemap1 = this.treemapPath[idx].children;
      return;
    }
    node = this.treemap1.find(d => d.name === item.name);
    if (node.children) {
      this.treemapPath.push(node);
      this.treemap1 = node.children;
    }
  }
}
