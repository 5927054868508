import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ImageComparePopupComponent} from "../../components/image_compare_popup/image_compare_popup.component";

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class TargetAndVisitSummaryComponent extends ReportBase implements IReport, OnInit {


  setTitle() {
    this.title = 'Visit Summary Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Visit Summary Report');
    this.API_URL = '/api/pwa_reports/getSalesmanVistandTargetReport';

  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureGrid() {

    this.columnDefs = [

      {headerName: "REGION", field: "region", enableRowGroup: true, width: 60},
      {headerName: "EMP CODE", field: "emp_code", enableRowGroup: true, width: 60},
      {headerName: "SA CODE", field: "sales_area_code", enableRowGroup: true, width: 60},
      {headerName: "ID", field: "salesman_id", width: 60, hide:true},
      {headerName: "SALESMAN", field: "user_name", enableRowGroup: true, width: 60},
      {headerName: "TEAM", field: "team", enableRowGroup: true, hide: true, width: 60},
      {headerName: "TEAM LEAD", field: "team_lead", enableRowGroup: true, width: 60},
      {headerName: "TL ECODE", field: "tl_emp", enableRowGroup: true, width: 60},
      {headerName: "VERTICAL", field: "vertical", enableRowGroup: true, width: 60},
      {
        headerName: "Targets", children: []
      },
      {
        headerName: "BEAT",
        children: [{headerName: "COUNT", field: "beat_count", width: 30},
          {headerName: "Planned", field: "beat_planned", width: 30},
          {headerName: "Unattended", field: "unattended", width: 30}
        ]
      },
      {
        headerName: "PRIMARY",
        children: [
          {headerName: "COUNT", field: "primary_count", width: 30},
          {headerName: "Visited", field: "pri_completed", width: 30},
          {headerName: "Missed", field: "pri_missed", width: 30},
          {headerName: "Upcoming", field: "pri_upcoming", width: 30}
        ]
      },
      {
        headerName: "SECONDARY",
        children: [
          {headerName: "COUNT", field: "sec_count", width: 30},
          {headerName: "Visited", field: "sec_completed", width: 30},
          {headerName: "Missed", field: "sec_missed", width: 30},
          {headerName: "Upcoming", field: "sec_upcoming", width: 30}
        ]
      },
      {
        headerName: "PROJECT",
        children: [
          {headerName: "COUNT", field: "project_count", width: 30},
          {headerName: "Visited", field: "proj_completed", width: 30},
          {headerName: "Missed", field: "proj_missed", width: 30},
          {headerName: "Upcoming", field: "proj_upcoming", width: 30}
        ]
      }
    ];
  }

  configureData() {

    let index;
    this.rowData = [];

    const target_types =  [...new Set(this.apiData.targets.map(obj => obj.target_type))];

    target_types.forEach(target_key => {
      const measureArray = this.apiData.targets.filter(item => item.target_type === target_key);
      const measures =  [...new Set(measureArray.map(obj => obj.measure_type))];

      const target_type_header_column = {headerName: target_key, children: []};
      measures.forEach(measure => {
        const mKey = String(measure).toLowerCase().replace(/\s/g, "");
        const measure_header_column = {headerName: measure, children: [
            {headerName: 'Target', field: target_key + '_' + mKey + '_target'},
            {headerName: 'Achievement', field: target_key + '_' + mKey + '_ach'}
          ]};
        target_type_header_column.children.push(measure_header_column);
      });

      this.columnDefs[9].children.push(target_type_header_column);
    });

    this.apiData.master.forEach(masterRow => {
      const salesman_targets = this.apiData.targets.filter(item => item.salesman_id === masterRow.salesman_id);

      for (let index = 0; index < salesman_targets.length; index++) {
        const mKey = String(salesman_targets[index].measure_type).toLowerCase().replace(/\s/g, "");
        masterRow[salesman_targets[index].target_type + '_' + mKey + '_target'] = salesman_targets[index].target;
        masterRow[salesman_targets[index].target_type + '_' + mKey + '_ach'] = salesman_targets[index].achivement;
      }
      this.rowData.push(masterRow);
    });
  }
}

// m1.secondary_count,
//   m1.primary_count,
//   m1.project_count,
//   sum(completed) filter (where partner_type = 'secondary')  as sec_completed,
//   sum(missed)  filter (where partner_type = 'secondary') as sec_missed,
//   sum(upcoming)  filter (where partner_type = 'secondary') as sec_upcoming,
//   sum(completed) filter (where partner_type = 'primary')  as pri_completed,
//   sum(missed)  filter (where partner_type = 'primary') as pri_missed,
//   sum(upcoming)  filter (where partner_type = 'primary') as pri_upcoming,
//   sum(completed) filter (where partner_type = 'primary')  as proj_completed,
//   sum(missed)  filter (where partner_type = 'primary') as proj_missed,
//   sum(upcoming)  filter (where partner_type = 'primary') as proj_upcoming
//
