<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 5%">
      <div id="overlay"></div>
      <div  class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0" style="margin-bottom: 10px;">
                  <div class="row">
                    <div  *ngIf="!editMode && this.activeObject['order_id']" class="button-row">
                      <button
                        class="btn grey-button"
                        (click)="view_order()"
                      >VIEW ORDER
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="action_edit()"
                      >EDIT
                      </button>
                    </div>
<!--                    <div  *ngIf="!editMode && activeObject['state'] !== 'cancel'" class="button-row">-->
<!--                      <button-->
<!--                        class="btn grey-button"-->
<!--                        (click)="action_cancel()"-->
<!--                      >CANCEL-->
<!--                      </button>-->
<!--                    </div>-->
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="discard()"
                      >
                        DISCARD
                      </button>
                    </div>
                    <div  *ngIf="editMode" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="saveCart()"
                      >
                        SAVE
                      </button>
                    </div>
<!--                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">-->
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                        (click)="action_confirm_invoice()"-->
<!--                      >CONFIRM-->
<!--                      </button>-->
<!--                    </div>-->
                    <div  *ngIf="!editMode && activeObject['state'] === 'dispatched'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="action_mark_received()"
                      >Mark Received
                      </button>
                    </div>
<!--                    <div  *ngIf="!editMode && activeObject['state'] === 'delivered'" class="button-row">-->
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                        (click)="action_accept_returns()"-->
<!--                      >RETURNS-->
<!--                      </button>-->
<!--                    </div>-->
                    <div *ngIf="!newMode && !editMode" style="position: absolute;right: 40px;">
                      <div class="button-row">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">
              <div class="col-md-9 pt-3 pad0">
                <div class="col-md-12 pt-3 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">CUSTOMER</label>
                    <div  class="formselect">
                        <ng-select *ngIf="newMode"
                                   [items]="customerList"
                                   bindLabel="name"
                                   bindValue="id"
                                   (ngModelChange)="customerChangeEvent($event)"
                                   [(ngModel)]="activeObject['partner_id']">
                          >
                        </ng-select>
                      <p class="lbl3" *ngIf="!newMode">{{activeObject['customer_name']}}</p>
                    </div>
                    <div style="display: inline" *ngIf="!newMode">
                      <p style="display: inline" *ngIf="activeObject['customer_code']" class="lbl2">Code : {{activeObject['customer_code']}}</p>
                      <p style="display: inline" *ngIf="activeObject['customer_gst_id']" class="lbl2 ml-3">Tax Id : {{activeObject['customer_gst_id']}}</p>
                      <p *ngIf="activeObject['pricelist_name']" class="lbl2">Pricelist : {{activeObject['pricelist_name']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BILL TO/SUPPLIER</label>
                    <div class="formselect">
                      <ng-select *ngIf="newMode"
                                 [items]="supplierList"
                                 bindLabel="name"
                                 bindValue="id"
                                 (ngModelChange)="supplierChangeEvent($event)"
                                 [(ngModel)]="activeObject['supplier_id']">
                        >
                      </ng-select>
                    </div>
                    <div>
                      <p class="lbl3" *ngIf="!editMode && activeObject['supplier_name']">{{activeObject['supplier_name']}}</p>
                      <p *ngIf="activeObject['supplier_gst_id']" class="lbl2">Tax Id : {{activeObject['supplier_gst_id']}}</p>
                      <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{activeObject['supplier_code']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BEAT</label>
                    <div class="formselect">
                      <ng-select *ngIf="newMode"
                                 [items]="beatList"
                                 bindLabel="name"
                                 bindValue="id"
                                 (ngModelChange)="beatChange($event)"
                                 [(ngModel)]="activeObject['beat_id']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['beat_name'] && !newMode">{{activeObject['beat_name']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">SALESMAN</label>
                    <div class="formselect">
                      <ng-select *ngIf="userList && userList.length > 0 && newMode"
                                 [items]="userList"
                                 bindLabel="itemname"
                                 bindValue="id"
                                 (ngModelChange)="salesmanChange($event)"
                                 [(ngModel)]="activeObject['user_id']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['salesman'] && !newMode">{{activeObject['salesman']}}</p>
                        <p *ngIf="activeObject['emp_code']" class="lbl2">Code : {{activeObject['emp_code']}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pt-3 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <!--                    <label class="lbl">SHIP TO</label>-->
                    <!--                    <div *ngIf="shipto_adrr_list" class="formselect">-->
                    <!--                      <select-->
                    <!--                        *ngIf="shipto_adrr_list.length > 0 && editMode"-->
                    <!--                        name="suppaddrss"-->
                    <!--                        [(ngModel)]="activeObject['shipping_partner_id']"-->
                    <!--                        (ngModelChange)="shipaddrssChange($event)"-->
                    <!--                        #suppaddrss="ngModel"-->
                    <!--                        class="address-sel"-->
                    <!--                      >-->
                    <!--                        <option-->
                    <!--                          *ngFor="let item of shipto_adrr_list"-->
                    <!--                          [ngValue]="item"-->
                    <!--                        >-->
                    <!--                          {{ item.itemname }}-->

                    <!--                      </select>-->
                    <!--                      <div *ngIf="activeObject.shipto_address && (!editMode) ">-->
                    <!--                        <p class="lbl3" *ngIf="activeObject.shipto_address">{{activeObject.shipto_address}}</p>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <!--                    <label class="lbl">BILL TO</label>-->
                    <!--                    <div class="formselect"> &lt;!&ndash; *ngIf="billto_adrr_list" &ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <select&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        *ngIf="billto_adrr_list.length > 1 && editMode"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        name="custaddrss"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        [(ngModel)]="selected_billto_addr_id"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        (ngModelChange)="billaddrssChange($event)"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        #custaddrss="ngModel"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        class="address-sel"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <option&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          *ngFor="let item of billto_adrr_list"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          [ngValue]="item"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          {{ item.itemName }}&ndash;&gt;-->

                    <!--                      &lt;!&ndash;                      </select>&ndash;&gt;-->
                    <!--                      <div *ngIf="activeObject">-->
                    <!--                        <p class="lbl3" *ngIf="activeObject.billto_address">{{activeObject.billto_address}}</p>-->
                    <!--                        &lt;!&ndash;                        <p *ngIf="customer_billto_addr_code" class="lbl2">Code : {{customer_billto_addr_code}}</p>&ndash;&gt;-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>

                  <div class="col-md-2 pl-0 pr-30"  *ngIf="showPlantField">
                    <label class="lbl">PLANT</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="plantList && plantList.length > 0 && editMode"
                                 [items]="plantList"
                                 bindLabel="name"
                                 bindValue="code"
                                 (ngModelChange)="plantChangeEvent($event)"
                                 [(ngModel)]="this.activeObject['config_json']['plant']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="this.activeObject['config_json'] && !editMode">{{this.activeObject['config_json']['plant_name']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30"  *ngIf="showIncoTerms">
                    <label class="lbl">INCO TERMS</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="inco_terms_list && inco_terms_list.length > 0 && editMode"
                                 [items]="inco_terms_list"
                                 bindLabel="code"
                                 bindValue="code"
                                 [(ngModel)]="this.activeObject['config_json']['inco_term']">
                        >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="this.activeObject['config_json'] && !editMode">{{this.activeObject['config_json']['inco_term']}}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-3 pt-3 pad0">
                <div class="col-md-12 pt-3 pad0" *ngIf="newMode">
                  <label class="lbl">DATE</label>
                  <div>
                    <div>
                      <input
                        class="forminput1"
                        type="text"
                        name="date_order"
                        style="width:60%"
                        [disabled]="dateDisable"
                        [readonly]="true"
                        [(ngModel)]="activeObject['date_invoice']"
                        #date_order="ngModel"
                        [owlDateTime]="dt"
                        [owlDateTimeTrigger]="dt"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
                    <!--                    <p class="lbl3" [hidden]="sharedService.dmsParams.new_mode || (sharedService.dmsParams.edit_mode && !invEnable &&-->
                    <!--                             dmsStatus === 'confirm')">{{poDate}}</p>-->
                  </div>
                </div>
                <div class="col-md-12 pl-0 pr-0 shadow-box" *ngIf="!newMode && activeObject['id']">
                  <label class="lbl4" style="margin-left: 35%;margin-top:3%;">INVOICE INFORMATION</label>
                  <div style="margin-top:4%;padding-bottom: 8px;">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="lbl4">ID : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['id']}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="lbl4">INVOICE NO : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['name']}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="lbl4">GRN NO : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['grn']}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="lbl4">DATE : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['date_invoice'] + 'Z' | date:'yyyy-MM-dd'}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="lbl4">STATUS : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['state']}}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="activeObject['origin']">
                      <div class="col-md-4">
                        <label class="lbl4">ORDER : </label>
                      </div>
                      <div class="col-md-8">
                        <span class="lbl3" >{{activeObject['origin']}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0" *ngIf="editMode && !reference_order_mode">
              <div class="col-md-4 pl-0 pr-30">
                <label class="lbl">PRODUCT</label>
                <div class="formselect">
                  <ng-select
                             #productSelector
                             [items]="prodList"
                             bindLabel="product"
                             bindValue="p_id"
                             [virtualScroll]="true"
                             [(ngModel)]="selectedProduct"
                             (ngModelChange)="productSelectorChange()"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">QTY</label>
                <div>
                  <div>
                    <input
                      class="forminput1"
                      type="text"
                      name="qty"
                      onlyNumber
                      [(ngModel)]="enteredQty"
                      #qty="ngModel"
                      style="width: 100%;"
                      (keydown.enter)="addProductClick()"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30" *ngIf="UOMList">
                <label class="lbl">UOM</label>
                  <div class="formselect">
                    <ng-select
                      #productSelectorUOM
                      [items]="UOMList"
                      bindLabel="name"
                      bindValue="id"
                      (ngModelChange)="productUOMChange($event)"
                      [(ngModel)]="selectedUOM_id"
                    >
                    </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">STOCK</label>
                <div>
                  <div>
                    <input
                      class="forminput1"
                      type="text"
                      name="stck"
                      [(ngModel)]="stock"
                      [disabled]="true"
                      style="width: 100%;"
                      #stck="ngModel"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pt-4 pl-0 pr-30">
                <button class="teal-btn2"
                        (click)="addProductClick()"
                >
                  ADD
                </button>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0" *ngIf="editMode && applicableSchemes && applicableSchemes.length > 0">
              <label class="lbl"> {{applicableSchemes.length}}  Schemes Applicable</label>
            </div>
            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 [context]="context"
                                 [isExternalFilterPresent]="isExternalFilterPresent"
                                 [doesExternalFilterPass]="doesExternalFilterPass"
                                 (cellEditingStarted)="onCellEditingStarted($event)"
                                 (cellEditingStopped)="onCellEditingStopped($event)"
                >
                </ag-grid-angular>
              </div>
            </div>
            <div class="col-md-12 pt-4 pad0">
              <div class="col-md-4 pl-0 pr-30">
                <label class="lbl">INSTRUCTIONS</label>
                <div class="formselect">
                  <div *ngIf="editMode">
                  <textarea style="background-color: #F5F5FF" class="form-control"
                            rows="4" id="comment" [(ngModel)]="this.activeObject['comment']"></textarea>
                  </div>
                  <p class="lbl3" *ngIf="!editMode">{{this.activeObject['comment']}}</p>
                </div>
              </div>
              <div  class="col-md-4 mt-2" style="margin-top: 20px; !important">
                <div *ngIf="activeObject['activity_json']">
                  <label class="lbl">ACTIVITIES</label>
                  <div class="history-tl-container">
                    <ul class="tl">
                      <li *ngFor="let activity of activeObject['activity_json']" ng-repeat="item in retailer_history">
                        <div class="item-title">{{activity.s}}</div>
                        <div class="item-detail">{{activity.t + 'Z' | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                        <div class="lbl2">{{activity.m}}</div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject" class="col-md-4 mt-2">
                <table style="float: right;">
                  <tbody>
                  <tr *ngIf="activeObject['scheme_discount']">
                    <td ><p class="p-class">Scheme Discount</p></td>
                    <td ><p class="p-class1">{{math.abs(activeObject['scheme_discount']).toFixed(apiService.decimalPolicy)}}</p></td>
                  </tr>
                  <tr *ngIf="activeObject['amount_untaxed']">
                    <td ><p class="p-class">Taxable Amount</p></td>
                    <td ><p class="p-class1">{{math.abs(activeObject['amount_untaxed']).toFixed(apiService.decimalPolicy)}}</p></td>
                  </tr>
                  <tr *ngIf="activeObject['amount_tax']">
                    <td ><p class="p-class">Taxes</p></td>
                    <td ><p class="p-class1">{{math.abs(activeObject['amount_tax']).toFixed(apiService.decimalPolicy)}}</p></td>
                  </tr>
                  <tr *ngIf="showTotalWeight">
                    <td ><p class="p-class">Total Weight</p></td>
                    <td ><p class="p-class1">{{totalWeightFieldValue}}</p></td>
                  </tr>
                  <tr *ngIf="showTotalMeasure">
                    <td ><p class="p-class">Total Measure</p></td>
                    <td ><p class="p-class1">{{totalMeasureFieldValue}}</p></td>
                  </tr>
                  <tr style=" border-top: 2px solid #f3f3f3;"  *ngIf="activeObject['amount_total']">
                    <td ><p class="p-class3">GRAND TOTAL</p></td>
                    <td ><p class="p-class4">{{math.abs(activeObject['amount_total']).toFixed(apiService.decimalPolicy)}}</p></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

