import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../../../_services/shared_service";

@Component({
  selector: 'app-payment-advance-popup',
  templateUrl: './payment_advance_popup.component.html',
  styleUrls: ['./payment_advance_popup.component.scss']

})

export class PaymentAdvancePopupComponent implements OnInit {

  orderDetails: any = {};
  paymentDetails: any = {};
  order_id;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.order_id = dialogdata.id;
    this.paymentDetails.payment_mode = 'upi';
    this.getOrderDetails();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getOrderDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.order_id
    };
    this.apiService.post('/api/pwa_dms_connects/get_order_detail', paramObject)
      .subscribe(res => {
        console.log(res);
        // t.stop();
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.orderDetails = res.results.data;
        }
      });
  }

  onAmountChange() {

  }

  collectPayment() {

  }
}
