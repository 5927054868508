<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 50px!important">
      <div style="background-color: white;margin-left: 5px;margin-right: 25px;height:88vh;margin-top: 0px;">
        <div style="margin:10px;">
          <div>
            <div>
              <div *ngIf="loaded" class="search-list" style="margin-top: 0px;">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 20px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="edit && taskDetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn confirm-blue-btn"
                        form="ngForm"
                        [disabled]="taskform.form.invalid"
                      >
                        SAVE
                      </button>
                      <span>
                         <a *ngIf="viewtype!='create'" class="btn grey-btn" (click)="discardClick()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!edit && taskDetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <span *ngIf="task_type.type !== 'spl'">
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                      <span *ngIf="showCancel">
                        <a class="btn grey-btn" (click)="cancelClick()">CANCEL</a>
                      </span>
                      <button *ngIf="showApprove"
                              (click)="openApproveRejectPopup('approve')"
                              mat-button
                              style="margin-right:15px;"
                              class="btn confirm-green-btn"
                      >
                        Approve
                      </button>
                      <button *ngIf="showApprove"
                              (click)="openApproveRejectPopup('reject')"
                              mat-button
                              style="margin-right:15px;"
                              class="btn reject-btn"
                      >
                        Reject
                      </button>
                      <button *ngIf="showSubmit"
                              (click)="openSubmitDialog()"
                              mat-button
                              style="margin-right:15px;"
                              class="btn confirm-blue-btn"
                             >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 20px;margin-top: 0px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR" style="margin-right: 20px;margin-top: 10px;">
                        <span *ngIf="!edit">
                         <a class="btn teal-border-btn" style="margin-right:15px;"
                            (click)="viewReport()">VIEW REPORT</a>
                        </span>
                      </li>
                      <li class="margLR" *ngIf="viewtype!='create'">
                        <div style="width: 200px;margin-top: 15px;">
                          <label for="file" class="progresslabel">
                            PROGRESS</label>
                          <label *ngIf="taskDetails.progress<30" class="progresslabelcount"
                                 style="color:#B82525">{{taskDetails.completed_count}}</label>
                          <label *ngIf="taskDetails.progress>30"
                                 class="progresslabelcount">{{taskDetails.completed_count}}</label>
                          <label style="color: black;font-size: 12px;"> /{{taskDetails.line_count}}</label>
                          <progress id="file" max="100" value="{{taskDetails.progress}}"
                                    style="margin-top: 5px;"></progress>
                        </div>
                      </li>
                      <li class="margLR" *ngIf="taskDetails.status=='published'"
                          style="margin-left: 5px;margin-bottom: 5px;">
                        <div style="margin-top: 15px;margin-left: 10px;margin-right: 10px;">
                          <img *ngIf="taskDetails.status=='published' && taskDetails.active"
                               style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/tickteal.svg">
                          <img *ngIf="taskDetails.status=='published' && !taskDetails.active"
                               style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/crossred.svg">
                          <span *ngIf="taskDetails.status=='published' && taskDetails.active"
                                class="activetext">ACTIVE</span>
                          <span *ngIf="taskDetails.status=='published' && !taskDetails.active" class="activetext"
                                style="color:#B82525">INACTIVE</span>
                        </div>
                      </li>
                      <li class="margLR" style="margin-left: 10px;"
                          *ngIf="!edit && taskDetails!=null && taskDetails.status!='published' && taskDetails['line_items']!=null && taskDetails['line_items'].length>0">
                        <button
                          mat-button
                          style="margin-left:15px; margin-right:15px;margin-top: 10px;"
                          class="btn confirm-blue-btn"
                          (click)="openPublishDialog()"
                        >
                          PUBLISH
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pad0">
              <form novalidate (ngSubmit)="createOrUpdateTask()" id="ngForm" #taskform="ngForm">
                <div class="col-md-12 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="detaillabel" style="margin-top: 20px;">Task</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit && task_type.type !== 'spl'">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="taskname"
                          [(ngModel)]="taskDetails.name"
                          #taskname="ngModel"
                          required
                        >
                        <div *ngIf="taskname.errors && (taskname.dirty || taskname.touched)">
                          <span *ngIf="taskname.errors.required" class="text-danger">Task name is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit || task_type.type === 'spl'" class="detailvalue1">{{taskDetails.name}}</p>
                      <p class="detailvaluedesc">Code : {{taskDetails.code}}</p>
                      <!-- <div class="mt-2 centeralign"
                            style="border: 1px solid #E89221; border-radius: 5px;padding: 10px;background: #FFF8F0;text-align: center;">
                         <span style="color: #E89221; font-size: 14px;font-weight: 400;margin-left: auto;">View Additional Info</span>
                         <img src="./../../../assets/images/warning_orange.svg"
                              style="margin-left: 5px;margin-right: auto;">
                       </div>-->
                    </div>
                  </div>
                  <div class="col-md-9 pl-0 pr-30">
                    <div class="mt-2"
                         style="border: 1px solid #D7DDE6; border-radius: 3px;padding: 20px;background: #F5F7F9">
                      <div style="display: flex">
                        <div style="flex: 1">
                          <label class="detaillabel">Type</label>
                          <div style="margin-top: 5px;" class="formselect">
                            <select
                              *ngIf="edit && task_type.type !== 'spl'"
                              name="task_type"
                              [(ngModel)]="taskDetails.type_id"
                              #task_type="ngModel"
                              style="width: 80%;"
                              (change)="checkAllowStartDate()"
                            >
                              <option
                                *ngFor="let item of task_types"
                                value="{{ item.id }}"
                              >
                                {{ item.itemname }}
                              <option value="Activation" selected>Activation</option>
                              <option value="BTL Activity">BTL Activity</option>
                              <option value="Scheme">Scheme</option>
                            </select>
                            <p *ngIf="!edit || task_type.type === 'spl'"
                               class="detailvalue1">{{taskDetails.type_name}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Brand</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails.brand}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Category</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails?.category}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Channel</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails?.channel}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Description</label>
                          <div style="margin-top: 5px;">
                            <div *ngIf="edit && task_type.type !== 'spl'">
                              <input
                                class="forminputwhitebg"
                                style="width: 70%;"
                                type="text"
                                name="taskdescription"
                                [(ngModel)]="taskDetails.description"
                                #taskdescription="ngModel"
                                required
                              >
                              <div *ngIf="taskdescription.errors">
                                <span *ngIf="taskdescription.errors.required" class="text-danger">Description is required</span>
                              </div>
                            </div>
                            <p *ngIf="!edit || task_type.type === 'spl'"
                               class="detailvalue1">{{taskDetails.description}}</p>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="task_type.type === 'spl'" class="mt-3" style="display: flex">
                        <div style="flex: 1">
                          <label class="detaillabel">State</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails?.state}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Region</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails?.region}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Cluster</label>
                          <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                            <p class="detailvalue1">{{taskDetails?.cluster}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">Start Date</label>
                          <div style="margin-top: 5px;">
                            <div *ngIf="edit && task_type.type !== 'spl'">
                              <input
                                class="forminputwhitebg"
                                type="text"
                                name="taskstart_date"
                                [(ngModel)]="taskDetails.start_date"
                                #taskstart_date="ngModel"
                                [owlDateTime]="dt1"
                                [owlDateTimeTrigger]="dt1"
                                required
                              >
                              <owl-date-time (afterPickerClosed)="setStartDate(dt1)" [pickerType]="'calendar'"
                                             #dt1></owl-date-time>
                              <div *ngIf="taskstart_date.errors && (taskstart_date.dirty || taskstart_date.touched)">
                                <span *ngIf="taskstart_date.errors.required"
                                      class="text-danger">Start Date is required</span>
                              </div>
                            </div>
                            <p *ngIf="!edit || task_type.type === 'spl'"
                               class="detailvalue1">{{taskDetails.start_date}}</p>
                          </div>
                        </div>
                        <div style="flex: 1">
                          <label class="detaillabel">End Date</label>
                          <div style="margin-top: 5px;">
                            <div *ngIf="edit && task_type.type !== 'spl'">
                              <input
                                class="forminputwhitebg"
                                type="text"
                                name="taskend_date"
                                #taskend_date="ngModel"
                                [(ngModel)]="taskDetails.end_date"
                                [owlDateTime]="dt2"
                                [owlDateTimeTrigger]="dt2"
                                required
                              >
                              <owl-date-time (afterPickerClosed)="setEndDate(dt2)" [pickerType]="'calendar'"
                                             #dt2></owl-date-time>
                              <div *ngIf="taskend_date.errors && (taskend_date.dirty || taskend_date.touched)">
                                <span *ngIf="taskend_date.errors.required"
                                      class="text-danger">End Date is required</span>
                              </div>
                            </div>
                            <p *ngIf="!edit || task_type.type === 'spl'"
                               class="detailvalue1">{{taskDetails.end_date}}</p>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="task_type.type === 'spl' && configFields" class="mt-3">
                        <div class="cardgrid mt-2" style="grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">
                          <ng-container *ngFor="let config of configFields; let i = index">
                            <div>
                              <label class="detaillabel">{{config.name}}</label>
                              <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                                <p class="detailvalue1">{{config.value}}</p>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="task_type.type === 'spl'" class="mt-4 col-md-12 pad0">
                  <div style="border: 1px solid #0762AD; border-radius: 5px;padding: 15px;background: #EBF2F8;">
                    <div class="row centeralign" style="cursor: pointer" (click)="detailsClick()">
                      <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Additional Details</span>
                      <img *ngIf="!showDetails" style="position: absolute; right: 30px;height: 8px;"
                           src="../../../assets/images/down_arrow_black.svg"/>
                      <img *ngIf="showDetails" style="position: absolute; right: 30px;height: 8px;"
                           src="../../../assets/images/up_arrow.svg"/>
                    </div>
                    <div class="cardgrid mt-2" *ngIf="showDetails">
                      <ng-container *ngFor="let tile of taskParams">
                        <div>
                          <span style="color: #5D5D5D; font-size: 12px; font-weight: 400;">{{tile.key}} : </span>
                          <span style="color: #2D2D2D; font-size: 12px; font-weight: 400;">{{tile.value}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="task_type.type === 'spl' && (rejectComments || approveComments)" class="mt-4 centeralign col-md-12 pad0"
                     [ngClass]="{'approveCard': approveComments, 'rejectCard': rejectComments}">

                  <div class="row centeralign">
                    <span *ngIf="rejectComments" style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span>
                    <span *ngIf="approveComments" style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span>
                    <span style="color: #2D2D2D; font-size: 14px;" >{{displayStatus}}</span>
                    <div class="row centeralign" style=" position: absolute;right: 30px;">
                       <span
                         style="color:#5D5D5D; font-size: 14px; font-weight: 400;">{{reviewDate}}</span>
                      <span class="greyindicator" style="margin-left: 30px;margin-right: 10px;"></span>
                      <span *ngIf="rejectComments"
                        style="color:#5D5D5D; font-size: 14px; font-weight: 400;">{{rejectComments}}</span>
                      <span *ngIf="approveComments"
                            style="color:#5D5D5D; font-size: 14px; font-weight: 400;">{{approveComments}}</span>
                    </div>

                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div class="mt-4" style="display: flex">
                    <div *ngIf="task_type.type === 'spl'" style="flex: 1">
                      <label class="detaillabel">Period</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <p class="detailvalue1">{{taskDetails?.period}}</p>
                      </div>
                    </div>
                    <div style="flex: 1">
                      <label class="detaillabel">Task Created Date</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <p class="detailvalue1">{{taskCreateDate}}</p>
                        <p class="detailvaluedesc">Created By : {{taskDetails.created_by}}</p>
                      </div>
                    </div>
                    <div *ngIf="task_type.type === 'spl'" style="flex: 1">
                      <label class="detaillabel">Details Submitted Date</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <p class="detailvalue1">{{detailsSubmittedDate}}</p>
                        <p class="detailvaluedesc">Submitted By : {{taskDetails.submitted_by}}</p>
                      </div>
                    </div>
                    <div *ngIf="task_type.type === 'spl'" style="flex: 1">
                      <label class="detaillabel">Review Date</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <p class="detailvalue1">{{reviewDate}}</p>
                        <p
                          *ngIf="!taskDetails?.config_json?.reject_reason || taskDetails?.config_json?.reject_reason === 'null'"
                          class="detailvaluedesc">Approved By : {{taskDetails.reviewed_by}}</p>
                        <p
                          *ngIf="taskDetails?.config_json?.reject_reason && taskDetails?.config_json?.reject_reason !== 'null'"
                          class="detailvaluedesc">Rejected By : {{taskDetails.reviewed_by}}</p>
                        <!--<p *ngIf="taskDetails?.config_json?.reject_reason && taskDetails?.config_json?.reject_reason !== 'null'" class="detailvaluedesc" style="color: #E24260;">{{taskDetails.config_json.reject_reason}}</p>-->
                      </div>
                    </div>
                    <div *ngIf="task_type.type === 'spl'" style="flex: 1">
                      <label class="detaillabel">Retailer Uploaded Date</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <p class="detailvalue1">{{retailerAddedDate}}</p>
                        <p class="detailvaluedesc">Uploaded By : {{taskDetails.ret_added_by}}</p>
                      </div>
                    </div>
                    <div style="flex: 2">
                      <label class="detaillabel">Instructions</label>
                      <div style="margin-top: 5px;  white-space: pre-wrap;word-wrap: break-word;">
                        <div *ngIf="edit && task_type.type !== 'spl'">
                          <textarea
                            class="forminput"
                            type="text"
                            name="taskinstructions"
                            [(ngModel)]="taskDetails.instructions"
                            #taskinstructions="ngModel"
                            style="width: 100%;"
                          ></textarea>
                          <div *ngIf="taskinstructions.errors && (taskinstructions.dirty || taskinstructions.touched)">
                            <span *ngIf="taskinstructions.errors.required" class="text-danger">Task description is required</span>
                          </div>
                        </div>
                        <p *ngIf="!edit || task_type.type === 'spl'" style="width:100%;"
                           class="detailvalue1">{{taskDetails.instructions}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div style="margin-top: 50px;">
              <div class="search-list">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;">
                  <div class="search" style="display: inline-block;">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                           class="search-control-area"
                           placeholder="Search">
                  </div>
                  <div style="display: inline-block;margin-right: 10px;">
                    <div style="display: inline-block;margin-top: 5px;margin-left:15px;cursor:pointer;">
                      <button *ngIf="edit && task_type.type!== 'spl'" class="icon-btn-small confirm-blue-btn"
                              (click)="createDialog()"
                      ><i class="fas fa-plus-circle fa-2x"></i>
                        Bulk Retailer Selection
                      </button>
                      <button *ngIf="task_type.type === 'spl' && taskDetails.status === 'approved' && upload_retailer"
                              class="icon-btn-small confirm-blue-btn" style="margin-left: 15px;"
                              (click)="openRetailereUploadPopup()">
                        <i class="fal fa-upload fa-2x"></i>
                        Upload Retailer Sheet
                      </button>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR">
                        <app-excelicon [gridOption]="gridOptions" [title]="'Task Lines Report'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:30vh;margin-top: 10px;"
                                     class="ag-theme-balham" [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                  <!--  <p *ngIf="edit" style="font-size: 12px;color:#17A2B8;margin-top: 10px;margin-left: 10px;cursor:pointer" (click)="createDialog()">Add an Item</p>-->
                  <!-- <img *ngIf="edit"
                        style="margin-top: 10px;margin-left: 10px;cursor:pointer"
                        title="Add Item"
                        (click)="createDialog()"
                        src="./../../../assets/additem.svg" />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="invoiceModal" role="dialog">
  <div class="modal-dialog" style="width: 300px;">
    <!-- Modal content-->
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <img [src]="photo">
      </div>
    </div>
  </div>
</div>

<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#invoiceModal">Open Modal</button>
