<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0" style="margin-bottom: 10px;">
                  <div class="row">

<!--                    <div  *ngIf="!editMode && activeObject['status'] === 'draft'" class="button-row">-->
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                        (click)="action_edit()"-->
<!--                      >EDIT-->
<!--                      </button>-->
<!--                    </div>-->
                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="openApproveRejectPopup('posted')"
                      >APPROVE
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">
                      <button
                        class="btn grey-button"
                        (click)="openApproveRejectPopup('rejected')"
                      >REJECT
                      </button>
                    </div>

                    <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                      <div class="button-row">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-3 pad0"  *ngIf="!newMode">
                <div class="col-md-12 pt-3 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">PAYMENT BY</label>

                    <div>
                      <p class="lbl3" *ngIf="activeObject['partner_name']">{{activeObject['partner_name']}}</p>
                      <p *ngIf="activeObject['partner_gst_id']" class="lbl2">Tax Id : {{activeObject['partner_gst_id']}}</p>
                      <p *ngIf="activeObject['partner_code']" class="lbl2">Code : {{activeObject['partner_code']}}</p>
                    </div>
                  </div>

                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">PAYMENT TO</label>

                    <div>
                      <p class="lbl3" *ngIf="activeObject['supplier_name']">{{activeObject['supplier_name']}}</p>
                      <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{activeObject['supplier_code']}}</p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">AMOUNT</label>
                    <div>
                      <p class="lbl3">{{activeObject['amount']}}</p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">TYPE</label>
                    <div>
                      <p class="lbl3">{{activeObject['type']}}</p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">STATUS</label>
                    <div>
                      <p class="lbl3">{{activeObject['state']}}</p>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [context]="context">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

