import {Component, OnInit, ElementRef, Renderer2, ComponentFactoryResolver, ViewChild} from '@angular/core';
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import {AllModules, GridOptions} from '@ag-grid-enterprise/all-modules';
import {AdDirective} from "../ad.directive";
import {ReportDropdownComponent} from "../components/report_dropdown/report-dropdown.component";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-loadin-report-v2',
  templateUrl: './loadin-report-v2.component.html',
  styleUrls: ['./loadin-report-v2.component.scss']
})
export class LoadinReportV2Component implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild('dp') dp: ElementRef<HTMLElement>;
  user;
  res_data;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    dc_id: [],
    flag: true,
    zip_flag: 'normal'
  };
  public modules = AllModules;
  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name = "LOADIN REPORT";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public first_hide = false;
  public all_dc = [];
  public default = true;
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ["", 0];
  public selectedZipDc = "0";
  public zip_url = "";
  public all_dc_flag = false;
  public role_features = [];
  public zipDate = [];

  constructor(private apiService: ApiService,
              public sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {
    if (this.sharedService.ACLcheck('feature_all_dc_filter')) {
      this.all_dc_flag = true;
    }
    this.zipDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.zipDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.loadinreport();
    this.gridOptions = { rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }} as GridOptions;

  }

  loadinreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    let total = 0;
    const datePipe = this.datePipe;
    this.res_data = this.apiService.loadinReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (!this.user_data.flag) {
          if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
            res['results'].data.forEach(data => {
              total = parseInt(data.opening, 10) + parseInt(data.sales, 10) + parseInt(data.closing, 10);
              if (total !== 0) {
                const pushData = {
                  Date: datePipe.transform((new Date(data.stock_date + ' UTC')).toString(), 'dd/MM/yy'),
                  State: data.dc_state,
                  Region: data.region,
                  DC: data.dc,
                  Van: data.user,
                  "Product Code": data.product_code,
                  Product: data.product,
                  Balance: data.loadin,
                  Loadout: parseInt(data.loadout, 10),
                  Opening: parseInt(data.opening, 10),
                  Sales: parseFloat(data.sales),
                  Damage: parseInt(data.damage, 10),
                  Sample: parseInt(data.sample, 10),
                  Promo: parseInt(data.promo, 10),
                  Return: parseInt(data.return, 10),
                  Closing: parseInt(data.closing, 10),
                  Cancelled: parseInt(data.cancelled, 10),
                  Deviation: parseInt(data.deviation, 10)
                };
                rowdata.push(pushData);
              }
            });
            this.data = rowdata[0];
            this.rowData = rowdata;
            for (const key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key === 'Date' || key === 'User ID' || key === 'Product ID') {
                  this.columnDefs.push({headerName: key, field: key, width: 100, aggFunc: 'sum'});
                } else if (key === 'Van') {
                  this.columnDefs.push({ headerName: key, field: key, width: 220,  aggFunc: 'sum'});
                } else if (key === 'Product') {
                  this.columnDefs.push({headerName: key,  field: key, width: 220, aggFunc: 'sum'});
                } else if (key === 'Product Code' || key === 'Opening' || key === 'Sales' || key === 'Damage' || key === 'Deviation' || key === 'Closing' ||
                  key === 'Sample' || key === 'Promo' || key === 'Return' || key === 'Cancelled') {
                  this.columnDefs.push({
                    headerName: key, field: key, hide: true,
                    width: 90, aggFunc: 'sum', cellStyle: {textAlign: "right"},
                  });
                } else if (key === 'Balance' || key === 'Loadout') {
                  this.columnDefs.push({
                    headerName: key, field: key,
                    width: 90, aggFunc: 'sum', cellStyle: {textAlign: "right"},
                  });
                } else {
                  this.columnDefs.push({ headerName: key, field: key, width: 90, aggFunc: 'sum' });
                }
              }
            }
            this.loaded = true;

          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        } else {
          if (this.all_dc_flag) {
            this.all_dc = [{id: 0, itemName: "All DC", checked: false}];
            Array.prototype.push.apply(this.all_dc, res['results'].dcs);
          } else {
            this.all_dc = res['results'].dcs;
          }
          this.dcList();
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  goToLog() {
    localStorage.setItem('log_params', JSON.stringify({start_date: this.user_data.date, last_date: this.user_data.last_date}));
    this.router.navigate([this.apiService.redirect_url + "/logs"] );
  }

  dcList() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDropdownComponent);
    const componentRef = viewContainerRef.createComponent<ReportDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.all_dc;
    if (this.default || this.user_data.dc_id.length === 0) {
      componentRef.instance.selectedItems = [this.all_dc[this.all_dc.length - 1]];
    } else {
      componentRef.instance.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    }
    this.user_data.dc_id = [componentRef.instance.selectedItems[0].id];
    componentRef.instance.statusChange.subscribe(res => {
      this.default = false;
      const dc_ids = [];
      res.forEach(each_dc => {
        dc_ids.push(each_dc.id);
      });
      this.user_data.dc_id = dc_ids;
    });
  }

  run() {
    this.user_data.flag = false;
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.loadinreport();
    }
  }

  dropDown() {
    const el: HTMLElement = this.dp.nativeElement;
    if (el.classList.contains('visible')) {
      this.renderer.removeClass(el, 'visible');
    } else {
      this.renderer.addClass(el, 'visible');
    }
  }

  close() {
    this.zip_loader = false;
    this.zip_ready = false;
  }

  generateZip() {
    this.zip_loader = true;
    this.zip_ready = false;
    this.user_data.zip_flag = 'zip';
    this.user_data.date = this.datePipe.transform(this.zipDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.zipDate[1], 'yyyy-MM-dd');
    if (this.all_dc_flag) {
      this.user_data.dc_id = this.all_dc.reduce((a, o) => (o.checked && a.push(o.id), a), []);
    } else {
      this.user_data.dc_id = [parseInt(this.selectedZipDc, 10)];
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.zipDate[0];
    const secondDate = this.zipDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (this.all_dc_flag && diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (this.user_data.dc_id.length === 0) {
      window.alert('Please Select 1 DC');
      this.zip_loader = false;
    } else if (!this.all_dc_flag && parseInt(this.selectedZipDc, 10) === 0 && diffDays > 7) {
      window.alert('Please Select 1 week data for all DC');
      this.zip_loader = false;
    } else {
      this.user_data.flag = false;
      this.res_data = this.apiService.loadinReport(this.user_data);
      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.zip_ready = true;
        }
        this.zip_loader = false;
      }, error => {
        console.log(error);
        this.zip_loader = false;
      });
    }
  }
  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            // params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      // params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }

  showAll(event) {
    this.first_hide = !this.first_hide;
    this.gridColumnApi.setColumnsVisible(['Opening', 'Sales', 'Damage', 'Deviation', 'Closing'], this.first_hide);
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

  }
}
