<div class="sidebar">
  <div class="row">
    <ul class="main list-unstyled" style="padding-top: 12%;margin-top: 0px;">
      <li id="dashboard" class="mainli" style="margin-top: 0px;">
        <a id="a_dashboard" (click)="goToDashboard()" class="menuText">
          <img id="img_dashboard" src="./../../../assets/menu/dashboard.svg"/>
        </a>
      </li>
      <ng-container *ngFor="let menu of sharedService.menuList; let i = index">
        <!-- <li routerLinkActive="active" class="mainli">
           <a class="menuText" routerLink="{{menu.route}}" routerLinkActive="active" >
             <img [src]="getIcon(menu.icon)"/>
             {{menu.name}}
           </a>-->

        <li class="mainli" id="{{menu.name.toString().toLowerCase().replace(' ', '_')}}">
          <a (click)="changeActive(menu.route)">
            <img id="img_{{menu.name.toString().toLowerCase().replace(' ', '_')}}" [src]="getIcon(menu.icon)"
                 style="padding: 0px 15px 4px 15px;"/>
            <label id="a_{{menu.name.toString().toLowerCase().replace(' ', '_')}}"> {{menu.name}}</label>
          </a>
          <div class="row" *ngIf="menu.sub_menu.length > 0">
            <ul id="ul_{{menu.name.toString().toLowerCase().replace(' ', '_')}}">
              <ng-container *ngFor="let submenu of menu.sub_menu; let j = index">
                <li class="subli"
                    id="{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"
                    (click)="changeActive(submenu.route)">
                  <a style="color: #2D2D2D;">
                    <img *ngIf="submenu.icon && submenu.icon !== ''"
                      id="img_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"
                      [src]="getIcon(submenu.icon)" style="width: 30px;padding-right: 5px;"/>
                    <label *ngIf="submenu.icon && submenu.icon !== ''"
                      id="a_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"> {{submenu.name}}</label>
                    <label *ngIf="!submenu.icon || submenu.icon === ''" style="margin-left: 30px;"
                           id="a_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"> {{submenu.name}}</label>
                  </a>
                </li>
              </ng-container>
              <img style="margin-top: auto;" src="./../../../assets/menu/salesdiary_logo.svg"/>
            </ul>
          </div>
        </li>
      </ng-container>
      <li id="report" class="mainli" style="margin-top: 5px;">
        <a (click)="gotoReports()">
          <img id="img_report" src="./../../../assets/menu/report.svg" style="padding: 0px 15px 4px 15px;"/>
          <label id="a_report">Reports</label>
        </a>
      </li>
    </ul>
  </div>
</div>
