import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {FooterComponent} from './components/footer/footer.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RouterModule} from '@angular/router';
import {PopupComponent} from './popup/popup.component';
import {HeaderComponent} from './components/header/header.component';
import {SearchbarComponent} from './components/searchbar/searchbar.component';
import {PrinticonComponent} from './components/printicon/printicon.component';
import {ExceliconComponent} from './components/excelicon/excelicon.component';
import {VanComponent} from './components/van/van.component';
import {StatusComponent} from './components/status/status.component';
import {ProductionlistComponent} from './components/productionlist/productionlist.component';
import {ProductionlistdescComponent} from './components/productionlistdesc/productionlistdesc.component';
import {EodlistcardComponent} from './components/eodlistcard/eodlistcard.component';
import {EodcardComponent} from './components/eodcard/eodcard.component';
import {EodcarddescComponent} from './components/eodcarddesc/eodcarddesc.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DcvanlistComponent} from './dcvanlist/dcvanlist.component';
import {MtSalesComponent} from './mt_sales_dc/mtsales.component';
import {MtSalesOutletsComponent} from './mt_sales_outlets/mtsales_outlets.component';
import {PurchaseOrderComponent} from './purchase-order/purchase-order.component';
import {ProfilebarComponent} from './components/profilebar/profilebar.component';
import {WhitefiledvanlistComponent} from './whitefiledvanlist/whitefiledvanlist.component';
import {SuccessComponent} from './components/success/success.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {DispatchComponent} from './components/dispatch/dispatch.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownIconComponent} from './components/dropdown-icon/dropdown-icon.component';
import {ReportDropdownComponent} from './components/report_dropdown/report-dropdown.component';
import {SupplierDropdownComponent} from './components/supplier_dropdown/supplier-dropdown.component';
import {DcvanlistStatusComponent} from './dcvanlist-status/dcvanlist-status.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MultiselectComponent} from './components/multiselect/multiselect.component';
import {MatCheckboxModule, MatProgressBarModule, MatTabsModule} from '@angular/material';
import {AgmCoreModule, LAZY_MAPS_API_CONFIG} from '@agm/core';
import {ApproveClickRenderer} from './approveClick.component';
import {CellEditRenderer} from './editCell.component';
import {ColorCellRenderer} from './colorCell.component';
import {HeaderClickComponent} from './headerClick.component';
import {ConvertComponent} from './convert.component';
import {ConvertCellRenderer} from './convertCell.component';
import {PoEditRenderer} from './poEdit.component';
import {GrnEditRenderer} from './grnEdit.component';
import {InvoiceEditRenderer} from './invoiceEdit.component';
import {CellEdit2Renderer} from './editCell2.component';
import {ImageClickRenderer} from './imageClick.component';
import {EmptystateComponent} from './components/emptystate/emptystate.component';
import {LoadingstateComponent} from './components/loadingstate/loadingstate.component';
import {DashcardComponent} from './components/dashcard/dashcard.component';
import {ReportLogComponent} from './report-log/report-log.component';
import {ReportUrlComponent} from './reportUrl.component';
import {BeatDropdownComponent} from './components/beat_dropdown/beat-dropdown.component';

import {HttpClientModule} from '@angular/common/http';
import {BnNgIdleService} from 'bn-ng-idle';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {DatePipe} from '@angular/common';
import {ChartComponent} from './chart/chart.component';
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import {AuthGuard} from './auth/auth.guard';
import {NgxPaginationModule} from 'ngx-pagination';
import {RawSaleReportComponent} from './raw-sale-report/raw-sale-report.component';
import {SettleSaleReportComponent} from './settle-sale-report/settle-sale-report.component';
import {LoadinReportComponent} from './loadin-report/loadin-report.component';
import {PricelistReportComponent} from './pricelist-report/pricelist-report.component';
import {UserCollectionReportComponent} from './user-collection-report/user-collection-report.component';
import {NgxPrintModule} from 'ngx-print';
import {EodstatuscardComponent} from './components/eodstatuscard/eodstatuscard.component';
import {EodcarddescComponent2} from './components/eodcarddesc2/eodcarddesc2.component';
import {DamageEditRenderer} from './damageEdit.component';
import {ReuseRenderer} from './reuse.component';
import {PinnedCellRenderer} from './pinnedCellRenderer.component';
import {ConvertPopUpComponent} from './convert.popup.component';
import {ReplaceRenderer} from './replacesku.component';
import {ReportComponent} from './report/report.component';
import {ProductCollectionReportComponent} from './product-collection-report/product-collection-report.component';
import {OutletDumpReportComponent} from './reports/master/outlet-dump-report.component';
import {SaleReturnReportComponent} from './sale-return-report/sale-return-report.component';
import {ReportCardsComponent} from './components/report-cards/report-cards.component';
import {GrowthReportComponent} from './growth-report/growth-report.component';
import {PrintClickRenderer} from './printClickRenderer.component';
import {PrintInvoiceComponent} from './printInvoice.component';
import {SaveMTsaleComponent} from './saveMTsale.component';
import {MtCellEditComponent} from './mtCellEdit.component';
import {VanDisplayRendererComponent} from './vanShow.component';
import {SalesComponent} from './sales/sales.component';
import {VanReportComponent} from './van_productivity_report/van-productivity-report.component';
import {SchemeReportComponent} from './reports/master/scheme_report.component';
import {CancelReportComponent} from './reports/sales/cancel-report.component';
import {BeatReportComponent} from './reports/productivity/beat-productivity-report.component';
import {TiDashboardReportComponent} from './ti_dashboard_report/ti-dashboard-report.component';
import {OutletAggregateReportComponent} from './outlet_aggregate_report/outlet-aggregate-report.component';
import {AgChartsAngularModule} from 'ag-charts-angular';

import {AllModules, ModuleRegistry} from '@ag-grid-enterprise/all-modules';
import {LivestockReportComponent} from './livestock-report/livestock-report.component';
import {OutletAggregateProductReportComponent} from './outlet_aggregate_product_report/outlet-aggregate-product-report.component';
import {AdDirective} from './ad.directive';
import {AdDirective1} from './ad1.directive';
import {OutletDropdownComponent} from './components/outlet_dropdown/outlet-dropdown.component';
import {ProdDropdownComponent} from './components/prod_dropdown/prod-dropdown.component';
import {AdDirective2} from './ad2.directive';
import {TypeDropdownComponent} from './components/type_dropdown/type-dropdown.component';
import {AdDirective3} from './ad3.directive';
import {BtlActivityReportComponent} from './reports/productivity/btl-activity-report.component';
import {Po2Component} from './po-2/po2.component';
import {CommonDashboardComponent} from './common_dashboard/common-dashboard.component';
import {QRCodeModule} from 'angularx-qrcode';
import {CollectionReportComponent} from './collection-report/collection-report.component';
import {PoOrderComponent} from './po_order/po-order.component';
import {New_po_pageComponent} from './new_po_page/new_po_page.component';
import {SuppProdDropdownComponent} from './components/supp_prod_dropdown/supp-prod-dropdown.component';
import {SerialNoRenderer} from './serialNo.component';
import {Stock_in_hand_reportComponent} from './stock_in_hand_report/stock_in_hand_report.component';
import {EinvoiceReportComponent} from './reports/finance/einvoice-report.component';
import {RegenerateComponent} from './regenerate.component';
import {RegenerateHeaderComponent} from './regenerateHeader.component';
import {Dms_orderComponent} from './dms/dms_orders/dms_order.component';
import {NeworderpageComponent} from './new_order_page/neworderpage.component';
import {Dms_invoicesComponent} from './dms/dms_invoices/dms_invoices.component';
import {Dms_retailersComponent} from './dms/dms_retailers/dms_retailers.component';
import {New_retailer_pageComponent} from './new_retailer_page/new_retailer_page.component';
import {Received_poComponent} from './received_po/received_po.component';
import {Po_returnsComponent} from './po_returns/po_returns.component';
import {OrderAgingReportComponent} from './order-aging-report/order-aging-report.component';
import {OutletOverdueReportComponent} from './outlet-overdue-report/outlet-overdue-report.component';
import {PaymentApprovalReportComponent} from './payment_approval-report/payment-approval-report.component';
import {PriceCellRendererComonent} from './priceCellRenderer.comonent';
import {StockClickRendererComponent} from './stockClickRenderer.component';
import {StockLogComponent} from './stock_log_entry/stock-log.component';
import {CostLogComponent} from './cost_log_entry/cost-log.component';
import {ExpenseComponent} from './expense/expense.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ClaimMaxComponent} from './claimMax.component';
import {BeatPerformanceComponent} from './reports/productivity/beat-performance.component';
// import {MonthlyAttendanceReportComponent} from './reports/compliance/monthly_attendance.component';
import {FiltersComponent} from './components/filters/filter';
import {Dms_order_Component} from './dms/new_order/dms_order';
import {Dms_Invoice_Component} from './dms/new_order/dms_invoice';
import {DeleteComponent} from './deleteCell.component';
import {UomComponent} from './uom.component';
import {MyExpenseComponent} from './expense/my_expense/my_expense.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DetailExpenseComponent} from './expense/detail_expense/detail_expense.component';
import {BoardOneComponent} from './boards/board_one/board_one';
import {GridModule} from './boards/grid/grid.module';
import {GridComponent} from './boards/grid/grid.component';
import {StatisticGadgetComponent} from './boards/gadgets/statistic/statistic-gadget.component';

import {GadgetModule} from './boards/gadgets/gadget.module';
import {DynamicFormModule} from './boards/dynamic-form/dynamic-form-module';
import {GadgetPropertyService} from './boards/gadgets/_common/gadget-property.service';
import {ConfigurationModule} from './boards/configuration/configuration.module';
import {BarGraphGadgetComponent} from './boards/gadgets/bar_graph/bar-graph-gadget.component';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatRadioModule} from "@angular/material/radio";
import {MatGridListModule} from "@angular/material/grid-list";
import {ToastrModule} from "ngx-toastr";
import {TaskComponent} from './task/task.component';
import {TaskDetailComponent} from "./task/task_detail/task_detail.component";
import {BulkAddRetailerComponent} from "./components/bulk_add_retailer/bulk_add_retailer.component";
import {RetailAuditComponent} from "./retail_audit/retail_audit.component";
import {AssetsRequestComponent} from "./assets/assets_request/assets_request.component";
import {UsersComponent} from "./users/users.component";
import {UserAssessmentComponent} from "./users/users_assessment/users_assessment.component";
import {UserKPIScoreCardComponent} from "./users/users_kpi_score_card/users_kpi_score_card.component";
import {UserTeamComponent} from "./users/users_team.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RetailAuditDetailComponent} from "./retail_audit/retail_audit_detail/retail_audit_detail.component";
import {ConfirmDialogComponent} from "./components/confirmdialog/confirmdialog.component";
import {RetailAuditSKUCreateComponent} from "./retail_audit/retail_audit_sku_create/retail_audit_sku_create.component";
import {PieGraphGadgetComponent} from './boards/gadgets/pie_graph/pie-graph-gadget.component';
import {TreeGraphGadgetComponent} from './boards/gadgets/tree_graph/tree-graph-gadget.component';
import {HeatGraphGadgetComponent} from './boards/gadgets/heat_graph/heat-graph-gadget.component';
import {AttendanceReportComponent} from "./attendance-report/attendance-report.component";
import {LoginCompanyKeyComponent} from "./login/login_companykey/login_companykey.component";
import {OKTA_CONFIG, OktaAuthModule, OktaCallbackComponent} from '@okta/okta-angular';
import {GuageGraphGadgetComponent} from './boards/gadgets/gauge_graph/gauge-graph-gadget.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BarlineGraphGadgetComponent} from './boards/gadgets/barline_graph/barline-graph-gadget.component';
import {ComboChartComponent, ComboSeriesVerticalComponent} from './boards/gadgets/combo-chart';
import {RetailSurveyMarketComponent} from "./retail_survey/retail_survey_market/retail_survey_market.component";
import {SurveyTerritoryCreateComponent} from "./survey/survey_territory_create/survey_territory_create.component";
import {MarketIntelligenceReportComponent} from "./reports/survey/market_intelligence_report.component";
import {RetailAuditReportComponent} from "./reports/audit/retail_audit_report.component";
import {MarketIntelligenceSummaryReportComponent} from "./reports/survey/market_intelligence_summary_report.component";
import {SurveyQuestionCreateComponent} from "./survey/survey_question_create/survey_question_create.component";
import {RetailAuditDistributionReportComponent} from "./reports/audit/retail_audit_distribution_report.component";
import {ErrorHandlerModule} from './error/error.module';
import {VolumeDistributionReportComponent} from "./reports/audit/volume_distribution.component";
import {TLCLusterLevelRollupReportComponent} from "./reports/productivity/tl_cluster_level_rollup.component";
import {ShelfAnalysisReport} from './reports/audit/shelf-analysis-report';
import {SecondarySalesReport} from './reports/sales/secondary-sales-report.component';
import {AssetsDetailsComponent} from "./assets/assets_details/assets_details.component";
import {RetailStockReport} from './reports/stock/retail-stock-report';
import {VisibilityReportComponent} from "./reports/audit/visibility_report.component";
import {HsnReportComponent} from "./reports/finance/hsn_report.component";
import {GstrReportComponent} from "./reports/finance/gstr_report.component";
import {GstrLineReportComponent} from "./reports/finance/gstr_line_report.component";
import {SecondarySalesDetailedReport} from "./reports/sales/secondary_sales_detailed";
import {SaleMetricReportComponent} from "./reports/sales/sale_metric_report.component";
import {SaleMetricBeatwiseReportComponent} from "./reports/sales/sale_metric_beatwise_report.component";
import {SaleMetricOutletwiseReportComponent} from "./reports/sales/sale_metric_outletwise_report.component";
import {InvoiceReport} from "./reports/finance/invoice_report";
import {UserSurveyListComponent} from "./user_survey/user_survey_list/user_survey_list.component";
import {PeriodDropdownComponent} from "./components/period_dropdown/period_dropdown.component";
import {UserAssessmentDetailComponent} from "./users/users_assessment/user_assessment_detail/user_assessment_detail.component";
import {CustomerComponent} from "./retailers/customers/customer.component";
import {ChangeRequestComponent} from "./retailers/change_request/change-request.component";
import {ImagePopupComponent} from "./components/image_popup/image_popup.component";
import {RetailSurveyKYCComponent} from "./retail_survey/retail_survey_kyc/retail_survey_kyc.component";
import {CustomFilterComponent} from "./components/customfilter/customfilter.component";
import {UserSurveyPivotReportComponent} from "./reports/survey/user_survey_pivot_report.component";
import {UserSurveyComponent} from "./user_survey/user_survey.component";
import {SurveyCardComponent} from "./components/survey_card/survey_card.component";
import {AssetsDeployedComponent} from "./assets/assets_deployed/assets_deployed.component";
import {Pre_orderComponent} from "./reports/sales/pre_order.component";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getPerformance} from "firebase/performance";
import {Dms_paymentsComponent} from "./dms/dms_payments/dms_payments.component";
import {Dist_reconcileComponent} from "./reports/stock/dist_reconcile.component";
import {PaymentPopupComponent} from "./components/payment_popup/payment_popup.component";
import {Pre_order_vanComponent} from "./reports/sales/pre_order_van.component";
import {Dms_credit_notesComponent} from "./dms/dms_credit_notes/dms_credit_notes.component";
import {AvailabilityReportComponent} from "./reports/audit/availablity_report.component";
import {PartialRejectPopupComponent} from "./components/partial_reject_popup/partial_reject_popup.component";
import {UpdateOrderStatusComponent} from "./components/direct_order_status_popup/update_order_status.component";
import {ApproveSurveyPopupComponent} from "./reports/survey/approve_survey_popup/approve-survey-popup.component";
import {PartialApprovePopupComponent} from "./components/partial_approve_popup/partial_approve_popup.component";
import {RetailAuditPopupComponent} from "./retail_audit/retail_audit_popup/retail_audit_popup.component";
import {RetailSurveyKYCReportComponent} from "./reports/survey/retail_survey_kyc_report";
import {TaskActivationReportComponent} from "./reports/task/taskActivationReport.component";
import {OutletAggReportComponent} from "./outlet_agg_report/outlet_agg_report.component";
import {ProductAgingReportComponent} from "./product-ageing-report/product-aging-report.component";
import {ProductMasterReportComponent} from './reports/master/product_master.component';
import {RetailerSKUOfferReportComponent} from './reports/retailer_sku_offer_report';
import {RetailerMasterReportComponent} from './reports/master/retailer_master.component';
import {DailyAttendanceReportComponent} from './reports/compliance/daily_attendance_report.component';
import {MonthlyAttendanceReportV2Component} from './reports/compliance/monthy_attendance_report_v2.component';
import {PromoterVisitReportComponent} from './reports/productivity/promoter_visit_report.component';
import {ActivitiesPerformanceReportComponent} from './reports/productivity/activities_performance_report';
import {StockAvailabilityReportComponent} from "./reports/stock/stock_availablity_report";
import {GstinCancelComponent} from "./gstinCancel.component";
import {TertiarySalesReportComponent} from './reports/sales/tertiary_sales_report.component';
import {LocationPopupComponent} from './components/location_popup/location_popup.component';
import {RetailerListPopupComponent} from './components/retailer_list_popup/retailer_list_popup.component';
import {StockDistributionReportComponent} from "./reports/stock/stock_distribution_report";
import {EmployeeMasterReportComponent} from './reports/master/employee_master_report.component';
import {ImageComparePopupComponent} from './components/image_compare_popup/image_compare_popup.component';
import {PricelistReportV2Component} from './reports/master/pricelist_reportv2.component';
import {InvoiceComponent} from "./components/invoice-component/invoice.component";
import {OrderPrintComponent} from "./components/order-print-component/order-print.component";
import {DistributorRetailerMappingComponent} from "./reports/master/distributor_retailer_report";
import {MyStockComponent} from "./dms/my-stock/my-stock.component";
import {OutletLastVisitReportComponent} from './reports/productivity/outlet_last_visit_report.component';
import {PrintComponent} from "./components/print-component/print.component";
import {ChangeRequestPopupComponent} from './retailers/change_request/change_request_popup/change_request_popup.component';
import {setAnalyticsCollectionEnabled} from '@firebase/analytics';
import {BeatActivityDetailedReportComponent} from './reports/productivity/beat_activity_detailed_report.component';
import {BeatPlanningAndTargetTrackerComponent} from './reports/productivity/beat_planning_and_target_tracker.component';
import {agmConfigFactory, SharedService} from './_services/shared_service';
import {LoginComponent} from './login/login.component';
import {UpdateService} from './_services/update.service';
import {BeatActivitySummaryReportComponent} from './reports/productivity/beat_activity_summary_report.component';
import {UserActivityReportComponent} from './reports/productivity/user_activity_report.component';
import {OutletFinanceLedgerReportComponent} from "./outlet-finance-ledger-report/customer-ledger/outlet-finance-ledger-report.component";
import {OutstandingShowComponent} from "./outstandingShow.component";
import {MyLedgerReportComponent} from "./outlet-finance-ledger-report/my-ledger/my-ledger-report.component";
import {TaskAdherenceReportComponent} from "./reports/task/task_adherence.component";
import {ApprovalRequestComponent} from "./retailers/approval_request/approval-request.component";
import {OutletVerifyComponent} from "./outletVerify.component";
import {ChainVerifyPopupComponent} from "./retailers/approval_request/chain_verify_popup/chain-verify-popup.component";
import {GstinVerifyPopupComponent} from "./retailers/approval_request/gstin_verify_popup/gstin-verify-popup.component";
import {ApprovePopupComponent} from "./retailers/approval_request/approve_popup/approve-popup.component";
import {FinanceVerifyPopupComponent} from "./retailers/approval_request/finance_verify_popup/finance-verify-popup.component";
import {CustomtooltipComponent} from "./customtooltip.component";
import {AssetTypeMasterReportComponent} from "./reports/master/asset_type_master.component";
import {PartnerAssetReportComponent} from "./reports/productivity/partner_asset.component";
import {OutletNotVisitReportComponent} from "./reports/productivity/outlet_not_visit.component";
import {OutletVisitDayWiseReportComponent} from "./reports/productivity/outlet_visit_day_wise.component";
import {CreatePopupComponent} from "./components/create_popup/create_popup.component";
import {RetailerTargetAchievementReportComponent} from "./reports/productivity/retailer_target_achievement.component";
import {SalesmanTargetAchievementReportComponent} from "./reports/productivity/salesman_target_achievement.component";
import {DistributorTargetAchievementReportComponent} from "./reports/productivity/distributor_target_achievement.component";
import {BeatLastVisitReportComponent} from "./reports/productivity/beat_last_visit_report.component";
import {BeatCoverageReportComponent} from "./reports/productivity/beat_coverage_report.component";
import {Bill_scheme_reportComponent} from "./reports/scheme/bill_scheme_report.component";
import {Product_scheme_reportComponent} from "./reports/sales/product_scheme_report.component";
import {ClaimSchemeReportComponent} from "./reports/scheme/claim-scheme-report.component";
import {Primary_bill_scheme_reportComponent} from "./reports/scheme/primary_bill_scheme_report.component";
import {Primary_product_scheme_reportComponent} from "./reports/scheme/primary_product_scheme_report.component";
import {PrimaryClaimSchemeReportComponent} from "./reports/scheme/primary-claim-scheme-report.component";
import {CreditReturnsReportComponent} from "./reports/credit-returns-report.component";
import {MSLComplianceReportComponent} from "./reports/audit/msl_compliance_report";
import {BeatPlanListComponent} from "./beats/beat_plan_list.component";
import {BeatScheduleListComponent} from "./beats/beat_schedule_list.component";
import {CreateBeatPlanPopupComponent} from "./beats/create_beat_plan_popup/create_beat_plan_popup.component";
import {OutletVisitPopupComponent} from "./components/outlet_visit_popup/outlet_visit_popup.component";
import {DistributorMasterReportComponent} from "./reports/master/distributor_master.component";
import {OpenOrderComponent} from "./dms/dms_credit_notes/openOrder.component";
import {AddBeatRetailerPopupComponent} from "./beats/add_beat_retailer_popup/add_beat_retailer_popup.component";
import {Credit_notes_reportComponent} from "./reports/credit_notes_report.component";
import {Debit_notes_reportComponent} from "./reports/debit_notes_report.component";
import {OpenCreditComponent} from "./dms/dms_payments/openCredit.component";
import {SalesmanTargetAchievementByTargetReportComponent} from "./reports/productivity/salesman_target_achievement_bytarget.component";
import {TooltipComponent} from "./mt_sales_outlets/tooltip.component";
import {CreateEditTeamComponent} from "./users/create_edit_team/create_edit_team.component";
import {AGGridEditButtonRendererComponent} from "./users/AGGridEditButtonRendererComponent";
import {MonthlyAttendanceTeamWiseReportComponent} from "./reports/compliance/monthy_attendance__team_wise.component";
import {DailyAttendanceTeamWiseReportComponent} from "./reports/compliance/daily_attendance_team_wise.component";
import {CustomPrintComponent} from "./components/custom-print-component/custom-print.component";
import {BillInvoiceSchemeReportComponent} from "./reports/scheme/bill-invoice-scheme-report.component";
import {Product_invoice_scheme_reportComponent} from "./reports/scheme/product_invoice_scheme_report.component";
import {Claim_invoice_scheme_reportComponent} from "./reports/scheme/claim_invoice_scheme_report.component";
import {EodsettlementNewComponent} from "./eodsettlement/eodsettlement_new.component";
import {Primary_invoice_bill_scheme_reportComponent} from "./reports/scheme/primary_invoice_bill_scheme_report.component";
import {PrimaryInvoiceProductSchemeReportComponent} from "./reports/scheme/primary-invoice-product-scheme-report.component";
import {Primary_invoice_claim_scheme_reportComponent} from "./reports/scheme/primary_invoice_claim_scheme_report.component";
import {ZipPrintComponent} from "./components/zip-print-component/zip-print.component";
import {CreateEditUserComponent} from "./users/create_edit_user/create_edit_user.component";
import {ProlistNewComponent} from "./prolist/prolist-new.component";
import {SalesmanSubTargetAchievementMonthReportComponent} from "./reports/productivity/salesman_sub_tvsa_month.component";
import {Dms_Invoice_New_Component} from "./dms/new_order/dm_invoice_new";
import {BeatRetailerReportComponent} from "./reports/master/beat_retailer.component";
import {PrimarySalesDetailedReportComponent} from "./reports/sales/primary_sales_detailed.component";
import {BeatProductivityReportComponent} from "./reports/productivity/beat_productivity.component";
import {ProductsListComponent} from "./products/productslist.component";
import {CreateEditProductTemplateComponent} from "./products/create_edit_product/create_edit_product_template.component";
import {BulkUploadPopupComponent} from "./components/bulk_upload_popup/bulk_upload_popup.component";
import {CustomAuditReportComponent} from "./reports/audit/custom_audit_report.component";
import {BulkUploadStatusPopupComponent} from "./components/bulk_upload_popup/bulk_upload_status_popup/bulk_upload_status_popup.component";
import {LoadinReportV2Component} from "./loadin-report-v2/loadin-report-v2.component";
import {ProgressPopupComponent} from "./components/progress_popup/progress_popup.component";
import {AddGadgetModule} from "./boards/add-gadget/add-gadget.module";
import {VanStockReportComponent} from "./reports/sales/van_stock_report.component";
import {ProductPerformanceReport} from "./reports/sales/product_performance_report";
import {StockDisputeReportComponent} from "./reports/stock/stock_dispute.component";
import {InternalStockTransferComponent} from "./reports/stock/internal_stock_transfer.component";
import {LocationVerifyPopupComponent} from "./retailers/approval_request/location_verify_popup/location_verify_popup.component";
import {FavoritesDialog} from "./reports/_common/dialog-favoraite.component";
import {VanDayWiseSummaryReportComponent} from "./reports/finance/van_day_wise_summary.component";
import {RetailStockRangeDistributionReport} from "./reports/stock/retail-stock-range-distribution";
import {SafeHtmlPipe} from "./SafeHtmlPipe.component";
import {StatisticArrayGadgetComponent} from "./boards/gadgets/statistic-array/statistic-array-gadget.component";
import {CumulativeSalesReport} from "./reports/sales/cumulative-sales-report";
import {CustomGridToolPanelComponent} from "./reports/_common/CustomGridToolPanel.component";
import {AttendanceImagePopupComponent} from "./components/attendance_image_popup/attendance_image_popup.component";
import {SalesTrendAnalysis} from "./reports/sales/sales-trend-analysis";
import {PurchaseOrderFulfillmentReportComponent} from "./reports/sales/purchase_order_fulfillment";
import {SalesandReturnDetailedReportComponent} from "./reports/sales/sales_and_return_detailed.component";
import {DistributorAuditComponent} from "./distributor_audit/distributor_audit.component";
import {DistributorAuditDetailComponent} from "./distributor_audit/distributor_audit_detail/distributor_audit_detail.component";
import {CustomerDetailsComponent} from "./retailers/customers/customer_details/customer_details.component";
import {InfluencerListComponent} from "./retailers/influencer/influencer.component";
import {ProjectListComponent} from "./retailers/project/project.component";
import {InfluencerDetailsComponent} from "./retailers/influencer/influencer_details/influencer_details.component";
import {ProjectDetailsComponent} from "./retailers/project/project_details/project_details.component";
import {GenerateDraftInvoiceComponent} from "./generateDraftInvoice.component";
import {GenerateDraftInvoiceHeaderComponent} from "./generateDraftInvoiceHeader.component";
import {VanStockReconciliationReportComponent} from "./reports/sales/van_stock_reconciliation_report.component";
import {StockInvalidateComponent} from "./StockInvalidateComponent.component";
import {PrimarySalesStockReportComponent} from "./reports/sales/primary_sales_stock.component";
import {LeadsListComponent} from "./retailers/leads/leads.component";
import {PartnerLeadsListComponent} from "./retailers/leads/partner_leads/partner_leads.component";
import {PartnerLeadsCreateUploadComponent} from "./retailers/leads/partner_leads/partner_leads_create_upload.component";
import {LeadDetailsComponent} from "./retailers/leads/lead_details/lead_details.component";
import {ProlistV3Component} from "./prolist/prolist-v3.component";
import {ZeroSalesReportComponent} from "./reports/sales/zero_sales.component";
import {ConsecutiveNoorderReportComponent} from "./reports/sales/consecutive_noorder_report.component";
import {CoverageProductivityReportComponent} from "./reports/sales/coverage_and_productivity_report.component";
import {ProductionOrderReportComponent} from "./reports/productivity/production_order.component";
import {NewOutletReportComponent} from "./reports/master/new_outlet_report.component";
import {DeliveryPlanPopupComponent} from "./components/delivery_plan_popup/delivery_plan_popup.component";
import {DeliveryPlanDetailComponent} from "./dms/delivery-plan/delivery-plan-detail/delivery-plan-detail.component";
import {DeliveryPlanListComponent} from "./dms/delivery-plan/delivery-plan-list.component";
import {DeliveryPlanAddInvoicePopupComponent} from "./components/delivery_plan_add_invoice_popup/delivery_plan_add_invoice_popup.component";
import {SalesmanPerformanceMeasureReportComponent} from "./reports/productivity/salesman_performance_measure_report.components";
import {SRScorecardReportComponent} from "./reports/productivity/sr_scorecard_report.component";
import {LessthanMSSOutletReportComponent} from "./reports/sales/lessthan_mss_outlet_report.component";
import {RevenueDispersionReportComponent} from "./reports/sales/revenue_dispersion_report.component";
import {SKUDispersionReportComponent} from "./reports/sales/sku_dispersion_report.component";
import {MultiSelectDropdownComponent} from "./components/multi-select-dropdown/multi-select-dropdown.component";
import {MultiSelectFilterComponent} from "./components/multiselect-filter/multiselect-filter.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DailyGoalsReportComponent} from "./reports/productivity/daily_goals.component";
import {DailyGoalsReportV2Component} from "./reports/productivity/daily_goals_v2.component";
import {ServiceSparePartsComponent} from "./reports/productivity/service_spare_parts.component";
import {ServiceSparePartsRequestComponent} from "./reports/productivity/service_spare_parts_request.component";
import {ServiceLineSparePartsComponent} from "./reports/productivity/service_line_spare_parts.component";
import {ProductCategoryGrowthReportComponent} from "./reports/productivity/product_category_growth.component";
import {AssetServiceReportComponent} from "./reports/productivity/asset_service_report.component";
import {PartialReturnApprovePopupComponent} from "./components/partial_return_approve_popup/partial_return_approve_popup.component";
import {PartnerMeetReportComponent} from "./reports/productivity/partner_meet.component";
import {PartnerVisitReportComponent} from "./reports/productivity/partner_visit.component";
import {ReturnApprovePopupComponent} from "./components/return_approve_popup/return_approve_popup.component";
import {MeetAttendeesReportComponent} from "./reports/productivity/meet_attendees.component";
import {ClaimSchemeOrderReportComponent} from "./reports/scheme/claim_scheme_order_report.component";
import {RetailerVerticalDistributorMasterReport} from "./reports/master/retailer_vertical_distributor_master.component";
import {BeatRetailerV2ReportComponent} from "./reports/master/beat_retailerv2.component";
import {RetailerAssetSalesReportComponent} from "./reports/sales/retailer_asset_sales.component";
import {PartnerLeadMasterReportComponent} from "./reports/master/partner_lead_master.components";
import {ProductSugReportComponent} from "./reports/sales/product_suggestion_report.component";
import {RetailerSupVerticalComponent} from "./reports/master/retailer_supplier_vertical_map.components";
import {InfluencerMasterReportComponent} from "./reports/master/influencer_master.component";
import {PartnerContactMasterReportComponent} from "./reports/master/partner_contact_master.component";
import {ProjectMasterReportComponent} from "./reports/master/project_master.component";
import {VanStockReportV2Component} from "./reports/stock/van_stock_reportv2.component";
import {AssetActivityReportComponent} from "./reports/productivity/asset_activity_report.component";
import {SupplierListComponent} from "./supplier/supplier.component";
import {PartnerApproveComponent} from "./retailers/partner_approve/partner_approve.component";
import {UserPrformanceReportComponent} from "./reports/productivity/user_performance_report.component";
import {DealerPrformanceReportComponent} from "./reports/productivity/dealer_performance_report.component";
import {YTDDealerBillingReportComponent} from "./reports/sales/ytd_dealer_billing.component";
import {DealerBillingReportComponent} from "./reports/sales/dealer_billing_report.component";
import {VisitSummaryReportComponent} from "./reports/productivity/visit_summary_report.component";
import {ZipInvoiceDownloadComponent} from "./components/zip_invoice_download/zip_invoice_download.component";
import {MoveBeatRetailerPopupComponent} from "./beats/move_beat_retailer_popup/move_beat_retailer_popup.component";
import {BeatRetailerListComponent} from "./beats/beat_retailer_list.component";
import {DealerOutstandingComponent} from "./reports/master/dealer_outstanding.component";
import {TerritoryListComponent} from "./beats/territory_list.component";
import {RemoveBeatRetailerPopupComponent} from "./beats/remove_beat_retailer_popup/remove_beat_retailer_popup.component";
import {ChangeBeatSalesmanPopupComponent} from "./beats/change_beat_salesman_popup/change_beat_salesman_popup.component";
import {VanBonusReportComponent} from "./reports/productivity/van_bonus_report.component";
import {ImageRenderComponent} from "./components/imagerender/imagerender.component";
import {PricelistDCWiseReportComponent} from "./reports/master/pricelist_dcwise_report.component";
import {UserLeadsReportComponent} from "./reports/leads/userleadsreport.component";
import {DailyUserPerformanceReportComponent} from "./reports/productivity/daily_user_performance.component";
import {UserPerformanceSummaryReportComponent} from "./reports/productivity/user_perf_summary.component";
import {VanSaleSummaryReportComponent} from "./reports/productivity/van_sale_summary.component";
import {OnlyNumberDirective} from "./only-number.directive";
import {SecondaryReturnInvoiceListComponent} from "./dmsv2/secondary/return_invoice/return_invoice_list";
import {ReturnInvoiceComponent} from "./dmsv2/secondary/return_invoice/return_invoice";
import {DMSCustomerSelectionPopupComponent} from "./dmsv2/secondary/customer_selection/dms_customer_selection";
import {UOMRendererComponent} from "./pagebase/components/UOMRendererComponent";
import {NumericEditorComponent} from "./pagebase/components/NumericEditorComponent";
import {ExcelUploadComponent} from "./admin/upload/ExcelUploadComponent";
import {SecondarySaleOrderListComponent} from "./dmsv2/secondary/sale_order/secondary_sale_order_list";
import {SecondarySaleInvoiceListComponent} from "./dmsv2/secondary/sale_invoice/secondary_sale_invoice_list";
import {PODPopupComponent} from "./components/pod_popup/pod_popup.component";
import {BeatPlanDetailPageComponent} from "./beats/beat_plan/beat_plan";
import {Dashboard} from "./dash/dashboard";
import {GridsterModule} from "angular-gridster2";
import {StatisticsComponent} from "./dash/widgets/statisticsWidget";
import {UploadBeatPlanComponent} from "./beats/upload_beat_plan/upload_beat_plan";
import {ChangePromoterPopupComponent} from "./retailers/change_promoter_popup/change_pomoter.component";
import {DCStockReportComponent} from "./reports/stock/dc_stock.component";
import {GalleryCardComponent} from "./components/gallery-card/gallery-card.component";
import {GalleryContainerComponent} from "./components/gallery-container/gallery-container.component";
import {AddressCellRendererComponent} from "./addressCellRenderer.component";
import {DirectOrderListComponent} from "./dmsv2/custom/orders/direct_order_list";
import {DirectOrderComponent} from "./dmsv2/custom/orders/direct_order";
import {SingleMarginBillSchemeReportComponent} from "./reports/scheme/single_margin_bill_scheme.component";
import {AssetApprovePopupComponent} from "./assets/asset_approve_popup/asset_approve_popup.component";
import {TaskSurveyComponent} from "./task/task_survey/task_survey.component";
import {TaskSurveyDetailComponent} from "./task/task_survey/task_survey_detail/task_survey_detail.component";
import {TaskSurveyReportComponent} from "./reports/survey/task_survey_report.component";
import {SecondarySchemeListComponent} from "./dmsv2/secondary/claims/secondary_schemes_list";
import {GenrateClaimsPopupComponent} from "./dmsv2/secondary/claims/generate_claims_popup/generate_claims_popup.component";
import {SurveyAnswerPopupComponent} from "./survey/survey_answer_popup/survey_answer_popup.component";
import {SurveyDetailComponent} from "./survey/survey_detail/survey_detail.component";
import {TaskActivationPopupComponent} from "./components/task_activation_popup/task_activation_popup.component";
import {ClaimsComponent} from "./claims/claims.component";
import {ClaimsDetailPopupComponent} from "./claims/claims_detail_popup/claims_detail_popup.component";
import {StockAuditMarketShareReportComponent} from "./reports/audit/stock_audit_market_share.component";
import {TargetAndVisitSummaryComponent} from "./reports/productivity/target_and_visit_summary.component";
import {TaskPublishPopupComponent} from "./components/task_publish_popup/task_publish_popup.component";
import {DirectSaleInvoiceListComponent} from "./dmsv2/custom/invoices/direct_sale_invoice_list";
import {DirectInvoiceComponent} from "./dmsv2/custom/invoices/direct_invoice";
import {NewReportComponent} from "./reports/productivity/new_report.component";
import {TertiaryAuditReportComponent} from "./reports/audit/tertiary_audit_report.component";
import {OutletProductRecommendationReportComponent} from "./reports/master/outlet_product_recommendation_report.component";
import {DistributorLastCheckinReport} from "./reports/productivity/distributor_last_checkin_report";
import {TerritoryUsermappingReportComponent} from "./reports/master/territory_user_mapping.component";
import {PartnerMeetPopupComponent} from "./components/partner_meet_popup/partner_meet_popup.component";
import {UserSurveyReportComponent} from "./reports/survey/user_survey_report.component";
import {SecondaryOrderComponent} from "./dmsv2/secondary/sale_order/secondary_order";
import {POExcelUploadComponent} from "./admin/upload/POExcelUploadComponent";
import {PurchaseOrderImeiReportComponent} from "./reports/sales/purchase_order_imei.component";
import {SecondarySalesDetailedIMEIReport} from "./reports/sales/secondary_sales_detailed_imei.component";
import {PartnerMeetGalleryReportComponent} from "./reports/productivity/partner_meet_gallery.component";
import {PartnerKYCPivotReportComponent} from "./reports/survey/partner_kyc_pivot_report.component";
import {PartnerMarketIntelligencePivotReportComponent} from "./reports/survey/partner_market_intelligence_pivot_report.component";
import {PartnerKYCDumpReportComponent} from "./reports/survey/partner_kyc_dump_report.component";
import {PartnerMarketIntelligenceDumpReportComponent} from "./reports/survey/partner_market_intelligence_dump_report.component";
import {SaleLeadMasterReportComponent} from "./reports/master/sale_lead_master.component";
import {SaleInvoiceComponent} from "./dmsv2/secondary/sale_invoice/sale_invoice";
import {BulkInvoiceComponent} from "./dmsv2/secondary/sale_order/bulk_invoice/bulk_invoice";
import {SchemeMasterListComponent} from "./master/scheme/scheme_master.component";
import {PricelistMasterListComponent} from "./master/pricelist/pricelist_master.component";
import {SchemeMasterDetailComponent} from "./master/scheme/detail/scheme_master_detail.component";
import {LinkWidgetComponent} from "./dash/widgets/linkWidget";
import {SingleWidgetComponent} from "./dash/widgets/singleWidget";
import {ProgressBarWidgetComponent} from "./dash/widgets/progressBarWIdget";
import {GridWidgetComponent} from "./dash/widgets/gridWidget";
import {MultiGridWidgetComponent} from "./dash/widgets/multiGridWidget";
import {DonutWidgetComponent} from "./dash/widgets/donutWidget";
import {YTDSupplierOrderGrowthComponent} from "./reports/productivity/ytd_supplier_order_growth.component";
import {YTDPrimaryOrderGrowthComponent} from "./reports/productivity/ytd_primary_order_growth.component";
import {MultiDonutWidgetComponent} from "./dash/widgets/multiDonutWidget";
import {BarGraphWidgetComponent} from "./dash/widgets/barGraphWidget";
import {LineChartWidgetComponent} from "./dash/widgets/lineChartWidget";
import {QtyEditRendererComponent} from "./qtyEditRenderer";
import {ErrortooltipComponent} from "./errortooltip";
import {PicklistPrintComponent} from "./components/picklist_print/picklist_print.component";
import {PurchaseOrderListComponent} from "./dmsv2/purchase/order/purchase_order_list";
import {PurchaseOrderV2Component} from "./dmsv2/purchase/order/purchase_order";
import {PurchaseInvoiceComponent} from "./dmsv2/purchase/grn/grn";
import {PurchaseInvoiceListComponent} from "./dmsv2/purchase/grn/purchase_invoice_list";
import {RaiseSchemeClaimListComponent} from "./dmsv2/secondary/claims/raise_scheme_claim_list.component";
import {ClaimReturnComponent} from "./claims/claim_return.component";
import {ClaimApproveComponent} from "./claims/claim_approve/claim_approve.component";
import {PrimarySchemeListComponent} from "./dmsv2/purchase/primary_schemes_list.component";
import {PDFPopupComponent} from "./components/pdf_popup/pdf_popup.component";
import {OutletProductRecommendationComponent} from "./retailers/product_recommendation/outlet_product_recommendation.component";
import {UserTaskListComponent} from "./task/user-task.component";
import {UserTaskDetailComponent} from "./task/user_task_detail/user_task_detail.component";
import {UserAlertMasterComponent} from "./reports/master/user_alert_master.component";
import {PartnerAlertMasterComponent} from "./reports/master/partner_alert_master.component";
import {PartnerCheckinMasterComponent} from "./reports/master/partner_checkin_master.component";
import {MasterZeroSalesReportComponent} from "./reports/sales/master_zero_sales.component";
import {PageLoaderComponent} from "./components/page_loader/page_loader.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {OverlayService} from "./_services/overlay.service";
import {BeatPlanningSalesComponent} from "./reports/productivity/beat_planning_and_sales.component";
import {UserKpiSummaryComponent} from "./reports/kpi/user_kpi_summary.component";
import {TerritoryKpiSummaryComponent} from "./reports/kpi/territory_kpi_summary.component";
import {UserKpiResultLeaderBoardComponent} from "./reports/kpi/user_kpi_leaderboard.component";
import {TerritoryKpiResultLeaderBoardComponent} from "./reports/kpi/territory_kpi_leaderboard.component";
import {CreditNoteListComponent} from "./dmsv2/secondary/credit_notes/credit_note_list";
import {PaymentReceiptListComponent} from "./dmsv2/secondary/payments/payment_receipt_list";
import {ApproveRetailerComponent} from "./retailers/approval_request/approve-retailer.component";
import {StockListComponent} from "./products/product_stock_list.component";
import {RetailerRejectPopupComponent} from "./components/retailer_reject_popup/retailer_reject_popup.component";
import {BulkProductSelectPopupComponent} from "./components/bulk_product_select_popup/bulk_product_select_popup.component";
import {ClaimsListComponent} from "./dmsv2/secondary/claims/claims_list.component";
import {ClaimDetailComponent} from "./dmsv2/secondary/claims/claim_detail";
import {UOMProductNumericEditorComponent} from "./uomProductNumericEditor";
import {PartnerRAWCheckinComponent} from "./reports/master/partner_raw_checkin.component";
import {OutstandingUploadComponent} from "./dmsv2/partners/upload/outstanding_upload.component";
import {StockUploadComponent} from "./dmsv2/stock/upload_stock/stock_upload.component";
import {MyStockListComponent} from "./dmsv2/stock/my_stock_list.component";
import {LoadOutListComponent} from "./dmsv2/vansales/loadout/loadout_list";
import {LoadOutOrderComponent} from "./dmsv2/vansales/loadout/load-out";
import {ClaimsToApproveListComponent} from "./dmsv2/secondary/claims/claims_to_approve_list.component";
import {DebitNoteListComponent} from "./dmsv2/purchase/debit_notes/debit_note_list";
import {PaymentCollectPopupComponent} from "./dmsv2/secondary/payments/payment_collect_popup/payment_collect_popup.component";
import {CreditNotePopupComponent} from "./dmsv2/secondary/credit_notes/credit_note_popup/credit_popup.component";
import {PaymentEditorComponent} from "./paymentEditor";
import {RaiseSaleReturnClaimListComponent} from "./dmsv2/secondary/claims/raise_sale_return_claim_list.component";
import {PurchaseOrderReportComponent} from "./reports/sales/purchase_order_report.component";
import {MeetPrintComponent} from "./components/meet_print/meet_print.component";
import {CustomerListComponent} from "./dmsv2/partners/customer_list";
import {SupplierComponent} from './dmsv2/partners/supplier_list';
import {CustomerCreatePopupComponent} from "./dmsv2/partners/create_popup/customer_create_popup.component";
import {RaiseMultiBillClaimListComponent} from "./dmsv2/secondary/claims/raise_multibil_scheme_claim_list.component";
import {AssetDeployedUploadComponent} from "./assets/assets_deployed/asset_deployed_upload.component";
import {GenrateMultiBillClaimsPopupComponent} from "./dmsv2/secondary/claims/generate_multibill_claims_popup/generate_multi_bill_claims_popup.component";
import {RawVisitComponent} from "./reports/master/raw_visits.component";
import {MemberVisitComponent} from "./reports/productivity/member_visits.component";
import {RawUserAlertsComponent} from "./reports/master/raw_user_alerts.component";
import {SaleOrderBulkUploadComponent} from "./dmsv2/secondary/sale_order/sale_order_bulk_upload.component";
import {MeetReviewPopupComponent} from "./components/meet_review_popup/meet_review_popup.component";
import {OutletStockAgeingReportComponent} from "./reports/stock/outlet_stock_ageing_report";
import {PaymentCellRendererComponent} from "./payment-cell-renderer.component";
import {UOMProductCellRendererComponent} from "./uomProductCellRenderer";
import {HeaderV2Component} from "./components/headerv2/headerv2.component";
import {SidebarV2Component} from "./components/sidebarv2/sidebarv2.component";
import {TerritoryVisitComponent} from "./reports/productivity/visits_overview.component";
import {SettlementListComponent} from "./dmsv2/vansales/settlement/settlement_list";
import {SaleInvoiceBulkUploadComponent} from "./dmsv2/secondary/sale_invoice/sale_invoice_bulk_upload.component";
import {BasePrintComponent} from "./components/base_print/base_print.component";
import {LoadOutUploadComponent} from "./dmsv2/vansales/loadout/loadout_upload.component";
import {ImageGalleryPopupComponent} from "./components/image_gallery_popup/image_gallery_popup.component";
import {PaymentAdvancePopupComponent} from "./dmsv2/purchase/order/payment_advance_popup/payment_advance_popup.component";
import {TaskPlannerComponent} from "./task/task_planner/task_planner.component";
import {TaskPlannerCreateUploadComponent} from "./task/task_planner/task_planner_create_upload.component";
import {TaskPlannerDetailsUploadComponent} from "./task/task_planner/task_planner_details_upload.component";
import {AssignVendorPopupComponent} from "./branding_asset_request/assign_vendor_popup/assign_vendor_popup.component";
import {RetailAssetRequestComponent} from "./branding_asset_request/retail_asset_request.component";
import {CreateRetailRequestPopupComponent} from "./branding_asset_request/create_retail_request_asset_popup/create_retail_request_asset_popup.component";
import {AssignServiceExecutivePopupComponent} from "./branding_asset_request/assign_service_executive_popup/assign_service_executive_popup.component";
import {RetailAssetRequestDetailsComponent} from "./branding_asset_request/retail_asset_request_details/retail_asset_request_details.component";
import {FeasibilityApprovePopupComponent} from "./branding_asset_request/feasibility_approve_popup/feasibility_approve_popup.component";
import {TaskRetailerUploadComponent} from "./task/task_detail/task_retailer_upload.component";
import {RetailerAttributeUploadComponent} from "./dmsv2/partners/upload/retailer_attribute_upload.component";
import {BeatPartnerReportComponent} from "./reports/master/beat_partner.component";
import {AssetServiceTicketComponent} from "./branding_asset_request/asset_service_ticket.component";
import {CustomFilterV2Component} from "./components/customfilterv2/customfilterv2.component";
import {TakeSurveyPopupComponent} from "./components/take_survey_popup/take_survey_popup.component";
import {ApproveRejectCommentsPopupComponent} from "./components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {AssetServiceDetailsComponent} from "./branding_asset_request/service_ticket_details/service_ticket_details.component";
import {NewRetailerUploadComponent} from "./dmsv2/partners/upload/new_retailer_upload.component";
import {BulkSuppliersUploadComponent} from './dmsv2/partners/upload/bulk_suppliers_upload.component';
import {PlanogramListComponent} from "./retailers/planogram/planogram_list.component";
import {PlanogramUploadComponent} from "./retailers/planogram/planogram_upload.component";
import {NewUserUploadComponent} from "./users/upload/new_user_upload.component";
import {PartnerMerchandiseReportComponent} from "./reports/audit/partner_merchandise_report.component";
import {MeetAttendanceComponent} from "./standalone/meet_invitation/meet_attendance/meet_attendance.component";
import {EwayBillReportComponent} from "./reports/finance/ewaybill_report.component";
import {RegisterMeetAttendeeComponent} from "./standalone/meet_invitation/register_meet_attendee/register_meet_attendee.component";
import {OTPVerificationComponent} from "./standalone/otp_verification/otp_verification.component";
import {ServiceRequestComponent} from "./service_request/service_request.component";
import {ServiceRequestDetailsComponent} from "./service_request/details/service_request_details.component";
import {AssignServiceEngineerPopupComponent} from "./service_request/assign_service_engineer_popup/assign_service_engineer_popup.component";
import {CreateServiceRequestPopupComponent} from "./service_request/create_service_request/create_service_request.component";
import {StarRatingComponent} from "./components/star_rating/star_rating.component";
import {ServiceProductPopupComponent} from "./components/service_product_popup/service_product_popup.component";
import {CellEditRendererComponent} from "./CellEditRendererComponent";
import {DateEditRendererComponent} from "./DateEditorComponent";
import {StockTransferListComponent} from "./dmsv2/vansales/transfer/transfer_list";
import {VanTransferComponent} from "./dmsv2/vansales/transfer/transfer";
import {DeliveryPlanDetailV1Component} from "./dmsv2/secondary/delivery_plan/deliveryplan-detail";
import {DeliveryPlanListV1Component} from "./dmsv2/secondary/delivery_plan/delivery-plan-list.component";
import {LoyaltyProgramTrackerComponent} from "./reports/scheme/loyalty-program-tracker.component";
import {LoyaltyProgramDetailsComponent} from "./reports/scheme/loyalty-program-details.component";
import {BatchStockListComponent} from "./dmsv2/stock/batch_stock_list.component";
import {DailyTrackerReportComponent} from "./reports/compliance/daily_tracker_report.component";
import {ClaimGeneratePopupComponent} from "./dmsv2/secondary/claims/claim_generate_popup/claim_generate_popup.component";
import {EwayCancelComponent} from "./ewaycancel.component";
import {PaymentDetailComponent} from "./dmsv2/secondary/payments/payment_detail";
import {LedgerListComponent} from "./dmsv2/secondary/ledger/ledger_list";
import {SearchableFilterComponent} from "./components/searchablefilter/searchablefilter.component";
import {DiscountPopupComponent} from "./dmsv2/discount_popup/discount_popup.component";
import {NgxBarcodeModule} from "ngx-barcode";
import {TaskActivationUploadComponent} from "./task/task_activation_upload.component";
import {DaywiseSalesReport} from "./reports/sales/daywise_sales_report.component";
import {QuestionnaireUploadComponent} from "./retail_survey/questionnaire_upload.component";
import {TwoDigitDecimaNumberDirective} from "./components/twodigitdecimalnumber.directive";
import {MonthlyPartnerVisitReviewComponent} from "./reports/productivity/monthly_partner_visit_review.component";
import {SurveyGalleryPopupComponent} from "./components/survey_gallery_popup/survey_gallery_popup.component";
import {MapContainerComponent} from "./components/map-container/map-container.component";
import {SettleExpenseComponent} from "./expense/settle_expense/settle_expense.component";
import {TertiarySalesWithSerialNoReport} from "./reports/sales/tertiary_sales_detailed_imei.component";

const oktaConfig = {
  clientId: '',
  issuer: '',
  redirectUri: '',
  scopes: ['openid', 'profile', 'email'],
  pkce: false
};

@NgModule({
  declarations: [
    OnlyNumberDirective,
    AdDirective,
    AdDirective1,
    AdDirective2,
    AdDirective3,
    AppComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    PopupComponent,
    HeaderComponent,
    SearchbarComponent,
    PrinticonComponent,
    ExceliconComponent,
    VanComponent,
    StatusComponent,
    ProductionlistComponent,
    ProductionlistdescComponent,
    EodlistcardComponent,
    EodcardComponent,
    EodcarddescComponent,
    DcvanlistComponent,
    ProfilebarComponent,
    WhitefiledvanlistComponent,
    SuccessComponent,
    DatepickerComponent,
    DispatchComponent,
    DropdownComponent,
    DropdownIconComponent,
    ReportDropdownComponent,
    DcvanlistStatusComponent,
    Dashboard,
    DashboardComponent,
    MultiselectComponent,
    ApproveClickRenderer,
    AGGridEditButtonRendererComponent,
    CellEditRenderer,
    PinnedCellRenderer,
    ColorCellRenderer,
    HeaderClickComponent,
    ConvertComponent,
    ConvertCellRenderer,
    PoEditRenderer,
    GrnEditRenderer,
    PrintClickRenderer,
    PrintInvoiceComponent,
    SaveMTsaleComponent,
    MtCellEditComponent,
    DamageEditRenderer,
    VanReportComponent,
    SchemeReportComponent,
    CancelReportComponent,
    ReuseRenderer,
    ReplaceRenderer,
    InvoiceEditRenderer,
    CellEdit2Renderer,
    ConvertPopUpComponent,
    ImageClickRenderer,
    VanDisplayRendererComponent,
    SalesComponent,
    ChartComponent,
    EmptystateComponent,
    LoadingstateComponent,
    PagenotfoundComponent,
    DashcardComponent,
    RawSaleReportComponent,
    SettleSaleReportComponent,
    UserCollectionReportComponent,
    LoadinReportComponent,
    PricelistReportComponent,
    EodstatuscardComponent,
    EodcarddescComponent2,
    ReportComponent,
    ProductCollectionReportComponent,
    SaleReturnReportComponent,
    ReportCardsComponent,
    GrowthReportComponent,
    OutletDumpReportComponent,
    LivestockReportComponent,
    BeatReportComponent,
    TiDashboardReportComponent,
    OutletAggregateReportComponent,
    OutletAggregateProductReportComponent,
    OutletDropdownComponent,
    ProdDropdownComponent,
    TypeDropdownComponent,
    BtlActivityReportComponent,
    MtSalesComponent,
    MtSalesOutletsComponent,
    ReportLogComponent,
    ReportUrlComponent,
    PurchaseOrderComponent,
    Po2Component,
    CommonDashboardComponent,
    CollectionReportComponent,
    PoOrderComponent,
    New_po_pageComponent,
    SupplierDropdownComponent,
    SuppProdDropdownComponent,
    SerialNoRenderer,
    Stock_in_hand_reportComponent,
    EinvoiceReportComponent,
    RegenerateComponent,
    RegenerateHeaderComponent,
    Dms_orderComponent,
    NeworderpageComponent,
    BeatDropdownComponent,
    Dms_invoicesComponent,
    Dms_retailersComponent,
    New_retailer_pageComponent,
    Received_poComponent,
    Po_returnsComponent,
    OrderAgingReportComponent,
    OutletOverdueReportComponent,
    PaymentApprovalReportComponent,
    PriceCellRendererComonent,
    StockClickRendererComponent,
    StockLogComponent,
    CostLogComponent,
    ExpenseComponent,
    ClaimMaxComponent,
    // MonthlyAttendanceReportComponent,
    BeatPerformanceComponent,
    ShelfAnalysisReport,
    FiltersComponent,
    Dms_order_Component,
    Dms_Invoice_Component,
    DeleteComponent,
    UomComponent,
    UOMRendererComponent,
    NumericEditorComponent,
    MyExpenseComponent,
    SettleExpenseComponent,
    DetailExpenseComponent,
    BoardOneComponent,
    TaskComponent,
    TaskDetailComponent,
    BulkAddRetailerComponent,
    RetailAuditComponent,
    AssetsRequestComponent,
    AssetsDeployedComponent,
    UsersComponent,
    UserAssessmentComponent,
    UserKPIScoreCardComponent,
    UserTeamComponent,
    CreateEditTeamComponent,
    CreateEditUserComponent,
    ProductsListComponent,
    CreateEditProductTemplateComponent,
    RetailAuditDetailComponent,
    ConfirmDialogComponent,
    RetailAuditSKUCreateComponent,
    AttendanceReportComponent,
    LoginCompanyKeyComponent,
    RetailSurveyMarketComponent,
    SurveyTerritoryCreateComponent,
    MarketIntelligenceReportComponent,
    RetailAuditReportComponent,
    TertiaryAuditReportComponent,
    DistributorLastCheckinReport,
    MarketIntelligenceSummaryReportComponent,
    SurveyQuestionCreateComponent,
    RetailAuditDistributionReportComponent,
    SalesTrendAnalysis,
    SecondarySalesReport,
    CumulativeSalesReport,
    RetailAuditDistributionReportComponent,
    VolumeDistributionReportComponent,
    Pre_orderComponent,
    TLCLusterLevelRollupReportComponent,
    ActivitiesPerformanceReportComponent,
    AvailabilityReportComponent,
    MSLComplianceReportComponent,
    StockAvailabilityReportComponent,
    StockDistributionReportComponent,
    AssetsDetailsComponent,
    RetailStockReport,
    RetailStockRangeDistributionReport,
    VisibilityReportComponent,
    HsnReportComponent,
    GstrReportComponent,
    GstrLineReportComponent,
    SecondarySalesDetailedReport,
    SaleMetricReportComponent,
    SaleMetricBeatwiseReportComponent,
    SaleMetricOutletwiseReportComponent,
    DaywiseSalesReport,
    DailyTrackerReportComponent,
    InvoiceReport,
    UserSurveyListComponent,
    PeriodDropdownComponent,
    UserAssessmentDetailComponent,
    PeriodDropdownComponent,
    CustomerComponent,
    ChangeRequestComponent,
    ImagePopupComponent,
    ImageGalleryPopupComponent,
    RetailSurveyKYCComponent,
    CustomFilterComponent,
    UserSurveyPivotReportComponent,
    UserSurveyComponent,
    SurveyCardComponent,
    Dms_paymentsComponent,
    Dms_credit_notesComponent,
    Dist_reconcileComponent,
    PaymentPopupComponent,
    Pre_order_vanComponent,
    PartialRejectPopupComponent,
    UpdateOrderStatusComponent,
    MeetReviewPopupComponent,
    PartialApprovePopupComponent,
    RetailAuditPopupComponent,
    RetailSurveyKYCReportComponent,
    TaskActivationReportComponent,
    OutletAggReportComponent,
    ProductAgingReportComponent,
    ProductMasterReportComponent,
    RetailerSKUOfferReportComponent,
    RetailerMasterReportComponent,
    RetailerVerticalDistributorMasterReport,
    DistributorRetailerMappingComponent,
    DailyAttendanceReportComponent,
    MonthlyAttendanceReportV2Component,
    PromoterVisitReportComponent,
    GstinCancelComponent,
    TertiarySalesReportComponent,
    LocationPopupComponent,
    MapContainerComponent,
    RetailerListPopupComponent,
    EmployeeMasterReportComponent,
    BeatPlanningAndTargetTrackerComponent,
    ImageComparePopupComponent,
    PricelistReportV2Component,
    PricelistDCWiseReportComponent,
    InvoiceComponent,
    OrderPrintComponent,
    MyStockComponent,
    OutletLastVisitReportComponent,
    BeatLastVisitReportComponent,
    BeatCoverageReportComponent,
    PrintComponent,
    ChangeRequestPopupComponent,
    BeatActivityDetailedReportComponent,
    BeatActivitySummaryReportComponent,
    UserActivityReportComponent,
    OutletFinanceLedgerReportComponent,
    OutstandingShowComponent,
    MyLedgerReportComponent,
    TaskAdherenceReportComponent,
    MyLedgerReportComponent,
    ApprovalRequestComponent,
    OutletVerifyComponent,
    ChainVerifyPopupComponent,
    GstinVerifyPopupComponent,
    ApprovePopupComponent,
    FinanceVerifyPopupComponent,
    AssetTypeMasterReportComponent,
    PartnerAssetReportComponent,
    ProductPerformanceReport,
    OutletNotVisitReportComponent,
    CustomtooltipComponent,
    OutletVisitDayWiseReportComponent,
    CreatePopupComponent,
    RetailerTargetAchievementReportComponent,
    SalesmanTargetAchievementReportComponent,
    DistributorTargetAchievementReportComponent,
    CreatePopupComponent,
    Bill_scheme_reportComponent,
    Product_scheme_reportComponent,
    ClaimSchemeReportComponent,
    Primary_bill_scheme_reportComponent,
    PrimaryClaimSchemeReportComponent,
    Primary_product_scheme_reportComponent,
    CreditReturnsReportComponent,
    BeatPlanListComponent,
    BeatPlanDetailPageComponent,
    BeatScheduleListComponent,
    CreateBeatPlanPopupComponent,
    OutletVisitPopupComponent,
    DistributorMasterReportComponent,
    CreateBeatPlanPopupComponent,
    OpenOrderComponent,
    AddBeatRetailerPopupComponent,
    Credit_notes_reportComponent,
    Debit_notes_reportComponent,
    OpenCreditComponent,
    SalesmanTargetAchievementByTargetReportComponent,
    TooltipComponent,
    MonthlyAttendanceTeamWiseReportComponent,
    DailyAttendanceTeamWiseReportComponent,
    CustomPrintComponent,
    BillInvoiceSchemeReportComponent,
    Product_invoice_scheme_reportComponent,
    Claim_invoice_scheme_reportComponent,
    EodsettlementNewComponent,
    PrimaryInvoiceProductSchemeReportComponent,
    Primary_invoice_claim_scheme_reportComponent,
    Primary_invoice_bill_scheme_reportComponent,
    ZipPrintComponent,
    ProlistNewComponent,
    // SalesmanMainTargetAchievementMonthReportComponent,
    SalesmanSubTargetAchievementMonthReportComponent,
    Dms_Invoice_New_Component,
    BeatRetailerReportComponent,
    PrimarySalesDetailedReportComponent,
    BeatProductivityReportComponent,
    BulkUploadPopupComponent,
    CustomAuditReportComponent,
    BulkUploadStatusPopupComponent,
    LoadinReportV2Component,
    ProgressPopupComponent,
    VanStockReportComponent,
    StockDisputeReportComponent,
    InternalStockTransferComponent,
    LocationVerifyPopupComponent,
    FavoritesDialog,
    VanDayWiseSummaryReportComponent,
    SafeHtmlPipe,
    CustomGridToolPanelComponent,
    AttendanceImagePopupComponent,
    PurchaseOrderFulfillmentReportComponent,
    SalesandReturnDetailedReportComponent,
    DistributorAuditComponent,
    DistributorAuditDetailComponent,
    CustomerDetailsComponent,
    InfluencerListComponent,
    ProjectListComponent,
    InfluencerDetailsComponent,
    ProjectDetailsComponent,
    GenerateDraftInvoiceComponent,
    GenerateDraftInvoiceHeaderComponent,
    VanStockReconciliationReportComponent,
    StockInvalidateComponent,
    PrimarySalesStockReportComponent,
    LeadsListComponent,
    PartnerLeadsListComponent,
    LeadDetailsComponent,
    ProlistV3Component,
    ZeroSalesReportComponent,
    MasterZeroSalesReportComponent,
    ConsecutiveNoorderReportComponent,
    CoverageProductivityReportComponent,
    ProductionOrderReportComponent,
    NewOutletReportComponent,
    DeliveryPlanPopupComponent,
    DeliveryPlanListComponent,
    DeliveryPlanListV1Component,
    DeliveryPlanDetailComponent,
    DeliveryPlanDetailV1Component,
    DeliveryPlanAddInvoicePopupComponent,
    SalesmanPerformanceMeasureReportComponent,
    SRScorecardReportComponent,
    LessthanMSSOutletReportComponent,
    RevenueDispersionReportComponent,
    SKUDispersionReportComponent,
    MultiSelectDropdownComponent,
    MultiSelectFilterComponent,
    DailyGoalsReportComponent,
    DailyGoalsReportV2Component,
    ServiceSparePartsComponent,
    ServiceSparePartsRequestComponent,
    ServiceLineSparePartsComponent,
    ProductCategoryGrowthReportComponent,
    AssetServiceReportComponent,
    PartialReturnApprovePopupComponent,
    PartnerMeetReportComponent,
    PartnerVisitReportComponent,
    ReturnApprovePopupComponent,
    MeetAttendeesReportComponent,
    ClaimSchemeOrderReportComponent,
    BeatRetailerV2ReportComponent,
    BeatPartnerReportComponent,
    RetailerAssetSalesReportComponent,
    PartnerLeadMasterReportComponent,
    ProductSugReportComponent,
    RetailerSupVerticalComponent,
    InfluencerMasterReportComponent,
    PartnerContactMasterReportComponent,
    ProjectMasterReportComponent,
    VanStockReportV2Component,
    ProjectMasterReportComponent,
    AssetActivityReportComponent,
    SupplierListComponent,
    PartnerApproveComponent,
    UserPrformanceReportComponent,
    DealerPrformanceReportComponent,
    YTDDealerBillingReportComponent,
    DealerBillingReportComponent,
    VisitSummaryReportComponent,
    ZipInvoiceDownloadComponent,
    MoveBeatRetailerPopupComponent,
    RemoveBeatRetailerPopupComponent,
    ChangeBeatSalesmanPopupComponent,
    ChangePromoterPopupComponent,
    UserLeadsReportComponent,
    BeatRetailerListComponent,
    TerritoryListComponent,
    DealerOutstandingComponent,
    VanBonusReportComponent,
    ImageRenderComponent,
    DailyUserPerformanceReportComponent,
    UserPerformanceSummaryReportComponent,
    VanSaleSummaryReportComponent,
    SecondaryReturnInvoiceListComponent,
    DMSCustomerSelectionPopupComponent,
    CustomerListComponent,
    SupplierComponent,
    ReturnInvoiceComponent,
    DirectOrderComponent,
    SecondaryOrderComponent,
    SaleInvoiceComponent,
    PurchaseInvoiceComponent,
    LoadOutOrderComponent,
    ExcelUploadComponent,
    BulkInvoiceComponent,
    UploadBeatPlanComponent,
    LoadOutListComponent,
    StockTransferListComponent,
    VanTransferComponent,
    SettlementListComponent,
    SecondarySaleOrderListComponent,
    SecondarySaleInvoiceListComponent,
    CreditNoteListComponent,
    DebitNoteListComponent,
    PaymentReceiptListComponent,
    ClaimsListComponent,
    ClaimsToApproveListComponent,
    ClaimDetailComponent,
    PaymentDetailComponent,
    LedgerListComponent,
    PurchaseInvoiceListComponent,
    PurchaseOrderListComponent,
    PurchaseOrderV2Component,
    GenrateClaimsPopupComponent,
    GenrateMultiBillClaimsPopupComponent,
    SecondarySchemeListComponent,
    DirectOrderListComponent,
    DirectSaleInvoiceListComponent,
    DirectInvoiceComponent,
    PODPopupComponent,
    StatisticsComponent,
    DCStockReportComponent,
    GalleryCardComponent,
    GalleryContainerComponent,
    AddressCellRendererComponent,
    SingleMarginBillSchemeReportComponent,
    AssetApprovePopupComponent,
    TaskSurveyComponent,
    TaskSurveyDetailComponent,
    TaskSurveyReportComponent,
    SurveyAnswerPopupComponent,
    SurveyDetailComponent,
    TaskActivationPopupComponent,
    ClaimsComponent,
    ClaimsDetailPopupComponent,
    StockAuditMarketShareReportComponent,
    TargetAndVisitSummaryComponent,
    StockAuditMarketShareReportComponent,
    TaskPublishPopupComponent,
    NewReportComponent,
    OutletProductRecommendationReportComponent,
    PartnerMeetPopupComponent,
    TerritoryUsermappingReportComponent,
    UserSurveyReportComponent,
    POExcelUploadComponent,
    PurchaseOrderImeiReportComponent,
    SecondarySalesDetailedIMEIReport,
    PartnerMeetGalleryReportComponent,
    SecondarySalesDetailedIMEIReport,
    UserSurveyReportComponent,
    PartnerKYCPivotReportComponent,
    PartnerMarketIntelligencePivotReportComponent,
    PartnerKYCDumpReportComponent,
    PartnerMarketIntelligenceDumpReportComponent,
    SaleLeadMasterReportComponent,
    SchemeMasterListComponent,
    PricelistMasterListComponent,
    SchemeMasterDetailComponent,
    LinkWidgetComponent,
    SingleWidgetComponent,
    ProgressBarWidgetComponent,
    GridWidgetComponent,
    MultiGridWidgetComponent,
    DonutWidgetComponent,
    YTDSupplierOrderGrowthComponent,
    YTDPrimaryOrderGrowthComponent,
    MultiDonutWidgetComponent,
    BarGraphWidgetComponent,
    LineChartWidgetComponent,
    QtyEditRendererComponent,
    ErrortooltipComponent,
    PicklistPrintComponent,
    RaiseSchemeClaimListComponent,
    RaiseMultiBillClaimListComponent,
    RaiseSaleReturnClaimListComponent,
    ClaimReturnComponent,
    ClaimApproveComponent,
    PrimarySchemeListComponent,
    PDFPopupComponent,
    OutletProductRecommendationComponent,
    UserTaskListComponent,
    UserTaskDetailComponent,
    UserAlertMasterComponent,
    PartnerAlertMasterComponent,
    PartnerMerchandiseReportComponent,
    PartnerCheckinMasterComponent,
    PartnerRAWCheckinComponent,
    PageLoaderComponent,
    BeatPlanningSalesComponent,
    UserKpiSummaryComponent,
    TerritoryKpiSummaryComponent,
    UserKpiResultLeaderBoardComponent,
    TerritoryKpiResultLeaderBoardComponent,
    ApproveRetailerComponent,
    StockListComponent,
    RetailerRejectPopupComponent,
    BulkProductSelectPopupComponent,
    UOMProductNumericEditorComponent,
    OutstandingUploadComponent,
    NewRetailerUploadComponent,
    BulkSuppliersUploadComponent,
    NewUserUploadComponent,
    RetailerAttributeUploadComponent,
    StockUploadComponent,
    MyStockListComponent,
    BatchStockListComponent,
    PaymentCollectPopupComponent,
    CreditNotePopupComponent,
    CustomerCreatePopupComponent,
    PaymentEditorComponent,
    PurchaseOrderReportComponent,
    MeetPrintComponent,
    AssetDeployedUploadComponent,
    RawVisitComponent,
    MemberVisitComponent,
    TerritoryVisitComponent,
    MonthlyPartnerVisitReviewComponent,
    RawUserAlertsComponent,
    SaleOrderBulkUploadComponent,
    LoadOutUploadComponent,
    SaleInvoiceBulkUploadComponent,
    OutletStockAgeingReportComponent,
    PaymentCellRendererComponent,
    UOMProductCellRendererComponent,
    HeaderV2Component,
    SidebarV2Component,
    BasePrintComponent,
    PaymentAdvancePopupComponent,
    TaskPlannerComponent,
    TaskPlannerCreateUploadComponent,
    PartnerLeadsCreateUploadComponent,
    TaskPlannerDetailsUploadComponent,
    AssignVendorPopupComponent,
    RetailAssetRequestComponent,
    CreateRetailRequestPopupComponent,
    AssignServiceExecutivePopupComponent,
    RetailAssetRequestDetailsComponent,
    AssetServiceDetailsComponent,
    FeasibilityApprovePopupComponent,
    TaskRetailerUploadComponent,
    AssetServiceTicketComponent,
    CustomFilterV2Component,
    TakeSurveyPopupComponent,
    ApproveRejectCommentsPopupComponent,
    PlanogramListComponent,
    PlanogramUploadComponent,
    MeetAttendanceComponent,
    EwayBillReportComponent,
    RegisterMeetAttendeeComponent,
    OTPVerificationComponent,
    ServiceRequestComponent,
    ServiceRequestDetailsComponent,
    AssignServiceEngineerPopupComponent,
    CreateServiceRequestPopupComponent,
    StarRatingComponent,
    ServiceProductPopupComponent,
    CellEditRendererComponent,
    DateEditRendererComponent,
    LoyaltyProgramTrackerComponent,
    LoyaltyProgramDetailsComponent,
    ClaimGeneratePopupComponent,
    EwayCancelComponent,
    SearchableFilterComponent,
    DiscountPopupComponent,
    SearchableFilterComponent,
    ApproveSurveyPopupComponent,
    TaskActivationUploadComponent,
    QuestionnaireUploadComponent,
    TwoDigitDecimaNumberDirective,
    SurveyGalleryPopupComponent,
    TertiarySalesWithSerialNoReport
  ],
  imports: [
    GridsterModule,
    OktaAuthModule,
    BrowserModule,
    AgChartsAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    FormsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    MatExpansionModule,
    AgGridModule.withComponents([ApproveClickRenderer, CellEditRenderer, ColorCellRenderer, AGGridEditButtonRendererComponent,
      HeaderClickComponent, ReuseRenderer, ReplaceRenderer, DamageEditRenderer, PoEditRenderer, GrnEditRenderer, CellEdit2Renderer,
      ImageClickRenderer, InvoiceEditRenderer, ConvertComponent,
      ConvertCellRenderer, ConvertPopUpComponent, PinnedCellRenderer, PrintClickRenderer, PrintInvoiceComponent, SaveMTsaleComponent,
      MtCellEditComponent, VanDisplayRendererComponent, ReportUrlComponent, SerialNoRenderer, RegenerateComponent,
      RegenerateHeaderComponent,
      PriceCellRendererComonent,
      StockClickRendererComponent, OutstandingShowComponent,
      ClaimMaxComponent, DeleteComponent, UomComponent, GstinCancelComponent, OutletVerifyComponent, CustomtooltipComponent,
      OpenOrderComponent, TooltipComponent, QtyEditRendererComponent, EwayCancelComponent]),
    AngularMultiSelectModule,
    GridModule.withComponents([GridComponent, StatisticArrayGadgetComponent, StatisticGadgetComponent,
      BarGraphGadgetComponent, PieGraphGadgetComponent, TreeGraphGadgetComponent, HeatGraphGadgetComponent,
      GuageGraphGadgetComponent, ComboChartComponent,
      ComboSeriesVerticalComponent, BarlineGraphGadgetComponent]),
    GadgetModule,
    AddGadgetModule,
    DynamicFormModule,
    ConfigurationModule,
    AppRoutingModule,
    MatTabsModule,
    NgxPaginationModule,
    NgxPrintModule,
    MatDialogModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'initialKey',
      libraries: ['places', 'geometry']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    MatIconModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatMenuModule, MatRadioModule, MatGridListModule, MatProgressBarModule,
    FontAwesomeModule,
    OverlayModule,
    RouterModule.forRoot([
      {path: '', component: LoginCompanyKeyComponent},
      {path: 'login', component: LoginComponent},
      {path: 'callback', component: OktaCallbackComponent},
      {path: 'dash', component: Dashboard, canActivate: [AuthGuard]},
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      {path: 'boards/board_one', component: BoardOneComponent, canActivate: [AuthGuard]},
      {path: 'dcvanlist', component: DcvanlistComponent, canActivate: [AuthGuard]},
      {path: 'wfvanlist', component: WhitefiledvanlistComponent, canActivate: [AuthGuard]},
      {path: 'eod', component: EodsettlementNewComponent, canActivate: [AuthGuard]},
      {path: 'dcvanliststatus', component: DcvanlistStatusComponent, canActivate: [AuthGuard]},
      {path: 'rawsalereport', component: RawSaleReportComponent, canActivate: [AuthGuard]},
      {path: 'settlesalereport', component: SettleSaleReportComponent, canActivate: [AuthGuard]},
      {path: 'usercollectionreport', component: UserCollectionReportComponent, canActivate: [AuthGuard]},
      {path: 'loadinreport', component: LoadinReportComponent, canActivate: [AuthGuard]},
      {path: 'loadinreportv2', component: LoadinReportV2Component, canActivate: [AuthGuard]},
      {path: 'pricelistreport', component: PricelistReportComponent, canActivate: [AuthGuard]},
      {path: 'prolist', component: ProlistNewComponent, canActivate: [AuthGuard]},
      {path: 'prolistv3', component: ProlistV3Component, canActivate: [AuthGuard]},
      {path: 'reports', component: ReportComponent, canActivate: [AuthGuard]},
      {path: 'pagenotfound', component: PagenotfoundComponent},
      {path: 'prodcollectionreport', component: ProductCollectionReportComponent, canActivate: [AuthGuard]},
      {path: 'salereturnreport', component: SaleReturnReportComponent, canActivate: [AuthGuard]},
      {path: 'growthreport', component: GrowthReportComponent, canActivate: [AuthGuard]},
      {path: 'outletdumpreport', component: OutletDumpReportComponent, canActivate: [AuthGuard]},
      {path: 'livestockreport', component: LivestockReportComponent, canActivate: [AuthGuard]},
      {path: 'mystock', component: MyStockComponent, canActivate: [AuthGuard]},
      {path: 'sales', component: SalesComponent, canActivate: [AuthGuard]},
      {path: 'vanreport', component: VanReportComponent, canActivate: [AuthGuard]},
      {path: 'schemereport', component: SchemeReportComponent, canActivate: [AuthGuard]},
      {path: 'cancelreport', component: CancelReportComponent, canActivate: [AuthGuard]},
      {path: 'beatreport', component: BeatReportComponent, canActivate: [AuthGuard]},
      {path: 'tireport', component: TiDashboardReportComponent, canActivate: [AuthGuard]},
      {path: 'outaggreport', component: OutletAggregateReportComponent, canActivate: [AuthGuard]},
      {path: 'outaggprodreport', component: OutletAggregateProductReportComponent, canActivate: [AuthGuard]},
      {path: 'mtsalesdc', component: MtSalesComponent, canActivate: [AuthGuard]},
      {path: 'mtoutsalesdc', component: MtSalesOutletsComponent, canActivate: [AuthGuard]},
      {path: 'btlreport', component: BtlActivityReportComponent, canActivate: [AuthGuard]},
      {path: 'logs', component: ReportLogComponent, canActivate: [AuthGuard]},
      {path: 'po', component: PurchaseOrderComponent, canActivate: [AuthGuard]},
      {path: 'po2', component: Po2Component, canActivate: [AuthGuard]},
      {path: 'cd', component: CommonDashboardComponent, canActivate: [AuthGuard]},
      {path: 'dailycollection', component: CollectionReportComponent, canActivate: [AuthGuard]},
      {path: 'po_po', component: PoOrderComponent, canActivate: [AuthGuard]},
      {path: 'new_po', component: New_po_pageComponent, canActivate: [AuthGuard]},
      {path: 'stockinhand', component: Stock_in_hand_reportComponent, canActivate: [AuthGuard]},
      {path: 'einvoice', component: EinvoiceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/ewaybill', component: EwayBillReportComponent, canActivate: [AuthGuard]},
      {path: 'orders', component: Dms_orderComponent, canActivate: [AuthGuard]},
      {path: 'new_order', component: Dms_order_Component, canActivate: [AuthGuard]},
      //{path: 'new_invoice', component: Dms_Invoice_Component, canActivate: [AuthGuard]},
      {path: 'new_invoice', component: Dms_Invoice_New_Component, canActivate: [AuthGuard]},
      {path: 'order_invoices', component: Dms_invoicesComponent, canActivate: [AuthGuard]},
      {path: 'retailer_list', component: Dms_retailersComponent, canActivate: [AuthGuard]},
      {path: 'new_retailer', component: New_retailer_pageComponent, canActivate: [AuthGuard]},
      {path: 'receipts', component: Received_poComponent, canActivate: [AuthGuard]},
      {path: 'returns', component: Po_returnsComponent, canActivate: [AuthGuard]},
      {path: 'order_aging_report', component: OrderAgingReportComponent, canActivate: [AuthGuard]},
      {path: 'product_ageing_report', component: ProductAgingReportComponent, canActivate: [AuthGuard]},
      {path: 'outlet_overdue_report', component: OutletOverdueReportComponent, canActivate: [AuthGuard]},
      {path: 'payment_approval_report', component: PaymentApprovalReportComponent, canActivate: [AuthGuard]},
      {path: 'stock_log_entry', component: StockLogComponent, canActivate: [AuthGuard]},
      {path: 'cost_log_entry', component: CostLogComponent, canActivate: [AuthGuard]},
      {path: 'expense', component: ExpenseComponent, canActivate: [AuthGuard]},
      // {path: 'reports/monthly_attendance', component: MonthlyAttendanceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_performance', component: BeatPerformanceComponent, canActivate: [AuthGuard]},
      {path: 'reports/shelf_analysis', component: ShelfAnalysisReport, canActivate: [AuthGuard]},
      {path: 'reports/outlet_sales', component: SecondarySalesReport, canActivate: [AuthGuard]},
      {path: 'reports/trend_analysis', component: SalesTrendAnalysis, canActivate: [AuthGuard]},
      {path: 'reports/attendance', component: AttendanceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/retail_stock', component: RetailStockReport, canActivate: [AuthGuard]},
      {
        path: 'reports/retail_stock_range',
        component: RetailStockRangeDistributionReport,
        canActivate: [AuthGuard]
      },
      {path: 'expense/my_expense', component: MyExpenseComponent, canActivate: [AuthGuard]},
      {path: 'expense/settle_expense', component: SettleExpenseComponent, canActivate: [AuthGuard]},
      {path: 'task', component: TaskComponent, canActivate: [AuthGuard]},
      {path: 'task_planner', component: TaskPlannerComponent, canActivate: [AuthGuard]},
      {path: 'task/task_detail', component: TaskDetailComponent, canActivate: [AuthGuard]},
      {path: 'user_tasks', component: UserTaskListComponent, canActivate: [AuthGuard]},
      {path: 'user_tasks/detail', component: UserTaskDetailComponent, canActivate: [AuthGuard]},
      {path: 'retail_audit', component: RetailAuditComponent, canActivate: [AuthGuard]},
      {path: 'retail_audit/retail_audit_detail', component: RetailAuditDetailComponent, canActivate: [AuthGuard]},
      {path: 'assets/retail_request', component: RetailAssetRequestComponent, canActivate: [AuthGuard]},
      {path: 'assets/retail_request/details', component: RetailAssetRequestDetailsComponent, canActivate: [AuthGuard]},
      {path: 'assets/service_ticket', component: AssetServiceTicketComponent, canActivate: [AuthGuard]},
      {path: 'assets/service_ticket/details', component: AssetServiceDetailsComponent, canActivate: [AuthGuard]},
      {path: 'assets/request', component: AssetsRequestComponent, canActivate: [AuthGuard]},
      {path: 'assets/tagged', component: AssetsDeployedComponent, canActivate: [AuthGuard]},
      {path: 'service/request', component: ServiceRequestComponent, canActivate: [AuthGuard]},
      {path: 'service/request/details', component: ServiceRequestDetailsComponent, canActivate: [AuthGuard]},
      {path: 'customers/retailers', component: CustomerComponent, canActivate: [AuthGuard]},
      {path: 'customers/request', component: ChangeRequestComponent, canActivate: [AuthGuard]},
      {path: 'customers/new_outlet', component: ApprovalRequestComponent, canActivate: [AuthGuard]},
      {path: 'products', component: ProductsListComponent, canActivate: [AuthGuard]},
      {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
      {path: 'users/assessment', component: UserAssessmentComponent, canActivate: [AuthGuard]},
      {path: 'users/assessment_detail', component: UserAssessmentDetailComponent, canActivate: [AuthGuard]},
      {path: 'users/team', component: UserTeamComponent, canActivate: [AuthGuard]},
      {path: 'users/kpiscorecard', component: UserKPIScoreCardComponent, canActivate: [AuthGuard]},
      {
        path: 'retail_survey/market_intelligence',
        component: RetailSurveyMarketComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'market_intelligence_report',
        component: MarketIntelligenceReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'market_intelligence_summary_report',
        component: MarketIntelligenceSummaryReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'retail_audit_report', component: RetailAuditReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/tertiary_audit_report', component: TertiaryAuditReportComponent, canActivate: [AuthGuard]},
      {
        path: 'retail_audit_distribution_report',
        component: RetailAuditDistributionReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/volume_distribution',
        component: VolumeDistributionReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/tl_cluster_level_rollup',
        component: TLCLusterLevelRollupReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/activities_performance',
        component: ActivitiesPerformanceReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/report_availability', component: AvailabilityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/report_msl_compliance', component: MSLComplianceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/stock_availability', component: StockAvailabilityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/stock_distribution', component: StockDistributionReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/visibility_report', component: VisibilityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/hsn_report', component: HsnReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/gstr_report', component: GstrReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/gstr_line_report', component: GstrLineReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/secondary_sales_detailed', component: SecondarySalesDetailedReport, canActivate: [AuthGuard]},
      {path: 'reports/sale_metric_report', component: SaleMetricReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/sale_metric_beatwise_report', component: SaleMetricBeatwiseReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/sale_metric_outletwise_report', component: SaleMetricOutletwiseReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/daywise_sales_report', component: DaywiseSalesReport, canActivate: [AuthGuard]},
      {path: 'reports/daily_tracker_report', component: DailyTrackerReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/cumulative_sales', component: CumulativeSalesReport, canActivate: [AuthGuard]},
      {path: 'reports/invoice_report', component: InvoiceReport, canActivate: [AuthGuard]},
      {path: 'user_survey/user_survey_list', component: UserSurveyListComponent, canActivate: [AuthGuard]},
      {path: 'retail_survey/kyc', component: RetailSurveyKYCComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_survey_pivot', component: UserSurveyPivotReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_survey', component: UserSurveyReportComponent, canActivate: [AuthGuard]},
      {path: 'user_survey', component: UserSurveyComponent, canActivate: [AuthGuard]},
      {path: 'dms_payments', component: Dms_paymentsComponent, canActivate: [AuthGuard]},
      {path: 'credit_notes', component: Dms_credit_notesComponent, canActivate: [AuthGuard]},
      {path: 'reports/pre-order', component: Pre_orderComponent, canActivate: [AuthGuard]},
      {path: 'reports/pre-order-van', component: Pre_order_vanComponent, canActivate: [AuthGuard]},
      {path: 'reports/dist_stock_reconcile', component: Dist_reconcileComponent, canActivate: [AuthGuard]},
      {path: 'reports/survey_kyc', component: RetailSurveyKYCReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/task_activation', component: TaskActivationReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/outlet_aggregate_report', component: OutletAggReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/product_master_report', component: ProductMasterReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/retailer_sku_offer_report',
        component: RetailerSKUOfferReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/retailer_master_report',
        component: RetailerMasterReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/retailer_vertical_distributor_master',
        component: RetailerVerticalDistributorMasterReport,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/distributor_retailer_report',
        component: DistributorRetailerMappingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/daily_attendance_report',
        component: DailyAttendanceReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/monthly_attendance_report',
        component: MonthlyAttendanceReportV2Component,
        canActivate: [AuthGuard]
      },
      {path: 'reports/promoter_visit_report', component: PromoterVisitReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/tertiary_sales_report', component: TertiarySalesReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/employee_master', component: EmployeeMasterReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/monthly_attendancev2',
        component: MonthlyAttendanceReportV2Component,
        canActivate: [AuthGuard]
      },
      {path: 'reports/pricelistv2', component: PricelistReportV2Component, canActivate: [AuthGuard]},
      {path: 'reports/dcpricelist', component: PricelistDCWiseReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/outlet_last_visit', component: OutletLastVisitReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/distributor_last_checkin_report',
        component: DistributorLastCheckinReport,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/territory_user_mapping',
        component: TerritoryUsermappingReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/beat_last_visit', component: BeatLastVisitReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_coverage', component: BeatCoverageReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_activity', component: BeatActivityDetailedReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_planning_and_target_tracker', component: BeatPlanningAndTargetTrackerComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/beat_activity_summary',
        component: BeatActivitySummaryReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/user_activity', component: UserActivityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/customer_ledger', component: OutletFinanceLedgerReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/my_ledger', component: MyLedgerReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/task_adherence', component: TaskAdherenceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/asset_type_master', component: AssetTypeMasterReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_asset', component: PartnerAssetReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/product_performance', component: ProductPerformanceReport, canActivate: [AuthGuard]},
      {path: 'reports/outlet_not_visit', component: OutletNotVisitReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/outlet_visit_day_wise',
        component: OutletVisitDayWiseReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/retailer_target_achievement',
        component: RetailerTargetAchievementReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/salesman_target_achievement',
        component: SalesmanTargetAchievementReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/distributor_target_achievement',
        component: DistributorTargetAchievementReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/bill_scheme_s_report', component: Bill_scheme_reportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/product_scheme_s_report',
        component: Product_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/claim_scheme_report', component: ClaimSchemeReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/bill_scheme_invoice_s_report',
        component: BillInvoiceSchemeReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/product_scheme_invoice_s_report',
        component: Product_invoice_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/claim_scheme_invoice_s_report',
        component: Claim_invoice_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/bill_scheme_p_report',
        component: Primary_bill_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/product_scheme_p_report',
        component: Primary_product_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/claim_scheme_p_report',
        component: PrimaryClaimSchemeReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/bill_invoice_scheme_p_report',
        component: Primary_invoice_bill_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/product_invoice_scheme_p_report',
        component: PrimaryInvoiceProductSchemeReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/claim_invoice_scheme_p_report',
        component: Primary_invoice_claim_scheme_reportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/credit_returns', component: CreditReturnsReportComponent, canActivate: [AuthGuard]},
      {path: 'beats/beat_retailer', component: BeatRetailerListComponent, canActivate: [AuthGuard]},
      {path: 'beats/beat_plan_list', component: BeatPlanListComponent, canActivate: [AuthGuard]},
      {path: 'beats/beat_plan_detail', component: BeatPlanDetailPageComponent, canActivate: [AuthGuard]},
      {path: 'beats/territory', component: TerritoryListComponent, canActivate: [AuthGuard]},
      // {path: 'beats/beat_plan/details', component: BeatPlanDetailComponent, canActivate: [AuthGuard]},
      {path: 'beats/beat_schedule', component: BeatScheduleListComponent, canActivate: [AuthGuard]},
      {path: 'reports/distributor_master', component: DistributorMasterReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/credit_notes', component: Credit_notes_reportComponent, canActivate: [AuthGuard]},
      {path: 'reports/debit_notes', component: Debit_notes_reportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/salesman_target_achievement_bytarget',
        component: SalesmanTargetAchievementByTargetReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/monthly_attendance_team_wise',
        component: MonthlyAttendanceTeamWiseReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/daily_attendance_team_wise',
        component: DailyAttendanceTeamWiseReportComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'reports/salesman_main_target_achievement_month',
      //   component: SalesmanMainTargetAchievementMonthReportComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'reports/salesman_sub_target_achievement_month',
        component: SalesmanSubTargetAchievementMonthReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/beat_retailer', component: BeatRetailerReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/primary_sales', component: PrimarySalesDetailedReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_productivity', component: BeatProductivityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/custom_retail_audit', component: CustomAuditReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/van_stock', component: VanStockReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/van_stockv2', component: VanStockReportV2Component, canActivate: [AuthGuard]},
      {
        path: 'reports/van_day_wise_summary_report',
        component: VanDayWiseSummaryReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/stock_dispute', component: StockDisputeReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/internal_stock_transfer', component: InternalStockTransferComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/purchase_order_fulfillment',
        component: PurchaseOrderFulfillmentReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/sales_and_return_detailed',
        component: SalesandReturnDetailedReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'distributor_audit', component: DistributorAuditComponent, canActivate: [AuthGuard]},
      {path: 'distributor_audit/detail', component: DistributorAuditDetailComponent, canActivate: [AuthGuard]},
      {path: 'customers/retailers/details', component: CustomerDetailsComponent, canActivate: [AuthGuard]},
      {path: 'customers/influencers', component: InfluencerListComponent, canActivate: [AuthGuard]},
      {path: 'customers/influencers/details', component: InfluencerDetailsComponent, canActivate: [AuthGuard]},
      {path: 'customers/projects', component: ProjectListComponent, canActivate: [AuthGuard]},
      {path: 'customers/projects/details', component: ProjectDetailsComponent, canActivate: [AuthGuard]},
      {
        path: 'customers/outlet_product_recommendation',
        component: OutletProductRecommendationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/van_stock_reconciliation',
        component: VanStockReconciliationReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/primary_sales_stock',
        component: PrimarySalesStockReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'customers/leads', component: LeadsListComponent, canActivate: [AuthGuard]},
      {path: 'customers/partner_leads', component: PartnerLeadsListComponent, canActivate: [AuthGuard]},
      {path: 'customers/leads/details', component: LeadDetailsComponent, canActivate: [AuthGuard]},
      {path: 'suppliers/list', component: SupplierListComponent, canActivate: [AuthGuard]},
      {path: 'customers/partner_approve', component: PartnerApproveComponent, canActivate: [AuthGuard]},
      {path: 'customers/retailer_approve', component: ApproveRetailerComponent, canActivate: [AuthGuard]},
      {path: 'customers/planogram', component: PlanogramListComponent, canActivate: [AuthGuard]},
      {path: 'reports/zero_sales', component: ZeroSalesReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/master_zero_sales', component: MasterZeroSalesReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/consecutive_no_sales',
        component: ConsecutiveNoorderReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/coverage_and_productivity',
        component: CoverageProductivityReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/production_order', component: ProductionOrderReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/new_outlets', component: NewOutletReportComponent, canActivate: [AuthGuard]},

      {
        path: 'reports/salesman_performance_measure',
        component: SalesmanPerformanceMeasureReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/sr_scorecard', component: SRScorecardReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/less_than_mss_outlet',
        component: LessthanMSSOutletReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/revenue_dispersion', component: RevenueDispersionReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/sku_dispersion', component: SKUDispersionReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/daily_goals', component: DailyGoalsReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/daily_goals_v2', component: DailyGoalsReportV2Component, canActivate: [AuthGuard]},
      {path: 'reports/service_spare_parts', component: ServiceSparePartsComponent, canActivate: [AuthGuard]},
      {path: 'reports/service_spare_parts_request', component: ServiceSparePartsRequestComponent, canActivate: [AuthGuard]},
      {path: 'reports/service_line_spare_parts', component: ServiceLineSparePartsComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/product_category_growth',
        component: ProductCategoryGrowthReportComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'reports/asset_service_report',
        component: AssetServiceReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/partner_meet', component: PartnerMeetReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_visit', component: PartnerVisitReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/meet_attendees', component: MeetAttendeesReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_meet_gallery', component: PartnerMeetGalleryReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/claim_scheme_order', component: ClaimSchemeOrderReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/retailer_vertical_distributor_master',
        component: RetailerVerticalDistributorMasterReport,
        canActivate: [AuthGuard]
      },
      {path: 'reports/beat_retailerv2', component: BeatRetailerV2ReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_partner', component: BeatPartnerReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/retailer_asset_sales', component: RetailerAssetSalesReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_lead_master', component: PartnerLeadMasterReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/sale_lead_master', component: SaleLeadMasterReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/ytd_supplier_order_growth', component: YTDSupplierOrderGrowthComponent, canActivate: [AuthGuard]},
      {path: 'reports/ytd_primary_order_growth', component: YTDPrimaryOrderGrowthComponent, canActivate: [AuthGuard]},
      {path: 'reports/product_sug', component: ProductSugReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/retailer_sup_vertical_map', component: RetailerSupVerticalComponent, canActivate: [AuthGuard]},
      {path: 'reports/influencer_master', component: InfluencerMasterReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/partner_contact_master',
        component: PartnerContactMasterReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/project_master', component: ProjectMasterReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/asset_activity', component: AssetActivityReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_performance', component: UserPrformanceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/dealer_performance', component: DealerPrformanceReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/ytd_dealer_billing', component: YTDDealerBillingReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/dealer_billing', component: DealerBillingReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/visit_summary', component: VisitSummaryReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/dealer_outstanding', component: DealerOutstandingComponent, canActivate: [AuthGuard]},
      {path: 'reports/van_bonus', component: VanBonusReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_leads', component: UserLeadsReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_alerts', component: UserAlertMasterComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_alerts', component: PartnerAlertMasterComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_merchandise', component: PartnerMerchandiseReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_checkin_master', component: PartnerCheckinMasterComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_raw_checkin', component: PartnerRAWCheckinComponent, canActivate: [AuthGuard]},
      {path: 'reports/beat_planning_sales', component: BeatPlanningSalesComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_kpi_summary', component: UserKpiSummaryComponent, canActivate: [AuthGuard]},
      {path: 'reports/territory_kpi_summary', component: TerritoryKpiSummaryComponent, canActivate: [AuthGuard]},
      {path: 'reports/user_kpi_leaderboard', component: UserKpiResultLeaderBoardComponent, canActivate: [AuthGuard]},
      {path: 'reports/territory_kpi_leaderboard', component: TerritoryKpiResultLeaderBoardComponent, canActivate: [AuthGuard]},
      {path: 'reports/raw_visits', component: RawVisitComponent, canActivate: [AuthGuard]},
      {path: 'reports/member_visits', component: MemberVisitComponent, canActivate: [AuthGuard]},
      {path: 'reports/territory_visits', component: TerritoryVisitComponent, canActivate: [AuthGuard]},
      {path: 'reports/monthly_partner_visits', component: MonthlyPartnerVisitReviewComponent, canActivate: [AuthGuard]},
      {path: 'reports/tertiary_sales_with_serial', component: TertiarySalesWithSerialNoReport, canActivate: [AuthGuard]},
      {path: 'reports/raw_user_alerts', component: RawUserAlertsComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/stock_audit_market_share',
        component: StockAuditMarketShareReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/daily_user_performance',
        component: DailyUserPerformanceReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/user_performance_summary',
        component: UserPerformanceSummaryReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/van_sale_summary', component: VanSaleSummaryReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/outlet_stock_ageing', component: OutletStockAgeingReportComponent, canActivate: [AuthGuard]},


      {path: 'dms/customers', component: CustomerListComponent, canActivate: [AuthGuard]},
      {path: 'dms/suppliers', component: SupplierComponent, canActivate: [AuthGuard]},
      {path: 'dms/return_invoice', component: ReturnInvoiceComponent, canActivate: [AuthGuard]},
      {path: 'dms/sec_sale_orders', component: SecondarySaleOrderListComponent, canActivate: [AuthGuard]},
      {path: 'dms/direct_primary_orders', component: DirectOrderListComponent, canActivate: [AuthGuard]},
      {path: 'dms/direct_primary_invoices', component: DirectSaleInvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'dms/direct_invoice', component: DirectInvoiceComponent, canActivate: [AuthGuard]},
      {path: 'dms/direct_order', component: DirectOrderComponent, canActivate: [AuthGuard]},
      {path: 'dms/sale_order', component: SecondaryOrderComponent, canActivate: [AuthGuard]},
      // {path: 'dms/delivery_plan', component: DeliveryPlanListComponent, canActivate: [AuthGuard]},
      {path: 'dms/delivery_plans', component: DeliveryPlanListV1Component, canActivate: [AuthGuard]},
      // {path: 'dms/delivery_plan/details', component: DeliveryPlanDetailComponent, canActivate: [AuthGuard]},
      {path: 'dms/delivery_plan_detail', component: DeliveryPlanDetailV1Component, canActivate: [AuthGuard]},
      {path: 'dms/sec_sale_invoices', component: SecondarySaleInvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'dms/sale_invoice', component: SaleInvoiceComponent, canActivate: [AuthGuard]},
      {path: 'dms/sec_sale_invoices', component: SecondarySaleInvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'dms/credit_notes', component: CreditNoteListComponent, canActivate: [AuthGuard]},
      {path: 'dms/debit_notes', component: DebitNoteListComponent, canActivate: [AuthGuard]},
      {path: 'dms/pay_receipts', component: PaymentReceiptListComponent, canActivate: [AuthGuard]},
      {path: 'dms/ledger', component: LedgerListComponent, canActivate: [AuthGuard]},
      {path: 'dms/claim_detail', component: ClaimDetailComponent, canActivate: [AuthGuard]},
      {path: 'dms/sec_schemes', component: SecondarySchemeListComponent, canActivate: [AuthGuard]},
      {path: 'dms/sec_return_invoices', component: SecondaryReturnInvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'dms/purchase_orders', component: PurchaseOrderListComponent, canActivate: [AuthGuard]},
      {path: 'dms/purchase_order', component: PurchaseOrderV2Component, canActivate: [AuthGuard]},
      {path: 'dms/purchase_invoices', component: PurchaseInvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'dms/p_schemes', component: PrimarySchemeListComponent, canActivate: [AuthGuard]},
      {path: 'dms/grn', component: PurchaseInvoiceComponent, canActivate: [AuthGuard]},
      {path: 'dms/purchase_orders', component: PurchaseOrderListComponent, canActivate: [AuthGuard]},

      {path: 'dms/claims', component: ClaimsListComponent, canActivate: [AuthGuard]},
      {path: 'dms/supplier_claims', component: ClaimsToApproveListComponent, canActivate: [AuthGuard]},
      {path: 'dms/payment', component: PaymentDetailComponent, canActivate: [AuthGuard]},
      {path: 'dms/raise_scheme_claims', component: RaiseSchemeClaimListComponent, canActivate: [AuthGuard]},
      {path: 'dms/raise_sale_return_claims', component: RaiseSaleReturnClaimListComponent, canActivate: [AuthGuard]},
      {path: 'dms/raise_multi_bill_claims', component: RaiseMultiBillClaimListComponent, canActivate: [AuthGuard]},
      {path: 'dms/my_stock', component: MyStockListComponent, canActivate: [AuthGuard]},
      {path: 'dms/batch_stock', component: BatchStockListComponent, canActivate: [AuthGuard]},

      {path: 'vansales/load_outs', component: LoadOutListComponent, canActivate: [AuthGuard]},
      {path: 'vantransfers', component: StockTransferListComponent, canActivate: [AuthGuard]},
      {path: 'vansales/transfer', component: VanTransferComponent, canActivate: [AuthGuard]},
      {path: 'vansales/load_out', component: LoadOutOrderComponent, canActivate: [AuthGuard]},
      {path: 'vansales/settlements', component: SettlementListComponent, canActivate: [AuthGuard]},
      {path: 'vansales/settlement', component: DcvanlistComponent, canActivate: [AuthGuard]},


      {path: 'reports/dc_stock', component: DCStockReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/single_margin_bill', component: SingleMarginBillSchemeReportComponent, canActivate: [AuthGuard]},
      {path: 'task/survey', component: TaskSurveyComponent, canActivate: [AuthGuard]},
      {path: 'task/survey/detail', component: TaskSurveyDetailComponent, canActivate: [AuthGuard]},
      {path: 'reports/task_survey', component: TaskSurveyReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/target_and_visit_report', component: TargetAndVisitSummaryComponent, canActivate: [AuthGuard]},
      {path: 'survey_detail', component: SurveyDetailComponent, canActivate: [AuthGuard]},
      // {path: 'claims/list', component: ClaimsComponent, canActivate: [AuthGuard]},
      // {path: 'claims/raise', component: ClaimSchemeComponent, canActivate: [AuthGuard]},
      // {path: 'claims/returns', component: ClaimReturnComponent, canActivate: [AuthGuard]},
      // {path: 'claims/approve', component: ClaimApproveComponent, canActivate: [AuthGuard]},
      {path: 'reports/new_report', component: NewReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/outlet_product_recommendation',
        component: OutletProductRecommendationReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'reports/purchase_order', component: PurchaseOrderReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/purchase_order_imei', component: PurchaseOrderImeiReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/secondary_sales_detailed_imei',
        component: SecondarySalesDetailedIMEIReport,
        canActivate: [AuthGuard]
      },
      {path: 'reports/partner_kyc_survey_pivot', component: PartnerKYCPivotReportComponent, canActivate: [AuthGuard]},
      {path: 'reports/partner_kyc_dump_survey', component: PartnerKYCDumpReportComponent, canActivate: [AuthGuard]},
      {
        path: 'reports/partner_market_intelligence_pivot',
        component: PartnerMarketIntelligencePivotReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports/partner_market_intelligence_dump_survey',
        component: PartnerMarketIntelligenceDumpReportComponent,
        canActivate: [AuthGuard]
      },
      {path: 'products/schemes', component: SchemeMasterListComponent, canActivate: [AuthGuard]},
      {path: 'products/pricelist', component: PricelistMasterListComponent, canActivate: [AuthGuard]},
      {path: 'products/schemes/detail', component: SchemeMasterDetailComponent, canActivate: [AuthGuard]},
      {path: 'products/stock', component: StockListComponent, canActivate: [AuthGuard]},
      {path: 'vansale_credit_notes', component: CreditNoteListComponent, canActivate: [AuthGuard]},

      //Standalone Pages
      {path: 'imagerender', component: ImageRenderComponent},
      {path: 'meet_att', component: MeetAttendanceComponent},
      {path: 'reg_meet_att', component: RegisterMeetAttendeeComponent},
      {path: 'otp_verification', component: OTPVerificationComponent},
      {path: 'reports/loyalty_program_tracker', component: LoyaltyProgramTrackerComponent, canActivate: [AuthGuard]},
      {path: 'reports/loyalty_program_details', component: LoyaltyProgramDetailsComponent, canActivate: [AuthGuard]}
    ]), NgxChartsModule, ErrorHandlerModule, MatCheckboxModule, MatProgressSpinnerModule, NgxBarcodeModule
  ],
  providers: [BnNgIdleService, DatePipe, AuthGuard, GadgetPropertyService, UpdateService, OverlayService, AppComponent,
    {provide: OKTA_CONFIG, useValue: environment.oktaConfig},
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [LAZY_MAPS_API_CONFIG, SharedService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public perf;
  public analytics;

  constructor() {
    ModuleRegistry.registerModules(AllModules);

    // TODO: Replace the following with your app's Firebase project configuration
    const firebaseConfig = {
      apiKey: "AIzaSyC89xRGREv7-9o0C9IL9Syj2nQRY3jUcz0",
      authDomain: "salesdiary-pwa.firebaseapp.com",
      projectId: "salesdiary-pwa",
      storageBucket: "salesdiary-pwa.appspot.com",
      messagingSenderId: "272841099066",
      appId: "1:272841099066:web:4658a9a37b0a2cd60f3203",
      measurementId: "G-N8GMWKFGT7"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    this.perf = getPerformance(app);
    this.analytics = analytics;
    setAnalyticsCollectionEnabled(this.analytics, true);
  }
}
