import {Component, ElementRef, Renderer2} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SharedService} from './_services/shared_service';

@Component({
  selector: 'app-cell-delete',
  template: `<div [hidden]="hide"><span class="element-po" style="margin-left: 25%;"><img (click)="removeRow()" class="element-img" src="../assets/delete1.svg" alt=""></span></div>`
})
export class DeleteComponent implements ICellRendererAngularComp {
  public params: any;
  public searchValue;
  public hide = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private sharedService: SharedService) {}

  agInit(params: any): void {
    this.params = params;
    this.searchValue = params.data[this.params.column.colId] ;
    // if ((this.params.context.componentParent.dmsStatus === 'confirm' || this.params.context.componentParent.dmsStatus === 'sale') &&
    //   (params.data.ordered_qty && parseFloat(params.data.ordered_qty) !== 0)) {
    //   this.hide = true;
    // }
  }

  removeRow() {
    this.params.data.QTY = 0;
    if (this.params.context.componentParent) {
      this.params.context.componentParent.removeProduct(this.params.rowIndex);
    } else {
      this.params.context.removeProduct(this.params.rowIndex);
    }
  }

  refresh(): boolean {
    return false;
  }
}
