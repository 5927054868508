import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../../../_services/localforage.service";
import {ErrorObject} from "../../../../error/error-model";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-move-beat-retailer-popup',
  templateUrl: './generate_multi_bill_claims_popup.component.html',
  styleUrls: ['./generate_multi_bill_claims_popup.component.scss']

})

export class GenrateMultiBillClaimsPopupComponent implements OnInit {

  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  API_URL = '';
  USER;
  access_token;
  lines = [];
  invoice_ids = [];
  line_count = 0;
  invoices_count = 0;
  claim_amount = 0;
  inv_amount = 0;
  scheme_count = 0;

  preloader: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  quantity = 0;
  customer_count = 0;
  claimType = '';

  @Output() saveActionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              protected _localforageService: LocalforageService,
              private domSanitizer: DomSanitizer,
              private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.lines = dialogdata.lines;
    this.claimType = dialogdata.type;
    this.invoice_ids = [];
    this.lines.forEach(line => {
      if (!line.claim_id) {
        this.invoice_ids = this.invoice_ids.concat(line.inv_ids);
        this.claim_amount = this.claim_amount + parseFloat(line.claim_amount);
        this.inv_amount = this.inv_amount + parseFloat(line.inv_amount);
      }
    });

    this.access_token = localStorage.getItem('resfreshToken');
    this.USER = JSON.parse(localStorage.getItem('user'));

    this.line_count = this.lines.length;
    this.invoices_count = this.invoice_ids.length;


    this.claim_amount = Number(this.claim_amount.toFixed(2));
    this.customer_count = this.unique(this.lines, ['code']).length;
    this.scheme_count = this.unique(this.lines, ['scheme_code']).length;

    console.log('constructor');
  }

  ngOnInit(): void {

  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }


  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  generateClaims() {

    this.API_URL = '/api/pwa_dms_connects/generate_multi_bill_scheme_claims';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    if (this.invoice_ids.length > 0) {
      paramObject.data = {};
      paramObject.data = {
        invoice_ids: this.invoice_ids,
        claim_amount: this.claim_amount,
        inv_amount: this.inv_amount,
        // team_id: this.USER.sale_team_id,
        // datas: this.expensephotos,
        // claim_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        claimType: 'multi bill'
      };
      this.apiService.post(this.API_URL, paramObject)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results')) {
              // this.dialog.closeAll();
              // window.location.reload();
              if (res.results.status === 200) {
                this.saveData.emit(res.results.data);
              } else {
                this.toastr.error(res.results.msg);
              }
            } else {
              this.toastr.error('Error Generating Claims');
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Error Generating Claims');
          });
    } else {
      this.toastr.error('No Lines Selected');
    }
  }

}
