<section class="dialog-section">
  <button class="close-dialog" (click)="closeDialog()">
    <i class="fas fa-times"></i>
  </button>
  <div class="container-padding">
    <div class="header-section">
      <div class="col-sm-2 col-xs-6" sty>
        <!--<img class="img-responsive" src="assets/no-image-icon.png" style="display: block;margin: 0 auto;"/>-->
        <img (click)="showImagePopup('partner_asset',assetDetails.asset_store_fname)" class="img-responsive" [src]="photo" style="display: block;margin: 0 auto;">
      </div>
      <div class="col-sm-10 col-xs-6">
        <h2 style="font-weight: 400;font-size: 24px;">{{assetDetails['retailer']}}</h2>
        <h2 style="font-weight: 500;padding-top: 10px;">{{assetDetails['name']}}</h2>
        <!--  <p>{{assetData['type']}} || {{assetData['brand']}}</p>-->
        <div class="row" style="margin-top: 20px;">
          <p >{{assetDetails['type']}}</p>
          <p style="margin-left: 10px;margin-right: 10px;color: #9D9D9D;">|</p>
          <p >{{assetDetails['brand']}}</p>
          <p style="margin-left: 10px;margin-right: 10px;color: #9D9D9D;">|</p>
          <p style=" color: #9D9D9D;margin-right: 5px;">Installed Date : </p>
          <p >{{assetDetails['installed_date'] | date : "dd MMMM yyyy"}}</p>
        </div>
        <div *ngIf="assetDetails['deployed']" style="margin-top: 20px;">
          <div class="row">
            <p>Deployed on {{assetDetails['deployed_on']}} by {{assetDetails['deployed_by']}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xs-12" style="margin-top: 10px;">
      <div class="header-grey-section">
        <h2>Activities</h2>
      </div>
      <div class="body-section">
        <div *ngFor="let activity of assetDetails.activities" style="border-bottom: solid 1px;color:#A3ACB9">
          <div class="row" style="width: 100%">
            <div  class="col-sm-4 col-xs-6" style="margin-top: 20px;margin-bottom: 20px;">
              <!-- <img *ngIf="expense.photo" class="img-responsive" [src]="expense.photo" style="
                                          height: 150px;
                                          border-left: solid 3px lightgrey;
                                          border-right: solid 3px lightgrey;
                                          border-top: solid 3px lightgrey;
                                          border-bottom: solid 3px lightgrey;"/>-->
              <!--<img class="img-responsive" src="assets/no-image-icon.png" style="
                                     display: block;
   display: block;
    margin: 0 auto;"/>-->
              <img class="img-responsive" (click)="showImagePopup('asset_activity',activity.store_fname)" [src]="activity.photo" style="display: block;margin: 5 auto;">
            </div>
            <div class="col-sm-8 col-xs-6"  style="margin-top: 20px;">
              <div >
                <label style="color:#9D9D9D;font-weight: 400;">{{activity.type}}</label>
              </div>
              <div>
                <label style="font-size: 20px;color:#2D2D2D;font-weight: 500;">{{activity.name}}</label>
              </div>
              <!-- <div style=" display: block;margin-top: 10px;">
                 <label >Reliance retail store Pvt Ltd.</label>
               </div>-->
              <div class="row" style="margin-top: 15px;">
                <label >{{activity.salesman}}</label>
                <label style="color:#9D9D9D;margin-left: 10px;margin-right: 10px;">|</label>
                <label style="color:#9D9D9D;font-weight: 400;margin-right: 5px;">Activity Date : </label>
                <label >{{activity.activity_date | date : "EEE, dd MMM"}}</label>
              </div>
              <div >
                <label style="color:#9D9D9D;font-weight: 400;margin-right: 5px;font-size: 14px;">Comments : </label>
                <label style="font-size: 14px;"> {{activity.comments}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
