<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dcvanlist">
            <div class="search-list">
              <div class="col-md-6 pad0">
                  <div class="search">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                    placeholder="Search for DC">
                  </div>
              </div>
              <div class="col-md-6 pad0">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR">
                        <div class="date-picker">
                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                            <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                            <owl-date-time (afterPickerClosed)="changeDate()" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                    </li>
                      <li class="margLR">
                          <app-excelicon [gridOption]="gridOptions" [title]="'DC List'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
              </div>
              <div class="col-md-12 pl-0" style="margin-top: 10px;margin-right: 8px;">
                <div class="my-table dcvanlist">
                  <ag-grid-angular id="myGrid" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                   (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
                  </ag-grid-angular>
                  <div *ngIf="preloader">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

