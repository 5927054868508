import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-retail-stock-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RetailStockReport extends ReportBase implements OnInit {

  apiData: any = [];

  ngOnInit() {

    this.sharedService.setHeader('Retail Stock History');
    this.API_URL = '/api/pwa_reports/get_retail_stock_summary';

    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    //this.configureGrid();
      }

  loadServerData() {
    this.goClicked = true;
    //debugger;
    /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
       t.start();
       t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }

  configureGrid() {
    this.columnDefs = [];

    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    // retailer_id integer, outlet_code varchar, outlet_name varchar, product_id integer, product_code varchar,
    //   product varchar, salesman varchar, state varchar,

    this.columnDefs = [
      /*  { headerName: 'State',  field: 'state',  width: 70},*/
      {
        headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.region) {
              return params.data.region;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.cluster) {
              return params.data.cluster;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.territory) {
              return params.data.territory;
            } else {
              return 'NA';
            }
          }
        }
      },
      {headerName: 'Salesman', field: 'salesman', enableRowGroup: true, width: 100},
      /*  { headerName: 'Role', field: 'role', width: 100},*/
      {headerName: 'RCode', field: 'outlet_code', enableRowGroup: true, width: 80},
      {headerName: 'Retailer', field: 'outlet_name', enableRowGroup: true, width: 150},
      {headerName: 'DCode', field: 'd_code', enableRowGroup: true, width: 80},
      {headerName: 'Distributor', field: 'd_name', enableRowGroup: true, width: 150},
      {headerName: 'PCode', field: 'product_code', enableRowGroup: true, width: 80},
      {headerName: 'Product', field: 'product', enableRowGroup: true, width: 180},
      {headerName: 'AVG STOCK', field: 'avg_deviation', enableRowGroup: true, width: 80},
      {
        headerName: 'S1 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          // console.log(params.data.stock);
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 0) {
            if (params.data.stock[0].rn === 1) {
              return params.data.stock[0].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S1 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 0) {
            if (params.data.stock[0].rn === 1) {
              return params.data.stock[0].qty;
            }
          }
        }
      },
      {
        headerName: 'S1 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 0) {
            if (params.data.stock[0].rn === 1) {
              return params.data.stock[0].salesman;
            }
          }
        }
      },
      {
        headerName: 'S1 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 0) {
            if (params.data.stock[0].rn === 1) {
              return params.data.stock[0].role;
            }
          }
        }
      },
      {
        headerName: 'S2 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          // console.log(params.data.stock);
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 1) {
            if (params.data.stock[1].rn === 2) {
              return params.data.stock[1].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S2 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 1) {
            if (params.data.stock[1].rn === 2) {
              return params.data.stock[1].qty;
            }
          }
        }
      },
      {
        headerName: 'S2 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 0) {
            if (params.data.stock[0].rn === 1) {
              return params.data.stock[0].salesman;
            }
          }
        }
      },
      {
        headerName: 'S2 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 1) {
            if (params.data.stock[1].rn === 2) {
              return params.data.stock[1].role;
            }
          }
        }
      },
      {
        headerName: 'S3 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 2) {
            if (params.data.stock[2].rn === 3) {
              return params.data.stock[2].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S3 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 2) {
            if (params.data.stock[2].rn === 3) {
              return params.data.stock[2].qty;
            }
          }
        }
      },
      {
        headerName: 'S3 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 1) {
            if (params.data.stock[1].rn === 2) {
              return params.data.stock[1].salesman;
            }
          }
        }
      },
      {
        headerName: 'S3 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 2) {
            if (params.data.stock[2].rn === 3) {
              return params.data.stock[2].role;
            }
          }
        }
      },
      {
        headerName: 'S4 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 3) {
            if (params.data.stock[3].rn === 4) {
              return params.data.stock[3].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S4 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 3) {
            if (params.data.stock[3].rn === 4) {
              return params.data.stock[3].qty;
            }
          }
        }
      },
      {
        headerName: 'S4 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 3) {
            if (params.data.stock[3].rn === 4) {
              return params.data.stock[3].salesman;
            }
          }
        }
      },
      {
        headerName: 'S4 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 3) {
            if (params.data.stock[3].rn === 4) {
              return params.data.stock[3].role;
            }
          }
        }
      },
      {
        headerName: 'S5 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 4) {
            if (params.data.stock[4].rn === 5) {
              return params.data.stock[4].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S5 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 4) {
            if (params.data.stock[4].rn === 5) {
              return params.data.stock[4].qty;
            }
          }
        }
      },
      {
        headerName: 'S5 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 4) {
            if (params.data.stock[4].rn === 5) {
              return params.data.stock[4].salesman;
            }
          }
        }
      },
      {
        headerName: 'S5 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 4) {
            if (params.data.stock[4].rn === 5) {
              return params.data.stock[4].role;
            }
          }
        }
      },
      {
        headerName: 'S6 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 5) {
            if (params.data.stock[5].rn === 6) {
              return params.data.stock[5].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S6 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 5) {
            if (params.data.stock[5].rn === 6) {
              return params.data.stock[5].qty;
            }
          }
        }
      },
      {
        headerName: 'S6 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 5) {
            if (params.data.stock[5].rn === 6) {
              return params.data.stock[5].salesman;
            }
          }
        }
      },
      {
        headerName: 'S6 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 5) {
            if (params.data.stock[5].rn === 6) {
              return params.data.stock[5].role;
            }
          }
        }
      },
      {
        headerName: 'S7 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 6) {
            if (params.data.stock[6].rn === 7) {
              return params.data.stock[6].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S7 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 6) {
            if (params.data.stock[6].rn === 7) {
              return params.data.stock[6].qty;
            }
          }
        }
      },
      {
        headerName: 'S7 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 6) {
            if (params.data.stock[6].rn === 7) {
              return params.data.stock[6].salesman;
            }
          }
        }
      },
      {
        headerName: 'S7 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 6) {
            if (params.data.stock[6].rn === 7) {
              return params.data.stock[6].role;
            }
          }
        }
      },
      {
        headerName: 'S8 Date', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 7) {
            if (params.data.stock[7].rn === 8) {
              return params.data.stock[7].date;
            }
          }
          return '';
        }
      },
      {
        headerName: 'S8 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 7) {
            if (params.data.stock[7].rn === 8) {
              return params.data.stock[7].qty;
            }
          }
        }
      },
      {
        headerName: 'S8 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 7) {
            if (params.data.stock[7].rn === 8) {
              return params.data.stock[7].salesman;
            }
          }
        }
      },
      {
        headerName: 'S8 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 7) {
            if (params.data.stock[7].rn === 8) {
              return params.data.stock[7].role;
            }
          }
        },
      },
      {
        headerName: 'S9 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 8) {
            if (params.data.stock[8].rn === 9) {
              return params.data.stock[8].qty;
            }
          }
        }
      },
      {
        headerName: 'S9 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 8) {
            if (params.data.stock[8].rn === 9) {
              return params.data.stock[8].salesman;
            }
          }
        }
      },
      {
        headerName: 'S9 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 8) {
            if (params.data.stock[8].rn === 9) {
              return params.data.stock[8].role;
            }
          }
        },
      },
      {
        headerName: 'S10 Stock', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 9) {
            if (params.data.stock[9].rn === 10) {
              return params.data.stock[9].qty;
            }
          }
        }
      },
      {
        headerName: 'S10 SALESMAN', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 9) {
            if (params.data.stock[9].rn === 10) {
              return params.data.stock[9].salesman;
            }
          }
        }
      },
      {
        headerName: 'S10 ROLE', width: 80, enableRowGroup: true,
        valueGetter: params => {
          if (params.data && params.data.stock != null && Object.keys(params.data.stock).length > 9) {
            if (params.data.stock[9].rn === 10) {
              return params.data.stock[9].role;
            }
          }
        }
      },
    ];
  }

  configureData() {
    this.rowData = [];
    for (const key in this.apiData) {

      var indRow = {};
      var stockRow: any = {};
      var deviationRow: any = {};
      stockRow = this.apiData[key];


      var count = 0;
      var avg = 0;
      var sum = 0;
      for (var key1 in this.apiData[key].stock) {

        if (this.apiData[key].stock != null && this.apiData[key].stock.length > 0) {
          sum += this.apiData[key].stock[key1].qty;
          count++;
        }
      }

      avg = sum / count;

      avg = Math.round((avg + Number.EPSILON) * 100) / 100;

      deviationRow.avg_deviation = avg;

      indRow = {
        ...stockRow,
        ...deviationRow
      };

      this.rowData.push(indRow);

    }

    console.log(this.rowData);
  }


}
