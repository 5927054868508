<div class="eod-card-list">
  <ul>
    <li>
      <label>TC</label>
      <span>{{tc}}</span>
    </li>
    <li>
      <label>VC</label>
      <span>{{vc}}</span>
    </li>
    <li>
      <label>PC</label>
      <span>{{pc}}</span>
    </li>
    <li>
      <label>GC</label>
      <span>{{gc}}</span>
    </li>
    <li>
      <label>NV</label>
      <span>{{nv}}</span>
    </li>
  </ul>
</div>
