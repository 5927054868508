<div class="van-card">
  <div class="van-card-img">
    <img src={{img}} />
  </div>
  <div class="van-card-desc">
    <h1>{{value}}</h1>
    <label>{{title}}</label>
  </div>
</div>

