import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'cell-editor',
  template: `<div class="po-top po-page" layout="row">
            <input [ngModel]="searchValue"  (ngModelChange)="modelChanged($event)" [disabled]="!edit_enable" type="text" style="font-size: 100%;"
                   name="searchValue" autocomplete="off"
                           class="custom-inputbox" [ngClass]="{'color_red': rangeVal === false, 'color_blue': searchValue === '0', 'color_green': change_color === true}"/>
            <span *ngIf="showValue" style="font-size: 80%;" class="element-req">{{req}}</span>
            </div>`
})
export class CellEditRenderer implements ICellRendererAngularComp {
  public params: any;
  public sug;
  public req;
  public searchValue;
  public showValue = false;
  public dispatch = false;
  public show_sug = true;
  public change_color = false;
  public edit_enable = false;
  public rangeVal = true;
  public total;
  public prvs = {};

  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  agInit(params: any): void {
    this.params = params;
    this.edit_enable = this.params.context.componentParent.edit_enable;
    if (!this.params.value) {
      this.searchValue = 0;
      this.sug = 0;
      this.show_sug = false;
    } else {
      this.searchValue = params.value.qty;
      this.sug = params.value.sug;
      this.change_color = params.value.change_color;
    }
    if (this.params.context.componentParent.enable_recommented && this.params.data.dispatch_status !== 'delivered' &&
      (parseFloat(params.value.pre_sug) !== 0 || parseFloat(params.value.ord_sug) !== 0)) {
      if ((parseFloat(this.searchValue) < params.value.min) ||
        (parseFloat(this.searchValue) > params.value.max)) {
        if (params.value.min === 0 && params.value.max === 0) {
          this.rangeVal = true;
        } else {
          this.rangeVal = false;
          this.change_color = false;
        }
      } else {
        this.rangeVal = true;
      }
    }
    this.req = 0;
    if (!params.value.active || params.data.dispatch_status === 'dispatched' || params.data.dispatch_status === 'delivered') {
      this.edit_enable = false;
    }
    if (params.data.ostatus === 'inactive' && !this.params.context.componentParent.edit_notplaced) {
      this.edit_enable = false;
    }

    if (this.params.context.componentParent.status === 'Processed' || this.params.context.componentParent.status === 'Inprogress') {

      this.renderer.removeClass(this.elRef.nativeElement.querySelector('.po-page'), 'active');
      this.showValue = true;
      this.show_sug = false;
    }
    if ((this.params.context.componentParent.status === 'Submitted') || (this.params.context.componentParent.status === 'Completed')) {
      this.renderer.addClass(this.elRef.nativeElement.querySelector('.po-page'), 'active');
      this.showValue = false;
      this.show_sug = false;
      this.edit_enable = false;
    }
    this.total = this.params.context.componentParent.gridOptions.pinnedTopRowData;

    /*this.searchValue.valueChanges.subscribe(val => {
     this.modelChanged(val);
    });*/
  }

  modelChanged(event) {

    if (event !== '') {
      let new_prod = false;
      this.searchValue = event;
      this.change_color = true;
      if (this.params.context.componentParent.enable_recommented && (parseFloat(this.params.value.pre_sug) !== 0 || parseFloat(this.params.value.ord_sug) !== 0)) {
        if ((parseFloat(this.searchValue) < this.params.value.min) || (parseFloat(this.searchValue) > this.params.value.max)) {
          this.rangeVal = false;
          this.change_color = false;
        } else {
          this.rangeVal = true;
        }
      } else if (this.params.context.componentParent.enable_recommented && (!this.params.value.ordered || this.params.value.ordered === 0)) {
        this.change_color = false;
      }
      if (this.params.context.componentParent.status === 'Draft') {
        if (this.prvs[this.params.data.VAN]) {
          this.total[0][this.params.colDef.field] = this.total[0][this.params.colDef.field] - this.prvs[this.params.data.VAN];
          this.total[0][this.params.colDef.field] += parseFloat(event);
        } else {
          this.total[0][this.params.colDef.field] = this.total[0][this.params.colDef.field] - this.params.value.qty;
          this.total[0][this.params.colDef.field] += parseFloat(event);
        }
        this.prvs[this.params.data.VAN] = parseFloat(event);
        this.params.context.componentParent.gridApi.setPinnedTopRowData(this.total);
      }
      this.params.value.qty = event;
      if (this.showValue) {
        if (this.params.value.ordered === undefined) {
          this.req = this.searchValue;
          new_prod = true;
        } else {
          this.req = this.searchValue - this.params.value.ordered;
        }
      } else {
        if (this.params.value.ordered === undefined) {
          new_prod = true;
        }
      }
      this.params.context.componentParent.editCell(this.params.data, this.searchValue, this.params.colDef.field, new_prod);
    }
  }

  editCell() {

    if (this.showValue) {
      this.req = this.searchValue - this.params.value.ordered;
    }
    this.params.context.componentParent.editCell(this.params.data, this.searchValue, this.params.colDef.field);
  }

  refresh(): boolean {
    return false;
  }
}
