import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-beatperformance-component',
  templateUrl: '../_common/reportbase.html',

})


export class BeatPerformanceComponent extends ReportBase implements IReport, OnInit {

  uqRoles;

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Beat Performance Report');
    this.API_URL = '/api/pwa_reports/get_beat_performance';
    this.showableFilters.salesman = true;
  }

  setTitle() {
    this.title = 'Beat Performance Report';
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: "Period", field: "month", width: 50},
          {headerName: "Region", field: "region", width: 100},
          {headerName: "District", field: "zone", width: 100},
          {headerName: "Territory", field: "territory", width: 100},
          {headerName: "Salesman", field: "salesman", width: 120},
          {headerName: "Beat id", field: "beat_plan_id", width: 80},
          {headerName: "Beat", field: "beat", width: 100},
          {headerName: "Type", field: "type", width: 50},
          {headerName: "Date", field: "date", cellStyle: {textAlign: 'center'}, width: 80},
          {headerName: "TC", field: "tc", cellStyle: {textAlign: 'right'}, width: 80},
          {headerName: "VC", field: "vc", cellStyle: {textAlign: 'right'}, width: 80},
          {headerName: "PC", field: "pc", cellStyle: {textAlign: 'right'}, width: 80},
          {headerName: "Target", field: "target", cellStyle: {textAlign: 'right'}, width: 100},
          {headerName: "Revenue", field: "revenue", cellStyle: {textAlign: 'right'}, width: 100},
          {headerName: "MV Revenue", field: "mv_revenue", cellStyle: {textAlign: 'right'}, width: 100, hide: true},
          {headerName: "MVPC", field: "mvpc", cellStyle: {textAlign: 'right'}, width: 100, hide: true},
          {headerName: "FirstActivity", field: "firstactivity", cellStyle: {textAlign: 'center'}, width: 80},
          {headerName: "LastActivity", field: "lastactivity", cellStyle: {textAlign: 'center'}, width: 80},
          {headerName: "WorkMins", field: "workmin", cellStyle: {textAlign: 'right'}, width: 80},
          {headerName: "Stock", field: "stock_count", cellStyle: {textAlign: 'right'}, width: 70},
          {headerName: "Shelf", field: "shelf_count", cellStyle: {textAlign: 'right'}, width: 70},
          {headerName: "BTL", field: "btl_count", cellStyle: {textAlign: 'right'}, width: 70},
          {headerName: "EOD", field: "eod", cellStyle: {textAlign: 'left'}, width: 50},
          {headerName: "Scheduled Count", field: "scheduled_count", cellStyle: {textAlign: 'right'}, width: 100}
        ]
      },
      {headerName: 'SUPERVISION', children: []}
    ];

    for (const key of this.uqRoles) {
      this.columnDefs[1].children.push(
        {headerName: key, field: key, width: 80},
      );
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.uqRoles = new Set();
    this.apiData.forEach(obj => {
      if (obj.supervision !== null && obj.supervision !== undefined && obj.supervision.length > 0) {
        obj.supervision.forEach(manager => {
          if (obj[manager.role]) {
            obj[manager.role] = obj[manager.role] + ',' + manager.manager;
          } else {
            obj[manager.role] = manager.manager;
          }
          this.uqRoles.add(manager.role);
        });
      }
    });
    this.rowData = this.apiData;
  }
}
