import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-van',
  templateUrl: './van.component.html',
  styleUrls: ['./van.component.scss']
})
export class VanComponent implements OnInit {
  @Input() value: string;
  @Input() title: string;
  @Input() img: string;

  constructor() { }

  ngOnInit() {
  }


}

