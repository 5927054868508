import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ProductMasterReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Product Master Report');

    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.API_URL = '/api/pwa_reports/getProductMasterReport';
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureGrid() {
    this.columnDefs = [

      {
        headerName: '',
        children: [

          {headerName: "CATEGORY", field: "category", enableRowGroup: true, width: 100},
          {headerName: "FAMILY ID", field: "family_id", width: 100},
          {headerName: "FAMILY CODE", field: "family_code", width: 100},
          {headerName: "FAMILY NAME", field: "family_name", enableRowGroup: true, width: 200},
          {headerName: "PRODUCT ID", field: "prd_id", width: 100},
          {headerName: "PRODUCT CODE", field: "prd_code", width: 100},
          {headerName: "PRODUCT NAME", field: "prd_name", enableRowGroup: true, width: 200},
          {headerName: "MRP", field: "mrp", width: 100},
          {headerName: "TAX MASTER", field: "tax", width: 100},
          {headerName: "EXPIRY DAYS", field: "expiry_days", width: 100},
          {headerName: "UOM", field: "default_uom", width: 100},
          {
            headerName: "CREATE DATE", field: "create_date", enableRowGroup: true, width: 100,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'MM-dd-y', 'en-US') : '';
            }
          },
          {headerName: "Status", field: "status", enableRowGroup: true, width: 100},
          {headerName: "BRAND", field: "brand", enableRowGroup: true, width: 100},
          {headerName: "BUSINESS GROUP", field: "business_group", enableRowGroup: true, width: 100},
        ]
      },
      {
        headerName: 'ATTRIBUTES',

        children: []
      }
    ];

    if (this.apiData.prd_attr != null) {
      this.attributeTypes = this.apiData.prd_attr.map(p => p.attribute)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in this.attributeTypes) {
        this.columnDefs[1].children.push({
          headerName: this.attributeTypes[key],
          field: this.attributeTypes[key],
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

    /*  this.columnDefs.push(
        { headerName: "MRP",  field: "mrp", width: 100},
        { headerName: "EXPIRY DAYS",  field: "expiry_days", width: 100},
        { headerName: "UOM",  field: "default_uom", width: 100},
        { headerName: "CREATE DATE",  field: "create_date", enableRowGroup: true,  width: 100,
          cellRenderer: (data) => {
            return data.value ? formatDate(new Date(data.value), 'MM-dd-y', 'en-US') : '';
          }
        },
        { headerName: "Status",  field: "status", enableRowGroup: true,  width: 100},
        { headerName: "BRAND",  field: "brand", enableRowGroup: true,  width: 100},
        { headerName: "BUSINESS GROUP",  field: "business_group", enableRowGroup: true,  width: 100},
      );*/

  }

  // loadServerData() {
  //   this.goClicked = true;
  //   //debugger;
  //   /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
  //      t.start();
  //      t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
  //   console.log(this.sharedService.headerTitleSource.value);
  //   this.dataLoaded = false;
  //   this.preloader = true;
  //   this.emptyState = false;
  //   this.errorExists = false;
  //
  //   if (this.API_URL === '') {
  //     return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
  //   }
  //
  //   this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
  //   this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
  //   this.apiService.post(this.API_URL, this.userData)
  //     .subscribe(res => {
  //         console.log(res);
  //         if (res.hasOwnProperty('results') && (res.results.status === 200)) {
  //           //this.rowData = res.results.data;
  //           this.apiData = res.results.data;
  //           this.configureGrid();
  //           this.configureData();
  //           this.dataLoaded = true;
  //           this.preloader = false;
  //           this.emptyState = (this.rowData.length === 0);
  //         } else if (res.results.status === 203) {
  //           this.handleError(ErrorHandler.getErrorObject(res.results.msg));
  //         } else {
  //           this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
  //         }
  //       },
  //       // tslint:disable-next-line:no-shadowed-variable
  //       error => this.handleError(ErrorHandler.getErrorObject(error)));
  //
  // }

  configureData() {
    this.rowData = [];
    let masterRow: any = {};
    masterRow = this.apiData.master;
    const inThis = this;
    for (const key in masterRow) {
      let indRow = {};
      const attRow = [];

      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = this.apiData.prd_attr.filter(api => api.prd_id === masterRow[key].prd_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }

      indRow = {
        ...masterRow[key],
        ...attRow
      };
      this.rowData.push(indRow);
    }
  }

}
