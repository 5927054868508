import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'replace-editor',
  template: `<angular2-multiselect [data]="itemList"
                                   [(ngModel)]="selectedItems"
                                   [settings]="settings"
                                   (onSelect)="onItemSelect($event)">
    <!--(onDeSelect)="OnItemDeSelect($event)"-->
    <!--(onSelectAll)="onSelectAll($event)"-->
    <!--(onDeSelectAll)="onDeSelectAll($event)"-->
  </angular2-multiselect>`
})
export class ReplaceRenderer implements ICellRendererAngularComp {

  itemList = [];
  selectedItems = [];
  settings = {};

  agInit(params: any): void {

  }

  ngOnInit(params: any): void {
    this.itemList = [
      { "id": 1, "itemName": "Same" },
      { "id": 2, "itemName": "Other" },
    ];

    this.selectedItems = [
      { "id": 1, "itemName": "Same" }];
    this.settings = { singleSelection: true, text: "Same" };
  }

  onItemSelect(event) {

  }

  refresh(): boolean {
    return false;
  }
}
