import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-secondary-scheme-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class SecondarySchemeListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Secondary Schemes');
    this.showCustomFilter = true;
    this.showDate = false;
    //this.showActionButton = true;

    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.userData.customFilter['type'] = 'S';
    this.statusConfig.success = 'Active';
    this.statusConfig.success1 = 'Ongoing';
    this.statusConfig.error = 'Inactive';
    this.statusConfig.error1 = 'Expired';
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/sale_schemes/getShemeList';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'Name', field: 'name', width: 250},
      {headerName: 'Code', field: 'code', width: 100},
      {headerName: 'Description', field: 'description', width: 200, hide: true},
      {
        headerName: 'Scheme Type', field: 'scheme_type', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.scheme_type === 'qps') {
              return 'Product Scheme';
            } else if (params.data.scheme_type === 'vps') {
              return 'Bill Scheme';
            } else {
              return params.data.scheme_type.toString().toUpperCase();
            }
          }
        },
      },
      {
        headerName: 'Start Date', field: 'start_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_date) {
              return inThis.datePipe.transform((new Date(params.data.start_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.start_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'End Date', field: 'end_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_date) {
              return inThis.datePipe.transform((new Date(params.data.end_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.end_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'Reward Type', field: 'reward_type', width: 80,
        cellStyle(params) {
          if (params.data && params.value) {
            if (params.value === 'product') {
              return {color: '#17A2B8', 'font-weight': 600, 'text-transform': 'capitalize'};
            } else if (params.value === 'discount') {
              return {color: '#F6A82C', 'font-weight': 600, 'text-transform': 'capitalize'};
            } else {
              return {'font-weight': 600, 'text-transform': 'capitalize'};
            }
          }
        }
      },
      {
        headerName: 'Status', field: 'status', width: 80, cellRenderer: inThis.sharedService.statusCellRenderer,
        valueGetter(params) {
          if (params.data) {
            if (params.data.end_date) {
              if ((new Date(params.data.end_date)).getTime() < (new Date()).getTime()) {
                return 'Expired';
              }
              if (params.data.start_date) {
                if ((new Date(params.data.start_date)).getTime() > (new Date()).getTime()) {
                  return 'Upcoming';
                } else {
                  return 'Ongoing';
                }
              }
            } else {
              return 'Ongoing';
            }
          }
        }
      },
      {headerName: 'Active', field: 'active', width: 80, cellRenderer: inThis.sharedService.statusIndicatorCellRenderer}
    ];
  }


}
