import {Component, OnInit} from "@angular/core";
import {formatDate} from "@angular/common";
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {RetailAuditPopupComponent} from "../../retail_audit/retail_audit_popup/retail_audit_popup.component";

@Component({
  selector: 'app-tertiary_audit_report',
  templateUrl: '../_common/reportbase.html',
})

export class TertiaryAuditReportComponent extends ReportBase implements OnInit {

  showCustomFilter = false;
  showDate = true;


  ngOnInit() {
    this.sharedService.setHeader('Tertiary Audit Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'id');
    this.showProgressCustomText = true;
    this.configureGrid();

  }


  setTitle() {
    // this.title = 'Beat Performance Reports';
  }

  configureGrid() {

    this.API_URL = '/api/retail_audit_sessions/getTertiaryAuditReport';

    this.columnDefs = [
      {headerName: "REGION", field: "region",   width: 50},
      {headerName: "CLUSTER", field: "cluster",   width: 50},
      {headerName: "TCODE", field: "tcode",  width: 50, hide: true },
      {headerName: "TERRITORY", field: "territory",  width: 50, hide: true},
      {headerName: "TEAM", field: "team",  width: 50},
      {headerName: "SALESMAN", field: "user_name",  width: 50 },
      {headerName: "EMP CODE", field: "emp_code", width: 40, hide: true},
      {headerName: "DATE", field: "create_date",  width: 40},
      {headerName: "AUDIT", field: "audit_name",  width: 40},
      { headerName: 'CODE', field: 'code',  width: 40},
      {headerName: "RETAILER", field: "retailer",  width: 60},
      { headerName: 'TYPE', field: 'o_type',   width: 40},
      { headerName: 'CLASS', field: 'class',   width: 40},
      { headerName: 'PROGRAM', field: 'program',   width: 40},
      { headerName: 'CATEGORY', field: 'categ',   width: 40},
      { headerName: 'BRAND', field: 'brand',   width: 40},
      { headerName: 'FAMILY', field: 'family',   width: 40, hide: true},
      { headerName: 'COMPETITOR', field: 'competition',   width: 40},
      { headerName: 'COMP', field: 'comp',  width: 30, hide: true},
      { headerName: 'SKU CODE', field: 'sku_code',   width: 60, hide: true},
      { headerName: 'SKU', field: 'sku',   width: 80},
      { headerName: 'VARIANT', field: 'variant_name',  width: 80, hide: true},
      { headerName: 'PACK SIZE', field: 'pack_size',  width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.attribute_json) {
              if (params.data.attribute_json.constructor !== Object) {
                try {
                  params.data.attribute_json = JSON.parse(params.data.attribute_json.param_json);
                } catch (e) {
                }
              }
              if (params.data.attribute_json) {
                return params.data.attribute_json['display_name'];
              }
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "QTY", field: "volume", cellStyle: {textAlign: 'right'}, width: 40},
      {headerName: "SHARE", field: "ms", hide: true, width: 40},

    ];

  }




}
