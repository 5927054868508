import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {GridOptions} from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-retailer-list-popup',
  templateUrl: './retailer_list_popup.component.html',
  styleUrls: ['./retailer_list_popup.component.scss']

})

export class RetailerListPopupComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public emptyState = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any)
  {

    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'} as GridOptions;

    this.columnDefs = [
      {headerName: "REGION", headerClass: 'myagheader', field: "region", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 150, resizable: true},
      {headerName: "CLUSTER", headerClass: 'myagheader', field: "cluster", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 150, resizable: true},
      {headerName: "CODE", headerClass: 'myagheader', field: "code", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 150, resizable: true},
      {headerName: "NAME", headerClass: 'myagheader', field: "name", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 150, resizable: true},
    ];
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    this.gridApi.setSortModel(sortModel);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
