import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from '../../../node_modules/@ag-grid-enterprise/all-modules';
import {ApiService} from '../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AdDirective} from '../ad.directive';
import {AdDirective2} from '../ad2.directive';
import {AdDirective3} from '../ad3.directive';
import {ReportDropdownComponent} from '../components/report_dropdown/report-dropdown.component';
import {TypeDropdownComponent} from '../components/type_dropdown/type-dropdown.component';
import {ProdDropdownComponent} from '../components/prod_dropdown/prod-dropdown.component';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../components/image_popup/image_popup.component";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-raw-sale-report',
  templateUrl: './raw-sale-report.component.html',
  styleUrls: ['./raw-sale-report.component.scss']
})
export class RawSaleReportComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('dp') dp: ElementRef<HTMLElement>;
  user;
  res_data;
  user_data = {
    date: null,
    status: 'dc',
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    dc_id: [],
    out_id: null,
    out_type: null,
    prod_id: '0',
    zip_flag: 'normal',
    flag: true
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name = 'DC LIST';
  public styleGrid;
  public myDate;
  public zipDate = [];
  public multidate = false;
  public collapse_disable;
  public report_flag;
  public role_features = [];
  public all_dc = [];
  public all_outlets = [];
  public all_types = [];
  public all_prods = [];
  public default = true;
  public out_default = true;
  public prod_default = true;
  public type_default = true;
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ['', 0];
  public selectedZipDc = '0';
  public zip_url = '';
  public all_dc_flag = false;
  public optDc = true;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public dialog: MatDialog,
              public sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    if (this.sharedService.ACLcheck('module_po')) {
      this.optDc = false;
    }
    if (this.sharedService.ACLcheck('feature_dcsales_multidate')) {
      this.multidate = true;
    }
    if (this.sharedService.ACLcheck('feature_all_dc_filter')) {
      this.all_dc_flag = true;
      this.all_dc = [{id: 0, itemName: "All DC", checked: false}];
    }
    this.all_dc = this.all_dc.concat(JSON.parse(localStorage.getItem('all_dcs')));
    this.zipDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.zipDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (this.multidate) {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.user_data.date = this.myDate[0];
      this.user_data.last_date = this.myDate[1];
    } else {
      this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.user_data.date = this.myDate;
      this.user_data.last_date = this.myDate;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.last_date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params.report_flag;
    localStorage.setItem('selected_product', JSON.stringify([{id: 0, itemName: 'All Products'}]));
    localStorage.setItem('selected_type', JSON.stringify([{id: 0, itemName: 'All Types'}]));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.user_data.dc_id = [this.all_dc[this.all_dc.length - 1].id];
    this.rawsalereport();
    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      icons: {
        'custom-tools': '<span><i style="padding: 8px" class="fal fa-tools"></i></span>',
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
    } as GridOptions;
  }

  /*configureGrid() {
    this.columnDefs = [
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'Region', field: 'Region', width: 80},
      {headerName: 'DC NAME', field: 'DC NAME', width: 80},
      {headerName: 'TI', field: 'TI', width: 80},
      {headerName: 'Van', field: 'Van', width: 80},
      {headerName: 'Sales Area Code', field: 'Sales Area Code', width: 80},
      {headerName: 'Beat', field: 'Beat', width: 80},
      {headerName: 'Zone', field: 'Zone', width: 80},
      {headerName: 'Area', field: 'Area', width: 80},
      {headerName: 'PriceList', field: 'PriceList', width: 80},
      {headerName: 'Outlet', field: 'Outlet', width: 80},
      {headerName: 'Outlet ID', field: 'Outlet ID', width: 80},
      {headerName: 'Outlet CODE', field: 'Outlet CODE', width: 80},
      {headerName: 'Outlet Status', field: 'Outlet Status', width: 80},
      {headerName: 'GSTIN', field: 'GSTIN', width: 80},
      {headerName: 'Outlet Type', field: 'Outlet Type', width: 80},
      {headerName: 'Planned Date', field: 'Planned Date', width: 80},
      {headerName: 'Qty', field: 'Qty', width: 80},
      {headerName: 'Product CODE', field: 'Product CODE', width: 80},
      {headerName: 'Product Name', field: 'Product Name', width: 80},
      {headerName: 'Product Category', field: 'Product Category', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},
      {headerName: 'State', field: 'State', width: 80},

    ]
  }*/

  rawsalereport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const loop_data = [];
    const datePipe = this.datePipe;
    const all_outlets = [];
    const all_types = [];
    const all_prods = [];
    let item_obj = {};
    const loop_dict = {};
    const optDc = this.optDc;
    this.user_data.zip_flag = 'normal';
    let API_URL = '/api/pwa_connects/raw_sale_report';
    if (!this.optDc) {
      API_URL = '/api/pwa_connects/raw_sale_report2';
    }
    this.apiService.post(API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          const inthis = this;
          if (!this.user_data.flag) {
            if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
              const taxes = res.results.taxes;
              all_types.push({id: 0, itemName: 'All Types'});
              all_prods.push({id: '0', itemName: 'All Products'});
              res.results.data.forEach(data => {
                if (data.approved_date != null) {
                  data.approved_date = datePipe.transform((data.approved_date).toString(),
                    'dd/MM/yy HH:mm:ss');
                }
                if (!loop_dict[data.retailer_type]) {
                  item_obj = {id: data.retailer_type, itemName: data.outlet_type};
                  all_types.push(item_obj);
                  loop_dict[data.retailer_type] = true;
                }
                if (!loop_dict[data.product_code]) {
                  item_obj = {id: data.product_code, itemName: data.product_name};
                  all_prods.push(item_obj);
                  loop_dict[data.product_code] = true;
                }
                const pushData = {
                  State: data.dc_state,
                  Region: data.region,
                  'DC NAME': data.dc_name,
                  TI: data.ti_name,
                  Van: data.user,
                  'Sales Area Code': data.sales_area_code,
                  Beat: data.beat,
                  Zone: data.zone,
                  Area: data.area,
                  PriceList: data.pricelist,
                  Outlet: data.outlet,
                  'Outlet ID': data.outlet_id,
                  'Outlet CODE': data.outlet_code,
                  'Outlet Status': data.out_status,
                  GSTIN: data.retailer_gstin,
                  'Outlet Type': data.outlet_type,
                  'Planned Date': datePipe.transform((data.order_date).toString(), 'dd/MM/yy'),
                  Qty: parseInt(data.qty, 10),
                  'Product CODE': data.product_code,
                  'Product Name': data.product_name,
                  'Product Category': data.catgeory,
                  Brand: data.brand,
                  HSN: data.hsn,
                  MRP: data.mrp,
                  'Basic Rate': data.basic_rate,
                  'Unit Price': data.ut_price,
                  'Invoice ID': data.invoice_id,
                  Invoice: data.invoice,
                  Comments: data.comments,
                  'Scheme Type': data.scheme_type,
                  'Scheme Name': data.scheme_name,
                  'Ordered Scheme Disc': data.ordered_scheme_disc,
                  'Delivered Scheme Disc': data.del_scheme_disc,
                  Margin: data.o_mr,
                  'Net Sale': parseFloat(data.price_subtotal),
                  Tax: data.tax,
                  'Total Tax (%)': parseFloat(data.tax_amount),
                  'Amount Tax': parseFloat(data.amount_tax),
                  'Line ID': data.line_id,
                  pod_store_fname: data.pod_store_fname,
                  config_json: data.config_json
                };
                if (data.tax_notes) {
                  console.log(data.tax_notes);
                  if (typeof data.tax_notes === 'string') {
                    data.tax_notes = JSON.parse(data.tax_notes);
                  }
                  if (typeof data.tax_notes === 'string') {
                    data.tax_notes = JSON.parse(data.tax_notes);
                  }
                  for (const key in taxes) {
                    if (!data.tax_notes[taxes[key]]) {
                      pushData[taxes[key]] = 0;
                    } else {
                      pushData[taxes[key]] = parseFloat(data.tax_notes[taxes[key]].split('(')[0])
                        .toFixed(inthis.apiService.decimalPolicy);
                    }
                  }
                } else {
                  for (const key in taxes) {
                    pushData[taxes[key]] = 0;
                  }
                }
                pushData['Gross Sale'] = parseFloat(data.amount_total);
                pushData['TCS/TDS'] = parseFloat(data.tcs.toFixed(5));
                pushData['Grand Total'] = parseFloat(data.grand_total.toFixed(3));
                pushData['Payment Status'] = data.p_status;
                pushData['Payment Mode'] = data.mode;
                if (!optDc) {
                  if (data.due_date) {
                    pushData['Due Date'] = datePipe.transform((data.due_date).toString(), 'dd/MM/yy');
                  } else {
                    pushData['Due Date'] = null;
                  }
                }
                pushData['Approval Status'] = data.approved;
                pushData['Approved By'] = data.approved_by;
                pushData['Approved Date'] = data.approved_date;
                pushData['PO'] = data.po;
                pushData['Einvoice Status'] = data.einv_status;
                pushData['IRN'] = data.irn;
                pushData['GRN'] = data.grn;
                pushData['Create Time'] = data.mobile_date;
                pushData['Sync Time'] = data.server_date;
                pushData['Van Status'] = data.van_status;
                rowdata.push(pushData);
              });
              this.data = rowdata[0];
              this.rowData = rowdata;
              if (this.type_default) {
                localStorage.setItem('all_types', JSON.stringify(all_types));
                this.all_types = all_types;
              } else {
                this.all_types = JSON.parse(localStorage.getItem('all_types'));
              }

              if (this.prod_default) {
                localStorage.setItem('all_prods', JSON.stringify(all_prods));
                this.all_prods = all_prods;
              } else {
                this.all_prods = JSON.parse(localStorage.getItem('all_prods'));
              }

              // this.outList();
              this.typeList();
              this.prodList();

              for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                  if (key === 'State' || key === 'Region' || key === 'Zone' || key === 'Area' || key === 'Approval Status' || key === 'Outlet Status') {
                    this.columnDefs.push({headerName: key, field: key, width: 150, aggFunc: 'sum', chartDataType: 'category'});
                  } else if (key === 'Qty' || key === 'HSN' || key === 'Unit Price' || key === 'Net Sale' ||
                    key === 'Tax' || key === 'Amount Tax' ||
                    key === 'Gross Sale' || key === 'Line ID' || key === 'CGST' || key === 'SGST' || key === 'VAT' || key === 'IGST' || key === 'TCS/TDS') {
                    this.columnDefs.push({headerName: key, field: key, width: 120, cellStyle: {textAlign: 'right'}, aggFunc: 'sum', chartDataType: 'series'});
                  } else if (key === 'Outlet CODE' || key === 'Product CODE' || key === 'Sales Area Code') {
                    this.columnDefs.push({headerName: key, field: key, width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum', chartDataType: 'category'});
                  } else if (key === 'Outlet') {
                    this.columnDefs.push({headerName: key, field: key, width: 150, aggFunc: 'sum', chartDataType: 'category'});
                  } else if (key === 'Approved By' || key === 'Van' || key === 'TI' || key === 'Product Category' || key === 'DC NAME' ||
                    key === 'Line ID') {
                    this.columnDefs.push({headerName: key, field: key, width: 100, aggFunc: 'sum', chartDataType: 'category'});
                  } else if (key === 'Total Tax (%)') {
                    this.columnDefs.push({headerName: key, field: key, width: 250, aggFunc: 'avg', chartDataType: 'series'});
                  } else if (key === 'Invoice' || key === 'PriceList' || key === 'Comments') {
                    this.columnDefs.push({
                      headerName: key, field: key, width: 150, cellClass: 'textFormat'
                    });
                  } else if (key === 'Invoice ID' || key === 'Product Name') {
                    this.columnDefs.push({headerName: key, field: key, width: 250});
                  } else if (key === 'Payment Status') {
                    this.columnDefs.push({
                      headerName: key, field: key, width: 150,
                      cellClass: 'textFormat',
                      valueGetter(params) {
                        if (params.data['Payment Status'] === 'new') {
                          return 'unpaid';
                        } else {
                          return params.data['Payment Status'];
                        }
                      },
                      hide: optDc
                    });
                  } else if (key === 'Brand' || key === 'Outlet ID' || key === 'Due Date') {
                    this.columnDefs.push({headerName: key, field: key, width: 150, cellClass: 'textFormat', hide: optDc});
                  } else if (key === 'MRP') {
                    this.columnDefs.push({headerName: key, field: 'mrp', valueGetter: this.configValueGetter('mrp')});
                  } else if (key === 'Margin') {
                    this.columnDefs.push({headerName: key, field: 'o_mr', valueGetter: this.configValueGetter('o_mr')});
                  } else if (key === 'Ordered Scheme Disc' || key === 'Delivered Scheme Disc') {
                    this.columnDefs.push({
                      headerName: key, field: key,
                      valueGetter(params) {
                        if (params.data && params.data.hasOwnProperty(key) && params.data[key] !== null) {
                          return Math.abs(parseFloat(parseFloat(params.data[key]).toFixed(3)));
                        }
                      }
                    });
                  } else if (key === 'config_json') {
                    this.columnDefs.push({headerName: key, field: 'config_json', hide: true});
                  } else if (key !== 'pod_store_fname') {
                    this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', enableValue: true});
                  }
                }
              }
              this.columnDefs.push({
                headerName: 'POD', field: 'pod_store_fname', width: 50, menuTabs: [],
                cellRenderer(params) {
                  if (params.data) {
                    if (params.data.pod_store_fname == null || params.data.pod_store_fname === "") {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                    } else {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                    }
                  }
                }
              });
              this.loaded = true;

            } else {
              this.preloader = false;
              this.empty_state = true;
            }
          } else {
            this.dcList();
            this.preloader = false;
            this.empty_state = true;
          }

        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  dcList() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDropdownComponent);
    const componentRef = viewContainerRef.createComponent<ReportDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.all_dc;
    if (this.default || this.user_data.dc_id.length === 0) {
      componentRef.instance.selectedItems = [this.all_dc[this.all_dc.length - 1]];
    } else {
      componentRef.instance.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    }
    componentRef.instance.statusChange.subscribe(res => {

      this.default = false;
      this.prod_default = true;
      this.type_default = true;
      localStorage.setItem('selected_outlet', JSON.stringify([{id: 0, itemName: 'All Outlets'}]));
      localStorage.setItem('selected_product', JSON.stringify([{id: 0, itemName: 'All Products'}]));
      localStorage.setItem('selected_type', JSON.stringify([{id: 0, itemName: 'All Types'}]));
      // this.user_data.out_id = 0;
      this.user_data.out_type = 0;
      this.user_data.prod_id = '0';
      const dc_ids = [];
      res.forEach(each_dc => {
        dc_ids.push(each_dc.id);
      });
      this.user_data.dc_id = dc_ids;
    });
  }

  goToLog() {
    localStorage.setItem('log_params', JSON.stringify({start_date: this.user_data.date, last_date: this.user_data.last_date}));

    this.router.navigate([this.apiService.redirect_url + '/logs']);
  }

  dropDown() {
    const el: HTMLElement = this.dp.nativeElement;
    if (el.classList.contains('visible')) {
      this.renderer.removeClass(el, 'visible');
    } else {
      this.renderer.addClass(el, 'visible');
    }
  }

  typeList() {
    const viewContainerRef3 = this.adHost3.viewContainerRef;
    viewContainerRef3.clear();
    const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(TypeDropdownComponent);
    const componentRef3 = viewContainerRef3.createComponent<TypeDropdownComponent>(componentFactory3);
    componentRef3.instance.itemList = this.all_types;
    componentRef3.instance.selectedItems = JSON.parse(localStorage.getItem('selected_type'));
    componentRef3.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.user_data.out_type = res[0].id;
        localStorage.setItem('selected_type', JSON.stringify(res));
        if (res[0].id === 0) {
          this.type_default = true;
        } else {
          this.type_default = false;
        }
      }
    });
  }

  prodList() {
    const viewContainerRef2 = this.adHost2.viewContainerRef;
    viewContainerRef2.clear();
    const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(ProdDropdownComponent);
    const componentRef2 = viewContainerRef2.createComponent<ProdDropdownComponent>(componentFactory2);
    componentRef2.instance.itemList = this.all_prods;
    componentRef2.instance.selectedItems = JSON.parse(localStorage.getItem('selected_product'));
    componentRef2.instance.statusChange.subscribe(res => {
      if (res && res.length > 0) {
        this.user_data.prod_id = res[0].id;
        localStorage.setItem('selected_product', JSON.stringify(res));
        if (res[0].id === 0) {
          this.prod_default = true;
        } else {
          this.prod_default = false;
        }
      }
    });
  }

  close() {
    this.zip_loader = false;
    this.zip_ready = false;
  }

  generateZip() {
    this.zip_loader = true;
    this.zip_ready = false;
    if (this.all_dc_flag) {
      this.user_data.dc_id = this.all_dc.reduce((a, o) => (o.checked && a.push(o.id), a), []);
    } else {
      this.user_data.dc_id = [parseInt(this.selectedZipDc, 10)];
    }
    this.user_data.zip_flag = 'zip';
    this.user_data.date = this.datePipe.transform(this.zipDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.zipDate[1], 'yyyy-MM-dd');
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.zipDate[0];
    const secondDate = this.zipDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (this.all_dc_flag && this.user_data.dc_id.length > 1 && diffDays > 7) {
      window.alert('Please Select 1 week data');
      this.zip_loader = false;
    } else if (this.user_data.dc_id.length === 0) {
      window.alert('Please Select 1 DC');
      this.zip_loader = false;
    } else if (this.user_data.dc_id[0] === 0 && diffDays > 8) {
      window.alert('Please Select 1 week data');
      this.zip_loader = false;
    } else if (diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (!this.all_dc_flag && parseInt(this.selectedZipDc, 10) === 0 && diffDays > 7) {
      window.alert('Please Select 1 week data for all DC');
      this.zip_loader = false;
    } else {
      this.user_data.flag = false;
      let API_URL = '/api/pwa_connects/raw_sale_report';
      if (!this.optDc) {
        API_URL = '/api/pwa_connects/raw_sale_report2';
      }
      this.apiService.post(API_URL, this.user_data)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.zip_ready = true;
          }
          this.zip_loader = false;
        }, error => {
          console.log(error);
          this.zip_loader = false;
        });
    }
  }

  run() {
    this.user_data.flag = false;
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (this.all_dc_flag && this.user_data.dc_id.length > 1 && diffDays > 7) {
      window.alert('Please Select 1 week data');
    } else if (this.user_data.dc_id[0] === 0 && diffDays > 8) {
      window.alert('Please Select 1 week data');
    } else if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.rawsalereport();
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (noOfColumns < 11 && screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeAllColumns();
          }
        }
      });
    });
    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    if (this.multidate) {
      this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    } else {
      this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.user_data.last_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    }
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.type_default = true;
    this.prod_default = true;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column !== 'pod_store_fname') {
    } else {
      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('pod_store_fname') && rows[0].pod_store_fname != null && rows[0].pod_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].pod_store_fname,
              container: "sale_order_gallery"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].pod_store_fname,
              container: "sale_order_gallery"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {
      }
    }
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json = params.data.config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          }
          if (config_json.hasOwnProperty(field)) {
            return parseFloat(config_json[field]).toFixed(2);
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header + '.csv'
    };
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData.length > 200000) {
      this.gridApi.exportDataAsCsv(params);
    } else {
      this.gridApi.exportDataAsExcel(params);
    }
  }
}
