import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";


@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit {
  @Input() list: any[];
  @Input() name;
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();


  checkedList: any[];
  currentSelected: {};
  showDropDown = false;

  constructor() {
    this.checkedList = [];
  }

  ngOnInit(): void {
    if (this.list !== null && this.list !== undefined) {
      let all = false;
      for (const item of this.list) {
        if (item.itemName.toLowerCase().includes("all")) {
          all = true;
          break;
        }
      }
      if (all && this.list.length > 1 && this.name.toLowerCase().includes("instance")) {
        this.list[1].checked = true;
        this.getSelectedValue(true, this.list[1].itemName);
      } else {
        this.list[0].checked = true;
        this.getSelectedValue(true, this.list[0].itemName);
      }
    }
  }

  getSelectedValue(status, value) {
    if (status) {
      if (value.toLowerCase().includes("all")) {
        this.checkedList = [];
        for (const item of this.list) {
          item.checked = true;
          this.checkedList.push(item.itemName);
        }
      } else {
        this.checkedList.push(value);
      }
    } else {
      if (value.toLowerCase().includes("all")) {
        for (const item of this.list) {
          item.checked = false;
        }
        this.checkedList = [];
      } else {
        for (const item of this.checkedList) {
          if (item.toLowerCase().includes("all")) {
            const listallindex = this.list.findIndex(x => x.itemName === item);
            if (listallindex !== null && listallindex !== undefined) {
              this.list[listallindex].checked = false;
            }
            const allindex = this.checkedList.indexOf(item);
            this.checkedList.splice(allindex, 1);
          }
        }
        const index = this.checkedList.indexOf(value);
        this.checkedList.splice(index, 1);
      }
    }

    this.currentSelected = {checked: status, name: value};

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }

  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }

  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }


}
