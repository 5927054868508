import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-create-popup',
  templateUrl: './create_popup.component.html',
  styleUrls: ['./create_popup.component.scss']

})

export class CreatePopupComponent implements OnInit {

  public formData = [];
  public header = "";

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    /* this.store_fname = dialogdata.store_fname;
     this.container = dialogdata.container;*/

    this.header = dialogdata.header;

    this.formData.push(
      {
        name: 'Scheme',
        value: '',
        type: 'text'
      },
      {
        name: 'Start Date',
        value: '',
        type: 'date'
      },
      {
        name: 'End Date',
        value: '',
        type: 'date'
      }
    );

  }

  ngOnInit(): void {
  }

  submit() {

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
