<!--<div id="overlay"></div>-->
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{header}}</h2>
      <!-- <button class="close-dialog" (click)="closeDialog()">
         <i class="fas fa-times"></i>
       </button>-->
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 40px 15px;">
      <div class="row" style="display: block">
        <div>
          <div class="row">
            <span class="span-number">1</span>
            <span class="header-span">Download Template</span>
          </div>
          <div style="margin-left: 40px;margin-top: 10px;">
            <span style="color:#9D9D9D;">{{step1Text}}</span>
            <div class="col-md-12 pl-0 pr-0" style="margin-top: 20px">
              <div class="col-md-3 pl-0 pr-10">
                <div class="card-box" (click)="sampleDownload1()">
                  <span style="color:#0762AD;margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
                  <span style="color:#0762AD; margin-left: 15px;font-size: 14px;font-weight: 500;">{{sampleText1}}</span>
                </div>
              </div>
              <div class="col-md-3 pl-0 pr-10">
                <div class="card-box" (click)="sampleDownload2()">
                  <span style="color:#0762AD;margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
                  <span style="color:#0762AD; margin-left: 15px;font-size: 14px;font-weight: 500;">{{sampleText2}}</span>
                </div>
              </div>
              <div class="col-md-6 pl-0 pr-10">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 pad0" style="margin-top: 40px;">
          <div class="row">
            <span class="span-number">2</span>
            <span class="header-span">{{actionText}}</span>
          </div>
          <div style="margin-left: 40px;margin-top: 5px;">
            <span style="color:#9D9D9D;">{{step2Text}}</span>
            <span style="color:#183153;font-weight: bold;cursor:pointer;"> Learn about template</span>
            <div style="margin-top: 20px;">
              <table class="customtable" style="background: white;">
                <tr>
                  <ng-container *ngFor="let row of tableData[0]">
                    <th style="background: white; color: #0762AD;">{{row}}</th>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container *ngFor="let row of tableData[1]">
                    <td style="font-weight: bold">{{row}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container *ngFor="let row of tableData[2]">
                    <td>{{row}}</td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12 pad0" style="margin-top: 40px;">
          <div class="row">
            <span class="span-number">3</span>
            <span class="header-span">Upload File</span>
            <input #fileUp type="file" class="form-control" style="display:none" (change)="uploadedFile($event)"
                   placeholder="Upload file" accept=".xlsx,.csv">
          </div>
          <div style="margin-left: 40px;margin-top: 5px;">
            <span style="color:#9D9D9D;">Maximum File Size : 10 MB</span>
            <div class="dottedcard" (click)="fileUp.click()">
              <div class="row" *ngIf="fileUploaded">
                <img style="height: 56px; padding: 10px;" src="../../assets/excel_icon.png"/>
                <div style="padding-top: 10px;">
                <span
                  style="font-size:14px; font-weight: 600; display: block; color: #2D2D2D">{{fileUploaded.name}}</span>
                  <span
                    style="font-size:12px; font-weight: 400; display: block; color: #7D7D7D; text-align: start">{{filesize}}
                    Kb</span>
                </div>

              </div>
              <div *ngIf="!fileUploaded">
                <span style="color: #0762AD;"><i class="fal fa-cloud-upload fa-5x"></i></span>
                <span style="margin-top: 10px;display: block; font-size: 18px;color: #0762AD; font-weight: 500;">Select a file to Upload</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 pad0" style="margin-top:20px;padding:15px;height:50px;">
      <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;" data-toggle="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
        <button
          mat-button
          style="margin-left:15px; margin-right:15px;"
          class="btn confirm-blue-btn"
          (click)="goToStatusPopup()"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</section>
