import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ErrorHandler} from "../../error/error-handler";


@Component({
  selector: 'app-user_survey',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class UserSurveyPivotReportComponent extends ReportBase implements OnInit {

  API_URL = '';
  customFilter: any = {
    name: 'Questionnaire',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };


  ngOnInit(): void {
    this.sharedService.setHeader('User Survey Pivot Report');
    let datas: any = {};
    datas = history.state;
    this.userData.customFilter['id'] = datas.surveyID;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.userData.type = 'survey';
    this.loadInBatches(50000, 'id');
    this.showProgressCustomText = true;
    this.container = 'partner_answer';
    this.API_URL = '/api/pwa_reports/getUserSurveyAnswers';
    //this.configureGrid();
    this.fetchQuestions();
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.showCustomFilter = false;
      this.loadServerData();
    }
  }

  fetchQuestions() {
    this.elRef.nativeElement.querySelector('#overlay1').style.display = "block";
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.apiService.post('/api/pwa_reports/getUserSurveyQuestions', this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.customFilter.values = [];
            if (res.results.data && res.results.data.length > 0) {
              for (const item of res.results.data) {
                this.customFilter.values.push({id: item.id, itemName: item.name});
              }
              this.customFilter.value = this.customFilter.values[0].id;
              this.userData.customFilter['id'] = this.customFilter.values[0].id;
              /* this.elRef.nativeElement.querySelector('#customfilter')['options'] = this.customFilter.values;
               this.elRef.nativeElement.querySelector('#customfilter')['options'][0].selected = true;*/
              this.showCustomFilter = true;
              console.log(this.userData);
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "", children: [
          {headerName: "EMP CODE", field: "emp_code", width: 100},
          {headerName: "SALESMAN", field: "salesman", width: 100},
          {headerName: "ROLE", field: "role", width: 100},
          {headerName: "SURVEY", field: "survey", width: 120},
          {headerName: "DATE", field: "completion_date", width: 100},
          {headerName: "Customer Name", field: "customer_name", width: 100},
          {headerName: "Customer Mobile", field: "customer_mobile", width: 100},
          {headerName: "MAX SCORE", field: "max_score", width: 100},
        ]
      },
    ];
    const unique_questions = this.unique(this.apiData, ['quest_id']);
    unique_questions.sort((a, b) => a['sequence'] > b['sequence'] ? 0 : 1);
    if (unique_questions !== null && unique_questions !== undefined && unique_questions.length > 0) {
      for (let i = 0; i < unique_questions.length; i++) {
        this.columnDefs.push({headerName: unique_questions[i]['question'], headerClass: 'myagheader', children: []});
        this.columnDefs[1 + i].children.push(
          {
            headerName: "ANSWER", field: unique_questions[i]['quest_id'] + '_a', width: 80,
            valueGetter: this.generateImageTextUrl(unique_questions[i]['quest_id'] + '_ai', unique_questions[i]['quest_id'] + '_a'),
            cellRenderer(params) {
              if (params.data) {
                if (params.data[unique_questions[i]['quest_id'] + '_ai'] !== null && params.data[unique_questions[i]['quest_id'] + '_ai'] !== undefined) {
                  if (params.data[unique_questions[i]['quest_id'] + '_ai'] === null || params.data[unique_questions[i]['quest_id'] + '_ai'] === "") {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                  } else {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  }
                } else if (params.data[unique_questions[i]['quest_id'] + '_a'] !== null && params.data[unique_questions[i]['quest_id'] + '_a'] !== undefined) {
                  return params.data[unique_questions[i]['quest_id'] + '_a'];
                } else {
                  return params.value;
                }
              }
            }
          },
          {headerName: "REMARKS", field: unique_questions[i]['quest_id'] + '_r', width: 100},
          {headerName: "SCORE", field: unique_questions[i]['quest_id'] + '_s', width: 80},
        );
      }
    }
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    let masterData: any = {};
    masterData = this.unique(this.apiData, ['completion_date', 'salesman', 'surveyid', 'activity_id']);
    if (masterData !== null && masterData !== undefined && masterData.length > 0) {
      for (const item of masterData) {
        const indRow: any = item;
        const questData = this.apiData.filter(x => x.completion_date === item.completion_date &&
          x.salesman === item.salesman && x.surveyid === item.surveyid && x.activity_id === item.activity_id);
        if (questData !== null && questData !== undefined && questData.length > 0) {
          for (const questrow of questData) {
            if (questrow.type === 'image') {
              indRow[questrow.quest_id + '_ai'] = questrow.store_fname;
            } else {
              indRow[questrow.quest_id + '_a'] = questrow.answer_text;
            }
            indRow.quest_id = questrow.quest_id;
            indRow[questrow.quest_id + '_s'] = questrow.ind_score;
            indRow[questrow.quest_id + '_r'] = questrow.remarks;
          }
        }
        this.rowData.push(indRow);
      }
    }
    console.log(this.rowData);
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column + 'i') && rows[0][column + 'i'] !== null && rows[0][column + 'i'] !== "") {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "70%",
          maxHeight: "95%",
          data: {
            store_fname: rows[0][column + 'i'],
            container: "partner_answer"
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "90%",
          maxHeight: "65%",
          data: {
            store_fname: rows[0][column + 'i'],
            container: "partner_answer"
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      }

    } else {

    }
  }

  generateImageTextUrl(imagefield: string, textfield: string) {
    return (params) => {
      if (params.data) {
        if (!params.data[imagefield] || params.data[imagefield] === "") {
          if (!params.data[textfield] || params.data[textfield] === "") {
            return '';
          } else {
            return params.data[textfield];
          }
        } else {
          const url = 'https://' + this.subdomain + '.salesdiary.in/imagerender?url=' + this.apiService.api_url +
            '&sf=' + params.data[imagefield] + '&cont=' + this.container;
          return url;
        }
      } else {
        return '';
      }
    };
  }

  //
  // loadServerData(): void {
  //   var indrow = {
  //     "period":"Once",
  //     "cluster":"Bangalore",
  //     "territory":"BLR South",
  //     "zone":"Default",
  //     "salesman":"Bhavesh C",
  //     "retailer":"Shobha Supermarket",
  //     "survey_name":"KA FD POP",
  //     "question":"How many bottles sold per day?",
  //     "answer":"183 bottles",
  //     "score":"64%",
  //     "date":"12/06/2021"
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }


}
