<div class="eod-list-card">
  <div class="eod-list-card-heading">
    <h3>{{title}}</h3>
  </div>
  <div class="eod-list-card-desc">
    <h2>{{achv}}/{{target}}</h2>
  </div>
  <div *ngIf="perc" class="eod-list-card-statics" [ngClass]="{'low': perc[0] === '-', 'high': perc[0] != '-'}">
    <span>{{perc}}</span>
  </div>
</div>
