import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';
import {MatDialogConfig} from '@angular/material/dialog';
import {ImagePopupComponent} from '../../components/image_popup/image_popup.component';
import {LocationPopupComponent} from '../../components/location_popup/location_popup.component';
import {AttendanceImagePopupComponent} from "../../components/attendance_image_popup/attendance_image_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DailyAttendanceTeamWiseReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Daily Attendance (Team Wise)');

    /*  this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');*/

    this.dateSelectMode = 'single';
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;

    // this.showDate = false;

    this.gridOptions.rowHeight = 100;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'CATEGORY',
      field: 'categ',
      minWidth: 150,
      cellRenderer: 'agGroupCellRenderer',
    };
    this.gridOptions.defaultColDef.autoHeight = true;
    this.container = 'hr_attendance';
    this.configureGrid();
  }

  configureGrid() {

    const datepipe = this.datePipe;

    this.API_URL = '/api/pwa_reports/dailyAttendanceReportByTeam';

    this.columnDefs = [
      {
        headerName: 'DATE', field: 'date', enableRowGroup: true,
        width: 80
      },
      {
        headerName: 'PARENT TEAM',
        field: 'parent_team',
        enableRowGroup: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'TEAM',
        field: 'team_name',
        enableRowGroup: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'EMP CODE', field: 'emp_code',
        width: 100
      },
      {
        headerName: 'EMP NAME', field: 'name', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'EMP DOJ', field: 'doj', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'EMP DOE', field: 'doe', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'SALES AREA', field: 'sac',
        width: 100
      },
      {
        headerName: 'ROLE', field: 'role', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'IMAGE', field: 'store_fname',
        width: 150,
        valueGetter: this.generateImageUrl('store_fname'),
        cellRenderer: params => {
          if (params.data) {
            if (params.data.photo == null || params.data.photo === '') {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img src="' + params.data.photo + '" style="padding:2px;margin-top:5px;width:100px;height:100px;"></img>';
            }
          }
        }
      },

      {
        headerName: 'MAP',
        field: 'url',
        width: 80,
        resizable: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
          if (params.data) {
            if (params.data.longitude != null && params.data.latitude != null) {
              return '<div style="margin-top: 15px;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            } else {
              return '<div style="margin-top: 15px;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            }

          }
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.longitude != null && params.data.latitude != null) {
              return 'http://maps.google.com/maps?q=' + params.data.latitude + ',' + params.data.longitude;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'LOCATION',
        field: 'location_name',
        width: 80,
        resizable: true,
        hide: true,
        cellStyle: {textAlign: 'center'},
        valueGetter(params) {
          if (params.data && params.data.location_name) {
            return params.data.location_name;
          } else {
            return 'NA';
          }
        }
      },
      {
        headerName: 'P/L/A/OA/HF', field: 'attendance',
        width: 100, cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'ATT TYPE', field: 'att_type', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'LATE REASON', field: 'late_reason', enableRowGroup: true, hide: true,
        width: 100
      },
      {
        headerName: 'DISTANCE', field: 'distance_travelled',
        maxWidth: 100, cellStyle: {textAlign: 'center'}
      },

      {
        headerName: 'ATT TIME', field: 'att_time',
        width: 100,
        valueGetter: params => {
          if (params.data && params.data.att_time) {
            return datepipe.transform((new Date(params.data.att_time + ' UTC')).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'FIRST ACTVITIY', field: 'first_act',
        width: 100,
        valueGetter: params => {
          if (params.data && params.data.first_act) {
            return datepipe.transform((new Date(params.data.first_act + ' UTC')).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'LAST ACTIVITY', field: 'last_act',
        width: 100,
        valueGetter: params => {
          if (params.data && params.data.last_act) {
            return datepipe.transform((new Date(params.data.last_act + ' UTC')).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'EOD TIME', field: 'eod_time',
        width: 100,
        valueGetter: params => {
          if (params.data && params.data.eod_time) {
            return datepipe.transform((new Date(params.data.eod_time + ' UTC')).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'TIME IN MARKET', field: 'time_market',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.att_time && params.data.eod_time) {

              const hourDiff = new Date(params.data.eod_time).getTime() - new Date(params.data.att_time).getTime(); //in ms
              const secDiff = hourDiff / 1000; //in s
              const minDiff = hourDiff / 60 / 1000; //in minutes
              const hDiff = hourDiff / 3600 / 1000; //in hours

              return Math.floor(hDiff) + ':' + Math.floor(minDiff - 60 * Math.floor(hDiff));
            }
          }
        }
      },
      {
        headerName: 'TIME IN OUTLET', field: 'time_outlet',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.first_act && params.data.last_act) {

              const hourDiff = new Date(params.data.last_act).getTime() - new Date(params.data.first_act).getTime(); //in ms
              const secDiff = hourDiff / 1000; //in s
              const minDiff = hourDiff / 60 / 1000; //in minutes
              const hDiff = hourDiff / 3600 / 1000; //in hours

              return Math.floor(hDiff) + ':' + Math.floor(minDiff - 60 * Math.floor(hDiff));
            }
          }
        }
      },
      {
        headerName: 'STATE', field: 'state',
        width: 80
      },
      {
        headerName: 'EOD TYPE', field: 'eod_type',
        width: 120, hide: true
      },
      {
        headerName: 'EOD REASON', field: 'eod_reason',
        width: 120, hide: true
      },
      {
        headerName: 'EOD REMARKS', field: 'eod_remarks',
        width: 120, hide: true
      },
      {
        headerName: 'REGION', field: 'region',
        width: 80
      },
      {
        headerName: 'REGION IN CHARGE',
        field: 'region_manager',
        enableRowGroup: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'CLUSTER IN CHARGE',
        field: 'cluster_manager',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true
      },
      {
        headerName: 'TERRITORY IN CHARGE',
        field: 'territory_manager',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true
      },
      {
        headerName: 'REPORTING MANAGER',
        field: 'reporting_manager',
        enableRowGroup: true,
        hide: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'STATUS',
        field: 'status',
        enableRowGroup: true,
        width: 100,
        resizable: true
      },
    ];

  }

  postProcessData() {
    this.rowData = this.apiData;
    this.configureData();
  }

  async configureData() {
    let images = [];
    for (const key in this.rowData) {
      images = [];
      this.rowData[key].photo = null;
      if (this.rowData[key].store_fname != null) {
        images.push({store_fname: this.rowData[key].store_fname, container: 'hr_attendance'});
        //images.push({store_fname:  'cbd/cbdc5e57b977bc71e5011583f424e0aae23f0ecc4ebe7a47e258495ee3fb9aa3', container: 'expense_gallery'});
        const res = await this.apiService.fetchExpenseImage({
          access_token: this.userData.access_token,
          url: this.userData.url,
          data: images
        });
        if (res.hasOwnProperty('result') && res['result'].length > 0) {
          this.rowData[key].photo = 'data:image/png;base64, ' + res['result'][0].bill_datas;
          this.gridApi.applyTransactionAsync({update: [this.rowData[key]]}, () => {
            // tslint:disable-next-line:no-non-null-assertion
            const row = this.gridApi.getDisplayedRowAtIndex(key);
            // tslint:disable-next-line:no-non-null-assertion
            this.gridApi.redrawRows({ rowNodes: [row]});
          });

        }
      }
    }
  }


  onCellClicked(event) {
    const rowIndex = event.rowIndex;
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    rows[0].location = 'abc';
    const displayedrows = [];
    const filteredRowData =  this.gridApi.rowModel.rowsToDisplay;
    filteredRowData.forEach(node => {
      displayedrows.push(node.data);
    });
    if (column === 'store_fname') {
      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0].store_fname != null && rows[0].store_fname !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '60%',
            maxHeight: '98%',
            data: {
              rowData: displayedrows,
              rowIndex,
              mapKey: this.mapkey,
              container: 'hr_attendance',
            }
          };

          const creatediaeref = this.dialog.open(AttendanceImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '90%',
            data: {
              rowData: displayedrows,
              rowIndex,
              mapKey: this.mapkey,
              container: 'hr_attendance',
            }
          };

          const creatediaeref = this.dialog.open(AttendanceImagePopupComponent, config);
        }


      } else {

      }
    } else if (column === 'url') {

      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('latitude') && rows[0].latitude != null && rows[0].latitude !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '70%',
            maxHeight: '95%',
            data: {
              latitude: parseFloat(rows[0].latitude),
              longitude: parseFloat(rows[0].longitude)
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '65%',
            data: {
              latitude: parseFloat(rows[0].latitude),
              longitude: parseFloat(rows[0].longitude)
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        }


      } else {

      }
    }
  }
}
