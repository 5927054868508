import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../../../_services/localforage.service";
import {ErrorObject} from "../../../../error/error-model";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-move-beat-retailer-popup',
  templateUrl: './generate_claims_popup.component.html'
})

export class GenrateClaimsPopupComponent implements OnInit {

  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  API_URL = '';
  USER;
  access_token;
  lines = [];
  line_ids = [];
  line_count = 0;
  invoices_count = 0;
  claim_amount = '';

  preloader: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  quantity = 0;
  customer_count = 0;
  // expensephoto = null;
  // expensephotos: any = {};
  // expensephotodata = null;
  // expensephotodatas = {};
  // expenseFileData = {};
  claimType = '';

  @Output() saveActionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              protected _localforageService: LocalforageService,
              private domSanitizer: DomSanitizer,
              private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.lines = dialogdata.lines;
    this.claimType = dialogdata.type;
    this.line_ids = [...new Set(this.lines.map(obj => obj.id))];
    this.access_token = localStorage.getItem('resfreshToken');
    this.USER = JSON.parse(localStorage.getItem('user'));

    this.line_count = this.line_ids.length;
    this.invoices_count = this.unique(this.lines, ['invoice_no']).length;

    let claimAmount = 0;
    this.lines.forEach(line => {
      if (this.claimType === 'scheme') {
        if (line.discount > 0) {
          claimAmount = claimAmount + parseFloat(line.discount);
        }
      } else if (this.claimType === 'sale return') {
        claimAmount = claimAmount + parseFloat(line.price_total);
      }
    });
    for (const line of this.lines) {
      this.quantity += parseInt(line.quantity, 10);
    }
    this.claim_amount = parseFloat(String(claimAmount)).toFixed(2);
    this.customer_count = this.unique(this.lines, ['code']).length;
    console.log('constructor');
  }

  ngOnInit(): void {

  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }


  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  generateClaims() {

    this.API_URL = '/api/pwa_dms_connects/generate_scheme_claims';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    if (this.line_ids.length > 0) {
      paramObject.data = {};
      paramObject.data = {
        line_ids: this.line_ids,
        amount: this.claim_amount,
        // team_id: this.USER.sale_team_id,
        // datas: this.expensephotos,
        // claim_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        claimType: this.claimType
      };
      this.apiService.post(this.API_URL, paramObject)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results')) {
              // this.dialog.closeAll();
              // window.location.reload();
              if (res.results.status === 200) {
                this.saveData.emit(res.results.data);
              } else {
                this.toastr.error(res.results.msg);
              }
            } else {
              this.toastr.error('Error Generating Claims');
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Error Generating Claims');
          });
    } else {
      this.toastr.error('No Lines Selected');
    }
  }

  // handleFileSelect(evt) {
  //   // console.log(evt);
  //   const files = evt.target.files;
  //   const file = files[0];
  //   this.expenseFileData = {};
  //   if (files && file) {
  //     const reader = new FileReader();
  //     this.expenseFileData['name'] = file.name;
  //     this.expenseFileData['type'] = file.type;
  //     this.expenseFileData['size'] = Math.round(file.size / 1024);
  //     reader.onload = this._handleReaderLoaded.bind(this);
  //
  //     reader.readAsBinaryString(file);
  //   }
  // }

  // _handleReaderLoaded(readerEvt, file) {
  //   const binaryString = readerEvt.target.result;
  //   const base64textString = btoa(binaryString);
  //   this.expensephotodata = base64textString;
  //   let type = '';
  //   if (this.expenseFileData['type'].includes('pdf')) {
  //     type = 'pdf';
  //   } else {
  //     type = 'image';
  //   }
  //   this.expensephotodatas = {
  //     name: this.expenseFileData['name'],
  //     size: this.expenseFileData['size'],
  //     photodata: base64textString,
  //     type
  //   };
  //   if (type === 'pdf') {
  //     this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + base64textString);
  //   } else {
  //     this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
  //   }
  //   this.expensephotos = {
  //     name: this.expenseFileData['name'],
  //     size: this.expenseFileData['size'],
  //     photo: base64textString,
  //     type
  //   };
  // }
}
