import {Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-create-beat-plan-popup',
  templateUrl: './create_beat_plan_popup.component.html',
  styleUrls: ['./create_beat_plan_popup.component.scss']

})

export class CreateBeatPlanPopupComponent implements OnInit {

  beatPlanData: any = {};
  territoriesList: any = [];
  salesmenList: any = [];
  beatTravelTypes = [];
  schedule_type_list = ['manual', 'daily', 'recurring'];
  API_URL = '';
  access_token;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
      this.salesmenList.sort((a, b) => a.itemname.localeCompare(b.itemame));
    }

    if (localStorage.getItem('all_territories') !== null && localStorage.getItem('all_territories') !== 'null' && JSON.parse(localStorage.getItem('all_territories')) !== null) {
      this.territoriesList = JSON.parse(localStorage.getItem('all_territories'));
      this.territoriesList.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    this.access_token =  localStorage.getItem('resfreshToken');
    this.beatPlanData.salesman_id = this.salesmenList[0].id;
    this.beatPlanData.territory_id = this.territoriesList[0].id;

  }

  ngOnInit(): void {

    this.getBeatTravelTypes();
  }

  getBeatTravelTypes() {
    if (localStorage.getItem('all_travel_types') !== null && localStorage.getItem('all_travel_types') !== 'null') {
      this.beatTravelTypes = JSON.parse(localStorage.getItem('all_travel_types'));
    }

    // const paramObject: any = {};
    // paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    // this.API_URL = '/api/beat_travel_types';
    // this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
    //   .subscribe(res => {
    //     // console.log(res);
    //     if (res.length > 0) {
    //       this.beatTravelTypes = res;
    //       this.beatPlanData.travel_type_id = this.beatTravelTypes[0].id;
    //     }
    //   });
  }

  createBeatPlan() {
    this.API_URL = '/api/beat_plans/create_beat_plan';
    const paramObject: any = {};
    this.beatPlanData.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.beatPlanData;
    this.apiService.post(this.API_URL, this.beatPlanData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res.result.status === 409)) {
            this.toastr.warning('Cannot create Beat with existing name and code');
          } else if (res.hasOwnProperty('result') && (res.result.success)) {
            this.toastr.success('Created Beat Plan Successfully');
            this.dialog.closeAll();
            window.location.reload();
          } else {
            this.toastr.error('Error creating Beat Plan');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error creating Beat Plan');
        });

  }


  closeDialog() {
    this.dialog.closeAll();
  }

  onChangescheduletype() {

    if (this.beatPlanData.schedule_type === 'daily' || this.beatPlanData.schedule_type === 'manual') {
      this.beatPlanData.schedule_config = '';
    }
  }

}
