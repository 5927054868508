import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {ErrorHandler} from "../../../error/error-handler";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {MatDialogConfig} from "@angular/material/dialog";
import {BulkUploadStatusPopupComponent} from "../../../components/bulk_upload_popup/bulk_upload_status_popup/bulk_upload_status_popup.component";
import {PaymentCollectPopupComponent} from "../payments/payment_collect_popup/payment_collect_popup.component";
import {DiscountPopupComponent} from "../../discount_popup/discount_popup.component";


@Component({
  selector: 'app-sale-invoice',
  templateUrl: 'sale_invoice.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class SaleInvoiceComponent extends DMSBasePage implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;
  OPERATION_MODE = 'S';

  dateDisable;
  myDate;
  public addressLibrary;
  public inco_terms_list;

  public priceEdited = false;
  public uomEdited = false;

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;


  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  showPlantField = false;
  showIncoTerms = false;
  showTotalWeight = false;
  showTotalMeasure = false;

  plantFieldValue = '';
  incoTermsFieldValue = '';
  totalMeasureFieldValue = '';
  totalWeightFieldValue = '';

  reference_order_mode = false;

  feature_disable_supplier_live_stock = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_invoice_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Sale Invoice');
    const inThis = this;

    this.configureGrid();

    this.initialize_default_values();

    this.readPageParms(params => {

      if (params.get('reference_order') === 'true') {

        const ref_order_object = JSON.parse(localStorage.getItem('reference_order'));
        if (ref_order_object !== undefined) {

          this.prepare_referance_object(ref_order_object);
          //   Page Can be ready only after detail Object is fetched
          this.loadMasterDataForEdit(() => {

            this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
              this.pageReady = true;
              this.compute_scheme_and_tax();
              // this.rowData = schme_tax_res.items;
              // this.activeObject['tax_notes'] = schme_tax_res.tax_notes;
              // this.compute_tax();
              this.compute_totals();

              this.enableDisableGridEdit();
            });
          });

        }
      } else {

        if (this.activeID > 0) {
          this.newMode = false;
          this.editMode = false;
          //   View Mode
          //   Page Can be ready only after detail Object is fetched
          this.loadPageForView(() => {
            this.pageReady = true;
            this.enableDisableGridEdit();
          });
        } else {
          this.newMode = true;
          this.editMode = true;
          //   Page Can be ready only after detail Object is fetched
          this.loadMasterDataForEdit(() => {
            this.pageReady = true;
            this.enableDisableGridEdit();
          });
        }
      }
    });
  }

  enableDisableGridEdit() {
    this.gridOptions.suppressClickEdit = !this.editMode;
    this.gridOptions.singleClickEdit = this.editMode;

    // if (this.editMode) {
    //   this.rowData.forEach(line => {
    //     const prod = this.prodList.find(item => item.p_id === line.product_id);
    //     if (prod) {
    //       line.price_unit = DMSBasePage.GetUnitPrice(prod);
    //       line.base_price = DMSBasePage.GetBasePrice(prod);
    //     }
    //   });
    // }
  }

  loadPage() {
    this.pageReady = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  //Page Initilizefunctions
  prepare_referance_object(ref_order_object) {

    this.activeObject = {...ref_order_object}; //Shallow Copy the reference Object.
    this.activeObject.id = 0;
    this.activeObject.name = '';
    this.activeObject['state'] = 'draft';

    this.activeObject.lines = [];
    this.rowData = [];

    this.activeID = 0;
    this.newMode = false;
    this.editMode = true;
    this.reference_order_mode = true;

    let uid = 10;
    ref_order_object.lines.forEach(item => {
      const inv_item = {...item};
      inv_item.uid = uid;
      inv_item.mid = this.get_mid(uid);
      uid = uid + 10;

      if (inv_item.hasOwnProperty('config_json') && inv_item.config_json !== null && inv_item.config_json !== undefined) {
        if (inv_item.config_json.hasOwnProperty('base_price') && inv_item.config_json.base_price > 0) {
          inv_item['base_price'] = inv_item.config_json.base_price;
        } else {
          inv_item['base_price'] = inv_item.price_unit;
        }
      }

      this.rowData.push(inv_item);
    });

    if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json && this.activeObject.config_json.hasOwnProperty('cash_dis') && this.activeObject.config_json.cash_dis !== null && this.activeObject.config_json.cash_dis !== undefined) {
      this.cash_dis = parseFloat(this.activeObject.config_json.cash_dis);
    }
  }
  initialize_default_values() {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.discount = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.activeObject.date_invoice = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.partner_tcs = 0;

    this.feature_disable_supplier_live_stock = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = [];
      let unique_id = 1;

      if (lineItems) {
        lineItems.forEach(line => {
          line['uid'] = unique_id;

          if (line['discount'] && line['discount'] > 0) {
            const discount_value = ( parseFloat(line['discount']) / 100) * (parseInt(line['quantity'], 10) * parseFloat(line['price_unit']));
            line['disc_value'] = discount_value;
            line['disc_qty'] = discount_value / parseInt(line['quantity'], 10);
            // row['disc_volume'] = event.data['disc_qty'] / unit_measure;
          }
          if (line.hasOwnProperty('config_json') && line.config_json !== null && line.config_json !== undefined) {
            if (line.config_json.hasOwnProperty('base_price') && line.config_json.base_price > 0) {
              line['base_price'] = line.config_json.base_price;
            } else {
              line['base_price'] = line.price_unit;
            }
          }


          rowData.push(line);
          unique_id++;
        });
      }
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['partner_id'] === undefined) {
        this.toastr.error('invalid state: partner_id not found');
      }

      if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
        this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
      }

      if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = prepareLineData(this.activeObject.lines);
        // this.gridOptions.api.setRowData(this.rowData);
      }

      if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json !== null && this.activeObject.config_json !== undefined) {
        if (this.activeObject.config_json.hasOwnProperty('plant') && this.activeObject.config_json.plant !== null && this.activeObject.config_json.plant !== undefined) {
          this.showPlantField = true;
          this.plantFieldValue = this.activeObject.config_json.plant_name;
        }
        if (this.activeObject.config_json.hasOwnProperty('inco_term') && this.activeObject.config_json.inco_term !== null && this.activeObject.config_json.inco_term !== undefined) {
          this.showIncoTerms = true;
          this.incoTermsFieldValue = this.activeObject.config_json.inco_term;
        }
        if (this.activeObject.config_json.hasOwnProperty('totalWeight') && this.activeObject.config_json.totalWeight !== null && this.activeObject.config_json.totalWeight !== undefined) {
          this.showTotalWeight = true;
          this.totalWeightFieldValue = parseFloat(this.activeObject.config_json.totalWeight).toFixed(2);
        }
        if (this.activeObject.config_json.hasOwnProperty('totalMeasure') && this.activeObject.config_json.totalMeasure !== null && this.activeObject.config_json.totalMeasure !== undefined) {
          this.showTotalMeasure = true;
          this.totalMeasureFieldValue = parseFloat(this.activeObject.config_json.totalMeasure).toFixed(2);
        }
        if (this.activeObject.config_json.hasOwnProperty('cash_dis') && this.activeObject.config_json.cash_dis !== null && this.activeObject.config_json.cash_dis !== undefined) {
          this.cash_dis = parseFloat(this.activeObject.config_json.cash_dis);
        }
      }

      if (this.activeObject.amount_tax) {
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
      }
      if (this.activeObject.amount_total) {
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
      }
      if (this.activeObject.amount_untaxed) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
      }
      if (this.activeObject.tax_source) {
        this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
      } else if (this.activeObject.charges) {
        this.activeObject.charges = parseFloat(this.activeObject.charges);
      }

      this.compute_totals();
      this.get_supplier_available_stocks([this.activeObject], (err, result) => {
        if (err) {
          this.handleError(err);
        } else if (result) {
          this.rowData.forEach(line => {
            result.forEach(tp => {
              if (line.product_id === tp.product_id) {
                line.stock = tp.stock;
              }
            });
          });
        }
        this.gridOptions.api.setRowData(this.rowData);
        callback();
      });
    });

  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
    this.showSave = false;
  }

  async onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.newValue !== event.oldValue) {
      event.data['price_tax'] = 0;
      let unit_measure = 1;
      if (event.data.hasOwnProperty('unit_measure') && event.data.unit_measure > 0) {
        unit_measure = event.data.unit_measure;
      }
      if (!event.newValue) {
        event.newValue = 0;
      }

      if (event.colDef.field === 'quantity') {
        event.data['reusable_qty'] = event.newValue;
        event.data['price_tax'] = 0;
        event.data['promo_qty'] = 0;

        const prod = this.prodList.find(p => p.p_id === event.data['product_id']);
        if (prod && prod.volume) {
          const selected_uom_factor = this.get_factor_for_selected_uom(event.data['uom_id'], event.data['default_uom_id']);
          event.data['volume'] = selected_uom_factor * event.newValue * prod.volume;
        }


      } else if (event.colDef.field === 'disc_value') {
        const discount_value = parseFloat(event.newValue);
        const base_total = parseInt(event.data['quantity'], 10) * parseFloat(event.data['price_unit']);
        if (discount_value >= base_total) {
          event.data['disc_value'] = event.oldValue;
        } else {
          event.data['disc_value'] = discount_value;
          event.data['discount'] = (discount_value * 100) / base_total;
          event.data['disc_qty'] = discount_value / parseInt(event.data['quantity'], 10);
          event.data['disc_volume'] = discount_value / unit_measure;
        }

      } else if (event.colDef.field === 'disc_qty') {
        const discount_per_qty = parseFloat(event.newValue);
        const discount_value = parseInt(event.data['quantity'], 10) * discount_per_qty;
        const base_total = parseInt(event.data['quantity'], 10) * parseFloat(event.data['price_unit']);
        if (discount_value >= base_total) {
          event.data['disc_qty'] = event.oldValue;
        } else {
          event.data['disc_value'] = discount_value;
          event.data['discount'] = (discount_value * 100) / base_total;
          event.data['disc_volume'] = discount_per_qty / unit_measure;
        }
      } else if (event.colDef.field === 'disc_volume') {
        const discount_per_volume = parseFloat(event.newValue);
        const discount_per_qty = discount_per_volume * unit_measure;
        const discount_value = parseInt(event.data['quantity'], 10) * discount_per_qty;
        const base_total = parseInt(event.data['quantity'], 10) * parseFloat(event.data['price_unit']);
        if (discount_value >= base_total) {
          event.data['disc_volume'] = event.oldValue;
        } else {
          event.data['disc_qty'] = discount_per_qty;
          event.data['disc_value'] = discount_value;
          event.data['discount'] = (discount_value * 100) / base_total;
        }
      } else if (event.colDef.field === 'discount') {
        const discount_pct = parseFloat(event.newValue);
        const base_total = parseInt(event.data['quantity'], 10) * parseFloat(event.data['price_unit']);
        const discount_value = (discount_pct / 100) * base_total;
        if (discount_value >= base_total) {
          event.data['discount'] = event.oldValue;
        } else {
          event.data['disc_value'] = discount_value;
          event.data['disc_qty'] = discount_value / parseInt(event.data['quantity'], 10);
          event.data['disc_volume'] = event.data['disc_qty'] / unit_measure;
        }
      }

      this.compute_scheme_and_tax();
    }
    this.showSave = true;
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }

  //Page Events

  customerChangeEvent(selected_customer_id) {
    const data = this.customerList.find(item => item.id === selected_customer_id);
    // this.changeCustomer(selected_customer);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['partner_invoice_id'] = data.id;
      this.activeObject['partner_shipping_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;

      this.activeObject['area_id'] = data.area_id;
      this.activeObject['zone_id'] = data.zone_id;
      this.activeObject['state_id'] = data.state_id;

      if (data.hasOwnProperty('partner_type') && data['partner_type'] === 'supplier') {
        this.OPERATION_MODE = 'P';
        this.activeObject['type'] = 'P';
      } else {
        this.OPERATION_MODE = 'S';
        this.activeObject['type'] = 'S';
      }

      this.activeObject['tax_source'] = data.tax_source;
      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist_name;
      this.activeObject['shipto_address'] = data.address;
      // this.supplierList = [];
      this.beatList = [];

      if (data['beats']) {
        this.beatList = data['beats'];
        if (this.beatList.length > 0) {
          this.activeObject['beat_id'] = this.beatList[0].id;
          this.activeObject['territory_id'] = this.beatList[0].t_id;
          this.activeObject['user_id'] = this.beatList[0].sm_id;
          this.activeObject['beat_name'] = this.beatList[0].name;
          this.salesmanChange(this.activeObject['user_id']);
        }
      }
      if (!this.activeObject['territory_id'] && data.territory_id) {
        this.activeObject['territory_id'] = data.territory_id;
      }

      if (this.supplierList.length === 0) {
        this.toastr.error('Unable to load suppliers');
      } else {
        if (this.activeObject['supplier_id'] !== this.supplierList[0].id) {
          this.populateSupplierAttributes(this.supplierList[0].id);
        }
        // this.supplierChangeEvent({id: this.supplierList[0].id});
      }

      this.prodList = [];
      this.selectedProduct = 0;
      this.loadProducts(this.activeObject['partner_id'], this.activeObject['distributor_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
      });
    }

  }

  supplierChangeEvent(event) {

    if (event.id) {
      if (this.activeObject['supplier_id'] !== event.id) {
        // this.activeObject['distributor_id'] = event.id;

        //Supplier Chnage Detected
        if (this.populateSupplierAttributes(this.activeObject['supplier_id'])) {
          this.loadProducts(this.activeObject['partner_id'], this.activeObject['distributor_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
          });
        }
      }
    }
  }

  plantChangeEvent(plant_code) {
    if (plant_code) {
      this.activeObject['config_json']['plant'] = plant_code;

      const select_plan_obj = this.plantList.find(item => item.code = plant_code);
      if (select_plan_obj) {
        this.activeObject['config_json']['plant_name'] = select_plan_obj.name;
      }
    }
  }

  beatChange(beat_id) {
    this.activeObject['beat_id'] = beat_id;
    const selected_beat = this.beatList.find(item => item.id === beat_id);
    if (selected_beat) {
      this.activeObject['beat_name'] = selected_beat.name;
      this.activeObject['user_id'] = selected_beat.sm_id;
      this.activeObject['team_id'] = selected_beat.team_id;
      this.activeObject['salesman'] = selected_beat.sm;
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.find(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['salesman'] = selecteduser.itemname;
        this.activeObject['emp_code'] = selecteduser.emp_code;
        this.activeObject['team_id'] = selecteduser.sale_team_id;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.find(item => item.p_id === this.selectedProduct);
    if (prod) {
      this.UOMList = this.UOMMasterList.filter(item => item.category_id === prod.uom_category_id);
      this.selectedUOM_id = prod.uom_id;
      this.stock = prod.stock;
    }
    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange(uom_id) {
    console.log(this.selectedUOM_id);
  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.enableDisableGridEdit();
      this.loadPage();
    }
  }

  action_edit() {
    this.editMode = true;
    this.pageReady = false;
    this.loadMasterDataForEdit(() => {
      this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
        this.pageReady = true;
        this.enableDisableGridEdit();
      });
    });
    this.gridApi.redrawRows();
  }

  gotoinvoice() {

    const order = this.activeObject;
    order['mid'] = undefined;
    localStorage.setItem('invoice_create', JSON.stringify(order));

    this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'],
      {
        relativeTo: this.route,
        queryParams: {invoice_form_order: true}
      });
  }


  view_order() {

    if (this.activeObject.hasOwnProperty('order_id')) {
      this.router.navigate([this.apiService.redirect_url + '/dms/sale_order'],
        {
          relativeTo: this.route,
          queryParams: {id: this.activeObject['order_id']}
        });
    }
  }

  //GRID Functions
  configureGrid() {
    this.columnDefs = [
      {headerName: 'UID', field: 'uid', hide: true},
      {headerName: 'CODE', field: 'code', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 120},
      {headerName: 'STOCK', field: 'stock', width: 40, cellStyle: {textAlign: 'right'}},
      {headerName: 'QTY', field: 'quantity', width: 40, editable: (params) => (params.data['type'] !== 'promo' && this.editMode === true), cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7', textAlign: 'right'};
            } else {
              return {backgroundColor: '#cae4f8', textAlign: 'right'};
            }
          }
        }
      },
      {headerName: 'UOM', field: 'uom_name', width: 40},
      {headerName: 'PRICE', field: 'price_unit', width: 40, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'Unit Measure', field: 'unit_measure', width: 10, hide: true},
      {headerName: 'RATE (KG/LTR)', field: 'card_rate', width: 30,
        valueFormatter: params => this.formatNumber(params.data.card_rate),
        hide: !(this.allow_discount_per_volume),
        cellStyle: {textAlign: 'right'}
      },
      {headerName: 'DISC (KG/LTR)', field: 'disc_volume', width: 40,
        valueFormatter: params => this.formatNumber(params.data.disc_volume),
        hide: true,
        cellStyle: {textAlign: 'right'}
      },
      {headerName: 'DISC%', field: 'discount', width: 40,
        valueFormatter: params => this.formatNumber(params.data.discount),
        hide: !this.allow_discount,
        cellStyle: {textAlign: 'right'}
      },
      {headerName: 'DISC', field: 'disc_value', width: 40,
        valueFormatter: params => this.formatNumber(params.data.disc_value),
        hide: !this.allow_discount,
        cellStyle: {textAlign: 'right'}
      },
      {headerName: 'DISC Per Unit', field: 'disc_qty', width: 30,
        valueFormatter: params => this.formatNumber(params.data.disc_qty),
        hide: true,
        cellStyle: {textAlign: 'right'}
      },
      {headerName: 'DISCOUNT', field: 'disc_display', width: 50,
        cellRenderer(params) {
          if (params.data) {
            if (params.data['type'] !== 'promo') {
              if (params._self.editMode) {
                return `<span style="color: #E89221;font-size: 11px;font-weight:600;cursor: pointer;">Apply discounts</span>`;
              } else {
                return `<span style="color: #0762AD;font-size: 11px;font-weight:600;cursor: pointer;">View discounts</span>`;
              }
            }
          }
        }, cellRendererParams: {
          _self: this
        }
        },
      {headerName: 'TRADE DISC', field: 'trade_disc', width: 40, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent, hide: true},
      {headerName: 'CHARGES', field: 'fixed_cost', width: 40, hide: true},
      {headerName: 'SCHEME', field: 'scheme_code', width: 60},
      {headerName: 'SCHEME DISC', field: 'scheme_discount', width: 40, valueFormatter: params => this.formatNumber(params.data.scheme_discount), cellStyle: {textAlign: 'right'}},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', width: 50, valueFormatter: params => this.formatNumber(params.data.price_subtotal), pinned: 'right', cellStyle: {textAlign: 'right'}},
      {headerName: 'TAX', field: 'price_tax', width: 40, valueFormatter: params => this.formatNumber(params.data.price_tax), pinned: 'right', cellStyle: {textAlign: 'right'}},
      {headerName: 'TAX Breakup', field: 'tax_notes', width: 50, hide: true},
      {headerName: 'TOTAL', field: 'price_total', width: 50, valueFormatter: params => this.formatNumber(params.data.price_total), pinned: 'right', cellStyle: {textAlign: 'right'}},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 20, pinned: 'right'},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
    };
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  }

  addProductClick() {

    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      this.toastr.error('Invalid Customer for invoice');
      return;
    }
    /*if (this.activeObject['user_id'] === undefined || this.activeObject['user_id'] === 0) {
      this.toastr.error('Please select the salesman');
      return;
    }*/

    this.newMode = false;

    this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.stock, this.enteredQty, undefined);

    this.compute_scheme_and_tax();

    this.gridApi.setRowData(this.rowData);

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.productSelector.focus();
  }

  removeProduct(index) {
    if (this.editMode) {
      const old_line = this.rowData[index];
      if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0) {
        this.rowData[index]['deleted'] = true;
        this.rowData[index]['quantity'] = 0;
        this.rowData[index]['reusable_qty'] = 0;

        this.gridApi.onFilterChanged();
      } else {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.compute_scheme_and_tax();
    }
  }


  //Page core functions
  saveCart() {


    //TODO: Complete basic validation of page and line items
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      return this.toastr.error('Invalid Customer for invoice');
    }
    if (this.activeObject['supplier_id'] === undefined || this.activeObject['supplier_id'] === 0) {
      return this.toastr.error('Invalid Supplier');
    }
    if (!this.activeObject['user_id'] || this.activeObject['user_id'] === 0) {
      this.activeObject['user_id'] = JSON.parse(localStorage.getItem('user')).id;
    }
    this.rowData.forEach(line => {
      if (line['mid'] === undefined || line['mid'].length === 0) {
        return this.toastr.error('Invalid mid for line');
      }
    });

    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {
      if (!(line.deleted === true && line.line_id === undefined)) {
        this.activeObject['lines'].push(line);
      }
    });

    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_sale_invoice";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {
        this.editMode = false;
        this.enableDisableGridEdit();
        this.loadPage();
      }
    });
  }


  action_create_invoice() {


  }

  action_cancel() {
    this.pageReady = false;
    this.ChangeInvoiceStatus(this.activeObject.id, 'cancel', (err, result) => {
      if (result) {
        this.activeObject['state'] = result['state'];
      }
      this.pageReady = true;
    });
  }

  action_confirm_invoice() {
    this.pageReady = false;
    this.ChangeInvoiceStatus(this.activeObject.id, 'confirm', (err, result) => {
      if (result) {
        this.activeObject['state'] = result['state'];
      }
      this.pageReady = true;
    });
  }

  action_mark_dispatch() {
    this.pageReady = false;
    this.ChangeInvoiceStatus(this.activeObject.id, 'dispatched', (err, result) => {
      if (result) {
        this.activeObject['state'] = result['state'];
      }
      this.pageReady = true;
    });
  }

  action_mark_delivered() {
    this.pageReady = false;
    this.ChangeInvoiceStatus(this.activeObject.id, 'delivered', (err, result) => {
      if (result) {
        this.activeObject['state'] = result['state'];
      }
      this.pageReady = true;
    });
  }

  action_accept_returns() {

    localStorage.setItem('reference_invoice', JSON.stringify(this.activeObject));

    this.router.navigate([this.apiService.redirect_url + '/dms/return_invoice'], {
      relativeTo: this.route,
      queryParams: {id: 0, reference_invoice: true}
    });
  }

  async action_pay_settle() {

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "88vh",
        maxWidth: "70%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          data: this.activeObject,
          partner_id: this.activeObject['partner_id']
        }
      };
      const creatediaeref = this.dialog.open(PaymentCollectPopupComponent, config);
      creatediaeref.componentInstance.markPaid.subscribe(data => {
        this.loadPageForView(() => {
          this.pageReady = true;
          this.enableDisableGridEdit();
        });
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          data: this.activeObject,
          partner_id: this.activeObject['partner_id']
        }
      };
      const creatediaeref = this.dialog.open(PaymentCollectPopupComponent, config);
      creatediaeref.componentInstance.markPaid.subscribe(data => {
        this.loadPageForView(() => {
          this.pageReady = true;
          this.enableDisableGridEdit();
        });
      });
    }
  }

  getPrintDetails() {
    this.appComponent.getPrintDetails([this.activeObject.id], '/api/pwa_dms_connects/get_invoice_print_details', null);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'disc_display' && event.data['type'] !== 'promo') {
      this.openDiscountDialog(event.data);
    }
  }

  openDiscountDialog(row) {
    const config: MatDialogConfig = {
      width: "650px",
      height: "auto",
      maxHeight: '100%',
      data: {
        editMode: this.editMode,
        productObject: row,
        discountMode: 'discount'
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(DiscountPopupComponent, config);
    confirmdiaeref.componentInstance.discountappplied.subscribe(data => {
      const index = this.rowData.findIndex(x => x.uid === data.uid);
      this.rowData[index] = data;
      this.compute_scheme_and_tax();
      this.showSave = true;
    });
  }

}
