import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-components-customfilter',
  templateUrl: './customfilter.component.html'
})

export class CustomFilterComponent implements OnInit {

  state: [];

  selectedData: any = {};

  @Input() customFilters: any;
  @Output() onsetCustomFilter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.selectedData[this.customFilters.key] = this.customFilters.value;
  }

  setCustomFilter() {
    this.onsetCustomFilter.emit(this.selectedData);
  }

}
