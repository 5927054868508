import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ToastrService} from "ngx-toastr";
import {ngxCsv} from "ngx-csv";
import {BulkUploadStatusPopupComponent} from "./bulk_upload_status_popup/bulk_upload_status_popup.component";
import * as XLSX from "xlsx-js-style";

@Component({
  selector: 'app-bulk-upload-popup',
  templateUrl: './bulk_upload_popup.component.html',
  styleUrls: ['./bulk_upload_popup.component.scss']

})

export class BulkUploadPopupComponent implements OnInit {

  public header = "";
  API_URL = '';
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: []
    },
    customFilter: {}
  };
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  productData = [];
  private gridApi;
  private gridColumnApi;
  uploadexcel;
  uploadTask = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  sampleText1;
  sampleURL1;
  sampleFile1;
  sampleType1;
  sampleData1 = [];
  sampleText2;
  sampleURL2;
  sampleFile2;
  sampleType2;
  sampleData2 = [];
  tableData = [];
  step1Text = '';
  step2Text = '';
  actionText = '';
  filesize = 0;
  type;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    /* this.store_fname = dialogdata.store_fname;
     this.container = dialogdata.container;*/

    this.header = dialogdata.header;
    this.type = dialogdata.type;
    this.sampleText1 = dialogdata.sampleText1;
    this.sampleURL1 = dialogdata.sampleURL1;
    this.sampleFile1 = dialogdata.sampleFile1;
    this.sampleType1 = dialogdata.sampleType1;
    this.sampleData1 = dialogdata.sampleData1;
    this.sampleText2 = dialogdata.sampleText2;
    this.sampleURL2 = dialogdata.sampleURL2;
    this.sampleFile2 = dialogdata.sampleFile2;
    this.sampleType2 = dialogdata.sampleType2;
    this.sampleData2 = dialogdata.sampleData2;
    this.actionText = dialogdata.actionText;
    this.tableData = dialogdata.tableData;
    this.step1Text = dialogdata.step1Text;
    this.step2Text = dialogdata.step2Text;
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;
  }

  ngOnInit(): void {
    this.uploadexcel = this.uploadData;
  }

  submit() {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveData() {
  }

  sampleDownload1() {
    if (this.sampleType1 === 'api') {
      this.apiService.post(this.sampleURL1, this.userData)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              if (this.type === 'upload_product') {
                this.processProductTemplateData(res.results.data, this.sampleFile1);
              } else if (this.type === 'upload_stock') {
                this.processProductData(res.results.data,  this.sampleFile1);
              }
            } else {
              this.preloader = false;
              //this.empty_state = true;
            }
          },
          error => {
            console.log(error);
            this.preloader = false;
          });
    } else {
      this.downloadExcel(this.sampleData1, this.sampleFile1);
    }
  }

  sampleDownload2() {
    if (this.sampleType2 === 'api') {
      this.apiService.post(this.sampleURL2, this.userData)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              if (this.type === 'upload_product') {
                this.processProductTemplateData(res.results.data,  this.sampleFile2);
              } else if (this.type === 'upload_stock') {
                this.processProductData(res.results.data,  this.sampleFile2);
              }
            } else {
              this.preloader = false;
              //this.empty_state = true;
            }
          },
          error => {
            console.log(error);
            this.preloader = false;
          });
    } else {
      this.downloadExcel(this.sampleData2, this.sampleFile2);
    }
  }

  processProductData(products, filename) {
    this.productData = [];
    for (const prod of products) {

      const indrow: any = {};
      indrow.category = prod.category;
      indrow.brand = prod.brand;
      indrow.sku_code = prod.sku_code;
      indrow.sku_name = prod.sku_name;
      indrow.sku_seq = prod.sku_seq;
      indrow.description = prod.description;
      indrow.uom = prod.uom;
      indrow.uom_factor = prod.uom_factor;
      indrow.ptd = prod.ptd;
      indrow.ptr = prod.ptr;
      indrow.priority = prod.priority;
      indrow.active = prod.active;
      if (prod.hasOwnProperty('tax_config') && prod.tax_config) {
        indrow.gst = prod.tax_config.gst;
        indrow.vat = prod.tax_config.vat;
        indrow.cess = prod.tax_config.cess;
        indrow.excise = prod.tax_config.excise;
      }

      this.productData.push(indrow);
    }
    this.downloadExcel( this.productData, filename);
  }

  processProductTemplateData(products, filename) {
    this.productData = [];
    for (const prod of products) {

      const indrow: any = {};
      indrow.category = prod.category;
      indrow.brand = prod.brand;

      if (prod.variants && prod.variants.length > 0) {
        for (const variant of prod.variants) {
          indrow.family_code = variant.code;
          indrow.family_name = variant.name;
          indrow.family_seq = variant.seq;
        }
      }
      indrow.sku_code = prod.sku_code;
      indrow.sku_name = prod.sku_name;
      indrow.sku_seq = prod.sku_seq;
      indrow.description = prod.description;
      indrow.uom = prod.uom;
      indrow.uom_factor = prod.uom_factor;
      indrow.ptd = prod.ptd;
      indrow.ptr = prod.ptr;
      indrow.priority = prod.priority;
      indrow.active = prod.active;
      if (prod.hasOwnProperty('tax_config') && prod.tax_config !== null) {
        indrow.gst = prod.tax_config.gst;
        indrow.vat = prod.tax_config.vat;
        indrow.cess = prod.tax_config.cess;
        indrow.excise = prod.tax_config.excise;
      }
      this.productData.push(indrow);
    }
    this.downloadExcel( this.productData, filename);
  }

  downloadTemplate() {
    this.productData = [];
    const indProduct: any = {};
    indProduct['CATEGORY'] = '' ;
    indProduct['BRAND'] = '' ;
    indProduct['FAMILY CODE'] = '' ;
    indProduct['FAMILY NAME'] = '' ;
    indProduct['FAMILY SEQ'] = '' ;
    indProduct['SKU CODE'] = '' ;
    indProduct['SKU NAME'] = '' ;
    indProduct['SKU SEQ'] = '' ;
    indProduct['Description'] = '' ;
    indProduct['UOM'] = '' ;
    indProduct['UOM CATEGORY'] = '' ;
    indProduct['PTD'] = '' ;
    indProduct['PTR'] = '' ;
    indProduct['PRIORITY'] = '' ;
    indProduct['ACTIVE'] = '' ;
    indProduct['GST'] = '' ;
    indProduct['VAT'] = '' ;
    indProduct['CESS'] = '' ;
    indProduct['EXCISE'] = '' ;
    this.productData.push(indProduct);
    this.downloadExcel( this.productData, 'product');

  }

  downloadExcel(data, filename) {
    const headers = Object.keys(data[0]);
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'ProductData',
      useBom: true,
      noDownload: false,
      headers
    };
    return new ngxCsv(this.productData, filename, options);
  }


  goToStatusPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "85vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          header: 'Bulk Update Products',
          rowData: this.productData
        }
      };
      const creatediaeref = this.dialog.open(BulkUploadStatusPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          header: 'Bulk Update Products',
          rowData: this.productData
        }
      };
      const creatediaeref = this.dialog.open(BulkUploadStatusPopupComponent, config);
    }
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.filesize = Math.round(this.fileUploaded.size / 1024);
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.uploadData();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadData() {
    //this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    this.productData = [];
    const paramObject: any = {};
    let obj = {};
    let invalid = false;
    const numcharPattern = /^([a-z0-9]+)$/i;
    const floatPattern = /^\d*\.?\d*$/;
    const numPattern = /^[0-9]*$/;
    paramObject.access_token = this.userData.access_token;
    for (const item of this.upload_task_data) {
      obj = {
       /* category: item['CATEGORY'],
        brand: item['BRAND'],
        sku_code: item['FAMILY CODE'],
        sku_name: item['FAMILY NAME'],
        sku_seq: item['FAMILY SEQ'],
        description: item['Description'],
        uom: item['UOM'],
        ptd: item['PTD'],
        ptr: item['PTR'],
        priority: item['PRIORITY'],
        active: item['ACTIVE'],
        gst: item['GST'],
        vat: item['VAT'],
        cess: item['CESS'],
        excise: item['EXCISE'],*/
        category: item.category,
        brand: item.brand,
        sku_code: item.sku_code,
        sku_name: item.sku_name,
        sku_seq: item.sku_seq,
        description: item.description,
        uom: item.uom,
        ptd: item.ptd,
        ptr: item.ptr,
        priority: item.priority,
        gst: item.gst,
        vat: item.vat,
        cess: item.cess,
        excise: item.excise,
      };
      this.productData.push(obj);
    }
    //this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
  }
}
