import {Component, OnInit, ElementRef, Renderer2, ViewChild, ComponentFactoryResolver} from '@angular/core';
import { ApiService} from '../_services/api.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {GridOptions, ChartType} from '@ag-grid-enterprise/all-modules';
import {SuppProdDropdownComponent} from '../components/supp_prod_dropdown/supp-prod-dropdown.component';
import {AdDirective} from '../ad.directive';
import {AdDirective1} from '../ad1.directive';
import {AdDirective2} from '../ad2.directive';
import { SharedService } from '../_services/shared_service';
import {SerialNoRenderer} from '../serialNo.component';
import {BeatDropdownComponent} from '../components/beat_dropdown/beat-dropdown.component';
import {OutletDropdownComponent} from '../components/outlet_dropdown/outlet-dropdown.component';

@Component({
  selector: `app-new-order-page`,
  templateUrl: './neworderpage.component.html',
  styleUrls: ['./neworderpage.component.scss']
})
export class NeworderpageComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective1, {static: true}) adHost1: AdDirective1;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('serialIn') serialIn: ElementRef<HTMLElement>;

  userData = {
    date: null,
    dc_id: 0,
    supplier_id: null,
    pricelist_id: null,
    access_token: null,
    url: null,
    offset: null,
    po_data: null
  };
  resData;
  public myDate;
  public preloader = false;
  public roleFeatures;
  public rowData = [];
  private gridApi;
  private gridColumnApi;
  public context;
  public frameworkComponents;
  public columnDefs = [];
  public data;
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader1 = false;
  public emptyState = false;
  public browserRefresh = false;
  public subscription;
  public enteredQty = 0;
  public discount = 0;
  public extraMargin = 0.00;
  public maxMargin = 0;
  public minMargin = 0;
  public fixedCost = 0;
  public tradeDisc = 0.00;
  public schemeDiscount = 0;
  public po;

  public orderNo = null;
  public beatId = null;
  public customerMail = null;
  public beatName = null;
  public note = null;
  public customerTax = null;
  public prodShow = false;
  public addProd = true;
  public supplierProducts = [];
  public productId = null;
  public productName = null;
  public uomName = null;
  public uomId = null;
  public stock = 0;
  public tax = null;
  public poDate = null;
  public dueDate = null;
  public supplierName  = null;
  public supplierId  = null;
  public dStatus = null;
  public invoiceNo;
  public serno1;
  public count = 1;
  public sernos = {};
  public lineId;
  public beats = [];
  public outlets = [];
  public pricelist = null;
  public pricelistId = 1;
  public unitPrice = 0;
  public salePrice = 0;
  public margin = 0;
  public customerName = null;
  public customerId = null;
  public lineData = [];
  public taxmasterId;
  public orderdetails;


  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private datePipe: DatePipe,
              public router: Router,
              public sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.poDate = this.myDate;
    this.userData.date = this.myDate;
    this.userData.offset = -1 * (new Date(this.userData.date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    this.supplierId = JSON.parse(localStorage.getItem('user')).partner_id;
    this.userData.supplier_id = this.supplierId;
    this.preloader = true;
    if (this.sharedService.new) {
      this.productEntry([], this.sharedService.new, this.sharedService.mark_dispatch,
                       this.sharedService.invoice, this.sharedService.flag);
    } else {
      this.sharedService.flag = 'new';
      this.sharedService.mark_dispatch = false;
      this.sharedService.invoice = false;
      this.orderDetails();
    }

    if (this.sharedService.new) {
      this.get_beats();
    }

    // this.vanreport();

    this.gridOptions = {
      rowHeight: 28,
      headerHeight: 30,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    } as GridOptions;
  }

  get_beats() {
    this.resData = this.apiService.getBeats(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status == 200)) {
          if (res.results.beats) { this.beats = res.results.beats; }
          if (res.results.outlets) { this.outlets = res.results.outlets; }
          this.beatList();
      } else {
        window.alert ('Fetching Beats Failed');
      }
    }, error => {
      window.alert ('Fetching Beats Failed');
    });
  }

  addInput() {
    if (this.sernos[this.lineId]) {
      const serialin = this.serialIn;
      this.sernos[this.lineId].forEach(no => {
        serialin.nativeElement.insertAdjacentHTML('beforeend', '<input type="text" class="form-control" ' +
          'style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" value="' + no + '" />');
      });
    } else {
      this.serialIn.nativeElement.insertAdjacentHTML('beforeend', '<input type="text" class="form-control" ' +
        'style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" />');
    }
  }

  confirmSerial() {

    const elements = this.serialIn.nativeElement.querySelectorAll('input');
    const nos = [];
    elements.forEach(function(elem) {
      nos.push(elem.value);
    });
    this.sernos[this.lineId] = nos;
    elements.forEach(function(elem) {
      elem.remove();
    });
  }

  savePO() {

    const order = {
      order_id: null,
      partner_id: this.customerId,
      distributor_id: this.supplierId,
      pricelist_id: this.pricelistId,
      beat_plan_id: this.beatId,
      date_order: this.userData.date,
      order_type: 'sale',
      name: 'O_' + this.supplierId + '_' + Date.now(),
      line_data: this.lineData,
      dms_order: true
    };
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: [order],
      distributor_id: this.supplierId,
      order_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    };

    this.resData = this.apiService.savemtsales(orderData);

    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
        window.alert('Created Successfully');
        this.sharedService.new = false;
        this.sharedService.order_id = res.results.id;
        this.sharedService.flag = 'same';
        this.orderDetails();
      } else {
        window.alert('Creation failed');
      }
    }, error => {
      console.log(error);
      if (error.error && error.error.error === undefined) {
        window.alert('Creation failed');
      } else {
        window.alert('Creation failed: ' + error.error.error.message);
      }
    });
  }

  dispatch() {
    const order = {
      order_id: this.sharedService.order_id,
      supplier_id: this.supplierId,
      line_data: []
    };
    const lineData = [];
    const orderdetail = this.orderdetails;

    this.orderdetails.sale_items.forEach(line => {
      order.line_data.push({
        line_id: line.id,
        product_id: line.product_id,
        territory_id: orderdetail.territory_id,
        distributor_id: orderdetail.supplier_id,
        quantity: line.delivered_qty,
        valid: false
      });
    });

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order
    };

    this.resData = this.apiService.markDispatch(orderData);

    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
        window.alert('dispatched Successfully');
        this.sharedService.flag = 'same';
        this.sharedService.mark_dispatch = false;
        this.orderDetails();
      } else {
        window.alert('Dispatch failed');
      }
    }, error => {
      console.log(error);
      if (error.error && error.error.error === undefined) {
        window.alert('Dispatch failed');
      } else {
        window.alert('Dispatch failed: ' + error.error.error.message);
      }
    });
  }

  orderDetails() {

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order_id: [this.sharedService.order_id]
    };

    this.resData = this.apiService.getOrderDetail(orderData);

    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        const data = res.results.data[0];
        this.orderdetails = res.results.data[0];
        this.orderNo = this.orderdetails.name;
        this.poDate = this.datePipe.transform(this.orderdetails.schedule_date, 'dd/MM/yyyy');
        this.supplierName = this.orderdetails.supplier;
        this.dStatus = this.orderdetails.d_status;
        this.beatName = this.orderdetails.beat_name;
        this.customerId = this.orderdetails.partner_id;
        this.customerName = this.orderdetails.partner;
        this.customerTax = this.orderdetails.partner_gstn;
        this.customerMail = this.orderdetails.partner_email;
        this.invoiceNo = this.orderdetails.invoice_no;
        let linediscount = 0;
        let spcldiscount = 0;
        this.po = this.orderdetails.po;

        this.orderdetails.sale_items.forEach(line => {
          const subtotal = parseFloat(line.unit_price) * parseFloat(line.qty);
          linediscount += subtotal * (1 - (parseFloat(line.discount) / 100));
          spcldiscount += (parseFloat(line.qty) * parseFloat(line.trade_disc));
        });

        this.orderdetails.line_discount = linediscount.toFixed(this.apiService.decimalPolicy);
        this.orderdetails.spcl_discount = spcldiscount.toFixed(this.apiService.decimalPolicy);

        if (this.orderdetails.d_status !== 'dispatched' && this.orderdetails.d_status !== 'delivered') {
          if (this.orderdetails.invoice_status === 'draft') {
            this.sharedService.invoice = true;
          } else if (this.orderdetails.invoice_status === 'invoiced') {
            this.sharedService.mark_dispatch = true;
          }
        }

        const orderLines = this.orderdetails.sale_items;
        this.productEntry(orderLines, this.sharedService.new, this.sharedService.mark_dispatch, this.sharedService.invoice,
                          this.sharedService.flag);
      }
    }, error => {
        console.log(error);
    });
  }

  saveProduct() {

    if (this.enteredQty > 0) {

      if (parseInt(this.enteredQty.toString(), 10) <= parseInt(this.stock.toString(), 10)) {

        const lineData = [];
        let priceSubtotal = this.salePrice * parseFloat(this.enteredQty.toString());
        priceSubtotal = priceSubtotal * (1 - (this.extraMargin / 100));
        priceSubtotal = priceSubtotal - (parseFloat(this.enteredQty.toString()) * this.tradeDisc);
        const priceTotal = this.unitPrice * parseFloat(this.enteredQty.toString());
        const productLine = {
          product_id: parseInt(this.productId, 10),
          qty: this.enteredQty,
          price_subtotal: priceSubtotal,
          price_unit: this.salePrice,
          new: false,
          product_uom: this.uomId,
          foc: false,
          foc_product_id: null,
          scheme_id: null,
          scheme_discount: 0,
          taxmaster_id: this.taxmasterId,
          fixed_cost: this.fixedCost,
          trade_disc: this.tradeDisc,
          extra_margin: this.extraMargin,
          schemeCode: undefined,
          schemeUsed: undefined
        };
        lineData.push(productLine);
        const order = {
          order_id: null,
          partner_id: this.customerId,
          distributor_id: this.supplierId,
          line_data: lineData
        };

        const orderData = {
          access_token: this.userData.access_token,
          url: this.userData.url,
          orders: [order]
        };

        const allLines = this.lineData;
        let schemediscount = 0;

        this.resData = this.apiService.generateInvoice(orderData);

        this.resData.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
            const freeOrders = res.results.orders;
            freeOrders.forEach(resOrder => {
              // tslint:disable-next-line:only-arrow-functions
              resOrder.lines.forEach(function(schemes) {
                schemes.lines.forEach(schemeLine => {
                  productLine.schemeCode = schemeLine.scheme_code;
                  if (parseFloat(schemeLine.scheme_discount) > 0) {
                    productLine.scheme_discount = schemeLine.scheme_discount;
                    productLine.schemeUsed = true;
                    schemediscount = productLine.scheme_discount;
                  }
                });
                schemes.free.forEach(freeLine => {
                  allLines.push({
                    product_id: freeLine.product_id,
                    qty: freeLine.qty,
                    price_unit: freeLine.price_unit,
                    scheme_code: productLine.schemeCode,
                    scheme_used: true,
                    new: true,
                    foc: false,
                    foc_product_id: null,
                    scheme_id: null,
                    scheme_discount: 0,
                    discount: 0
                  });
                });
              });
            });
            this.schemeDiscount = schemediscount;
            allLines.push(productLine);
            this.lineData = allLines;
            this.rowData.push({
              PRODUCT: this.productName,
              STOCK: this.stock,
              UOM: this.uomName,
              QTY: this.enteredQty,
              PRICE: this.salePrice,
              'DISC%': this.extraMargin,
              'SPCL DIS/qty': this.tradeDisc,
              'FIXED COST': this.fixedCost,
              'SCHEME DIS': this.schemeDiscount,
              TAX: this.tax,
              TOTAL: priceSubtotal,
              product_id: this.productId
            });
            this.gridApi.setRowData(this.rowData);
          }
        }, error => {
          console.log(error);
        });
      } else {
        window.alert('Stock available is ' + this.stock + ' only');
      }
    } else {
      window.alert('Enter Quantity');
    }
  }

  addProduct() {
    this.resData = this.apiService.supplierProducts(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {

        this.supplierProducts = res.results.data[0].products;

        const viewContainerRef2 = this.adHost2.viewContainerRef;
        viewContainerRef2.clear();

        const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

        const componentRef2 = viewContainerRef2.createComponent<SuppProdDropdownComponent>(componentFactory2);
        componentRef2.instance.itemList = this.supplierProducts;
        componentRef2.instance.statusChange.subscribe(selectedOne => {

          if (selectedOne && selectedOne.length > 0) {
            this.productName = selectedOne[0].itemName;
            this.productId = selectedOne[0].id;
            this.uomName = selectedOne[0].uom;
            this.uomId = selectedOne[0].uom_id;
            this.stock = selectedOne[0].stock;
            this.tax = selectedOne[0].tax;
            this.margin = selectedOne[0].margin;
            this.taxmasterId = selectedOne[0].taxmaster_id;
            this.extraMargin = selectedOne[0].extra_margin;
            this.maxMargin = selectedOne[0].max_margin;
            this.minMargin = selectedOne[0].min_margin;
            this.fixedCost = selectedOne[0].fixed_cost;
            this.tradeDisc = 0;
            this.enteredQty = 0;
            if (res[0].tax_include) {
              this.unitPrice = res[0].unit_price;
              this.salePrice = (this.unitPrice * 100) / (100 + parseFloat(this.tax));
            } else {
              this.salePrice = res[0].unit_price;
              this.unitPrice = this.salePrice * ((100 + parseFloat(this.tax)) / 100);
            }
            this.unitPrice = this.unitPrice * (1 - (this.margin / 100));
            this.salePrice = this.salePrice * (1 - (this.margin / 100));
          }
        });
      }
    });
  }

  beatList() {

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BeatDropdownComponent);

    const componentRef = viewContainerRef.createComponent<BeatDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.beats;

    componentRef.instance.selectedItems = [this.beats[this.beats.length - 1]];
    this.beatName = componentRef.instance.selectedItems[0].itemName;
    this.beatId = componentRef.instance.selectedItems[0].id;
    this.supplierName = componentRef.instance.selectedItems[0].supplier;

    this.customerList(this.beatId);

    componentRef.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.beatId = res[0].id;
        this.beatName = res[0].itemName;
        this.customerList(this.beatId);
      } else {
        this.beatId = null;
        this.beatName = null;
      }

    });
  }

  customerList(beatId) {
    let filteredOutlet = [];
    this.outlets.forEach(outlet => {
      if (parseInt(outlet.beat_plan_id, 10) === parseInt(beatId, 10)) {
        filteredOutlet = outlet.outlets;
      }
    });


    const viewContainerRef1 = this.adHost1.viewContainerRef;
    viewContainerRef1.clear();

    const componentFactory1 = this.componentFactoryResolver.resolveComponentFactory(OutletDropdownComponent);

    const componentRef1 = viewContainerRef1.createComponent<OutletDropdownComponent>(componentFactory1);
    componentRef1.instance.itemList = filteredOutlet;

    componentRef1.instance.statusChange.subscribe(res => {

      if (res.length > 0) {
        this.customerId = res[0].id;
        this.customerName = res[0].itemName;
        this.customerMail = res[0].email;
        this.customerTax = res[0].gstn;
        this.pricelist = res[0].pricelist;
        this.pricelistId = res[0].pricelist_id;
        this.userData.pricelist_id = this.pricelistId;
        this.addProduct();
        this.prodShow = true;
      } else {
        this.customerId = null;
        this.customerName = '';
        this.customerMail = '';
        this.customerTax = '';
        this.pricelist = '';
        this.pricelistId = 1;
        this.prodShow = false;
      }
    });

  }

  productEntry(lines, nw, dispatch, inv, flag) {
      this.loaded = false;
      this.preloader = true;
      this.rowData = [];
      if (nw) {
        this.data = {
          PRODUCT: '',
          STOCK: '',
          UOM: '',
          QTY: '',
          SCHEME: '',
          PRICE: '',
          'DISC%': '',
          'SPCL DIS/qty': '',
          'FIXED COST': '',
          'SCHEME DIS': '',
          TAX: '',
          TOTAL: '',
          product_id: '',
        };
      } else {
        const rowData = [];
        if (flag === 'same') { this.columnDefs = []; }
        lines.forEach(line => {
          rowData.push ({
            PRODUCT: line.product_name,
            STOCK: line.stock,
            UOM: line.product_uom,
            QTY: line.qty,
            'INVOICED QTY': line.delivered_qty,
            SCHEME: '',
            PRICE: line.unit_price,
            'DISC%': line.extra_margin,
            'SPCL DIS/qty': line.trade_disc,
            'FIXED COST': line.fixed_cost,
            'SCHEME DIS': line.scheme_discount,
            TAX: line.tax,
            TOTAL: line.amount_total,
            'Serial NO': '../../assets/edit.svg',
            id: line.id,
            product_id: line.product_id,
            tax_amount: line.tax_perc
          });
        });
        this.data = rowData[0];
        this.rowData = rowData;
      }

      for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (key === 'DISC%' || key === 'SPCL DIS/qty') {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,
            editable: false, resizable: true
          });
        } else if (key === 'PRODUCT' ) {
          let edt = false;
          if (nw) { edt = true; }
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,
            editable: false, resizable: true,
            checkboxSelection: edt
          });
        } else if (key == 'INVOICED QTY' ) {
          let edt = false;
          if (inv) { edt = true; }
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,
            editable: edt, resizable: true
          });
        } else if (key === 'product_id' || key === 'id' || key === 'tax_amount') {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, hide: true, unSortIcon: true, lockPosition: true,
            editable: true, resizable: true
          });
        } else if (key === 'Serial NO' ) {
          this.columnDefs.push({
            headerName: key, field: key, lockPosition: true, resizable: true, hide: true,
            cellRenderer: 'serialNoRenderer',
          });
        } else {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, resizable: true
          });
        }
      }
    }
      this.context = {componentParent: this};
      this.frameworkComponents = {
      serialNoRenderer: SerialNoRenderer
    };
      this.loaded = true;
      if (flag === 'same') {
      this.gridApi.setRowData(this.rowData);
      this.preloader = false;
      if (screen.width > 991) {
        this.gridOptions.api.sizeColumnsToFit();
      } else {
        this.gridColumnApi.autoSizeColumns();
      }
    }
  }

  removeProduct() {
    const nodes = this.gridApi.getSelectedNodes();
    const inThis = this;
    nodes.forEach(node => {
      inThis.rowData.splice(node.rowIndex, 1);
      inThis.lineData.splice(node.rowIndex, 1);
    });
    this.gridApi.setRowData(this.rowData);
  }

  enterSerialNo(data) {

     this.lineId = data.id;
     const el: HTMLElement = this.imageClick.nativeElement;
     el.click();
     if (this.sernos[this.lineId]) {
        const elements = this.serialIn.nativeElement.querySelectorAll('input');
        elements.forEach(elem => {
          elem.remove();
        });
        this.addInput();
      }
  }

  receivePO() {
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order: null,
      flag: null
    };
    const order = {
      order_id: this.sharedService.order_id,
      invoice_no: this.invoiceNo,
      partner_id: this.customerId,
      distributor_id: this.supplierId,
      line_data: [],
      po: this.po
    };
    const serialnos = this.sernos;
    let orderFlag = 'same';
    this.rowData.forEach(line => {
      if (parseInt(line['INVOICED QTY'], 10) !== parseInt(line.QTY, 10)) { orderFlag = 'new'; }
      const lineData = {
        order_qty: line.QTY,
        qty: line['INVOICED QTY'],
        product_id: line.product_id,
        unit_price: line.PRICE,
        tax: line.TAX,
        serials: [],
        tax_amount: line.tax_amount
      };
      if (serialnos[line.id]) {
        serialnos[line.id].forEach(serial => {
          lineData.serials.push({
            serial_no: serial
          });
        });
      }
      order.line_data.push(lineData);
    });
    orderData.flag = orderFlag;

    orderData.order = order;

    this.resData = this.apiService.createInvoice(orderData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
        window.alert('Created Successfully');
        this.sharedService.new = false;
        this.sharedService.order_id = res.results.id;
        this.sharedService.flag = 'same';
        this.sharedService.invoice = false;
        this.sharedService.mark_dispatch = false;
        this.orderDetails();
      } else {
        window.alert('Creation failed');
      }
      // this.router.navigate([this.apiService.redirect_url+"iduat.salesdiary.in/order_invoices"]);
    }, error => {
      console.log(error);
      if (error.error && error.error.error === undefined) {
        window.alert('Creation failed');
      } else {
        window.alert('Creation failed: ' + error.error.error.message);
      }
    });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

  }

  ngOnInit() {

  }

  changeDate(dt) {

    this.poDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
  }
}

