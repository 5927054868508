import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../_services/shared_service";
import {StockClickRendererComponent} from "../stockClickRenderer.component";

@Component({
  selector: 'app-stock-log',
  templateUrl: './stock-log.component.html',
  styleUrls: ['./stock-log.component.scss']
})
export class StockLogComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('myDiv1') myDiv1: ElementRef<HTMLElement>;
  user;
  res_data;
  payment_enable = true;
  user_data = {
    "date": null,
    "access_token": null,
    "url": null,
    "last_date": null,
    "offset": null,
    "distributor_id": null,
    "product_id": null
  };

  public modules = AllModules;

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public orderDetails = {};
  public all_orders = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "LOADIN REPORT";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public first_hide = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public  domSanitizer: DomSanitizer,
              private datePipe: DatePipe,
              private sharedService: SharedService) {


    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.distributor_id = this.sharedService.logData.distributor_id;
    this.user_data.product_id = this.sharedService.logData.product_id;


    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.stockLog();

  }

  stockLog() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];
    let total = 0;
    let datePipe = this.datePipe;


    this.res_data = this.apiService.getStockLog(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            var pushData = {
              "DATE": datePipe.transform((new Date(data.stock_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
              "CREATED BY": data.created_by,
              "CREATED DATE": datePipe.transform((new Date(data.create_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
              "TERRITORY": data.territory,
              "PRODUCT": data.product,
              "QUANTITY": data.quantity,
              "TYPE": data.type,
              "COMMENTS": data.comments,
              "BALANCE": data.balance,
              "ORDER": "order",
              "order_id": data.order_id,
              invoice_id: data.invoice_id
            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key == "TYPE") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true,
                  cellRenderer: function(params) {
                      if (params.value == 'd') {
                        params.value = 'deviation';
                      }else if (params.value == 's') {
                        params.value = 'secondary';
                      }else if (params.value == 'p') {
                        params.value = 'primary';
                      }else if (params.value == 'a') {
                        params.value = 'admin';
                      }
                      return params.value;
                  },
                });
              } else if (key == "ORDER") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, filter: true,
                  cellRenderer: "stockClickRenderer",
                });
              } else if (key === 'id' || key === 'order_id' || key === 'invoice_id') {
                this.columnDefs.push({
                  headerName: key, field: key,
                  lockPosition: true, hide: true
                });
              } else {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, lockPosition: true,width:120,
                  resizable: true, enableRowGroup: true, enablePivot: true, aggFunc: 'sum', enableValue: true
                });
              }
            }
          }
          this.loaded = true;
          this.context = { componentParent: this };

          this.frameworkComponents = {
            stockClickRenderer: StockClickRendererComponent
          };

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  goBack() {
    this.router.navigate([this.apiService.redirect_url+"/livestockreport"]);
  }

  goToLogs(value, params) {
    debugger;
    if ( value === 'order') {
      let datePipe = this.datePipe;
      if (params.order_id) {
        let order_data = {
          "access_token": this.user_data.access_token,
          "url": this.user_data.url,
          "order_id": [params.order_id]
        };
        this.res_data = this.apiService.getOrderDetail(order_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
            this.orderDetails = res['results'].data[0];
            if (this.orderDetails['date_delivered'] != null) this.orderDetails['date_delivered'] =
              this.datePipe.transform((new Date(this.orderDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss')
            if (this.orderDetails['pay_items'].length == 0) {
              this.payment_enable = false;
            } else {
              let datePipe = this.datePipe;
              this.orderDetails['pay_items'].forEach(function (pay_item) {
                if (pay_item['payment_type'] === 'Cr Used') {
                  pay_item['payment_type'] = 'Cr Note';
                }else if (pay_item['payment_type'] === 'cash_and_credit') {
                  pay_item['payment_type'] = 'Credit With Payment';
                }
                pay_item['payment_date'] = datePipe.transform((new Date(pay_item['payment_date'] + ' UTC')).toString(),
                  'yyyy-MM-dd HH:mm:ss');
              });
              this.payment_enable = true;
            }
            let el: HTMLElement = this.myDiv.nativeElement;
            el.click();
          }
        },error => {
          console.log(error);
        });
      } else if (params.invoice_id) {
        let order_data = {
          "access_token": this.user_data.access_token,
          "url": this.user_data.url,
          "inv_id": [params.invoice_id]
        };
        this.res_data = this.apiService.getInvoices(order_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.orderDetails = res.results.data[0];
            const inthis = this;
            this.orderDetails['invoice_date'] = this.datePipe.transform(this.orderDetails['inv_date'], 'yyyy-MM-dd HH:mm:ss');
            this.payment_enable = false;
            if (this.orderDetails['type'] === 'out_invoice') {
              this.orderDetails['type'] = 'Invoice';
            } else if (this.orderDetails['type'] === 'out_refund') {
              this.orderDetails['type'] = 'Refund';
            }
            // if (this.orderDetails['pay_items'].length == 0) {
            //   this.payment_enable = false;
            // } else {
            //   this.orderDetails['pay_items'].forEach(function (pay_item) {
            //     if (pay_item['payment_type'] === 'Cr Used') {
            //       pay_item['payment_type'] = 'Cr Note';
            //     }else if (pay_item['payment_type'] === 'cash_and_credit') {
            //       pay_item['payment_type'] = 'Credit With Payment';
            //     }
            //     pay_item['payment_date'] = datePipe.transform((new Date(pay_item['payment_date'] + ' UTC')).toString(),
            //       'yyyy-MM-dd HH:mm:ss');
            //   });
            //   this.payment_enable = true;
            // }
            let el: HTMLElement = this.myDiv1.nativeElement;
            el.click();
          }
        });
      }
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function () {
      setTimeout(function () {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    var oneDay = 24 * 60 * 60 * 1000;
    var firstDate = this.myDate[0];
    var secondDate = this.myDate[1];
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
   /* if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.stockLog();
    }*/
    this.stockLog();
  }
}
