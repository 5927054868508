import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-retailer-supplier-vertical-map-components',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RetailerSupVerticalComponent extends ReportBase implements IReport, OnInit {

 /* showCustomFilter = false;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };*/

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Retailer Supplier Vertical Mapping');
    this.showDate = false;

    this.multiFilter.value = this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(10000, 'csrid');
    this.showProgressCustomText = true;
   /* this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];*/
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSupMappingReport';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 60},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 50},
      {headerName: 'ID', field: 'r_id', enableRowGroup: true, width: 60},
      {headerName: 'CODE', field: 'r_code', enableRowGroup: true, width: 50},
      {headerName: 'NAME', field: 'r_name', width: 80},
      {headerName: 'MOBILE', field: 'mobile', width: 80},
      {headerName: 'TYPE', field: 'r_type', width: 60},
      {headerName: 'PROGRAM', field: 'program', width: 60},
      {headerName: 'CLASS', field: 'class', width: 50},
      {headerName: 'PAN', field: 'pan', width: 80},
      {
        headerName: 'GST DETAILS',
        children: [
          {headerName: 'GST', field: 'gst', width: 80},
          {headerName: 'LEGAL NAME', field: 'legal_name', width: 80},
          {headerName: 'REGISTERED DATE', field: 'registered_date', width: 80},
        ]
      },
      {headerName: 'POTENTIAL', field: 'potential', width: 80},
      {headerName: 'CITY', field: 'rp_city', width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'PINCODE', field: 'rp_zip', width: 60},
      {headerName: 'ACTIVE', field: 'r_status', width: 80},
      {headerName: 'CREATED BY', field: 'created_by', hide: true, width: 80},
      {headerName: 'LEAD ID', field: 'lead_id', width: 50},
      {headerName: 'LEAD', field: 'lead', width: 80},
      {headerName: 'CREATE DATE', field: 'create_date', hide: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }},
      {headerName: 'SCODE', field: 'sup_code', enableRowGroup: true, width: 80},
      {headerName: 'SUPPLIER', field: 'sup', enableRowGroup: true, width: 80},
      {headerName: 'VERTICAL', field: 'sup_vertical', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, width: 80},
      {headerName: 'TTY NAME', field: 'ter_name', enableRowGroup: true, width: 80, valueGetter: inThis.TerritoryNameValueGetter()},
      {headerName: 'TTY CODE', field: 'ter_code', enableRowGroup: true, width: 80, valueGetter: inThis.TerritoryCodeValueGetter()},
      {
        headerName: 'BEAT 1',
        children: [
          {
            headerName: 'ID', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 'bid')
          },
          {headerName: 'NAME', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 'b')},
          {headerName: 'TCODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 'tc')},
          {headerName: 'TERRITORY', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 't')},
          {headerName: 'EMP CODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 'ec')},
          {headerName: 'SALESMAN', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 's')},
          {headerName: 'ROLE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(1, 'rr')},
        ]
      },
      {
        headerName: 'BEAT 2',
        children: [
          {
            headerName: 'ID', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 'bid')
          },
          {headerName: 'NAME', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 'b')},
          {headerName: 'TCODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 'tc')},
          {headerName: 'TERRITORY', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 't')},
          {headerName: 'EMP CODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 'ec')},
          {headerName: 'SALESMAN', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 's')},
          {headerName: 'ROLE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(2, 'rr')},
        ]
      },
      {
        headerName: 'BEAT 3',
        children: [
          {
            headerName: 'ID', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 'bid')
          },
          {headerName: 'NAME', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 'b')},
          {headerName: 'TCODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 'tc')},
          {headerName: 'TERRITORY', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 't')},
          {headerName: 'EMP CODE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 'ec')},
          {headerName: 'SALESMAN', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 's')},
          {headerName: 'ROLE', field: 'sup', enableRowGroup: true, width: 80,
            valueGetter: inThis.beatValueGetter(3, 'rr')},
        ]
      }
    ];

  }

  TerritoryNameValueGetter() {
    return (params) => {
      if (params.data) {
        if (params.data.vertical_json && params.data.salesman) {
          const vertical = params.data.vertical_json.filter(existed =>
            existed.s !== null && existed.s !== undefined &&
            existed.s === params.data.salesman);
          if (vertical !== null && vertical !== undefined && vertical.length > 0) {
            return vertical[0]['t'];
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  }

  TerritoryCodeValueGetter() {
    return (params) => {
      if (params.data) {
        if (params.data.vertical_json && params.data.salesman) {
          const vertical = params.data.vertical_json.filter(existed =>
            existed.s !== null && existed.s !== undefined &&
            existed.s === params.data.salesman);
          if (vertical !== null && vertical !== undefined && vertical.length > 0) {
            return vertical[0]['tc'];
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  }

  beatValueGetter(position: number, field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.vertical_json) {
          const vertical = params.data.vertical_json.filter(existed =>
            existed.v !== null && existed.v !== undefined &&
            existed.v.includes(params.data.ver_id));
          if (vertical !== null && vertical !== undefined && vertical.length >= position) {
            return vertical[position - 1][field];
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  }

}
