import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";
import {ErrorObject} from "../../../error/error-model";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {CustomGridToolPanelComponent} from "../../../reports/_common/CustomGridToolPanel.component";
import {ErrorHandler} from "../../../error/error-handler";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../../components/image_popup/image_popup.component";
import {
  PaymentCollectPopupComponent
} from "../../../dmsv2/secondary/payments/payment_collect_popup/payment_collect_popup.component";
import {CreditNotePopupComponent} from "../../../dmsv2/secondary/credit_notes/credit_note_popup/credit_popup.component";

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer_details.component.html',
  styleUrls: ['./customer_details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  apiData: any = [];
  attributeList: any = [];
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  showCustomFilter = false;
  showCreateCreditNote = false;
  showCollectPayment = false;
  customFilter: any = {};
  periodID: 0;
  showDate = true;
  dateFilterType: any = 'range';
  title = '';

  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any;
  context: any;
  rowData = [];

  public gridColumnApi1: any;
  public gridApi1: any;
  public pinnedParams1 = [];
  preloader1: any;
  emptyState1: any;
  errorObject1: ErrorObject;
  errorExists1 = false;
  dataLoaded1: any;
  gridOptions1: GridOptions;
  styleGrid1: any;
  columnDefs1 = [];
  frameworkComponents1: any;
  context1: any;
  rowData1 = [];

  public gridColumnApi2: any;
  public gridApi2: any;
  public pinnedParams2 = [];
  preloader2: any;
  emptyState2: any;
  errorObject2: ErrorObject;
  errorExists2 = false;
  dataLoaded2: any;
  gridOptions2: GridOptions;
  styleGrid2: any;
  columnDefs2 = [];
  frameworkComponents2: any;
  context2: any;
  rowData2 = [];

  public gridColumnApi3: any;
  public gridApi3: any;
  public pinnedParams3 = [];
  preloader3: any;
  emptyState3: any;
  errorObject3: ErrorObject;
  errorExists3 = false;
  dataLoaded3: any;
  gridOptions3: GridOptions;
  styleGrid3: any;
  columnDefs3 = [];
  frameworkComponents3: any;
  context3: any;
  rowData3 = [];

  public gridColumnApiAddr: any;
  public gridApiAddr: any;
  preloaderAddr: any;
  emptyStateAddr: any;
  errorObjectAddr: ErrorObject;
  errorExistsAddr = false;
  dataLoadedAddr: any;
  gridOptionsAddr: GridOptions;
  styleGridAddr: any;
  columnDefsAddr = [];
  frameworkComponentsAddr: any;
  contextAddr: any;
  rowDataAddr = [];

  public gridColumnApiBeat: any;
  public gridApiBeat: any;
  preloaderBeat: any;
  emptyStateBeat: any;
  errorObjectBeat: ErrorObject;
  errorExistsBeat = false;
  dataLoadedBeat: any;
  gridOptionsBeat: GridOptions;
  styleGridBeat: any;
  columnDefsBeat = [];
  frameworkComponentsBeat: any;
  contextBeat: any;
  rowDataBeat = [];

  public gridColumnApiScheme: any;
  public gridApiScheme: any;

  public gridColumnApiCreditNote: any;
  public gridApiCreditNote: any;

  preloaderCreditNote: any;
  preloaderScheme: any;
  emptyStateScheme: any;
  emptyStateCreditNote: any;

  errorObjectScheme: ErrorObject;
  errorExistsScheme = false;
  dataLoadedScheme: any;
  dataLoadedCreditNote: any;
  gridOptionsScheme: GridOptions;
  gridOptionsCreditNote: GridOptions;

  styleGridScheme: any;
  columnDefsScheme = [];
  columnDefsCreditNote = [];
  frameworkComponentsScheme: any;
  contextScheme: any;
  rowDataScheme = [];
  rowDataCreditNote = [];

  myDate: any;
  searchValue: any;

  filterData: any;

  API_URL = '';

  dateSelectMode = 'range';

  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = false;
  showProgressBar = false;
  progressMode = 'indeterminate';
  showProgressCustomText = false;
  progressTotal = 0;
  keyColumn = '';

  uploadTask = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  public order_data = [];
  succcessCount = 0;
  failedCount = 0;
  uploadexcel;
  public orderDetails = {'label_config': {}};
  public all_orders = [];
  public payment_enable: boolean;
  goClicked = false;

  user_report_config = [];
  role_report_config = [];
  customerData = [];
  customerDetails: any = {};
  rowIndex = 0;
  activetab = 'Overview';
  zoom: number;
  map: any;
  latitude: number;
  longitude: number;
  edit = false;
  attributes: any = [];
  attributeEdit = false;
  params: any = [];
  paramEdit = false;
  changeRequestData = [];
  masterGalleryData = [];
  prodRecommendationData = [];
  galleryphotodata = null;
  galleryphotodatas = [];
  mainGalleryData = [];
  shelfGalleryData = [];
  btlGalleryData = [];
  categoryChartData = [];
  private user_report_json: unknown;

  view1: any[] = [400, 170];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  categoryChartTitle = 'Category Projection';
  colorScheme = {
    domain: ['#F3622D', '#57B757']
  };
  salesDataLoaded = false;
  beatData = [];
  schemeData = [];
  creditNotesData = [];
  logData = [];
  image;
  dms = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.customerData = JSON.parse(localStorage.getItem('customerdata'));
    this.rowIndex = parseInt(localStorage.getItem('customerindex'), 0);
    this.userData.data.id = this.customerData[this.rowIndex];
    this.zoom = 15;

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userData.data.date = this.myDate;

    const that = this;
    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptions1 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents1 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptions2 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      }
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents2 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptions3 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      }
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents3 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptionsAddr = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponentsAddr = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptionsBeat = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponentsBeat = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptionsScheme = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      }
    } as GridOptions;

    this.gridOptionsCreditNote = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#C4C4C4 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderblue',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      }
    } as GridOptions;

    if (localStorage.getItem('all_attributes') !== null && localStorage.getItem('all_attributes') !== 'null' && JSON.parse(localStorage.getItem('all_attributes')) !== null) {
      let attrlist = JSON.parse(localStorage.getItem('all_attributes'));
      if (attrlist !== null) {
        attrlist = attrlist.filter(attr => attr.type === 'retailer').map(obj => obj.name);
        if (attrlist !== null) {
          this.attributeList = attrlist;
        }
      }
    }
    if (localStorage.getItem('module_dmsv2') || localStorage.getItem('dms_module')) {
      this.dms = true;
    } else {
      this.dms = false;
    }

    this.sharedService.filterSetEvent
      .subscribe((set: boolean) => {
        if (set) {
          this.filterSet = true;
        } else {
          this.filterSet = false;
        }
      });

    /*this.gridOptions.doesExternalFilterPass = rowNode => {
      if (that.attributeEdit) {
        return rowNode.data.name === 'aggridedit';
      } else {
        return rowNode.data.name !== 'aggridedit';
      }
    };*/
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Retailer Details");
    this.API_URL = '/api/res_partners/get_partner_details';

    this.showCollectPayment = this.sharedService.ACLcheck('feature_collect_payment');
    this.showCreateCreditNote = this.sharedService.ACLcheck('feature_create_credit_note');

    this.configureAttributeGrid();
    this.configureParamGrid();
    this.configureCRGrid();
    this.configureAddressGrid();
    this.configureBeatGrid();
    this.configureProductRecommendationGrid();
    this.configureSchemeGrid();
    this.configureCreditNote();
    this.getCustomerDetails();
    this.getSalesData();
  }

  fetchCustomerSupplierBalance() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      retailer_ids: [this.userData.data.id]
    };
    this.apiService.post('/api/res_partners/get_customer_supplier_balance', paramObject)
      .subscribe(res1 => {
        console.log(res1);
        // t.stop();
        if (res1.hasOwnProperty('results') && (res1.results.status === 200) && res1.results.data && res1.results.data.length > 0) {
          this.customerDetails.outstanding = res1.results.data[0].balance;
          this.customerDetails.credits = res1.results.data[0].credit;
        }
      });
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  configureAttributeGrid() {
    this.columnDefs = [
      {
        headerName: 'ATTRIBUTE', field: 'name', menuTabs: [], width: 60,
        editable: false,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: this.attributeList
        },
      },
      {
        headerName: 'ATTRIBUTE VALUE', field: 'value', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.attributeEdit;
        }
      },
      {
        headerName: "",
        headerClass: 'myagheader',
        field: "delete",
        cellStyle: {textAlign: 'right'},
        width: 20,
        resizable: true,
        cellRenderer(params) {
          if (params.data && params.context.attributeEdit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }
      },
    ];
  }

  configureParamGrid() {
    this.columnDefs1 = [
      {
        headerName: 'NAME', field: 'n', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: 'VALUE', field: 'v', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: 'CATEGORY', field: 'c', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: 'TYPE', field: 't', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: "",
        headerClass: 'myagheader',
        field: "delete",
        cellStyle: {textAlign: 'right'},
        width: 20,
        resizable: true,
        cellRenderer(params) {
          if (params.data && params.context.paramEdit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }
      },
    ];
  }

  configureCRGrid() {
    const inThis = this;
    this.columnDefs2 = [
      {
        headerName: 'Request Type', field: 'request_type',
        width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            /*if (params.data.request_type === 'contact_details') {
              return 'Contact';
            } else if (params.data.request_type === 'location_with_address' ||) {
              return 'Location With Add';
            } else if (params.data.request_type === 'program_type') {
              return 'Program Type';
            } else {
              return params.data.request_type;
            }*/

            if (params.data.request_type != null) {
              /* let str = params.data.request_type.replace(/_/g, ' ');*/
              const str = params.data.request_type;
              return str
                .replace(/_/g, ' ')
                .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
              /*.replace(/\s(.)/g, $1 => $1.toUpperCase())
              .replace(/\s/g, '')
              .replace(/^(.)/, $1 => $1.toLowerCase());*/
            }
          }
        }
      },
      {
        headerName: 'Request Date', field: 'req_date',
        width: 110,
        valueGetter: params => {
          if (params.data) {
            return inThis.datePipe.transform((new Date(params.data.req_date + ' UTC')).toString(), 'dd/MM/yyyy');
          }
        }
      },
      {
        headerName: 'Requested By', field: 'req_by',
        width: 150, enableRowGroup: true
      },
      {
        headerName: 'Old Value', field: 'old_val',
        maxWidth: 200,
        valueGetter: inThis.oldValueGetter
      },
      {
        headerName: 'New Value', field: 'new_val', wrapText: true,
        maxWidth: 200,
        valueGetter: inThis.newValueGetter
      },
      {
        headerName: 'Approved By', field: 'app_by', width: 100
      },
      {
        headerName: 'Approved Date', field: 'app_date', width: 110,
        valueGetter: params => {
          if (params.data && params.data.app_date) {
            return inThis.datePipe.transform((new Date(params.data.app_date + ' UTC')).toString(), 'dd/MM/yyyy');
          }
        }
      },
      {
        headerName: 'Status', field: 'status',
        width: 100, enableRowGroup: true,
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'accepted') {
              displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            } else if (params.value === 'rejected') {
              displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            } else {
              displayElem = '<span style="color:#17A2B8; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            }
          }
          return displayElem;
        },
      },
    ];
  }

  configureAddressGrid() {
    this.columnDefsAddr = [
      {
        headerName: 'Type', field: 'type', width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.type === 'delivery') {
              return 'Shipping';
            } else {
              return 'Invoice';
            }
          }
        }
      },
      {headerName: 'Address', field: 'address', width: 200},
    ];
  }

  configureBeatGrid() {
    this.columnDefsBeat = [
      {headerName: 'Beat', field: 'name', width: 100},
      {headerName: 'Salesman', field: 'user_name', width: 100},
      {headerName: 'Territory', field: 'territory', width: 100},
    ];
  }

  configureProductRecommendationGrid() {
    this.columnDefs3 = [
      {
        headerName: '',
        children: [
          {headerName: 'PCODE', field: 'p_code', width: 80},
          {headerName: 'PRODUCT', field: 'p_name', width: 150},
          {headerName: 'VERTICAL', field: 'vertical', width: 150},
          {headerName: 'FAMILY CODE', field: 'family_code', width: 80},
          {headerName: 'FAMILY', field: 'family_name', width: 80},
          {headerName: 'BRAND', field: 'brand', width: 80},
          {headerName: 'NAME', field: 'name', width: 80},
          {headerName: 'TYPE', field: 'type', width: 80},
        ]
      },
      {
        headerName: 'CONFIG',
        children: [
          {
            headerName: 'SUGG', field: 'sugg', width: 80,
            valueGetter: this.configAllValueGetter('sugg')
          },
          {
            headerName: 'UMIN', field: 'umin', width: 80,
            valueGetter: this.configAllValueGetter('umin')
          },
          {
            headerName: 'UMAX', field: 'umax', width: 80,
            valueGetter: this.configAllValueGetter('umax')
          },
          {
            headerName: 'LMIN', field: 'lmin', width: 80,
            valueGetter: this.configAllValueGetter('lmin')
          },
          {
            headerName: 'LMAX', field: 'lmax', width: 80,
            valueGetter: this.configAllValueGetter('lmax')
          },
          {
            headerName: 'EXPECTED CLOSING', field: 'lmax', width: 80,
            valueGetter: this.configValueGetter('expected_closing')
          },
        ]
      },
    ];
  }

  configureSchemeGrid() {
    const inThis = this;
    this.columnDefsScheme = [
      {headerName: 'Name', field: 'name', width: 200},
      {
        headerName: 'Scheme Type', field: 'scheme_type', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.scheme_type === 'qps') {
              return 'Product Scheme';
            } else if (params.data.scheme_type === 'vps') {
              return 'Bill Scheme';
            } else {
              return params.data.scheme_type.toString().toUpperCase();
            }
          }
        },
      },
      {headerName: 'Reward Type', field: 'reward_type', width: 100},
      {
        headerName: 'Start Date', field: 'start_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_date) {
              return inThis.datePipe.transform((new Date(params.data.start_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.start_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'End Date', field: 'end_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_date) {
              return inThis.datePipe.transform((new Date(params.data.end_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.end_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'Type', field: 's_type', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.s_type === 'S') {
              return 'Secondary';
            } else if (params.data.s_type === 'P') {
              return 'Primary';
            } else {
              return params.data.s_type.toString().toUpperCase();
            }
          }
        }
      }
    ];
  }

  configureCreditNote() {
    const inThis = this;
    this.columnDefsCreditNote = [
      {headerName: 'Name', field: 'name', width: 200},
      {headerName: 'Amount', field: 'amount', width: 200},
      {headerName: 'Used', field: 'used', width: 200},
    ];
  }


  configValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config) {
          if (params.data.config[field]) {
            return params.data.config[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  configAllValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config && params.data.config.hasOwnProperty('all')) {
          if (params.data.config.all[field]) {
            return params.data.config.all[field];
          } else {
            return '';
          }
        }
      } else {
        return '';
      }
    };
  }

  getCustomerDetails() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.attributes = [];
    this.params = [];
    this.image = null;

    this.dataLoadedAddr = false;
    this.preloaderAddr = true;
    this.emptyStateAddr = false;

    this.dataLoadedBeat = false;
    this.preloaderBeat = true;
    this.emptyStateBeat = false;

    this.dataLoadedScheme = false;
    this.preloaderScheme = true;
    this.emptyStateScheme = false;

    this.dataLoadedCreditNote = false;
    this.preloaderCreditNote = true;
    this.emptyStateCreditNote = false;


    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }


    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data; //TODO: check if this line is needed
            this.customerDetails = res.results.data.master;
            if (this.dms) {
              this.fetchCustomerSupplierBalance();
            }
            this.changeRequestData = res.results.data.cr;
            this.prodRecommendationData = res.results.data.prod_reco;
            this.masterGalleryData = res.results.data.gallery;
            this.beatData = res.results.data.beats;
            this.schemeData = res.results.data.schemes;
            this.rowDataCreditNote = res.results.data.old_credit_notes;
            this.logData = res.results.data.logs;
            if (this.customerDetails.payment_mode !== null && this.customerDetails.payment_mode !== undefined && this.customerDetails.payment_mode !== "") {
              this.customerDetails.payment_mode = this.titleCase(this.customerDetails.payment_mode.toString().toLowerCase().replace(/_/g, " "));
            }
            this.latitude = parseFloat(this.customerDetails.lat);
            this.longitude = parseFloat(this.customerDetails.long);

            if ((this.customerDetails.attributes !== null)) {
              const attr = this.customerDetails.attributes;
              for (const i in attr) {
                this.attributes.push({name: i, value: attr [i]});
              }
            }
            this.rowData = this.attributes;
            this.dataLoaded = true;
            //this.rowData should be ready now
            this.preloader = false;
            if (this.rowData === null || this.rowData === undefined) {
              this.emptyState = true;
            } else if (this.rowData.length === 0) {
              this.emptyState = true;
            } else {
            }
            /*this.fetchImage('c49/c491325e5f1bf758fc84dbb069a28c6e18249ef393bb404ef999c8996e5bed4c');*/
            if (this.customerDetails.hasOwnProperty('image_path') && this.customerDetails.image_path && this.customerDetails.image_path !== "") {
              this.fetchImage(this.customerDetails.image_path);
            }

            /*if ((this.customerDetails.params !== null)) {
              const param = this.customerDetails.params;
              for (const i in param) {
                this.params.push({name: i, value: param [i]});
              }
            }*/
            if (this.customerDetails.params !== null) {
              for (const item of this.customerDetails.params) {
                if (item.t === 'list') {
                  /* for (const listitem of item.v) {
                     const inditem: any = {};
                     inditem.n = item.n;
                     inditem.t = item.t;
                     inditem.v = JSON.stringify(listitem);
                     this.rowData1.push(inditem);
                   }*/
                  const inditem: any = {};
                  inditem.n = item.n;
                  inditem.t = item.t;
                  inditem.v = JSON.stringify(item.v);
                  this.rowData1.push(inditem);
                } else {
                  this.rowData1.push(item);
                }
              }
              //this.rowData1 = this.customerDetails.params;
            }

            this.dataLoaded1 = true;
            //this.rowData should be ready now
            this.preloader1 = false;
            if (this.rowData1 === null || this.rowData1 === undefined) {
              this.emptyState1 = true;
            } else if (this.rowData1.length === 0) {
              this.emptyState1 = true;
            } else {
            }

            this.rowData2 = this.changeRequestData;
            this.dataLoaded2 = true;
            //this.rowData should be ready now
            this.preloader2 = false;
            if (this.rowData2 === null || this.rowData2 === undefined) {
              this.emptyState2 = true;
            } else if (this.rowData2.length === 0) {
              this.emptyState2 = true;
            } else {
            }

            this.rowData3 = this.prodRecommendationData;
            this.dataLoaded3 = true;
            //this.rowData should be ready now
            this.preloader3 = false;
            if (this.rowData3 === null || this.rowData3 === undefined) {
              this.emptyState3 = true;
            } else if (this.rowData3.length === 0) {
              this.emptyState3 = true;
            } else {
            }

            this.rowDataAddr = res.results.data.addr;
            this.dataLoadedAddr = true;
            //this.rowData should be ready now
            this.preloaderAddr = false;
            if (this.rowDataAddr === null || this.rowDataAddr === undefined) {
              this.emptyStateAddr = true;
            } else if (this.rowDataAddr.length === 0) {
              this.emptyStateAddr = true;
            } else {
            }

            this.rowDataBeat = this.beatData;
            this.dataLoadedBeat = true;
            //this.rowData should be ready now
            this.preloaderBeat = false;
            if (this.rowDataBeat === null || this.rowDataBeat === undefined) {
              this.emptyStateBeat = true;
            } else if (this.rowDataBeat.length === 0) {
              this.emptyStateBeat = true;
            } else {
            }

            this.rowDataScheme = this.schemeData;
            this.dataLoadedScheme = true;
            //this.rowData should be ready now
            this.preloaderScheme = false;
            if (this.rowDataScheme === null || this.rowDataScheme === undefined) {
              this.emptyStateScheme = true;
            } else if (this.rowDataScheme.length === 0) {
              this.emptyStateScheme = true;
            } else {
            }

            // this.rowDataCreditNote = this.creditNotesData;
            this.dataLoadedCreditNote = true;
            //this.rowData should be ready now
            this.preloaderCreditNote = false;
            if (this.rowDataCreditNote === null || this.rowDataCreditNote === undefined) {
              this.emptyStateCreditNote = true;
            } else if (this.rowDataCreditNote.length === 0) {
              this.emptyStateCreditNote = true;
            }

            this.getGalleryImages();

          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.emptyState1 = true;
            this.emptyState2 = true;
            this.emptyState3 = true;
            this.emptyStateAddr = true;
            this.emptyStateBeat = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            this.emptyState1 = true;
            this.emptyState2 = true;
            this.emptyState3 = true;
            this.emptyStateAddr = true;
            this.emptyStateBeat = true;
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  getSalesData() {
    this.salesDataLoaded = false;
    this.apiService.post('/api/res_partners/get_retailer_monthly_sales', this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.constructSalesChart(res.results.data);
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  constructSalesChart(data) {
    data.forEach(item => {
      var categoryData: any = {};
      categoryData.name = item.category;
      categoryData.series = [];
      categoryData.series.push(
        {name: 'lmtd_sale_achv', value: item.lmtd_sale_achv},
        {name: 'mtd_sale_achv', value: item.mtd_sale_achv},
      );
      this.categoryChartData.push(categoryData);
    });
    this.salesDataLoaded = true;
  }

  async fetchImage(store_fname) {
    const apiurl = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = store_fname;
    paramObject.container = 'res_partner';
    console.log(paramObject);
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data') && res['result'].data && res['result'].data.length > 0) {
            this.image = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {
            this.image = null;
          }
        },
        error => {
          console.log(error);

        });
  }

  async getGalleryImages() {
    let images = [];
    if (this.masterGalleryData.length > 0) {
      this.masterGalleryData.forEach(image => {
        if (image != null) {
          images.push({store_fname: image.store_fname, container: 'res_partner_gallery', type: image.type});
        }
      });
      if (images.length > 0) {
        const res = await this.apiService.fetchExpenseImage({
          access_token: this.userData.access_token,
          url: this.userData.url,
          data: images
        });
        if (res.hasOwnProperty('result') && res['result'].length > 0) {
          console.log(res);
          this.galleryphotodata = [];
          const domSanitizer = this.domSanitizer;
          res['result'].forEach(photo => {
            this.galleryphotodata.push({
              photo: domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas),
              type: photo.type
            });
          });
          this.mainGalleryData = this.galleryphotodata;
          this.shelfGalleryData = this.galleryphotodata.filter(photo => photo.type === 'Shelf');
          this.btlGalleryData = this.galleryphotodata.filter(photo => photo.type === 'BTL');
        }
      }
    }
  }

  titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g,
      (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, ' ');
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
    if (this.activetab === 'Sales') {
      this.getSalesData();
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          params.columnApi.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid5');

    // this.get_favorites();
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    const noOfColumns = this.gridColumnApi1.getAllColumns().length;

    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams1.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions1, inThis.pinnedParams1);
          }
        } else {
          params.columnApi1.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions1);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
      if (inThis.pinnedParams1.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions1, inThis.pinnedParams1);
      }
    } else {
      this.gridColumnApi1.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions1);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid5');

    // this.get_favorites();
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();

    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    const noOfColumns = this.gridColumnApi2.getAllColumns().length;

    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams2.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions2, inThis.pinnedParams2);
          }
        } else {
          params.columnApi1.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions2);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
      if (inThis.pinnedParams2.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions2, inThis.pinnedParams2);
      }
    } else {
      this.gridColumnApi2.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions2);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid5');

    // this.get_favorites();
  }

  onGridReady3(params) {
    this.gridOptions3.api.showLoadingOverlay();

    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
    const noOfColumns = this.gridColumnApi3.getAllColumns().length;

    params.api.setRowData(this.rowData3);
    this.preloader3 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams3.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions3, inThis.pinnedParams3);
          }
        } else {
          params.columnApi1.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions3);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi3.sizeColumnsToFit();
      if (inThis.pinnedParams3.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions3, inThis.pinnedParams3);
      }
    } else {
      this.gridColumnApi3.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions3);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid6');

    // this.get_favorites();
  }


  onGridReadyAddr(params) {
    this.gridOptionsAddr.api.showLoadingOverlay();

    this.gridApiAddr = params.api;
    this.gridColumnApiAddr = params.columnApi;
    const noOfColumns = this.gridColumnApiAddr.getAllColumns().length;

    params.api.setRowData(this.rowDataAddr);
    this.preloaderAddr = false;
    const inThis = this;
    this.gridApiAddr.setDomLayout("autoHeight");
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApiAddr.sizeColumnsToFit();
    } else {
      this.gridColumnApiAddr.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid4');

    // this.get_favorites();
  }

  onGridReadyBeat(params) {
    this.gridOptionsBeat.api.showLoadingOverlay();

    this.gridApiBeat = params.api;
    this.gridColumnApiBeat = params.columnApi;
    const noOfColumns = this.gridColumnApiBeat.getAllColumns().length;

    params.api.setRowData(this.rowDataBeat);
    this.preloaderBeat = false;
    const inThis = this;
    this.gridApiBeat.setDomLayout("autoHeight");
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApiBeat.sizeColumnsToFit();
    } else {
      this.gridColumnApiBeat.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid5');

    // this.get_favorites();
  }

  onGridReadyScheme(params) {
    this.gridOptionsScheme.api.showLoadingOverlay();

    this.gridApiScheme = params.api;
    this.gridColumnApiScheme = params.columnApi;
    const noOfColumns = this.gridColumnApiScheme.getAllColumns().length;

    params.api.setRowData(this.rowDataScheme);
    this.preloaderScheme = false;
    const inThis = this;
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApiScheme.sizeColumnsToFit();
    } else {
      this.gridColumnApiScheme.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid7');

    // this.get_favorites();
  }

  onGridReadyCreditNote(params) {
    this.gridOptionsCreditNote.api.showLoadingOverlay();

    this.gridApiCreditNote = params.api;
    this.gridColumnApiCreditNote = params.columnApi;
    const noOfColumns = this.gridColumnApiCreditNote.getAllColumns().length;

    params.api.setRowData(this.rowDataCreditNote);
    this.preloaderCreditNote = false;
    const inThis = this;
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApiCreditNote.sizeColumnsToFit();
    } else {
      this.gridColumnApiCreditNote.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid8');

    // this.get_favorites();
  }

  updateCustomer(userData) {
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res2 => {
          console.log(res2);
          if (res2.hasOwnProperty('results') && (res2.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.getCustomerDetails();
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Error updating  Customer');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Approval Request');
        });
  }


  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.getCustomerDetails();
  }

  attributeEditClick() {
    this.attributeEdit = !this.attributeEdit;
    if (this.attributeEdit) {
      this.gridApi.setRowData(this.rowData);
      this.gridColumnApi.getColumn('name').getColDef().editable = true;
    } else {
      this.gridColumnApi.getColumn('name').getColDef().editable = false;
      this.gridApi.stopEditing();
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      const objectData: any = {};
      for (const i in this.rowData) {
        objectData[this.rowData[i]['name']] = this.rowData[i]['value'];
      }
      paramObject.data = {
        partner_id: this.customerDetails.id,
        attribute_json: objectData
      };
      this.updateCustomer(paramObject);
    }
  }

  paramEditClick() {
    this.paramEdit = !this.paramEdit;
    if (this.paramEdit) {
      this.gridApi1.setRowData(this.rowData1);
    } else {
      this.gridApi1.stopEditing();
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      /* const objectData: any = {};
       for (const i in this.rowData1) {
         objectData[this.rowData1[i]['name']] = this.rowData1[i]['value'];
       }*/
      paramObject.data = {
        partner_id: this.customerDetails.id,
        param_json: JSON.stringify(this.rowData1)
      };
      this.updateCustomer(paramObject);
    }
  }

  addAttributeRow() {
    if (this.attributeList !== null && this.attributeList.length > 0) {
      const indrow = {
        name: this.attributeList[0],
        value: "1"
      };
      this.rowData.splice(0, 0, indrow);
      this.gridApi.setRowData(this.rowData);
    } else {
      this.toastr.error("No Attribute Master records added");
    }
  }

  addParamRow() {
    const indrow = {
      "n": "Name",
      "v": "Value",
      "c": "Category",
      "t": ""
    };
    this.rowData1.splice(0, 0, indrow);
    this.gridApi1.setRowData(this.rowData1);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const rowindex = event.rowIndex;
    if (column === 'delete') {
      this.rowData.splice(rowindex, 1);
      this.gridApi.setRowData(this.rowData);
    }
  }

  onCellClicked1(event) {
    const column = event.column.getColId();
    const rows = this.gridApi1.getSelectedRows();
    const rowindex = event.rowIndex;
    if (column === 'delete') {
      this.rowData1.splice(rowindex, 1);
      this.gridApi1.setRowData(this.rowData1);
    }
  }

  onCellClicked2(event) {
    const column = event.column.getColId();
    const rows = this.gridApi2.getSelectedRows();
    const rowindex = event.rowIndex;
  }

  onCellClicked3(event) {
    const column = event.column.getColId();
    const rows = this.gridApi3.getSelectedRows();
    const rowindex = event.rowIndex;
  }

  onCellClickedAddr(event) {
    const column = event.column.getColId();
    const rows = this.gridApiAddr.getSelectedRows();
    const rowindex = event.rowIndex;
  }

  onCellClickedBeat(event) {
    const column = event.column.getColId();
    const rows = this.gridApiBeat.getSelectedRows();
    const rowindex = event.rowIndex;
  }

  oldValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.old_val !== null && params.data.old_val !== "") {
        try {
          JSON.parse(params.data.old_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.old_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          /* if(jsonValue.hasOwnProperty("area"))
             address = address.concat(jsonValue.area) + ",";
           if(jsonValue.hasOwnProperty("zone"))
             address = address.concat(jsonValue.zone) + ",";
           if(jsonValue.hasOwnProperty("city"))
             address = address.concat(jsonValue.city) + ",";
           if(jsonValue.hasOwnProperty("state"))
             address = address.concat(jsonValue.state) + ",";
           if(jsonValue.hasOwnProperty("zip"))
             address = address.concat(jsonValue.zip) + ",";
           if(jsonValue.hasOwnProperty("country"))
             address = address.concat(jsonValue.country)*/

          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return '';
      }
    }
  }

  formatValue(requestType, requestValue) {
    let isJson = true;
    if (requestValue !== null && requestValue !== "") {
      try {
        JSON.parse(requestValue);
      } catch (e) {
        isJson = false;
      }
    } else {
      isJson = false;
    }
    if (isJson) {
      if (this.isJsonString(requestValue)) {
        const jsonValue = JSON.parse(requestValue);
        if (requestType === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          if (jsonValue.hasOwnProperty('area')) {
            address = address.concat(jsonValue.area) + ',';
          }
          if (jsonValue.hasOwnProperty('zone')) {
            address = address.concat(jsonValue.zone) + ',';
          }
          if (jsonValue.hasOwnProperty('city')) {
            address = address.concat(jsonValue.city) + ',';
          }
          if (jsonValue.hasOwnProperty('state')) {
            address = address.concat(jsonValue.state) + ',';
          }
          if (jsonValue.hasOwnProperty('zip')) {
            address = address.concat(jsonValue.zip) + ',';
          }
          if (jsonValue.hasOwnProperty('country')) {
            address = address.concat(jsonValue.country);
          }

          return address;
        } else if (requestType === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return '';
          }
        } else if (requestType === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (requestType === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (requestType === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return requestValue;
      }
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  newValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.new_val !== null && params.data.new_val !== "") {
        try {
          JSON.parse(params.data.new_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.new_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          /*  if (jsonValue.hasOwnProperty("area"))
              address = address.concat(jsonValue.area) + ",";
            if (jsonValue.hasOwnProperty("zone"))
              address = address.concat(jsonValue.zone) + ",";
            if (jsonValue.hasOwnProperty("city"))
              address = address.concat(jsonValue.city) + ",";
            if (jsonValue.hasOwnProperty("state"))
              address = address.concat(jsonValue.state) + ",";
            if (jsonValue.hasOwnProperty("zip"))
              address = address.concat(jsonValue.zip) + ",";
            if (jsonValue.hasOwnProperty("country"))
              address = address.concat(jsonValue.country)*/

          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return params.data.new_val;
      }
    }
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.userData.data.id = this.customerData[this.rowIndex];
      this.getCustomerDetails();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.customerData.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.userData.data.id = this.customerData[this.rowIndex];
      this.getCustomerDetails();
    }
  }

  formatNumber(val) {
    if (val > 999999) {
      return val / 1000000 + 'M';
    } else if (val > 999) {
      return val / 1000 + 'K';
    } else {
      return val;
    }
    // return val + "%"
  }

  changeDate(dt) {
    this.userData.data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.getSalesData();
  }

  showCustomerImage() {
    if (this.image) {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "70%",
          maxHeight: "95%",
          data: {
            photo: this.image
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "90%",
          maxHeight: "65%",
          data: {
            photo: this.image
          }
        };
        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      }
    }
  }

  action_credit_note() {

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "30%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          partner_id: this.userData.data.id,
          cust_obj: this.customerDetails
        }
      };
      const creatediaeref = this.dialog.open(CreditNotePopupComponent, config);
      creatediaeref.componentInstance.refreshPageAction.subscribe(data => {
        this.getCustomerDetails();
        this.getSalesData();
      });

    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "90%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          partner_id: this.userData.data.id
        }
      };
      const creatediaeref = this.dialog.open(CreditNotePopupComponent, config);
      creatediaeref.componentInstance.refreshPageAction.subscribe(data => {
        this.getCustomerDetails();
        this.getSalesData();
      });
    }
  }

  async action_pay_settle() {

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "88vh",
        maxWidth: "70%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          partner_id: this.userData.data.id
        }
      };
      const creatediaeref = this.dialog.open(PaymentCollectPopupComponent, config);
      creatediaeref.componentInstance.markPaid.subscribe(data => {
        this.getCustomerDetails();
        this.getSalesData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          partner_id: this.userData.data.id
        }
      };
      const creatediaeref = this.dialog.open(PaymentCollectPopupComponent, config);
      creatediaeref.componentInstance.markPaid.subscribe(data => {
        this.getCustomerDetails();
        this.getSalesData();
      });
    }
  }


}
