import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  @Input() order_detail: object;
  public math = Math;
  amount_in_words;
  amount_in_arabic;
  private a = ['Zero ', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];
  showdiscount = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              public apiService: ApiService,
              private datePipe: DatePipe,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    const role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_print_show_discount' === existed.name);
    if (role_features.length > 0) {
      this.showdiscount = true;
    } else {
      this.showdiscount = false;
    }
    if (this.order_detail['date_due']) {
      this.order_detail['date_due'] = this.datePipe.transform((new Date(this.order_detail['date_due'] + ' UTC')).toString(), 'dd/MM/yyyy');
    }
    this.order_detail['sale_items'].forEach(product => {
      if (this.showdiscount) {
        if (product.hasOwnProperty('scheme_discount') && product.scheme_discount !== null && product.scheme_discount !== undefined) {
          product['scheme_discount'] = parseFloat(product['scheme_discount']).toFixed(2);
        } else {
          product['scheme_discount'] = 0;
        }
      }
      if (!product['tax_notes'] || product['tax_notes'].length === 0) {
        product['tax_notes'] = [];
        if (this.order_detail['tax_breakup'] && this.order_detail['tax_breakup'].length > 0) {
          this.order_detail['tax_breakup'].forEach(tax => {
            product['tax_notes'].push({
              name: tax.name,
              tax: 0,
              rate: 0
            });
          });
        }
      }
    });
    this.getAmountInWords();
    if (!this.order_detail['has_einvoice']) {
      this.makeQrCode();
    }

    for (const item of this.order_detail['sale_items']) {
      if (item.scheme_discount > 0) {
        const ind_disc = item['scheme_discount'] / item['qty'];
        item['default_price'] = item['real_price'] - ind_disc;
        item['default_price'] = parseFloat(item['default_price']).toFixed(2);
      } else {
        item['default_price'] = parseFloat(item['real_price']).toFixed(2);
      }
    }

    if (this.order_detail['discount']) {
      this.order_detail['discount'] = parseFloat(this.order_detail['discount']).toFixed(2);
    }

    if (this.order_detail['new_tax']) {
      this.order_detail['new_tax'] = parseFloat(this.order_detail['new_tax']).toFixed(2);
    }
  }

  getAmountInWords() {
    let primary_currency = '';
    let secondary_currency = '';
    if (primary_currency) {
      primary_currency = primary_currency;
    }
    if (secondary_currency) {
      secondary_currency = secondary_currency;
    }
    if (this.order_detail['order_type'] === 'return') {
      this.amount_in_words = this.inWords(0);
      this.amount_in_arabic = this.GetArabicNumberInWords(0);
    } else {
      if (this.order_detail['amount_pay']) {
        this.order_detail['amount_pay'] = parseFloat(this.order_detail['amount_pay']).toFixed(2);
      }
      const amount = this.order_detail['amount_pay'].split('.');
      if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
        this.amount_in_words = this.inWords(Math.abs(parseInt(amount[0], 10)));
        this.amount_in_words = primary_currency + ' ' +
          this.amount_in_words + ' And ' + secondary_currency + ' ' +
          this.inWords(Math.abs(parseInt(amount[1], 10)));
        this.amount_in_arabic = this.GetArabicNumberInWords(Math.abs(parseInt(amount[0], 10)));
        this.amount_in_arabic = primary_currency + ' ' +
          this.amount_in_arabic + secondary_currency + ' ' +
          this.GetArabicNumberInWords(Math.abs(parseInt(amount[1], 10)));
      } else {
        this.amount_in_words = primary_currency + ' ' +
          this.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
        this.amount_in_arabic = primary_currency + ' ' +
          this.GetArabicNumberInWords(Math.abs(parseInt(amount[0], 10)));
      }
    }
  }

  GetArabicNumberInWords(numericValue) {

    const digits_length = (numericValue = numericValue.toString()).length;
    let i = 0;
    /*  ArrayList<String> str = new ArrayList<>();
      HashMap<Integer, String> words = new HashMap<>();*/
    const stringlist: any = [];
    const words = new Map();
    words.set(0, "");
    words.set(1, "واحد");
    words.set(2, "اثنين");
    words.set(3, "ثلاثة");
    words.set(4, "أربعة");
    words.set(5, "خمسة");
    words.set(6, "ستة");
    words.set(7, "سبعة");
    words.set(8, "ثمانية");
    words.set(9, "تسع");
    words.set(10, "عشرة");
    words.set(11, "أحد عشر");
    words.set(12, "اثني عشر");
    words.set(13, "ثلاثة عشر");
    words.set(14, "أربعة عشرة");
    words.set(15, "خمسة عشر");
    words.set(16, "السادس عشر");
    words.set(17, "سبعة عشر");
    words.set(18, "الثامنة عشر");
    words.set(19, "تسعة عشر");
    words.set(20, "عشرين");
    words.set(30, "ثلاثون");
    words.set(40, "أربعين");
    words.set(50, "خمسون");
    words.set(60, "ستون");
    words.set(70, "سبعون");
    words.set(80, "ثمانون");
    words.set(90, "تسعين");
    const digits: any = ["", "مائة", "ألف", "لكح", "الكرور عشرة ملا يين"];
    while (i < digits_length) {
      const divider = (i === 2) ? 10 : 100;
      const number = numericValue % divider;
      numericValue = numericValue / divider;
      if (numericValue.toString().includes('.')) {
        numericValue = parseInt(numericValue.toString().split(".")[0], 0);
      }
      i += divider === 10 ? 1 : 2;
      if (number > 0) {
        const counter = stringlist.length;
        const plural = (counter > 0 && number > 9) ? "s" : "";
        const tmp = (number < 21) ? words.get(number) + " " + digits[counter] + plural : words.get(Math.floor(number / 10) * 10) + " " + words.get((number % 10)) + " " + digits[counter] + plural;
        stringlist.push(tmp);
      } else {
        stringlist.push("");
      }
    }

    let valueInWords = "";
    stringlist.reverse();
    for (const rs of stringlist) {
      valueInWords = valueInWords + " " + rs;
    }
    return valueInWords;
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ' : '';
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    } else {
      if (this.a[n[5][1]] !== 'Zero ') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      } else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }


  makeQrCode() {
    const payload = {
      SellerName: this.order_detail['dc_name'],
      SellerTaxID: this.order_detail['dc_gstn'],
      SellerRegNo: this.order_detail['supplier_pan'],
      BuyerName: this.order_detail['partner'],
      BuyerTaxID: this.order_detail['partner_gstn'],
      BuyerRegNo: this.order_detail['pan'],
      DocNo: this.order_detail['invoice_no'],
      DocTyp: "INV",
      DocDt: this.order_detail['invoice_date'],
      DocTime: this.order_detail['order_date'],
      TotVal: this.order_detail['amount_total'],
      TaxAmt: this.order_detail['amount_tax'],
      Items: this.order_detail['total_qty'],
      Issuer: "salesdiary",
      CKey: this.order_detail['ckey'],
    };
    if (this.order_detail['order_date']) {
      payload['DocTime'] = this.order_detail['order_date'].split(' ')[1];
    }
    this.order_detail['signed_qr'] = JSON.stringify(payload);
  }

  protected readonly parseFloat = parseFloat;
}
