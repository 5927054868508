<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{title}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F3F6FD; padding: 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D">
          {{message}}
        </label>
        <label style="font-weight: 400;font-size: 8px;color: #2D2D2D">
          {{instruction}}
        </label>
      </div>
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #exceluploadform="ngForm">
          <div style="margin-top: 20px; margin-bottom: 20px;">
            <input #fileUp type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
            <!--<a *ngIf="validateButton && fileUploaded" class="btn blue-btn" style="margin-top: 10px;" (click)="onValidateClick()">Validate</a>-->
          </div>
          <div *ngIf="upload_loader">
            <img style="width:5%;margin-top: 10%;" src="../../../assets/images/loader_icon.gif"/>
          </div>
          <div *ngIf="invalidFormatError">
            <div style="display: inline;">
              <p style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
                {{invalidFormatError}}
              </p>
            </div>
          </div>
          <div style="margin-top: 20px; margin-bottom: 20px;" *ngIf="dataValidated">
            <div style="display: inline;">
              <p style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
                TOTAL: {{totalDataCount}},  Valid Records : {{validDataCount}},  Records with error : {{errorDataCount}}<br>
                Only validated records will be uploaded on upload button click
              </p>
            </div>

            <ag-grid-angular id="myGrid1" style="width: 100%; height: 20vh" class="ag-theme-balham"
                             [columnDefs]="columnDefs"
                             [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)"
                             [frameworkComponents]="frameworkComponents"
                             [context]="context"
            >
            </ag-grid-angular>

          </div>
        </form>
      </div>
    </div>
    <div class="button-row" style="margin-top: 10px;display: block" *ngIf="dataValidated">
      <div style="float: right;margin-right: 15px;margin-top: 12px;">
        <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;" (click)="closeDialog()">
          <a class="btn grey-btn" >CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          (click)="onUploadConfirm()"
          form="ngForm"
          [disabled]="exceluploadform.form.invalid"
        >
          UPLOAD
        </button>
      </div>
    </div>
  </div>
</section>
