import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class NewReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('New Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getEmployeeMasterReport';
    const inthis = this;
    this.columnDefs = [

      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: "TARGET", field: "schedule_target", width: 80, aggFunc: 'sum'},
      {
        headerName: 'APP', field: 'last_app_login', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.last_app_login) {
              return inthis.datePipe.transform((new Date(params.data.last_app_login + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.last_app_login;
            }
          } else {
            return '';
          }
        }
      }
    ];

  }

}
