import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashcard',
  templateUrl: './dashcard.component.html',
  styleUrls: ['./dashcard.component.scss']
})
export class DashcardComponent implements OnInit {
  @Input() card_name: string;
  @Input() title: string;
  @Input() value: string;
  @Input() title1: string;
  @Input() value1: string;
  @Input() title2: string;
  @Input() value2: string;
  @Input() title3: string;
  @Input() value3: string;
  @Input() title4: string;
  @Input() value4: string;
  @Input() card_img: string;
  @Input() first_card: boolean;

  constructor() { }

  ngOnInit() {
  }

}

