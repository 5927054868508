<div id="profile" class="profilebar" style="width: 0%;">
    <div class="profilebar-header">
        <div class="profilebar-img">
            <img src={{user_image}}>
        </div>
        <div class="profilebar-text">
            <h2>{{user_info.user_name}}</h2>
            <label>{{user_info.role.name}}</label>
        </div>
        <div class="profilebar-contact">
          <ul class="list-unstyled">
            <li>
              <div>
                <label>Phone</label>
                <span> {{user_info.mobilenumber}}</span>
              </div>
              <div style="margin-top: 13px;">
                <label>Designation </label>
                <span> {{user_info.role.name}}</span>
              </div>
              <div *ngIf="module_dms" style="margin-top: 13px;">
                <label>Print Format</label>
                <select style="width: 45px;margin-left: 4px;" class="print-form" (change)="printformat($event)">
                <option *ngFor="let format of ['A4', 'A5L']">{{format}}</option>
                </select>
              </div>
            </li>
          </ul>
      </div>
    </div>
     <!--<div style="padding-bottom: 4%;">-->
       <!--<span style="font-size: small;padding-left: 30%;"><a href="#">Change Password</a></span>-->
     <!--</div>-->
     <div style="text-align: center;">
       <span><a (click)="logOut()" class="btn confirm-blue-btn">LOGOUT</a></span>
       <img style="display: block;margin-left: auto; margin-right: auto;width:80px; margin-top: 20px;" src="./../../../assets/menu/salesdiary_logo.svg"/>
     </div>

      <!--<div class="profilebar-contact">-->
        <!--<ul class="list-unstyled">-->
            <!--<li>-->
                <!--<label>Role </label>-->
                <!--<span> {{user_info.role.name}}</span>-->
            <!--</li>-->
            <!--<li>-->
                <!--<label>TEAM</label>-->
                <!--<span> Marsoque Team</span>-->
            <!--</li>-->
            <!--<li>-->
                <!--<label>Phone</label>-->
                <!--<span> {{user_info.mobilenumber}}</span>-->
            <!--</li>-->
            <!--<li>-->
                <!--<label>Location</label>-->
                <!--<span> Whitefield, Bangalore</span>-->
            <!--</li>-->
        <!--</ul>-->
</div>
