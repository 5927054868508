import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-users-assessment.component',
  templateUrl: './users_assessment.component.html',
  styleUrls: ['./users_assessment.component.scss']
})
export class UserAssessmentComponent implements OnInit {

  public myDate = [];
  public assessmentData: any = [];
  access_token;
  user_id: null;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      territory_ids: [],
      type: "assessment"
    }
  };
  team_territories_list = [];
  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public collapse_disable;
  public data = [];
  API_URL = '';


  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              public dialog: MatDialog,
              private sharedService: SharedService,
              private toastr: ToastrService,
              private elRef: ElementRef) {
    var date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.access_token = this.user_data.access_token;
    this.user_data.url = localStorage.getItem('private_url');
    //console.log(localStorage.getItem('private_url'));
    this.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.team_territories_list = JSON.parse(localStorage.getItem('team_territories'));

    if (this.team_territories_list != null) {
      for (var i = 0; i < this.team_territories_list.length; i++) {
        this.user_data.data.territory_ids.push(this.team_territories_list[i].id);
      }
    }

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    var indrow = {
      "code": "POP01",
      "questionnaire": "Performance assessment",
      "no_of_questions": "7",
      "start_date": "12/06/2021",
      "end_date": "19/06/2021",
      "frequency": "Monthly",
      "score": "31%",
      "completed": "649",
      "content_url": "www.example.com"
    };

    /* this.rowData.push(indrow);
     this.preloader = false;
     this.empty_state = false;

     this.loaded = true;*/

    this.columnDefs = [
      {headerName: "CODE", headerClass: 'myagheader', field: "code", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 50, resizable: true},
      {headerName: "QUESTIONNAIRE", headerClass: 'myagheader', field: "name", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {headerName: "NO OF QUESTIONS", headerClass: 'myagheader', field: "questions", cellStyle: {textAlign: 'right'}, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 80, resizable: true,},
      {headerName: "START DATE", headerClass: 'myagheader', field: "start_date", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 80, resizable: true},
      {headerName: "END DATE", headerClass: 'myagheader', field: "end_date", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 80, resizable: true},
      {headerName: "FREQUENCY", headerClass: 'myagheader', field: "period", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true},
      {headerName: "SCORE", headerClass: 'myagheader', field: "score", cellStyle: {textAlign: 'center'}, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 50, resizable: true},
      {headerName: "COMPLETED", headerClass: 'myagheader', field: "completed", cellStyle: {textAlign: 'center'}, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 50, resizable: true},
      {
        headerName: "CONTENT URL", headerClass: 'myagheader', field: "content_url", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 80, resizable: true,
        cellStyle: function(params) {
          return {color: '#2F7BFF'};
        }
      },

    ];

    this.fetchAssessments();
  }


  ngOnInit(): void {
    this.sharedService.setHeader('User Assessment');

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  fetchAssessments() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    console.log(this.user_data);
    this.API_URL = '/api/crm_profiling_questionnaires/get_user_survey_list';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status == 200) && (res.results.data.length > 0)) {

            this.assessmentData = res.results.data;
            this.rowData = this.assessmentData;
            this.loaded = true;

          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    this.gridApi.setSortModel(sortModel);
    //console.log(this.rowData);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('surveytype', 'assessment');
    localStorage.setItem('surveyid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/survey_detail'], {
      state: {
        type: 'detail',
        surveyid: rows[0].id
      }
    });
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  changeDate(dt) {

  }

  zipDownload() {
    this.API_URL = '/api/crm_profiling_questionnaires/survey_zip_download';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status == 200)) {

            this.openPublishDialog();
          } else {
            this.toastr.error("Failed to generate zip");
          }
        },
        error => {
          console.log(error);

        });
  }

  openPublishDialog() {
    let config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Zip Download",
        message1: "Your zip generation is in progress.",
        message2: "It will be available in the downloads section in reports once done",
        showNoButton: false,
        yesButton: "OK",
        noButton: ""
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data == 'yes') {
        this.dialog.closeAll();
      } else {

      }
    });
  }


}
