import { Component, OnInit, ElementRef, Renderer2, Input } from '@angular/core';
import { Router } from '@angular/router';
import {LoginComponent} from "../../login/login.component";
import { PagenotfoundComponent } from '../../pagenotfound/pagenotfound.component';
import {DcvanlistStatusComponent} from "../../dcvanlist-status/dcvanlist-status.component";
import { AuthGuard } from '../../auth/auth.guard';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() title: string;
  public currentApplicationVersion = environment.appVersion;
  constructor(private elRef:ElementRef,
              private renderer: Renderer2,
              private router: Router) { }

  ngOnInit() {

  }

}
