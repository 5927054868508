import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-hsn-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class HsnReportComponent extends ReportBase implements  OnInit {

  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'S',
    values: [],
    show: true
  };

  ngOnInit() {
    this.sharedService.setHeader('HSN Report');
    this.API_URL = '/api/pwa_reports/getHsnReport1';
    this.customFilter.values = [
      {id: "S", itemName: "Secondary"},
      {id: "P", itemName: "Primary"}
    ];


    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Brand',
      field: 'brand_name',
      minWidth: 250
    };

      }

  configureGrid() {
    this.columnDefs = [];
    this.columnDefs = [
      {field: "hsn", rowGroup: true, hide: true},
      {headerName: 'Brand Name', field: "brand_name", rowGroup: true, hide: true},
      {
        headerName: 'QTY',
        field: 'item_qty'
      },
    ];
    const taxMaster = this.apiData.taxmaster;
    for (const key in taxMaster) {
      this.columnDefs.push({
        headerName: taxMaster[key].description,
        field: taxMaster[key].description,
        cellStyle: {textAlign: 'center'}
      });
    }
    this.columnDefs.push({
      headerName: 'TAXABLE VALUE',
      field: 'taxable_amount',
      cellStyle: {textAlign: 'center'}
    });
    this.columnDefs.push({
        headerName: 'TOTAL AMOUNT',
        field: 'total_amount',
        cellStyle: {textAlign: 'center'},
        valueGetter(params) {
          if (params.data) {
            return Math.round((params.data.total_amount + Number.EPSILON) * 100) / 100;
          }

        }
      },
    );
    this.columnDefs.push({
      headerName: 'INVOICE TYPE',
      field: 'invoice_type',
    });

  }
  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    let salesMaster = [];
    let taxLines = [];
    this.rowData = [];

    salesMaster = this.apiData.sales;
    taxLines = this.apiData.taxlines;
    const taxMaster = this.apiData.taxmaster;

    for (const key in this.apiData.sales) {

      let indrow = {};
      let salesRow: any = {};
      const taxRow: any = {};
      let taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(tax => tax.hsn === salesRow.hsn && tax.brand_id === salesRow.brand_id);

      for (const key1 in taxMaster) {

        taxRow[taxMaster[key1].description] = taxLine.filter(tax => tax.description === taxMaster[key1].description)[0];
        if (taxRow[taxMaster[key1].description]  !==  null && taxRow[taxMaster[key1].description]  !==  undefined) {
          taxRow[taxMaster[key1].description] = taxRow[taxMaster[key1].description].tax_amt;
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }

    console.log(this.rowData);

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });
    this.gridColumnApi.autoSizeAllColumns();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
