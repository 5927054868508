import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-ytd-supplier-bill-count',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class YTDSupplierOrderGrowthComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  showCustomFilter = false;
  uqTypes;
  uqImageTypes;

  setTitle() {
    this.title = 'YTD Supplier Order Growth';
  }

  ngOnInit() {
    this.setTitle();
    this.showDate = false;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.sharedService.setHeader('YTD Supplier Order Growth');
    this.API_URL = '/api/pwa_v2_reports/ytdSupplierWiseBillCount';

    this.userData.offsetID = 0;
    this.userData.offset = 1000;
    this.loadInBatches(1000, 'distributor_id');
    this.showProgressCustomText = true;
    this.configureGrid();

  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [

      {headerName: "ID", field: "distributor_id", width: 50},
      {headerName: "Name", field: "distributor_name", enableRowGroup: true, width: 100},
      {headerName: "Code", field: "distributor_code", enableRowGroup: true, width: 50},
      {headerName: "Type", field: "distributor_type", enableRowGroup: true, width: 60},
      {headerName: "Zone", field: "zone", width: 100},
      {headerName: "State", field: "state", width: 100},
      {headerName: "Territory", field: "territory_name", width: 60},
      {headerName: "Region", field: "region", width: 80},
      {headerName: "Manger", field: "region_manager", width: 100},
      {headerName: "Manger Code", field: "region_manager_code", width: 50},

      {
        headerName: "January",
        children: [
          {headerName: "CY Bill Count", field: "jan_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "jan_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "jan_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "jan_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "jan_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "jan_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "jan_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.jan_sales_growth_percentage) {
                  return parseFloat(params.data.jan_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "February",
        children: [
          {headerName: "CY Bill Count", field: "feb_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "feb_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "feb_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "feb_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "feb_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "feb_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "feb_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.feb_sales_growth_percentage) {
                  return parseFloat(params.data.feb_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "March",
        children: [
          {headerName: "CY Bill Count", field: "mar_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "mar_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "mar_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "mar_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "mar_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "mar_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "mar_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.mar_sales_growth_percentage) {
                  return parseFloat(params.data.mar_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "April",
        children: [
          {headerName: "CY Bill Count", field: "apr_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "apr_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "apr_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "apr_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "apr_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "apr_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "apr_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.apr_sales_growth_percentage) {
                  return parseFloat(params.data.apr_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          },
        ]
      },
      {
        headerName: "May",
        children: [
          {headerName: "CY Bill Count", field: "may_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "may_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "may_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "may_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "may_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "may_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "may_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.may_sales_growth_percentage) {
                  return parseFloat(params.data.may_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          },
        ]
      },
      {
        headerName: "June",
        children: [
          {headerName: "CY Bill Count", field: "jun_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "jun_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "jun_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "jun_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "jun_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "jun_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "jun_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.jun_sales_growth_percentage) {
                  return parseFloat(params.data.jun_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "July",
        children: [
          {headerName: "CY Bill Count", field: "jul_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "jul_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "jul_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "jul_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "jul_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "jul_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "jul_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.jul_sales_growth_percentage) {
                  return parseFloat(params.data.jul_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          },
        ]
      },
      {
        headerName: "August",
        children: [
          {headerName: "CY Bill Count", field: "aug_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "aug_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "aug_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "aug_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "aug_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "aug_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "aug_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.aug_sales_growth_percentage) {
                  return parseFloat(params.data.aug_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "September",
        children: [
          {headerName: "CY Bill Count", field: "sep_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "sep_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "sep_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "sep_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "sep_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "sep_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "sep_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.sep_sales_growth_percentage) {
                  return parseFloat(params.data.sep_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "October",
        children: [
          {headerName: "CY Bill Count", field: "oct_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "oct_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "oct_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "oct_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "oct_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "oct_last_year_quantity", width: 50},
          {headerName: "Growth%", field: "oct_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.oct_sales_growth_percentage) {
                  return parseFloat(params.data.oct_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "November",
        children: [
          {headerName: "CY Bill Count", field: "nov_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "nov_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "nov_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "nov_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "nov_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "nov_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "nov_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.nov_sales_growth_percentage) {
                  return parseFloat(params.data.nov_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          },
        ]
      },
      {
        headerName: "December",
        children: [
          {headerName: "CY Bill Count", field: "dec_current_year_bill_count", width: 50},
          {headerName: "LY Bill Count", field: "dec_last_year_bill_count", width: 50},
          {headerName: "CY Sales", field: "dec_current_year_total_sales", width: 50},
          {headerName: "LY Sales", field: "dec_last_year_total_sales", width: 50},
          {headerName: "CY Qty", field: "dec_current_year_quantity", width: 50},
          {headerName: "LY Qty", field: "dec_last_year_quantity", width: 50},
          {
            headerName: "Growth%", field: "dec_sales_growth_percentage", width: 50,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 0) {
                  return {backgroundColor: '#B3DFB0'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.dec_sales_growth_percentage) {
                  return parseFloat(params.data.dec_sales_growth_percentage.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          }
        ]
      },
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }

  getLicenseImageDetails(license_config, license) {
    if (license_config && license) {
      return license_config.find(x => x.comment === license);
    } else {
      return null;
    }
  }

}
