import {PageBase} from "../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-beat-schedule-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class BeatScheduleListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Beat Schedule');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    const date = new Date();
    this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/beat_schedules/get_beat_schedule_list_pwa';
    const inthis = this;
    this.columnDefs = [
      {
        headerName: "DATE", field: "schedule_date", width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_date) {
              return inthis.datePipe.transform((new Date(params.data.schedule_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.schedule_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "SALESMAN", field: "salesman", width: 150},
      {headerName: "SCODE", field: "emp_code", width: 80, hide: true},
      {headerName: "TEAM", field: "team", width: 100},

      {headerName: "BS ID", field: "id", hide: true, width: 50},
      {headerName: "BP ID", field: "bp_id", hide: true, width: 50},
      {headerName: "BEAT PLAN", field: "name", width: 120},
      {headerName: "BEAT CODE", field: "code", width: 80, hide: true},
      {headerName: "TARGET", field: "schedule_target", width: 80, aggFunc: 'sum'},
      {
        headerName: "REVENUE", field: "beat_revenue", width: 100, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.beat_revenue) {
              return params.data.beat_revenue;
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: "OUTLET COUNT", field: "retailer_count", width: 80, aggFunc: 'sum'},
      {headerName: "TYPE", field: "travel_type", width: 80},
      {
        headerName: "FIRST ACTIVITY", field: "first_activity_time", width: 40,
        valueGetter: params => {
          if (params.data) {
            if (params.data.first_activity_time) {
              return inthis.datePipe.transform((new Date(params.data.first_activity_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.first_activity_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: "LAST ACTIVITY", field: "last_activity_time", width: 40,
        valueGetter: params => {
          if (params.data) {
            if (params.data.last_activity_time) {
              return inthis.datePipe.transform((new Date(params.data.last_activity_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.last_activity_time;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "WORKED MINS", field: "working_mins", width: 40},
      {
        headerName: "FOT", field: "fot", width: 40,
        valueGetter: params => {
          if (params.data) {
            if (params.data.fot) {
              return inthis.datePipe.transform((new Date(params.data.fot + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.fot;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: "LAST ORDER", field: "lot", width: 40,
        valueGetter: params => {
          if (params.data) {
            if (params.data.lot) {
              return inthis.datePipe.transform((new Date(params.data.lot + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.lot;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "REGION", field: "region", width: 80},
      {headerName: "CLUSTER", field: "cluster", width: 80},
      {headerName: "TERRITORY", field: "territory", width: 80},
      {headerName: "DISTRICT", field: "zone", width: 80},
      { headerName: "Supervision", field: "supervision", cellStyle: {textAlign: 'left'},   width: 50}
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (rows !== null && rows.length > 0 ) {
      rows[0].id = rows[0].beat_plan_id;
      localStorage.setItem('beat_plan_data', JSON.stringify(rows[0]));
      this.router.navigate([this.apiService.redirect_url + '/beats/beat_plan/details'], {
        state: {
          type: 'detail',
          beatplandata: rows[0]
        }
      });
    }
  }


}
