import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss']
})

export class MapContainerComponent implements OnInit {

  @Input() mapData = [];
  agm_map;

  zoom = 1;
  markerType = 'marker';
  latitude = 1.1;
  longitude = 1.1;
  title1 = 'Test map';

  testData = [{lat: this.latitude, lng: this.longitude, name: 'tet'}];
  public amber_marker = {
    url: '../../assets/images/Amber.svg',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public grey_marker = {
    url: '../../assets/images/Grey.svg',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public teel_marker = {
    url: '../../assets/images/Teel.svg',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };


  public green_marker = {
    url: '../../assets/images/Green.svg',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };




  ngOnInit(): void {

  }


  onMapReady(event) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;

    for (const mm of this.mapData) {
      bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    }
    this.agm_map.fitBounds(bounds);

  }


}
