<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <div>
        <span class="salesmanspan">{{sessionData.user_name}}</span>
        <span class="detailspan">{{sessionData.audit_name}}</span>
        <span style="margin-left: 5px;margin-right: 5px;">|</span>
        <span class="detailspan">{{sessionData.retailer}}</span>
        <div class="row" style="margin-top: 10px;">
          <div class="input-color">
            <span class="input-color color-box" style=" border-color:#3C8C03">{{P5}}</span>
            <span  class="input-color color-box-text" >MSL</span>
          </div>
          <div class="input-color" style="margin-left: 25px;">
            <span class="input-color color-box" style=" border-color:#E3615F">{{P4}}</span>
            <span  class="input-color color-box-text" >Focus</span>
          </div>
          <div class="input-color" style="margin-left: 25px;">
            <span class="input-color color-box" style=" border-color:#CEAF0C">{{P3}}</span>
            <span  class="input-color color-box-text" >Regional</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-section">
      <div class="search" style="display: inline-block; margin-top: 20px;">
        <span class="search-control"><img src="assets/searchicon.png"/></span>
        <input  id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                placeholder="Search">
      </div>
      <div class="col-md-12 pad0" style="margin-top: 10px;">
        <div>
          <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height: 50vh" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="empty_state">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
