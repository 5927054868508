import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-van-productivity-report',
  templateUrl: './van-productivity-report.component.html',
  styleUrls: ['./van-productivity-report.component.scss']
})
export class VanReportComponent implements OnInit {
  user;
  res_data;
  user_data = {
    start_date: null,
    access_token: null,
    url: null,
    end_date: null,
    offset: null,
    dc_id: 0,
    van_id: 0,
    type_id: null
  };

  private gridApi;
  private gridColumnApi;
  private gridApiList;
  public context;
  private gridColumnApiList;
  public columnDefs = [];
  public columnDefsList = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public gridOptionsList: GridOptions;
  public datavalue;
  public listData = [];
  public list_loaded = false;
  public list_preloader = false;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public list_empty_state = false;
  public title_name = "DC LIST";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_dc = [];
  public default = true;
  public type_datas = [];
  public all_types = {};
  public dcList = [{id: 0, itemName: 'DC'}];
  public selectedDC = [{id: 0, itemName: 'DC'}];
  public vanList = [{id: 0, itemName: 'Van'}];
  public selectedVan = [{id: 0, itemName: 'Van'}];
  public typeList = [{id: 0, itemName: 'Outlet Type'}];
  public selectedType = [{id: 0, itemName: 'Outlet Type'}];
  public pinnedTotalData = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    this.dcList = this.dcList.concat(JSON.parse(localStorage.getItem('all_dcs')));
    this.vanList = this.vanList.concat(JSON.parse(localStorage.getItem('all_vans')));
    this.typeList = this.typeList.concat(JSON.parse(localStorage.getItem('all_out_types')));

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = {
      rowHeight: 28,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressHorizontalScroll: false,
      autoGroupColumnDef: {
        cellRendererParams: {
          suppressCount: true
        }
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.gridOptionsList = {
      rowHeight: 28,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressHorizontalScroll: false,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;
  }

  dcChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.dc_id = event[0].id;
      } else {
        this.user_data.dc_id = null;
      }
    }
  }

  vanChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.van_id = event[0].id;
      } else {
        this.user_data.van_id = null;
      }
    }
  }

  typeChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.type_id = event[0].id;
      } else {
        this.user_data.type_id = null;
      }
    }
  }

  run() {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (this.user_data.van_id === 0 && diffDays > 0) {
      window.alert('Please select 1 Day Data ');
    } else if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.vanreport();
    }
  }

  vanreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const datePipe = this.datePipe;
    let type_datas;
    let keydata = [];
    this.pinnedTotalData = [];
    this.res_data = this.apiService.vanReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        this.all_dc = res['results'].dcs;
        type_datas = res['results'].type_data;
        let all_tc = 0, all_vc = 0, all_pc = 0, all_ret = 0, all_zero = 0, all_net = 0, all_nv = 0, all_tar = 0;
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          for (const k in res['results'].data[0]) {
            keydata.push(k);
          }
          keydata = keydata.splice(23);
          res['results'].data.forEach(data => {
            if (data.first_activity_time !== null) {
              data.first_activity_time = datePipe.transform((new Date(data.first_activity_time +
                ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }
            if (data.last_activity_time !== null) {
              data.last_activity_time = datePipe.transform((new Date(data.last_activity_time +
                ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }
            if (data.approved_date !== null) {
              data.approved_date = datePipe.transform((new Date(data.approved_date +
                ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }
            if (data.eod_time !== null) {
              data.eod_time = datePipe.transform((new Date(data.eod_time +
                ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }

            const pushData = {
              Date: data.date,
              DC: data.dc,
              user_id: data.user_id,
              Van: data.van,
              Target: parseFloat(data.schedule_target),
              Beat: data.beat_plan,
              TC: parseInt(data.tc, 10),
              VC: parseInt(data.vc, 10),
              PC: parseInt(data.pc, 10),
              "Net Sale": parseFloat(data.sale.toFixed(3)),
              "Return Outlets": parseInt(data.return_out, 10),
              "Not Visited": parseInt(data.tc, 10) - parseInt(data.vc, 10),
              "Zero Transactions": parseInt(data.zero_trans, 10),
              "First Activity": data.first_activity_time,
              "Last Activity": data.last_activity_time,
              "Avg Time": data.avg_time_spent,
              "EOD Time": data.eod_time,
              "Settlement Time": data.approved_date,
              Status: data.state
            };
            if (pushData['Not Visited'] < 0) {
              pushData['Not Visited'] = 0;
            }
            if (pushData['Zero Transactions'] < 0) {
              pushData['Zero Transactions'] = 0;
              data.zero_trans = 0;
            }
            all_tc += parseInt(data.tc, 10);
            all_vc += parseInt(data.vc, 10);
            all_pc += parseInt(data.pc, 10);
            all_ret += parseInt(data.return_out, 10);
            all_zero += parseInt(data.zero_trans, 10);
            all_nv += pushData['Not Visited'];
            all_net += parseFloat(data.sale);
            all_tar += parseFloat(data.schedule_target);

            for (const key in keydata) {
              let splitData;
              if (data[keydata[key]] !== null) {
                splitData = data[keydata[key]].split('$$');
                pushData[keydata[key]] = {
                  tc: splitData[0], pc: splitData[1], sale: splitData[2], damage: parseFloat(splitData[3]),
                  return: parseFloat(splitData[4]).toFixed(0), vc: splitData[5]
                };
              } else {
                pushData[keydata[key]] = {tc: 0, pc: 0, sale: 0, damage: 0, return: 0, vc: 0};
              }
            }
            rowdata.push(pushData);
          });
          this.pinnedTotalData.push({
            Date: null,
            DC: null,
            user_id: null,
            Van: null,
            Target: all_tar.toFixed(this.apiService.decimalPolicy),
            Beat: null,
            TC: all_tc.toFixed(this.apiService.decimalPolicy),
            VC: all_vc.toFixed(this.apiService.decimalPolicy),
            PC: all_pc.toFixed(this.apiService.decimalPolicy),
            "Net Sale": all_net.toFixed(this.apiService.decimalPolicy),
            "Return Outlets": all_ret.toFixed(this.apiService.decimalPolicy),
            "Not Visited": all_nv.toFixed(this.apiService.decimalPolicy),
            "Zero Transactions": all_zero.toFixed(this.apiService.decimalPolicy),
            "First Activity": null,
            "Last Activity": null,
            "Avg Time": null,
            "EOD Time": null,
            "Settlement Time": null,
            Status: null
          });
          this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
          let cat;
          const all_types = {};
          for (const type in type_datas) {
            all_types['rp_' + type_datas[type].id] = type_datas[type].name;
          }
          this.type_datas = type_datas;
          this.all_types = all_types;

          for (const type in type_datas) {
            cat = type_datas[type];
            this.columnDefs.push({
              headerName: cat.name,
              children: []
            });
          }

          this.data = rowdata[0];
          this.rowData = rowdata;
          let cat_data = [];

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (this.data[key] !== null && typeof (this.data[key]) === 'object') {

                cat_data = this.columnDefs.filter(existed => all_types[key] === existed.headerName);
                cat_data[0].children.push({
                  headerName: 'TC', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.tc + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });
                cat_data[0].children.push({
                  headerName: 'VC', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.vc + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });
                cat_data[0].children.push({
                  headerName: 'PC', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.pc + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });
                cat_data[0].children.push({
                  headerName: 'SALE', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.sale + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });
                cat_data[0].children.push({
                  headerName: 'REP', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.damage + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });
                cat_data[0].children.push({
                  headerName: 'RET', field: key, width: 70,
                  cellRenderer(params) {
                    if (params.value !== null && params.value !== undefined) {
                      const displayElem = '<p>' + params.value.return + '</p>';
                      return displayElem;
                    } else {
                      const displayElem = '<p>0</p>';
                      return displayElem;
                    }
                  },
                });

              } else if (key === 'TC' || key === 'VC' || key === 'PC' || key === 'Not Visited' || key === 'Zero Transactions') {
                this.columnDefs.push({headerName: key, field: key, width: 100, chartDataType: 'series', type: 'numericColumn'});
              } else if (key === 'DC' || key === 'Van' || key === 'Date') {
                this.columnDefs.push({headerName: key, field: key, width: 120, chartDataType: 'category', pinned: 'left'});
              } else if (key === 'First Activity' || key === 'Last Activity' || key === 'EOD Time' || key === 'Settlement Time') {
                this.columnDefs.push({headerName: key, field: key, width: 200, enableRowGroup: true});
              } else if (key === 'user_id') {
                this.columnDefs.push({headerName: key, field: key, width: 120, hide: true});
              } else {
                this.columnDefs.push({
                  headerName: key, field: key, width: 100, type: 'numericColumn'
                });
              }
            }
          }
          const temp = this.columnDefs.splice(keydata.length);
          this.columnDefs = temp.concat(this.columnDefs);
          this.loaded = true;
          this.context = {componentParent: this};
        } else {
          this.preloader = false;
          this.empty_state = true;
        }
      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  onCellClicked(event) {
    this.list_loaded = false;
    this.list_preloader = true;
    this.list_empty_state = false;
    this.columnDefsList = [];
    const datePipe = this.datePipe;
    const data = event.node.data;
    const userData = {
      date: data['Date'],
      salesman_id: data['user_id']
    };
    const sendData = {};
    sendData['access_token'] = this.user_data.access_token;
    sendData['url'] = this.user_data.url;
    sendData['data'] = userData;
    const listdata = [];
    let must_visit = false;

    this.res_data = this.apiService.vanList(sendData);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        res['results'].data.forEach(data => {
          if (data.visit_type === 'daily') {
            must_visit = true;
          } else if (data.visit_type === 'monthly') {
            const dates = data.visit_config.split(',');
            const today = (new Date(data.schedule_date)).getDate();
            dates.forEach(num => {
              if (parseInt(num, 10) === today) {
                must_visit = true;
              }
            });
          } else if (data.visit_type === 'weekly') {
            const dates = data.visit_config.split(',');
            const today = (new Date(data.schedule_date)).getDay() + 1;
            dates.forEach(num => {
              if (parseInt(num, 10) === today) {
                must_visit = true;
              }
            });
          } else if (data.visit_type === 'visitbased') {
            const dat = parseInt(data.visit_config, 10);
            const dt1 = new Date(data.last_visited);
            const dt2 = new Date(data.schedule_date);
            const diff = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
            if (diff >= dat) {
              must_visit = true;
            }
          }
          if (!must_visit && (data.visit_details !== 'Not Visited')) {
            data.visit_details = 'Beat Jump';
          } else if (!must_visit && (data.visit_details === 'Not Visited')) {
            data.visit_details = 'Not Planned';
          }
          must_visit = false;
          let time_spent = new Date(data.check_out).getTime() - new Date(data.check_in).getTime();
          time_spent = time_spent / (1000 * 60);
          if (data.check_in !== null) {
            data.check_in = datePipe.transform((new Date(data.check_in + ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
          }
          if (data.check_out !== null) {
            data.check_out = datePipe.transform((new Date(data.check_out + ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
          }
          if (data.schedule_date !== null) {
            data.schedule_date = datePipe.transform(data.schedule_date, 'dd/MM/yy');
          }
          const listData = {
            Van: data.van,
            Date: data.schedule_date,
            Code: data.code,
            Outlet: data.name,
            Type: data.type,
            Class: data.class_type,
            "Visit Details": data.visit_details,
            "Check IN": data.check_in,
            "Check Out": data.check_out,
            "Time Spent(M)": time_spent.toFixed(0),
            "Gross Sale": data.sale,
            Return: data.return_sale,
            Replace: data.damage_sale
          };

          listdata.push(listData);
        });

        this.listData = listdata;
        const list_data = listdata[0];

        for (const key in list_data) {
          if (list_data.hasOwnProperty(key)) {
            if (key === 'Visit Details') {
              this.columnDefsList.push({
                headerName: key, field: key,
                cellStyle(params) {
                  if (params.value !== null && params.value !== undefined) {
                    if (params.value === 'Productive') {
                      return {'background-color': 'MediumSeaGreen'};
                    } else if (params.value === 'Non Productive') {
                      return {'background-color': 'Moccasin'};
                    } else if (params.value === 'Not Visited') {
                      return {'background-color': 'Red'};
                    } else if (params.value === 'Not Planned') {
                      return {'background-color': 'LightGrey'};
                    } else if (params.value === 'Beat Jump') {
                      return {'background-color': 'Orange'};
                    }
                  }
                }
              });
            } else {
              this.columnDefsList.push({
                headerName: key, field: key, resizable: true
              });
            }
          }
        }
        this.list_loaded = true;

      } else {
        this.list_preloader = false;
        this.list_empty_state = true;
      }
    }, error => {
      console.log(error);
    });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    if (screen.width > 991) {
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  close_child() {
    this.list_loaded = false;
  }

  onGridReadyList(params) {
    this.gridOptionsList.api.showLoadingOverlay();

    this.gridApiList = params.api;
    this.gridColumnApiList = params.columnApi;
    params.api.setRowData(this.listData);
    this.list_preloader = false;
    if (screen.width > 991) {
      this.gridOptionsList.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiList.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.list_loaded = false;
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }
}
