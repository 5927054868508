import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";


@Component({
  selector: 'app-delivery-plan',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})


export class DeliveryPlanListComponent extends PageBase implements OnInit {

  ngOnInit(): void {

    this.sharedService.setHeader('Delivery Plan');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/inv_delivery_plans/getInvoiceDeliveryPlans';
    const inthis = this;
    this.columnDefs = [
      {headerName: "DATE", field: "date", width: 100},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "BEAT", field: "beats", width: 100},
      {headerName: "EMP CODE", field: "van_code", maxWidth: 100},
      {headerName: "SALESMAN", field: "van_name", width: 80},
      {headerName: "VEHICLE NO", field: "vehicle", width: 80},
      {headerName: "DELIVERY PERSON", field: "del_person", width: 100},
      {headerName: "INVOICES", field: "invoices", maxWidth: 90},
      {headerName: "DELIVERED", field: "delivered", maxWidth: 100},
      {headerName: "NOT DELIVERED", field: "not_delivered", maxWidth: 100},
      {headerName: "GRAND TOTAL", field: "grand_total", maxWidth: 90}
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    let filteredRows = [];
    for (const key in filteredRowData) {
      /* if (!filteredRowData[key].hasOwnProperty('data')) {
         filteredRows.push(filteredRowData[key]);
       }*/
      filteredRows.push(filteredRowData[key].data);
    }
    if (filteredRows !== null && filteredRows.length > 0) {
      this.storeDPData(filteredRows);
    }
    localStorage.setItem('dpid', rows[0].id);
    localStorage.setItem('dpindex', event.rowIndex);
    this.router.navigate([this.apiService.redirect_url + '/dms/delivery_plan/details'], {
      state: {
        viewtype: 'detail',
        customerid: rows[0].id
      }
    });
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.storeDPData(this.apiData);
    return;
  }

  storeDPData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('dpdata', JSON.stringify(ids));
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.gridApi.sizeColumnsToFit();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }
}
