<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <style type="text/css">

    body {
      margin-top: 0px;
      margin-left: 0px;
    }

    #page_1 {
      position: relative;
      overflow: hidden;
      margin: 43px 0px 12px 39px;
      padding: 0px;
      border: none;
      width: 754px;
    }

    #page_1 #id1_1 {
      border: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;
      border: 1px solid black;
      width: 752px;
      overflow: hidden;
    }


    #page_1 #p1dimg1 {
      position: absolute;
      top: 10px;
      left: 6px;
      z-index: 8;
      width: 68px;
      height: 68px;
    }

    #page_1 #p1dimg1 #p1img1 {
      width: 68px;
      height: 67px;
    }


    .ft0 {
      font: 1px 'Arial';
      line-height: 1px;
    }

    .ft1 {
      font: bold 16px 'Arial';
      line-height: 29px;
    }

    .ft2 {
      font: 11px 'Arial';
      line-height: 14px;
    }

    .ft3 {
      font: bold 11px 'Arial';
      line-height: 14px;
    }

    .ft4 {
      font: 1px 'Arial';
      line-height: 7px;
    }


    .ft6 {
      font: 1px 'Arial';
      line-height: 2px;
    }

    .p0 {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p1 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }


    .p5 {
      text-align: left;
      padding-left: 2px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p50 {
      text-align: center;
      padding-left: 2px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p6 {
      text-align: left;
      padding-left: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p7 {
      text-align: left;
      padding-left: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p8 {
      text-align: left;
      padding-left: 3px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }


    .p12 {
      text-align: left;
      margin-top: 2px;
      margin-bottom: 0px;
    }


    .table_style {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      border: 1px;
      width: 100%;
    }


    .td5 {
      border-left: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td6 {
      padding: 0px;
      margin: 0px;
      width: 323px;
      vertical-align: bottom;
    }

    .td7 {
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td8 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td9 {
      padding: 0px;
      margin: 0px;
      width: 94px;
      vertical-align: bottom;
    }


    .td11 {
      border-left: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td12 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 94px;
      vertical-align: bottom;
    }

    .td13 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 60px;
      vertical-align: bottom;
    }

    .td14 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td15 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td16 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td17 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 59px;
      vertical-align: bottom;
    }

    .td18 {
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td19 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 59px;
      vertical-align: bottom;
    }

    .serialNumber {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 10%;
      vertical-align: bottom;
    }

    .expenseType {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .amount {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .companyShare {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .tr2 {
      height: 15px;
    }

    .tr3 {
      height: 7px;
    }

    .tr5 {
      height: 2px;
    }

    .tr6 {
      height: 21px;
    }

    .tr7 {
      height: 20px;
    }

    .t0 {
      width: 100%;
      font: 11px 'Arial';
    }

  </style>
</head>

<body>
<div id="page_1">
  <div id="p1dimg1">
    <img [src]="company_logo" id="p1img1">
  </div>


  <div id="id1_1">
    <p class="p1 ft1"><u>{{meet_detail['comp_name']}}</u></p>
    <p class="p1 ft1"><u>Promotional Activity Expense Claim Form -  {{meet_detail['type']}}</u></p>

    <table cellpadding=0 cellspacing=0 class="t0">
      <tr>
        <td class="tr3 td11"><p class="p0 ft4">&nbsp;</p></td>
        <td class="tr3 td12"><p class="p0 ft4">&nbsp;</p></td>
        <td class="tr3 td13"><p class="p0 ft4">&nbsp;</p></td>
        <td class="tr3 td14"><p class="p0 ft4">&nbsp;</p></td>
        <td class="tr3 td15"><p class="p0 ft4">&nbsp;</p></td>
        <td class="tr3 td16"><p class="p0 ft4">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft2"><SPAN class="ft3">Salesman: </SPAN> {{meet_detail['user_name']}} </p>
        </td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td5"><p class="p5 ft2"><SPAN class="ft3">Meet Ref: </SPAN> {{meet_detail['meet_name']}} </p>
        </td>
        <td class="tr2 td5"><p class="p50 ft2"><SPAN class="ft3">Meet ID: </SPAN>{{meet_detail['meet_id']}}</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr5 td11"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td12"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td19"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td14"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td15"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td16"><p class="p0 ft6">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft3">Salesman No: <SPAN class="ft2">{{meet_detail['mobilenumber']}}</SPAN>
        </p></td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN class="ft3">Date: </SPAN>{{meet_detail['start_time']}}</p></td>
        <td class="tr2 td7"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr5 td11"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td12"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td19"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td14"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td15"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td16"><p class="p0 ft6">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft3">Expected Attendees: <SPAN
          class="ft2">{{meet_detail['exp_attendees']}}</SPAN></p></td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN
          class="ft3">Actual Attendees: </SPAN>{{meet_detail['att_count']}}</p></td>
        <td class="tr2 td7"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft3">Attendees Present:  <SPAN
          class="ft2">{{meet_detail['prs_count']}}</SPAN></p></td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN
          class="ft3">Total Guests: </SPAN>{{meet_detail['guest_count']}}</p></td>
        <td class="tr2 td7"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft3">Total Prospects:   <SPAN
          class="ft2">{{meet_detail['total_pros_count']}}</SPAN></p></td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN
          class="ft3">Prospects Won: </SPAN>{{meet_detail['converted_count']}}</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN
          class="ft3">Prospects Lost: </SPAN>{{meet_detail['rejected_count']}}</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr5 td11"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td12"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td19"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td14"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td15"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td16"><p class="p0 ft6">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr2 td5"><p class="p5 ft3">Territory: <SPAN class="ft2">{{meet_detail['ter_name']}}</SPAN></p>
        </td>
        <td class="tr2 td9"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td17"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td18"><p class="p6 ft2"><SPAN
          class="ft3">Product Category: </SPAN>{{meet_detail['product_category']}}</p></td>
        <td class="tr2 td7"><p class="p0 ft0">&nbsp;</p></td>
        <td class="tr2 td8"><p class="p0 ft0">&nbsp;</p></td>
      </tr>
      <tr>
        <td class="tr5 td11"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td12"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td19"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td14"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td15"><p class="p0 ft6">&nbsp;</p></td>
        <td class="tr5 td16"><p class="p0 ft6">&nbsp;</p></td>
      </tr>

    </table>

    <p class="p12 ft2"><b>Meeting Location: </b> {{meet_detail['meet_address']}} </p><br>
    <p class="p1 ft1"><u>Expenses</u></p>


    <table class="table_style">

      <tr>

        <td class="tr6 serialNumber"><p class="p7 ft3">Sl. No.</p></td>
        <td class="tr6 expenseType"><p class="p7 ft3">Expense Type</p></td>
        <td class="tr6 amount"><p class="p7 ft3">Amount</p></td>
        <td class="tr6 companyShare"><p class="p8 ft3">Company Share</p></td>
      </tr>
      <tr *ngFor="let expense of expense_json;  let i = index;">
        <td class="tr7 serialNumber"><p class="p7 ft2">{{i + 1}}</p></td>
        <td class="tr7 expenseType"><p class="p7 ft2">{{expense.name}}</p></td>
        <td class="tr7 amount"><p class="p7 ft2">{{expense.total}}</p></td>
        <td class="tr7 companyShare"><p class="p8 ft2">{{expense.comp_share}}%</p></td>
      </tr>


    </table>

    <br><br>

    <div style="display: flex; width: 100%;">

      <div style="flex: 1;">


        <h2 class="p7 ft3">Sponsors : </h2>

        <div *ngFor="let host of sponsor_json">
          <ul>
            <li class="p7 ft2">{{host.name}}</li>
          </ul>
        </div>
      </div>


      <div style="flex: 1;">
        <table cellpadding="0" cellspacing="0" class="t2">
          <tbody *ngIf="meet_budget && meet_budget">

          <tr bgcolor="#d1d1d1">
            <td class="tr6 td9"><p class="p7 ft3">Type</p></td>
            <td class="tr6 td9"><p class="p7 ft3">Per Attendee</p></td>
            <td class="tr6 td9"><p class="p7 ft3">Budget</p></td>
            <td class="tr6 td9"><p class="p7 ft3">Expense</p></td>
            <td class="tr6 td9"><p class="p7 ft3">Variance</p></td>
          </tr>


          <tr *ngFor="let budget of meet_budget">
            <td class="tr6 td9"><p class="p7 ft3">{{budget.type}}</p></td>
            <td class="tr6 td9"><p class="p7 ft3">{{budget.bud_per_att}}</p></td>
            <td class="tr6 td9"><p class="p7 ft3">{{budget.t_bud}}</p></td>
            <td class="tr6 td9"><p class="p7 ft3">{{budget.exp_per_att}}</p></td>
            <td class="tr6 td9"><p class="p7 ft3">{{budget.variance}}</p></td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>

    <br><br>

    <div style="display: flex; width: 100%;">
      <div style="flex: 1;">
        <p class="p5 ft3">Name of TGM/Salesman : {{meet_detail['user_name']}}</p>
      </div>
      <div style="flex: 1;">
        <p class="p5 ft3">Signature of TGM/Salesman : _______________________________</p>
      </div>
    </div>

    <br><br>

    <div style="display: flex; width: 100%;">
      <div style="flex: 1;">
        <p class="p5 ft3">Name of Team Leader : {{managerName}}</p>
      </div>
      <div style="flex: 1;">
        <p class="p5 ft3">Signature of Team Leader : ________________________________</p>
      </div>
    </div>

    <br><br>
    <p class="p12 ft2"><b>Advance Received from Company : </b>{{meet_detail['adv_received']}} {{advRecievedInwords}}
    </p><br>
    <p class="p12 ft2"><b>Total Expense raised : </b> {{totalExp}} ({{totalExpInwords}} Only)</p><br>
    <p class="p12 ft2"><b>Total Company Share : </b> {{totalCompShare}} ({{totalCompShareInwords}} Only)</p><br>
    <p *ngIf="meet_budget && meet_budget" class="p12 ft2"><b>Total Budget : </b>{{meet_detail['t_bud']}} </p><br>
    <p *ngIf="meet_budget && meet_budget" class="p12 ft2"><b>Total Variance : </b> {{meet_detail['t_var']}} % </p>
    <br>
    <p class="p12 ft2"><b>Terms and Conditions :</b><br> {{terms}} </p>
    <p class="p12 ft2"><br><b>Note : Please enclose all the original vouchers and photographs in support of the
      claim.</b></p>
  </div>
</div>
</body>
</html>
