import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from "@angular/common";

declare let google: any;
declare let $: any;
declare let _: any;

@Component({
  selector: 'app-location-verify-popup',
  templateUrl: './location_verify_popup.component.html',
  styleUrls: ['./location_verify_popup.component.scss']

})

export class LocationVerifyPopupComponent implements OnInit {
  userloc = {
    lat: 0,
    long: 0,
    street: '',
    street2: '',
    area: '',
    zone: '',
    zip: ''
  };
  retailerloc = {
    lat: 0,
    long: 0,
    street: '',
    street2: '',
    area: '',
    zone: '',
    city: '',
    state: 0,
    state_code: '',
    zip: '',
    address: '',
    locality: '',
    country: ''
  };
  dcloc = {
    lat: 0,
    long: 0,
    street: '',
    street2: '',
    area: '',
    zone: '',
    zip: ''
  };
  userlatitude: number;
  userlongitude: number;
  retailerLatitude: number;
  retailerLongitude: number;
  zoom: number;
  title1;
  title2;
  infowindow1: any;
  infowindowContent1: any;
  infowindow2: any;
  infowindowContent2: any;
  geoCoder;

  map: any;
  public is_chain = '0';
  public customers = [];
  public gstin = null;
  public pgm_type = null;
  public pgm_id = null;
  public date = null;
  public parent_id = null;
  public mobile = null;
  public email = null;
  public selCust;
  public verify_json;
  public has_purchase_order;
  public has_preorder = '0';
  public refreshDlg = false;
  public pricelist_id = null;
  public pricelist = null;
  public selectedChain = [];
  API_URL = '';
  showMap = false;
  allStates = [];
  public icon_url = {
    url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|FFFFFF'
  };
  public green_url = {
    url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|00E84E',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public red_url = {
    url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|EA4335',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public blue_url = {
    url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|6990FD',
    scaledSize: {
      width: 40,
      height: 40,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  private partner_id: any;
  countryset = true;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.verify_json = dialogdata.verify_json;
    this.partner_id = dialogdata.id;
    this.mobile = dialogdata.params.mobile;
    this.email = dialogdata.params.email;
    this.zoom = 15;
  }

  ngOnInit(): void {
    if (localStorage.getItem('all_states') !== null && localStorage.getItem('all_states') !== 'null' && JSON.parse(localStorage.getItem('all_states')) !== null) {
      this.allStates = JSON.parse(localStorage.getItem('all_states'));
      this.allStates.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }
    this.getLocationDetails();
  }

  getLocationDetails() {
    this.API_URL = '/api/res_partners/getLocationVerifyDetails';
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.partner_id;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            const data = res.results.data[0];
            /*  this.userloc.lat = parseFloat(data.u_lat);
              this.userloc.long = parseFloat(data.u_long);*/

            this.userloc.lat = 19.216328041572915;
            this.userloc.long = 73.19237890472411;

            this.retailerloc.lat = parseFloat(data.r_lat);
            this.retailerloc.long = parseFloat(data.r_long);
            this.retailerloc.street = data.r_street;
            this.retailerloc.street2 = data.r_street2;
            this.retailerloc.area = data.r_area;
            this.retailerloc.zone = data.r_zone;
            this.retailerloc.city = data.r_city;
            this.retailerloc.state = data.r_state;
            this.retailerloc.state_code = data.r_state_code;
            if (data.r_zip !== null) {
              this.retailerloc.zip = data.r_zip.replace(/[^0-9]/g, '');
            }
            this.retailerloc.address = data.r_street + ',' + data.r_street2 + ',' + data.r_area + ','
              + data.r_zone + ',' + data.r_zip;
            this.retailerloc.country = data.country;
            /*if (data.country === null || data.country === undefined || data.country === "" ||
              data.r_state === null || data.r_state === undefined || data.r_state === "") {
              this.countryset = false;
            } else {
              this.countryset = true;
            }*/
            /*  this.dcloc.lat = parseFloat(data.dr_lat);
              this.dcloc.long = parseFloat(data.dr_long);*/

            this.dcloc.lat = 19.213045547519354;
            this.dcloc.long = 73.19692793121337;
            this.dcloc.street = data.dr_street;
            this.dcloc.street2 = data.dr_street2;
            this.dcloc.area = data.dr_area;
            this.dcloc.zone = data.dr_zone;
            this.dcloc.zip = data.dr_zip;

            this.showMap = true;

          } else {
          }
        },
        error => {
          console.log(error);
        });
  }

  markerDragEnd($event: any) {

    this.retailerloc.lat = $event.coords.lat;
    this.retailerloc.long = $event.coords.lng;
    this.getAddress(this.retailerloc.lat, this.retailerloc.long);
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.retailerloc.street = this.extractFromAddress(results[0].address_components, 'street_number', 'long_name');
          if (this.retailerloc.street === null || this.retailerloc.street === '') {
            this.retailerloc.street = this.extractFromAddress(results[0].address_components, 'route', 'long_name');
          }
          this.retailerloc.street2 = this.extractFromAddress(results[0].address_components, 'sublocality_level_2', 'long_name');
          if (this.retailerloc.street2 === null || this.retailerloc.street2 === '') {
            this.retailerloc.street2 = this.extractFromAddress(results[0].address_components, 'sublocality_level_3', 'long_name');
          }
          this.retailerloc.area = this.extractFromAddress(results[0].address_components, 'sublocality_level_1', 'long_name');
          if (this.retailerloc.area === null || this.retailerloc.area === '') {
            this.retailerloc.area = this.extractFromAddress(results[0].address_components, 'locality', 'long_name');
            if (this.retailerloc.area === null || this.retailerloc.area === '') {
              this.retailerloc.area = this.extractFromAddress(results[0].address_components, 'neighborhood', 'long_name');
            }
          }
          this.retailerloc.zone = this.extractFromAddress(results[0].address_components, 'administrative_area_level_2', 'long_name');
          if (this.retailerloc.zone === null || this.retailerloc.zone === '') {
            this.retailerloc.zone = this.extractFromAddress(results[0].address_components, 'administrative_area_level_1', 'long_name');
          }
          this.retailerloc.city = this.extractFromAddress(results[0].address_components, 'locality', 'long_name');
          let state = this.extractFromAddress(results[0].address_components, 'administrative_area_level_1', 'long_name');
          let filtered_state = this.allStates.filter(existed => existed.itemName === state);
          if (filtered_state !== null && filtered_state.length > 0) {
            this.retailerloc.state = filtered_state[0].id;
          } else {
            state = this.extractFromAddress(results[0].address_components, 'administrative_area_level_1', 'short__name');
            filtered_state = this.allStates.filter(existed => existed.code === state);
            if (filtered_state !== null && filtered_state.length > 0) {
              this.retailerloc.state = filtered_state[0].id;
            } else {
              this.retailerloc.state = 0;
            }
          }
          this.retailerloc.zip = this.extractFromAddress(results[0].address_components, 'postal_code', 'short_name');
          this.pincodeCheck();
          this.retailerloc.address = this.retailerloc.street + ',' +
            this.retailerloc.street2 + ',' + this.retailerloc.area + ',' + this.retailerloc.zone + ',';
        }
      }
    });
  }

  extractFromAddress(components, type, key) {
    return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item[key]).pop() || null;
  }

  pincodeCheck() {
    if (this.retailerloc.zip !== null) {
      this.retailerloc.zip =  this.retailerloc.zip.replace(/[^0-9]/g, '');
    }
  }

  verifyLocation() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';

    if (this.verify_json.hasOwnProperty('location')) {
      this.verify_json.location.verified = true.toString();
      this.verify_json.location.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.verify_json.location.by = JSON.parse(localStorage.getItem('user')).user_name;
    } else {
      const location = {
        verified: true.toString(),
        date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        by: JSON.parse(localStorage.getItem('user')).user_name
      };
      this.verify_json['location'] = location;
    }
    this.pincodeCheck();
    const userData = {
      access_token: localStorage.getItem('resfreshToken'),
      data: {
        verification_json: this.verify_json,
        partner_id: this.partner_id,
        street: this.retailerloc.street,
        street2: this.retailerloc.street2,
        area: this.retailerloc.area,
        zone: this.retailerloc.zone,
        city: this.retailerloc.city,
        state_id: this.retailerloc.state,
        zip: this.retailerloc.zip
      }
    };

    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Approval Request');
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error updating Approval Request');
        });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
