<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Generate Claims</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D">
          You have selected
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{line_count}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          scheme lines from
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{invoices_count}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          invoices.
        </label> <br/>

      </div>
      <div class="row" style="background: white; margin-top:10px; margin-bottom: 10px; border: 1px solid #D7DDE6; border-radius: 10px;">
        <div class="col-md-12 pad0" style="padding-top: 12px;">
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Schemes</label>
            <p style="font-size: 14px;font-weight: 500;">{{scheme_count}}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Invoices</label>
            <p style="font-size: 14px;font-weight: 500;">{{invoices_count}}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">No Of Parties</label>
            <p style="font-size: 14px;font-weight: 500;">{{customer_count}}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Total</label>
            <p style="font-size: 14px;font-weight: 500;">{{claim_amount}}</p>
          </div>

        </div>
      </div>
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #generateclaimsform="ngForm">

        </form>
      </div>
<!--      <div style="margin-top: 10px;">-->
<!--        <p>Upload Attachment</p>-->
<!--        <div style="display: inline-flex;align-items: center;">-->
<!--          <input-->
<!--            type="file"-->
<!--            #file-->
<!--            style="display:none"-->
<!--            accept="image/png, image/jpeg, application/pdf"-->
<!--            (change)="handleFileSelect($event)"/>-->
<!--          <span style="margin-right:5px;display:inline" (click)="file.click()">-->
<!--            <img *ngIf="!expensephoto" src="assets/upload_picture.svg" width="40px" />-->
<!--            <img *ngIf="expensephoto && expensephotos.type !== 'pdf'" [src]="expensephoto" style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px; cursor: pointer"/>-->
<!--            <img *ngIf="expensephoto && expensephotos.type === 'pdf'" src="./assets/pdf_icon.png" style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px; cursor: pointer"/>-->
<!--          </span>-->
<!--        </div>-->
<!--        <p *ngIf="expensephoto" style="margin-bottom: 0px;padding-bottom: 0px;">{{expensephotos.name}}</p>-->
<!--        <p *ngIf="expensephoto">{{expensephotos.size}} Kb</p>-->
<!--      </div>-->
      <div style="margin-top: 20px; margin-bottom: 20px; border-left: 4px solid #17A2B8">
        <div style="display: inline;">
          <p style="font-weight: 400;font-size: 11px;color: #2D2D2D; padding-left: 8px;">
            Generate claim for the selected scheme lines. To continue, click on submit.
          </p>
          <p *ngIf="line_count === 0" style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
            <span style="font-weight: 400;font-size: 12px;color: #E24260; margin-left: 3px; margin-right: 3px;">No Scheme records selected!</span>
          </p>
        </div>
      </div>

    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: left; margin: 15px 30px 15px 15px;">
        <label style="color:#7D7D7D;font-weight: 400;">Claim Amount</label>
        <p style="font-weight: 600;font-size: 14px;color: #0762AD; margin-left: 3px;">
          {{claim_amount}}
        </p>
      </div>
      <div style="float: right; margin: 15px 30px 15px 0px;">
           <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;" (click)="closeDialog()">
           <a class="btn grey-btn" >CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          (click)="generateClaims()"
          form="ngForm"
          [disabled]="generateclaimsform.form.invalid"
        >
          SUBMIT
        </button>
      </div>
    </div>
  </div>
</section>
