import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {MatDialogConfig} from "@angular/material/dialog";
// import {DMSCustomerSelectionPopupComponent} from "../customer_selection/dms_customer_selection";
import {NgSelectComponent} from "@ng-select/ng-select";
import {UOMRendererComponent} from "../../../pagebase/components/UOMRendererComponent";
import {CellEditingStartedEvent, CellEditingStoppedEvent, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";


@Component({
  selector: 'app-direct-invoice',
  templateUrl: 'direct_invoice.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class DirectInvoiceComponent extends DetailPageBase implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;

  public orderDetails;
  public userList;
  public addressLibrary;

  public priceEdited = false;
  public uomEdited = false;

  public linkedInvoiceMode = false;
  public ref_invoice_obj = {};

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;

  supplierList;
  customerList;
  dateDisable;
  myDate;

  prodList = [];
  UOMList = [];
  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  ngOnInit(): void {
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_invoice_detail";
    this.context = {componentParent: this};

    this.addressLibrary = JSON.parse(localStorage.getItem('cust_addresses'));
    this.sharedService.setHeader('Invoice');

    this.configureGrid();

    this.initialize_default_values(() => {});

    this.loadPage();
  }



  //Page Initilizefunctions
  initialize_default_values(callback) {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.activeObject.type = 'out_refund';
    this.partner_tcs = 0;

    this.userList = JSON.parse(localStorage.getItem('dms_users'));
    this.supplierList = JSON.parse(localStorage.getItem('dist_list'));
    this.customerList =  JSON.parse(localStorage.getItem('customers_list'));
    // this.loadSuppliers(callback);
    // callback();
    //initialize customer if the type is P, or initialize supplier if type is S.
    // Accordingly initialize derived fields
    // this.readPageParms(params => {
    //   this.queryParams = params;
    //   callback();
    //   // this.activeObject['invoice_type'] = params.get('type');
    //   // if (this.activeObject['invoice_type'] === 'S') {
    //   //   this.activeObject.supplier_id = this.USER.partner_id;
    //   //   // this.loadCustomers();
    //   //   callback();
    //   // } else {
    //   //   this.activeObject.customer_id = this.USER.partner_id;
    //   //   this.loadSuppliers(callback);
    //   // }
    // });
  }

  loadUsers() {

  }

  loadCustomers() {
    this.customerList =  JSON.parse(localStorage.getItem('customers_list'));
  }

  loadSuppliers(callback) {
    const user_data = {};
    user_data['access_token'] = this.userContext.access_token;
    user_data['dc_id'] = this.activeObject['supplier_id'];
    this.apiService.getSuppliers(user_data).subscribe(res => {
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        this.supplierList = res['results'].data[0].suppliers;
        callback();
        // console.log('Suplier loaded' + this.supplierList.length);
        // if (this.supplierList.length > 0) {
        //   this.activeObject['supplier_id'] = this.supplierList[0].id;
        //   this.activeObject['supplier_code'] = this.supplierList[0].code;
        //   this.activeObject['supplier_Name'] = this.supplierList[0].itemName;
        //   this.activeObject['supplier_gst_id'] = this.supplierList[0].tax;
        // } else {
        //
        // //  TODO: Throw erreor
        // }
      }
    });
  }

  loadProducts(callback) {
    if (this.prodList === undefined || this.prodList.length === 0) {

      const userData = {};
      userData['access_token'] = this.userContext.access_token;
      userData['supplier_id'] = this.activeObject['supplier_id'];

      if (!this.activeObject['pricelist_id']) {
        userData['pricelist_id'] = 0;
      } else {
        userData['pricelist_id'] = this.activeObject['pricelist_id'];
      }

      this.apiService.post('/api/pwa_dms_connects/get_products_for_dms', userData).subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          this.prodList = res['results'].data;
        }

        return callback();
      });
    } else {
      return callback();
    }
  }

  populateCustomerAttributes() {
    if (this.customerList !== undefined && this.customerList !== null) {
      const cust_obj = this.customerList.find(item => item.id === this.activeObject['partner_id']);
      if (cust_obj) {
        this.activeObject['customer_name'] = cust_obj.name;
        this.activeObject['customer_code'] = cust_obj.code;
        this.activeObject['customer_gst_id'] = cust_obj.gst_id;
        this.activeObject['customer_email'] = cust_obj.email;
        this.activeObject['customer_address'] = cust_obj.address;
        this.activeObject['beat_id'] = cust_obj.beat_id;
        this.activeObject['user_id'] = cust_obj.user_id;
        this.activeObject['salesman'] = cust_obj.user;
        this.activeObject['tax_source'] = cust_obj.tax_source;
        this.activeObject['pricelist_id'] = cust_obj.pricelist_id;
        this.activeObject['pricelist_name'] = cust_obj.pricelist;

        this.billto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(cust_obj.id, 10)) && (existed.type === 'invoice')));
        this.billto_adrr_list = [{id: cust_obj.id, parent_id: cust_obj.id, itemname: cust_obj.address}].concat(this.billto_adrr_list);

        this.shipto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(cust_obj.id, 10)) && (existed.type === 'delivery')));
        this.shipto_adrr_list = [{id: cust_obj.id, parent_id: cust_obj.id, itemname: cust_obj.address}].concat(this.shipto_adrr_list);
      }
    }
  }

  populateSupplierAttributes() {
    if (this.supplierList !== undefined && this.supplierList !== null) {
      if (this.activeObject.distributor_id) {
        const supplier = this.supplierList.find(item => item.id = this.activeObject.distributor_id);
        if (supplier) {
          this.activeObject['supplier_name'] = supplier.name;
          this.activeObject['supplier_code'] = supplier.code;
          this.activeObject['supplier_gst_id'] = supplier.gst_id;
        }
      }
    }
  }



  loadPage() {
    this.loadActiveObject(() => {

      if (this.activeID > 0 && this.activeObject['partner_id'] !== undefined) {

        this.billto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === this.activeObject['partner_id']) && (existed.type === 'invoice')));
        this.billto_adrr_list = [{id: this.activeObject['partner_id'], parent_id: this.activeObject['partner_id'], itemname: this.activeObject['customer_address']}].concat(this.billto_adrr_list);
        this.shipto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === this.activeID) && (existed.type === 'delivery')));
        this.shipto_adrr_list = [{id: this.activeObject['partner_id'], parent_id: this.activeObject['partner_id'], itemname: this.activeObject['customer_address']}].concat(this.shipto_adrr_list);
        if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
          this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
        }

        this.loadlineData();

      } else {

        //Load all default Values
        this.activeObject['id'] = 0;
        this.billto_adrr_list = [];
        this.shipto_adrr_list = [];
        this.activeObject.total = 0;
        this.activeObject.line_discount = 0;
        this.activeObject.spcl_discount = 0;

        if (this.userList.length > 0) {
          this.activeObject['user_id'] = this.userList[0].id;
        }

        // this.readRefInvoice();

      }

      this.populateSupplierAttributes();
      this.populateCustomerAttributes();

      if (this.activeObject.amount_tax) {
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
      }
      if (this.activeObject.amount_total) {
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
      }
      if (this.activeObject.amount_untaxed) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
      }
      if (this.activeObject.tax_source) {
        this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
      } else if (this.activeObject.charges) {
        this.activeObject.charges = parseFloat(this.activeObject.charges);
      }


      this.selectedUser = this.userList.find(item => item.id === this.activeObject['user_id']);


      // this.loadProducts(() => {
      //   if (this.activeID > 0) {
      //     this.loadlineData();
      //   } else if (this.ref_invoice_obj) {
      //     this.populateRefInvoiceLines();
      //   }
      // });

      // if (this.activeID > 0) {
      //
      // } else if (this.ref_invoice_obj) {
      //   this.populateRefInvoiceLines();
      // }

    });
  }

  loadlineData() {
    this.rowData = [];
    if (this.activeObject && this.activeObject['sale_items'] && this.activeObject.sale_items.length > 0) {
      this.activeObject.sale_items.forEach(line => {
        // const prod = this.prodList.find(item => item.p_id === line.product_id);
        // line['uoms'] = prod.uoms;
        // line['stock'] = prod.tot_qty;
        this.rowData.push(line);
      });

      this.gridOptions.api.setRowData(this.rowData);
      // this.compute_totals();
    }
  }
  //
  // readRefInvoice() {
  //   this.ref_invoice_obj = JSON.parse(localStorage.getItem('reference_invoice'));
  //   if (this.ref_invoice_obj) {
  //     this.linkedInvoiceMode = true;
  //     this.activeObject['invoice_id'] = this.ref_invoice_obj['id'];
  //     this.activeObject['partner_id'] = this.ref_invoice_obj['partner_id'];
  //     this.activeObject['supplier_id'] = this.ref_invoice_obj['supplier_id'];
  //     this.activeObject['user_id'] = this.ref_invoice_obj['user_id'];
  //     this.rowData = [];
  //   }
  // }

  // populateRefInvoiceLines() {
  //   if (this.ref_invoice_obj['lines'] !== undefined) {
  //     this.ref_invoice_obj['lines'].foreach(item => {
  //       this.addProductLine(item.product_id, item.uom_id, item.quantity, item.price_unit);
  //     });
  //
  //     this.compute_totals();
  //     this.gridOptions.api.setRowData(this.rowData);
  //   }
  // }

  //GRID Functions
  configureGrid() {

    this.columnDefs = [
      {headerName: 'PRODUCT', field: 'name', width: 350},
      {headerName: 'STOCK', field: 'stock', width: 80, hide: true},
      {headerName: 'QTY', field: 'quantity', width: 80, editable: true, cellEditor: NumericEditorComponent},
      {headerName: 'REUSE', field: 'reusable_qty', width: 80, editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'UOM', field: 'base_uom', width: 100},
      {headerName: 'SCHEME', field: 'scheme_code', hide: true},
      {headerName: 'PRICE', field: 'price_unit', cellClass: ['number-cell', 'total-col'], valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'DISC%', field: 'discount', editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'SPCL DISC/qty', field: 'trade_disc', editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'CHARGES', field: 'fixed_cost', hide: true},
      {headerName: 'SCHEME DIS', field: 'scheme_discount', hide: true},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', cellClass: ['number-cell', 'total-col'], valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'TAX', field: 'tax_name'},
      {headerName: 'TOTAL', field: 'price_total', cellClass: ['number-cell', 'total-col'], valueFormatter: params => this.formatNumber(params.data.price_total)},
      {headerName: 'Serial NO', hide: true},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 60, hide: true},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
      uomRenderer: UOMRendererComponent
    };
  }



  onRowEditingStarted(event: RowEditingStartedEvent) {
    console.log('never called - not doing row editing');
  }

  onRowEditingStopped(event: RowEditingStoppedEvent) {
    console.log('never called - not doing row editing');
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.colDef.field === 'quantity') {
      event.data['reusable_qty'] = event.newValue;
      event.data['price_subtotal'] = parseInt(event.newValue, 10) * parseFloat(event.data['price_unit']);
      if (event.data['tax_amount'] !== undefined && event.data['tax_amount'] > 0) {
        event.data['price_tax'] = (event.data['price_subtotal'] * parseFloat(event.data['tax_amount'])) / 100;
      }
      event.data['price_total'] = parseFloat(event.data['price_subtotal']) + parseFloat(event.data['price_tax']) ;
    }
    this.compute_totals();
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }



  //Page Events
  showCustomerSelection() {
    // const config: MatDialogConfig = {
    //   width: "100vw",
    //   height: "100vh",
    //   maxWidth: "1000px",
    //   maxHeight: "620px"
    // };
    //
    // const creatediaeref = this.dialog.open(DMSCustomerSelectionPopupComponent, config);
    //
    // creatediaeref.componentInstance.selectedOutlet.subscribe(data => {
    //   this.changeCustomer(data);
    //   this.dialog.closeAll();
    // });
  }

  changeCustomer(data) {
    console.log(data);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;
      this.activeObject['beat_id'] = data.beat_id;
      this.activeObject['user_id'] = data.user_id;
      this.activeObject['salesman'] = data.user;
      this.activeObject['tax_source'] = data.tax_source;
      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist;

      this.billto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(data.id, 10)) && (existed.type === 'invoice')));
      this.billto_adrr_list = [{id: data.id, parent_id: data.id, itemname: data.address}].concat(this.billto_adrr_list);

      this.shipto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(data.id, 10)) && (existed.type === 'delivery')));
      this.shipto_adrr_list = [{id: data.id, parent_id: data.id, itemname: data.address}].concat(this.shipto_adrr_list);

      if (this.pricelist_id !== prev_pricelist_id) {
        this.prodList = [];
        this.loadProducts(() => {
          this.rowData = [];
        });
      }
    }
  }

  customerChangeEvent(selected_customer_id) {
    const selected_customer = this.customerList.find( item => item.id === selected_customer_id);
    this.changeCustomer(selected_customer);
  }

  billaddrssChange(event) {
    if (event.id) {
      this.selected_billto_addr_id = event.id;
    }
  }

  shipaddrssChange(event) {
    if (event.id) {
      this.activeObject['partner_shipping_id'] = event.id;
    }
  }



  suppChange(event) {
    if (event.id) {
      this.activeObject['supplier_id'] = event.id;
      // this.loadProducts();
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.filter(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['emp_code'] = selecteduser.emp_code;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.filter(item => item.p_id === this.selectedProduct)[0];
    this.UOMList = prod.uoms;
    this.selectedUOM_id = prod.uom_id;

    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange() {
    console.log(this.selectedUOM_id);

  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    // this.columnDefs[5].editable = this.isGridCellEditable();
    // this.columnDefs[12].editable = this.isGridCellEditable();
  }

  editInvoice() {
    this.editMode = true;
    // this.columnDefs[5].editable = this.isGridCellEditable();
    // this.columnDefs[12].editable = this.isGridCellEditable();
    this.gridApi.redrawRows();

  }

  isGridCellEditable() {
    return this.editMode;
  //  TODO: Check for status
  }

  //Page core functions
  saveCart() {
    this.editMode = false;

    //TODO: Complete basic validation of page and line items
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] > 0) {

    }

    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {

      const prod = this.prodList.filter(item => item.p_id === line.product_id);
      let priceSubtotal = parseFloat(line.quantity) * parseFloat(line.price_unit);
      priceSubtotal = priceSubtotal * (1 - (parseFloat(line.discount) / 100));
      this.activeObject['lines'].push(line);
    });

    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_return_invoice";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {
        this.toastr.success(result);
      }

      this.loadPage();
    });
  }

  addProductLine(productId, uomId, quantity, unitPrice) {
    const prod = this.prodList.filter(item => item.p_id === productId)[0];
    const uom_obj = this.UOMList.filter(item => item.id === uomId)[0];
    if (unitPrice === undefined) {
      unitPrice = parseFloat(String(prod['unit_price']));
    }
    const line_subtotal = unitPrice * parseInt(quantity, 10);

    //TODO: exclude promo/foc items
    const prod_match = this.rowData.filter(item => item.product_id ===  productId && item.uom_id === uomId);
    let line = {};
    if (prod_match.length > 0) {
      if (prod_match['deleted']) {
        prod_match['deleted'] = false;
        line = prod_match[0];
      }
    }
    let priceTax = 0;
    let priceTotal = 0;
    const disc = parseFloat(prod['margin']);

    if (prod['tax_amount'] !== undefined && prod['tax_amount'] > 0) {
      priceTax = (line_subtotal * prod['tax_amount']) / 100;
    }
    priceTotal = line_subtotal + priceTax;

    line = {
      type: 'return',
      uoms: prod.uoms,
      stock: this.stock,
      product_uom: uom_obj.itemName,
      name: prod['product'],
      product_id: productId,
      tax_amount: parseFloat(prod['tax']),
      uom_id: uomId,
      price_unit: unitPrice,
      discount: disc,
      trade_disc: 0,
      fixed_cost: 0,
      scheme_discount: 0,
      quantity: parseInt(quantity, 10),
      reusable_qty: parseInt(quantity, 10),
      price_subtotal: line_subtotal,
      price_tax: priceTax,
      price_total: priceTotal,
      tax_name: prod['tax_name'],
      taxmaster_id: prod['taxmaster_id'],
      factor_inv: prod['factor_inv'],
      factor: prod['factor'],
    };

    if (line['mid'] === undefined || line['mid'].length === 0) {
      line['mid'] = this.get_mid(this.activeObject.partner_id);
    }
    if (line['line_id'] === undefined) {
      line['line_id'] = 0;
    }

    this.rowData.push(line);
  }

  addProductClick() {

    this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.enteredQty, undefined);

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.compute_totals();
    this.gridOptions.api.setRowData(this.rowData);
    this.productSelector.focus();
  }

  removeProduct(index) {
    const old_line = this.rowData[index];
    if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0) {
      this.rowData[index]['deleted'] = true;
      this.rowData[index]['quantity'] = 0;
      this.rowData[index]['reusable_qty'] = 0;

      this.gridApi.onFilterChanged();
    } else {
      this.rowData.splice(index, 1);
      this.gridApi.setRowData(this.rowData);
    }
    this.compute_totals();

  }

  compute_totals() {
    this.activeObject.amount_untaxed = 0;
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;

    this.rowData.forEach(line => {
      if (line['deleted'] === undefined || line['deleted'] === false) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed) + parseFloat(line.price_subtotal);
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax) + parseFloat(line.price_tax);
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total) + parseFloat(line.price_total);
      }
    });
  }

}
