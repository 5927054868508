import { Injectable } from '@angular/core';
import * as localforage from 'localforage'; // this works!!!

@Injectable({
  providedIn: 'root'
})
export class LocalforageService {

  constructor() {
    localforage.config({
      name: 'SD Storage'
    });
  }

  get(key: string) {
    return localforage.getItem(key);
  }

  set(key: string, value: any) {
    return localforage.setItem(key, value);
  }

  remove(key: string) {
    return localforage.removeItem(key);
  }

  DELETE_ALL() {
    return localforage.clear();
  }

  listKeys() {
    return localforage.keys();
  }

  getAppConfig() {
      return localforage.getItem('APP_CONFIG');
  }

  setAppConfig(value: any) {
    return localforage.setItem('APP_CONFIG', value);
  }

  getAccessControlList() {
    return localforage.getItem('AccessControlLogic');
  }

  setAcessControl(value: any) {
    return localforage.setItem('AccessControlLogic', value);
  }


}
