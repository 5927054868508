import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'grn-editor',
  template: `<div class="eod-top" layout="row">` +
  `<span class="element-po"><input type="text" [disabled]="disable" [(ngModel)]="grnValue" (ngModelChange)="editCell()" class="custom-inputbox"/></span>` +
  `</div>`
})
export class GrnEditRenderer implements ICellRendererAngularComp {
  public params: any;
  public grnValue = "";
  public disable = false;

  agInit(params: any): void {
    this.params = params;
    this.grnValue = this.params.data['GRN'].grn;
    if (this.params.data['approved'] == true || this.params.data['state'] == 'cancel') {
      this.disable = true;
    }else {
      this.disable = false;
    }
  }

  public editCell() {
    this.params.data['GRN'].grn = this.grnValue;
  }

  refresh(): boolean {
    return false;
  }
}
