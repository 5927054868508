import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../../pagebase/pagebase";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {PrintClickRenderer} from "../../../printClickRenderer.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {BulkUploadStatusPopupComponent} from "../../../components/bulk_upload_popup/bulk_upload_status_popup/bulk_upload_status_popup.component";
import {BasePrintComponent} from "../../../components/base_print/base_print.component";
import {AddBeatRetailerPopupComponent} from "../../../beats/add_beat_retailer_popup/add_beat_retailer_popup.component";

@Component({
  selector: 'app-return-invoice-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})


export class SecondaryReturnInvoiceListComponent extends PageBase implements OnInit, IPage {


  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Return Invoices');
    this.showActionButton = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'draft', itemName: 'Draft'},
      {id: 'confirm', itemName: 'Confirmed'},
      {id: 'cancel', itemName: 'Cancel'},
    ];
    this.showCustomFilter = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount'];
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_return_invoices';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 60, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "INVOICE NO", field: "name", width: 60},
      {headerName: "INVOICE DATE", field: "date_invoice", width: 120},
      {headerName: "REFERENCE", field: "origin", width: 150},
      {headerName: "CUSTOMER", field: "cust_name", width: 150},
      {headerName: "AMOUNT", field: "amount", width: 150},
      {headerName: "STATUS", field: "state", width: 80},
      {headerName: "SALESMAN", field: "salesman", width: 100},
      {headerName: "OUTSTANDING", field: "outstanding", width: 100},
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 80
      }
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (column === 'PRINT') {
      //this.getPrintDetails([event.data['id']], '/api/pwa_dms_connects/get_invoice_print_details');
      this.appComponent.getPrintDetails([event.data['id']], '/api/pwa_dms_connects/get_invoice_print_details', null);
      //this.openPrintPopup([event.data['id']]);
    } else {
      if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/dms/return_invoice'], {
          relativeTo: this.route,
          queryParams: {id: rows[0].id}
        });
      }
    }
  }

  openPrintPopup(order_ids) {
    const config: MatDialogConfig = {
      data: {
        order_ids,
        type: 'invoice'
      }
    };

    const creatediaeref = this.dialog.open(BasePrintComponent, config);
  }
  printAll(rows) {
    this.appComponent.printAll(rows, 'id', '/api/pwa_dms_connects/get_invoice_print_details', null);
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
      this.showCreate = true;
    // });
  }

  openCreateDialog() {
    const default_return_invoice = {};
    default_return_invoice['id'] = 0;

    this.router.navigate([this.apiService.redirect_url + '/dms/return_invoice'], {
      relativeTo: this.route,
      queryParams: {id: 0}
    });

  }

}
