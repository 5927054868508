import {ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild,} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, DOCUMENT} from '@angular/common';

import {DomSanitizer} from '@angular/platform-browser';
import {AgmCoreModule, LatLngBounds, MapsAPILoader} from '@agm/core';
import {AdDirective3} from '../../ad3.directive';
import {ApiService} from '../../_services/api.service';
import {SharedService} from '../../_services/shared_service';
import {GridOptions} from '../../../../node_modules/@ag-grid-enterprise/all-modules';
import {ClaimMaxComponent} from '../../claimMax.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DetailExpenseComponent} from '../detail_expense/detail_expense.component';
import {ToastrService} from 'ngx-toastr';

declare let google: any;
declare let $: any;
declare let _: any;


@Component({
  selector: 'app-my_expense-component',
  templateUrl: './my_expense.component.html',
  styleUrls: ['./my_expense.component.scss']
})
export class MyExpenseComponent implements OnInit {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('detailClick') detailClick: ElementRef<HTMLElement>;
  @ViewChild('galleryClick') galleryClick: ElementRef<HTMLElement>;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  public pinnedParams = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  end_address: string;
  showtravel = true;
  showstay = false;
  showallowances = false;
  showothers = false;
  showscheme = false;
  showdamage = false;
  showTax = false;
  maxamountExceeded = false;
  masterCreated = false;
  submitClicked = false;
  travelamountdisabled = true;
  map: any;
  marker: any;
  infowindow: any;
  infowindowContent: any;
  user_id: null;
  app1_id = null;
  app2_id = null;
  cid: null;
  API_URL = '';
  access_token;
  travelRows: any = [];
  stayRows: any = [];
  allowanceRows: any = [];
  otherRows: any = [];
  schemeRows: any = [];
  damageRows: any = [];
  displayRows: any = [];
  policyLineData: any = [];
  policyLineTypes: any = [];
  photo: any;
  dailyExpenses = {
    TA: 0,
    DA: 0,
    Food: 0,
    Stay: 0,
    Others: 0,
    Total: 0
  };
  user;
  res_data;
  beatTravelTypes = [];
  expenseData = [];
  expenseMasterData: any = {};
  stopLocations: any = [];
  newExpenseData: any = {};
  expenseDetailsData;
  expensephoto = null;
  expensephotos = [];
  expensephotodata = null;
  expensephotodatas = [];
  expenseFileData = {};
  user_data = {
    date: null,
    start_date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    expense_id: null,
    se_id: null,
    salesman_id: null,
    user_id: null,
    policy_id: null,
    expense_ids: [],
    mode: null
  };
  public tabIndex = 0;
  public policy_html = ``;
  public agm_map;
  public mapEvent;
  public mapHide = false;
  public mapCenter = {lat: 0, lng: 0};
  public track;
  public map_loader;
  public isModelOpened = true;
  public start_act;
  public end_act;
  public is_act;
  public activity;
  public columnDefs = [];
  public columnDefs1 = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public apiData = [];
  public expensetypes;
  public meetexpensetypes;
  public masterexpensetypes = [];
  public rowData1 = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public datavalue;
  public data = [];
  public data1;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public styleGrid;
  public myDate = [];
  public expenseDate = 'Select Date';
  public expenseDateTime = '';
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public show_approve = false;
  public dateSelected = false;
  public pinnedTotalData = [
    {
      state: 'TOTAL',
      expense_date: null,
      claimamt: 0,
      reject_amt: 0,
      appr_amt: 0
    }];
  public icon_url = {
    url: '../../assets/retailer.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public start_url = {
    url: '../../assets/start.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public end_url = {
    url: '../../assets/end.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public gallery = [];
  public expense_act: any;
  public exp_display_dict = {
    da: 'DA',
    ta: 'TA',
    sa: 'SA',
    travel_company: 'Company Travel',
    stay_company: 'Company Stay'
  };
  public itemList = [{id: 0, itemName: 'Salesman'}];
  public selectedItems = [{id: 0, itemName: 'Salesman'}];
  public policyList = [{id: 0, itemName: 'Policy'}];
  private geoCoder;
  private prevIndex;
  private gridApi;
  private gridColumnApi;
  private gridApi1;
  private gridColumnApi1;
  columnState;
  agGridFilter;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private agmCore: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private toastr: ToastrService,
              private changeDetector: ChangeDetectorRef,
              @Inject(DOCUMENT) private _document: Document,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.expenseDate = this.datePipe.transform(new Date(), 'EEE, dd MMM');
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.start_date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.access_token = this.user_data.access_token;
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.report_flag = this.route.snapshot.params.report_flag;
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_exp_users')));
    this.policyList = this.policyList.concat(JSON.parse(localStorage.getItem('all_expenses')));

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;
    this.getBeatTravelTypes();
    this.getUserExpensePolicies();
    this.expense();
  }

  to_upperCase(str) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  }

  ngAfterViewInit() {
    $(window).on('popstate', () => {
      $('.modal').click();
    });
  }

  display_name(str) {
    if (this.exp_display_dict[str]) {
      return this.exp_display_dict[str];
    } else if (str) {
      if (str.split('_').length > 1) {
        str = str.split('_')[1];
      }
      return this.to_upperCase(str);
    } else {
      return str;
    }
  }

  userChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.user_id = event[0].id;
        this.expense();
      } else {
        this.user_data.user_id = null;
        this.expense();
      }
    }
  }

  policyChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.policy_id = event[0].id;
        this.expense();
      } else {
        this.user_data.policy_id = null;
        this.expense();
      }
    }
  }

  getBeatTravelTypes() {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.API_URL = '/api/beat_travel_types';
    this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
      .subscribe(res => {
        if (res.length > 0) {
          this.beatTravelTypes = res;
        }
      });
  }

  getUserExpensePolicies() {
    const paramObject: any = {};
    paramObject.cid = this.cid;
    paramObject.user_id = this.user_id;
    this.API_URL = '/api/exp_user_mappings';
    this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
      .subscribe(res => {
        if (res && res.length > 0) {
          this.user_data.policy_id = res[0].exp_policy_id;
          this.app1_id = res[0].user_id1;
          this.app2_id = res[0].user_id2;
        }
      });
  }

  configureData(responseData) {
    this.apiData = [];
    this.expensetypes = new Set();
    this.meetexpensetypes = new Set();
    let claimamt = 0.0;
    let reject_amt = 0.0;
    let appr_amt = 0.0;
    if (responseData !== null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      const master = responseData.master;
      const detail = responseData.detail;
      for (const key in master) {

        let indrow = {};
        let masterRow: any = {};
        const detailRow: any = {};
        let detailsLine = [];

        masterRow = master[key];
        claimamt += parseFloat(masterRow.claimamt);
        reject_amt += parseFloat(masterRow.reject_amt);
        appr_amt += parseFloat(masterRow.appr_amt);
        detailsLine = detail.filter(function(dt) {
          return dt.expense_date === masterRow.expense_date && dt.master_id === masterRow.master_id;
        });

        if (detailsLine !== null && detailsLine !== undefined && detailsLine.length > 0) {
          for (const key1 in detailsLine) {
            if (detailsLine[key1].resource_type === 'Meet') {
              detailRow['meet_' + detailsLine[key1].exp_type] = detailsLine[key1].total;
              detailRow['meet_max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;
              this.meetexpensetypes.add(detailsLine[key1].exp_type);
            } else {
              detailRow[detailsLine[key1].exp_type] = detailsLine[key1].total;
              detailRow['max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;
              this.expensetypes.add(detailsLine[key1].exp_type);
            }
          }
        }

        indrow = {
          ...masterRow,
          ...detailRow
        };
        this.apiData.push(indrow);

      }
    }

    this.pinnedTotalData = [
      {
        state: 'TOTAL',
        expense_date: 'TOTAL',
        claimamt,
        reject_amt,
        appr_amt
      }];
    this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
    // console.log(this.apiData);
    this.configureGrid();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: '',
        pinned: 'left',
        children: [
          {
            headerName: 'State', field: 'state', pinned: 'left', lockPosition: true,
            maxWidth: 20,
            valueGetter(params) {
              if (params.data) {
                if (params.data.state === 'TOTAL') {
                  return '';
                } else {
                  return params.data.state;
                }
              }

            },
            cellStyle(params) {
              if (params.data) {
                if (params.data.state === 'approved') {
                  return {background: '#379862', color: '#379862'};
                } else if (params.data.state === 'rejected') {
                  return {background: '#E24260', color: '#E24260'};
                } else if (params.data.state === 'settled') {
                  return {background: '#2D2D2D', color: '#2D2D2D'};
                } else if (params.data.state === 'new') {
                  return {background: '#22A6B3', color: '#22A6B3'};
                } else if (params.data.state === 'TOTAL') {
                  return '';
                } else {
                  return {background: '#F6A82C', color: '#F6A82C'};
                }
              }
            }
          },
          {
            headerName: 'Date', field: 'expense_date', pinned: 'left', width: 100, lockPosition: true,
            cellRenderer(params) {
              if (params.data && params.data.state === 'TOTAL') {
                const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
                return displayElem;
              } else {
                return params.value;
              }
            }
          },
          {
            headerName: 'Claimed', field: 'claimamt', cellStyle: {textAlign: 'right'}, pinned: 'left', lockPosition: true,
            width: 60,
            cellRenderer(params) {
              if (params.data) {
                if (params.data.max_claim_exceeded) {
                  const displayElem = '<span style="color:#E24260; font-weight: bold">' + params.value + '</span>';
                  return displayElem;
                } else {
                  return params.value;
                }
              }
            }
          },
        ]
      },
      {
        headerName: '',
        children: [{
          headerName: 'Rejected', field: 'reject_amt', width: 60, cellStyle: {textAlign: 'right'}
        },
          {
            headerName: 'Approved', field: 'appr_amt', width: 60, cellStyle: {textAlign: 'right'}
          },
          {
            headerName: 'Policy', field: 'policy', width: 60
          },
          {
            headerName: 'Beat', field: 'beat', width: 60
          },
          {
            headerName: 'Beat Type', field: 'beat_type', width: 60
          },
          {
            headerName: 'City Class', field: 'class_type', width: 60
          },
          {
            headerName: 'First Approver', field: 'approver1', width: 60
          },
          {
            headerName: 'Note', field: 'note', width: 200
          },
          {
            headerName: 'Comments', field: 'comments', width: 200
          },
          {
            headerName: 'Expense ID', field: 'expense_id', width: 60, hide: true
          },
          {
            headerName: 'User ID', field: 'user_id', width: 60, hide: true
          },
          {
            headerName: 'Policy Name', field: 'policy_name', width: 60, hide: true
          },
          {
            headerName: 'Status', field: 'Status', hide: true,
            width: 150,
            valueGetter(params) {
              if (params.data) {
                if (params.data.Status === 'TOTAL') {
                  return '';
                } else {
                  return params.data.Status;
                }
              }
            },
            cellStyle(params) {
              if (params.data) {
                if (params.value === 'approved') {
                  return {color: '#379862'};
                } else if (params.value === 'rejected') {
                  return {color: '#E24260'};
                } else if (params.value === 'settled') {
                  return {color: '#2D2D2D'};
                } else {
                  return {color: '#F6A82C'};
                }
              }
            },
            cellRenderer(params) {
              if (params.data) {
                if (params.data.Status === 'TOTAL') {
                  return '';
                } else {
                  const displayElem = '<span class="ml-1 status">' + params.value + '</span>';
                  return displayElem;
                }
              }
            }
          },
        ]
      },
      {
        headerName: 'User Expenses',
        children: []
      },
      {
        headerName: 'Meet Expenses',
        children: []
      },
    ];
    this.rowData = this.apiData;
    this.loaded = true;
    this.context = {componentParent: this};
    this.frameworkComponents = {
      maxClaimRenderer: ClaimMaxComponent
    };

    if (this.expensetypes) {
      for (const key of this.expensetypes) {
        this.columnDefs[2].children.push({
          headerName: key,
          field: key,
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

    if (this.meetexpensetypes) {
      for (const key of this.meetexpensetypes) {
        this.columnDefs[3].children.push({
          headerName: key,
          field: 'meet_' + key,
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

  }

  expense() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    this.API_URL = '/api/sales_expenses/my_expensesv2';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          // console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.configureData(res.results.data);
          } else {
            this.preloader = false;
            this.empty_state = true;
          }

        },
        error => {
          this.preloader = false;
        });
  }

  close() {
    this.isModelOpened = true;
  }

  view_gallery(photo) {
    this.photo = photo;
    this.isModelOpened = false;
  }

  tabClick(event) {
    // debugger;
    if (event.index === 1) {
      this.map_loader = true;
      const trackData = [];
      const datePipe = this.datePipe;
      this.res_data = this.apiService.getTrack(this.user_data);
      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          res.results.data.forEach(data => {
            if (data.log !== null) {
              data.log.forEach(tracklog => {
                if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
                  trackData.push({
                    lat: tracklog.lat,
                    lng: tracklog.lng
                  });
                }
              });
            }
          });
        }
        if (parseFloat(trackData[0].lat) > 0) {
          this.mapCenter.lat = parseFloat(trackData[0].lat);
          this.mapCenter.lng = parseFloat(trackData[0].lng);
        }
        this.track = trackData;
        this.map_loader = false;
      });

      const actData = [];

      this.res_data = this.apiService.getActivity(this.user_data);

      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(data => {
            if (parseInt(data.lat, 10) > 0 && parseInt(data.long, 10) > 0) {
              actData.push({
                id: data.retailer_id,
                lat: data.lat,
                lng: data.long,
                retailer: data.retailer_name,
                activity: data.actions,
                device_time: datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                comments: data.comments
              });
            }
          });
          if (parseFloat(actData[0].lat) > 0) {
            this.mapCenter.lat = parseFloat(actData[0].lat);
            this.mapCenter.lng = parseFloat(actData[0].lng);
          }
        }

        if (actData.length > 0) {
          this.start_act = actData[0];
          this.end_act = actData[actData.length - 1];
          actData.splice(0, 1);
          actData.splice(actData.length - 1, 1);
          this.is_act = true;
        }
        this.activity = actData;
        this.mapHide = true;
        this.foo(this.mapEvent);
      });
    } else if (event.index === 2) {
      this.empty_state1 = false;
      this.loaded1 = false;
      this.preloader1 = true;
      const beatData = [];
      const datePipe = this.datePipe;

      this.res_data = this.apiService.getBeatAct(this.user_data);

      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(function(data) {
            beatData.push({
              'Activity Type': data.activity_type,
              Beat: data.beat,
              Retailer: data.retailer,
              'Comment Type': data.comment_type,
              Comments: data.comments,
              device_time: datePipe.transform((new Date(data.device_date + ' UTC')).toString(), 'HH:mm:ss'),
              Latitude: data.latitude,
              Longitude: data.longitude,
              Distance: data.distance,
              'Created On': datePipe.transform((new Date(data.create_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
            });
          });
          this.data1 = beatData[0];
          this.rowData1 = beatData;
          for (const key in this.data1) {
            if (this.data1.hasOwnProperty(key)) {
              this.columnDefs1.push({
                headerName: key, field: key, sort: true
              });
            }
          }
          this.loaded1 = true;
        } else {
          this.preloader1 = false;
          this.empty_state1 = true;
        }
      }, error => {
        this.preloader1 = false;
      });
    }
  }

  foo(event) {
    this.mapEvent = event;
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;
    for (const mm of this.track) {
      bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    }
    this.agm_map.fitBounds(bounds);
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  onFilterChanged(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.user_data,
      searchText: this.searchValue,
      agGridFilter: this.agGridFilter
    };
  }

  onFilterModified(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.user_data,
      searchText: this.searchValue,
      agGridFilter: this.agGridFilter
    };
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        params.columnApi.autoSizeAllColumns();
      });
    });
    params.columnApi.autoSizeAllColumns();
  }

  ngOnInit() {
    this.sharedService.setHeader('My Expenses');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  changeDate(dt) {
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.expense();
  }

  setExpenseStartDate(dt) {
    this.newExpenseData.expense_date = this.datePipe.transform(this.newExpenseData.expense_date, 'yyyy-MM-dd');
  }

  setExpenseEndDate(dt) {
    this.newExpenseData.end_date = this.datePipe.transform(this.newExpenseData.end_date, 'yyyy-MM-dd');
  }

  setGSTInvoiceDate(dt) {
    this.newExpenseData.inv_date = this.datePipe.transform(this.newExpenseData.inv_date, 'yyyy-MM-dd');
  }

  configureMasterData(responseData) {
    if (responseData !== null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      const master = responseData.master;
      const detail = responseData.detail;

      if (master === null || master.length === 0) {
        this.masterCreated = false;
      } else {
        this.masterCreated = true;
        this.masterexpensetypes = responseData.detail.map(p => p.exp_type)
          .filter((type, index, arr) => arr.indexOf(type) === index);
        for (const [key, value] of Object.entries(responseData.master)) {
          this.expenseMasterData[key] = value;
        }

        if (this.expenseMasterData !== null && this.expenseMasterData !== undefined) {
          this.expenseMasterData = this.expenseMasterData[0];
        }
        let meetpresent = false;
        if (detail !== null && detail !== undefined && detail.length > 0) {
          if (!this.expenseMasterData['meet']) {
            this.expenseMasterData['meet'] = 0.0;
            this.expenseMasterData['max_claim_meet'] = 0.0;
          }
          for (const detailkey in detail) {
            if (detail[detailkey].resource_type === 'Meet') {
              meetpresent = true;
              this.expenseMasterData['meet'] += parseFloat(detail[detailkey].total);
              this.expenseMasterData['max_claim_meet'] += parseFloat(detail[detailkey].max_claim);
            }
            this.expenseMasterData[detail[detailkey].exp_type + 'amt'] = detail[detailkey].total;
            this.expenseMasterData['max_claim_' + detail[detailkey].exp_type] = detail[detailkey].max_claim;
          }
        }
        if (meetpresent) {
          this.masterexpensetypes.push('meet');
        }
      }
    }
  }

  setExpenseDate(dt) {
    this.masterCreated = false;
    this.expenseMasterData = {};
    this.newExpenseData = {};
    this.stopLocations = [];
    this.address = '';
    this.end_address = '';
    this.latitude = 0;
    this.longitude = 0;
    this.dailyExpenses = {
      TA: 0,
      DA: 0,
      Food: 0,
      Stay: 0,
      Others: 0,
      Total: 0
    };
    if (this.expenseDate.length <= 11) {
      if (this.expenseDate === this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
        this.expenseMasterData.expense_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      } else {
        this.expenseMasterData.expense_date = this.expenseDate;
      }
    } else {
      this.expenseDate = this.datePipe.transform(this.expenseDate, 'yyyy-MM-dd');
      this.expenseMasterData.expense_date = this.datePipe.transform(this.expenseDate, 'yyyy-MM-dd');

    }

    this.expenseMasterData.travel_type_id = this.beatTravelTypes[0].id;
    this.expenseMasterData.class_type = 'a';
    this.user_data.start_date = this.expenseMasterData.expense_date;
    this.user_data.last_date = this.expenseMasterData.expense_date;
    // console.log(this.user_data);
    this.API_URL = '/api/expense_masters/fetch_expensesv2';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.configureMasterData(res.results.data);
          if (this.masterCreated) {
            this.newExpenseData = {};
            this.newExpenseData.master_id = this.expenseMasterData.id;
            if (this.expenseMasterData.hasOwnProperty('company_tax_id')) {
              this.newExpenseData.company_tax_id = this.expenseMasterData.company_tax_id;
            }

            this.user_data.expense_id = this.expenseMasterData.id;
            this.res_data = this.apiService.expenseDetails(this.user_data);
            this.res_data.subscribe(async res1 => {
              if (res1.hasOwnProperty('results') && (res1.results.status === 200) && (res1.results.data.length > 0)) {
                this.expenseDetailsData = res1.results.data;
              }
            });
          }
        } else {
          this.masterCreated = false;
        }
      });

  }

  showAddExpensePopup() {
    $('#expensemasterModal').modal('show');
    this.expenseDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.setExpenseDate('');
  }

  mainexpenseclick() {
    this.policyLineTypes = [];
    this.expensephotos = [];
    if (this.expenseDate === 'Select Date') {
      this.toastr.warning('Please Select Date');
    } else {
      $('#expensemasterModal').modal('hide');
      this.travelRows = [];
      this.stayRows = [];
      this.allowanceRows = [];
      this.otherRows = [];
      this.schemeRows = [];
      this.damageRows = [];

      this.API_URL = '/api/expense_policy_lines/fetch_policy_lines';
      if (this.masterCreated) {
        this.user_data.policy_id = this.expenseMasterData.policyid;
      }
      const paramObject: any = {};
      paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
      paramObject.policy_id = this.user_data.policy_id;
      paramObject.class_type = this.expenseMasterData.class_type;
      if (this.expenseMasterData.travel_type_id !== null) {
        paramObject.beat_type_id = this.expenseMasterData.travel_type_id;
      }
      paramObject.access_token = this.access_token;
      paramObject.mode = null;
      this.apiService.post(this.API_URL, paramObject)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.policyLineData = res.results.data;
            this.policyLineTypes = this.policyLineData.map(p => p.exp_type)
              .filter((type, index, arr) => arr.indexOf(type) === index);
            for (let i = 0; i < this.policyLineData.length; i++) {
              if (this.policyLineData[i].exp_type === 'travel') {
                this.travelRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'stay') {
                this.stayRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'allowance') {
                this.allowanceRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'scheme') {
                this.schemeRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'damage') {
                this.damageRows.push(this.policyLineData[i]);
              } else {
                this.otherRows.push(this.policyLineData[i]);
              }
            }
          }
        });

      $('#expensetypepopupModal').modal('show');
    }
  }

  handleExpensetypeClick(item) {
    if (item.hasOwnProperty('tax_type') && item.tax_type !== null) {
      this.newExpenseData.tax_type = item.tax_type;

      if (item.tax_type === 'no_tax') {
        this.showTax = false;
        this.newExpenseData.has_gst = false;
      } else {
        this.showTax = true;
        this.newExpenseData.has_gst = true;
      }
    } else {
      this.showTax = false;
      this.newExpenseData.has_gst = false;
    }
    if (item.hasOwnProperty('policy_line_html')) {
      this.newExpenseData.policy_line_html = item.policy_line_html;
    }
    this.newExpenseData.exp_type = item.exp_type;
    this.newExpenseData.expense_policy_id = item.policy_id;
    this.newExpenseData.expense_line_id = item.id;
    this.newExpenseData.expense_date = this.user_data.start_date;
    this.newExpenseData.bill_mandatory = item.bill_mandatory;
    if (item.hasOwnProperty('claim_amount')) {
      this.newExpenseData.claim_amount = item.claim_amount;
    }
    if (item.hasOwnProperty('max_claim_amount')) {
      this.newExpenseData.max_claim_amount = item.max_claim_amount;
    }
    if (item.exp_type === 'stay') {
      this.newExpenseData.stay_name = 'Stay Expense';
      this.newExpenseData.stay_type = item.stay_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.stay_type;
      $('#staypopupModal').modal('show');
    } else if (item.exp_type === 'travel') {
      this.newExpenseData.travel_type = item.travel_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.travel_type;
      if (item.hasOwnProperty('config') && item.config !== null && item.config.hasOwnProperty('perkm')) {
        this.newExpenseData.perkm = item.config.perkm;
        this.travelamountdisabled = true;
      } else {
        this.travelamountdisabled = false;
      }

      $('#travelpopupModal').modal('show');
    } else if (item.exp_type === 'allowance') {
      this.newExpenseData.allowance_type = item.allowance_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.allowance_type;
      $('#allowancepopupModal').modal('show');
    } else if (item.exp_type === 'scheme') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else if (item.exp_type === 'damage') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else if (item.exp_type === 'display') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else {
      $('#otherspopupModal').modal('show');
    }

  }


  expenseTypeClick(item) {
    if (item.hasOwnProperty('allow_multiple_expense') && item.allow_multiple_expense) {
      this.handleExpensetypeClick(item);
    } else {
      if (!this.expenseDetailsData) {
        this.handleExpensetypeClick(item);
      } else {
        const expenseItems = this.expenseDetailsData.filter(d => d.expense_line_id === item.id && d.state !== 'cancelled');
        if (expenseItems !== null && expenseItems.length > 0) {
          if (expenseItems.state !== 'rejected' && expenseItems.state !== 'cancelled') {
            this.toastr.error('Multiple Expenses not allowed for this Policy');
          } else {
            this.handleExpensetypeClick(item);
          }
        } else {
          this.handleExpensetypeClick(item);
        }
      }

    }
  }

  showPolicyInfoPopup() {
    const policyinfoid = document.getElementById('policyinfo');
    policyinfoid.innerHTML = this.newExpenseData.policy_line_html;
    $('#policyinfopopupModal').modal('show');
  }

  createExpenseClickTest(data) {
  }

  createExpenseClick() {
    this.submitClicked = true;
    if (this.submitClicked) {

      if (this.newExpenseData.bill_mandatory && (this.expensephotodatas === null || this.expensephotodatas.length === 0)) {
        this.submitClicked = false;
        this.toastr.warning('Bill is Mandatory');
      } else if (this.newExpenseData.hasOwnProperty('max_claim_amount') && this.newExpenseData.max_claim_amount !== 0 && this.newExpenseData.amount > this.newExpenseData.max_claim_amount) {
        this.submitClicked = false;
        this.onAmountChange();
      } else if (this.masterCreated) {
        this.createExpense();
      } else {
        this.createMaster();
      }
    }
  }

  createMaster() {
    this.expenseMasterData.name = JSON.parse(localStorage.getItem('user')).login;
    this.expenseMasterData.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.expenseMasterData.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.expenseMasterData.access_token = this.access_token;
    this.expenseMasterData.create_uid = this.user_id;
    this.expenseMasterData.create_date = new Date();
    this.expenseMasterData.write_uid = this.user_id;
    this.expenseMasterData.write_date = new Date();
    this.expenseMasterData.state = 'new';
    this.expenseMasterData.total_claim = 0;
    this.API_URL = '/api/expense_masters';
    this.apiService.post(this.API_URL, this.expenseMasterData)
      .subscribe(res => {
        // console.log(res);
        this.masterCreated = true;
        this.expenseMasterData = res;
        this.newExpenseData.master_id = this.expenseMasterData.id;
        this.createExpense();
      });
  }

  createExpense() {
    if (this.newExpenseData.exp_type === 'allowance') {
      if (this.newExpenseData.da_type !== null) {
        this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.da_type;
      } else {
        this.newExpenseData.name = this.newExpenseData.exp_type;
      }
    }
    this.newExpenseData.submitted_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    // this.newExpenseData.expense_date = this.expenseDateTime;
    this.newExpenseData.salesman_id = JSON.parse(localStorage.getItem('user')).id;
    this.newExpenseData.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.newExpenseData.team_id = JSON.parse(localStorage.getItem('user')).sale_team_id;
    this.newExpenseData.app1_id = this.app1_id;
    this.newExpenseData.app2_id = this.app2_id;
    this.newExpenseData.app1_state = 'pending';
    this.newExpenseData.app2_state = 'pending';
    this.newExpenseData.state = 'new';
    this.newExpenseData.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.newExpenseData.access_token = localStorage.getItem('resfreshToken');
    if (this.newExpenseData.hasOwnProperty('distance') && this.newExpenseData.distance > 0) {
      this.newExpenseData.distance = this.newExpenseData.distance * 1000;
    }
    if (this.newExpenseData.exp_type === 'travel' && this.stopLocations !== null && this.stopLocations.length > 0) {
      this.newExpenseData.travel_path_locations = this.stopLocations;
    }
    if (this.expensephotodatas !== null) {
      this.newExpenseData.gallery = [];
      for (let i = 0; i < this.expensephotodatas.length; i++) {
        const imageObject: any = {};
        imageObject.datas = this.expensephotodatas[i].photodata;
        imageObject.store_fname = 'Expense_image_' + this.newExpenseData.expense_date;
        imageObject.name = imageObject.store_fname;
        if (this.expensephotodatas[i].type && this.expensephotodatas[i].type.toString().includes('pdf')) {
          imageObject.type = 'pdf';
        } else {
          imageObject.type = 'image';
        }
        this.newExpenseData.gallery.push(imageObject);
      }
    }
    this.API_URL = '/api/sales_expenses/createExpense';
    this.apiService.post(this.API_URL, this.newExpenseData)
      .subscribe(res => {
        this.submitClicked = false;
        if (this.newExpenseData.exp_type === 'stay') {
          $('#staypopupModal').modal('hide');
        }
        if (this.newExpenseData.exp_type === 'travel') {
          $('#travelpopupModal').modal('hide');
        }
        if (this.newExpenseData.exp_type === 'allowance') {
          $('#allowancepopupModal').modal('hide');
        } else {
          $('#otherspopupModal').modal('hide');
        }
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data)) {
          this.toastr.success('Expense created Successfully');
          this.newExpenseData.id = res.results.data.id;
          this._document.defaultView.location.reload();
        } else {
          this.toastr.error('Failed to create Expense');
        }
      });
  }

  openDetailDialog() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '720px',
      maxHeight: '720px',
      data: {
        expenseData: this.expenseData,
        expenseDetailsData: this.expenseDetailsData,
        rows: this.gridApi.getSelectedRows(),
        user_data: this.user_data,
        dialogType: 'user'
      }
    };
    if (this.gridOptions.columnApi) {
      this.columnState = this.gridColumnApi.getColumnState();
    }
    const creatediaeref = this.dialog.open(DetailExpenseComponent, config);
    creatediaeref.afterClosed().subscribe(result => {
      this.expense();
    });
  }

  locationPicker(locationType) {
    $('#locationpopupModal').modal('show');
    if (this.map !== null) {
      google.maps.event.clearListeners(this.map, 'click');
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation(locationType);
      this.geoCoder = new google.maps.Geocoder;
      document.getElementById('pac-input')['value'] = '';
      const input = document.getElementById('pac-input') as HTMLInputElement;
      const autocomplete = new google.maps.places.Autocomplete(input);

      let pacContainerInitialized = false;
      $('#pac-input').keypress(function() {
        if (!pacContainerInitialized) {
          $('.pac-container').css('z-index', '9999');
          pacContainerInitialized = true;
        }
      });

      if (this.infowindow === null) {
        this.infowindow = new google.maps.InfoWindow();
      }
      this.infowindowContent = document.getElementById(
        'infowindow-content'
      ) as HTMLElement;
      this.infowindow.setContent(this.infowindowContent);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          this.toastr.warning('No details available for input: \'' + place.name + '\'');
          return;
        }
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(15);
        }

        const latlng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        this.marker.setPosition(latlng);
        this.marker.setVisible(true);
        this.infowindowContent.children['place-name'].textContent = place.name;
        this.infowindowContent.children['place-address'].textContent = place.formatted_address;
        this.infowindow.open(this.map, this.marker);
        const area = this.extractFromAddress(place.address_components, 'locality');
        const zone = this.extractFromAddress(place.address_components, 'administrative_area_level_2');
      });


    });
    const confirmBtn = document.getElementById('mapsubmit');
    const outer = this;
    confirmBtn.onclick = () => {
      outer.getAddress(outer.marker.getPosition().lat(), outer.marker.getPosition().lng(), locationType);
    };
  }

  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    /*this.latitude = $event['coords'].lat;
    this.longitude = $event['coords'].lng;
    this.getAddress(this.latitude, this.longitude);*/
  }

  getAddress(latitude, longitude, locationType) {
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        // console.log(results[0])
        if (results[0]) {
          this.infowindowContent.children['place-name'].textContent = results[0].address_components[0].short_name;
          this.infowindowContent.children['place-address'].textContent = results[0].formatted_address;
          this.infowindow.open(this.map, this.marker);

          const area = this.extractFromAddress(results[0].address_components, 'locality');
          const zone = this.extractFromAddress(results[0].address_components, 'administrative_area_level_2');
          this.setExpenseLocations(latitude, longitude, results[0].formatted_address, area, zone, locationType);
        } else {
          this.toastr.error('No results found');
        }
      } else {
        this.toastr.error('Geocoder failed due to: ' + status);
      }

    });
  }

  extractFromAddress(components, type) {
    return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || null;
  }

  setExpenseLocations(lat, lng, address, area, zone, locationType) {

    if (locationType === 'start') {
      this.newExpenseData.lat = lat;
      this.newExpenseData.long = lng;
      this.address = address;
      if (area !== null) {
        this.newExpenseData.area = area;
      }
      if (zone !== null) {
        this.newExpenseData.zone = zone;
      }
    } else if (locationType === 'end') {
      this.newExpenseData.end_lat = lat;
      this.newExpenseData.end_long = lng;
      this.end_address = address;
      if (area !== null) {
        this.newExpenseData.end_area = area;
      }
      if (zone !== null) {
        this.newExpenseData.end_zone = zone;
      }
    } else {
      const stoplocation: any = {};
      stoplocation.lat = lat;
      stoplocation.long = lng;
      if (area !== null) {
        stoplocation.area = area;
      }
      if (zone !== null) {
        stoplocation.zone = zone;
      }
      stoplocation.address = address;

      this.stopLocations.push(stoplocation);
    }
   this.computeDistance();


  }

  computeDistance() {
    if (this.newExpenseData.hasOwnProperty('lat') && this.newExpenseData.lat !== null && this.newExpenseData.hasOwnProperty('end_lat') && this.newExpenseData.lat !== null) {
      const start = new google.maps.LatLng(this.newExpenseData.lat, this.newExpenseData.long);
      const end = new google.maps.LatLng(this.newExpenseData.end_lat, this.newExpenseData.end_long);

      let distance = google.maps.geometry.spherical.computeDistanceBetween(start, end);

      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: start,
        destination: end,
        waypoints: [],
        travelMode: 'DRIVING'
      };
      if (this.stopLocations !== null && this.stopLocations.length > 0) {
        for (let i = 0; i < this.stopLocations.length; i++) {
          request.waypoints.push(
            {
              location: new google.maps.LatLng(this.stopLocations[i].lat, this.stopLocations[i].long),
              stopover: true
            }
          );
        }
      }
      const outer = this;
      directionsService.route(request, function(response, status) {
        if (status === 'OK') {
          if (response.hasOwnProperty('routes') && response.routes.length > 0) {
            if (response.routes[0].hasOwnProperty('legs') && response.routes[0].legs.length > 0) {
              distance = 0;
              for (let j = 0; j < response.routes[0].legs.length; j++) {
                distance += response.routes[0].legs[j].distance.value;
              }
              // distance = response.routes[0].legs[0].distance.value;
              outer.handleDistance(distance);
            } else {
              outer.handleDistance(distance);
            }
          } else {
            outer.handleDistance(distance);
          }
        } else {
          outer.handleDistance(distance);
        }
      });


    }
  }

  handleDistance(distance) {
    if (distance !== null) {
      distance = distance / 1000;
      distance = Math.round(distance * 100) / 100;
      this.newExpenseData.distance = distance;
      this.calculateAmount();
    }
  }

  removeStop(index) {
    this.stopLocations.splice(index, 1);
    this.computeDistance();
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];
    this.expenseFileData = {};
    if (files && file) {
      const reader = new FileReader();
      this.expenseFileData['name'] = file.name;
      this.expenseFileData['type'] = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.expensephotodata = base64textString;
    this.expensephotodatas.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photodata: base64textString
    });
    this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
    this.expensephotos.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photos: this.expensephoto
    });
  }

  calculateAmount() {
    this.newExpenseData.amount = this.newExpenseData.distance * this.newExpenseData.perkm;
    this.newExpenseData.amount = Math.round(this.newExpenseData.amount * 100) / 100;
    this.onAmountChange();
  }

  onAmountChange() {
    if (this.newExpenseData.hasOwnProperty('max_claim_amount') && this.newExpenseData.max_claim_amount !== 0 && (this.newExpenseData.amount > this.newExpenseData.max_claim_amount)) {
      this.maxamountExceeded = true;
    } else {
      this.maxamountExceeded = false;
    }

    this.newExpenseData.amount = Math.round(this.newExpenseData.amount * 100) / 100;
    this.changeDetector.detectChanges();
  }

  showFilterPopup() {
    $('#filterpopupModal').modal('show');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column !== 'Date' && column !== 'Policy') {
      this.viewDetails('details');
      this.tabIndex = 0;
      if (this.prevIndex !== event.rowIndex) {
        if (event.node.selected) {
          this.show_approve = true;
          this.prevIndex = event.rowIndex;
        }
      } else {
        if (event.node.selected) {
          this.show_approve = true;
        } else {
          this.show_approve = false;
        }
        this.prevIndex = event.rowIndex;
      }
    } else if (column === 'Policy') {
      this.policy_html = event.data.policy_html + event.data.line_html;
      const el: HTMLElement = this.galleryClick.nativeElement;
      el.click();
    }
  }

  viewDetails(check) {
    const rows = this.gridApi.getSelectedRows();
    this.user_data.expense_id = rows[0].id;
    this.user_data.date = rows[0].Date;
    this.user_data.salesman_id = rows[0].user_id;
    this.expenseData = rows[0];
    let images = [];
    this.res_data = this.apiService.expenseDetails(this.user_data);
    this.res_data.subscribe(async res => {
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        this.expenseDetailsData = res.results.data;
        for (const exp of this.expenseDetailsData) {
          images = [];
          exp.asd = 1;
          exp.policy_name = rows[0].policy_name;
          exp.photo = null;
          if (exp.gallery.length > 0) {
            exp.gallery.forEach(image => {
              if (image != null) {
                images.push({store_fname: image.store_fname, container: 'expense_gallery', type: image.type});
              }
            });
            exp.gallery = [];
            if (images.length > 0) {
              const payload = {
                access_token: this.user_data.access_token,
                url: this.user_data.url,
                data: images
              };
              const res1 = await this.apiService.fetchExpenseImage(payload);
              if (res1.hasOwnProperty('result') && res1['result'].length > 0) {
                // console.log(res);
                const domSanitizer = this.domSanitizer;
                let img;
                res1['result'].forEach(photo => {
                  if (!exp.photo) {
                    if (photo.type && photo.type === 'pdf') {
                      exp.photo = './../../../assets/pdf_icon.png';
                    } else {
                      exp.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'][0].bill_datas);
                    }
                  }
                  if (photo.type && photo.type === 'pdf') {
                    img = domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + photo.bill_datas);
                  } else {
                    img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
                  }
                  exp.gallery.push(
                    {
                      photo: img,
                      type: photo.type
                    });
                });
              }
            }
          } else {
            exp.gallery = [];
          }
          if (exp.exp_type === 'travel') {
            exp.travelpath = '';
            if (!Array.isArray(exp.travel_path_locations)) {
              exp.travel_path_locations = JSON.parse(exp.travel_path_locations);
            }
            if (exp.travel_path_locations !== null) {
              for (let i = 0; i < exp.travel_path_locations.length; i++) {
                if (exp.travel_path_locations[i]['area']) {
                  exp.travelpath = exp.travelpath.concat(exp.travel_path_locations[i].area + ' => ');
                }
              }
            }
            if (exp.travelpath && exp.travelpath.length > 3) {
              exp.travelpath = exp.travelpath.slice(0, -4);
            }
          }
        }
        if (check === 'approve') {
          const el: HTMLElement = this.imageClick.nativeElement;
          el.click();
        } else {
          const el: HTMLElement = this.detailClick.nativeElement;
          el.click();
        }
      }
    }, error => {
    });
  }

  goBack(modalName) {
    $(modalName).modal('hide');
    if (modalName === '#expensetypepopupModal') {
      $('#expensemasterModal').modal('show');
    } else if (modalName === '#staypopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#travelpopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#allowancepopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#otherspopupModal') {
      $('#expensetypepopupModal').modal('show');
    }
  }

  private setCurrentLocation(locationType) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (locationType === 'start' && this.newExpenseData.lat && this.newExpenseData.long) {
          this.latitude = this.newExpenseData.lat;
          this.longitude = this.newExpenseData.long;
        } else if (locationType === 'end' && this.newExpenseData.end_lat && this.newExpenseData.end_long) {
          this.latitude = this.newExpenseData.end_lat;
          this.longitude = this.newExpenseData.end_long;
        } else {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }

        this.zoom = 15;
        if (this.map === null) {
          this.map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
              zoom: 15,
              center: {lat: this.latitude, lng: this.longitude},
              mapTypeId: 'terrain',
            }
          );
        }
        this.marker = new google.maps.Marker({
          // The below line is equivalent to writing:
          // position: new google.maps.LatLng(-34.397, 150.644)
          position: {lat: this.latitude, lng: this.longitude},
          map: this.map,
        });
        if (this.infowindow === null) {
          this.infowindow = new google.maps.InfoWindow();
        }
        this.infowindowContent = document.getElementById(
          'infowindow-content'
        ) as HTMLElement;
        this.infowindow.setContent(this.infowindowContent);
        this.infowindow.open(this.map);

        if (locationType !== 'stop') {
          this.getAddress(this.latitude, this.longitude, locationType);
        }

        this.map.addListener('click', (e) => {
          this.map.setCenter(e.latLng);
          this.marker.setPosition(e.latLng);
          this.geoCoder.geocode({
            location: {
              lat: this.marker.getPosition().lat(),
              lng: this.marker.getPosition().lng()
            }
          }, (results, status) => {

            if (status === 'OK') {
              if (results[0]) {
                this.infowindowContent.children['place-name'].textContent = results[0].address_components[0].short_name;
                this.infowindowContent.children['place-address'].textContent = results[0].formatted_address;
                this.infowindow.open(this.map, this.marker);
              }
            }
          });
        });

      });
    }
  }

}
