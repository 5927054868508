<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div >
        <div class="col-md-6 col-12 nopadding pl-0" >
        </div>
        <div class="col-md-6 col-12 pad0 text-right" >
          <div class="button-row" style="margin-bottom: 10px;">
             <span *ngIf="!showAll">
                  <a class="btn white-btn" (click)="showReports()" style="margin-right:15px;background-color: #FFFFFF">SHOW ALL</a>
             </span>
            <span *ngIf="showAll">
                  <a class="btn white-btn" (click)="showReports()" style="margin-right:15px;background-color: #FFFFFF">HIDE ALL</a>
             </span>
            <span>
                  <a class="btn white-btn" style="margin-right:15px;background-color: #FFFFFF"
                     (click)="gotoDownloads()">DOWNLOADS
                     <span style="margin-left: 2px;"><i class="far fa-arrow-to-bottom"></i></span></a>

            </span>
          </div>
        </div>
        <div class="col-md-12 col-12 nopadding pl-0" >
          <div *ngFor="let c of reportCategories;" >
            <div class="eod-list-carda"   [ngClass]="{'audit': c.category === 'Retail Audit',
     'survey': c.category === 'Survey',
     'sales': c.category === 'Sales',
     'finance': c.category === 'Finance',
     'master': c.category === 'Master',
     'productivity': c.category === 'Productivity',
     'stock': c.category === 'Stock',
     'leads': c.category === 'Leads',
     'compliance': c.category === 'Compliance',
     'multiinstance': c.category === 'Multi Instance',
     'scheme': c.category === 'Scheme',
     'targets': c.category === 'Targets',
     'kpi': c.category === 'KPI'
     }"
            >
              <div class="eod-list-carda-heading" (click)="c.show = !c.show">
                <h3 style="display:inline;text-align: center;">{{c.category}}</h3>
                <span *ngIf="c.show"
                      style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
                <span *ngIf="!c.show"
                      style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
              </div>
            </div>
            <div class="eod-list-carda" *ngIf="c.show">
              <!--<span style="display: block; font-weight: 400; font-size: 18px;margin-bottom: 5px;margin-left: 15px;">{{c}}</span>-->
              <div style="margin-top: 15px;" >
                <div class="cardgrid" >
                  <ng-container *ngFor="let r of filterReport(c.category); let i = index" class="col-md-3 eod-width">
                    <app-reportcard [title]="r.title"
                                    [id]="r.id"
                                    [code]="r.code"
                                    [type]="r.category"
                                    (reportClick)="cardReportClick(r.route)">

                    </app-reportcard>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
