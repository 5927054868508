import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-visibility-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VisibilityReportComponent extends ReportBase implements IReport, OnInit  {

  /* showableFilters={
     max_claim:false,
     policy:false,
     salesman:true,
     status:false,
     class:false,
     beat:false,
     trax_outlet:false,
     non_trax_outlet:false,
     exclude_trax_audit:false,
     region:true,
     cluster:true,
     team:true,
     territory:false,
     outlet_type: false,
     dc: false
   }*/

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Share of Shelf Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getTraxVisiblitySummary';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Salesman',
      field: 'user_name',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
      pinned: 'left'
    };

    this.columnDefs = [
      { headerName: "CLUSTER",  field: "cluster",   rowGroup: true, enableRowGroup: true, hide:true},
      { headerName: "TERRITORY",  field: "territory",   rowGroup: true, enableRowGroup: true, hide:true},
      { headerName: "SALESMAN",  field: "user_name"},
      { headerName: "SCODE",  field: "scode"},
      { headerName: "CATEGORY",  field: "category",   rowGroup: true, enableRowGroup: true},
      { headerName: "Code",  field: "o_code",   width: 60},
      { headerName: "Outlet",  field: "o_name",   enableRowGroup: true},
      { headerName: "COMPANY",  field: "company",   enableRowGroup: true},
      { headerName: "SHARE OF SHELF",  field: "sos_score"},
    ];
  }

  // loadserverdata() { this.goClicked = true;
  //   var indrow = {
  //     "category":"Scotch",
  //     "company":"DIAGEO",
  //     "share_of_shelf":"64%"
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }

}
