import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SurveyQuestionCreateComponent} from "../survey_question_create/survey_question_create.component";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-survey_answer_popup-component',
  templateUrl: './survey_answer_popup.component.html',
  styleUrls: ['./survey_answer_popup.component.scss']
})
export class SurveyAnswerPopupComponent implements OnInit {

  @Output() linkedQuestion: EventEmitter<any> = new EventEmitter<any>();

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: null
    }
  };
  API_URL = '';
  filters = {
    area: null,
    state_id: null,
    cluster_id: null,
    salesman_id: null,
  };
  states = [];
  clusters = [];
  questionData: any = {};
  edit = false;
  answerDetails: any = {};
  linkedQuestions = [];
  surveyData: any = [];

  constructor(private apiService: ApiService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.edit = dialogdata.edit;
    if (this.dialogdata.hasOwnProperty('questionData') && dialogdata.questionData != null) {
      this.questionData = dialogdata.questionData;
    }
    if (this.dialogdata.hasOwnProperty('surveyData') && dialogdata.surveyData != null) {
      this.surveyData = dialogdata.surveyData;
    }
    if (this.dialogdata.hasOwnProperty('answerData') && dialogdata.answerData != null) {
      this.answerDetails = dialogdata.answerData;
      this.user_data.data.id = dialogdata.answerData.id;
    }
    if (this.questionData != null && this.questionData.hasOwnProperty('ans') && this.questionData.ans.length > 0) {
      this.rowData = this.questionData.ans;
    }
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.defaultColDef = {
      flex: 1,
      minWidth: 110,
      headerClass: 'myagheaderwhitebold',
      filter: true,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true
    };

    this.columnDefs = [
      {headerName: "SEQUENCE", field: "sequence", width: 80},
      {headerName: "CODE", field: "code", width: 80,},
      {headerName: "QUESTIONS", field: "name", width: 400},
      {headerName: "QUESTION TYPE", field: "type"},
      {headerName: "NOTES", field: "note"},
      {
        headerName: "ANSWERS", field: "ans", width: 200,
        valueGetter(params) {
          return params.data.ans.length;
        }
      },
      {
        headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, width: 50,
        cellRenderer(params) {
          if (params._self.edit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
    ];

    this.fetchAnswerDetails();
  }

  ngOnInit(): void {
  }

  fetchAnswerDetails() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.API_URL = '/api/crm_profiling_questionnaires/get_linked_questions';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            this.linkedQuestions = res.results.data;
            this.rowData = res.results.data;

            this.loaded = true;

            if (res.results.status < 200 && res.results.msg != null) {
              this.toastr.warning(res.results.msg);
            }

          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const selectedQuestion = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Questionnaire !!",
          message1: selectedQuestion.name,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          if (selectedQuestion[0].hasOwnProperty('id')) {
            selectedQuestion[0].active = false;
            const foundQuestion = this.surveyData.questions.find(x => x.id = selectedQuestion[0].id);
            if (foundQuestion) {
              foundQuestion.active = false;
            }
            this.updateSurveyData();
          } else {
            this.linkedQuestions.splice(event.rowIndex, 1);
            const foundQuestion = this.surveyData.questions.indexOf(selectedQuestion[0]);
            this.surveyData.questions.splice(foundQuestion, 1);
            this.rowData = this.linkedQuestions;
            this.gridApi.setRowData(this.rowData);
          }

        } else {

        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "1000px",
        maxHeight: "600px",
        data: {
          edit: this.edit,
          questionData: selectedQuestion[0]
        }
      };

      const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);

      creatediaeref.componentInstance.createdQuestion.subscribe(data => {

        if (data !== []) {

          for (let i = 0; i < this.surveyData.questions.length; i++) {
            if (this.surveyData.questions[i].id === data.id) {
              this.surveyData.questions[i] = data;
            }
          }
          for (let i = 0; i < this.linkedQuestions.length; i++) {
            if (this.linkedQuestions[i].id === data.id) {
              this.linkedQuestions[i] = data;
            }
          }
          this.rowData = this.linkedQuestions;
          this.loaded = true;

          this.gridApi.setRowData(this.rowData);
          creatediaeref.close();
        }
      });
    }
  }

  closeDialog() {
   this.dialogRef.close();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  addQuestion() {
    const newQuestion: any = {};
    newQuestion.answer_id = this.user_data.data.id;
    newQuestion.note = 'child';
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "1000px",
      maxHeight: "600px",
      panelClass: 'custom-dialog-container',
      data: {
        edit: this.edit,
        questionData: newQuestion
      }
    };

    const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);
    creatediaeref.componentInstance.createdQuestion.subscribe(data => {

      if (data !== []) {
        this.surveyData.questions.push(data);
        this.linkedQuestions.push(data);
        this.rowData = this.linkedQuestions;
        this.loaded = true;

        this.gridApi.setRowData(this.rowData);
      }
      creatediaeref.close();

    });
  }

  saveQuestion() {
    this.updateSurveyData();
  }

  updateSurveyData() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.surveyData;

    if (this.surveyData.hasOwnProperty("questions")) {
      for (let i = 0; i <= this.surveyData.questions.length - 1; i++) {
        if (!this.surveyData.questions[i].hasOwnProperty("active")) {
          this.surveyData.questions[i].active = true;
        } else {
          continue;
        }
      }
    }


    this.API_URL = '/api/crm_profiling_questionnaires/retail_survey_update';
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Survey updated Successfully');
          window.location.reload();
        } else {
          this.toastr.error('Failed to update Survey');
        }
      });
  }

}
