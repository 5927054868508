import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-components-period-dropdown',
  templateUrl: './period_dropdown.component.html',
  styleUrls: ['./period_dropdown.component.scss']

})

export class PeriodDropdownComponent implements OnInit  {

  periodData :any= [];
  selectedPeriod:any={};
  periodID = 0;

  @Output() onsetPeriod: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService){

    this.periodData = JSON.parse(localStorage.getItem('jc_months'));
    this.periodData.reverse();

    var indPeriod = {"id":0,"start_date":null,"end_date":null,"period_name":"No Date"}
    this.periodData.push(indPeriod);
    console.log(this.periodData);

    if(this.periodData!=null && this.periodData.length>0)
      this.periodID = this.periodData[0].id;

  }

  ngOnInit(): void {
  }

  onPeriodChange(){
    var periodid = this.periodID;
    this.selectedPeriod = this.periodData.filter(function (period) {
      return period.id == periodid;
    });
    if(this.selectedPeriod!=null && this.selectedPeriod.length>0)
      this.selectedPeriod = this.selectedPeriod[0];

    this.onsetPeriod.emit(this.selectedPeriod);
  }

}
