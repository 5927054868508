<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div class="col-md-12 top-card">
        <div>
          <div>
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                  <div class="row">
                    <div *ngIf="!editMode && activeObject['status'] === 'draft'" class="button-row">
                      <button (click)="approveTransfer()"
                              class="btn confirm-green-btn"
                      > APPROVE
                      </button>
                    </div>
                    <div *ngIf="!editMode && activeObject['status'] === 'draft'" class="button-row">
                      <button (click)="rejectTransfer()"
                              class="btn red-btn"
                      > REJECT
                      </button>
                    </div>
                    <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                      <div class="button-row">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">

              <div class="col-md-12 pt-3 pad0">

                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">FROM DISTRIBUTOR</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['from_distributor']}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">FROM TERRITORY</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['from_territory']}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">STATUS</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['status']}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">TYPE</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['type']}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">TO TERRITORY</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['to_territory']}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">TO DISTRIBUTOR</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['to_distributor']}}</p>
                    </div>
                  </div>

                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">REQUESTED BY</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['req_by']}}</p>
                    </div>
                  </div>

                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">REQUESTED ON</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <p class="lbl3">{{activeObject['req_on']}}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div>
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 [context]="context"

                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


