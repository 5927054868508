import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SharedService} from './_services/shared_service';

@Component({
  selector: 'app-uom-select',
  template: `<span *ngIf="editable"><ng-select appendTo="body"
                        [items]="itemList"
                        bindLabel="itemName"
                        multiple="true"
                        maxSelectedItems="1"
                        [(ngModel)]="selectedUom"
                        (ngModelChange)="uomChange($event)"
  >
  </ng-select></span>
  <span *ngIf="!editable">{{selectedUom[0].itemName}}</span>`
})
export class UomComponent implements ICellRendererAngularComp {

  itemList = [];
  selectedUom = [];
  params;
  editable = false;

  agInit(params: any): void {
    this.params = params;
    if (this.params.context.componentParent.sharedService.dmsParams.new_mode ||
        this.params.context.componentParent.sharedService.dmsParams.edit_mode) {
      this.editable = true;
    } else {
      this.editable = false;
    }
    if (params.data.edit_status) {
      this.editable = false;
    }
    this.itemList = params.value;
    if (this.params.data.product_uom) {
      this.selectedUom = [{id: this.params.data.uom_id, itemName: this.params.data.product_uom}];
    } else {
      this.selectedUom = [{id: this.params.data.uom_id, itemName: this.params.data.product_uom}];
      this.params.data.product_uom = this.selectedUom[0].itemName;
      this.params.data.uom_id = this.selectedUom[0].id;
      this.params.data.factor_inv = this.selectedUom[0].factor_inv;
      this.params.data.factor = this.selectedUom[0].factor;
    }
  }

  uomChange(event) {

    if (event.length > 0) {
      this.params.context.componentParent.uomEdited = true;
      this.params.data.product_uom = event[0].itemName;
      this.params.data.uom_id = event[0].id;
      this.params.data.factor_inv = event[0].factor_inv;
      this.params.data.factor = event[0].factor;
      this.params.context.componentParent.save('edit');
    }
  }

  refresh(): boolean {
    return false;
  }
}
