import {Component, OnInit} from "@angular/core";
import {formatDate} from "@angular/common";
import {MatDialogConfig} from "@angular/material/dialog";
import {AssetsDetailsComponent} from "../assets_details/assets_details.component";
import {PageBase} from "../../pagebase/pagebase";
import * as XLSX from "xlsx-js-style";
import {AssetDeployedUploadComponent} from "./asset_deployed_upload.component";

@Component({
  selector: 'app-assets-deployed-component',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})
export class AssetsDeployedComponent extends PageBase implements OnInit {

  upload_data = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'type',
    value: 'not_reviewed',
    values: [],
    show: true
  };
  showCustomFilter1 = true;
  customFilter1: any = {
    name: 'Competitor',
    key: 'active',
    value: false,
    values: [],
    show: true
  };
  uqAttributes: any = [];

  ngOnInit(): void {
    this.sharedService.setHeader('Tagged Assets');

    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;
    this.showBaseUpload = this.sharedService.ACLcheck('feature_create_deployed_asset_upload');
    if (this.showBaseUpload) {
      this.uploadTask = false;
    } else {
      this.uploadTask = true;
    }

    this.customFilter.values = [
      {id: "not_reviewed", itemName: "Not Reviewed"},
      {id: "reviewed", itemName: "Reviewed"},
    ];
    this.customFilter1.values = [
      {id: true, itemName: "Yes"},
      {id: false, itemName: "No"}
    ];
    this.API_URL = '/api/partner_assets/get_asset_manage';


    this.showViewSelector = true;
    this.showGalleryview = true;
    this.container = 'partner_asset';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'asset_store_fname',
      name: 'asset_name',
      field1: 'asset_type',
      field2: '',
      field3: 'comments',
      status: 'status',
      statusTypes: [
        {name: "new", type: "new"},
        {name: "pending", type: "pending"},
        {name: "approved", type: "completed"},
        {name: "rejected", type: "error"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['pending']
        }
      ]
    };

    this.loadServerData();
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: "ID", field: "id", width: 100},
          {headerName: "REGION", field: "region", width: 100},
          {headerName: "REGION MGR", field: "region_manager", width: 100},
          {headerName: "CLUSTER", field: "cluster", width: 100},
          {headerName: "CLUSTER MGR", field: "cluster_manager", width: 100},
          {headerName: "STATE", field: "state", width: 100},
          {headerName: "TERRITORY", field: "territory", width: 100},
          {headerName: "TCODE", field: "tcode", width: 80},
          {headerName: "TERRITORY MGR", field: "territory_manager", width: 100},
          {headerName: "CITY", field: "city", width: 100},
          {headerName: "SALESMAN", field: "salesman", width: 100},
          {headerName: "SCODE", field: "scode", width: 80},
          {headerName: "PARTNER NAME", field: "retailer", width: 250},
          {headerName: "PARTNER CODE", field: "r_code", width: 100},
          {headerName: "PARTNER TYPE", field: "partner_type", width: 100},
          {
            headerName: "DEPLOYED ON", field: "deployed_on", width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.deployed_on) {
                  return inThis.datePipe.transform((new Date(params.data.deployed_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.deployed_on;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "DEPLOYED BY", field: "deployed_by", width: 100},
          {headerName: "ASSET", field: "asset_type", width: 100},
          {headerName: "ASSET NAME", field: "asset_name", width: 150},
          {headerName: "CONFIG JSON", field: "config_json", hide: true, width: 150},
          {
            headerName: "IMAGE", headerClass: 'myagheader', field: "url", sortable: true, filter: true, menuTabs: [], width: 100, resizable: true,
            cellRenderer: params => {
              if (params.data) {
                if (params.data.asset_store_fname === null || params.data.asset_store_fname === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            },
            valueGetter: this.generateImageUrl('asset_store_fname')
          },
          {headerName: "QTY", field: "qty", width: 80},
          {
            headerName: "BRAND", field: "brand", width: 150,
            valueGetter: params => {
              if (params.data) {
                if (params.data.config_json && params.data.config_json.hasOwnProperty('brand')) {
                  return params.data.config_json.brand;
                } else {
                  return "";
                }
              }

            }
          },
          {
            headerName: "FAMILY", field: "family", width: 150,
            valueGetter: params => {
              if (params.data) {
                if (params.data.config_json && params.data.config_json.hasOwnProperty('template')) {
                  return params.data.config_json.template;
                } else {
                  return "";
                }
              }

            }
          },
          {
            headerName: "INSTALLED DATE", field: "installed_date", width: 100,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
            }
          },
          {headerName: "BARCODE", field: "barcode", width: 80},
          {
            headerName: "LAST ACTIVITY DATE", field: "last_activity_day", width: 100,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
            }
          },
          {
            headerName: "NEXT ACTIVITY DATE", field: "next_activity_date", width: 100,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
            }
          },
          {
            headerName: "CREATE DATE", field: "create_date", width: 100,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
            }
          },
          {headerName: "RENEWAL", field: "renewal", width: 100},
          {headerName: "RENTAL", field: "rental", width: 80},
          {headerName: "COMMENTS", field: "comments"},
        ]
      },
      {
        headerName: "ATTRIBUTES",
        children: []
      }
    ];
    for (const key of this.uqAttributes) {
      const header = this.sharedService.titleCase(key);
      this.columnDefs[1].children.push(
        {headerName: header, field: 'attr_' + key, width: 80, aggFunc: 'sum'},
      );
    }
  }

  postProcessData() {

    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqAttributes = new Set();
    this.apiData.forEach(obj => {
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (const [key, value] of Object.entries(obj.config_json)) {
          if (key === 'attribute') {
            for (const [a_key, attribute] of Object.entries(value)) {
              this.uqAttributes.add(a_key);
              obj['attr_' + a_key] = attribute;
            }
          } else {
            this.uqAttributes.add(key);
            if (key.includes('assigned_on')) {
              obj['attr_' + key] = this.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
            } else if (key.includes('vendor_assigned_by')) {
              obj['attr_' + key] = obj.vendor_assigned_by;
            } else {
              obj['attr_' + key] = value;
            }
          }
        }
      }
    });
    this.rowData = this.apiData;
  }


  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    this.openDetailDialog(row);
  }

  onCellClicked(event) {

    this.openDetailDialog(event.data);
  }


  openDetailDialog(row) {

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "850px",
      maxHeight: "720px",
      data: {
        assetDetails: row,
        attributeList: this.uqAttributes
      },
      /*panelClass:'custom-dialog-container'*/
    };

    this.dialog.open(AssetsDetailsComponent, config);
  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header
    };
    this.gridApi.exportDataAsExcel(params);
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true, range: 1});
      this.upload_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true, range: 1});
      console.log(this.upload_task_data);
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadexcel() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    let unauthorised_access = false;
    const uploadData = [];
    let upload_status = "";
    for (const item of this.upload_task_data) {
      if (item.ID && item['DEPLOYED'] && item['DEPLOYED'].toString().toLowerCase() === 'true') {
        uploadData.push(item);
      }
    }
    if (uploadData && uploadData.length > 0) {
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      const ids = [];
      for (let i = 0; i < uploadData.length; i++) {
        ids.push(uploadData[i].ID);
      }
      paramObject.data = {
        ids
      };
      const resp = await this.apiService.postPromise('/api/partner_assets/mark_asset_deployed', paramObject);
      if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
        upload_status = resp['results'].data;
      } else if (resp.hasOwnProperty('results') && (resp['results'].status === 199 || resp['results'].status === 203)) {
        this.toastr.warning(upload_status);
        unauthorised_access = true;
      } else {
        if (resp['results'].msg.includes('partner_unique')) {
          this.toastr.warning('duplicate retailers are available for same task');
        }
      }
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      if (unauthorised_access) {
        this.uploadCaption = [upload_status];
      } else {
        this.uploadCaption = ['Uploading completed :  ' + upload_status['affectedRows'] + ' assets updated', 0];
      }
      this.uploading = false;
      this.upload_loader = false;
      this.upload_data = [];
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.fileUploaded = null;
      this.loadServerData();
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning("No valid data to Mark as Deployed");
    }
  }

  showUploadPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {}
      };
      const creatediaeref = this.dialog.open(AssetDeployedUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {}
      };
      const creatediaeref = this.dialog.open(AssetDeployedUploadComponent, config);
    }
  }

}
