<div class="production-sending">
  <div class="common-wrapper">
    <div class="common-container">
      <div class="main-wrapper prolist">
        <!--<div class="production-card">-->
        <!--<div class="col-md-3 padR plist">-->
        <!--<app-productionlist [value]="passedParams['TOTAL VANS']"></app-productionlist>-->
        <!--</div>-->
        <!--<div class="col-md-3 padR">-->
        <!--<app-productionlistdesc [title]="'PLACED/NOT PLACED SALE'" [unit]="'Vans'" [value]="passedParams['VANS SETTLED']+'/'+passedParams['not_placed']" [perc]="passedParams['perc']"></app-productionlistdesc>-->
        <!--</div> <div class="col-md-3 padR">-->
        <!--<app-productionlistdesc [title]="'SALE VALUE'" [unit]="''" [value]="passedParams['ORDER VALUE'].total"></app-productionlistdesc>-->
        <!--</div>-->
        <!--<div class="col-md-3 pad0">-->
        <!--<app-productionlistdesc [title]="'MTD ACHIEVEMENT'" [unit]="'K'" [value]="(passedParams['MTD ACHIEVEMENT'].total/1000).toFixed(this.apiService.decimalPolicy)" [perc]="passedParams.mtd_perc"></app-productionlistdesc>-->
        <!--</div>-->
        <!--</div>-->
        <div class="search-list">
          <div class="col-md-4 pad0">
            <div class="search">
              <span class="search-control"><img src="assets/searchicon.png"/></span>
              <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue"
                     class="search-control-area"
                     placeholder="Search">
            </div>
            <div *ngIf="no_po" class="margLR" style="margin-top: 10%;
    margin-left: 54%;">
              <button (click)="new_loadout()" class="btn confirm-blue-btn">Add Loadout</button>
            </div>
          </div>
          <div class="col-md-8 pad0">
            <div class="filters">
              <ul class="list-unstyled">
                <li class="margLR">
                      <span class="draft-state">
                          <app-status [status]="status" [style_class]="status_class"></app-status>
                      </span>
                  <!--<span class="success-state hide">-->
                  <!--<app-success></app-success>-->
                  <!--</span>-->
                </li>
                <li class="margLR">
                  <div class="date-picker">
                    <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                    <input [disabled] = "!apiloaded" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                    <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                  </div>
                </li>
                <!--                    <li class="margLR">-->
                <!--                        <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
                <!--                    </li>-->
                <li class="margLR">
                  <app-excelicon [gridOption]="gridOptions" [title]="'Production Order List'"></app-excelicon>
                </li>
                <!--<li class="margLR">-->
                <!--<img data-toggle="modal" data-target="#popupModal" src="./../../assets/upload.svg"/>-->
                <!--</li>-->
              </ul>
            </div>
          </div>
          <div class="col-md-12 pad0">
            <div class="po-default">
              <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:70vh;" class="ag-theme-balham"
                               [columnDefs]="columnDefs"
                               [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
                               [tabToNextCell]="tabToNextCell"
                               [tooltipShowDelay]="tooltipShowDelay"
                               [tooltipHideDelay]="tooltipHideDelay"
                               [frameworkComponents]="frameworkComponents" [context]="context"
                               (cellClicked)="onCellClicked($event)">
              </ag-grid-angular>
              <!--<ag-grid-angular id="myGrid1" *ngIf="loaded1" style="width: 100%;height:70vh;min-width: 145px" class="ag-theme-balham" [columnDefs]="columnDefs1"-->
              <!--[gridOptions]="gridOptions1" (gridReady)="onGridReady1($event)"-->
              <!--[tabToNextCell]="tabToNextCell"-->
              <!--[frameworkComponents]="frameworkComponents1" [context]="context1">-->
              <!--</ag-grid-angular>-->
              <div *ngIf="preloader">
                <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
                <!--<img src="../../assets/images/preloader.gif"/>-->
                <!--</div>-->
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="send-production sticky-bottom">
  <div id="overlay"></div>
  <span class="sendpopup">
      <ul class="list-unstyled">
        <li>
          <p style="font-size: medium" [style.color]="saveCaption[1] == 0 ? 'green' : 'red'">{{saveCaption[0]}}</p>
        </li>
          <li>
            <a *ngIf="status == 'Draft'" (click)="save()" class="btn white-btn"> <span class="save-icon"><img
              src="../../assets/save.png"> </span>SAVE </a>
          </li>
          <li>
            <a *ngIf="!disable_send && !direct_dispatch && status == 'Draft'" (click)="sendTOproduction(1)"
               data-toggle="modal" data-target="#polModal" class="btn confirm-blue-btn">SEND TO PRODUCTION </a>
          </li>
          <li>
            <a *ngIf="direct_dispatch && status == 'Draft'" (click)="directDispatchCall()"
               class="btn confirm-blue-btn">CONFIRM</a>
          </li>
        </ul>
  </span>
  <span class="updatepopup">
    <button
      *ngIf="(status == 'Processed' || status == 'Inprogress' || status == 'Completed') && delivery_status"
      style=" vertical-align:bottom; margin-right: 10px;"
      mat-button
      class="icon-btn-border confirm-blue-btn"
      (click)="printPicklist()"
    ><i class="fas fa-print"></i>
                      PRINT PICKLIST
                    </button>
      <a *ngIf="!disable_send && status == 'Submitted'" (click)="sendTOproduction2()" data-toggle="modal"
         data-target="#polModalUpdate" class="btn confirm-blue-btn">UPDATE PRODUCTION ORDER</a>
      <a *ngIf="dispatch_feature && (status == 'Processed' || status == 'Inprogress')" (click)="dispatchCall()"
         data-dismiss="modal" class="btn confirm-blue-btn">DISPATCH</a>
  </span>
  <span class="submitpopup hide">
      <a *ngIf="!disable_send && submit && status == 'Submitted'" data-toggle="modal" data-target="#polModalUpdate"
         class="btn confirm-blue-btn">UPDATE PRODUCTION ORDER</a>
  </span>
</div>


<!--<div class="pol-modal pol-width pol-send modal" id="polModal" role="dialog">
  <div class="modal-dialog" style="width:960px;">
    <div class="modal-content">
      <div style="padding: 15px;">
        <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
        <h2>PRODUCTION ORDER LIST</h2>
      </div>
      <div class="modal-body" style="background-color: #F3F6FD; padding: 15px;">
        <div class="pol-modal-content">
          <div>
            <label style="font-weight: 400;padding-top: 7px;">Update the list considering the instock items</label>
            <button
              style="float: right;"
              mat-button
              class="btn teal-btn"
            >Pull from SAP
            </button>
          </div>
          <div class="col-md-12 pad0" style="margin-top: 10px;">
            <div>
              <ag-grid-angular *ngIf="loadedPO" id="myGridpo" style="height: 65vh" class="ag-theme-balham"
                               [columnDefs]="columnDefsPO" [gridOptions]="gridOptionsPO"
                               (gridReady)="onGridReadyPO($event)"
                               [context]="context">
              </ag-grid-angular>
              <div *ngIf="preloaderPO">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_statePO">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-row" style="margin-top: 10px;">
        <div style="float: right; margin: 15px;">
          <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;">
           <a class="btn white-btn">DISCARD</a>
        </span>
          <button
            mat-button
            class="btn confirm-green-btn"
          >
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  </div>
</div>-->


<!-- send to production modal -->
<div class="pol-modal pol-width pol-send modal" id="polModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="pol-modal-heading">
          <h1>PRODUCTION ORDER LIST</h1>
          <label>Update the list considering the instock items</label>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>PRODUCT</label>
            </li>
            <li>
              <label>PRICE</label>
            </li>
            <li>
              <label>REQUESTED</label>
            </li>
            <li>
              <label>IN STOCK</label>
            </li>
            <li>
              <label>SEND TO PRODUCTION</label>
            </li>
          </ul>
          <div *ngIf="pop_loader">
            <img style="width:5%;position: relative;left: 41%;" src="assets/images/loader_icon.gif"/>
          </div>
          <div class="pol-content-text" *ngFor="let item of productList;let index = index;">
            <ul class="list-unstyled">
              <li>
                <h2>{{item.name}}</h2>
              </li>
              <li>
                <label>{{item.price}}</label>
              </li>
              <li>
                <label>{{item.req}}</label>
              </li>
              <li>
                <label>{{item.stock}}</label>
              </li>
              <li>
                <input (ngModelChange)="editProdCell($event, item)" class="custom-inputbox" type="text" [(ngModel)]="item.rem">
              </li>
            </ul>
          </div>
          <div *ngIf="sendConfirm" class="pol-btn">
            <a data-dismiss="modal" (click)="confirmProd()" class="popage btn confirm-blue-btn">Confirm</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- update modal -->
<div class="update-modal">
  <div class="pol-modal pol-width pol-update modal" id="polModalUpdate" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
        <div class="modal-body">
          <div class="pol-modal-heading">
            <h1>PRODUCTION ORDER LIST</h1>
            <label> Kindly enter the produced quantity</label>
          </div>
          <div class="pol-modal-content">
            <ul class="pol-content-heading list-unstyled">
              <li>
                <label>PRODUCT</label>
              </li>
              <li>
                <label>PRICE</label>
              </li>
              <li>
                <label>DC SUBMITTED QTY</label>
              </li>
              <li>
                <label>CONFIRM</label>
              </li>
            </ul>
            <div *ngIf="pop_loader">
              <img style="width:5%;position: relative;left: 41%;" src="assets/images/loader_icon.gif"/>
            </div>
            <div class="pol-content-text" *ngFor="let item of productList">
              <ul class="list-unstyled">
                <li>
                  <h2>{{item.name}}</h2>
                </li>
                <li>
                  <label>{{item.price}}</label>
                </li>
                <li>
                  <label>{{item.rem}}<span> ({{item.produced - item.rem}})</span></label>
                </li>
                <li>
                  <input (ngModelChange)="editProdCell2($event, item)" class="custom-inputbox" type="text"
                         [(ngModel)]="item.produced">
                </li>
              </ul>
            </div>
            <div *ngIf="updateConfirm" class="pol-btn">
              <a data-dismiss="modal" (click)="confirmProd2()" class="popage btn confirm-blue-btn">Confirm</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-width pol-send modal" id="createModal" role="dialog">
  <div class="modal-dialog" style="width: 600px !important;">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="pol-modal-heading">
          <h1>CREATE NEW LOADOUT</h1>
          <label *ngIf="confirmEnable">Enter The Quantity For</label><span *ngIf="!van_show" style="margin-left: 1%;
    color: #2e384d;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;">  {{van_code}}</span>
        </div>
        <div [hidden]="!van_show"
             style="width: 40%;font-size: 130%;font-weight: 500;display: inline-block;margin-top: -6%;">
          <label
            style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">VAN</label>
          <ng-template adHost2></ng-template>
        </div>
        <div style="width: 100%;font-size: 130%;font-weight: 500;">
          <label
            style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">PRODUCT</label>
          <ng-template style="position:relative;top:20px;" adHost3></ng-template>
        </div>
        <div *ngIf="confirmEnable">
          <button (click)="addProduct()" style="position: relative;margin-top: -33.5%;left: 91%;margin-left: -9%;"
                  class="btn confirm-blue-btn">ADD
          </button>
        </div>
        <div class="eod-table">
          <div class="dms_table">
            <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;" class="ag-theme-balham"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                             (gridReady)="onGridReady1($event)"
                             [frameworkComponents]="frameworkComponents" [context]="context">
            </ag-grid-angular>
          </div>
        </div>
        <div *ngIf="confirmEnable">
          <button (click)="loadConfirm()" style="position: relative;margin-top: 3%;left: 91%;margin-left: -13%;"
                  data-dismiss="modal" class="btn confirm-blue-btn">CONFIRM
          </button>
        </div>
        <!--        <div class="eod-table">-->
        <!--          <div class="dms_table">-->
        <!--            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham"-->
        <!--                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)"-->
        <!--                             [frameworkComponents]="frameworkComponents" [context]="context">-->
        <!--            </ag-grid-angular>-->
        <!--            <div *ngIf="preloader">-->
        <!--              <app-loadingstate></app-loadingstate>-->
        <!--            </div>-->
        <!--            <div *ngIf="empty_state">-->
        <!--              <app-emptystate></app-emptystate>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="pol-modal-content" style="margin-top: 7%;">-->
        <!--          <ul class="pol-content-heading list-unstyled">-->
        <!--            <li>-->
        <!--              <label>PRODUCT</label>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--          <div *ngIf="pop_loader">-->
        <!--            <img style="width:5%;position: relative;left: 41%;" src="assets/images/loader_icon.gif"/>-->
        <!--          </div>-->
        <!--          <div class="pol-content-text" *ngFor="let item of productList;let index = index;">-->
        <!--            <ul class="list-unstyled">-->
        <!--              <li>-->
        <!--                <h2>{{item.name}} ({{item.code}})</h2>-->
        <!--              </li>-->
        <!--              <li style="padding-left: 100px;">-->
        <!--                <input (ngModelChange)="editProdCell($event, item)" class="custom-inputbox" type="text" [(ngModel)]="item.req">-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--          <div class="pol-btn">-->
        <!--            <a data-dismiss="modal" (click)="confirmProd()" class="popage btn confirm-blue-btn">Confirm</a>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>

<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  *ngFor="let order_detail of all_orders">
            <app-picklist-print [order_detail]="order_detail"></app-picklist-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>

<button #newLoadout id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#createModal"></button>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>
