<div class="dash-card">

<!--    <a class="ui left corner label">-->
<!--        <i class="circle icon" [ngClass]="{'green':inRun, 'yellow':!inRun}"></i>-->
<!--    </a>-->

    <app-gadget-header
            (mouseover)="showGadgetControls(true)"
            (mouseleave)="showGadgetControls(false)"
            [globalOptions]="globalOptions"
            [title]="title"
            [inRun]="inRun"
            [inConfig]="inConfig"
            [actionInitiated]="actionInitiated"
            [showOperationControls]="showOperationControls"
            [gadgetHasOperationControls]="gadgetHasOperationControls"
            [showConfigurationControl]="showConfigurationControl"
            [showControls]="showControls"
            [showDeleteControl]="showDeleteControl"
            (toggleConfigModeEvent)="toggleChartProperties()"
            (removeEvent)="remove()"
            (runEvent)="run()"
            (stopEvent)="stop()">
    </app-gadget-header>

      <div *ngIf="!inConfig && dataUpdated" style="min-height: 300px">


       <div>
         <ng-container *ngFor="let item of treemapPath; let last = last;">
           <button class="ui blue basic button" [class.active]="last" [disabled]="last" (click)="treemapSelect(item)"> {{item.name}} ({{item.value}}) </button>
           <span *ngIf="!last"> / </span>
         </ng-container>
        </div>


        <ngx-charts-tree-map
          [scheme]="colorScheme"
          [results]="treemap1"
          (select)="treemapSelect($event)" >
        </ngx-charts-tree-map>
    </div>

    <!-- use the side bar content -->
    <div *ngIf="globalOptions.displayGadgetOptionsInSideBar == true" class="ui sidebar left vertical menu"
         #chartOptionsSideBar_tag>
        <br>
        <div class="ct-title">{{title}}</div>
        <br>
        <app-dynamic-form
                [gadgetTags]="gadgetTags"
                [propertyPages]="propertyPages"
                [instanceId]="instanceId"
                (updatePropertiesEvent)="updateProperties($event)">
        </app-dynamic-form>
    </div>


    <app-dynamic-form *ngIf="inConfig && globalOptions.displayGadgetOptionsInSideBar == false"
                      [gadgetTags]="gadgetTags"
                      [propertyPages]="propertyPages"
                      [instanceId]="instanceId"
                      (updatePropertiesEvent)="updateProperties($event)">
    </app-dynamic-form>

    <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
    </app-error-handler>

<!--    <app-vis-drill-down-modal #drillDownPopUp></app-vis-drill-down-modal>-->

</div>


