import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-retail_audit_sku_create-component',
  templateUrl: './retail_audit_sku_create.component.html',
  styleUrls: ['./retail_audit_sku_create.component.scss']
})
export class RetailAuditSKUCreateComponent implements OnInit {

  @Output() selectedProducts: EventEmitter<any> = new EventEmitter<any>();

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  user_data = {
    date: null,
    access_token: null,
    url: null
  };
  API_URL = '';
  productData = [];
  selectedProduct = [];
  type;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.type = dialogdata.type;
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      /* getRowStyle: params => {
         if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1){
           return { background: 'red' };
         }
         else {
           return {}
         }
       },*/
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.columnDefs = [
      {headerName: "Code", field: "code", headerCheckboxSelection: true, checkboxSelection: true, flex: 1},
      {headerName: "DETAIL NAME", field: "name",  hide: true},
      {headerName: "Name", field: "prod_name", flex: 3},
      {headerName: "Category ID", field: "categ_id", hide: true,  },
      {headerName: "Category", field: "categ_name", flex: 1 },
      {headerName: "Brand ID", field: "brand_id", hide: true  },
      {headerName: "Brand", field: "brand_name", flex: 2 },
      {headerName: "Template ID", field: "temp_id", hide: true,  },
      {headerName: "Template", field: "temp_name", flex: 2},
    ];

    if (this.type === 'tertiary') {
      this.columnDefs.push({headerName: "Competitor", field: "competitor", flex: 2 });
    }

    this.fetchProducts();
  }

  ngOnInit(): void {
  }

  fetchProducts() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    console.log(this.user_data);
    this.API_URL = '/api/audit_masters/get_products';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.productData = res.results.data[0].products;
            if (this.type === 'tertiary') {
              this.getCompetitorProducts();
            } else {
              this.rowData = this.productData;
              this.loaded = true;
            }
          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  getCompetitorProducts() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    console.log(this.user_data);
    this.API_URL = '/api/audit_masters/get_competitor_products';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.productData.push(...res.results.data[0].products);
            this.rowData = this.productData;
            this.loaded = true;

          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.preloader = false;

    params.api.setRowData(this.rowData);
    window.addEventListener('resize', () => {
      setTimeout(function() {
        if (screen.width > 991) {
          this.gridApi.sizeColumnsToFit();
        } else {
          this.gridColumnApi.autoSizeColumns();
        }
      });
    });

   /* if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }*/

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  addProducts() {
    this.selectedProduct = this.gridApi.getSelectedRows();
    //console.log(this.selectedProduct);
    this.selectedProducts.emit(this.selectedProduct);
    this.dialog.closeAll();
  }

}
