import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";
import {ErrorHandler} from "../../../error/error-handler";

@Component({
  selector: 'app-retailer-attributes-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class RetailerAttributeUploadComponent extends BaseUpload {

  ColumnsWithData = [];
  unique_column = '';
  PartnerLogs = [];

  async setViewParams() {
    this.header = 'Customer Fields Update';
    this.sampleText1 = 'DOWNLOAD ALL CUSTOMER LIST';
    this.sampleText2 = 'DOWNLOAD SAMPLE TEMPLATE';


    this.excel_columns = [{C: 'A', H: 'ID', V: '23434', field: 'id', required: true},
      {C: 'B', H: 'Code', V: 'C001', field: 'code', required: true},
      {C: 'C', H: 'Territory', V: 'TTY01', required: true, T: 'FK', field: 'territory', DBF: 'territory_id'},
      {C: 'D', H: 'Name', V: 'Shobha Stores Pvt Ltd', required: false, T: 'text', field: 'name', DBF: 'name', validation: { null_check: true}},
      {C: 'E', H: 'Type', V: 'Wholesale', required: false, T: 'FK', field: 'type', DBF: 'retailer_type', validation: { null_check: true}},
      {C: 'F', H: 'Class', V: 'a', required: false, T: 'text', field: 'class_type', DBF: 'class_type'},
      {C: 'G', H: 'Mobile', V: '9480439477', required: false, T: 'int', field: 'mobile', DBF: 'mobile', validation: { null_check: false, regex: '^[6-9]\\d{9}$'}},
      {C: 'H', H: 'Promoter', V: 'Santosh', required: false, T: 'FK', field: 'promoter', DBF: 'user_id'},
      {C: 'I', H: 'Program', V: 'Rang Raj', required: false, T: 'FK', field: 'program', DBF: 'program_type'},
      {C: 'J', H: 'Price List', V: 'ClassA Price 1', required: false, T: 'FK', field: 'pricelist_name', DBF: 'pricelist_id'},
      {C: 'K', H: 'Latitude', V: '13.5899', required: false, T: 'numeric', field: 'lat', DBF: 'partner_latitude', validation: {null_check: false, regex: '/^-?\\d*\\.?\\d+$/'}},
      {C: 'L', H: 'Longitude', V: '75.699', required: false, T: 'numeric', field: 'long', DBF: 'partner_longitude'},
      {C: 'M', H: 'Key Outlet', V: '[1/0/True/False]', required: false, T: 'boolean', field: 'key_outlet'},
      {C: 'N', H: 'Payment Mode', V: '[Cash/Credit/Credit Only]', required: false, T: 'selection', field: 'payment_mode'
        , selection_val: [{key: 'cash', text: 'Cash'}, {key: 'credit', text: 'Credit Without Payment'}, {key: 'cash_and_credit', text: 'Credit'}]},
      {C: 'O', H: 'Credit Days', V: '15', required: false, T: 'int', field: 'cl_days', DBF: 'cl_days'},
      {C: 'P', H: 'Credit Limit', V: '50000', required: false, T: 'numeric', field: 'credit_limit', DBF: 'credit_limit'},
      {C: 'Q', H: 'GSTN', V: '29AALCA..', required: false, T: 'text', field: 'gst_id', DBF: 'gst_id', validation: { null_check: false, regex: '^[6-9]\\d{9}$'}},
      {C: 'R', H: 'PAN', V: 'AALCA4969J', required: false, T: 'text', field: 'pan', DBF: 'pan', validation: { null_check: false, regex: '^[6-9]\\d{9}$'}},
      {C: 'S', H: 'Street 1', V: '1 MG Road', required: false, T: 'text', field: 'street', DBF: 'street'},
      {C: 'T', H: 'Street 2', V: 'Malleshwaram', required: false, T: 'text', field: 'street2', DBF: 'street2'},
      {C: 'U', H: 'City', V: '1 MG Road', required: false, T: 'text', field: 'city', DBF: 'city'},
      {C: 'V', H: 'District', V: 'Bengaluru urban', required: false, T: 'FK', field: 'zone', DBF: 'zone_id'},
      {C: 'W', H: 'State', V: 'Karnataka', required: false, T: 'FK', field: 'state', DBF: 'state_id'},
      {C: 'X', H: 'Zip', V: 'zip', required: false, T: 'int', field: 'zip', DBF: 'zip'},
      {C: 'Y', H: 'Actions', V: 'Return,Replace', required: false, T: 'json', field: 'actions', DBF: 'param_json'},
      {C: 'Z', H: 'Visit Type', V: '[daily/Weekly]', required: false, T: 'json', field: 'visit_type', DBF: 'param_json'},
      {C: 'AA', H: 'Visit Frequency', V: '4', required: false, T: 'json', field: 'visit_freq', DBF: 'param_json', validation: { null_check: false, regex: '^[6-9]\\d{9}$'}},
    ];

    this.step1Text = 'Download all customer list or sample template for reference.';
    this.step2Text = 'Required fields to update are code & field to be updated  ';
    this.actionText = 'Update Customer attributes to matching retailer Code';
    // this.baseData = this.sampleData1;
    await this.fetchCustomerData();
    await this.fetchBeatsData();
    await this.fetchPriceList();
    this.dataLoaded = true;
  }

  sampleDownload1() {
    if (this.customerList.length > 0) {
      this.sampleData1 = [];
      this.customerList.forEach(row => {
        const data = {};
        this.excel_columns.forEach(col => {
          if (col.field) {
            if (row[col.field] !== undefined && row[col.field] !== null) {
              data[col.H] = row[col.field];
            } else {
              data[col.H] = '';
            }
          }
        });
        this.sampleData1.push(data);
      });

    }
    this.downloadExcel(this.sampleData1, 'customer_attributes_update');
  }

  sampleDownload2() {
    this.downloadSampleData('attributes_template');
  }
  configureGrid() {

  }

  async fetchCustomerData() {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_customers_for_dms', this.userData );
    if (res && res.results.status === 200) {
      this.customerList =  res.results.data;
    } else {
      this.toastr.error('Error fetching customer master');
    }
  }


  async fetchBeatsData() {
    const res = await this.apiService.postPromise('/api/beat_plans/get_beat_plans_list_for_dropdown', this.userData );
    if (res && res.results.status === 200) {
      this.beatList =  res.results.data;
    } else {
      this.toastr.error('Error fetching Beat master');
    }
  }
  async fetchPriceList() {
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/getpriceListMaster', this.userData );
    if (res && res.results.status === 200) {
      this.priceList =  res.results.data;
    } else {
      this.toastr.error('Error fetching Price list master');
    }
  }

  OrgFieldRenderer(col_type, org_field, field) {
    return (params) => {
      if (params.data) {
        const cmp_res = this.compareDatav2(col_type, params.data, org_field, field);
        if (cmp_res === 3) {
          return {color: 'red'};
          // return '<span style="color:#E24260; font-weight: bold">' + (params.value !== undefined) ? params.value : '' + '</span>';
        } else if (cmp_res === 1) {
          return {color: 'blue'};
          // return '<span style="color:#0363c2; font-weight: bold">' + (params.value !== undefined) ? params.value : '' + '</span>';
        } else {
          return;
        }
      } else {
        return;
      }
    };
  }

  NewFieldRenderer(col_type, org_field, field) {
    return (params) => {
      if (params.data) {
        const cmp_res = this.compareDatav2(col_type, params.data, org_field, field);
        if (cmp_res === 2) {
          return {color: 'green'};
          // return '<span style="color:#0c6702; font-weight: bold">' + (params.value !== undefined) ? params.value : '' + '</span>';
        } else if (cmp_res === 1) {
          // return '<span style="color:#0363c2; font-weight: bold">' + (params.value !== undefined) ? params.value : '' + '</span>';
          return {color: 'blue'};
        } else {
          // return params.value;
          return;
        }
      } else {
        return;
      }
    };
  }

  prepareGridColumns(dataRows) {

    if (dataRows[0].hasOwnProperty('Id') || dataRows[0].hasOwnProperty('id') || dataRows[0].hasOwnProperty('ID')) {
      this.unique_column = 'id';
      this.columnDefs.push({headerName: 'ID', field: 'id', pinned: 'left'});
    } else {
      this.unique_column = 'Territory & Code';
      this.columnDefs.push(
        {headerName: 'ID', field: 'id', pinned: 'left'},
        {headerName: 'Code', field: 'code', pinned: 'left'},
        {headerName: 'Territory', field: 'territory', pinned: 'left'},
      );
    }

    this.excel_columns.forEach(tmpField => {

      let dataFound = false;
      dataRows.forEach(row => {
        if (tmpField.required === false && row[tmpField.H] !== undefined && row[tmpField.H] !== '' && row[tmpField.H] !== 'undefined') {
          dataFound = true;
        }
      });
      if (!tmpField.required && dataFound) {
        this.columnDefs.push({headerName: tmpField.H, children: [{headerName: 'OLD', field: 'org_' + tmpField.H, cellStyle: this.OrgFieldRenderer( tmpField.T, 'org_' + tmpField.H, tmpField.field)},
                                                                  {headerName: 'NEW', field: tmpField.field, cellStyle: this.NewFieldRenderer(tmpField.T, 'org_' + tmpField.H, tmpField.field)}]});
        this.ColumnsWithData.push(tmpField);
      }
    });
  }

  isNullOrEmpty(value) {
    return (value === undefined || value === null || value === 'null' || value === '');
  }
  SetInvalidStatus(row, errMsg) {
    row.valid = 'Invalid';
    if (row.hasOwnProperty('errorMsg')) {
      row.errorMsg += '\n';
    }
    row.errorMsg += errMsg;
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.prepareGridColumns(this.uploaded_data);

    this.uploaded_data.forEach(data => {

      const indrow: any = {};
      let rowModified = false;
      indrow['id'] = this.getExcelFieldValue(data, ['Id', 'ID', 'id']);
      indrow['code'] = this.getExcelFieldValue(data, ['Code', 'CODE', 'code']);
      indrow['territory'] = this.getExcelFieldValue(data, ['Territory', 'TERRITORY', 'territory']);

      let customerObject = {};
      let territoryObject = {};
      if (this.unique_column === 'id') {
        if (indrow['id'] !== undefined && Number(indrow['id']) > 0) {
          customerObject = inThis.customerList.find(x => (x.id === Number(indrow['id'])));
          if (!customerObject) {
            this.SetInvalidStatus(indrow, 'No Matching record for Id ' + Number(indrow['id']));
          }
        } else {
          this.SetInvalidStatus(indrow, ' [Id] is mandatory');
        }
      } else {
        if (indrow['territory'] !== undefined && indrow['territory'] !== '') {
          territoryObject = inThis.territoriesList.find(x => ((x.hasOwnProperty('code') && x.code === indrow['territory']) || (x.hasOwnProperty('itemname') && x.itemname === indrow['territory'])));
        }
        if (indrow['code'] !== undefined && indrow['code'] !== '' && territoryObject !== undefined) {
          customerObject = inThis.customerList.find(x => (x.hasOwnProperty('code') && x.code === indrow['code'] && x.hasOwnProperty('territory_id') && x.territory_id === territoryObject['id']));
        }

        if (customerObject === undefined || !customerObject.hasOwnProperty('id')) {
          this.SetInvalidStatus(indrow, ' [Id] OR [Code,Territory] is mandatory');
        } else {
          indrow['id'] = customerObject['id'];
          if (customerObject.hasOwnProperty('param_json') && !this.isNullOrEmpty(customerObject['param_json'])) {
            Object.entries(customerObject['param_json']).forEach(([key, value]) => {
              customerObject[key] = value;
            });
          }
        }
      }
      inThis.ColumnsWithData.forEach(col => {
        indrow[col.field] = data[col.H];
        if (customerObject && customerObject[col.field]) {
          indrow['org_' + col.H] = customerObject[col.field];
        }
        const compare_res = this.compareDatav2(col.T, indrow, 'org_' + col.H, col.field);
        if (compare_res !== 0) {
          rowModified = true;

          if (col.T === 'FK') {
            indrow[col.DBF] = this.getLookupId(col.DBF, indrow[col.field]);

            if (col.validation && col.validation.null_check && col.validation.null_check === true) {
              if (indrow[col.DBF] === undefined || indrow[col.DBF] <= 0) {
                this.SetInvalidStatus(indrow, 'Mandatory Field: ' + col.H);
              }
            } else if ((indrow[col.field] && indrow[col.field] !== '') && (indrow[col.DBF] === undefined || indrow[col.DBF] <= 0)) {
              this.SetInvalidStatus(indrow, 'No Matching entry found for: ' + col.H);
            }
          } else if (col.T === 'selection') {
            if (indrow[col.field] !== undefined && indrow[col.field] !== '' && indrow[col.field] === null && indrow[col.field] === 'null') {
              if (!this.validateSelection(indrow[col.field], col.selection_val)) {
                let valid_text = '';
                col.selection_val.forEach(k => {
                  valid_text = valid_text + k.text;
                });
                this.SetInvalidStatus(indrow, 'Expected Text: ' + valid_text);
              }
            }
          } else if (col.T === 'numeric') {
            if (indrow[col.field] !== undefined && indrow[col.field] !== '' && indrow[col.field] === null && indrow[col.field] === 'null') {
              if (!this.validateDecimalNumberUsingMatch(indrow[col.field])) {
                this.SetInvalidStatus(indrow, 'Expected Numeric value for field: ' + col.H);
              }
            }
          } else if (col.T === 'boolean') {
            if (indrow[col.field] !== undefined && indrow[col.field] !== '' && indrow[col.field] === null && indrow[col.field] === 'null') {
              if (!(indrow[col.field].toLowerCase() === 'true' || indrow[col.field].toLowerCase() === 'false' || indrow[col.field].toLowerCase() === '1' || indrow[col.field].toLowerCase() === '0')) {
                this.SetInvalidStatus(indrow, 'Expected boolean value for field: ' + col.H);
              } else {
                indrow[col.field] = indrow[col.field].toLowerCase();
              }
            }
          } else if (col.T === 'json') {

            if (indrow[col.field] !== undefined && indrow[col.field] !== '' && indrow[col.field] === null && indrow[col.field] === 'null') {
              if (!(indrow[col.field].toLowerCase() === 'true' || indrow[col.field].toLowerCase() === 'false' || indrow[col.field].toLowerCase() === '1' || indrow[col.field].toLowerCase() === '0')) {
                this.SetInvalidStatus(indrow, 'Expected boolean value for field: ' + col.H);
              } else {
                indrow[col.field] = indrow[col.field].toLowerCase();
              }
            }
          } else {
            if (col.validation && col.validation.null_check && col.validation.null_check === true) {
              if (indrow[col.field] === undefined || indrow[col.field] === '' || indrow[col.field] === null || indrow[col.field] === 'null') {
                this.SetInvalidStatus(indrow, 'Mandatory Field: ' + col.H);
              }
            }
          }
        }
      });


      if (!rowModified) {
        indrow.valid = 'No Change';
      } else {

        if (indrow.valid === 'Invalid') {
          this.errorRecords++;
        } else {
          indrow.valid = 'Valid';
          this.validRecords++;
        }
      }

      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
  compareData(col_type, rowData, org_field, field) {
    if (col_type === 'numeric' || col_type === 'int') {
      if (rowData[org_field] === undefined && rowData[field] === undefined) {
        return true;
      } else {
        return (Number(rowData[org_field]) === Number(rowData[field]));
      }
    } else if (col_type === 'boolean') {
      if (rowData[org_field] === undefined && rowData[field] === undefined) {
        return true;
      } else {
        return (Boolean(rowData[org_field]) === Boolean(rowData[field]));
      }
    } else {
      return (rowData[org_field] === rowData[field]);
    }
  }

  //0 = Equal
  //1 = Change
  //2 = New
  //3 = remove
  compareDatav2(col_type, rowData, org_field, field) {
    if (this.isNullOrEmpty(rowData[org_field]) && this.isNullOrEmpty(rowData[field])) {
      return 0;
    } else if (this.isNullOrEmpty(rowData[org_field]) && !this.isNullOrEmpty(rowData[field])) {
      return 2;
    } else if (!this.isNullOrEmpty(rowData[org_field]) && this.isNullOrEmpty(rowData[field])) {
      return 3;
    } else {
      if (col_type === 'numeric' || col_type === 'int') {
          return (Number(rowData[org_field]) === Number(rowData[field])) ? 0 : 1;
      } else if (col_type === 'boolean') {
          return (this.getBoolean(rowData[org_field]) === this.getBoolean(rowData[field])) ? 0 : 1;
      } else {
        //Do not do strict compare, to avoid numeric and string conversion
        return (rowData[org_field] == rowData[field]) ? 0 : 1;
      }
    }
  }

  getBoolean(value) {
    return (value === true || value.toLowerCase() === 'true' || value.toLowerCase() === 'no' || value === '0' ) ? true : false;
  }

  validateDecimalNumberUsingMatch(input) {
    // Use the match() method with a regular expression
    const isDecimal = input.match(/^-?\d*\.?\d+$/);

    // Return true if it's a valid decimal number, otherwise return false
    return isDecimal !== null;
  }

  validateSelection(input, selection_vals) {
    const s = selection_vals.find(x => x.key === input || x.text === input);
    if (s) {
      return true;
    } else {
      return false;
    }
  }

  async upload() {
    this.uploadStarted = true;
    this.progress = 0;
    let index = 0;
    this.totalRecords = this.rowData.length;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = [];
    const log_messages = [];
    this.completedRecords = 0;
    const validRecords = this.rowData.filter(x => x.valid === 'Valid' && x.id > 0);
    if (validRecords && validRecords.length === 0) {
      this.toastr.error("No Valid Records to upload");
    }
    let has_error = false;
    const primitiveColumnsWithData = this.ColumnsWithData.filter(x => x.T !== 'json');
    index = 0;
    do {
      const col = primitiveColumnsWithData[index];
      if (col.T !== 'json') {
        const apidata = {
          column_name: col.field,
          column_data: []
        };
        if (col.hasOwnProperty('DBF')) {
          apidata.column_name = col.DBF;
        }
        const tmp_log_message = [];
        validRecords.forEach(vr => {
          const compare_res = this.compareDatav2(col.T, vr, 'org_' + col.H, col.field);
          // if (vr[col.field] !== vr['org_' + col.H]) {
          if (compare_res !== 0) {
            if (col.T === 'FK') {

              if (!this.isNullOrEmpty(vr[col.DBF]) && vr[col.DBF] > 0) {
                apidata.column_data.push({id: vr.id, value: vr[col.DBF]});
              } else {
                if (!col.validation || col.validation.null_check === false) {
                  apidata.column_data.push({id: vr.id, value: undefined});
                } else {
                  //   SKIP
                }
              }
            } else if (col.T === 'boolean') {
              apidata.column_data.push({id: vr.id, value: this.getBoolean(vr[col.field])});
            } else if (col.T === 'numeric') {
              apidata.column_data.push({id: vr.id, value: Number(vr[col.field])});
            } else {
              apidata.column_data.push({id: vr.id, value: vr[col.field]});
            }
            tmp_log_message.push({id: vr.id, body: col.H + ': ' + vr['org_' + col.H] + '->' + vr[col.field] });
          }
        });

        // await this.progressUpdateColumn(index, gridRowsToUpdate, 'updating ' + col.H);

        if (apidata.column_data.length > 0) {
          const success = await this.UpdatePartnerColumns(apidata, tmp_log_message);
          has_error = !success;
          // this.API_URL = '/api/pwa_dms_connects/upload_customer_attributes';
          // paramObject.data = apidata;
          // const res = await this.apiService.postPromise(this.API_URL, paramObject);
          // if (res.hasOwnProperty('results') && res.results.status === 200) {
          //   if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {
          //
          //     tmp_log_message.forEach(m => {
          //       const log = log_messages.find(x => x.id === m.id);
          //       if (log) {
          //         log.body += m.body;
          //       } else {
          //         log_messages.push({id: m.id, body : m.body});
          //       }
          //     });
          //
          //   } else {
          //     this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, No records got updated. Contact support'));
          //     has_error = true;
          //   }
          // } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
          //   this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          //   has_error = true;
          // } else {
          //   this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          //   has_error = true;
          // }
        }
      }
      index++;
    }
    while (index < primitiveColumnsWithData.length && !has_error) ;
    const columnsWithJSONData = this.ColumnsWithData.filter(x => x.T === 'json');
    if (columnsWithJSONData.length > 0) {
      const apidata = {
        column_name: 'param_json',
        column_data: []
      };
      const data_to_log = [];
      validRecords.forEach(vr => {
        const jsonData = {};
        columnsWithJSONData.forEach(col => {

          const compare_res = this.compareDatav2(col.T, vr, 'org_' + col.H, col.field);
          if (compare_res !== 0) {
            jsonData[col.field] = vr[col.field];
            data_to_log.push({id: vr.id, body: col.H + ': ' + vr['org_' + col.H] + '->' + vr[col.field] });
          }
        });
        if (data_to_log.length > 0) {
          apidata.column_data.push({id: vr.id, value: jsonData});
        }
      });

      if (apidata.column_data.length > 0) {
        // this.API_URL = '/api/pwa_dms_connects/upload_customer_attributes';
        // paramObject.data = apidata;
        // const res = await this.apiService.postPromise(this.API_URL, paramObject);
        // if (res.hasOwnProperty('results') && res.results.status === 200) {
        //   if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {
        //
        //     tmp_log_message.forEach(m => {
        //       const log = log_messages.find(x => x.id === m.id);
        //       if (log) {
        //         log.body += m.body;
        //       } else {
        //         log_messages.push({id: m.id, body : m.body});
        //       }
        //     });
        //
        //     await this.logMessages(log_messages);
        //
        //   } else {
        //     this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, No records got updated. Contact support'));
        //   }
        // } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
        //   this.handleError(ErrorHandler.getErrorObject(res.results.msg));
        //   has_error = true;
        // } else {
        //   this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        // }
        const success = await this.UpdatePartnerColumns(apidata, data_to_log);
        has_error = !success;
      }
    }

    await this.LogPartnerMessages();

    this.progress = 100;
    this.uploadCompleted = true;
  }


  async UpdatePartnerColumns(apidata, records_to_log) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    if (apidata.column_data.length > 0) {
      paramObject.data = apidata;
      const res = await this.apiService.postPromise('/api/pwa_dms_connects/upload_customer_attributes', paramObject);
      if (res.hasOwnProperty('results') && res.results.status === 200) {
        if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

          records_to_log.forEach(m => {
            const log = this.PartnerLogs.find(x => x.id === m.id);
            if (log) {
              log.body += m.body;
            } else {
              this.PartnerLogs.push({id: m.id, body : m.body});
            }
          });

          return true;
        } else {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, No records got updated. Contact support'));
          return false;
        }
      } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
        this.handleError(ErrorHandler.getErrorObject(res.results.msg));
        return false;
      } else {
        this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        return false;
      }
    }
  }


  async LogPartnerMessages() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject['messages'] = this.PartnerLogs;
    paramObject['model'] = 'res.partner';
    this.API_URL = '/api/pwa_admin_connects/add_mail_messages';
    if (this.PartnerLogs.length > 0) {
      const res = await this.apiService.postPromise(this.API_URL, paramObject);
      if (res.hasOwnProperty('results') && res.results.status === 200) {
        if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {
          this.toastr.success('Changes Logged successfully');

        } else {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, No records got updated. Contact support'));
        }
      } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
        this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      } else {
        this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
      }
    }
  }

  async progressUpdateColumn(index, rowsToUpdate, status) {
    if (this.gridOptions && this.gridOptions.api) {
      rowsToUpdate.forEach(r => { r.status = status; });
      this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
      });
    }
    this.progress = Math.round((index / this.ColumnsWithData.length) * 100);
    this.completedRecords = index;
  }
}
