<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div style="width: fit-content" class="col-md-3 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-4 pad0 nopadding">
          <div class="row">
            <div class="filter-container">
              <ng-select class="filter-container filter-box" [items]="dcList"
                         bindLabel="itemName"
                         multiple="true"
                         maxSelectedItems="1"
                         [(ngModel)]="selectedDC"
                         (ngModelChange)="dcChange($event)"
              >
              </ng-select>
            </div>
            <div class="filter-container">
              <ng-select class="filter-container filter-box" [items]="vanList"
                         bindLabel="itemName"
                         multiple="true"
                         maxSelectedItems="1"
                         [(ngModel)]="selectedVan"
                         (ngModelChange)="vanChange($event)"
              >
              </ng-select>
            </div>
            <div class="filter-container">
              <ng-select class="filter-container filter-box" [items]="typeList"
                         bindLabel="itemName"
                         multiple="true"
                         maxSelectedItems="1"
                         [(ngModel)]="selectedType"
                         (ngModelChange)="typeChange($event)"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;">
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="run()"
                  >GO
                  </button>
                </div>
              </li>
              <li style="margin-top: 0px;margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Van-Productivity-Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0" style="margin-top: 10px;">
          <div class="mt-default" >
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="width: 100%;height: 72vh;" class="ag-theme-alpine"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [context]="context"
                             (cellClicked)="onCellClicked($event)">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
          <div>
            <button *ngIf="list_loaded" (click)="close_child()">Close</button>
          </div>
          <div class="mt-default" >
            <ag-grid-angular id="myGrid1" *ngIf="list_loaded" style="width: 100%;height: 72vh;" class="ag-theme-balham"  [columnDefs]="columnDefsList"
                             [gridOptions]="gridOptionsList"
                             (gridReady)="onGridReadyList($event)" [frameworkComponents]="frameworkComponents"
                             >
            </ag-grid-angular>
            <div *ngIf="list_preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="list_empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
