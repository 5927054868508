import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DistributorTargetAchievementReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Distributor Target vs Achievement Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] =  this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.gridOptions.pivotMode = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'GROUP',
      field: 'd_name',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer'
    };
    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDistributorTargetvsAchievementReport';

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80, rowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80, rowGroup: true},
      {headerName: 'DTYPE', field: 'd_type', width: 150, enableRowGroup: true, rowGroup: true},
      {headerName: 'DCODE', field: 'd_code', width: 100, enableRowGroup: true, rowGroup: true},
      {headerName: 'DISTRIBUTOR', field: 'd_name', width: 150, enableRowGroup: true, rowGroup: true},

      {
        headerName: 'MONTH', field: 'jc_name', menuTabs: [], width: 150, pivot: true,
        enablePivot: true, pivotComparator: this.MyMonthPivotComparator
      },
      {headerName: 'TARGET', field: 'target', enableRowGroup: true, width: 120,  aggFunc: 'sum'},
      {headerName: 'ACHIEVEMENT', field: 'achievement', width: 120,  aggFunc: 'sum'},
      {headerName: 'PROGRESS', field: 'progress', width: 120, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.target === null || parseInt(params.data.target) === 0 || params.data.achievement === null || parseInt(params.data.achievement) === 0) {
              return 0;
            } else {
              return Math.trunc((parseInt(params.data.achievement) / parseInt(params.data.target)) * 100);
            }
          }
        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer">\n' +
            '              <div class="progressinside" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }

          displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },
    ];

  }

  MyMonthPivotComparator(a, b) {
    return b - a;
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }

  changeDate(dt) {
    const temp = this.myDate;
    this.myDate = [];
    this.myDate[0] = this.datePipe.transform(new Date(temp[0].getFullYear(), temp[0].getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] =  this.datePipe.transform(new Date(temp[1].getFullYear(), temp[1].getMonth() + 1, 0), 'yyyy-MM-dd');

    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];

    this.changeDetector.detectChanges();
  }

}
