import {Component, ElementRef, Renderer2} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SharedService} from './_services/shared_service';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-cell-qty-edit',
  template: `
    <div class="po-top po-page" layout="row">
      <input *ngIf="edit" [ngModel]="searchValue" (keydown)="onKeyDown($event)" (ngModelChange)="modelChanged($event)" type="number" style="font-size: 100%; width: 100px;"
             name="searchValue"
             class="custom-inputbox"/>
      <span *ngIf="!edit" class="element-req">{{searchValue}}</span>
    </div>`
})
export class QtyEditRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public searchValue;
  public edit = false;
  public context;
  public editingFinished = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private sharedService: SharedService) {
  }

  agInit(params: any): void {
    this.editingFinished = false;
    this.params = params;
    if (this.params.context.componentParent) {
      this.context = this.params.context.componentParent;
    } else {
      this.context = this.params?.context?.params?.context?.context?.componentParent;
    }
    if (this.context) {
      this.edit = !this.context.fullyInvoiced;
    }
    if (this.params?.data?.type && this.params?.data?.type === 'promo') {
      this.edit = false;
    }
    this.searchValue = params.data[this.params.column.colId];
  }

  modelChanged($event) {
    console.log('model');
    if (this.editingFinished) {
      this.params.data[this.params.column.colId] = $event;
      if (this.context) {
        this.context.onQtyEdit(this.params, $event);
      }
    }
  }

  refresh(): boolean {
    return false;
  }

  onKeyDown(event: any): void {
    console.log('key');
    if (this.isLeftOrRight(event) || this.isBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
    if (this.finishedEditingPressed(event)) {
      this.editingFinished = true;
    }
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  private isBackspace(event: any) {
    return event.key === KEY_BACKSPACE;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
