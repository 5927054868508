import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';


@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DailyGoalsReportV2Component extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Daily Goals Report V2');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.API_URL = '/api/pwa_v2_reports/dailyGoalsReportV2';
  }

  configureGrid() {

    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: 'SALESMAN', field: 'user_name', width: 80, pinned: 'left'},
          {headerName: 'SUPPLIER', field: 'supplier', width: 100, hide: true, pinned: 'left'},
          {headerName: 'DATE', field: 'date', width: 80, pinned: 'left'},
        ]
      },
      {
        headerName: "User",
        children: [
          {headerName: 'SCODE', field: 'emp_code', width: 100, hide: true},
          {headerName: 'TEAM', field: 'team', width: 100, hide: true},
          {headerName: 'PARENT TEAM', field: 'parent_team', width: 100, hide: true},
          {headerName: 'STATE', field: 'state', width: 100, hide: true},
          {headerName: 'REGION', field: 'region', width: 100, hide: true},
          {headerName: 'ROLE', field: 'user_role', width: 100, hide: true},
          {headerName: 'REPORTING MANAGER', field: 'reporting_manager', width: 100, hide: true},
        ]
      },
      {
        headerName: "GOAL",
        children: [
          {
            headerName: 'STATUS', field: 'status', width: 100, hide: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.status != null) {
                  return params.data.status.toUpperCase();
                }
              }
            }
          },
          {headerName: 'TARGET', field: 'goal_total_target', width: 100, aggFunc: 'sum'},
          {headerName: 'ACHIEVED', field: 'goal_total_achieved', width: 100, aggFunc: 'sum'},

          {
            headerName: "PROGRESS",
            headerClass: 'myagheader',
            field: "goal_total_ach_percent",
            minWidth: 150,
            aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.goal_total_ach_percent) {
                  return parseFloat(params.data.goal_total_ach_percent.toFixed(2));
                } else {
                  return 0;
                }
              }
            },
            cellRenderer: params => {

              let displayElem = '<div class="row">\n' +
                '            <div class="progresscontainer1">\n' +
                '              <div class="progressinside1" style=" ';

              if (params.value > 50) {
                displayElem = displayElem + ' background-color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' background-color: #D97C0E;';
              } else {
                displayElem = displayElem + ' background-color: #E24260;';
              }

              displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                '            </div>\n' +
                '            <div class="progresstext1" style="';

              if (params.value > 50) {
                displayElem = displayElem + ' color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' color: #D97C0E;';
              } else {
                displayElem = displayElem + ' color: #E24260;';
              }

              displayElem = displayElem + '">' + params.value + '%</div></div>';


              return displayElem;
            }
          },
          // {
          //   headerName: 'ACH %', field: 'goal_total_ach_percent', width: 100,   aggFunc: this.avgAggFunction,
          //   cellStyle: params => {
          //     if (params.data) {
          //       if (params.value > 75) {
          //         return {backgroundColor: '#037f51'};
          //       } else if (params.value > 50) {
          //         return {backgroundColor: '#FF8C00'};
          //       } else {
          //         return {backgroundColor: '#FF0000'};
          //       }
          //     }
          //   },
          //   valueGetter: params => {
          //     if (params.data) {
          //       if (params.data.goal_total_ach_percent) {
          //         return parseFloat(params.data.goal_total_ach_percent.toFixed(2));
          //       } else {
          //         return 0;
          //       }
          //     }
          //   }
          // },
        ]
      },
      {
        headerName: "BEAT",
        children: [
          {headerName: 'BEAT NAME', field: 'beat', width: 100},
          {headerName: 'TERRITORY', field: 'territory', width: 100},
          {
            headerName: 'DAY TARGET', hide: true, field: 'schedule_target', width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data && parseFloat(params.data.schedule_target) > 0) {
                if (params.data.schedule_target) {
                  return parseInt(params.data.schedule_target);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: 'DAY ACHIEVED', hide: true, field: 'beat_revenue', width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data && parseFloat(params.data.beat_revenue) > 0) {
                if (params.data.beat_revenue) {
                  return parseInt(params.data.beat_revenue);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: 'DAY ACH %', hide: true, field: 'beat_ach_percent', width: 100, aggFunc: this.avgAggFunction,
            cellStyle: params => {
              if (params.data) {
                if (params.value > 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            },
            valueGetter: params => {
              if (params.data) {
                if (params.data.beat_ach_percent) {
                  return parseFloat(params.data.beat_ach_percent.toFixed(2));
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: 'TC', field: 'tc', width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data && parseFloat(params.data.tc) > 0) {
                if (params.data.tc) {
                  return parseInt(params.data.tc);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: 'VC', field: 'visited_count', width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data && parseFloat(params.data.visited_count) > 0) {
                if (params.data.visited_count) {
                  return parseInt(params.data.visited_count);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: 'PC', field: 'pc', width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data && parseFloat(params.data.pc) > 0) {
                if (params.data.pc) {
                  return parseInt(params.data.pc);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: 'EOD', field: 'eod', width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.eod != null) {
                  return params.data.eod.toUpperCase();
                }
              }
            }
          }
        ]
      }
    ];

    this.apiData.forEach(data => {
      if (data.line_json) {
        data.line_json.forEach(lineRow => {
          let lineHeader;
          let alreadyExist;
          if (lineRow.type === 'fss_product_value' || lineRow.type === 'category' || lineRow.type === 'secondary_category_qty' || lineRow.type === 'primary_category_qty') {

            lineHeader = lineRow.name.toString().replace('_', ' ').toUpperCase();

            alreadyExist = false;
            for (let i = 0; i < this.columnDefs.length; i++) {
              if (this.columnDefs[i].headerName === lineHeader) {
                alreadyExist = true;
                break;
              }
            }

            if (!alreadyExist) {
              this.columnDefs.push({
                headerName: lineRow.name.toString().replace('_', ' ').toUpperCase(),
                children: [
                  {
                    headerName: "TARGET",
                    field: lineRow.type + '_' + lineRow.name + '_target',
                    width: 100, aggFunc: 'sum'
                  },
                  {
                    headerName: "ACHIEVEMENT",
                    field: lineRow.type + '_' + lineRow.name + '_achievement',
                    width: 100, aggFunc: 'sum'
                  },
                  {
                    headerName: "COMMENT",
                    field: lineRow.type + '_' + lineRow.name + '_comment',
                    width: 100
                  },
                  {
                    headerName: "ACH %",
                    field: lineRow.type + '_' + lineRow.name + '_achievement_percent',
                    width: 100, aggFunc: this.avgAggFunction,
                    cellStyle: params => {
                      if (params.data) {
                        if (params.value > 75) {
                          return {backgroundColor: '#B3DFB0'};
                        } else if (params.value > 50) {
                          return {backgroundColor: '#F0E199'};
                        } else {
                          return {backgroundColor: '#F8D1C8'};
                        }
                      }
                    },
                    valueGetter: params => {
                      if (params.data) {
                        if (params.data[lineRow.type + '_' + lineRow.name + '_achievement_percent']) {
                          return parseFloat(params.data[lineRow.type + '_' + lineRow.name + '_achievement_percent'].toFixed(2));
                        } else {
                          return 0;
                        }
                      }
                    }
                  }
                ]
              });
            }
          } else {
            lineHeader = lineRow.type.toString().replace('_', ' ').toUpperCase();

            alreadyExist = false;
            for (let i = 0; i < this.columnDefs.length; i++) {
              if (this.columnDefs[i].headerName === lineHeader) {
                alreadyExist = true;
                break;
              }
            }

            if (!alreadyExist) {
              this.columnDefs.push({
                headerName: lineRow.type.toString().replace('_', ' ').toUpperCase(),
                children: [
                  {
                    headerName: "TARGET",
                    field: lineRow.type + '_target',
                    width: 100, aggFunc: 'sum'
                  },
                  {
                    headerName: "ACHIEVEMENT",
                    field: lineRow.type + '_achievement',
                    width: 100, aggFunc: 'sum'
                  },
                  {
                    headerName: "COMMENT",
                    field: lineRow.type + '_comment',
                    width: 100
                  },
                  {
                    headerName: "ACH %",
                    field: lineRow.type + '_achievement_percent',
                    width: 100, aggFunc: this.avgAggFunction,
                    cellStyle: params => {
                      if (params.data) {
                        if (params.value > 75) {
                          return {backgroundColor: '#B3DFB0'};
                        } else if (params.value > 50) {
                          return {backgroundColor: '#F0E199'};
                        } else {
                          return {backgroundColor: '#F8D1C8'};
                        }
                      }
                    },
                    valueGetter: params => {
                      if (params.data) {
                        if (params.data._achievement_percent) {
                          return parseFloat(params.data._achievement_percent.toFixed(2));
                        } else {
                          return 0;
                        }
                      }
                    }
                  }
                ]
              });
            }
          }
        });
      }

    });
    this.columnDefs.push(
      {headerName: 'RATING', field: 'rating', width: 100}
    );
  }

  configureData() {
    this.rowData = [];

    this.apiData.forEach(data => {
      let totalTarget = 0;
      let totalAchievement = 0;
      if (data.line_json) {
        data.line_json.forEach(lineRow => {
          let target = 0;
          let achievement = 0;
          let comment;
          if (lineRow.type === 'fss_product_value' || lineRow.type === 'category' || lineRow.type === 'secondary_category_qty' || lineRow.type === 'primary_category_qty') {
            if (lineRow.target && lineRow.target > 0) {
              target = lineRow.target;
            }

            if (lineRow.achieved && lineRow.achieved > 0) {
              achievement = lineRow.achieved;
            }

            data[lineRow.type + '_' + lineRow.name + '_target'] = target;
            data[lineRow.type + '_' + lineRow.name + '_achievement'] = achievement;
            if (target > 0 && achievement > 0) {
              data[lineRow.type + '_' + lineRow.name + '_achievement_percent'] = (achievement * 100) / target;
            } else {
              data[lineRow.type + '_' + lineRow.name + '_achievement_percent'] = 0;
            }
            comment = "";
            if (lineRow.note && lineRow.note.length > 0) {
              lineRow.note.forEach(noteRow => {
                if (comment && comment !== "") {
                  comment = comment + ", " + noteRow.username + ":" + noteRow.comment;
                } else {
                  comment = noteRow.username + ":" + noteRow.comment;
                }
              });
            }

            data[lineRow.type + '_' + lineRow.name + '_comment'] = comment;
            totalTarget = totalTarget + target;
            totalAchievement = totalAchievement + achievement;
          } else {
            if (lineRow.target && lineRow.target > 0) {
              target = lineRow.target;
            }

            if (lineRow.achieved && lineRow.achieved > 0) {
              achievement = lineRow.achieved;
            }

            data[lineRow.type + '_target'] = target;
            data[lineRow.type + '_achievement'] = achievement;
            if (target > 0 && achievement > 0) {
              data[lineRow.type + '_achievement_percent'] = (achievement * 100) / target;
            } else {
              data[lineRow.type + '_achievement_percent'] = 0;
            }

            comment = "";
            if (lineRow.note && lineRow.note.length > 0) {
              lineRow.note.forEach(noteRow => {
                if (comment && comment !== "") {
                  comment = comment + ", " + noteRow.username + ":" + noteRow.comment;
                } else {
                  comment = noteRow.username + ":" + noteRow.comment;
                }
              });
            }
            data[lineRow.type + '_comment'] = comment;

            totalTarget = totalTarget + target;
            totalAchievement = totalAchievement + achievement;

          }
        });
      }
      data['goal_total_target'] = totalTarget;
      data['goal_total_achieved'] = totalAchievement;
      if (totalTarget > 0 && totalAchievement > 0) {
        data['goal_total_ach_percent'] = (totalAchievement * 100) / totalTarget;
      } else {
        data['goal_total_ach_percent'] = 0;
      }
      if (data.schedule_target > 0 && data.beat_revenue > 0) {
        data.beat_ach_percent = (data.beat_revenue * 100) / data.schedule_target;
      } else {
        data.beat_ach_percent = 0;
      }
    });

    this.rowData = this.apiData;
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function () {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.gridColumnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // this.get_favorites();
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

}
