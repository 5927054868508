/**
 * Created by jayhamilton on 2/7/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

interface GadgetLibraryResponse {
  library: any[];
}

@Injectable()
export class AddGadgetService {

    env: any;

    constructor(private _http: HttpClient) {
        this.env = environment;
    }

    getGadgetLibrary() {
        return this._http.get<GadgetLibraryResponse>('/assets/api/gadget-library-model.json');
    }

}
