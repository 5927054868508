import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-partner-meet-popup',
  templateUrl: './partner_meet_popup.component.html',
  styleUrls: ['./partner_meet_popup.component.scss']

})

export class PartnerMeetPopupComponent implements OnInit {

  pmeetDetails: any = {};
  photo;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.pmeetDetails = dialogdata.taskDetails;
    this.photo = dialogdata.photo;

    if (this.pmeetDetails.hasOwnProperty('config_json') && this.pmeetDetails.config_json) {
      if (this.pmeetDetails.config_json.constructor !== Object) {
        this.pmeetDetails.config_json = JSON.parse(this.pmeetDetails.config_json);
      }
      this.pmeetDetails.address = this.pmeetDetails.config_json['address'];
      if (this.pmeetDetails.config_json.hasOwnProperty('gift_json') && this.pmeetDetails.config_json.gift_json && this.pmeetDetails.config_json.gift_json.length > 0) {
        this.pmeetDetails.gift_type = this.pmeetDetails.config_json.gift_json[0].type;
        this.pmeetDetails.gift_qty = this.pmeetDetails.config_json.gift_json[0].qty;
      }
    }
    if (this.pmeetDetails.end_time && this.pmeetDetails.start_time) {
      const diff = new Date(this.pmeetDetails.end_time + ' UTC').getTime() - new Date(this.pmeetDetails.start_time + ' UTC').getTime();
      //return (diff / (1000 * 60)).toFixed(2);
      let msec = diff;
      const hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      const mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      const ss = Math.floor(msec / 1000);
      msec -= ss * 1000;
      this.pmeetDetails.time_spent = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
    }
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
