import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-invoice-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class InvoiceReport extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  showCustomFilter = true;

  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'S',
    values: [],
    show: true
  };

  setTitle() {
    this.title = 'Invoice Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Invoice Report');
    this.API_URL = '/api/pwa_reports/getInvoiceReport';
    this.customFilter.values = [
      {id: "S", itemName: "Secondary"},
      {id: "P", itemName: "Primary"}
    ];
      }

  configureGrid() {
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "Invoice Date", field: "date_invoice", menuTabs: [], width: 100},
      {headerName: "Customer Name", field: "cust_name", menuTabs: [], width: 100},
      {headerName: "Customer Code", field: "cust_code", menuTabs: [], width: 100},
      {headerName: "Customer Status", field: "cust_status", menuTabs: [], width: 100},
      {headerName: "Customer GST", field: "cust_gst", menuTabs: [], width: 100},
      {headerName: "Customer Mobile", field: "cust_mobile", menuTabs: [], width: 100},
      {headerName: "Customer Email", field: "cust_email", menuTabs: [], width: 100},
      {headerName: "Customer Class", field: "cust_class", menuTabs: [], width: 100},
      {headerName: "Customer Type", field: "cust_type", menuTabs: [], width: 100},
      {headerName: "Customer Program Type", field: "cust_pgm_type", menuTabs: [], width: 100},
      {headerName: "Customer Lat", field: "cust_lat", menuTabs: [], width: 100},
      {headerName: "Customer Long", field: "cust_long", menuTabs: [], width: 100},
      {headerName: "Customer Address", field: "cust_address", menuTabs: [], width: 100},
      {headerName: "Customer State", field: "cust_state", menuTabs: [], width: 100},
      {headerName: "Customer District", field: "cust_zone", menuTabs: [], width: 100},
      {headerName: "Customer Area", field: "cust_area", menuTabs: [], width: 100},
      {headerName: "Customer City", field: "cust_city", menuTabs: [], width: 100},
      {headerName: "Customer Created", field: "cust_created", menuTabs: [], width: 100},
      {headerName: "Customer Target", field: "cust_target", menuTabs: [], width: 100},

      {headerName: "User", field: "user_name", menuTabs: [], width: 100},
      {headerName: "Emp Code", field: "emp_code", menuTabs: [], width: 100},
      {headerName: "Role", field: "user_role", menuTabs: [], width: 100},
      {headerName: "SAC", field: "sac", menuTabs: [], width: 100},
      {headerName: "Team", field: "team", menuTabs: [], width: 100},
      {headerName: "Team Lead", field: "team_lead", menuTabs: [], width: 100},
      {headerName: "User State", field: "user_state", menuTabs: [], width: 100},

      {headerName: "Beat ID", field: "beat_id", menuTabs: [], width: 100},
      {headerName: "Beat Name", field: "beat_name", menuTabs: [], width: 100},
      {headerName: "Terr Code", field: "terr_code", menuTabs: [], width: 100},
      {headerName: "Terr Name", field: "terr_name", menuTabs: [], width: 100},
      {headerName: "Region", field: "region", menuTabs: [], width: 100},

      {headerName: "Distributor Name", field: "dist_name", menuTabs: [], width: 100},
      {headerName: "Distributor Code", field: "dist_code", menuTabs: [], width: 100},
      {headerName: "Distributor City", field: "dist_city", menuTabs: [], width: 100},
      {headerName: "Distributor State", field: "dist_state", menuTabs: [], width: 100},

      {headerName: "Invoice ID", field: "invoice_id", menuTabs: [], width: 100},
      {headerName: "Invoice No", field: "invoice_ref", menuTabs: [], width: 100},
      {headerName: "Delivery Status", field: "dlr_status", menuTabs: [], width: 100},
      {headerName: "Delivered Date", field: "date_delivered", menuTabs: [], width: 100},
      {headerName: "Sale Ref", field: "order_ref", menuTabs: [], width: 100},

      {headerName: "Product Name", field: "prd_name", menuTabs: [], width: 100},
      {headerName: "Product Code", field: "prd_code", menuTabs: [], width: 100},
      {headerName: "Product Family", field: "family", menuTabs: [], width: 100},
      {headerName: "Product Brand", field: "brand", menuTabs: [], width: 100},
      {headerName: "Product Category", field: "categ", menuTabs: [], width: 100},

      {headerName: "Line Type", field: "line_type", menuTabs: [], width: 100},
      {headerName: "Qty", field: "qty", menuTabs: [], width: 100},

      {headerName: "Price", field: "price_unit", menuTabs: [], width: 100},
      {headerName: "Line Disc(%)", field: "line_disc_in_pct", menuTabs: [], width: 100},
      {
        headerName: "Line Disc", field: "line_disc_in_amt", menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            return Math.round(parseFloat(params.data.line_disc_in_amt) * 100) / 100;
          }
        }
      },
      {headerName: "SPL Disc", field: "spl_discount", menuTabs: [], width: 100},
      {headerName: "Scheme Disc", field: "scheme_disc", menuTabs: [], width: 100},
      {
        headerName: "Subtotal", field: "price_subtotal", menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            return Math.round(parseFloat(params.data.price_subtotal) * 100) / 100;
          }
        }
      }
    ];

    var taxMaster = this.apiData.taxmaster;
    for (var key in taxMaster) {
      this.columnDefs.push({
        headerName: taxMaster[key].description,

        field: taxMaster[key].description,
        cellStyle: {textAlign: 'center'},


        menuTabs: [],
        width: 100,
      });
    }

    this.columnDefs.push({
      headerName: "Tax", field: "amt_tax", cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.amt_tax) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "Total", field: "amt_total", cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.amt_total) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "TDS/TCS", field: "tax_source", menuTabs: [], width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "Roundoff", field: "roundoff", cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.roundoff) * 100) / 100;
        }
      }
    });
  }

  loadServerData() {
 this.goClicked = true;
  this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showCustomFilter) {
      if ( this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.status));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  configureData() {
    this.rowData = [];
    var taxMaster = this.apiData.taxmaster;
    var salesMaster = this.apiData.sales;
    var taxLines = this.apiData.taxlines;

    for (var key in this.apiData.sales) {

      var indrow = {};
      var taxRow: any = {};
      var salesRow: any = {};
      var taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(function(tax) {
        return tax.id == salesRow.line_id;
      });

      for (var key in taxMaster) {
        taxRow[taxMaster[key].description] = taxLine.filter(function(tax) {
          return tax.description == taxMaster[key].description;
        })[0];
        if (taxRow[taxMaster[key].description] != null && taxRow[taxMaster[key].description] != undefined) {
          taxRow[taxMaster[key].description] = taxRow[taxMaster[key].description].tax_amt;
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (noOfColumns < 16 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    /* if (screen.width > 991) {
       this.gridApi.sizeColumnsToFit();
     } else {
       this.gridColumnApi.autoSizeColumns();
     }*/
    if (noOfColumns < 16 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
