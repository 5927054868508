import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

declare var $: any;
@Component({
  selector: 'pinnedcell-editor',
  /*template: `<div layout="row"> ` +
            `<span *ngIf="readjust" title="Make all values to 0" (click)="reAdjustValues()"> <i class="fas fa-trash delete"></i></span>` +

            `<span *ngIf="batchscale" title="Scale to batch size" (click)="reAdjustValues()"> <i class="fas fa-trash delete"></i></span>` +
            ` <span *ngIf="mt_sug">{{value}}</span><span *ngIf="mt_sug" [ngClass]="{'color_red': color_change === true}">({{dev}})</span>` +
            ` <span *ngIf="!mt_sug && disable_dev">{{poValue}}</span><span *ngIf="sugDev" [ngClass]="{'color_red': (dev[0] !== '-' && dev[0] !== '0')}">({{dev}})</span>` +
             `<span *ngIf="!mt_sug && !disable_dev">{{value}}</span><br/>` +
             `<span *ngIf="!mt_sug && !disable_dev" [ngClass]="{'color_red': dev[0] === '-'}">{{dev}}</span>` +
             `</div>`,*/
  template: `
    <div layout="row">
      <span *ngIf="readjust" title="Make all values to 0" (click)="reAdjustValues()"> <i class="fas fa-trash delete"></i></span>
      <div *ngIf="batchscale" style="margin-top: 5px;">
        <div class="formselectsmall">
          <select
            #batchselect
            id="batchselect"
            name="batch"
            [(ngModel)]="selecteduom"
            (change) = "batchScale($event)"
            #batch="ngModel"
            style="width: 100%;">
            <option
                *ngFor="let item of uomlist"
                value="{{ item.id}}"
            > {{ item.name }}</option>
          </select>
        </div>
        <!--<button  class="btn blue-btn" style="margin-top: 5px; padding-top: 0px;padding-bottom:0px; padding-left:2px;padding-right:2px;font-size: 10px;width:100%" (click)="batchScale($event)">scale</button>-->
      </div>
      <span *ngIf="mt_sug">{{value}}</span><span *ngIf="mt_sug" [ngClass]="{'color_red': color_change === true}">({{dev}})</span>
      <span *ngIf="!mt_sug && disable_dev">{{poValue}}</span><span *ngIf="sugDev" [ngClass]="{'color_red': (dev[0] !== '-' && dev[0] !== '0')}">({{dev}})</span>
      <span *ngIf="!mt_sug && !disable_dev" [ngClass]="{'color_red': dev[0] === '-'}">{{value}}</span><br/>
      <!--<span *ngIf="!mt_sug && !disable_dev" [ngClass]="{'color_red': dev[0] === '-'}">{{dev}}</span>-->
    </div>`,
  styleUrls: ['./../app/prolist/prolist.component.scss']
})
export class PinnedCellRenderer implements ICellRendererAngularComp {
  public params: any;
  public poValue = "";
  public disable_dev = true;
  public value;
  public dev;
  public splitVal;
  public mt_sug = false;
  public prodRange;
  public color_change = false;
  public sugTotal;
  public sugDev = false;
  public readjust = false;
  public batchscale = false;
  uomlist = [];
  selecteduom = 0;
  user_uom_config = {};
  agInit(params: any): void {
    this.disable_dev = true;
    this.sugDev = false;
    this.params = params;
    this.prodRange = this.params.context.componentParent.prodRange;
    this.sugTotal = this.params.context.componentParent.sugTotal;
    this.color_change = false;
    let updated = [];
    let min = 0;
    let max = 0;
    let sug = 0;
    this.uomlist = [{id: 0, factor_inv: 0, uom_type: 'null', name: ''}].concat(JSON.parse(localStorage.getItem('all_uoms')));
    this.user_uom_config = this.params.context.componentParent.user_uom_config;
    /*if (this.uomlist) {
     /!* this.uomlist = this.uomlist.filter(x => x.uom_type === 'bigger');
      if (this.uomlist) {
        this.selecteduom = this.uomlist[0].id;
      }*!/
      this.selecteduom = this.uomlist[0].id;
      this.params.data['uom'] =  this.uomlist[0].id;
    }*/
    if (this.params.data['VAN'] === 'RESET TO 0' && (this.params.context.componentParent.status !== 'Inprogress' && this.params.context.componentParent.status !== 'Completed')) {
      this.readjust = true;
    } else if (this.params.data['VAN'] === 'BATCH SCALE') {
      this.batchscale = true;
      this.selecteduom = this.params.value;
      if (this.user_uom_config && this.user_uom_config[this.params.colDef.field]) {
        this.selecteduom = this.user_uom_config[this.params.colDef.field];
        const batchscalerow = this.params.context.componentParent.gridOptions.pinnedTopRowData.find(x => x['VAN'] === 'BATCH SCALE');
        batchscalerow[this.params.colDef.field] = parseInt(String(this.selecteduom), 10);
      }
    } else {
      this.readjust = false;
      if (this.params.data['VAN'] === 'REQUESTED' && this.params.context.componentParent.status === 'Draft' &&
        this.params.context.componentParent.enable_recommented) {
        this.sugDev = true;
        this.value = this.params.value;
        sug = this.sugTotal[this.params.colDef.field];
        this.dev = (this.params.value - sug).toString();
      }
      if (typeof (this.params.value) === "string" && this.params.value.split(' (').length > 1) {
        this.disable_dev = false;
        this.splitVal = this.params.value.split(' (');
        //this.value = this.splitVal[0];
        this.value = this.params.value;
        this.dev = this.splitVal[1].split(')')[0];

      }
      if (this.params.data['Outlet'] === 'SUGGESTED') {
        this.mt_sug = true;
        const total = this.params.api.getPinnedBottomRow(0).data;
        if (this.prodRange[this.params.colDef.field]) {
          min = this.prodRange[this.params.colDef.field].split('$')[0];
          max = this.prodRange[this.params.colDef.field].split('$')[1];
        }
        this.dev = (total[this.params.colDef.field] - this.params.value).toString();
        if (parseFloat(total[this.params.colDef.field]) < parseFloat(String(min))) {
          this.color_change = true;
        }
        if (parseFloat(total[this.params.colDef.field]) > parseFloat(String(max))) {
          this.color_change = true;
        }
        this.value = this.params.value;
      }
      this.poValue = this.params.value;
    }

   /* $('#batchselect').on({
      "change": function() {
        $(this).blur();

      },

      'focus': function() {
        console.log("displayed");
      },

      "blur": function() {
        console.log("not displayed");
      },

      "keyup": function(e) {
        if (e.keyCode == 27)
          console.log("displayed");
      }
    });*/

  }

  refresh(): boolean {
    return false;
  }

  reAdjustValues() {
    this.params.context.componentParent.reAdjustValues(this.params);
  }

  batchScale(event) {
    console.log(event);
    /*const scalefactor = this.uomlist.find(x => x.id === parseInt(String(this.selecteduom), 0)).factor_inv;
    this.params.value = this.selecteduom;*/

    const batchscalerow = this.params.context.componentParent.gridOptions.pinnedTopRowData.find(x => x['VAN'] === 'BATCH SCALE');
    batchscalerow[this.params.colDef.field] = parseInt(String(this.selecteduom), 10);

    this.params.context.componentParent.storeScaleUnitValues();

  // this.params.context.componentParent.batchscaleValues(this.params, parseFloat(scalefactor));
  }
}
