import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'update-order-status',
  templateUrl: './update_order_status.component.html',
  styleUrls: ['./update_order_status.component.scss']

})

export class UpdateOrderStatusComponent implements OnInit {

  @Output() updateStatusEmitter: EventEmitter<any> = new EventEmitter<any>();

  title;
  updateData: any = {};
  beatActivityData = [];
  statusReasons: [];

  constructor(
    public apiService: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
  }

  ngOnInit(): void {
    const company_config = JSON.parse(localStorage.getItem('company_config'));
    if (company_config && company_config.length > 0) {
      const order_activity = company_config.find(x => x.type === 'Order Activity');
      if (order_activity && order_activity.config_json && order_activity.config_json['order_status']) {
        this.statusReasons = order_activity.config_json['order_status'];
      }
    }
  }

  performAction() {
    this.updateData.reason = this.updateData.reason;
    this.updateStatusEmitter.emit(this.updateData);
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
