<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dcvanlist">
            <div class="search-list">
              <div class="col-md-6 pad0">
                  <div class="search">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                    placeholder="Search for DC">
                  </div>
              </div>
              <div class="col-md-6 pad0">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR">
                        <div class="date-picker">
                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                            <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                    </li>
<!--                      <li class="margLR">-->
<!--                          <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--                      </li>-->
                      <li class="margLR">
                          <app-excelicon [gridOption]="gridOptions" [title]="'MT List'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
              </div>
              <div class="col-md-12 pad0">
                <div class="mat-tab">
                  <mat-tab-group>

                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span style="font-weight: bolder;color: black;padding-left: 4%;">ORDER DETAILS</span>
                      </ng-template>
                      <div class="eod-table">
                        <div>
                          <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 99%;" class="ag-theme-balham"  [columnDefs]="columnDefs"
                                           [gridOptions]="gridOptions"
                                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
                                              </ag-grid-angular>
                          <div *ngIf="preloader">-->

                          <app-loadingstate></app-loadingstate>
                        </div>
                        <div *ngIf="empty_state">
                          <app-emptystate></app-emptystate>
                        </div>
                        </div>
                      </div>

                    </mat-tab>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span style="font-weight: bolder;color: black;padding-left: 4%;">INVOICE</span>
                      </ng-template>
                      <div class="eod-table">
                        <div>
                          <ag-grid-angular id="myGrid1" *ngIf="loaded2" style="width: 99%;" class="ag-theme-balham"  [columnDefs]="columnDefs2"
                                           [gridOptions]="gridOptions2"
                                           (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents"
                                           (cellClicked)="onCellClicked($event)">
                          </ag-grid-angular>
                          <div *ngIf="preloader2">

                            <app-loadingstate></app-loadingstate>
                          </div>
                          <div *ngIf="empty_state2">
                            <app-emptystate></app-emptystate>
                          </div>
                        </div>
                      </div>

                    </mat-tab>
                  </mat-tab-group>
<!--                <div>-->
<!--                  <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 101%;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"-->
<!--                                   (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">-->
<!--                  </ag-grid-angular>-->
<!--                  <div *ngIf="preloader">-->
<!--                    &lt;!&ndash;<div style="position:absolute;left:44%;top:200px;font-size: medium">&ndash;&gt;-->
<!--                      &lt;!&ndash;<img src="../../assets/images/preloader.gif"/>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    <app-loadingstate></app-loadingstate>-->
<!--                  </div>-->
<!--                  <div *ngIf="empty_state">-->
<!--                    <app-emptystate></app-emptystate>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

<div #BillingModal class="pol-modal pol-send invoice modal" id="BillingModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      <div class="billing-details">
        <div class="billing-name">
          <ul class="list-unstyled">
            <li>
              <h1>{{orderDetails['partner']}}</h1>
              <h1>Code: {{orderDetails['code']}}</h1>
            </li>
            <li>
              <label>ROUTE NAME : {{orderDetails['beat_name']}}</label>
            </li>
          </ul>
        </div>
        <div class="billing-order">
          <ul class="list-unstyled">
            <li>
              <label>INVOICE NO : {{orderDetails['invoice_no']}}</label>
            </li>
            <li>
              <label>REF NO : {{orderDetails['name']}}</label>
            </li>
            <li>
              <label>ID : {{orderDetails['id']}}</label>
            </li>
            <li>
              <label>GST/TAX ID : </label>
            </li>
            <li>
              <label>DATE : {{orderDetails['order_date']}}</label>
            </li>
            <li>
              <label>DELIVERY DATE : {{orderDetails['date_delivered']}}</label>
            </li>
          </ul>
        </div>

        <!--<div class="popup-print">-->
        <!---->
        <!--&lt;!&ndash;<input (click)="printDetails()" type="button" value="Print" id="btnPrint" />&ndash;&gt;-->
        <!--</div>-->

      </div>
      <div class="modal-body">

        <div class="pol-modal-heading">
          <h1>DETAILS</h1>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>PRODUCT</label>
            </li>
            <li>
              <label>QUANTITY</label>
            </li>
            <li>
              <label>TAX</label>
            </li>
            <li>
              <label>TAX AMOUNT</label>
            </li>
            <li>
              <label>TCS/TDS</label>
            </li>
            <li>
              <label>VALUE</label>
            </li>
          </ul>
          <div class="pol-content-text" *ngFor="let product of orderDetails['sale_items']">
            <ul class="list-unstyled">
              <li>
                <h2>{{product['product_name']}}</h2>
              </li>
              <li>
                <label>{{product['qty']}}</label>
              </li>
              <li>
                <label>{{product['tax']}}</label>
              </li>
              <li>
                <label>{{product['price_tax']}}</label>
              </li>
              <li>
                <label>{{product['tcs'].toFixed(5)}}</label>
              </li>
              <li>
                <label> {{product['amount_subtotal']}}</label>
              </li>
            </ul>
          </div>
          <div *ngIf="payment_enable" class="payment">
            <div class="pol-modal-heading">
              <h1>PAYMENT</h1>
            </div>
            <ul class="pol-content-heading list-unstyled">
              <li>
                <label>TRANSCTION ID</label>
              </li>
              <li>
                <label>DATE</label>
              </li>
              <li>
                <label>PAYMENT TYPE</label>
              </li>
              <li>
                <label>AMOUNT</label>
              </li>
            </ul>
            <div class="pol-content-text" *ngFor="let payment of orderDetails['pay_items']">
              <ul class="list-unstyled">
                <li>
                  <h2>#{{payment['id']}}</h2>
                </li>
                <li style="width: 241px;">
                  <label>{{payment['payment_date']}}</label>
                </li>
                <li>
                  <span style="font-size: small; float: left">{{payment['payment_type']}}</span>
                </li>
                <li>
                  <span style="font-size: small"> {{payment['amount'].toFixed(apiService.decimalPolicy)}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #myDiv id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#BillingModal"></button>


