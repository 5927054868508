<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<HTML>
<HEAD>
  <META http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <TITLE>local</TITLE>
  <META name="generator" content="BCL easyConverter SDK 5.0.210">
  <STYLE type="text/css">

    body {margin-top: 0px;margin-left: 0px;}

    #page_1 {position:relative; overflow: hidden;margin: -16% 0px 12px -2%;padding: 0px;border: none;width: auto;}
    #page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: auto;overflow: hidden;}
    #page_1 #id1_2 {border:none;margin: 3px 0px 0px 426px;padding: 0px;border:none;width: 698px;overflow: hidden;}

    #page_1 #p1dimg1 {position:absolute;top:66px;left:76px;z-index:-1;width:5px;height:4px;font-size: 1px;line-height:nHeight;}
    #page_1 #p1dimg1 #p1img1 {width:5px;height:4px;}

    .m1{width: 60vw;}
    .m2{width: fit-content;}
    .m3{height: 80vh;
      overflow-y: scroll;border:solid 1px;}


    .ft0{font: 11px 'Arial';line-height: 14px;}
    .ft1{font: bold 25px 'Arial';line-height: 30px;}
    .ft2{font: bold 13px 'Arial';line-height: 15px;}
    .ft3{font: 1px 'Arial';line-height: 1px;}
    .ft4{font: 13px 'Arial';line-height: 15px;}
    .ft5{font: 1px 'Arial';line-height: 7px;}
    .ft6{font: 1px 'Arial';line-height: 5px;}
    .ft7{font: bold 13px 'Arial';line-height: 14px;}
    .ft8{font: 1px 'Arial';line-height: 6px;}
    .ft9{font: 1px 'Arial';line-height: 10px;}
    .ft10{font: 1px 'Arial';line-height: 9px;}
    .ft11{font: 1px 'Arial';line-height: 8px;}
    .ft12{font: 8px 'Arial';line-height: 10px;}
    .ft13{font: 8px 'Calibri';line-height: 10px;}

    .p0{text-align: left;padding-left: 35px;margin-top: 0px;margin-bottom: 0px;}
    .p1{text-align: left;padding-left: 342px;margin-top: 70px;margin-bottom: 0px;}
    .p2{text-align: left;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p3{text-align: right;padding-right: 9px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p4{text-align: right;padding-right: 22px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p5{text-align: left;padding-left: 7px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p6{text-align: left;padding-left: 4px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p7{text-align: left;padding-left: 3px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p8{text-align: left;padding-left: 6px;margin-top: 0px;margin-bottom: 5px;white-space: nowrap;}
    .p9{text-align: left;padding-left: 6px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p10{text-align: left;padding-left: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p11{text-align: left;padding-left: 25px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p12{text-align: right;padding-right: 3px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p13{text-align: left;padding-left: 12px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p14{text-align: right;padding-right: 36px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p15{text-align: right;padding-right: 15px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p16{text-align: right;padding-right: 13px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p17{text-align: right;padding-right: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p18{text-align: right;padding-right: 35px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p19{text-align: right;padding-right: 16px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p20{text-align: left;padding-left: 76px;margin-top: 35px;margin-bottom: 0px;}
    .p21{text-align: left;padding-left: 289px;margin-top: 372px;margin-bottom: 0px;}
    .p22{text-align: right;padding-right: 35px;margin-top: 444px;margin-bottom: 0px;}
    .p23{text-align: left;margin-top: 0px;margin-bottom: 0px;}

    .td0{padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td1{padding: 0px;margin: 0px;width: 135px;vertical-align: bottom;}
    .td2{padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td3{padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td4{padding: 0px;margin: 0px;width: 101px;vertical-align: bottom;}
    .td5{padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
    .td6{padding: 0px;margin: 0px;width: 15px;vertical-align: bottom;}
    .td7{padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
    .td8{padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td9{padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td10{padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td11{padding: 0px;margin: 0px;width: 183px;vertical-align: bottom;}
    .td12{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td13{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;}
    .td14{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 19px;vertical-align: bottom;}
    .td15{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 101px;vertical-align: bottom;}
    .td16{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
    .td17{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 76px;vertical-align: bottom;}
    .td18{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td19{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td20{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td21{padding: 0px;margin: 0px;width: 123px;vertical-align: bottom;}
    .td22{padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;border-right: #000000 2px solid;}
    .td23{border-right: #000000 2px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;}
    .td24{border-right: #000000 2px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
    .td25{padding: 0px;margin: 0px;width: 76px;vertical-align: top;}
    .td26{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 123px;vertical-align: bottom;}
    .td27{border-right: #000000 2px solid;border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;}
    .td28{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 83px;vertical-align: bottom;}
    .td29{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td30{border-right: #000000 2px solid;border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
    .td31{border-left: white 1px solid;padding: 0px;margin: 0px;width: 123px;vertical-align: top;}
    .td32{border-right: white 1px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: top;}
    .td33{padding: 0px;margin: 0px;width: 83px;vertical-align: top;}
    .td34{border-right: white 1px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: top;}
    .td35{border-left: white 1px solid;padding: 0px;margin: 0px;width: 211px;vertical-align: top;}
    .td36{padding: 0px;margin: 0px;width: 225px;vertical-align: top;}
    .td37{padding: 0px;margin: 0px;width: 143px;vertical-align: top;}
    .td38{padding: 0px;margin: 0px;width: 28px;vertical-align: top;}
    .td62{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 1%;}
    .td39{padding: 0px;margin: 0px;padding-left: 25px;width: 19px;vertical-align: bottom;}
    .td40{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 3%;}
    .td41{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 28px;vertical-align: bottom;}
    .td42{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td43{padding: 0px;margin: 0px;width: 47px;vertical-align: bottom;padding-right: 15px;}
    .td65{padding: 0px;margin: 0px;width: 37px;vertical-align: bottom;padding-left: 13px;}
    .td63{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 4%;}
    .td44{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td45{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;}
    .td46{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 19px;vertical-align: bottom;}
    .td47{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 28px;vertical-align: bottom;}
    .td48{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td49{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td50{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 2%;}
    .td51{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 5%;}
    .td52{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 4%;}
    .td53{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td54{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td55{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td56{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td57{padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td58{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td59{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
    .td60{padding: 0px;margin: 0px;width: 314px;vertical-align: bottom;}
    .td61{border-bottom: grey 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}

    .tr0{height: 19px;}
    .tr1{height: 15px;}
    .tr2{height: 16px;}
    .tr3{}
    .tr4{height: 7px;}
    .tr5{height: 5px;}
    .tr6{height: 22px;}
    .tr7{height: 6px;}
    .tr8{height: 18px;}
    .tr9{height: 10px;}
    .tr10{height: 9px;}
    .tr11{height: 8px;}
    .tr12{height: 36px;}
    .tr13{height: 25px;}

    .t0{width: fit-content;margin-left: 2%;margin-top: 2%;font: 12px 'Arial';}
    .t00{width: 691px;margin-left: 2%;margin-top: 4%;font: 12px 'Arial';}


  </STYLE>
</HEAD>

<BODY>
<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
<!--              <li class="margLR">-->
<!--                <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--              </li>-->
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;">
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="collectionreport()"
                  >GO
                  </button>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Daily Collection Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height: 70vh" class="ag-theme-balham"  [columnDefs]="columnDefs"
                             [gridOptions]="gridOptions" [modules]="modules"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)" [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    &lt;!&ndash; Modal content&ndash;&gt;
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
        </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  *ngFor="let order_detail of all_orders">
            <div style="border-left: black 2px solid;border-right: black 2px solid;border-top: black 2px solid;margin-top: 4%;margin-left: 1%;margin-right: 1%;" >
              <img style="width: 12%;margin-top:2px" [src]="order_detail['logo']">
              <DIV id="page_1">
                <DIV id="p1dimg1">
                  <IMG src="data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAEAAUDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD0eeQTxp+7jj2s/Ma4zk9/p2ooooA//9k=" id="p1img1"></DIV>
                &lt;!&ndash;              <div>&ndash;&gt;
                &lt;!&ndash;                <img style="width: 15%;margin-left: 65%;" src="./../../assets/qrCode.png">&ndash;&gt;
                &lt;!&ndash;              </div>&ndash;&gt;

                <DIV id="id1_1">
                  <P style="padding-left: 38% !important;margin-top: 9% !important;" class="p1 ft1">{{order_detail['dc_name']}}</P>
                  <P style="margin-top:0;font-size: 17px;font-weight: 500;padding-left: 43% !important;" class="p1 ft1">{{order_detail['dc_address']}}</P>
                  <P style="margin-top:0;font-size: 17px;font-weight: 500;padding-left: 46% !important;" class="p1 ft1">{{order_detail['dc_state']}}</P>
                  <P style="margin-top:0;font-size: 16px;font-weight: 500;padding-left: 39% !important;" class="p1 ft1"><SPAN style="font-size: 18px;" class="ft1">{{order_detail['label_config']['TaxLabel']}} </SPAN>{{order_detail['dc_gstn']}}</P>
                  <P style="border-top: black solid 2px;
                            margin-top: 15px;
                            font-size: 17px;
                            font-weight: bold;
                            padding-left: 41% !important;
                            margin-left: 2%;" class="p1 ft1">Cash Receipt</P>
                  <TABLE cellpadding=0 cellspacing=0 class="t0">
                    <TR>
                      <TD colspan=6 class="tr0 td0" style="border-right: black 2px solid;border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;">Customer: <span style="font-weight: 100;">{{order_detail['cust_name']}}</span></P></TD>
                      &lt;!&ndash;                    <TD colspan=2 class="tr0 td0"><P class="p2 ft2" style="margin-top: -10.1% !important;border-top: black 2px solid;"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td2"><P class="p2 ft2" style="margin-top: -10.3% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td3"><P class="p2 ft2" style="margin-top: -14.1% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      <TD colspan=7 class="tr0 td0" style="border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;">Rcpt No: <span style="font-weight: 100;">{{order_detail['receipt']}}</span></P></TD>
&lt;!&ndash;                      <TD class="tr0 td5"><P class="p2 ft3"></P></TD>&ndash;&gt;
&lt;!&ndash;                      <TD colspan=2 class="tr0 td4"><P class="p2 ft3"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td6"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td7"><P class="p2 ft2"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td8"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td9"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td10"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                    </TR>
                    <TR>
                      <TD class="tr2 td12"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td13"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td14"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=3 class="tr2 td12" style="border-right: black 2px solid;"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td15" ><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td16"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr2 td17"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td18"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td19"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td20"><P class="p2 ft3">&nbsp;</P></TD>
                    </TR>
                    <TR>
                      <TD colspan=6 class="tr0 td0" style="border-right: black 2px solid;border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;">Customer No: <span style="font-weight: 100;">{{order_detail['cust_code']}}</span></P></TD>
                      &lt;!&ndash;                    <TD colspan=2 class="tr0 td0"><P class="p2 ft2" style="margin-top: -10.1% !important;border-top: black 2px solid;"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td2"><P class="p2 ft2" style="margin-top: -10.3% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td3"><P class="p2 ft2" style="margin-top: -14.1% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      <TD colspan=7 class="tr0 td0" style="border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;">Date: <span style="font-weight: 100;">{{order_detail['payment_date']}}</span></P></TD>
                      &lt;!&ndash;                      <TD class="tr0 td5"><P class="p2 ft3"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                      <TD colspan=2 class="tr0 td4"><P class="p2 ft3"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td6"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td7"><P class="p2 ft2"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td8"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td9"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td10"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                    </TR>
                    <TR>
                      <TD class="tr2 td12"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td13"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td14"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=3 class="tr2 td12" style="border-right: black 2px solid;"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td15" ><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td16"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr2 td17"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td18"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td19"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td20"><P class="p2 ft3">&nbsp;</P></TD>
                    </TR>
                    <TR>
                      <TD colspan=6 class="tr0 td0" style="border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;">Salesman: <span style="font-weight: 100;">{{order_detail['sman']}}</span></P></TD>
                      &lt;!&ndash;                    <TD colspan=2 class="tr0 td0"><P class="p2 ft2" style="margin-top: -10.1% !important;border-top: black 2px solid;"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td2"><P class="p2 ft2" style="margin-top: -10.3% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td3"><P class="p2 ft2" style="margin-top: -14.1% !important;border-top: black 2px solid;">&nbsp;</P></TD>&ndash;&gt;
                      <TD colspan=7 class="tr0 td0" style="border-top: black 2px solid;"><P class="p2 ft2" style="margin-left: 1.3%;"></P></TD>
                      &lt;!&ndash;                      <TD class="tr0 td5"><P class="p2 ft3"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                      <TD colspan=2 class="tr0 td4"><P class="p2 ft3"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td6"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td7"><P class="p2 ft2"></P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td8"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td9"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                      &lt;!&ndash;                    <TD class="tr0 td10"><P class="p2 ft3">&nbsp;</P></TD>&ndash;&gt;
                    </TR>
                    <TR>
                      <TD class="tr2 td12"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td13"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td14"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=3 class="tr2 td12"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td15" ><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td16"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr2 td17"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td18"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td19"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr2 td20"><P class="p2 ft3">&nbsp;</P></TD>
                    </TR>
                    <TR>
                      <TD colspan=2 class="tr3 td22"><P class="p5 ft2">Invoice No</P></TD>
                      <TD colspan=2 class="tr3 td0"><P class="p2 ft2">Invoice Date</P></TD>
                      <TD class="tr3 td23"><P class="p6 ft2"></P></TD>
                      <TD colspan=2 class="tr3 td8"><P class="p2 ft2">Payment Type</P></TD>
                      <TD class="tr3 td24"><P class="p2 ft3"></P></TD>
                      <TD class="tr3 td8"><P class="p2 ft2">&nbsp;</P>Remarks</TD>
                      <TD class="tr3 td24"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr3 td8"><P class="p2 ft2">Amount</P></TD>
                      <TD class="tr3 td8"><P class="p2 ft3"></P></TD>
                    </TR>
                    <TR>
                      <TD colspan=2 class="tr4 td27" ><P class="p2 ft5">&nbsp;</P></TD>
                      <TD colspan=2 class="tr4 td28"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD class="tr4 td27"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD colspan=2 class="tr4 td28"><P class="p2 ft5">&nbsp;</P></TD>
                      &lt;!&ndash;                      <TD class="tr4 td29"><P class="p2 ft5">&nbsp;</P></TD>&ndash;&gt;
                      <TD class="tr4 td27"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD class="tr4 td28"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD class="tr4 td27"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD colspan=2 class="tr4 td18"><P class="p2 ft5">&nbsp;</P></TD>
                      <TD class="tr4 td19"><P class="p2 ft5">&nbsp;</P></TD>
&lt;!&ndash;                      <TD class="tr4 td30"><P class="p2 ft5">&nbsp;</P></TD>&ndash;&gt;
                    </TR>
                    <TR style="height: 30px;border-bottom: black 2px solid;">
                      <TD colspan=2 class="tr3 td31" style="border-right: black 2px solid;"><P class="p8 ft4">{{order_detail['inv_no']}}</P></TD>
                      <TD colspan=2 class="tr3 td33"><P class="p7 ft4">{{order_detail['invoice_date']}}</P></TD>
                      <TD class="tr3 td3" style="border-right: black 2px solid;"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr3 td37"><P class="p9 ft4">{{order_detail['receipt_type']}}<span *ngIf="order_detail['chq_no']">  {{order_detail['chq_no']}}</span></P></TD>
                      <TD class="tr3 td9" style="border-right: black 2px solid;"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD class="tr3 td37"><P class="p9 ft4">{{order_detail['branch']}}</P></TD>
                      <TD class="tr3 td10" style="border-right: black 2px solid;"><P class="p2 ft3">&nbsp;</P></TD>
                      <TD colspan=2 class="tr3 td37"><P class="p9 ft4">{{order_detail['pay_amount']}}</P></TD>
                      <TD class="tr3 td10"><P class="p2 ft3">&nbsp;</P></TD>
                    </TR>
                  </TABLE>
                </DIV>
              </DIV>
            </div>
            <p style="margin-left: 1%;margin-top: 10px;font-size: 110%;">This is a computer generated invoice, No signature is required.</p>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
      </div>
    </div>
  </div>
</div>

<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>-->
</BODY>
</HTML>
