/**
 * Created by jayhamilton on 1/24/17.
 */
import {
  ViewChild, ElementRef, AfterViewInit, Component, Input, Inject, OnInit
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {AddGadgetService} from "../../add-gadget/service";

declare var jQuery: any;

/**
 * Message Modal - clasable modal with message
 *
 * Selector message-modal
 *
 * Methods
 *      popMessageModal - display a message modal for a sepcified duration
 *      showMessageModal - show the message modal
 *      hideMessageModal - hide the message modal
 */
@Component({
    selector: 'app-help-modal',
    moduleId: module.id,
    templateUrl: './help-modal.html',

})
export class HelpModalComponent implements OnInit {

  title: string;
  key: string;
  description: string;

  constructor(public dialog: MatDialog,
              private _addGadgetService: AddGadgetService,
              @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    if (dialog_data.title) {
      this.title = dialog_data.title;
      this.key = dialog_data.key;
    }
  }

  ngOnInit(): void {
    this.getGadgetFromLibrary();
  }

  getGadgetFromLibrary() {
    this._addGadgetService.getGadgetLibrary().subscribe(data => {
      // const gadget = data.library.find(item => {
      //   return item.name === this.title;
      // });
      // if (Object.keys(gadget).length > 0) {
      //   this.description = gadget.description;
      // }

      data.library.forEach(gadget => {
        const props = gadget['config']['propertyPages'][0]['properties'];
        props.forEach(p => {
          if (p['key'] === 'kpi_name' && p['value'] === this.key) {
            this.description = gadget.description;
          }
        });
      });
    });
  }


  closeDialog() {
    this.dialog.closeAll();
  }


}
