
<section class="dialog-section" #messagemodal_tag>
  <div class="container-padding">
    <div class="header-section">
        <h2 class="modal-title"> Add Gadegt</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    <div class="body-section">
        <app-data-list

          [objectList]="gadgetObjectList"
          [objectTitleList]="gadgetObjectTitleList"
          [placeHolderText]="placeHolderText"
          [typeAheadIsInMenu]="typeAheadIsInMenu"
          [layoutColumnOneWidth]="layoutColumnOneWidth"
          [layoutColumnTwoWidth]="layoutColumnTwoWidth"
          [listHeader]="listHeader"
          [facetTags]="facetTags">

          <ng-template  let-item="$implicit">

            <div [@showHideAnimation] class="ui segment" style="background-color:white;">
              <div class="ui large middle aligned divided list">
                <div class="item">
                  <div class="right floated content">
                    <!-- todo : if there are more than one action display a button with
                    vertical ellipse and implement onHover to show all action buttons
                    -->
                    <a *ngIf="item.actions.length == 1"
                       mat-raised-button
                       routerLink="."
                       (click)="actionHandler(item, item.actions[0].name)" color="primary">
                      {{item.actions[0].name}}
                    </a>
                  </div>
<!--                  <img class="ui image" src="{{item.icon}}">-->
                  <div class="header">{{item.name}}</div>
                  <div class="content small mt-3" #descriptionDiv [innerHTML]="item.description">>
                  </div>
                </div>
              </div>
              <div class="ui bottom attached small">
                <button *ngFor="let tag of item.tags" class="mini ui basic gray compact button">{{tag.name}}
                </button>
              </div>
            </div>

          </ng-template>

        </app-data-list>
      </div>
    </div>
</section>
