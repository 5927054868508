import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-secondary-sales-detailed',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class SecondarySalesDetailedIMEIReport extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  setTitle() {
    this.title = 'Secondary Sales Report - Detailed(IMEI)';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Secondary Sales Report - Detailed(IMEI)');
    this.API_URL = '/api/pwa_reports/getOutletSalesReportDetailedImei';
    this.userData.customFilter['type'] = 'S';
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'line_id');
    this.showProgressCustomText = true;
    this.configureGrid();

  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "Region", field: "region_name", width: 100},
      {headerName: "Cluster", field: "cluster_name", width: 100},
      {headerName: "User", field: "user_name", width: 100},
      {headerName: "Role", field: "user_role", width: 100},
      {
        headerName: "Sale Date", field: "ord_date", width: 100,
        /* valueGetter: params => {
           if (params.data) {
             if (params.data.ord_date) {
               return inThis.datePipe.transform((new Date(params.data.ord_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
             } else {
               return params.data.ord_date;
             }
           } else {
             return '';
           }
         }*/
      },

      {
        headerName: 'CUSTOMER',
        children: [
          {headerName: "Customer ID", field: "cust_id", width: 100},
          {headerName: "Customer Name", field: "cust_name", width: 100},
          {headerName: "Customer Code", field: "cust_code", width: 100},
          {
            headerName: "Customer Status", field: "cust_status", width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.cust_status) {
                  return 'Active';
                } else {
                  return 'Inactive';
                }
              }
            }
          },
          {headerName: "Customer GST", field: "cust_gst", width: 100},
          {headerName: "Customer Mobile", field: "cust_mobile", width: 100},
          {headerName: "Customer Email", field: "cust_email", width: 100},
          {headerName: "Customer Class", field: "cust_class", width: 100},
          {headerName: "Channel", field: "channel", width: 100},
          {headerName: "Customer Type", field: "cust_type", width: 100},
          {headerName: "Customer Program Type", field: "cust_pgm_type", width: 100},
          {headerName: "Customer Lat", field: "cust_lat", width: 100},
          {headerName: "Customer Long", field: "cust_long", width: 100},
          {headerName: "Customer Address", field: "cust_address", width: 100},
          {headerName: "Customer State", field: "cust_state", width: 100},
          {headerName: "Customer District", field: "cust_zone", width: 100},
          {headerName: "Customer Area", field: "cust_area", width: 100},
          {headerName: "Customer City", field: "cust_city", width: 100},
          {headerName: "Customer Created", field: "cust_created", width: 100},
          {headerName: "Customer Target", field: "cust_target", width: 100},
          {headerName: "Customer Manager Code", field: "cust_manager_code", width: 100},
          {headerName: "Customer Manager", field: "cust_manager", width: 100},
        ]
      },

      {
        headerName: 'SALES TEAM',

        children: [
          {headerName: "Emp Code", field: "user_code", width: 100},
          {headerName: "SAC", field: "user_sac", width: 100},
          {headerName: "Team", field: "team", width: 100},
          {headerName: "Team Lead", field: "team_lead", width: 100},
          {headerName: "User State", field: "user_state", width: 100},
          {headerName: "Beat ID", field: "beat_id", width: 100},
          {headerName: "Beat Name", field: "beat_name", width: 100},
          {headerName: "Terr Code", field: "terr_code", width: 100},
          {headerName: "Terr Name", field: "terr_name", width: 100},
        ]
      },
      {
        headerName: 'DISTRIBUTOR',

        children: [
          {headerName: "Distributor ID", field: "dist_id", width: 100},
          {headerName: "Distributor Name", field: "dist_name", width: 100},
          {headerName: "Distributor Code", field: "dist_code", width: 100},
          {headerName: "Distributor Address", field: "dist_address", width: 100},
          {headerName: "Distributor City", field: "dist_city", width: 100},
          {headerName: "Distributor State", field: "dist_state", width: 100},
          {headerName: "Distributor GST", field: "dist_gst", width: 100},
          {headerName: "Distributor PAN", field: "dist_pan", width: 100},
        ]
      },
      {
        headerName: 'ORDER INFO',

        children: [
          {headerName: "Order ID", field: "ord_id", width: 100},
          {headerName: "Order Reference", field: "ord_reference", width: 100},
          {headerName: "Order Type", field: "ord_type", width: 100},
          {headerName: "Order Status", field: "ord_state", width: 100},
          {headerName: "Invoice No", field: "invoice_no", width: 100},
          {headerName: 'Visit Type', field: 'visit_type', width: 80},
          {headerName: "Last Modified", field: "last_modified", width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.last_modified) {
                  return inThis.datePipe.transform((new Date(params.data.last_modified + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.last_modified;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: "Delivered Date", field: "dlr_date", width: 100,
            /* valueGetter: params => {
               if (params.data) {
                 if (params.data.dlr_date) {
                   return inThis.datePipe.transform((new Date(params.data.dlr_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                 } else {
                   return params.data.dlr_date;
                 }
               } else {
                 return '';
               }
             }*/
          },
          {headerName: "Additional Notes", field: "note", width: 100},
          {headerName: "Line Type", field: "line_type", width: 100},
        ]
      },
      {
        headerName: 'PRODUCT INFO',

        children: [
          {headerName: "Product Name", field: "prod_name", width: 100},
          {headerName: "Product Code", field: "prod_code", width: 100},
          {headerName: "Product Family", field: "prod_family", width: 100},
          {headerName: "Product Brand", field: "prod_brand", width: 100},
          {headerName: "Product Category", field: "prod_categ", width: 100},
          {headerName: "Price", field: "price_unit", width: 100},
          {headerName: "Secondary Qty", field: "qty", width: 100, aggFunc: 'sum'},
          {headerName: "Delivered Qty", field: "dlr_qty", width: 100, aggFunc: 'sum'},
          {headerName: "Invoiced Qty", field: "inv_qty", width: 100, aggFunc: 'sum'},
          {headerName: "Qty in RUOM", field: "qty_ruom", width: 100, aggFunc: 'sum'},
          {headerName: "Delivered Qty in RUOM", field: "dlr_qty_ruom", width: 100},
          {headerName: "Invoiced Qty in RUOM", field: "inv_qty_ruom", width: 100},
          {headerName: "Line Disc(%)", field: "line_discount_in_pct", width: 100},
          {headerName: "Line Disc", field: "line_discount_in_amt", width: 100},
          {headerName: "SPL Disc", field: "spl_discount", width: 100},
          {headerName: "Scheme Disc", field: "scheme_disc", width: 100},
          {headerName: "Discount", field: "discount", width: 100},
          {headerName: "Credits", field: "credits", width: 100},
          {headerName: "Subtotal", field: "price_subtotal", width: 100, aggFunc: 'sum'},
          {
            headerName: "CGST", field: "tax_json", width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'CGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {
            headerName: "SGST", field: "tax_json", cellStyle: {textAlign: 'center'}, width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'SGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {
            headerName: "IGST", field: "tax_json", cellStyle: {textAlign: 'center'}, width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'IGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {headerName: "Tax", field: "price_tax", cellStyle: {textAlign: 'center'}, width: 100},
          {headerName: "Total", field: "price_total", cellStyle: {textAlign: 'center'}, width: 100},
          {
            headerName: "TDS/TCS", field: "tax_source", width: 100,
            valueGetter: params => {
              if (params.data) {
                return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
              }
            }
          }
        ]
      },
    ];

    /*  var taxMaster = this.apiData.taxmaster;
      for (var key in taxMaster) {
        this.columnDefs.push({
          headerName: taxMaster[key].description,

          field: taxMaster[key].description,
          cellStyle: {textAlign: 'center'},

          filter: true,

          width: 100,
          resizable: true
        });
      }
  */
    this.columnDefs.push(
      {headerName: "Tax", field: "price_tax", cellStyle: {textAlign: 'center'}, width: 100},
      {headerName: "Total", field: "price_total", cellStyle: {textAlign: 'center'}, width: 100},
      {
        headerName: "TDS/TCS", field: "tax_source", width: 100,
        valueGetter: params => {
          if (params.data) {
            return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
          }
        }
      },
      {headerName: "IMEI", field: "serial_no", width: 100}
    );
  }

  /* loadServerData() {
  this.goClicked = true;
   this.dataLoaded = false;
     this.preloader = true;
     this.emptyState = false;
     this.errorExists = false;

     if (this.API_URL === '') {
       return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
     }

     this.apiService.post(this.API_URL, this.userData)
       .subscribe(res => {
           console.log(res);
           if (res.hasOwnProperty('results') && (res.results.status === 200)) {
             this.apiData = res.results;
             this.configureGrid();
             this.configureData();
             this.dataLoaded = true;
             this.preloader = false;
             this.emptyState = (this.rowData.length === 0);
           } else if (res.results.status === 203) {
             this.handleError(ErrorHandler.getErrorObject(res.results.status));
           } else {
             this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
           }
         },
         // tslint:disable-next-line:no-shadowed-variable
         error => this.handleError(ErrorHandler.getErrorObject(error)));
   }*/

  /* postProcessData() {
     this.configureGrid();
     this.configureData();
   }
 */
  /*configureData() {
    this.rowData = [];
    var taxMaster = this.apiData.taxmaster;
    var salesMaster = this.apiData.sales;
    var taxLines = this.apiData.taxlines;

    for (var key in this.apiData.sales) {

      var indrow = {};
      var taxRow: any = {};
      var salesRow: any = {};
      var taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(function (tax) {
        return tax.id == salesRow.line_id;
      });

      for (var key in taxMaster) {
        taxRow[taxMaster[key].description] = taxLine.filter(function (tax) {
          return tax.description == taxMaster[key].description;
        })[0];
        if (taxRow[taxMaster[key].description] != null && taxRow[taxMaster[key].description] != undefined)
          taxRow[taxMaster[key].description] = taxRow[taxMaster[key].description].tax_amt;
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }*/
}

