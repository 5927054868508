import {Component, OnInit} from "@angular/core";
import {formatDate} from "@angular/common";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {AssignVendorPopupComponent} from "./assign_vendor_popup/assign_vendor_popup.component";
import {CreateRetailRequestPopupComponent} from "./create_retail_request_asset_popup/create_retail_request_asset_popup.component";
import {AssignServiceExecutivePopupComponent} from "./assign_service_executive_popup/assign_service_executive_popup.component";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-retail-asset-request-component',
  templateUrl: '../pagebase/pagebasev2.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class RetailAssetRequestComponent extends PageBase implements OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'pending',
    values: [],
    show: true
  };
  role_features = [];
  uqAttributes: any = [];
  role = '';
  matActionMenuItemDeclaration = [{name: 'Validate Requests', feature: 'feature_validate_asset_request'},
    {name: 'Confirm Requests', feature: 'feature_confirm_asset_request'},
    {name: 'Assign Vendor', feature: 'feature_asset_assign_vendor'},
    {name: 'Assign Service Executive', feature: 'feature_asset_assign_service_executive'}];
  currentProfile;

  ngOnInit() {
    this.sharedService.setHeader('Retail Asset Request');
    this.currentProfile = JSON.parse(localStorage.getItem('user')).profile_name;
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;
    if (this.currentProfile === 'Vendor') {
      this.customFilter.value = 'confirmed';
      this.customFilter.values = [
        {id: "", itemName: "All"},
        {id: "confirmed", itemName: "Confirmed"},
        {id: "installation", itemName: "Installation"},
        {id: "deployed", itemName: "Deployed"},
      ];
    } else {
      this.customFilter.value = 'pending';
      this.customFilter.values = [
        {id: "", itemName: "All"},
        {id: "pending", itemName: "Pending"},
        {id: "approved", itemName: "Validated"},
        {id: "confirmed", itemName: "Confirmed"},
        {id: "installation", itemName: "Installation"},
        {id: "deployed", itemName: "Deployed"},
      ];
    }

    this.tabIndex = 1;
    this.container = 'partner_asset';
    this.showCreate = this.sharedService.ACLcheck('feature_create_retail_asset');
    this.showActionButton = true;
    this.API_URL = '/api/partner_assets/get_partner_asset';
    this.getAuthorizedActions();
    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            headerName: "ID", field: "id", width: 80,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
          },
          {
            headerName: "Status", field: "status", width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.status === 'approved') {
                  return 'validated';
                } else {
                  return params.data.status;
                }
              }
            }
          },
          {headerName: "Salesman", field: "salesman", width: 80},
          {headerName: "Emp Code", field: "scode", width: 80},
          {headerName: "Emp Mobile", field: "user_mobile", width: 80, hide: true},
          {headerName: "Vendor", field: "vendor", width: 80},
          {headerName: "Class", field: "class_type", width: 80},
          {headerName: "Outlet Type", field: "out_type", width: 80},
          {headerName: "Outlet", field: "retailer", width: 120},
          {headerName: "OCode", field: "r_code", width: 80},
          {headerName: "Street", field: "street", width: 80, hide: true},
          {headerName: "Area", field: "area", width: 80, hide: true},
          {headerName: "District", field: "district", width: 80, hide: true},
          {headerName: "Zip", field: "zip", width: 50, hide: true},
          {headerName: "Mobile", field: "mobile", width: 80, hide: true},
          {
            headerName: "Create Date", field: "create_date", width: 60,
            cellRenderer: (params) => {
              if (params.data) {
                return params.value ? formatDate(new Date(params.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
              }
            }
          },
          {headerName: "Asset Name", field: "asset_name", width: 100},
          {headerName: "Asset", field: "asset_type", width: 100},
          {
            headerName: "Photo", field: "url", width: 50,
            valueGetter: this.generateImageUrl('asset_store_fname'),
            cellRenderer(params) {
              if (params.data.asset_store_fname === null || params.data.asset_store_fname === "") {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              } else {
                return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
              }
            }
          },
          {headerName: "Qty", field: "qty", width: 40},
          {headerName: "Approved Qty", field: "approved_qty", width: 40},
          {
            headerName: "Reviewed", field: "reviewed", width: 40,
            valueGetter(params) {
              if (params.data.reviewed === 'reviewed') {
                return "YES";
              } else {
                return "NO";
              }
            }
          },
          {headerName: "Comments", field: "comments", resizable: true, width: 100},
        ]
      },
      {
        headerName: 'ATTRIBUTES',

        children: []
      }
    ];

    for (const key of this.uqAttributes) {
      const header = this.sharedService.titleCase(key);
      this.columnDefs[1].children.push(
        {headerName: header, field: 'attr_' + key, width: 80, aggFunc: 'sum'},
      );
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqAttributes = new Set();
    this.apiData.forEach(obj => {
      obj['deployed'] = false;
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (const [key, value] of Object.entries(obj.config_json)) {
          if (key === 'attribute') {
            for (const [a_key, attribute] of Object.entries(value)) {
              this.uqAttributes.add(a_key);
              obj['attr_' + a_key] = attribute;
            }
          } else {
            this.uqAttributes.add(key);
            if (key.includes('assigned_on')) {
              obj['attr_' + key] = this.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
            } else if (key.includes('vendor_assigned_by')) {
              obj['attr_' + key] = obj.vendor_assigned_by;
            } else {
              obj['attr_' + key] = value;
            }
          }
        }
      }
    });
    this.rowData = this.apiData;
    this.filteredData = this.rowData;
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Asset');
    } else {
      if (menuitem.name === 'Validate Requests') {
        if (this.validateValidateRows(rows)) {
          this.openValidateDialog(rows);
        } else {
          this.toastr.error("Select only Pending Assets");
        }
      } else if (menuitem.name === 'Confirm Requests') {
        if (this.validateConfirmRows(rows)) {
          this.openConfirmDialog(rows);
        } else {
          this.toastr.error("Select only Validated Assets");
        }
      } else if (menuitem.name === 'Assign Vendor') {
        if (this.validateVendorAssignRows(rows)) {
          this.openAssignVendorPopup(rows);
        } else {
          this.toastr.error("Vendor already Assigned");
        }
      } else if (menuitem.name === 'Assign Service Executive') {
        if (this.validateServiceExecutiveAssignRows(rows)) {
          this.openAssignServiceExecutivePopup(rows);
        } else {
          this.toastr.error("Vendor not Assigned");
        }
      }
    }
  }

  validateValidateRows(rows) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].status !== 'pending') {
        valid = false;
        break;
      }
    }
    return valid;
  }

  validateConfirmRows(rows) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].status !== 'approved') {
        valid = false;
        break;
      }
    }
    return valid;
  }

  validateVendorAssignRows(rows) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]?.vendor_id) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  validateServiceExecutiveAssignRows(rows) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i]?.vendor_id) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    for (const key in filteredRowData) {
      filteredRows.push(filteredRowData[key].data);
    }
    if (filteredRows !== null && filteredRows.length > 0) {
      this.storeAssetData(filteredRows);
    }
    localStorage.setItem('id', event.data.id);
    const index = JSON.parse(localStorage.getItem("asset_ids")).indexOf(event.data.id);
    localStorage.setItem('index', index);
    this.router.navigate([this.apiService.redirect_url + '/assets/retail_request/details'], {
      state: {
        viewtype: 'detail',
        id: event.data.id
      }
    });
  }

  storeAssetData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('asset_ids', JSON.stringify(ids));
  }

  openConfirmDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Confirm Requests",
        message1: "You have selected " + rows.length + " manager-approved request(s) for Confirmation.",
        message2: "Are you sure you want to Confirm these Request(s)?",
        showNoButton: true,
        yesButton: "CONFIRM",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.confirmAssets(rows);
      } else {

      }
    });
  }

  openValidateDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Validate Requests",
        message1: "You have selected " + rows.length + " request(s) for Validation.",
        message2: "Are you sure you want to Validate these Request(s)?",
        showNoButton: true,
        yesButton: "VALIDATE",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.validateAssets(rows);
      } else {

      }
    });
  }

  confirmAssets(rows) {
    const ids = [];
    rows.forEach(row => {
      ids.push(row.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids
    };
    this.apiService.post('/api/partner_assets/mark_asset_confirmed', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(rows.length + " Asset(s) Confirmed Successfully");
          this.loadServerData();
        } else {
          this.toastr.error("Failed to Confirm Assets");
        }
      });
  }

  validateAssets(rows) {
    const ids = [];
    rows.forEach(row => {
      ids.push(row.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids,
      status: "approved"
    };
    this.apiService.post('/api/partner_assets/mark_asset_approved', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(rows.length + " Asset(s) Confirmed Successfully");
          this.loadServerData();
        } else {
          this.toastr.error("Failed to Confirm Assets");
        }
      });
  }

  openAssignVendorPopup(rows) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "30%",
        maxHeight: "75%",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignVendorPopupComponent, config);
      creatediaeref.componentInstance.vendorAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignVendorPopupComponent, config);
      creatediaeref.componentInstance.vendorAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    }
  }

  openAssignServiceExecutivePopup(rows) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "430px",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceExecutivePopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceExecutivePopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    }
  }

  openCreateDialog() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "400px",
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(CreateRetailRequestPopupComponent, config);
      creatediaeref.componentInstance.createAssetEvent.subscribe(data => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(CreateRetailRequestPopupComponent, config);
      creatediaeref.componentInstance.createAssetEvent.subscribe(data => {
        this.loadServerData();
      });
    }
  }
}
