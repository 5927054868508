import {Component, OnInit} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-retail_audit_report',
  templateUrl: '../_common/reportbase.html',
})

export class RetailAuditDistributionReportComponent  extends ReportBase implements OnInit {

 /* showableFilters={
    max_claim:false,
    policy:false,
    salesman:true,
    status:false,
    class:false,
    beat:false,
    trax_outlet:true,
    non_trax_outlet:true,
    exclude_trax_audit:true,
    region:true,
    cluster:true,
    team:true,
    territory:false,
    outlet_type: false,
    dc: false
  }*/

  /* public myDate = [];
   public auditReportData : any= [];
   access_token;
   user_id : null;
   user_data = {
     date: null,
     access_token: null,
     url: null,
     data: {
       start_date:null,
       end_date:null
     }
   };
   private gridApi;
   private gridColumnApi;
   public columnDefs = [];
   public rowData = [];
   public gridOptions: GridOptions;
   public loaded = false;
   public preloader = false;
   public empty_state = false;
   public defaultColDef;
   public styleGrid;
   public frameworkComponents;
   public context;
   public collapse_disable;
   public data = [];
   API_URL='';
   showableFilters={
     max_claim:false,
     policy:false,
     salesman:true,
     status:false,
     class:false,
     beat:false,
     trax_outlet:true,
     non_trax_outlet:true,
     exclude_trax_audit:true,
     region:true,
     cluster:true,
     team:true,
     territory:true
   }*/


  /* constructor(private apiService: ApiService,
               private router: Router,
               private datePipe: DatePipe,
               private renderer: Renderer2,
               private sharedService: SharedService,
               private ngzone: NgZone,
               private elRef: ElementRef)
   {
     //this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
     this.myDate[0] = '2021-06-01';
     this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
     //this.myDate[1] = '2021-06-31';
     this.user_data.access_token = localStorage.getItem('resfreshToken');
     this.access_token = this.user_data.access_token;
     this.user_data.url = localStorage.getItem('private_url');
     //console.log(localStorage.getItem('private_url'));
     this.user_id = JSON.parse(localStorage.getItem('user')).id;

     this.gridOptions = { rowHeight: 40,
       rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
       sideBar: {
         toolPanels: [
           {
             id: 'filters',
             labelDefault: 'Filters',
             labelKey: 'filters',
             iconKey: 'filter',
             toolPanel: 'agFiltersToolPanel',
           },
         ],
         position: 'right',
         defaultToolPanel: '',
       },
       rowSelection: 'multiple'} as GridOptions;

     var indrow = {
       "category":"BIO-Scotch",
       "template":"Telisker",
       "TL<":"23%",
       "TN":"11%",
       "TP":"56%",
       "TQ":"56%",
       "T>D":"34% ",
       "AL<":"23%",
       "AN":"11%",
       "AP":"56%",
       "AQ":"56%",
       "A>D":"34% ",
     }

     this.rowData.push(indrow);
     this.preloader = false;
     this.empty_state = false;

     this.loaded = true;

     this.columnDefs = [
       { headerName: "CATEGORY",  field: "category",   menuTabs: [], width: 50},
       { headerName: "TEMPLATE",field: "template",   menuTabs: [], width: 80},


       { headerName: "L<",field: "TL<",  menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#FCF6F4' };
         }},
       { headerName: "N", field: "TN",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#FCF6F4'};
         }},

       { headerName: "P", field: "TP",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#FCF6F4' };
         }},
       { headerName: "Q", field: "TQ",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#FCF6F4' };
         }},
       { headerName: ">D", field: "T>D",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#FCF6F4' };
         }},
       { headerName: "L<",field: "AL<",  menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#EEF6F5' };
         }
       },
       { headerName: "N", field: "AN",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#EEF6F5' };
         }},
       { headerName: "P", field: "AP",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#EEF6F5' };
         }},
       { headerName: "Q", field: "AQ",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#EEF6F5' };
         }},
       { headerName: ">D", field: "A>D",   menuTabs: [], width: 50,
         cellStyle: function (params) {
           return { background: '#EEF6F5' };
         }},

     ];
   }
 */

  ngOnInit() {
    this.sharedService.setHeader('Availability(Prod Summary) Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
      }

  setTitle() {
    // this.title = 'Beat Performance Reports';
  }

  getValue(params){
    var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
    if(jsonValue.hasOwnProperty('T->L'))
    {
      return jsonValue['T->L'];
    }
    else{
      return "";
    }

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDistributionSummary';

    /* this.myDate[0] = '2021-06-01';
     this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');*/

    /*  this.gridOptions = { rowHeight: 40,
        rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
        sideBar: {
          toolPanels: [
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
          position: 'right',
          defaultToolPanel: '',
        },
        rowSelection: 'multiple'} as GridOptions;*/

    this.columnDefs = [
      { headerName: "",

        children: [
          { headerName: "CATEGORY",  field: "category",   menuTabs: [], width: 100},
          { headerName: "TEMPLATE",field: "product",   menuTabs: [], width: 150},
        ]},
      { headerName: "TRAX (%)",

        children: [
          { headerName: ">L",field: "T->L",  menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#FCF6F4',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('T->L'))
              {
                return jsonValue['T->L'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "Q", field: "T-Q",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#FCF6F4',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('T-Q'))
              {
                return jsonValue['T-Q'];
              }
              else{
                return "";
              }
            }},
          { headerName: "P", field: "T-P",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#FCF6F4',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('T-P'))
              {
                return jsonValue['T-P'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "N", field: "T-N",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#FCF6F4',textAlign: 'center'};
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('T-N'))
              {
                return jsonValue['T-N'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "<D", field: "T-<D",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#FCF6F4',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('T-<D'))
              {
                return jsonValue['T-<D'];
              }
              else{
                return "";
              }
            }},
        ]},
      { headerName: "AVAILABILITY (%)",

        children: [
          { headerName: ">L",field: "A->L",  menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#EEF6F5',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('A->L'))
              {
                return jsonValue['A->L'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "Q", field: "A-Q",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#EEF6F5',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('A-Q'))
              {
                return jsonValue['A-Q'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "P", field: "A-P",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#EEF6F5',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('A-P'))
              {
                return jsonValue['A-P'];
              }
              else{
                return "";
              }
            }
          },
          { headerName: "N", field: "A-N",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#EEF6F5',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('A-N'))
              {
                return jsonValue['A-N'];
              }
              else{
                return "";
              }
            }},
          { headerName: "<D", field: "A-<D",   menuTabs: [], width: 50,
            cellStyle: function (params) {
              return { background: '#EEF6F5',textAlign: 'center' };
            },
            valueGetter: function (params) {
              var jsonValue =  JSON.parse(JSON.stringify(params.data.distribution));
              if(jsonValue.hasOwnProperty('A-<D'))
              {
                return jsonValue['A-<D'];
              }
              else{
                return "";
              }
            }
          },
        ]}

    ];

  }

}
