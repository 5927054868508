import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {TaskActivationPopupComponent} from "../../components/task_activation_popup/task_activation_popup.component";


@Component({
  selector: 'app-market_intelligence_report',
  templateUrl: '../_common/reportbase.html',
})
export class TaskActivationReportComponent extends ReportBase implements OnInit {

  API_URL = '';
  apiData: any = [];
  showCustomFilter = true;
  showCustomFilter1 = true;
  /*customFilter: any = {
    name: 'Status',
    key: 'type',
    value: 'completed',
    values: [],
    show: true
  };*/
  customFilter: any = {
    name: 'Status',
    key: 'type',
    value: 'completed',
    values: [],
    show: true
  };
  customFilter1: any = {
    name: 'Reviewed',
    key: 'status',
    value: 'new',
    values: [],
    show: true
  };
  task_types = [];

  ngOnInit(): void {
    let datas: any = {};
    datas = history.state;

    if (datas !== null && datas.hasOwnProperty('start_date')) {
      this.userData.customFilter['id'] = datas.taskID;
      this.myDate[0] = datas.start_date;
      this.myDate[1] = datas.end_date;
      this.userData.start_date = datas.start_date;
      this.userData.end_date = datas.end_date;
    }

    const showApprove = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function(existed) {
      return 'feature_task_approve' === existed.name;
    });
    if (showApprove.length > 0) {
      this.showApproveBtn = true;
    } else {
      this.showApproveBtn = false;
    }

    const showReject = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function(existed) {
      return 'feature_task_reject' === existed.name;
    });
    if (showReject.length > 0) {
      this.showRejectBTn = true;
    } else {
      this.showRejectBTn = false;
    }
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'draft', itemName: 'Draft'},
      {id: 'published', itemName: 'Published'},
      {id: 'completed', itemName: 'Completed'},
      {id: 'rejected', itemName: 'Rejected'}
    ];

    this.customFilter1.values = [
      {id: '', itemName: 'All'},
      {id: 'new', itemName: 'Pending'},
      {id: 'approved', itemName: 'Approved'},
      {id: 'rejected', itemName: 'Rejected'}
    ];

    this.sharedService.setHeader('Task Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showableFilters.active = true;

    this.showDate = true;

    this.showViewSelector = true;
    this.showGalleryview = true;
    this.container = 'task_line';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'task_store_fname',
      name: 'retailer',
      field1: 'code',
      field2: 'date',
      field3: 'type_name',
      status: 'status',
      statusTypes: [
        {name: "published", type: "completed"},
        {name: "completed", type: "success"},
        {name: "new", type: "draft"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['completed']
        },
        {
          field: 'reviewed',
          matches: true,
          values: ['new']
        }
      ]
    };
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_types = this.task_types.filter(x => x.type === 'spl');

    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    // this.configureGrid();
    this.API_URL = '/api/pwa_reports/getTaskActivationReport';
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.loadServerData();
    }
  }

  configureGrid() {


    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Territory',
      field: 'territory',
      minWidth: 150,
      cellRenderer: 'agGroupCellRenderer',
    };
    const inthis = this;
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            headerName: "Task Type", field: "type",
            valueGetter: params => {
              if (params.data) {
                if (params.data.type) {
                  if (params.data.type === 'spl') {
                    return 'Paid';
                  } else {
                    return 'Free';
                  }
                } else {
                  return '';
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "State", field: "state"},
          {headerName: "Region", field: "region" },
          {headerName: "Cluster", field: "cluster"},
          {headerName: "Territory", field: "territory" },
          {headerName: "TCode", field: "tcode"},
          {headerName: "Terr Manager", field: "terr_mgr"},
          {headerName: "Terr Manager Code", field: "tm_code"},
          {headerName: "Beat", field: "beat"},
          {headerName: "Code", field: "code"},
          {headerName: "Task", field: "task_name"},
          {headerName: "Type", field: "type_name"},
          {headerName: "Brand", field: "brand"},
          {headerName: "Executed Date", field: "date"},
          {
            headerName: "Create Date", field: "create_date",
            valueGetter: params => {
              if (params.data) {
                if (params.data.create_date) {
                  return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.create_date;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "Start Date", field: "start_date"},
          {headerName: "End Date", field: "end_date"},
          {headerName: "Retailer", field: "retailer"},
          {headerName: "RCode", field: "retailer_code"},
          {headerName: "Class", field: "class_type"},
          {headerName: "Town Tier", field: "program_type"},
          {headerName: "Owner", field: "owner"},
          {headerName: "Completed By", field: "completed_by"},
          {headerName: "SCode", field: "comp_code"},
          {
            headerName: "Photo", field: "task_store_fname",
            valueGetter: this.generateImageUrl('task_store_fname'),
            cellRenderer(params) {
              if (params.data) {
                if (params.data.task_store_fname === null || params.data.task_store_fname === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {headerName: "Status", field: "status"},
          {headerName: "Reviewed", field: "reviewed"},
          {headerName: "Reviewed By", field: "reviewed_by"},
          {
            headerName: "Reviewed On", field: "reviewed_on",
            valueGetter: params => {
              if (params.data) {
                if (params.data.reviewed_on) {
                  return inthis.datePipe.transform((new Date(params.data.reviewed_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.reviewed_on;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "Remarks", field: "comments"},
          {headerName: "Active", field: "active"}
        ]
      },
      {
        headerName: 'Task Plan',
        children: [
          {headerName: "Category", field: "category"},
          {headerName: "Channel", field: "channel"},
          {headerName: "Period", field: "period"}
        ]
      },
      {
        headerName: 'Task Plan Details',
        children: []
      }
    ];
    const inThis = this;
    const uqTaskConfig = new Set();
    const uqTaskParams = new Set();
    const unique_tasks = this.unique(this.apiData, ['type_name']);
    unique_tasks.forEach(type => {
      const task_type = inThis.task_types.find(x => x.itemname === type['type_name']);
      if (task_type && task_type?.param_json?.config) {
        task_type.param_json.config.forEach(param => {
          if (!uqTaskConfig.has(param.name)) {
            uqTaskConfig.add(param.name);
            inThis.columnDefs[1].children.push({headerName: param.name, field: param.field, valueGetter: inThis.configValueGetter(param.field)});
          }
        });
      }
      if (task_type && task_type?.param_json?.params) {
        /*for (const [key, value] of Object.entries(tasktype.param_json)) {
          if (!uqTaskParams.has(value)) {
            uqTaskParams.add(value);
            inThis.columnDefs[2].children.push({headerName: value, field: key, valueGetter: inThis.configDetailValueGetter(key)});
          }
        }*/
        task_type.param_json.params.forEach(param => {
          if (!uqTaskParams.has(param.name)) {
            uqTaskParams.add(param.name);
            inThis.columnDefs[2].children.push({headerName: param.name, field: param.field, valueGetter: inThis.configDetailValueGetter(param.field)});
          }
        });
        inThis.columnDefs[2].children.push({
          headerName: '% Relevant Universe', field: 'percent_uni',
          valueGetter(params) {
            if (params.data) {
              if (params.data?.config_json?.params?.planned_scale && params.data?.config_json?.params?.relevant_universe) {
                return parseFloat(String((parseInt(params.data.config_json.params.planned_scale, 10) / parseInt(params.data.config_json.params.relevant_universe, 10)) * 100)).toFixed(2);
              }
            }
          }
        });
      }
    });
    inThis.columnDefs[1].children.push({headerName: "Instructions", field: "instructions"},
      {
        headerName: "Plan Create Date", field: "create_date",
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Created By", field: "created_by"},
      {
        headerName: "Details Submitted Date", field: "submitted_at",
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['submitted_at']) {
              return inthis.datePipe.transform((new Date(params.data.config_json['submitted_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Details Submitted By", field: "submitted_by"},
      {
        headerName: "Reviewed Date", field: "reviewed_at",
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['reviewed_at']) {
              return inthis.datePipe.transform((new Date(params.data.config_json['reviewed_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Reviewed By", field: "reviewed_by"},
      {
        headerName: "Retailers Added Date", field: "retailer_uploaded_at",
        valueGetter: params => {
          if (params.data) {
            if (params.data.config_json && params.data.config_json['retailer_uploaded_at']) {
              return inthis.datePipe.transform((new Date(params.data.config_json['retailer_uploaded_at'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "Retailers Added By", field: "ret_added_by"});
  }

  postProcessData() {
    this.configureGrid();
    this.rowData = this.apiData;
    this.filteredData = this.rowData;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column !== 'task_store_fname') {

    } else {

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('task_store_fname') && rows[0].task_store_fname !== null && rows[0].task_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }
      } else {

      }
    }
  }

  openConfirmationDialog(state: string) {

    let title = '';
    let message1 = '';
    let message2 = '';
    let selectedCount = 0;
    const main_ids = [];
    let remaining_ids = [];
    const selectedData = this.pageData.filter(x => x['item_selected'] === true);
    if (selectedData) {
      selectedCount = selectedData.length;
      for (const selected of selectedData) {
        main_ids.push(selected.id);
      }
      const nonSelectedData = this.pageData.filter(x => x['item_selected'] !== true);
      if (nonSelectedData) {
        for (const nonselected of nonSelectedData) {
          remaining_ids.push(nonselected.id);
        }
      }
    } else {
      selectedCount = 0;
    }
    title = state.toUpperCase() + " " + selectedCount + " Task";
    message1 = "Do you wish to " + state.toUpperCase() + " " + selectedCount + " task?";
    message2 = "";
    remaining_ids = [];

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title,
        message1,
        message2,
        showNoButton: true,
        yesButton: state,
        noButton: "CANCEL"
      }
    };

    if (state === 'reject') {
      config.data.deleteButton = true;
    }

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let status = '';
      if (state === 'REJECT') {
        status = 'rejected';
      } else {
        status = 'approved';
      }
      if (data === 'yes') {
        let main_state = '';
        let remaining_state = '';
        if (state === 'reject') {
          main_state = 'rejected';
          remaining_state = 'approved';
        } else {
          main_state = 'approved';
          remaining_state = 'rejected';
        }
        this.markReviewed(main_state, remaining_state, main_ids, remaining_ids);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  markReviewed(main_state, remaining_state, main_ids, remaining_ids) {
    const apiurl = '/api/task_lines/mark_task_reviewed';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    const data = {
      main_state,
      remaining_state,
      main_ids,
      remaining_ids
    };
    paramObject.data = data;
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Reviewed Successfully');
            //window.location.reload();
            this.loadServerData();
          } else {
            this.toastr.error('Error reviewing Task');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error reviewing Task');
        });
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "33%",
        maxHeight: "94%",
        data: {
          taskDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(TaskActivationPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "95%",
        maxHeight: "90%",
        data: {
          taskDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(TaskActivationPopupComponent, config);
    }
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config_json && params.data.config_json.task_config) {
          if (params.data.config_json.task_config[field]) {
            return params.data.config_json.task_config[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  configDetailValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config_json && params.data.config_json.params) {
          if (params.data.config_json.params[field]) {
            return params.data.config_json.params[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }
}
