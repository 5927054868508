import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-popup',
  templateUrl: './image_popup.component.html',
  styleUrls: ['./image_popup.component.scss']

})

export class ImagePopupComponent implements OnInit {

  public photo;
  store_fname;
  API_URL = '';
  container = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.store_fname = dialogdata.store_fname;
    this.container = dialogdata.container;
    if (dialogdata.hasOwnProperty("photo") && dialogdata.photo !== "") {
      this.photo = dialogdata.photo;
    } else {
      this.fetchImage();
    }

  }

  ngOnInit(): void {
  }

  fetchImage() {
    this.API_URL = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
            this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {


          }
        },
        error => {
          console.log(error);

        });
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
