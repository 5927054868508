import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import * as XLSX from "xlsx-js-style";
import {ErrorHandler} from "../../error/error-handler";
import {ApiService} from "../../_services/api.service";
import {ErrorObject} from "../../error/error-model";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../pagebase/components/NumericEditorComponent";
import {DeleteComponent} from "../../deleteCell.component";
import {UOMRendererComponent} from "../../pagebase/components/UOMRendererComponent";


@Component({
  selector: 'app-upload-data',
  templateUrl: './ExcelUploadComponent.html',
  styleUrls: ['./common/baseUpload.scss']

})
export class ExcelUploadComponent implements OnInit {
  @Output() uploadData: EventEmitter<any> = new EventEmitter<any>();

  public fileUploaded: File;
  public validateButton = true;
  public upload_loader = false;
  public worksheet: any;
  public data_from_sheet = [];

  public title = 'UPLOAD DATA';
  public message = 'Default Message';
  public instruction = 'Default Instruction';
  public active_id = 0;
  public upload_type = '';

  public data_to_validate = [];
  public data_to_upload = [];
  public userContext = {
    access_token: null,
    data: null
  };

  invalidFormatError = false;
  uploadConfirmFlag: boolean;
  errorDataCount = 0;
  totalDataCount = 0;
  validDataCount = 0;

  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  rowData = [];
  public gridColumnApi: any;
  public gridApi: any;

  constructor(public dialog: MatDialog,
              private apiService: ApiService,
              private toastr: ToastrService,
              private elRef: ElementRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.userContext.access_token = localStorage.getItem('resfreshToken');
    this.upload_type = dialogdata.upload_type;
    this.title = dialogdata.title;
    this.message = dialogdata.message;
    this.instruction = dialogdata.instruction;
    this.active_id = dialogdata.active_id;

    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowSelection: 'multiple',
      groupSelectsChildren: false,
      enableRangeSelection: false,
      enableCharts: false,
      animateRows: false,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        autoHeight: true,
        wrapText: true
      },
    } as GridOptions;

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnInit(): void {

    this.configureGrid();
  }

  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      let data: any;
      // @ts-ignore
      data = new Uint8Array(readFile.result);
      const arr = [];
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.data_from_sheet = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(this.data_from_sheet);
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }


  handleError(error: ErrorObject) {
    this.upload_loader = false;
    this.toastr.error(error.summary);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    // this.preloader = false;
    //this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return (node.data.status !== 'OK');
    }
    return true;
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'OUTLET CODE', field: 'outlet_code', width: 100},
      {headerName: 'OUTLET ID', field: 'outlet_id', width: 100},
      {headerName: 'STATUS', field: 'status', width: 100},
    ];
  }

  onValidateClick() {
    if (this.upload_type === 'survey_outlet_mapping') {
      return this.validate_survey_outlet_mapping();
    }
  }

  onUploadConfirm() {
    if (this.upload_type === 'survey_outlet_mapping') {
      return this.upload_survey_outlet_mapping();
    }
  }

  validate_survey_outlet_mapping() {
    let inValidData = false;
    this.data_to_upload = [];
    if (this.data_from_sheet.length > 0) {
      this.data_from_sheet.forEach(line => {
        if (line.outlet_code !== undefined && line.outlet_code.length > 0) {
          // if (line.action === undefined || line.action.toUpperCase() === 'ADD') {
          //   line.action = 'ADD';
          // } else if (line.action.toUpperCase() === 'REMOVE') {
          //   line.action = 'REMOVE';
          // }
          this.data_to_validate.push(line);
        } else {
          inValidData = true;
        }
      });
    }

    if (inValidData || this.data_to_validate.length === 0) {
      this.upload_loader = false;
      // this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else {
      this.userContext.data = this.data_to_validate;
      this.validateButton = false;
      this.upload_loader = true;

      this.totalDataCount = this.data_to_validate.length;

      this.apiService.post('/api/crm_profiling_questionnaires/validate_survey_outlet_mapping', this.userContext)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.validateButton = false;
              this.upload_loader = false;

              this.data_to_upload = res.results.data.filter(item => item.status === 'OK');
              this.rowData = res.results.data.filter(item => item.status !== 'OK');

              this.errorDataCount = this.rowData.length;
              this.validDataCount = this.data_to_upload.length;

              this.uploadConfirmFlag = true;
              this.validateButton = false;

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });
    }

  }

  upload_survey_outlet_mapping() {
    this.upload_loader = true;

    this.data_to_upload.forEach(line => {
      line.survey_id = parseInt(String(this.active_id), 10);
    });
    this.userContext['outlet_mapping'] = this.data_to_upload;
    console.log(this.data_to_upload);
    this.apiService.post('/api/crm_profiling_questionnaires/upload_survey_outlet_mapping', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {

            this.upload_loader = false;
            let message = "Success: ";
            if (res.results.insert_row_count > 0) {
              message += res.results.insert_row_count.toString() + " Inserted ";
            }
            if (res.results.remove_row_count > 0) {
              message += res.results.remove_row_count.toString() + "Deleted";
            }
            this.toastr.success(message);
            this.uploadData.emit("success");
            this.closeDialog();

          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            this.closeDialog();
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            this.closeDialog();
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

}
