import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ZeroSalesReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Zero Sales Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.multiFilter.value = this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getZerosales';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance', width: 80},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'DISTRICT', field: 'zone', width: 80},
      {headerName: 'TERRITORY', field: 'territory', width: 80},
      {headerName: 'BEAT', field: 'beat_name', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'user_name', width: 80},
      {headerName: 'ID', field: 'outlet_id', width: 80},
      {headerName: 'CODE', field: 'code', width: 80},
      {headerName: 'OUTLET', field: 'name', width: 80},
      {headerName: 'OUTLET CLASS', field: 'class_type', width: 80},
      {headerName: 'OUTLET TYPE', field: 'outlet_type', width: 80},
      {headerName: 'PROGRAM TYPE', field: 'program_type', width: 80},
      {headerName: 'LAST VISIT', field: 'last_visited', width: 80},
      {
        headerName: 'VISIT STATUS', field: 'visit_count', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.visit_count && params.data.visit_count > 0) {
              return 'VISITED';
            } else {
              return 'NOT VISITED';
            }
          }
        }
      },
      {
        headerName: 'REASON', field: 'reason', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.order_count && params.data.order_count > 0) {
              return '';
            } else {
              return params.data.reason;
            }
          }
        }
      },
      {headerName: 'VISITED', field: 'visit_count', width: 80}

    ];

  }

}
