<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div  class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                  <div class="row">
                    <div *ngIf="!invEnable && (dmsStatus === 'confirm' || dmsStatus === 'sale')
                          && !sharedService.dmsParams.edit_mode"
                         class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="gotoInv()"
                      >VIEW INVOICES
                      </button>
                    </div>
                    <div *ngIf="!invEnable && (dmsStatus === 'confirm' || dmsStatus === 'sale') &&
                                !sharedService.dmsParams.edit_mode"
                         class="button-row" >
                      <button
                        class="btn grey-button"
                        (click)="gotoRef()"
                      >
                        VIEW RETURN
                      </button>
                    </div>
                    <div *ngIf="invEnable && !sharedService.dmsParams.edit_mode && !sharedService.dmsParams.new_mode"
                         class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="gotoOrder()"
                      >
                        VIEW ORDER
                      </button>
                    </div>
                    <div *ngIf="sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode"
                         class="button-row">
                      <button
                        style="margin-left:0px !important;"
                        class="btn grey-button"
                        (click)="discard()"
                      >
                        DISCARD
                      </button>
                    </div>
                    <div *ngIf="primary && dmsStatus == 'draft' && !sharedService.dmsParams.edit_mode
                         && sharedService.dmsParams.dmsType === 'S'"
                         class="button-row">
                      <button
                        style="margin-left:0px; !important;"
                        class="btn grey-button"
                        (click)="edit()"
                      >
                        EDIT
                      </button>
                    </div>
                    <div *ngIf="!sharedService.dmsParams.edit_mode && !sharedService.dmsParams.new_mode && dStatus == 'new'
                    && dmsStatus === 'draft'"
                         class="button-row">
                      <button
                        class="btn grey-button"
                        (click)="cancel()"
                      >
                        CANCEL
                      </button>
                    </div>
                    <div *ngIf="sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="save('')"
                      >
                        SAVE
                      </button>
                    </div>
                    <div *ngIf="primary && dmsStatus == 'draft' &&
                     (!invEnable || (sharedService.dmsParams.cust_supp && featurePrimaryConfirm) ||
                       (!sharedService.dmsParams.cust_supp && featureConfirm) ) &&
                     !sharedService.dmsParams.edit_mode
                     && (orderdetails.type != 'out_refund' && orderdetails.type != 'out_damage') && sharedService.dmsParams.dmsType === 'S'"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="confirm()"
                      >
                        CONFIRM
                      </button>
                    </div>
                    <div *ngIf="enableAutoComplete && primary && !invEnable && (dmsStatus === 'confirm' || dmsStatus === 'sale')
                               && invStatus === 'to invoice' && !sharedService.dmsParams.edit_mode"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="autoComplete()"
                      >
                        MARK COMPLETE
                      </button>
                    </div>
                    <div *ngIf="primary && !invEnable && (dmsStatus === 'confirm' || dmsStatus === 'sale') && invStatus !== 'invoiced' &&
                                !sharedService.dmsParams.edit_mode"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="edit()"
                      >
                        CREATE INVOICE
                      </button>
                    </div>
                    <div *ngIf="featureDispatch && featureConfirm && !sharedService.dmsParams.edit_mode && sharedService.dmsParams.dmsType == 'S'
                     && !this.sharedService.dmsParams.cust_supp
                     && invEnable && (!featureConfirm || dmsStatus == 'confirm' || dmsStatus == 'sale')
                     && dStatus == 'new' && orderdetails.type == 'out_invoice'"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="markDelivery('mark_dispatch')"
                      >
                        MARK DISPATCH
                      </button>
                    </div>
                    <div *ngIf="featureDispatch && !featureConfirm && !sharedService.dmsParams.edit_mode && sharedService.dmsParams.dmsType == 'S'
                     && !this.sharedService.dmsParams.cust_supp
                     && invEnable && (!featureConfirm || dmsStatus == 'confirm' || dmsStatus == 'sale')
                     && dStatus == 'new' && orderdetails.type == 'out_invoice'"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="markDelivery('mark_dispatch')"
                      >
                        {{cnfLabel}}
                      </button>
                    </div>
                    <div *ngIf="featureDelivery && !sharedService.dmsParams.edit_mode && sharedService.dmsParams.dmsType == 'S'
                     && !this.sharedService.dmsParams.cust_supp
                     && invEnable && (!featureConfirm || dmsStatus == 'confirm' || dmsStatus == 'sale')
                     && ((dStatus == 'new') || (dStatus == 'dispatched' )) && orderdetails.type == 'out_invoice'"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="markDelivery('delivery')"
                      >
                        MARK DELIVERED
                      </button>
                    </div>
                    <div *ngIf="!returnDisable && !sharedService.dmsParams.edit_mode
                     && invEnable && dStatus == 'delivered' && orderdetails.type == 'out_invoice'"
                         class="button-row">
                      <button
                        class="btn green-button"
                        (click)="makeReturn()"
                      >
                        RETURN
                      </button>
                    </div>
                    <div *ngIf="!sharedService.dmsParams.edit_mode && sharedService.dmsParams.dmsType == 'S'
                     && invEnable && dmsStatus == 'draft'
                     && (orderdetails.type == 'out_refund' || orderdetails.type == 'out_damage')"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="markConfirmRefund()"
                      >
                        CONFIRM RETURN
                      </button>
                    </div>
                    <div *ngIf="primary && this.sharedService.dmsParams.cust_supp && featurePrimaryDispatch && featurePrimaryConfirm
                     && invEnable && (dmsStatus == 'confirm' || dmsStatus == 'sale' || !featurePrimaryConfirm) &&
                     orderdetails.type == 'out_invoice'
                     && dStatus == 'new'"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="dispatchWizard()"
                      >
                        Mark Dispatched
                      </button>
                    </div>
                    <div *ngIf="primary && this.sharedService.dmsParams.cust_supp && featurePrimaryDispatch && !featurePrimaryConfirm
                     && invEnable && (dmsStatus == 'confirm' || dmsStatus == 'sale' || !featurePrimaryConfirm) &&
                     orderdetails.type == 'out_invoice'
                     && dStatus == 'new'"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="dispatchWizard()"
                      >
                        {{cnfLabel}}
                      </button>
                    </div>
                    <div *ngIf="featureReceive && featurePrimaryDispatch && !primary && invEnable && (dmsStatus == 'confirm' || dmsStatus == 'sale')
                     && dStatus == 'dispatched'"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="markReceive()"
                      >
                        Mark Received
                      </button>
                    </div>
                    <div *ngIf="primary && this.sharedService.dmsParams.cust_supp
                     && invEnable && featureReceive && !featurePrimaryDispatch && ((dmsStatus == 'confirm' || dmsStatus == 'sale') || !featurePrimaryConfirm) &&
                     orderdetails.type == 'out_invoice'
                     && dStatus == 'new'"
                         class="button-row" >
                      <button
                        class="btn green-button"
                        (click)="dispatchWizard()"
                      >
                        {{cnfLabel1}}
                      </button>
                    </div>
                    <div *ngIf="!sharedService.dmsParams.edit_mode && !sharedService.dmsParams.new_mode" style="position: absolute;right: 30px;">
                      <div class="button-row" style="display: flex;align-items: center">
                        <div [ngClass]="{'draft': dmsStatus === 'draft' || dmsStatus === 'no',
                                          'cancel': dmsStatus === 'cancel',
                                          'confirm': dmsStatus === 'confirm',
                                          'sale': dmsStatus === 'sale',
                                          'to_invoice': dmsStatus === 'to_invoice' || dmsStatus === 'to invoice',
                                          'invoiced': dmsStatus === 'invoiced'}"></div>
                        <span style="margin-right: 10px; font-size: 12px;">{{displayStatus}}</span>
                       <span (click)="fetchPreviousData()"><i
                         [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                         class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; font-size: 12px;">{{rowIndex+1}}/{{orderData.length}}</span>
                        <span (click)="fetchNextData()"><i
                          [ngClass]="{'disabledspan': rowIndex === orderData.length-1,
                         'enabledspan': rowIndex < orderData.length-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">
              <div class="col-md-9 pt-3 pad0">
              <div class="col-md-12 pt-3 pad0">
                <div class="col-md-3 pl-0 pr-30">
                  <label class="lbl">CUSTOMER</label>
                  <div style="margin-top: 5px;">
                    <div [hidden]="sharedService.dmsParams.dmsType == 'P' || !sharedService.dmsParams.new_mode">
                      <input
                        class="forminput"
                        style="width: 94%;"
                        [disabled]="true"
                        type="text"
                        name="custname"
                        [(ngModel)]="customerName"
                        #custname="ngModel"
                        required
                      >
                      <div *ngIf="custname.errors && (custname.dirty || custname.touched)">
                        <span *ngIf="custname.errors.required" class="text-danger">Customer name is required</span>
                      </div>
                      <img style="width: 4%;margin-left: 2%;"
                           (click)="custShow()"
                           [hidden]="sharedService.dmsParams.dmsType == 'P' || !sharedService.dmsParams.new_mode"
                           src="assets/clipart3513671.svg"/>
                    </div>
                    <p class="lbl3"
                       *ngIf="sharedService.dmsParams.dmsType == 'P' || !sharedService.dmsParams.new_mode">{{customerName}}</p>
                  </div>
<!--                  <div>-->
<!--                    <p style="display: inline-block" *ngIf="customerTax" class="lbl2">Tax Id : {{customerTax}} &nbsp;&nbsp;&nbsp;</p>-->
<!--                    <p style="display: inline-block" *ngIf="pricelist" class="lbl2">Pricelist : {{pricelist}}</p>-->
<!--                  </div>-->
                  <div style="display: inline">
                    <p style="display: inline" *ngIf="customerCode" class="lbl2">Code : {{customerCode}}</p>
                    <p style="display: inline" *ngIf="customerTax" class="lbl2 ml-3">Tax Id : {{customerTax}}</p>
                  </div>
                  <div>
                    <p *ngIf="pricelist" class="lbl2">Pricelist : {{pricelist}}</p>
                  </div>
                </div>
                <div class="col-md-3 pl-0 pr-30">
                  <label class="lbl">SUPPLIER</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <select
                      *ngIf="suppEnable"
                      name="suppname"
                      [(ngModel)]="selectedSupp"
                      (ngModelChange)="suppChange($event)"
                      #suppname="ngModel"
                      style="width: 90%;"
                    >
                      <option
                        *ngFor="let item of supplierList"
                        [ngValue]="item"
                      >
                        {{ item.itemName }}
                        <!--  <option value="Activation" selected>Activation</option>
                          <option value="BTL Activity">BTL Activity</option>
                          <option value="Scheme">Scheme</option>-->
                    </select>
                    <div *ngIf="!suppEnable">
                      <p class="lbl3" *ngIf="supplierName">{{supplierName}}</p>
                    </div>
                    <div>
                      <p *ngIf="supplierTax" class="lbl2">Tax Id : {{supplierTax}}</p>
                    </div>
                    <div>
                      <p *ngIf="supplierCode" class="lbl2">Code : {{supplierCode}}</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="(userList && userList.length > 0 && this.sharedService.dmsParams.new_mode) ||
                          (!this.sharedService.dmsParams.new_mode && this.userName)" class="col-md-3 pl-0 pr-30">
                  <label class="lbl">SALESMAN</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <ng-select *ngIf="userList && userList.length > 0 && this.sharedService.dmsParams.new_mode"
                               [items]="userList"
                               bindLabel="itemName"
                               multiple="true"
                               maxSelectedItems="1"
                               [hidden]="!custDisable"
                               [(ngModel)]="selectedUser"
                               (ngModelChange)="userChange($event)"
                    >
                    </ng-select>
<!--                    <select-->
<!--                      *ngIf="userList && userList.length > 0 && this.sharedService.dmsParams.new_mode"-->
<!--                      name="sname"-->
<!--                      [(ngModel)]="selectedUser.itemName"-->
<!--                      (ngModelChange)="userChange($event)"-->
<!--                      #sname="ngModel"-->
<!--                    >-->
<!--                      <option-->
<!--                        *ngFor="let item of userList"-->
<!--                        [ngValue]="item"-->
<!--                      >-->
<!--                        {{ item.itemName }}-->
<!--                    </select>-->
                    <div *ngIf="!this.sharedService.dmsParams.new_mode && this.userName">
                      <p class="lbl3" *ngIf="userName">{{userName}}</p>
                      <p *ngIf="userCode" class="lbl2">Code : {{userCode}}</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="beatName" class="col-md-3 pl-0 pr-30">
                  <label class="lbl">BEAT</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <p class="lbl3">{{beatName}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pt-2 pad0" style="margin-top: 12px;">
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">BILL TO</label>
                  <div  class="formselect">
                    <select
                      *ngIf="addrssList.length > 1 && this.sharedService.dmsParams.new_mode"
                      name="custaddrss"
                      [(ngModel)]="addrssSel2"
                      (ngModelChange)="billaddrssChange($event)"
                      #custaddrss="ngModel"
                      class="address-sel"
                    >
                      <option
                        *ngFor="let item of addrssList"
                        [ngValue]="item"
                      >
                        {{ item.itemname }}

                    </select>
                    <div *ngIf="customerAddress && (addrssList.length==1 || !sharedService.dmsParams.new_mode)">
                      <p class="lbl3" *ngIf="invAddrName">{{invAddrName}}</p>
                      <p class="lbl3" *ngIf="customerAddress">{{customerAddress}}</p>
                      <p *ngIf="invAddrCode" class="lbl2">Code : {{invAddrCode}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">SHIP TO</label>
                  <div  class="formselect">
                    <select
                      *ngIf="suppaddrssList.length > 1 && this.sharedService.dmsParams.new_mode"
                      name="suppaddrss"
                      [(ngModel)]="addrssSel"
                      (ngModelChange)="shipaddrssChange($event)"
                      #suppaddrss="ngModel"
                      class="address-sel"
                    >
                      <option
                        *ngFor="let item of suppaddrssList"
                        [ngValue]="item"
                      >
                        {{ item.itemname }}

                    </select>
                    <div *ngIf="supplierAddress && (suppaddrssList.length==1 || !sharedService.dmsParams.new_mode) ">
                      <p class="lbl3" *ngIf="shipAddrName">{{shipAddrName}}</p>
                      <p class="lbl3" *ngIf="supplierAddress">{{supplierAddress}}</p>
                      <p *ngIf="shipAddrCode" class="lbl2">Code : {{shipAddrCode}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30" *ngIf="showPlant">
                  <label class="lbl">PLANT</label>
                  <div class="formselect">
                    <p class="lbl3" >{{plant}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30" *ngIf="showIncoTerms">
                  <label class="lbl">INCO TERMS</label>
                  <div class="formselect">
                    <p class="lbl3" >{{inco_term}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">INSTRUCTIONS</label>
                  <div class="formselect">
                    <div *ngIf="sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode">
                    <textarea style="background-color: #F5F5FF" class="form-control"
                              rows="4" id="comment" [(ngModel)]="comments"></textarea>
                    </div>
                    <p class="lbl3" *ngIf="!sharedService.dmsParams.new_mode && !sharedService.dmsParams.edit_mode">{{comments}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label class="lbl">PURCHASE ORDER</label>
                  <div class="formselect">
                    <div *ngIf="sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode">
                      <p style="padding-top: 8px;">
                        <!--                      <mat-radio-group aria-label="Select an option">-->
                        <!--                        <mat-radio-button value="1">Manual</mat-radio-button>-->
                        <!--                        <mat-radio-button value="2">Select</mat-radio-button>-->
                        <!--                      </mat-radio-group>-->
                      </p>
                      <p>
                        <input
                          class="forminput"
                          type="text"
                          name="ponumber_text"
                          [(ngModel)]="po_number"
                          #ponumber_text="ngModel"
                          style="width: 80%;"
                        >
                      </p>
                    </div>
                    <p class="lbl3" *ngIf="!sharedService.dmsParams.new_mode && !sharedService.dmsParams.edit_mode">{{po_number}}</p>
                  </div>
                </div>
                <div *ngIf="invEnable && sharedService.dmsParams.new_mode" class="col-md-2 pl-0 pr-30">
                  <label class="lbl">REFERENCE</label>
                  <div class="formselect">
                    <div>
                      <input
                        class="forminput"
                        type="text"
                        name="ord"
                        [(ngModel)]="orderNo"
                        #ord="ngModel"
                        required
                      >
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-3 pt-3 pad0">
                <div class="col-md-12 pl-0 pr-0" *ngIf="sharedService.dmsParams.new_mode || (sharedService.dmsParams.edit_mode &&
                             (!invEnable || dStatus === 'delivered') &&
                             dmsStatus === 'confirm')">
                  <label class="lbl">DATE</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <input
                        class="forminput"
                        type="text"
                        name="date"
                        [disabled]="dateDisable"
                        [readonly]="true"
                        [(ngModel)]="myDate"
                        #date="ngModel"
                        [owlDateTime]="dt"
                        [owlDateTimeTrigger]="dt"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
<!--                    <p class="lbl3" [hidden]="sharedService.dmsParams.new_mode || (sharedService.dmsParams.edit_mode && !invEnable &&-->
<!--                             dmsStatus === 'confirm')">{{poDate}}</p>-->
                  </div>
                </div>
                <div  class="col-md-12 pl-0 pr-0" *ngIf="(!sharedService.dmsParams.edit_mode && !sharedService.dmsParams.new_mode) && !invEnable"
                     class="col-md-12 pl-0 pr-0 shadow-box">
                  <label class="lbl4" style="margin-left: 35%;margin-top:3%;">ORDER INFORMATION</label>
                  <div style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Order Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{orderNo}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Order Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{displayOrdDate}}</span></label>
                    <label *ngIf="confirmationOrdDate" class="lbl4" style="margin-left: 4%;margin-top: 3%;display: block;">Confirmation Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{confirmationOrdDate}}</span></label>
                  </div>
                  <div *ngIf="invoiceDate" style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Invoice Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{invoiceNo}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Invoice Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{invoiceDate}}</span></label>
                  </div>
                </div>
                <div  class="col-md-12 pl-0 pr-0" *ngIf="(!sharedService.dmsParams.edit_mode && !sharedService.dmsParams.new_mode) && invEnable"
                     class="col-md-12 pl-0 pr-20 shadow-box">
                  <label class="lbl4" style="margin-left: 20%;margin-top:3%;">ORDER/INVOICE INFORMATION</label>
                  <div style="margin-top:4%">
                    <label class="lbl4" style="margin-left: 4%;">Order Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{orderNo}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Order Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{displayOrdDate}}</span></label>
                    <label *ngIf="confirmationOrdDate" class="lbl4" style="margin-left: 4%;margin-top: 3%;display: block;">Confirmation Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{confirmationOrdDate}}</span></label>
                  </div>
                  <div style="border-bottom: #A3ACB9 1px solid;
                              margin-left: 4%;
                              margin-top: 2%;">
                  </div>
                  <div style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Invoice Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{invoiceNo}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Invoice Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{displayInvDate}}</span></label>
                  </div>
                </div>
              </div>
            </div>



            <div class="col-md-12 pt-4 pad0"
                 *ngIf="(sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode) && prodShow">
              <div class="col-md-5 pl-0 pr-30"
                   >
                <label class="lbl">PRODUCT</label>
                <div class="formselect">
                  <ng-select [hidden]="!custDisable"
                             #productSelector
                             [items]="prodList"
                             bindLabel="itemName"
                             multiple="false"
                             maxSelectedItems="1"
                             [virtualScroll]="true"
                             [(ngModel)]="selectedProd"
                             (ngModelChange)="prodChange($event)"
                  >
                  </ng-select>
<!--                  <select-->
<!--                    name="prod"-->
<!--                    [ngModel]="selectedProd"-->
<!--                    (ngModelChange)="prodChange($event)"-->
<!--                    #prod="ngModel"-->
<!--                    style="width: 100%;"-->
<!--                  >-->
<!--                    <option-->
<!--                      *ngFor="let item of prodList"-->
<!--                      [ngValue]="item"-->
<!--                    >-->
<!--                      {{ item.itemName }}-->

<!--                  </select>-->
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">QTY</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="qty"
                      onlyNumber
                      [(ngModel)]="enteredQty"
                      #qty="ngModel"
                      style="width: 100%;"
                      (keydown.enter)="saveProduct()"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">STOCK</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="stck"
                      [(ngModel)]="stock"
                      [disabled]="true"
                      style="width: 100%;"
                      #stck="ngModel"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pt-4 pl-0 pr-30">
                <button class="teal-btn2"
                  (click)="saveProduct()"
                >
                  ADD
                </button>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents" [context]="context">
                </ag-grid-angular>
                <div *ngIf="preloader">
                  <app-loadingstate></app-loadingstate>
                </div>
                <div *ngIf="emptyState">
                  <app-emptystate></app-emptystate>
                </div>
              </div>
            </div>

          <!--  <div class="col-md-6 mt-2">
              <div *ngIf="retInvoiceNo && damagePhoto && !sharedService.dmsParams.edit_mode"
                   class="col-md-2 mt-2 pl-0 pr-30">
                <img [src]="damagePhoto" (click)="clickPhoto()" style="height: 100px;width:100px;"/>
              </div>
              <div *ngIf="(retInvoiceNo || this.sharedService.dmsParams.type == 'return') &&
                      (sharedService.dmsParams.edit_mode || sharedService.dmsParams.new_mode)"
                   class="col-md-2 mt-4 pl-0 pr-30">
                <div style=" display: inline-flex;align-items: center;">
                  <input
                    type="file"
                    #file
                    style="display:none"
                    accept="image/png, image/jpeg"
                    (change)="handleFileSelect($event)"/>
                  <img style="width:15%;" *ngIf="damagePhoto"
                       (click)="removeImage()"
                       src="../../../assets/delete1.svg"/>
                  <span style="margin-right:5px;display:inline" (click)="file.click()">
                    <img  *ngIf="!damagePhoto" src="assets/upload_picture.svg" width="40px" />
                    <img *ngIf="damagePhoto" [src]="damagePhoto" style="height: 100px;width:100px;"/>
                  </span>

                </div>
              </div>
            </div>-->

            <div  class="col-md-6 mt-2" style="margin-top: 20px; !important">
              <div *ngIf="activityJson">
                <label class="lbl">ACTIVITIES</label>
                <div class="history-tl-container">
                  <ul class="tl">
                    <li *ngFor="let activity of activityJson" ng-repeat="item in retailer_history">
                      <div class="item-title">{{activity.s}}</div>
                      <div class="item-detail">{{activity.t}}</div>
                      <div class="lbl2">{{activity.m}}</div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

            <div *ngIf="orderdetails" class="col-md-6 mt-2">
              <table style="float: right;">
                <tbody>
                <tr>
                  <td ><p class="p-class">Taxable Amount</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['amount_untaxed'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Line Discount</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['line_discount'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Special Discount</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['spcl_discount'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Charges</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['charges'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Taxes</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['amount_tax'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Total</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['amount_total'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">TDS/TCS</p></td>
                  <td ><p class="p-class1">{{math.abs(orderdetails['tcs'])}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Discount</p></td>
                  <TD *ngIf="!sharedService.dmsParams.new_mode && !sharedService.dmsParams.edit_mode"><p class="p-class1" >{{orderDiscount}}</p></TD>
                  <TD *ngIf="(sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode) && prodShow">
                    <input
                      type="text" class="form-control" style="width: 35% !important;float: right" [(ngModel)]="orderDiscount" />
                  </TD>
                </tr>
                <tr>
                  <td ><p class="p-class">Total Weight</p></td>
                  <td ><p class="p-class1">{{totalWeight}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Total Measure</p></td>
                  <td ><p class="p-class1">{{totalMeasure}}</p></td>
                </tr>
                <tr style=" border-top: 2px solid #f3f3f3;">
                  <td ><p class="p-class3">GRAND TOTAL</p></td>
                  <td ><p class="p-class4">{{math.abs(orderdetails['total'])}}</p></td>
                </tr>
                </tbody>
              </table>
            </div>

<!--            <div *ngIf="orderdetails" style="background: white;height:26vh">-->
<!--              <TABLE cellpadding=0 cellspacing=0 style="float:right;margin-top: 1%;">-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Taxable Amount</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['amount_untaxed']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Line Discount</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['line_discount']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Special Discount</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['spcl_discount']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Charges</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['charges']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Taxes</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['amount_tax']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Total</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['amount_total']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">TDS/TCS</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['tcs']}}</P></TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Discount</P></TD>-->
<!--                  <TD *ngIf="!sharedService.dmsParams.new_mode && !sharedService.dmsParams.edit_mode"><P class="p-class1">{{orderDiscount}}</P></TD>-->
<!--                  <TD *ngIf="(sharedService.dmsParams.new_mode || sharedService.dmsParams.edit_mode) && prodShow">-->
<!--                    <input-->
<!--                      type="text" class="form-control" style="width: 35% !important;" [(ngModel)]="orderDiscount" />-->
<!--                  </TD>-->
<!--                </TR>-->
<!--                <TR>-->
<!--                  <TD><P class="p-class lbl3">Grand Total</P></TD>-->
<!--                  <TD><P class="p-class1 lbl3">{{orderdetails['total']}}</P></TD>-->
<!--                </TR>-->
<!--              </TABLE>-->
<!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-width pol-send" id="customerModal" [hidden]="custDisable">
  <div class="modal-dialog" style="width: 900px !important;">
    <div class="modal-content" style="height: 700px;margin-top: -422px;">
      <span class="close" (click)="close()">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div style="margin-left: 40%;" class="pol-modal-heading">
          <h1 style="font-size: 170%;
                     font-weight: 600;">Customers</h1>
          <a *ngIf="custRefresh" style="margin-left: 77%;" class="btn blue-btn" (click)="refreshCust()">Refresh</a>
          <img *ngIf="!custRefresh" style="width: 4%;margin-left: 86%;" src="assets/images/loader_icon.gif"/>
        </div>
        <div class="search" style="margin-top: -95px;">
          <span class="search-control"><img src="../../../assets/searchicon.png"/></span>
          <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                 placeholder="Search">
        </div>
        <div class="eod-table" style="margin-top: 160px;">
          <div class="dms_table">
            <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:50vh" class="ag-theme-balham"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"  (gridReady)="onGridReady1($event)"
                             [frameworkComponents]="frameworkComponents" [context]="context"
                             (rowSelected)="outChange()">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="DispatchModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>Dispatch Confirmation</label>
        </div>
        <div class="approve-modal-content">
          <div>
            <label>Tracking Referance</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="trackRef" />
          </div>
          <div>
            <label>Comments</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="comments" />
          </div>
          <div style="width: 278px;">
            <label>Transporter</label>
            <ng-select [items]="transList"
                       bindLabel="itemName"
                       multiple="true"
                       maxSelectedItems="1"
                       [(ngModel)]="selectedTrans"
                       (ngModelChange)="transChange($event)"
            >
            </ng-select>
          </div>
          <div style="width: 278px;">
            <label>Transport type</label>
            <ng-select [items]="typeList"
                       bindLabel="itemName"
                       multiple="true"
                       maxSelectedItems="1"
                       [(ngModel)]="selectedType"
                       (ngModelChange)="typeChange($event)"
            >
            </ng-select>
          </div>
          <div>
            <label>NO Of Bundle</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="bundle" />
          </div>
          <div>
            <label>Weight</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="weight" />
          </div>
          <a (click)="markDispatch()" data-dismiss="modal" class="btn blue-btn">Dispatch</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="invoiceModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <img style="width:100%;" [src]="damagePhoto">
      </div>
    </div>
  </div>
</div>

<button #imageShow id="openModalButton3" [hidden]="true" data-toggle="modal" data-target="#invoiceModal"></button>
<button #showCustomer id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#customerModal"></button>
<button #dispatchDiv id="openModalButton2" [hidden]="true" data-toggle="modal" data-target="#DispatchModal"></button>
<button #serial id="openImage1" [hidden]="true" data-toggle="modal" data-target="#serialNoModel">Open Modal</button>
<button #cust id="openImage2" [hidden]="true" data-toggle="modal" data-dismiss="#customerModal">Open Modal</button>
