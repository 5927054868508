import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-cost-log',
  templateUrl: './cost-log.component.html',
  styleUrls: ['./cost-log.component.scss']
})
export class CostLogComponent implements OnInit {

  user;
  res_data;
  user_data = {
    "date": null,
    "access_token": null,
    "url": null,
    "last_date": null,
    "offset": null,
    "territory_id": null,
    "product_id": null
  };

  public modules = AllModules;

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public orderDetails = {};
  public all_orders = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "LOADIN REPORT";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public first_hide = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public  domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              private datePipe: DatePipe) {


    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.territory_id = this.sharedService.logData.territory_id;
    this.user_data.product_id = this.sharedService.logData.product_id;

    this.costLog();

    this.gridOptions = <GridOptions>{
      rowHeight: 35,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pagination: true
    };

  }

  costLog() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];
    let total = 0;
    let datePipe = this.datePipe;


    this.res_data = this.apiService.getCostLog(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            var pushData = {
              "TERRITORY": data.territory,
              "CHANGE DATE": datePipe.transform((new Date(data.write_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
              "UPDATED BY": data.created_by,
              "PRODUCT": data.product,
              "OLD COST": data.cost,
              "NEW COST": data.new_cost,
              "REFERENCE": data.reference,
              "TYPE": data.type,
              "COMMENTS": data.comments
            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              this.columnDefs.push({
                headerName: key, field: key, sortable: true, filter: true, lockPosition: true,width:120,
                resizable: true,
              });
            }
          }
          this.loaded = true;

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  goBack() {
    this.router.navigate([this.apiService.redirect_url+"/livestockreport"]);
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function () {
      setTimeout(function () {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    var oneDay = 24 * 60 * 60 * 1000;
    var firstDate = this.myDate[0];
    var secondDate = this.myDate[1];
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.costLog();
    }
  };
};
