 <!-- Production -->
 <a data-toggle="modal" data-target="#polModal">Production</a>
 <div class="pol-modal pol-send modal fade" id="polModal" role="dialog">
     <div class="modal-dialog">
       <!-- Modal content-->
       <div class="modal-content">
         <span class="close" data-dismiss="modal">
           <img src="../../assets/close.png">
         </span>
         <div class="modal-body">
           <div class="pol-modal-heading">
             <h1>SALE ITEM DETAILS</h1>
             <label>Kindly check the sale item details</label>
           </div>
           <div class="pol-modal-content">
             <ul class="pol-content-heading list-unstyled">
               <li>
                 <label>SALE ID</label>
               </li>
               <li>
                 <label>SALE TYPE</label>
               </li>
               <li>
                   <label>QUANTITY</label>
               </li>
               <li>
                 <label>VALUE</label>
               </li>
             </ul>
             <div class="pol-content-text">
               <ul class="list-unstyled">
                   <li>
                       <h2>Product 1</h2>
                   </li>
                   <li>
                       <label>Sale</label>
                   </li>
                   <li>
                         <span>30</span>
                   </li>
                   <li>
                     <span> 300.00</span>
                   </li>
               </ul>
             </div>
             <div class="pol-content-text">
                 <ul class="list-unstyled">
                     <li>
                      <h2>Product 2</h2>
                     </li>
                     <li>
                         <label>Return</label>
                     </li>
                     <li>
                           <span>50</span>
                     </li>
                     <li>
                       <span> 500.00</span>
                     </li>
                 </ul>
               </div>
               <div class="pol-content-text">
                   <ul class="list-unstyled">
                       <li>
                        <h2>Product 3</h2>
                       </li>
                       <li>
                           <label>Replace</label>
                       </li>
                       <li>
                             <span>60</span>
                       </li>
                       <li>
                         <span> 600.00</span>
                       </li>
                   </ul>
                 </div>
                 <div class="pol-content-text">
                     <ul class="list-unstyled">
                         <li>
                            <h2>Product 4</h2>
                         </li>
                         <li>
                             <label>Sample</label>
                         </li>
                         <li>
                               <span>70</span>
                         </li>
                         <li>
                           <span> 700.00</span>
                         </li>
                     </ul>
                   </div>
                   <div class="pol-content-text">
                       <ul class="list-unstyled">
                           <li>
                               <h2>#2186</h2>
                           </li>
                           <li>
                               <label>Sample</label>
                           </li>
                           <li>
                                 <span>80</span>
                           </li>
                           <li>
                             <span> 800.00</span>
                           </li>
                       </ul>
                     </div>
                     <div class="pol-btn">
                         <a href="" class="btn blue-btn">Confirm</a>
                     </div>
           </div>
         </div>
       </div>
     </div>
  </div>

<a data-toggle="modal" data-target="#invoiceModal">Production 1</a>
<div class="pol-modal pol-send invoice modal fade" id="invoiceModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      <div class="invoice-details">
        <div class="invoice-date">
          <label>2018/04/25 02:07:59</label>
          <span>Invoice 70d4d7d0</span>
        </div>
        <div class="invoice-address">
          <h1>Dollie Bullock</h1>
          <label> 704 6th Ave, New York, NY 10010, USA
            +1-202-555-0175
          </label>
          <span>abbott@withinpixels.com</span>
        </div>
      </div>
      <div class="modal-body">
        <!--<div class="invoice-details">-->
          <!--<div class="">-->

          <!--</div>-->
          <!--<div class="">-->

          <!--</div>-->
        </div>
        <div class="pol-modal-heading">
          <h1>SALE DETAILS</h1>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>SALE ID</label>
            </li>
            <li>
              <label>SALE TYPE</label>
            </li>
            <li>
              <label>QUANTITY</label>
            </li>
            <li>
              <label>VALUE</label>
            </li>
          </ul>
          <div class="pol-content-text">
            <ul class="list-unstyled">
              <li>
                <h2>Product 1</h2>
              </li>
              <li>
                <label>Sale</label>
              </li>
              <li>
                <span>30</span>
              </li>
              <li>
                <span> 300.00</span>
              </li>
            </ul>
          </div>
          <div class="pol-content-text">
            <ul class="list-unstyled">
              <li>
                <h2>Product 2</h2>
              </li>
              <li>
                <label>Return</label>
              </li>
              <li>
                <span>50</span>
              </li>
              <li>
                <span> 500.00</span>
              </li>
            </ul>
          </div>
          <div class="pol-content-text">
            <ul class="list-unstyled">
              <li>
                <h2>Product 3</h2>
              </li>
              <li>
                <label>Replace</label>
              </li>
              <li>
                <span>60</span>
              </li>
              <li>
                <span> 600.00</span>
              </li>
            </ul>
          </div>
          <div class="pol-content-text">
            <ul class="list-unstyled">
              <li>
                <h2>Product 4</h2>
              </li>
              <li>
                <label>Sample</label>
              </li>
              <li>
                <span>70</span>
              </li>
              <li>
                <span> 700.00</span>
              </li>
            </ul>
          </div>
          <div class="pol-content-text">
            <ul class="list-unstyled">
              <li>
                <h2>#2186</h2>
              </li>
              <li>
                <label>Sample</label>
              </li>
              <li>
                <span>80</span>
              </li>
              <li>
                <span> 800.00</span>
              </li>
            </ul>
          </div>
          <div class="invoice-total">
            <ul class="list-unstyled">
              <li>
                <label>Total</label>
              </li>
              <li>
                <label>Rs. 4500</label>
              </li>
            </ul>
          </div>
          <div class="invoice-notes">
            <p>Please pay within 15 days.Thank you for your business</p>
          </div>
        </div>
    </div>
  </div>
</div>

<!--<a data-toggle="modal" data-target="#BillingModal">Production 2</a>-->
<!--<div class="pol-modal pol-send invoice modal fade" id="BillingModal" role="dialog">-->
  <!--<div class="modal-dialog">-->
    <!--&lt;!&ndash; Modal content&ndash;&gt;-->
    <!--<div class="modal-content">-->
          <!--<span class="close" data-dismiss="modal">-->
            <!--<img src="../../assets/close.png">-->
          <!--</span>-->
      <!--<div class="billing-details">-->
        <!--<div class="billing-name">-->
          <!--<ul class="list-unstyled">-->
            <!--<li>-->
              <!--<h1>Retailer Name</h1>-->

            <!--</li>-->
            <!--<li>-->
              <!--<h1>H1B1</h1>-->
              <!--<label>ROUTE NAME : H1B1RRN1</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<h1>STATUS : PAID</h1>-->
            <!--</li>-->
          <!--</ul>-->
        <!--</div>-->
        <!--<div class="billing-order">-->
          <!--<ul class="list-unstyled">-->
            <!--<li>-->
              <!--<label>ORDER NO : 1234567890</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>GST/TAX ID : 24238791092</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>ORDER DATE : 2019-08-08 15:08:05</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>DELIVERY DATE : 2019-08-08 15:08:05</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>INVOICE NO : 2019H1 B197</label>-->
            <!--</li>-->
          <!--</ul>-->
        <!--</div>-->

      <!--</div>-->
      <!--<div class="modal-body">-->

        <!--<div class="pol-modal-heading">-->
          <!--<h1>ORDER DETAILS</h1>-->
        <!--</div>-->
        <!--<div class="pol-modal-content">-->
          <!--<ul class="pol-content-heading list-unstyled">-->
            <!--<li>-->
              <!--<label>ORDER ID</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>SALE TYPE</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>QUANTITY</label>-->
            <!--</li>-->
            <!--<li>-->
              <!--<label>VALUE</label>-->
            <!--</li>-->
          <!--</ul>-->
          <!--<div class="pol-content-text">-->
            <!--<ul class="list-unstyled">-->
              <!--<li>-->
                <!--<h2>#24636</h2>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>Sale</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span>30</span>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span> 300.00</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div class="pol-content-text">-->
            <!--<ul class="list-unstyled">-->
              <!--<li>-->
                <!--<h2>#24637</h2>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>Return</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span>50</span>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span> 500.00</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div class="pol-content-text">-->
            <!--<ul class="list-unstyled">-->
              <!--<li>-->
                <!--<h2>#456688</h2>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>Replace</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span>60</span>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span> 600.00</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div class="pol-content-text">-->
            <!--<ul class="list-unstyled">-->
              <!--<li>-->
                <!--<h2>#218634</h2>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>Sample</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span>70</span>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span> 700.00</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div class="pol-content-text">-->
            <!--<ul class="list-unstyled">-->
              <!--<li>-->
                <!--<h2>#218623</h2>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>Sample</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span>80</span>-->
              <!--</li>-->
              <!--<li>-->
                <!--<span> 800.00</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
          <!--<div class="payment">-->
            <!--<div class="pol-modal-heading">-->
              <!--<h1>PAYMENT</h1>-->
            <!--</div>-->
            <!--<ul class="pol-content-heading list-unstyled">-->
              <!--<li>-->
                <!--<label>TRANSCTION ID</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>DATE</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>PAYMENT TYPE</label>-->
              <!--</li>-->
              <!--<li>-->
                <!--<label>AMOUNT</label>-->
              <!--</li>-->
              <!--<li class="popup-print">-->
                <!--<app-printicon></app-printicon>-->
              <!--</li>-->
            <!--</ul>-->
            <!--<div class="pol-content-text">-->
              <!--<ul class="list-unstyled">-->
                <!--<li>-->
                  <!--<h2>#24636</h2>-->
                <!--</li>-->
                <!--<li>-->
                  <!--<label>05/11/2018</label>-->
                <!--</li>-->
                <!--<li>-->
                  <!--<span>Cash</span>-->
                <!--</li>-->
                <!--<li>-->
                  <!--<span> 300.00</span>-->
                <!--</li>-->
              <!--</ul>-->
            <!--</div>-->
          <!--</div>-->

        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->


  <a data-toggle="modal" data-target="#invoiceModal">Production 1</a>
  <div class="pol-modal pol-send invoice modal fade" id="invoiceModal" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
          <div class="invoice-details">
              <div class="invoice-date">
                <label>2018/04/25 02:07:59</label>
                <span>Invoice 70d4d7d0</span>
              </div>
              <div class="invoice-address">
                <h1>Dollie Bullock</h1>
                <label> 704 6th Ave, New York, NY 10010, USA
                    +1-202-555-0175
                    </label>
                <span>abbott@withinpixels.com</span>
              </div>
        </div>
          <div class="modal-body">

            <div class="pol-modal-heading">
              <h1>ORDER DETAILS</h1>
            </div>
            <div class="pol-modal-content">
              <ul class="pol-content-heading list-unstyled">
                <li>
                  <label>ORDER ID</label>
                </li>
                <li>
                  <label>SALE TYPE</label>
                </li>
                <li>
                    <label>QUANTITY</label>
                </li>
                <li>
                  <label>VALUE</label>
                </li>
              </ul>
              <div class="pol-content-text">
                <ul class="list-unstyled">
                    <li>
                        <h2>#24636</h2>
                    </li>
                    <li>
                        <label>Sale</label>
                    </li>
                    <li>
                          <span>30</span>
                    </li>
                    <li>
                      <span> 300.00</span>
                    </li>
                </ul>
              </div>
              <div class="pol-content-text">
                  <ul class="list-unstyled">
                      <li>
                       <h2>#24637</h2>
                      </li>
                      <li>
                          <label>Return</label>
                      </li>
                      <li>
                            <span>50</span>
                      </li>
                      <li>
                        <span> 500.00</span>
                      </li>
                  </ul>
                </div>
                <div class="pol-content-text">
                    <ul class="list-unstyled">
                        <li>
                         <h2>#456688</h2>
                        </li>
                        <li>
                            <label>Replace</label>
                        </li>
                        <li>
                              <span>60</span>
                        </li>
                        <li>
                          <span> 600.00</span>
                        </li>
                    </ul>
                  </div>
                  <div class="pol-content-text">
                      <ul class="list-unstyled">
                          <li>
                             <h2>#218634</h2>
                          </li>
                          <li>
                              <label>Sample</label>
                          </li>
                          <li>
                                <span>70</span>
                          </li>
                          <li>
                            <span> 700.00</span>
                          </li>
                      </ul>
                    </div>
                    <div class="pol-content-text">
                        <ul class="list-unstyled">
                            <li>
                                <h2>#218623</h2>
                            </li>
                            <li>
                                <label>Sample</label>
                            </li>
                            <li>
                                  <span>80</span>
                            </li>
                            <li>
                              <span> 800.00</span>
                            </li>
                        </ul>
                      </div>
                      <div class="invoice-total">
                          <ul class="list-unstyled">
                              <li>
                                  <label>Total</label>
                              </li>
                              <li>
                                  <label>Rs. 4500</label>
                              </li>
                            </ul>
                      </div>
                      <div class="invoice-notes">
                        <p>Please pay within 15 days.Thank you for your business</p>

                      </div>

            </div>
          </div>
        </div>
      </div>
   </div>



   <a data-toggle="modal" data-target="#BillingModal">Production 2</a>
  <div class="pol-modal pol-send invoice modal fade" id="BillingModal" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
          <div class="billing-details">
              <div class="billing-name">
                  <ul class="list-unstyled">
                    <li>
                      <h1>Retailer Name</h1>

                    </li>
                    <li>
                        <h1>H1B1</h1>
                        <label>ROUTE NAME : H1B1RRN1</label>
                      </li>
                      <li>
                          <h1>STATUS : PAID</h1>
                        </li>
                  </ul>
              </div>
              <div class="billing-order">
                  <ul class="list-unstyled">
                    <li>
                      <label>ORDER NO : 1234567890</label>
                    </li>
                    <li>
                        <label>GST/TAX ID : 24238791092</label>
                      </li>
                    <li>
                        <label>ORDER DATE : 2019-08-08 15:08:05</label>
                      </li>
                      <li>
                          <label>DELIVERY DATE : 2019-08-08 15:08:05</label>
                        </li>
                        <li>
                            <label>INVOICE NO : 2019H1 B197</label>
                          </li>
                      </ul>
                </div>

            </div>
          <div class="modal-body">

            <div class="pol-modal-heading">
              <h1>ORDER DETAILS</h1>
            </div>
            <div class="pol-modal-content">
              <ul class="pol-content-heading list-unstyled">
                <li>
                  <label>ORDER ID</label>
                </li>
                <li>
                  <label>SALE TYPE</label>
                </li>
                <li>
                    <label>QUANTITY</label>
                </li>
                <li>
                  <label>VALUE</label>
                </li>
              </ul>
              <div class="pol-content-text">
                <ul class="list-unstyled">
                    <li>
                        <h2>#24636</h2>
                    </li>
                    <li>
                        <label>Sale</label>
                    </li>
                    <li>
                          <span>30</span>
                    </li>
                    <li>
                      <span> 300.00</span>
                    </li>
                </ul>
              </div>
              <div class="pol-content-text">
                  <ul class="list-unstyled">
                      <li>
                       <h2>#24637</h2>
                      </li>
                      <li>
                          <label>Return</label>
                      </li>
                      <li>
                            <span>50</span>
                      </li>
                      <li>
                        <span> 500.00</span>
                      </li>
                  </ul>
                </div>
                <div class="pol-content-text">
                    <ul class="list-unstyled">
                        <li>
                         <h2>#456688</h2>
                        </li>
                        <li>
                            <label>Replace</label>
                        </li>
                        <li>
                              <span>60</span>
                        </li>
                        <li>
                          <span> 600.00</span>
                        </li>
                    </ul>
                  </div>
                  <div class="pol-content-text">
                      <ul class="list-unstyled">
                          <li>
                             <h2>#218634</h2>
                          </li>
                          <li>
                              <label>Sample</label>
                          </li>
                          <li>
                                <span>70</span>
                          </li>
                          <li>
                            <span> 700.00</span>
                          </li>
                      </ul>
                    </div>
                    <div class="pol-content-text">
                        <ul class="list-unstyled">
                            <li>
                                <h2>#218623</h2>
                            </li>
                            <li>
                                <label>Sample</label>
                            </li>
                            <li>
                                  <span>80</span>
                            </li>
                            <li>
                              <span> 800.00</span>
                            </li>
                        </ul>
                      </div>
                      <div class="payment">
                      <div class="pol-modal-heading">
                          <h1>PAYMENT</h1>
                      </div>
                      <ul class="pol-content-heading list-unstyled">
                          <li>
                            <label>TRANSCTION ID</label>
                          </li>
                          <li>
                            <label>DATE</label>
                          </li>
                          <li>
                              <label>PAYMENT TYPE</label>
                          </li>
                          <li>
                            <label>AMOUNT</label>
                          </li>
                          <li class="popup-print">
                            <app-printicon></app-printicon>
                          </li>
                        </ul>
                        <div class="pol-content-text">
                          <ul class="list-unstyled">
                              <li>
                                  <h2>#24636</h2>
                              </li>
                              <li>
                                  <label>05/11/2018</label>
                              </li>
                              <li>
                                    <span>Cash</span>
                              </li>
                              <li>
                                <span> 300.00</span>
                              </li>
                          </ul>
                        </div>
                      </div>

            </div>
          </div>
        </div>
      </div>
   </div>

