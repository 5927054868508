import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';


@Component({
  selector: 'app-shelf-analysis-report',
  templateUrl: '../_common/reportbase.html',

})


// tslint:disable-next-line:component-class-suffix
export class SalesTrendAnalysis extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];

  ngOnInit() {
    this.setTitle();
    //this.configureGrid();
    this.API_URL = '/api/pwa_reports/salesTrendAnalysis';

    this.configureGrid();
  }

  setTitle() {
    this.title = 'Trend Analysis';
  }


  configureGrid() {

    this.sharedService.setHeader('Trend Analysis');
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.columnDefs = [
      {headerName: 'Region', field: 'region', enableRowGroup: true, width: 25},
      {headerName: 'State', field: 'state', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Cluster', field: 'cluster', enableRowGroup: true, width: 30},
      {headerName: 'Team', field: 'team', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Salesman', field: 'user_name', enableRowGroup: true, width: 50},
      {headerName: 'Week', field: 'week', enableRowGroup: true, width: 50},
      {headerName: 'Month', field: 'month', enableRowGroup: true, width: 50},
      {headerName: 'Channel', field: 'channel', enableRowGroup: true, hide: true, width: 50},
      {headerName: 'Type', field: 'o_type', enableRowGroup: true, width: 40},
      {headerName: 'Program', field: 'program', hide: true, enableRowGroup: true, width: 80},
      {headerName: 'Family', field: 'family', enableRowGroup: true, hide: true, width: 50},
      {headerName: 'Brand', field: 'brand', enableRowGroup: true, hide: true, width: 40},
      {headerName: 'PCode', field: 'p_code', hide: true, width: 50},
      {headerName: 'Product', field: 'product', enableRowGroup: true, width: 60},
      {headerName: 'Category', field: 'category', enableRowGroup: true, width: 30},
      {headerName: 'Qty', field: 'sale_qty', width: 40, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'value', field: 'sales', width: 60, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
    ];

  }

}
