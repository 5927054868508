import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { ApiService} from '../../_services/api.service';
import { SharedService } from '../../_services/shared_service';
import { Router, ActivatedRoute } from '@angular/router';
import { ColorCellRenderer } from '../../colorCell.component';
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from '../../components/loadingstate/loadingstate.component';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as XLSX from "xlsx-js-style";
import {PrintInvoiceComponent} from '../../printInvoice.component';
import {PrintClickRenderer} from '../../printClickRenderer.component';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-dmssales',
  templateUrl: './dms_order.component.html',
  styleUrls: ['./dms_order.component.scss']
})
export class Dms_orderComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('interPrint2') public interPrint2: ElementRef;
  @ViewChild('fileUp') fileUp: ElementRef;

  user;
  res_data;
  user_data = {
    date: null,
    last_date: null,
    type: null,
    order_type: '',
    dc_id: null,
    ref: '',
    order_status: null,
    inv_status: null,
    access_token: null,
    url: null,
    offset: null,
    upload_data: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public orderDetails: any;
  public orderStatus = null;
  public invStatus = null
  private a = ['Zero ','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ',
    'Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
  private b = ['', '', 'Twenty ','Thirty ','Forty ','Fifty ', 'Sixty ','Seventy ','Eighty ','Ninety '];
  public math = Math;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public uploadLoader = false;
  public uploadEnable = false;
  public uploadPodata;
  public poData;
  public printFormat = 'A4';
  public allOrders= [];
  public showAct= false;
  public API_URL = '';

  constructor(private apiService: ApiService,
              public sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public toastr: ToastrService,
              private datePipe: DatePipe,
              public  domSanitizer: DomSanitizer,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    let role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_show_actions' === existed.name;
    });

    if (role_features.length > 0) {
      this.showAct = true;
    }
    let date = localStorage.getItem('my_dates');
    let ord_stat = localStorage.getItem('order_status');
    if (date && (date !== 'null')) {
      date = JSON.parse(date);
      this.myDate[0] = date[0];
      this.myDate[1] = date[1];
      this.user_data.date = this.myDate[0];
      this.user_data.last_date = this.myDate[1];
    } else {
      let today = new Date();
      this.user_data.last_date = today.toISOString().split('T')[0];
      today = new Date();
      today.setDate(today.getDate() - 30);
      this.user_data.date = today.toISOString().split('T')[0];
      this.myDate[0] = this.user_data.date;
      this.myDate[1] = this.user_data.last_date;
      localStorage.setItem('my_dates',  JSON.stringify([this.user_data.date, this.user_data.last_date]));
    }
    // if (this.user_data.date === null || this.user_data.date === 'null') {
    //   this.user_data.date = this.myDate;
    //   localStorage.setItem('my_date', this.myDate);
    // } else {
    //   this.myDate = this.user_data.date;
    // }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    if (ord_stat && ord_stat !== 'null') {
      this.orderStatus = JSON.parse(ord_stat)[0];
      this.user_data.order_status = JSON.parse(ord_stat)[1];
    } else {
      this.user_data.order_status = 'draft';
      this.orderStatus = 'Draft';
      localStorage.setItem('order_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
    }
    this.invStatus = 'Draft';
    this.user_data.inv_status = 'draft';
    this.route.queryParamMap
      .subscribe(params => {

        this.user_data.ref = '';
        this.user_data.type = params.get('type');
        this.user_data.order_type = params.get('order_type');
        this.sharedService.dmsParams.dmsType = this.user_data.type;
        this.sharedService.dmsParams.type = this.user_data.order_type;
        localStorage.setItem('dms_order_type', this.user_data.order_type);
        localStorage.setItem('dms_type', this.user_data.type);
        let date = localStorage.getItem('my_dates');
        let ord_stat = localStorage.getItem('order_status');
        if (date && (date !== 'null')) {
          date = JSON.parse(date);
          this.myDate[0] = date[0];
          this.myDate[1] = date[1];
          this.user_data.date = this.myDate[0];
          this.user_data.last_date = this.myDate[1];
        } else {
          let today = new Date();
          this.user_data.last_date = today.toISOString().split('T')[0];
          today = new Date();
          today.setDate(today.getDate() - 30);
          this.user_data.date = today.toISOString().split('T')[0];
          this.myDate[0] = this.user_data.date;
          this.myDate[1] = this.user_data.last_date;
          localStorage.setItem('my_dates',  JSON.stringify([this.user_data.date, this.user_data.last_date]));
        }
        if (ord_stat && ord_stat !== 'null') {
          this.orderStatus = JSON.parse(ord_stat)[0];
          this.user_data.order_status = JSON.parse(ord_stat)[1];
        } else {
          this.user_data.order_status = 'draft';
          this.orderStatus = 'Draft';
          localStorage.setItem('order_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
        }
        this.invStatus = 'Draft';
        this.user_data.inv_status = 'draft';
        if (this.sharedService.dmsParams.goToOrd.active) {
          this.user_data.ref = this.sharedService.dmsParams.goToOrd.ref;
          this.user_data.order_status = 'All';
          this.user_data.inv_status = 'All';
          this.orderStatus = 'All';
          localStorage.setItem('order_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
          this.sharedService.dmsParams.goToOrd.active = false;
          this.sharedService.dmsParams.goToOrd.ref = '';
        } else {
          this.user_data.ref = null;
        }
        this.apiDmsOrderList();
      });


    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      rowSelection: 'multiple',
      suppressAggFuncInHeader: true,
      pivotMode: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: 9,
      groupMultiAutoColumn: true,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

  }

  statusOrdChange(event) {
    if (event === 'Draft') {
      this.user_data.order_status = 'draft';
    } else if (event === 'Confirm') {
      this.user_data.order_status = 'sale';
    } else if (event === 'Cancel') {
      this.user_data.order_status = 'cancel';
    } else {
      this.user_data.order_status = 'All';
    }
    localStorage.setItem('order_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
  }

  statusInvChange(event) {
    if (event === 'Draft') {
      this.user_data.inv_status = 'draft';
    } else if (event === 'Partial') {
      this.user_data.inv_status = 'to invoice';
    } else if (event === 'Invoiced') {
      this.user_data.inv_status = 'invoiced';
    } else {
      this.user_data.inv_status = 'All';
    }
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) { return 'overflow'; }
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) { return; } var str = '';

    str += (n[1] != '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ': '';
    str += (n[2] != '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ': '';
    str += (n[3] != '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ': '';
    str += (n[4] != '0') ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ': '' ;
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    }else {
      if (this.a[n[5][1]] != 'Zero ') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      }else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      this.uploadPodata = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.poData = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadexcel() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.uploadLoader = true;
    const distinct_order = [];
    const map = new Map();
    let inthis = this;
    let success_count = 0;
    let failed_count = 0;
    let failed_invoices = [];
    for (const item of this.poData) {
      if (item['Customer Code'] !== null) {
        if (map.get(item['Customer Code']) !== item['Order Reference']) {
          map.set(item['Customer Code'], item['Order Reference']);
          distinct_order.push(item);
        }
      }
    }
    let total_len = distinct_order.length;
    let count = 0;
    let customers = JSON.parse(localStorage.getItem('customers_list'));
    if (customers.length > 0) {
      do {
        let upload_order = distinct_order[count];
        let order = {};
        let invoice = {};
        let find_cust = customers.filter(function (existed) {
          return upload_order['Customer Code'] === existed.code;
        });
        if (find_cust.length === 0) {
          failed_invoices.push(upload_order['Invoice Number']);
          failed_count += 1;
          count++;
        } else {
          if (typeof upload_order['Invoice Date'] === 'string') {
            let inv_date = upload_order['Invoice Date'].split('-')
            upload_order['Invoice Date'] = inv_date[2] + '-' + inv_date[1] + '-' + inv_date[0];
            this.sharedService.fetch_periodID(upload_order['Invoice Date']);
            let period_id = localStorage.getItem('period_id');
            order = {
              order_id: null,
              partner_id: find_cust[0].id,
              distributor_id: find_cust[0].supplier_id,
              pricelist_id: find_cust[0].pricelist_id,
              user_id: upload_order['Salesman'],
              beat_plan_id: null,
              date_order: upload_order['Invoice Date'],
              order_type: 'sale',
              name: upload_order['Order Reference'],
              amount_total: 0,
              amount_tax: 0,
              amount_untaxed: 0,
              line_data: [],
              type: 'P',
              state: 'confirm',
              zone_id: find_cust[0].zone_id,
              state_id: find_cust[0].state_id,
              area_id: find_cust[0].area_id,
              territory_id: null,
              supp_territory_id: null,
              payment_mode: find_cust[0].payment_mode,
              invoice_no: '',
              has_einvoice: find_cust[0].has_einvoice,
              company_id: find_cust[0].company_id,
              tax_source: find_cust[0].tax_source,
              discount: 0,
              period_id
            };
            invoice = {
              order_id: null,
              order_type: 'sale',
              type: 'out_invoice',
              sale_ref: upload_order['Order Reference'],
              invoice_no: upload_order['Invoice Number'],
              date_invoice: upload_order['Invoice Date'],
              pricelist_id: find_cust[0].pricelist_id,
              user_id: upload_order['Salesman'],
              partner_id: find_cust[0].id,
              partner_shipping_id: find_cust[0].id,
              supplier_id: find_cust[0].supplier_id,
              territory_id: null,
              supplier_territory_id: null,
              state_id: find_cust[0].state_id,
              zone_id: find_cust[0].zone_id,
              area_id: find_cust[0].area_id,
              company_id: find_cust[0].company_id,
              dist_code: find_cust[0].dist_code,
              supplier_code: find_cust[0].dist_code,
              invoice_type: 'P',
              comment: 'PWA Invoice Upload',
              beat_id: null,
              team_id: null,
              amount_total: 0,
              amount_tax: 0,
              amount_untaxed: 0,
              tax_source: find_cust[0].tax_source,
              inv_items: [],
              state: 'confirm',
              discount: 0,
              period_id
            };
            const all_lines = [];
            let lookup = {};
            for (let i = 0; i < this.poData.length; i++) {
              if ((upload_order['Customer Code'] === this.poData[i]['Customer Code']) &&
                (upload_order['Order Reference'] === this.poData[i]['Order Reference'])) {
                this.poData[i]['Product Code'] = this.poData[i]['Product Code'].toString();
                let product = this.poData[i]['Product Code'];

                if (!(product in lookup)) {
                  lookup[product] = 1;
                  all_lines.push(this.poData[i]);
                }
              }
            }
            order['lines'] = JSON.stringify(all_lines);
            invoice['lines'] = JSON.stringify(all_lines);
            order['invoice'] = invoice;
            this.user_data.upload_data = [order];
            let resp = await this.apiService.uploadDms(this.user_data);
            console.log(resp);
            if (resp.hasOwnProperty('results') && resp['results'].failed_invoices.length === 0) {
              success_count += 1;
            } else {
              failed_invoices.push(resp['results'].failed_invoices);
              failed_count += 1;
            }
          } else {
            this.toastr.warning('Date format should be text');
            failed_invoices.push(upload_order['Invoice Number']);
            failed_count += 1;
          }
          count++;
        }
      } while (count < total_len);
      this.uploadCaption = ['Uploading completed, Successes: ' + success_count + ',Failures: ' + failed_count + '/n '
      + JSON.stringify(failed_invoices), 0];
      this.uploading = false;
      this.uploadLoader = false;
      this.poData = [];
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.apiDmsOrderList();
    } else {
      this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
    }
    // this.res_data = this.apiService.uploadDms(this.user_data);
    // this.res_data.subscribe(res => {
    //     console.log(res);
    //     this.uploading = false;
    //     this.uploadLoader = false;
    //     if (res.hasOwnProperty('results')) {
    //       if (res.results.statusCode === 200) {
    //         this.uploadCaption  = [res.results.msg, 0];
    //       } else {
    //         this.uploadCaption = [res.results.msg, 1];
    //       }
    //     }
    //     this.poData = [];
    //
    //     this.fileUp.nativeElement.value = null;
    //
    //     this.apiDmsOrderList();
    //
    //   },
    //   error => {
    //     console.log(error);
    //     this.uploading = false;
    //     this.uploadLoader = false;
    //     if (error.error.error.msg === undefined) {
    //       this.uploadCaption = ['Upload Failed', 1];
    //     } else {
    //       this.uploadCaption = [error.error.error.msg, 1];
    //     }
    //
    //     this.poData = [];
    //     this.fileUp.nativeElement.value = null;
    //   });
  }

  apiDmsOrderList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    let sl_no = 0;
    const datePipe = this.datePipe;
    if (!this.user_data.order_type) { this.user_data.order_type = ''; }
    if (!this.user_data.ref) { this.user_data.ref = ''; }
    if (this.user_data.type === 'S') {
      this.API_URL = '/api/dms_connects/get_sale_orders';
    } else {
      this.API_URL = '/api/dms_connects/get_primary_orders';
    }

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (res.results.data.length > 0) {
            res.results.data.forEach(function(data) {
              rowdata.push({
                ORDER: data.name,
                'ORDER DATE': datePipe.transform(data.date, 'dd/MM/yyyy'),
                CUSTOMER: data.outlet,
                SUPPLIER: data.van,
                BEAT: data.beat,
                'GRAND TOTAL': data.value,
                STATUS: data.state,
                INVOICED: data.invoice_status,
                PRINT: '',
                id: data.id
              });
            });
            this.data = rowdata[0];
            this.rowData = rowdata;
            this.storeOrderData(this.rowData);

            for (const key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key === 'STATUS' || key === 'INVOICED') {
                  this.columnDefs.push({
                    headerName: key,headerClass: 'myagheader', field: key, sortable: true, unSortIcon: true,
                    cellStyle(params) {
                      if (params.value != null && params.value !== undefined) {
                        if (params.value === 'draft' || params.value === 'no') {
                          return {color: 'blue'};
                        } else if (params.value === 'cancel') {
                          return {color: 'LightCoral'};
                        } else if (params.value === 'confirm') {
                          return {color: 'MediumSeaGreen'};
                        } else if (params.value === 'sale') {
                          return {color: '#696969'};
                        } else if (params.value === 'to_invoice' || params.value === 'to invoice') {
                          return {color: '#F4A460'};
                        } else if (params.value === 'invoiced') {
                          return {color: 'MediumSeaGreen'};
                        }
                      }
                    },
                    cellRenderer: function (params) {
                      if (params.value === 'no') { params.value = 'draft'; }
                      if (params.value === 'sale') { params.value = 'confirm'; }
                      if (params.value === 'to_invoice' || params.value === 'to invoice') { params.value = 'partial'; }
                      var displayElem = '<span class="ml-1 status">'+ params.value + '</span>';

                      return displayElem;
                    }

                  });

                } else if (key === 'ORDER') {
                  this.columnDefs.push({
                    headerName: key, headerClass: 'myagheader',field: key, sortable: true, filter: true, resizable: true,
                    lockPosition: true,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    width: 250
                  });
                }else if (key === 'PRINT') {
                  this.columnDefs.push({
                    headerName: key, headerClass: 'myagheader', field: key, resizable: true, pinned: 'right',
                    cellRenderer: 'printClickRenderer',
                    headerComponent: 'printInvoicecomponent',
                  });
                } else if (key === 'dc_id' || key === 'id') {
                  this.columnDefs.push({
                    headerName: key, headerClass: 'myagheader', field: key, hide: true, filter: false, unSortIcon: true, lockPosition: true,
                  });
                } else {
                  this.columnDefs.push({
                    headerName: key, headerClass: 'myagheader', field: key, sortable: true, unSortIcon: true, lockPosition: true,
                  });
                }
              }
            }
            this.loaded = true;
            this.context = { componentParent: this };

            this.frameworkComponents = {
              colorCellRenderer: ColorCellRenderer,
              printInvoicecomponent: PrintInvoiceComponent,
              printClickRenderer: PrintClickRenderer
            };
          } else {
            this.preloader = false;
            this.empty_state = true;
          }

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  printAll(rows) {
    const orderIds = [];
    this.allOrders = [];
    rows.forEach(vanRow => {
      orderIds.push(vanRow.id);
    });

    const orderData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      order_id: orderIds
    };

    this.apiService.post('/api/dms_connects/get_sale_order', orderData)
      .subscribe(res => {
        console.log(res);
        let orderDetails = [];
        const datePipe = this.datePipe;
        const domSanitizer = this.domSanitizer;
        let inthis = this;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(data => {
            orderDetails = data;
            orderDetails['label_config'] = {
              SupplierLabel: 'Supplier',
              TaxLabel: 'GSTN',
              PrimaryCurrency: '',
              SecondaryCurrency: ''
            };
            orderDetails['date'] = datePipe.transform(orderDetails['date_order'], 'yyyy-MM-dd');
            const labels = orderDetails['labels'];
            if (labels) {
              labels.forEach(function(lab) {
                orderDetails['label_config'][lab.label] = lab.display;
              });
            }
            orderDetails['customer_skip'] = true;
            orderDetails['total'] = parseFloat(orderDetails['amount_total']) -  parseFloat(orderDetails['discount']);
            orderDetails['round_off'] = (inthis.math.round(orderDetails['total'])-orderDetails['total']).toFixed(inthis.apiService.decimalPolicy);
            orderDetails['total'] = inthis.math.round(orderDetails['total']);
            orderDetails['amount_pay'] = (parseFloat(orderDetails['amount_pay']) + parseFloat(orderDetails['round_off'])).toFixed(inthis.apiService.decimalPolicy);
            const amount = orderDetails['amount_pay'].toString().split('.');
            if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
              orderDetails['amount_in_words'] = inthis.inWords(Math.abs(parseInt(amount[0], 10)));
              orderDetails['amount_in_words'] = orderDetails['label_config'].PrimaryCurrency + ' ' +
                orderDetails['amount_in_words'] + ' And ' + orderDetails['label_config'].SecondaryCurrency + ' ' +
                inthis.inWords(Math.abs(parseInt(amount[1], 10))) +  ' Only';
            } else {
              orderDetails['amount_in_words']  = orderDetails['label_config'].PrimaryCurrency + ' ' +
                inthis.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
            }
            orderDetails['charges'] = 0;
            orderDetails['line_discount'] = 0;
            orderDetails['spcl_discount'] =  0;
            orderDetails['total_quantity'] = 0;
            orderDetails['sale_items'].forEach(function(line) {
              line.net_price = (parseFloat(line.amount_total)/parseFloat(line.qty)).toFixed(inthis.apiService.decimalPolicy);
              line.linediscount = (parseFloat(line.qty) * parseFloat(line.unit_price) * parseFloat(line.discount)) / 100;
              line.spcldiscount = (parseFloat(line.qty) * parseFloat(line.trade_disc));
              orderDetails['charges'] += parseFloat(line.fixed_cost);
              orderDetails['line_discount'] += line.linediscount;
              orderDetails['spcl_discount'] += line.spcldiscount;
              orderDetails['total_quantity'] += parseFloat(line.qty);
              line.unit_price = parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy);
              line.base_price = (parseFloat(line.real_price) * parseFloat(line.base_inv))/(parseFloat(line.factor_inv));
            });
            if (orderDetails['logo']) { orderDetails['logo'] = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' +
              orderDetails['logo']); }
            inthis.allOrders.push(orderDetails);
          });
          this.triggerFalseClick2();
        }
    });
  }

  onCellClicked(event) {

    const column = event.column.getColId();

    if (column === 'PRINT') {
      const orderData = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        order_id: [event.data.id]
      };

      this.apiService.post('/api/dms_connects/get_sale_order', orderData)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.orderDetails = res.results.data[0];
            const inthis = this;
            this.orderDetails['date'] = this.datePipe.transform(this.orderDetails['date_order'], 'yyyy-MM-dd');
            this.orderDetails['label_config'] = {
              SupplierLabel: 'Supplier',
              TaxLabel: 'GSTN',
              PrimaryCurrency: '',
              SecondaryCurrency: ''
            };
            const labels = this.orderDetails.labels;
            if (labels) {
              labels.forEach(function(lab) {
                inthis.orderDetails['label_config'][lab.label] = lab.display;
              });
            }
            this.orderDetails['total'] = parseFloat(this.orderDetails['amount_total']) -  parseFloat(this.orderDetails['discount']);
            this.orderDetails['round_off'] = (this.math.round(this.orderDetails['total'])-this.orderDetails['total']).toFixed(this.apiService.decimalPolicy);
            this.orderDetails['total'] = this.math.round(this.orderDetails['total']);
            this.orderDetails['amount_pay'] = (parseFloat(this.orderDetails['amount_pay']) + parseFloat(this.orderDetails['round_off'])).toFixed(this.apiService.decimalPolicy);
            const amount = this.orderDetails.amount_pay.toString().split('.');
            if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
              this.orderDetails.amount_in_words = this.inWords(Math.abs(parseInt(amount[0], 10)));
              this.orderDetails.amount_in_words = this.orderDetails.label_config.PrimaryCurrency + ' ' + this.orderDetails.amount_in_words +
                ' And ' + this.orderDetails.label_config.SecondaryCurrency + ' ' + this.inWords(Math.abs(parseInt(amount[1], 10))) +
                ' Only';
            } else {
              this.orderDetails.amount_in_words = this.orderDetails.label_config.PrimaryCurrency + ' ' +
                this.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
            }
            this.orderDetails.charges = 0;
            this.orderDetails.line_discount = 0;
            this.orderDetails.spcl_discount =  0;
            this.orderDetails.total_quantity = 0;
            this.orderDetails.sale_items.forEach(function(line) {
              line.net_price = (parseFloat(line.amount_total)/parseFloat(line.qty)).toFixed(inthis.apiService.decimalPolicy);
              line.linediscount = (parseFloat(line.qty) * parseFloat(line.unit_price) * parseFloat(line.discount)) / 100;
              line.spcldiscount = (parseFloat(line.qty) * parseFloat(line.trade_disc));
              inthis.orderDetails.charges += parseFloat(line.fixed_cost);
              inthis.orderDetails.line_discount += line.linediscount;
              inthis.orderDetails.spcl_discount += line.spcldiscount;
              inthis.orderDetails.total_quantity += parseFloat(line.qty);
              line.unit_price = parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy);
              line.base_price = (parseFloat(line.real_price) * parseFloat(line.base_inv))/(parseFloat(line.factor_inv));
            });
            this.orderDetails.date = this.datePipe.transform(this.orderDetails.date, 'yyyy-MM-dd');
            this.orderDetails.customer_skip = true;
            if (this.orderDetails.logo) { this.orderDetails.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' +
              this.orderDetails.logo); }
            this.allOrders = [this.orderDetails];
            this.triggerFalseClick2();
          }
      });
    } else {
      const rows = this.gridApi.getSelectedRows();
      const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
      let filteredRows = [];
      for (const key in filteredRowData) {
        /* if (!filteredRowData[key].hasOwnProperty('data')) {
           filteredRows.push(filteredRowData[key]);
         }*/
        filteredRows.push(filteredRowData[key].data);
      }
      if (filteredRows !== null && filteredRows.length > 0) {
        this.storeOrderData(filteredRows);
      }
      localStorage.setItem('orderid', rows[0].id);
      const index = JSON.parse(localStorage.getItem("orderdata")).indexOf(rows[0].id);
      localStorage.setItem('orderindex', index);
      this.sharedService.setDmsParams();
      this.sharedService.dmsParams.order_id = event.data.id;
      localStorage.setItem('dms_order_id', event.data.id);
      this.router.navigate([this.apiService.redirect_url + '/new_order']);
      this.router.navigate([this.apiService.redirect_url + '/new_order'], {
        state: {
          viewtype: 'detail',
          orderid: rows[0].id
        }
      });
    }

  }

  triggerFalseClick2() {
    this.printFormat = localStorage.getItem('print-format');
    if (this.printFormat === 'A4') {
      const el: HTMLElement = this.interPrint.nativeElement;
      el.click();
    } else {
      const el: HTMLElement = this.interPrint2.nativeElement;
      el.click();
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  newOrderCreation() {
    this.sharedService.setDmsParams();
    this.sharedService.dmsParams.new_mode = true;
    this.sharedService.dmsParams.type = 'Order';
    this.sharedService.dmsParams.dmsType = this.user_data.type;
    localStorage.setItem('dms_order_type', 'order');
    localStorage.setItem('dms_type', this.user_data.type);
    this.router.navigate([this.apiService.redirect_url + '/new_order']);
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'),
      'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('my_dates',  JSON.stringify([this.user_data.date, this.user_data.last_date]));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

  }

  storeOrderData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('orderdata', JSON.stringify(ids));
  }
}
