import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ImageComparePopupComponent} from "../../components/image_compare_popup/image_compare_popup.component";

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class AssetActivityReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  setTitle() {
    this.title = 'Asset Activity Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Asset Activity Report');


    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;


    this.loadInBatches(1000, 'id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.container = 'asset_activity';
    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getAssetActivityReport';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance', hide: true, width: 80},
      {headerName: "REGION", field: "region", enableRowGroup: true, width: 100},
      {headerName: "CLUSTER", field: "cluster", enableRowGroup: true, width: 100},
      {headerName: "ID", field: "cust_id", enableRowGroup: true, width: 100},
      {headerName: "RCODE", field: "cust_code", enableRowGroup: true, width: 100},
      {headerName: "NAME", field: "cust_name", enableRowGroup: true, width: 100},
      {headerName: "MOBILE", field: "cust_mob", enableRowGroup: true, hide: true, width: 100},
      {headerName: "STATE", field: "state", enableRowGroup: true, width: 100},
      {headerName: "ZONE", field: "zone", enableRowGroup: true, width: 100},
      {headerName: "EMP CODE", field: "emp_code", width: 100},
      {headerName: "SALESMAN", field: "user", width: 100},
      {headerName: "TEAM", field: "team", width: 100},
      {headerName: "BEAT ID", field: "beat_id", width: 100},
      {headerName: "BEAT", field: "beat_name", width: 100},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "ASSET", field: "asset", width: 100},
      {headerName: "ASSET TYPE", field: "asset_type", hide: true, width: 100},
      {headerName: "PARTNER TYPE", field: "partner_type", hide: true, width: 100},
      {headerName: "TAGGING TYPE", field: "tagging_type", hide: true, width: 100},
      {headerName: "TAGGING VALUE", field: "tagging_val", width: 100},
      {headerName: "INSTALL DATE", field: "install_date", width: 100},
      {headerName: "QTY", field: "qty", width: 80},
      {headerName: "REVIEWED", field: "reviewed", hide: true, width: 100},
      {headerName: "REVIEWED ON", field: "reviewed_on", hide: true, width: 100},
      {headerName: "REVIEWER", field: "review_name", hide: true, width: 100},
      {headerName: "CODE", field: "review_code", hide: true, width: 100},
      {headerName: "ACTIVITY DATE", field: "activity_date", width: 100},
      {headerName: "CONDITION", field: "condition", width: 100},
      {headerName: "COMMENTS", field: "comments", width: 100},
      {headerName: "NEXT ACTIVITY", field: "next_activity", width: 100},
      {
        headerName: "PHOTO", field: "store_fname", menuTabs: [], width: 80,
        valueGetter: this.generateImageUrl('store_fname'),
        cellRenderer(params) {
          if (params.data) {
            if (params.data.store_fname === null || params.data.store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      }
    ];
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column !== 'store_fname') {

    } else {
      console.log(rows[0].store_fname);
      //rows[0].store_fname = '964/9640c96d933643095139096372fda9ed189a8ff360c5bca0b4bc9f7d0ba661c1'

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0].store_fname !== null && rows[0].store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].store_fname,
              container: "asset_activity"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].store_fname,
              container: "asset_activity"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }


  }
}
