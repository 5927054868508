import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {SharedService} from "./_services/shared_service";

@Component({
  selector: 'serial-click',
  template: `<div class="eod-top" layout="row">` +
    `<span class="element-po"><img (click)="enterSerial()" class="element-img" src="../assets/edit.svg" alt=""></span>` +
    `<span *ngIf="sharedService.new" class="element-po" style="margin-left: 25%;"><img (click)="removeRow(params)" class="element-img" src="../assets/delete1.svg" alt=""></span>` +
    `</div>`
})
export class SerialNoRenderer implements ICellRendererAngularComp {
  public params: any;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              public sharedService: SharedService){}

  agInit(params: any): void {
    this.params = params;
  }

  enterSerial() {

    this.params.context.componentParent.enterSerialNo(this.params.data);
  }

  removeRow() {

    this.params.context.componentParent.removeProduct(this.params.rowIndex);
  }

  refresh(): boolean {
    return false;
  }
}
