import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-verify',
  template: `<span *ngIf="!enable && verify_label === 'Verified'" class="ml-1 status2" style="font-weight: bold;background-color:#E1F0E7; border:#E1F0E7; color: #379862">{{verify_label}}</span>
       <span *ngIf="!enable && verify_label === 'Unverified'" class="ml-1 status2" style="font-weight: bold;background-color:#FBE3E7; border:#FBE3E7; color: #E24260">{{verify_label}}</span>
    <button *ngIf="enable && verify" class="btn confirm-blue-btn" style="padding: 1px 20px; text-transform: none" (click)="action(display_val)">{{display}}</button>
  <button *ngIf="enable && !verify" (click)="action(display_val)" class="btn confirm-green-btn" style="padding: 1px 20px; text-transform: none">{{display}}</button>`,
})
export class OutletVerifyComponent implements ICellRendererAngularComp {
  public params: any;
  public display_val = '';
  public verify = true;
  public enable = true;
  public verify_label;
  public display = 'VERIFY';

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    this.params = params;
    this.verify = true;
    this.enable = true;
    this.verify_label = '';
    if (this.params.colDef.headerName === 'GSTIN VERIFY' && (this.params.data.verify.gst.verified === 'false' || !this.params.data.verify.gst.verified)) {
      this.display_val = 'Verify GSTIN';
      this.display = "Verify";
    } else if (this.params.colDef.headerName === 'CHAIN VERIFY' && (this.params.data.verify.chain.verified === 'false' || !this.params.data.verify.chain.verified)) {
      this.display_val = 'Verify Chain';
      this.display = "Verify";
    }  else if (this.params.colDef.headerName === 'LOCATION VERIFY' && (!this.params.data.verify.hasOwnProperty('location') || this.params.data.verify.location.verified === 'false' || !this.params.data.verify.location.verified)) {
      this.display_val = 'Verify Location';
      this.display = "Verify";
    } else if (this.params.colDef.headerName === 'FINANCE VERIFY' &&
             (this.params.data.verify.finance.verified === 'false' || !this.params.data.verify.finance.verified)) {
      this.display_val = 'Verify Finance';
      this.display = "Verify";
    } else if (this.params.colDef.headerName === 'APPROVE' && (this.params.data.verify.final_verification.verified === 'false' || !this.params.data.verify.final_verification.verified)) {
      this.display_val = 'Approve';
      this.display = "Approve";
      this.verify = false;
    } else {
      this.verify_label = 'Verified';
      this.enable = false;
    }
  }

  action(label) {
    if (label === 'Verify Chain') {
      this.params.context.componentParent.openChainVerify(this.params.data);
    } else if (label === 'Verify Location') {
      this.params.context.componentParent.openLocationVerify(this.params.data);
    } else if (label === 'Verify GSTIN') {
      this.params.context.componentParent.openGSTINVerify(this.params.data);
    } else if (label === 'Approve') {
      this.params.context.componentParent.openVerify(this.params.data);
    } else if (label === 'Verify Finance') {
      this.params.context.componentParent.openFinanceVerify(this.params.data);
    }
  }

  refresh(): boolean {
    return false;
  }
}
