import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../_services/shared_service";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-user_survey-component',
  templateUrl: './user_survey.component.html',
  styleUrls: ['./user_survey.component.scss']
})
export class UserSurveyComponent implements OnInit {

  public myDate = [];
  public usersurveydata : any= [];
  access_token;
  user_id : null;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date:null,
      end_date:null,
      type: 'survey',
      retailer: false,
      territory_ids:[]
    },

  };
  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public collapse_disable;
  public data = [];
  API_URL='';
  team_territories_list = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef)
  {
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.access_token = this.user_data.access_token;
    this.user_data.url = localStorage.getItem('private_url');
    //console.log(localStorage.getItem('private_url'));
    this.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.team_territories_list= JSON.parse(localStorage.getItem('team_territories'))

    if(this.team_territories_list!=null) {
      for (var i = 0; i < this.team_territories_list.length; i++) {
        this.user_data.data.territory_ids.push(this.team_territories_list[i].id);
      }
    }

    this.fetchRetailSurvey();
  }

  ngOnInit(): void {
    this.sharedService.setHeader('User Survey');
  }

  fetchRetailSurvey(){
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.API_URL = '/api/crm_profiling_questionnaires/get_user_survey_list';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status == 200) && (res.results.data.length > 0)) {

            this.usersurveydata = res.results.data;
            // this.rowData = this.retailsurveydata;
            // this.loaded = true;

          }
          else {
           /* this.preloader = false;
            this.empty_state = true;*/
          }
        },
        error => {
          console.log(error);
          //this.preloader = false;
        });

  }

  reportClick()
  {
    this.router.navigate([this.apiService.redirect_url + 'reports/user_survey_report']);
  }

  listClick()
  {
    this.router.navigate([this.apiService.redirect_url + '/user_survey/user_survey_list']);
  }

}
