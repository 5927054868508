import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-stock-availability-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class MSLComplianceReportComponent extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter:any={
     name:'Availability',
     key:'availability',
     value:1,
     values:[],
     show:true
   };*/

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('MSL compliance Report');

    this.showableFilters.territory = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    /* this.customFilter.values = [
       {id: '', itemName: 'All'},
       {id: 1, itemName: 'Available'},
       {id: 0, itemName: 'Not Available'}
     ];*/

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getMSLCompliance';

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', width: 80, enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 80, enableRowGroup: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80, enableRowGroup: true},
      {headerName: 'TEAM', field: 'team', width: 80, enableRowGroup: true},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'RID', field: 'retailer_id', enableRowGroup: true, width: 50, hide: true},
      {headerName: 'RCODE', field: 'o_code', enableRowGroup: true, width: 50},
      {headerName: 'RETAILER', field: 'o_name', enableRowGroup: true, width: 120},
      {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, width: 80},
      {headerName: 'PROGRAM', field: 'program', enableRowGroup: true, width: 80},
      {headerName: 'CLASS', field: 'class', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'DATE', field: 'date', enableRowGroup: true, width: 80},
      {headerName: "MSL SCORE", field: "msl", width: 100, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction},
      {headerName: 'Compliance Count', field: 'compliance', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
      {
        headerName: 'Compliance %', field: 'compliance_per', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.compliance === 1) {
              return 100;
            } else {
              return 0;
            }
          }
        }
      },

    ];
  }


  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }


}
