import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-bulk_add_retailer-component',
  templateUrl: './bulk_add_retailer.component.html',
  styleUrls: ['./bulk_add_retailer.component.scss']
})
export class BulkAddRetailerComponent implements OnInit {
  @Output() selectedOutlets: EventEmitter<any> = new EventEmitter<any>();
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  outlet_types = [{id: 0, itemName: 'All Outlets'}];
  program_types = [{id: 0, itemName: 'All Programs'}];
  salesmen = [{id: 0, itemname: 'All Salesmen'}];
  states = [{id: 0, itemName: 'All States'}];
  clusters = [{id: 0, itemname: 'All Clusters'}];
  API_URL = '';
  outletFilters = {
    class_type: 0,
    program_type: 0,
    outlet_type: 0,
    salesman_id: 0,
    state_id: 0,
    cluster_id: 0,
    territory_ids: [],
    showAll: true
  };
  team_territories_list = [];
  outletData = [];
  selectedOutlet = [];
  selectedCount = 0;
  private gridApi;
  private gridColumnApi;
  vendor = false;

  constructor(private apiService: ApiService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.vendor = this.dialogdata.vendor;
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      suppressContextMenu:true,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          }
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    if (localStorage.getItem('all_out_types') != null) {
      this.outlet_types = this.outlet_types.concat(JSON.parse(localStorage.getItem('all_out_types')));
    }
    if (localStorage.getItem('all_program_types') != null) {
      this.program_types = this.program_types.concat(JSON.parse(localStorage.getItem('all_program_types')));
    }
    if (localStorage.getItem('all_salesmans') != null && localStorage.getItem('all_salesmans') != 'null' && JSON.parse(localStorage.getItem('all_salesmans')) != null) {
      this.salesmen = this.salesmen.concat(JSON.parse(localStorage.getItem('all_salesmans')));
    }
    if (localStorage.getItem('all_states') != null) {
      this.states = this.states.concat(JSON.parse(localStorage.getItem('all_states')));
    }
    if (localStorage.getItem('all_clusters') != null) {
      this.clusters = this.clusters.concat(JSON.parse(localStorage.getItem('all_clusters')));
    }

    this.team_territories_list = JSON.parse(localStorage.getItem('team_territories'));


    if (this.team_territories_list != null) {
      for (var i = 0; i < this.team_territories_list.length; i++) {
        this.outletFilters.territory_ids.push(this.team_territories_list[i].id);
      }
    }


    this.columnDefs = [
      {
        headerName: "Code", field: "code", width: 150,
        cellStyle(params) {
          if (params.data) {
            if (params.data.code === 'Add an Item') {
              return {color: '#17A2B8'};
            } else {
              return {};
            }
          }
        }, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      {headerName: "Name", field: "name", width: 180},
      {
        headerName: "Beat", field: "beat", width: 150,
        valueGetter(params) {
          if (params.data) {
            if (params.data.beat !== null && params.data.beat.length > 0) {
              return params.data.beat[0].bp;
            }
          }
        }
      },
      {headerName: "Contact", field: "contact_name", width: 180},
      {headerName: "Mobile", field: "mobile", width: 180},
      {headerName: "Class", field: "class_type", width: 100},
      {headerName: "Program Type", field: "program_type", width: 100},
      {headerName: "Salesman", field: "user", width: 150},
      {headerName: "District", field: "zone", width: 150},
      {headerName: "Cluster", field: "cluster", width: 150},
      {headerName: "State", field: "state", width: 150},
      {headerName: "Region", field: "region", width: 150},
      {headerName: "Address", field: "address", width: 150}
    ];
    if (this.vendor) {
      const class_type = this.columnDefs.find(x => x.field === 'class_type');
      class_type['hide'] = true;

      const program = this.columnDefs.find(x => x.field === 'program_type');
      program['hide'] = true;
    }
    this.loaded = true;

  }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onRowSelected(event) {
    const rows = this.gridApi.getSelectedRows();
    if (rows) {
      this.selectedCount = rows.length;
      this.selectedOutlet = rows;
    } else {
      this.selectedCount = 0;
    }
  }
  onCellClicked(event) {
    const column = event.column.getColId();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  fetchOutlets() {
    let paramObject: any = {};
    this.rowData = [];
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.outletFilters;
    this.API_URL = '/api/audit_masters/get_customers';
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        this.loaded = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.outletData = res.results.data;
          this.rowData = this.outletData;
          this.gridApi.setRowData(this.rowData);
          //this.loaded = true;
        } else {
          this.preloader = false;
          //this.empty_state = true;
        }
      });
  }

  addOutlets() {
    this.selectedOutlet = this.gridApi.getSelectedRows();
    this.selectedOutlets.emit(this.selectedOutlet);
    this.dialogRef.close();
  }
}
