import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {DateTimeAdapter} from "ng-pick-datetime";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment_popup.component.html',
  styleUrls: ['./payment_popup.component.scss']

})

export class PaymentPopupComponent implements OnInit {

  @Output() markPayment: EventEmitter<any> = new EventEmitter<any>();
  public paymentModes = [{id: 1, name: 'cash'}, {id: 2, name: 'cheque'}, {id: 3, name: 'online'}];
  public datas;
  public comments = '';
  public paymentData: any = {};
  public enableWriteOff = false;

  constructor(private apiService: ApiService,
              public sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public domSanitizer: DomSanitizer,
              public toastr: ToastrService,
              public dialog: MatDialog,
              private dateTimeAdapter: DateTimeAdapter<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.datas = dialogdata.datas;
    this.paymentData = dialogdata.paymentData;
    console.log(this.paymentData);
  }

  ngOnInit(): void {
  }

  changePayDate(dt) {
    this.paymentData.payDate = this.datePipe.transform(this.paymentData.payDate, 'yyyy-MM-dd');
  }

  changeChqDate(dt) {
    this.paymentData.chequeDate = this.datePipe.transform(this.paymentData.chequeDate, 'yyyy-MM-dd');
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  updateWriteoff() {
    if (this.datas.credit_used) {
      if (parseFloat(this.datas.cr_used_credits) <= parseFloat(this.paymentData.out_amount)) {
        this.paymentData.writeoff_amt = (this.paymentData.out_amount - (parseFloat(this.datas.cr_used_credits) +
          parseFloat(this.paymentData.amount))).toFixed(2);
        this.enableWriteOff = this.paymentData.writeoff_amt > 0;
      }
    } else {
      this.paymentData.writeoff_amt = (this.paymentData.out_amount - this.paymentData.amount).toFixed(2);
      this.enableWriteOff = this.paymentData.writeoff_amt > 0;
    }
  }

  updateCredAmt() {
    if (this.datas.credit_used) {
      if (parseFloat(this.datas.cr_used_credits) > parseFloat(this.paymentData.out_amount)) {
        this.paymentData.amount = 0;
      } else {
        this.paymentData.amount = (this.paymentData.out_amount - this.datas.cr_used_credits).toFixed(2);
      }
    } else {
      this.paymentData.amount = this.paymentData.out_amount;
    }
    this.enableWriteOff = false;
  }

  markPaid() {
    this.markPayment.emit(this.paymentData);
    this.dialog.closeAll();
  }
}
