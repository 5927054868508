 import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {ActivatedRoute, Router} from '@angular/router';
import {ColorCellRenderer} from "../../colorCell.component";
import {DatePipe} from '@angular/common';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {DomSanitizer} from "@angular/platform-browser";
import {PrintInvoiceComponent} from "../../printInvoice.component";
import {PrintClickRenderer} from "../../printClickRenderer.component";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PaymentPopupComponent} from "../../components/payment_popup/payment_popup.component";
import {OutstandingShowComponent} from "../../outstandingShow.component";
import {DeliveryPlanPopupComponent} from "../../components/delivery_plan_popup/delivery_plan_popup.component";
import {LocalforageService} from "../../_services/localforage.service";

@Component({
  selector: 'app-dms_invoices',
  templateUrl: './dms_invoices.component.html',
  styleUrls: ['./dms_invoices.component.scss']
})
export class Dms_invoicesComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('interPrint2') public interPrint2: ElementRef;
  @ViewChild('payment') public payment: ElementRef;

  user;
  res_data;
  public enableDelivery = false;
  public enableDispatch = false;
  user_data = {
    'start_date': null,
    'end_date': null,
    type: null,
    order_type: null,
    status: null,
    order_status: null,
    pay_status: 'new',
    ref: null,
    'dc_id': null,
    'access_token': null,
    'url': null,
    'offset': null
  };
  public API_URL = '';
  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  public payDate;
  public frameworkComponents;
  public context;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public show_buttons = false;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public orderDetails: any;
  public allOrders = [];
  private prevIndex;
  private a = ['Zero ', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];
  public math = Math;
  public printFormat = 'A4';
  private tabIndex: number;
  public status = null;
  public action = '';
  public orderStatus = null;
  public payStatus = 'Draft';
  public isModelOpened = true;
  public paymentMode = 'cash';
  public comments = '';
  public amount = 0;
  public out_amount = 0;
  public writeoff = false;
  public credit_note = false;
  public writeoff_amt = 0;
  public paymentModes = [{id: 1, name: 'cash'}, {id: 2, name: 'cheque'}, {id: 3, name: 'online (upi/ref)'}];
  public datas;
  public retail_credits;
  public order_paids;
  public chequeNo;
  public chequeDate;
  public branch;
  public ref;
  public role_features = [];
  public markPay = false;
  public showAct = false;
  public labels = [];
  public cnfLabel = 'Mark Dispatch';
  public cnfLabel1 = 'Dispatch & Receive';
  public customers;
  public featureReceive = false;
  public featureDelivery = false;
  public featureDispatch = false;
  public featureConfirm = false;
  public featurePrimaryDispatch = false;
  public featurePrimaryConfirm = false;
  public advancePay = false;
  public feature_round_off = false;

  constructor(private apiService: ApiService,
              public sharedService: SharedService,
              private _localConfigCache: LocalforageService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public domSanitizer: DomSanitizer,
              public toastr: ToastrService,
              public dialog: MatDialog,
              private dateTimeAdapter: DateTimeAdapter<any>) {
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_confirm' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureConfirm = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_confirm' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featurePrimaryConfirm = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_dispatch' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureDispatch = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_dispatch' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featurePrimaryDispatch = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_delivery' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureDelivery = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_receive' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureReceive = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_mark_payment' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.markPay = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_advance_payment' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.advancePay = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_show_actions' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.showAct = true;
    }
    this.labels = JSON.parse(localStorage.getItem('custom_labels'));
    let update = this.labels.filter(function (existed) {
      return existed.label === 'confirmLabel';
    });

    if (update.length > 0) {
      this.cnfLabel = update[0].display;
    }

    update = this.labels.filter(function (existed) {
      return existed.label === 'confirmLabelPrimary';
    });

    if (update.length > 0) {
      this.cnfLabel1 = update[0].display;
    }
    this.customers = JSON.parse(localStorage.getItem('customers_list'));
    this.payDate = new Date().toISOString().split('T')[0];
    this.chequeDate = new Date().toISOString().split('T')[0];
    let date = localStorage.getItem('my_dates');
    let d_stat_p = localStorage.getItem('d_status_p');
    let ord_stat = localStorage.getItem('invoice_status');
    let d_stat = localStorage.getItem('d_status');
    if (date && (date !== 'null')) {
      date = JSON.parse(date);
      this.myDate[0] = date[0];
      this.myDate[1] = date[1];
      this.user_data.start_date = this.myDate[0];
      this.user_data.end_date = this.myDate[1];
    } else {
      let today = new Date();
      this.user_data.end_date = today.toISOString().split('T')[0];
      today = new Date();
      today.setDate(today.getDate() - 30);
      this.user_data.start_date = today.toISOString().split('T')[0];
      this.myDate[0] = this.user_data.start_date;
      this.myDate[1] = this.user_data.end_date;
      localStorage.setItem('my_dates', JSON.stringify([this.user_data.start_date, this.user_data.end_date]));
    }
    this.user_data.offset = -1 * (new Date(this.user_data.start_date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    if (this.sharedService.dmsParams.dmsType === 'P') {
      if (d_stat_p && d_stat_p !== 'null') {
        this.status = JSON.parse(d_stat_p)[0];
        this.user_data.status = JSON.parse(d_stat_p)[1];
      } else {
        this.status = 'Dispatched';
        this.user_data.status = 'dispatched';
        localStorage.setItem('d_status_p', JSON.stringify([this.status, this.user_data.status]));
      }
    } else {
      if (d_stat && d_stat !== 'null') {
        this.status = JSON.parse(d_stat)[0];
        this.user_data.status = JSON.parse(d_stat)[1];
      } else {
        this.status = 'Draft';
        this.user_data.status = 'new';
        localStorage.setItem('d_status', JSON.stringify([this.status, this.user_data.status]));
      }
    }
    if (ord_stat && ord_stat !== 'null') {
      this.orderStatus = JSON.parse(ord_stat)[0];
      this.user_data.order_status = JSON.parse(ord_stat)[1];
    } else {
      if (this.sharedService.dmsParams.dmsType === 'P') {
        this.orderStatus = 'Confirm';
        this.user_data.order_status = 'confirm';
      } else {
        this.orderStatus = 'Draft';
        this.user_data.order_status = 'draft';
      }
      localStorage.setItem('invoice_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
    }

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.route.queryParamMap
      .subscribe(params => {
        this.user_data.type = params.get('type');
        this.user_data.order_type = params.get('order_type');
        if (this.user_data.order_type !== 'return') {
          this.user_data.order_type = 'sale';
        }
        this.sharedService.dmsParams.dmsType = this.user_data.type;
        this.sharedService.dmsParams.type = this.user_data.order_type;
        localStorage.setItem('dms_order_type', this.user_data.order_type);
        localStorage.setItem('dms_type', this.user_data.type);

        if (this.sharedService.dmsParams.goToInv.active) {
          this.user_data.ref = this.sharedService.dmsParams.goToInv.ref;
          this.sharedService.dmsParams.goToInv.active = false;
          this.sharedService.dmsParams.goToInv.ref = '';
          this.user_data.order_status = 'All';
          this.orderStatus = 'All';
          this.user_data.status = 'All';
          this.status = 'All';
          this.user_data.pay_status = 'All';
          this.payStatus = 'All';
          localStorage.setItem('invoice_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
          localStorage.setItem('d_status', JSON.stringify([this.status, this.user_data.status]));
          localStorage.setItem('d_status_p', JSON.stringify([this.status, this.user_data.status]));
        } else if (this.sharedService.dmsParams.goToRef.active) {
          this.user_data.ref = this.sharedService.dmsParams.goToRef.ref;
          this.sharedService.dmsParams.goToRef.active = false;
          this.sharedService.dmsParams.goToRef.ref = '';
          this.user_data.order_status = 'All';
          this.orderStatus = 'All';
          this.user_data.status = 'All';
          this.status = 'All';
          this.user_data.pay_status = 'All';
          this.payStatus = 'All';
          localStorage.setItem('invoice_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
          localStorage.setItem('d_status', JSON.stringify([this.status, this.user_data.status]));
          localStorage.setItem('d_status_p', JSON.stringify([this.status, this.user_data.status]));
        } else {
          this.user_data.ref = null;
        }
        let d_stat_p = localStorage.getItem('d_status_p');
        let ord_stat = localStorage.getItem('invoice_status');
        let d_stat = localStorage.getItem('d_status');
        if (this.sharedService.dmsParams.dmsType === 'P') {
          if (d_stat_p && d_stat_p !== 'null') {
            this.status = JSON.parse(d_stat_p)[0];
            this.user_data.status = JSON.parse(d_stat_p)[1];
          } else {
            this.status = 'Dispatched';
            this.user_data.status = 'dispatched';
            localStorage.setItem('d_status_p', JSON.stringify([this.status, this.user_data.status]));
          }
        } else {
          if (d_stat && d_stat !== 'null') {
            this.status = JSON.parse(d_stat)[0];
            this.user_data.status = JSON.parse(d_stat)[1];
          } else {
            this.status = 'Draft';
            this.user_data.status = 'new';
            localStorage.setItem('d_status', JSON.stringify([this.status, this.user_data.status]));
          }
        }
        if (ord_stat && ord_stat !== 'null') {
          this.orderStatus = JSON.parse(ord_stat)[0];
          this.user_data.order_status = JSON.parse(ord_stat)[1];
        } else {
          if (this.sharedService.dmsParams.dmsType === 'P') {
            this.orderStatus = 'Confirm';
            this.user_data.order_status = 'confirm';
          } else {
            this.orderStatus = 'Draft';
            this.user_data.order_status = 'draft';
          }
          localStorage.setItem('invoice_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
        }
        this.apiDmsOrderList();
        // let date = localStorage.getItem('my_dates');
        // if (date && (date !== 'null')) {
        //   date = JSON.parse(date);
        //   this.myDate[0] = date[0];
        //   this.myDate[1] = date[1];
        //   this.user_data.start_date = this.myDate[0];
        //   this.user_data.end_date = this.myDate[1];
        // } else {
        //   let today = new Date();
        //   this.user_data.end_date = today.toISOString().split('T')[0];
        //   today = new Date();
        //   today.setDate(today.getDate() - 30);
        //   this.user_data.start_date = today.toISOString().split('T')[0];
        //   this.myDate[0] = this.user_data.start_date;
        //   this.myDate[1] = this.user_data.end_date;
        //   localStorage.setItem('my_dates',  JSON.stringify([this.user_data.start_date, this.user_data.end_date]));
        // }
      });

    this.gridOptions = <GridOptions>{
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      suppressAggFuncInHeader: true,
      pivotMode: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: 9,
      groupMultiAutoColumn: true,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    };

  }

  close() {
    this.isModelOpened = true;
  }

  paymentPopup() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    let inThis = this;
    let datas = {
      write_off: false,
      writeoff_amt: 0,
      paid_amount: 0,
      invoices: [],
      payment_date: '',
      payment_type: '',
      comments: '',
      retailer: null,
      retailer_id: null,
      credits: 0,
      code: null,
      ref_id: null,
      cheque_no: null,
      cheque_date: null,
      branch_name: null,
      credit_used: false,
      cr_used_credits: 0
    };
    let invoices = this.gridApi.getSelectedRows();
    datas.retailer = invoices[0].CUSTOMER;
    datas.retailer_id = invoices[0].customer_id;
    datas.code = invoices[0].code;
    let inthis = this;
    invoices.forEach(invoice => {
      if (invoice.PAID === 'paid') {
        invoices = [];
        this.toastr.error('Payment is already done');
      } else if (invoice.STATUS === 'draft' && !inthis.advancePay) {
        invoices = [];
        this.toastr.error('Advance Payment is not enabled.Please select confirmed invoices');
      } else if (invoice.STATUS === 'cancel') {
        invoices = [];
        this.toastr.error('Canceled Invoice');
      } else if (datas.retailer_id !== invoice.customer_id) {
        invoices = [];
        this.toastr.error('Please select Single Customer payments');
      } else {
        datas.paid_amount += (parseFloat(invoice['GRAND TOTAL']) - parseFloat(invoice.paid));
        datas.invoices.push({
          d_status: invoice['DELIVERED'],
          p_status: invoice['PAID'],
          amount_total: invoice['GRAND TOTAL'],
          partner_id: invoice.customer_id,
          supplier_id: invoice.supplier_id,
          id: invoice.id,
          user_id: invoice.user_id,
          invoice_type: 'out_invoice'
        });
      }
    });
    if (invoices.length > 0) {
      let inData = {
        retailer_id: datas.retailer_id,
        access_token: localStorage.getItem('resfreshToken')
      };
      this.API_URL = '/api/dms_connects/get_retailer_credits';
      this.apiService.post(this.API_URL, inData)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            if (res.results.data.length > 0) {
              datas.credits = res.results.data[0].credits;
              datas.cr_used_credits = datas.credits;
            } else {
              datas.credits = 0;
              datas.cr_used_credits = 0;
            }
            invoices.reverse();
            this.out_amount = parseFloat(datas.paid_amount.toFixed(2));
            this.amount = parseFloat(datas.paid_amount.toFixed(2));
            this.datas = datas;
            var paymentData: any = {};
            paymentData.amount = this.amount;
            paymentData.out_amount = this.out_amount;
            paymentData.writeoff = this.writeoff;
            paymentData.writeoff_amt = this.writeoff_amt;
            paymentData.payDate = this.payDate;
            paymentData.chequeDate = this.chequeDate;
            paymentData.chequeNo = this.chequeNo;
            paymentData.branch = this.branch;
            paymentData.ref = this.ref;
            paymentData.title = 'MARK PAYMENT';
            const el: HTMLElement = this.payment.nativeElement;
            //el.click();

            var creatediaeref;
            if (screen.width > 991) {
              let config: MatDialogConfig = {
                width: "100vw",
                height: "100vh",
                maxWidth: "50%",
                maxHeight: "85%",
                data: {
                  datas: this.datas,
                  paymentData: paymentData
                }
              }
              creatediaeref = this.dialog.open(PaymentPopupComponent, config);
            } else {
              let config: MatDialogConfig = {
                width: "100vw",
                height: "100vh",
                maxWidth: "90%",
                maxHeight: "85%",
                data: {
                  datas: this.datas,
                  paymentData: paymentData
                }
              }

              creatediaeref = this.dialog.open(PaymentPopupComponent, config);
            }

            creatediaeref.componentInstance.markPayment.subscribe(data => {
              this.markPaid(data);
            });
          } else {
            this.toastr.error('Credit Fetch Failed' + res.results.msg);
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        });
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('Select Atleast One Valid Customer payments');
    }
  }

  updateWriteoff() {
    this.writeoff_amt = parseFloat((parseFloat(String(this.out_amount)) - parseFloat(String(this.amount))).toFixed(2));
  }

  markPaid(params) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';

    let branch = '';
    if (params.bank && params.bank !== '') {
      branch = params.bank;
      if (params.branch && params.branch !== '') {
        branch = branch + ' - ' + params.branch;
      }
    } else {
      if (params.branch && params.branch !== '') {
        branch = params.branch;
      }
    }

    this.datas.payment_date = params.payDate;
    this.datas.payment_type = params.paymentMode;
    this.datas.paid_amount = parseFloat(String(params.amount)).toFixed(2);
    this.datas.comments = params.comments;
    this.datas.write_off = params.writeoff;
    this.datas.writeoff_amt = params.writeoff_amt;
    this.datas.cheque_date = params.chequeDate;
    this.datas.cheque_no = params.chequeNo;
    this.datas.branch = branch;
    this.datas.ref = params.ref;
    let data = {
      datas: this.datas,
      access_token: localStorage.getItem('resfreshToken')
    }
    this.API_URL = '/api/pwa_connects/dms_mark_payments';
    this.apiService.post(this.API_URL, data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.close();
          this.toastr.success('Payment Successfull');
          this.apiDmsOrderList();
        } else {
          this.toastr.error('Payment Failed' + res.results.msg);
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      });
  }

  actionClick($event) {
    console.log(this.action);
    if (this.action === 'new_order') {
      this.newOrderCreation();
    } else if (this.action === 'dispatch') {
      this.markAll('dispatch')
    } else if (this.action === 'receive') {
      this.markAll('recieve')
    } else if (this.action === 'mark_dispatch') {
      this.markAll('mark_dispatch')
    } else if (this.action === 'delivery') {
      this.markAll('delivery')
    } else if (this.action === 'payment') {
      this.paymentPopup()
    }

    this.action = '';
    console.log(this.action);
  }

  statusChange(event) {
    if (event === 'Draft') {
      this.user_data.status = 'new';
    } else if (event === 'Dispatched') {
      this.user_data.status = 'dispatched';
    } else if (event === 'Delivered') {
      this.user_data.status = 'delivered';
    } else {
      this.user_data.status = 'All';
    }
    if (this.sharedService.dmsParams.dmsType === 'P') {
      localStorage.setItem('d_status_p', JSON.stringify([this.status, this.user_data.status]));
    } else {
      localStorage.setItem('d_status', JSON.stringify([this.status, this.user_data.status]));
    }
  }

  statusPayChange(event) {
    if (event === 'Draft') {
      this.user_data.pay_status = 'new';
    } else if (event === 'Partial') {
      this.user_data.pay_status = 'partial';
    } else if (event === 'Paid') {
      this.user_data.pay_status = 'paid';
    } else {
      this.user_data.pay_status = 'All';
    }
  }

  statusOrdChange(event) {
    if (event === 'Draft') {
      this.user_data.order_status = 'draft';
    } else if (event === 'Confirm') {
      this.user_data.order_status = 'confirm';
    } else if (event === 'Cancel') {
      this.user_data.order_status = 'cancel';
    } else {
      this.user_data.order_status = 'All';
    }
    localStorage.setItem('invoice_status', JSON.stringify([this.orderStatus, this.user_data.order_status]));
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    var str = '';

    str += (n[1] != '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != '0') ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ' : '';
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    } else {
      if (this.a[n[5][1]] != 'Zero ') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      } else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

  async markAll(status) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    let rows = this.gridApi.getSelectedRows();
    let success_count = 0;
    let failed_count = 0;
    let failed = [];
    let allOrders = [];
    let inthis = this;
    const orderData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      order: {}
    };
    rows.forEach(function (order) {
      if (inthis.featureDispatch && status === 'mark_dispatch' && order.DELIVERED === 'dispatched') {
        console.log('error');
      } else {
        const data = {
          supplier_territory_id: order.supplier_territory,
          distributor_id: order.supplier_id,
          territory_id: order.territory_id,
          customer_id: order.customer_id,
          partner_id: order.customer_id,
          invoice_id: order.id,
          order_id: order.order_id,
          feature_confirm: inthis.featureConfirm,
          feature_dispatch: inthis.featureDispatch,
          feature_delivery: inthis.featureDelivery,
          feature_primary_confirm: inthis.featurePrimaryConfirm,
          feature_primary_dispatch: inthis.featurePrimaryDispatch,
          feature_receive: inthis.featureReceive,
          stock_added: false,
          stock_data: [],
          stock_log_data: [],
          tracking_ref: '',
          transporter_id: null, // Fetch From res.transporter table
          transport_type: '',
          no_of_bundle: null,
          weight: null,
          comments: 'PWA',
          label: status,
          type: 'out_invoice',
          id: order.id,
          total: order['GRAND TOTAL'],
          user_id: order.user_id
        };
        allOrders.push(data);
      }
    });
    let total_len = allOrders.length;
    if (total_len > 0) {
      let count = 0;
      do {
        orderData.order = allOrders[count];
        let resp;
        if (status === 'delivery' || status === 'mark_dispatch') {
          resp = await this.apiService.markDeliveryDmsAll(orderData);
        } else if (status === 'recieve') {
          resp = await this.apiService.markReceiveDmsAll(orderData);
        } else if (status === 'dispatch') {
          resp = await this.apiService.markDispatchDmsAll(orderData);
        }
        if (resp.hasOwnProperty('results') && resp['results'].failed_invoices.length === 0) {
          success_count += 1;
        } else {
          failed.push(resp['results'].failed_invoices);
          failed_count += 1;
        }
        count++;
      } while (count < total_len);
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      if (failed.length > 0) {
        this.toastr.error('Success count: ' + success_count + ', Failed Count: ' + failed_count + ' ' + JSON.stringify(failed));
      } else {
        this.toastr.success('Done Successfully');
        this.apiDmsOrderList();
      }
    } else {
      this.toastr.warning('No valid records to do operation');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }
  }

 /* onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected) {
        let enable_dispatch = true;
        let enable_delivery = true;
        let rows = this.gridApi.getSelectedRows();
        rows.forEach(function (order) {
          if (order.cust_supp) {
            enable_delivery = false;
          } else {
            enable_dispatch = false;
          }
        });
        this.enableDispatch = enable_dispatch;
        this.enableDelivery = enable_delivery;
        this.show_buttons = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected) {
        let enable_dispatch = true;
        let enable_delivery = true;
        let rows = this.gridApi.getSelectedRows();
        rows.forEach(function (order) {
          if (order.cust_supp) {
            enable_delivery = false;
          } else {
            enable_dispatch = false;
          }
        });
        this.enableDispatch = enable_dispatch;
        this.enableDelivery = enable_delivery;
        this.show_buttons = true;
      } else {
        this.show_buttons = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }*/

  onSelectionChanged(event) {
    this.tabIndex = 0;
    let enable_dispatch = true;
    let enable_delivery = true;
    const rows = this.gridApi.getSelectedRows();
    if (rows && rows.length > 0) {
      rows.forEach(function (order) {
        if (order.cust_supp) {
          enable_delivery = false;
        } else {
          enable_dispatch = false;
        }
      });
      this.enableDispatch = enable_dispatch;
      this.enableDelivery = enable_delivery;
      this.show_buttons = true;
    } else {
      this.show_buttons = false;
    }
  }

  apiDmsOrderList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let sl_no = 0;
    let datePipe = this.datePipe;
    this.show_buttons = false;
    this.enableDelivery = false;
    this.enableDispatch = false;
    if (!this.user_data.order_type) {
      this.user_data.order_type = '';
    }
    if (!this.user_data.ref) {
      this.user_data.ref = '';
    }
    if (this.user_data.type === 'S') {
      this.API_URL = '/api/dms_connects/get_sale_invoices';
    } else {
      this.API_URL = '/api/dms_connects/get_primary_invoices';
    }

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
            // this.retail_credits = res['results'].credits;
            // this.order_paids = res['results'].paid;
            if (res['results'].data.length > 0) {
              res['results'].data.forEach(function (data) {
                sl_no = sl_no + 1;
                rowdata.push({
                  INVOICE: data.inv_no,
                  DATE: datePipe.transform(data.inv_date, 'dd/MM/yyyy'),
                  'ORDER REF': data.sale_ref,
                  CUSTOMER: data.cust_name,
                  SUPPLIER: data.supp_name,
                  'GRAND TOTAL': Math.abs(data.grand_total),
                  OUTSTANDING: data.outstanding,
                  TYPE: data.type,
                  STATUS: data.state,
                  PO : data.po,
                  DELIVERED: data.d_status,
                  PAID: data.p_status,
                  /* PRINT: '',*/
                  id: data.inv_id,
                  customer_supplier: data.customer_supplier,
                  line_data: data.sale_items,
                  supplier_territory: data.supplier_territory_id,
                  supplier_id: data.supp_id,
                  order_id: data.order_id,
                  territory_id: data.territory_id,
                  customer_id: data.cust_id,
                  cust_supp: data.customer_supplier,
                  user_id: data.sman_id,
                  userid: data.user_id,
                  beat_id: data.beat_id,
                  code: data.cust_code,
                  credits: data.credits,
                  paid: data.paid,
                  inv_dp_id: data.inv_dp_id,
                  inv_dp_name: data.inv_dp_name
                });
              });
              this.data = rowdata[0];
              this.rowData = rowdata;
              this.storeOrderData(this.rowData);
              for (var key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                  if (key === "STATUS") {
                    this.columnDefs.push({
                      headerName: key,
                      headerClass: 'myagheader',
                      field: key,
                      sortable: true,
                      filter: true,
                      unSortIcon: true,
                      lockPosition: true,
                      width: 150,
                      pinned: 'right',
                      cellStyle: function (params) {
                        if (params.value != null && params.value != undefined) {
                          if (params.value === 'draft') {
                            return {color: 'blue'};
                          } else if (params.value === 'cancel') {
                            return {color: 'LightCoral'};
                          } else if (params.value === 'confirm' || params.value === 'sale') {
                            return {color: 'MediumSeaGreen'};
                          } /*else if (params.value === 'sale') {
                            return {color: '#696969'};
                          }*/
                        }
                      },
                      cellRenderer: function (params) {
                        if (params.value === 'sale') {
                          params.value = 'confirm';
                        }
                        var displayElem = '<span class="ml-1 status">' + params.value + '</span>';

                        return displayElem;
                      }
                    });

                  } else if (key === 'DELIVERED' || key === 'PAID') {
                    let hd = false;
                    if (this.user_data.order_type === 'return') {
                      hd = true;
                    }
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, sortable: true, filter: true,
                      unSortIcon: true, lockPosition: true, width: 120, hide: hd, pinned: 'right',
                      cellStyle: function (params) {
                        if (params.value != null && params.value != undefined) {
                          if (params.value === 'new') {
                            return {color: 'blue'};
                          } else if (params.value === 'cancel') {
                            return {color: 'LightCoral'};
                          } else if (params.value === 'dispatched') {
                            return {color: 'MediumSeaGreen'};
                          } else if (params.value === 'paid') {
                            return {color: 'MediumSeaGreen'};
                          } else if (params.value === 'delivered') {
                            return {color: '#696969'};
                          } else if (params.value === 'partial') {
                            return {color: '#F4A460'};
                          }
                        }
                      },
                      cellRenderer: function (params) {
                        if (params.value === 'new') {
                          params.value = 'draft';
                        }
                        var displayElem = '<span class="ml-1 status">' + params.value + '</span>';

                        return displayElem;
                      }
                    });

                  } else if (key === 'INVOICE') {
                    this.columnDefs.push({
                      headerName: key,
                      headerClass: 'myagheader',
                      field: key,
                      sortable: true,
                      filter: true,
                      resizable: true,
                      lockPosition: true,
                      width: 200,
                      checkboxSelection: true,
                      headerCheckboxSelection: true,
                      headerCheckboxSelectionFilteredOnly: true,
                      pinned: 'left'
                    });
                  } else if (key === 'ORDER REF') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, resizable: true,
                      width: 170, filter: true,
                      lockPosition: true
                    });
                  } else if (key === 'OUTSTANDING') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', filter: true, unSortIcon: true,
                      lockPosition: true, width: 100, resizable: true,
                      cellRenderer: 'outstandingShowRenderer'
                    });
                  } else if (key === 'GRAND TOTAL') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, filter: true, unSortIcon: true,
                      lockPosition: true, width: 100, resizable: true
                    });
                  } else if (key === 'TYPE') {
                    let hd = true;
                    if (this.sharedService.dmsParams.type === 'return') {
                      hd = false;
                    }
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, filter: true, unSortIcon: true,
                      lockPosition: true, width: 100, resizable: true, hide: hd, pinned: 'right',
                      cellStyle: function (params) {
                        if (params.value != null && params.value != undefined) {
                          if (params.value === 'out_refund') {
                            return {color: '#F4A460'};
                          } else if (params.value === 'out_damage') {
                            return {color: 'LightCoral'};
                          }
                        }
                      },
                      cellRenderer: function (params) {
                        if (params.value === 'out_damage') {
                          params.value = 'damage';
                        } else {
                          params.value = 'return';
                        }
                        var displayElem = '<span class="ml-1 status">' + params.value + '</span>';

                        return displayElem;
                      }
                    });
                  } else if (key === 'DATE') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, filter: true, unSortIcon: true,
                      lockPosition: true, width: 100, resizable: true
                    });
                  } else if (key === 'SUPPLIER') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, filter: true, unSortIcon: true, hide:true,
                      lockPosition: true, width: 100, resizable: true
                    });
                  } else if (key === 'dc_id' || key === 'id' || key === 'customer_supplier' || key === 'line_data' ||
                    key === 'supplier_territory' || key === 'supplier_id' || key === 'order_id' ||
                    key === 'territory_id' || key === 'customer_id' || key === 'cust_supp' || key === 'user_id' || key === 'code' ||
                    key === 'credits' || key === 'paid' || key === 'userid' || key === 'beat_id') {
                    this.columnDefs.push({
                      headerName: key,
                      headerClass: 'myagheader',
                      field: key,
                      hide: true,
                      filter: true,
                      unSortIcon: true,
                      lockPosition: true
                    });
                  } else if (key === 'inv_dp_id') {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, sortable: true, filter: true,
                      unSortIcon: true, lockPosition: true, hide:true
                    });

                  } else if (key === 'inv_dp_name') {
                    this.columnDefs.push({
                      headerName: 'DELIVERY PLAN', headerClass: 'myagheader', field: key, sortable: true, filter: true,
                      unSortIcon: true
                    });

                  } else {
                    this.columnDefs.push({
                      headerName: key, headerClass: 'myagheader', field: key, sortable: true, filter: true,
                      unSortIcon: true, lockPosition: true,
                    });
                  }
                }
              }
              this.loaded = true;
              this.context = {componentParent: this};

              this.frameworkComponents = {
                colorCellRenderer: ColorCellRenderer,
                printInvoicecomponent: PrintInvoiceComponent,
                printClickRenderer: PrintClickRenderer,
                outstandingShowRenderer: OutstandingShowComponent
              };
            } else {
              this.preloader = false;
              this.empty_state = true;
            }

          } else {
            this.preloader = false;
            this.empty_state = true;
          }

        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  toLedger(params) {

    this.sharedService.dmsParams.ret_id = params.customer_id;
    this.router.navigate([this.apiService.redirect_url + '/reports/customer_ledger']);
  }

  printrows() {

    let selectedrows = this.gridApi.getSelectedRows();
    this.printAll(selectedrows);
  }

  printAll(rows) {
    const orderIds = [];
    this.allOrders = [];
    rows.forEach(vanRow => {
      orderIds.push(vanRow.id);
    });

    const orderData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      inv_id: orderIds
    };

    this.res_data = this.apiService.getInvoices(orderData);

    this.res_data.subscribe(res => {
      console.log(res);
      let orderDetails = [];
      const datePipe = this.datePipe;
      const domSanitizer = this.domSanitizer;
      let inthis = this;
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        res.results.data.forEach(data => {
          orderDetails = data;
          orderDetails['round_off'] = 0;
          orderDetails['label_config'] = {
            SupplierLabel: 'Supplier',
            TaxLabel: 'GSTN',
            PrimaryCurrency: '',
            SecondaryCurrency: ''
          };
          orderDetails['invoice_date'] = datePipe.transform(orderDetails['inv_date'], 'yyyy-MM-dd');
          const labels = orderDetails['labels'];
          if (labels) {
            labels.forEach(function (lab) {
              orderDetails['label_config'][lab.label] = lab.display;
            });
          }
          orderDetails['customer_skip'] = true;
          orderDetails['total'] = parseFloat(orderDetails['amount_total']) - parseFloat(orderDetails['discount']);


          if (this.feature_round_off === null || this.feature_round_off === undefined || this.feature_round_off === false) {
            orderDetails['round_off'] = (inthis.math.round(orderDetails['total']) - orderDetails['total']).toFixed(inthis.apiService.decimalPolicy);
            orderDetails['total'] = inthis.math.round(orderDetails['total']);
          }

          orderDetails['amount_pay'] = parseFloat(orderDetails['amount_pay']) + parseFloat(orderDetails['round_off']);
          const amount = orderDetails['amount_pay'].toString().split('.');
          if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
            orderDetails['amount_in_words'] = inthis.inWords(Math.abs(parseInt(amount[0], 10)));
            orderDetails['amount_in_words'] = orderDetails['label_config'].PrimaryCurrency + ' ' +
              orderDetails['amount_in_words'] + ' And ' + orderDetails['label_config'].SecondaryCurrency + ' ' +
              inthis.inWords(Math.abs(parseInt(amount[1], 10))) + ' Only';
          } else {
            orderDetails['amount_in_words'] = orderDetails['label_config'].PrimaryCurrency + ' ' +
              inthis.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
          }
          orderDetails['charges'] = 0;
          orderDetails['line_discount'] = 0;
          orderDetails['spcl_discount'] = 0;
          orderDetails['total_quantity'] = 0;
          orderDetails['sale_items'].forEach(function (line) {
            line.qty = Math.abs(line.qty);
            line.net_price = Math.abs(parseFloat(line.price_total) / parseFloat(line.qty)).toFixed(inthis.apiService.decimalPolicy);
            line.linediscount = Math.abs((parseFloat(line.qty) * parseFloat(line.price_unit) * parseFloat(line.discount)) / 100);
            line.spcldiscount = (parseFloat(line.qty) * parseFloat(line.trade_disc));
            orderDetails['charges'] += parseFloat(line.fixed_cost);
            orderDetails['line_discount'] += line.linediscount;
            orderDetails['spcl_discount'] += line.spcldiscount;
            orderDetails['total_quantity'] += parseFloat(line.qty);
            line.price_unit = parseFloat(line.price_unit).toFixed(inthis.apiService.decimalPolicy);
            line.base_qty = (parseFloat(line.qty) * parseFloat(line.factor_inv)).toFixed(0);
            line.base_price = (line.real_price * line.factor).toFixed(2);
          });

          if (orderDetails['logo']) {
            orderDetails['logo'] = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' +
              orderDetails['logo']);
          }
          inthis.allOrders.push(orderDetails);
        });
        this.triggerFalseClick2();
      }
    });
  }

  onCellClicked(event) {

    const column = event.column.getColId();

    if (column === 'PRINT') {
      const orderData = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        inv_id: [event.data.id]
      };

      this.res_data = this.apiService.getInvoices(orderData);

      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.orderDetails = res.results.data[0];
          const inthis = this;
          this.orderDetails['label_config'] = {
            SupplierLabel: 'Supplier',
            TaxLabel: 'GSTN',
            PrimaryCurrency: '',
            SecondaryCurrency: ''
          };
          const labels = this.orderDetails.labels;
          if (labels) {
            labels.forEach(function (lab) {
              inthis.orderDetails['label_config'][lab.label] = lab.display;
            });
          }
          this.orderDetails['total'] = parseFloat(this.orderDetails['amount_total']) - parseFloat(this.orderDetails['discount'])
          const amount = this.orderDetails.amount_pay.toString().split('.');
          if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
            this.orderDetails.amount_in_words = this.inWords(Math.abs(parseInt(amount[0], 10)));
            this.orderDetails.amount_in_words = this.orderDetails.label_config.PrimaryCurrency + ' ' + this.orderDetails.amount_in_words +
              ' And ' + this.orderDetails.label_config.SecondaryCurrency + ' ' + this.inWords(Math.abs(parseInt(amount[1], 10))) +
              ' Only';
          } else {
            this.orderDetails.amount_in_words = this.orderDetails.label_config.PrimaryCurrency + ' ' +
              this.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
          }
          this.orderDetails.invoice_date = this.datePipe.transform(this.orderDetails.inv_date, 'yyyy-MM-dd');
          this.orderDetails.customer_skip = true;
          if (this.orderDetails.logo) {
            this.orderDetails.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' +
              this.orderDetails.logo);
          }
          this.orderDetails.charges = 0;
          this.orderDetails.line_discount = 0;
          this.orderDetails.spcl_discount = 0;
          this.orderDetails.total_quantity = 0;
          this.orderDetails.sale_items.forEach(function (line) {
            line.qty = Math.abs(line.qty);
            line.net_price = Math.abs(parseFloat(line.price_total) / parseFloat(line.qty)).toFixed(inthis.apiService.decimalPolicy);
            line.linediscount = Math.abs((parseFloat(line.qty) * parseFloat(line.price_unit) * parseFloat(line.discount)) / 100);
            line.spcldiscount = (parseFloat(line.qty) * parseFloat(line.trade_disc));
            inthis.orderDetails.charges += parseFloat(line.fixed_cost);
            inthis.orderDetails.line_discount += line.linediscount;
            inthis.orderDetails.spcl_discount += line.spcldiscount;
            inthis.orderDetails.total_quantity += parseFloat(line.qty);
            line.price_unit = parseFloat(line.price_unit).toFixed(inthis.apiService.decimalPolicy);
            line.base_qty = (parseFloat(line.qty) * parseFloat(line.factor_inv)).toFixed(0);
            line.base_price = (line.real_price * line.factor).toFixed(2);
          });
          this.allOrders = [this.orderDetails];
          this.triggerFalseClick2();
        }
      });
    } else {
      this.sharedService.setDmsParams();
      this.sharedService.dmsParams.order_id = event.data.id;
      this.sharedService.dmsParams.cust_supp = event.data.customer_supplier;
      const rows = this.gridApi.getSelectedRows();
      const index = JSON.parse(localStorage.getItem("orderdata")).indexOf(rows[0].id);
      localStorage.setItem('orderindex', index);
      localStorage.setItem('invoice_order_id', event.data.id);
      this.router.navigate([this.apiService.redirect_url + "/new_invoice"]);
    }

  }

  triggerFalseClick2() {
    this.printFormat = localStorage.getItem('print-format');
    if (this.printFormat === 'A4') {
      const el: HTMLElement = this.interPrint.nativeElement;
      el.click();
    } else {
      const el: HTMLElement = this.interPrint2.nativeElement;
      el.click();
    }
  }

  newOrderCreation() {
    this.sharedService.setDmsParams();
    this.sharedService.dmsParams.new_mode = true;
    this.sharedService.dmsParams.type = this.user_data.order_type;
    this.sharedService.dmsParams.dmsType = this.user_data.type;
    localStorage.setItem('dms_order_type', this.user_data.order_type);
    localStorage.setItem('dms_type', this.user_data.type);
    this.router.navigate([this.apiService.redirect_url + "/new_invoice"]);
  }

  openDeliveryPlanPopup() {
    const rows = this.gridApi.getSelectedRows();
    /* const invoiceids = [];
     for (const key in rows) {
       invoiceids.push(rows[key].id);
     }*/
    let valid = true;
    let invalid_msg = '';
    for (const item of rows) {
      if (item['DELIVERED'] === 'delivered') {
        valid = false;
        invalid_msg = 'Cannot select already delivered invoice : ' + item['INVOICE'];
        break;
      }
      if (item['inv_dp_id'] !== null && item['inv_dp_id'] !== undefined) {
        valid = false;
        invalid_msg = 'Invoice already present in delivery plan : ' + item['INVOICE'];
        break;
      }
    }
    if (!valid) {
        this.toastr.error(invalid_msg);
    } else {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "38%",
          maxHeight: "70%",
          data: {
            invoices: rows
          },
          panelClass: 'custom-dialog-container'
        };
        const creatediaeref = this.dialog.open(DeliveryPlanPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "95%",
          maxHeight: "70%",
          data: {
            invoices: rows
          },
          panelClass: 'custom-dialog-container'
        };
        const creatediaeref = this.dialog.open(DeliveryPlanPopupComponent, config);
      }
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let inthis = this;
    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function () {
      setTimeout(function () {
        if (screen.width > 991) {
          inthis.sharedService.resetPinned(inthis.gridOptions, [
            {colId: 'STATUS', pinned: 'right'},
            {colId: 'INVOICE', pinned: 'left'},
            {colId: 'DELIVERED', pinned: 'right'},
            {colId: 'PAID', pinned: 'right'},
            {colId: 'TYPE', pinned: 'right'}
          ]);
        } else {
          inthis.gridColumnApi.autoSizeColumns();
          inthis.sharedService.clearPinned(inthis.gridOptions);
        }
      });
    });
    if (screen.width > 991) {
      inthis.sharedService.resetPinned(inthis.gridOptions, [
        {colId: 'STATUS', pinned: 'right'},
        {colId: 'INVOICE', pinned: 'left'},
        {colId: 'DELIVERED', pinned: 'right'},
        {colId: 'PAID', pinned: 'right'},
        {colId: 'TYPE', pinned: 'right'}
      ]);
    } else {
      inthis.gridColumnApi.autoSizeColumns();
      inthis.sharedService.clearPinned(inthis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this._localConfigCache.getAppConfig().then(app_config => {
      this.feature_round_off = app_config['round_off'];
    });

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass']
    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changePayDate(dt) {
    this.payDate = this.datePipe.transform(this.payDate, 'yyyy-MM-dd');
  }

  changeChqDate(dt) {
    this.chequeDate = this.datePipe.transform(this.chequeDate, 'yyyy-MM-dd');
  }

  changeDate(dt) {

    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('my_dates', JSON.stringify([this.user_data.start_date, this.user_data.end_date]));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

  }

  storeOrderData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('orderdata', JSON.stringify(ids));
  }
}
