import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DistributorMasterReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Distributor Master Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/get_distributor_master';
    const inthis = this;
    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
          {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
          {headerName: 'ID', field: 'id', enableRowGroup: true, width: 150},
          {headerName: 'NAME', field: 'name', enableRowGroup: true, width: 150},
          {headerName: 'CODE', field: 'code', enableRowGroup: true, width: 80},
          {headerName: 'TYPE', field: 'type', enableRowGroup: true, width: 100},
          {headerName: 'DIST TYPE', field: 'dist_type', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'MOBILE', field: 'mobile', width: 100},
          {headerName: 'STATUS', field: 'status', width: 150},
          {headerName: 'BEAT', field: 'beat', enableRowGroup: true, width: 150},
          {headerName: 'ACTIVE', field: 'active', enableRowGroup: true, width: 80},
          {
            headerName: 'CREATE DATE', field: 'create_date', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.create_date) {
                  return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.create_date;
                }
              } else {
                return '';
              }
            }
          }
        ]
      }
    ];

    if (this.uqTypes) {
      let headercount = 1;
      for (const params in this.uqTypes) {
        this.columnDefs.push(
          {
            headerName: params,
            children: []
          }
        );
        for (const key of this.uqTypes[params]) {
          this.columnDefs[headercount].children.push(
            {headerName: key, field: params + '_' + key, width: 80}
          );
        }
        headercount++;
      }
    }

  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.uqTypes = [];
    this.apiData.forEach(obj => {
      if (obj.params) {
        if (obj.params.constructor !== Object) {
          try {
            obj.params = JSON.parse(obj.params);
          } catch (e) {
            console.log(obj);
          }
        }
        if (obj.params && obj.params.length > 0) {
          for (const params of obj.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }

        if (obj.params.params && obj.params.params.length > 0) {
          for (const params of obj.params.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }
      }
    });
    this.rowData = this.apiData;
    // console.log(this.rowData);
    this.configureGrid();
  }

}
