import {Component, OnInit, Output, EventEmitter, Input, Directive, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-beat-dropdown',
  templateUrl: './beat-dropdown.component.html',
  styleUrls: ['./beat-dropdown.component.scss']
})
export class BeatDropdownComponent implements OnInit {
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() itemList: object;
  @Input() selectedItems: object;

  // itemList = [];
  // selectedItems = [];
  settings = {};
  constructor() { }

  ngOnInit() {

    // this.itemList = [
    //   { "id": 1, "itemName": "DC Sales" },
    //   { "id": 2, "itemName": "User Collection Report" },
    // ];

    // this.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    // if (this.selectedItems[0].id == "0") {
    //   this.selectedItems =  [{"id": 0, "itemName": "All Beats"}];
    // }
    // this.settings = { singleSelection: true, text: this.selectedItems[0].itemName, enableSearchFilter: true };
  }

  beatChange(event) {

    this.statusChange.emit(event);
  }

  onSelectAll(event) {
    event = {"id": 0, "itemName": "All"};
    this.statusChange.emit(event);
  }

}


