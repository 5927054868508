<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="search-list row">
        <div class="col-md-5 pad0">
          <div class="search" style="display: inline-block;">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input  id="filter-text-box" type="text"
                    placeholder="filter"
                    (input)="quickSearch()"
                    class="search-control-area"
                    placeholder="Search">
          </div>
        </div>
        <div class="col-md-2 pad0 nopadding">

        </div>
        <div class="col-md-5 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost3></ng-template>
              </li>
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <!-- <li class="margLR">
                 <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>
               </li>-->
              <!-- <li class="margLR">
                 <div
                   class="actionbutton"
                   title="Upload"
                 >
                   <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                 </div>
               </li>-->
              <li class="margLR">
                <div style="background-color: white;width:45px;height:45px;display: flex;
    align-items: center; justify-content: center;cursor:pointer; "
                >
                  <!--   <mat-icon style="height: 25px;">filter_list</mat-icon>-->
                  <span title="Add Filter" >
                    <img
                      style="height: 20px;"
                      src="../../assets/filter.svg"
                      [matMenuTriggerFor]="filterdropdown"
                    >
                  </span>
                  <mat-menu #filterdropdown="matMenu">
                   <app-components-filters  [matMenuTriggerFor]="filterdropdown" [showableFilters]="showableFilters"  (onsetFilter)="setFilter($event)" (onGridSearch)="gridSearch($event)"  ></app-components-filters>
                  </mat-menu>
                </div>
              </li>
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="loadServerData()"
                  >GO</button>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Retail Audit Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 70vh" class="ag-theme-balham"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context">
            </ag-grid-angular>

            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="emptyState">
              <app-emptystate></app-emptystate>
            </div>
            <app-error-handler
              [errorObject]="errorObject"
              [errorExists]="errorExists">
            </app-error-handler>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
