<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Payment</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="row">
        <div class="col-md-12 pad0">
          <div class="col-md-7 pl-0 pr-20">
            <div style="width: 100%">
              <span class="namespan">{{customerDetails.name}}</span>

              <span class="codespan" style="font-weight: 500;"> {{customerDetails.code}}</span>
              <span class="codespan">Code </span>
            </div>
            <div class="cardbox cardgrid" style="margin-top: 35px;width: 100%">
              <div>
                <label class="headerlabel">Outstanding</label>
                <span class="headerspan">{{customerDetails.balance}}</span>
              </div>
              <div>
                <label class="headerlabel">Credit Limit</label>
                <span class="headerspan">{{customerDetails.credit_limit}}</span>
              </div>
              <div>
                <label class="headerlabel">Credit Days</label>
                <span class="headerspan">{{customerDetails.credit_days}}</span>
              </div>
              <div>
                <label class="headerlabel">Credit Available</label>
                <span class="headerspan">{{customerDetails.credit}}</span>
              </div>
            </div>
            <div class="cardbox" style="margin-top: 15px;width: 100%">
              <div style="width: 100%">
                <span class="headerspan" style="float: left">Invoices</span>

                <div style="display: inline-block; float: right;">
                  <span style="font-size: 14px;color: #2D2D2D;">Total Invoices</span>
                  <span
                    style="margin-left:5px; color: #0762AD;font-size: 14px;font-weight: 500;">{{selectedInvoices.length}}</span>
                  <span style="font-size: 14px;color: #2D2D2D;">/{{invoiceList.length}}</span>
                </div>

              </div>
              <div class="row" style="display: block; margin-top: 35px;">
                <div class="col-md-12 pad0">
                  <div>
                    <ag-grid-angular *ngIf="dataLoaded" id="myGrid6" style="height: 35vh" class="ag-theme-alpine"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     [getRowId]="getRowId"
                                     (rowSelected)="onRowSelected($event)"
                                     (cellEditingStopped)="onCellEditingStopped($event)"
                                     [context]="context">
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
              <div style="margin-top: 35px;">
                <div style="width: 100%">
                  <span class="headerspan" style="float: left">Available Credit Notes - {{availableCreditNotes}}</span>
                </div>
              </div>
              <div class="crgrid" style="margin-top: 70px;width: 100%;">
                <ng-container *ngFor="let tile of crNotesList">
                  <div class="crbox"
                       [ngClass]="{'full': tile.selected && tile.used === tile.balance,
                       'partial': tile.selected && tile.used < tile.balance}"
                       (click)="crnoteClick(tile)">
                    <span class="crspanheader"
                          [ngClass]="{'full': tile.selected && tile.used === tile.balance,
                       'partial': tile.selected && tile.used < tile.balance}">{{tile.name}}</span>
                    <span class="crspanheader"
                          [ngClass]="{'full': tile.selected && tile.used === tile.balance,
                       'partial': tile.selected && tile.used < tile.balance}">{{tile.cn_date}}</span>
                    <span class="crspanAmt"
                          [ngClass]="{'full': tile.selected  && tile.used === tile.balance,
                      'partial': tile.selected && tile.used < tile.balance
                      }">{{tile.balance.toFixed(2)}}</span>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
          <div class="col-md-5 pl-0 pr-20">
            <div class="cardbox" style="margin-top: 15px;width: 100%">
              <div style="width: 100%">
                <span class="headerspan" style="float: left">Total Amount Collected</span>

                <div style="display: inline-block; float: right;" title="Amount Collected + Credit Used + Round-Off(Checked)">
                  <span style="font-size: 14px;color: #2D2D2D;">Total Amount - </span>
                  <span
                    style="margin-left:5px; color: #0762AD;font-size: 14px;font-weight: 500;">{{amount_collected.toFixed(2)}}</span>
                </div>

              </div>
              <input
                id="tot_amount"
                class="forminput"
                style="display: block; color: #0762AD; font-size: 22px; font-weight: 500;margin-top: 10px;width: 100%;height:45px;"
                type="number"
                name="code"
                [(ngModel)]="paymentDetails.tot_amount"
                (change)="onAmountChange()"
                #code="ngModel"
              >
              <div *ngIf="creditUsed>0" style="width: 100%;margin-top: 10px;">
                <span style="font-size: 14px;color: #2D2D2D;">CR Utilised - </span>
                <span style="font-size: 14px;color: #379862;font-weight:500;margin-left: 5px;">{{creditUsed}}</span>
              </div>
              <div *ngIf="(paymentDetails.tot_amount + creditUsed) > totalPayableAmt" style="background-color: #E1F0E7; padding: 10px;display: flex;margin-top: 10px;">
                <img src="../../../../../assets/warning_green.svg"
                     style="width: 12px; height:12px; margin: 2px 10px 0px 3px;">
                <span style="font-size: 12px;color: #379862">Entered amount exceeds total invoices amount. Excess amount of {{excess_amount.toFixed(apiService.decimalPolicy)}} will be added as a new credit note.</span>
              </div>
              <div *ngIf="(paymentDetails.tot_amount + creditUsed) < totalPayableAmt && (totalCrNoteAmt > creditUsed)" style="background-color: #FBEBE6; padding: 10px;display: flex;margin-top: 10px;">
                <img src="../../../../../assets/Warning.svg" style="width: 12px; height:12px; margin: 2px 10px 0px 3px;">
                <span style="font-size: 12px;color: #DC562E">Credit note available</span>
              </div>
              <div *ngIf="write_off_amt !== 0" style="margin-top: 10px;">
<!--                <input type="checkbox" style="vertical-align: middle;margin-bottom: 6px;" [(ngModel)]="paymentDetails.write_off"  (change)="writeOffChecked($event)"/>-->
                <span style="padding-left: 5px;white-space: nowrap; overflow: hidden !important;text-overflow: ellipsis;width: 220px;font-size: 12px;vertical-align: middle">Round off {{write_off_amt.toFixed(apiService.decimalPolicy)}}</span>
              </div>

            </div>
            <div class="cardbox" style="margin-top: 20px;width: 100%">
              <span class="headerspan">Payment Method</span>
              <label class="headerlabel">Choose customer payment method.</label>
              <div style="margin-top: 5px;" class="formselect">
                <select
                  name="paymentmode"
                  [(ngModel)]="paymentDetails.payment_type"
                  #paymentmode="ngModel"
                  style="width: 100%;">
                  <option
                    *ngFor="let item of paymentModes"
                    value="{{ item.id }}"
                  >
                    {{ item.itemName }}
                  </option>
                </select>
              </div>
              <div *ngIf="paymentDetails.payment_type === 'cheque'">
                <div style="display: inline-block; margin-top: 25px; width: 100%">
                  <mat-radio-group aria-label="Select an option"
                                   class="radio-group"
                                   [(ngModel)]="paymentDetails.state"
                                   (change)="chequeTypeSelection()">
                    <mat-radio-button value="posted" checked>Cleared</mat-radio-button>
                    <mat-radio-button value="draft" style="margin-left: 20px;">Not Cleared</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="row" style="display: flex;width: 100%;">
                  <input
                    class="forminput"
                    style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;margin-right: 10px;flex: 1; "
                    type="text"
                    name="chequeno"
                    [(ngModel)]="paymentDetails.cheque_no"
                    #chequeno="ngModel"
                    placeholder="Cheque Number"
                  >
                  <div class="date-picker-form-grey" style="flex: 1;">
                    <input [selectMode]="'single'"
                           [readonly]="true"
                           [(ngModel)]="paymentDetails.cheque_date"
                           [owlDateTimeTrigger]="dt"
                           [owlDateTime]="dt" placeholder="Deposit Date"
                           name="date"
                           #date="ngModel"
                           style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;width: 100%;">
                    <!--<span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                    <div style=" position: absolute;right: 20px;top: 20px;">
                      <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
                    </div>

                    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                  </div>
                </div>

                <input
                  class="forminput"
                  style="display: block; color: #2D2D2D; font-size: 14px;margin-top: 10px;width: 100%;"
                  type="text"
                  name="branch"
                  [(ngModel)]="paymentDetails.branch"
                  #branch="ngModel"
                  placeholder="Branch Name"
                >
              </div>

            </div>
            <div class="cardbox" style="margin-top: 20px;width: 100%">
              <span class="headerspan">Narration</span>
              <textarea
                class="forminputwhitebg"
                style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
                type="text"
                name="narration"
                [(ngModel)]="paymentDetails.narration"
                #narration="ngModel"
                placeholder="Enter Narration"
              ></textarea>
            </div>
            <div>
              <button
                class="btnsmall confirm-blue-btn"
                style="width: 100%; margin-top: 15px;padding: 10px 30px;"
                (click)="collectPayment()"
              >
                Collect Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
