import {Component} from "@angular/core";
import {IHeaderAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'open-order',
  template: `<span><button *ngIf="config_json" class="btn white-blue-btn" (click)="showAll()">Invoices</button></span>`,
  styles: []
})
export class OpenOrderComponent implements IHeaderAngularComp {
  public params: any;
  public config_json = null;

  agInit(params: any): void {
    this.params = params;
    this.config_json = this.params.data.config_json;
  }

  public showAll() {

    this.params.context.componentParent.showAll(this.config_json);

  }

  refresh(): boolean {
    return false;
  }
}
