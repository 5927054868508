import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../../pagebase/pagebase";
import {ErrortooltipComponent} from "../../../errortooltip";
import {MatDialogConfig} from "@angular/material/dialog";
import {LoadOutUploadComponent} from "../loadout/loadout_upload.component";
import {ErrorHandler} from "../../../error/error-handler";

@Component({
  selector: 'app-stock-transfer-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class StockTransferListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.checkAndLoadPreviousSate();

    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "draft", itemName: "Draft"},
      {id: "approved", itemName: "Approved"},
      {id: "rejected", itemName: "Rejected"}
    ];

    this.showCustomFilter = true;
    this.showActionButton = false;
    this.showDate = true;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };
    this.showPinnedFooter = true;
    // this.getAuthorizedActions();
    // this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();

    this.sharedService.setHeader('Transfer List');
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/stock_transfers/transfer_detail';

    this.columnDefs = [
      {
        headerName: "ID",
        field: "id",
        width: 20
      },
      {
        headerName: "FROM",
        children: [
          {headerName: "Distributor", field: "from_distributor", width: 80},
          {headerName: "Territory", field: "from_territory", width: 80},
        ]
      },
      {
        headerName: "TO",
        children: [
          {headerName: "Distributor", field: "to_distributor", width: 40},
          {headerName: "Territory", field: "to_territory", width: 50},
        ]
      }, {
        headerName: "STOCK REQUEST",
        children: [
          {headerName: "Type", field: "type", width: 40},
          {
            headerName: "Requested By",
            field: "req_by",
            width: 50
          },
          {headerName: "Requested On", field: "req_on", width: 40},
          {
            headerName: "Status", field: "status", width: 40,
            valueGetter: params => {
              if (params.data && params.data.hasOwnProperty('status')) {
                if (params.data.status === 'draft') {
                  return 'Draft';
                } else if (params.data.status === 'approved') {
                  return 'Approved';
                } else if (params.data.status === 'rejected') {
                  return 'Rejected';
                } else {
                  return params.data.status;
                }
              }
            },
            cellRenderer(params) {
              let displayElem = '';
              if (params.data) {
                if (params.value === 'Rejected') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'Draft') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'Approved') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else {
                  if (params.value) {
                    displayElem = '<span>' + params.value + '</span>';
                  }
                }

                return displayElem;
              }
            }
          }
        ]
      }
    ];

    this.getRowId = (params) => params.data.id;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/vansales/transfer'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
