import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {MapsAPILoader} from '@agm/core';

@Component({
  selector: 'app-location-popup',
  templateUrl: './location_popup.component.html',
  styleUrls: ['./location_popup.component.scss']

})

export class LocationPopupComponent implements OnInit {

  latitude: number;
  longitude: number;
  endLatitude: number;
  endLongitude: number;
  zoom: number;
  title1;
  title2;
  infowindow1: any;
  infowindowContent1: any;
  infowindow2: any;
  infowindowContent2: any;

  map: any;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private domSanitizer: DomSanitizer,
              private mapsAPILoader: MapsAPILoader,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.latitude = dialogdata.latitude;
    this.longitude = dialogdata.longitude;

    if (dialogdata.end_latitude) {
      this.endLatitude = dialogdata.end_latitude;
      this.endLongitude = dialogdata.end_longitude;
    }

    if (dialogdata.title1) {
      this.title1 = dialogdata.title1;
    }

    if (dialogdata.title2) {
      this.title2 = dialogdata.title2;
    }

    this.zoom = 15;
  }

  ngOnInit(): void {

   /* if (this.map == null) {
      this.map = new google.maps.Map(
        document.getElementById('map1') as HTMLElement,
        {
          zoom: 15,
          center: { lat: this.latitude, lng: this.longitude  },
          mapTypeId: 'terrain',
        }
      );
    }
    this.mapsAPILoader.load().then(() => {
      if (this.title1) {
        this.infowindowContent1 = document.getElementById(
          'infowindow-content'
        ) as HTMLElement;
        this.infowindow1.setContent(this.infowindowContent1);

        this.infowindowContent1.children['place-name'].textContent = this.title1;
      }
    });*/


    /* if (dialogdata.title1) {
       this.title1 = dialogdata.title1;
       if (this.infowindow1 == null) {
         this.infowindow1 = new google.maps.InfoWindow();
       }
       this.infowindowContent1 = document.getElementById(
         'infowindow-content1'
       ) as HTMLElement;
       this.infowindow1.setContent(this.infowindowContent1);
       this.infowindowContent1.children['place-name1'].textContent = dialogdata.title1;
     }
     if (dialogdata.title2) {
       this.title2 = dialogdata.title2;
       if (this.infowindow2 == null) {
         this.infowindow2 = new google.maps.InfoWindow();
       }
       this.infowindowContent2 = document.getElementById(
         'infowindow-content2'
       ) as HTMLElement;
       this.infowindow2.setContent(this.infowindowContent2);
       this.infowindowContent2.children['place-name2'].textContent = dialogdata.title2;
     }*/
  }
  closeDialog() {
    this.dialog.closeAll();
  }
}
