import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-add-beat-retailer-popup-component',
  templateUrl: './add_beat_retailer_popup.component.html',
  styleUrls: ['./add_beat_retailer_popup.component.scss']
})
export class AddBeatRetailerPopupComponent implements OnInit {
  @Output() selectedOutlets: EventEmitter<any> = new EventEmitter<any>();
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  beatPlanDetails;
  outlet_types = [{id: 0, itemName: 'All Outlets'}];
  program_types = [{id: 0, itemName: 'All Programs'}];
  salesmen = [{id: 0, itemname: 'All Salesmen'}];
  states = [{id: 0, itemName: 'All States'}];
  clusters = [{id: 0, itemname: 'All Clusters'}];
  territories = [{id: 0, itemname: 'All Territories'}];
  API_URL = '';
  outletFilters = {
    class_type: 0,
    program_type: 0,
    outlet_type: 0,
    salesman_id: 0,
    state_id: 0,
    cluster_id: 0,
    territory_id: 0,
    territory_ids: [],
    showAll: true
  };
  team_territories_list = [];
  outletData = [];
  selectedOutlet = [];
  @Output() addretailersubmit: EventEmitter<any> = new EventEmitter<any>();
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.beatPlanDetails = dialogdata.beatPlanDetails;

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    if (localStorage.getItem('all_out_types') !== null) {
      this.outlet_types = this.outlet_types.concat(JSON.parse(localStorage.getItem('all_out_types')));
      this.outlet_types.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }
    if (localStorage.getItem('all_program_types') !== null) {
      this.program_types = this.program_types.concat(JSON.parse(localStorage.getItem('all_program_types')));
      this.program_types.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }
    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmen = this.salesmen.concat(JSON.parse(localStorage.getItem('all_salesmans')));
      this.salesmen.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    if (localStorage.getItem('all_states') !== null) {
      this.states = this.states.concat(JSON.parse(localStorage.getItem('all_states')));
      this.states.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }
    if (localStorage.getItem('all_clusters') !== null) {
      this.clusters = this.clusters.concat(JSON.parse(localStorage.getItem('all_clusters')));
      this.clusters.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    if (localStorage.getItem('all_territories') !== null) {
      this.territories = this.territories.concat(JSON.parse(localStorage.getItem('all_territories')));
      this.territories.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    this.team_territories_list = JSON.parse(localStorage.getItem('team_territories'));


    if (this.team_territories_list !== null) {
      for (let i = 0; i < this.team_territories_list.length; i++) {
        this.outletFilters.territory_ids.push(this.team_territories_list[i].id);
      }
    }


    this.columnDefs = [
      {
        headerName: "Code", headerClass: 'myagheader', field: "code", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true,
        /*cellStyle: params => {
          if (params.value === 'Add an Item') {
            return {color: '#17A2B8'};
          } else {
            return params.value;
          }
        },*/ headerCheckboxSelection: true, checkboxSelection: true
      },
      {headerName: "Name", headerClass: 'myagheader', field: "name", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 180, resizable: true},
      {
        headerName: "Beat", headerClass: 'myagheader', field: "beat", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.beat !== null) {
              let beatnames = '';
              params.data.beat.forEach(data => {
                beatnames = beatnames + data.bp + ', ';
              });
              return beatnames.substring(0, beatnames.length - 2);
            }
          }
        }
      },
      {headerName: "Class", headerClass: 'myagheader', field: "class_type", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true},
      {headerName: "Program Type", headerClass: 'myagheader', field: "program_type", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true},
      {headerName: "Salesman", headerClass: 'myagheader', field: "user", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {headerName: "District", headerClass: 'myagheader', field: "area", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {headerName: "Cluster", headerClass: 'myagheader', field: "cluster", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      /*  {headerName: "State", headerClass: 'myagheader', field: "state", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},*/
      {headerName: "Territory", headerClass: 'myagheader', field: "territory", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {headerName: "Region", headerClass: 'myagheader', field: "zone", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},

    ];
    this.loaded = true;

  }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    // this.gridApi.setDomLayout('autoHeight');
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    /* const sortModel = [
       {colId: 'Date', sort: 'desc'}
     ];

     this.gridApi.setSortModel(sortModel);*/

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  fetchOutlets() {
    const paramObject: any = {};
    this.rowData = [];
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.outletFilters;
    this.API_URL = '/api/audit_masters/get_customers';
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        this.loaded = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.outletData = res.results.data;
          this.rowData = this.outletData;
          this.gridApi.setRowData(this.rowData);
          //this.loaded = true;
        } else {
          this.preloader = false;
          //this.empty_state = true;
        }
      });
  }

  addOutlets() {
    this.selectedOutlet = this.gridApi.getSelectedRows();
    this.selectedOutlets.emit(this.selectedOutlet);
    this.dialog.closeAll();
  }

  addRetailers() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    const rows = this.gridApi.getSelectedRows();
    paramObject.data = {};
    paramObject.data.beat_plan_id = this.beatPlanDetails.id;
    paramObject.data.retailer_ids = [];
    rows.forEach(r => {
      paramObject.data.retailer_ids.push(r.id);
    });

    this.API_URL = '/api/beat_plan_retailers/add_beat_plan_retailer';
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        this.loaded = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.addretailersubmit.emit(true);
          this.dialog.closeAll();
        } else {
          this.preloader = false;
        }
      });
  }
}
