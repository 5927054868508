import {Component, Input, OnInit} from '@angular/core';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import {DatePipe} from '@angular/common';
import {jsPDF} from "jspdf";

@Component({
  selector: 'app-excelicon',
  templateUrl: './excelicon.component.html',
  styleUrls: ['./excelicon.component.scss']
})
export class ExceliconComponent implements OnInit {
  @Input() gridOption;
  @Input() title;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
  }

  onBtnClick() {


    const exportData = [];
    let headers = [];
    const fields = [];
    //debugger;

    if (this.title === 'Production Order List') {

      this.gridOption.columnDefs.forEach(header => {
        if (header.hasOwnProperty('children')) {
          header.children.forEach(each_child => {
            if (each_child.hide === undefined || each_child.hide === false) {
              fields.push(each_child.field);
            }
          });
        }
      });
      headers = ['VanCode', 'LoadoutDate', 'ProductCode', 'ProductName', 'Qty',
        'SuggestedQty', 'SalesmanOrder', 'PreOrder Sug', 'Closing Sug', 'PreOrder', 'Closing',
      'Final Loadout Sug', 'Final Loadout', 'LoadoutValue'];
      const filteredRowData =  this.gridOption.api.rowModel.rowsToDisplay;
      filteredRowData.forEach(node => {
        fields.forEach(key => {
          if (node.data[key]['channel'] !== null && Object.keys(node.data[key]['channel']).length > 0) {
            for (const [key1, value] of Object.entries(node.data[key]['channel'])) {
              if (!headers.includes(key1)) {
                headers.push(key1);
              }
            }
          }
        });
      });
      let passObject = {};
      const all_products = this.gridOption.context.componentParent.all_products;
      const loadout_date = this.gridOption.context.componentParent.myDate;
      let qty = 0, sug = 0, ordered_qty = 0, value = 0, sug_total = 0, act_total = 0;
      const datePipe = this.datePipe;

      filteredRowData.forEach(node => {
        // if (node.data.ostatus != 'inactive') {
        fields.forEach(key => {
          if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
            qty = parseInt(node.data[key][Object.keys(node.data[key])[0]], 10);
            //ordered_qty = parseInt(node.data[key].prvs_qty, 10);
            ordered_qty = node.data[key]['prvs_qty'];
           /* if (node.data[key].sug === null || node.data[key].sug === undefined || node.data[key].sug === 0) {
              if (node.data[key].ordered !== null && node.data[key].ordered !== undefined) {
                ordered_qty = node.data[key].ordered;
              }
            }*/
            if (node.data[key].hasOwnProperty('find_prod') && node.data[key]['find_prod'] !== null && node.data[key]['find_prod'] !== undefined && node.data[key]['find_prod'].length > 0) {
              value = node.data[key]['find_prod'][0].price_total;
            }
            //value = parseFloat(node.data[key][Object.keys(node.data[key])[5]]);
           /* value = parseFloat(node.data[key][Object.keys(node.data[key])[4]]);
            if (node.data[key].van_preorder && node.data[key].van_preorder.length > 0) {
              sug = parseFloat(node.data[key].ord_sug) - parseFloat(node.data[key].pre_sug) + parseFloat(node.data[key].pre) - node.data[key].clos_sug;
            } else {
              sug = node.data[key].ord_sug - node.data[key].clos_sug;
            }*/
            /*if (node.data[key].pre === 0) {
              sug = node.data[key].ord_sug - node.data[key].clos_sug;
            } else {
              sug = parseFloat(node.data[key].ord_sug) - parseFloat(node.data[key].pre_sug) + parseFloat(node.data[key].pre) - node.data[key].clos_sug;
            }*/
            sug = node.data[key].ord_sug - parseFloat(node.data[key].pre_sug);
            if (sug < 0) {
              sug = 0;
            }
            sug_total = sug + node.data[key]['pre_sug'] - node.data[key]['clos_sug'];
            if (sug_total < 0) {
              sug_total = 0;
            }
            act_total = node.data[key]['act_total'];
            if (act_total < 0) {
              act_total = 0;
            }
          }
          if (qty === 0 && sug === 0 ) {
          } else {
            passObject = {};
            passObject['VanCode'] = node.data['van_code'];
            passObject['LoadoutDate'] = datePipe.transform((new Date(loadout_date)).toString(), 'dd/MM/yyyy');
            passObject['ProductCode'] = all_products[key].code;
            passObject['ProductName'] = all_products[key].name;
            passObject['Qty'] = qty;
            passObject['SuggestedQty'] = sug;
            passObject['SalesmanOrder'] = ordered_qty;
            passObject['PreOrder Sug'] = node.data[key]['pre_sug'];
            passObject['Closing Sug'] = node.data[key]['clos_sug'];
            passObject['PreOrder'] = node.data[key]['pre'];
            passObject['Closing'] = node.data[key]['act_clos'];
            passObject['Final Loadout Sug'] = sug_total;
            passObject['Final Loadout'] = act_total;
            passObject['LoadoutValue'] = value;
            /*  passObject['MT'] = node.data[key]['channel']['MT'];
              passObject['ECOM'] = node.data[key]['channel']['ECOM'];*/
            if (headers.length > 14) {
              for (let i = 14; i < headers.length; i++) {
                if (node.data[key]['channel'][headers[i]] !== null && node.data[key]['channel'][headers[i]] !== undefined) {
                  passObject[headers[i]] = node.data[key]['channel'][headers[i]];
                } else {
                  passObject[headers[i]] = 0;
                }
              }
            }
            exportData.push(passObject);
          }
        });
        // }
      });

    } else if (this.title === 'Outlet-Product-Report' || this.title === 'Outlet-Aggregate-Report') {
      this.gridOption.columnDefs.forEach(header => {
        if (header.hasOwnProperty('children')) {
          header.children.forEach(each_child => {
            if (each_child.hide === undefined || each_child.hide === false) {
              headers.push(each_child.headerName);
              fields.push(each_child.field);
            }
          });
        } else {
          if (header.hide === undefined || header.hide === false) {
            headers.push(header.headerName);
            fields.push(header.field);
          }
        }
      });

      let passObject = {};


      this.gridOption.api.forEachLeafNode(node => {
        passObject = {};
        fields.forEach(key => {
          if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
            passObject[key] = node.data[key][Object.keys(node.data[key])[0]];
          } else {
            passObject[key] = node.data[key];
          }
        });
        exportData.push(passObject);
      });
    } else if (this.title === 'Van-Productivity-Report') {
      const type_datas = this.gridOption.context.componentParent.type_datas;
      const all_types = this.gridOption.context.componentParent.all_types;
      this.gridOption.columnDefs.forEach(header => {
        if (header.hasOwnProperty('children')) {
        } else {
          if (header.hide === undefined || header.hide === false) {
            headers.push(header.headerName);
            fields.push(header.field);
          }
        }
      });
      for (const type in type_datas) {
        fields.push('rp_' + type_datas[type].id);
      }
      headers.push('TYPE');
      headers.push('OUT_TC');
      headers.push('OUT_PC');
      headers.push('OUT_SALE');
      headers.push('OUT_REP');
      headers.push('OUT_RET');
      headers.push('OUT_VC');

      let passObject = {};
      const filteredRowData =  this.gridOption.api.rowModel.rowsToDisplay;
      filteredRowData.forEach(node => {
        passObject = {};
        fields.forEach(key => {
          if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
            const obj = {};
            obj['TYPE'] = all_types[key];
            obj['OUT_TC'] = node.data[key][Object.keys(node.data[key])[0]];
            obj['OUT_PC'] = node.data[key][Object.keys(node.data[key])[1]];
            obj['OUT_SALE'] = node.data[key][Object.keys(node.data[key])[2]];
            obj['OUT_REP'] = node.data[key][Object.keys(node.data[key])[3]];
            obj['OUT_RET'] = node.data[key][Object.keys(node.data[key])[4]];
            obj['OUT_VC'] = node.data[key][Object.keys(node.data[key])[5]];
            const obj1 = {
              ...passObject,
              ...obj
            };
            exportData.push(obj1);
          } else {
            passObject[key] = node.data[key];
          }
        });
        // exportData.push(passObject);
      });


    } else {
      this.gridOption.columnDefs.forEach(header => {
        if (header.hasOwnProperty('children')) {
          header.children.forEach(each_child => {
            if (each_child.hide === undefined || each_child.hide === false) {
              headers.push(each_child.headerName);
              fields.push(each_child.field);
            }
          });
        } else {
          if (header.hide === undefined || header.hide === false) {
            headers.push(header.headerName);
            fields.push(header.field);
          }
        }
      });

      let passObject = {};
      const filteredRowData =  this.gridOption.api.rowModel.rowsToDisplay;
      filteredRowData.forEach(node => {
        passObject = {};
        fields.forEach(key => {
          if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
            passObject[key] = node.data[key][Object.keys(node.data[key])[0]];
          } else {
            passObject[key] = node.data[key];
          }
        });
        exportData.push(passObject);
      });
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: this.title,
      useBom: true,
      noDownload: false,
      headers
    };

    new ngxCsv(exportData, this.title, options);
  }

}
