import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-claim-scheme-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class LoyaltyProgramTrackerComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = false;
  // customFilter: any = {
  //   name: 'Sale Type',
  //   key: 'type',
  //   value: 'P',
  //   values: [],
  //   show: true
  // };
  setTitle() {
    this.title = 'Loyalty Program Tracker';
  }

  ngOnInit() {
    this.sharedService.setHeader('Loyalty Program Tracker');
    this.statusConfig.success = 'View';
    this.statusConfig.error = 'Not Enrolled';
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/getLoyaltyPrograms';

    this.columnDefs = [
      {headerName: 'Campaign', field: 'c_name', width: 100},
      {headerName: 'Campaign Code', field: 'c_code', width: 100},
      {headerName: 'Objective', field: 'objective', width: 100},
      {headerName: 'Campaign Type', field: 'c_type', width: 100},
      {headerName: 'Name', field: 's_name', width: 100},
      {headerName: 'Code', field: 's_code', width: 100},
      {headerName: 'Description', field: 'description', hide: true, width: 100},
      {headerName: 'Type', field: 's_type', width: 100},
      {headerName: 'Start Date', field: 's_date', width: 100},
      {headerName: 'End Date', field: 'e_date', width: 100},
      {headerName: 'Enrolled Partners', field: 'o_cnt', width: 100},
      {headerName: 'Measure', field: 'm_type', width: 100},
      {headerName: 'Target', field: 'target', width: 100},
      {
        headerName: 'View Details', field: 'o_cnt', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.o_cnt > 0) {
              return 'View';
            } else {
              return 'Not Enrolled';
            }
          }
        }, cellRenderer: this.sharedService.statusCellRenderer
      }
    ];
  }

  onCellClicked(event) {
    if (event.colDef.headerName === 'View Details' && event.data.s_id && event.data.o_cnt > 0) {
      this.redirectToDetailedReport(event.data.s_id, event.data.c_id);
      console.log("Redirecting with Scheme - " + event.data.s_id + " and Campaign - " + event.data.c_id);
    }
  }

  private redirectToDetailedReport(scheme_id, campaign_id) {
    localStorage.setItem('loyalty_pgm_id', scheme_id);
    localStorage.setItem('loyalty_pgm_campaign', campaign_id);

    this.router.navigate([this.apiService.redirect_url + '/reports/loyalty_program_details'], {
      relativeTo: this.route,
      queryParams: {loyalty_pgm_id: scheme_id, loyalty_pgm_campaign: campaign_id}
    });
  }
}
