import {Component, OnInit} from '@angular/core';
import {ReportBase} from './_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: './_common/reportbase.html',
  styleUrls: ['./_common/reportbase.scss']
})

export class RetailerSKUOfferReportComponent extends ReportBase implements IReport, OnInit  {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Retailer SKU Offer Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getRetailerSKUOfferReport';

    this.columnDefs = [
      { headerName: "RETAILER",  field: "retailer",menuTabs: [], width: 100},
      { headerName: "DATE",  field: "completion_date",menuTabs: [], width: 100},
      { headerName: "RUNNING PROMO",  field: "question",menuTabs: [], width: 250},
      { headerName: "ANSWER",  field: "answer_text",sortable: true,menuTabs: [], width: 100},
      { headerName: "SURVEY",  field: "survey",menuTabs: [], width: 100},
      { headerName: "USER",  field: "user",menuTabs: [], width: 100},
      { headerName: "QUESTION CODE",  field: "default_code", hide:true,menuTabs: [], width: 100},
      { headerName: "REMARKS",  field: "remarks",menuTabs: [], width: 100},
    ];

  }

}
