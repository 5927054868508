import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VanSaleSummaryReportComponent extends ReportBase implements IReport, OnInit {

  uniqueproducts;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Sale Summary Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.API_URL = '/api/pwa_reports/getVanSaleSummary';
  }

  configureGrid() {
    this.uniqueproducts = this.unique(this.apiData, ['prod_id']);
    const inThis = this;
    this.columnDefs = [
      {headerName: 'VAN', field: 'user_name', width: 80},
      {headerName: 'O Code', field: 'o_code', width: 80},
      {headerName: 'Outlet', field: 'outlet', width: 80},
    ];

    //const uniqueproducts = this.unique(this.apiData, ['prod_id']);

    /*for (const prod of uniqueproducts) {
      if (prod['user_id'] && prod['partner_id']) {
        this.columnDefs.push(
          {
            headerName: prod['p_name'], field: prod['p_name'], width: 80
          },
        );
      }
    }*/
    if (this.uniqueproducts && this.uniqueproducts.length > 0) {
      for (let i = 0; i < this.uniqueproducts.length; i++) {
        this.columnDefs.push(
          {
            headerName: this.uniqueproducts[i]['p_name'], field: this.uniqueproducts[i]['p_name'], width: 80
          },
        );
      }
    }


    this.columnDefs.push(
      {headerName: 'Sales', field: 'total_sales', width: 80},
      {
        headerName: 'Cash', field: 'cash', width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.cash) {
              return parseFloat(params.data.cash).toFixed(2);
            }
          }
        }
      },
      {headerName: 'Cheque', field: 'cheque', width: 80},
      {headerName: 'Approved Cheque', field: 'approved_cheque', width: 80},
      {headerName: 'Balance', field: 'balance', width: 80},
    );

  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const uniquedata = this.unique(this.apiData, ['user_id', 'partner_id']);
    if (uniquedata && uniquedata.length > 0) {
      for (const item of uniquedata) {
        const indrow: any = {};
        if (item['user_id'] && item['partner_id']) {
          const vandata = this.apiData.filter(x => x.user_id === item['user_id'] && x.partner_id === item['partner_id']);
          if (vandata && vandata.length > 0) {
            indrow.user_name = vandata[0].user_name;
            indrow.o_code = vandata[0].o_code;
            indrow.outlet = vandata[0].outlet;
            indrow.total_sales = vandata[0].total_sales;
            indrow.cash = vandata[0].cash;
            indrow.cheque = vandata[0].cheque;
            indrow.approved_cheque = vandata[0].approved_cheque;
            indrow.balance = vandata[0].balance;
            indrow.user_id = vandata[0].user_id;
            indrow.partner_id = vandata[0].partner_id;
            /* for (let i = 0; i < vandata.length; i++) {
               indrow['prod_' + i] = parseFloat(vandata[i].p_sales);
             }*/
            /*for (const prod of vandata) {
              //indrow[prod.user_id + '_' + prod.partner_id + '_' + prod.prod_id] = parseFloat(prod.p_sales);
              for (let i = 0; i < this.uniqueproducts.length; i++) {
                if (prod.prod_id === this.uniqueproducts[i]['prod_id']) {
                  indrow['prod_' + i] = parseFloat(prod.p_sales);
                }
              }
            }*/
            for (const prod of vandata) {
              indrow[prod.p_name] = parseFloat(prod.p_sales);
            }
          }
          this.rowData.push(indrow);
        }
      }
    }
    console.log(this.rowData);
  }

}
