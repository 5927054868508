<div class="dash-card">

<!--    <a class="ui left corner label">-->
<!--        <i class="circle icon" [ngClass]="{'green':inRun, 'yellow':!inRun}"></i>-->
<!--    </a>-->

    <app-gadget-header
            (mouseover)="showGadgetControls(true)"
            (mouseleave)="showGadgetControls(false)"
            [globalOptions]="globalOptions"
            [title]="title"
            [inRun]="inRun"
            [inConfig]="inConfig"
            [actionInitiated]="actionInitiated"
            [showOperationControls]="showOperationControls"
            [gadgetHasOperationControls]="gadgetHasOperationControls"
            [showConfigurationControl]="showConfigurationControl"
            [showControls]="showControls"
            [showDeleteControl]="showDeleteControl"
            (toggleConfigModeEvent)="toggleChartProperties()"
            (removeEvent)="remove()"
            (runEvent)="run()"
            (stopEvent)="stop()">
    </app-gadget-header>
    <div *ngif="!inRun" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div>
        <div *ngIf="!inConfig && pieChartType == 'pieChart'" style="min-height: 300px">
          <ngx-charts-pie-chart
            [scheme]="colorScheme"
            [results]="pieData"
            [gradient]="gradient"
            [legend]="showLegend"
            [legendPosition]="legendPosition"
            [labels]="showLabels"
            [doughnut]="isDoughnut"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-pie-chart>
        </div>
        <div *ngIf="!inConfig && pieChartType == 'pieChartAdvanced'" style="min-height: 300px">
          <ngx-charts-advanced-pie-chart
            [scheme]="colorScheme"
            [results]="pieData"
            [gradient]="gradient"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-advanced-pie-chart>
        </div>


    </div>

    <!-- use the side bar content -->
    <div *ngIf="globalOptions.displayGadgetOptionsInSideBar == true" class="ui sidebar left vertical menu"
         #chartOptionsSideBar_tag>
        <br>
        <div class="ct-title">{{title}}</div>
        <br>
        <app-dynamic-form
                [gadgetTags]="gadgetTags"
                [propertyPages]="propertyPages"
                [instanceId]="instanceId"
                (updatePropertiesEvent)="updateProperties($event)">
        </app-dynamic-form>
    </div>


    <app-dynamic-form *ngIf="inConfig && globalOptions.displayGadgetOptionsInSideBar == false"
                      [gadgetTags]="gadgetTags"
                      [propertyPages]="propertyPages"
                      [instanceId]="instanceId"
                      (updatePropertiesEvent)="updateProperties($event)">
    </app-dynamic-form>

    <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
    </app-error-handler>

<!--    <app-vis-drill-down-modal #drillDownPopUp></app-vis-drill-down-modal>-->

</div>


