import { PageBase } from "../../pagebase/pagebase";
import { Component, OnInit } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { ErrorHandler } from "../../error/error-handler";
import { ErrortooltipComponent } from "../../errortooltip";
import { OutstandingUploadComponent } from "./upload/outstanding_upload.component";
// import {SupplierCreatePopupComponent} from "./create_popup_suppliers/supplier_create_popup.component";
import { RetailerAttributeUploadComponent } from "./upload/retailer_attribute_upload.component";
import { BulkSuppliersUploadComponent } from "./upload/bulk_suppliers_upload.component";


@Component({
    selector: 'app-dms-supplier-list',
    templateUrl: '../../pagebase/pagebase.html',
    styleUrls: ['../../pagebase/pagebase.scss']
})

export class SupplierComponent extends PageBase implements OnInit, IPage {
    customFilter: any = {
        name: 'Active',
        key: 'status',
        value: 'active',
        values: [],
        show: true
    };
    supplier_ids = [];
    matActionMenuItemDeclaration = [{ name: 'Upload Supplier Attributes', feature: 'feature_create_retailer' },
    { name: 'Upload Outstanding', feature: 'feature_upload_outstanding' },
    ];

    ngOnInit(): void {
        this.sharedService.setHeader('Suppliers');
        this._localCacheService.getAccessControlList().then(list => {
            this.showCreate = list['feature_create_retailer'];
            this.showUpload = list['action_beat_plan_create'];
        });
        this.customFilter.values = [
            { id: 'all', itemName: 'All' },
            { id: 'active', itemName: 'Active' },
            { id: 'inactive', itemName: 'InActive' },
        ];
        this.checkAndLoadPreviousSate();
        this.showCustomFilter = true;
        this.showActionButton = true;
        this.showDate = false;

        this.gridOptions.getRowStyle = this.getRowStyleFunc;
        this.frameworkComponents = {
            errortooltipComponent: ErrortooltipComponent
        };
        this.showPinnedFooter = false;
        this.columnsWithAggregation = ['amount_untaxed'];
        this.getAuthorizedActions();
        this.checkAccessControl();
        this.configureGrid();
        this.loadServerData();
    }

    getRowStyleFunc(params) {
        if (params.data && params.data.id === 'TOTAL') {
            return { 'font-weight': 'bold' };
        }
    }

    configureGrid() {
        this.API_URL = '/api/pwa_admin_connects/get_supplier_detail_for_dms';

        const ActiveCellClassRules = {
            'rag-green-outer': (params) => params.value === 'true',
            // 'rag-amber-outer': (params) => params.value === 'confirmed',
            'rag-red-outer': (params) => params.value === 'false',
          };

        this.columnDefs = [
            { headerName: "id", field: "id", hide: true },
            { headerName: "Name", field: "name", width: 250, checkboxSelection: true, headerCheckboxSelection: true },
            { headerName: "Code", field: "code", width: 100 },
            { headerName: "Mobile", field: "mobile", width: 100 },
            { headerName: "Email", field: "email", width: 150 },
            { headerName: "GST Id", field: "gst_id", width: 80 },
            { headerName: "Type", field: "type", width: 100 },
            { headerName: "Outstanding", field: "outstanding", width: 100, cellRenderer: 'outstandingShowRenderer', cellStyle: { textAlign: 'right' }, valueFormatter: params => this.formatNumber(params.value) },
            { headerName: "Credit Limit", field: "credit_limit", width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => this.formatNumber(params.value) },
            { headerName: "Address", field: "address", width: 200 },
            { headerName: "Partner Type", field: "partner_type", width: 100 },
            { headerName: "Pricelist ID", field: "pricelist_id", width: 100, hide: true },
            { headerName: "Pricelist Name", field: "pricelist_name", width: 250 },
            { headerName: "Secondary Pricelist ID", field: "sec_pricelist_id", width: 100, hide: true },
            { headerName: "Secondary Pricelist Name", field: "sec_pricelist_name", width: 250 },
            { headerName: "Zone ID", field: "zone_id", width: 100, hide: true },
            { headerName: "State ID", field: "state_id", width: 100, hide: true },
            { headerName: "Area ID", field: "area_id", width: 100, hide: true },
            { headerName: "Zone", field: "zone", width: 250 },
            { headerName: "State", field: "state", width: 250 },
            { headerName: "Area", field: "area", width: 250 },
            { headerName: "Territory", field: "territory_name", width: 250 },
            { headerName: "Territory ID", field: "territory_id", width: 100, hide: true },
            { headerName: "Tax Source", field: "tax_source", width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => this.formatNumber(params.value) }

        ];

        this.getRowId = (params) => params.data.id;

    }
    loadServerData() {
        this.goClicked = true;
        this.dataLoaded = false;
        this.preloader = true;
        this.emptyState = false;
        this.errorExists = false;
        this.pageData = [];
        this.selectedCount = 0;
        this.showApprove = false;

        if (this.API_URL === '') {
            return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
        }
        if (this.showCustomFilter) {
            if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
                this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
            }
        }
        if (this.customFilter1) {
            if (this.customFilter != null && !this.customFilter.hasOwnProperty(this.customFilter1.key)) {
                this.customFilter[this.customFilter1.key] = this.customFilter1.value;
            }
        }

        this.apiService.post(this.API_URL, this.userData)
            .subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res.results.status === 200)) {
                    this.apiData = res.results.data;
                    this.storeSupplierdata(this.apiData);
                    this.processData(this.apiData);
                } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
                    this.emptyState = true;
                    if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                        console.error(res.results.errmsg);
                    }
                    this.handleError(ErrorHandler.getErrorObject(res.results.errmsg));
                } else {
                    this.emptyState = true;
                    if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                        console.error(res.results.errmsg);
                    }
                    this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
                }
            },
                error => this.handleError(ErrorHandler.getErrorObject(error)));

    }
    processData(apiData) {
        if (!Array.isArray(apiData)) {
            console.warn('apiData is not an array, wrapping it in an array:', apiData);
            apiData = [apiData];
        }
        this.rowData = apiData;

        this.preloader = false;
        if (this.rowData === null || this.rowData === undefined) {
            this.emptyState = true;
        } else if (this.rowData.length === 0) {
            this.emptyState = true;
        } else {
            this.dataLoaded = true;
        }
        return;
    }
    storeSupplierdata(data) {
        const ids = [];
        this.supplier_ids = ids;
        localStorage.setItem('supplierdata', JSON.stringify(ids));
    }
    showUploadPopup() {
        this.uploadBulkSuppliersClick();
    }
    uploadBulkSuppliersClick() {
        let creatediaeref;
        if(screen.width>991) {
            const config: MatDialogConfig = {
                width: "100vw",
                height: "95vh",
                maxWidth: "90%",
                maxHeight: "100%",
                panelClass: 'custom-dialog-container',
            };
            creatediaeref = this.dialog.open(BulkSuppliersUploadComponent, config);
        } else {
            const config: MatDialogConfig = {
                width: "100vw",
                height: "100vh",
                maxWidth: "90%",
                maxHeight: "65%",
                panelClass: 'custom-dialog-container',
            };
            creatediaeref = this.dialog.open(BulkSuppliersUploadComponent, config);
        }
    }

}

