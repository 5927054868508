import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";
import {PartnerMeetPopupComponent} from "../../components/partner_meet_popup/partner_meet_popup.component";

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class MeetAttendeesReportComponent extends ReportBase implements IReport, OnInit {

  maxImages;
  maxGuests;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Meet Attendees Report');
    this.showableFilters.salesman = true;
    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.container = 'meet_gallery';

    /*this.showViewSelector = true;
    this.showGalleryview = true;
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'store_fname',
      name: 'name',
      field1: 'user',
      field2: 'att_partner',
      field3: 'type_name',
      status: 'status',
      statusTypes: [
        {name: "started", type: "new"},
        {name: "scheduled", type: "pending"},
        {name: "completed", type: "success"},
        {name: "cancelled", type: "error"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['completed']
        },
        {
          field: 'reviewed',
          matches: true,
          values: ['new']
        }
      ]
    };*/
    this.userData.offsetID = 0;
    this.userData.offset = 50000;
    this.loadInBatches(50000, 'rank');
    this.showProgressCustomText = true;

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/attendeesMeetReport';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'STATE', field: 'state', width: 80},
          {headerName: 'CLUSTER', field: 'cluster', width: 80},
          {headerName: 'TERRITORY', field: 'territory', width: 80},
          {headerName: 'MEET ID', field: 'meet_id', width: 80},
          {headerName: 'SUBJECT', field: 'name', width: 150},
          {headerName: 'PLANNED DATE', field: 'planned_date', width: 100},
          {
            headerName: 'START TIME', field: 'start_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.start_time) {
                  return inThis.datePipe.transform((new Date(params.data.start_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.start_time;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'END TIME', field: 'end_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.end_time) {
                  return inThis.datePipe.transform((new Date(params.data.end_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.end_time;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'MEET TYPE', field: 'type', width: 80},
          {headerName: 'SCODE', field: 'emp_code', width: 50},
          {headerName: 'SALESMAN', field: 'user', width: 80},
          {headerName: 'ROLE', field: 'user_role', width: 50},
          {headerName: 'TEAM', field: 'team', width: 80},
          {
            headerName: 'LOCATION', field: 'location', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return 'http://maps.google.com/maps?q=' + params.data.lat + ',' + params.data.lng;
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                } else {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                }
              }
            }
          },
          {headerName: 'ADDRESS', field: 'address', width: 150, valueGetter: this.addressValueGetter()},
          {headerName: 'DESCRIPTION', field: 'description', width: 100},
          {headerName: 'STATUS', field: 'status', width: 80}
        ]
      },
      {
        headerName: 'IMAGES',
        children: []
      },
      {
        headerName: 'ATTENDEES',
        children: [
          {
            headerName: 'PARTNER TYPE', field: 'partner_type', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.partner_type) {
                  return params.data.partner_type;
                } else {
                  if (params.data.ma_config) {
                    try {
                      let config_json;
                      if (params.data.ma_config.constructor !== Object) {
                        config_json = JSON.parse(params.data.ma_config);
                      } else {
                        config_json = params.data.ma_config;
                      }
                      if (config_json && config_json.hasOwnProperty('lead_type')) {
                        return config_json['lead_type'];
                      }
                    } catch (e) {
                    }
                  } else {
                    return '';
                  }
                }
              }
            }
          },
          {
            headerName: 'PROSPECT STATUS', field: 'config_json', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.ma_config) {
                    try {
                      let config_json;
                      if (params.data.ma_config.constructor !== Object) {
                        config_json = JSON.parse(params.data.ma_config);
                      } else {
                        config_json = params.data.ma_config;
                      }
                      if (config_json && config_json.hasOwnProperty('marked_lost')) {
                        return 'LOST';
                      } else if (config_json && config_json.hasOwnProperty('converted')) {
                        return 'WON';
                      }
                    } catch (e) {
                    }
                  } else {
                    return '';
                  }
              }
            }
          },
          {headerName: 'PARTNER CODE', field: 'att_code', width: 80},
          {headerName: 'PARTNER', field: 'att_partner', width: 150},
          {headerName: 'MOBILE', field: 'att_mobile', width: 60},
          {headerName: 'TYPE', field: 'role', width: 80},
          {headerName: 'EXP ORDER', field: 'exp_order', width: 80},
          {
            headerName: 'ATTENDANCE', field: 'attendance', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (!params.data.attendance) {
                  return false;
                } else {
                  return params.data.attendance;
                }
              }
            }
          },
        ]
      }, {
        headerName: 'GUESTS',
        children: []
      }
    ];

    if (this.maxImages > 0) {
      for (let i = 0; i < this.maxImages; i++) {
        this.columnDefs[1].children.push(
          {
            headerName: "Image " + (i + 1), field: 'img_' + i, width: 80, valueGetter: this.generateImageUrl('img_' + i),
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['img_' + i] || params.data['img_' + i] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
        );
      }
    }
    if (this.maxGuests > 0) {
      for (let i = 0; i < this.maxGuests; i++) {
        this.columnDefs[3].children.push(
          {headerName: "Name " + (i + 1), field: 'guest_name' + i, width: 80},
          {headerName: "Mobile " + (i + 1), field: 'guest_mob' + i, width: 80},
        );
      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.maxImages = 0;
    this.maxGuests = 0;
    this.apiData.forEach(obj => {
      if (obj.ma_config && obj.ma_config.constructor !== Object) {
        obj.ma_config = JSON.parse(obj.ma_config);
      }
      if (obj.ma_config && obj.ma_config.hasOwnProperty('members') && obj.ma_config.members && obj.ma_config.members.length > 0) {
        if (obj.ma_config.members.length > this.maxGuests) {
          this.maxGuests = obj.ma_config.members.length;
        }
        for (let i = 0; i < obj.ma_config.members.length; i++) {
          obj['guest_name' + i] = obj.ma_config.members[i].name;
          obj['guest_mob' + i] = obj.ma_config.members[i].phoneNumber;
        }
      }
      if (obj.images) {
        if (obj.images.length > this.maxImages) {
          this.maxImages = obj.images.length;
        }
        for (let i = 0; i < obj.images.length; i++) {
          obj['img_' + i] = obj.images[i];
        }
      }
    });
    this.rowData = this.apiData;
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column === 'location' && rows[0].hasOwnProperty('lat') && rows[0].lat != null && rows[0].lat !== '') {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '70%',
          maxHeight: '95%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '65%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      }
    } else if (column.includes('img')) {
      //rows[0].store_fname = '964/9640c96d933643095139096372fda9ed189a8ff360c5bca0b4bc9f7d0ba661c1'

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column],
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column],
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }
  }

  configValueGetter(field) {
    return (params) => {
      if (params.data && params.data.ma_config) {
        try {
          let config_json;
          if (params.data.ma_config.constructor !== Object) {
            config_json = JSON.parse(params.data.ma_config);
          } else {
            config_json = params.data.ma_config;
          }
          if (config_json && config_json.hasOwnProperty(field)) {
            return config_json[field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  addressValueGetter() {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty('address')) {
            return config_json['address'];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  giftValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          const config_json = JSON.parse(params.data.config_json);
          if (config_json && config_json.hasOwnProperty('gift_json')) {
            return config_json['gift_json'][0][field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  expenseValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.expense_json) {
        try {
          const expense_json = JSON.parse(params.data.expense_json);
          return expense_json[0][field];
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "33%",
        maxHeight: "94%",
        data: {
          taskDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(PartnerMeetPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "95%",
        maxHeight: "90%",
        data: {
          taskDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(PartnerMeetPopupComponent, config);
    }
  }


}
