<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Assign Vendor</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div class="cardbox" style="width: 100%">
          <span class="headerspan">Asset Details</span>
          <div class="cardgriddash">
            <div>
              <label class="headerlabel">Total Assets</label>
              <span class="headerspan">{{totalAssets}}</span>
            </div>
            <div>
              <label class="headerlabel">Total Brands</label>
              <span class="headerspan">{{totalBrands}}</span>
            </div>
            <div>
              <label class="headerlabel">Total Qty</label>
              <span class="headerspan">{{totalQty}}</span>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="dialoglabel">PO Number</span>
          <input
            class="forminputwhitebg"
            style="display: block; font-size: 14px;margin-top: 5px;width: 100%;"
            type="text"
            name="po"
            [(ngModel)]="vendorDetails.po_no"
            #po="ngModel"
            placeholder="Enter PO Number"
          >
        </div>
        <div style="margin-top: 20px;">
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">PO Date</span>
              <div class="date-picker-form">
                <input [selectMode]="'single'"
                       [readonly]="true" [(ngModel)]="vendorDetails.po_date"
                       [owlDateTimeTrigger]="dt"
                       [owlDateTime]="dt" placeholder="Date"
                       name="date"
                       #date="ngModel"
                       style="margin-top: 5px;width: 100%;">
                <div style=" position: absolute;right: 20px;top: 12px;">
                  <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
                </div>
                <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
              </div>
            </div>
            <div class="col-md-6 pl-0 pr-0 mt-0">
              <span class="dialoglabel">PO Amount</span>
              <input
                class="forminputwhitebg"
                style="display: block;  font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="amount"
                [(ngModel)]="vendorDetails.po_amount"
                #amount="ngModel"
                placeholder="Enter PO Amount"
              >
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="dialoglabel">Vendor</span>
          <span class="requiredspan"> *</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="vendorList"
                       bindLabel="user_name"
                       bindValue="id"
                       class="custom"
                       [(ngModel)]="vendorDetails.vendor_id">
              >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="position: absolute;  right: 0px; margin-top: 20px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="assignVendor()"
      >
        Assign
      </button>
    </div>
  </div>
</section>
