
<div class="ui fluid transparent icon input" [ngClass]="{inverted:typeAheadIsInMenu == true}">
    <input type="text" width="100%" placeholder={{placeHolderText}} [(ngModel)]=query (keyup)=filter()>
    <i *ngIf="!query"  class="large grey search icon"></i> <i *ngIf="query"  class="large grey remove link icon" (click)="query=''"></i>
</div>

<div class='suggestions' *ngIf='filteredList.length > 0'>

    <ul style='list-style-type:none; background: white; border-radius: 7px;'>
        <li style='padding:5px' *ngFor='let item of filteredList'>
            <a (click)='select(item)'>{{item}}</a>
        </li>
    </ul>
</div>
