import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {ErrorHandler} from "../../error/error-handler";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-stock-availability-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DCStockReportComponent extends ReportBase implements IReport, OnInit  {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('DC Stock Report');

    this.showableFilters.territory = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDCStock';

    this.columnDefs = [
      { headerName: 'REGION', field: 'region', width: 80},
      { headerName: 'CLUSTER', field: 'cluster', width: 80},
      { headerName: 'TERRITORY', field: 'territory', width: 80},
      { headerName: 'DC', field: 'dc', width: 80},
      { headerName: 'PCODE', field: 'pcode', width: 80},
      { headerName: 'PRODUCT', field: 'product', width: 80},
      { headerName: 'MRP', field: 'mrp', width: 80},
      { headerName: 'TAX', field: 'tax', width: 80},
      { headerName: 'QTY', field: 'qty', width: 80},
    ];
  }
}
