import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-donut',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
      <div style="height: 78%; width: 100%; margin-top: 10px;">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="widget.data.value"
          [gradient]=true
          [legend]=true
          [legendTitle]="''"
          [legendPosition]="'below'"
          [labels]=true
          [doughnut]=true
        >
          <ng-template #tooltipTemplate let-model="model">
            <div style="padding: 4px;">{{ model.name}}</div>
            <div style="padding-bottom: 4px;font-size: 16px;">{{ formatDataLabel(model.value)}}</div>
          </ng-template>
        </ngx-charts-pie-chart>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DonutWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;
  colorScheme: any = {
    domain: ['#0762AD', '#E24260', '#379862', '#F6A82C']
  };

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    /*if (this.widget?.data?.value && this.widget.data.value.length > 0) {
      this.widget.data.value.forEach(value => {
        value.tooltip_name = this.formatDataLabel(value.value);
      });
      if (this.widget?.data?.value_type === 'currency') {
        console.log(this.widget);
      }

    }*/
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }
  formatDataLabel(value) {
    if (value) {
      if (this.widget?.data?.value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else {
        return value;
      }
    } else {
      return 0;
    }
  }

}
