import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from "../_services/api.service";
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ReportDropdownComponent} from "../components/report_dropdown/report-dropdown.component";
import {SupplierDropdownComponent} from "../components/supplier_dropdown/supplier-dropdown.component";
import {SuppProdDropdownComponent} from "../components/supp_prod_dropdown/supp-prod-dropdown.component";
import {AdDirective} from "../ad.directive";
import {AdDirective1} from "../ad1.directive";
import {AdDirective2} from "../ad2.directive";
import {SharedService} from "../_services/shared_service";
import {SerialNoRenderer} from "../serialNo.component";
import {CellEdit2Renderer} from "../editCell2.component";
import {PriceCellRendererComonent} from "../priceCellRenderer.comonent";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {POExcelUploadComponent} from "../admin/upload/POExcelUploadComponent";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-new_po_page',
  templateUrl: './new_po_page.component.html',
  styleUrls: ['./new_po_page.component.scss']
})
export class New_po_pageComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective1, {static: true}) adHost1: AdDirective1;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild('serial') serial: ElementRef<HTMLElement>;
  @ViewChild('serialIn') serialIn: ElementRef<HTMLElement>;

  user_data = {
    "date": null,
    "dc_id": 0,
    "supplier_id": null,
    "access_token": null,
    "url": null,
    "offset": null,
    "start_date": '2020-10-15',
    "end_date": '2020-10-15',
    "po_data": null,
    "customer_id": null
  };
  res_data;
  public dashDisplay = false;
  public cardData = {
    'total_vans': 0,
    'loaded_out': 0,
    'deviation_count': 0,
    'settled': 0,
    'not_loaded': 0,
    'total_expense': 0,
    'unapproved_orders': '...',
    'cash_collected': '...',
    'pay_amount': '...',
    'credit_sales': '...'
  };
  public preloader = false;
  public role_features;
  public state;
  public expense = false;
  public settlemnt_dash = false;
  public dc_dash = false;
  public rowData = [];
  public chart_show = false;
  private gridApi;
  private gridColumnApi;
  public frameworkComponents;
  public context;
  public columnDefs = [];
  public data;
  public parent_po;
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader1 = false;
  public empty_state = false;
  public browserRefresh = false;
  public subscription;
  public last_updated;
  public entered_qty = 0;
  public serl_arr = [];
  public sernos = {};
  public serial_count = 0;
  public line_id;
  public invoice_no = '';

  public order_no = null;
  public dc_id = null;
  public supplier_mail = null;
  public dc_name = null;
  public note = null;
  public supplier_tax = null;
  public prod_show = false;
  public add_prod = true;
  public supplier_products = [];
  public product_id = null;
  public product_name = null;
  public uom_name = null;
  public uom_id = null;
  public stock = 0;
  public tax = null;
  public po_date = null;
  public expiry_date = null;
  public due_date = null;
  public supplier_name = null;
  public supplier_id = null;
  public d_status = null;
  public price = null;
  feature_dispatch = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private router: Router,
              public sharedService: SharedService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.po_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.expiry_date = this.po_date;

    this.user_data.date = this.po_date;
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    this.preloader = true;
    this.last_updated = localStorage.getItem('last_updated');
    this.sharedService.gotoGrv.active = false;
    this.sharedService.gotoGrv.po = '';
    this.sharedService.gotoGrn.active = false;
    this.sharedService.gotoGrn.po = '';
    this.sharedService.gotoPO = {active: false, po: ''};
    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_purchase_order_dispatch')) {
        this.feature_dispatch = true;
      }
    }

    if (this.sharedService.new) {
      this.productEntry([], this.sharedService.new, false, this.sharedService.flag);
    } else {
      this.sharedService.flag = 'new';
      if (!this.sharedService.order_id) {
        this.sharedService.order_id = localStorage.getItem('purchase_order_id');
      }
      this.orderDetails();
    }
    // this.vanreport();

    this.gridOptions = <GridOptions> {
      rowHeight: 35,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    };
  }

  savePO() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    let po_data = {
      "dc_id": this.dc_id,
      "supplier_id": this.user_data.supplier_id,
      "po_date": this.po_date,
      "note": this.note,
      "lines": []
    };

    let line_data = [];

    this.rowData.forEach(function(line) {
      line_data.push({
        product_id: line.product_id,
        qty: line['QTY'],
        price: line['PRICE'],
        serialData: line['serialData']
      });
    });

    po_data.lines = line_data;
    this.user_data.po_data = po_data;

    this.res_data = this.apiService.poCreation(this.user_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
        window.alert("Creation Successfull");
        this.sharedService.new = false;
        this.sharedService.order_id = res['results'].id;
        this.sharedService.mark_receieve = false;
        this.sharedService.is_receipt = false;
        this.sharedService.is_return = false;
        this.sharedService.flag = 'same';
        this.orderDetails();
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        window.alert("Creation failed");
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      console.log(error);
      if (error.error && error.error.error === undefined) {
        window.alert("Creation failed");
      } else {
        window.alert("Creation failed: " + error.error.error.message);
      }
    });
  }

  orderDetails() {

    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "offset": this.user_data.offset,
      "order_id": [this.sharedService.order_id]
    };

    if (this.sharedService.is_receipt || this.sharedService.is_return) {
      this.res_data = this.apiService.getPEDetails(order_data);
    } else {
      this.res_data = this.apiService.getPODetails(order_data);
    }


    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        let data = res['results'].data[0];
        this.order_no = data.name;
        this.po_date = this.datePipe.transform(data.date, 'yyyy-MM-dd');
        this.dc_name = data.customer;
        this.dc_id = data.dc_id,
          this.note = data.notes;
        this.supplier_name = data.supplier;
        this.supplier_mail = data.supplier_email;
        this.d_status = data.d_status;
        this.state = data.state;
        this.invoice_no = data.invoice_no;
        this.parent_po = data.po;

        this.sharedService.new = false;
        this.sharedService.mark_receieve = false;
        this.sharedService.delivered_po = false;
        this.sharedService.is_return = false;
        this.sharedService.order_type = data.entry_type;
        this.sharedService.order_id = data.id;
        localStorage.setItem('purchase_order_id', data.id);

        let order_lines = data.sale_items;
        let sernos = {};
        order_lines.forEach(function(line) {
          sernos[line.id] = line.sers;
        });
        this.sernos = sernos;
        if (this.d_status === 'partial' || this.d_status === 'new' || this.d_status === 'dispatched') {
          this.sharedService.mark_receieve = true;
        } else if (this.d_status === 'delivered') {
          this.sharedService.delivered_po = true;
        }
        if (this.d_status === 'new') {
          this.d_status = 'draft';
        }
        if (this.d_status === 'partial') {
          this.d_status = 'partially delivered';
        }
        if (data.order_type === 'return') {
          if (data.state === 'confirmed') {
            this.sharedService.is_receipt = true;
          } else if (data.state === 'draft') {
            this.sharedService.is_return = true;
          }
        }
        this.productEntry(order_lines, this.sharedService.new, this.sharedService.mark_receieve, this.sharedService.flag);
      }
    }, error => {

    });
  }

  gotoGrv() {
    this.sharedService.gotoGrv.active = true;
    this.sharedService.gotoGrv.po = this.order_no;
    this.router.navigate([this.apiService.redirect_url + "/returns"]);
  }

  gotoGrn() {
    this.sharedService.gotoGrn.active = true;
    this.sharedService.gotoGrn.po = this.order_no;
    this.router.navigate([this.apiService.redirect_url + "/receipts"]);
  }

  gotoPO() {
    this.sharedService.gotoPO.active = true;
    this.sharedService.gotoPO.po = this.parent_po;
    this.router.navigate([this.apiService.redirect_url + "/po_po"]);
  }

  saveProduct() {
    let total = parseFloat(this.price) * parseFloat(this.entered_qty.toString());
    total = total * ((100 + parseFloat(this.tax)) / 100);
    this.rowData.push({
      "PRODUCT": this.product_name,
      "STOCK": this.stock,
      "UOM": this.uom_name,
      "QTY": this.entered_qty,
      "PRICE": parseFloat(this.price).toFixed(this.apiService.decimalPolicy),
      "TAX": parseFloat(this.tax).toFixed(this.apiService.decimalPolicy),
      "TOTAL": total.toFixed(this.apiService.decimalPolicy),
      "product_id": this.product_id,
      "Serial NO": "",
    });
    this.gridApi.setRowData(this.rowData);
  }

  addProduct() {
    this.prod_show = true;
    const viewContainerRef2 = this.adHost2.viewContainerRef;
    viewContainerRef2.clear();

    const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

    const componentRef2 = viewContainerRef2.createComponent<SuppProdDropdownComponent>(componentFactory2);
    componentRef2.instance.itemList = this.supplier_products;
    let price= 0;
    componentRef2.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        if ( res[0].hasOwnProperty('price') && res[0].price) {
          price = parseFloat(res[0].price);
        }
        this.product_name = res[0].itemName.split('/')[2];
        this.product_id = res[0].id;
        this.uom_name = res[0].uom;
        this.uom_id = res[0].uom_id;
        this.stock = res[0].stock;
        this.tax = res[0].tax;
        this.entered_qty = 0;
        this.price = price;
      }
    });
  }

  removeProduct(index) {
    this.rowData.splice(index, 1);
    this.gridApi.setRowData(this.rowData);
  }

  supplierList(suppliers) {

    const viewContainerRef1 = this.adHost1.viewContainerRef;
    viewContainerRef1.clear();

    const componentFactory1 = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);

    const componentRef1 = viewContainerRef1.createComponent<SupplierDropdownComponent>(componentFactory1);
    componentRef1.instance.itemList = suppliers;

    componentRef1.instance.statusChange.subscribe(res => {

      if (res.length > 0) {
        this.supplier_mail = res[0].email;
        this.supplier_tax = res[0].gstin;
        this.user_data.supplier_id = res[0].id;
        this.user_data.customer_id = this.dc_id;
        this.res_data = this.apiService.primarysupplierProducts(this.user_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {

            this.supplier_products = res['results'].data[0].products;
            this.addProduct();
          }
        });
      } else {
        this.supplier_mail = "";
        this.supplier_tax = "";
        this.rowData = [];
        this.gridApi.setRowData(this.rowData);
        this.prod_show = false;
      }

    });
  }

  dcList() {

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDropdownComponent);

    const componentRef = viewContainerRef.createComponent<ReportDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.sharedService.po_dc_id;

    componentRef.instance.selectedItems = [this.sharedService.po_dc_id[this.sharedService.po_dc_id.length - 1]];
    this.dc_name = componentRef.instance.selectedItems[0].itemName;
    this.dc_id = componentRef.instance.selectedItems[0].id;
    componentRef.instance.statusChange.subscribe(res => {

      if (res.length > 0) {
        this.dc_id = res[0].id;
        this.dc_name = res[0].itemName;
      } else {
        this.dc_id = null;
        this.dc_name = null;
      }

    });
    this.user_data.dc_id = this.dc_id;
    this.res_data = this.apiService.getSuppliers(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {

        var suppliers = res['results'].data[0].suppliers;
        this.supplierList(suppliers);
        // const viewContainerRef1 = this.adHost1.viewContainerRef;
        // viewContainerRef1.clear();
        //
        // const componentFactory1 = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);
        //
        // const componentRef1 = viewContainerRef1.createComponent<SupplierDropdownComponent>(componentFactory1);
        // componentRef1.instance.itemList = suppliers;
        //
        // componentRef1.instance.statusChange.subscribe(res => {
        //   debugger;
        //   this.supplier_mail = res[0].email;
        //   this.supplier_tax = res[0].gstin;
        //   this.user_data.supplier_id = res[0].id;
        //   this.res_data = this.apiService.supplierProducts(this.user_data);
        //   this.res_data.subscribe(res => {
        //     console.log(res);
        //     if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        //       debugger;
        //       this.supplier_products = res['results'].data[0].products;
        //       this.add_prod = false;
        //     }
        //   })
        //
        // });

        // componentRef1.instance.selectedItems = [suppliers[suppliers.length-1]];
      }
    });
  }

  productEntry(lines, nw, recv, flag) {
    this.loaded = false;
    this.preloader = true;
    let inthis = this;
    if (nw) {
      this.rowData = [];
      this.data = {
        "PRODUCT": "",
        "STOCK": "",
        "UOM": "",
        "QTY": "",
        "PRICE": "",
        "TAX": "",
        "TOTAL": "",
        "product_id": "",
        "Serial NO": "",
      };
    } else if (recv) {
      let rowData = [];
      if (flag === 'same') {
        this.columnDefs = [];
      }
      lines.forEach(function(line) {
        rowData.push({
          "PRODUCT": line.product,
          "STOCK": line.stock,
          "UOM": line.product_uom,
          "ORDERED": line.ordered,
          "RECEIVED": line.delivered,
          "RETURNED": line.returned,
          "QTY": 0,
          "PRICE": parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
          "TAX": parseFloat(line.tax).toFixed(inthis.apiService.decimalPolicy),
          "TOTAL": parseFloat(line.amount_total).toFixed(inthis.apiService.decimalPolicy),
          "Serial NO": "",
          "id": line.id,
          "product_id": line.product_id
        });
      });
      this.data = rowData[0];
      this.rowData = rowData;
    } else if (this.sharedService.is_receipt) {
      let rowData = [];
      if (flag === 'same') {
        this.columnDefs = [];
      }
      lines.forEach(function(line) {
        rowData.push({
          "PRODUCT": line.product,
          "STOCK": line.stock,
          "UOM": line.product_uom,
          "QTY": line.delivered,
          "PRICE": parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
          "TAX": parseFloat(line.tax).toFixed(inthis.apiService.decimalPolicy),
          "TOTAL": parseFloat(line.amount_total).toFixed(inthis.apiService.decimalPolicy),
          "Serial NO": "",
          "id": line.id,
          "product_id": line.product_id,
          "qty_check": line.delivered
        });
      });
      this.data = rowData[0];
      this.rowData = rowData;
    } else if (this.sharedService.is_return) {
      this.columnDefs = [];
      this.data = this.rowData[0];
      let rowData = [];
      lines.forEach(function(line) {
        rowData.push({
          PRODUCT: line.product,
          STOCK: line.stock,
          UOM: line.product_uom,
          QTY: line.delivered,
          PRICE: parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
          TAX: parseFloat(line.tax).toFixed(inthis.apiService.decimalPolicy),
          TOTAL: parseFloat(line.amount_total).toFixed(inthis.apiService.decimalPolicy),
          "Serial NO": "",
          id: line.id,
          product_id: line.product_id,
          qty_check: line.delivered
        });
      });
      if (rowData.length > 0) {
        this.rowData = rowData;
        this.data = this.rowData[0];
      }
    } else {
      let rowData = [];
      lines.forEach(function(line) {
        rowData.push({
          PRODUCT: line.product,
          STOCK: line.stock,
          UOM: line.product_uom,
          ORDERED: line.ordered,
          RECEIVED: line.delivered,
          RETURNED: line.returned,
          QTY: 0,
          PRICE: parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
          TAX: parseFloat(line.tax).toFixed(inthis.apiService.decimalPolicy),
          TOTAL: parseFloat(line.amount_total).toFixed(inthis.apiService.decimalPolicy),
          id: line.id,
          product_id: line.product_id,
          qty_check: line.delivered
        });
      });
      this.data = rowData[0];
      this.rowData = rowData;
    }

    for (var key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (key === "RECEIVED") {
          let hd = false;
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            hide: hd
          });
        } else if (key === "ORDERED") {
          let hd = false;
          if (this.sharedService.is_return && this.sharedService.order_type === 'return') {
            hd = true;
          }
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            hide: hd
          });
        } else if (key === "RETURNED") {
          let hd = false;
          if ((this.sharedService.is_return && this.sharedService.order_type === 'sale') || this.sharedService.is_receipt) {
            hd = true;
          }
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            hide: hd
          });
        } else if (key === "QTY") {

          var hd = false;
          if (recv || this.sharedService.delivered_po) {
            hd = true;
          }
          this.columnDefs.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            cellRenderer: "cellEdit2Renderer",
            hide: hd
          });

        } else if (key === "PRICE") {

          this.columnDefs.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            cellRenderer: "priceCellRenderer"
          });

        } else if (key === "PRODUCT") {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true, editable: false, resizable: true,
            width: 600
          });
        } else if (key === 'Serial NO') {
          let hd = false;
          if (this.sharedService.order_type === 'return' || this.sharedService.delivered_po) {
            hd = true;
          }
          this.columnDefs.push({
            headerName: key, field: key, lockPosition: true, resizable: true,
            cellRenderer: "serialNoRenderer", hide: hd
          });
        } else if (key === 'product_id' || key === 'id' || key === 'qty_check') {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, hide: true, unSortIcon: true, lockPosition: true, editable: true
          });
        } else {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
          });
        }
      }
    }
    this.loaded = true;
    if (flag === 'same') {
      this.gridApi.setRowData(this.rowData);
      this.preloader = false;
      if (screen.width > 991) {
        this.gridOptions.api.sizeColumnsToFit();
      } else {
        this.gridColumnApi.autoSizeColumns();
      }
    }
    this.context = {componentParent: this};
    this.frameworkComponents = {
      serialNoRenderer: SerialNoRenderer,
      cellEdit2Renderer: CellEdit2Renderer,
      priceCellRenderer: PriceCellRendererComonent
    };
  }

  confirmSerial() {

    this.sernos[this.line_id] = this.serl_arr;
  }

  enterSerialNo(data) {

    this.serl_arr = [];
    this.line_id = data.id;
    let exist_sers = this.sernos[this.line_id];
    if (exist_sers && exist_sers.length > 0) {
      this.serl_arr = this.sernos[this.line_id];
    } else {
      if (this.sharedService.is_return) {
        data['RECEIVED'] = data['QTY'];
      }
      for (var i = 0; i < parseInt(data['RECEIVED'], 10); i++) {
        this.serl_arr.push({
          "no": null
        });
      }
    }
    let el: HTMLElement = this.serial.nativeElement;
    el.click();
  }

  makeRecieve() {
    this.sharedService.is_receipt = false;
    this.sharedService.is_return = true;
    this.sharedService.mark_receieve = false;
    this.sharedService.delivered_po = false;
    this.sharedService.order_type = 'sale';
    this.productEntry([], false, false, 'same');
  }

  makeReturn() {
    this.sharedService.is_receipt = false;
    this.sharedService.is_return = true;
    this.sharedService.mark_receieve = false;
    this.sharedService.delivered_po = false;
    this.sharedService.order_type = 'return';
    this.productEntry([], false, false, 'same');
  }

  receivePO(type) {
    debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    let splDate = this.po_date.split('/');
    if (splDate.length > 1) {
      this.po_date = splDate[2] + '-' + splDate[1] + '-' + splDate[0];
    }
    if (!this.invoice_no) {
      window.alert("Invoice No should not be null");
      this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
    } else {
      if (!this.note) {
        this.note = '';
      }
      let order_data = {
        "access_token": this.user_data.access_token,
        "url": this.user_data.url,
        "order": null,
        "flag": null
      };
      let order = {
        "order_id": this.sharedService.order_id,
        "line_data": [],
        "distributor_id": this.dc_id,
        "supplier_id": this.user_data.supplier_id,
        "order_type": type,
        "invoice_no": this.invoice_no,
        "order_date": this.po_date,
        "note": this.note
      };
      let serialnos = this.sernos;
      let order_flag = true;
      let is_return = this.sharedService.is_return;
      let is_new = this.sharedService.new;
      if (is_return && this.sharedService.new) {
        order_data.flag = 'new';
      }
      this.rowData.forEach(function(line) {
        if (parseFloat(line['QTY']) > 0) {
          if (is_return) {
            if (type === 'return') {
              if (parseFloat(line['RETURNED']) + parseFloat(line['QTY']) > parseFloat(line['RECEIVED'])) {
                order_flag = false;
              }
              if (parseFloat(line['QTY']) > parseFloat(line['STOCK'])) {
                order_flag = false;
              }
            } else {
              if (parseFloat(line['QTY']) > (parseFloat(line['ORDERED']) - parseFloat(line['RECEIVED']))) {
                order_flag = false;
              }
            }
          } else {
            if (parseFloat(line['QTY']) > (parseFloat(line['ORDERED']) - parseFloat(line['RECEIVED']))) {
              order_flag = false;
            }
          }
          let line_data;
          if (is_new) {
            line_data = {
              "qty": line['QTY'],
              "product_id": line.product_id,
              "unit_price": line['PRICE'],
              "tax": line['TAX'],
              "serials": [],
              "tax_amount": line.tax_amount
            };
          } else {
            line_data = {
              "order_qty": parseFloat(line['ORDERED'].toString()) - parseFloat(line['RECEIVED'].toString()),
              "qty": line['QTY'],
              "product_id": line.product_id,
              "unit_price": line['PRICE'],
              "tax": line['TAX'],
              "serials": [],
              "tax_amount": line.tax_amount,
              id: line.id
            };
          }
          if (serialnos[line.id]) {
            line_data.serials = serialnos[line.id];
            // serialnos[line.id].forEach(function (serial) {
            //   line_data.serials.push({
            //     "serial_no": serial
            //   })
            // });
          }
          order.line_data.push(line_data);
        }
      });

      if (order_flag && order.line_data.length != 0) {

        order_data.order = order;

        this.res_data = this.apiService.receive_PO(order_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
            if (this.sharedService.order_type === 'sale') {
              window.alert("Recieved Successfully");
            } else {
              window.alert("Returned Successfully");
            }
            this.sharedService.new = false;
            this.sharedService.order_id = res['results'].id;
            this.sharedService.mark_receieve = false;
            this.sharedService.is_receipt = true;
            this.sharedService.is_return = false;
            // if (is_return) this.sharedService.is_receipt = true;
            this.sharedService.delivered_po = false;
            this.sharedService.flag = 'same';
            this.orderDetails();
            this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
            if (res.hasOwnProperty('results') && res['results'].msg) {
              window.alert(res['results'].msg);
            } else {
              window.alert("Recieving failed");
            }
          }
          // this.router.navigate([this.apiService.redirect_url+"vanuat.salesdiary.in/order_invoices"]);
        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
          console.log(error);
          if (error.error && error.error.error === undefined) {
            window.alert("Recieving failed");
          } else {
            window.alert("Recieving failed: " + error.error.error.message);
          }
        });
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        window.alert("Qty is Wrong");
      }
    }
  }

  // updateRowData(params) {
  //   this.gridApi.setR
  // }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

  }

  ngOnInit() {
    if (this.sharedService.new) {
      this.dcList();
    }
  }

  changeDate(dt) {

    this.po_date = this.datePipe.transform(this.po_date, 'yyyy-MM-dd');
  }

  changeExpiryDate(dt) {

    this.expiry_date = this.datePipe.transform(this.expiry_date, 'yyyy-MM-dd');
  }

  openfileUploadDialog() {
    const config: MatDialogConfig = {
      width: "150vw",
      height: "120vh",
      maxWidth: "450px",
      maxHeight: "560px",
      data: {
        upload_type: 'po_imei_upload',
        title: "Upload Products with Serial Numbers",
        message: "You are uploading products with linked serial numbers",
        instruction: "Column 1: Outlet_code <br> Column 2: action (ADD/REMOVE)",
        active_id: 0,
        supplier_products: this.supplier_products

      },
      panelClass: 'custom-dialog-container'
    };

    const dialogRef = this.dialog.open(POExcelUploadComponent, config);
    dialogRef.componentInstance.uploadData.subscribe(finalData => {
      this.dialog.closeAll();
      console.log(finalData);
      finalData.forEach(data => {
        const product = this.supplier_products.find(x => x.code === data.code);
        if (product) {
          this.rowData.push({
            PRODUCT: product.itemName.split('/')[2],
            STOCK: product.stock,
            UOM: product.uom,
            QTY: data.qty,
            PRICE: 0,
            TAX: parseFloat(product.tax).toFixed(this.apiService.decimalPolicy),
            TOTAL: 0,
            product_id: product.id,
            'Serial NO': "",
            serialData: data.serial
          });
        }
      });
      this.gridApi.setRowData(this.rowData);
    });
  }


  dispatch() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.order_id = this.sharedService.order_id;
    this.apiService.post('/api/pwa_connects/dispatch_purchase_order', paramObject)
      .subscribe(res1 => {
        console.log(res1);
        // t.stop();
        if (res1.hasOwnProperty('results') && (res1.results.status === 200)) {
          this.toastr.success("Marked Dispatch !!");
          this.orderDetails();
        } else {
          this.toastr.error("Failed to Dispatch");
        }
      });
  }
}

