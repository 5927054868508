import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-survey_territory_create-component',
  templateUrl: './survey_territory_create.component.html',
  styleUrls: ['./survey_territory_create.component.scss']
})
export class SurveyTerritoryCreateComponent implements OnInit {

  @Output() selectedTerritories: EventEmitter<any> = new EventEmitter<any>();

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public emptyState = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  user_data = {
    date: null,
    access_token: null,
    url: null
  };
  API_URL = '';
  allTerritories = [];
  selectedData = {
    area: null,
    state_id: 0,
    cluster_id: 0,
    salesman_id: 0,
    region_id: 0,
    territory_id: 0,
    territory_ids: []
  };
  territoryData = [];
  selectedTerritory = [];

  constructor(private apiService: ApiService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 1px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        autoHeight: true,
        wrapText: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          }
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.columnDefs = [
      {headerName: "TERRITORY", field: "name", headerCheckboxSelection: true, checkboxSelection: true},
      {headerName: "REGION", field: "region"},
      {headerName: "CLUSTER", field: "cluster"},
      {headerName: "MANAGER", field: "salesman"}
    ];

    this.territoryData = JSON.parse(localStorage.getItem('all_territories'));
    this.allTerritories = this.territoryData;
    this.fetchTerritories();
  }

  ngOnInit(): void {
  }

  fetchTerritories() {
    this.preloader = true;
    this.emptyState = false;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/res_territories/get_territory_list_pwa', paramObject)
      .subscribe(res => {
        console.log(res);
        // t.stop();
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data && res.results.data.length > 0) {
          this.territoryData = res.results.data;
          this.rowData = this.territoryData;
          this.preloader = false;
          if (this.rowData === null || this.rowData === undefined) {
            this.emptyState = true;
          } else if (this.rowData.length === 0) {
            this.emptyState = true;
          } else {
            this.loaded = true;
          }
        } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
          this.emptyState = true;
          if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
            console.error(res.results.errmsg);
          }
        } else {
          this.emptyState = true;
          if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
            console.error(res.results.errmsg);
          }
        }
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  addTerritories() {

    this.selectedTerritory = this.gridApi.getSelectedRows();
    this.selectedTerritories.emit(this.selectedTerritory);
    this.dialog.closeAll();

  }

}
