<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>APPROVE RETURNS</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div class="col-md-12 pad0" style="margin-top: 10px;">
        <div>
          <ag-grid-angular *ngIf="dataLoaded" id="myGridpo" style="height: 65vh" class="ag-theme-balham"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions" (cellValueChanged)="onCellValueChanged($event)"
                           (gridReady)="onGridReady($event)"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="emptyState">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px;">
          <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;">
           <a class="btn white-btn">DISCARD</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          data-dismiss="modal"
          (click)="confirm()"
        >
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</section>
