import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-retail-survey_kyc-component',
  templateUrl: './../../pagebase/pagebase.html',
  styleUrls: ['./../../pagebase/pagebase.scss']
})
export class RetailSurveyKYCComponent extends PageBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('Retailer KYC Survey');
    this.showDate = false;
    this.userData.customFilter['type'] = 'retailer kyc';
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_profiling_questionnaires/get_retail_survey_list';
    this.columnDefs = [
      {headerName: "CODE", field: "code", width: 50},
      {headerName: "QUESTIONNAIRE", field: "name", width: 150},
      {headerName: "DESCRIPTION", field: "description", width: 150},
      {headerName: "NO OF QUESTIONS", field: "questions", cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "START DATE", field: "start_date", width: 100},
      {headerName: "END DATE", field: "end_date", width: 80},
      {headerName: "FREQUENCY", field: "period", width: 100},
      {
        headerName: "SCORE MTD  %", field: "score", cellStyle: {textAlign: 'center'}, width: 80,
        valueGetter(params) {
          return Math.round((params.data.score + Number.EPSILON) * 100) / 100;
        }
      },
      {headerName: "COMPLETED MTD", field: "completed", cellStyle: {textAlign: 'center'}, width: 80},
    ];
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('surveytype', 'kyc');
    localStorage.setItem('surveyid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/survey_detail'], {
      state: {
        type: 'detail',
        surveyid: rows[0].id
      }
    });
  }
}
