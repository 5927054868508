<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Generate Claims</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #claimgenerateform="ngForm">
          <div class="col-md-12 pad0" style="margin-top: 5px;background: white;padding: 10px;border: 1px solid #D7DDE6;">
            <div class="col-md-3 pl-0 pr-30">
              <label class="detaillabel" style="color: #1D1D1D; font-weight: 400;">Period</label>
              <div class="periodselect1">
                <select
                  name="period"
                  (change)="changePeriod($event.target['value'])"
                  [(ngModel)]="periodID"
                >
                  <option
                    *ngFor="let item of periodData"
                    value="{{ item.id }}"
                  > {{ item.period_name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-9 pl-0 pr-0">
              <div class="mt-2"
                   style="border: 1px solid #D7DDE6; border-radius: 3px;padding: 20px;background: #F5F7F9">
                <div style="display: flex">
                  <div style="flex: 1">
                    <label class="detaillabel">Quantity</label>
                    <p class="detailvalue1">{{totalQuantity}}</p>
                  </div>
                  <div style="flex: 1">
                    <label class="detaillabel">Invoices</label>
                    <p class="detailvalue1">{{totalInvoices}}</p>
                  </div>
                  <div style="flex: 1">
                    <label class="detaillabel">No Of Parties</label>
                    <p class="detailvalue1">{{totalOutlets}}</p>
                  </div>
                  <div style="flex: 1">
                    <label class="detaillabel">Total</label>
                    <p class="detailvalue1">{{totalClaimAmount.toFixed(2)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" style="margin-top: 15px;background: white;padding: 10px;border: 1px solid #D7DDE6;">
            <div class="search-list">
              <div class="col-md-6 col-12 nopadding pl-0" style="display: inline-block">
                <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                  <mat-tab label="Summary">
                  </mat-tab>
                  <mat-tab label="Sale">
                  </mat-tab>
                  <mat-tab label="Return">
                  </mat-tab>
                 <!-- <mat-tab label="Expense">
                  </mat-tab>-->
                </mat-tab-group>
              </div>
              <div class="col-md-6 col-12 pad0 text-right">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR" style="margin-left: 10px;">
                      <div class="search" style="display: inline-block;">
                        <span class="search-control"><img src="assets/searchicon.png"/></span>
                        <input id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()"
                               class="search-control-area"
                               placeholder="Search">
                      </div>
                    </li>
                    <li *ngIf="activetab==='Summary'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="summary_gridOptions" [title]="'Summary Report'"></app-excelicon>
                    </li>
                    <li *ngIf="activetab==='Sale'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="sale_gridOptions" [title]="'Sale Report'"></app-excelicon>
                    </li>
                    <li *ngIf="activetab==='Return'" class="margLR" style="margin-left:5px;margin-right:5px" >
                      <app-excelicon [gridOption]="return_gridOptions" [title]="'Return Report'"></app-excelicon>
                    </li>
                    <li *ngIf="activetab==='Expense'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="return_gridOptions" [title]="'Expense Report'"></app-excelicon>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Summary'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="summarygrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="summary_columnDefs"
                                     [gridOptions]="summary_gridOptions"
                                     (gridReady)="summaryOnGridReady($event)"
                                     [context]="sale_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Sale'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="salegrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="sale_columnDefs"
                                     [gridOptions]="sale_gridOptions"
                                     (gridReady)="saleOnGridReady($event)"
                                     (cellClicked)="saleOnCellClicked($event)"
                                     (rowSelected)="saleOnSelectionChanged($event)"
                                     [getRowId]="getRowId"
                                     [context]="sale_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Return'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="returngrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="return_columnDefs"
                                     [gridOptions]="return_gridOptions"
                                     (gridReady)="returnOnGridReady($event)"
                                     (cellClicked)="returnOnCellClicked($event)"
                                     (rowSelected)="returnOnSelectionChanged($event)"
                                     [getRowId]="getRowId"
                                     [context]="return_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Expense'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="expensegrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     class="ag-theme-alpine myGrid2"
                                     [columnDefs]="expense_columnDefs"
                                     [gridOptions]="expense_gridOptions"
                                     (gridReady)="expenseOnGridReady($event)"
                                     [getRowId]="getRowId"
                                     [context]="expense_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="dataLoaded" class="button-row" style="padding-top: 10px;display: block; background: white;">
              <div style="float: left;margin-left: 15px;margin-top: 12px;">
                <button
                  style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                  mat-button
                  class="btn confirm-blue-btn"
                  (click)="openConfirmDialog()"
                  [disabled]="this.totalInvoices === 0"
                  form="ngForm"
                >
                  GENERATE CLAIM
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
