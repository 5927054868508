import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateEditUserComponent} from "./create_edit_user/create_edit_user.component";
import {AGGridEditButtonRendererComponent} from "./AGGridEditButtonRendererComponent";


@Component({
  selector: 'app-user-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class UsersComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {
    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };

    this.sharedService.setHeader('Users');

    this.showableFilters.region = true;
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.customFilter.values = [
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'},
      {id: '', itemName: 'All'}
    ];

    this.showDate = false;
    this.showCreate = true;
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/res_users/getUsersListPWA';
    const inthis = this;
    this.columnDefs = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'EMP NAME', field: 'user_name', enableRowGroup: true, width: 150, checkboxSelection: true, headerCheckboxSelection: true},
      {headerName: 'EMP CODE', field: 'emp_code', menuTabs: [], width: 80},
      {headerName: 'SALES AREA CODE', field: 'sales_area_code', menuTabs: [], width: 80},
      {headerName: 'ROLE', field: 'role', enableRowGroup: true, width: 100},
      {headerName: 'ROLE ID', field: 'role_id', hide: true},
      {headerName: 'MOBILE', field: 'mobilenumber', width: 100},
      {headerName: 'EMAIL', field: 'user_email', width: 150},
      {headerName: 'REPORTING MANAGER', field: 'reporting_manager', hide: true, enableRowGroup: true, width: 150},
      {headerName: 'ACTIVE', field: 'status', enableRowGroup: true, width: 80},
      {headerName: 'TEAM', field: 'team_name', width: 100, enableRowGroup: true},
      {headerName: 'TEAM ID', field: 'sale_team_id', width: 100, enableRowGroup: true},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'REGION ID', field: 'region_id', hide: true},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'STATE ID', field: 'state_id', hide: true, width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_team', hide: true, width: 100, enableRowGroup: true},
      {headerName: 'INACTIVATION DATE', field: 'inactivation_date', hide: true, enableRowGroup: true, width: 80},
      {
        headerName: "CREATE DATE", field: "create_date", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'APP VERSION', field: 'app_version', enableRowGroup: true, width: 80},
      // {
      //   headerName: '',
      //   cellRenderer: 'buttonRenderer',
      //   cellRendererParams: {
      //     onClick: this.onEditClick.bind(this),
      //     label: 'edit'
      //   }
      // },
    ];

    this.getRowId = (params) => params.data.id;
  }


  // onEditClick(e) {
  //   const config: MatDialogConfig = {
  //     width: "750px",
  //     height: "500px",
  //     panelClass: 'custom-dialog-container',
  //     data: {}
  //   };
  //
  //   const confirmdiaeref = this.dialog.open(CreateEditUserComponent, config);
  //
  //   confirmdiaeref.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed', result);
  //     if (result) {
  //       this.loadServerData();
  //     }
  //   });
  //
  // }

  openCreateDialog() {
    const config: MatDialogConfig = {
      width: "750px",
      height: "550px",
      panelClass: 'custom-dialog-container',
      data: {}
    };

    const creatediaeref = this.dialog.open(CreateEditUserComponent, config);

    creatediaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }


}
