export class MultiBillSchemeCalculator {

  sales = [];
  schemes = [];

  constructor(sales, schemes) {
    this.sales = sales;
    this.schemes = schemes;
  }

  ComputeScheme() {
    let claim_data = [];
    this.sales.forEach(sale => {
      if (!sale.claim_id) {
        let schemes = [];
        schemes = this.getEligibleSchemes(sale);
        schemes.forEach(scheme => {
          if (!sale.scheme_id) {

            if (scheme.definition) {
              scheme.definition.forEach(criteria => {
                if (criteria.criteria_type === 'bill_value' && sale.inv_amount >= criteria.value) {
                  sale.scheme_id = scheme.id;
                  sale.claim_amount = this.getSchemePctAndAmountDiscount(scheme, sale.inv_amount);
                  sale.scheme_code = scheme.code;
                  sale.scheme_name = scheme.name;
                  sale.scheme_start = scheme.start_date;
                  sale.scheme_end = scheme.scheme_end;

                  claim_data.push(sale);
                }
              });
            }
          }
        });
      }
    });

    return claim_data;
  }

  getEligibleSchemes(sale) {
    return this.schemes.sort((a, b) => a.priority - b.priority);
  }


  getSchemePctAndAmountDiscount(scheme, mSubTotal) {
    let reward = 0;
    if (scheme['margin'] && scheme['margin'] > 0 && mSubTotal > 0) {
      reward = (mSubTotal * scheme['margin']) / 100;
    } else if (scheme['amount'] && scheme['amount'] > 0) {
      reward = scheme['amount'];
    }
    return reward;
  }
}
