import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {GadgetInstanceService} from '../../grid/grid.service';
import {GadgetPropertyService} from '../_common/gadget-property.service';
import {GadgetBase} from '../_common/gadget-base';
import {Router} from '@angular/router';
import {OptionsService} from '../../configuration/tab-options/service';
import {ApiService} from '../../../_services/api.service';
import {ErrorHandler} from '../../../error/error-handler';
import {KpiHelper} from '../../services/kpi-helper';
import {DashboardFilterService} from "../../services/dashboard-filter.service";
import {LocalforageService} from "../../../_services/localforage.service";
import {MatDialog} from "@angular/material/dialog";
import {CustomConfigService} from "../../../_services/CustomConfigService";

declare var jQuery: any;

@Component({
    selector: 'app-dynamic-component',
    moduleId: module.id,
    templateUrl: './view.html',
    styleUrls: ['../_common/styles-gadget.css',
      '../_common/styles-gadget-common.scss']
})

// To Be Declared in following places
// 1. GadgetFactory.ts
// 2. Gadget module Declarations & Exports section
// 3. app.module.ts under GridModule
export class BarGraphGadgetComponent extends GadgetBase {

    @ViewChild('chartOptionsSideBar_tag', {static: false}) chartOptionsSideBarRef: ElementRef;
    chartOptionsSideBar: any;

    // chart options
    showXAxis: boolean;
    showYAxis: boolean;
    gradient: boolean;
    showLegend: boolean;
    showXAxisLabel: boolean;
    showYAxisLabel: boolean;
    barChartType: string;
    showDataLabel: boolean;
    yAxisLabel: string;
    xAxisLabel: string;
    view: any[];
    // colorScheme = 'cool';
    colorScheme: any = {
        domain: [
          '#16a085',
          '#7dcea0',
          '#abebc6',
          '#f7dc6f',
          '#e59866' ]
    };
    //////////////////

    chart_data: any[] = [];
    // subscription: any;
    state: string;

    RUN_STATE = 'run';
    STOP_STATE = 'stop';
    POLL_INTERVAL = 15000;


  constructor(protected _apiService: ApiService,
              protected _gadgetInstanceService: GadgetInstanceService,
              protected _propertyService: GadgetPropertyService,
              protected _changeDetectionRef: ChangeDetectorRef,
              protected _optionsService: OptionsService,
              protected _configService: CustomConfigService,
              protected _dashboardFilterService: DashboardFilterService,
              protected _router: Router,
              protected _localforageService: LocalforageService,
              protected _dialog: MatDialog,

  ) {
    super(_apiService,
      _gadgetInstanceService,
      _propertyService,
      _changeDetectionRef,
      _optionsService,
      _configService,
      _dashboardFilterService,
      _router,
      _localforageService,
      _dialog);

    // this.preRun();

  }

    public preRun() {

        /**
         * the base class initializes the common property gadgets. Prerun gives
         * us a chance to initialize any of the gadgets unique properties.
         */
        this.dataUpdated = false;
        this.setAPIPath('/api/pwa_boards/get_static_kpi');
        this.initializeTheRemainderOfTheProperties();
        // if (this.getPropFromPropertyPages('state') === this.RUN_STATE) {
        this.run();
        // }
    }

    initializeTheRemainderOfTheProperties() {

        this.gradient = this.getPropFromPropertyPages('gradient');
        this.showXAxis = this.getPropFromPropertyPages('showXAxis');
        this.showYAxis = this.getPropFromPropertyPages('showYAxis');
        this.showLegend = this.getPropFromPropertyPages('showLegend');
        this.showXAxisLabel = this.getPropFromPropertyPages('showXAxisLabel');
        this.showYAxisLabel = this.getPropFromPropertyPages('showYAxisLabel');
        this.barChartType = this.getPropFromPropertyPages('barChartType');
        this.showDataLabel = this.getPropFromPropertyPages('showDataLabel');
        this.yAxisLabel = this.getPropFromPropertyPages('yAxisLabel');
        this.xAxisLabel = this.getPropFromPropertyPages('xAxisLabel');
        this.kpiName = this.getPropFromPropertyPages('kpi_name');

        // If not set in XML config set defults from Helper
        const kpiParams = KpiHelper.getKpiProperties(this.kpiName);
        if (kpiParams.hasOwnProperty('colorScheme')) {
          this.colorScheme = kpiParams.colorScheme;
        } else {
          this.colorScheme = 'cool';
        }
        if (this.xAxisLabel !== '' && (kpiParams.hasOwnProperty('xAxisLabel'))) {
          this.xAxisLabel = kpiParams.xAxisLabel;
        }
        if (this.yAxisLabel !== '' && (kpiParams.hasOwnProperty('yAxisLabel'))) {
          this.yAxisLabel = kpiParams.yAxisLabel;
        }
    }


    public run() {

        this.clearChartData();
        this.initializeRunState(true);
        this.updateData(null);
        // this.saveState(this.RUN_STATE);
    }

    clearChartData() {
        this.chart_data = [];
    }


    public stop() {

        this.stopWithoutStateSave();
        this.saveState(this.STOP_STATE);
    }

    /**
     * The state is being saved to allow the board to load with the last state. Also, when the gadget is moved
     * within the board we need to carry the gadget's state along.
     * @param state
     */
    public saveState(state: string) {

        this.updateProperties('{\"state\":\"' + state + '\"}');
        // this.persistTheChangeInInternalState();

    }

    /**
     * When the gadget is destroyed (see ngOnDestroy) there is no need to
     * save the state. We just want to stop any API calls.
     */
    public stopWithoutStateSave() {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
        // const data = [];
        // Object.assign(this, {data});
        // this.setStopState(false);

    }

    public updateData(userData: any[]) {
      const paramObject: any = {};
      paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
      paramObject.access_token = localStorage.getItem('resfreshToken');
      paramObject.kpi_name = this.kpiName;
      if (this.filterData != null) {
          paramObject.filterData = this.filterData;
      }



      this.dataUpdated = false;
      this.apiService.post(this.getAPIPath(), paramObject)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {


            this.chart_data = res.results.data;
            this.dataUpdated = true;
            this.stop();
            this._localforageService.set(this.kpiName, this.chart_data);

            this.changeDetectionRef.detectChanges();
          } else {
            this.handleError(ErrorHandler.getErrorObject('Internal Error'));
          }
        }, error => this.handleError(ErrorHandler.getErrorObject(error)));
    }

    public drillDown(data) {

        this.stopWithoutStateSave();

        this._router.navigate(['/detail'], {
            queryParams:
                {
                    chartType: 'bar',
                    chartSeries: data.series,
                    chartMetric: data.name
                    // endPointName: this.endpointObject.name
                }
        });
    }


    private setInternalProperties(updatedPropsObject: any) {

        this.state = updatedPropsObject.state;

        if (updatedPropsObject.title !== undefined) {

            this.title = updatedPropsObject.title;
            this.kpiName = updatedPropsObject.kpi_name;
            this.showXAxis = updatedPropsObject.showXAxis;
            this.showYAxis = updatedPropsObject.showYAxis;
            this.gradient = updatedPropsObject.gradient;
            this.showLegend = updatedPropsObject.showLegend;
            this.showXAxisLabel = updatedPropsObject.showXAxisLabel;
            this.showYAxisLabel = updatedPropsObject.showYAxisLabel;
            this.barChartType = updatedPropsObject.barChartType;
            this.showDataLabel = updatedPropsObject.showDataLabel;
            this.xAxisLabel = updatedPropsObject.xAxisLabel;
            this.yAxisLabel = updatedPropsObject.yAxisLabel;
            this.showOperationControls = true;

            const kpiParams = KpiHelper.getKpiProperties(this.kpiName);
            if (kpiParams.hasOwnProperty('colorScheme')) {
              this.colorScheme = kpiParams.colorScheme;
            } else {
              // @ts-ignore
              this.colorScheme = 'cool';
            }
        }
    }

    /**
     * todo
     *  This is called from the dynamic property page form or when the internal running state changes
     *  A similar operation exists on the procmman-config-service
     *  whenever the property page form is saved, the in memory board model
     *  is updated as well as the gadget instance properties
     *  which is what the code below does. This can be eliminated with code added to the
     *  config service or the property page service.
     *
     * **/
    public updateProperties(updatedProperties: any) {
        const updatedPropsObject = JSON.parse(updatedProperties);


        /**
         * update this tools property pages
         */
        this.propertyPages.forEach(propertyPage => {
            for (let x = 0; x < propertyPage.properties.length; x++) {
                for (const prop in updatedPropsObject) {
                    if (updatedPropsObject.hasOwnProperty(prop)) {
                        if (prop === propertyPage.properties[x].key) {
                            propertyPage.properties[x].value = updatedPropsObject[prop];
                        }

                    }
                }
            }
        });

        /**
         * update the tools internal state
         */
        this.setInternalProperties(updatedPropsObject);

    }

  public ngOnDestroy = () => {
      this.stopWithoutStateSave();
  };


    /**
     * todo - need to improve how internal state is saved to persistant store
     */
    private persistTheChangeInInternalState() {
        let payLoad =
            "{\"instanceId\":" + this.instanceId
            + ",\"title\":\"" + this.title
            + "\",\"state\":\"" + this.state
            // + "\",\"endpoint\":\"" + this.endpointObject.name
            + "\",\"gradient\":" + this.gradient
            + ",\"showXAxis\":" + this.showXAxis
            + ",\"showYAxis\":" + this.showYAxis
            + ",\"showLegend\":" + this.showLegend
            + ",\"showXAxisLabel\":" + this.showXAxisLabel
            + ",\"showYAxisLabel\":" + this.showYAxisLabel
            + ",\"showDataLabel\":" + this.showDataLabel
            + ",\"barChartType\":\"" + this.barChartType
            + "\",\"yAxisLabel\":\"" + this.yAxisLabel
            + "\",\"xAxisLabel\":\"" + this.xAxisLabel
            + "\"}";


        this._configService.notifyGadgetOnPropertyChange(payLoad, this.instanceId);

    }

    toggleChartProperties() {

        if (this.globalOptions.displayGadgetOptionsInSideBar === false) {
            this.toggleConfigMode();
            return;
        }
        this.chartOptionsSideBar = jQuery(this.chartOptionsSideBarRef.nativeElement);
        this.chartOptionsSideBar.sidebar('setting', 'transition', 'overlay');
        this.chartOptionsSideBar.sidebar('toggle');

    }

}
