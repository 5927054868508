<div *ngIf="dataLoaded" class="cardbox" style="border: 1px solid #D7DDE6;background: #FFFFFF;position: absolute;
    bottom: 15px;
    top: 15px;
    left: 15px;
    right: 15px;">
  <h3>{{meetDetails.meet_name}}</h3>

  <div *ngIf="!locationEnabled" class="mt-4"
       style="border: 1px solid #E89221; border-radius: 5px;padding: 20px;background: #FFF8F0;">
    <label style="font-size: 14px; font-weight: 400; color: #2D2D2D;">Location Access Not Enabled.
      Please enable Location Access for this Page through Browser Settings.</label>
    <!--<button
      mat-button
      style="display: block; margin-top: 10px;"
      class="btn orange-btn"(click)="requestLocation()">
      Allow Access
    </button>-->
  </div>
  <div class="mt-4">
    <span style="color: #2D2D2D; font-size: 15px;font-weight: 600;">{{meetDetails?.ma_config?.name}}</span>
  </div>
  <div class="row centeralign">
    <span class="detailvalue">{{meetDetails.ma_config?.mobile}}</span>
    <span *ngIf="meetDetails?.ma_config?.email" class="greyindicator" style="margin-left: 30px;"></span>
    <span *ngIf="meetDetails?.ma_config?.email" class="detailvalue">{{meetDetails?.ma_config?.email}}</span>
  </div>
  <div class="mt-4" style="border-radius: 3px; padding: 20px;"
       [ngClass]="{'futuremeeting': days !== 0, 'meetingday': days === 0 }">
    <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;">Meet Details</span>
    <div class="row centeralign" style="margin-top: 5px;">
      <span class="detaillabel mr-2">Date & Time</span>
      <span *ngIf="days !== 0" class="detailvalue" style="color: #757BC8">{{meetDetails.planned_date}}</span>
      <span *ngIf="days === 0" class="detailvalue">{{meetDetails.planned_date}}</span>
    </div>
    <div class="row centeralign" style="margin-top: 5px;">
      <span class="detaillabel mr-2">Meet Type</span>
      <span class="detailvalue">{{meetDetails.type}}</span>
    </div>
    <div class="row centeralign" style="margin-top: 5px;">
      <span class="detaillabel mr-2">Sponsors</span>
      <span class="detailvalue">{{sponsors}}</span>
    </div>
    <div class="row centeralign" style="margin-top: 5px;">
      <span class="detaillabel mr-2">Organiser</span>
      <span class="detailvalue">{{meetDetails.user}}</span>
    </div>
    <div *ngIf="meetDetails.mobile" class="row centeralign">
     <!-- <img style="margin-right: 5px;height: 10px;"  src="./assets/images/call.svg"/>
      <span class="detailvaluedesc">Call Organiser</span>-->
      <a class="detailvaluedesc" href="tel:{{meetDetails.mobile}}"><img style="margin-right: 5px;height: 10px;" src="../../../../assets/images/call.svg" alt="Call Organiser" />Call Organiser</a>
    </div>
  </div>
  <div class="mt-4" style="border-radius: 3px; padding: 20px;"
       [ngClass]="{'futuremeeting': days !== 0, 'meetingday': days === 0 }">
    <div class="row centeralign" style="justify-content: space-between">
      <div class="centeralign" style="margin-right: auto">
        <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;">Meet Address</span>
      </div>
      <div (click)="openMapsinNewTab()">
        <img *ngIf="distance > 1000" style="margin-right: 5px;height: 12px;" src="../../../../assets/images/distance_far.svg"/>
        <img *ngIf="distance <= 1000" style="margin-right: 5px;height: 12px;"
             src="../../../../assets/images/distance_near.svg"/>
        <span *ngIf="distance && distance > 1000"
              style="color: #2D2D2D; font-size: 12px;">{{(distance / 1000).toFixed(2)}} Km</span>
        <span *ngIf="distance && distance < 1000" style="color: #2D2D2D; font-size: 12px;">{{distance}} m</span>
      </div>
    </div>
    <div class="row centeralign" style="margin-top: 10px;">
      <span class="detailvalue">{{meetDetails.pm_config?.address}}</span>
    </div>
  </div>
  <!--<div class="mt-5 row centeralign">
    <img src="./assets/Warning.svg">
  &lt;!&ndash;  <span>Survey Not Available. Move Closer to the Meet Location to Take the Survey.</span>&ndash;&gt;
    <span>Survey Not Available. </span>
  </div>-->
  <!--<div class="mt-5">
    <img src="./assets/Warning.svg">
    <p style="display: inline;color: #D97C0E;margin-left: 1%;">Survey Not Available. Move Closer to the Meet Location to Take the Survey.</p>
  </div>
-->

  <div *ngIf="surveyPresent && surveyCompleted" class="mt-4"
       style="border: 1px solid #F5F7F9; border-radius: 3px;padding: 20px;background: #F5F7F9">
    <div class="row centeralign">
      <span style="color: #2D2D2D; font-size: 14px;font-weight: 400;display: block;">Survey Completed</span>
      <img style="margin-left: 5px;height: 12px;" src="../../../../assets/tickgreen.svg"/>
    </div>
    <button
      class="btnsmall confirm-blue-btn"
      style="margin-top: 15px;padding: 10px 30px;"
      (click)="openTakeSurveyPopup(false)"
    >
      View Results
    </button>
  </div>

  <div *ngIf="distance > 1000 && days >= 0" class="mt-5" style="display: flex;">
    <div style="display: inline-block;">
      <img src="../../../../assets/Warning.svg">
    </div>
    <div style="display: inline-block; margin-left: 10px;">
      <p *ngIf="surveyPresent && !surveyCompleted"
         style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Survey Not Available. Move Closer to
        the Meet Location to Take the Survey.</p>
      <p *ngIf="!surveyPresent && days === 0" style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Move Closer to
        the Meet Location to Mark Attendance.</p>
    </div>

  </div>

  <div *ngIf="locationEnabled" style="position:absolute; bottom: 20px; left: 15px; right:15px;">
    <div style="all:revert !important"></div>
    <div *ngIf="days < 0 || meetCompleted"  class="mb-2" style="display: flex;">
      <div style="display: inline-block;">
        <img src="../../../../assets/Warning.svg">
      </div>
      <div style="display: inline-block; margin-left: 10px;">
        <p *ngIf="meetCompleted" style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Attendance cannot be marked after meeting is completed</p>
        <p *ngIf="days < 0 && !meetCompleted" style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Attendance cannot be marked as it was not recorded within {{limit_days}} day(s) of the meeting.</p>
      </div>
    </div>

    <div *ngIf="surveyPresent && !surveyCompleted">
      <button
        class="btn confirm-blue-btn"
        style="width: 100%; margin-top: 15px;padding: 10px 30px;"
        (click)="openTakeSurveyPopup(true)"
        [disabled]="distance > 1000 || days < 0">
        Take Survey
      </button>
    </div>
    <div *ngIf="(surveyCompleted || !surveyPresent) && !markedAttendance" class="button-row"
         style="display: flex;">
      <button
        mat-button
        style="flex: 1;"
        class="btn confirm-blue-btn"
        (click)="markAttended()"
        [disabled]="distance>1000 || (surveyPresent && !surveyCompleted) || days < 0"
      >
        Mark Attended
      </button>
    </div>
    <div *ngIf="(surveyCompleted || !surveyPresent) && !markedAttendance && (days > 0 || meetNotStarted)" class="button-row"
         style="padding: 10px; background-color: #EAEBF7; border-radius: 50px;text-align: center;">
      <img style="margin-right: 5px;height: 14px;" src="../../../../assets/images/time.svg"/>
      <span *ngIf="days > 0" style="font-size: 14px;color: #757BC8;"> Meeting starts in {{days}} day(s)</span>
      <span *ngIf="meetNotStarted" style="font-size: 14px;color: #757BC8;"> Meeting will start soon</span>
    </div>
  </div>
</div>
