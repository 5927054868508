import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-task-survey',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})
export class TaskSurveyComponent extends PageBase implements OnInit {


  ngOnInit() {
    this.sharedService.setHeader('Task Survey');
    this.gridOptions.context = this;
    this.showDate = false;
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_profiling_questionnaires/get_task_survey_list';
    this.columnDefs = [
      {headerName: "CODE", field: "code", width: 100},
      {headerName: "TASK", field: "task_name", width: 150},
      {headerName: "QUESTIONNAIRE", field: "name", width: 150},
      {headerName: "NO OF QUESTIONS", field: "questions", cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "START DATE", field: "start_date", width: 80},
      {headerName: "END DATE", field: "end_date", width: 80},
      {headerName: "FREQUENCY", field: "period", width: 80},
      {
        headerName: "SCORE MTD  %", field: "score", cellStyle: {textAlign: 'center'}, width: 80,
        valueGetter: params => Math.round((params.data.score + Number.EPSILON) * 100) / 100
      },
      {headerName: "COMPLETED MTD", field: "completed", cellStyle: {textAlign: 'center'}, width: 80},
    ];
  }


    onCellClicked(event) {
      const column = event.column.getColId();
      const rows = this.gridApi.getSelectedRows();
      localStorage.setItem('surveytype', 'task');
      localStorage.setItem('surveyid', rows[0].id);
      this.router.navigate([this.apiService.redirect_url + '/survey_detail'], {state: {
          type: 'detail',
          surveyid : rows[0].id
        }});
    }
}
