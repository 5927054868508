import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-raw-user-alert-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RawUserAlertsComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Raw User Alerts Report');

    // this.showableFilters.cluster = true;
    this.showableFilters.territory = true;
    // this.showableFilters.region = true;
    this.showableFilters.salesman = true;
    this.showDate = true;
    this.customFilter.values = [
      {id: true, itemName: 'Active'},
      {id: false, itemName: 'Inactive'}
    ];

    this.loadInBatches(10000, 'id');
    this.showProgressCustomText = true;

    this.API_URL = '/api/pwa_v2_reports/getRawUserAlertsReport';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Alert",
        children: [
          {headerName: 'ALERT ID', field: 'a_id', width: 80},
          {headerName: 'ALERT NAME', field: 'name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'code', width: 80},
          {headerName: 'LINE', field: 'line_name', enableRowGroup: true, width: 80},
          {headerName: 'LINE ID', field: 'line_id', enableRowGroup: true, width: 80},
          {headerName: 'ALERT TYPE', field: 'type_name', enableRowGroup: true, width: 100},
          {headerName: 'START DATE', field: 'start_date', width: 100},
          {headerName: 'END DATE', field: 'end_date', width: 100},
          {headerName: 'DESCRIPTION', field: 'description', width: 80},
          {headerName: 'STATUS', field: 'line_status', width: 80}
        ]
      }, {
        headerName: "User Details",
        children: [
          {headerName: 'NAME', field: 'user_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'emp_code', width: 80},
          {headerName: 'TERRITORY', field: 'rt_name', enableRowGroup: true, width: 80},
          {headerName: 'TEAM', field: 'team_name', enableRowGroup: true, width: 80},
          {headerName: 'MANAGER', field: 'manager', enableRowGroup: true, width: 80},
          {headerName: 'DESIGNATION', field: 'designation', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "Partner Details",
        children: [
          {headerName: 'PARTNER NAME', field: 'partner_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'partner_code', width: 80},
          {headerName: 'PARTNER TYPE', field: 'partner_type', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'partner_class', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "Product Details",
        children: [
          {headerName: 'CODE', field: 'prod_code', width: 80},
          {headerName: 'PRODUCT NAME', field: 'prod_name', enableRowGroup: true, width: 80},
          {headerName: 'FAMILY', field: 'prod_family', width: 80},
          {headerName: 'CATEGORY', field: 'prod_cat', width: 80},
          {headerName: 'BRAND', field: 'prod_brand', width: 80}
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
