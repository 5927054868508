import {Component} from '@angular/core';
import {ITooltipAngularComp} from '@ag-grid-community/angular';
import {ITooltipParams} from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-tooltip-component',
  template: `
    <div *ngIf="enable" class="custom-tooltip" [style.background-color]="color">
      <!--<table style="margin-left: 10%;">
        <tr>
          <td class="num padR cGrey">{{ord}}</td>
          <td class="num padL">{{sug}}</td>
        </tr>
        <tr>
          <td class="num_label padR mrgR">User</td>
          <td class="num_label padL">User Sug</td>
        </tr>
        <tr>
          <td class="num padR mrgR">{{pre}}</td>
          <td class="num padL">{{pre_sug}}</td>
        </tr>
        <tr>
          <td class="num_label padR mrgR">Pre Order</td>
          <td class="num_label padL">Order Sug</td>
        </tr>
        <tr>
          <td class="num padR mrgR cRed">{{act_clos}}</td>
          <td class="num padL cRed">{{clos_sug}}</td>
        </tr>
        <tr>
          <td class="num_label padR mrgD">Closing</td>
          <td class="num_label padL mrgD">Expected</td>
        </tr>
        <tr>
          <td class="num padR ">{{act_total}}</td>
          <td class="num padL">{{sug_total}}</td>
        </tr>
      </table>-->

      <table style="margin-left: 10%;  border-spacing: 0 10px;">
        <tr>
          <td class="num_label mrgR"></td>
          <td class="num_label padL padR mrgR">User</td>
          <td class="num_label padL padR ">Suggested</td>
        </tr>
        <tr>
          <td class="num_label mrgR">GT Sales</td>
          <td class="num padL padR mrgR">{{non_pre_inv}}</td>
          <td [ngClass]="{'highlightgreennoborder': loadoutedit && !isTotal && sugPresent,
                     'highlightrednoborder': !loadoutedit && !isTotal && sugPresent,
                     'nonhighlightnoborder': isTotal || !sugPresent}">{{sug}}</td>
        </tr>
        <tr>
          <td class="num_label mrgR">Pre-Invoice</td>
          <td [ngClass]="{'highlightgreen': loadoutedit && vanpreorder && !isTotal && sugPresent,
                           'highlightred': !loadoutedit  && vanpreorder && !isTotal && sugPresent,
                     'nonhighlight': !vanpreorder || isTotal || !sugPresent}">{{pre}}</td>
          <td [ngClass]="{'highlightgreennoborder': loadoutedit && !vanpreorder && !isTotal && sugPresent,
                     'highlightrednoborder': !loadoutedit && !vanpreorder && !isTotal && sugPresent,
                    'nonhighlightnoborder': vanpreorder || isTotal || !sugPresent}">{{pre_sug}}</td>
        </tr>
        <tr>
          <td class="num_label mrgR">Closing</td>
          <td [ngClass]="{'highlightgreen': loadoutedit && !last_started && !isTotal && sugPresent,
                     'highlightred': !loadoutedit && !last_started && !isTotal &&sugPresent,
                     'nonhighlight': last_started || isTotal || !sugPresent}">{{act_clos}}</td>
          <td [ngClass]="{'highlightgreennoborder': loadoutedit && last_started && !isTotal && sugPresent,
                     'highlightrednoborder': !loadoutedit && last_started && !isTotal && sugPresent,
                    'nonhighlightnoborder': !last_started || isTotal || !sugPresent}">{{clos_sug}}</td>
        </tr>
        <tr>
          <td class="num_label mrgR mrgD">Final Loadout</td>
          <td class="num padL padR mrgD">{{act_total}}</td>
          <td class="num padL padR mrgD">{{sug_total}}</td>
        </tr>
        <tr>
          <td class="num_label mrgR">Salesman Order</td>
          <td class="num padL padR">{{ord}}</td>
          <td class="num padL pad"></td>
        </tr>
      </table>
    </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: 240px;
        height: 90px;
        pointer-events: none;
        transition: opacity 1s;
        margin-top: -8%;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        border: 1px solid #C4C4C4;
        border-radius: 5px;
      }

      .highlightred {
        color: indianred;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .highlightgreen {
        color: #379862;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .highlightrednoborder {
        color: indianred;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .highlightgreennoborder {
        color: #379862;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .nonhighlight {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .nonhighlightnoborder {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .padR {
        padding-right: 10px;
      }

      .padL {
        padding-left: 10px;
      }

      .mrgR {
        border-right: #C4C4C4 1px solid;
      }

      .mrgD {
        border-bottom: #C4C4C4 1px solid;
      }

      .num {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
      }

      .num_label {
        width: 100%;
        color: darkblue;
        margin-left: 5px;
        margin-right: 5px;
      }

      .cGrey {
        color: lightgrey;
      }

      .cRed {
        color: indianred;
      }
    `,
  ],
})
export class CustomtooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data;
  public color!: string;
  public ord = 0;
  non_pre_inv = 0;
  sug = 0;
  pre = 0;
  pre_sug = 0;
  sug_total = 0;
  clos_sug = 0;
  act_clos = 0;
  act_total = 0;
  public enable = true;
  vanpreorder = false;
  van_preorder = [];
  loadoutedit = false;
  total_pre = false;
  last_started = false;
  isTotal = false;
  sugPresent = false;

  agInit(params: { color: string } & ITooltipParams): void {
    this.enable = true;
    this.params = params;
    this.data = this.params.data[this.params.colDef['field']];
    this.last_started = this.data.last_started;
    this.color = this.params.color || 'white';
    if (this.params.data.VAN === 'REQUESTED' || this.params.data.VAN === 'PRODUCED QTY' || this.params.data.VAN === 'AVAILABLE QTY' ||
      this.params.data.VAN === 'SUGGESTED' || this.params.data.VAN === 'TOTAL QTY' || this.params.data.VAN === 'FINAL REQ'
      || this.params.data.VAN === 'BATCH SCALE' || this.params.data.VAN === 'RESET TO 0') {
      this.isTotal = true;
      this.processTotalRowData();
    } else {
      const indrowdata = this.processIndividualData(this.data);
      this.getFinalValues(indrowdata);
    }
  }

  processIndividualData(indrowdata) {
    indrowdata.ord = indrowdata.prvs_qty;
    /* if (indrowdata.ordered !== null && indrowdata.ordered !== undefined) {
       indrowdata.ord = indrowdata.ordered;
     }*/
    indrowdata.sug = parseFloat(indrowdata.ord_sug) - parseFloat(indrowdata.pre_sug);
    indrowdata.pre = indrowdata.pre;
    indrowdata.pre_sug = indrowdata.pre_sug;
    indrowdata.clos_sug = indrowdata.clos_sug;
    indrowdata.act_clos = indrowdata.act_clos;
    if ((indrowdata.van_preorder && indrowdata.van_preorder.length > 0) || indrowdata.total_pre) {
      indrowdata.vanpreorder = true;
      this.vanpreorder = true;
    } else {
      indrowdata.vanpreorder = false;
      this.vanpreorder = false;
    }
    indrowdata.sug_total = indrowdata.sug + indrowdata.pre_sug - indrowdata.clos_sug;
    indrowdata.act_total = indrowdata.act_total;
    if (indrowdata.act_total < 0) {
      indrowdata.act_total = 0;
    }
    if (indrowdata.sug_total < 0) {
      indrowdata.sug_total = 0;
    }

    if (indrowdata.sug > 0 || indrowdata.pre_sug > 0) {
      this.sugPresent = true;
    } else {
      this.sugPresent = false;
    }

    /* if (indrowdata.find_prod === null || indrowdata.find_prod === undefined) {
       indrowdata.ord = 0;
     } else if (indrowdata.find_prod !== null && indrowdata.find_prod !== undefined) {
       if (indrowdata.find_prod.state === 'draft') {
         indrowdata.ord = indrowdata.find_prod.ordered_qty;
       } else {
         indrowdata.ord =  indrowdata.find_prod.delivered_qty;
       }
     }*/

    /* if (indrowdata.prvs_qty !== null && indrowdata.prvs_qty !== undefined && (indrowdata.prvs_qty !== indrowdata.sug) && indrowdata.qty !== null && indrowdata.qty !== undefined) {
       indrowdata.loadoutedit = true;
       indrowdata.ord = indrowdata.qty;
     } else {
       indrowdata.loadoutedit = false;
     }*/

    let deliveredqty_present = false;
    let orderqty_present = false;

    if (this.data.find_prod !== null && this.data.find_prod !== undefined && this.data.find_prod.length > 0
      && this.data.find_prod[0].hasOwnProperty('delivered_qty')) {
      deliveredqty_present = true;
    } else {
      deliveredqty_present = false;
    }

    if (this.data.find_prod !== null && this.data.find_prod !== undefined && this.data.find_prod.length > 0
      && this.data.find_prod[0].hasOwnProperty('ordered_qty') && this.data.find_prod[0].ordered_qty > 0) {
      orderqty_present = true;
    } else {
      orderqty_present = false;
    }

    if (this.data.find_prod !== null && this.data.find_prod !== undefined && this.data.find_prod.length > 0
      && this.data.find_prod[0].hasOwnProperty('delivered_qty') && this.data.find_prod[0].delivered_qty > 0) {
      indrowdata.loadoutedit = false;
      this.loadoutedit = false;
    } else {
      indrowdata.loadoutedit = true;
      this.loadoutedit = true;
    }

    if (deliveredqty_present && orderqty_present
      && this.data.find_prod[0].delivered_qty !== this.data.find_prod[0].ordered_qty
    ) {
      indrowdata.loadoutedit = true;
      this.loadoutedit = true;
    } else if (!this.sugPresent && indrowdata.pre > 0
      && deliveredqty_present && this.data.find_prod[0].delivered_qty !== indrowdata.pre
    ) {
      indrowdata.loadoutedit = true;
      this.loadoutedit = true;
    } else {
      indrowdata.loadoutedit = false;
      this.loadoutedit = false;
    }


    /*if (this.data.find_prod !== null && this.data.find_prod !== undefined && this.data.find_prod.length > 0 &&
      this.data.find_prod[0].delivered_qty > 0) {
      this.loadoutedit = true;
      this.ord = this.data.qty;
    } else {
      this.loadoutedit = false;
    }*/

   /* if (indrowdata.ordered !== null && indrowdata.ordered !== undefined && indrowdata.ordered > 0) {
      indrowdata.non_pre_inv = indrowdata.ord - indrowdata.pre + indrowdata.act_clos;
    } else {
      indrowdata.non_pre_inv = indrowdata.act_total - indrowdata.pre + indrowdata.act_clos;
    }*/

    if (indrowdata.sug === 0 && !this.loadoutedit && indrowdata.ord !== null && indrowdata.ord !== undefined && indrowdata.ord > 0) {
      indrowdata.non_pre_inv = indrowdata.ord - indrowdata.pre + indrowdata.act_clos;
    } else {
      indrowdata.non_pre_inv = indrowdata.act_total - indrowdata.pre + indrowdata.act_clos;
    }
   /* if (this.loadoutedit) {
      indrowdata.non_pre_inv = indrowdata.act_total - indrowdata.pre + indrowdata.act_clos;
    } else {
      indrowdata.non_pre_inv = indrowdata.ord - indrowdata.pre + indrowdata.act_clos;
    }*/

    if (indrowdata.non_pre_inv < 0) {
      indrowdata.non_pre_inv = 0;
    }


    return indrowdata;
  }

  getFinalValues(finalData) {
    this.non_pre_inv = finalData.non_pre_inv;
    this.sug = finalData.sug;
    this.pre = finalData.pre;
    this.pre_sug = finalData.pre_sug;
    this.act_clos = finalData.act_clos;
    this.clos_sug = finalData.clos_sug;
    this.act_total = finalData.act_total;
    this.sug_total = finalData.sug_total;
    this.ord = finalData.ord;
    this.van_preorder = finalData.van_preorder;
    this.loadoutedit = finalData.loadoutedit;
  }

  processTotalRowData() {
    const totaldata = {
      ordered: 0,
      non_pre_inv: 0,
      sug: 0,
      pre: 0,
      qty: 0,
      prvs_qty: 0,
      pre_sug: 0,
      ord_sug: 0,
      clos_sug: 0,
      act_clos: 0,
      act_total: 0,
      sug_total: 0,
      ord: 0
    };

    const rowdata = this.params.context.componentParent.rowData;

    rowdata.forEach(indrow => {
      if (indrow.hasOwnProperty(this.params.colDef['field'])) {
        let indrowdata = indrow[this.params.colDef['field']];
        indrowdata = this.processIndividualData(indrowdata);
        totaldata.non_pre_inv += indrowdata.non_pre_inv;
        totaldata.sug += indrowdata.sug;
        totaldata.pre += indrowdata.pre;
        totaldata.pre_sug += indrowdata.pre_sug;
        totaldata.act_clos += indrowdata.act_clos;
        totaldata.clos_sug += indrowdata.clos_sug;
        totaldata.act_total += indrowdata.act_total;
        totaldata.sug_total += indrowdata.sug_total;
        totaldata.ord += indrowdata.ord;
      }
    });
    this.getFinalValues(totaldata);
  }

}
