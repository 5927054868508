import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ErrorHandler} from "../../error/error-handler";
import {ErrortooltipComponent} from "../../errortooltip";
import {OutstandingUploadComponent} from "./upload/outstanding_upload.component";
import {CustomerCreatePopupComponent} from "./create_popup/customer_create_popup.component";
import {RetailerAttributeUploadComponent} from "./upload/retailer_attribute_upload.component";
import {NewRetailerUploadComponent} from "./upload/new_retailer_upload.component";
import {ChangePromoterPopupComponent} from "../../retailers/change_promoter_popup/change_pomoter.component";


@Component({
  selector: 'app-dms-customer-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class CustomerListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Active',
    key: 'status',
    value: 'active',
    values: [],
    show: true
  };
  retailer_ids = [];
  matActionMenuItemDeclaration = [{name: 'Upload Customer Attributes', feature: 'feature_create_retailer'},
                                  {name: 'Upload Outstanding', feature: 'feature_upload_outstanding'},
                                  {name: 'Change Promoter', feature: 'feature_change_promoter'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {
// 'feature_upload_customer_attributes'
    this.sharedService.setHeader('Customers');
    this._localCacheService.getAccessControlList().then(list => {
      this.showCreate = list['feature_create_retailer'];
      this.showUpload = list['action_beat_plan_create'];
    });
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'active', itemName: 'Active'},
      {id: 'inactive', itemName: 'InActive'},
    ];

    this.checkAndLoadPreviousSate();

    this.showCustomFilter = true;
    this.showActionButton = true;
    this.showDate = false;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount_untaxed'];
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {

    this.API_URL = '/api/pwa_dms_connects/get_customers_for_dms';

    const ActiveCellClassRules = {
      'rag-green-outer': (params) => params.value === 'true',
      // 'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'false',
    };
    this.columnDefs = [
      {headerName: "id", field: "id", hide: true},
      {headerName: 'Name', field: 'name', width: 250, checkboxSelection: true, headerCheckboxSelection: true},
      {headerName: "Code", field: "code", width: 100},
      {headerName: "Mobile", field: "mobile", width: 100},
      {headerName: "Gstin", field: "gst_id", width: 80},
      {headerName: "Type", field: "type", width: 100},
      {headerName: "Class", field: "class_type", width: 50},
      {headerName: "Program", field: "program", width: 120},
      {headerName: "Territory", field: "territory", width: 120},
      {
        headerName: "Beat", field: "beats", width: 100,
        valueGetter(params) {
          if (params.data.beats) {
            const beats = [...new Set(params.data.beats.map(item => item.name))];
            return beats.join();
          } else {
            return params.data.beats;
          }
        }
      },
      {headerName: "Outstanding", field: "outstanding", width: 100, cellRenderer: 'outstandingShowRenderer', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "Credits", field: "credit", width: 100, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value) },
      {headerName: "PROMOTER CODE", field: "promoter_code", width: 50, hide: true},
      {headerName: "PROMOTER", field: "promoter", width: 100},
      {headerName: "Region", field: "region", width: 100, hide: true},
      {headerName: "Cluster", field: "cluster", width: 120, hide: true},
      {headerName: "Territory", field: "territory", width: 120, hide: true},
      {headerName: "Territory_ID", field: "territory_id", hide: true},
      {headerName: "District", field: "zone", width: 80},
      {headerName: "Area", field: "area", width: 80},
      {headerName: "Zip Code", field: "zip", width: 80},
      {headerName: "City", field: "city", width: 80},
      {headerName: "State", field: "state", width: 80},
      {headerName: "Verified", field: "is_verified", width: 60},
      {headerName: "user_id", field: "user_id", hide: true},
      {headerName: "Created By", field: "created_by", width: 90, hide: true},
      {headerName: "Create Date", field: "create_date", width: 90, hide: true},

    ];

    this.getRowId = (params) => params.data.id;
  }


  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.pageData = [];
    this.selectedCount = 0;
    this.showApprove = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    if (this.showCustomFilter1) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.userData.customFilter[this.customFilter1.key] = this.customFilter1.value;
      }
    }

    const direct_customer = this.sharedService.ACLcheck('show_direct_customers_only');
    if (direct_customer) {
      this.userData.customFilter['feature'] = 'show_direct_customers_only';
    }

    //Load Data in one shot
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data; //TODO: check if this line is needed
            this.storeCustomerData(this.apiData);
            this.fetchCustomerSupplierBalance();

          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  fetchCustomerSupplierBalance() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      retailer_ids: this.retailer_ids
    };
    this.apiService.post('/api/res_partners/get_customer_supplier_balance', paramObject)
      .subscribe(res1 => {
        console.log(res1);
        // t.stop();
        if (res1.hasOwnProperty('results') && (res1.results.status === 200)) {
          this.processData(res1.results.data);
        }
      });
  }

  processData(supplierBalanceData) {
    if (supplierBalanceData) {
      this.apiData.forEach(row => {
        const supplierBalanceRow = supplierBalanceData.find(x => x.partner_id === row.id);
        if (supplierBalanceRow) {
          row.outstanding = supplierBalanceRow.balance;
          row.credit = supplierBalanceRow.credit;
        }
      });
    }
    this.rowData = this.apiData;
    //this.rowData should be ready now
    this.preloader = false;
    if (this.rowData === null || this.rowData === undefined) {
      this.emptyState = true;
    } else if (this.rowData.length === 0) {
      this.emptyState = true;
    } else {
      this.dataLoaded = true;
    }
    return;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
        filteredRows.push(r.data);
      });

      if (filteredRows && filteredRows.length > 0) {
        this.storeCustomerData(filteredRows);
      }

      localStorage.setItem('customerid', rows[0].id);
      const index = JSON.parse(localStorage.getItem("customerdata")).indexOf(rows[0].id);
      localStorage.setItem('customerindex', index);

      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/customers/retailers/details'], {
        state: {
          viewtype: 'detail',
          customerid: rows[0].id
        }
      });
    }
  }

  // TODO: get rid of this backward coompatatiblity code
  storeCustomerData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    this.retailer_ids = ids;
    localStorage.setItem('customerdata', JSON.stringify(ids));
  }

  checkAccessControl() {
    this.showCreate = this.sharedService.ACLcheck('feature_create_retailer');
  }

  openCreateDialog() {
    const config: MatDialogConfig = {
      width: "600px",
      height: "800px",
      panelClass: 'custom-dialog-container',
      data: {}
    };

    const creatediaeref = this.dialog.open(CustomerCreatePopupComponent, config);


    creatediaeref.componentInstance.refreshPageAction.subscribe(data => {
      if (data) {

        this._localCacheService.remove('customers').then(r => {});
        this.loadServerData();
        // this.dialog.closeAll();
      } else {
        // this.dialog.closeAll();
      }
    });

  }
  //
  showUploadPopup() {
    // this.uploadCustomerAttributesClick();
    this.uploadBeatCustomersClick();
  }

  uploadBeatCustomersClick() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "95vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(NewRetailerUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(NewRetailerUploadComponent, config);
    }
  }

  uploadCustomerAttributesClick() {
    const uploadSampleData = [];
    //this.prepareAttributesUploadSampleData();
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "95vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          sampleData1: uploadSampleData
        }
      };
      creatediaeref = this.dialog.open(RetailerAttributeUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          sampleData1: uploadSampleData
        }
      };
      creatediaeref = this.dialog.open(RetailerAttributeUploadComponent, config);
    }
  }

  changePromoterClick() {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Beat');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 retailers : ' + rows.length + ' selected');
    } else {

      const r_ids = [...new Set(rows.map(obj => obj.id))];

      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "350px",
        data: {
          retailer_ids: r_ids,
        },
        panelClass: 'custom-dialog-container'
      };

      const confirmdiaeref = this.dialog.open(ChangePromoterPopupComponent, config);
      confirmdiaeref.componentInstance.changeData.subscribe(status => {
        if (status === 'success') {
          this.dialog.closeAll();
          window.location.reload();
        }
      });
    }
  }


  uploadCustomerOutstandingClick() {
    const uploadSampleData = this.prepareUploadSampleData();
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          sampleData1: uploadSampleData
        }
      };
      creatediaeref = this.dialog.open(OutstandingUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          sampleData1: uploadSampleData
        }
      };
      creatediaeref = this.dialog.open(OutstandingUploadComponent, config);
    }
  }

  prepareUploadSampleData() {
    if (this.rowData && this.rowData.length > 0) {
      const uploadSampleData = [];
      this.rowData.forEach(row => {
        const indrow: any = {};
        indrow.Code = row.code;
        indrow['Customer Name'] = row.name;
        indrow['Current Credits'] = row.credit_limit;
        indrow['Current Outstanding'] = row.outstanding;
        indrow['New Credits'] = '';
        indrow['New Outstanding'] = '';
        uploadSampleData.push(indrow);
      });
      return uploadSampleData;
    } else {
      this.toastr.error('No Retailers to Update');
    }
  }

  onMenuItemSelected(menuitem) {

    if (menuitem.name === 'Upload Outstanding') {
      this.uploadCustomerOutstandingClick();
    } else if (menuitem.name === 'Upload Customer Attributes') {
      // this.uploadBeatCustomersClick();
      this.uploadCustomerAttributesClick();
    } else if (menuitem.name === 'Change Promoter') {
      this.changePromoterClick();
    } else {


      const rows = this.gridApi.getSelectedRows();
      if (rows === null || rows === undefined || rows.length === 0) {
        this.toastr.error('Please select at least 1 Order');
      } else if (rows.length > 100) {
        this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
      } else {
        const ids = [];

        if (menuitem.name === 'Confirm Order') {

        }
      }
    }
  }


  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
