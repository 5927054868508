import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AdDirective3} from '../../ad3.directive';
import {SharedService} from '../../_services/shared_service';
import {DomSanitizer} from '@angular/platform-browser';
import {LatLngBounds} from '@agm/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DetailExpenseComponent} from '../detail_expense/detail_expense.component';
import {ToastrService} from 'ngx-toastr';
import {MatMenuTrigger} from '@angular/material';
import {ClaimMaxComponent} from "../../claimMax.component";

declare let google: any;
declare let $: any;
declare let _: any;

@Component({
  selector: 'app-settle_expense-component',
  templateUrl: './settle_expense.component.html',
  styleUrls: ['./settle_expense.component.scss']
})
export class SettleExpenseComponent implements OnInit {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('detailClick') detailClick: ElementRef<HTMLElement>;
  @ViewChild('galleryClick') galleryClick: ElementRef<HTMLElement>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  user;
  public pinnedParams = [];
  res_data;
  expenseData = {};
  expenseDetailsData;
  photo = null;
  API_URL = '';
  user_data = {
    date: null,
    start_date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    expense_id: null,
    se_id: null,
    salesman_id: null,
    user_id: null,
    policy_id: null,
    expense_ids: [],
    class_type: '',
    travel_type_id: null,
    state: '',
    max_claim: false,
    apprtype: 'appr2',
    note: '',
    reason_id: 0,
    mode: null,
    pay_ref:''
  };
  beatActivityData = [];
  public tabIndex = 0;
  public policy_html = ``;
  public agm_map;
  public mapEvent;
  public mapHide = false;
  public mapCenter = {lat: 0, lng: 0};
  public track;
  public map_loader;
  public isModelOpened = true;
  public start_act;
  public end_act;
  public is_act;
  public activity;
  private prevIndex;
  private gridApi;
  private gridColumnApi;
  private gridApi1;
  private gridColumnApi1;
  public columnDefs = [];
  public columnDefs1 = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public expensetypes;
  public meetexpensetypes;
  public apiData = [];
  public rowData1 = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public datavalue;
  public data = [];
  public data1;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public show_approve = false;
  public pinnedTotalData = [
    {
      state: 'TOTAL',
      user_name: 'TOTAL',
      claimamt: 0,
      reject_amt: 0,
      appr_amt: 0
    }];
  public icon_url = {
    url: '../../assets/retailer.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public start_url = {
    url: '../../assets/start.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public end_url = {
    url: '../../assets/end.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public gallery = [];
  public expense_act: any;
  public exp_display_dict = {
    da: 'DA',
    ta: 'TA',
    sa: 'SA',
    travel_company: 'Company Travel',
    stay_company: 'Company Stay'
  };
  public itemList = [{id: 0, itemName: 'Salesman'}];
  public selectedItems = [{id: 0, itemName: 'Salesman'}];
  public policyList = [{id: 0, itemName: 'Policy'}];
  public policyItems = [{id: 0, itemName: 'Policy'}];
  showableFilters = {
    suggestedfilters: true,
    max_claim: true,
    policy: true,
    salesman: true,
    status: true,
    statusValues: [
      {id: 'new', itemname: 'New'},
      {id: 'pending', itemname: 'Pending'},
      {id: 'approved', itemname: 'Approved'},
      {id: 'settled', itemname: 'Settled'},
      {id: 'rejected', itemname: 'Rejected'},
    ],
    class: true,
    beat: true
  };
  apprtype = 'appr2';
  commentstate = '';
  columnState;
  agGridFilter;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    let date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    //this.myDate[1] = '2021-06-08';

    this.user_data.start_date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params.report_flag;
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_exp_users')));
    this.policyList = this.policyList.concat(JSON.parse(localStorage.getItem('all_expenses')));
    if (localStorage.getItem('beat_activity_types') !== null) {
      this.beatActivityData = JSON.parse(localStorage.getItem('beat_activity_types'));
    }
    this.beatActivityData = this.beatActivityData.filter(bat => bat.action_type === 'Expense Reject Reason');

    if (localStorage.getItem('feature_expense_approve2') === 'true') {
      this.user_data.apprtype = 'appr2';
    } else {
      this.user_data.apprtype = 'appr1';
    }

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
    } as GridOptions;


    if (localStorage.getItem('feature_expense_approve2') === 'true') {
      this.apprtype = 'appr2';
    } else {
      this.apprtype = 'appr1';
    }

    this.expense();

  }

  to_upperCase(str) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  }

  display_name(str) {
    if (this.exp_display_dict[str]) {
      return this.exp_display_dict[str];
    } else if (str) {
      if (str.split('_').length > 1) {
        str = str.split('_')[1];
      }
      return this.to_upperCase(str);
    } else {
      return str;
    }
  }

  userChange(event) {
    //debugger;
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.user_id = event[0].id;
        this.expense();
      } else {
        this.user_data.user_id = null;
        this.expense();
      }
    }
  }

  policyChange(event) {
    //debugger;
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.policy_id = event[0].id;
        this.expense();
      } else {
        this.user_data.policy_id = null;
        this.expense();
      }
    }
  }

  configureData(responseData) {
    this.apiData = [];
    this.expensetypes = new Set();
    this.meetexpensetypes = new Set();
    let claimamt = 0.0;
    let reject_amt = 0.0;
    let appr_amt = 0.0;
    if (responseData !== null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      let master = responseData.master;
      let detail = responseData.detail;

      //this.expensetypes = _.keys(_.countBy(responseData.detail, function(data) { return data.exp_type; }));
      /*  this.expensetypes = responseData.detail.map(p => p.exp_type)
          .filter((type, index, arr) => arr.indexOf(type) === index);*/
      // console.log(this.expensetypes);

      for (let key in master) {

        let indrow = {};
        let masterRow: any = {};
        let detailRow: any = {};
        let detailsLine = [];

        masterRow = master[key];
        claimamt += parseFloat(masterRow.claimamt);
        reject_amt += parseFloat(masterRow.reject_amt);
        appr_amt += parseFloat(masterRow.appr_amt);
        detailsLine = detail.filter(function(dt) {
          return dt.expense_date === masterRow.expense_date && dt.master_id === masterRow.master_id;
        });

        if (detailsLine !== null && detailsLine !== undefined && detailsLine.length > 0) {
          for (const key1 in detailsLine) {
            if (detailsLine[key1].resource_type === 'Meet') {
              detailRow['meet_' + detailsLine[key1].exp_type] = detailsLine[key1].total;
              detailRow['meet_max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;
              this.meetexpensetypes.add(detailsLine[key1].exp_type);
            } else {
              detailRow[detailsLine[key1].exp_type] = detailsLine[key1].total;
              detailRow['max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;
              this.expensetypes.add(detailsLine[key1].exp_type);
            }
          }
        }

        indrow = {
          ...masterRow,
          ...detailRow
        };
        this.apiData.push(indrow);
      }
    }
    this.pinnedTotalData = [
      {
        state: 'TOTAL',
        user_name: 'TOTAL',
        claimamt,
        reject_amt,
        appr_amt
      }];
    this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
    // console.log(this.apiData);
    this.configureGrid();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: '',
        pinned: 'left',
        children: [
          {
            headerName: 'State', field: 'state', pinned: 'left',
            maxWidth: 20,
            valueGetter(params) {
              if (params.data) {
                if (params.data.state === 'TOTAL') {
                  return '';
                } else {
                  return params.data.state;
                }
              }

            },
            cellStyle(params) {
              if (params.data) {
                if (params.data.state === 'approved') {
                  return {background: '#379862', color: '#379862'};
                } else if (params.data.state === 'rejected') {
                  return {background: '#E24260', color: '#E24260'};
                } else if (params.data.state === 'settled') {
                  return {background: '#2D2D2D', color: '#2D2D2D'};
                } else if (params.data.state === 'new') {
                  return {background: '#22A6B3', color: '#22A6B3'};
                } else if (params.data.state === 'TOTAL') {
                  return '';
                } else {
                  return {background: '#F6A82C', color: '#F6A82C'};
                }
              }
            }
          },
          {
            headerName: 'Name', field: 'user_name', pinned: 'left',
            width: 90,
            cellRenderer(params) {
              if (params.data) {
                if (params.data.state === 'TOTAL') {
                  return 'TOTAL';
                } else {
                  return params.value;
                }
              }
            }
          }]
      },
      {
        headerName: '',
        children: [
          {
            headerName: 'Expense Date', field: 'expense_date', width: 100, headerCheckboxSelection: true, checkboxSelection: true,
          },
          {
            headerName: 'Create Date', field: 'create_date', width: 100
          },
          {
            headerName: 'Claimed', field: 'claimamt', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
            width: 60,
            cellRenderer(params) {
              if (params.data) {
                let claimamt = 0.0;
                if (params.value) {
                  claimamt = parseFloat(parseFloat(params.value).toFixed(2));
                }
                if (params.data.max_claim_exceeded) {
                  const displayElem = '<span style="color:#E24260; font-weight: bold">' + claimamt + '</span>';
                  return displayElem;
                } else {
                  return claimamt;
                }
              }
            }
          },
          {
            headerName: 'Rejected', field: 'reject_amt', width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'
          },
          {
            headerName: 'Approved', field: 'appr_amt', width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'
          },
          {
            headerName: 'Code', field: 'emp_code', width: 60
          },
          {
            headerName: 'Policy Code', field: 'policy_code', width: 60
          },
          {
            headerName: 'Policy', field: 'policy', width: 60
          },
          {
            headerName: 'Beat', field: 'beat', width: 60
          },
          {
            headerName: 'Beat Type', field: 'beat_type', width: 60
          },
          {
            headerName: 'City Class', field: 'class_type', width: 60
          },
          {
            headerName: 'First Approver', field: 'approver1', width: 60
          },
          {
            headerName: 'Note', field: 'note', width: 200
          },
          {
            headerName: 'Comments', field: 'comments', width: 200
          },
          {
            headerName: 'Status', field: 'Status', hide: true,
            width: 150,
            valueGetter(params) {
              if (params.data) {
                if (params.data.Status === 'TOTAL') {
                  return '';
                } else {
                  return params.data.Status;
                }
              }
            },
            cellStyle(params) {
              if (params.data) {
                if (params.value === 'approved') {
                  return {color: '#379862'};
                } else if (params.value === 'rejected') {
                  return {color: '#E24260'};
                } else if (params.value === 'settled') {
                  return {color: '#2D2D2D'};
                } else {
                  return {color: '#F6A82C'};
                }
              }
            },
            cellRenderer(params) {
              if (params.data) {
                if (params.data.Status === 'TOTAL') {
                  return '';
                } else {
                  const displayElem = '<span class="ml-1 status">' + params.value + '</span>';
                  return displayElem;
                }
              }
            }
          },
          {
            headerName: 'Expense ID', field: 'id', width: 60, hide: true
          },
          {
            headerName: 'User ID', field: 'user_id', width: 60, hide: true
          },
          {
            headerName: 'Policy Name', field: 'policy_name', width: 60, hide: true
          }]
      },
      {
        headerName: 'User Expenses',
        children: []
      },
      {
        headerName: 'Meet Expenses',
        children: []
      },
    ];
    this.rowData = this.apiData;
    this.loaded = true;
    this.context = {componentParent: this};
    this.frameworkComponents = {
      maxClaimRenderer: ClaimMaxComponent
    };
    if (this.expensetypes) {
      for (const key of this.expensetypes) {
        this.columnDefs[2].children.push({
          headerName: key,
          field: key,
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

    if (this.meetexpensetypes) {
      for (const key of this.meetexpensetypes) {
        this.columnDefs[3].children.push({
          headerName: key,
          field: 'meet_' + key,
          width: 100,
          aggFunc: 'sum'
        });
      }
    }
  }

  expense() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];

    //debugger;
    this.res_data = this.apiService.settleExpenses(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.configureData(res.results.data);
        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        // console.log(error);
        this.preloader = false;
      });
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column !== 'Date' && column !== 'Policy') {
      this.viewDetails('details');
      this.tabIndex = 0;
      if (this.prevIndex !== event.rowIndex) {
        if (event.node.selected) {
          this.show_approve = true;
          this.prevIndex = event.rowIndex;
        }
      } else {
        if (event.node.selected) {
          this.show_approve = true;
        } else {
          this.show_approve = false;
        }
        this.prevIndex = event.rowIndex;
      }
    } else if (column === 'Policy') {
      // debugger;
      this.policy_html = event.data.policy_html + event.data.line_html;
      const el: HTMLElement = this.galleryClick.nativeElement;
      el.click();
    }
  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected) {
        this.show_approve = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected) {
        this.show_approve = true;
      } else {
        this.show_approve = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  rejectSales(exp) {
    this.user_data.expense_ids = [exp.se_id];
    this.res_data = this.apiService.expenseSalesReject(this.user_data);
    this.res_data.subscribe(res => {
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        exp.state = 'rejected';
        this.toastr.success('Rejected Successfully');
      } else {
        this.toastr.error('Rejection Failure');
      }
    }, error => {
      // console.log (error);
      this.toastr.error('Rejection Failure');
    });
  }

  reject() {
    const expense_ids = [];
    const rows = this.gridApi.getSelectedRows();
    rows.forEach(function(data) {
      expense_ids.push(data.id);
    });
    this.user_data.expense_ids = expense_ids;
    this.user_data.apprtype = this.apprtype;
    this.res_data = this.apiService.expenseReject(this.user_data);
    this.res_data.subscribe(res => {
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Rejected Successfully');
        this.show_approve = false;
        this.expense();
      } else {
        this.toastr.error('Rejection Failure');
      }

    }, error => {
      // console.log(error);
      if (error.status === 409) {
        this.toastr.warning('Clear individual approved expenses!');
      } else {
        this.toastr.error('Rejection Failure');
      }
    });
  }

  settle() {
    const expense_ids = [];
    const rows = this.gridApi.getSelectedRows();
    rows.forEach(function(data) {
      expense_ids.push(data.id);
    });
    this.user_data.expense_ids = expense_ids;
    this.res_data = this.apiService.expenseSettle(this.user_data);
    this.res_data.subscribe(res => {
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Settled Successfully');
        this.show_approve = false;
        this.expense();
      } else {
        this.toastr.error('Settlement Failure');
      }

    }, error => {
      // console.log(error);
      this.toastr.error('Settlement Failure');
    });
  }

  approveDlg(flag) {
    //debugger;
    this.expense_act = flag;
    const el: HTMLElement = this.imageClick.nativeElement;
    el.click();

  }

  commentDlg(flag) {
    this.expense_act = flag;
    if (flag === 'reject') {
      this.commentstate = 'Reject';
    } else {
      this.commentstate = 'Settle';
    }
    $('#commentsModel').modal('show');
  }


  approve() {
    const expense_ids = [];
    const rows = this.gridApi.getSelectedRows();
    rows.forEach(function(data) {
      expense_ids.push(data.id);
    });
    this.user_data.expense_ids = expense_ids;
    this.res_data = this.apiService.expenseApproval(this.user_data);
    this.user_data.apprtype = this.apprtype;
    // console.log(this.user_data);
    this.res_data.subscribe(res => {
      // console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Approved Successfully');
        this.show_approve = false;
        this.expense();
      } else {
        this.toastr.error('Approve Failed');
      }
    }, error => {
      // console.log(error);
      this.toastr.error('Approve Failed');
    });
  }

  viewDetails(check) {
    const rows = this.gridApi.getSelectedRows();
    this.user_data.expense_id = rows[0].id;
    this.user_data.date = rows[0].Date;
    this.user_data.salesman_id = rows[0].user_id;
    this.expenseData = rows[0];
    let images = [];
    this.res_data = this.apiService.expenseDetails(this.user_data);
    this.res_data.subscribe(async res => {
      // console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        this.expenseDetailsData = res.results.data;
        for (const exp of this.expenseDetailsData) {
          images = [];
          exp.policy_name = rows[0].policy_name;
          exp.photo = null;
          if (exp.gallery.length > 0) {
            exp.gallery.forEach(image => {
              if (image != null) {
                images.push({store_fname: image.store_fname, container: 'expense_gallery', type: image.type});
              }
            });
            exp.gallery = [];
            if (images.length > 0) {
              // console.log(this.user_data);
              const payload = {
                access_token: this.user_data.access_token,
                url: this.user_data.url,
                data: images
              };
              const res1 = await this.apiService.fetchExpenseImage(payload);
              if (res1.hasOwnProperty('result') && res1['result'].length > 0) {
                // console.log(res);
                const domSanitizer = this.domSanitizer;
                let img;
                res1['result'].forEach(photo => {
                  if (!exp.photo) {
                    if (photo.type && photo.type === 'pdf') {
                      exp.photo = './../../../assets/pdf_icon.png';
                    } else {
                      exp.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'][0].bill_datas);
                    }
                  }
                  if (photo.type && photo.type === 'pdf') {
                    img =  domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + photo.bill_datas);
                  } else {
                    img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
                  }
                  exp.gallery.push(
                    {
                      photo: img,
                      type: photo.type
                    });
                });
              }
            }
          } else {
            exp.gallery = [];
          }

          if (exp.exp_type === 'travel') {
            exp.travelpath = '';
            if (!Array.isArray(exp.travel_path_locations)) {
              exp.travel_path_locations  = JSON.parse(exp.travel_path_locations);
            }
            if (exp.travel_path_locations !== null) {
              for (let i = 0; i < exp.travel_path_locations.length; i++) {
                if (exp.travel_path_locations[i]['area']) {
                  exp.travelpath = exp.travelpath.concat(exp.travel_path_locations[i].area + ' => ');
                }
              }
            }
           if (exp.travelpath && exp.travelpath.length > 3) {
             exp.travelpath =  exp.travelpath.slice(0, -4);
           }

          }

        }
        if (check === 'approve') {
          const el: HTMLElement = this.imageClick.nativeElement;
          el.click();
        } else {
          const el: HTMLElement = this.detailClick.nativeElement;
          el.click();
        }
      }
    }, error => {
      // console.log(error);
    });
  }

  close() {
    this.isModelOpened = true;
  }

  view_gallery(photo) {
    this.photo = photo;
    this.isModelOpened = false;
  }

  tabClick(event) {
    // debugger;
    if (event.index === 1) {
      this.map_loader = true;
      const trackData = [];
      const datePipe = this.datePipe;
      this.res_data = this.apiService.getTrack(this.user_data);
      let lat, lng;

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          res.results.data.forEach(function(data) {
            if (data.log !== null) {
              data.log.forEach(function(tracklog) {
                if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
                  trackData.push({
                    lat: tracklog.lat,
                    lng: tracklog.lng
                  });
                }
              });
            }
          });
        }
        if (parseFloat(trackData[0].lat) > 0) {
          this.mapCenter.lat = parseFloat(trackData[0].lat);
          this.mapCenter.lng = parseFloat(trackData[0].lng);
        }
        this.track = trackData;
        this.map_loader = false;
      });

      const actData = [];

      this.res_data = this.apiService.getActivity(this.user_data);

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(function(data) {
            if (parseInt(data.lat, 10) > 0 && parseInt(data.long, 10) > 0) {
              actData.push({
                id: data.retailer_id,
                lat: data.lat,
                lng: data.long,
                retailer: data.retailer_name,
                activity: data.actions,
                device_time: datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                comments: data.comments
              });
            }
          });
          if (parseFloat(actData[0].lat) > 0) {
            this.mapCenter.lat = parseFloat(actData[0].lat);
            this.mapCenter.lng = parseFloat(actData[0].lng);
          }
        }

        if (actData.length > 0) {
          this.start_act = actData[0];
          this.end_act = actData[actData.length - 1];
          actData.splice(0, 1);
          actData.splice(actData.length - 1, 1);
          this.is_act = true;
        }
        this.activity = actData;
        this.mapHide = true;
        this.foo(this.mapEvent);
      });
    } else if (event.index === 2) {
      this.empty_state1 = false;
      this.loaded1 = false;
      this.preloader1 = true;
      const beatData = [];
      const datePipe = this.datePipe;

      this.res_data = this.apiService.getBeatAct(this.user_data);

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(function(data) {
            beatData.push({
              'Activity Type': data.activity_type,
              Beat: data.beat,
              Retailer: data.retailer,
              'Comment Type': data.comment_type,
              Comments: data.comments,
              device_time: datePipe.transform((new Date(data.device_date + ' UTC')).toString(), 'HH:mm:ss'),
              Latitude: data.latitude,
              Longitude: data.longitude,
              Distance: data.distance,
              'Created On': datePipe.transform((new Date(data.create_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
            });
          });
          this.data1 = beatData[0];
          this.rowData1 = beatData;


          for (const key in this.data1) {
            if (this.data1.hasOwnProperty(key)) {
              this.columnDefs1.push({
                headerName: key, field: key, lockPosition: true, sort: true
              });
            }
          }
          this.loaded1 = true;
        } else {
          this.preloader1 = false;
          this.empty_state1 = true;
        }
      }, error => {
        // console.log(error);
        this.preloader1 = false;
      });
    }
  }

  foo(event) {
    //debugger;
    this.mapEvent = event;
    // console.log(event);
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;

    for (const mm of this.track) {
      bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    }
    this.agm_map.fitBounds(bounds);
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const inThis = this;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    // console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
        params.columnApi.autoSizeAllColumns();
      });
    });
    if (screen.width > 991) {
      // params.api.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }
    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.user_data,
      searchText: this.searchValue,
      agGridFilter: this.agGridFilter
    };
    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterModified(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.user_data,
      searchText: this.searchValue,
      agGridFilter: this.agGridFilter
    };
    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      // params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }
  }

  ngOnInit() {
    // debugger;
    this.sharedService.setHeader('Expenses');

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  changeDate(dt) {
    //debugger;
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.expense();
  }

  openDetailDialog() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '720px',
      maxHeight: '720px',
      data: {
        expenseData: this.expenseData,
        expenseDetailsData: this.expenseDetailsData,
        rows: this.gridApi.getSelectedRows(),
        user_data: this.user_data,
        dialogType: 'admin'
      }
    };

    this.dialog.open(DetailExpenseComponent, config);
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  downloadImage(base64content, filename) {
    const blobData = this.convertBase64ToBlobData(base64content);
    // filename = "test.png"
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
      window.navigator.msSaveOrOpenBlob(blobData, filename);
    } else { // chrome
      const blob = new Blob([blobData], {type: 'base64'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
  }

  async downloadExpenseFiles() {
    const rows = this.gridApi.getSelectedRows();
    for (let row of rows) {
      // console.log(row);
      this.user_data.expense_id = row.id;
      this.user_data.date = row.Date;
      this.user_data.salesman_id = row.user_id;
      this.expenseData = row;
      let images = [];
      this.res_data = this.apiService.expenseDetailsasync(this.user_data);
      let res = await this.apiService.expenseDetailsasync(this.user_data);
      //this.res_data.subscribe(async res => {
      // console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        this.expenseDetailsData = res['results'].data;
        for (const exp of this.expenseDetailsData) {
          images = [];
          exp.photo = null;
          if (exp.gallery.length > 0) {
            exp.gallery.forEach(image => {
              if (image !== null) {
                images.push({store_fname: image, container: 'expense_gallery'});
              }
            });
            if (images.length > 0) {
              const res = await this.apiService.fetchExpenseImage({
                access_token: this.user_data.access_token,
                url: this.user_data.url,
                data: images
              });
              if (res.hasOwnProperty('result') && res['result'].length > 0) {
                exp.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'][0].bill_datas);
                exp.gallery = [];
                const domSanitizer = this.domSanitizer;
                res['result'].forEach(function(photo) {
                  exp.gallery.push(photo.bill_datas);
                });
              }
            }
          }
        }
        for (let exp of this.expenseDetailsData) {
          if (exp.gallery !== null && exp.gallery.length > 0) {

            for (let i = 0; i < exp.gallery.length; i++) {
              // console.log(exp);
              if (exp.gallery[i] !== null) {
                this.downloadImage(exp.gallery[i], exp.salesman + '_' + exp.expense_date + '_' + i + '.png');
              }
            }
          }
        }
      }
      /* }, error => {
         // console.log(error);
       });*/
    }

  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  gridSearch(searchText) {
    this.gridApi.setQuickFilter(searchText);
  }

  setFilter(filteredData: any) {
    // console.log(filteredData);

    if (filteredData.policyID !== null && filteredData.policyID !== 0) {
      this.user_data.policy_id = filteredData.policyID;
    } else {
      this.user_data.policy_id = null;
    }

    if (filteredData.salesman_id !== null && filteredData.salesman_id !== 0) {
      this.user_data.user_id = filteredData.salesman_id;
    } else {
      this.user_data.user_id = null;
    }

    if (filteredData.class_type !== null && filteredData.class_type !== '') {
      this.user_data.class_type = filteredData.class_type;
    } else {
      this.user_data.class_type = '';
    }

    if (filteredData.travel_type_id !== null && filteredData.travel_type_id !== 0) {
      this.user_data.travel_type_id = filteredData.travel_type_id;
    } else {
      this.user_data.travel_type_id = null;
    }

    if (filteredData.status !== null && filteredData.status !== '') {
      this.user_data.state = filteredData.status;
    } else {
      this.user_data.state = "";
    }

    this.user_data.max_claim = filteredData.max_claim;

    this.trigger.closeMenu();
    this.expense();
  }
}
