import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-new-customer-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class NewRetailerUploadComponent extends BaseUpload {

  async setViewParams() {
    this.header = 'New Customer Upload';
    this.sampleText1 = 'DOWNLOAD TEMPLATE';
    this.sampleText2 = '';

    this.excel_columns = [{C: 'A', H: 'Code', V: 'ES2355', required: false},
      {C: 'B', H: 'Customer Name', V: 'Shobha Stores Pvt Ltd', required: true},
      {C: 'C', H: 'Territory', V: '[Territory Code/Name/ID]', required: true, T: 'FK', field: 'territory', DBF: 'territory_id'},
      {C: 'D', H: 'Beat', V: '[Beat Code/Name/ID]', required: true, T: 'FK', field: 'beat_plan_id', DBF: 'beat_plan_id'},
      {C: 'E', H: 'Type', V: 'Wholesale', required: true, T: 'FK', field: 'type', DBF: 'retailer_type', validation: {null_check: true}},
      {C: 'F', H: 'Mobile', V: '9480439477', required: false, T: 'int', field: 'mobile', DBF: 'mobile', validation: {null_check: false, regex: '^[6-9]\\d{9}$'}},
      {C: 'G', H: 'Tax Id', V: '29AALCA496', required: false, T: 'text', field: 'gst_id', DBF: 'gst_id', validation: {null_check: false, regex: '^(\\d{2})[a-zA-z]{5}\\d{4}[a-zA-Z]{1}\\d{1}[a-zA-Z\\d]{1}[zZ]{1}[a-zA-Z\\d]{1}$'}},
      {C: 'H', H: 'Class', V: 'A', required: false, T: 'text', field: 'class', DBF: 'class'},
      {C: 'I', H: 'Promoter', V: '[EMP Name/Code]', required: false, T: 'FK', field: 'promoter', DBF: 'user_id'},
      {C: 'J', H: 'Street 1', V: '365, 2nd Cross', required: false, T: 'text', field: 'street', DBF: 'street'},
      {C: 'K', H: 'Street 2', V: 'MG Road', required: false, T: 'text', field: 'street2', DBF: 'street2'},
      {C: 'L', H: 'Zip', V: '560043', required: false, T: 'int', field: 'zip', DBF: 'zip'},
      {C: 'M', H: 'City', V: 'Bengaluru', required: false, T: 'text', field: 'city', DBF: 'city'},
      {C: 'N', H: 'State', V: 'Karnataka', required: true, T: 'FK', field: 'state', DBF: 'state_id'},
      {C: 'O', H: 'Balance', V: '5000', required: false, T: 'int', field: 'balance', DBF: 'balance'},
      {C: 'P', H: 'Payment Mode', V: '[Cash/Credit]', required: false, T: 'text', field: 'payment_mode', DBF: 'payment_mode'},
      {C: 'Q', H: 'Credit Limit', V: '10000', required: false, T: 'numeric', field: 'credit_limit', DBF: 'credit_limit'},
      {C: 'R', H: 'Credit Days', V: '10000', required: false, T: 'int', field: 'cl_days', DBF: 'cl_days'},
    ];
    this.step1Text = 'Download all customer list or sample template for reference.';
    this.step2Text = 'Required fields are name, beat,  type, state';
    this.actionText = 'Fill excel with new customers';
    // this.loadMasterDataForCustomerCreate();
    await this.fetchCustomerData();
    await this.fetchBeatsData();
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData( 'new_customer_upload');
  }

  sampleDownload2() {
    // this.downloadSampleData('new_customer_upload_template');
  }
  configureGrid() {
    this.API_URL = '/api/pwa_admin_connects/upload_new_customers';
    this.columnDefs = [
      {headerName: 'ID', field: 'uid', width: 100, hide: true},
      {headerName: 'Code', field: 'code', width: 100},
      {headerName: 'Customer Name', field: 'name', width: 200},
      {headerName: 'Territory', field: 'territory', width: 100},
      {headerName: 'Territory_ID', field: 'territory_id', width: 100, hide: true},
      {headerName: 'Beat', field: 'beat', width: 100},
      {headerName: 'Beat_ID', field: 'beat_plan_id', width: 100, hide: true},
      {headerName: 'Type', field: 'type', width: 100},
      {headerName: 'Class', field: 'class', width: 100},
      {headerName: 'Salesman', field: 'salesman', width: 100},
      {headerName: 'Mobile', field: 'mobile', width: 100},
      {headerName: 'Tax Id', field: 'gst_id', width: 100},
      {headerName: 'Street 1', field: 'street', width: 100},
      {headerName: 'Street 2', field: 'street2', width: 100},
      {headerName: 'City', field: 'city', width: 100},
      {headerName: 'Zip', field: 'zip', width: 100},
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Promoter', field: 'promoter', width: 100},
      {headerName: 'Balance', field: 'balance', width: 100},
      {headerName: 'Payment Mode', field: 'payment_mode', DBF: 'payment_mode'},
      {headerName: 'Credit Limit', field: 'credit_limit', DBF: 'credit_limit'},
      {headerName: 'Credit Days', field: 'cl_days', DBF: 'cl_days'}
    ];
  }

  async fetchCustomerData() {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_customers_for_dms', this.userData);
    if (res && res.results.status === 200) {
      this.customerList = res.results.data;
    } else {
      this.toastr.error('Error fetching custer master');
    }
  }


  async fetchBeatsData() {
    const res = await this.apiService.postPromise('/api/beat_plans/get_beat_plans_list_for_dropdown', this.userData);
    if (res && res.results.status === 200) {
      this.beatList = res.results.data;
    } else {
      this.toastr.error('Error fetching Beat master');
    }
  }

  loadMasterDataForCustomerCreate() {

    // if (localStorage.getItem('all_states') != null) {
    //   this.stateList = JSON.parse(localStorage.getItem('all_states'));
    // }
    // if (localStorage.getItem('all_out_types') != null) {
    //   this.customerTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    // }

    // this.customerTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    // this.beatList = JSON.parse(localStorage.getItem('terr_beats'));
    // this.stateList = JSON.parse(localStorage.getItem('all_states'));
    // this.LoadDefaultSupplier(callback);
  }


  SetInvalidStatus(row, errMsg, field) {
    row.valid = 'Invalid';
    if (!row.errorDetails) {
      row.errorDetails = {};
    }
    if (!row.errorDetails[field]) {
      row.errorDetails[field] = errMsg;
    } else {
      row.errorDetails[field] += '\n' + errMsg;
    }
    //console.log(row.errorDetails);
  }


  validate() {
    const inThis = this;
    let index = 0;
    this.validRecords = 0;
    this.errorRecords = 0;

    this.uploaded_data.forEach(data => {
      const indrow: any = {};

      indrow['code'] = this.getExcelFieldValue(data, ['Code', 'code']);
      if (indrow['code'] !== undefined && indrow['code'] !== '') {
        const customerObject = inThis.customerList.find(x => (x.hasOwnProperty('code') && x.code === indrow['code']));
        //console.log('customerObject in code check', customerObject);
        if (customerObject) {
          this.SetInvalidStatus(indrow, 'Record exists with same Code, ID: ' + customerObject.id, 'code');
        }
      }

      indrow['name'] = this.getExcelFieldValue(data, ['name', 'Name', 'Customer Name']);
      indrow['beat'] = this.getExcelFieldValue(data, ['beat', 'Beat']);
      indrow['type'] = this.getExcelFieldValue(data, ['Type', 'type']);
      indrow['class'] = this.getExcelFieldValue(data, ['Class', 'class', 'class_type'], 'B');
      indrow['gst_id'] = this.getExcelFieldValue(data, ['Tax Id', 'tax id', 'TAN', 'GST']);
      indrow['mobile'] = this.getExcelFieldValue(data, ['mobile', 'Mobile', , 'Mobile No']);
      indrow['street'] = this.getExcelFieldValue(data, ['Street 1', 'street1', 'street', 'street 1']);
      indrow['street2'] = this.getExcelFieldValue(data, ['Street 2', 'street2', 'street 2']);
      indrow['city'] = this.getExcelFieldValue(data, ['City', 'city']);
      indrow['zip'] = this.getExcelFieldValue(data, ['Zip', 'zip', 'Zip Code']);
      indrow['city'] = this.getExcelFieldValue(data, ['City', 'city']);
      indrow['state'] = this.getExcelFieldValue(data, ['State', 'state']);
      indrow['promoter'] = this.getExcelFieldValue(data, ['Promoter', 'promoter']);
      indrow['balance'] = this.getExcelFieldValue(data, ['Balance', 'balance']);
      indrow['payment_mode'] = this.getExcelFieldValue(data, ['Payment Mode', 'payment mode', 'payment_mode'], 'cash');
      indrow['credit_limit'] = this.getExcelFieldValue(data, ['Credit Limit', 'credit limit', 'credit_limit']);
      indrow['cl_days'] = this.getExcelFieldValue(data, ['Credit Days', 'credit days', 'cl_days']);
      indrow['errorDetails'] = indrow['errorDetails'] || {};


      if (!indrow['name'] || indrow['name'] === '') {
        this.SetInvalidStatus(indrow, 'Name Is mandatory Field', 'name');
      } else {
        const customerObject = inThis.customerList.find(x => (x.name !== undefined && x.name.toLowerCase() === indrow['name'].toLowerCase()));
        if (customerObject) {
          this.SetInvalidStatus(indrow, 'Record exists with same Name, ID: ' + customerObject.id, 'name');
        }
      }


      if (indrow['beat'] && indrow['beat'] !== '') {

        const beat = inThis.beatList.find(x => ((x.name !== undefined && x.name.toLowerCase() === indrow['beat'].toLowerCase()) || (x.id !== undefined && x.id === indrow['beat'])));
        if (!beat) {
          this.SetInvalidStatus(indrow, 'Beat Not Found', 'beat');
        } else {

          const territory = this.territoriesList.find(x => x.id === beat.territory_id);
          if (!territory) {
            this.SetInvalidStatus(indrow, 'Beat Territory Not Found', 'beat');
          } else {
            const salesman = this.userList.find(x => x.id === beat.salesman_id);
            if (!salesman) {
              this.SetInvalidStatus(indrow, 'Beat Salesman Not Found', 'beat');
            } else {

              indrow['beat_plan_id'] = beat.id;
              indrow['territory_id'] = beat.territory_id;
              indrow['supplier_id'] = beat.distributor_id;
              indrow['territory'] = territory.itemname;
              indrow['salesman'] = salesman.itemname;
              indrow['customer'] = true;
              indrow['supplier'] = false;
            }
          }
        }

      } else {

        // If beat is not provided, Territory and Promoter becomes mandatory field
        indrow['territory'] = this.getExcelFieldValue(data, ['territory', 'Territory']);
        if (!indrow['territory'] || indrow['territory'] === '') {
          this.SetInvalidStatus(indrow, 'Beat OR Territory Is mandatory Field', 'territory');
        }

        if (!indrow['promoter'] || indrow['promoter'] === '') {
          this.SetInvalidStatus(indrow, 'Beat OR [Territory, Promoter] Is mandatory Field', 'promoter');
        }

        indrow['territory_id'] = this.getLookupId('territory_id', indrow['territory']);
        if (!indrow['territory_id']) {
          this.SetInvalidStatus(indrow, 'No Match found for Territory', 'territory');
        }
        indrow['user_id'] = this.getLookupId('user_id', indrow['promoter']);
        if (!indrow['user_id']) {
          this.SetInvalidStatus(indrow, 'No Match found for Promoter', 'promoter');
        }

      }


      if (!indrow['type'] || indrow['type'] === '') {
        this.SetInvalidStatus(indrow, 'Type Is mandatory Field', 'type');
      } else {
        indrow['retailer_type'] = inThis.getLookupId('retailer_type', indrow['type']);
        if (!indrow['retailer_type']) {
          this.SetInvalidStatus(indrow, 'Type Not Found', 'type');
        }
      }

      const valid_class = ['A', 'B', 'C', 'D'];
      if (indrow['class'] && indrow['class'] === '') {
        indrow['class'] = indrow['class'].toUpperCase();
      }
      if (!valid_class.includes(indrow['class'])) {
        this.SetInvalidStatus(indrow, 'Invalid class', 'class');
      }

      //Mobile No. Validation
      if (indrow['mobile'] && indrow['mobile'] !== '') {
        const isMobile = /^[6-9]\d{9}$/.test(indrow['mobile']);
        if (isMobile !== true) {
          this.SetInvalidStatus(indrow, 'Mobile No. is not valid', 'mobile');
        }
      } else {
        //Skip
      }

      //GSTIN Validation
      if (indrow['gstin'] && indrow['gstin'] !== '') {
        const gstinRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/;

        const IsValid = gstinRegex.test(indrow['gstin']);
        if (IsValid !== true) {
          this.SetInvalidStatus(indrow, 'Gstin is not valid', 'gstin');
        }
      }

      if (!indrow['state'] || indrow['state'] === '') {
        this.SetInvalidStatus(indrow, 'Type Is mandatory Field', 'state');
      } else {
        indrow['state_id'] = inThis.getLookupId('state_id', indrow['state']);
        if (!indrow['state_id']) {
          this.SetInvalidStatus(indrow, 'State Not Found', 'state');
        }
      }


      if (!indrow['user_id'] && indrow['promoter'] && indrow['promoter'] !== '') {
        indrow['user_id'] = this.getLookupId('user_id', indrow['promoter']);
        if (!indrow['user_id']) {
          this.SetInvalidStatus(indrow, 'Promoter Not Found', 'promoter');
        }
      }

      if (indrow['balance'] && indrow['balance'] !== '' && isNaN(Number(indrow['balance'].toString()))) {
        this.SetInvalidStatus(indrow, 'Balance has to be numeric', 'balance');
      }


      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        this.validRecords++;
      }
      index++;
      indrow['uid'] = index;
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
    this.setCellClassRulesAndTooltips();
  }
  downloadDataWithErrors() {
    super.downloadDataWithErrors();
  }
}
