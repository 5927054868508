import {Component, ElementRef, Inject, OnInit, Renderer2} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {ngxCsv} from "ngx-csv";
import {ExcelUploadComponent} from "../../admin/upload/ExcelUploadComponent";
import {SurveyQuestionCreateComponent} from "../../survey/survey_question_create/survey_question_create.component";
import {SurveyTerritoryCreateComponent} from "../../survey/survey_territory_create/survey_territory_create.component";

@Component({
  selector: 'app-surveydetail-component',
  templateUrl: './survey_detail.component.html',
  styleUrls: ['./survey_detail.component.scss']
})
export class SurveyDetailComponent implements OnInit {

  private gridApi;
  private gridApi1;
  private gridApi2;
  private gridApiOutletMap;

  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApi2;
  private gridColumnApiOutletMap;

  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefs2 = [];
  public columnDefsOutletMap = [];

  public rowData = [];
  public rowData1 = [];
  public rowData2 = [];
  public rowDataOutletMap = [];

  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptions2: GridOptions;
  public gridOptionsOutletMap: GridOptions;

  public loaded = false;
  public loaded1 = false;
  public loaded2 = false;
  private outletMapLoaded = false;

  public preloader = false;
  public preloader1 = false;
  public preloader2 = false;
  public preloaderOutletMap = false;

  public empty_state = false;
  public empty_state1 = false;
  public empty_state2 = false;
  public empty_state_OutletMap = false;

  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: null
    }
  };
  viewtype = 'edit';
  surveyDetails: any = {};
  activetab = 'QUESTIONS';
  //score = 35;
  selectedProducts = [];
  outletData = [];
  surveyType;
  modifiedRowStatus = '';
  mail_message_log = {};

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.data.id = localStorage.getItem("surveyid");
    this.surveyType = localStorage.getItem("surveytype");

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptionsOutletMap = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.columnDefs = [
      {headerName: "SEQUENCE", field: "sequence", width: 80},
      {headerName: "CODE", field: "code", width: 80},
      {headerName: "QUESTIONS", field: "name", width: 400},
      {headerName: "QUESTION TYPE", field: "type"},
      {headerName: "NOTES", field: "note"},
      {
        headerName: "ANSWERS", field: "ans", width: 200,
        valueGetter(params) {
          return params.data.ans.length;
        }
      },
      {
        headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, filter: false, width: 50,
        cellRenderer(params) {
          if (params._self.edit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
    ];

    this.columnDefs1 = [
      {headerName: "CODE", field: "code"},
      {headerName: "NAME", field: "name"},
      {headerName: "ZONE", field: "zone"},
      {headerName: "STATE", field: "state"},
      {headerName: "TERRITORY MANAGER", field: "terr_mgr"},
      {headerName: "BEAT COUNT", field: "beat_count", cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "OUTLET COUNT", field: "outlet_count", cellStyle: {textAlign: 'right'}, width: 80},
      {
        headerName: "", field: "download_outlet", cellStyle: {textAlign: 'center'}, width: 80,
        cellRenderer(params) {
          return ' <span title="Download Outlets" ><i class="fad fa-download" style="color:#0032e6" data-action-type="download_outlet"></i></span>';
        },
      },
      {
        headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, filter: false, width: 50,
        cellRenderer(params) {
          if (params._self.edit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
    ];

    this.columnDefs2 = [
      {headerName: "TYPE", field: "type"},
      {headerName: "CONDITION", field: "expression"},
      {headerName: "VALUE", field: "resource_name"}
    ];
    this.columnDefsOutletMap = [
      {headerName: "ID", field: "id"},
      {headerName: "CODE", field: "code"},
      {headerName: "NAME", field: "name"},
      {headerName: "TERRITORY", field: "territory"},
      {headerName: "CLUSTER", field: "cluster"},
      {headerName: "REGION", field: "region", width: 80},
    ];
    this.fetchretailSurveyDetails();
  }


  ngOnInit(): void {
    if (this.surveyType === 'retail') {
      this.sharedService.setHeader("Market Intelligence Survey");
    } else if (this.surveyType === 'kyc') {
      this.sharedService.setHeader("Retailer KYC Survey");
    } else if (this.surveyType === 'user') {
      this.sharedService.setHeader("User Survey");
    } else if (this.surveyType === 'task') {
      this.sharedService.setHeader("Task Survey");
    }
  }

  fetchretailSurveyDetails() {

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.loaded1 = false;
    this.preloader1 = true;
    this.empty_state1 = false;

    this.loaded2 = false;
    this.preloader2 = true;
    this.empty_state2 = false;

    this.outletMapLoaded = false;
    this.preloaderOutletMap = true;
    this.empty_state_OutletMap = false;

    this.API_URL = '/api/crm_profiling_questionnaires/get_retail_survey_details';


    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            this.surveyDetails = res.results.data;
            this.rowData = this.surveyDetails.questions;
            this.rowData1 = this.surveyDetails.territories;
            this.rowData2 = this.surveyDetails.criterias;
            this.rowDataOutletMap = this.surveyDetails.outletMappings;

            if (this.surveyDetails.hasOwnProperty("score") && this.surveyDetails.score != null) {
              this.surveyDetails.score = Math.round((this.surveyDetails.score + Number.EPSILON) * 100) / 100;
            }

            this.loaded = true;
            this.loaded1 = true;
            this.loaded2 = true;
            this.outletMapLoaded = true;

            if (res.results.status < 200 && res.results.msg != null) {
              this.toastr.warning(res.results.msg);
            }

          } else {
            this.preloader = false;
            this.empty_state = true;

            this.preloader1 = false;
            this.empty_state1 = true;

            this.preloader2 = false;
            this.empty_state2 = true;

            this.preloaderOutletMap = false;
            this.empty_state_OutletMap = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
          this.preloader1 = false;
          this.preloader2 = false;
          this.preloaderOutletMap = false;
        });

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
    } else {
      this.gridColumnApi2.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid3');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    let selectedQuestion = this.gridApi.getSelectedRows();
    if (selectedQuestion != null) {
      selectedQuestion = selectedQuestion[0];
    }
    console.log(selectedQuestion);
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Questionnaire !!",
          message1: selectedQuestion.name,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          if (selectedQuestion.hasOwnProperty('id')) {
            selectedQuestion.active = false;
            this.updateSurveyDetails();
          } else {
            this.surveyDetails.questions.splice(event.rowIndex, 1);
            this.rowData = this.surveyDetails.questions;
            this.gridApi.setRowData(this.rowData);
          }

        } else {

        }
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: 'auto',
        maxWidth: '1000px',
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          edit: this.edit,
          questionData: selectedQuestion,
          surveyData: this.surveyDetails
        }
      };

      const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);

      creatediaeref.componentInstance.createdQuestion.subscribe(data => {

        if (data !== []) {

          for (let i = 0; i < this.surveyDetails.questions.length; i++) {
            if (this.surveyDetails.questions[i].id === data.id) {
              this.surveyDetails.questions[i] = data;
            }
          }
          if (this.rowData === null || this.rowData.length === 0) {
            this.rowData = this.surveyDetails.questions;
            this.loaded = true;
          }

          this.gridApi.setRowData(this.rowData);
        }

        console.log(this.surveyDetails);

      });
    }

  }

  onCellClicked1(event) {
    const column = event.column.getColId();
    let selectedTerritory = this.gridApi1.getSelectedRows();
    if (selectedTerritory != null) {
      selectedTerritory = selectedTerritory[0];
    }
    if (column === 'download_outlet') {
      this.downloadOutletData(selectedTerritory);
    } else if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Territory !!",
          message1: selectedTerritory.name,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          if (selectedTerritory.hasOwnProperty('id')) {
            selectedTerritory.active = false;
            this.updateSurveyDetails();
          } else {
            this.surveyDetails.territories.splice(event.rowIndex, 1);
            this.rowData1 = this.surveyDetails.territories;
            this.gridApi1.setRowData(this.rowData1);
          }

        } else {

        }
      });
    }

  }

  onCellClicked2(event) {
    const column = event.column.getColId();
  }

  downloadOutletData(territoryData) {

    this.API_URL = '/api/res_partners/get_territory_outlets';
    const paramObject: any = {};

    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.territory_id = territoryData.id;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {

            this.outletData = res.results.data;

            this.outletExcelDownload(this.outletData, territoryData);
          } else {
          }
        },
        error => {
          console.log(error);
        });

  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  outletExcelDownload(outletData, territoryData) {

    const headers = [];
    headers.push("Outlet Name");
    headers.push("Code");
    const title = 'Outlet Data : ' + territoryData.name;
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title,
      useBom: true,
      noDownload: false,
      headers
    };
    new ngxCsv(outletData, title, options);
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.surveyDetails.start_date = this.datePipe.transform(this.surveyDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.surveyDetails.end_date = this.datePipe.transform(this.surveyDetails.end_date, 'yyyy-MM-dd');
  }

  updateMailMessageLog(field, value) {
    if (this.mail_message_log && this.mail_message_log.hasOwnProperty(field)) {
      this.mail_message_log[field] = this.mail_message_log[field] + ' ,' + value;
    } else {
      this.mail_message_log[field] = value;
    }
  }

  updateSurveyDetails() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.surveyDetails;
    paramObject.data['mail_message_log'] = this.mail_message_log;

    if (this.surveyDetails.hasOwnProperty("questions")) {
      for (let i = 0; i <= this.surveyDetails.questions.length - 1; i++) {
        if (!this.surveyDetails.questions[i].hasOwnProperty("active")) {
          this.surveyDetails.questions[i].active = true;
        } else if (this.surveyDetails.questions[i].hasOwnProperty("active") && !this.surveyDetails.questions[i].active) {
          this.updateMailMessageLog('question_deleted', this.surveyDetails.questions[i].name);
        }
        if (!this.surveyDetails.questions[i].hasOwnProperty("id")) {
          this.updateMailMessageLog('question_added', this.surveyDetails.questions[i].name);
        }
      }
    }
    if (this.surveyDetails.hasOwnProperty("territories")) {
      for (let i = 0; i <= this.surveyDetails.territories.length - 1; i++) {
        if (!this.surveyDetails.territories[i].hasOwnProperty("active")) {
          this.surveyDetails.territories[i].active = true;
        } else if (this.surveyDetails.territories[i].hasOwnProperty("active") && !this.surveyDetails.territories[i].active) {
          this.updateMailMessageLog('territory_deleted', this.surveyDetails.territories[i].name);
        }
        if (!this.surveyDetails.territories[i].hasOwnProperty("id")) {
          this.updateMailMessageLog('territory_added', this.surveyDetails.territories[i].name);
        }
      }
    }


    this.API_URL = '/api/crm_profiling_questionnaires/retail_survey_update';
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Survey updated Successfully');
          this._document.defaultView.location.reload();
        } else {
          this.toastr.error('Failed to update Survey');
        }
      });
  }

  editClick() {
    this.edit = !this.edit;
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
    if (this.gridApi1) {
      this.gridApi1.setRowData(this.rowData1);
    }
  }

  discardClick() {
    this.edit = !this.edit;
    this._document.defaultView.location.reload();
  }

  createDialog() {
    if (this.activetab === "QUESTIONS") {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "1000px",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          edit: true,
          questionData: null
        }
      };

      const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);

      creatediaeref.componentInstance.createdQuestion.subscribe(data => {

        if (data !== []) {
          this.surveyDetails.questions.push(data);
          if (this.rowData === null || this.rowData.length === 0) {
            this.rowData = this.surveyDetails.questions;
            this.loaded = true;
          }

          this.gridApi.setRowData(this.rowData);
        }

      });
    } else if (this.activetab === "TERRITORY") {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "90%",
        panelClass: 'custom-dialog-container',
      };

      const creatediaeref = this.dialog.open(SurveyTerritoryCreateComponent, config);

      creatediaeref.componentInstance.selectedTerritories.subscribe(data => {

        if (data !== []) {
          let indterritory: any = {};
          if (!this.surveyDetails.hasOwnProperty("territories")) {
            this.surveyDetails.territories = [];
          }
          for (const item of data) {
            indterritory = {};
            indterritory.territory_id = item.id;
            indterritory.name = item.name;
            indterritory.code = item.code;
            indterritory.zone = item.zone;
            indterritory.zone_id = item.zone_id;
            indterritory.state = item.state;
            indterritory.state_id = item.state_id;
            indterritory.terr_mgr = item.salesman;
            indterritory.beat_count = item.beat_count;

            this.surveyDetails.territories.push(indterritory);

          }

          if (this.rowData1 === null || this.rowData1.length === 0) {
            this.rowData1 = this.surveyDetails.territories;
            this.loaded1 = true;
          }

          this.gridApi1.setRowData(this.rowData1);
          this.modifiedRowStatus = "Territory Added";
        }

      });
    }

  }

  viewReport() {
    let url = '';
    if (this.surveyType === 'retail') {
      url = '/market_intelligence_report';
    } else if (this.surveyType === 'kyc') {
      url = '/reports/survey_kyc';
    } else if (this.surveyType === 'user') {
      url = '/reports/user_survey_report';
    } else if (this.surveyType === 'task') {
      url = '/reports/task_survey';
    }
    this.router.navigate([this.apiService.redirect_url + url], {
      state: {

        surveyID: this.surveyDetails.id
      }
    });
  }

  onGridReadyOutletMap(params) {
    this.gridOptionsOutletMap.api.showLoadingOverlay();
    this.gridApiOutletMap = params.api;
    this.gridColumnApiOutletMap = params.columnApi;
    params.api.setRowData(this.rowDataOutletMap);
    this.preloaderOutletMap = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApiOutletMap.sizeColumnsToFit();
    } else {
      this.gridColumnApiOutletMap.autoSizeColumns();
    }
  }

  uploadClick() {
    this.openfileUploadDialog();
  }

  openfileUploadDialog() {
    const config: MatDialogConfig = {
      width: "150vw",
      height: "120vh",
      maxWidth: "450px",
      maxHeight: "560px",
      data: {
        upload_type: 'survey_outlet_mapping',
        title: "Upload Outlet Mapping",
        message: "You are uploading outlet mappings for the selected Survey",
        instruction: "Column 1: Outlet_code <br> Column 2: action (ADD/REMOVE)",
        active_id: this.user_data.data.id
      },
      panelClass: 'custom-dialog-container'
    };

    const dialogRef = this.dialog.open(ExcelUploadComponent, config);
    dialogRef.componentInstance.uploadData.subscribe(status => {
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }
}
