import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class LessthanMSSOutletReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Less than 50% MSS Outlet');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    //this.showDate = false;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getLessthanMSSOutletsReport';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'salesman_name', enableRowGroup: true, width: 80},
      {headerName: 'DIST CODE', field: 'dist_code', enableRowGroup: true, width: 80},
      {headerName: 'DISTRIBUTOR', field: 'dist_name', enableRowGroup: true, width: 80},
      {headerName: 'BEAT', field: 'beat', width: 80},
      {headerName: 'ID', field: 'r_id', hide: true, width: 80},
      {headerName: 'CODE', field: 'o_code', width: 80},
      {headerName: 'RETAILER', field: 'o_name', width: 80},
      {headerName: 'TYPE', field: 'o_type', width: 80},
      {headerName: 'CHANNEL', field: 'o_channel', width: 80},
      {headerName: 'PROGRAM', field: 'program', width: 80},
      {headerName: 'SALES', field: 'sales', width: 80},
      {headerName: 'MSS', field: 'mss', width: 80}
    ];

  }

}
