import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams, GridOptions, RowNode} from "@ag-grid-enterprise/all-modules";
import {SharedService} from "../../../../_services/shared_service";
import {PaymentCellRendererComponent} from "../../../../payment-cell-renderer.component";

@Component({
  selector: 'app-payument-collect-popup',
  templateUrl: './payment_collect_popup.component.html',
  styleUrls: ['./payment_collect_popup.component.scss']

})

export class PaymentCollectPopupComponent implements OnInit {
  @Output() markPaid = new EventEmitter<boolean>();
  partner_id;
  customerDetails: any = {};
  invoiceList = [];
  selectedInvoices = [];
  crNotesList = [];
  selectedCRNotes = [];
  totalCrNoteAmt = 0;
  totalPayableAmt = 0;
  creditUsed = 0;
  remainingCredits = 0;
  amount_collected = 0;
  excess_amount = 0;
  dataLoaded = false;
  paymentDetails: any = {};
  public paymentModes = [{id: 'cash', itemName: 'Cash'}, {id: 'cheque', itemName: 'Cheque'}, {id: 'online', itemName: 'Online'}];

  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  private gridApi;
  private gridColumnApi;
  frameworkComponents: any;
  styleGrid: any;
  context: any;
  write_off_amt = 0;
  columnsWithAggregation = [];
  show_write_off = false;
  availableCreditNotes = 0;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.partner_id = dialogdata.partner_id;
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#D7DDE6 1px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      singleClickEdit: true,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheadergrey',
        filter: true,
        sortable: true,
        resizable: true
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      },
      getRowHeight: params => {
        return params.data && params.data.cr_used && params.data.cr_used > 0 ? 60 : 40;
      }
    } as GridOptions;

    this.sharedService.statusConfig = {
      success: 'Paid',
      success1: 'success',
      error: 'Partial',
      error1: 'fail',
      warning: 'warning',
      warning1: 'warning'
    };
    this.columnsWithAggregation = ['order_amount', 'outstanding_amount', 'tot_amt', 'balance', 'cr_used'];
    this.columnDefs = [
      {headerName: 'Invoice', field: 'name', width: 120},
      {headerName: 'id', field: 'id', width: 80, hide: true},
      {headerName: 'Date', field: 'date_invoice', width: 85},
      {
        headerName: 'Bill Amt', field: 'amount_total', width: 100, menuTabs: [], cellStyle: {textAlign: 'right'},
        valueGetter: params => {
          if (params.data) {
            if (params.data.order_amount) {
              return parseFloat(params.data.order_amount).toFixed(2);
            } else {
              return params.data.order_amount;
            }
          }
        }
      },
      {
        headerName: 'Payable', field: 'outstanding_amount', width: 100, menuTabs: [], cellStyle: {textAlign: 'right'},
        valueGetter: params => {
          if (params.data) {
            if (params.data.outstanding_amount) {
              return parseFloat(params.data.outstanding_amount).toFixed(2);
            } else {
              return params.data.outstanding_amount;
            }
          }
        }
      },
      {
        headerName: 'Amt Allocated', field: 'tot_amt', width: 100, menuTabs: [], cellStyle: {textAlign: 'right'}, editable: false,
        cellEditor: 'agNumberCellEditor', cellRenderer: PaymentCellRendererComponent
      },
      {headerName: 'CR Used', field: 'cr_used', width: 100, hide: true},
      {
        headerName: 'Balance', field: 'balance', width: 70, menuTabs: [], cellStyle: {textAlign: 'right'},
        cellRenderer(params) {
          if (params.data) {
            let tot_amt = 0;
            if (params.data.tot_amt && params.data.tot_amt > 0) {
              tot_amt += parseFloat(params.data.tot_amt);
            }
            if (params.data.cr_used && params.data.cr_used > 0) {
              tot_amt += parseFloat(params.data.cr_used);
            }
            if (tot_amt > 0) {
              if (params.value && parseFloat(params.value) > 0) {
                return '<span style="color: #E89221;font-weight:500;" >' + parseFloat(params.value).toFixed(2) + '</span>';
              } else {
                return '<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862" >Paid</span>';
              }
            }
          }
        }
      },
      {headerName: 'Write Off', field: 'write_off', width: 100, menuTabs: [], cellStyle: {textAlign: 'right'}, hide: true},
    ];
    this.paymentDetails.tot_amount = 0;
    this.paymentDetails.payment_type = 'cash';
    this.paymentDetails.state = 'posted';
    this.fetchPartnerDetails();
  }

  ngOnInit(): void {
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data['id'];
  }

  fetchPartnerDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.customer_id = this.partner_id;
    this.apiService.post('/api/pwa_dms_connects/get_customer_outstandings', paramObject)
      .subscribe(res => {
        console.log(res);
        this.dataLoaded = true;
        // t.stop();
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.customerDetails = res.results.data.master[0];
          this.invoiceList = res.results.data.invoices;
          this.rowData = res.results.data.invoices;
          this.crNotesList = res.results.data.cr_notes;
          if (this.crNotesList && this.crNotesList.length > 0) {
            this.crNotesList.forEach(crnote => {
              this.totalCrNoteAmt += parseFloat(crnote.balance);
              crnote.selected = false;
            });
            this.totalCrNoteAmt = parseFloat(this.totalCrNoteAmt.toFixed(2));
          }
          if (this.invoiceList && this.invoiceList.length > 0) {
            this.invoiceList.forEach(invoice => {
              this.totalPayableAmt += parseFloat(invoice.outstanding_amount);
            });
          }
          this.availableCreditNotes = parseFloat((this.totalCrNoteAmt - this.creditUsed).toFixed(2));
        }
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;

    this.setBottomPinnedData();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  setBottomPinnedData() {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }


  onRowSelected(event) {
    console.log(event);
  }

  writeOffChecked(event) {
    // this.amount_collected = this.paymentDetails.tot_amount + this.creditUsed;
    // if (this.paymentDetails.write_off) {
    //   this.amount_collected += this.write_off_amt;
    //   const found = this.rowData.find(x => parseFloat(x.balance) > 0);
    //   if (found) {
    //     found.write_off = this.paymentDetails.write_off;
    //     found.balance = 0;
    //     found.amt = parseFloat(found.outstanding_amount);
    //   }
    // } else {
    //   const found = this.rowData.find(x => parseFloat(x.write_off) > 0);
    //   if (found) {
    //     found.amt = 0;
    //     found.balance = 0;
    //     if (found.tot_amt && parseFloat(found.tot_amt) > 0) {
    //       found.amt += parseFloat(found.tot_amt);
    //       this.paymentDetails.tot_amount += parseFloat(found.tot_amt);
    //     }
    //     if (found.cr_used) {
    //       found.amt += parseFloat(found.cr_used);
    //     }
    //     if (found.amt > 0) {
    //       found.balance = parseFloat(found.outstanding_amount) - parseFloat(found.amt);
    //     }
    //   }
    // }
    // this.gridApi.applyTransactionAsync({update: this.rowData}, () => {
    //   this.setBottomPinnedData();
    //   this.gridApi.refreshCells({force: true});
    //   this.gridApi.resetRowHeights();
    //   //this.gridApi.setRowData(this.rowData);
    // });
  }

  resetCreditNoteandWriteOff() {
    this.creditUsed = 0;
    this.selectedCRNotes = [];
    this.paymentDetails.write_off = false;
    if (this.crNotesList && this.crNotesList.length > 0) {
      this.crNotesList.forEach(crnote => {
        crnote.selected = false;
        crnote.used = 0;
      });
    }
    this.rowData.forEach(row => {
      row.cr_used = 0;
      row.write_off = 0;
    });
  }

  onAmountChange() {
    if (this.gridApi && this.gridApi.getEditingCells() && this.gridApi.getEditingCells().length > 0) {
      this.gridApi.stopEditing();
    }
    this.onCellEditingStopped(null);
    const updatedRows = [];
    this.paymentDetails.tot_amount = parseFloat(document.getElementById('tot_amount')['value']);
    this.amount_collected = this.paymentDetails.tot_amount;
    this.resetCreditNoteandWriteOff();
    let tot_amount = parseFloat(this.paymentDetails.tot_amount);
    this.write_off_amt = 0;
    if (this.rowData && this.rowData.length > 0 && tot_amount !== null && tot_amount !== undefined) {
      this.rowData.forEach(row => {
        row.tot_amt = 0;
        row.balance = 0;
        row.write_off = 0;
        row.cr_used = 0;
      });
      for (let i = 0; i < this.rowData.length; i++) {
        if (parseFloat(this.rowData[i].outstanding_amount) >= tot_amount) {
          this.rowData[i].tot_amt = tot_amount;
          tot_amount = 0;
        } else {
          this.rowData[i].tot_amt = this.rowData[i].outstanding_amount;
          tot_amount -= parseFloat(this.rowData[i].outstanding_amount);
        }

        this.rowData[i].tot_amt = parseFloat(parseFloat(this.rowData[i].tot_amt).toFixed(2));

        if (tot_amount < 1) {
          this.rowData[i].write_off = tot_amount;
          this.rowData[i].balance = 0;
          this.rowData[i].tot_amt += tot_amount;
          tot_amount = 0;
          this.write_off_amt += tot_amount;
        } else {
          this.rowData[i].balance = parseFloat(this.rowData[i].outstanding_amount) - parseFloat(this.rowData[i].tot_amt);
        }

        // this.write_off_amt += this.rowData[i].balance;

        /*if (this.paymentDetails.write_off) {
          this.rowData[i].write_off = this.rowData[i].balance;
        }*/
        updatedRows.push(this.rowData[i]);
        if (tot_amount === 0) {
          break;
        }
      }
      this.write_off_amt = parseFloat(this.write_off_amt.toFixed(2));
      this.computeSelectedInvoices();
      //this.gridApi.setRowData(this.rowData);
    }
  }

  crnoteClick(creditnote) {
    let creditNoteSelectable = true;
    if (!creditnote.selected) {
      creditNoteSelectable = this.checkCreditNoteSelectable(creditnote);
    }
    if (creditNoteSelectable) {
      creditnote.selected = !creditnote.selected;
      this.computeCreditNotes();
      this.computeSelectedInvoices();
      this.availableCreditNotes = parseFloat((this.totalCrNoteAmt - this.creditUsed).toFixed(2));
    } else {
      this.toastr.error('Amount exceeds Total Payable Amount');
    }
  }

  computeCreditNotes() {
    let amount_collected = this.paymentDetails.tot_amount + this.creditUsed;
    if (this.paymentDetails.write_off) {
      amount_collected += this.write_off_amt;
    }
    this.creditUsed = 0;
    this.selectedCRNotes = [];
    if (this.crNotesList && this.crNotesList.length > 0) {
      this.crNotesList.forEach(crnote => {
        if (crnote.selected) {
          amount_collected = this.paymentDetails.tot_amount + this.creditUsed;
          if (this.paymentDetails.write_off) {
            amount_collected += this.write_off_amt;
          }
          if ((amount_collected + crnote.balance) > this.totalPayableAmt) {
            crnote.used = this.totalPayableAmt - amount_collected;
          } else {
            crnote.used = crnote.balance;
          }
          crnote.used = parseFloat(parseFloat(crnote.used).toFixed(2));
          this.creditUsed += parseFloat(crnote.used);
          this.selectedCRNotes.push(crnote);
        }
      });
    }
    this.rowData.forEach(row => {
      row.cr_used = 0;
      row.write_off = 0;
      if (row.tot_amt !== null && row.tot_amt !== undefined && row.tot_amt === 0) {
        row.tot_amt = null;
      }
    });
    if (this.creditUsed && this.creditUsed > 0) {
      this.creditUsed = parseFloat(this.creditUsed.toFixed(2));
      this.paymentDetails.write_off = false;
      let totcrused = this.creditUsed;
      let tot_amt = 0;
      this.rowData.forEach(row => {
        if (!row.tot_amt) {
          tot_amt = 0;
        } else {
          tot_amt = row.tot_amt;
        }
        if (row.cr_used && row.cr_used > 0) {
          row.cr_used = 0;
        }
        if (totcrused > 0 && row.outstanding_amount > tot_amt) {
          row.cr_used = 0;
          if ((tot_amt + totcrused) > row.outstanding_amount) {
            row.cr_used = row.outstanding_amount - tot_amt;
            totcrused = totcrused - row.cr_used;
          } else {
            row.cr_used = totcrused;
            totcrused = 0;
          }
        }
        if (row.cr_used && row.cr_used > 0) {
          if (tot_amt === 0) {
            row.tot_amt = 0;
          }
        } else {
          if (row.tot_amt !== null && row.tot_amt !== undefined && row.tot_amt === 0) {
            row.tot_amt = null;
          }
        }
      });
    }
  }

  rowEdit(row, value) {
    const found = this.rowData.find(x => x.id === row.id);
    found.tot_amt = value;
    this.resetCreditNoteandWriteOff();
    this.computeSelectedInvoices();
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    console.log(event);
    if (event?.data) {
      event.data.tot_amt = parseFloat(event.data.tot_amt);
      this.resetCreditNoteandWriteOff();
      this.computeSelectedInvoices();
    }
  }

  computeSelectedInvoices() {
    this.selectedInvoices = [];
    this.write_off_amt = 0;
    const inThis = this;
    //this.paymentDetails.tot_amount = 0;
    let balance_count = 0;
    let totcrused = this.creditUsed;
    if (!totcrused) {
      totcrused = 0;
    }
    let tot_amount = parseFloat(this.paymentDetails.tot_amount);
    if (!tot_amount) {
      tot_amount = 0;
    }
    this.rowData.forEach(row => {
      /* if (row.tot_amt && parseFloat(row.tot_amt) > 0) {
         this.selectedInvoices.push(row);
         row.balance = parseFloat(row.outstanding_amount) - parseFloat(row.tot_amt);
         this.write_off_amt += row.balance;
         if (this.paymentDetails.write_off) {
           row.amt = row.outstanding_amount;
         } else {
           row.amt = row.tot_amt;
         }
         this.paymentDetails.tot_amount += parseFloat(row.tot_amt);
       }
       if (row.cr_used) {
         row.balance -= parseFloat(row.cr_used);
       }
       if (row.balance && row.balance > 0) {
         balance_count++;
       }*/
      row.amt = 0;
      row.balance = 0;
      if (row.tot_amt && parseFloat(row.tot_amt) > 0) {
        row.amt += parseFloat(row.tot_amt);
        // this.paymentDetails.tot_amount += parseFloat(row.tot_amt);
      }
      if (row.cr_used) {
        row.amt += parseFloat(row.cr_used);
      }
      if (row.amt > 0) {
        row.balance = parseFloat(row.outstanding_amount) - parseFloat(row.amt);
        if (row.balance < 1 && row.balance > 0) {
          this.write_off_amt += row.balance;
        }
        this.selectedInvoices.push(row);
        if (row.balance && row.balance > 0) {
          balance_count++;
        }
      }
    });
    /*if (this.creditUsed > 0) {
      let balancerows = this.rowData.filter(x => x.balance && x.balance > 0);
      if (balancerows && balancerows.length > 0) {

      }
    }*/
    if (balance_count === 1) {
      this.show_write_off = true;
    } else {
      this.show_write_off = false;
    }
    // this.write_off_amt = parseFloat(this.write_off_amt.toFixed(2));
    this.amount_collected = this.paymentDetails.tot_amount + this.creditUsed;
    if (this.paymentDetails.write_off) {
      this.amount_collected += this.write_off_amt;
    }
    if (this.amount_collected > this.totalPayableAmt) {
      this.excess_amount = this.amount_collected - this.totalPayableAmt;
    } else {
      this.excess_amount = 0;
    }
    /*this.gridApi.setRowData(this.rowData);
    this.setBottomPinnedData();*/
    this.gridApi.applyTransactionAsync({update: this.rowData}, () => {
      this.setBottomPinnedData();
      this.gridApi.refreshCells({force: true});
      this.gridApi.resetRowHeights();
      //this.gridApi.setRowData(this.rowData);
    });
  }

  checkCreditNoteSelectable(creditnote) {
    let amount_collected = this.paymentDetails.tot_amount + this.creditUsed;
    if (this.paymentDetails.write_off) {
      amount_collected += this.write_off_amt;
    }
    if (amount_collected >= this.totalPayableAmt) {
      return false;
    } else {
      return true;
    }
  }

  chequeTypeSelection() {

  }

  changeDate(dt) {

  }

  closeDialog() {
    this.dialogRef.close();
  }


  collectPayment() {
    this.computeSelectedInvoices();
    if (this.paymentDetails.payment_type === 'cash') {
      this.paymentDetails.state = 'posted';
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.partner_id;
    paramObject.data = {
      invoices: this.selectedInvoices,
      amount_collected: parseFloat(this.paymentDetails.tot_amount),
      credits_allocated: this.creditUsed,
      round_off: this.write_off_amt,
      payment_type: this.paymentDetails.payment_type,
      cheque_no: this.paymentDetails.cheque_no,
      cheque_date: this.paymentDetails.cheque_date,
      branch: this.paymentDetails.branch,
      credit_notes: this.selectedCRNotes,
      state: this.paymentDetails.state
    };
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_mark_paid', paramObject)
      .subscribe(res => {
        console.log(res);
        // t.stop();
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.toastr.success("Payment Successful");
          this.dialog.closeAll();
          this.markPaid.emit(true);
        } else {
          this.toastr.error("Payment Error");
        }
      });
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    const result = {};

    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {
    //console.log(target);
    //list of columns fo aggregation

    this.columnsWithAggregation.forEach(element => {
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        //if(rowNode.index < 10){
        //console.log(rowNode);
        //}
        if (rowNode.data[element]) {
          if (Number(rowNode.data[element]) % 1 !== 0) {
            target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
          } else {
            target[element] += Number(rowNode.data[element]);
          }
        }
      });
      if (target[element]) {
        if (target[element] % 1 !== 0) {
          target[element] = `${target[element].toFixed(2)}`;
        }
      }
    });
    //console.log(target);
    // target['athlete'] = 'Total';
    if (this.rowData && this.rowData.length > 0) {
      target[this.columnDefs[0].field] = 'TOTAL';
      if (target) {
        if (target['tot_amt']) {
          target['tot_amt'] = parseFloat(target['tot_amt']);
        } else {
          target['tot_amt'] = 0;
        }
        if (target['cr_used']) {
          target['cr_used'] = parseFloat(target['cr_used']);
        } else {
          target['cr_used'] = 0;
        }
        target['tot_amt'] += target['cr_used'];
      }
    }
    return target;
  }
}
