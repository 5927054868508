import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-product-category-growth-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ProductCategoryGrowthReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Product Category Growth Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.configureGrid();
    this.API_URL = '/api/pwa_v2_reports/productCategoryGrowthReport';
  }


  configureGrid() {

    this.columnDefs = [
      {
        headerName: "USER DATA",
        children: [
          {headerName: 'SALESMAN', field: 'user_name', enableRowGroup: true, width: 80},
          {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, width: 50},
          {headerName: 'TEAM', field: 'team', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'PARENT TEAM', field: 'parent_team', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
          {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 120},
          {headerName: 'REGION MANAGER', field: 'region_manager', enableRowGroup: true, width: 120},
          {headerName: 'ROLE', field: 'user_role', enableRowGroup: true, width: 80},
          {headerName: 'REPORTING MANAGER', field: 'reporting_manager', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'TCODE', field: 'tcode', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'TERRITORY MANAGER', field: 'territory_manager', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'CLUSTER MANAGER', field: 'cluster_manager', enableRowGroup: true, hide: true, width: 120},
          {headerName: 'CATEGORY', field: 'resource_name', width: 80},
        ]
      },
      {
        headerName: "MONTH",
        children: [
          {
            headerName: "QUANTITY",
            children: [
              {
                headerName: 'LY TARGET', field: 'lmq_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lmq_target) > 0) {
                    if (params.data.lmq_target) {
                      return parseInt(params.data.lmq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lmq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lmq_achieved) > 0) {
                    if (params.data.lmq_achieved) {
                      return parseInt(params.data.lmq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cmq_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cmq_target) > 0) {
                    if (params.data.cmq_target) {
                      return parseInt(params.data.cmq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cmq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cmq_achieved) > 0) {
                    if (params.data.cmq_achieved) {
                      return parseInt(params.data.cmq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "month_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cmq_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cmq_achieved) / parseInt(params.data.cmq_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              },
              {
                headerName: 'GROWTH', field: 'mq_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.mq_growth) > 0) {
                    if (params.data.mq_growth) {
                      return parseInt(params.data.mq_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              },
            ]
          },
          {
            headerName: "VALUE",
            children: [
              {
                headerName: 'LY TARGET', field: 'lmv_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lmv_target) > 0) {
                    if (params.data.lmv_target) {
                      return parseInt(params.data.lmv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lmv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lmv_achieved) > 0) {
                    if (params.data.lmv_achieved) {
                      return parseInt(params.data.lmv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cmv_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cmv_target) > 0) {
                    if (params.data.cmv_target) {
                      return parseInt(params.data.cmv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cmv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cmv_achieved) > 0) {
                    if (params.data.cmv_achieved) {
                      return parseInt(params.data.cmv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "month_value_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cmv_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cmv_achieved) / parseInt(params.data.cmv_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              },
              {
                headerName: 'GROWTH', field: 'mv_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.mv_growth) > 0) {
                    if (params.data.mv_growth) {
                      return parseInt(params.data.mv_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              },
            ]
          },
        ]
      },
      {
        headerName: "QUARTER",
        children: [
          {
            headerName: "QUANTITY",
            children: [
              {
                headerName: 'LY TARGET', field: 'lqq_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lqq_target) > 0) {
                    if (params.data.lqq_target) {
                      return parseInt(params.data.lqq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lqq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lqq_achieved) > 0) {
                    if (params.data.lqq_achieved) {
                      return parseInt(params.data.lqq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cqq_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cqq_target) > 0) {
                    if (params.data.cqq_target) {
                      return parseInt(params.data.cqq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cqq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cqq_achieved) > 0) {
                    if (params.data.cqq_achieved) {
                      return parseInt(params.data.cqq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "quarter_quantity_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cqq_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cqq_achieved) / parseInt(params.data.cqq_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              },
              {
                headerName: 'GROWTH', field: 'qq_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.qq_growth) > 0) {
                    if (params.data.qq_growth) {
                      return parseInt(params.data.qq_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              },
            ]
          },
          {
            headerName: "VALUE",
            children: [
              {
                headerName: 'LY TARGET', field: 'lqv_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lqv_target) > 0) {
                    if (params.data.lqv_target) {
                      return parseInt(params.data.lqv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lqv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lqv_achieved) > 0) {
                    if (params.data.lqv_achieved) {
                      return parseInt(params.data.lqv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cqv_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cqv_target) > 0) {
                    if (params.data.cqv_target) {
                      return parseInt(params.data.cqv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cqv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cqv_achieved) > 0) {
                    if (params.data.cqv_achieved) {
                      return parseInt(params.data.cqv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "quarter_value_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cqv_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cqv_achieved) / parseInt(params.data.cqv_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              },
              {
                headerName: 'GROWTH', field: 'qv_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.qv_growth) > 0) {
                    if (params.data.qv_growth) {
                      return parseInt(params.data.qv_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              },
            ]
          },
        ]
      },
      {
        headerName: "YEAR",
        children: [
          {
            headerName: "QUANTITY",
            children: [
              {
                headerName: 'LY TARGET', field: 'lyq_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lyq_target) > 0) {
                    if (params.data.lyq_target) {
                      return parseInt(params.data.lyq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lyq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lyq_achieved) > 0) {
                    if (params.data.lyq_achieved) {
                      return parseInt(params.data.lyq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cyq_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cyq_target) > 0) {
                    if (params.data.cyq_target) {
                      return parseInt(params.data.cyq_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cyq_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cyq_achieved) > 0) {
                    if (params.data.cyq_achieved) {
                      return parseInt(params.data.cyq_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "year_quantity_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cyq_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cyq_achieved) / parseInt(params.data.cyq_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              },
              {
                headerName: 'GROWTH', field: 'yq_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.yq_growth) > 0) {
                    if (params.data.yq_growth) {
                      return parseInt(params.data.yq_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              },
            ]
          },
          {
            headerName: "VALUE",
            children: [
              {
                headerName: 'LY TARGET', field: 'lyv_target', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lyv_target) > 0) {
                    if (params.data.lyv_target) {
                      return parseInt(params.data.lyv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'LY ACH', field: 'lyv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.lyv_achieved) > 0) {
                    if (params.data.lyv_achieved) {
                      return parseInt(params.data.lyv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY TARGET', field: 'cyv_target', width: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cyv_target) > 0) {
                    if (params.data.cyv_target) {
                      return parseInt(params.data.cyv_target);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: 'CY ACH', field: 'cyv_achieved', maxwidth: 80, aggFunc: 'sum',
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.cyv_achieved) > 0) {
                    if (params.data.cyv_achieved) {
                      return parseInt(params.data.cyv_achieved);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }
              },
              {
                headerName: "ACH%", field: "year_value_ach_percent", minWidth: 150,

                valueGetter: params => {
                  if (params.data) {
                    if (parseInt(params.data.cyv_target) === 0) {
                      return 0;
                    } else {
                      return Math.trunc((parseInt(params.data.cyv_achieved) / parseInt(params.data.cyv_target)) * 100);
                    }
                  } else {
                    return 0;
                  }
                },
                cellRenderer: params => {

                  let displayElem = '<div class="row">\n' +
                    '            <div class="progresscontainer">\n' +
                    '              <div class="progressinside" style=" ';

                  if (params.value > 50) {
                    displayElem = displayElem + ' background-color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' background-color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' background-color: #E24260;';
                  }

                  displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                    '            </div>\n' +
                    '            <div class="progresstext" style="';

                  if (params.value > 50) {
                    displayElem = displayElem + ' color: #379862;';
                  } else if (params.value > 20) {
                    displayElem = displayElem + ' color: #D97C0E;';
                  } else {
                    displayElem = displayElem + ' color: #E24260;';
                  }

                  displayElem = displayElem + '">' + params.value + '%</div></div>';


                  return displayElem;
                }
              }, {
                headerName: 'GROWTH', field: 'yv_growth', maxwidth: 80, aggFunc: this.avgAggFunction,
                valueGetter: params => {
                  if (params.data && parseFloat(params.data.yv_growth) > 0) {
                    if (params.data.yv_growth) {
                      return parseInt(params.data.yv_growth);
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                },
                cellStyle: params => {
                  if (params.data) {
                    if (params.value > 0) {
                      return {backgroundColor: '#B3DFB0'};
                    } else {
                      return {backgroundColor: '#F8D1C8'};
                    }
                  }
                }
              }
            ]
          },
        ]
      }
    ];

  }

}
