import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../_services/shared_service";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {PageBase} from "../pagebase/pagebase";
// @ts-ignore
import {IPage} from "../pagebase/ipage";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../components/image_popup/image_popup.component";
import {CreatePopupComponent} from "../components/create_popup/create_popup.component";

@Component({
  selector: 'app-retail_audit',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class RetailAuditComponent extends PageBase implements OnInit, IPage {
  ngOnInit(): void {

    this.sharedService.setHeader('Retail Audit');
    this.showDate = false;
    this.configureGrid();
    this.loadServerData();
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'viewreport') {
      this.router.navigate([this.apiService.redirect_url + '/retail_audit_report'], {
        state: {

          retailauditid: rows[0].id
        }
      });
    } else {
      localStorage.setItem('retailauditid', rows[0].id);
      this.router.navigate([this.apiService.redirect_url + '/retail_audit/retail_audit_detail'], {
        state: {
          type: 'detail',
          retailauditid: rows[0].id
        }
      });
    }
  }

  configureGrid() {
    this.API_URL = '/api/audit_masters/get_retail_audit_list';
    this.columnDefs = [
      {headerName: "CODE", headerClass: 'myagheader', field: "code",     width: 100},
      {headerName: "AUDIT NAME", headerClass: 'myagheader', field: "name",     width: 150},
      {
        headerName: "DESCRIPTION", headerClass: 'myagheader', field: "description",     width: 150,
        cellRenderer: params => {
          const displayElem = params.value;
          return displayElem;
        }
      },
      {headerName: "TYPE", headerClass: 'myagheader', field: "type",     width: 100},
      {headerName: "NO OF SKU", headerClass: 'myagheader', field: "sku_count",     cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "LAST MODIFIED BY", headerClass: 'myagheader', field: "last_modified",     width: 120},
      {headerName: "SCORE MTD %", headerClass: 'myagheader', field: "score",   cellStyle: {textAlign: 'right'},   width: 80},
      {headerName: "COMPLETED MTD", headerClass: 'myagheader', field: "completed",   cellStyle: {textAlign: 'right'},   width: 80},
      {
        headerName: "", headerClass: 'myagheader', field: "viewreport",     width: 120,
        valueGetter: params => "View Report",
        cellStyle: params => ({color: '#2F7BFF'}),
        cellRenderer: params => {
          const displayElem = '<span style="color:#2F7BFF; font-weight: 400;cursor: pointer;">' + params.value + '</span>';
          return displayElem;
        }
      },
    ];
  }
}
