import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";


@Component({
  selector: 'app-purchase-invoice-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class PurchaseInvoiceListComponent extends PageBase implements OnInit, IPage {

  matActionMenuItemDeclaration = [{name: 'Confirm Invoices' }, {name: 'Mark Delivered' }]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Sale Invoices');
    this.showActionButton = true;
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_purchase_invoices';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 60, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "GRN NO", field: "grn", width: 100},
      {headerName: "INVOICE NO", field: "inv_no", width: 80},
      {headerName: "INVOICE DATE", field: "inv_date", width: 80},
      {headerName: "REFERENCE", field: "sale_ref", width: 150},
      {headerName: "CUSTOMER", field: "cust_name", width: 150},
      {headerName: "OUTSTANDING", field: "outstanding", width: 10},
      {headerName: "SALESMAN", field: "salesman", width: 120},
      {headerName: "STATUS", field: "state", width: 80},
    ];

    this.getRowId = (params) => params.data.id;
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/dms/grn'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = false;
    // });
  }

  openCreateDialog() {
    this.router.navigate([this.apiService.redirect_url + '/dms/grn'], {
      relativeTo: this.route,
      queryParams: {id: 0 }
    });
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Invoice');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
    } else {

      if (menuitem.name === 'Mark Delivered') {
        const ids = [];
        rows.forEach(r => {
          if (r.state === 'draft' || r.state === 'confirm') {
            ids.push(r.id);
          }
        });
        this.MarkDelivery(ids);
      } else if (menuitem.name === 'Confirm Invoices') {
        const ids = [];
        rows.forEach(r => {
          if (r.state === 'draft') {
            ids.push(r.id);
          }
        });
        this.ConfirmInvoicesClick(ids);
      }
    }
  }


  MarkDelivery(invoice_ids) {
    if (invoice_ids.length > 0) {
      const contenxt = {};
      contenxt['access_token'] = this.userData.access_token;
      contenxt['data'] = {};

      invoice_ids.forEach(inv_id => {

        contenxt['data']['id'] = inv_id;
        contenxt['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
        this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', contenxt)
          .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {

                const ord = this.rowData.find(item => item.id === inv_id);
                const row = this.updateImmutableObject(ord, {state: "delivered"});

                this.gridApi.applyTransactionAsync({update: [row]}, () => {
                });

              } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
                this.handleError(ErrorHandler.getErrorObject(res.results.msg));
              } else {
                this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
              }
            },
            error => {
              this.handleError(ErrorHandler.getErrorObject(error));
            });

      });
    } else {
      this.toastr.success('No Invoices Selected');
      return;
    }
  }


  ConfirmInvoicesClick(invoice_ids) {
    if (invoice_ids.length > 0) {
      const userData = {};
      userData['access_token'] = this.userData.access_token;
      userData['invoice_ids'] = invoice_ids;

      this.apiService.post('/api/pwa_dms_connects/confirm_invoices', userData)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              const rowsToUpdate = [];
              res.results.data.forEach(res_id => {
                const inv = this.rowData.find(item => item.id === res_id['id']);
                rowsToUpdate.push(this.updateImmutableObject(inv, {state: res_id['state']}));
              });

              this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
              });

              this.toastr.success(rowsToUpdate.length + ' INvoices confirmed!');

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('All orders are already confirmed!');
      return;
    }
  }


}
