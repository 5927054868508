import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-secondary-sales-detailed',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class TertiarySalesWithSerialNoReport extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  setTitle() {
    this.title = 'Tertiary Sales Report - With Serials';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Tertiary Sales Report - With Serials');
    this.API_URL = '/api/pwa_reports/getOutletSalesReportDetailedImei';
    this.userData.customFilter['type'] = 'T';
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'line_id');
    this.showProgressCustomText = true;
    this.configureGrid();

  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "Region", field: "region_name", width: 100},
      {headerName: "Cluster", field: "cluster_name", width: 100},
      {headerName: "User", field: "user_name", width: 100},
      {headerName: "Role", field: "user_role", width: 100},
      {
        headerName: "Sale Date", field: "ord_date", width: 100,
        /* valueGetter: params => {
           if (params.data) {
             if (params.data.ord_date) {
               return inThis.datePipe.transform((new Date(params.data.ord_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
             } else {
               return params.data.ord_date;
             }
           } else {
             return '';
           }
         }*/
      },

      {
        headerName: 'Partner',
        children: [
          {headerName: "ID", field: "cust_id", width: 100},
          {headerName: "Name", field: "cust_name", width: 100},
          {headerName: "Code", field: "cust_code", width: 100},
          {
            headerName: "Status", field: "cust_status", hide: true, width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.cust_status) {
                  return 'Active';
                } else {
                  return 'Inactive';
                }
              }
            }
          },
          {headerName: "TAX ID", field: "cust_gst", width: 100},
          {headerName: "Mobile", field: "cust_mobile", width: 100},
          {headerName: "Email", field: "cust_email", width: 100},
          {headerName: "Class", field: "cust_class", width: 100},
          {headerName: "Channel", field: "channel", width: 100, hide: true},
          {headerName: "Chain", field: "chain_name", width: 100, hide: true},
          {headerName: "Chain Code", field: "chain_code", width: 100, hide: true},
          {headerName: "Type", field: "cust_type", width: 100},
          {headerName: "Program Type", field: "cust_pgm_type", width: 100},
          {headerName: "Lat", field: "cust_lat", width: 100},
          {headerName: "Long", field: "cust_long", width: 100},
          {headerName: "Address", field: "cust_address", width: 100},
          {headerName: "State", field: "cust_state", width: 100},
          {headerName: "District", field: "cust_zone", width: 100},
          {headerName: "Area", field: "cust_area", width: 100},
          {headerName: "City", field: "cust_city", width: 100},
          {headerName: "Created Date", field: "cust_created", width: 100},
          {headerName: "Target", field: "cust_target", width: 100},
          {headerName: "Promoter Code", field: "cust_manager_code", width: 100},
          {headerName: "Promoter", field: "cust_manager", width: 100},
        ]
      },

      {
        headerName: 'SALES TEAM',

        children: [
          {headerName: "Emp Code", field: "user_code", width: 100},
          {headerName: "SAC", field: "user_sac", width: 100},
          {headerName: "Team", field: "team", width: 100},
          {headerName: "Team Lead", field: "team_lead", width: 100},
          {headerName: "User State", field: "user_state", width: 100},
          {headerName: "Beat ID", field: "beat_id", width: 100},
          {headerName: "Beat Name", field: "beat_name", width: 100},
          {headerName: "Terr Code", field: "terr_code", width: 100},
          {headerName: "Terr Name", field: "terr_name", width: 100},
        ]
      },
      {
        headerName: 'ORDER INFO',

        children: [
          {headerName: "Order ID", field: "ord_id", width: 100},
          {headerName: "Order Reference", field: "ord_reference", width: 100},
          {headerName: "Order Type", field: "ord_type", width: 100, hide: true},
          {headerName: "Order Status", field: "ord_state", width: 100},
          {headerName: "Invoice No", field: "invoice_no", width: 100},
          {headerName: 'Visit Type', field: 'visit_type', width: 80},
          {
            headerName: "Last Modified", field: "last_modified", width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.last_modified) {
                  return inThis.datePipe.transform((new Date(params.data.last_modified + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.last_modified;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: "Delivered Date", field: "dlr_date", width: 100, hide: true,
            /* valueGetter: params => {
               if (params.data) {
                 if (params.data.dlr_date) {
                   return inThis.datePipe.transform((new Date(params.data.dlr_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                 } else {
                   return params.data.dlr_date;
                 }
               } else {
                 return '';
               }
             }*/
          },
          {headerName: "Additional Notes", field: "note", width: 100},
          {headerName: "Line Type", field: "line_type", width: 100},
        ]
      },
      {
        headerName: 'PRODUCT INFO',

        children: [
          {headerName: "Product Name", field: "prod_name", width: 100},
          {headerName: "Product Code", field: "prod_code", width: 100},
          {headerName: "Product Family", field: "prod_family", width: 100},
          {headerName: "Product Brand", field: "prod_brand", width: 100},
          {headerName: "Product Category", field: "prod_categ", width: 100},
          {headerName: "Price", field: "price_unit", width: 100},
          {headerName: "Serial No", field: "serial_no", width: 100},
          {headerName: "Order Qty", field: "qty", width: 100, aggFunc: 'sum'},
          // {headerName: "Delivered Qty", field: "dlr_qty", width: 100, aggFunc: 'sum'},
          // {headerName: "Invoiced Qty", field: "inv_qty", width: 100, aggFunc: 'sum'},
          {headerName: "Qty in RUOM", field: "qty_ruom", width: 100, hide: true, aggFunc: 'sum'},
          // {headerName: "Delivered Qty in RUOM", field: "dlr_qty_ruom", width: 100},
          // {headerName: "Invoiced Qty in RUOM", field: "inv_qty_ruom", width: 100},
          {headerName: "Line Disc(%)", field: "line_discount_in_pct", hide: true, width: 100},
          {headerName: "Line Disc", field: "line_discount_in_amt", hide: true, width: 100},
          {headerName: "SPL Disc", field: "spl_discount", hide: true, width: 100},
          {headerName: "Scheme Disc", field: "scheme_disc", hide: true, width: 100},
          {headerName: "Discount", field: "discount", width: 100},
          {headerName: "Credits", field: "credits", hide: true, width: 100},
          {headerName: "Subtotal", field: "price_subtotal", width: 100, aggFunc: 'sum'},
          {
            headerName: "CGST", field: "tax_json", width: 100, hide: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'CGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {
            headerName: "SGST", field: "tax_json", cellStyle: {textAlign: 'center'}, width: 100, hide: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'SGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {
            headerName: "IGST", field: "tax_json", cellStyle: {textAlign: 'center'}, width: 100, hide: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.tax_json !== null) {
                  {
                    const found = params.data.tax_json.find(existed => existed.name = 'IGST');
                    if (found !== null && found !== undefined) {
                      return ((params.data.price_subtotal * found.amt) / 100).toFixed(4);
                    }
                  }
                }
              }
            }
          },
          {headerName: "Tax", field: "price_tax", cellStyle: {textAlign: 'center'}, width: 100},
          {headerName: "Total", field: "price_total", cellStyle: {textAlign: 'center'}, width: 100},
          {
            headerName: "TDS/TCS", field: "tax_source", width: 100, hide: true,
            valueGetter: params => {
              if (params.data) {
                return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
              }
            }
          }
        ]
      },
    ];

  }
}

