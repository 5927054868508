import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-pricelist-master',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})


export class PricelistMasterListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Pricelist Master');

    /* this.showableFilters.region = true;
     this.showableFilters.territory = true;
     this.showableFilters.salesman = true;
     this.showableFilters.team = true;*/
    this.showDate = false;

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/pwa_connects/pricelist_report';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Pricelist', field: 'pricelist', width: 100},
      {headerName: 'Product', field: 'product', width: 100},
      {headerName: 'Code', field: 'code', width: 100},
      {headerName: 'MRP', field: 'mrp', width: 100},
      {
        headerName: 'Margin', field: 'margin_perc', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.margin_perc) {
              return parseFloat(params.data.margin_perc).toFixed(3);
            } else {
              return 0.000;
            }
          }
        }
      },
      {
        headerName: 'PTR', field: 'ptr', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.ptr) {
              return parseFloat(params.data.ptr).toFixed(3);
            } else {
              return 0.000;
            }
          }
        }
      },
      {
        headerName: 'Tax', field: 'tax', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.tax) {
              return parseFloat(params.data.tax).toFixed(3);
            } else {
              return 0.000;
            }
          }
        }
      },
      {
        headerName: 'Before Tax', field: 'before_tax', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.before_tax) {
              return parseFloat(params.data.before_tax).toFixed(3);
            } else {
              return 0.000;
            }
          }
        }
      },
    ];
  }

  onCellClicked(event) {

  }


}
