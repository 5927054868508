import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-community/all-modules";
import {ApiService} from "../_services/api.service";
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {SaveMTsaleComponent} from "../saveMTsale.component";
import {MtCellEditComponent} from "../mtCellEdit.component";
import {DomSanitizer} from '@angular/platform-browser';
import {PinnedCellRenderer} from "../pinnedCellRenderer.component";
import {CustomtooltipComponent} from "../customtooltip.component";
import {TooltipComponent} from "./tooltip.component";
import * as XLSX from "xlsx-js-style";
import {environment} from "../../environments/environment";
import {AddressCellRendererComponent} from "../addressCellRenderer.component";

declare let $: any;
@Component({
  selector: 'app-mtsales-outlets',
  templateUrl: './mtsales_outlets.component.html',
  styleUrls: ['./mtsales_outlets.component.scss']
})
export class MtSalesOutletsComponent implements AfterViewInit {
  @ViewChild('genInvoice') public genInvoice: ElementRef;
  @ViewChild('printInvoice') printInvoice: ElementRef<HTMLElement>;
  @ViewChild('customPrintClick') customPrintClick: ElementRef<HTMLElement>;
  @ViewChild('fileUp') fileUp: ElementRef;

  user;
  res_data;
  user_data = {
    date: null,
    dc_id: null,
    van_id: null,
    dist_id: null,
    access_token: null,
    url: null,
    offst: null,
    data: []
  };
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public logo;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public keydata = [];
  public all_products = {};
  public context;
  public van_name;
  public all_orders = [];
  public tcs;
  public confirm_enable = true;
  public confirm_loading = false;
  public invoice_orders = [];
  public free_orders = [];
  public generate_loading = false;
  public irn_loading = false;
  public modules = AllModules;
  public label_config = {};
  public math = Math;
  public initial_load = true;
  public min;
  public max;
  public prodRange = {};
  public pinnedData = [
    {
      Outlet: "SUGGESTED"
    },
    {
      Outlet: "TOTAL"
    }];
  public enable_recommented = false;
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2400000;
  public act_channel_split = {};
  public actChannelProds = {};
  public templ_config;
  public template;
  public selectedTemplate = null;
  public upload_stock = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public uploadLoader = false;
  public uploadEnable = false;
  public uploadPodata;
  public poData;
  productlist = [];
  private gridApi;
  private gridColumnApi;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  private rowHeight;
  private a = ['Zero ', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];
  public tabIndex = 0;
  public prevIndex;
  showAutoPopulate = false;
  autoPopulate = false;
  addressLibrary = [];
  feature_select_address = false;
  feature_promo_full_price = false;
  feature_discount_on_basic_rate = false;
  gst_template;
  non_gst_template;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public domSanitizer: DomSanitizer,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    this.user_data.offst = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    if (this.user_data.date === null || this.user_data.date === "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.van_id = JSON.parse(localStorage.getItem('mt_params')).van_id;
    this.user_data.dc_id = JSON.parse(localStorage.getItem('mt_params')).dc_id;
    this.user_data.dist_id = JSON.parse(localStorage.getItem('mt_params')).dist_id;
    this.van_name = JSON.parse(localStorage.getItem('mt_params'))['VAN'];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    let role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_enable_recommented_preorder' === existed.name);

    if (role_features.length > 0) {
      this.enable_recommented = true;
    }

    role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_select_mt_address' === existed.name);

    if (role_features.length > 0) {
      this.feature_select_address = true;
    }

    role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_promo_full_price' === existed.name);

    if (role_features.length > 0) {
      this.feature_promo_full_price = true;
    }

    role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_discount_on_basic_rate' === existed.name);

    if (role_features.length > 0) {
      this.feature_discount_on_basic_rate = true;
    }


    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');
    this.addressLibrary = JSON.parse(localStorage.getItem('cust_addresses'));

    const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
    const updated = custom_templates.filter(elem => {
      return elem.type === 'Secondary Print PDF';
    });
    if (updated.length > 0) {
      this.templ_config = [{name: 'Default'}].concat(updated);
      this.template = true;
    }
    const appConfig = JSON.parse(localStorage.getItem('app')).config_json;
    if (appConfig && appConfig.hasOwnProperty('default_gst_print')) {
      const gst_template = updated.find(x => x.id === appConfig.default_gst_print);
      if (gst_template) {
        this.gst_template = gst_template;
      } else {
        this.gst_template = null;
      }
    } else {
      this.gst_template = null;
    }

    if (appConfig && appConfig.hasOwnProperty('default_non_gst_print')) {
      const non_gst_template = updated.find(x => x.id === appConfig.default_non_gst_print);
      if (non_gst_template) {
        this.non_gst_template = non_gst_template;
      } else {
        this.non_gst_template = null;
      }
    } else {
      this.non_gst_template = null;
    }

    this.gridOptions = {
      rowHeight: 80, headerHeight: 60,
      rowStyle: {'text-align': 'left', padding: '20px 0px'},
      getRowStyle: this.getRowStyleFunc,
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions.getRowHeight = params => {
      let row_ht = 80;
      if (params.data['Outlet'] === 'TOTAL' || params.data['Outlet'] === 'SUGGESTED') {
        row_ht = 30;
      }
      return row_ht;
    };
    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.status === 'Suspended') {
          return false;
        } else {
          return true;
        }
      }
    };

    this.apiDcMtOutList();

  }

  async get_mt_prods() {
    const all_prod = {};
    const res = await this.apiService.getMTproducts(this.user_data);
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      console.log(res);
      this.productlist = res['results'].data;
      const partners = res['results'].data.map(item => item.partner_id)
        .filter((value, index, self) => self.indexOf(value) === index);

      partners.forEach(partner => {
        const prod = {};
        res['results'].data.forEach(data => {
          if (partner === data.partner_id) {
            prod['p_' + data.id] = {
              name: data.name,
              default_code: data.default_code,
              category_id: data.category_id,
              unit_price: data.unit_price,
              sale_price: data.sale_price,
              margin: data.margin,
              tax: parseFloat(data.tax),
              product_uom: data.uom_id,
              taxmaster_id: data.taxmaster_id,
              stock: data.stock,
              break_up: JSON.parse(data.break_up),
              hide: data.prod_status,
              extra_margin: data.extra_margin,
              mrp: data.mrp,
              original_price: parseFloat(data.unit_price) * (1 - (parseFloat(data.margin) / 100))
            };
          }
        });
        all_prod[partner] = prod;
      });
      localStorage.setItem('products', JSON.stringify(this.all_products));
      return all_prod;
    } else {
      return all_prod;
    }
  }

  inWords(num, inthis) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (inthis.a[Number(n[1])] || inthis.b[n[1][0]] + ' ' + inthis.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (inthis.a[Number(n[2])] || inthis.b[n[2][0]] + ' ' + inthis.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (inthis.a[Number(n[3])] || inthis.b[n[3][0]] + ' ' + inthis.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (inthis.a[Number(n[4])] || inthis.b[n[4][0]] + ' ' + inthis.a[n[4][1]]) + 'Hundred ' : '';
    if (inthis.a[Number(n[5])]) {
      str += inthis.a[Number(n[5])];
    } else {
      if (inthis.a[n[5][1]] !== 'Zero ') {
        str += inthis.b[n[5][0]] + ' ' + inthis.a[n[5][1]];
      } else {
        str += inthis.b[n[5][0]];
      }
    }
    return str;
  }

  getRowStyleFunc(params) {
    if (params.data.Outlet === 'SUGGESTED' || params.data.Outlet === 'TOTAL') {
      return {
        'background-color': 'white'
      };
    } else {
      if (params.data.po === null) {
        return {
          'background-color': 'FloralWhite'
        };
      } else if (params.data.invoice !== null) {
        return {
          'background-color': 'Gainsboro'
        };
      }
      if (params.data.status === 'Suspended') {
        return {
          'background-color': '#F8D1C8'
        };
      }
    }
  }

  async apiDcMtOutList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.confirm_loading = false;
    this.columnDefs = [];
    const rowdata = [];
    let keydata = [];
    const act_channel_split = {};
    const actChannelProds = {};
    this.pinnedData = [
      {
        Outlet: "SUGGESTED"
      },
      {
        Outlet: "TOTAL"
      }];
    const suggestedPinn = this.pinnedData[0];
    const totalPinn = this.pinnedData[1];
    const prod_range = {};
    let pre_qty = [];
    let pre_filtered = [];

    if (this.initial_load) {
      this.initial_load = false;
      const all_products = await this.get_mt_prods();
      this.all_products = all_products;
    }
    this.res_data = await this.apiService.dcmtoutsales(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        for (const k in res['results'].data[0]) {
          keydata.push(k);
        }
        keydata = keydata.splice(11);
        const i = 0;

        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          /*  res['results'].data[0].status = 'Suspended'*/
          res['results'].data.forEach(data => {
            pre_qty = res['results'].pre;
            let out_addr = '';
            const outlet = res['results'].outlets.find(existed => existed.outlet_id === data.out_id);
            if (outlet !== null && outlet !== undefined) {
              out_addr = outlet.out_name;
            }
            let billto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(data.out_id, 10)) && (existed.type === 'invoice')));
            billto_adrr_list = [{id: data.out_id, parent_id: data.out_id, name: 'Default'}].concat(billto_adrr_list);

            let shipto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(data.out_id, 10)) && (existed.type === 'delivery')));
            shipto_adrr_list = [{id: data.out_id, parent_id: data.out_id, name: 'Default'}].concat(shipto_adrr_list);

            const pushData = {
              Outlet: data.outlet,
              code: data.code,
              po: data.po,
              invoice: data.invoice_no,
              order_id: data.order_id,
              distributor_id: data.distributor_id,
              id: data.real_id,
              error_status: data.error_status,
              partner_id: data.out_id,
              tax_source: data.tax_source,
              type: data.type,
              status: 'Active',
              mbq_type: "",
              mbq_qty: 0,
              showSug: false
            };

            if (this.feature_select_address) {
              pushData['Bill To'] = billto_adrr_list[0].id;
              pushData['Ship To'] = shipto_adrr_list[0].id;
              pushData['billto_adrr_list'] = billto_adrr_list;
              pushData['shipto_adrr_list'] = shipto_adrr_list;
            }

            if (outlet !== null && outlet !== undefined) {
              pushData.status = outlet.status;
            }

            const outmbqdata = res['results'].mbq.filter(existed => existed.retailer_id === data.out_id);
            for (const key in keydata) {
              const p_id = parseInt(keydata[key].split('_')[1], 10);
              let splitData;
              let scheme = false;
              if (!totalPinn[keydata[key]]) {
                totalPinn[keydata[key]] = 0;
              }
              let indmbq = {};
              let showSug = false;
              if (outmbqdata) {
                indmbq = outmbqdata.find(existed => existed.product_id === p_id);
              }
              if (data[keydata[key]] !== null || indmbq) {
                if (data[keydata[key]] && data[keydata[key]].includes('$$')) {
                  splitData = data[keydata[key]].split('$$');
                  scheme = (splitData[3] !== '0');
                  if (actChannelProds[keydata[key]]) {
                    if (actChannelProds[keydata[key]][data.type]) {
                      actChannelProds[keydata[key]][data.type] += parseFloat(splitData[6]);
                    } else {
                      actChannelProds[keydata[key]][data.type] = parseFloat(splitData[6]);
                    }
                  } else {
                    actChannelProds[keydata[key]] = {};
                    actChannelProds[keydata[key]][data.type] = parseFloat(splitData[6]);
                  }
                }
                let mbq_type = '';
                let mbq_qty = 0;
                let qty = 0;

                if (indmbq && this.enable_recommented && !data.real_id) {
                  showSug = true;
                  mbq_type = indmbq['mbq_name'];
                  mbq_qty = indmbq['mbq_qty'];
                  qty = mbq_qty;
                } else if (splitData) {
                  qty = splitData[6];
                }
                if (splitData) {
                  if (splitData[2] === 'cancel' || splitData[2] === 'draft') {
                    pushData[keydata[key]] = {
                      qty,
                      sug: splitData[5],
                      prvs_qty: splitData[0],
                      scheme,
                      price_unit: splitData[4],
                      stock: parseInt(splitData[0], 10) - parseInt(splitData[1], 10),
                      tax: splitData[7],
                      extra_margin: splitData[13],
                      margin: splitData[5],
                      mrp: splitData[14],
                      original_price: splitData[15],
                      mbq_type,
                      mbq_qty,
                      showSug
                    };
                    totalPinn[keydata[key]] += parseFloat(splitData[6]);
                  } else if (splitData[2] === 'done' || splitData[2] === 'sale') {
                    pushData[keydata[key]] = {
                      qty,
                      sug: splitData[5],
                      prvs_qty: splitData[0],
                      scheme,
                      price_unit: splitData[4],
                      stock: parseInt(splitData[0], 10) - parseInt(splitData[1], 10),
                      tax: splitData[7],
                      extra_margin: splitData[13],
                      margin: splitData[5],
                      mrp: splitData[14],
                      original_price: splitData[15],
                      mbq_type,
                      mbq_qty,
                      showSug
                    };
                    totalPinn[keydata[key]] += parseFloat(splitData[6]);
                  } else {
                    pushData[keydata[key]] = {
                      sug: 0, qty: 0, prvs_qty: 0,
                      scheme, price_unit: 0, stock: 0, tax: 0, extra_margin: 0, margin: 0,
                      mrp: 0, original_price: 0, mbq_type, mbq_qty, showSug
                    };
                  }
                } else {
                  pushData[keydata[key]] = {
                    sug: 0, qty, prvs_qty: 0,
                    scheme, price_unit: 0, stock: 0, tax: 0, extra_margin: 0, margin: 0,
                    mrp: 0, original_price: 0, mbq_type, mbq_qty, showSug
                  };
                }
              } else {
                pushData[keydata[key]] = {
                  sug: 0, qty: 0, prvs_qty: 0,
                  scheme, price_unit: 0, stock: 0, tax: 0, extra_margin: 0, margin: 0,
                  mrp: 0, original_price: 0, mbq_type: "", mbq_qty: 0, showSug
                };
              }
            }
            for (const k in keydata) {
              pre_filtered = [];
              if (!suggestedPinn[keydata[k]]) {
                pre_filtered = pre_qty.filter(pre => {
                  return parseInt(keydata[k].split('_')[1], 10) === pre.product_id;
                });
                suggestedPinn[keydata[k]] = 0;
                prod_range[keydata[k]] = '0$0';
                if (pre_filtered.length > 0) {
                  if (pre_filtered[0].pre_json && pre_filtered[0].pre_json.preorder) {
                    if (pre_filtered[0].pre_json.preorder.qty) {
                      suggestedPinn[keydata[k]] = pre_filtered[0].pre_json.preorder.qty;
                    }
                    if (pre_filtered[0].pre_json.preorder.min) {
                      prod_range[keydata[k]] = pre_filtered[0].pre_json.preorder.min;
                    } else {
                      prod_range[keydata[k]] = '0';
                    }
                    if (pre_filtered[0].pre_json.preorder.max) {
                      prod_range[keydata[k]] += '$' + pre_filtered[0].pre_json.preorder.max;
                    } else {
                      prod_range[keydata[k]] += '$0';
                    }
                    if (pre_filtered[0].pre_json.channel_split) {
                      prod_range[keydata[k]] += '$' + JSON.stringify(pre_filtered[0].pre_json.channel_split);
                    }
                  }
                }
              }
            }
            rowdata.push(pushData);
          });
          console.log(rowdata);
          this.data = rowdata[0];
          this.rowData = rowdata;
          this.keydata = keydata;
          this.prodRange = prod_range;
          this.act_channel_split = act_channel_split;
          this.actChannelProds = actChannelProds;
          this.pinnedData[1] = totalPinn;
          this.gridOptions.pinnedBottomRowData = [this.pinnedData[1]];
          if (this.enable_recommented) {
            this.pinnedData[0] = suggestedPinn;
            this.gridOptions.pinnedTopRowData = [this.pinnedData[0]];
          }
          const all_products = this.all_products[Object.keys(this.all_products)[0]];

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'Outlet') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, resizable: true, width: 250,
                  headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, pinned: 'left',
                  checkboxSelection(params) {
                    if (params.data.status === 'Suspended') {
                      return false;
                    } else {
                      return (params.data.po !== null);
                    }
                  },
                  cellRenderer(params) {
                    let displayElem = '';
                    if (params.value === 'TOTAL' || params.value === 'SUGGESTED') {
                      return params.value;
                    } else if (params.data['po']) {
                      if (params.data.error_status === 'error') {
                        displayElem = '<p><b>(' + params.data['code'] + ') ' + params.value + '</b><br>po: ' + params.data['po'] + '<br/>inv: ' + params.data['invoice'] + '/<span style="color: red;">IRN Failed</span></p>';
                      } else {
                        displayElem = '<p><b>(' + params.data['code'] + ') ' + params.value + '</b><br>po: ' + params.data['po'] + '<br/>inv: ' + params.data['invoice'] + '</p>';
                      }
                    } else {
                      displayElem = '<p><b>(' + params.data['code'] + ') ' + params.value + '</b></p>';
                    }
                    return displayElem;
                  },
                });
              } else if (key === 'Bill To' || key === 'Ship To') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, resizable: true, width: 150,
                  pinned: 'left',
                  cellRenderer: AddressCellRendererComponent
                });
              } else if (this.data[key] !== null && typeof (this.data[key]) === 'object' && all_products[key] !== undefined) {
                this.columnDefs.push({
                  headerName: all_products[key].name, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                  width: 120, resizable: true,
                  tooltipComponent: 'tooltipComponent',
                  tooltipField: key,
                  cellRendererSelector(params) {
                    if (params.node.rowPinned) {
                      return {
                        component: PinnedCellRenderer,
                      };
                    } else {
                      return {
                        component: MtCellEditComponent,
                      };
                    }
                  },
                });
              } else {
                this.columnDefs.push({
                  headerName: key, field: key, hide: true, sortable: true, filter: false, unSortIcon: true, lockPosition: true, resizable: true, width: 120
                });
              }

            }
          }
          this.loaded = true;

          this.context = {componentParent: this};
          this.frameworkComponents = {
            printInvoicecomponent: PrintInvoiceComponent,
            printClickRenderer: PrintClickRenderer,
            saveClickRenderer: SaveMTsaleComponent,
            mtcellEditRenderer: MtCellEditComponent,
            pinnedCellRenderer: PinnedCellRenderer,
            customtooltipComponent: CustomtooltipComponent,
            tooltipComponent: TooltipComponent,
            addressCellRendererComponent: AddressCellRendererComponent
          };
          console.log(this.data);
        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  update_with_stock() {

    const rows = this.gridApi.getSelectedRows();
    const prodKeyData = this.keydata;
    const total = this.gridOptions.pinnedBottomRowData;
    let updata;
    this.rowData.forEach(row => {
      updata = rows.filter(exist => {
        return (!row.id && (row.order_id === exist.order_id));
      });
      if (updata.length > 0) {
        for (const prod in prodKeyData) {
          total[0][prodKeyData[prod]] += (row[prodKeyData[prod]].stock - row[prodKeyData[prod]].qty);
          row[prodKeyData[prod]].qty = row[prodKeyData[prod]].stock;
        }
      }
    });
    this.gridApi.setPinnedBottomRowData(total);
    this.gridApi.setRowData(this.rowData);
    /* const rows = this.gridApi.getSelectedRows();
     const total = this.gridOptions.pinnedBottomRowData;
     let updata;
     const prodKeyData = this.keydata;
     this.rowData.forEach(row => {
       updata = rows.filter(exist => {
         return (!row.id && (row.order_id === exist.order_id));
       });
       if (updata.length > 0) {
         this.autoPopulate = true;
         for (const prod in prodKeyData) {
           total[0][prodKeyData[prod]] += row[prodKeyData[prod]].mbq_qty;
           row[prodKeyData[prod]].qty = row[prodKeyData[prod]].mbq_qty;
           row[prodKeyData[prod]].showSug = true;
         }
       }
     });
     this.gridApi.setPinnedBottomRowData(total);
     this.gridApi.setRowData(this.rowData);*/
  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected) {
        this.showAutoPopulate = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected) {
        this.showAutoPopulate = true;
      } else {
        this.showAutoPopulate = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  saveMT() {

    this.confirm_loading = true;
    this.confirm_enable = false;
    // let orders = [];
    // let keydat = [];
    // let distributor_id;
    // let rows = this.gridApi.getSelectedRows();
    // var amount_total = 0;
    // var amount_untaxed = 0;
    // var amount_tax = 0;
    // let all_products = this.all_products;
    // let abort_save = false;
    // let cancel_prod = [];
    //
    // rows.forEach(order =>  {
    //   amount_total = 0;
    //   amount_untaxed = 0;
    //   amount_tax = 0;
    //   keydat = [];
    //   let line_data = [];
    //   let new_data = [];
    //   let unit_price = 0;
    //   var price_subtotal = 0;
    //   var price_total = 0;
    //   var price_tax = 0;
    //   distributor_id = order.distributor_id;
    //   for (var k in order) keydat.push(k);
    //   keydat = keydat.splice(7);
    //   keydat.forEach(function (prod) {
    //     if (parseInt(order[prod].prvs_qty,10) > 0 || parseInt(order[prod].qty,10) > 0) {
    //
    //       if (parseInt(order[prod].qty,10) > parseInt(all_products[prod].stock,10)) {
    //         cancel_prod.push(all_products[prod].name)
    //         abort_save = true;
    //       }else {
    //         if (order[prod].price_unit === 0) {
    //           unit_price = parseFloat(all_products[prod].unit_price)*(1-(parseFloat(all_products[prod].margin)/100));
    //           price_subtotal = parseFloat(order[prod].qty) * unit_price;
    //           price_total = (price_subtotal * (100 + parseFloat(all_products[prod].tax)))/100;
    //           price_tax = price_total - price_subtotal;
    //           new_data.push({
    //             'order_id': order.order_id,
    //             'product_id': parseInt(prod.split('_')[1], 10),
    //             'prod_name': all_products[prod].name,
    //             'qty': parseInt(order[prod].qty, 10),
    //             'price_total': price_total,
    //             'price_subtotal': price_subtotal,
    //             'price_tax': price_tax,
    //             'product_uom': all_products[prod].product_uom,
    //             'taxmaster_id': all_products[prod].taxmaster_id,
    //             'price_unit': all_products[prod].unit_price,
    //             'name': all_products[prod].name
    //           })
    //         }else {
    //           price_subtotal = parseFloat(order[prod].qty) * parseFloat(order[prod].price_unit);
    //           price_total = (price_subtotal * (100 + parseFloat(order[prod].tax))) / 100;
    //           price_tax = price_total - price_subtotal;
    //
    //           line_data.push({
    //             'order_id': order.order_id,
    //             'product_id': parseInt(prod.split('_')[1], 10),
    //             'qty': parseInt(order[prod].qty, 10),
    //             'price_total': price_total,
    //             'price_subtotal': price_subtotal,
    //             'price_tax': price_tax
    //           })
    //         }
    //         amount_total += price_total;
    //         amount_untaxed += price_subtotal;
    //         amount_tax += price_tax;
    //       }
    //     }
    //   });
    //   orders.push({
    //     'order_id': order.order_id,
    //     'amount_total': amount_total,
    //     'amount_untaxed': amount_untaxed,
    //     'amount_tax': amount_tax,
    //     'order_name': 'O_'+order.distributor_id+'_'+ Date.now(),
    //     'line_data': line_data,
    //     'new_data': new_data
    //   })
    // });
    const free_items = this.free_orders;
    const orders = this.invoice_orders;
    const products = this.all_products;
    const bxgx_items = [];
    let distributor_id;
    /* let config_json:any = {};
     config_json.pwa_ver = environment.appVersion;
     config_json.order = {};*/
    orders.forEach(each_order => {
      each_order.config_json.pwa_ver = environment.appVersion;
      //each_order.config_json = config_json;
      const all_products = products[each_order.partner_id];
      distributor_id = each_order.distributor_id;
      free_items.forEach(scheme_lines => {
        if (each_order.order_id === scheme_lines.order_id) {
          const line_data = [];
          each_order.line_data.forEach(each_line => {
            scheme_lines.lines.forEach(schemes => {
              schemes.free.forEach(free_lines => {
                if (!free_lines.new) {
                  free_lines.new = true;
                  each_order.amount_untaxed = parseFloat(each_order.amount_untaxed) + parseFloat(free_lines.price_subtotal);
                  each_order.amount_total = parseFloat(each_order.amount_total) + parseFloat(free_lines.price_total);
                  each_order.amount_tax = parseFloat(each_order.amount_tax) + parseFloat(free_lines.price_tax);
                    if (free_lines.scheme_type === 'bxgx') {
                      bxgx_items.push(free_lines);
                    } else {
                      line_data.push(free_lines);
                    }
                 // line_data.push(free_lines);
                }
              });
              schemes.lines.forEach(line => {
                if (each_line.product_id === line.product_id) {
                  if (this.feature_promo_full_price) {
                    each_line.scheme_code = line.scheme_code;
                  }
                  // if (parseFloat(line.scheme_discount) > 0) {
                  // each_line.price_total = parseFloat(each_line.price_total) - parseFloat(line.scheme_discount);
                  // each_order.amount_total = parseFloat(each_order.amount_total) - parseFloat(line.scheme_discount);
                  let price_tax = 0;
                  const temp = parseFloat(each_line.price_subtotal);
                  each_order.amount_untaxed = parseFloat(each_order.amount_untaxed) - parseFloat(each_line.price_subtotal);
                  each_order.amount_total = parseFloat(each_order.amount_total) - parseFloat(each_line.price_total);
                  each_order.amount_tax = parseFloat(each_order.amount_tax) - parseFloat(each_line.price_tax);
                  each_order.tcs = parseFloat(each_order.tcs) - parseFloat(each_line.tcs);
                  each_line.price_subtotal = parseFloat(each_line.price_unit) * parseFloat(each_line.qty);

                  if (each_line.price_subtotal !== temp) {
                    /*if (this.feature_discount_on_basic_rate) {
                      each_line.price_subtotal = each_line.price_subtotal / (1 + (parseFloat(all_products['p_' + each_line.product_id].tax) / 100));
                      each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                    } else {
                      each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                      each_line.price_subtotal = each_line.price_subtotal / (1 + (parseFloat(all_products['p_' + each_line.product_id].tax) / 100));
                    }*/
                    each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                    each_line.price_subtotal = each_line.price_subtotal / (1 + (parseFloat(all_products['p_' + each_line.product_id].tax) / 100));
                  } else {
                    each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                  }

                  /* all_products['p_'+each_line.product_id].break_up.forEach(function(tax) {
                     price_tax += (each_line.price_subtotal*parseFloat(tax.tax))/100
                   });*/
                  price_tax = (each_line.price_subtotal * all_products['p_' + each_line.product_id].tax) / 100;
                  each_line.price_tax = price_tax;
                  each_line.price_total = each_line.price_subtotal + price_tax;
                  each_line.tcs = each_line.price_total * (parseFloat(each_order.tax_source) / 100);

                  each_order.amount_untaxed = each_order.amount_untaxed + each_line.price_subtotal;
                  each_order.amount_total = each_order.amount_total + each_line.price_total;
                  each_order.amount_tax = each_order.amount_tax + each_line.price_tax;
                  each_order.tcs = each_order.tcs + each_line.tcs;
                  each_line.scheme_id = line.scheme_id;
                  each_line.scheme_discount = line.scheme_discount;

                  if (bxgx_items && bxgx_items.length > 0) {
                    const bxgxdata = bxgx_items.find(x => x.product_id === each_line.product_id);
                    if (bxgxdata) {
                      const bxgx_scheme = bxgxdata['qty'] * parseFloat(each_line.sale_price);
                      each_line.scheme_discount = each_line.scheme_discount + bxgx_scheme;
                      each_line.qty += bxgxdata['qty'];
                    }
                  }

                  const o_mr = parseFloat(String(((all_products['p_' + each_line.product_id].mrp - all_products['p_' + each_line.product_id].original_price) / (all_products['p_' + each_line.product_id].mrp)) * 100)).toFixed(3);
                  const line_config: any = {};
                  line_config.mrp = all_products['p_' + each_line.product_id].mrp;
                  line_config.o_ptr = all_products['p_' + each_line.product_id].original_price;
                  line_config.o_mr = o_mr;
                  line_config.o_dis = all_products['p_' + each_line.product_id].extra_margin;
                  line_config.order = {};
                  line_config.order.total = parseFloat(String(each_line.price_total)).toFixed(3);
                  line_config.order.tax = parseFloat(String(price_tax)).toFixed(3);
                  line_config.order.sub_total = parseFloat(String(each_line.price_subtotal)).toFixed(3);
                  line_config.order.dis = each_line.discount;
                  line_config.order.sch_dis = parseFloat(String(each_line.scheme_discount)).toFixed(3);
                  line_config.order.sch_id = each_line.scheme_id;
                  each_line.config_json = line_config;
                  // }
                }
              });
            });
            line_data.push(each_line);
          });
          each_order.line_data = line_data;
        }
      });

      each_order.amount_untaxed = parseFloat(parseFloat(each_order.amount_untaxed).toFixed(3));
      each_order.amount_total = parseFloat(parseFloat(each_order.amount_total).toFixed(3));
      each_order.amount_tax = parseFloat(parseFloat(each_order.amount_tax).toFixed(3));

      each_order.config_json.order.total = each_order.amount_total;
      each_order.config_json.order.tax = each_order.amount_tax;
      each_order.config_json.order.sub_total = each_order.amount_untaxed;
      /* each_order.config_json.order.dis = each_order.discount;
       each_order.config_json.order.sch_dis = each_order.scheme_discount;
       each_order.config_json.order.sch_id = each_order.scheme_id;*/
    });

    /* const inThis = this;

     orders.forEach(function(each_order) {
       var all_products = products[each_order.partner_id];
       each_order.line_data.forEach(function(line) {
         if (line.type === 'promo' && line.scheme_type === 'bxgx') {
           /!*if (line.criteria_mode === 'and_combo') {
             const parentlines = each_order['line_data'].filter(
               existed => existed.scheme_code === line.scheme_code && existed.type === 'normal');
             if (parentlines !== null && parentlines !== undefined && parentlines.length > 0) {
               let newdiscount = 0;
               newdiscount = parseFloat(line.price_subtotal) / parentlines.length;
               parentlines.forEach(parentLine => {
                 if (parentLine.scheme_discount === null) {
                   parentLine.scheme_discount = 0;
                 }
                 try {
                   each_order['line_data'][each_order['line_data'].indexOf(parentLine)].scheme_discount = parseFloat(parentLine.scheme_discount) + newdiscount;
                   each_order['line_data'][each_order['line_data'].indexOf(parentLine)].scheme_discount = parseFloat(parentLine.scheme_discount).toFixed(inThis.apiService.decimalPolicy);
                 } catch (e) {
                 }
               });
             }
           } else {*!/
             const parentlineIndex = each_order['line_data'].findIndex(existed => existed.product_id === line.foc_product_id );
             if (parentlineIndex !== null && parentlineIndex !== undefined && parentlineIndex !== -1) {
               each_order['line_data'][parentlineIndex].scheme_discount = parseFloat(each_order['line_data'][parentlineIndex].scheme_discount) + parseFloat(line.price_subtotal);
             }
          // }
         }

         if (parseFloat(line.scheme_discount) > 0) {
           // each_line.price_total = parseFloat(each_line.price_total) - parseFloat(line.scheme_discount);
           // each_order.amount_total = parseFloat(each_order.amount_total) - parseFloat(line.scheme_discount);
           var price_tax = 0;
           var temp = parseFloat(line.price_subtotal);
           each_order.amount_untaxed = parseFloat(each_order.amount_untaxed) - parseFloat(line.price_subtotal);
           each_order.amount_total = parseFloat(each_order.amount_total) - parseFloat(line.price_total);
           each_order.amount_tax = parseFloat(each_order.amount_tax) - parseFloat(line.price_tax);
           each_order.tcs = parseFloat(each_order.tcs) - parseFloat(line.tcs);

           line.price_subtotal = parseFloat(line.price_unit) * parseFloat(line.qty);
           if (line.price_subtotal != temp) {
             line.price_subtotal = line.price_subtotal / (1 + (parseFloat(all_products['p_' + line.product_id].tax) / 100));
             line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
           } else {
             line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
           }

           /!* all_products['p_'+each_line.product_id].break_up.forEach(function(tax) {
              price_tax += (each_line.price_subtotal*parseFloat(tax.tax))/100
            });*!/
           price_tax = (line.price_subtotal * all_products['p_' + line.product_id].tax) / 100;
           line.price_tax = price_tax;
           line.price_total = line.price_subtotal + price_tax;
           line.tcs = line.price_total * (parseFloat(each_order.tax_source) / 100);

           each_order.amount_untaxed = each_order.amount_untaxed + line.price_subtotal;
           each_order.amount_total = each_order.amount_total + line.price_total;
           each_order.amount_tax = each_order.amount_tax + line.price_tax;
           each_order.tcs = each_order.tcs + line.tcs;
           line.scheme_id = line.scheme_id;
           line.scheme_discount = line.scheme_discount;
         }
       });
     });*/
    if (this.feature_promo_full_price) {
      const inThis = this;
      orders.forEach(each_order => {
        each_order.amount_untaxed = 0;
        each_order.amount_total = 0;
        each_order.amount_tax = 0;
        each_order.tcs = 0;
        const all_products = products[each_order.partner_id];
        each_order.line_data.forEach(line => {
          if (line.type === 'promo') {
            if (line.criteria_mode === 'and_combo') {
              const parentlines = each_order['line_data'].filter(
                existed => existed.scheme_code === line.scheme_code && existed.type === 'normal');
              if (parentlines !== null && parentlines !== undefined && parentlines.length > 0) {
                let newdiscount = 0;
                let subtotal = 0;
                parentlines.forEach(parentLine => {
                  subtotal += parentLine.price_subtotal;
                });
                parentlines.forEach(parentLine => {
                  if (parentLine.scheme_discount === null) {
                    parentLine.scheme_discount = 0;
                  }
                  newdiscount = (parseFloat(parentLine.price_subtotal) / subtotal) * line.price_subtotal;
                  newdiscount = parseFloat(newdiscount.toFixed(3));
                  try {
                    each_order['line_data'][each_order['line_data'].indexOf(parentLine)].scheme_discount = parseFloat(parentLine.scheme_discount) + newdiscount;
                    each_order['line_data'][each_order['line_data'].indexOf(parentLine)].scheme_discount = parseFloat(parentLine.scheme_discount).toFixed(inThis.apiService.decimalPolicy);
                  } catch (e) {
                  }
                });
              }
            } else {
              const parentlineIndex = each_order['line_data'].findIndex(existed => existed.product_id === line.foc_product_id);
              if (parentlineIndex !== null && parentlineIndex !== undefined && parentlineIndex !== -1) {
                each_order['line_data'][parentlineIndex].scheme_discount = parseFloat(each_order['line_data'][parentlineIndex].scheme_discount) + parseFloat(line.price_subtotal);
              }
            }
          }

          //if (parseFloat(line.scheme_discount) > 0) {
          // each_line.price_total = parseFloat(each_line.price_total) - parseFloat(line.scheme_discount);
          // each_order.amount_total = parseFloat(each_order.amount_total) - parseFloat(line.scheme_discount);
          let price_tax = 0;
          const temp = parseFloat(line.price_subtotal);


          line.price_subtotal = parseFloat(line.price_unit) * parseFloat(line.qty);
          if (line.price_subtotal !== temp) {
            if (this.feature_discount_on_basic_rate) {
              line.price_subtotal = line.price_subtotal / (1 + (parseFloat(all_products['p_' + line.product_id].tax) / 100));
              line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
            } else {
              line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
              line.price_subtotal = line.price_subtotal / (1 + (parseFloat(all_products['p_' + line.product_id].tax) / 100));
            }
            /*line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
            line.price_subtotal = line.price_subtotal / (1 + (parseFloat(all_products['p_' + line.product_id].tax) / 100));*/
          } else {
            line.price_subtotal = line.price_subtotal - parseFloat(line.scheme_discount);
          }

          /* all_products['p_'+each_line.product_id].break_up.forEach(function(tax) {
             price_tax += (each_line.price_subtotal*parseFloat(tax.tax))/100
           });*/

          price_tax = (line.price_subtotal * all_products['p_' + line.product_id].tax) / 100;
          line.price_tax = price_tax;
          line.price_total = line.price_subtotal + price_tax;
          line.tcs = line.price_total * (parseFloat(each_order.tax_source) / 100);

          each_order.amount_untaxed = each_order.amount_untaxed + line.price_subtotal;
          each_order.amount_total = each_order.amount_total + line.price_total;
          each_order.amount_tax = each_order.amount_tax + line.price_tax;
          each_order.tcs = each_order.tcs + line.tcs;

          line.price_subtotal = parseFloat(parseFloat(line.price_subtotal).toFixed(3));
          line.price_tax = parseFloat(parseFloat(line.price_tax).toFixed(3));
          line.price_total = parseFloat(parseFloat(line.price_total).toFixed(3));
          line.tcs = parseFloat(parseFloat(line.tcs).toFixed(3));

          const o_mr = parseFloat(String(((all_products['p_' + line.product_id].mrp - all_products['p_' + line.product_id].original_price) / (all_products['p_' + line.product_id].mrp)) * 100)).toFixed(3);
          const line_config: any = {};
          line_config.mrp = all_products['p_' + line.product_id].mrp;
          line_config.o_ptr = all_products['p_' + line.product_id].original_price;
          line_config.o_mr = o_mr;
          line_config.o_dis = all_products['p_' + line.product_id].extra_margin;
          line_config.order = {};
          line_config.order.total = parseFloat(String(line.price_total)).toFixed(3);
          line_config.order.tax = parseFloat(String(price_tax)).toFixed(3);
          line_config.order.sub_total = parseFloat(String(line.price_subtotal)).toFixed(3);
          line_config.order.dis = line.discount;
          line_config.order.sch_dis = parseFloat(String(line.scheme_discount)).toFixed(3);
          line_config.order.sch_id = line.scheme_id;
          line.config_json = line_config;
          //}
        });
        each_order.amount_untaxed = parseFloat(parseFloat(each_order.amount_untaxed).toFixed(3));
        each_order.amount_total = parseFloat(parseFloat(each_order.amount_total).toFixed(3));
        each_order.amount_tax = parseFloat(parseFloat(each_order.amount_tax).toFixed(3));
        each_order.tcs = parseFloat(parseFloat(each_order.tcs).toFixed(3));

        each_order.config_json.order.total = each_order.amount_total;
        each_order.config_json.order.tax = each_order.amount_tax;
        each_order.config_json.order.sub_total = each_order.amount_untaxed;
      });
    }

    const order_data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      orders,
      distributor_id,
      order_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    };

    this.res_data = this.apiService.savemtsales(order_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
        this.apiDcMtOutList();
      } else if (res.hasOwnProperty('results') && (res['results'].statusCode === 403)) {
        window.alert(res['results'].msg);
        this.confirm_loading = false;
      }

    }, error => {
      console.log(error);
      this.confirm_loading = false;
    });

  }

  generate_invoice() {
    this.generate_loading = true;
    const orders = [];
    let keydat = [];
    let distributor_id;
    const rows = this.gridApi.getSelectedRows();
    let amount_total = 0;
    let amount_untaxed = 0;
    let amount_tax = 0;
    let total_tcs = 0;
    const products = this.all_products;
    let abort_save = false;
    const cancel_prod = [];
    let printDetails = {};
    let amount_in_words;
    let invoice_date;
    const datePipe = this.datePipe;
    const all_orders = [];
    const inWords = this.inWords;
    const inThis = this;
    const tcs = this.tcs;
    let confirm_enable = true;
    const order_ids = [];
    const create_date = this.user_data.date;
    let all_products;
    const user_id = this.user_data.van_id;

    if (rows.length > 0) {

      rows.forEach(order => {
        amount_total = 0;
        amount_untaxed = 0;
        amount_tax = 0;
        total_tcs = 0;
        all_products = products[order.partner_id];
        keydat = [];
        const line_data = [];
        const new_data = [];
        let unit_price = 0;
        let sale_price = 0;
        let price_subtotal = 0;
        let price_total = 0;
        let price_tax = 0;
        let tax_source = 0;
        if (order.id !== null) {
          confirm_enable = false;
          order_ids.push(order.id);
        } else {
          order_ids.push(order.order_id);

          distributor_id = order.distributor_id;
          for (const k in order) {
            keydat.push(k);
          }
          //keydat = keydat.splice(17);
          keydat.forEach(prod => {
            if (prod && prod.includes('p_') && parseInt(order[prod].qty, 10) > 0) {

              if (!order.id && (parseInt(order[prod].qty, 10) > parseInt(order[prod].stock, 10))) {
                cancel_prod.push(all_products[prod].name);
                abort_save = true;
              } else {
                // if (order[prod].price_unit === 0) {
                unit_price = parseFloat(all_products[prod].unit_price) * (1 - (parseFloat(all_products[prod].margin) / 100));
                sale_price = parseFloat(all_products[prod].sale_price) * (1 - (parseFloat(all_products[prod].margin) / 100));
                price_subtotal = parseFloat(order[prod].qty) * sale_price;
                // price_total = parseFloat(order[prod].qty) * unit_price;
                price_tax = 0;
                /* all_products[prod].break_up.forEach(function(tax) {
                   price_tax += (price_subtotal*parseFloat(tax.tax))/100
                 });*/
                price_tax = (price_subtotal * all_products[prod].tax) / 100;
                price_total = price_subtotal + price_tax;
                tax_source = price_total * (parseFloat(order.tax_source) / 100);

                const o_mr = parseFloat(String(((all_products[prod].mrp - all_products[prod].original_price) / (all_products[prod].mrp)) * 100)).toFixed(3);
                const line_config: any = {};
                line_config.mrp = all_products[prod].mrp,
                  line_config.o_ptr = all_products[prod].original_price,
                  line_config.o_mr = ((all_products[prod].mrp - all_products[prod].original_price) / (all_products[prod].mrp)) * 100,
                  line_config.o_dis = all_products[prod].extra_margin,
                  line_config.order = {};
                line_config.order.total = parseFloat(String(price_total)).toFixed(3),
                  line_config.order.tax = parseFloat(String(price_tax)).toFixed(3),
                  line_config.order.sub_total = parseFloat(String(price_subtotal)).toFixed(3),
                  line_config.order.dis = 0;
                line_config.order.sch_dis = 0;
                line_config.order.sch_id = null;

                line_data.push({
                  order_id: order.order_id,
                  product_id: parseInt(prod.split('_')[1], 10),
                  prod_name: all_products[prod].name,
                  category_id: all_products[prod].category_id,
                  scheme_qty: parseInt(order[prod].qty, 10),
                  qty: parseInt(order[prod].qty, 10),
                  price_total,
                  price_subtotal,
                  price_tax,
                  tcs: tax_source,
                  product_uom: all_products[prod].product_uom,
                  taxmaster_id: all_products[prod].taxmaster_id,
                  price_unit: unit_price,
                  real_price: unit_price,
                  sale_price,
                  name: all_products[prod].name,
                  new: true,
                  stock: all_products[prod].stock,
                  type: 'normal',
                  discount: 0,
                  foc: false,
                  foc_product_id: null,
                  scheme_id: null,
                  scheme_discount: 0,
                  config_json: line_config
                });
                // }else {
                //   price_subtotal = parseFloat(order[prod].qty) * parseFloat(order[prod].price_unit);
                //   price_total = (price_subtotal * (100 + parseFloat(order[prod].tax))) / 100;
                //   price_tax = price_total - price_subtotal;
                //   tax_source = price_total * (parseFloat(order.tax_source)/100);
                //   line_data.push({
                //     'order_id': order.order_id,
                //     'product_id': parseInt(prod.split('_')[1], 10),
                //     'prod_name': all_products[prod].name,
                //     'qty': parseInt(order[prod].qty, 10),
                //     'price_total': price_total,
                //     'price_subtotal': price_subtotal,
                //     'price_tax': price_tax,
                //     'tcs': tax_source,
                //     'new': false,
                //     'stock': all_products[prod].stock
                //   })
                // }
                amount_total += price_total;
                amount_untaxed += price_subtotal;
                amount_tax += price_tax;
                total_tcs += tax_source;
              }
            }
          });

          const order_config: any = {};
          order_config.pwa_ver = environment.appVersion;
          order_config.order = {};
          order_config.order.total = parseFloat(String(amount_total)).toFixed(3);
          order_config.order.tax = parseFloat(String(amount_tax)).toFixed(3);
          order_config.order.sub_total = parseFloat(String(amount_untaxed)).toFixed(3);
          /*  order_config.order.dis = 0;
            order_config.order.sch_dis = 0;
            order_config.order.sch_id = null;*/

          orders.push({
            order_id: order.order_id,
            date_order: create_date,
            order_type: 'sale',
            id: order.id,
            amount_total,
            amount_untaxed,
            amount_tax,
            tcs: total_tcs,
            tax_source: order.tax_source,
            order_name: 'O_' + order.distributor_id + '_' + Date.now(),
            line_data,
            partner_id: order.partner_id,
            distributor_id: order.distributor_id,
            outlet: order['Outlet'],
            code: order.code,
            user_id,
            scheme_type: 'S',
            config_json: order_config,
            partner_invoice_id: order['Bill To'],
            partner_shipping_id: order['Ship To']
          });
        }
      });

      if (!abort_save) {
        const order_data = {
          access_token: this.user_data.access_token,
          url: this.user_data.url,
          orders,
          feature_discount_on_basic_rate: this.feature_discount_on_basic_rate
        };
        this.invoice_orders = orders;
        this.confirm_enable = confirm_enable;
        const invoice_orders = orders;
        const domSanitizer = this.domSanitizer;
        this.res_data = this.apiService.generateInvoice(order_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
            const scheme_orders = res['results'].orders;
            this.free_orders = scheme_orders;
            const order_data1 = {
              access_token: this.user_data.access_token,
              url: this.user_data.url,
              order_id: order_ids
            };

            this.res_data = this.apiService.getOrderDetail(order_data1);
            this.res_data.subscribe(res1 => {
                console.log(res1);
                if (res1.hasOwnProperty('results') && (res1['results'].status === 200) && (res1['results'].data.length > 0)) {
                  res1['results'].data.forEach(data => {
                    printDetails = data;
                    printDetails['label_config'] = {};
                    const labels = data.labels;
                    if (labels) {
                      labels.forEach(lab => {
                        printDetails['label_config'][lab.label] = lab.display;
                      });
                    } else {
                      printDetails['label_config']['PrimaryCurrency'] = '';
                      printDetails['label_config']['SecondaryCurrency'] = '';
                      printDetails['label_config']['SupplierLabel'] = 'Supplier';
                      printDetails['label_config']['TaxLabel'] = 'GST';
                    }
                    // printDetails['amount_pay'] = printDetails['amount_pay'].toFixed(this.apiService.decimalPolicy);
                    amount_in_words = inWords(Math.abs(parseInt(printDetails['amount_pay'], 10)), inThis);
                    invoice_date = datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
                    printDetails['invoice_date'] = invoice_date;
                    printDetails['amount_in_words'] = amount_in_words;
                    printDetails['order_date'] = datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                    if (printDetails['date_delivered'] !== null) {
                      printDetails['date_delivered'] = datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                    }
                    if (!printDetails['invoice_no']) {
                      invoice_orders.forEach(order => {
                        if (!order.id) {
                          if (printDetails['id'] === order.order_id) {
                            const act_order = [];
                            order.line_data.forEach(each_line => {
                              printDetails['sale_items'].forEach(line => {
                                if (each_line.product_id === line.product_id) {
                                  line.qty = each_line.qty;
                                  line.price_tax = each_line.price_tax.toFixed(inThis.apiService.decimalPolicy);
                                  line.amount_subtotal = each_line.price_subtotal.toFixed(inThis.apiService.decimalPolicy);
                                  line.tcs = each_line.tcs;
                                  line.unit_price = each_line.price_unit.toFixed(inThis.apiService.decimalPolicy);
                                  act_order.push(line);
                                }
                              });
                            });
                            printDetails['sale_items'] = act_order;
                          }
                        }
                      });
                      scheme_orders.forEach(order => {
                        if (order.order_id === printDetails['id']) {
                          const val_lines = [];
                          printDetails['sale_items'].forEach(line => {
                            line.scheme_used = false;
                            val_lines.push(line);
                            order.lines.forEach(schemes => {
                              schemes.lines.forEach(scheme_line => {
                                if (line.product_id === scheme_line.product_id) {
                                  line.scheme_code = scheme_line.scheme_code;
                                  if (parseFloat(scheme_line.scheme_discount) > 0) {
                                    val_lines.push({
                                      name: line.product_name,
                                      scheme_code: scheme_line.scheme_code,
                                      scheme_discount: scheme_line.scheme_discount.toFixed(inThis.apiService.decimalPolicy),
                                      scheme_used: true
                                    });
                                  }
                                }
                              });
                              schemes.free.forEach(free_line => {
                                if (line.product_id === free_line.foc_product_id) {
                                  free_line.scheme_code = line.scheme_code;
                                  free_line.scheme_used = true;
                                  if (this.feature_promo_full_price && free_line.lst_price) {
                                    free_line.price_unit = free_line.lst_price;
                                  } else {
                                  }
                                  free_line.price_total = (parseFloat(free_line.qty) * parseFloat(free_line.price_unit)).toFixed(3);
                                  free_line.price_subtotal = (free_line.price_total / (1 + (parseFloat(all_products['p_' + free_line.product_id].tax) / 100))).toFixed(3);
                                  free_line.price_tax = (free_line.price_total - free_line.price_subtotal).toFixed(3);
                                  val_lines.push(free_line);
                                }
                              });
                            });
                          });
                          printDetails['sale_items'] = val_lines;
                        }
                      });
                    }
                    all_orders.push(printDetails);
                    console.log(all_orders);
                  });
                  this.all_orders = all_orders;
                } else {
                  this.all_orders = [];
                }
                this.tcs = tcs;
                this.generate_loading = false;
                this.triggerFalseClick2();
              },
              error => {
                console.log(error);
                this.generate_loading = false;
              }
            );
          }
        }, error => {
          console.log(error);
          this.generate_loading = false;
        });
      } else {
        window.alert('Entered quantity is more than stock for the product:-' + cancel_prod);
        this.confirm_loading = false;
        this.generate_loading = false;
      }
    } else {
      window.alert('Select atleast one order');
      this.confirm_loading = false;
      this.generate_loading = false;
    }
  }

  triggerFalseClick2() {
    const el: HTMLElement = this.genInvoice.nativeElement;
    el.click();
  }

  print_invoice() {
    const rows = this.gridApi.getSelectedRows();
    const order_ids = [];
    let printDetails = {};
    let amount_in_words;
    let invoice_date;
    const datePipe = this.datePipe;
    const all_orders = [];
    const inWords = this.inWords;
    const inThis = this;
    const tcs = this.tcs;
    const domSanitizer = this.domSanitizer;
    let total_qty = 0;
    let total_discount = 0;
    if (rows.length > 0) {
      rows.forEach(order => {
        if (order.id !== null) {
          order_ids.push(order.id);
        } else {
          order_ids.push(order.order_id);
        }
      });
      const order_data = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        order_id: order_ids
      };
      this.res_data = this.apiService.getOrderDetail(order_data);
      const in_this = this;
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
            this.selectedTemplate = null;
            this.templ_config = null;
            this.template = false;
            if (localStorage.getItem('custom_templates')) {
              const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
              const updated = custom_templates.filter(elem => {
                return elem.type === 'Secondary Print PDF';
              });
              if (updated.length > 0) {
                this.templ_config = [{name: 'Default'}].concat(updated);
                this.template = true;
              }
            }
            res['results'].data.forEach(data => {
              total_qty = 0;
              printDetails = data;
              printDetails['tax_display'] = false;
              printDetails['new_tax'] = 0;
              const tax_breaks = [];
              const taxnotes = [];
              printDetails['label_config'] = {};
              const labels = data.labels;
              if (labels) {
                labels.forEach(lab => {
                  printDetails['label_config'][lab.label] = lab.display;
                });
              } else {
                printDetails['label_config']['PrimaryCurrency'] = '';
                printDetails['label_config']['SecondaryCurrency'] = '';
                printDetails['label_config']['SupplierLabel'] = 'Supplier';
                printDetails['label_config']['TaxLabel'] = 'GST';
              }
              printDetails['tax_breakup'].forEach(each_tax => {
                if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
                  each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
                  printDetails['tax_display'] = true;
                  printDetails['new_tax'] += parseFloat(each_tax.tax);
                  tax_breaks.push(each_tax);
                  taxnotes.push(each_tax.name);
                } else {
                  tax_breaks.push(each_tax);
                  taxnotes.push(each_tax.name);
                }
              });
              printDetails['tax_breakup'] = tax_breaks;
              if (printDetails['logo']) {
                printDetails['logo'] = in_this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + printDetails['logo']);
              }
              if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
                printDetails['customer_skip'] = true;
              } else {
                printDetails['customer_skip'] = false;
              }
              printDetails['print_text'] = domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
              // tcs = (parseFloat(printDetails['amount_pay']) - parseFloat(printDetails['amount_total'])).toFixed(5)
              const amount = printDetails['amount_pay'].split('.');
              if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
                amount_in_words = inWords(Math.abs(parseInt(amount[0], 10)), inThis);
                amount_in_words = printDetails['label_config']['PrimaryCurrency'] + ' ' + amount_in_words + ' And ' + printDetails['label_config']['SecondaryCurrency'] + ' ' + inWords(Math.abs(parseInt(amount[1], 10)), inThis) + ' Only';
              } else {
                amount_in_words = printDetails['label_config']['PrimaryCurrency'] + ' ' + inWords(Math.abs(parseInt(amount[0], 10)), inThis) + ' Only';
              }
              invoice_date = datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');

              printDetails['sale_items'].forEach(sale_item => {
                sale_item['label'] = '[' + sale_item.type + ']';
                const breaks = [];
                sale_item['new_tax'] = 0;
                sale_item['tax_notes'].forEach(each_tax => {
                  if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
                    each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
                    sale_item['new_tax'] += parseFloat(each_tax.tax);
                    breaks.push(each_tax);
                  } else {
                    breaks.push(each_tax);
                  }
                });
                taxnotes.forEach(order_tax => {
                  let flag = false;
                  breaks.forEach(line_tax => {
                    if (line_tax.name === order_tax) {
                      flag = true;
                    }
                  });
                  if (!flag) {
                    breaks.push({name: order_tax, rate: 0, tax: 0});
                  }
                });
                sale_item['tax_notes'] = breaks;

                total_discount += parseFloat(sale_item.vps_dis);
                if (printDetails['d_status'] === 'delivered') {
                  sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
                  total_qty += parseInt(sale_item.delivered_qty, 0);
                  sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
                    (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
                } else {
                  sale_item['abs_qty'] = Math.abs(sale_item['qty']);
                  total_qty += parseInt(sale_item.qty, 0);
                  sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
                    (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
                }
                sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
              });
              printDetails['total_qty'] = total_qty;
              printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
              printDetails['invoice_date'] = invoice_date;
              printDetails['amount_in_words'] = amount_in_words;
              printDetails['order_date'] = datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              if (printDetails['date_delivered'] !== null) {
                printDetails['date_delivered'] = datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              }
              all_orders.push(printDetails);
              console.log(all_orders);
            });
            this.all_orders = all_orders;
          } else {
            this.all_orders = [];
          }
          this.tcs = tcs;

          this.triggerFalseClick();
        },
        error => {
          console.log(error);
        }
      );
    } else {
      window.alert('Select Atleast One order');
    }
  }

  generate_irn(order_id) {
    this.irn_loading = true;
    const order_data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      order_id
    };
    this.res_data = this.apiService.generateIrn(order_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.result.status === 'error') {
        this.irn_loading = false;
        if (res.result.comments) {
          if (JSON.parse(res.result.comments).error) {
            if (JSON.parse(res.result.comments).error.details) {
              window.alert(JSON.parse(res.result.comments).error.details);
            } else {
              window.alert(JSON.stringify(JSON.parse(res.result.comments).error));
            }
          } else if (JSON.parse(res.result.comments).details) {
            window.alert(JSON.parse(res.result.comments).details);
          } else {
            window.alert(res.result.comments);
          }
        } else {
          window.alert('IRN Generation Failed');
        }
      } else {
        this.irn_loading = false;
        window.alert('IRN Generation Success');
      }
    }, error => {
      console.log(error);
      this.irn_loading = false;
      window.alert('IRN Generation Failed');
    });
  }

  checkAndAssignTemplate() {
    for (const key in this.all_orders) {
      if (this.all_orders[key].hasOwnProperty('gstn')) {
        if (this.all_orders[key].gstn) {
          if (this.gst_template) {
            this.all_orders[key]['selectedTemplate'] = this.gst_template;
          } else {
            this.all_orders[key]['selectedTemplate'] = {name: 'Default'};
          }
        } else {
          if (this.non_gst_template) {
            this.all_orders[key]['selectedTemplate'] = this.non_gst_template;
          } else {
            this.all_orders[key]['selectedTemplate'] = {name: 'Default'};
          }
        }
      }  else {
        this.all_orders[key]['selectedTemplate'] = {name: 'Default'};
      }
    }
  }

  triggerFalseClick() {
    if (this.template) {
      if (this.gst_template || this.non_gst_template) {
        this.checkAndAssignTemplate();
        $('#DefaultTemplateModal').modal('show');
      } else {
        const el: HTMLElement = this.customPrintClick.nativeElement;
        el.click();
      }
    } else {
      const el2: HTMLElement = this.printInvoice.nativeElement;
      el2.click();
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const keys = this.keydata;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991 && keys.length < 13) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991 && this.keydata.length < 13) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngAfterViewInit() {
    this.min = new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    this.max = new Date(today.setDate(today.getDate() + 1));
    if (new Date().getMonth() !== this.max.getMonth()) {
      this.max = this.min;
    }
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.apiDcMtOutList();
  }

  uploadedFile(event) {
    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      this.uploadPodata = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.poData = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadexcel() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.uploadLoader = true;
    const inthis = this;
    const succcessCount = 0;
    const failedCount = 0;
    const paramObject: any = {};
    const obj = {};
    paramObject.access_token = this.user_data.access_token;
    const total_len = this.poData.length;
    if (this.poData.length > 0) {
      for (const item of this.poData) {
        if (inthis.gridApi !== null) {
          const prod = inthis.productlist.find(mode => mode.default_code === item.ProductCode.toString());
          const rowIndex = inthis.rowData.findIndex(x => x.code === item.OutCode.toString() && x.po === item.PO.toString());
          const row = inthis.rowData.find(x => x.code === item.OutCode.toString() && x.po === item.PO.toString());
          if (row !== null && row !== undefined && row['invoice'] === null) {
            inthis.rowData[rowIndex]['p_' + prod.id].qty = item.Qty;
          }
        }
      }
      this.gridApi.setRowData(this.rowData);
      this.uploadCaption = ['Uploading completed', 0];
      this.uploading = false;
      this.uploadLoader = false;
      this.poData = [];
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else {
      // this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
    }
  }

}
