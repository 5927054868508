<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<HTML>
<HEAD>
  <META http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <TITLE>local</TITLE>
  <META name="generator" content="BCL easyConverter SDK 5.0.210">
  <STYLE type="text/css">

    body {margin-top: 0px;margin-left: 0px;}

    #page_1 {position:relative; overflow: hidden;margin: -16% 0px 12px -2%;padding: 0px;border: none;width: auto;}
    #page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: auto;overflow: hidden;}
    #page_1 #id1_2 {border:none;margin: 3px 0px 0px 426px;padding: 0px;border:none;width: 698px;overflow: hidden;}

    #page_1 #p1dimg1 {position:absolute;top:66px;left:76px;z-index:-1;width:5px;height:4px;font-size: 1px;line-height:nHeight;}
    #page_1 #p1dimg1 #p1img1 {width:5px;height:4px;}

    .m1{width: 60vw;}
    .m2{width: fit-content;}
    .m3{height: 80vh;
      overflow-y: scroll;border:solid 1px;}


    .ft0{font: 11px 'Arial';line-height: 14px;}
    .ft1{font: bold 25px 'Arial';line-height: 30px;}
    .ft2{font: bold 13px 'Arial';line-height: 15px;}
    .ft3{font: 1px 'Arial';line-height: 1px;}
    .ft4{font: 13px 'Arial';line-height: 15px;}
    .ft5{font: 1px 'Arial';line-height: 7px;}
    .ft6{font: 1px 'Arial';line-height: 5px;}
    .ft7{font: bold 13px 'Arial';line-height: 14px;}
    .ft8{font: 1px 'Arial';line-height: 6px;}
    .ft9{font: 1px 'Arial';line-height: 10px;}
    .ft10{font: 1px 'Arial';line-height: 9px;}
    .ft11{font: 1px 'Arial';line-height: 8px;}
    .ft12{font: 8px 'Arial';line-height: 10px;}
    .ft13{font: 8px 'Calibri';line-height: 10px;}

    .p0{text-align: left;padding-left: 35px;margin-top: 0px;margin-bottom: 0px;}
    .p1{text-align: left;padding-left: 342px;margin-top: 70px;margin-bottom: 0px;}
    .p2{text-align: left;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p3{text-align: right;padding-right: 9px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p4{text-align: right;padding-right: 22px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p5{text-align: left;padding-left: 7px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p6{text-align: left;padding-left: 4px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p7{text-align: left;padding-left: 3px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p8{text-align: left;padding-left: 6px;margin-top: 0px;margin-bottom: 5px;white-space: nowrap;}
    .p9{text-align: left;padding-left: 6px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p10{text-align: left;padding-left: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p11{text-align: left;padding-left: 25px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p12{text-align: right;padding-right: 3px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p13{text-align: left;padding-left: 12px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p14{text-align: right;padding-right: 36px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p15{text-align: right;padding-right: 15px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p16{text-align: right;padding-right: 13px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p17{text-align: right;padding-right: 5px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p18{text-align: right;padding-right: 35px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p19{text-align: right;padding-right: 16px;margin-top: 0px;margin-bottom: 0px;white-space: nowrap;}
    .p20{text-align: left;padding-left: 76px;margin-top: 35px;margin-bottom: 0px;}
    .p21{text-align: left;padding-left: 289px;margin-top: 372px;margin-bottom: 0px;}
    .p22{text-align: right;padding-right: 35px;margin-top: 444px;margin-bottom: 0px;}
    .p23{text-align: left;margin-top: 0px;margin-bottom: 0px;}

    .td0{padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td1{padding: 0px;margin: 0px;width: 135px;vertical-align: bottom;}
    .td2{padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td3{padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td4{padding: 0px;margin: 0px;width: 101px;vertical-align: bottom;}
    .td5{padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
    .td6{padding: 0px;margin: 0px;width: 15px;vertical-align: bottom;}
    .td7{padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
    .td8{padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td9{padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td10{padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td11{padding: 0px;margin: 0px;width: 183px;vertical-align: bottom;}
    .td12{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td13{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;}
    .td14{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 19px;vertical-align: bottom;}
    .td15{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 101px;vertical-align: bottom;}
    .td16{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 6px;vertical-align: bottom;}
    .td17{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 76px;vertical-align: bottom;}
    .td18{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td19{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td20{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td21{padding: 0px;margin: 0px;width: 123px;vertical-align: bottom;}
    .td22{padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;}
    .td23{border-right: #000000 2px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;}
    .td24{border-right: #000000 2px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
    .td25{padding: 0px;margin: 0px;width: 76px;vertical-align: top;}
    .td26{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 123px;vertical-align: bottom;}
    .td27{border-right: #000000 2px solid;border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;}
    .td28{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 83px;vertical-align: bottom;}
    .td29{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td30{border-right: #000000 2px solid;border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: bottom;}
    .td31{border-left: white 1px solid;padding: 0px;margin: 0px;width: 123px;vertical-align: top;}
    .td32{border-right: white 1px solid;padding: 0px;margin: 0px;width: 18px;vertical-align: top;}
    .td33{padding: 0px;margin: 0px;width: 83px;vertical-align: top;}
    .td34{border-right: white 1px solid;padding: 0px;margin: 0px;width: 5px;vertical-align: top;}
    .td35{border-left: white 1px solid;padding: 0px;margin: 0px;width: 211px;vertical-align: top;}
    .td36{padding: 0px;margin: 0px;width: 225px;vertical-align: top;}
    .td37{padding: 0px;margin: 0px;width: 143px;vertical-align: top;}
    .td38{padding: 0px;margin: 0px;width: 28px;vertical-align: top;}
    .td62{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 1%;}
    .td39{padding: 0px;margin: 0px;padding-left: 25px;width: 19px;vertical-align: bottom;}
    .td40{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 3%;}
    .td41{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 28px;vertical-align: bottom;}
    .td42{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td43{padding: 0px;margin: 0px;width: 47px;vertical-align: bottom;padding-right: 15px;}
    .td65{padding: 0px;margin: 0px;width: 37px;vertical-align: bottom;padding-left: 13px;}
    .td63{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 4%;}
    .td44{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}
    .td45{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 88px;vertical-align: bottom;}
    .td46{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 19px;vertical-align: bottom;}
    .td47{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 28px;vertical-align: bottom;}
    .td48{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 55px;vertical-align: bottom;}
    .td49{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 41px;vertical-align: bottom;}
    .td50{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 2%;}
    .td51{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 5%;}
    .td52{padding: 0px;margin: 0px;width: 18px;vertical-align: bottom;padding-left: 4%;}
    .td53{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 67px;vertical-align: bottom;}
    .td54{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 40px;vertical-align: bottom;}
    .td55{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 46px;vertical-align: bottom;}
    .td56{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td57{padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td58{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 128px;vertical-align: bottom;}
    .td59{border-bottom: #000000 2px solid;padding: 0px;margin: 0px;width: 61px;vertical-align: bottom;}
    .td60{padding: 0px;margin: 0px;width: 314px;vertical-align: bottom;}
    .td61{border-bottom: grey 2px solid;padding: 0px;margin: 0px;width: 124px;vertical-align: bottom;}

    .tr0{height: 19px;}
    .tr1{height: 15px;}
    .tr2{height: 16px;}
    .tr3{}
    .tr4{height: 7px;}
    .tr5{height: 5px;}
    .tr6{height: 22px;}
    .tr7{height: 6px;}
    .tr8{height: 18px;}
    .tr9{height: 10px;}
    .tr10{height: 9px;}
    .tr11{height: 8px;}
    .tr12{height: 36px;}
    .tr13{height: 25px;}

    .t0{width: fit-content;margin-left: 2%;margin-top: 7%;font: 12px 'Arial';}
    .t00{width: 691px;margin-left: 2%;margin-top: 4%;font: 12px 'Arial';}

  </STYLE>
</HEAD>
<BODY>
<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dcvanlist">
            <div class="search-list">
              <div class="col-md-6 pad0">
                  <div class="search">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                    placeholder="Search">
                  </div>
                <div style="margin-top: 13%;margin-left: 80%;font-size: 182%;">{{van_name}}</div>
              </div>
              <div class="col-md-6 pad0">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR">
                        <div class="date-picker">
                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                            <input [min]="min" [max]="max" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                    </li>
<!--                      <li class="margLR">-->
<!--                          <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--                      </li>-->
                      <li class="margLR">
                          <app-excelicon [gridOption]="gridOptions" [title]="'MT List'"></app-excelicon>
                      </li>
                    <li style="margin-top: 0%;" class="margLR">
                      <img data-toggle="modal" data-target="#popupModal" src="../../../assets/upload.svg"/>
                    </li>
                    </ul>
                  </div>
              </div>
              <div class="col-md-12 pad0">
                <div class="mt-default">
                  <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height: 70vh;" class="ag-theme-balham"  [columnDefs]="columnDefs"
                                   [gridOptions]="gridOptions"
                                   (gridReady)="onGridReady($event)"
                                   (rowSelected)="onSelectionChanged($event)"
                                   [modules]="modules" [frameworkComponents]="frameworkComponents"
                                   [context]="context" [tooltipShowDelay]="tooltipShowDelay"
                                   [tooltipHideDelay]="tooltipHideDelay">
                  </ag-grid-angular>
                  <div *ngIf="preloader">
                    <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
                      <!--<img src="../../assets/images/preloader.gif"/>-->
                    <!--</div>-->
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<div style="position:fixed;bottom:0px;right: 2%;" class="generate-invoice">
  <div id="overlay"></div>
  <span class="sendpopup">
      <ul class="list-unstyled">
          <li *ngIf="showAutoPopulate">
            <a (click)="update_with_stock()" class="btn white-btn"> AUTO POPULATE </a>
          </li>
          <li>
            <a (click)="print_invoice()" class="btn white-btn"> PRINT </a>
          </li>
          <li *ngIf="!generate_loading">
            <a (click)="generate_invoice()" class="btn confirm-blue-btn">GENERATE INVOICE </a>
          </li>
          <li *ngIf="generate_loading">
               <img style="position:relative;right: 6vw;width:40%;" src="assets/images/loader_icon.gif"/>
          </li>
        </ul>
  </span>
</div>

<button #genInvoice id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#BillingModal"></button>
<button #printInvoice id="openModalButton2" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>

<div #DefaultTemplateModal class="pol-modal pol-send invoice modal" id="DefaultTemplateModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section--default">
          <div  class="invoice"  *ngFor="let order_detail of all_orders">
            <app-print *ngIf="!order_detail['selectedTemplate'] || (order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] == 'Default')"
                       [order_detail]="order_detail"></app-print>
            <app-custom-print *ngIf="order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] != 'Default'"
                              [order_detail]="order_detail"
                              [custom_template]="order_detail['selectedTemplate']"
            ></app-custom-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section--default"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
        </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  *ngFor="let order_detail of all_orders">
            <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
                       [order_detail]="order_detail"></app-print>
            <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                              [order_detail]="order_detail"
                              [custom_template]="selectedTemplate"
            ></app-custom-print>
          </div>
          </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
      </div>
    </div>
  </div>
</div>

<div #BillingModal class="pol-modal pol-send invoice modal" id="BillingModal" role="dialog">
  <div style="margin-bottom: 0px !important;" class="modal-dialog" *ngFor="let order_detail of all_orders">
    <!-- Modal content-->
    <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      <div class="billing-details">
        <div class="billing-name">
          <ul class="list-unstyled">
            <li>
              <h1>{{order_detail['partner']}}</h1>
              <h1>Code: {{order_detail['code']}}</h1>
            </li>
            <li>
              <label>ROUTE NAME : {{order_detail['beat_name']}}</label>
            </li>
            <a *ngIf="order_detail['has_einvoice'] && (order_detail['status'] == 'draft' || order_detail['status'] == 'error' || order_detail['status'] == 'processing')"
               style="margin-top: 4%;" class="btn blue-btn" (click)="generate_irn(order_detail['id'])">Generate IRN</a>
            <img *ngIf="irn_loading" style="position:relative;right: 5vw;width:5%;" src="assets/images/loader_icon.gif"/>
          </ul>
        </div>
        <div class="billing-order">
          <ul class="list-unstyled">
            <li>
              <label>INVOICE NO : {{order_detail['invoice_no']}}</label>
            </li>
            <li *ngIf="confirm_enable">
              <label>REF NO : </label>
            </li>
            <li *ngIf="!confirm_enable">
              <label>REF NO : {{order_detail['name']}}</label>
            </li>
            <li *ngIf="confirm_enable">
              <label>ID : </label>
            </li>
            <li *ngIf="!confirm_enable">
              <label>ID : {{order_detail['id']}}</label>
            </li>
            <li>
              <label>GST/TAX ID : {{order_detail['partner_gstn']}}</label>
            </li>
            <li>
              <label>DATE : {{order_detail['invoice_date']}}</label>
            </li>
            <li>
              <label>DELIVERY DATE :  {{order_detail['date_delivered']}}</label>
            </li>
          </ul>
        </div>
        <!--<div style="margin-left: 90%;margin-top: -4.5%;">-->
        <!--<button-->
        <!--[useExistingCss]="true"-->
        <!--printSectionId="print-section"-->
        <!--ngxPrint>Print</button>-->
        <!--</div>-->

        <!--<div class="popup-print">-->
        <!---->
        <!--&lt;!&ndash;<input (click)="printDetails()" type="button" value="Print" id="btnPrint" />&ndash;&gt;-->
        <!--</div>-->

      </div>
      <div class="modal-body">

        <div class="pol-modal-heading">
          <h1>DETAILS</h1>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>PRODUCT</label>
            </li>
            <li>
              <label>QUANTITY</label>
            </li>
            <li>
              <label>@PRICE</label>
            </li>
            <li>
              <label>TAX</label>
            </li>
            <li>
              <label>TAX AMOUNT</label>
            </li>
           <!-- <li>
              <label>DISCOUNT</label>
            </li>-->
            <li>
              <label>VALUE</label>
            </li>
            <li>
              <label>TCS/TDS</label>
            </li>
          </ul>
          <div style="margin-bottom: 0px;" class="pol-content-text" *ngFor="let product of order_detail['sale_items']">
            <ul class="list-unstyled" *ngIf="!product['scheme_used'] && product['type'] != 'promo'">
              <li>
                <h2>{{product['product_name']}}</h2>
              </li>
              <li>
<!--                <label *ngIf="product['state'] == 'done'">{{product['delivered_qty']}}</label>-->
                <label>{{product['qty']}}</label>
              </li>
              <li>
                <label>{{product['unit_price']}}</label>
              </li>
              <li>
                <label>{{product['tax']}}</label>
              </li>
              <li>
                <label>{{product['price_tax']}}</label>
              </li>
           <!--   <li>
                <label>{{product['scheme_discount']}}</label>
              </li>-->
              <li>
                <label> {{product['amount_subtotal']}}</label>
              </li>
              <li>
                <label>{{product['tcs'].toFixed(4)}}</label>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="product['type'] == 'promo' && order_detail['invoice_no'] != null">
              <li>
                <h2 style="color: lightgreen;">{{product['product_name']}}</h2>
              </li>
              <li>
<!--                <label style="color: lightgreen;" *ngIf="product['state'] == 'done'">{{product['delivered_qty']}}</label>-->
                <label style="color: lightgreen;">{{product['qty']}}</label>
              </li>
              <li>
                <label>{{product['unit_price']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['tax']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['price_tax']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;"> {{product['amount_subtotal']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">0</label>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="product['scheme_used']">
              <li>
                <h2 style="color: lightgreen;">{{product['name']}}</h2>
              </li>
              <li>
                <h2 style="color: lightgreen;">{{product['qty']}}</h2>
              </li>
              <li>
                <h2 style="color: lightgreen;"></h2>
              </li>
              <li>
                <h2 style="color: lightgreen;"></h2>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['scheme_code']}}</label>
              </li>
              <li *ngIf="product['scheme_discount'] != 0">
                <label style="color: lightgreen;">-{{product['scheme_discount']}}</label>
              </li>
              <li *ngIf="product['price_subtotal'] != 0">
                <label style="color: lightgreen;">{{product['price_subtotal']}}</label>
              </li>
            </ul>
          </div>
          <table *ngIf="order_detail['invoice_no'] != null">
            <TR style="font-size: 115%;font-weight: 500;">
              <TD >Tax</TD>
              <TD style="padding-left: 20%;">Amount</TD>
            </TR>
            <TR *ngFor="let tax of order_detail['tax_breakup']">
              <TD style="padding-top: 5%;font-size: 120%;">{{tax.name}}</TD>
              <TD style="padding-left: 20%;padding-top: 5%;font-size: 120%;">{{tax.tax}}</TD>
            </TR>
          </table>
          <table *ngIf="order_detail['invoice_no'] != null" style="margin-left: 70%;margin-top: -10%;">
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total Without Taxes</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_untaxed']}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Taxes</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_tax']}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_total']}}</TD>
            </TR>
            <TR>
<!--              <TD *ngIf="order_detail['tcs'] && order_detail['tcs'][0] == '-'" colspan=2 class="tr0 td57"><P class="p6 ft2">TDS</P></TD>-->
              <TD style="font-size: 115%;font-weight: 500;">TCS</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['tcs'].toFixed(4)}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total Payable</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_pay']}}</TD>
            </TR>
          </table>
        </div>
      </div>
    </div>
  </div>
  <a *ngIf="confirm_enable && !confirm_loading" style="margin-left: 45%;" class="btn blue-btn" (click)="saveMT()" >CONFIRM</a>
  <div *ngIf="confirm_loading" style="margin-left: 45%;">
    <img style="width:5%;margin-top: 10%;" src="assets/images/loader_icon.gif"/>
  </div>
</div>

<div class="approve-modal modal" id="CustomPrintModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Please select Print Template</h1>
        </div>
        <div class="approve-modal-content" style="witdh:50%;height:3vh">
          <select [(ngModel)]="selectedTemplate">
            <option>Select</option>
            <option *ngFor="let templ of templ_config"
                    [ngValue]="templ">{{templ.name}}</option>
          </select>
        </div>
        <button
          [hidden]="!selectedTemplate"
          [useExistingCss]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>

<button #customPrintClick id="openPrint" [hidden]="true" data-toggle="modal" data-target="#CustomPrintModal">Open Modal</button>

<button #printinvoice id="openImage" [hidden]="true" [useExistingCss]="true"
        printSectionId="print-section-sales"
        ngxPrint >Open Modal</button>
<div class="approve-modal modal" id="popupModal" role="dialog">
  <div id="overlay"></div>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label style="padding-bottom: 5%;">UPLOAD PRE-ORDER</label>
          <!--          <h1> Kindly update reason</h1>-->
          <input #fileUp type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
        </div>
        <p style="font-size: medium" [style.color]="uploadCaption[1] == 0 ? 'green' : 'red'">{{uploadCaption[0]}}</p>
        <div class="approve-modal-content">
          <a *ngIf="!uploading" class="btn blue-btn" (click)="uploadexcel()">Upload</a>
          <!--          <a href="https://docs.google.com/spreadsheets/d/1l0P-pxS_vC99Ceg3rQI066MCtY_4SJqldApoKZM8Ia0/edit?usp=sharing" target="_blank"-->
          <!--             style="margin-left: 30%;" class="btn blue-btn">Download Sample</a>-->
          <div *ngIf="uploadLoader">
            <img style="width:5%;margin-top: 10%;" src="../../../assets/images/loader_icon.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</BODY>
