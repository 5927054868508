<!--<angular2-multiselect [data]="itemList"-->
<!--                      [(ngModel)]="selectedItems"-->
<!--                      [settings]="settings"-->
<!--                      (onSelect)="onItemSelect($event)">-->
<!--  &lt;!&ndash;(onDeSelect)="OnItemDeSelect($event)"&ndash;&gt;-->
<!--&lt;!&ndash;  (onSelectAll)="onSelectAll($event)"&ndash;&gt;-->
<!--  &lt;!&ndash;(onDeSelectAll)="onDeSelectAll($event)"&ndash;&gt;-->
<!--</angular2-multiselect>-->
<ng-select [items]="itemList"
           bindLabel="itemName"
           multiple="true"
           maxSelectedItems="1"
           [(ngModel)]="selectedItems"
           (ngModelChange)="prodChange($event)">
</ng-select>

