import {Component, OnInit, Output, EventEmitter, Input, Directive, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-supp-proddropdown',
  templateUrl: './supp-prod-dropdown.component.html',
  styleUrls: ['./supp-prod-dropdown.component.scss']
})
export class SuppProdDropdownComponent implements OnInit {
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() itemList: object;
  @Input() selectedItems: object;

  // itemList = [];
  // selectedItems = [];
  settings = {};
  constructor() { }

  ngOnInit() {

    // this.itemList = [
    //   { "id": 1, "itemName": "DC Sales" },
    //   { "id": 2, "itemName": "User Collection Report" },
    // ];

    // this.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    // this.settings = { singleSelection: false, text: this.selectedItems[0].itemName, enableSearchFilter: true, showCheckbox: true };
  }

  dcChange(event) {

    localStorage.setItem('selected_dc', JSON.stringify(event));
    this.statusChange.emit(event);
  }

  onItemSelect(event) {
    this.statusChange.emit(event);
    localStorage.setItem('selected_dc', JSON.stringify(event));
  }

}


