import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-partnercontact-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerContactMasterReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Partner Type',
    key: 'partner_type',
    value: 'supplier',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Contact Master Report');

    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.loadInBatches(10000, 'rp_id');
    this.showProgressCustomText = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "supplier", itemName: "Supplier"},
      {id: "retailer", itemName: "Retailer"},
      {id: "project", itemName: "Project"},
    ];

    this.API_URL = '/api/pwa_v2_reports/getPartnerContactMaster';
    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;

    this.columnDefs = [
      {
        headerName: "Customer",
        children: [
          {headerName: 'ID', field: 'rp_id', width: 80},
          {headerName: 'CODE', field: 'rp_code', width: 80},
          {headerName: 'NAME', field: 'rp_name', enableRowGroup: true, width: 100},
          {headerName: 'GST', field: 'gst_id', enableRowGroup: true, width: 100},
          {headerName: 'PAN', field: 'pan', enableRowGroup: true, width: 100},
          {headerName: 'TYPE', field: 'rp_type', enableRowGroup: true, width: 80},
          {headerName: 'ADDRESS', field: 'rp_address', enableRowGroup: true, width: 80},
          {headerName: 'CITY', field: 'rp_city', enableRowGroup: true, width: 60},
          {headerName: 'ZIP', field: 'rp_zip', enableRowGroup: true, width: 60},
          {headerName: 'STATE', field: 'rp_state', enableRowGroup: true, width: 80},
          {headerName: 'STATUS', field: 'rp_status', enableRowGroup: true, width: 80},
          {headerName: 'TCODE', field: 'territory_code', enableRowGroup: true, width: 60},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 60},
          {headerName: 'PARTNER TYPE', field: 'partner_type', enableRowGroup: true, width: 60}
        ]
      },
      {
        headerName: "CONTACT",
        children: [
          {headerName: 'TYPE', field: 'c_type', width: 80},
          {headerName: 'ID', field: 'c_id', width: 80},
          {headerName: 'CODE', field: 'c_code', width: 80},
          {headerName: 'NAME', field: 'c_name', enableRowGroup: true, width: 100},
          {headerName: 'MOBILE', field: 'c_mobile', enableRowGroup: true, width: 100},
          {headerName: 'EMAIL', field: 'c_email', enableRowGroup: true, width: 100},
          {headerName: 'GST', field: 'c_gst', enableRowGroup: true, width: 100},
          {headerName: 'PAN', field: 'c_pan', enableRowGroup: true, width: 100},
          {headerName: 'ADDRESS', field: 'c_address', enableRowGroup: true, width: 80},
          {headerName: 'CITY', field: 'c_city', enableRowGroup: true, width: 60},
          {headerName: 'ZIP', field: 'c_zip', enableRowGroup: true, width: 60},
          {headerName: 'STATE', field: 'c_state', enableRowGroup: true, width: 80},
          {headerName: 'CREATED ON', field: 'c_create_date', enableRowGroup: true, width: 100},
          {headerName: 'CREATED BY', field: 'c_create_name', enableRowGroup: true, width: 60},
          {headerName: 'LAST MODIFIED', field: 'c_write_date', enableRowGroup: true, width: 80},
          {headerName: 'LAST MODIFIED BY', field: 'c_write_name', enableRowGroup: true, width: 80}
        ]
      }
    ];
  }
}
