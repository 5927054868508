<div  class="map" id="map1" style="margin-top: 20px;">
  <agm-map (mapReady)="onMapReady($event)"
    [zoom]="zoom" >
    <ng-container *ngFor="let item of mapData;">
      <agm-marker *ngIf="item.color === 'teel'" [latitude]="item.lat" [longitude]="item.lng" [iconUrl]="teel_marker" >
        <agm-info-window
          [latitude]="item.lat"
          [longitude]="item.lng">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
        </agm-info-window>
      </agm-marker>
      <agm-marker *ngIf="item.color === 'amber'" [latitude]="item.lat" [longitude]="item.lng" [iconUrl]="amber_marker">
        <agm-info-window
          [latitude]="item.lat"
          [longitude]="item.lng">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
        </agm-info-window>
      </agm-marker>
      <agm-marker *ngIf="item.color === 'grey'" [latitude]="item.lat" [longitude]="item.lng" [iconUrl]="grey_marker">
        <agm-info-window
          [latitude]="item.lat"
          [longitude]="item.lng">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
        </agm-info-window>
      </agm-marker>
      <agm-marker *ngIf="item.color === 'green'" [latitude]="item.lat" [longitude]="item.lng" [iconUrl]="green_marker">
        <agm-info-window
          [latitude]="item.lat"
          [longitude]="item.lng">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
        </agm-info-window>
      </agm-marker>
    </ng-container>
  </agm-map>
</div>
