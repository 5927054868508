import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ErrorHandler} from '../error/error-handler';
import {throwError} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {trace} from "firebase/performance";
import firebase from "firebase/compat";
import {AppModule} from "../app.module";
import {SharedService} from "./shared_service";

@Injectable({providedIn: 'root'})
export class ApiService {

  result;
  public redirect_url = '';
  public decimalPolicy = 2;
  public api_url;
  public oktaConfig = {
    clientId: '',
    issuer: '',
    redirectUri: '',
    scopes: ['openid', 'profile', 'email'],
    pkce: false
  };

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe,
              private router: Router,
              public toastr: ToastrService,
              public sharedService: SharedService) {

    // this.api_url = environment.loginURL;
    // this.api_url === 'http://localhost:3010/'

    // this.redirect_url = 'ggluat.salesdiary.in';
    this.redirect_url = '';
    this.decimalPolicy = parseInt(localStorage.getItem('decimal_policy'), 10);

  }

  static handleError(err: HttpErrorResponse | any) {

    const errMsg: any = {
      status: '-1',
      statusText: '',
      resource: ''
    };


    if (err.error instanceof Error) {
      errMsg.statusText = err.error.message;
      console.log('Client error');

    } else {
      errMsg.status = err.status;
      errMsg.statusText = 'A backend error occurred. In all likelihood the server/api service is not running.';
      errMsg.resource = err.url;

    }

    return throwError(ErrorHandler.getErrorObject(errMsg));

  }

  check_token_expiry() {
    const token_time = parseInt(localStorage.getItem('token_time'), 10);
    const nowTime = new Date().getTime();
    let diff = nowTime - token_time;
    diff = diff / (1000 * 60 * 60);
    if (diff > 8) {
      this.toastr.error('Auth Token Expired!!! Please log in again.');
      localStorage.clear();
      this.router.navigate([this.redirect_url + '']);
    }
  }

  findCompanyInstance(data) {
    // this.check_token_expiry();
    console.log('login_url: ' + environment.loginURL);
    console.log(data);
    return this.httpClient
      .post<any>(environment.loginURL + `/api/sd_companies/findcompanyinstancewithlogo`, data)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  authenticateUser(data) {
    if (environment.instanceURL === 'from_login') {
      this.api_url = localStorage.getItem('private_url');
    } else {
      this.api_url = environment.instanceURL;
    }
    return this.httpClient.post(this.api_url + '/api/res_users/instance_pwa_login', data);
  }

  reAuthenticateUser(data) {
    if (environment.instanceURL === 'from_login') {
      this.api_url = localStorage.getItem('private_url');
    } else {
      this.api_url = environment.instanceURL;
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/res_users/reAuthenticateUserPwa', data, {headers});
  }

  authenticateUserPromise(data) {
   /* if (environment.instanceURL === 'from_login') {
      this.api_url = localStorage.getItem('private_url');
    } else {
      this.api_url = environment.instanceURL;
    }*/
    return this.httpClient.post(data.url + '/api/res_users/instance_pwa_login', data).toPromise();
  }

  sendotp(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    return this.httpClient.post(this.api_url + '/api/res_users/checkuserandsendotp', data);
  }

  delete_order(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/delete_duplicate_loadout', data, {headers});
  }

  updateInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_invoice', data, {headers});
  }

  dclist(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dclist_v1', data, {headers});
  }

  dclist_2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dclist_2', data, {headers});
  }

  dclistdetail(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_vansales', data, {headers});
  }

  dclistdetail2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_vansales2', data, {headers});
  }

  dcmtsales(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_mt_sales_dc', data, {headers});
  }

  dcmtsales2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_mt_sales_dc2', data, {headers});
  }

  dcmtoutsales(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_mt_outlets', data, {headers});
  }

  savemtsales(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/saveMTSales', data, {headers});
  }

  dclist2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dclist_v2', data, {headers});
  }

  polistNew(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_list_vanwise_new', data, {headers}).toPromise();
  }

  polistV3(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_list_vanwiseV3', data, {headers}).toPromise();
  }

  getdcStock(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dcstock', data, {headers});
  }

  getprodOrder(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_production_order', data, {headers});
  }

  getSummary(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_salesman_summary', data, {headers});
  }

  getTrack(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_salesman_track', data, {headers});
  }

  getActivity(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_salesman_activity', data, {headers});
  }

  getStock(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_stock_and_sales', data, {headers});
  }

  cancelOrder(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/cancel_order', data, {headers});
  }

  getApproval(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_approval_orders', data, {headers});
  }

  getSettlement(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_settlement_summary', data, {headers});
  }

  markApprove(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_grn', data, {headers});
  }

  partialApprove(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/partial_approve', data, {headers});
  }

  approveAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_grn_for_orders', data, {headers});
  }

  updatePrimary(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_primary_order', data, {headers}).toPromise();
  }

  poLineMarkZero(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/po_line_mark_zero', data, {headers}).toPromise();
  }

  updatePrimaryv2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_primary_orderv2', data, {headers}).toPromise();
  }

  updateProduction(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_production_order', data, {headers});
  }

  updateProductionV2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/update_production_orderv2', data, {headers});
  }

  submitProduction(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/submit_production_order', data, {headers});
  }

  submitProductionV2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/submit_production_orderV2', data, {headers});
  }

  submitProductionV3(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/submit_production_orderV3', data, {headers});
  }

  getProduction(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_production_line_order', data, {headers});
  }

  addDcStocks(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/add_dc_stocks', data, {headers});
  }

  getDeviationDetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_deviation_details', data, {headers});
  }

  reduceDCStocks(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/reduce_dc_stocks', data, {headers});
  }

  markSettlement(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_settlement', data, {headers});
  }

  getProducts(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_products', data, {headers});
  }

  getAllProducts(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_products', data, {headers}).toPromise();
  }

  fetchPublicImage(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    //data.url='http://localhost:3000';
    return this.httpClient.post(data.url + '/api/pwa_connects/fetch_public_file', data);
  }

  fetchImage(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    //data.url='http://localhost:3000';
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(data.url + '/api/pwa_connects/fetch_image', data, {headers});
  }

  fetchImageWait(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    //data.url='http://localhost:3000';
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/fetch_image', data, {headers}).toPromise();
  }

  fetchExpenseImage(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    //data.url='http://localhost:3000';
    return this.httpClient.post(data.url + '/api/pwa_connects/fetch_image', data, {headers}).toPromise();
  }


  dashBoardDCSummary(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dc_dashboard', data, {headers});
  }

  dashBoardSettleSummary(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_settlement_dashboard', data, {headers});
  }

  dashBoardSettleSummary2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_settlement_dashboard2', data, {headers});
  }

  getOrderDetail(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_sale_order', data, {headers});
  }

  getPODetail(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_order', data, {headers});
  }

  rawSaleReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/raw_sale_report', data, {headers});
  }

  liveStockReport(data) {

    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set("authorization", data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_live_stock', data, {headers}).toPromise();
  }

  loadinReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/loadin_report', data, {headers});
  }

  growthReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/growth_report', data, {headers});
  }

  pricelistReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/pricelist_report', data, {headers});
  }

  outletDumpReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/outlet_dump_report', data, {headers});
  }

  userCollectionReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/user_collection_report', data, {headers});
  }

  prodCollectionReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/product_collection_report', data, {headers});
  }

  saleReturnReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/sale_return_report', data, {headers});
  }

  revertStatus(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/revertVanStatus', data, {headers});
  }

  addVansaleStock(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/add_vansale_stocks', data, {headers});
  }

  addVanStock(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/add_van_stock', data, {headers});
  }

  markDelivery(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_loadout_delivery', data, {headers});
  }

  markDeliveryv2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_loadout_deliveryv2', data, {headers});
  }

  markDeliveryv3(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_loadout_deliveryv3', data, {headers});
  }

  markDeliveryAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_delivery_all', data, {headers});
  }

  markDeliveryAllv2(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_delivery_allv2', data, {headers});
  }

  markDeliveryAllv3(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_delivery_allv3', data, {headers});
  }

  getPOStatus(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_status', data, {headers});
  }

  uploadLoadout(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/create_loadout', data, {headers});
  }

  getConvertProduct(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_convert_products', data, {headers});
  }

  convertAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/convert_products', data, {headers});
  }

  vanReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/van_productivity_report', data, {headers});
  }

  vanList(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/vanwise_list', data, {headers});
  }

  tiReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ti_report', data, {headers});
  }

  outReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/outlet_aggregate_report', data, {headers});
  }

  outProdReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/outlet_aggregate_product_report', data, {headers});
  }

  reportLog(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_report_logs', data, {headers});
  }

  dashCeo(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ceo_dash_year', data, {headers});
  }

  dashCeoMtd(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ceo_dash_mtd', data, {headers});
  }

  dashRegion(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ceo_region_target', data, {headers});
  }

  dashProd(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ceo_prod_sale', data, {headers});
  }

  dashCateg(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/ceo_categ_sale', data, {headers});
  }

  dashCommon(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dash_chart_van', data, {headers});
  }

  poUpload(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/po_upload', data, {headers});
  }

  getPos(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_pos', data, {headers});
  }

  getpoDetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_details', data, {headers});
  }

  getMTproducts(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_mt_products', data, {headers}).toPromise();
  }

  generateInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/generate_invoice', data, {headers});
  }

  generateIrn(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/einvoice_orders/getEinvoiceForOrder', data, {headers});
  }

  dailyCollectionReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/daily_collection_report', data, {headers});
  }

  showPos(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/show_pos', data, {headers});
  }

  receivedPos(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/show_recieved', data, {headers});
  }

  returnPos(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/show_returns', data, {headers});
  }

  getPODetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_order', data, {headers});
  }

  getPEDetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_pe_order', data, {headers});
  }

  getSuppliers(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_suppliers', data, {headers});
  }

  supplierProducts(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/supplier_products', data, {headers});
  }

  primarysupplierProducts(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/primary_supplier_products', data, {headers});
  }

  poCreation(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/po_creation', data, {headers});
  }

  receive_PO(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/receivePO', data, {headers});
  }

  stockInHand(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/stock_in_hand', data, {headers});
  }

  einvoiceReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/einvoice_report', data, {headers});
  }

  regenerateIrn(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/regenerate_irn', data, {headers}).toPromise();
  }

  getDmsOrders(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dms_orders', data, {headers});
  }

  getBeats(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_beats', data, {headers});
  }

  createInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/create_invoice', data, {headers});
  }

  getDmsinvoices(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_sale_invoices', data, {headers});
  }

  getInvoices(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_invoices', data, {headers});
  }

  markDispatch(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/mark_dispatch', data, {headers});
  }

  getRetailers(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/get_dms_retailers', data, {headers});
  }

  createRetailer(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_retailer_create', data, {headers});
  }

  getDmsUsers(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_users', data, {headers});
  }

  orderAgingReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/order_aging_report', data, {headers});
  }

  outletOverdueReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/outlet_overdue_report', data, {headers});
  }

  chequeApprovalReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/cheque_approval_report', data, {headers});
  }

  chequeApprovalReportDms(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/cheque_approval_report', data, {headers});
  }

  dmsPayments(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_payments', data, {headers});
  }

  updateChequeApproval(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/updateChequeApproval', data, {headers});
  }

  updateChequeReject(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/updateChequeReject', data, {headers});
  }

  getStockLog(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_stock_log', data, {headers});
  }

  getCostLog(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_cost_log', data, {headers});
  }

  poDash(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_po_dashboard', data, {headers});
  }

  dmsDash(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_dms_dash', data, {headers});
  }

  getFilters(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    // let  headers =new HttpHeaders().set("authorization", data.access_token);
    return this.post('/api/pwa_connects/filter_api', data);
  }

  expenseReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/expense_reportv2', data, {headers});
  }

  myExpenses(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/expense_masters/my_expenses', data, {headers});
  }

  settleExpenses(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/getExpenseToSettle', data, {headers});
  }

  expenseDetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/expense_details', data, {headers});
  }

  expenseDetailsasync(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/expense_details', data, {headers}).toPromise();
  }

  expenseApproval(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/approve_expense', data, {headers});
  }

  expenseReject(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/reject_expense', data, {headers});
  }

  expenseSalesReject(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/reject_sales_expense', data, {headers});
  }

  expenseSettle(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sales_expenses/settle_expense', data, {headers});
  }

  expenseMaster(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/expense_masters/fetch_expenses', data, {headers});
  }

  createExpense(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/', data, {headers});
  }

  getBeatAct(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/getBeatActivities', data, {headers});
  }

  confirmDmsOrder(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/confirmDmsOrder', data, {headers});
  }

  markDeliveryDmsInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_mark_delivery', data, {headers});
  }

  markDeliveryDmsAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/dms_mark_delivery_all', data, {headers}).toPromise();
  }

  markDispatchDmsInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_mark_dispatch', data, {headers});
  }

  markDispatchDmsAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/dms_mark_dispatch_all', data, {headers}).toPromise();
  }

  markReceiveDmsInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_mark_receive', data, {headers});
  }

  markReceiveDmsAll(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/dms_mark_receive_all', data, {headers}).toPromise();
  }

  markConfirmDmsRefund(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_confirm_refund', data, {headers});
  }

  markDmsCancel(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_mark_cancel', data, {headers});
  }

  markDmsCancelOrder(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_order_cancel', data, {headers});
  }

  getCustomerDetails(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/get_customer_details', data, {headers});
  }

  saveDmsOrder(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/dms_order_save', data, {headers});
  }

  uploadDms(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/upload_dms_invoice', data, {headers}).toPromise();
  }

  uploadTask(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/tasks/upload_task', data, {headers}).toPromise();
  }

  uploadUserTask(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/tasks/upload_user_task', data, {headers}).toPromise();
  }

  uploadCreditReturn(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sale_orders/uploadCreditReturns', data, {headers}).toPromise();
  }

  uploadStock(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/dms_connects/upload_stock', data, {headers}).toPromise();
  }


  attendanceReport(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/hr_attendances/attendance_report', data, {headers});
  }

  fetchAssetImage(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/partner_assets/fetch_file', data, {headers}).toPromise();
  }

  fetchStoreImage(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_connects/fetch_file', data, {headers}).toPromise();
  }

  sapSaveInvoice(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/sap_connects/SaveInvoice', data, {headers}).toPromise();
  }

  uploadChangeRequest(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/outlet_change_requests/uploadChangeRequest', data, {headers}).toPromise();
  }


  post(apiURL: string, data?: any) {
    const t = trace(this.sharedService.perf, apiURL);
    t.start();
    if (localStorage.getItem('company_key')) {
      t.putAttribute('companyKey', localStorage.getItem('company_key'));
    }
    if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')) != null) {
      t.putAttribute('user', JSON.parse(localStorage.getItem('user')).login);
      t.putAttribute('user_role', JSON.parse(localStorage.getItem('user')).role.name);
    }
    // Code that you want to trace

    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    // this.api_url = 'http://localhost:3000';
    console.log(this.api_url);
    return this.httpClient
      .post<any>(this.api_url + apiURL, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', authorization: data.access_token
        }),
      })
      .pipe(
        map((response) => {
          if (response) {
            t.stop();
            return response;
          }
        })
      );
  }

  postPromise(apiURL: string, data?: any) {
    const t = trace(this.sharedService.perf, apiURL);
    t.start();
    t.putAttribute('companyKey', localStorage.getItem('company_key'));
    if (JSON.parse(localStorage.getItem('user')) != null) {
      t.putAttribute('user', JSON.parse(localStorage.getItem('user')).login);
      t.putAttribute('user_role', JSON.parse(localStorage.getItem('user')).role.name);
    }
    // Code that you want to trace

    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    // this.api_url = 'http://localhost:3000';
    console.log(this.api_url);
    return this.httpClient
      .post<any>(this.api_url + apiURL, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', authorization: data.access_token
        }),
      }).toPromise()
      .then(res => {
        t.stop();
        return res;
      }).catch(e => {
        t.stop();
        return e; // "oh, no!"
      });
  }

  postPromisewithURL(apiURL: string, data?: any) {
    const t = trace(this.sharedService.perf, apiURL);
    t.start();
    t.putAttribute('companyKey', localStorage.getItem('company_key'));
    if (JSON.parse(localStorage.getItem('user')) != null) {
      t.putAttribute('user', JSON.parse(localStorage.getItem('user')).login);
      t.putAttribute('user_role', JSON.parse(localStorage.getItem('user')).role.name);
    }
    // Code that you want to trace
    // data.url is instance url;
    return this.httpClient
      .post<any>(data.url + apiURL, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json', authorization: data.access_token
        }),
      }).toPromise()
      .then(res => {
        t.stop();
        return res;
      }).catch(e => {
        t.stop();
        return e; // "oh, no!"
      });
  }

  getFilter(apiURL: string, access_token: string, data?: any) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    let paramObject = "";
    // paramObject.filter='{"where":{"user_id":'+this.user_id+',"cid":'+this.cid+'}}';
    paramObject = paramObject + '{"where":{';
    for (const [key, value] of Object.entries(data)) {
      if (paramObject.length > 12) {
        paramObject = paramObject + ',' + '"' + key + '"' + ':' + '"' + value + '"';
      } else {
        paramObject = paramObject + '"' + key + '"' + ':' + '"' + value + '"';
      }
    }
    paramObject = paramObject + '}}';
    console.log(paramObject);

    const params: any = {};
    params.filter = paramObject;

    return this.httpClient
      .get<any>(this.api_url + apiURL, {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json', authorization: access_token
        }),
      })
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  get_static_data(data) {
    if (!this.api_url || this.api_url === '') {
      this.api_url = localStorage.getItem('private_url');
    }
    const headers = new HttpHeaders().set('authorization', data.access_token);
    return this.httpClient.post(this.api_url + '/api/pwa_board_one/get_static_kpi', data, {headers});
  }
}
