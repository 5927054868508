import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-compare-popup',
  templateUrl: './image_compare_popup.component.html',
  styleUrls: ['./image_compare_popup.component.scss']

})

export class ImageComparePopupComponent implements OnInit {

  public photo1;
  public photo2;
  public store_fname1;
  public store_fname2;
  public beforevis;
  public aftervis;
  public comments;
  public category;
  API_URL='';
  container='';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id:null
    }
  };

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any)
  {
    this.store_fname1 = dialogdata.store_fname1;
    this.store_fname2 = dialogdata.store_fname2;
    this.beforevis = dialogdata.beforevis;
    this.aftervis = dialogdata.aftervis;
    this.comments = dialogdata.comments;
    this.category = dialogdata.category;
    this.container = dialogdata.container;

    console.log(dialogdata);

    this.fetchImage1();
    this.fetchImage2();


  }

  ngOnInit(): void {
  }

  fetchImage1(){
    this.API_URL = '/api/pwa_connects/fetch_file';
    var paramObject:any={};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname1;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
            this.photo1 = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          }
          else {


          }
        },
        error => {
          console.log(error);

        });
  }

  fetchImage2(){
    this.API_URL = '/api/pwa_connects/fetch_file';
    var paramObject:any={};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname2;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
            this.photo2 = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          }
          else {


          }
        },
        error => {
          console.log(error);

        });
  }

  closeDialog() {
    this.dialog.closeAll();
  }


}
