import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BulkAddRetailerComponent} from "../../components/bulk_add_retailer/bulk_add_retailer.component";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {TaskPublishPopupComponent} from "../../components/task_publish_popup/task_publish_popup.component";
import {TaskRetailerUploadComponent} from "./task_retailer_upload.component";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-task_detail-component',
  templateUrl: './task_detailv2.component.html',
  styleUrls: ['./task_detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  public columnDefs = [];
  public columnDefs1 = [];
  public rowData = [];
  public rowData1 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public pinnedTotalData = [
    {
      code: 'Add an Item',
      image: 'bottom',
      actions: 'bottom'
    }
  ];
  public taskDetails: any = {};
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  viewtype = 'edit';
  selectedOutlets = [];
  public photo;
  res_data;
  task_types = [];
  selected_task_type: any = {};
  allow_start_date: boolean = true;
  private gridApi;
  private gridColumnApi;
  task_type: any = {};
  statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning',
    new: 'new',
    processed: 'processed'
  };
  taskCreateDate;
  detailsSubmittedDate;
  reviewDate;
  retailerAddedDate;
  taskParams: any = [];
  showDetails = false;
  showCancel = false;
  showSubmit = false;
  showApprove = false;
  cancelFeature;
  configFields: any = [];
  rejectComments;
  approveComments;
  displayStatus;
  upload_retailer;
  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.cancelFeature = this.sharedService.ACLcheck('feature_cancel_task_plan');
    this.upload_retailer = this.sharedService.ACLcheck('feature_task_plan_retailer_upload');
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    console.log(this.task_types);

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      context: {componentParent: this},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        autoHeight: true,
        wrapText: true
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;

    let datas: any = {};
    datas = history.state;


    this.user_data.data.id = localStorage.getItem("taskid");
    this.viewtype = datas.viewtype;
    if (this.viewtype === 'create') {
      this.edit = true;
    }
    this.statusConfig.success = 'completed';
    this.statusConfig.processed = 'published';
    this.statusConfig.new = 'draft';
    this.statusConfig.error = 'rejected';
    this.configureGrid();
    this.fetchTaskDetails();
  }
  configureGrid() {
    this.columnDefs = [
      {
        headerName: "Code",  field: "retailer_code", width: 100,
        cellStyle: params => {
          if (params.data) {
            if (params.data.retailer_code === 'Add an Item') {
              return {color: '#17A2B8'};
            }
          }
        }
      },
      {headerName: "Name",  field: "retailer", width: 200},
      {headerName: "Beat",  field: "beat", width: 120 },
      {headerName: "Class",  field: "class_type", width: 100 },
      {headerName: "Program Type",  field: "program_type", width: 100},
      {headerName: "Salesman",  field: "user", width: 150 },
      {headerName: "S Code",  field: "scode",  width: 100 },
      {headerName: "Start Date",  field: "start_date", width: 100 },
      {headerName: "End Date",  field: "end_date", width: 100 },
      { headerName: "Status",  field: "status", width: 100, cellRenderer: this.sharedService.statusCellRenderer},
      {headerName: "Comments",  field: "comments", width: 150, },
      {headerName: "STORE FNAME",  field: "task_store_fname", hide: true, width: 150},
      {
        headerName: "Image",  field: "image", width: 100,
        cellRenderer: params => {
          if (params.data) {
            if (params.data.task_store_fname === null || params.data.task_store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      },
      {
        headerName: "",  field: "delete", cellStyle: {textAlign: 'right'},   width: 50,
        cellRenderer: params => {
          if (params.data) {
            console.log(params._self.edit);
            if (params._self.edit) {
              if (params.data.status !== 'draft') {
                return "";
              } else {
                return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
              }
            } else {
              return "";
            }
          }
        }, cellRendererParams: {
          _self: this
        }

      },

    ];
  }

  ngOnInit(): void {
  }

  fetchTaskDetails() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.API_URL = '/api/tasks/get_task_detail';
    this.edit = false;
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          this.loaded = true;
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {
            if (res.results.status < 200 && res.results.msg !== null) {
              this.toastr.warning(res.results.msg);
            }
            this.taskDetails = res.results.data;
            if (this.taskDetails.line_count &&  parseInt(this.taskDetails.line_count, 10) !== 0) {
              this.taskDetails.progress = Math.trunc((parseInt(this.taskDetails.completed_count, 10) / parseInt(this.taskDetails.line_count, 10)) * 100);
            } else {
              this.taskDetails.progress = 0;
            }

            this.task_types = JSON.parse(localStorage.getItem('task_types'));
            this.task_type = this.task_types.find(x => x.id === this.taskDetails.type_id);
            this.displayStatus = this.taskDetails.status.charAt(0).toUpperCase() + this.taskDetails.status.slice(1);
            if (this.task_type.type !== 'spl') {
              this.showCancel = true;
            } else if (this.cancelFeature && (this.taskDetails.status === 'draft' || this.taskDetails.status === 'updated')) {
              this.showCancel = true;
            } else {
              this.showCancel = false;
            }
            if (this.sharedService.ACLcheck('feature_submit_task_plan') && this.task_type.type === 'spl' && this.taskDetails.status === 'updated') {
              this.showSubmit = true;
            } else {
              this.showSubmit = false;
            }
            if (this.sharedService.ACLcheck('feature_approve_task_plan') && this.task_type.type === 'spl' && this.taskDetails.status === 'submitted') {
              this.showApprove = true;
            } else {
              this.showApprove = false;
            }
            if (this.task_type && this.task_type?.param_json?.config) {
              this.configFields = [];
              this.task_type.param_json.config.forEach(param => {
                if (this.taskDetails.config_json?.task_config?.[param.field]) {
                  this.configFields.push({name: param.name, value: this.taskDetails.config_json?.task_config?.[param.field]});
                }
              });
              if (this.taskDetails?.config_json?.reject_reason && this.taskDetails?.config_json?.reject_reason !== 'null') {
                this.rejectComments = this.taskDetails.config_json.reject_reason;
              } else {
                this.rejectComments = null;
              }
              if (this.taskDetails?.config_json?.approve_comments && this.taskDetails?.config_json?.approve_comments !== 'null') {
                this.approveComments = this.taskDetails.config_json.approve_comments;
              } else {
                this.approveComments = null;
              }
            }
            this.taskCreateDate = this.taskDetails.create_date;
            if (this.taskCreateDate) {
              this.taskCreateDate = this.datePipe.transform((new Date(this.taskCreateDate + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
            }
            this.detailsSubmittedDate = this.taskDetails?.config_json?.submitted_at;
            if (this.detailsSubmittedDate) {
              try {
                this.detailsSubmittedDate = this.datePipe.transform((new Date(this.detailsSubmittedDate + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } catch (e) {
              }
            }
            this.reviewDate = this.taskDetails?.config_json?.reviewed_at;
            if (this.reviewDate) {
              try {
                this.reviewDate = this.datePipe.transform((new Date(this.reviewDate + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } catch (e) {
              }
            }
            this.retailerAddedDate = this.taskDetails?.config_json?.retailer_uploaded_at;
            if (this.retailerAddedDate) {
              try {
                this.retailerAddedDate = this.datePipe.transform((new Date(this.retailerAddedDate + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } catch (e) {
              }
            }
            const params = this.taskDetails?.config_json?.params;
            if (params) {
              this.taskParams = [];

              if (this.task_type && this.task_type?.param_json?.params) {
                this.task_type.param_json.params.forEach(param => {
                  //const displayField = this.sharedService.removeUnderscoreAndFirstLetterCapital(param.field);
                  this.taskParams.push({key: param.name, value: params?.[param.field]});
                });
              }
            }
            this.checkAllowStartDate();
            this.rowData = this.taskDetails.line_items;
            if (this.gridApi !== null && typeof this.gridApi !== 'undefined') {
              this.gridApi.setRowData(this.rowData);
            }
          } else {
            this.preloader = false;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Task Line !!",
          message1: rows[0].retailer_code + " - " + rows[0].retailer,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        console.log(data);
        if (data === 'yes') {
          rows[0].active = false;
          this.createOrUpdateTask();
        } else {

        }
      });
    } else {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('task_store_fname') && rows[0].task_store_fname !== null && rows[0].task_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }

    }

  }

  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.fetchTaskDetails();
  }

  cancelClick() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Delete Task!!",
        message1: "",
        message2: "Do you wish to Delete this Task?",
        showNoButton: true,
        yesButton: "DELETE",
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.taskDetails.active = false;
        this.createOrUpdateTask();
      } else {

      }
    });
  }

  openPublishDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "550px",
      data: {
        title: "Publish Task",
        message1: "You will not be allowed to edit the Task once Published.",
        message2: "Do you wish to Publish the Task?",
        showNoButton: true,
        yesButton: "PUBLISH",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(TaskPublishPopupComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
     /* if (data === 'yes') {
        this.taskDetails.status = 'published';
        if (this.taskDetails.hasOwnProperty('line_items') && this.taskDetails.line_items.length > 0) {
          for (var i = 0; i < this.taskDetails.line_items.length; i++) {
            this.taskDetails.line_items[i].status = 'published';
          }
        }
        this.createOrUpdateTask();
      } else {

      }*/
      this.taskDetails.status = 'published';
      if (this.taskDetails.hasOwnProperty('line_items') && this.taskDetails.line_items.length > 0) {
        for (var i = 0; i < this.taskDetails.line_items.length; i++) {
          this.taskDetails.line_items[i].status = 'published';
        }
      }
      if (data && data.length > 0) {
        this.taskDetails.brand_id = data[0].id;
      } else {
        this.taskDetails.brand_id = null;
      }
      this.createOrUpdateTask();
    });
  }

  createDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "90%",
      maxHeight: "93%",
      panelClass: 'custom-dialog-container'
    };

    const creatediaeref = this.dialog.open(BulkAddRetailerComponent, config);

    creatediaeref.componentInstance.selectedOutlets.subscribe(data => {
      this.selectedOutlets = data;
      let indoutlet: any = {};
      if (!this.taskDetails.hasOwnProperty("line_items")) {
        this.taskDetails.line_items = [];
        this.taskDetails.status = 'draft';
      }

      for (var i = 0; i < this.selectedOutlets.length; i++) {
        indoutlet = {};
        indoutlet.retailer = this.selectedOutlets[i].name;
        if (this.selectedOutlets[i].beat && this.selectedOutlets[i].beat.length > 0) {
          indoutlet.beat = this.selectedOutlets[i].beat[0].bp;
        }
        indoutlet.retailer_code = this.selectedOutlets[i].code;
        indoutlet.partner_id = this.selectedOutlets[i].id;
        indoutlet.user_id = this.selectedOutlets[i].user_id;
        indoutlet.user = this.selectedOutlets[i].user;
        indoutlet.area = this.selectedOutlets[i].area;
        indoutlet.zone = this.selectedOutlets[i].zone;
        indoutlet.class_type = this.selectedOutlets[i].class_type;
        indoutlet.program_type = this.selectedOutlets[i].program_type;
        indoutlet.status = "draft";
        indoutlet.active = true;
        this.taskDetails.line_items.push(indoutlet);
      }

      if (this.rowData === null || this.rowData.length === 0) {
        this.rowData = this.taskDetails.line_items;
        this.loaded = true;
      }

      console.log(this.rowData);

      this.gridApi.setRowData(this.rowData);
    });
  }

  openRetailereUploadPopup() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          taskDetails: this.taskDetails
        }
      };
      creatediaeref = this.dialog.open(TaskRetailerUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.fetchTaskDetails();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          taskDetails: this.taskDetails
        }
      };
      creatediaeref = this.dialog.open(TaskRetailerUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.fetchTaskDetails();
      });
    }
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.taskDetails.start_date = this.datePipe.transform(this.taskDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.taskDetails.end_date = this.datePipe.transform(this.taskDetails.end_date, 'yyyy-MM-dd');
  }

  createOrUpdateTask() {
    console.log(this.taskDetails);
    const paramObject: any = {};
    const apiTaskData: any = {};
    apiTaskData.id = this.taskDetails.id;
    apiTaskData.name = this.taskDetails.name;
    apiTaskData.type_id = this.taskDetails.type_id;
    apiTaskData.code = this.taskDetails.code;
    apiTaskData.type_id = this.taskDetails.type_id;
    apiTaskData.description = this.taskDetails.description;
    apiTaskData.instructions = this.taskDetails.instructions;
    apiTaskData.start_date = this.taskDetails.start_date;
    apiTaskData.end_date = this.taskDetails.end_date;
    apiTaskData.active = this.taskDetails.active;
    apiTaskData.status = this.taskDetails.status;
    if (this.taskDetails.brand_id) {
      apiTaskData.brand_id = this.taskDetails.brand_id;
    }
    apiTaskData.line_items = [];
    paramObject.access_token = this.user_data.access_token;
    for (let key in this.taskDetails.line_items) {
      let ind_line: any = {};
      ind_line.task_id = this.taskDetails.id;
      ind_line.id = this.taskDetails.line_items[key].id;
      ind_line.partner_id = this.taskDetails.line_items[key].partner_id;
      ind_line.status = this.taskDetails.line_items[key].status;
      ind_line.active = this.taskDetails.line_items[key].active;
      ind_line.comments = this.taskDetails.line_items[key].comments;
      ind_line.task_store_fname = this.taskDetails.line_items[key].task_store_fname;
      ind_line.reason_id = this.taskDetails.line_items[key].reason_id;
      if (this.taskDetails.line_items[key].hasOwnProperty('user_id')) {
        ind_line.user_id = this.taskDetails.line_items[key].user_id;
      }
      apiTaskData.line_items.push(ind_line);
    }
    if (!apiTaskData.hasOwnProperty("status")) {
      apiTaskData.status = 'draft';
    }


    paramObject.data = apiTaskData;
    this.API_URL = '/api/tasks/create_task';
    console.log(this.taskDetails);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (this.viewtype === 'create') {
            this.toastr.success('Task created Successfully');
            this.viewtype = 'detail';
          } else {
            this.toastr.success('Task updated Successfully');
          }

          if (res.results.hasOwnProperty('task_id')) {
            localStorage.setItem("taskid", res.results.task_id);
          }

          if (this.edit) {
            this.edit = !this.edit;
          }

          if (this.taskDetails.active) {
            this.fetchTaskDetails();
          } else {
            window.history.go(-1);
          }

        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Task Updation Failed");
        }
      });

  }

  checkAllowStartDate() {
    if (this.task_types !== null) {
      let typeid = this.taskDetails.type_id;
      if (typeid) {
        typeid = parseInt(typeid, 0);
        const allowStartDate = this.task_types.filter(type => type.id === typeid).map(obj => obj.allow_start_date);
        console.log(allowStartDate);
        if (allowStartDate !== null) {
          this.allow_start_date = allowStartDate[0];
        } else {
          this.allow_start_date = true;
        }
      } else {
        typeid = 0;
        this.allow_start_date = true;
      }
    }
  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_activation'], {
      state: {

        taskID: this.taskDetails.id,
        start_date: this.taskDetails.start_date,
        end_date: this.taskDetails.end_date
      }
    });
  }

  goToSurvey() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_survey'],{state: {
        surveyID : this.taskDetails.survey_id
      }});
  }

  detailsClick() {
    this.showDetails = !this.showDetails;
  }

  openSubmitDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Submit Task",
        message1: "Are you sure you want to submit this Task Plan?",
        message2: "",
        showNoButton: true,
        yesButton: "SUBMIT",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.submitTasks();
      } else {

      }
    });
  }

  submitTasks() {
    const ids = [this.taskDetails.id];
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids
    };
    this.apiService.post('/api/tasks/submit_task_plan', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Task Plan Submitted Successfully");
          this.fetchTaskDetails();
        } else {
          this.toastr.error("Failed to Submit Task Plan");
        }
      });
  }
  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'reject') {
      title = "Reject Task Plan";
      message1 = "Are you sure you want to Reject Task Plan?";
      message2 = "Please provide a detailed reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Task Plan";
      message1 = "Are you sure you want to Approve Task Plan?";
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "380px",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectTasks( 'reject', data);
        } else {
          this.approveRejectTasks( 'approve', data);
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectTasks( 'reject', data);
        } else {
          this.approveRejectTasks( 'approve', data);
        }
      });
    }
  }

  approveRejectTasks(state, reason) {
    const ids = [this.taskDetails.id];
    let status = '';
    if (state === 'approve') {
      status = 'approved';
    } else {
      status = 'updated';
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids,
      reason,
      status
    };
    this.apiService.post('/api/tasks/review_task_plan', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(state.toUpperCase() + " Successfully");
          this.fetchTaskDetails();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Task");
        }
      });
  }

}
