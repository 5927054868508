import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SingleMarginBillSchemeReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Sale Type',
    key: 'type',
    value: 'P',
    values: [],
    show: true
  };
/*  showCustomFilter1 = true;
  customFilter1: any = {
    name: 'Scheme Type',
    key: 'name',
    value: 'single',
    values: [],
    show: true
  };*/

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Bill Scheme(Single and Margin) Report');

    this.showableFilters.dc = true;
    this.userData.customFilter['type'] = 'P';
    this.customFilter.values = [
      {id: "P", itemName: "Primary"},
      {id: "S", itemName: "Secondary"},
    ];
    this.customFilter1.values = [
      {id: "", itemName: "All"},
      {id: "single", itemName: "Single"},
      {id: "margin", itemName: "Margin"},
    ];
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSingleMarginSchemeClaimReport';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Type', field: 'claim_type', width: 80},
      {
        headerName: 'Supplier',
        children: [
          {headerName: 'ID', field: 'supp_id', hide: true, width: 100},
          {headerName: 'Code', field: 'supp_code', width: 100},
          {headerName: 'Name', field: 'supp_name', width: 100},
        ]
      },
      {
        headerName: 'Customer',
        children: [
          {headerName: 'ID', field: 'cust_id', hide: true, width: 100},
          {headerName: 'Code', field: 'cust_code', width: 100},
          {headerName: 'Name', field: 'cust_name', width: 100},
        ]
      },
      {
        headerName: 'Scheme',
        children: [
          {headerName: 'Type', field: 'scheme_type', width: 100},
          {headerName: 'Code', field: 'scheme_code', width: 100},
          {headerName: 'Name', field: 'scheme_name', width: 100},
        ]
      },
      {
        headerName: 'Order',
        children: [
          { headerName: 'Ref',  field: 'order', cellStyle: {textAlign: 'right'}, width: 80},
          { headerName: 'Date',  field: 'date_delivered', width: 80},
          {headerName: 'Prod Code', field: 'prod_code', width: 100},
          {headerName: 'Prod Name', field: 'prod_name', width: 100},
          {headerName: 'Type', field: 'type', width: 100},
          {
            headerName: 'Promo Qty', field: 'promo_qty', cellStyle: {textAlign: 'right'}, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (!params.data.promo_qty) {
                  params.data.promo_qty = 0;
                }
                return params.data.promo_qty;
              }
            }, aggFunc: 'sum'
          },
          {
            headerName: 'Cash Discount', field: 'cash_disc', cellStyle: {textAlign: 'right'}, width: 100,
            valueGetter: params => {
              if (params.data) {
                if (!params.data.cash_disc) {
                  params.data.cash_disc = 0;
                }
                return params.data.cash_disc;
              }
            }, aggFunc: 'sum'
          },
          {
            headerName: 'Perc Discount', field: 'perc_scheme_discount', hide: true, cellStyle: {textAlign: 'right'},
            width: 100,
            valueGetter: params => {
              if (params.data) {
                if (!params.data.perc_scheme_discount) {
                  params.data.perc_scheme_discount = 0;
                }
                return params.data.perc_scheme_discount;
              }
            }, aggFunc: 'sum'
          },
          {
            headerName: 'Promo Discount', field: 'promo_scheme_discount', hide: true, cellStyle: {textAlign: 'right'},
            width: 100,
            valueGetter: params => {
              if (params.data) {
                if (!params.data.promo_scheme_discount) {
                  params.data.promo_scheme_discount = 0;
                }
                return params.data.promo_scheme_discount;
              }
            }, aggFunc: 'sum'
          },
          {
            headerName: 'Order Discount', cellStyle: {textAlign: 'right'}, width: 100,
            valueGetter: params => {
              if (params.data) {
                let order_disc = 0;
                if (params.data.cash_disc) {
                  order_disc = order_disc + parseFloat(params.data.cash_disc);
                }
                if (params.data.perc_scheme_discount) {
                  order_disc = order_disc + parseFloat(params.data.perc_scheme_discount);
                }
                if (params.data.promo_scheme_discount) {
                  order_disc = order_disc + parseFloat(params.data.promo_scheme_discount);
                }
                return order_disc;
              }
            }, aggFunc: 'sum'
          },
          {
            headerName: 'Line Scheme Discount', field: 'line_scheme_discount', cellStyle: {textAlign: 'right'},
            width: 100, aggFunc: 'sum'
          },
          {
            headerName: 'Line Discount', field: 'line_discount', cellStyle: {textAlign: 'right'},
            width: 100, aggFunc: 'sum'
          },
          {
            headerName: 'Line  Total', field: 'price_total', hide: true, cellStyle: {textAlign: 'right'},
            width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.price_total) {
                  return parseFloat(params.data.price_total).toFixed(2);
                } else {
                  return 0;
                }
              }
            }, aggFunc: 'sum'
          },
          {headerName: 'Amount Total', field: 'amount_total', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
          /*{headerName: 'Claim Amount', field: 'claim_amount', hide: true, cellStyle: {textAlign: 'right'},
            width: 80, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                let claim_amount = 0;
                if (params.data.cash_disc) {
                  claim_amount += parseFloat(params.data.cash_disc);
                }
                if (params.data.perc_scheme_discount) {
                  claim_amount +=  parseFloat(params.data.perc_scheme_discount);
                }
                if (params.data.promo_scheme_discount) {
                  claim_amount += parseFloat(params.data.promo_scheme_discount);
                }
                claim_amount += parseFloat(params.data.line_scheme_discount) + parseFloat(params.data.line_discount);
                return claim_amount;
              }
            }
          },*/
        ]
      },


      /*{ headerName: 'Sale Type',  field: 's_type',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.s_type) {
              if (params.data.s_type === 'S') {
                return 'Secondary';
              } else if (params.data.s_type === 'P') {
                return 'Primary';
              }
            }
          }
        }
        },*/
      /*  { headerName: 'Order ID',  field: 'order', cellStyle: {textAlign: 'right'},width: 80},*/

    ];

  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column !== 'pod_store_fname') {

    } else {

      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('pod_store_fname') && rows[0].pod_store_fname != null && rows[0].pod_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].pod_store_fname,
              container: "sale_order_gallery"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].pod_store_fname,
              container: "sale_order_gallery"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }

  }

}
