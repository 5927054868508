import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @Input() status: string;
  @Input() style_class: string;

  constructor() { }

  ngOnInit() {

    console.log(this.style_class);
  }

}
