import {Component, ElementRef, Renderer2} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SharedService} from './_services/shared_service';
import {ApiService} from "./_services/api.service";

@Component({
  selector: 'app-cell-editor2',
  template: `<div class="po-top multi" layout="row">` +
  `<input *ngIf="editable" type="text" [(ngModel)]="searchValue" (keydown.enter)="updateCell()" (keyup)="editCell()" autocomplete="off"
          class="custom-inputbox multi"/>` +
    `<label *ngIf="!editable">{{displayValue}}</label>` +
    `<span *ngIf="unhide" class="element-po" style="margin-left: 25%;"><img (click)="removeRow()" class="element-img"
          src="../assets/delete1.svg" alt=""></span>` +
    `</div>`
})
export class CellEdit2Renderer implements ICellRendererAngularComp {
  public params: any;
  public searchValue;
  public displayValue;
  public editable = false;
  public unhide = false;
  public cell;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private sharedService: SharedService) {}

  agInit(params: any): void {

    this.params = params;
    this.searchValue = params.data[this.params.column.colId] ;
    this.displayValue = this.searchValue;
    this.displayValue = parseFloat(this.displayValue).toFixed(2);
    if (this.sharedService.is_return || this.sharedService.new || params.data.loadout ||
        this.sharedService.dmsParams.new_mode || this.sharedService.dmsParams.edit_mode) {
       this.editable = true;
    }
    if (this.params.column.colId === 'PRICE' && !this.params.context.componentParent.priceEdit) {
      this.editable = false;
    }
    if (params.data.edit_status) {
      this.editable = false;
    }
    if (params.data.loadout) {
      this.unhide = true;
    }
   /* if (this.params.column.colId === 'QTY' && this.sharedService.dmsParams.type === 'return') {
      this.editable = false;
    }*/
    if (this.sharedService.dmsParams.dmsType === 'P' &&
      (this.params.column.colId === 'extra_margin' || this.params.column.colId === 'trade_disc')) {
      this.editable = false;
    }
  }
  public editCell() {
    this.params.data[this.params.column.colId]  = this.searchValue;
    if (this.params.context.componentParent.page === 'DMS') {
      if (this.params.column.colId === 'PRICE' && this.params.context.componentParent.priceEdit) {
        this.params.data.real_price = this.searchValue;
        this.params.context.componentParent.priceEdited = true;
      } else if (this.params.column.colId === 'RET' &&
                 parseFloat(this.params.data.RET) > (parseFloat(this.params.data.invoiced_qty))) {
        this.params.data.RET = 0;
      } else if (this.params.column.colId === 'DMG' &&
        parseFloat(this.params.data.DMG) > (parseFloat(this.params.data.RET))) {
        this.params.data.DMG = 0;
      }
    }
  }

  removeRow() {

    if (this.params.context.componentParent) {
      this.params.context.componentParent.removeProduct(this.params.rowIndex);
    } else {
      this.params.context.removeProduct(this.params.rowIndex);
    }
  }

  bringFocusBack() {
    const cell = this.params.context.componentParent.gridOptions.api.getFocusedCell();

    if ( cell ) {
      this.params.context.componentParent.gridOptions.api.setFocusedCell( cell.rowIndex, cell.column.colId, null );
    }
  }

  updateCell() {
    if (this.params.column.colId !== 'DMG' && this.params.column.colId !== 'RET') {
      this.params.data[this.params.column.colId]  = this.searchValue;
    }
    if (this.params.context.componentParent.page === 'DMS') {
      if (this.params.column.colId === 'PRICE' && this.params.context.componentParent.priceEdit) {
        this.params.data.real_price = this.searchValue;
        this.params.context.componentParent.priceEdited = true;
      }
      this.params.context.componentParent.save('edit');
    }else if (this.searchValue !== '') {
      this.params.data.TOTAL = (parseFloat(this.searchValue) * parseFloat(this.params.data.PRICE)).toFixed(this.apiService.decimalPolicy);
      this.params.context.componentParent.gridApi.setRowData(this.params.context.componentParent.rowData);
    }
  }

  refresh(): boolean {
    return true;
  }
}
