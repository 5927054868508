import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ColorCellRenderer} from '../../colorCell.component';
import {MatDialogConfig} from '@angular/material/dialog';
import {ChangeRequestPopupComponent} from './change_request_popup/change_request_popup.component';
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {ProgressPopupComponent} from "../../components/progress_popup/progress_popup.component";
import * as XLSX from "xlsx-js-style";
import {GstinVerifyPopupComponent} from "../approval_request/gstin_verify_popup/gstin-verify-popup.component";
import {PageBase} from "../../pagebase/pagebase";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

declare let $: any;

@Component({
  selector: 'app-change-req',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})
export class ChangeRequestComponent extends PageBase implements OnInit {
  @ViewChild('fileUp') fileUp: ElementRef;
  user;
  res_data;
  showCustomFilter = true;
  showCustomFilter1 = true;
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };
  customFilter1: any = {
    name: 'Type',
    key: 'type',
    value: 'retailer',
    values: [],
    show: true
  };
  public isModelOpened = true;
  public newRetailer = {
    code: null,
    name: null,
    mobile: null,
    retailer_type: null,
    class_type: null,
    territory: null,
    beat: null,
    beat_name: null
  };
  public typeList;
  public selectedType = [];
  public classType = [];
  public selectedClass = [];
  public selectedTerr = [];
  public selectedBeat = [];
  public beatList = [];
  public terrList = [];
  public confirmEnable = true;
  public team_territories_list = [];
  userid = 0;
  public cr_data = [];
  succcessCount = 0;
  failedCount = 0;
  gstnData: any;
  public sapVerifyTypes = ['cash_to_credit', 'credit_to_cash', 'program_type', 'tax_details', 'contact_details', 'classification', 'location_with_address'];
  public sapEnable = false;
  public sapConfig: any = {};
  public role_features;
  sapSync = false;
  enableApprove = false;
  enableMarkReview = false;

  ngOnInit() {
    this.sharedService.setHeader('Change Request');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass']
    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

    this.userid = JSON.parse(localStorage.getItem('user')).id;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'draft', itemName: 'Draft'},
      {id: 'accepted', itemName: 'Accepted'},
      {id: 'rejected', itemName: 'Rejected'}
    ];
    this.customFilter1.values = [
      {id: '', itemName: 'All'},
      {id: 'retailer', itemName: 'Retailer'},
      {id: 'supplier', itemName: 'Supplier'},
      {id: 'influencer', itemName: 'Influencer'},
      {id: 'project', itemName: 'Project'},
      {id: 'leads', itemName: 'Lead'},
      {id: 'user', itemName: 'User'}
    ];
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.uploadTask = true;
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_sap_push' === existed.name);
    if (this.role_features.length > 0) {
      this.sapSync = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_enable_approve' === existed.name);
    if (this.role_features.length > 0) {
      this.enableApprove = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_cr_mark_review' === existed.name);
    if (this.role_features.length > 0) {
      this.enableMarkReview = true;
    }

    const integrs = JSON.parse(localStorage.getItem('integrations'));
    const updated = integrs.filter(existed => existed.integration_type === 'sap');
    if (updated.length > 0 && updated[0].config_json && this.sapSync) {
      this.sapEnable = true;
    } else {
      this.sapEnable = false;
    }
    this.frameworkComponents['colorCellRenderer'] = ColorCellRenderer;
    const that = this;
    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.request_type === 'tax_details' || params.data.request_type === 'gst') {
          return false;
        } else if (params.data.status !== 'draft') {
          return false;
        } else if (that.userid !== null && that.userid === params.data.create_uid) {
          if (that.enableApprove) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    };
    this.statusConfig.success = 'accepted';
    this.statusConfig.error = 'rejected';
    this.statusConfig.new = 'draft';
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/outlet_change_requests/getChangeRequest';
    const datepipe = this.datePipe;
    this.columnDefs = [
      {headerName: 'id', field: 'id', hide: true},
      {headerName: 'create_uid', field: 'create_uid', hide: true},
      {
        headerName: 'Name', field: 'partner_name', width: 150, pinned: 'left',
        headerCheckboxSelection: this.enableApprove,
        headerCheckboxSelectionFilteredOnly: this.enableApprove,
        checkboxSelection: params => {
          if (params.data) {
            if (params.data.request_type === 'tax_details' || params.data.request_type === 'gst') {
              return false;
            } else if (params.data.status !== 'draft') {
              return false;
            } else if (params.context.componentParent.userid !== null && params.context.componentParent.userid === params.data.create_uid) {
              if (params.context.componentParent.enableApprove) {
                return true;
              } else {
                return false;
              }
            } else {
              if (params.context.componentParent.enableApprove) {
                return true;
              } else {
                return false;
              }
            }
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
      {
        headerName: 'Actions', field: 'action', width: 100, pinned: 'left',
        cellRenderer(params) {
          if (params.data) {
            if (params._self.userid != null && params._self.userid === params.data.create_uid) {
              if (params._self.enableApprove) {
                if (params.data.status !== 'draft') {
                  return '';
                } else {
                  return '<span class="ml-1 status1" style="background: #379862;" data-action-type="approve">Approve</span><span class="ml-1 status1" style="background: #E24260;" data-action-type="reject">Reject</span>';
                }
              } else {
              }
            } else {
              if (params.data.status !== 'draft') {
                return '';
              } else {
                if (params._self.enableApprove) {
                  return '<span class="ml-1 status1" style="background: #379862;" data-action-type="approve">Approve</span><span class="ml-1 status1" style="background: #E24260;" data-action-type="reject">Reject</span>';
                } else {
                }
              }
            }
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
      {headerName: 'Type', field: 'partner_type', width: 80},
      {headerName: 'Code', field: 'code', width: 80},
      {
        headerName: 'Request Type', field: 'request_type', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.request_type != null) {
              const str = params.data.request_type;
              return str
                .replace(/_/g, ' ')
                .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
            }
          }
        }
      },
      {headerName: 'Old Value', field: 'old_val', maxWidth: 200, valueGetter: this.oldValueGetter},
      {headerName: 'New Value', field: 'new_val', wrapText: true, maxWidth: 200, valueGetter: this.newValueGetter},
      {headerName: 'Distance(KM)', field: 'distance', valueGetter: this.calculateDistance, hide: true, cellClass: 'number-cell', width: 60},
      {
        headerName: 'Status', field: 'status', width: 100, enableRowGroup: true,
        cellRenderer: this.sharedService.statusCellRenderer
      },
      {headerName: 'Region', field: 'region', width: 80, enableRowGroup: true},
      {headerName: 'Cluster', field: 'cluster', width: 80, enableRowGroup: true},
      {headerName: 'Territory', field: 'territory', width: 120, enableRowGroup: true},
      {headerName: 'Country', field: 'country', width: 120, hide: true},
      {headerName: 'Channel', field: 'out_type', width: 80},
      {headerName: 'Program', field: 'program_type', width: 100},
      {
        headerName: 'Request Date', field: 'req_date', width: 110,
        valueGetter: params => {
          if (params.data) {
            return datepipe.transform((new Date(params.data.req_date + ' UTC')).toString(), 'dd/MM/yyyy');
          }
        }
      },
      {headerName: 'Requested By', field: 'req_by', width: 150},
      {
        headerName: 'Image', field: 'store_fname', width: 80,
        cellRenderer(params) {
          if (params.data.store_fname === null || params.data.store_fname === "") {
            return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
          } else {
            return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
          }
        }
      },
      {headerName: 'Comments', field: 'comments', width: 150},
      {headerName: 'Approved By', field: 'approved_by', hide: true, width: 100},
      {headerName: 'Approved Date', field: 'approved_date', hide: true, width: 100},
      {headerName: 'Mobile', field: 'mobile', hide: true, width: 80},
      {headerName: 'Contact Name', field: 'contact_name', hide: true, width: 80},
      {headerName: 'Beat', field: 'beat', hide: true, width: 80},
      {headerName: 'GSTN', field: 'gst_id', hide: true, width: 80},
      {headerName: 'Outlet Address', field: 'outlet_address', hide: true, width: 80},
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      });
    });
    this.gridColumnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  newRetailerCreation() {
    $('#retailermasterModal').modal('show');
    this.confirmEnable = true;
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();

    if (column === 'action') {
      const actionType = event.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "approve": {
          if (event.data.request_type === 'tax_details') {
            this.openGSTINVerify(event.data, 'APPROVE');
          } else {
            this.openConfirmationDialog(event.data, 'APPROVE');
          }
          break;
        }
        case "reject": {
          this.openConfirmationDialog(event.data, "REJECT");
          break;
        }
      }
    } else if (column === 'store_fname') {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0].asset_store_fname !== null && rows[0].store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].store_fname,
              container: "res_partner_gallery"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].store_fname,
              container: "res_partner_gallery"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      }
    } else {
      let detailsdiaeref: any = {};
      event.data.old_val_text = this.formatValue(event.data.request_type, event.data.old_val);
      event.data.new_val_text = this.formatValue(event.data.request_type, event.data.new_val);
      event.data.old_val_json = event.data.old_val;
      event.data.new_val_json = event.data.new_val;

      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '100vw',
          height: 'auto',
          maxWidth: '70%',
          maxHeight: '100%',
          data: event.data
        };

        if (event.data.request_type.toLowerCase().includes('location') ||
          event.data.l_lat !== null && event.data.l_lat !== undefined
          && event.data.l_lat !== "") {
          config.height = '100vh';
          config.maxHeight = '93%';
        }

        detailsdiaeref = this.dialog.open(ChangeRequestPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '98%',
          data: event.data
        };

        detailsdiaeref = this.dialog.open(ChangeRequestPopupComponent, config);
      }

      detailsdiaeref.componentInstance.crAction.subscribe(status => {

        console.log(status);
        if (status === 'approved') {
          if (event.data.request_type === 'tax_details') {
            this.openGSTINVerify(event.data, 'APPROVE');
          } else if (!this.sapVerifyTypes.includes(event.data.request_type)) {
            this.updateChangeRequest(event.data, status);
          } else {
            this.verify(event.data, status);
          }
        } else {
          this.updateChangeRequest(event.data, status);
        }
      });
    }
  }

  oldValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.old_val !== null && params.data.old_val !== "") {
        try {
          JSON.parse(params.data.old_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.old_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return '';
      }
    }
  }

  formatValue(requestType, requestValue) {
    let isJson = true;
    if (requestValue !== null && requestValue !== "") {
      try {
        JSON.parse(requestValue);
      } catch (e) {
        isJson = false;
      }
    } else {
      isJson = false;
    }
    if (isJson) {
      if (this.isJsonString(requestValue)) {
        const jsonValue = JSON.parse(requestValue);
        if (requestType === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          if (jsonValue.hasOwnProperty('area')) {
            address = address.concat(jsonValue.area) + ',';
          }
          if (jsonValue.hasOwnProperty('zone')) {
            address = address.concat(jsonValue.zone) + ',';
          }
          if (jsonValue.hasOwnProperty('city')) {
            address = address.concat(jsonValue.city) + ',';
          }
          if (jsonValue.hasOwnProperty('state')) {
            address = address.concat(jsonValue.state) + ',';
          }
          if (jsonValue.hasOwnProperty('zip')) {
            address = address.concat(jsonValue.zip) + ',';
          }
          if (jsonValue.hasOwnProperty('country')) {
            address = address.concat(jsonValue.country);
          }

          return address;
        } else if (requestType === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return '';
          }
        } else if (requestType === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (requestType === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (requestType === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (requestType === "asset_transfer") {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            let asset: any = '';
            if (jsonValue.hasOwnProperty('asset_name')) {
              asset = "Asset : " + jsonValue.asset_name + ", ";
            }
            if (jsonValue.hasOwnProperty('partner_name')) {
              asset += "Partner : " + jsonValue.partner_name;
            }
            return asset;
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return requestValue;
      }
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  calculateDistance(params) {
    let new_v = {};
    let old_v = {};
    if (params.data) {
      if (params.data.request_type === 'location_with_address' || params.data.request_type === 'location_without_address') {
        if (params.data.new_val !== null && params.data.new_val !== "" && params.data.old_val !== null && params.data.old_val !== "") {
          try {
            new_v = JSON.parse(params.data.new_val);
            old_v = JSON.parse(params.data.old_val);
            const lat1 = new_v['latitude'];
            const lon1 = new_v['longitude'];
            const lat2 = parseFloat(old_v['latitude']);
            const lon2 = parseFloat(old_v['longitude']);
            if ((lat1 === lat2) && (lon1 === lon2)) {
              return 0;
            } else if ((lat1 === undefined) || (lon1 === undefined) || (lat2 === undefined) || (lon2 === undefined) || (lat1 === undefined) || (lon1 === 0) || (lat2 === 0) || (lon2 === 0)) {
              return 0;
            } else {
              const radlat1 = Math.PI * lat1 / 180;
              const radlat2 = Math.PI * lat2 / 180;
              const theta = lon1 - lon2;
              const radtheta = Math.PI * theta / 180;
              let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
              if (dist > 1) {
                dist = 1;
              }
              dist = Math.acos(dist);
              dist = dist * 180 / Math.PI;
              dist = dist * 60 * 1.1515;
              return (dist * 1.609344).toFixed(2);
            }
          } catch (e) {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }

  newValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.new_val !== null && params.data.new_val !== "") {
        try {
          JSON.parse(params.data.new_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.new_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (params.data.request_type === "asset_transfer") {
          let asset: any = '';
          if (jsonValue.hasOwnProperty('asset_name')) {
            asset = "Asset : " + jsonValue.asset_name + ", ";
          }
          if (jsonValue.hasOwnProperty('partner_name')) {
            asset += "Partner : " + jsonValue.partner_name;
          }
          return asset;
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return params.data.new_val;
      }
    }
  }

  checkBoxGetter(params) {
    if ((this.userid != null && this.userid === params.data.create_uid) || (params.data.status !== 'pending' && params.data.status !== 'draft')) {
      return false;
    } else {
      return true;
    }
  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  openConfirmationDialog(row: any, state: string) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: state + " Request",
        message1: "Do you wish to " + state + " the request?",
        message2: "",
        showNoButton: true,
        yesButton: state,
        noButton: "CANCEL"
      }
    };

    if (state === 'REJECT') {
      config.data.deleteButton = true;
    }

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let status = '';
      if (state === 'REJECT') {
        status = 'rejected';
      } else {
        status = 'approved';
      }
      if (data === 'yes') {
        if (state === 'REJECT' || !this.sapVerifyTypes.includes(row.request_type)) {
          this.updateChangeRequest(row, status);
        } else {
          this.verify(row, status);
        }
      } else {

      }
    });
  }

  bulkUpdateProgress(state) {
    let apiurl = '';
    if (this.enableMarkReview) {
      apiurl = '/api/outlet_change_requests/changeRequestMarkReview';
    } else {
      apiurl = '/api/outlet_change_requests/updateChangeRequest';
    }
    const request_ids = [];
    const rows = this.gridApi.getSelectedRows();
    rows.forEach(data => {
      request_ids.push(data.oc_id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.state = state;
    paramObject.comments = '';
    paramObject.sapVerifyTypes = this.sapVerifyTypes;

    let title = '';
    if (state === 'approved') {
      title = 'Approval in process';
    } else {
      title = 'Rejection in process';
    }

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "410px",
      maxHeight: "250px",
      /* disableClose: true,*/
      data: {
        title,
        user_data: paramObject,
        API_URL: apiurl,
        data: request_ids,
        dataname: "id",
        batchSize: 1,
        rowData: rows,
        type: 'changerequest'
      }
    };

    const confirmdiaeref = this.dialog.open(ProgressPopupComponent, config);

    confirmdiaeref.componentInstance.progressStatus.subscribe(data => {
      if (data.err !== null && data.err !== '') {
        this.toastr.error(data.err);
      } else if (data.successCount === 0) {
        this.toastr.error('Error updating change request');
      } else if (data.failedCount > 0) {
        this.toastr.warning('Success : ' + data.successCount + "Failed : " + data.failedCount);
      } else {
        this.toastr.success(state.toUpperCase() + " : " + data.successCount + " records");
        //window.location.reload();
        this.loadServerData();
      }
      this.showApprove = false;
    });
  }

  bulkUpdateChangeRequest(state) {
    const apiurl = '/api/outlet_change_requests/bulkUpdateChangeRequest';
    const request_ids = [];
    const rows = this.gridApi.getSelectedRows();
    rows.forEach(data => {
      request_ids.push(data.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.ids = request_ids;
    paramObject.state = state;
    paramObject.comments = '';
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res.result.success)) {
            this.toastr.success('Updated Successfully');
            //window.location.reload();
            this.loadServerData();
          } else {
            this.toastr.error('Error updating Change Request');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Change Request');
        });
  }

  verify(row, state) {
    if (this.sapEnable && this.sapSync) {
      const integrs = JSON.parse(localStorage.getItem('integrations'));
      const updated = integrs.filter(existed => existed.integration_type === 'sap' && existed.country_id === row.country_id);
      if (row.country_id === null || row.country_id === undefined) {
        this.toastr.error('Country not set for outlet : ' + row.code);
      } else if (updated.length > 0 && updated[0].config_json) {
        const sapConfig = updated[0].config_json;
        sapConfig.UpdateBp = sapConfig.UpdateBp.toString().replace("$outletcode$", row.code);
        const payload = {
          access_token: localStorage.getItem('resfreshToken'),
          data: {
            url: sapConfig.AuthUrl,
            payload: {
              CompanyDB: sapConfig.CompanyDB,
              Password: sapConfig.Password,
              UserName: sapConfig.UserName
            }
          }
        };
        this.apiService.post('/api/sap_connects/sapLogin', payload)
          .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && res.results.body) {
              const session = JSON.parse(res.results.body)['SessionId'].toString();
              const update_vals = {};
              if (row.request_type.toLowerCase() === 'cash_to_credit') {
                update_vals['U_Mode'] = 'credit';
              }
              if (row.request_type.toLowerCase() === 'credit_to_cash') {
                update_vals['U_Mode'] = 'cash';
              }
              if (row.request_type.toLowerCase() === 'program_type') {
                update_vals['GroupCode'] = JSON.parse(row.new_val)['value'];
              }
              if (row.request_type.toLowerCase() === 'tax_details') {
                if (row.country_id === 105) {
                  update_vals['U_GSTIN'] = JSON.parse(row.new_val)['gst'];
                }
                update_vals['U_Addrss1'] = this.gstnData.street;
                update_vals['U_Addrss2'] = this.gstnData.street2;
                let gst_type;
                if (this.gstnData.gst_id) {
                  gst_type = 'gstRegularTDSISD';
                } else {
                  gst_type = '';
                }
                update_vals['BPAddresses'] = [];
                if (this.gstnData.bill_addrss === 'GSTIN Billing') {
                  const addr = {
                    AddressName: this.gstnData.gst_name.substring(0, 50),
                    Street: this.gstnData.gst_street.substring(0, 50),
                    ZipCode: this.gstnData.gst_zip,
                    City: '',
                    Country: row.country,
                    State: row.state_code,
                    AddressType: 'bo_BillTo',
                    BPCode: row.code,
                    County: '',
                    Block: '',
                    FederalTaxID: '',
                    BuildingFloorRoom: '',
                    AddressName2: '',
                    AddressName3: '',
                    TypeOfAddress: '',
                    StreetNo: this.gstnData.gst_name,
                    RowNum: 0,
                    GlobalLocationNumber: '',
                    Nationality: '',
                    TaxOffice: ''
                  };
                  if (row.country_id === 105) {
                    addr['GSTIN'] = this.gstnData.gst_id;
                    addr['GstType'] = gst_type;
                  }
                  update_vals['BPAddresses'].push(addr);
                } else {
                  const addr = {
                    AddressName: this.gstnData.name.substring(0, 50),
                    Street: this.gstnData.street.substring(0, 50),
                    ZipCode: this.gstnData.zip,
                    City: '',
                    Country: row.country,
                    State: row.state_code,
                    AddressType: 'bo_BillTo',
                    BPCode: row.code,
                    County: '',
                    Block: '',
                    FederalTaxID: '',
                    BuildingFloorRoom: '',
                    AddressName2: '',
                    AddressName3: '',
                    TypeOfAddress: '',
                    StreetNo: this.gstnData.name,
                    RowNum: 0,
                    GlobalLocationNumber: '',
                    Nationality: '',
                    TaxOffice: ''
                  };
                  if (row.country_id === 105) {
                    addr['GSTIN'] = this.gstnData.gst_id;
                    addr['GstType'] = gst_type;
                  }
                  update_vals['BPAddresses'].push(addr);
                }
                if (this.gstnData.ship_addrss === 'GSTIN Billing') {
                  const addr = {
                    AddressName: this.gstnData.gst_name.substring(0, 50),
                    Street: this.gstnData.gst_street.substring(0, 50),
                    ZipCode: this.gstnData.gst_zip,
                    City: '',
                    Country: row.country,
                    State: row.state_code,
                    AddressType: 'bo_ShipTo',
                    BPCode: row.code,
                    County: '',
                    Block: '',
                    FederalTaxID: '',
                    BuildingFloorRoom: '',
                    AddressName2: '',
                    AddressName3: '',
                    TypeOfAddress: '',
                    StreetNo: this.gstnData.gst_name,
                    RowNum: 0,
                    GlobalLocationNumber: '',
                    Nationality: '',
                    TaxOffice: ''
                  };
                  if (row.country_id === 105) {
                    addr['GSTIN'] = this.gstnData.gst_id;
                    addr['GstType'] = gst_type;
                  }
                  update_vals['BPAddresses'].push(addr);
                } else {
                  const addr = {
                    AddressName: this.gstnData.name.substring(0, 50),
                    Street: this.gstnData.street.substring(0, 50),
                    ZipCode: this.gstnData.zip,
                    City: '',
                    Country: row.country,
                    State: row.state_code,
                    AddressType: 'bo_ShipTo',
                    BPCode: row.code,
                    County: '',
                    Block: '',
                    FederalTaxID: '',
                    BuildingFloorRoom: '',
                    AddressName2: '',
                    AddressName3: '',
                    TypeOfAddress: '',
                    StreetNo: this.gstnData.name,
                    RowNum: 0,
                    GlobalLocationNumber: '',
                    Nationality: '',
                    TaxOffice: ''
                  };
                  if (row.country_id === 105) {
                    addr['GSTIN'] = this.gstnData.gst_id;
                    addr['GstType'] = gst_type;
                  }
                  update_vals['BPAddresses'].push(addr);
                }
                if (row.country_id !== 105) {
                  update_vals['BPFiscalTaxIDCollection'] = {
                    Address: "",
                    CNAECode: null,
                    TaxId0: "",
                    TaxId1: "",
                    TaxId2: this.gstnData.gst_id,
                    TaxId3: "",
                    TaxId4: "",
                    TaxId5: "",
                    TaxId6: "",
                    TaxId7: "",
                    TaxId8: "",
                    TaxId9: "",
                    TaxId10: "",
                    TaxId11: "",
                    BPCode: row.code,
                    AddrType: "bo_ShipTo",
                    TaxId12: null,
                    TaxId13: ""
                  };
                } else {
                  if (this.gstnData.pan !== '') {
                    update_vals['BPFiscalTaxIDCollection'] = {
                      Address: "",
                      CNAECode: null,
                      TaxId0: this.gstnData.pan,
                      TaxId1: "",
                      TaxId2: "",
                      TaxId3: "",
                      TaxId4: "",
                      TaxId5: "",
                      TaxId6: "",
                      TaxId7: "",
                      TaxId8: "",
                      TaxId9: "",
                      TaxId10: "",
                      TaxId11: "",
                      BPCode: row.code,
                      AddrType: "bo_ShipTo",
                      TaxId12: null,
                      TaxId13: ""
                    };
                  }
                }
              }
              if (row.request_type.toLowerCase() === 'contact_details') {
                if (JSON.parse(row.new_val)['phone'] && JSON.parse(row.new_val)['phone'] !== "") {
                  update_vals['Phone1'] = JSON.parse(row.new_val)['phone'];
                }
                if (JSON.parse(row.new_val)['email'] && JSON.parse(row.new_val)['email'] !== "") {
                  update_vals['EmailAddress'] = JSON.parse(row.new_val)['email'];
                }
                if (JSON.parse(row.new_val)['outlet_name'] && JSON.parse(row.new_val)['outlet_name'] !== "") {
                  update_vals['CardName'] = JSON.parse(row.new_val)['outlet_name'];
                }
              }
              if (row.request_type.toLowerCase() === 'classification') {
                if (JSON.parse(row.new_val).hasOwnProperty('outlet_type')) {
                  update_vals['U_OutletType'] = JSON.parse(row.new_val)['outlet_type']['value'];
                  update_vals['U_Type'] = JSON.parse(row.new_val)['outlet_type']['value'];
                }
              }
              if (row.request_type.toLowerCase() === 'location_with_address') {
                update_vals['U_Zone'] = JSON.parse(row.new_val)['zone'];
                update_vals['U_Addrss1'] = JSON.parse(row.new_val)['street1'];
                update_vals['U_Addrss2'] = JSON.parse(row.new_val)['street2'];
              }
              const userData = {
                access_token: localStorage.getItem('resfreshToken'),
                data: {
                  outlet_code: row.code,
                  sessionId: session,
                  db: sapConfig.CompanyDB,
                  payload: update_vals,
                  url: sapConfig.UpdateBp
                }
              };
              this.apiService.post('/api/sap_connects/sapUpdatePartner', userData)
                .subscribe(res1 => {
                  console.log(res1);
                  if (res1.hasOwnProperty('error')) {
                    this.toastr.error(res1.error);
                  } else if (res1.hasOwnProperty('results')) {
                    if (res1.results.hasOwnProperty('error')) {
                      if (res1.results.error.hasOwnProperty("message") && res1.results.error.message.hasOwnProperty("value")) {
                        this.toastr.error(res1.results.error.message.value);
                      } else {
                        this.toastr.error(res1.results.error);
                      }
                    } else {
                      if (row.request_type.toLowerCase() === 'tax_details') {
                        this.updateTaxDetails(row, state);
                      } else {
                        this.updateChangeRequest(row, state);
                      }
                    }
                  } else {
                    this.toastr.error('Outlet Update Push Failed.!!! ');
                  }
                }, error => {
                  this.toastr.error('Outlet Update Push Failed.!!! ', error);
                });
            } else {
              this.toastr.error('Authentication Error ');
            }
          }, error => {
            this.toastr.error('Authentication Error ', error.error.error.message);
          });
      } else {
        this.updateChangeRequest(row, state);
      }
    } else {
      this.updateChangeRequest(row, state);
    }
  }

  updateChangeRequest(row, state) {
    let apiurl = '';
    if (this.enableMarkReview) {
      apiurl = '/api/outlet_change_requests/changeRequestMarkReview';
    } else {
      apiurl = '/api/outlet_change_requests/updateChangeRequest';
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = row.oc_id;
    paramObject.state = state;
    paramObject.comments = '';
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res.result.success)) {
            this.toastr.success(state.toUpperCase() + ' Successfully');
            //window.location.reload();
            this.loadServerData();
          } else {
            this.toastr.error('Error updating Change Request');
          }
        },
        error => {
          console.log(error);
          if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error')) {
            this.toastr.error(error.error.error.message);
          } else {
            this.toastr.error('Error updating Change Request');
          }
        });
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.cr_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadexcel() {
    /*  $('popupModal').modal('hide');*/
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    this.cr_data = [];
    this.succcessCount = 0;
    this.failedCount = 0;
    let task_status = "";
    let unauthorised_access = false;
    const paramObject: any = {};
    let obj = {};
    let invalid = false;
    let err_msg = '';
    const numcharPattern = /^([a-z0-9]+)$/i;
    const charspacePattern = /^[A-Za-z ]+$/;
    const floatPattern = /^\d*\.?\d*$/;
    const numPattern = /^[0-9]*$/;
    paramObject.access_token = this.userData.access_token;
    for (const item of this.upload_task_data) {
      invalid = false;
      if (item['Outlet Code'] === null || item['Outlet Code'] === undefined || item['Outlet Code'] === "") {
        invalid = true;
        err_msg = 'Outlet Code cannot be empty';
        break;
      } else if (!numcharPattern.test(item['Outlet Code'])) {
        invalid = true;
        err_msg = 'Invalid Outlet Code - ' + item.Invoiceno + ' - [a-z0-9]';
        break;
      } else if (item['CR type'] === null || item['CR type'] === undefined || item['CR type'] === "") {
        invalid = true;
        err_msg = 'CR type cannot be empty';
        break;
      } else if (!charspacePattern.test(item['CR type'])) {
        invalid = true;
        err_msg = 'Invalid CR type - ' + item.Invoiceno + ' - [a-zA-Z]';
        break;
      } else if (item['New Value'] === null || item['New Value'] === undefined || item['New Value'] === "") {
        if (item['CR type'] === 'Request Outlet Closure' ||
          item['CR type'] === 'Credit to Cash' || item['CR type'] === 'Cash to Credit') {
        } else {
          invalid = true;
          err_msg = 'New Value cannot be empty';
          break;
        }
      } else if (item['CR type'] === 'Cash to Credit' && (!floatPattern.test(item['New Value']))) {
        invalid = true;
        err_msg = 'Cash to Credit value must be a Number';
        break;
      } else if (item['CR type'] === 'Is Verified') {
        if (item['New Value'].toString().toLowerCase() !== 'false' && item['New Value'].toString().toLowerCase() !== 'true') {
          invalid = true;
          err_msg = 'New Value must be true or false';
        }
      }
      if (!invalid) {
        item['CR type'] = item['CR type'].trim();
        if (item['Type'] === null || item['Type'] === undefined || item['Type'] === '') {
          item['Type'] = 'retailer';
        } else {
          item['Type'] = item['Type'].toString().toLowerCase().trim();
        }
        let type = item['CR type'];
        if (type === 'Change Pricelist') {
          type = 'pricelist';
        } else if (type === 'Pre Order') {
          type = 'preorder';
        } else if (type === 'Request Outlet Closure') {
          type = 'closure';
        } else if (type === 'GST') {
          type = 'tax_details';
        } else {
          type = item['CR type'].toString().toLowerCase().replace(/ /g, "_");
        }

        let newval = '';
        if (type === 'class') {
          newval = item['New Value'].toString().toLowerCase();
        } else {
          newval = item['New Value'];
        }
        obj = {
          outlet_code: item['Outlet Code'],
          cr_type: type,
          new_val: newval,
          partner_type: item['Type']
        };
        this.cr_data.push(obj);
      }
    }
    let upload_task;
    if (this.cr_data.length > 0) {
      const responseData: any = [];
      this.failedCount = this.upload_task_data.length - this.cr_data.length;
      do {
        upload_task = this.cr_data.splice(1);
        paramObject.change_req = this.cr_data[0];
        const resp = await this.apiService.uploadChangeRequest(paramObject);
        if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
          this.succcessCount += this.cr_data.length;
          if (resp['results'].data !== null && resp['results'].data.length > 0) {
            responseData.push(resp['results'].data[0]);
          }
        } else if (resp.hasOwnProperty('results') && resp['results'].statusCode === 199) {
          this.toastr.warning('Unauthorised Access');
          task_status = resp['results'].msg;
          unauthorised_access = true;
          break;
        } else {
          if (resp.hasOwnProperty('results')) {
            this.toastr.error(resp['results'].msg);
            task_status = resp['results'].msg;
          }
          this.failedCount += 1;
        }
        this.cr_data = upload_task;
      } while (this.cr_data.length > 0);
      if (unauthorised_access) {
        this.uploadCaption = [task_status];
      } else {
        this.uploadCaption = ['Uploading completed, Successes: ' + this.succcessCount + ',Failures: ' + this.failedCount, 0];
      }
      this.uploading = false;
      this.upload_loader = false;
      this.cr_data = [];
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.fileUploaded = null;
      if (responseData !== null && responseData.length > 0 && this.enableApprove) {
        this.processResponseData(responseData);
      }
    } else {
      this.uploading = false;
      this.upload_loader = false;
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.fileUploaded = null;
      this.toastr.error('Invalid Data - ' + err_msg);
    }
  }

  processResponseData(data) {
    const request_ids = [];
    for (const i in data) {
      if (data[i].request_type !== 'tax_details') {
        request_ids.push(data[i].id);
      }
    }
    if (request_ids !== null && request_ids.length > 0) {
      let apiurl = '';
      if (this.enableMarkReview) {
        apiurl = '/api/outlet_change_requests/changeRequestMarkReview';
      } else {
        apiurl = '/api/outlet_change_requests/updateChangeRequest';
      }
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      paramObject.state = 'approved';
      paramObject.comments = '';
      paramObject.sapVerifyTypes = this.sapVerifyTypes;

      const title = 'Approval in process';

      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "410px",
        maxHeight: "250px",
        /* disableClose: true,*/
        data: {
          title,
          user_data: paramObject,
          API_URL: apiurl,
          data: request_ids,
          dataname: "id",
          batchSize: 1,
          rowData: data,
          type: 'changerequest'
        }
      };

      const confirmdiaeref = this.dialog.open(ProgressPopupComponent, config);

      confirmdiaeref.componentInstance.progressStatus.subscribe(data => {
        if (data.err !== null && data.err !== '') {
          this.toastr.error(data.err);
        } else if (data.successCount === 0) {
          this.toastr.error('Error updating change request');
        } else if (data.failedCount > 0) {
          this.toastr.warning('Success : ' + data.successCount + "Failed : " + data.failedCount);
        } else {
          this.toastr.success("Approved" + " : " + data.successCount + " records");
          //window.location.reload();
          this.loadServerData();
        }
        this.showApprove = false;
      });
    } else {
      // this.loadServerData();
    }

  }

  openGSTINVerify(row, state) {
    const data = row;
    data.gst_id = JSON.parse(row.new_val)['gst'];
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "50%",
        maxHeight: "93%",
        panelClass: 'custom-dialog-container',
        data: {
          params: data,
          type: 'edit'
        }
      };
      const creatediaeref = this.dialog.open(GstinVerifyPopupComponent, config);
      creatediaeref.componentInstance.verifyClicked.subscribe(data1 => {
        this.gstnData = data1;
        let status = '';
        if (state === 'REJECT') {
          status = 'rejected';
        } else {
          status = 'approved';
        }
        this.verify(row, status);
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        data: {
          params: data,
          type: 'edit'
        }
      };
      const creatediaeref = this.dialog.open(GstinVerifyPopupComponent, config);
      creatediaeref.componentInstance.verifyClicked.subscribe(data1 => {
        this.gstnData = data1;
        let status = '';
        if (state === 'REJECT') {
          status = 'rejected';
        } else {
          status = 'approved';
        }
        this.verify(row, status);
      });
    }
  }

  updateTaxDetails(row, state) {
    const apiurl = '/api/outlet_change_requests/updateTaxDetails';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.cr_id = row.oc_id;
    paramObject.data = this.gstnData;
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success(state.toUpperCase() + ' Successfully');
            //window.location.reload();

            this.loadServerData();
          } else {
            this.toastr.error('Error updating Change Request');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Change Request');
        });
  }
}
