import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ApiService} from "../../../_services/api.service";

@Component({
  selector: 'app-approve-popup-component',
  templateUrl: './approve-survey-popup.component.html',
  styleUrls: ['./approve-survey-popup.component.scss']

})

export class ApproveSurveyPopupComponent implements OnInit {

  @Output() updateStatusEmitter: EventEmitter<any> = new EventEmitter<any>();

  title;
  updateData: any = {};
  beatActivityData = [];

  constructor(
    public apiService: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
  }

  ngOnInit(): void {

  }

  performAction() {
    this.updateStatusEmitter.emit(this.updateData);
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
