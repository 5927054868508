import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";
import {BulkAddRetailerComponent} from "../../components/bulk_add_retailer/bulk_add_retailer.component";
declare var $: any;
@Component({
  selector: 'app-create-retail-request-popup',
  templateUrl: './create_retail_request_asset_popup.component.html',
  styleUrls: ['./create_retail_request_asset_popup.component.scss']

})

export class CreateRetailRequestPopupComponent implements OnInit {

  @Output() createAssetEvent = new EventEmitter<boolean>();
  assetDetails: any = {};
  state = 'retailer';
  retailerList: any = [];
  selectedRetailerCount = 0;
  assetTypeList: any = [];
  productBrandList: any = [];
  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService) {
    this.getBaseData();
  }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close();
  }

  backClick() {
    if (this.state === 'po') {
      this.state = 'asset';
      this.renderer.removeClass( document.getElementById('li_po'), 'done');
      this.dialogRef.updateSize('650px', '500px');
    } else {
      this.state = 'retailer';
      this.renderer.removeClass( document.getElementById('li_asset'), 'done');
      this.dialogRef.updateSize('650px', '400px');
    }
  }
  nextClick() {
    if (this.state === 'retailer') {
      this.state = 'asset';
      this.renderer.addClass( document.getElementById('li_asset'), 'done');
      this.dialogRef.updateSize('650px', '500px');
    } else {
      this.state = 'po';
      this.renderer.addClass( document.getElementById('li_po'), 'done');
      this.dialogRef.updateSize('650px', '620px');
    }
  }

  bulkAddRetailerPopup() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "90%",
      maxHeight: "93%",
      panelClass: 'custom-dialog-container',
      data: {
        vendor: true
      }
    };

    const creatediaeref = this.dialog.open(BulkAddRetailerComponent, config);

    creatediaeref.componentInstance.selectedOutlets.subscribe(data => {
      this.retailerList = data;
      this.selectedRetailerCount = data.length;
    });
  }

  getBaseData() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      profile_name: 'Vendor'
    };
    this.apiService.post('/api/partner_assets/get_asset_request_data', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.assetTypeList = res.results.data.asset_master;
          if (this.assetTypeList) {
            if (this.assetTypeList.length > 0) {
              this.assetDetails.type = this.assetTypeList[0].id;
            } else {
              this.toastr.error("Asset Types not configured");
              this.closeDialog();
            }
          }
          this.productBrandList = res.results.data.product_brand;
          if (this.productBrandList) {
            if (this.productBrandList.length > 0) {
              this.assetDetails.brand_id = this.productBrandList[0].id;
            } else {
              this.toastr.error("Brands not configured");
              this.closeDialog();
            }
          }
        }
      });
  }

  assetTypeChange(event) {

  }
  assetBrandChange(event) {

  }

  changePODate(dt) {
    this.assetDetails.po_date = this.datePipe.transform(this.assetDetails.po_date, 'yyyy-MM-dd');
  }

  createAssetRequest() {
    const asset = this.assetTypeList.find(x => x.id === this.assetDetails.type).name;
    const brand = this.productBrandList.find(x => x.id === this.assetDetails.brand_id).name;
    const partner_ids = [];
    this.retailerList.forEach(r => {
      partner_ids.push(r.id);
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      name: asset + '-' + brand,
      partner_ids,
      asset_id: this.assetDetails.type,
      partner_type: 'retailer',
      config_json : {
        request_brand: brand,
        po_no: this.assetDetails.po_no,
        po_date: this.assetDetails.po_date,
        po_amount: this.assetDetails.po_amount
      }
    };
    this.apiService.post('/api/partner_assets/create_asset_request', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Asset Created Successfully");
          this.createAssetEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.toastr.error("Failed to Create Asset");
        }
      });
  }
}
