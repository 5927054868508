import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-gstr-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class GstrReportComponent extends ReportBase implements OnInit {

  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'S',
    values: [],
    show: true
  };

  ngOnInit() {
    this.sharedService.setHeader('GSTR Report');
    this.API_URL = '/api/pwa_reports/getGstrReport1';
    this.customFilter.values = [
      {id: "S", itemName: "Secondary"},
      {id: "P", itemName: "Primary"}
    ];
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      minWidth: 250
    };
      }

  configureGrid() {
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "INVOICE DATE", field: "invoice_date", rowGroup: true, hide: true},
      //{headerName: 'INVOICE DATE',field: 'invoice_date',lockPosition: true},
      {headerName: 'CUSTOMER', field: 'cust_name'},
      {headerName: "CODE", field: "cust_code"},
      {headerName: "GSTIN", field: "gstin_uin"},
      {headerName: "INVOICE NO", field: "invoice_no"},
      {headerName: "TAXABLE VALUE", field: "taxable_amount", cellStyle: {textAlign: 'center'},  width: 100}
    ];
    const taxMaster = this.apiData.taxmaster;
    for (const key in taxMaster) {
      this.columnDefs.push({
        headerName: taxMaster[key].description,

        field: taxMaster[key].description,
        cellStyle: {textAlign: 'center'},

        width: 100
      });
    }
    this.columnDefs.push({headerName: "TOTAL TAX", field: "tax_amount", cellStyle: {textAlign: 'center'},  width: 100});
    this.columnDefs.push({headerName: "INVOICE AMOUNT", field: "invoice_amount", cellStyle: {textAlign: 'center'},  width: 100});
    this.columnDefs.push({headerName: "TDS TCS", field: "tds", cellStyle: {textAlign: 'center'},  width: 100});
    this.columnDefs.push({headerName: "INVOICE TYPE", field: "invoice_type",  width: 100});
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }


  configureData() {
    this.rowData = [];
    const taxMaster = this.apiData.taxmaster;
    const salesMaster = this.apiData.sales;
    const taxLines = this.apiData.taxlines;

    for (const key in this.apiData.sales) {

      let indrow = {};
      const taxRow: any = {};
      let salesRow: any = {};
      let taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(tax => tax.id === salesRow.invoice_id);

      for (const key1 in taxMaster) {
        taxRow[taxMaster[key1].description] = taxLine.filter(tax => tax.description === taxMaster[key1].description)[0];
        if (taxRow[taxMaster[key1].description] !== null && taxRow[taxMaster[key1].description] !== undefined) {
          taxRow[taxMaster[key1].description] = taxRow[taxMaster[key1].description].tax_amt;
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

}

