import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-scheme-master',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})


export class SchemeMasterListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Scheme Master');

   /* this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;*/
    this.showDate = false;

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/sale_schemes/getShemeMaster';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'Name', field: 'name', width: 200},
      {headerName: 'Description', field: 'description', width: 200, hide: true},
      {
        headerName: 'Scheme Type', field: 'scheme_type', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.scheme_type === 'qps') {
              return 'Product Scheme';
            } else if (params.data.scheme_type === 'vps') {
              return 'Bill Scheme';
            } else {
              return params.data.scheme_type.toString().toUpperCase();
            }
          }
        },
      },
      {headerName: 'Reward Type', field: 'reward_type', width: 100},
      {
        headerName: 'Start Date', field: 'start_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_date) {
              return inThis.datePipe.transform((new Date(params.data.start_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.start_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'End Date', field: 'end_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_date) {
              return inThis.datePipe.transform((new Date(params.data.end_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.end_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'Type', field: 's_type', width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.s_type === 'S') {
              return 'Secondary';
            } else if (params.data.s_type === 'P') {
              return 'Primary';
            } else {
              return params.data.s_type.toString().toUpperCase();
            }
          }
        }
      }
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('schemeid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/products/schemes/detail'], {
      state: {
        viewtype: 'detail',
        schemeid: rows[0].id
      }
    });
  }


}
