import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {AgmCoreModule, MapsAPILoader} from '@agm/core';
import {ApiService} from "../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {TakeSurveyPopupComponent} from "../../../components/take_survey_popup/take_survey_popup.component";
import {environment} from "../../../../environments/environment";

declare let google: any;

@Component({
  selector: 'app-meet-attendance',
  templateUrl: './meet_attendance.component.html',
  styleUrls: ['./meet_attendance.component.scss']
})

export class MeetAttendanceComponent implements OnInit {

  meetDetails: any = {};
  surveyDetails: any = {};
  lat;
  long;
  distance;
  locationEnabled = false;
  surveyCompleted = false;
  surveyPresent = false;
  markedAttendance = false;
  meet_id;
  attendee_id;
  survey_time;
  access_token;
  survey_id;
  dataLoaded = false;
  sponsors = '';
  hours = 0;
  days = 0;
  map_key;
  company_key;
  logo;
  meetNotStarted = false;
  meetCompleted = false;
  limit_days = 1;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private agmCore: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private toastr: ToastrService) {

    this.access_token = this.route.snapshot.queryParamMap.get('auth');
    this.company_key = this.route.snapshot.queryParamMap.get('ckey');
  }

  ngOnInit(): void {
    if (this.access_token && this.company_key) {
      localStorage.setItem('resfreshToken', this.access_token);
      this.sharedService.setHeader('Meet Attendance');
      this.fetchCompanyDetails();
    } else {
      this.router.navigate([this.apiService.api_url + "/pagenotfound"]);
    }
  }

  fetchCompanyDetails() {
    this.apiService.findCompanyInstance({companyKey: this.company_key})
      .subscribe(async res => {
        if (res.hasOwnProperty('success')) {
          if (res.success.logo_url != null) {
            this.logo = res.success.logo_url;
            localStorage.setItem('company_logo', this.logo);
          }
          localStorage.setItem('private_url', res.success.url);
          if (environment.instanceURL === 'from_login') {
            this.apiService.api_url = localStorage.getItem('private_url');
          } else {
            this.apiService.api_url = environment.instanceURL;
          }
          this.getMeetDetails();
        }
      });
  }

  requestLocation() {
    this.getCurrentLocation();
  }

  getMeetDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/partner_meets/get_invitation_meet_and_attendee_details', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dataLoaded = true;
          this.meetDetails = res.results.data[0];
          if (this.meetDetails && this.meetDetails.attendance) {
            this.markedAttendance = true;
          }
          if (this.meetDetails.status === 'unconfirmed') {
            this.meetNotStarted = true;
          } else {
            this.meetNotStarted = false;
          }
          if (this.meetDetails.status === 'completed') {
            this.meetCompleted = true;
          } else {
            this.meetCompleted = false;
          }
          if (this.meetDetails.map_key) {
            localStorage.setItem('map_key', this.meetDetails.map_key);
            this.sharedService.setApiKey(this.meetDetails.map_key);
          }
          if (this.meetDetails?.pm_config?.meet_config?.limit_days) {
            this.limit_days = this.meetDetails.pm_config.meet_config.limit_days;
          }
          if (this.meetDetails.planned_date) {
            this.hours = (new Date(this.meetDetails.planned_date).getTime() - new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime()) / 36e5;
            this.hours = this.hours + (this.limit_days * 24);
            this.days = parseInt(String(this.hours / 24), 10);
            this.meetDetails.planned_date = this.datePipe.transform((new Date(this.meetDetails.planned_date + ' UTC')).toString(), 'yyyy-MM-dd | h:mm a');
          }
          if (this.meetDetails.sponsor_json && this.meetDetails.sponsor_json.length > 0) {
            this.meetDetails.sponsor_json.forEach(sponsor => {
              this.sponsors = this.sponsors + ', ' + sponsor.name;
            });
            if (this.sponsors) {
              this.sponsors = this.sponsors.substring(1, this.sponsors.length);
            }
          }
          this.mapsAPILoader.load().then(() => {
            this.getCurrentLocation();
          });
        }
      });
  }

  openTakeSurveyPopup(edit) {
    const survey_id = 7111;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "30%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          survey_id,
          service_id: 52,
          edit,
          standalone: true
        }
      };
      const creatediaeref = this.dialog.open(TakeSurveyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        //this.getAssetDetails();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          survey_id,
          service_id: 52,
          /* partner_id: this.serviceDetails.customer_id,
           service_id: this.serviceDetails.id,
           partner_asset_id: this.serviceDetails.partner_asset_id,
           name: this.serviceDetails.serviceno*/
          edit,
          standalone: true
        }
      };
      const creatediaeref = this.dialog.open(TakeSurveyPopupComponent, config);
    }
  }

  closeDialog() {

  }

  markAttended() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      meet_id: this.meet_id,
      attendee_id: this.attendee_id,
      verification: {
        att_ver: true,
        lat: this.lat,
        lng: this.long,
        survey_id: this.survey_id
      }
    };
    if (this.survey_time) {
      paramObject.data.verification['survey_time'] = this.survey_time;
    }
    this.apiService.post('/api/meet_attendees/mark_meet_attendance', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.markedAttendance = true;
          this.toastr.success("Marked Attendance Successfully");
        } else {
          this.toastr.error("Failed To Mark Attendance");
        }
      });
  }

  getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationEnabled = true;
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        console.log (position.coords);
        this.computeDistance();
      });
    }
  }

  computeDistance() {
    if (this.lat && this.long && this.meetDetails.lat && this.meetDetails.lng) {
      const start = new google.maps.LatLng(this.lat, this.long);
      const end = new google.maps.LatLng(parseFloat(this.meetDetails.lat), parseFloat(this.meetDetails.lng));

      let distance = google.maps.geometry.spherical.computeDistanceBetween(start, end);
      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: start,
        destination: end,
        waypoints: [],
        travelMode: 'DRIVING'
      };
      const inThis = this;
      directionsService.route(request, (response, status) => {
        if (status === 'OK') {
          if (response.hasOwnProperty('routes') && response.routes.length > 0) {
            if (response.routes[0].hasOwnProperty('legs') && response.routes[0].legs.length > 0) {
              distance = 0;
              for (let j = 0; j < response.routes[0].legs.length; j++) {
                distance += response.routes[0].legs[j].distance.value;
              }
              // distance = response.routes[0].legs[0].distance.value;
              inThis.handleDistance(distance);
            } else {
              inThis.handleDistance(distance);
            }
          } else {
            inThis.handleDistance(distance);
          }
        } else {
          inThis.handleDistance(distance);
        }
      });
    }
  }

  handleDistance(dist) {
    if (dist !== null) {
      /* distance = distance / 1000;
       distance = Math.round(distance * 100) / 100;*/
      this.distance = dist;
      console.log(dist);
    }
  }

  openMapsinNewTab() {
    if (this.lat && this.long && this.meetDetails.lat && this.meetDetails.lng) {
      const url = 'http://www.google.com/maps/dir/' + this.lat + ',' + this.long + '/'
        + this.meetDetails.lat + ',' + this.meetDetails.lng;
      window.open(url, '_blank');
    }
  }
}
