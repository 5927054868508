import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef, AfterContentChecked
} from '@angular/core';
import { ApiService} from '../../_services/api.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import {SharedService} from '../../_services/shared_service';
import {error} from '@angular/compiler/src/util';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {AppComponent} from "../../app.component";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import { NgSelectComponent } from '@ng-select/ng-select';

export abstract class New_order_base implements OnInit {
  @ViewChild('dispatchDiv') dispatchDiv: ElementRef<HTMLElement>;
  @ViewChild('showCustomer') showCustomer: ElementRef<HTMLElement>;
  @ViewChild('serial') serial: ElementRef<HTMLElement>;
  @ViewChild('serialIn') serialIn: ElementRef<HTMLElement>;
  @ViewChild('cust') cust: ElementRef<HTMLElement>;
  @ViewChild('imageShow') imageShow: ElementRef<HTMLElement>;
  @ViewChild('qty', { read: ElementRef }) qtyElement: ElementRef;
  @ViewChild('productSelector') productSelector: NgSelectComponent;

  protected gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  public loaded = false;
  public priceEdited = false;
  public uomEdited = false;
  public confirmSerial;
  public custDisable = true;
  public searchValue;
  public preloader1 = false;
  public emptyState = false;
  public data;
  public dateDisable = true;
  public gridApi1;
  private gridColumnApi1;
  public gridOptions1: GridOptions;
  public loaded1 = false;
  public preloader2 = false;
  public emptyState1 = false;
  public data1;
  public makeReturn;
  public rowData = [];
  public rowData1 = [];
  public preloader = false;
  public myDate;
  public newOrder = false;
  public receiveOrder = false;
  public receiveTitle = '';
  public dispatchTitle = '';
  public title = '';
  public save;
  public receive;
  public dispatch;
  public edit;
  public confirm;
  public poDate;
  public beatList;
  public userList;
  public transList;
  public selectedBeat;
  public selectedUser;
  public selectedTrans;
  public outList;
  public selectedOut;
  public selectedProd;
  public beatId: any;
  public beatName: any;
  public userId: any;
  public userName: any;
  public userCode: any;
  public outlets;
  public orderNo = null;
  public po_number = null;
  public customerMail = null;
  public note = null;
  public customerTax = null;
  public customerAddress = null;
  public supplierAddress = null;
  public addrssList = [];
  public suppaddrssList = [];
  public partnerInvId = null;
  public partnerShippId = null;
  public supplierTax = null;
  public addrssSel;
  public addrssSel2;
  public prodShow = false;
  public addProd = true;
  public supplierProducts = [];
  public productId = null;
  public categoryId = null;
  public brandId = null;
  public productName = null;
  public uomName = null;
  public uomId = null;
  public stock = 0;
  public real_stock = 0;
  public real_supp_stock = 0;
  public supp_stock = 0;
  public tax = null;
  public dueDate = null;
  public supplierName  = null;
  public supplierId  = null;
  public territoryId = null;
  public dStatus = null;
  public invoiceNo;
  public retInvoiceNo;
  public serno1;
  public count = 1;
  public sernos = {};
  public lineId;
  public beats = [];
  public pricelist = null;
  public pricelistId = 1;
  public unitPrice = 0;
  public salePrice = 0;
  public margin = 0;
  public customerName = null;
  public customerId = null;
  public lineData = [];
  public taxmasterId;
  public orderdetails;
  public beatChange;
  public userChange;
  public outChange;
  public prodList;
  public prodChange;
  public columnDefs;
  public columnDefs1;
  public dmsStatus = null;
  public invStatus = null;
  public invEnable = false;
  public returnEnable = false;
  public updatedInvoice = false;
  public updateOrder;
  public markDelivery;
  public markDispatch;
  public cancel;
  public primary = true;
  public trackRef;
  public comments = 'Order Created From PWA';
  public transporter;
  public transType;
  public bundle;
  public weight;
  public markReceive;
  public transChange;
  public transpID;
  public typeList;
  public selectedType;
  public typeChange;
  public uomList;
  public uoms;
  public taxName;
  public taxSource;
  public factorInv;
  public uomType;
  public discard;
  public roleFeatures: any;
  public dmsConfirm: boolean;
  public page = 'DMS';
  public custShow;
  public orderDiscount = 0;
  public factor;
  public serl_arr: any;
  public line_id: any;
  public removedRows = [];
  public role_features = [];
  public custSupp;
  public returnDisable = false;
  public priceEdit = false;
  public supplierList = [];
  public suppEnable = false;
  public selectedSupp;
  public suppChange;
  public custRefresh = true;
  public stockCheck = false;
  public supplierStock = false;
  public orderDate;
  public displayOrdDate;
  public confirmationOrdDate;
  public displayInvDate;
  public supplierCode;
  public customerCode;
  public showAct = false;
  public shipaddrssChange;
  public cnfLabel = 'Mark Dispatch';
  public cnfLabel1 = 'Dispatch & Receive';
  damagePhoto = null;
  returnphotodata = null;
  photo = null;
  public featureReceive = false;
  public featureDelivery = false;
  public featureDispatch = false;
  public featureConfirm = false;
  public featurePrimaryDispatch = false;
  public featurePrimaryConfirm = false;
  public enableAutoComplete = false;
  public math = Math;
  shipAddrCode = '';
  shipAddrName = '';
  invAddrCode = '';
  invAddrName = '';
  showCardRate = false;
  showCardRateDiscount = false;
  showWeight = false;
  showPlant = false;
  showIncoTerms = false;
  plant = '';
  inco_term = '';
  totalWeight;
  totalMeasure;
  orderData;
  rowIndex;
  displayStatus;
  invoiceDate;
  activityJson;
  featureReuse;

  constructor(protected apiService: ApiService,
              public router: Router,
              public route: ActivatedRoute,
              protected elRef: ElementRef,
              private renderer: Renderer2,
              protected datePipe: DatePipe,
              public sharedService: SharedService,
              public appComp: AppComponent,
              public toastr: ToastrService,
              public domSanitizer: DomSanitizer,
              private componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef ) {

    this.orderData = JSON.parse(localStorage.getItem('orderdata'));
    if (this.orderData === undefined || this.orderData === null) {
      this.orderData = [];
    }
    this.rowIndex = parseInt(localStorage.getItem('orderindex'), 0);
   // this.sharedService.dmsParams.order_id = this.orderData[this.rowIndex];
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.poDate = this.myDate;
    this.appComp.header_title = localStorage.getItem('page_header');
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_date_enabled' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.dateDisable = false;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_reuse' === existed.name;
    });
    if (this.role_features.length > 0) {
      this.featureReuse = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_price_edit' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.priceEdit = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_stock_check' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.stockCheck = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_supplier_stock' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.supplierStock = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_confirm' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureConfirm = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_confirm' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featurePrimaryConfirm = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_dispatch' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureDispatch = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_dispatch' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featurePrimaryDispatch = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_delivery' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureDelivery = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_primary_receive' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.featureReceive = true;
    }
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_auto_complete_order' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.enableAutoComplete = true;
    }
    this.columnDefs1 = [
      { headerName: 'CUSTOMER', field: 'name' , sortable: true, filter : true, resizable: true, lockPosition: true,
        checkboxSelection: false},
      { headerName: 'CODE', field : 'code', sortable: true, filter : true, resizable: true, lockPosition: true},
      { headerName: 'BEAT', field: 'beat', sortable: true, filter : true, resizable: true, lockPosition: true,
        valueGetter(params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp))];
            return beats.join();
          } else {
            return params.data.beat;
          }
        }},
      { headerName: 'id', field: 'id', hide: true},
      { headerName: 'beat_id', field: 'beat_id', hide: true,
        valueGetter: function (params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp_id))];
            return JSON.stringify(beats);
          } else {
            return params.data.beat;
          }
        }},
      { headerName: 'supplier', field: 'supplier', hide: true},
      { headerName: 'user_id', field: 'user_id', hide: true},
      { headerName: 'user', field: 'user', hide: true},
      { headerName: 'email', hide: true},
      { headerName: 'gst_id', hide: true},
      { headerName: 'tax_source', hide: true},
      { headerName: 'pricelist_id', hide: true},
      { headerName: 'pricelist', hide: true},
      { headerName: 'label', hide: true},
      { headerName: 'cust_supp', hide: true}
    ];
    this.gridOptions = {
      rowHeight: 50,
      headerHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark'],
      defaultColDef: {
        menuTabs: []
      }
    } as GridOptions;
    this.gridOptions1 = {
      rowHeight: 40,
      headerHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      getRowStyle: this.getRowStyleFunc
    } as GridOptions;

    this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_order_confirm' === existed.name;
    });

    if (this.roleFeatures.length > 0) {
      this.dmsConfirm = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_show_actions' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.showAct = true;
    }
    let labels = JSON.parse(localStorage.getItem('custom_labels'));
    let update = labels.filter(function (existed) {
      return existed.label === 'confirmLabel';
    });

    if (update.length > 0) {
      this.cnfLabel = update[0].display;
    }

    update = labels.filter(function (existed) {
      return existed.label === 'confirmLabelPrimary';
    });

    if (update.length > 0) {
      this.cnfLabel1 = update[0].display;
    }
  }

  getRowStyleFunc(params) {
    if (params.data.label === 'supplier') {
      return {
        color: 'Brown'
      };
    }
  }

  close() {
    this.custDisable = true;
    this.gridOptions1.api.setFilterModel(null);
  }

  ngOnInit() {}

  changeDate(dt) {
    this.poDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
  }

  dispatchWizard() {
    this.transList =  JSON.parse(localStorage.getItem('all_transp'));
    this.typeList = [{itemName: 'local'}, {itemName: 'international'}];
    const el: HTMLElement = this.dispatchDiv.nativeElement;
    el.click();
  }

  quickSearch() {
    this.gridApi1.setQuickFilter(this.searchValue);
  }

  refreshCust() {
    this.custRefresh = false;
    const inData2 = {
      access_token: localStorage.getItem('resfreshToken'),
      data: {
        start_date: null,
        end_date: null,
        territory_ids: [],
        showAll: false
      }
    };
    if (localStorage.getItem('feature_show_all_outlets') === 'true') {
      inData2.data.showAll = true;
    }
    this.apiService.post('/api/audit_masters/get_customers', inData2)
      .subscribe(resp => {
        const inData3 = {
          access_token: localStorage.getItem('resfreshToken'),
          supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
          // url: this.url
        };
        this.apiService.post('/api/dms_connects/get_dms_retailers', inData3)
          .subscribe(resp1 => {
            this.rowData1 = resp['results'].data.concat(resp1['results'].data);
            this.gridApi1.setRowData(this.rowData1);
            this.custRefresh = true;
            localStorage.setItem('customers_list', JSON.stringify(resp['results'].data.concat(resp1['results'].data)));
            localStorage.setItem('dist_list', JSON.stringify(resp1['results'].data));
          });
      }, error => {
        console.log(error);
        this.custRefresh = true;
      });
    // const inData2 = {
    //   access_token: localStorage.getItem('resfreshToken'),
    //   supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
    // };
    // this.apiService.getRetailers(inData2)
    //   .subscribe(resp => {
    //     console.log(resp);
    //     localStorage.setItem('customers_list', JSON.stringify(resp['results'].data));
    //     this.rowData1 = resp['results'].data;
    //     this.gridApi1.setRowData(this.rowData1);
    //     this.custRefresh = true;
    //   }, error => {
    //     console.log(error);
    //     this.custRefresh = true;
    //   });
  }

  clickPhoto() {

    let el: HTMLElement = this.imageShow.nativeElement;
    el.click();
  }

  handleFileSelect(evt){
    //console.log(evt);
    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      let reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.returnphotodata = base64textString;
    this.damagePhoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
  }

  onGridReady(params) {

    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
  }

  onGridReady1(params) {

    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs1);
    params.api.setRowData(this.rowData1);
    this.preloader2 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    this.gridOptions.api.sizeColumnsToFit();
  }

  removeProduct(index) {

    const del = this.rowData.splice(index, 1);
    this.removedRows.push(del[0]);
    this.gridApi.setRowData(this.rowData);
  }
  fetchPreviousData() {
  }
  fetchNextData() {
  }
}
