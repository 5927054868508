import {AfterViewInit, Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {AGGridEditButtonRendererComponent} from "../users/AGGridEditButtonRendererComponent";


@Component({
  selector: 'app-product-stock-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class StockListComponent extends PageBase implements OnInit, IPage, AfterViewInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Distributor',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  ngOnInit(): void {
    this.sharedService.setHeader('Product Stock');
    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };
    this.customFilter.values = [
      {id: 0, itemName: 'All'}
    ];
    this.configureGrid();
    this.showDate = false;
    if (!localStorage.getItem('dist_list')) {
      this.fetchDistributors();
    } else {
      const distlist = JSON.parse(localStorage.getItem('dist_list'));
      distlist.forEach(dist => {
        this.customFilter.values.push({id: dist.id, itemName: dist.name});
      });
      this.loadServerData();
    }
  }

  ngAfterViewInit() {
    document.getElementById('custfilter').style.width = '200px';
  }

  fetchDistributors() {
    const inData3 = {
      access_token: localStorage.getItem('resfreshToken'),
      supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
      // url: this.url
    };
    this.apiService.post('/api/dms_connects/get_dms_retailers', inData3)
      .subscribe(resp1 => {
        if (resp1['results'].data) {
          localStorage.setItem('dist_list', JSON.stringify(resp1['results'].data));
          resp1['results'].data.forEach(dist => {
            this.customFilter.values.push({id: dist.id, itemName: dist.name});
          });
        }
      });
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/get_distributor_live_stock';
    const inthis = this;
    this.columnDefs = [
      {headerName: 'Territory Code', field: 'territory'},
      {headerName: 'Territory', field: 'code'},
      {headerName: 'Product ID', field: 'prod_id'},
      {headerName: 'Product Code', field: 'default_code'},
      {headerName: 'Product Name', field: 'name', width: 250},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Brand', field: 'brand'},
      {headerName: 'UOM', field: 'uom'},
      {headerName: 'Cost Price', field: 'cost_price', cellStyle: {textAlign: 'right'}},
      {headerName: 'Live Stock', field: 'stock', cellStyle: {textAlign: 'right'}},
      {
        headerName: 'Stock Value', field: 'stock', cellStyle: {textAlign: 'right'},
        valueGetter: params => {
          if (params.data) {
            if (params.data.cost_price) {
              return (parseFloat(params.data.stock) * parseFloat(params.data.cost_price)).toFixed(inthis.apiService.decimalPolicy);
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'Cost History', field: 'cost_history', menuTabs: [], cellStyle: {textAlign: 'center'},
        cellRenderer(params) {
          if (params.data) {
            return '<div><button class="btn blue-btn" style="padding: 2px 18px; font-size: 83%">VIEW</button></div>';
          }
        }
      },
      {
        headerName: 'Stock Log', field: 'stock_log', menuTabs: [], cellStyle: {textAlign: 'center'},
        cellRenderer(params) {
          if (params.data) {
            return '<div><button class="btn confirm-green-btn" style="padding: 2px 18px; font-size: 83%">VIEW</button></div>';
          }
        }
      },
      {headerName: 'distributor_id', field: 'dist_id', hide: true},
      {headerName: 'product_id', field: 'product_id', hide: true},
      {headerName: 'territory_id', field: 'territory_id', hide: true}
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'cost_history') {
      this.goToLogs('cost', event.data);
    } else if (column === 'stock_log') {
      this.goToLogs('stock', event.data);
    }
  }

  goToLogs(value, params) {
    this.sharedService.logData.distributor_id = params.dist_id;
    this.sharedService.logData.product_id = params.prod_id;
    this.sharedService.logData.territory_id = params.territory_id;

    if (value === 'stock') {
      this.router.navigate([this.apiService.redirect_url + "/stock_log_entry"]);
    } else if (value === 'cost') {
      this.router.navigate([this.apiService.redirect_url + "/cost_log_entry"]);
    }
  }

  showUploadPopup() {

    const tableData = [
      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H' , 'I'],
      ['Product Code', 'Product Name', 'Current Stock', 'Pack', 'Unit(s)', 'Pack Name', 'Pack Size', 'New Stock', 'Reason for Deviation'],
      ['PC001', 'Water bottle- 500 ml', '100', '4', '10', 'Case', '100', '110', 'Incorrect data recorded']
    ];
    const sampleData2 = [
      {
        CATEGORY: '',
        BRAND: '',
        'FAMILY CODE': '',
        'FAMILY NAME': '',
        'FAMILY SEQ': '',
        'SKU CODE': '',
        'SKU NAME': '',
        'SKU SEQ': '',
        Description: '',
        UOM: '',
        'UOM CATEGORY': '',
        PTD: '',
        PTR: '',
        PRIORITY: '',
        ACTIVE: '',
        GST: '',
        VAT: '',
        CESS: '',
        EXCISE: '',
      }
    ];
   /* if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "85vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    }*/
  }

}
