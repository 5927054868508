import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-tl-cluster-level-rollup-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ActivitiesPerformanceReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  outletTypes: any = [];
  activityTypes: any = [];
  kpitypes: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Activities and Performance Report');

    this.showableFilters.region = true;
    this.showableFilters.state = true;
    this.showableFilters.team = true;

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/get_activities_performance';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Salesman',
      field: 'salesman',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
      pinned: 'left'
    };


  }

  configureColumns() {
    this.columnDefs = [];
    this.columnDefs = [

      {field: 'region', headerName: 'Region', rowGroup: true, enableRowGroup: true, hide: true},
      {field: 'team', headerName: 'Team', rowGroup: true, enableRowGroup: true, hide: true},
      {field: 'salesman', pinned: 'left'},
      {
        headerName: '',
        children: [
          {
            headerName: 'EMP CODE',

            field: 'emp_code',

            filter: true,
            menuTabs: [],
            width: 80,
            resizable: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'ROLE',

            field: 'role',

            filter: true,
            menuTabs: [],
            width: 80,
            resizable: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'BEATS',

            field: 'beat_count',

            filter: true,
            aggFunc: 'sum',
            menuTabs: [],
            width: 80,
            resizable: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'RETAILERS',

            field: 'retailer_count',

            filter: true,
            aggFunc: 'sum',
            menuTabs: [],
            width: 80,
            resizable: true,
            cellStyle: {textAlign: 'center'}
          }
        ]
      },
      {
        headerName: 'CHANNEL',

        children: []
      }
    ];

    this.outletTypes = this.apiData.outlet_type.map(p => p.outlet_type)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    for (var key in this.outletTypes) {
      this.columnDefs[4].children.push({
        headerName: this.outletTypes[key],
        field: this.outletTypes[key] + '_count',
        menuTabs: [],
        width: 100,
        cellStyle: {textAlign: 'center'},
        aggFunc: 'sum'
      });

    }

    this.activityTypes = this.apiData.activities.map(p => p.action_type)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    this.columnDefs.push(
      {
        headerName: 'ACTIVITIES',

        children: []
      }
    );

    for (var key in this.activityTypes) {
      this.columnDefs[5].children.push({
        headerName: this.activityTypes[key],
        field: this.activityTypes[key] + '_visits',
        menuTabs: [],
        width: 100,
        cellStyle: {textAlign: 'center'},
        aggFunc: 'sum'
      });

    }

    this.columnDefs.push(
      {
        headerName: 'MARKET WORKING',

        children: [
          {
            headerName: 'WORKED DAYS',
            field: 'work_days',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction
            ,
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.work_days) {
                  return parseInt(params.data.work_days);
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: 'CALLAGE',
            field: 'callage',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction,
            valueGetter: function(params) {
              if (params.data) {
                var total_calls = 0;
                if (params.data.phy_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.phy_calls);
                }
                if (params.data.verified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.verified_calls);
                }
                if (params.data.unverified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.unverified_calls);
                }
                if (params.data.in_complete_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.in_complete_calls);
                }
                if (params.data.work_days > 0) {
                  return parseInt((total_calls / params.data.work_days).toFixed(2));
                }
              }
            }
          },
          {
            headerName: 'COVERAGE%',
            menuTabs: [],
            width: 90,
            headerTooltip: '(Unique Visits/Retailer Count) * 100',
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction,
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.unique_visits > 0 && params.data.retailer_count > 0) {
                  return Math.round(params.data.unique_visits * 100 / params.data.retailer_count);
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: 'NOT VISITED',
            field: 'unique_visits',
            headerTooltip: 'Retailer Count - Unique Visits',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: 'sum',
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.unique_visits > 0 && params.data.retailer_count > 0) {
                  if (params.data.unique_visits < params.data.retailer_count) {
                    return params.data.retailer_count - params.data.unique_visits;
                  }
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: 'Attendance',
            field: 'att_days',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction
          },
          {
            headerName: 'Att Time',
            field: 'att_time',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.dateavgAggFunction,
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.att_time) {
                  const d = new Date();
                  d.setUTCHours(params.data.att_time.hours);
                  d.setUTCMinutes(params.data.att_time.minutes);
                  d.setUTCSeconds(0);
                  return d.getHours() + ':' + d.getMinutes();
                } else {
                  return '';
                }
              }
            },
          },
          {
            headerName: 'EOD Time',
            field: 'eod_time',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction,
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.eod_time) {

                  const d = new Date();
                  d.setUTCHours(params.data.eod_time.hours);
                  d.setUTCMinutes(params.data.eod_time.minutes);
                  d.setUTCSeconds(0);
                  return d.getHours() + ':' + d.getMinutes();
                  //
                  // return params.data.eod_time.hours + ':' + params.data.eod_time.minutes;
                } else {
                  return '';
                }
              }
            },
          },

        ]
      },
      {
        headerName: 'VISITS',

        children: [
          {
            headerName: 'TOTAL', field: 'total_call', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: function(params) {
              if (params.data) {
                var total_calls = 0;
                if (params.data.phy_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.phy_calls);
                }
                if (params.data.verified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.verified_calls);
                }
                if (params.data.unverified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.unverified_calls);
                }
                if (params.data.in_complete_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.in_complete_calls);
                }
                return total_calls;
              }
            }
          },
          {
            headerName: 'IN COMPLETE',
            field: 'in_complete_calls',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: 'sum',
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.in_complete_calls) {
                  return parseInt(params.data.in_complete_calls);
                } else {
                  return 0;
                }
              }
            },
          }

        ]
      },
      {
        headerName: 'Score (%)',

        children: [
          {
            headerName: 'DISTRIBUTION',
            field: 'audit_score',
            menuTabs: [],
            width: 100,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction
          },
          {headerName: 'MSL', field: 'audit_p5', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction},
          {headerName: 'FOCUS', field: 'audit_p4', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction},
          {
            headerName: 'SURVEY',
            field: 'audit_p3',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            aggFunc: this.avgAggFunction
          },
        ]
      }
    );

    this.kpitypes = this.apiData.kpi.map(p => p.category)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    this.columnDefs.push(
      {
        headerName: 'KPI',

        children: []
      }
    );

    for (var key in this.kpitypes) {
      this.columnDefs[9].children.push({
        headerName: this.kpitypes[key],
        field: this.kpitypes[key] + '_score',
        menuTabs: [],
        width: 100,
        cellStyle: {textAlign: 'center'},
        aggFunc: 'sum'
      });

    }

    this.columnDefs.push({
        headerName: 'ADHERENCE (AVG)',

        children: [
          {
            headerName: 'First Check IN', field: 'avg_check_in_time', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'},
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.avg_check_in_time) {

                  const d = new Date();
                  d.setUTCHours(params.data.avg_check_in_time.hours);
                  d.setUTCMinutes(params.data.avg_check_in_time.minutes);
                  d.setUTCSeconds(0);
                  return d.getHours() + ':' + d.getMinutes();
                  //
                  // return params.data.eod_time.hours + ':' + params.data.eod_time.minutes;
                } else {
                  return '';
                }
              }
            },
          },
          {
            headerName: 'Last Check out', field: 'avg_check_out_time', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'},
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.avg_check_out_time) {

                  const d = new Date();
                  d.setUTCHours(params.data.avg_check_out_time.hours);
                  d.setUTCMinutes(params.data.avg_check_out_time.minutes);
                  d.setUTCSeconds(0);
                  return d.getHours() + ':' + d.getMinutes();
                  //
                  // return params.data.eod_time.hours + ':' + params.data.eod_time.minutes;
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: 'Time in Market',
            field: 'time_market',
            headerTooltip: 'Attendance Time - EOD Time',
            menuTabs: [],
            width: 80,
            cellStyle: {textAlign: 'center'},
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.att_time && params.data.eod_time) {
                  const att_d = new Date();
                  att_d.setUTCHours(params.data.att_time.hours);
                  att_d.setUTCMinutes(params.data.att_time.minutes);
                  att_d.setUTCSeconds(0);

                  const eod_d = new Date();
                  eod_d.setUTCHours(params.data.att_time.hours);
                  eod_d.setUTCMinutes(params.data.att_time.minutes);
                  eod_d.setUTCSeconds(0);

                  var diff = eod_d.getTime() - att_d.getTime();
                  var diff_as_date = new Date(diff);
                  diff_as_date.getHours(); // hours
                  diff_as_date.getMinutes(); // minutes
                  diff_as_date.getSeconds(); // seconds

                  return diff_as_date.getHours() + ':' + diff_as_date.getMinutes();
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: 'Time in Outlet',
            field: 'time_outlet',
            menuTabs: [],
            headerTooltip: 'Check Out Time - Check In Time',
            width: 80,
            cellStyle: {textAlign: 'center'},
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.avg_check_out_time && params.data.avg_check_in_time) {
                  const checkind = new Date();
                  checkind.setUTCHours(params.data.avg_check_in_time.hours);
                  checkind.setUTCMinutes(params.data.avg_check_in_time.minutes);
                  checkind.setUTCSeconds(0);

                  const checkoutd = new Date();
                  checkoutd.setUTCHours(params.data.avg_check_out_time.hours);
                  checkoutd.setUTCMinutes(params.data.avg_check_out_time.minutes);
                  checkoutd.setUTCSeconds(0);

                  var diff = params.data.avg_check_out_time.getTime() - params.data.avg_check_in_time.getTime();
                  var diff_as_date = new Date(diff);
                  diff_as_date.getHours(); // hours
                  diff_as_date.getMinutes(); // minutes
                  diff_as_date.getSeconds(); // seconds

                  return diff_as_date.getHours() + ':' + diff_as_date.getMinutes();
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: 'EOD', field: 'eod_time', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'},
            valueGetter: function(params) {
              if (params.data) {
                if (params.data.eod_time) {

                  const d = new Date();
                  d.setUTCHours(params.data.eod_time.hours);
                  d.setUTCMinutes(params.data.eod_time.minutes);
                  d.setUTCSeconds(0);
                  return d.getHours() + ':' + d.getMinutes();
                  //
                  // return params.data.eod_time.hours + ':' + params.data.eod_time.minutes;
                } else {
                  return '';
                }
              }
            },
          },
          {headerName: 'DEVIATION', field: 'avg_deviation_mtr', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}},
          {headerName: 'GEO TAGGED', field: 'geo_tagged', width: 80, enableRowGroup: true, cellStyle: {textAlign: 'center'}, aggFunc: 'sum'}
        ]
      }
    );
  }

  loadServerData() {
 this.goClicked = true;
  this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    /*this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');*/

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            // this.rowData = res.results.data;
            this.apiData = res.results;
            this.configureColumns();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.status));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }


  configureData() {

    this.rowData = [];
    var self = this;
    for (var key in this.apiData.master) {
      var indrow = {};
      var masterrow: any = {};
      var auditrow: any = {};
      var outletrow: any = [];
      var activityrow: any = [];
      var classrow: any = [];
      var visitsrow: any = [];
      var classificationrow: any = {};
      var adherencerow: any = {};
      var channelrow: any = {};
      var timingrow: any = {};
      var kpirow: any = {};


      masterrow = this.apiData.master[key];

      visitsrow = this.apiData.market_visit.filter(function(visits) {
        return visits.salesman_id == masterrow.salesman_id;
      });

      if (visitsrow != null && visitsrow.length > 0) {
        visitsrow = visitsrow[0];
      }

      auditrow = this.apiData.audit.filter(function(audit) {
        return audit.salesman_id === masterrow.salesman_id;
      });

      timingrow = this.apiData.timing.filter(function(timing) {
        return timing.salesman_id === masterrow.salesman_id;
      });

      for (var key in self.outletTypes) {
        outletrow[self.outletTypes[key] + '_count'] = self.apiData.outlet_type.filter(function(outlet) {
          return outlet.salesman_id === masterrow.salesman_id && outlet.outlet_type === self.outletTypes[key];
        }).reduce((acc, curr) => {
          return acc = acc + curr.count;
        }, 0);
      }

      for (var key in self.activityTypes) {
        activityrow[self.activityTypes[key] + '_visits'] = self.apiData.activities.filter(function(activity) {
          return activity.salesman_id === masterrow.salesman_id && activity.action_type === self.activityTypes[key];
        }).reduce((acc, curr) => {
          return acc = acc + curr.count;
        }, 0);
      }

      for (var key in self.kpitypes) {
        kpirow[self.kpitypes[key] + '_score'] = self.apiData.kpi.filter(function(kpi) {
          return kpi.salesman_id === masterrow.salesman_id && kpi.category === self.kpitypes[key];
        }).reduce((acc, curr) => {
          return acc = acc + curr.score;
        }, 0);
      }


      // outletrow = this.apiData.outlet_type.filter(function (outlet_type) {
      //   return outlet_type.salesman_id == masterrow.salesman_id;
      // });

      indrow = {
        ...masterrow,
        ...visitsrow,
        ...auditrow,
        ...outletrow,
        ...activityrow,
        ...classificationrow,
        ...adherencerow,
        ...timingrow,
        ...kpirow
      };
      this.rowData.push(indrow);
    }

    console.log(this.rowData);
  }

  dateavgAggFunction(params) {
    var sum = 0;
    var count = 0;
    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }

  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }

}
