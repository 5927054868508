
import {ActivatedRoute, Router} from '@angular/router';
import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../../_services/api.service';
import {LocationPopupComponent} from '../../../components/location_popup/location_popup.component';
import {ToastrService} from 'ngx-toastr';
import {LatLngBounds} from '@agm/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-chain-verify-popup',
  templateUrl: './chain-verify-popup.component.html',
  styleUrls: ['./chain-verify-popup.component.scss']

})

export class ChainVerifyPopupComponent implements OnInit {
  public is_chain = '0';
  public customers = [];
  public gstin = null;
  public pgm_type = null;
  public pgm_id = null;
  public date = null;
  public parent_id = null;
  public mobile = null;
  public email = null;
  public selCust;
  public verify_json;
  private partner_id: any;
  public has_purchase_order;
  public has_preorder = '0';
  public refreshDlg = false;
  public pricelist_id = null;
  public pricelist = null;
  public selectedChain = [];
  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.verify_json = dialogdata.verify_json;
    this.partner_id = dialogdata.id;
    this.mobile = dialogdata.params.mobile;
    this.email = dialogdata.params.email;
  }

  ngOnInit(): void {
    debugger;
    // this.date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.customers = JSON.parse(localStorage.getItem('chain_list'));
  }

  custChange(event) {
    debugger;
    if (event.length > 0) {
      this.date = null;
      this.gstin = event[0].gst_id;
      this.pgm_type = event[0].program_type;
      this.pgm_id = event[0].pgm_id;
      this.parent_id = event[0].id;
      if (event[0].pricelist_id) {
        this.pricelist_id = event[0].pricelist_id;
        this.pricelist = event[0].pricelist;
      }
      if (this.gstin && event[0].verify.chain.date) {
        this.date = this.datePipe.transform(event[0].verify.chain.date, 'dd/MM/yyyy');
      }
      this.has_purchase_order = event[0].has_purchase_order;
      if (this.has_purchase_order) {
        this.has_preorder = '1';
      } else {
        this.has_preorder = '0';
      }
      this.selectedChain = event;
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  verifyChain() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.has_purchase_order = false;
    if (this.has_preorder === '1') {
      this.has_purchase_order = true;
    }
    this.verify_json.chain.verified = true.toString();
    this.verify_json.chain.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.verify_json.chain.by = JSON.parse(localStorage.getItem('user')).user_name;
    let userData;
    if (this.is_chain === '0') {
      userData = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          verification_json: this.verify_json,
          has_purchase_order: this.has_purchase_order,
          partner_id: this.partner_id,
          mobile: this.mobile,
          email: this.email
        }
      };
    } else {
      userData = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          gst_id: this.gstin,
          program_type: this.pgm_id,
          verification_json: this.verify_json,
          parent_id: this.parent_id,
          partner_id: this.partner_id,
          pricelist_id: this.pricelist_id,
          has_purchase_order: this.has_purchase_order,
          mobile: this.mobile,
          email: this.email
        }
      };
    }
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Approval Request');
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error updating Approval Request');
        });

  }
}
