import {Component, ComponentFactoryResolver, ElementRef, Inject, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../../_services/shared_service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-assets-details-component',
  templateUrl: './assets_details.component.html',
  styleUrls: ['./assets_details.component.scss']
})
export class AssetsDetailsComponent implements OnInit {

  assetID: 0;
  assetDetails: any = {};
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  public photo;
  attributeList;
  attributes = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any,
              private componentFactoryResolver: ComponentFactoryResolver) {

    // this.assetID = dialogdata.assetID;
    this.assetDetails = dialogdata.assetDetails;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.data.id =  this.assetDetails.id;
    //this.assetData.installed_date = this.datePipe.transform(this.assetData.installed_date, 'EEE, dd MMM');
    //this.fetchAssetDetails();
    if (this.assetDetails.hasOwnProperty('config_json') && this.assetDetails.config_json && this.assetDetails.config_json.hasOwnProperty('template')) {
      this.assetDetails.brand = this.assetDetails.config_json.template;
    }
    if (this.assetDetails.hasOwnProperty('deployed_on') && this.assetDetails.deployed_on) {
       this.datePipe.transform((new Date(this.assetDetails.deployed_on + ' UTC')).toString(), 'HH:mm:ss');
    }
    this.attributeList = dialogdata.attributeList;
    if (this.attributeList) {
      for (const key of this.attributeList) {
        if (this.assetDetails['attr_' + key]) {
          // this.attributes += key + " : " + this.assetDetails['attr_' + key] + ", ";
          this.attributes.push({
            itemName: key,
            value: this.assetDetails['attr_' + key]
          });
        }
      }
    }
    this.fetchAssetActivity();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

 /* fetchAssetDetails() {

    this.API_URL = '/api/partner_assets/get_asset_details';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(async res => {
          if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
            this.assetDetails = res.results.data;

            if (this.assetDetails.hasOwnProperty('config_json') && this.assetDetails.config_json.hasOwnProperty('template')) {
              this.assetDetails.brand = this.assetDetails.config_json.template;
            }

            if (this.assetDetails.hasOwnProperty('asset_store_fname') && this.assetDetails.asset_store_fname !== null && this.assetDetails.asset_store_fname !== "") {
              this.fetchImage();
              this.fetchActivityImage();

            }

          } else {

          }
        },
        error => {
          console.log(error);

        });
  }*/

  fetchAssetActivity() {

    this.API_URL = '/api/partner_assets/get_asset_activity';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(async res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.assetDetails.activities = res.results.data;

            if (this.assetDetails.hasOwnProperty('asset_store_fname') && this.assetDetails.asset_store_fname !== null && this.assetDetails.asset_store_fname !== "") {
              this.fetchImage();
              this.fetchActivityImage();

            }

          } else {

          }
        },
        error => {
          console.log(error);

        });
  }

  async fetchImage() {
    this.API_URL = '/api/partner_assets/fetch_file';
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.file_path = this.assetDetails.asset_store_fname;
    const res = await this.apiService.fetchAssetImage(paramObject);
    if (res.hasOwnProperty('result')) {
      this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].file_data);
    }
  }

  fetchActivityImage() {
    if (this.assetDetails.hasOwnProperty('activities') && this.assetDetails.activities !== null && this.assetDetails.activities.length > 0) {
      this.assetDetails.activities.forEach(async assetactivity => {
        if (assetactivity !== null && assetactivity.store_fname !== null && assetactivity.store_fname !== "") {
          assetactivity.photo = null;
          /*this.API_URL = '/api/partner_assets/fetch_file';
          var paramObject:any={};
          paramObject.API_URL = this.API_URL;
          paramObject.access_token = localStorage.getItem('resfreshToken');
          paramObject.file_path = assetactivity.store_fname;
          const res1 = await this.apiService.fetchAssetImage(paramObject);
          if (res1.hasOwnProperty('result')) {
            assetactivity.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'].file_data);
          }*/
          this.API_URL = '/api/pwa_connects/fetch_file';
          var paramObject: any = {};
          paramObject.API_URL = this.API_URL;
          paramObject.access_token = localStorage.getItem('resfreshToken');
          paramObject.store_fname = assetactivity.store_fname;
          paramObject.container = 'asset_activity';
          const res1 = await this.apiService.fetchStoreImage(paramObject);
          if (res1.hasOwnProperty('result')) {
            console.log(res1);
            assetactivity.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'].data);
          }
        }
      });
    }
  }

  showImagePopup(type, store_fname) {
    if (screen.width > 991) {
      let config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          store_fname: store_fname,
          container: type
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      let config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          store_fname: store_fname,
          container: type
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }


}
