<div class="common-wrapper">
  <div id="overlay"></div>
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div style="margin-bottom: 0px;" class="col-md-4 search">
            <span style="padding-top: 10px !important;" class="search-control"><img src="../../../assets/searchicon.png"/></span>
            <input style="height:30px !important;" type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
          <div class="col-md-2" style="display: inline-block;" >
            <button class="icon-button btn-blue" (click)="refreshCust()">
              REFRESH</button>
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li *ngIf="generateCredt" style="margin-right: 15px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button class="icon-button btn-teal" (click)="generateCredit()">
                    GENERATE CR NOTE</button>
                </div>
              </li>
              <!--                    <li class="margLR">-->
              <!--                        <div class="date-picker">-->
              <!--                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
              <!--                            <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">-->
              <!--                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
              <!--                            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>-->
              <!--                        </div>-->
              <!--                    </li>-->
              <!--                    <li class="margLR">-->
              <!--                      <app-excelicon [gridOption]="gridOptions" [title]="'Customers List'"></app-excelicon>-->
              <!--                    </li>-->
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0 mt-2">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height:75vh;" class="ag-theme-balham"  [columnDefs]="columnDefs"
                             [gridOptions]="gridOptions" [context]="context"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
