import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {SharedService} from '../../_services/shared_service';

@Component({
  selector: 'app-components-filters',
  templateUrl: './filter.html',
  styleUrls: ['./filter.scss']

})

export class FiltersComponent implements OnInit {


  filterConfig = {
    salesman: false,
    outletType: false,
    dateRange: false,
    state: false
  };
  filterData = {
    salesman: [{id: 0, itemname: 'All Salesmen', sale_team_id: 0}],
    policy: [{id: 0, itemName: 'Policy'}],
    outlet_type: [{id: 0, itemName: 'All Outlets'}],
    program_type: [{id: 0, itemName: 'All Programs'}],
    beatTravelTypes: [],
    cluster: [{id: 0, itemname: 'All Clusters', region_id: 0}],
    state: [{id: 0, itemName: 'All States'}],
    region: [{id: 0, itemname: 'All Regions'}],
    territory: [{id: 0, itemname: 'All Territories', region_id: 0, cluster_id: 0}],
    team: [{id: 0, itemname: 'All Teams'}],
    dc: [{id: 0, itemName: 'All DCs'}],
    payment_mode: [{id: '', itemName: 'All Modes'}, {id: 'cash', itemName: 'Cash'},
      {id: 'credit', itemName: 'Credit'},
      {id: 'cash_and_credit', itemName: 'Credit With Payment'}],
    status: [{id: '', itemname: 'All Status'}],
    active: [{name: 'All', value: ''}, {name: 'Active', value: 'true'}, {name: 'Inactive', value: 'false'}],
  };

  allClusters = [];
  allTerritories = [];
  allTeams = [];
  allSalesmen = [];
  allRegions = [];
  allStates = [];
  allPrograms = [];
  allOutlets = [];
  allDcs = [];

  filterObject = [
    {id: 'salesman_id', value: this.allSalesmen},
    {id: 'region_id', value: this.allRegions},
    {id: 'cluster_id', value: this.allClusters},
    {id: 'territory_id', value: this.allTerritories},
    {id: 'state_id', value: this.allStates},
    {id: 'team_id', value: this.allTeams},
    {id: 'program_type', value: this.allPrograms},
    {id: 'dc_id', value: this.allDcs}
  ];
  selectedData = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    out_type: -1,
    salesman_id: 0,
    policyID: 0,
    outlet_type: 0,
    travel_type_id: 0,
    class_type: null,
    program_type: 0,
    status: '',
    max_claim: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region_id: 0,
    state_id: 0,
    cluster_id: 0,
    team_id: 0,
    territory_id: 0,
    territory_ids: [],
    member_ids: [],
    dc_id: 0,
    active: '',
    displayData: [],
    payment_mode: ''
  };

  API_URL = '';
  showStatus = false;
  showBeat = false;
  showClass = false;
  showProgramType = false;
  showOutletType = false;
  showDC = false;
  showSalesman = false;
  showPolicy = false;
  showRegion = false;
  showState = false;
  showCluster = false;
  showTeam = false;
  showTerritory = false;
  showActive = false;
  showPaymentMode = false;
  access_token = '';
  matmenu;


  /* @Input('filterConfig')
   set filterConfiguration(value: any) {

     this.filterConfig = value;
   }*/

  initialFilter = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    out_type: -1,
    salesman_id: 0,
    policyID: 0,
    outlet_type: 0,
    dc_id: 0,
    travel_type_id: 0,
    class_type: null,
    program_type: 0,
    status: '',
    max_claim: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region_id: 0,
    state_id: 0,
    cluster_id: 0,
    team_id: 0,
    territory_id: 0,
    territory_ids: [],
    member_ids: [],
    active: '',
    displayData: [],
    payment_mode: ''
  };

  @Input() showableFilters: any;
  @Output() filterEmittor: EventEmitter<any> = new EventEmitter<any>();
  @Output() onsetFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGridSearch: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService,
              private sharedService: SharedService
  ) {


  }

  ngOnInit(): void {

    /*if(this.showableFilters.hasOwnProperty('beat') && this.showableFilters.beat)
    {
      this.showFilterBeat = true;
      this.getBeatTravelTypes();
    }*/

    this.loadFilterData();

    this.access_token = localStorage.getItem('resfreshToken');

    this.sharedService.filterCancelCLickEvent
      .subscribe((data: string) => {
        /*if (data === 'salesman_id') {
          this.selectedData.salesman_id = 0;
        }
        if (data === 'region_id') {
          this.selectedData.region_id = 0;
        }
        if (data === 'team_id') {
          this.selectedData.team_id = 0;
        }
        if (data === 'territory_id') {
          this.selectedData.territory_id = 0;
        }
        if (data === 'cluster_id') {
          this.selectedData.cluster_id = 0;
        }
        if (data === 'status') {
          this.selectedData.status = '';
        }*/
        /* if (data !== 'status') {
           this.selectedData[data] = 0;
         } else {
           this.selectedData[data] = '';
         }*/
        this.selectedData[data] = this.initialFilter[data];
        this.selectedData.displayData = this.selectedData.displayData.filter(obj => obj.key !== data);
        this.sharedService.filterSet(this.checkFilterStatus());
        this.onRegionChange();
        this.onClusterChange();
        this.onTeamChange();
        this.onsetFilter.emit(this.selectedData);
      });


  }

  checkFilterStatus() {
    if (this.selectedData.displayData !== null && this.selectedData.displayData.length > 0) {
      return true;
    } else {
      return false;
    }

    /* const innerthis = this;
     let found = false;
     Object.keys(this.initialFilter).forEach((key, index) => {
       if (innerthis.initialFilter[key] !== innerthis.selectedData[key]) {
         found = true;
       }
     });
     return found;*/
    /* if (JSON.stringify(this.initialFilter) === JSON.stringify(this.selectedData)) {
       return false;
     } else {
       return true;
     }*/


  }

  loadFilterData() {
    if (this.showableFilters.outlet_type) {
      if (localStorage.getItem('all_out_types') !== null && localStorage.getItem('all_out_types') !== 'null' && JSON.parse(localStorage.getItem('all_out_types')) !== null) {
        this.allOutlets = JSON.parse(localStorage.getItem('all_out_types'));
        this.allOutlets.sort((a, b) => a.itemName.localeCompare(b.itemName));
        this.filterData.outlet_type = this.filterData.outlet_type.concat(this.allOutlets);
      }
    }

    if (this.showableFilters.dc) {
      if (localStorage.getItem('all_dcs') !== null && localStorage.getItem('all_dcs') !== 'null' && JSON.parse(localStorage.getItem('all_dcs')) !== null) {
        this.allDcs = JSON.parse(localStorage.getItem('all_dcs'));
        this.allDcs.sort((a, b) => a.itemName.localeCompare(b.itemName));
        this.filterData.dc = this.filterData.dc.concat(this.allDcs);
      }
    }

    if (this.showableFilters.program_type) {
      if (localStorage.getItem('all_program_types') !== null && localStorage.getItem('all_program_types') !== 'null' && JSON.parse(localStorage.getItem('all_program_types')) !== null) {
        this.allPrograms = JSON.parse(localStorage.getItem('all_program_types'));
        this.allPrograms.sort((a, b) => a.itemName.localeCompare(b.itemName));
        this.filterData.program_type = this.filterData.program_type.concat(this.allPrograms);
      }
    }

    //if(this.showableFilters.salesman) {
    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.allSalesmen = JSON.parse(localStorage.getItem('all_salesmans'));
      this.allSalesmen.sort((a, b) => a.itemname.localeCompare(b.itemname));
      this.filterData.salesman = this.filterData.salesman.concat(this.allSalesmen);
      this.selectedData.member_ids = this.allSalesmen;
    }
    // }

    if (this.showableFilters.policy) {
      if (localStorage.getItem('all_expenses') !== null && localStorage.getItem('all_expenses') !== 'null' && JSON.parse(localStorage.getItem('all_expenses')) !== null) {
        this.filterData.policy = this.filterData.policy.concat(JSON.parse(localStorage.getItem('all_expenses')));
      }
    }
    if (this.showableFilters.cluster) {
      if (localStorage.getItem('all_clusters') !== null && localStorage.getItem('all_clusters') !== 'null' && JSON.parse(localStorage.getItem('all_clusters')) !== null) {
        this.allClusters = JSON.parse(localStorage.getItem('all_clusters'));
        this.allClusters.sort((a, b) => a.itemname.localeCompare(b.itemname));
        this.filterData.cluster = this.filterData.cluster.concat(this.allClusters);
      }
    }
    if (this.showableFilters.region) {
      if (localStorage.getItem('all_regions') !== null && localStorage.getItem('all_regions') !== 'null' && JSON.parse(localStorage.getItem('all_regions')) !== null) {
        this.allRegions = JSON.parse(localStorage.getItem('all_regions'));
        this.allRegions.sort((a, b) => a.itemname.localeCompare(b.itemname));
        this.filterData.region = this.filterData.region.concat(this.allRegions);
      }
    }
    if (this.showableFilters.state) {
      if (localStorage.getItem('all_states') !== null && localStorage.getItem('all_states') !== 'null' && JSON.parse(localStorage.getItem('all_states')) !== null) {
        this.allStates = JSON.parse(localStorage.getItem('all_states'));
        this.allStates.sort((a, b) => a.itemName.localeCompare(b.itemName));
        this.filterData.state = this.filterData.state.concat(this.allStates);
      }
    }
    // if(this.showableFilters.territory) {
    if (localStorage.getItem('all_territories') !== null && localStorage.getItem('all_territories') !== 'null' && JSON.parse(localStorage.getItem('all_territories')) !== null) {
      this.allTerritories = JSON.parse(localStorage.getItem('all_territories'));
      this.allTerritories.sort((a, b) => a.itemname.localeCompare(b.itemname));
      this.filterData.territory = this.filterData.territory.concat(this.allTerritories);
      this.selectedData.territory_ids = this.allTerritories;
    }
    // }
    if (this.showableFilters.team) {
      if (localStorage.getItem('all_teams') !== null && localStorage.getItem('all_teams') !== 'null' && JSON.parse(localStorage.getItem('all_teams')) !== null) {
        this.allTeams = JSON.parse(localStorage.getItem('all_teams'));
        this.allTeams.sort((a, b) => a.itemname.localeCompare(b.itemname));
        this.filterData.team = this.filterData.team.concat(this.allTeams);
      }
    }

    if (this.showableFilters.status) {
      this.filterData.status = this.filterData.status.concat(this.showableFilters.statusValues);
    }
  }

  getBeatTravelTypes() {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.API_URL = '/api/beat_travel_types';
    this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
      .subscribe(res => {
        if (res.length > 0) {
          this.filterData.beatTravelTypes = res;
        }
      });
  }

  getValue(objectData: any[], objectValue, objectKey) {
    // tslint:disable-next-line:radix
    const data = objectData.filter(object => object[objectKey] === parseInt(objectValue))[0];
    return data;
  }

  setFilter() {
    this.selectedData.displayData = [];
    if (this.selectedData.salesman_id !== 0) {
      const data = this.getValue(this.allSalesmen, this.selectedData.salesman_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Salesman', value: data.itemname, key: 'salesman_id'});
      }
    }
    if (this.selectedData.region_id !== 0) {
      const data = this.getValue(JSON.parse(localStorage.getItem('all_regions')), this.selectedData.region_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Region', value: data.itemname, key: 'region_id'});
      }
    }
    if (this.selectedData.cluster_id !== 0) {
      const data = this.getValue(this.allClusters, this.selectedData.cluster_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Cluster', value: data.itemname, key: 'cluster_id'});
      }
    }
    if (this.selectedData.state_id !== 0) {
      const data = this.getValue(this.allStates, this.selectedData.state_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'State', value: data.itemName, key: 'state_id'});
      }
    }
    if (this.selectedData.territory_id !== 0) {
      const data = this.getValue(this.allTerritories, this.selectedData.territory_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Territory', value: data.itemname, key: 'territory_id'});
      }
    }
    if (this.selectedData.team_id !== 0) {
      const data = this.getValue(this.allTeams, this.selectedData.team_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Team', value: data.itemname, key: 'team_id'});
      }
    }
    if (this.selectedData.dc_id !== 0) {
      const data = this.getValue(this.allDcs, this.selectedData.dc_id, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'DC', value: data.itemName, key: 'dc_id'});
      }
    }
    if (this.selectedData.outlet_type !== 0) {
      const data = this.getValue(this.allOutlets, this.selectedData.outlet_type, 'id');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Outlet Type', value: data.itemName, key: 'outlet_type'});
      }
    }

    if (this.selectedData.status !== '') {
      const data = this.getValue(this.showableFilters.statusValues, this.selectedData.status, 'value');
      if (data != null) {
        this.selectedData.displayData.push({name: 'Status', value: data.value, key: 'status'});
      }
    }

    if (this.selectedData.payment_mode !== '') {
      const data = this.filterData.payment_mode.find(mode => mode.id === this.selectedData.payment_mode);
      if (data != null) {
        this.selectedData.displayData.push({name: 'Payment Mode', value: data.itemName, key: 'payment_mode'});
      }
    }

    /* selectedData = {
       date: null,
       access_token: null,
       url: null,
       last_date: null,
       offset: null,
       flag: true,
       out_type: -1,
       salesman_id: 0,
       policyID: 0,
       outlet_type: 0,
       travel_type_id: 0,
       class_type: null,
       program_type: 0,
       status: '',
       max_claim: false,
       trax_outlet: false,
       non_trax_outlet: false,
       exclude_trax_audit: false,
       region_id: 0,
       state_id: 0,
       cluster_id: 0,
       team_id: 0,
       territory_id: 0,
       territory_ids: [],
       member_ids: [],
       dc_id: 0,
       active: '',
       displayData: []
     };*/
    this.sharedService.filterSet(this.checkFilterStatus());
    this.onsetFilter.emit(this.selectedData);

  }

  clearFilters() {
    this.selectedData = {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesman_id: 0,
      policyID: 0,
      outlet_type: 0,
      dc_id: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: '',
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      state_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      territory_ids: [],
      member_ids: [],
      active: '',
      displayData: [],
      payment_mode: ''
    };

    this.filterData = {
      salesman: [{id: 0, itemname: 'Salesman', sale_team_id: 0}],
      policy: [{id: 0, itemName: 'Policy'}],
      outlet_type: [{id: 0, itemName: 'All Outlets'}],
      program_type: [{id: 0, itemName: 'All Programs'}],
      beatTravelTypes: [],
      cluster: [{id: 0, itemname: 'All Clusters', region_id: 0}],
      state: [{id: 0, itemName: 'All States'}],
      region: [{id: 0, itemname: 'All Regions'}],
      territory: [{id: 0, itemname: 'All Territories', region_id: 0, cluster_id: 0}],
      team: [{id: 0, itemname: 'All Teams'}],
      dc: [{id: 0, itemName: 'All DCs'}],
      payment_mode: [{id: '', itemName: 'All Modes'}, {id: 'cash', itemName: 'Cash'},
        {id: 'credit', itemName: 'Credit'},
        {id: 'cash_and_credit', itemName: 'Credit With Payment'}],
      status: [{id: '', itemname: 'All Status'}],
      active: [{name: 'All', value: ''}, {name: 'Active', value: 'true'}, {name: 'Inactive', value: 'false'}]
    };

    this.loadFilterData();
    this.sharedService.filterSet(this.checkFilterStatus());
    this.onsetFilter.emit(this.selectedData);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onRegionChange() {
    // tslint:disable-next-line:radix
    const reg_id = parseInt(String(this.selectedData.region_id));
    if (reg_id === 0) {
      this.filterData.cluster = [{id: 0, itemname: 'All Clusters', region_id: 0}];
      this.filterData.cluster = this.filterData.cluster.concat(JSON.parse(localStorage.getItem('all_clusters')));
      this.selectedData.cluster_id = 0;

      // if(this.showableFilters.territory) {
      this.filterData.territory = [{id: 0, itemname: 'All Territories', region_id: 0, cluster_id: 0}];
      this.filterData.territory = this.filterData.territory.concat(JSON.parse(localStorage.getItem('all_territories')));
      this.selectedData.territory_id = 0;
      this.selectedData.territory_ids = this.allTerritories;
      // }

    } else {
      this.filterData.cluster = [{id: 0, itemname: 'All Clusters', region_id: reg_id}];
      this.filterData.cluster = this.filterData.cluster.concat(this.allClusters.filter(cluster => cluster.region_id === reg_id));
      if (this.filterData.cluster !== null && this.filterData.cluster.length > 0) {
        this.selectedData.cluster_id = this.filterData.cluster[0].id;
      }

      // if(this.showableFilters.territory) {
      this.filterData.territory = [{id: 0, itemname: 'All Territories', region_id: reg_id, cluster_id: this.selectedData.cluster_id}];
      this.filterData.territory = this.filterData.territory.concat(this.allTerritories.filter(territory => territory.region_id === reg_id));
      if (this.filterData.territory !== null && this.filterData.territory.length > 0) {
        this.selectedData.territory_id = this.filterData.territory[0].id;
        this.selectedData.territory_ids = this.filterData.territory;
      }

      // }
    }

    console.log(this.selectedData.territory_ids);
  }

  onClusterChange() {
    // tslint:disable-next-line:radix
    const reg_id = parseInt(String(this.selectedData.region_id));
    // tslint:disable-next-line:radix
    const clus_id = parseInt(String(this.selectedData.cluster_id));
    // if(this.showableFilters.territory) {
    if (clus_id === 0) {

      this.filterData.territory = [{id: 0, itemname: 'All Territories', region_id: 0, cluster_id: 0}];
      this.filterData.territory = this.filterData.territory.concat(JSON.parse(localStorage.getItem('all_territories')));
      this.selectedData.territory_id = 0;
      this.selectedData.territory_ids = this.allTerritories;
    } else {
      this.filterData.territory = [{id: 0, itemname: 'All Territories', region_id: reg_id, cluster_id: clus_id}];
      this.filterData.territory = this.filterData.territory.concat(this.allTerritories.filter(territory => territory.cluster_id === clus_id));
      if (this.filterData.territory !== null && this.filterData.territory.length > 0) {
        this.selectedData.territory_id = this.filterData.territory[0].id;
        this.selectedData.territory_ids = this.filterData.territory;
      }

    }
    // }
    console.log(this.selectedData.territory_ids);
  }

  onTeamChange() {
    // tslint:disable-next-line:radix
    const team_id = parseInt(String(this.selectedData.team_id));
    if (team_id === 0) {
      this.filterData.salesman = [{id: 0, itemname: 'All Salesmen', sale_team_id: 0}];
      this.filterData.salesman = this.filterData.salesman.concat(JSON.parse(localStorage.getItem('all_salesmans')));
      this.selectedData.salesman_id = 0;
      this.selectedData.member_ids = this.allSalesmen;
    } else {
      this.filterData.salesman = [{id: 0, itemname: 'All Salesmen', sale_team_id: team_id}];
      this.filterData.salesman = this.filterData.salesman.concat(this.allSalesmen.filter(salesman => salesman.sale_team_id === team_id));
      if (this.filterData.salesman !== null && this.filterData.salesman.length > 0) {
        this.selectedData.salesman_id = this.filterData.salesman[0].id;
        this.selectedData.member_ids = this.filterData.salesman;
        console.log(this.selectedData.member_ids);
      }

    }
  }

  /*filterSalesman(str: string) {
    if (typeof str === 'string') {
      this.filterData.salesman = [{id: 0, itemname: 'All Salesmen', sale_team_id: 0}];
      this.filterData.salesman = this.filterData.salesman.concat(this.allSalesmen.filter(
          salesman => salesman.itemname.toLowerCase().startsWith(str.toLowerCase())));
    }
  }*/

  quickSearch() {
    this.onGridSearch.emit(document.getElementById('filter-text-box')['value']);
  }

}
