import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  RowEditingStartedEvent,
  RowEditingStoppedEvent
} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {ErrorHandler} from "../../../error/error-handler";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";
import {
  BulkProductSelectPopupComponent
} from "../../../components/bulk_product_select_popup/bulk_product_select_popup.component";
import {
  ChangeBeatSalesmanPopupComponent
} from "../../../beats/change_beat_salesman_popup/change_beat_salesman_popup.component";
import {AddBeatRetailerPopupComponent} from "../../../beats/add_beat_retailer_popup/add_beat_retailer_popup.component";
import {
  RemoveBeatRetailerPopupComponent
} from "../../../beats/remove_beat_retailer_popup/remove_beat_retailer_popup.component";


@Component({
  selector: 'app-van-transfer',
  templateUrl: 'transfer.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class VanTransferComponent extends DetailPageBase implements IDetailPage, OnInit {

  ngOnInit(): void {
    this.setTitle('Van Stock Transfer');
    this._Detail_GET_URL = "/api/stock_transfers/get_stock_transfer_detail";

    this.context = {componentParent: this};

    // this.getBeatTravelTypes();

    this.configureGrid();

    this.initialize_default_values(() => {

    });

    this.readPageParms(() => {
      this.loadPage();
    });
  }

  //Page Initilizefunctions
  initialize_default_values(callback) {


    return callback();
  }


  loadPage() {
    const inThis = this;
    this.loadActiveObject(() => {
      if (inThis.activeID > 0) {
        console.log(inThis.activeObject);
        this.loadProducts();
      } else {
        //Load all default Values
        this.activeObject['id'] = 0;
      }
    });
  }

  loadProducts() {
    this.rowData = [];
    if (this.activeObject && this.activeObject['config_json']) {
      this.rowData = this.activeObject['config_json']['products'];
      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  //GRID Functions
  configureGrid() {
    const inthis = this;
    this.columnDefs = [
      {headerName: "ID", field: "product_id", width: 40},
      {headerName: "CODE", field: "product_code", width: 40},
      {headerName: "NAME", field: "product_name", width: 80},
      {headerName: "QTY", field: "qty", width: 40}
    ];

  }

  async approveTransfer() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.activeID
    };
    const resp = await this.apiService.postPromise('/api/stock_transfers/approve_stock_transfer', paramObject);
    let upload_status;
    if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
      upload_status = resp['results'].data;
      this.toastr.success('Approved stock transfer');
      this.activeObject.status = 'approved';
    } else if (resp.hasOwnProperty('results') && (resp['results'].status === 199 || resp['results'].status === 203)) {
      this.toastr.error(resp['results'].msg);
      // unauthorised_access = true;
      // this.toastr.success('unauthorised_access');
    } else {
      this.toastr.error('Internal Error');
    }
  }

  async rejectTransfer() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.activeID
    };
    const resp = await this.apiService.postPromise('/api/stock_transfers/reject_stock_transfer', paramObject);
    let upload_status;
    if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
      upload_status = resp['results'].data;
      this.toastr.success('Rejected stock transfer');
      this.activeObject.status = 'rejected';
    } else if (resp.hasOwnProperty('results') && (resp['results'].status === 199 || resp['results'].status === 203)) {
      this.toastr.error(resp['results'].msg);
      // unauthorised_access = true;
      // this.toastr.success('unauthorised_access');
    } else {
      this.toastr.error('Internal Error');
    }
  }


}
