import {ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild,} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, DOCUMENT} from '@angular/common';

import {DomSanitizer} from '@angular/platform-browser';
import {AgmCoreModule, LatLngBounds, MapsAPILoader} from '@agm/core';
import {AdDirective3} from '../ad3.directive';
import {ApiService} from '../_services/api.service';
import {SharedService} from '../_services/shared_service';
import {GridOptions} from '../../../node_modules/@ag-grid-enterprise/all-modules';
import {ClaimMaxComponent} from '../claimMax.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DetailExpenseComponent} from '../expense/detail_expense/detail_expense.component';
import {ToastrService} from 'ngx-toastr';
import {ClaimsDetailPopupComponent} from "./claims_detail_popup/claims_detail_popup.component";

declare let google: any;
declare let $: any;
declare let _: any;


@Component({
  selector: 'app-claims-component',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('detailClick') detailClick: ElementRef<HTMLElement>;
  @ViewChild('galleryClick') galleryClick: ElementRef<HTMLElement>;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  public pinnedParams = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  end_address: string;
  showtravel = true;
  showstay = false;
  showallowances = false;
  showothers = false;
  showscheme = false;
  showdamage = false;
  showdisplay = false;
  showTax = false;
  maxamountExceeded = false;
  masterCreated = false;
  submitClicked = false;
  showFilterStatus = false;
  showFilterBeat = false;
  showFilterClass = false;
  travelamountdisabled = true;
  map: any;
  marker: any;
  infowindow: any;
  infowindowContent: any;
  user_id: null;
  app1_id = null;
  app2_id = null;
  cid: null;
  API_URL = '';
  access_token;
  travelRows: any = [];
  stayRows: any = [];
  allowanceRows: any = [];
  otherRows: any = [];
  schemeRows: any = [];
  damageRows: any = [];
  displayRows: any = [];
  policyLineData: any = [];
  policyLineTypes: any = [];
  photo: any;
  dailyExpenses = {
    TA: 0,
    DA: 0,
    Food: 0,
    Stay: 0,
    Others: 0,
    Total: 0
  };
  user;
  res_data;
  beatTravelTypes = [];
  expenseData = [];
  expenseMasterData: any = {};
  stopLocations: any = [];
  newExpenseData: any = {};
  expenseDetailsData;
  expensephoto = null;
  expensephotos = [];
  expensephotodata = null;
  expensephotodatas = [];
  expenseFileData = {};
  user_data = {
    date: null,
    start_date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    expense_id: null,
    se_id: null,
    salesman_id: null,
    user_id: null,
    policy_id: null,
    expense_ids: [],
    mode: 'claim'
  };
  public tabIndex = 0;
  public policy_html = ``;
  public agm_map;
  public mapEvent;
  public mapHide = false;
  public mapCenter = {lat: 0, lng: 0};
  public track;
  public map_loader;
  public isModelOpened = true;
  public start_act;
  public end_act;
  public is_act;
  public activity;
  public columnDefs = [];
  public columnDefs1 = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public apiData = [];
  public expensetypes = [];
  public masterexpensetypes = [];
  public rowData1 = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public datavalue;
  public data = [];
  public data1;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public styleGrid;
  public myDate = [];
  public expenseDate = 'Select Date';
  public expenseDateTime = '';
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public show_approve = false;
  public dateSelected = false;
  public pinnedTotalData = [
    {
      State: 'TOTAL',
      Date: null,
      ID: null,
      Status: 'TOTAL',
      TA: '0',
      DA: '0',
      Food: '0',
      Stay: '0',
      Others: '0',
      Policy: null,
      Beat: null,
      'Beat Type': null,
      'City Class': null,
      '1st Approver': null,
      Comment: null,
      Claimed: '0',
      Rejected: '0',
      Approved: '0'
    }];
  /* public newExpenseData: {
     stay_name:'',
     submitted_date:'',
     expense_date:"Start Date",
     end_date:"End Date",
     amount:'0',
     salesman_id:0,
     team_id:0,
     expense_line_id:0,
     expense_policy_id:0,
     travel_type:'',
     stay_type:'',
     exp_type:'',
     lat:0,
     long:0,
     area:'',
     zone:'',
     state:'new',
     note:'',
     app1_id:0,
     app2_id:0,
     app1_state:'pending',
     app2_state:'pending'
   };*/
  public icon_url = {
    url: '../../assets/retailer.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public start_url = {
    url: '../../assets/start.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public end_url = {
    url: '../../assets/end.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public gallery = [];
  public expense_act: any;
  public exp_display_dict = {
    da: 'DA',
    ta: 'TA',
    sa: 'SA',
    travel_company: 'Company Travel',
    stay_company: 'Company Stay'
  };
  public itemList = [{id: 0, itemName: 'Salesman'}];
  public selectedItems = [{id: 0, itemName: 'Salesman'}];
  public policyList = [{id: 0, itemName: 'Policy'}];
  public policyItems = [{id: 0, itemName: 'Policy'}];
  private geoCoder;
  private prevIndex;
  private gridApi;
  private gridColumnApi;
  private gridApi1;
  private gridColumnApi1;
  featureApproveClaims;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private agmCore: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private toastr: ToastrService,
              private changeDetector: ChangeDetectorRef,
              @Inject(DOCUMENT) private _document: Document,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.expenseDate = this.datePipe.transform(new Date(), 'EEE, dd MMM');

    // this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    //this.myDate[0] = '2022-01-01';
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // this.myDate[1] = '2021-06-31';
    this.user_data.start_date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.access_token = this.user_data.access_token;
    this.user_data.url = localStorage.getItem('private_url');
    // console.log(localStorage.getItem('private_url'));
    this.user_data.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.cid = JSON.parse(localStorage.getItem('user')).cid;
    // this.user_data.user_id = 522;
    // console.log(JSON.parse(localStorage.getItem('user')));
    // console.log(this.user_data);
    this.report_flag = this.route.snapshot.params.report_flag;
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_exp_users')));
    this.policyList = this.policyList.concat(JSON.parse(localStorage.getItem('all_expenses')));

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    const role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_approve_claims' === existed.name);
    if (role_features.length > 0) {
      this.featureApproveClaims = true;
    }

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      /* getRowStyle: params => {
        /!* if (params.data.Status === "new") {
           return { background: 'red' };
         }*!/
         if (params.data.Status === 'Approved') {
           return { color: '#D5E4C3' };
         }
         else if (params.data.Status === 'Rejected') {
           return { color: '#f29191' };
         }
         else {
           return { color: '#ffeda3' };
         }
       },*/
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.data && params.data[Object.keys(params.data)[1]] === 'TOTAL') {
          return {'font-weight': 'bold'};
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;

    // console.log(this.user_data);

    this.getBeatTravelTypes();
    this.getUserExpensePolicies();
    this.expense();

    /* let stoplocation:any= {};
     stoplocation.address = "1";
     let stoplocation1:any={};
     stoplocation1.address = "2";
     let stoplocation2:any={};
     stoplocation2.address = "3";

     this.stopLocations.push(stoplocation);
     this.stopLocations.push(stoplocation1);
     this.stopLocations.push(stoplocation2);*/

    // console.log(this.stopLocations);

  }

  to_upperCase(str) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  }

  ngAfterViewInit() {
    $(window).on('popstate', function() {
      $('.modal').click();
    });
  }

  display_name(str) {
    if (this.exp_display_dict[str]) {
      return this.exp_display_dict[str];
    } else if (str) {
      if (str.split('_').length > 1) {
        str = str.split('_')[1];
      }
      return this.to_upperCase(str);
    } else {
      return str;
    }
  }

  userChange(event) {
    // debugger;
    if (event.length > 0) {
      if (event[0].id != 0) {
        this.user_data.user_id = event[0].id;
        this.expense();
      } else {
        this.user_data.user_id = null;
        this.expense();
      }
    }
  }

  policyChange(event) {
    // debugger;
    if (event.length > 0) {
      if (event[0].id != 0) {
        this.user_data.policy_id = event[0].id;
        this.expense();
      } else {
        this.user_data.policy_id = null;
        this.expense();
      }
    }
  }

  getBeatTravelTypes() {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.API_URL = '/api/beat_travel_types';
    this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
      .subscribe(res => {
        // console.log(res);
        if (res.length > 0) {
          this.beatTravelTypes = res;
        }
      });
  }

  getUserExpensePolicies() {
    const paramObject: any = {};
    paramObject.cid = this.cid;
    paramObject.user_id = this.user_id;
    // paramObject.filter='{"where":{"user_id":'+this.user_id+',"cid":'+this.cid+'}}';

    // this.API_URL = '/api/exp_user_mapping/getUserExpensePolicies';
    this.API_URL = '/api/exp_user_mappings/findOne';
    this.apiService.getFilter(this.API_URL, this.access_token, paramObject)
      .subscribe(res => {
        // console.log(res);
        this.user_data.policy_id = res.exp_policy_id;
        this.app1_id = res.user_id1;
        this.app2_id = res.user_id2;
      });
  }

  configureData(responseData) {
    // debugger
    this.apiData = [];
    this.expensetypes = [];
    if (responseData != null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      const master = responseData.master;
      const detail = responseData.detail;

      //this.expensetypes = _.keys(_.countBy(responseData.detail, function(data) { return data.exp_type; }));
      this.expensetypes = responseData.detail.map(p => p.exp_type)
        .filter((type, index, arr) => arr.indexOf(type) === index);
      // console.log(this.expensetypes);


      for (const key in master) {

        let indrow = {};
        let masterRow: any = {};
        const detailRow: any = {};
        let detailsLine = [];

        masterRow = master[key];

        detailsLine = detail.filter(function(dt) {
          return dt.expense_date === masterRow.expense_date && dt.master_id === masterRow.master_id;
        });

        if (detailsLine != null && detailsLine != undefined && detailsLine.length > 0) {
          for (const key1 in detailsLine) {

            detailRow[detailsLine[key1].exp_type] = detailsLine[key1].total;
            detailRow['max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;

          }
        }

        indrow = {
          ...masterRow,
          ...detailRow
        };
        this.apiData.push(indrow);

      }

    }

    // console.log(this.apiData);
    this.configureGrid();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  configureGrid() {
    const rowdata = [];
    let ta = 0, da = 0, total = 0, food = 0, stay = 0, others = 0, claim = 0,
      apprvd = 0, rejctd = 0;
    const self = this;
    this.apiData.forEach(data => {
      const pushData = {
        Date: data.expense_date,
        Claimed: data.claimamt,
        Rejected: data.reject_amt,
        Approved: data.appr_amt,
        Status: data.state,
        /*  TA: data.travel,
          DA: data.allowance,
          Food: data.food,
          Stay: data.stay,
         Others: data.others,*/
       /* Policy: data.policy_code,
        Beat: data.beat,
        'Beat Type': data.beat_type,
        'City Class': data.class_type,*/
        '1st Approver': data.approver1,
        Note: data.note,
        Comments: data.comments,
        expense_id: data.id,
        user_id: data.user_id,
        max_claim_travel: data.max_claim_travel,
        max_claim_allowance: data.max_claim_allowance,
        max_claim_stay: data.max_claim_stay,
        max_claim_food: data.max_claim_food,
        max_claim_others: data.max_claim_others,
        policy_name: data.policy,
        max_claim_exceeded: false,
        policy_html: data.policy_html
      };

      if (self.expensetypes != null) {
        for (const key1 in self.expensetypes) {
          if (self.expensetypes[key1] != null && self.expensetypes[key1] != '') {
            pushData[self.expensetypes[key1]] = data[self.expensetypes[key1]];
          }

        }
      }


      const appState = true;
      const rejctState = true;
      pushData.Status = data.state;
      rowdata.push(pushData);
      ta += parseFloat(data.travelamt);
      da += parseFloat(data.allowanceamt);
      food += parseFloat(data.foodamt);
      stay += parseFloat(data.stayamt);
      others += parseFloat(data.othersamt);
      claim += parseFloat(data.claimamt);
      rejctd += parseFloat(data.reject_amt);
      apprvd += parseFloat(data.appr_amt);

    });
    this.pinnedTotalData = [
      {
        State: 'TOTAL',
        Date: 'TOTAL',
        ID: null,
        Status: 'TOTAL',
        TA: ta.toFixed(this.apiService.decimalPolicy),
        DA: da.toFixed(this.apiService.decimalPolicy),
        Food: food.toFixed(this.apiService.decimalPolicy),
        Stay: stay.toFixed(this.apiService.decimalPolicy),
        Others: others.toFixed(this.apiService.decimalPolicy),
        Policy: null,
        Beat: null,
        'Beat Type': null,
        // 'Beat Type': null,
        'City Class': null,
        '1st Approver': null,
        Comment: null,
        Claimed: claim.toFixed(this.apiService.decimalPolicy),
        Rejected: rejctd.toFixed(this.apiService.decimalPolicy),
        Approved: apprvd.toFixed(this.apiService.decimalPolicy)
      }];
    this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;

    this.data = rowdata[0];
    this.rowData = rowdata;
    const to_upperCase = this.to_upperCase;

    this.columnDefs.push({
      headerName: 'State', field: 'Status',  pinned: 'left',
       maxWidth: 20,
      valueGetter(params) {
        if (params.data.Status === 'TOTAL') {
          return '';
        } else {
          return params.data.Status;
        }
      },
      cellStyle(params) {
        if (params.data.Status === 'approved') {
          return {background: '#379862', color: '#379862'};
        } else if (params.data.Status === 'rejected') {
          return {background: '#E24260', color: '#E24260'};
        } else if (params.data.Status === 'settled') {
          return {background: '#2D2D2D', color: '#2D2D2D'};
        } else if (params.data.Status === 'new') {
          return {background: '#22A6B3', color: '#22A6B3'};
        } else if (params.data.Status === 'TOTAL') {
          return '';
        } else {
          return {background: '#F6A82C', color: '#F6A82C'};
        }
      }
    });

    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (key === 'Date') {
          this.columnDefs.push({
            headerName: key, field: key,  pinned: 'left', width: 90,
            cellRenderer(params) {
              if (params.data.Status === 'TOTAL') {
                const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
                return displayElem;
              } else {
                return params.value;
              }
            }
          });
        } else if (key === 'Name' || key === 'ID') {
          this.columnDefs.push({
            headerName: key, field: key, width: 150
          });
        } else if (key === 'travel' || key === 'allowance' || key === 'food' || key === 'stay' || key === 'others') {
          this.columnDefs.push({
            headerName: key, field: key,  cellStyle: {textAlign: 'right'}, width: 60
          });
        } else if (key === 'expense_id' || key === 'user_id' || key === 'max_claim_travel' || key === 'policy_name' ||
          key === 'max_claim_allowance' ||
          key === 'max_claim_stay' ||
          key === 'max_claim_food' ||
          key === 'max_claim_others' || key === 'max_claim_exceeded' || key === 'policy_html') {
          this.columnDefs.push({headerName: key, field: key,  hide: true});
        } else if (key === 'Note') {
          this.columnDefs.push({
            headerName: key, field: key, width: 200
          });
        } else if (key === 'Comments') {
          this.columnDefs.push({
            headerName: key, field: key, width: 200
          });
        } else if (key === 'Rejected' || key === 'Approved') {
          this.columnDefs.push({
            headerName: key, field: key,  cellStyle: {textAlign: 'right'}, width: 80
          });
        } else if (key === 'Claimed') {
          this.columnDefs.push({
            headerName: key, field: key,  filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}, width: 80, pinned: 'left',
            cellRenderer(params) {
              if (params.data.max_claim_exceeded) {
                const displayElem = '<span style="color:#E24260; font-weight: bold">' + params.value + '</span>';
                return displayElem;
              } else {
                return params.value;
              }
            }
          });
        } else if (key === 'Status') {
          this.columnDefs.push({
            headerName: key, field: key,
             width: 150, hide: true,
            valueGetter(params) {
              if (params.data.Status === 'TOTAL') {
                return '';
              } else {
                return params.data.Status;
              }
            },
            cellStyle(params) {
              if (params.value === 'approved') {
                return {color: '#379862'};
              } else if (params.value === 'rejected') {
                return {color: '#E24260'};
              } else if (params.value === 'settled') {
                return {color: '#2D2D2D'};
              } else {
                return {color: '#F6A82C'};
              }
            },
            cellRenderer(params) {

              if (params.data.Status === 'TOTAL') {
                return '';
              } else {
                const displayElem = '<span class="ml-1 status">' + params.value + '</span>';
                return displayElem;
              }
            }
          });
        } else {
          this.columnDefs.push({
            headerName: key, field: key, width: 80
          });
        }
      }
    }


    this.loaded = true;
    this.context = {componentParent: this};
    this.frameworkComponents = {
      maxClaimRenderer: ClaimMaxComponent
    };
    this.pinnedParams = [
      {colId: 'Status', pinned: 'left'},
      {colId: 'Date', pinned: 'left'},
      {colId: 'Claimed', pinned: 'left'}
    ];

  }

  expense() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];

    // debugger;
    // console.log(this.user_data);
    this.API_URL = '/api/sales_expenses/my_team_claims';
    // this.API_URL = '/api/expense_masters/fetch_expenses'
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          // console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.configureData(res.results.data);
          } else {
            this.preloader = false;
            this.empty_state = true;
          }

        },
        error => {
          // console.log(error);
          this.preloader = false;
        });
  }

  close() {
    this.isModelOpened = true;
  }

  view_gallery(photo) {
    this.photo = photo;
    this.isModelOpened = false;
  }

  tabClick(event) {
    // debugger;
    if (event.index === 1) {
      this.map_loader = true;
      const trackData = [];
      const datePipe = this.datePipe;
      this.res_data = this.apiService.getTrack(this.user_data);
      let lat, lng;

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          res.results.data.forEach(function(data) {
            if (data.log != null) {
              data.log.forEach(function(tracklog) {
                if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
                  trackData.push({
                    lat: tracklog.lat,
                    lng: tracklog.lng
                  });
                }
              });
            }
          });
        }
        if (parseFloat(trackData[0].lat) > 0) {
          this.mapCenter.lat = parseFloat(trackData[0].lat);
          this.mapCenter.lng = parseFloat(trackData[0].lng);
        }
        this.track = trackData;
        this.map_loader = false;
      });

      const actData = [];

      this.res_data = this.apiService.getActivity(this.user_data);

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(function(data) {
            if (parseInt(data.lat, 10) > 0 && parseInt(data.long, 10) > 0) {
              actData.push({
                id: data.retailer_id,
                lat: data.lat,
                lng: data.long,
                retailer: data.retailer_name,
                activity: data.actions,
                device_time: datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                comments: data.comments
              });
            }
          });
          if (parseFloat(actData[0].lat) > 0) {
            this.mapCenter.lat = parseFloat(actData[0].lat);
            this.mapCenter.lng = parseFloat(actData[0].lng);
          }
        }

        if (actData.length > 0) {
          this.start_act = actData[0];
          this.end_act = actData[actData.length - 1];
          actData.splice(0, 1);
          actData.splice(actData.length - 1, 1);
          this.is_act = true;
        }
        this.activity = actData;
        this.mapHide = true;
        this.foo(this.mapEvent);
      });
    } else if (event.index === 2) {
      this.empty_state1 = false;
      this.loaded1 = false;
      this.preloader1 = true;
      const beatData = [];
      const datePipe = this.datePipe;

      this.res_data = this.apiService.getBeatAct(this.user_data);

      this.res_data.subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(function(data) {
            beatData.push({
              'Activity Type': data.activity_type,
              Beat: data.beat,
              Retailer: data.retailer,
              'Comment Type': data.comment_type,
              Comments: data.comments,
              device_time: datePipe.transform((new Date(data.device_date + ' UTC')).toString(), 'HH:mm:ss'),
              Latitude: data.latitude,
              Longitude: data.longitude,
              Distance: data.distance,
              'Created On': datePipe.transform((new Date(data.create_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss'),
            });
          });
          this.data1 = beatData[0];
          this.rowData1 = beatData;


          for (const key in this.data1) {
            if (this.data1.hasOwnProperty(key)) {
              this.columnDefs1.push({
                headerName: key, field: key,  sort: true
              });
            }
          }
          this.loaded1 = true;
        } else {
          this.preloader1 = false;
          this.empty_state1 = true;
        }
      }, error => {
        // console.log(error);
        this.preloader1 = false;
      });
    }
  }

  foo(event) {
    // debugger;
    this.mapEvent = event;
    // console.log(event);
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;

    for (const mm of this.track) {
      bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    }
    this.agm_map.fitBounds(bounds);
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const inThis = this;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    // console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    //this.gridApi.setSortModel(sortModel);
    // console.log(this.rowData);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
        params.columnApi.autoSizeAllColumns();
      });
    });

    /* if (screen.width > 991) {
       // params.api.sizeColumnsToFit();
     } else {
       params.columnApi.autoSizeColumns();
     }*/
    if (screen.width > 991) {
      // params.api.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }
    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        /* if (screen.width > 991) {
           // params.api.sizeColumnsToFit();
         } else {
           params.columnApi.autoSizeColumns();
         }*/
        params.columnApi.autoSizeAllColumns();
      });
    });

    /* if (screen.width > 991) {
       // params.api.sizeColumnsToFit();
     } else {
       params.columnApi.autoSizeColumns();
     }*/
    params.columnApi.autoSizeAllColumns();
  }

  ngOnInit() {
    // debugger;
    this.sharedService.setHeader('Claims');

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  changeDate(dt) {
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.expense();
  }

  setExpenseStartDate(dt) {
    // console.log(this.newExpenseData.expense_date)
    this.newExpenseData.expense_date = this.datePipe.transform(this.newExpenseData.expense_date, 'yyyy-MM-dd');
  }

  setExpenseEndDate(dt) {
    // console.log(this.newExpenseData.expense_date)
    this.newExpenseData.end_date = this.datePipe.transform(this.newExpenseData.end_date, 'yyyy-MM-dd');
  }

  setGSTInvoiceDate(dt) {
    // console.log(this.newExpenseData.inv_date)
    this.newExpenseData.inv_date = this.datePipe.transform(this.newExpenseData.inv_date, 'yyyy-MM-dd');
  }

  configureMasterData(responseData) {

    if (responseData != null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      const master = responseData.master;
      const detail = responseData.detail;

      if (master === null || master.length === 0) {
        this.masterCreated = false;
      } else {
        this.masterCreated = true;
        //this.masterexpensetypes = _.keys(_.countBy(responseData.detail, function(data) { return data.exp_type; }));
        this.masterexpensetypes = responseData.detail.map(p => p.exp_type)
          .filter((type, index, arr) => arr.indexOf(type) === index);
        for (const [key, value] of Object.entries(responseData.master)) {
          this.expenseMasterData[key] = value;
        }

        if (this.expenseMasterData != null && this.expenseMasterData != undefined) {
          this.expenseMasterData = this.expenseMasterData[0];
        }
        if (detail != null && detail != undefined && detail.length > 0) {
          for (let detailkey in detail) {

            this.expenseMasterData[detail[detailkey].exp_type + 'amt'] = detail[detailkey].total;
            this.expenseMasterData['max_claim_' + detail[detailkey].exp_type] = detail[detailkey].max_claim;
          }
        }


        // console.log(this.expenseMasterData);
      }
    }
  }

  setExpenseDate(dt) {

    // console.log(dt);

    this.masterCreated = false;
    this.expenseMasterData = {};
    this.newExpenseData = {};
    this.stopLocations = [];
    this.address = '';
    this.end_address = '';
    this.latitude = 0;
    this.longitude = 0;
    this.dailyExpenses = {
      TA: 0,
      DA: 0,
      Food: 0,
      Stay: 0,
      Others: 0,
      Total: 0
    };
    if (this.expenseDate.length <= 11) {
      if (this.expenseDate === this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
        this.expenseMasterData.expense_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      } else {
        this.expenseMasterData.expense_date = this.expenseDate;
      }
    } else {
      // this.expenseMasterData.expense_date = this.expenseDate;
      this.expenseDate = this.datePipe.transform(this.expenseDate, 'yyyy-MM-dd');
      this.expenseMasterData.expense_date = this.datePipe.transform(this.expenseDate, 'yyyy-MM-dd');

    }

    // console.log(this.expenseDate);
    // console.log( this.expenseMasterData.expense_date);

    // this.expenseMasterData.expense_date = this.datePipe.transform(this.expenseMasterData.expense_date, 'yyyy-MM-dd');

    this.expenseMasterData.travel_type_id = this.beatTravelTypes[0].id;
    this.expenseMasterData.class_type = 'a';
    this.user_data.start_date = this.expenseMasterData.expense_date;
    this.user_data.last_date = this.expenseMasterData.expense_date;
    // console.log(this.user_data);
    this.API_URL = '/api/expense_masters/fetch_expensesv2';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        // console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          // this.masterCreated = true;
          this.configureMasterData(res.results.data);
          // console.log(res.results.data);
          // this.expenseMasterData = res.results.data[0];
          if (this.masterCreated) {
            this.newExpenseData = {};
            this.newExpenseData.master_id = this.expenseMasterData.id;
            if (this.expenseMasterData.hasOwnProperty('company_tax_id')) {
              this.newExpenseData.company_tax_id = this.expenseMasterData.company_tax_id;
            }

            this.user_data.expense_id = this.expenseMasterData.id;
            this.res_data = this.apiService.expenseDetails(this.user_data);
            this.res_data.subscribe(async res => {
              // console.log(res);
              if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
                this.expenseDetailsData = res.results.data;
                // console.log(this.expenseDetailsData);
              }
            });
          }
        } else {
          this.masterCreated = false;
        }
      });

  }

  showAddExpensePopup() {

    $('#expensemasterModal').modal('show');
    // this.expenseDate =  this.datePipe.transform(new Date(), 'EEE, dd MMM');
    this.expenseDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.setExpenseDate('');
  }

  mainexpenseclick() {
    // this.showModalOnClick.show();
    this.policyLineTypes = [];
    // console.log(this.expenseMasterData);
    this.expensephotos = [];
    if (this.expenseDate === 'Select Date') {
      this.toastr.warning('Please Select Date');
    } else {

      $('#expensemasterModal').modal('hide');

      this.travelRows = [];
      this.stayRows = [];
      this.allowanceRows = [];
      this.otherRows = [];
      this.schemeRows = [];
      this.damageRows = [];

      this.API_URL = '/api/expense_policy_lines/fetch_policy_lines';
      if (this.masterCreated) {
        this.user_data.policy_id = this.expenseMasterData.policyid;
      }
      // console.log(this.user_data);
      const paramObject: any = {};
      paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
      paramObject.policy_id = this.user_data.policy_id;
      // if(this.expenseMasterData.class_type!=null && this.expenseMasterData.class_type!='any')
      /*  paramObject.class_types = [];
          paramObject.class_types.push(this.expenseMasterData.class_type);
        paramObject.class_types.push('any');*/
      paramObject.class_type = this.expenseMasterData.class_type;
      paramObject.mode = 'claim';
      if (this.expenseMasterData.travel_type_id != null) {
        paramObject.beat_type_id = this.expenseMasterData.travel_type_id;
      }
      // console.log(paramObject);
      paramObject.access_token = this.access_token;
      this.apiService.post(this.API_URL, paramObject)
        .subscribe(res => {
          // console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
            this.policyLineData = res.results.data;
            //this.policyLineTypes = _.keys(_.countBy( this.policyLineData, function(data) { return data.exp_type; }));
            this.policyLineTypes = this.policyLineData.map(p => p.exp_type)
              .filter((type, index, arr) => arr.indexOf(type) === index);
            for (let i = 0; i < this.policyLineData.length; i++) {
              if (this.policyLineData[i].exp_type === 'travel') {
                /* if(this.policyLineData[i].hasOwnProperty('class_type') &&
                   (this.policyLineData[i].class_type==this.expenseMasterData.class_type) ||
                   this.policyLineData[i].class_type=='any')
                 {
                   this.travelRows.push(this.policyLineData[i]);
                 }*/
                this.travelRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'stay') {
                /* if(this.policyLineData[i].hasOwnProperty('class_type') &&
                   (this.policyLineData[i].class_type==this.expenseMasterData.class_type) ||
                   this.policyLineData[i].class_type=='any')
                 {
                   this.stayRows.push(this.policyLineData[i]);
                 }*/
                this.stayRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'allowance') {
                /*if(this.policyLineData[i].hasOwnProperty('class_type') &&
                  (this.policyLineData[i].class_type==this.expenseMasterData.class_type) ||
                  this.policyLineData[i].class_type=='any')
                {
                  this.allowanceRows.push(this.policyLineData[i]);
                }*/
                this.allowanceRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'scheme') {
                this.schemeRows.push(this.policyLineData[i]);
              } else if (this.policyLineData[i].exp_type === 'damage') {
                this.damageRows.push(this.policyLineData[i]);
              } /*else if (this.policyLineData[i].exp_type === 'display') {
                this.displayRows.push(this.policyLineData[i]);
              } */ else {
                this.otherRows.push(this.policyLineData[i]);
              }
            }

            // console.log(this.travelRows);
            // console.log(this.stayRows);
            // console.log(this.allowanceRows);
            // console.log(this.otherRows);
          }
        });

      $('#expensetypepopupModal').modal('show');
    }
  }

  handleExpensetypeClick(item) {
    // console.log(item);
    if (item.hasOwnProperty('tax_type') && item.tax_type != null) {
      this.newExpenseData.tax_type = item.tax_type;

      if (item.tax_type === 'no_tax') {
        this.showTax = false;
        this.newExpenseData.has_gst = false;
      } else {
        this.showTax = true;
        this.newExpenseData.has_gst = true;
      }

    } else {
      this.showTax = false;
      this.newExpenseData.has_gst = false;
    }
    if (item.hasOwnProperty('policy_line_html')) {
      this.newExpenseData.policy_line_html = item.policy_line_html;
    }

    this.newExpenseData.exp_type = item.exp_type;
    this.newExpenseData.expense_policy_id = item.policy_id;
    this.newExpenseData.expense_line_id = item.id;
    this.newExpenseData.expense_date = this.user_data.start_date;
    this.newExpenseData.bill_mandatory = item.bill_mandatory;
    if (item.hasOwnProperty('claim_amount')) {
      this.newExpenseData.claim_amount = item.claim_amount;
    }
    if (item.hasOwnProperty('max_claim_amount')) {
      this.newExpenseData.max_claim_amount = item.max_claim_amount;
    }
    // console.log(this.newExpenseData);
    if (item.exp_type === 'stay') {
      this.newExpenseData.stay_name = 'Stay Expense';
      this.newExpenseData.stay_type = item.stay_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.stay_type;
      $('#staypopupModal').modal('show');
    } else if (item.exp_type === 'travel') {
      this.newExpenseData.travel_type = item.travel_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.travel_type;
      if (item.hasOwnProperty('config') && item.config != null && item.config.hasOwnProperty('perkm')) {
        this.newExpenseData.perkm = item.config.perkm;
        this.travelamountdisabled = true;
      } else {
        // this.newExpenseData.perkm = 10;
        this.travelamountdisabled = false;
      }

      $('#travelpopupModal').modal('show');
    } else if (item.exp_type === 'allowance') {
      this.newExpenseData.allowance_type = item.allowance_type;
      this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.allowance_type;
      $('#allowancepopupModal').modal('show');
    } else if (item.exp_type === 'scheme') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else if (item.exp_type === 'damage') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else if (item.exp_type === 'display') {
      this.newExpenseData.name = this.newExpenseData.exp_type;
      $('#otherspopupModal').modal('show');
    } else {
      $('#otherspopupModal').modal('show');
    }

  }


  expenseTypeClick(item) {
    // console.log(this.expenseDetailsData);
    if (item.hasOwnProperty('allow_multiple_expense') && item.allow_multiple_expense) {
      this.handleExpensetypeClick(item);
    } else {
      if (!this.expenseDetailsData) {
        this.handleExpensetypeClick(item);
      } else {
        const expenseItems = this.expenseDetailsData.filter(d => d.expense_line_id === item.id && d.state !== 'cancelled');
        // console.log(this.expenseDetailsData);
        // console.log(expenseItems);
        // console.log(Object.keys(expenseItems).length);
        if (expenseItems != null && expenseItems.length > 0) {
          if (expenseItems.state !== 'rejected' && expenseItems.state !== 'cancelled') {
            this.toastr.error('Multiple Expenses not allowed for this Policy');
          } else {
            this.handleExpensetypeClick(item);
          }
        } else {
          this.handleExpensetypeClick(item);
        }
      }

    }
  }

  showPolicyInfoPopup() {
    const policyinfoid = document.getElementById('policyinfo');
    policyinfoid.innerHTML = this.newExpenseData.policy_line_html;
    $('#policyinfopopupModal').modal('show');
  }

  createExpenseClickTest(data) {
    // console.log(data);
  }

  createExpenseClick() {
    this.submitClicked = true;
    if (this.submitClicked) {

      if (this.newExpenseData.bill_mandatory && (this.expensephotodatas === null || this.expensephotodatas.length === 0)) {
        this.submitClicked = false;
        this.toastr.warning('Bill is Mandatory');
      } else if (this.newExpenseData.hasOwnProperty('max_claim_amount') && this.newExpenseData.max_claim_amount != 0 && this.newExpenseData.amount > this.newExpenseData.max_claim_amount) {
        this.submitClicked = false;
        this.onAmountChange();
      } else if (this.masterCreated) {
        this.createExpense();
      } else {
        this.createMaster();
      }

    }
  }

  createMaster() {

    this.expenseMasterData.name = JSON.parse(localStorage.getItem('user')).login;
    this.expenseMasterData.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.expenseMasterData.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.expenseMasterData.access_token = this.access_token;
    this.expenseMasterData.create_uid = this.user_id;
    this.expenseMasterData.create_date = new Date();
    this.expenseMasterData.write_uid = this.user_id;
    this.expenseMasterData.write_date = new Date();
    this.expenseMasterData.state = 'new';
    this.expenseMasterData.total_claim = 0;

    /* let paramObject :any= {};
     paramObject.name = JSON.parse(localStorage.getItem('user')).login;
     paramObject.user_id = JSON.parse(localStorage.getItem('user')).id;
     paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
     paramObject.access_token  = localStorage.getItem('resfreshToken');
     paramObject.create_uid = this.user_id;
     paramObject.create_date = new Date();
     paramObject.write_uid = this.user_id;
     paramObject.write_date = new Date();
     paramObject.state = "new";
     paramObject.total_claim = 0;
     paramObject.access_token = this.access_token;
     // console.log(paramObject);*/

    /* let paramObject: any = {};
     paramObject.data = this.expenseMasterData;
     paramObject.access_token  = localStorage.getItem('resfreshToken');*/

    // console.log( this.expenseMasterData);
    this.API_URL = '/api/expense_masters';
    this.apiService.post(this.API_URL, this.expenseMasterData)
      .subscribe(res => {
        // console.log(res);
        this.masterCreated = true;
        this.expenseMasterData = res;
        this.newExpenseData.master_id = this.expenseMasterData.id;
        this.createExpense();
        /*  // console.log(res.results.data);
          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data)) {
            this.masterCreated = true;
            this.expenseMasterData =   res.results.data;
            this.newExpenseData.master_id = this.expenseMasterData.id;
            this.createExpense();
          }
          else {
            window.alert('Failed to create Expense');
          }*/
      });
  }

  createExpense() {
    if (this.newExpenseData.exp_type === 'allowance') {
      if (this.newExpenseData.da_type != null) {
        this.newExpenseData.name = this.newExpenseData.exp_type + '-' + this.newExpenseData.da_type;
      } else {
        this.newExpenseData.name = this.newExpenseData.exp_type;
      }
    }

    this.newExpenseData.submitted_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    // this.newExpenseData.expense_date = this.expenseDateTime;
    this.newExpenseData.salesman_id = JSON.parse(localStorage.getItem('user')).id;
    this.newExpenseData.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.newExpenseData.team_id = JSON.parse(localStorage.getItem('user')).sale_team_id;
    this.newExpenseData.app1_id = this.app1_id;
    this.newExpenseData.app2_id = this.app2_id;
    this.newExpenseData.app1_state = 'pending';
    this.newExpenseData.app2_state = 'pending';
    this.newExpenseData.state = 'new';
    this.newExpenseData.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.newExpenseData.access_token = localStorage.getItem('resfreshToken');
    if (this.newExpenseData.hasOwnProperty('distance') && this.newExpenseData.distance > 0) {
      this.newExpenseData.distance = this.newExpenseData.distance * 1000;
    }
    if (this.newExpenseData.exp_type === 'travel' && this.stopLocations != null && this.stopLocations.length > 0) {
      this.newExpenseData.travel_path_locations = this.stopLocations;
    }

    /* let paramObject: any = {};
     paramObject.data = this.newExpenseData;
     paramObject.access_token  = localStorage.getItem('resfreshToken');*/
    if (this.expensephotodatas != null) {
      this.newExpenseData.gallery = [];
      for (let i = 0; i < this.expensephotodatas.length; i++) {
        const imageObject: any = {};
        imageObject.datas = this.expensephotodatas[i].photodata;
        imageObject.store_fname = 'Expense_image_' + this.newExpenseData.expense_date;
        imageObject.name = imageObject.store_fname;

        this.newExpenseData.gallery.push(imageObject);
      }
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.newExpenseData;
    // console.log(this.newExpenseData);
    this.API_URL = '/api/sales_expenses/createExpense';
    this.apiService.post(this.API_URL, this.newExpenseData)
      .subscribe(res => {
        // console.log(res);
        this.submitClicked = false;
        if (this.newExpenseData.exp_type === 'stay') {
          $('#staypopupModal').modal('hide');
        }
        if (this.newExpenseData.exp_type === 'travel') {
          $('#travelpopupModal').modal('hide');
        }
        if (this.newExpenseData.exp_type === 'allowance') {
          $('#allowancepopupModal').modal('hide');
        } else {
          $('#otherspopupModal').modal('hide');
        }

        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data)) {
          this.toastr.success('Expense created Successfully');
          // console.log(res.results.data);
          this.newExpenseData.id = res.results.data.id;
          this._document.defaultView.location.reload();
          /* if(this.expensephoto!=null)
             this.createImageRecord();*/
        } else {
          this.toastr.error('Failed to create Expense');
        }
      });


    // console.log(this.newExpenseData);
  }

  openDetailDialog() {
    let dialogType = 'user';
    if (this.featureApproveClaims) {
      dialogType = 'admin';
    }
    // console.log(this.expenseDetailsData);
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '850px',
      maxHeight: '720px',
      data: {
        expenseData: this.expenseData,
        expenseDetailsData: this.expenseDetailsData,
        rows: this.gridApi.getSelectedRows(),
        user_data: this.user_data,
        dialogType,
        start_date: this.user_data.start_date,
        end_date: this.user_data.last_date
      }
    };

    // console.log(this.expenseDetailsData);

    this.dialog.open(ClaimsDetailPopupComponent, config);
  }

  locationPicker(locationType) {
    $('#locationpopupModal').modal('show');
    if (this.map != null) {
      google.maps.event.clearListeners(this.map, 'click');
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation(locationType);
      this.geoCoder = new google.maps.Geocoder;
      document.getElementById('pac-input')['value'] = '';
      const input = document.getElementById('pac-input') as HTMLInputElement;
      const autocomplete = new google.maps.places.Autocomplete(input);

      let pacContainerInitialized = false;
      $('#pac-input').keypress(function() {
        if (!pacContainerInitialized) {
          $('.pac-container').css('z-index', '9999');
          pacContainerInitialized = true;
        }
      });

      if (this.infowindow === null) {
        this.infowindow = new google.maps.InfoWindow();
      }
      this.infowindowContent = document.getElementById(
        'infowindow-content'
      ) as HTMLElement;
      this.infowindow.setContent(this.infowindowContent);

      /*  this.map.addListener("bounds_changed", () => {
          autocomplete.setBounds(this.map.getBounds());
        });*/


      autocomplete.addListener('place_changed', () => {
        // console.log('yes');

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          this.toastr.warning('No details available for input: \'' + place.name + '\'');
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(15);
        }

        const latlng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        this.marker.setPosition(latlng);
        this.marker.setVisible(true);
        // console.log(place);
        this.infowindowContent.children['place-name'].textContent = place.name;
        this.infowindowContent.children['place-address'].textContent = place.formatted_address;
        this.infowindow.open(this.map, this.marker);
        const area = this.extractFromAddress(place.address_components, 'locality');
        const zone = this.extractFromAddress(place.address_components, 'administrative_area_level_2');
        // this.setExpenseLocations(place.geometry.location.lat(),place.geometry.location.lng(),place.formatted_address,area,zone,locationType)
        // this.getAddress(place.geometry.location.lat(),place.geometry.location.lng(),locationType);
      });


    });
    const confirmBtn = document.getElementById('mapsubmit');
    const outer = this;
    confirmBtn.onclick = function() {
      outer.getAddress(outer.marker.getPosition().lat(), outer.marker.getPosition().lng(), locationType);
    };
  }

  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    /*this.latitude = $event['coords'].lat;
    this.longitude = $event['coords'].lng;
    this.getAddress(this.latitude, this.longitude);*/
  }

  getAddress(latitude, longitude, locationType) {
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        // console.log(results[0])
        if (results[0]) {

          /* if(locationType=='start')
             this.address = results[0].formatted_address;
           else if(locationType=='end')
             this.end_address = results[0].formatted_address;*/

          this.infowindowContent.children['place-name'].textContent = results[0].address_components[0].short_name;
          this.infowindowContent.children['place-address'].textContent = results[0].formatted_address;
          this.infowindow.open(this.map, this.marker);

          const area = this.extractFromAddress(results[0].address_components, 'locality');
          const zone = this.extractFromAddress(results[0].address_components, 'administrative_area_level_2');
          /* let area = results[0].address_components[0].long_name;
           let zone = results[0].address_components[1].long_name;*/
          this.setExpenseLocations(latitude, longitude, results[0].formatted_address, area, zone, locationType);

          /* if(locationType=='start')
           {
             this.newExpenseData.lat = latitude;
             this.newExpenseData.lng = longitude;
             this.address =  results[0].formatted_address;
             if(area!=null)
               this.newExpenseData.area = area;
             if(zone!=null)
               this.newExpenseData.zone = zone;
           }
           else {
             this.newExpenseData.end_lat = latitude;
             this.newExpenseData.end_long = longitude;
             this.end_address =  results[0].formatted_address;
             if(area!=null)
               this.newExpenseData.end_area = area;
             if(zone!=null)
               this.newExpenseData.end_zone = zone;
           }*/
        } else {
          this.toastr.error('No results found');
        }
      } else {
        this.toastr.error('Geocoder failed due to: ' + status);
      }

    });
  }

  extractFromAddress(components, type) {
    return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || null;
  }

  setExpenseLocations(lat, lng, address, area, zone, locationType) {

    if (locationType === 'start') {
      this.newExpenseData.lat = lat;
      this.newExpenseData.long = lng;
      this.address = address;
      if (area != null) {
        this.newExpenseData.area = area;
      }
      if (zone != null) {
        this.newExpenseData.zone = zone;
      }
    } else if (locationType === 'end') {
      this.newExpenseData.end_lat = lat;
      this.newExpenseData.end_long = lng;
      this.end_address = address;
      if (area != null) {
        this.newExpenseData.end_area = area;
      }
      if (zone != null) {
        this.newExpenseData.end_zone = zone;
      }
    } else {
      const stoplocation: any = {};
      stoplocation.lat = lat;
      stoplocation.long = lng;
      if (area != null) {
        stoplocation.area = area;
      }
      if (zone != null) {
        stoplocation.zone = zone;
      }
      stoplocation.address = address;

      this.stopLocations.push(stoplocation);
    }

    // console.log(this.newExpenseData);

    this.computeDistance();


  }

  computeDistance() {
    if (this.newExpenseData.hasOwnProperty('lat') && this.newExpenseData.lat != null && this.newExpenseData.hasOwnProperty('end_lat') && this.newExpenseData.lat != null) {
      const start = new google.maps.LatLng(this.newExpenseData.lat, this.newExpenseData.long);
      const end = new google.maps.LatLng(this.newExpenseData.end_lat, this.newExpenseData.end_long);

      let distance = google.maps.geometry.spherical.computeDistanceBetween(start, end);
      // console.log(distance);

      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: start,
        destination: end,
        waypoints: [],
        travelMode: 'DRIVING'
      };
      if (this.stopLocations != null && this.stopLocations.length > 0) {
        for (let i = 0; i < this.stopLocations.length; i++) {
          // console.log(this.stopLocations[i].lat);
          request.waypoints.push(
            {
              location: new google.maps.LatLng(this.stopLocations[i].lat, this.stopLocations[i].long),
              stopover: true
            }
          );
        }
      }
      const outer = this;
      directionsService.route(request, function(response, status) {
        if (status === 'OK') {
          if (response.hasOwnProperty('routes') && response.routes.length > 0) {
            if (response.routes[0].hasOwnProperty('legs') && response.routes[0].legs.length > 0) {
              distance = 0;
              for (let j = 0; j < response.routes[0].legs.length; j++) {
                distance += response.routes[0].legs[j].distance.value;
              }
              // distance = response.routes[0].legs[0].distance.value;
              outer.handleDistance(distance);
            } else {
              outer.handleDistance(distance);
            }
          } else {
            outer.handleDistance(distance);
          }
        } else {
          outer.handleDistance(distance);
        }
      });


    }
  }

  handleDistance(distance) {
    if (distance != null) {
      distance = distance / 1000;
      distance = Math.round(distance * 100) / 100;
      this.newExpenseData.distance = distance;
      // console.log(distance);
      this.calculateAmount();
    }
  }

  removeStop(index) {
    this.stopLocations.splice(index, 1);
    this.computeDistance();
  }

  handleFileSelect(evt) {
    // console.log(evt);
    const files = evt.target.files;
    const file = files[0];
    this.expenseFileData = {};
    if (files && file) {
      const reader = new FileReader();
      this.expenseFileData['name'] = file.name;
      this.expenseFileData['type'] = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.expensephotodata = base64textString;
    this.expensephotodatas.push({
      name: this.expenseFileData['name'],
      type:  this.expenseFileData['type'],
      photodata: base64textString
    });
    this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
    this.expensephotos.push({
      name: this.expenseFileData['name'],
      type:  this.expenseFileData['type'],
      photos: this.expensephoto
    });
  }

  calculateAmount() {
    // console.log(this.newExpenseData.distance);
    this.newExpenseData.amount = this.newExpenseData.distance * this.newExpenseData.perkm;
    this.newExpenseData.amount = Math.round(this.newExpenseData.amount * 100) / 100;
    this.onAmountChange();
  }

  onAmountChange() {
    if (this.newExpenseData.hasOwnProperty('max_claim_amount') && this.newExpenseData.max_claim_amount != 0 && (this.newExpenseData.amount > this.newExpenseData.max_claim_amount)) {
      this.maxamountExceeded = true;
    } else {
      this.maxamountExceeded = false;
    }

    this.newExpenseData.amount = Math.round(this.newExpenseData.amount * 100) / 100;
    // console.log(this.maxamountExceeded);
    // console.log(this.newExpenseData.amount);
    this.changeDetector.detectChanges();
  }

  showFilterPopup() {
    $('#filterpopupModal').modal('show');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column !== 'Date' && column !== 'Policy') {
      this.viewDetails('details');
      this.tabIndex = 0;
      if (this.prevIndex !== event.rowIndex) {
        if (event.node.selected) {
          this.show_approve = true;
          this.prevIndex = event.rowIndex;
        }
      } else {
        if (event.node.selected) {
          this.show_approve = true;
        } else {
          this.show_approve = false;
        }
        this.prevIndex = event.rowIndex;
      }
    } else if (column === 'Policy') {
      // debugger;
      this.policy_html = event.data.policy_html + event.data.line_html;
      const el: HTMLElement = this.galleryClick.nativeElement;
      el.click();
    }
  }

  viewDetails(check) {
    const rows = this.gridApi.getSelectedRows();
    this.user_data.expense_id = rows[0].expense_id;
    this.user_data.date = rows[0].Date;
    this.user_data.salesman_id = rows[0].user_id;
    this.expenseData = rows[0];
    let images = [];
    this.res_data = this.apiService.expenseDetails(this.user_data);
    // console.log(rows[0]);
    this.res_data.subscribe(async res => {
      // console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        this.expenseDetailsData = res.results.data;
        for (const exp of this.expenseDetailsData) {
          images = [];
          exp.asd = 1;
          exp.policy_name = rows[0].policy_name;
          exp.photo = null;
          if (exp.gallery.length > 0) {
            exp.gallery.forEach(image => {
              if (image != null) {
                images.push({store_fname: image.store_fname, container: 'expense_gallery', type: image.type});
              }
            });
            exp.gallery = [];
            if (images.length > 0) {
              // console.log(this.user_data);
              const payload = {
                access_token: this.user_data.access_token,
                url: this.user_data.url,
                data: images
              };
              const res1 = await this.apiService.fetchExpenseImage(payload);
              if (res1.hasOwnProperty('result') && res1['result'].length > 0) {
                // console.log(res);
                const domSanitizer = this.domSanitizer;
                let img;
                res1['result'].forEach(photo => {
                  if (!exp.photo) {
                    if (photo.type && photo.type === 'pdf') {
                      exp.photo = './../../../assets/pdf_icon.png';
                    } else {
                      exp.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'][0].bill_datas);
                    }
                  }
                  if (photo.type && photo.type === 'pdf') {
                    img =  domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + photo.bill_datas);
                  } else {
                    img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
                  }
                  exp.gallery.push(
                    {
                      photo: img,
                      type: photo.type
                    });
                });
              }
            }
          } else {
            exp.gallery = [];
          }
          if (exp.exp_type === 'travel') {
            exp.travelpath = '';
            if (exp.zone != null) {
              exp.travelpath = exp.zone;
            }
            if (exp.area != null) {
              exp.travelpath = exp.travelpath.concat(',' + exp.area);
            }

            if (exp.travel_path_locations != null) {
              for (let i = 0; i < exp.travel_path_locations.length; i++) {
                if (exp.travel_path_locations[i].zone != null) {
                  exp.travelpath = exp.travelpath.concat(' => ' + exp.travel_path_locations[i].zone);
                }
                if (exp.travel_path_locations[i].area != null) {
                  exp.travelpath = exp.travelpath.concat(',' + exp.travel_path_locations[i].area);
                }
              }

            }

            if (exp.end_zone != null) {
              exp.travelpath = exp.travelpath.concat(' => ' + exp.end_zone);
            }
            if (exp.end_area != null) {
              exp.travelpath = exp.travelpath.concat(',' + exp.end_area);
            }

          }
        }
        if (check === 'approve') {
          const el: HTMLElement = this.imageClick.nativeElement;
          el.click();
        } else {
          const el: HTMLElement = this.detailClick.nativeElement;
          el.click();
        }
      }
    }, error => {
      // console.log(error);
    });
  }

  goBack(modalName) {
    $(modalName).modal('hide');
    if (modalName === '#expensetypepopupModal') {
      $('#expensemasterModal').modal('show');
    } else if (modalName === '#staypopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#travelpopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#allowancepopupModal') {
      $('#expensetypepopupModal').modal('show');
    } else if (modalName === '#otherspopupModal') {
      $('#expensetypepopupModal').modal('show');
    }
  }

  private setCurrentLocation(locationType) {
    // console.log(locationType);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        if (locationType === 'start' && this.newExpenseData.lat && this.newExpenseData.long) {
          this.latitude = this.newExpenseData.lat;
          this.longitude = this.newExpenseData.long;
        } else if (locationType === 'end' && this.newExpenseData.end_lat && this.newExpenseData.end_long) {
          this.latitude = this.newExpenseData.end_lat;
          this.longitude = this.newExpenseData.end_long;
        } else {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }

        this.zoom = 15;
        if (this.map === null) {
          this.map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
              zoom: 15,
              center: {lat: this.latitude, lng: this.longitude},
              mapTypeId: 'terrain',
            }
          );
        }
        this.marker = new google.maps.Marker({
          // The below line is equivalent to writing:
          // position: new google.maps.LatLng(-34.397, 150.644)
          position: {lat: this.latitude, lng: this.longitude},
          map: this.map,
        });
        if (this.infowindow === null) {
          this.infowindow = new google.maps.InfoWindow();
        }
        this.infowindowContent = document.getElementById(
          'infowindow-content'
        ) as HTMLElement;
        this.infowindow.setContent(this.infowindowContent);
        this.infowindow.open(this.map);

        if (locationType != 'stop') {
          this.getAddress(this.latitude, this.longitude, locationType);
        }

        this.map.addListener('click', (e) => {
          // console.log(this.type);

          this.map.setCenter(e.latLng);
          this.marker.setPosition(e.latLng);
          // this.getAddress(e.latLng.lat(),e.latLng.lng(),locationType);
          this.geoCoder.geocode({
            location: {
              lat: this.marker.getPosition().lat(),
              lng: this.marker.getPosition().lng()
            }
          }, (results, status) => {

            if (status === 'OK') {
              // console.log(results[0]);
              if (results[0]) {
                this.infowindowContent.children['place-name'].textContent = results[0].address_components[0].short_name;
                this.infowindowContent.children['place-address'].textContent = results[0].formatted_address;
                this.infowindow.open(this.map, this.marker);
              }
            }
          });
        });

      });
    }
  }

}
