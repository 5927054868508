import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VanBonusReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Bonus Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;

    this.showPinnedFooter = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/van_bonus_report';
    this.columnsWithAggregation = ['bonus', 'penalty', 'rejected', 'loadin', 'loadout', 'opening', 'sales', 'damage', 'sample', 'promo', 'return', 'closing', 'cancelled', 'deviation'];
    const inThis = this;
    this.columnDefs = [

      {
        headerName: 'DATE', field: 'stock_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.stock_date && params.data.stock_date !== 'TOTAL') {
              return inThis.datePipe.transform((new Date(params.data.stock_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.stock_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'STATE', field: 'dc_state', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', width: 100, enableRowGroup: true},
      {headerName: 'DC', field: 'dc', width: 100, enableRowGroup: true},
      {headerName: 'Van', field: 'user', width: 80},
      {headerName: 'Product Code', field: 'product_code', enableRowGroup: true, width: 150},
      {headerName: 'Product', field: 'product', width: 80},
      {headerName: 'Bonus', field: 'bonus', width: 80},
      {headerName: 'Penalty', field: 'penalty', width: 80},
      {headerName: 'Rejected', field: 'rejected', width: 80,
        cellStyle: params => {
          if (params.data) {
            if (params.value > 0) {
              return {backgroundColor: '#F8D1C8'};
            }
          }
        }
      },
      {headerName: 'Rej Reason', field: 'rej_reason', width: 80},
      {headerName: 'Balance', field: 'loadin', width: 80},
      {headerName: 'Loadout', field: 'loadout', width: 80},
      {headerName: 'Opening', field: 'opening', width: 80},
      {headerName: 'Sales', field: 'sales', width: 80},
      {headerName: 'Damage', field: 'damage', width: 80},
      {headerName: 'Sample', field: 'sample', width: 80},
      {headerName: 'Promo', field: 'promo', width: 80},
      {headerName: 'Return', field: 'return', width: 80},
      {headerName: 'Closing', field: 'closing', width: 80},
      /* {headerName: 'Add Return', field: 'closing', width: 80},*/
      {headerName: 'Cancelled', field: 'cancelled', width: 80},
      {headerName: 'Deviation', field: 'deviation', width: 80},

    ];
  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    if (this.apiData && this.apiData.length  > 0) {
      this.apiData.forEach(row => {
        const indrow = row;
        if (row.config_json && row.config_json.hasOwnProperty("bonus") && row.config_json.bonus !== null && row.config_json.bonus !== "") {
          indrow.bonus = parseFloat(row.config_json.bonus);
        } else {
          indrow.bonus = 0;
        }
        if (row.config_json && row.config_json.hasOwnProperty("penalty") && row.config_json.penalty !== null && row.config_json.penalty !== "") {
          indrow.penalty = parseFloat(row.config_json.penalty);
        } else {
          indrow.penalty = 0;
        }
        if (row.config_json && row.config_json.hasOwnProperty("rejected") && row.config_json.rejected !== null && row.config_json.rejected !== "") {
          indrow.rejected = parseFloat(row.config_json.rejected);
        } else {
          indrow.rejected = 0;
        }
        if (row.config_json && row.config_json.hasOwnProperty("rej_reason") && row.config_json.rej_reason !== null && row.config_json.rej_reason !== "") {
          indrow.rej_reason = parseFloat(row.config_json.rej_reason);
        } else {
          indrow.rej_reason = '';
        }
        this.rowData.push(indrow);
      });
    }
  }
}
