import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';


@Component({
  selector: 'app-secondary-sales-report',
  templateUrl: '../_common/reportbase.html',

})


// tslint:disable-next-line:component-class-suffix
export class SecondarySalesReport extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];

  ngOnInit() {
    this.setTitle();
    //this.configureGrid();
    this.API_URL = '/api/pwa_reports/get_outlet_sales';
  }

  setTitle() {
    this.title = 'Outlet Sales Report';
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureGrid() {

    this.sharedService.setHeader('Secondary Sales');
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;


    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Customer',
      field: 'o_name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
    const inThis = this;
    this.columnDefs = [
      {headerName: 'Region', field: 'region',  width: 25},
      {headerName: 'Region Mgr', field: 'region_manager', hide: true,  width: 50},
      {headerName: 'State', field: 'state', hide: true,  width: 50},
      {headerName: 'Cluster', field: 'cluster',  width: 30},
      {headerName: 'Cluster Mgr', field: 'cluster_manager', hide: true,  width: 50},
      {headerName: 'Team', field: 'team', hide: true,  width: 50},
      {headerName: 'Emp Code', field: 'emp_code', hide: true, width: 50},
      {headerName: 'Salesman', field: 'user_name',  width: 50},
      {headerName: 'Zone', field: 'zone', hide: true,  width: 50},
      {headerName: 'Beat', field: 'beat', hide: true,  width: 50},
      {headerName: 'Date', field: 'date',  width: 50,
       /* valueGetter: params => {
          if (params.data) {
            if (params.data.date) {
              return inThis.datePipe.transform((new Date(params.data.date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.date;
            }
          } else {
            return '';
          }
        }*/
      },
      {headerName: 'SCode', field: 'dist_code', hide: true, width: 40},
      {headerName: 'Supplier', field: 'dist_name',  width: 50},
      {headerName: 'Type', field: 'dist_type',  width: 30},
      {headerName: 'Retailer ID', field: 'id',  width: 40},
      {headerName: 'RCode', field: 'o_code', width: 40},
      {headerName: 'Retailer', field: 'o_name',  width: 60},
      {headerName: 'Order No', field: 'o_ref', hide: true,  width: 50},
      {headerName: 'Channel', field: 'channel', width: 50},
      {headerName: 'Type', field: 'o_type',  width: 40},
      {headerName: 'Program', field: 'program', hide: true,  width: 80},
      {headerName: 'Family', field: 'family',  hide: true, width: 50},
      {headerName: 'Brand', field: 'brand',  hide: true, width: 40},
      {headerName: 'PCode', field: 'p_code', hide: true, width: 50},
      {headerName: 'Product', field: 'product',  width: 60},
      {headerName: 'Qty', field: 'qty', width: 40, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'Subtotal', field: 'price_subtotal', width: 60, aggFunc: 'sum', cellStyle: {textAlign: 'right'},
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('price_subtotal') && params.data.price_subtotal !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.price_subtotal).toFixed(params.context.componentParent.apiService.decimalPolicy)));
          }
        }
      },
      {headerName: 'Category', field: 'category',  width: 30},
      {headerName: 'Report UOM', field: 'r_qty', width: 50, hide: true, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'MRP', field: 'mrp', hide: true,  width: 80, cellStyle: {textAlign: 'right'}},
      {headerName: 'Unscheduled', field: 'unscheduled', hide: true, width: 80},
    ];

    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['qty', 'price_subtotal'];

    if ( this.apiData.prd_attr != null) {
      this.attributeTypes = this.apiData.prd_attr.map(p => p.attribute)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      this.attributeTypes.forEach(item => {
        this.columnDefs.push({
          headerName: item,
          field: item,
          width: 100,
          aggFunc: 'sum'
        });
      });
    }
  }


  configureData() {

    this.rowData = [];
    let saleRows: any = {};
    saleRows = this.apiData.sales;
    const inThis = this;
    for (const index in saleRows) {
      let indRow = {};
      const attRow = [];

      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = this.apiData.prd_attr.filter(api => api.prd_id === saleRows[index].prd_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }

      indRow = {
        ...saleRows[index],
        ...attRow
      };
      this.rowData.push(indRow);
    }
  }

}
