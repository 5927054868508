import {ReportBase} from "../_common/reportbase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-stock-audit-ms-report',
  templateUrl: '../_common/reportbase.html',
})
export class StockAuditMarketShareReportComponent extends ReportBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('Tertiary Audit Market Share Report');

    let datas: any = {};
    datas = history.state;

    this.userData.customFilter['id'] = datas.audit_id;

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.userData.type = 'tertiary';

    // this.loadInBatches(50000, 'id');
    // this.showProgressCustomText = true;
    // this.container = 'partner_answer';

    this.API_URL = '/api/retail_audit_sessions/getStockAuditMarketShare';

    this.configureGrid();

    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.loadServerData();
    }
  }

  configureGrid() {
    this.columnDefs = [

      {headerName: "REGION", field: "region",  enableRowGroup: true, width: 60},
      {headerName: "CLUSTER", field: "cluster",  enableRowGroup: true, width: 60},
      {headerName: "TCODE", field: "tcode", enableRowGroup: true, width: 60, hide: true },
      {headerName: "TERRITORY", field: "territory", enableRowGroup: true, width: 60, hide: true},
      {headerName: "TEAM", field: "team", enableRowGroup: true, width: 60},
      {headerName: "SALESMAN", field: "user_name", enableRowGroup: true, width: 60 },
      {headerName: "EMP CODE", field: "emp_code", width: 40},
      {headerName: "DATE", field: "create_date",  width: 40},
      { headerName: 'CODE', field: 'code',  width: 40},
      {headerName: "RETAILER", field: "retailer",  width: 80},
      { headerName: 'TYPE', field: 'o_type', enableRowGroup: true,  width: 50},
      { headerName: 'CLASS', field: 'class', enableRowGroup: true,  width: 40},
      { headerName: 'PROGRAM', field: 'program',  enableRowGroup: true, width: 40},

    ];
  }

  postProcessData() {
    this.configureData();
    return;
  }

  configureData() {
    this.rowData = [];

    let masterData: any = {};
    let headersData: any = {};
    let linesData: any = {};

    masterData = this.apiData[0]['sessions'];
    headersData = this.apiData[0]['headers'];
    linesData = this.apiData[0]['lines'];

    if (headersData !== null && headersData !== undefined && headersData.length > 0) {
      for (const categ of headersData) {

        const categ_col = { headerName: categ.categ, field: 'c_' + categ.categ };

        categ_col['children'] = [];
        for (const family of categ.families) {
          const family_col = { headerName: family.family, field: 'f_' + family.family};
          family_col['children'] = [];
          for (const sku of family.skus) {
            family_col['children'].push({headerName: sku, field: 'p_' + categ.categ + '_' + family.family + '_' + sku});
          }
          categ_col['children'].push(family_col);
        }

        const ms_col = { headerName: 'Market Share', field: 'ms_' + categ.categ };
        ms_col['children'] = [];
        for (const family of categ.families) {
          ms_col['children'].push({ headerName: family.family, field: 'ms_' + categ.categ + '_' + family.family});
        }
        categ_col['children'].push(ms_col);

        this.columnDefs.push(categ_col);
      }
    }

    if (masterData !== null && masterData !== undefined && masterData.length > 0) {
      for (const item of masterData) {
        let indRow: any = {};
        indRow = item;

        const lines = linesData.filter(x => x.session_id === item.id);
        const ms_cols = [];
        if (lines !== null && lines !== undefined && lines.length > 0) {
          for (const line of lines) {
            const key = 'p_' + line.categ + '_' + line.family + '_' + line.sku;
            indRow[key] = line.volume;

            const ms_col_key = 'ms_' + line.categ + '_' + line.family;
            if (line.ms) {
              if (!indRow.hasOwnProperty(ms_col_key)) {
                indRow[ms_col_key] = line.ms;
                ms_cols.push(ms_col_key);
              } else {
                indRow[ms_col_key] = indRow[ms_col_key] + line.ms;
              }
            }
          }

          for (const ms_col of ms_cols) {
            indRow[ms_col] = Math.round(indRow[ms_col]);
          }
        }

        this.rowData.push(indRow);
      }
      this.filteredData = this.rowData;
    }
    console.log(this.rowData);
  }


}
