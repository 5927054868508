import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerVisitReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Visit Report');
    this.showableFilters.salesman = true;
    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/partnerVisitReport';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'RETAILER TERRITORY', field: 'territory', width: 80},
      {headerName: 'TERRITORY CODE', field: 'ter_code', width: 80, hide: true},
      {headerName: 'VISIT ID', field: 'visit_id', width: 150},
      {headerName: 'PURPOSE', field: 'purpose', width: 150},
      {headerName: 'PLANNED DAY', field: 'planned_day', width: 100},
      {
        headerName: 'SCHEDULE DATE', field: 'schedule_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_date) {
              return inThis.datePipe.transform((new Date(params.data.schedule_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.schedule_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'START TIME', field: 'start_time', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_time) {
              return inThis.datePipe.transform((new Date(params.data.start_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.start_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'END TIME', field: 'end_time', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time) {
              return inThis.datePipe.transform((new Date(params.data.end_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.end_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time && params.data.start_time) {
              const diff = new Date(params.data.end_time + ' UTC').getTime() - new Date(params.data.start_time + ' UTC').getTime();
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'SCODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'user', width: 80},
      {headerName: 'USER TERRITORY', field: 'user_territory_name', width: 80},
      {headerName: 'USER TERRITORY CODE', field: 'user_territory_code', width: 80, hide: true},
      {headerName: 'LEAD', field: 'l_name', width: 80},
      {headerName: 'PARTNER ID', field: 'o_id', width: 80},
      {headerName: 'PARTNER CODE', field: 'o_code', width: 80},
      {headerName: 'PARTNER', field: 'o_name', width: 80},
      {headerName: 'MOBILE', field: 'o_mobile', width: 80},
      {headerName: 'PARTNER TYPE', field: 'partner_type', width: 80},
      {headerName: 'STATUS', field: 'status', width: 80},
      {headerName: 'START REMARK', field: 'start_details', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data?.config_json?.start_details) {
              return params.data.config_json.start_details;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'END REMARK', field: 'end_details', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data?.config_json?.end_details) {
              return params.data.config_json.end_details;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'UNSCHEDULED VISIT', field: 'uv', width: 80},
      {
        headerName: "VISIT LOCATION", field: "url", maxWidth: 80, menuTabs: [],
        valueGetter(params) {
          if (params.data) {
            if (params.data.lat != null && params.data.lat !== "0.0") {
              return 'http://www.google.com/maps/dir/' + params.data.lat + ',' + params.data.lng + '/'
                + params.data.o_lat + ',' + params.data.o_lng;
            } else {
              return 'http://maps.google.com/maps?q=' + params.data.lat + ',' + params.data.long;
            }
          }
        },
        cellRenderer: params => {
          if (params.data) {
            if (params.data.lat != null && params.data.lat !== "0.0") {
              return '<div style="text-align: center;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            } else {
              return '<div style="text-align: center;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            }
          }
        }
      },
      {headerName: 'VISIT LAT', field: 'lat', width: 80, hide: true},
      {headerName: 'VISIT LNG', field: 'lng', width: 80, hide: true},
      {
        headerName: 'PARTNER LAT', field: 'o_lat', width: 80, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data['o_lat']) {
              return parseFloat(params.data.o_lat).toFixed(3);
            } else {
              return "";
            }
          }

        }
      },
      {
        headerName: 'PARTNER LNG', field: 'o_lng', width: 80, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data['o_lng']) {
              return parseFloat(params.data.o_lng).toFixed(3);
            } else {
              return "";
            }
          }

        }
      },
      {headerName: 'PARTNER ADDRESS', field: 'address', width: 150},

    ];

  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'url') {
      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('lat') && rows[0].lat != null && rows[0].lat !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '70%',
            maxHeight: '95%',
            data: {
              latitude: parseFloat(rows[0].o_lat),
              longitude: parseFloat(rows[0].o_lng),
              end_latitude: parseFloat(rows[0].lat),
              end_longitude: parseFloat(rows[0].lng),
              title1: 'Outlet',
              title2: 'Visit'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '65%',
            data: {
              latitude: parseFloat(rows[0].o_lat),
              longitude: parseFloat(rows[0].o_lng),
              end_latitude: parseFloat(rows[0].lat),
              end_longitude: parseFloat(rows[0].lng),
              title1: 'Outlet',
              title2: 'Visit'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        }


      }
    }
  }

}
