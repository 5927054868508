import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-numeric-cell',
  template: `<span *ngIf="!editMode"  class="element-req">{{value}}</span>
  <!--<div class="date-pickersmall" *ngIf="editMode">
    <span class="datepicker-icon"><img src="../../assets/date.png"></span>
    <input [selectMode]="'single'" [readonly]="true" [(ngModel)]="value" [owlDateTimeTrigger]="dt"
           [owlDateTime]="dt" placeholder="Date">
    <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
  </div>-->
  <div *ngIf="editMode" class="date-pickercell">
    <input [selectMode]="'single'" [readonly]="true" [(ngModel)]="value" [owlDateTimeTrigger]="dt"
           [owlDateTime]="dt" placeholder="Date">
    <span class="datepicker-icon" [owlDateTimeTrigger]="dt"><img src="../../assets/images/date.svg"></span>
    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
  </div>`,
})
export class DateEditRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public editMode = false;
  public context;
  private value;
  datePipe;
  agInit(params: any): void {
    this.params = params;
    if (params.value) {
      this.value = params.value;
    }
    if (this.params.context.componentParent) {
      this.context = this.params.context.componentParent;
    } else {
      this.context = this.params?.context?.params?.context?.context?.componentParent;
    }
    if (this.context) {
      this.editMode = this.context.editMode;
      this.datePipe = this.context.datePipe;
    }
  }

  refresh(): boolean {
    return false;
  }

  changeDate(dt) {
    this.value = this.datePipe.transform(this.value, 'yyyy-MM-dd');
  }
}
