import {Component, OnInit, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from "../_services/api.service";

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor(private router: Router,
              private apiService: ApiService) { }

  ngOnInit() {
  }

  home() {

    this.router.navigate([this.apiService.redirect_url+""]);
  }

}
