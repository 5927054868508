import { Component, OnInit, ElementRef, Renderer2, Input } from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-zip-print',
  templateUrl: './zip-print.component.html',
  styleUrls: ['./zip-print.component.scss']
})
export class ZipPrintComponent implements OnInit {
  @Input() order_detail: object;
  public math = Math;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              public apiService: ApiService,
              private datePipe: DatePipe,
              private sharedService: SharedService) { }

  ngOnInit() {
    if (this.order_detail['date_due']) {
      this.order_detail['date_due'] = this.datePipe.transform((new Date(this.order_detail['date_due'] + ' UTC')).toString(), 'dd/MM/yyyy');
    }
    if (!this.order_detail['has_einvoice']) {
      this.makeQrCode();
    }
  }

  makeQrCode() {
    const payload = {
      SellerName  : this.order_detail['dc_name'],
      SellerTaxID : this.order_detail['dc_gstn'],
      SellerRegNo : this.order_detail['supplier_pan'],
      BuyerName   : this.order_detail['partner'],
      BuyerTaxID  : this.order_detail['partner_gstn'],
      BuyerRegNo  : this.order_detail['pan'],
      DocNo       : this.order_detail['invoice_no'],
      DocTyp      : "INV",
      DocDt       : this.order_detail['invoice_date'],
      DocTime     : this.order_detail['order_date'],
      TotVal      : this.order_detail['amount_total'],
      TaxAmt      : this.order_detail['amount_tax'],
      Items       : this.order_detail['total_qty'],
      Issuer      : "salesdiary",
      CKey        : this.order_detail['ckey'],
    };
    if (this.order_detail['order_date']) {
      payload['DocTime'] = this.order_detail['order_date'].split(' ')[1];
    }
    this.order_detail['signed_qr'] = JSON.stringify(payload);
  }

}
