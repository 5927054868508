import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from "../../error/error-handler";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class OutletProductRecommendationComponent extends PageBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  attributeData: any = [];
  attributeTypes: any [];

  verticalData: any = [];
  verticalTypes: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Product Recommendation');
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.uploadTask = true;
    this.uploadTaskwithType = true;
    this.userData.offsetID = 0;
    this.userData.offset = 50000;
    this.loadInBatches(50000, 'id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_reports/getOutletProductRecoReport';

    this.uploadTypeList = [
      {id: 'product', itemName: "Product"},
      {id: 'template', itemName: "Template"}
    ];
    this.excel_sample = [
      {
        ProductCode: '95',
        RetailerCode: '26901',
        Qty: 10,
        type: ''
      },
      {
        ProductCode: '96',
        RetailerCode: '11071',
        Qty: 5,
        type: 'all'
      },

    ];
    this.excel_sample_title = 'outlet_product_recommendation';
    this.selectedUploadType = 'product';
    this.showExcelSample = true;
    this.fetchAttributes();
  }

  fetchAttributes() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.apiService.post('/api/pwa_reports/getProductAttributes', this.userData)
      .subscribe(res => {

          console.log(res);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.attributeData = res.results.data;
            if (this.attributeData) {
              this.attributeTypes = this.attributeData.map(p => p.attribute)
                .filter((type, index, arr) => arr.indexOf(type) === index);
            }
            /* this.verticalData = res.results.data.vert;
             if (this.verticalData) {
               this.verticalTypes = this.verticalData.map(p => p.vertical)
                 .filter((type, index, arr) => arr.indexOf(type) === index);
             }*/
            //this.customFilter.values = res.result.data;
            this.loadServerData();
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

  configureGrid() {

    const inthis = this;
    this.columnDefs = [
      {
        headerName: '',
        pinned: 'left',
        children: [
          {headerName: 'RETAILER', field: 'r_name', width: 150, pinned: 'left'},
          {headerName: 'PRODUCT', field: 'p_name', width: 150, pinned: 'left'}
        ]
      },
      {
        headerName: '',
        children: [
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'CLUSTER', field: 'cluster', width: 80},
          {headerName: 'DC', field: 'dc', width: 80},
          {headerName: 'TERRITORY', field: 'territory', width: 100},
          {headerName: 'TERR CODE', field: 'tcode', width: 80},
          {headerName: 'TERR MANAGER', field: 'ter_mgr', width: 100},
          {headerName: 'RCODE', field: 'r_code', width: 80},
          {headerName: 'VERTICAL', field: 'vertical', width: 150},
          {headerName: 'PCODE', field: 'p_code', width: 80},
          {headerName: 'FAMILY CODE', field: 'family_code', width: 80},
          {headerName: 'FAMILY', field: 'family_name', width: 80},
          {headerName: 'BRAND', field: 'brand', width: 80},
        ]
      },
      {
        headerName: 'ATTRIBUTES',
        children: []
      },
      {
        headerName: 'RECOMMENDATION',
        children: [
          {headerName: 'NAME', field: 'name', width: 80},
          {headerName: 'TYPE', field: 'type', width: 80},
          {
            headerName: 'SUGG', field: 'sugg', width: 80,
            valueGetter: this.configAllValueGetter('sugg')
          },
          {
            headerName: 'UMIN', field: 'umin', width: 80,
            valueGetter: this.configAllValueGetter('umin')
          },
          {
            headerName: 'UMAX', field: 'umax', width: 80,
            valueGetter: this.configAllValueGetter('umax')
          },
          {
            headerName: 'LMIN', field: 'lmin', width: 80,
            valueGetter: this.configAllValueGetter('lmin')
          },
          {
            headerName: 'LMAX', field: 'lmax', width: 80,
            valueGetter: this.configAllValueGetter('lmax')
          },
          {
            headerName: 'EXPECTED CLOSING', field: 'lmax', width: 80,
            valueGetter: this.configValueGetter('expected_closing')
          },
        ]
      },
    ];

    if (this.attributeTypes) {
      for (const key in this.attributeTypes) {
        this.columnDefs[2].children.push({
          headerName: this.attributeTypes[key],
          field: this.attributeTypes[key],
          width: 100,
          aggFunc: 'sum'
        });
      }
    }
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config) {
          if (params.data.config[field]) {
            return params.data.config[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }


  configAllValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config && params.data.config.hasOwnProperty('all')) {
          if (params.data.config.all[field]) {
            return params.data.config.all[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // this.get_favorites();
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    const inThis = this;
    this.apiData.forEach(obj => {
      let indRow = {};
      const attRow = [];
      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = inThis.attributeData.filter(api => api.prd_id === obj.product_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }
      indRow = {
        ...obj,
        ...attRow
      };
      this.rowData.push(indRow);
    });
  }

  async uploadexcel() {
    //debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    let distinct_order = [];
    const line_items = [];
    const inthis = this;
    let succcessCount = 0;
    let failedCount = 0;
    let failed_tasks = [];
    let task_status = "";
    let unauthorised_access = false;
    let upload_status = 0;
    const paramObject: any = {};
    paramObject.access_token = this.userData.access_token;
    let invalid = false;
    let apiurl = '';
    if (this.selectedUploadType === 'product') {
      apiurl = '/api/api_connects/setOutletSaleSuggestion';
    } else {
      apiurl = '/api/api_connects/setOutletTemplateSuggestion';
    }
    let retailers = new Set();
    for (const item of inthis.upload_task_data) {

      if (!item.ProductCode || item.ProductCode === "") {
        invalid = true;
        this.toastr.warning('Product Code cannot be empty');
        break;
      }
      if (!item.RetailerCode || item.RetailerCode === "") {
        invalid = true;
        this.toastr.warning('Retailer Code cannot be empty');
        break;
      }
      if (!item['Qty']) {
        invalid = true;
        this.toastr.warning('Quantity cannot be empty');
        break;
      }
      retailers.add(item.RetailerCode);
    }
    if (!invalid) {
      let upload_task;
      let upload_obj = [];
      for (const retailer of retailers) {
        const products = inthis.upload_task_data.filter(x => x.RetailerCode === retailer);
        const line = [];
        for (const product of products) {
          line.push({
            product_code: product.ProductCode.toString(),
            qty: product.Qty,
            type: product.type
          });
        }
        upload_obj.push({
          code: retailer.toString(),
          products: line
        });
      }

      if (upload_obj.length > 0) {
        do {
          upload_task = upload_obj.splice(1000);
          paramObject.data = upload_obj;
          const resp = await this.apiService.postPromise(apiurl, paramObject);
          if (resp.hasOwnProperty('results')) {
            if (resp.results.hasOwnProperty('status')) {

            }
            if (resp.results.hasOwnProperty('received') && resp.results.hasOwnProperty('processed') ) {
              failedCount += (resp['results'].received - resp['results'].processed);
              succcessCount += resp['results'].processed;
            } else {
              failedCount += upload_obj.length;
            }
            if (resp.results.hasOwnProperty('error_details') && resp.results.error_details && resp.results.error_details.length > 0) {
              for (const error of resp.results.error_details) {
                if (task_status === "") {
                  task_status = error.error +  " : "  +  error.details;
                } else {
                  task_status = task_status + ", " + error.error +  ' : ' +  error.details;
                }
              }
            }
          } else {
            failedCount += upload_obj.length;
          }
          distinct_order = upload_task;
        } while (distinct_order.length > 0);
        if (failedCount > 0) {
          upload_status = 1;
        } else {
          upload_status = 0;
        }
        if (unauthorised_access) {
          this.uploadCaption = [task_status];
        } else {
          this.uploadCaption = ['Uploading completed, Successes: ' + succcessCount + ',Failures: ' + failedCount + " - " + task_status, upload_status];
        }
        this.uploading = false;
        this.upload_loader = false;
        this.fileUp.nativeElement.value = null;
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.fileUploaded = null;
        this.loadServerData();
      } else {
        // this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
      }
    } else {
      this.uploading = false;
      this.upload_loader = false;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }

  }
}
