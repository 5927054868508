import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {NgSelectComponent} from "@ng-select/ng-select";
import {DetailPageBase} from "../../pagebase/DetailPageBase";
import {AddBeatRetailerPopupComponent} from "../add_beat_retailer_popup/add_beat_retailer_popup.component";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {RemoveBeatRetailerPopupComponent} from "../remove_beat_retailer_popup/remove_beat_retailer_popup.component";
import {ChangeBeatSalesmanPopupComponent} from "../change_beat_salesman_popup/change_beat_salesman_popup.component";


@Component({
  selector: 'app-beat-plan-detail',
  templateUrl: 'beat_plan.html',
  styleUrls: ['../../pagebase/pagebase_detail.scss']
})

export class BeatPlanDetailPageComponent extends DetailPageBase implements IDetailPage, OnInit {

  territoriesList: any = [];
  salesmenList: any = [];
  beatTravelTypes: any =  [];
  schedule_type_list = [{label: 'manual', value: 'manual'}, {label: 'daily', value: 'daily'}, {label: 'recurring', value: 'recurring'}];

  ngOnInit(): void {
    this.setTitle('Beat Plan');
    this._Detail_GET_URL = "/api/beat_plans/get_beat_plan_detail";
    this._Detail_SAVE_URL = "/api/beat_plans/create_beat_plan";

    this.context = {componentParent: this};

    // this.getBeatTravelTypes();

    this.configureGrid();

    this.initialize_default_values(() => {

    });

    this.readPageParms(() => {
      this.loadPage();
    });
  }

  //Page Initilizefunctions
  initialize_default_values(callback) {

    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
      this.salesmenList.sort((a, b) => a.itemname.localeCompare(b.itemame));
    }

    if (localStorage.getItem('all_territories') !== null && localStorage.getItem('all_territories') !== null) {
      this.territoriesList = JSON.parse(localStorage.getItem('all_territories'));
      this.territoriesList.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    this.activeObject.salesman_id = this.salesmenList[0].id;
    this.activeObject.territory_id = this.territoriesList[0].id;

    if (localStorage.getItem('all_travel_types') !== null && localStorage.getItem('all_travel_types') !== 'null') {
      this.beatTravelTypes = JSON.parse(localStorage.getItem('all_travel_types'));
    }

    return callback();
  }

  // getBeatTravelTypes() {
  //   const paramObject: any = {};
  //   paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
  //   this.apiService.getFilter('/api/beat_travel_types', this.userContext.access_token, paramObject)
  //     .subscribe(res => {
  //       console.log(res);
  //       if (res.length > 0) {
  //         this.beatTravelTypes = res;
  //         this.activeObject.travel_type_id = this.beatTravelTypes[0].id;
  //       }
  //     });
  // }



  loadPage() {
    this.loadActiveObject(() => {
      if (this.activeID > 0) {
        console.log(this.activeObject);
        this.loadBeatRetailers();
      } else {
        //Load all default Values
        this.activeObject['id'] = 0;
      }
    });
  }

  loadBeatRetailers() {
    this.rowData = [];
    if (this.activeObject && this.activeObject['beat_retailers']) {
      this.rowData = this.activeObject['beat_retailers'];
      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  //GRID Functions
  configureGrid() {
    const inthis = this;
    this.columnDefs = [
      {headerName: "BPR ID", field: "bpr_id", width: 40, hide: true},
      {headerName: "ID", field: "r_id", width: 80, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "BEAT ID", field: "bp_id", width: 50, hide: true},
      {headerName: "CODE", field: "code", width: 80},
      {headerName: "OUTLET", field: "name", width: 150},
      {headerName: "AVG BILL",  field: "abv", width: 80},
      {headerName: "AVG LINES SOLD",  field: "als", width: 80},
      {headerName: "ORDER FREQ",  field: "order_frequency", width: 60},
      {headerName: "LAST ORDERED",  field: "last_order_date", width: 60,
        valueGetter: params => this.formatTime(params.data.last_order_date)},
      {headerName: "PAYMENT DUE",  field: "payment_due_date", width: 60,
        valueGetter: params => this.formatTime(params.data.payment_due_date)},
      {headerName: "LAST CHECKIN",  field: "last_successful_checkin", width: 60,
        valueGetter: params => this.formatTime(params.data.last_successful_checkin)},
    ];

  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }

  formatTime(dataTimeField) {
    if (dataTimeField) {
      return this.datePipe.transform((new Date(dataTimeField + ' UTC')).toString(), 'HH:mm:ss');
    } else {
      return dataTimeField;
    }
  }


  //Page Events
  changeTerritory(territory_id) {
    const selectedTerritory = this.territoriesList.find(item => item.id === territory_id);
    if (selectedTerritory && selectedTerritory.manager_id) {
      const manager = this.salesmenList.find(item => item.id === selectedTerritory.manager_id);
      console.log(manager);
      if (manager) {
        this.activeObject['territory_manager'] = manager.itemname;
      }
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.salesmenList.filter(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['emp_code'] = selecteduser.emp_code;
      }
    }
  }


  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
  }

  openChangeSalesmanDialog() {
    const beatsIds = [this.activeObject.id];
    const displayMessage = "You are about to change the salesman for the selected beat";

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "450px",
      data: {
        beat_ids: beatsIds,
        message: displayMessage
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(ChangeBeatSalesmanPopupComponent, config);
    confirmdiaeref.componentInstance.changeData.subscribe(status => {
      console.log('remove reponse : ' + status );
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }

  openChangeBeatStatusPopup(status) {
    const operation = (status === true ? 'Activate' : 'De-Activate');
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: operation + ' Beat !',
        message1: " You are about to " + operation + " " + " beat: " + this.activeObject['name'],
        message2: "",
        showNoButton: true,
        yesButton: operation,
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        console.log(operation);
        this.saveSelectedField({active : status}, (err, result) => {
          if(err) {
            this.toastr.error(err);
          } else {
            this.dialog.closeAll();
            window.location.reload();
            this.toastr.success("Success!");
          }
        });
      } else {
        this.dialog.closeAll();
        console.log("Discard");
      }
    });
  }

  changeStatus(status) {

  }

  changeSalesman() {

  }

  editBeatPlan() {
    this.editMode = true;
    // this.columnDefs[5].editable = this.isGridCellEditable();
    // this.columnDefs[12].editable = this.isGridCellEditable();
    this.gridApi.redrawRows();

  }

  isGridCellEditable() {
    return this.editMode;
  //  TODO: Check for status
  }

  addBeatRetailers() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "1000px",
      maxHeight: "620px",
      data : {
        beatPlanDetails: this.activeObject
      }
    };

    const creatediaeref = this.dialog.open(AddBeatRetailerPopupComponent, config);

    creatediaeref.componentInstance.addretailersubmit.subscribe(data => {
      this.toastr.success('Beat Retailer Added Successfully');
      window.location.reload();
    });
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'delete' && this.editMode) {
      this.deleteBeatRetailer();
    } else {

    }
  }

  deleteBeatRetailer() {
    const rows = this.gridApi.getSelectedRows();
    if (rows) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "330px",
        data: {
          type: "Copy",
          retailerData: rows,
          uq_ret: rows.length,
          uq_bp: 1,
          uq_ter: 1
        },
        panelClass: 'custom-dialog-container'
      };

      const confirmdiaeref = this.dialog.open(RemoveBeatRetailerPopupComponent, config);
      confirmdiaeref.componentInstance.removeData.subscribe(status => {
        console.log('remove reponse : ' + status);
        if (status === 'success') {
          this.dialog.closeAll();
          window.location.reload();
        }
      });
    }
  }

  //Page core functions
  saveBeatPlan() {
    this.editMode = false;

    //TODO: Complete basic validation of page and line items


    //TODO: Validation
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {
        this.toastr.success(result);
      }

      this.loadPage();
    });
  }




}
