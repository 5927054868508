import {Component} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";


@Component({
  selector: 'app-asset-deployed-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class AssetDeployedUploadComponent extends BaseUpload {

  setViewParams() {
    this.batchSize = 10;
    this.API_URL = '/api/partner_assets/asset_deployed_upload';
    this.header = 'Asset Deployed Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    /*this.excel_columns = [
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      ['Asset Serial', 'Asset Type Code', 'Outlet Code', 'Installed Date', 'Asset Qty', 'Employee Code', 'Brand'],
      ['WE13241', 'EXFL01', 'R1234', '2024-01-01', '5', 'E1234', 'B12']
    ];*/
    this.excel_columns = [{C: 'A', H: 'Asset Serial', V: 'WE13241', required: true},
        {C: 'B', H: 'Asset Name', V: 'Asset 456', required: true},
        {C: 'C', H: 'Asset Type Code', V: 'EXFL01', required: true},
        {C: 'D', H: 'Outlet Code', V: 'R1234', required: true},
        {C: 'E', H: 'Installed Date', V: '2024-01-01', required: true},
        {C: 'F', H: 'Asset Qty', V: '5', required: false},
        {C: 'G', H: 'Employee Code', V: 'E1234', required: false},
        {C: 'H', H: 'Brand', V: 'B12', required: false}
      ];
    this.step1Text = 'Download sample for reference.';
    this.step2Text = 'Required fields are Asset Serial, Asset Name, Asset Type Code, Outlet Code, Installed Date.';
    this.actionText = 'Add new assets to be marked as deployed in the template';
    this.dataLoaded = true;
  }

  async fetchBaseData() {
  }

  sampleDownload1() {
    this.downloadSampleData('asset_deployed_template');
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Asset Serial', field: 'barcode', width: 100},
      {headerName: 'Asset Name', field: 'name', width: 150},
      {headerName: 'Asset Type Code', field: 'type', width: 100},
      {headerName: 'Outlet Code', field: 'o_code', width: 120},
      {headerName: 'Installed Date', field: 'start_date', width: 100},
      {headerName: 'Asset Qty', field: 'qty', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'Employee Code', field: 'emp_code', width: 100},
      {headerName: 'Brand', field: 'brand', width: 100}
    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.rowData = [];
    this.uploaded_data.forEach(row => {
      const indrow: any = {};
      indrow.barcode = row['Asset Serial'];
      indrow.name = row['Asset Name'];
      indrow.type = row['Asset Type Code'];
      indrow.o_code = row['Outlet Code'];
      indrow.start_date = row['Installed Date'];
      indrow.qty = row['Asset Qty'];
      indrow.emp_code = row['Employee Code'];
      indrow.brand = row['Brand'];
      if (!indrow['barcode'] || indrow['barcode'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Asset Serial Missing';
      }
      if (!indrow['name'] || indrow['name'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Asset Name Missing';
      }
      if (indrow['type'] === null || indrow['type'] === undefined || indrow['type'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Asset Type Code Missing';
      } else {
        indrow['type'] = indrow['type'].toString();
      }
      if (indrow['o_code'] === null || indrow['o_code'] === undefined || indrow['o_code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Outlet Code Missing';
      } else {
        indrow['o_code'] = indrow['o_code'].toString();
      }
      if (indrow['start_date'] === null || indrow['start_date'] === undefined || indrow['start_date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Installed Date Missing';
      } else {
        indrow['start_date'] = this.sharedService.convertExcelStringToDate(indrow['start_date']);
        if (!indrow['start_date'] || indrow['start_date'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid Installed Date';
        }
      }
      if (indrow['qty'] && !this.numPattern.test(indrow['qty'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Asset Quantity';
      } else {
        indrow['qty'] = parseInt(indrow['qty'], 10);
      }
      if (indrow['emp_code'] && !this.numchar_hyphen_underscore_Pattern.test(indrow['emp_code'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Employee Code';
      }
      if (indrow['brand'] && !this.numchar_hyphen_underscore_Pattern.test(indrow['brand'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Brand Code';
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';

        this.validRecords++;
      }
      inThis.rowData.push(indrow);
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
