import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'retail-audit-popup',
  templateUrl: './retail_audit_popup.component.html',
  styleUrls: ['./retail_audit_popup.component.scss']

})

export class RetailAuditPopupComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  P5='';
  P4='';
  P3='';
  API_URL='';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: 0,
    },
  }
  sessionData:any;

  constructor(
    private apiService: ApiService,
    private elRef: ElementRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.data.id = dialogdata.rowData.id;

    this.sessionData = dialogdata.rowData;
    if(!this.sessionData.score_json.hasOwnProperty('P5'))
    {
      this.P5='NA'
    }
    else {
      this.P5 = this.sessionData.score_json.P5.toString().concat('%');
    }
    if(!this.sessionData.score_json.hasOwnProperty('P4'))
    {
      this.P4='NA'
    }
    else {
      this.P4 = this.sessionData.score_json.P4.toString().concat('%');
    }
    if(!this.sessionData.score_json.hasOwnProperty('P3'))
    {
      this.P3='NA'
    }
    else {
      this.P3 = this.sessionData.score_json.P3.toString().concat('%');
    }
    console.log(this.sessionData);

    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'} as GridOptions;

    this.columnDefs = [
      { headerName: "CATEGORY", headerClass: 'myagheader', field: "category", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 100, resizable: true},
      { headerName: "PRODUCT", headerClass: 'myagheader', field: "product", sortable: true, filter: true, lockPosition: true,menuTabs: [], width: 100, resizable: true},
      { headerName: "<L", headerClass: 'myagheader', field: ">L", sortable: true, filter: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer:this.priorityCellRenderer,
        valueGetter: this.distributionValueGetter
      },
      { headerName: "N", headerClass: 'myagheader', field: "N", sortable: true, filter: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer:this.priorityCellRenderer,
        valueGetter: this.distributionValueGetter
      },
      { headerName: "P", headerClass: 'myagheader', field: "P", sortable: true, filter: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer:this.priorityCellRenderer,
        valueGetter: this.distributionValueGetter
      },
      { headerName: "Q", headerClass: 'myagheader', field: "Q", sortable: true, filter: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer:this.priorityCellRenderer,
        valueGetter: this.distributionValueGetter
      },
      { headerName: ">D", headerClass: 'myagheader', field: "<D", sortable: true, filter: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer:this.priorityCellRenderer,
        valueGetter: this.distributionValueGetter
      },
    ]

    this.fetchSessionDistributionData();
  }

  ngOnInit(): void {
  }

  distributionValueGetter(params) {
    if(params.data)
    {
      var jv= params.data.distribution.find(x=>x.v==params.colDef.field)
      if(jv)
      {
        if(jv.a>0){
          return 'Y'
        }
        else {
          return 'N'
        }
      }
    }
  }

  priorityCellRenderer(params)
  {
    if(params.value)
    {
      var jv= params.data.distribution.find(x=>x.v==params.colDef.field)
      if(jv)
      {
        if(jv.p=="5")
        {
          return '<span style=" width: 30px;\n' +
            '  height: 26px;\n' +
            '  display: inline-block;\n' +
            '  border: 1px solid #3C8C03;\n' +
            '  border-radius: 4px;\n' +
            '  text-align: center;\n' +
            '  font-weight: 500;">' + params.value + '</span>'
        }
        else if(jv.p=="4")
        {
          return '<span style=" width: 30px;\n' +
            '  height: 26px;\n' +
            '  display: inline-block;\n' +
            '  border: 1px solid #E3615F;\n' +
            '  border-radius: 4px;\n' +
            '  text-align: center;\n' +
            '  font-weight: 500;">' + params.value + '</span>'
        }
        else if(jv.p=="3")
        {
          return '<span style=" width: 30px;\n' +
            '  height: 26px;\n' +
            '  display: inline-block;\n' +
            '  border: 1px solid #CEAF0C;\n' +
            '  border-radius: 4px;\n' +
            '  text-align: center;\n' +
            '  font-weight: 500;">' + params.value + '</span>'
        }
        else
          return '<span style=" width: 30px;\n' +
            '  height: 26px;\n' +
            '  display: inline-block;\n' +
            '  border: 1px solid #FFFFFF;\n' +
            '  border-radius: 4px;\n' +
            '  text-align: center;\n' +
            '  font-weight: 500;">' + params.value + '</span>'

      }

    }

  }

  fetchSessionDistributionData(){

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.API_URL = '/api/audit_masters/get_audit_session_distribution';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status == 200) && (res.results.data.length > 0)) {

            this.rowData = res.results.data;
            console.log( this.rowData);
            this.loaded = true;
          }
          else {
            this.preloader = false;
            this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  closeDialog(){
    this.dialog.closeAll();
  }

  quickSearch(){
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    // this.gridApi.setDomLayout('autoHeight');
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    this.gridApi.setSortModel(sortModel);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

}
