<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2 *ngIf="editMode">Apply Discounts</h2>
      <h2 *ngIf="!editMode">Discounts</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 600;font-size: 16px;color: #1D1D1D">
          {{ productObject.name }}
        </label>
      </div>
      <div class="row"
           style="background: white; margin-top:10px; margin-bottom: 10px; border: 1px solid #D7DDE6; border-radius: 10px;">
        <div class="col-md-12 pad0" style="padding-top: 12px;">
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Untaxed Total</label>
            <p style="font-size: 14px;font-weight: 500;">{{ productObject.price_subtotal.toFixed(apiService.decimalPolicy) }}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Unit Price (base)</label>
            <p style="font-size: 14px;font-weight: 500;">{{ productObject.price_unit.toFixed(apiService.decimalPolicy) }}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Rate</label>
            <p style="font-size: 14px;font-weight: 500;">{{ productObject.price_unit.toFixed(apiService.decimalPolicy) }}</p>
          </div>
          <div class="col-md-3 pl-30 pr-30 pt-30">
            <label style="color:#7D7D7D;font-weight: 400;">Cash/Bill Discount</label>
            <p style="font-size: 14px;font-weight: 500;" *ngIf="cash_dis > 0">{{ cash_dis.toFixed(apiService.decimalPolicy) }}</p>
            <p style="font-size: 14px;font-weight: 500;" *ngIf="cash_dis === 0">--</p>
          </div>
        </div>
      </div>
      <div  style="display: inline-block; margin-top: 20px; width: 100%">
        <form novalidate id="ngForm" #discountform="ngForm">
          <div *ngIf="editMode">
            <mat-radio-group aria-label="Select an option"
                             class="radio-group"
                             name="mode"
                             #mode="ngModel"
                             [(ngModel)]="discountMode"
                             (change)="discountSelected()">
              <mat-radio-button value="discount">Discount %</mat-radio-button>
              <mat-radio-button value="disc_value">Discount Value</mat-radio-button>
              <mat-radio-button value="disc_qty" checked>Discount Per unit</mat-radio-button>
              <mat-radio-button value="disc_volume" *ngIf="allow_discount_per_volume">Discount Per Measure</mat-radio-button>
            </mat-radio-group>
            <div class="mt-4">
              <div style="position: relative">
                <input
                  class="forminputwhitebg"
                  style="color: #1D1D1D; font-size: 16px; margin-top: 5px;width: 100%;"
                  type="textbox"
                  appTwoDigitDecimaNumber
                  name="disc"
                  [(ngModel)]="user_input"
                  (ngModelChange)="discountChanged($event)"
                  #disc="ngModel"
                  placeholder="Enter Discount"
                  required
                >
                <div style=" position: absolute;right: 20px;top: 16px;">
                  <span class="datespan">Maximum Rate: </span>
                  <span class="datespan">{{max_rate}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="error" class="row centeralign mt-2">
              <img src="./../../../assets/exclamation.svg"/>
              <span style="color: #E24260; font-size: 12px; font-weight: 400;margin-left: 8px;">Entered value exceeds Total Amount</span>
            </div>
            <div *ngIf="!error" style="padding-top: 12px;">

              <span style="font-size: 14px;font-weight: 400;margin-left: 20px;">Discount %</span>
              <span style="font-size: 14px;font-weight: 600;color: #0762AD; margin-left: 8px;">{{productObject.discount.toFixed(apiService.decimalPolicy)}}</span>

              <span style="font-size: 14px;font-weight: 400;margin-left: 20px;">Discount Value</span>
              <span style="font-size: 14px;font-weight: 600;color: #0762AD;margin-left: 8px;" *ngIf="productObject.disc_value">{{productObject.disc_value.toFixed(apiService.decimalPolicy)}}</span>

              <span style="font-size: 14px;font-weight: 400;">Discount Per unit</span>
              <span style="font-size: 14px;font-weight: 600;color: #0762AD; margin-left: 8px;" *ngIf="productObject.disc_qty">{{productObject.disc_qty.toFixed(apiService.decimalPolicy)}}</span>

              <span style="font-size: 14px;font-weight: 400;margin-left: 20px;" *ngIf="allow_discount_per_volume">Discount Per Measure</span>
              <span style="font-size: 14px;font-weight: 600;color: #0762AD;margin-left: 8px;" *ngIf="(allow_discount_per_volume && productObject.disc_volume)">{{productObject.disc_volume.toFixed(apiService.decimalPolicy)}}</span>
            </div>
          </div>
        </form>
        <div *ngIf="!editMode">
          <div style="display: inline-block">
            <label style="font-weight: 400;font-size: 16px;color: #1D1D1D">
              Discount Details
            </label>
          </div>
          <div class="row"
               style="background: white; margin-top:10px; margin-bottom: 30px; border: 1px solid #D7DDE6; border-radius: 10px;">
            <div class="col-md-12 pad0" style="padding-top: 12px;">

              <div class="col-md-3 pl-30 pr-30 pt-30">
                <label style="color:#7D7D7D;font-weight: 400;">Discount %</label>
                <p style="font-size: 14px;font-weight: 500;">{{ user_dis.toFixed(apiService.decimalPolicy) }}</p>
              </div>
              <div class="col-md-3 pl-30 pr-30 pt-30">
                <label style="color:#7D7D7D;font-weight: 400;">Discount Value</label>
                <p style="font-size: 14px;font-weight: 500;">{{ productObject.disc_value.toFixed(apiService.decimalPolicy) }}</p>
              </div>
              <div class="col-md-3 pl-30 pr-30 pt-30">
                <label style="color:#7D7D7D;font-weight: 400;">Discount Per unit</label>
                <p style="font-size: 14px;font-weight: 500;">{{ productObject.disc_qty.toFixed(apiService.decimalPolicy) }}</p>
              </div>
              <div class="col-md-3 pl-30 pr-30 pt-30" *ngIf="allow_discount_per_volume">
                <label style="color:#7D7D7D;font-weight: 400;">Discount Per Measure</label>
                <p style="font-size: 14px;font-weight: 500;">{{ productObject.disc_volume.toFixed(apiService.decimalPolicy) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div *ngIf="editMode" class="button-row footer-section">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="applyDiscount()"
        form="ngForm"
        [disabled]="discountform.form.invalid"
      >
        Submit
      </button>
    </div>
  </div>
</section>
