import {PageBase} from 'src/app/pagebase/pagebase';
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {PlanogramUploadComponent} from "./planogram_upload.component";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";


@Component({
  selector: 'app-credit-note-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class PlanogramListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Planogram');
    this.showDate = false;
    this.showBaseUpload = this.sharedService.ACLcheck('feature_planogram_upload');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {

    this.API_URL = '/api/retail_planograms/get_retail_planogram_list';
    this.columnDefs = [
      {
        headerName: 'Retailer',
        children: [
          {headerName: "ID", field: "r_id", width: 60, hide: true},
          {headerName: "Code", field: "r_code", width: 100},
          {headerName: "Name", field: "r_name", width: 150},
          {headerName: "Region", field: "region", width: 100},
          {headerName: "Cluster", field: "cluster", width: 120},
          {headerName: "Territory", field: "territory", width: 120},
          {headerName: "Territory Manager", field: "terr_mgr", width: 120},
          {headerName: "Zone", field: "zone", width: 80},
          {headerName: "Area", field: "area", width: 80},
          {headerName: "Mobile", field: "mobile", width: 100},
          {headerName: "Type", field: "type", width: 100},
          {headerName: "Program", field: "program_type", width: 120}
        ]
      },
      {
        headerName: 'Planogram',
        children: [
          {headerName: "ID", field: "p_id", width: 60, hide: true},
          {headerName: "Code", field: "p_code", width: 100},
          {headerName: "Name", field: "p_name", width: 150},
          {headerName: "Type", field: "p_type", width: 100},
          {headerName: "SKU Count", field: "sku_count", width: 100, hide: true},
          {headerName: "SKU Qty", field: "sku_qty", width: 100, hide: true},
          {headerName: "SOS", field: "sos", width: 100, hide: true},
          {
            headerName: "Reference", field: "file_path", width: 100,
            cellRenderer(params) {
              if (params.data.file_path === null || params.data.file_path === "") {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              } else {
                if (params.data.file_type === 'img') {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                } else if (params.data.file_type === 'pdf') {
                  return '<img style="padding:2px;width: 35px;" src="./../../../assets/pdf_icon.png"></img>';
                }
              }
            }
          },
        ]
      }
    ];
  }

  showUploadPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "95vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {}
      };
      const creatediaeref = this.dialog.open(PlanogramUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {}
      };
      const creatediaeref = this.dialog.open(PlanogramUploadComponent, config);
    }
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('file_path') && rows[0].file_path !== null && rows[0].file_path !== "") {
      if (rows[0].file_type === 'img') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].file_path,
              container: "retail_planograms"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].file_path,
              container: "retail_planograms"
            }
          };
          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }
      } else  if (rows[0].file_type === 'pdf') {
       this.fetchPDFImage(rows);
      }
    } else {

    }
  }
  async fetchPDFImage(rows) {
    const res = await this.apiService.fetchExpenseImage({
      access_token: localStorage.getItem('resfreshToken'),
      url: localStorage.getItem('private_url'),
      data: [{store_fname: rows[0].file_path, container: 'retail_planograms'}]
    });
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const pdf =  this.domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + res['result'][0].bill_datas);
      this.openPDFinNewTab(pdf);
    }
  }

  openPDFinNewTab(pdf) {
    if (pdf && pdf !== 'null') {
      let pdfSrc = pdf.toString();
      pdfSrc = pdfSrc.replace('SafeValue must use [property]=binding: data:application/pdf;base64, ', '');
      pdfSrc = pdfSrc.replace(' (see http://g.co/ng/security#xss)', '');
      let byteCharacters;
      if (pdfSrc === "null") {
        console.log(pdf);
        this.toastr.error("Invalid PDF");
      } else {
        try {
          byteCharacters = atob(pdfSrc);
        } catch (e) {
          console.log(pdf);
          this.toastr.error("Invalid PDF");
        }
        if (byteCharacters) {
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(file );
          window.open(blobUrl);
        }
      }
    } else {
      console.log(pdf);
      this.toastr.error("Invalid PDF");
    }

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
