import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {CreateEditUserComponent} from "../../users/create_edit_user/create_edit_user.component";

@Component({
  selector: 'app-create-product-popup',
  templateUrl: './create_edit_product_template.component.html',
  styleUrls: ['./create_edit_product_template.component.scss']

})

export class CreateEditProductTemplateComponent implements OnInit {

  parameterObject: any = {};
  prdTemplateObject: any = {};
  taxMasterList: any = [];
  categoryList: any = [];
  brandList: any = [];
  uomList: any = [];
  priorityList: any = [];
  API_URL = '';
  access_token;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private _changeDetectionRef: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.parameterObject = dialogdata.productTemplateObject;

    if (this.parameterObject == null) {
      this.prdTemplateObject = {};
      this.prdTemplateObject.id = 0;
      this.prdTemplateObject.taxmaster_id = 0;
    } else {
      this.prdTemplateObject = dialogdata.productTemplateObject;
      if (this.prdTemplateObject.variants !== undefined && this.prdTemplateObject.variants.length > 0) {
        this.prdTemplateObject.taxmaster_id = this.prdTemplateObject.variants[0].taxmaster_id;
      }
    }

    this.access_token =  localStorage.getItem('resfreshToken');

    this.priorityList = [{id:1, name: 'MUST SELL'}, {id:2, name: 'FOCUS'}];

  }

  ngOnInit(): void {


    if (localStorage.getItem('cache_category_list') === null
      || localStorage.getItem('cache_brand_list') === null
      || localStorage.getItem('cache_uom_list') === null
      || localStorage.getItem('cache_taxmaster_list') === null ) {
      this.fetchMasters();
    } else {


      this.taxMasterList = JSON.parse(localStorage.getItem('cache_taxmaster_list'));
      this.categoryList = JSON.parse(localStorage.getItem('cache_category_list'));
      this.brandList = JSON.parse(localStorage.getItem('cache_brand_list'));
      this.uomList = JSON.parse(localStorage.getItem('cache_uom_list'));

      console.log(this.taxMasterList);
      console.log(this.categoryList);

    }
  }


  fetchMasters() {

    const sessionData = {
      access_token: this.access_token
    };

    console.log('inside ftech masters');
    this.apiService.post('/api/product_products/getProductsMastersPWA', sessionData)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {

          this.taxMasterList = res.results.data.taxmaster_list;
          this.uomList = res.results.data.uom_list;
          this.categoryList = res.results.data.category_list;
          this.brandList = res.results.data.brand_list;

          localStorage.setItem('cache_category_list', JSON.stringify(this.categoryList));
          localStorage.setItem('cache_brand_list', JSON.stringify(this.brandList));
          localStorage.setItem('cache_uom_list', JSON.stringify(this.uomList));
          localStorage.setItem('cache_taxmaster_list', JSON.stringify(this.taxMasterList));

          this._changeDetectionRef.detectChanges();

        } else {
          this.toastr.error('Missing Configuration, Contact support');
        }
      });

  }

  CreateNewCategory() {
    const confirmdiaeref = this.dialog.open(CreateEditUserComponent, {
      height: '400px',
      width: '600px',
    });

    confirmdiaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {

      }
    });
  }


  saveProductTemplate() {
    this.API_URL = '/api/crm_teams/saveTeamPWA';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.prdTemplateObject;

    console.log(this.prdTemplateObject);

    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('result') && (res.result.status === 409)) {
            this.toastr.warning('Cannot create Team with existing name and code');
          } else if (res.hasOwnProperty('result') && (res.result.status === 200)) {

            this.toastr.success('Created Beat Plan Successfully');
            this.dialog.closeAll();

          } else {
            this.toastr.error('Error creating/modifing Team');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error creating/modifing Team');
        });

  }


  closeDialog() {
    this.dialog.closeAll();
  }

}
