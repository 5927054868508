<div id="overlay"></div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <h2 style="color: #7D7D7D;">Verify Outlet</h2>
      <div style="border-bottom: 1px solid #E5E5E5;"></div>
    </div>
    <div class="body-section">
      <div class="row">
        <div class="col-md-2">
          <div class="box mt-3">
             <h4 class="mt-2 ml-3">OTP Verification</h4>
             <div *ngIf="!otp">
               <p class="pink-text ml-3 mt-2">OTP Unverified</p>
               <img class="ml-2" src="assets/Cancel.svg">
             </div>
            <div *ngIf="otp">
              <p class="green-text ml-3 mt-2">OTP Verification Done</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div class="mt-5" *ngIf="!has_einvoice">
              <p class="pink-text ml-3 mt-2">Has Einvoice</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div class="mt-5" *ngIf="has_einvoice">
              <p class="green-text ml-3 mt-2">Has Einvoice</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div *ngIf="!has_purchase_order">
              <p class="pink-text ml-3 mt-2">Has Purchase Order</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div *ngIf="has_purchase_order">
              <p class="green-text ml-3 mt-2">Has Purchase Order</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="box mt-3">
            <h4 class="mt-2 ml-3">Chain Verification</h4>
            <div *ngIf="chain.verified === false || chain.verified === 'false'">
              <p class="pink-text ml-3 mt-2">Chain Unverified</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div *ngIf="chain.verified === true || chain.verified === 'true'">
              <p class="green-text ml-3 mt-2">Chain Verification Done</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div class="mt-3">
               <label class="ml-3">Approver : </label><span>{{chain.by}}</span><br/>
               <label class="ml-3">Date : </label><span>{{chain.date}}</span><br/>
               <label class="ml-3">Is Chain : </label><span>{{is_chain}}</span><br/>
               <label class="ml-3">Chain : </label><span>{{parent}}</span><br/>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="box mt-3">
            <h4 class="mt-2 ml-3">Location Verification</h4>
            <div *ngIf="location.verified === false || location.verified === 'false'">
              <p class="pink-text ml-3 mt-2">Location Unverified</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div *ngIf="location.verified === true || location.verified === 'true'">
              <p class="green-text ml-3 mt-2">Location Verification Done</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div class="mt-3">
              <label class="ml-3">Approver : </label><span>{{location.by}}</span><br/>
              <label class="ml-3">Date : </label><span>{{location.date}}</span><br/>
              <label class="ml-3">Address 1 : </label><span>{{street}}</span><br/>
              <label class="ml-3">Address 2 : </label><span>{{street2}}</span><br/>
              <label class="ml-3">Area : </label><span>{{area}}</span><br/>
              <label class="ml-3">Zone : </label><span>{{zone}}</span><br/>
              <label class="ml-3">State : </label><span>{{state}}</span><br/>
              <label class="ml-3">Zip : </label><span>{{zip}}</span><br/>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="box mt-3">
            <h4 class="mt-2 ml-3">GSTIN Verification</h4>
            <div *ngIf="gst.verified === false || gst.verified === 'false'">
              <p class="pink-text ml-3 mt-2">GSTIN Unverified</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div *ngIf="gst.verified === true || gst.verified === 'true'">
              <p class="green-text ml-3 mt-2">GSTIN Verification Done</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div class="mt-3">
              <label class="ml-3">Approver : </label><span>{{gst.by}}</span><br/>
              <label class="ml-3">Date : </label><span>{{gst.date}}</span><br/>
              <label class="ml-3">GSTIN : </label><span>{{gstin}}</span><br/>
              <label class="ml-3">State : </label><span>{{state}}</span><br/>
            </div>
          </div>
        </div>
        <div *ngIf="finance_enable" class="col-md-2">
          <div class="box mt-3">
            <h4 class="mt-2 ml-3">Finance Verification</h4>
            <div *ngIf="finance.verified === false || finance.verified === 'false'">
              <p class="pink-text ml-3 mt-2">Finance Unverified</p>
              <img class="ml-2" src="assets/Cancel.svg">
            </div>
            <div *ngIf="finance.verified === true || finance.verified === 'true'">
              <p class="green-text ml-3 mt-2">Finance Verification Done</p>
              <img class="ml-2" src="assets/Check.svg">
            </div>
            <div class="mt-3">
              <label class="ml-3">Approver : </label><span>{{finance.by}}</span><br/>
              <label class="ml-3">Date : </label><span>{{finance.date}}</span><br/>
              <label class="ml-3">Type : </label><span>{{mode}}</span><br/>
              <label class="ml-3">Payment mode : </label><span>{{mode}}</span><br/>
              <label class="ml-3">Credit Limit : </label><span>{{credit_limit}}</span><br/>
              <label class="ml-3">Credit Days : </label><span>{{credit_days}}</span><br/>
              <label class="ml-3">Pricelist : </label><span>{{ret_pricelist}}</span><br/>
              <label class="ml-3 mt-3 display">Territory Pricelist :  </label><span>{{pricelist}}</span><br/>
              <label *ngIf="parent" class="ml-3 display">Chain Pricelist :  </label><span>{{chain_pricelist}}</span><br/>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-3">
        <div class="mt-5">
          <div *ngIf="!is_verify && !final_verified">
            <img src="./assets/Warning.svg">
            <p class="pink-text ml-2">Warning! You cannot proceed without verifying {{unverified}}</p>
          </div>
          <div *ngIf="!is_verify && final_verified">
            <img class="ml-2" src="assets/Check.svg">
            <p class="green-text ml-3 mt-2" style="font-weight: bold; font-size: 14px;vertical-align: middle">Verified on {{finalVerification.date}} by {{approval_user}}</p>
          </div>
          <div *ngIf="is_verify">
            <p class="green-text ml-2">Success! By clicking verify, the selected outlet will be verified. Verify all the verifications before finishing the Final verification.</p>
          </div>
        </div>
        <div class="mt-3" *ngIf="!final_verified">
          <button *ngIf="is_verify" (click)="checkSapPush()" class="btn confirm-green-btn" style="margin-right: 8px;">VERIFY</button>
          <button (click)="closeDialog()" class="btn grey-btn">CLOSE</button>
          <button (click)="rejectPopup()" class="btn red-btn"  style="margin-left: 8px;">REJECT</button>
          <button (click)="reject()" class="btn red-btn"  style="margin-left: 8px;float: right">INACTIVATE</button>
        </div>

      </div>
    </div>
  </div>
</section>
