<div class="col-md-12 col-12 nopadding pl-0" style="height: 75vh;overflow:auto">

  <div class="eod-list-carda">
    <div>
      <div class="gallerycardgrid" >
        <ng-container *ngFor="let data of pageData; let i = index">
          <div [ngClass]="{'selectedborder': data['item_selected'] && (data['reviewed'] !== galleryData['reviewTypes']['yes']) && (!data['reviewed']!== galleryData['reviewTypes']['no']),
                           'notselectedborder': !data['item_selected'] && (!data['reviewed'] !== galleryData['reviewTypes']['yes']) && (!data['reviewed']!== galleryData['reviewTypes']['no']),
                           'approvedborder': data['reviewed'] === galleryData['reviewTypes']['yes'],
                           'rejectedborder': data['reviewed'] === galleryData['reviewTypes']['no']}">
            <app-gallerycard [id]="data[galleryData['id']]"
                             [image]="data[galleryData['image']]"
                             [name]="data[galleryData['name']]"
                             [field1]="data[galleryData['field1']]"
                             [field2]="data[galleryData['field2']]"
                             [field3]="data[galleryData['field3']]"
                             [status]="data[galleryData['status']]"
                             [reviewed]="data[galleryData['reviewed']]"
                             [selected]="data['item_selected']"
                             [galleryData] = "galleryData"
                             (galleryClick)="galleryClick($event)">
            </app-gallerycard>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div  class="col-md-12 pad0" style="background-color: white;height: 40px; margin-top: 15px; width: 100%; padding: 10px;">
  <div class="col-md-6 pad0">
    <span  style="font-weight: bold; font-size:15px;">{{filteredData.length.toLocaleString('en', {useGrouping:true})}}</span>
    <span style="font-size:15px;"> of </span>
    <span  style="font-weight: bold; font-size:15px;">{{rowData.length.toLocaleString('en', {useGrouping:true})}}</span>
  </div>
  <div class="col-md-6 pad0">
    <div style="float: right; margin-right: 50px;">
      <span style="font-weight: bold; font-size:15px;">{{currentIndex.toLocaleString('en', {useGrouping:true})}} </span>
      <span style="font-size:15px;"> to </span>
      <span style="font-weight: bold; font-size:15px;">{{(currentIndex + pageData.length - 1).toLocaleString('en', {useGrouping:true})}}</span>
      <span style="font-size:15px;"> of </span>
      <span  #rowspan id='rowspan' style="font-weight: bold; font-size:15px;">{{filteredData.length.toLocaleString('en', {useGrouping:true})}}</span>

      <span style="margin-left: 30px; margin-right: 10px;"
            (click)="firstPage()"
            [ngClass]="{'disabledspan': currentPage === 1,
                     'enabledspan': currentPage >1}"> <i class="fas fa-chevron-double-left"></i></span>
      <span style="margin-left: 10px; margin-right: 10px;"
            (click)="previousPage()"
            [ngClass]="{'disabledspan': currentIndex === 1,
                     'enabledspan': currentIndex >1}"><i class="far fa-chevron-left"></i></span>
      <span style="font-size:15px;"> Page </span>
      <span style="font-weight: bold; font-size:15px;">{{currentPage.toLocaleString('en', {useGrouping:true})}} </span>
      <span style="font-size:15px;"> of </span>
      <span style="font-weight: bold; font-size:15px;">{{pageCount.toLocaleString('en', {useGrouping:true})}} </span>
      <span style="margin-left: 10px; margin-right: 10px"
            (click)="nextPage()"
            [ngClass]="{'disabledspan': (currentIndex + pageData.length - 1) >= filteredData.length,
                     'enabledspan': (currentIndex + pageData.length - 1) < filteredData.length}"><i class="far fa-chevron-right"></i></span>
      <span style="margin-left: 10px; margin-right: 10px"
            (click)="lastPage()"
            [ngClass]="{'disabledspan':  this.currentPage >= this.pageSize,
                     'enabledspan':  this.currentPage < this.pageSize}"><i class="fas fa-chevron-double-right"></i></span>
    </div>
  </div>
</div>
