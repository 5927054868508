import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {formatDate} from '@angular/common';
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PricelistDCWiseReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];

  setTitle() {
    this.title = 'Price List Master';
  }

  ngOnInit() {

    this.sharedService.setHeader('DC Price List Master');

    this.showableFilters.salesman = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = false;
    this.showableFilters.region = false;
    this.showDate = false;

    this.setTitle();
    this.API_URL = '/api/pwa_reports/getVansalesDCPricelistReport';
    this.configureGrid();
    this.loadServerData();

  }

  configureGrid() {

    this.columnDefs = [
      {headerName: "DC", field: "dc",  width: 80},
      {headerName: "PRICE LIST", field: "pricelist",  width: 100},
      {headerName: "MODE", field: "p_mode",  width: 80,
        valueGetter(params) {
          if (params.data) {
            return params.data.p_mode.replace(/_/g, ' ')
              .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
          }
        }
      },
      {headerName: "PRODUCT CODE", field: "code",  width: 80},
      {headerName: "PRODUCT NAME", field: "product",  width: 150},
      {headerName: "MRP", field: "mrp", width: 80},
      {headerName: "BEFORE TAX", field: "before_tax", width: 80},
      {headerName: "MARGIN", field: "margin_perc",  width: 80},
      {headerName: "EXTRA MARGIN", field: "extra_margin",  width: 80},
      {headerName: "MIN MARGIN", field: "min_margin",  width: 80},
      {headerName: "MAX MARGIN", field: "max_margin",  width: 80},
      {headerName: "PTR", field: "ptr", width: 80},
      {headerName: "TAX IN PRICE", field: "price_include", width: 80, hide: true},
      {headerName: "TAX", field: "tax", width: 80},

    ];

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          params.columnApi.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // this.get_favorites();
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

}
