import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ToastrService} from "ngx-toastr";
import {ApiService} from "../../../_services/api.service";

@Component({
  selector: 'app-bulk-upload-status-popup',
  templateUrl: './bulk_upload_status_popup.component.html',
  styleUrls: ['./bulk_upload_status_popup.component.scss']

})

export class BulkUploadStatusPopupComponent implements OnInit {

  public header = "";
  API_URL = '';
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: []
    },
    customFilter: {}
  };
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  productData = [];
  dataLoaded: any;
  frameworkComponents: any;
  context: any;
  private gridApi;
  private gridColumnApi;
  styleGrid: any;
  totalRecords = 0;
  validRecords = 0;
  errorRecords = 0;
  statusConfig = {
    success: 'Valid',
    success1: 'Success',
    error: 'Invalid',
    error1: 'Error',
    warning: 'warning',
    warning1: 'warning'
  };

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    /* this.store_fname = dialogdata.store_fname;
     this.container = dialogdata.container;*/

    this.header = dialogdata.header;
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;
    this.rowData = dialogdata.rowData;
    this.totalRecords = this.rowData.length;
    this.columnDefs = [
      {headerName: 'CODE', field: 'sku_code', width: 100},
      {headerName: 'NAME', field: 'sku_name', width: 120},
      {headerName: 'CATEGORY', field: 'category', width: 150},
      {headerName: 'BRAND', field: 'brand', width: 120},
      {headerName: 'MRP', field: 'mrp', width: 100},
      {headerName: 'UOM', field: 'uom', width: 100},
      {headerName: 'TAX', field: 'tax', width: 100},
      {headerName: 'FAMILY', field: 'code', width: 100},
      {headerName: 'Validation', field: 'valid', width: 100, cellRenderer: this.statusCellRenderer},
      {headerName: 'Status', field: 'status', width: 100, cellRenderer: this.statusIndicatorCellRenderer},
      {
        headerName: '',
        field: 'ACTION',
        width: 100,
        menuTabs: [],
        cellRenderer(params) {
          return '<span title="Delete" style="color: #E24260"><i class="fas fa-trash-alt delete" data-action-type="delete"></i></span>';
        },
      },
    ];
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      getRowStyle: params => {
        if (params.data && (params.data.valid === 'Invalid' || params.data.status === 'Error')) {
          return {background: '#FDF2F4', color: '#E24260'};
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
  }

  ngOnInit(): void {
    if (this.rowData !== null && this.rowData.length > 0) {
      this.dataLoaded = true;
    } else {
      this.dataLoaded = false;
    }
    this.validate();
  }

  validate() {
    this.validRecords = 0;
    this.errorRecords = 0;
    let i = 0;
    this.rowData.forEach(row => {
      if (i % 2 === 0) {
        row.valid = "Valid";
        row.status = "Success";
        this.validRecords++;
      } else {
        row.valid = "Invalid";
        row.status = "Error";
        this.errorRecords++;
      }
      i++;
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  submit() {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveData() {
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.gridApi.sizeColumnsToFit();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

  }

  onCellClicked(event) {
  }

  statusCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value ===  params.context.componentParent.statusConfig.success || params.value ===  params.context.componentParent.statusConfig.success1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862" >' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FEF2DF; border:#FEF2DF; color: #F6A82C">' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span class="ml-1 status2" style="background-color:#E3F4F6; border:#E3F4F6; color: #3A6C87"">' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }
  statusIndicatorCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value ===  params.context.componentParent.statusConfig.success || params.value ===  params.context.componentParent.statusConfig.success1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #3A6C87;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }
}
