import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-product-suggestion',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class ProductSugReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Region',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };
  allRegions: any = [];

  setTitle() {
    this.title = 'Product Suggestion Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Product Suggestion Report');
    this.API_URL = '/api/pwa_reports/getProductSugReport';
    if (localStorage.getItem('all_regions') !== null && localStorage.getItem('all_regions') !== 'null' && JSON.parse(localStorage.getItem('all_regions')) !== null) {
      this.showCustomFilter = true;
      this.allRegions = JSON.parse(localStorage.getItem('all_regions'));
      this.allRegions.sort((a, b) => a.itemname.localeCompare(b.itemname));
      this.customFilter.values = [];
      for (const item of this.allRegions) {
        this.customFilter.values.push({id: item.id, itemName: item.itemname});
      }
      this.customFilter.value =  this.customFilter.values[0].id;
    } else {
      this.showCustomFilter = false;
    }

    this.loadInBatches(50000, 'sug_id');
    this.showProgressCustomText = true;
    this.configureGrid();

  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "Region", field: "region", enableRowGroup: true, width: 50},
      {headerName: "Cluster", field: "cluster", enableRowGroup: true, width: 60},
      {headerName: "Emp Code", field: "emp_code", width: 50},
      {headerName: "Salesman", field: "salesman", width: 80},
      {headerName: "Role", field: "user_role", width: 50},
      {headerName: "Team", field: "team", width: 100},
      {headerName: "ID", field: "retailer_id", width: 100},
      {headerName: "RCode", field: "retailer_code", width: 60},
      {headerName: "Retailer", field: "retailer_name", width: 80},
      {headerName: "Date", field: "sug_date", width: 50},
      {headerName: "Name", field: "reco_name", width: 70},
      {headerName: "Product Code", field: "p_code", width: 50},
      {headerName: "Product", field: "product", width: 60},
      {headerName: "Template Code", field: "t_code", width: 50},
      {headerName: "Template", field: "template", width: 60},
      {headerName: "MBQ", field: "mbq", width: 70},
      {headerName: "Family", field: "family", width: 100},
      {headerName: "Type", field: "type", width: 50},
      {headerName: "Range", field: "range", width: 70},
      {headerName: "Sub Category", field: "sub_categ", width: 70},
      {headerName: "Order Value", field: "order_value", width: 80}
    ];
  }
}
