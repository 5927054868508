import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-dist-reconcile-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class Dist_reconcileComponent extends ReportBase implements IReport, OnInit  {

  /*showableFilters={
    max_claim:false,
    policy:false,
    salesman:false,
    status:false,
    class:false,
    beat:false,
    trax_outlet:false,
    non_trax_outlet:false,
    exclude_trax_audit:false,
    region:false,
    cluster:false,
    team:false,
    territory:false,
    outlet_type: false,
    dc: false
  }*/

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.sharedService.setHeader('Distributor Stock Reconcile');
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/dist_reconsile_report';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    this.columnDefs = [
      { headerName: 'State',  field: 'state_name', width: 100},
      { headerName: 'Region',  field: 'region_name', lockPosition: true ,
        width: 100, },
      { headerName: 'Reg Manager',  field: 'reg_manager', lockPosition: true ,
        width: 100, },
      { headerName: 'Tr Manager',  field: 'tr_manager', lockPosition: true ,
        width: 100, },
      { headerName: 'Territory',  field: 'territory_name', lockPosition: true ,
        width: 80, },
      { headerName: 'Dist Code',  field: 'dist_code',
        width: 100},
      { headerName: 'Dist Name',  field: 'dist_name',
        width: 100},
      { headerName: 'Dist Type',  field: 'dist_type',
        width: 100},
      { headerName: 'Dist City',  field: 'dist_city', cellStyle: {textAlign: 'center'},
         width: 100},
      { headerName: 'Category',  field: 'category_name', cellStyle: {textAlign: 'right'},
          width: 80},
      { headerName: 'Family',  field: 'family_name', cellStyle: {textAlign: 'right'},
        filter: true,  width: 80},
      { headerName: 'Item Name',  field: 'product_name', cellStyle: {textAlign: 'right'},
        filter: true,  width: 100},
      { headerName: 'Item Code',  field: 'product_code', cellStyle: {textAlign: 'right'},
          width: 100},
      { headerName: 'Open Qty',  field: 'opening_qty', cellStyle: {textAlign: 'right'},
          width: 100},
      { headerName: 'Pri Qty',  field: 'received_qty', cellStyle: {textAlign: 'center'},
          width: 80},
      { headerName: 'Sec Qty',  field: 'issued_qty', cellStyle: {textAlign: 'center'},
          width: 80},
      { headerName: 'Dvn Qty',  field: 'deviation_qty', cellStyle: {textAlign: 'right'},
          width: 80},
      { headerName: 'Close Qty',  field: 'closing_qty', cellStyle: {textAlign: 'right'},
          width: 70},
      { headerName: 'CRQty',  field: 'report_close_qty', cellStyle: {textAlign: 'right'},
          width: 70},
      { headerName: 'Uom',  field: 'uom_name', cellStyle: {textAlign: 'right'},
          width: 70},
      { headerName: 'Ruom',  field: 'report_uom_name', cellStyle: {textAlign: 'right'},
          width: 70},
    ];
  }

  // loadserverdata() {
  //   var indrow = {
  //     "category":"Scotch",
  //     "brands":"Black & White",
  //     "volume":"231 Cases",
  //     "market_share":"64%",
  //     "growth":"20%",
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }

}
