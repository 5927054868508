import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class RetailerAssetSalesReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  setTitle() {
    this.title = 'Retailer Asset Sales Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Retailer Asset Sales Report');
    this.API_URL = '/api/pwa_reports/getRetailerAssetSalesReport';
    this.showableFilters.state = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'line_id');
    this.showProgressCustomText = true;
    //this.configureGrid();

  }

  configureGrid() {
    this.columnDefs = [{
      headerName: '',
      children: [
        {headerName: "Cluster", field: "cluster", enableRowGroup: true, width: 100},
        {headerName: "Region", field: "region", enableRowGroup: true, width: 100},
        {headerName: "State", field: "state", enableRowGroup: true, width: 100},
        {headerName: "Retailer ID", field: "o_id", enableRowGroup: true, width: 100},
        {headerName: "RCode", field: "o_code", width: 100},
        {headerName: "Retailer", field: "o_name", width: 100},
        {headerName: "Mobile", field: "mobile", width: 100},
        {headerName: "Type", field: "outlet_type", width: 100},
        {headerName: "City", field: "city", width: 100},
        {headerName: "Pincode", field: "zip", width: 100},
        {headerName: "Assets", field: "asset_count", width: 100},
      ]
    },
      {
        headerName: 'Asset 1',
        children: [
          {
            headerName: "Name", field: "name1", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 0) {
                    return jsonvalue[0].a_name;
                  }
                } catch (e) {
                }
              }
            }
          },
          {
            headerName: "Image", field: "image1", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 0) {
                    return jsonvalue[0].a_image;
                  }
                } catch (e) {
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 0
                    && jsonvalue[0].a_image !== null && jsonvalue[0].a_image !== undefined && jsonvalue[0].a_image !== "" ) {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  } else {
                    return '';
                  }
                } catch (e) {
                }
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
          {
            headerName: "Date", field: "date1", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 0) {
                    return jsonvalue[0].a_installed_date;
                  }
                } catch (e) {
                }
              }
            }
          },
        ]
      },
      {
        headerName: 'Asset 2',
        children: [
          {
            headerName: "Name", field: "name2", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 1) {
                    return jsonvalue[1].a_name;
                  }
                } catch (e) {
                }
              }
            }
          },
          {
            headerName: "Image", field: "image2", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 1) {
                    return jsonvalue[1].a_image;
                  }
                } catch (e) {
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 1
                    && jsonvalue[1].a_image !== null && jsonvalue[1].a_image !== undefined && jsonvalue[1].a_image !== "" ) {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  } else {
                    return '';
                  }
                } catch (e) {
                }
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
          {
            headerName: "Date", field: "date2", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 1) {
                    return jsonvalue[1].a_installed_date;
                  }
                } catch (e) {
                }
              }
            }
          },
        ]
      },
      {
        headerName: 'Asset 3',
        children: [
          {
            headerName: "Name", field: "name3", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 2) {
                    return jsonvalue[2].a_name;
                  }
                } catch (e) {
                }
              }
            }
          },
          {
            headerName: "Image", field: "image3", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 2) {
                    return jsonvalue[2].a_image;
                  }
                } catch (e) {
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 2
                    && jsonvalue[2].a_image !== null && jsonvalue[2].a_image !== undefined && jsonvalue[2].a_image !== "" ) {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  } else {
                    return '';
                  }
                } catch (e) {
                }
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
          {
            headerName: "Date", field: "date3", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset );
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 2) {
                    return jsonvalue[2].a_installed_date;
                  }
                } catch (e) {
                }
              }
            }
          },
        ]
      },
      {
        headerName: 'Asset 4',
        children: [
          {
            headerName: "Name", field: "name4", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 3) {
                    return jsonvalue[3].a_name;
                  }
                } catch (e) {
                }
              }
            }
          },
          {
            headerName: "Image", field: "image4", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 3) {
                    return jsonvalue[3].a_image;
                  }
                } catch (e) {
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 3
                    && jsonvalue[3].a_image !== null && jsonvalue[3].a_image !== undefined && jsonvalue[3].a_image !== "" ) {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  } else {
                    return '';
                  }
                } catch (e) {
                }
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
          {
            headerName: "Date", field: "date4", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 3) {
                    return jsonvalue[3].a_installed_date;
                  }
                } catch (e) {
                }
              }
            }
          },
        ]
      },
      {
        headerName: 'Asset 5',
        children: [
          {
            headerName: "Name", field: "name5", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 4) {
                    return jsonvalue[4].a_name;
                  }
                } catch (e) {
                }
              }
            }
          },
          {
            headerName: "Image", field: "image5", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 4) {
                    return jsonvalue[4].a_image;
                  }
                } catch (e) {
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 4
                  && jsonvalue[4].a_image !== null && jsonvalue[4].a_image !== undefined && jsonvalue[4].a_image !== "" ) {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  } else {
                    return '';
                  }
                } catch (e) {
                }
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
          {
            headerName: "Date", field: "date5", width: 100,
            valueGetter: params => {
              if (params.data && params.data.asset) {
                try {
                  const jsonvalue = JSON.parse(params.data.asset);
                  if (jsonvalue !== null && jsonvalue !== undefined && jsonvalue.length > 4) {
                    return jsonvalue[4].a_installed_date;
                  }
                } catch (e) {
                }
              }
            }
          },
        ]
      },
      {
        headerName: '',
        children: [
          {headerName: "Order Count", field: "order_count", width: 100},
          {headerName: "Sales", field: "total_sales", width: 100},
        ]
      }
    ];

    /*  let max = this.getMax(this.apiData, 'asset');

      for (let i = 0; i < max; i++) {
        this.columnDefs.push({
          headerName: 'Asset' + (i + 1),
          children: [
            {headerName: "Name", field: "name", width: 100},
            {headerName: "Image", field: "image", width: 100},
            {headerName: "Date", field: "date", width: 100},
          ]
        });
      }*/

  }

  getMax(arr, prop) {
    let max = 0;
    for (let i = 0; i < arr.length; i++) {
      let length = 0;
      if (arr[i][prop] !== null && arr[i][prop] !== undefined) {
        try {
          const jsonvalue = JSON.parse(arr[i][prop]);
          if (jsonvalue !== null && jsonvalue !== undefined) {
            length = jsonvalue.length;
          }
        } catch (e) {
        }
      } else {
        length = 0;
      }
      if (max == null || length > max) {
        max = length;
      }
    }
    return max;
  }

  postProcessData() {
    this.configureGrid();
    this.rowData = this.apiData;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (rows !== null && rows.length > 0 && column.includes("image") && rows[0].asset !== null && rows[0].asset !== undefined) {

      try {
        const jsonvalue = JSON.parse(rows[0].asset);
        let position = column.toString().slice(5);
        position = parseInt(position, 0);
        position = position - 1;
        if (jsonvalue !== null && jsonvalue !== undefined) {
          const assetfname = jsonvalue[position].a_image;

          if (screen.width > 991) {
            const config: MatDialogConfig = {
              width: "100vw",
              height: "100vh",
              maxWidth: "70%",
              maxHeight: "95%",
              data: {
                store_fname: assetfname,
                container: "partner_asset"
              }
            };

            const creatediaeref = this.dialog.open(ImagePopupComponent, config);
          } else {
            const config: MatDialogConfig = {
              width: "100vw",
              height: "100vh",
              maxWidth: "90%",
              maxHeight: "65%",
              data: {
                store_fname: assetfname,
                container: "partner_asset"
              }
            };

            const creatediaeref = this.dialog.open(ImagePopupComponent, config);
          }

        }
      } catch (e) {
      }

    }
  }
}
