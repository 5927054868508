import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RevenueDispersionReportComponent extends ReportBase implements IReport, OnInit {
  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Revenue Dispersion Report');
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;


    this.loadInBatches(5000, 'partner_id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getRevenueDispersionReport';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance',  width: 80},
      {headerName: 'LABEL', field: 'label',  width: 80},
      {headerName: 'CUSTOMERS', field: 'customer_cnt',  width: 80},
      {headerName: 'ORDERS', field: 'order_cnt',  width: 80},
      {headerName: 'SALES', field: 'sales',   width: 80},
      {headerName: 'CONTRIBUTION %', field: 'contrib',  width: 80}
    ];

  }

}
