import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-asset-type-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class AssetTypeMasterReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Asset Type Master Report');

    this.showableFilters.salesman = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getAssetTypeReport';

    this.columnDefs = [

      {headerName: 'NAME', field: 'name', width: 100},
      {headerName: 'CODE', field: 'code', width: 100},
      {headerName: 'TYPE', field: 'type', width: 80},
      {headerName: 'RENTAL VALUE', field: 'rental_value', width: 80},
      {
        headerName: 'ALLOW RENEWAL', field: 'allow_renewal', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.allow_renewal !== null && params.data.allow_renewal) {
              return 'True';
            } else {
              return 'False';
            }
          }
        }
      },
      {headerName: 'ALLOW QUANTITY', field: 'allow_qty', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.allow_qty !== null && params.data.allow_qty) {
              return 'True';
            } else {
              return 'False';
            }
          }
        }},
      {headerName: 'DEPLOYED COUNT', field: 'deployed_count', width: 100},
      {headerName: 'REQUEST COUNT', field: 'req_count', width: 100},
      {headerName: 'ACTIVITY FREQUENCY', field: 'next_activity_days', width: 100}
    ];

  }

}
