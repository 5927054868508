import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-favorite-save',
  template: `<section class="dialog-section">
    <div class="container-padding">
      <div class="header-section">
        <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
          <i class="fas fa-times"></i>
        </button>
        <h2>Save Favorite</h2>
      </div>
      <div class="body-section">
        <div>
          <form novalidate  (ngSubmit)="saveFavorite()" #favoriteReportsform="ngForm">
            <div class="mt-4">
              <label >NAME</label>
              <div class="mt-1 formselect">
                <input
                  class="forminput"
                  type="text"
                  name="fav_code"
                  [(ngModel)]="favoritesData.name"
                  #fav_code="ngModel"
                  required
                >
                <div *ngIf="fav_code.errors && (fav_code.dirty || fav_code.touched)">
                  <span *ngIf="fav_code.errors.required" class="text-danger">Name is required</span>
                </div>
              </div>
              <div class="mt-3">
                <input
                  class="forminput"
                  type="checkbox"
                  name="fav_default"
                  [(ngModel)]="favoritesData.default"
                  #fav_default="ngModel"
                >
                <label>Load By Default</label>
              </div>
            </div>
          </form>
        </div>
        <div class="button-row mt-2"  >
          <button
            mat-button
            style=" vertical-align:bottom;margin-top: 20px;"
            class="btn teal-btn"
            type="submit"
            form="ngForm"
            (click)="saveFavorite()"
            [disabled]="favoriteReportsform.form.invalid"
          >
            SAVE
          </button>
          <span (click)="closeDialog()">
           <a class="btn white-btn">DISCARD</a>
        </span>
        </div>
      </div>
    </div>
  </section>
  `
})
// tslint:disable-next-line:component-class-suffix
export class FavoritesDialog {

  favoritesData = {
    name : '',
    columnState : [],
    default : false
  };

  constructor(
    public dialogRef: MatDialogRef<FavoritesDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    if (dialogData.favoritesData !== undefined) {
      this.favoritesData = dialogData.favoritesData;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveFavorite(): void {
    this.dialogRef.close(this.favoritesData);
  }
}
