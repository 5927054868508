import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class InternalStockTransferComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = false;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: '',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Internal Stock Transfer');

   /* this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;*/
    // this.customFilter.values = [
    //   {id: '', itemName: 'All'},
    //   {id: 'Secondary Return', itemName: 'Secondary Return'},
    //   {id: 'Damage', itemName: 'Damage'},
    //   {id: 'loadout', itemName: 'Loadout'},
    // ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/getInternalStockTransfer';
    const inthis = this;
    this.columnDefs = [

      {headerName: 'Source', field: 'van', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.type !== 'Secondary Return') {
              return params.data.outlet;
            } else {
              return params.data.van;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'Destination', field: 'dc', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.type !== 'Secondary Return') {
              return params.data.van;
            } else {
              return params.data.dc;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'REQ DATE', field: 'req_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.req_date) {
              return inthis.datePipe.transform((new Date(params.data.req_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.req_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'P CODE', field: 'p_code', enableRowGroup: true, width: 80},
      {headerName: 'PRODUCT', field: 'p_name', enableRowGroup: true, width: 80},
      {headerName: 'OLD VAL', field: 'val1', enableRowGroup: true, width: 80},
      {headerName: 'NEW VAL', field: 'val2', enableRowGroup: true, width: 80},
      {headerName: 'Transfer QTY', field: 'deviation', enableRowGroup: true, width: 80},

      {headerName: 'REQUESTED BY', field: 'create_name', enableRowGroup: true, width: 80}
    ];

  }

}
