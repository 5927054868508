<div class="eod-sales-card">
  <ul>
    <li>
      <label>{{title1}}</label>
      <span>{{zs}}</span>
    </li>
    <li>
      <label>{{title2}}</label>
      <span>{{dc}}</span>
    </li>
  </ul>
</div>
