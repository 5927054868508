<!--<div id="overlay"></div>-->
<section  class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{header}}</h2>
      <!-- <button class="close-dialog" (click)="closeDialog()">
         <i class="fas fa-times"></i>
       </button>-->
    </div>
    <div *ngIf="dataLoaded">
      <div *ngIf="viewMode === 'upload'" class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
        <div class="row" style="display: block">
          <div>
            <div class="row">
              <div class="step-container">
                <div class="step">
              <span class="span-number">1</span>
              <span class="header-span">Download and Add or Edit Info in the Template</span>
              </div>
              </div>
            </div>
            <div style="margin-left: 40px;margin-top: 10px;">
              <span class="desc-span">{{step1Text}} {{actionText}}</span>
              <div class="col-md-12 pl-0 pr-0" style="margin-top: 20px">
                <div class="col-md-3 pl-0 pr-10">
                  <div class="card-box" (click)="sampleDownload1()">
                    <span style="color:#0762AD;margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
                    <span
                      style="color:#0762AD; margin-left: 15px;font-size: 14px;font-weight: 500;">{{sampleText1}}</span>
                  </div>
                </div>
                <div class="col-md-3 pl-0 pr-10">
                  <div *ngIf="sampleText2">
                    <div class="card-box" (click)="sampleDownload2()">
                      <span style="color:#0762AD;margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
                      <span
                        style="color:#0762AD; margin-left: 15px;font-size: 14px;font-weight: 500;">{{sampleText2}}</span>
                    </div>
                  </div>
                </div>
                <!--<div class="col-md-6 pl-0 pr-10">
                </div> -->
              </div>
            </div>
            <div *ngIf="!multiSheetUpload" class="col-md-12 pad0" style="margin-top: 10px;">
              <div style="margin-left: 40px;margin-top: 5px;">
                <span class="desc-span">{{step2Text}}</span>
                <!--<span style="color:#183153;font-weight: bold;cursor:pointer;"> Learn about template</span>-->
                <div style="margin-top: 20px; overflow-x: auto;">
                  <table class="customtable" style="background: white;">
                    <tr>
                      <ng-container *ngFor="let row of excel_columns">
                        <th style="background: white; color: #0762AD;">{{row.C}}</th>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container *ngFor="let row of excel_columns">
                        <td>
                          <span [ngClass]="{'required': row.required === true,'optional': row.required === false}">{{row.H}}</span>
                          <span *ngIf="row.required === true" style="color: darkred">*</span>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container *ngFor="let row of excel_columns">
                        <td>{{row.V}}</td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 pad0" style="margin-top: 40px;">
            <div class="row">
              <div class="step-container">
                <div class="step">
              <span class="span-number">2</span>
              <span class="header-span">Upload File</span>
              </div>
              </div>
              <input #fileUp type="file" class="form-control" style="display:none" (change)="uploadedFile($event)"
                     placeholder="Upload file" accept=".xlsx,.csv">
            </div>
            <div style="margin-left: 40px;margin-top: 5px;">
              <span class="desc-span">Maximum File Size : 10 MB</span>
              <div class="dottedcard" (click)="fileUp.click()">
                <div class="row" *ngIf="fileUploaded">
                  <img style="height: 77px; padding: 10px;" src="../../../assets/excel_icon.png"/>
                  <div style="padding-top: 10px;">
                <span
                  style="font-size:14px; font-weight: 600; display: block; color: #2D2D2D">{{fileUploaded.name}}</span>
                    <span
                      style="font-size:12px; font-weight: 400; display: block; color: #7D7D7D; text-align: start">{{filesize}}
                      Kb</span>
                  </div>

                </div>
                <div *ngIf="!fileUploaded">
                  <span style="color: #0762AD;"><i class="fal fa-cloud-upload fa-4x"></i></span>
                  <span style="margin-top: 10px;display: block; font-size: 18px;color: #0762AD; font-weight: 500;">Select a file to Upload</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="viewMode === 'validate'" class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
        <span style="color: #2D2D2D; font-size: 14px;">Total Records</span><span style="margin-left: 5px;color: #0762AD; font-weight: bold;font-size: 14px;">{{totalRecords}}</span>
        <span style="margin-left: 20px; color: #2D2D2D;font-size: 14px;">Valid Records</span><span style="margin-left: 5px;color: #0762AD; font-weight: bold;font-size: 14px;">{{validRecords}}</span>
        <span style="margin-left: 20px; color: #2D2D2D;font-size: 14px;">Records with Error</span><span style="margin-left: 5px;color: #E24260; font-weight: bold;font-size: 14px;">{{errorRecords}}</span>
        <div class="row" style="display: block; margin-top: 10px;">
          <div class="col-md-12 pad0">
            <div>
              <ag-grid-angular id="myGrid2" style="height: 60vh" class="ag-theme-alpine"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                               (gridReady)="onGridReady($event)"
                               [components]="frameworkComponents"
                               [getRowId]="getRowId"
                               (cellClicked)="onCellClicked($event)"
                               [context]="context"
                               [tooltipShowDelay]="tooltipShowDelay"
                               [tooltipHideDelay]="tooltipHideDelay">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="viewMode === 'upload'"  style="margin: 25px 15px 25px 15px;">
        <div class="button-row" >
          <div *ngIf="!multiSheetUpload" style="display: inline-block; float: left; margin-top: 10px;margin-bottom: 30px;">
            <span class="notespan" style="font-weight: 500"> Note:</span>
            <span class="notespan"> Blue header Texts with an asterisk </span>
            <span style="font-weight: 500; font-size: 14px; color: darkred">(*)</span>
            <span class="notespan"> indicate mandatory fields</span>
          </div>
          <div style=" display: inline-block; float: right">
            <button
              mat-button
              style="margin-left:15px; margin-right:15px; margin-bottom: 10px;"
              class="btn grey-btn"
              (click)="closeDialog()"
            >
              CANCEL
            </button>
            <button
              *ngIf="uploaded_data"
              mat-button
              style="margin-left:15px; margin-right:15px; margin-bottom: 10px;"
              class="btn confirm-blue-btn"
              (click)="nextClick()"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="viewMode === 'validate'" class="col-md-12 pad0">
        <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
          <div *ngIf="uploadStarted" style="display: inline-block; margin-right:15px;">
            <span [ngClass]="{'progressTextComplete': progress === 100,'progressText': progress < 100}" style="">{{completedRecords}}</span>
            <span style="font-size: 14px;">/{{validRecords}}</span>
            <span [ngClass]="{'progressTextComplete': progress === 100,'progressText': progress < 100}" style="float: right;">{{progress}}%</span>
            <div  [ngClass]="{
            'warn-bar': progress > 0,
            'ok-bar': progress === 100
          }">
              <mat-progress-bar style=" width: 180px;height: 8px;border-radius: 10px;" class="" [mode]="'determinate'"
                                [value]="progress"></mat-progress-bar>
            </div>
          </div>
          <button
            *ngIf="!uploadCompleted"
            mat-button
            style="margin-left:15px; margin-right:15px; margin-bottom: 10px;"
            class="btn grey-btn"
            (click)="closeDialog()"
          >
            CANCEL
          </button>
          <button
            mat-button
            *ngIf="!uploadCompleted && errorRecords>0"
            style=" margin-right:15px; margin-bottom: 10px;"
            class="btn grey-btn"
            (click)="downloadDataWithErrors()">
            Download Data with Errors
          </button>
          <button
            *ngIf="!uploadCompleted && validRecords>0"
            mat-button
            style=" margin-right:15px; margin-bottom: 10px;"
            class="btn confirm-blue-btn"
            (click)="upload()"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!dataLoaded" style="margin-top: 200px;">
      <app-loadingstate></app-loadingstate>
    </div>
  </div>

</section>
