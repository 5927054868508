<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Delivery Plan</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F3F6FD; padding: 15px;">
      <label style="font-weight: 400;padding-top: 7px;display: block; font-size: 12px;color: #2D2D2D">
        Delivery plan helps you to create pick-list for a single delivery and automate the delivery route.
      </label>
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <mat-radio-group aria-label="Select an option"
                         class="radio-group"
                         [(ngModel)]="dp_mode"
        (change)="changeSelection()">
          <mat-radio-button value="create">Create New Plan</mat-radio-button>
          <mat-radio-button value="add">Add to Existing Plan</mat-radio-button>
        </mat-radio-group>
        <div>
          <form novalidate id="ngForm" #dpform="ngForm">
            <div *ngIf="dp_mode === 'create'" class="col-md-12 col-12 nopadding pl-0 pr-0">

              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-6 pl-0 pr-0" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Delivery Date</label>
                  <div style="margin-top: 5px;">
                    <div class="date-picker-form">
                      <input [selectMode]="'single'"
                             [readonly]="true" [(ngModel)]="deliveryPlanData.date"
                             [owlDateTimeTrigger]="dt"
                             [owlDateTime]="dt" placeholder="Date"
                             name="date"
                             #date="ngModel"
                             style="width: 100%;">
                      <!--<span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                      <div style=" position: absolute;right: 20px;top: 12px;">
                        <span class="datespan" (click)="selectTodaysDate()">Today</span>
                        <span class="datespan" (click)="selectTommorowDate()">Tomorrow</span>
                        <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
                      </div>

                      <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Route</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <input
                        class="forminputwhitebg"
                        type="text"
                        name="beats"
                        [(ngModel)]="deliveryPlanData.beats"
                        #beats="ngModel"
                        style="width: 100%;"
                        [disabled] = true
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-0 pr-0" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Delivered By</label>
                  <div style="margin-top: 5px;">
                    <div class="formselectwhitebg">
                      <select
                        name="del_user_id"
                        [(ngModel)]="deliveryPlanData.del_user_id"
                        required
                        #del_user_id="ngModel"
                        style="width: 100%;"
                      >
                        <option
                          *ngFor="let item of userList"
                          value="{{ item.id }}"
                        >
                          {{ item.user_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Vehicle Number</label>
                  <div style="margin-top: 5px;">
                    <div style="margin-top: 5px;">
                      <div>
                        <input
                          class="forminputwhitebg"
                          type="text"
                          name="vehicle"
                          [(ngModel)]="deliveryPlanData.vehicle"
                          #vehicle="ngModel"
                          style="width: 100%;"
                          required
                        >
                        <div *ngIf="vehicle.errors && (vehicle.dirty || vehicle.touched)">
                          <span *ngIf="vehicle.errors.required" class="text-danger">Vehicle Number is required</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-0 pr-0" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Driver Contact</label>
                  <div style="margin-top: 5px;">
                    <div>
                      <input
                        class="forminputwhitebg"
                        type="text"
                        name="code"
                        [(ngModel)]="deliveryPlanData.code"
                        #code="ngModel"
                        style="width: 100%;"
                        required
                      >
                      <div *ngIf="code.errors && (code.dirty || code.touched)">
                        <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="dp_mode === 'add'" class="col-md-12 col-12 nopadding pl-0">
              <div class="col-md-12 nopadding  pl-0 pr-0">
                <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Delivery Date</label>
                  <div style="margin-top: 5px;">
                    <div class="date-picker-form">
                      <input [selectMode]="'single'"
                             [readonly]="true" [(ngModel)]="deliveryPlanData.date"
                             [owlDateTimeTrigger]="dt"
                             [owlDateTime]="dt" placeholder="Date"
                             name="date"
                             #date="ngModel"
                             style="width: 100%;">
                      <!--<span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                      <div style=" position: absolute;right: 6px;top: 12px;">
                        <span style="padding-left: 3px;"><i class="far fa-calendar-alt"></i></span>
                      </div>

                      <owl-date-time (afterPickerClosed)="changeDate1(dt)" [pickerType]="'calendar'"
                                     #dt></owl-date-time>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 nopadding  pl-0 pr-0" style="margin-top: 20px;">
                  <label style="color: #5D5D5D">Plan Name</label>
                  <div style="margin-top: 5px;">
                    <div class="formselectwhitebg">
                      <select
                        name="addreference"
                        [(ngModel)]="deliveryPlanData.reference"
                        #addreference="ngModel"
                        style="width: 100%;"
                        (change)="populateData()"
                      >
                        <option
                          *ngFor="let item of dpList"
                          value="{{ item.reference }}"
                        >
                          {{ item.reference }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="deliveryPlanData.reference === null">
                      <span class="text-danger">Delivery Plan is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 nopadding roundedcard" style="margin-top: 20px;">
                <div style="padding: 15px;">
                  <div class="col-md-6 pl-0 pt-0 pr-30">
                    <div class="row">
                      <label style="color: #5D5D5D">Delivery Date : </label>
                      <span style="padding-left: 3px;">{{deliveryPlanData.date}}</span>
                    </div>
                    <div class="row" style="margin-top: 6px;">
                      <label style="color: #5D5D5D">Route : </label>
                      <span style="padding-left: 3px;">{{deliveryPlanData.beats}}</span>
                    </div>
                    <div class="row" style="margin-top: 6px;">
                      <label style="color: #5D5D5D">Vehicle Number : </label>
                      <span style="padding-left: 3px;">{{deliveryPlanData.vehicle}}</span>
                    </div>
                    <div class="row" style="margin-top: 6px;">
                      <label style="color: #5D5D5D">Delivered By : </label>
                      <span style="padding-left: 3px;">{{deliveryPlanData.del_person}}</span>
                    </div>
                    <div class="row" style="margin-top: 6px;">
                      <label style="color: #5D5D5D">Driver Contact: </label>
                      <span style="padding-left: 3px;">+91 9828875600</span>
                    </div>
                  </div>
                  <div class="col-md-6 pl-0 pr-30 roundedcard">
                    <label>Invoice Details</label>
                    <div style="margin-top: 10px;">
                      <div class="col-md-4 pl-0 pr-30">
                        <label>Invoices</label>
                        <label style="display:block; color:#8D67BB; font-size: 18px;">{{invoicecount}}</label>
                      </div>
                      <div class="col-md-4 pl-0 pr-30">
                        <label>Weight</label>
                        <label style="display:block; color:#8D67BB; font-size: 18px;">220 kg</label>
                      </div>
                      <div class="col-md-4 pl-0 pr-30">
                        <label>Volume</label>
                        <label style="display:block; color:#8D67BB; font-size: 18px;">400 Ltr</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px 30px 15px 0px;">
          <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;">
           <a class="btn grey-btn">CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          data-dismiss="modal"
          [disabled]="dpform.form.invalid || (dp_mode === 'add')"
          (click)="updateDeliveryPlan()">
          DONE
        </button>
      </div>
    </div>
  </div>
</section>
