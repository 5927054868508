import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {ICellRendererParams} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-uom-select',
  template: `<span *ngIf="editable"><ng-select appendTo="body"
                        [items]="itemList"
                        bindLabel="itemName"
                        bindValue="id"
                        [(ngModel)]="selected_uom_id"
                        (ngModelChange)="uomChange($event)"
  >
  </ng-select></span>
  <span *ngIf="!editable">{{selected_uom_text}}</span>`
})
export class UOMRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  itemList = [];
  // selectedUom = [];
  selected_uom_text = '';
  selected_uom_id = 0;
  // params;
  editable = false;

  agInit(params: any): void {
    this.params = params;
    if (this.params.context.componentParent.editMode) {
      this.editable = true;
    } else {
      this.editable = false;
    }
    if (params.data.readonly) {
      this.editable = false;
    }
    this.itemList = params.value;
    this.selected_uom_id = this.params.data.uom_id;
    this.selected_uom_text = this.params.data.product_uom;
    // if (this.params.data.uom_id) {
    //   this.selectedUom = [{id: this.params.data.uom_id, itemName: this.params.data.product_uom}];
    // } else {
    //   this.selectedUom = [{id: this.params.data.uom_id, itemName: this.params.data.product_uom}];
    //   this.selected_uom_id = this.params.data.uom_id;
    //   this.params.data.product_uom = this.selectedUom[0].itemName;
    //   this.params.data.uom_id = this.selectedUom[0].id;
    //   this.params.data.factor_inv = this.selectedUom[0].factor_inv;
    //   this.params.data.factor = this.selectedUom[0].factor;
    // }
  }

  uomChange(event) {
    const uom_obj = this.itemList.find(item => item.id === this.selected_uom_id);
    this.params.data.readonly = true;
    this.selected_uom_text = uom_obj.itemName;
    this.params.data.product_uom = uom_obj.itemName;
    this.params.data.uom_id = uom_obj.id;
    this.params.data.factor_inv = uom_obj.factor_inv;
    this.params.data.factor = uom_obj.factor;

    // if (event.length > 0) {
      // this.params.context.componentParent.uomEdited = true;
      // this.params.data.product_uom = event[0].itemName;
      // this.params.data.uom_id = event[0].id;
      // this.params.data.factor_inv = event[0].factor_inv;
      // this.params.data.factor = event[0].factor;
    // }
  }

  refresh(): boolean {
    return false;
  }
}
