import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {PageBase} from "../../../pagebase/pagebase";
import { PartnerLeadsCreateUploadComponent } from "./partner_leads_create_upload.component";

@Component({
  selector: 'app-partner-leads',
  templateUrl: '../../../pagebase/pagebasev2.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})
export class PartnerLeadsListComponent extends PageBase implements OnInit, IPage {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'retailer',
    values: [],
    show: true
  };

  ngOnInit(): void {
    this.sharedService.setHeader('Partner Leads');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showCreate = true;

    // filter options
    this.customFilter.values = [
      {id: "retailer", itemName: "Retailer"},
      {id: "supplier", itemName: "Distributor"},
      {id: "project", itemName: "Project"},
      {id: "influencer", itemName: "Influencer"},
    ];

    // Set the default filter value
    this.customFilter.value = this.customFilter.values[0].id; // Default to "Retailer"

    // Configure the grid and load data
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_leads/get_partner_leads_list';
    const inThis = this;

    // Define the grid columns
    this.columnDefs = [
      {headerName: "AREA", field: "area", width: 100},
      {headerName: "ZONE", field: "zone", width: 100},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "CUSTOMER", field: "partner_name", width: 150},
      {headerName: "EMAIL", field: "email_from", width: 80},
      {headerName: "MOBILE", field: "mobile", width: 100},
      {headerName: "SALESMAN", field: "salesman", width: 100},
      {headerName: "ROLE", field: "role", width: 100},
      {headerName: "PLANNED REVENUE", field: "planned_revenue", width: 100},
      /*{headerName: "TYPE", field: "type", width: 150},*/
      {headerName: "DESCRIPTION", field: "description", hide: true, width: 50},
      {headerName: "STAGE", field: "stage", width: 150},
      {headerName: "REGION", field: "region", width: 100},
      {headerName: "STATE", field: "state", width: 100},
      {
        headerName: "CREATE DATE", field: "create_date", width: 80,
        valueGetter: params => {
          if (params.data && params.data.create_date) {
            return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
          } else {
            return '';
          }
        }
      },
    ];
  }

  openCreateDialog() {
    this.openPartnerLeadsCreateUpload();
  }

  openPartnerLeadsCreateUpload() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }
      };
      creatediaeref = this.dialog.open(PartnerLeadsCreateUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          type_id: this.userData.customFilter['type']
        }
      };
      creatediaeref = this.dialog.open(PartnerLeadsCreateUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  onCellClicked(event) {
   /* const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('customerid', rows[0].id);
    localStorage.setItem('customerindex', event.rowIndex);
    this.router.navigate([this.apiService.redirect_url + '/customers/leads/details'], {
      state: {
        viewtype: 'detail',
        customerid: rows[0].id
      }
    });*/
  }
}
