<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
/>
<script src="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.js"></script>

<div class="content">
    <div class="ui grid">
        <div class="{{layoutColumnOneWidth}} wide column">
            <app-typeahead-input
                    [searchList]="objectTitleList"
                    [placeHolderText]="placeHolderText"
                    [typeAheadIsInMenu]="typeAheadIsInMenu"
                    (selectionEvent)="filterListBySearchString($event)">
            </app-typeahead-input>
            <app-filter-list #filterComponent
                             [facet_tags]="facetTags"
                             (tagSelectEvent)="filterTag.updateFilterList($event)">

            </app-filter-list>
        </div>
        <div class="{{layoutColumnTwoWidth}} wide column">
            <br>
            <app-filter-tag #filterTag
                            (updateFilterListEvent)="filterListByTags($event)">

            </app-filter-tag>

            <div class="list-content">
                <ng-template ngFor [ngForOf]="objectList" [ngForTemplate]="template"></ng-template>
            </div>
        </div>
    </div>
</div>
