// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment = {
  appVersion: 'v' + require('../../package.json').version.split('.')[0] + '.' + require('../../package.json').version.split('.')[1] + '-dev',
  production: false,
  loginURL: 'https://uatlogin.salesdiary.in:2001',
  //loginURL: 'https://sdlogin.salesdiary.in:2001',
  // instanceURL: 'http://localhost:3000',
  instanceURL: 'from_login',
  oktaConfig: {
    clientId: '0oa18drw5jD0v1Tnl5d7',
    issuer: 'https://dev-14198474.okta.com/oauth2/default',
    redirectUri: 'http://localhost:4200/login',
    scopes: ['openid', 'profile', 'email'],
    pkce: false
  },
  apiKey: 'AIzaSyBSNMC7ZoqOHYTFgoySTMrAICL9LgPEAB0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
