import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'meet-review-popup',
  templateUrl: './meet_review_popup.component.html',
  styleUrls: ['./meet_review_popup.component.scss']

})

export class MeetReviewPopupComponent implements OnInit {

  @Output() meetReviewSubmit: EventEmitter<any> = new EventEmitter<any>();

  public review_comments = '';

  constructor(
    public dialog: MatDialog) {

  }

  ngOnInit(): void {

  }

  performAction() {
    this.meetReviewSubmit.emit(this.review_comments);
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
