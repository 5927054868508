<div class="eod-list-card"
     [ngClass]="{'audit': type === 'Retail Audit',
     'survey': type === 'Survey',
     'sales': type === 'Sales',
     'finance': type === 'Finance',
     'master': type === 'Master',
     'productivity': type === 'Productivity',
     'stock': type === 'Stock',
     'compliance': type === 'Compliance',
     'multiinstance': type === 'Multi Instance',
     'scheme': type === 'Scheme',
     'targets': type === 'Targets'
     }"
>
  <div style="cursor: pointer" (click)="goToReport()" class="eod-list-card-heading">
    <h3>{{type}}</h3>
  </div>
  <div style="cursor: pointer;" (click)="goToReport()" class="eod-list-card-desc">
    <h2>{{title}}</h2>
  </div>
  <div>
    <div style="cursor: pointer;" (click)="goToReport()" class="eod-list-card-statics">
      <label>{{code}}</label>
    </div>
    <div style="cursor: pointer;float:right;" (click)="goToReport()" class="eod-list-card-open"
         [ngClass]="{'audit': type === 'Retail Audit',
     'survey': type === 'Survey',
     'sales': type === 'Sales',
     'finance': type === 'Finance',
     'master': type === 'Master',
     'productivity': type === 'Productivity',
     'stock': type === 'Stock',
     'compliance': type === 'Compliance',
     'multiinstance': type === 'Multi Instance',
     'scheme': type === 'Scheme',
     'targets': type === 'Targets'
     }">
      <span>OPEN</span>
    </div>
  </div>
</div>
