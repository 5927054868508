import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-productionlistdesc',
  templateUrl: './productionlistdesc.component.html',
  styleUrls: ['./productionlistdesc.component.scss']
})
export class ProductionlistdescComponent implements OnInit {
  @Input() value: string;
  @Input() perc: string;
  @Input() title: string;
  @Input() unit: string;

  constructor() { }

  ngOnInit() {
  }

}
