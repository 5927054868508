import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-tooltip1-component',
  template: ` <div *ngIf="enable" class="custom-tooltip" [style.background-color]="color">
    <div class="row">
      <div class="col-md" *ngFor="let elem of actkeys">
        <p>{{actchannelDict[elem]}}</p>
        <p>{{elem}}</p>
      </div>
    </div>
    <div class="row" style="background-color: lightblue;">
      <div class="col-md" *ngFor="let elem of keys">
        <p>{{channelDict[elem]}}</p>
        <p>{{elem}} sug</p>
      </div>
    </div>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: fit-content;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        border: 1px solid #C4C4C4;
        border-radius: 5px;
      }
      .padR {
        padding-right: 20px;
      }

      .padL {
        padding-left: 20px;
      }

      .mrgR {
        border-right: #C4C4C4 1px solid;
      }

      .mrgD {
        border-bottom: #C4C4C4 1px solid;
      }

      .num {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
      }
      .num_label {
        color: darkblue;
        margin-left: 5%;
      }

      .cGrey {
        color: lightgrey;
      }

      .cRed {
        color: indianred;
      }
    `,
  ],
})
export class TooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data;
  public color!: string;
  public enable = false;
  public prodRange;
  public channelDict;
  public actchannelDict;
  public actChannelProd;
  public keys = [];
  public actkeys = [];

  agInit(params: any): void {

    this.params = params;
    this.enable = false;
    this.color = 'white';
    this.prodRange = this.params.context.componentParent.prodRange;
    this.actChannelProd = this.params.context.componentParent.actChannelProds;
    if (this.params.context.componentParent.enable_recommented && (this.params.data.Outlet === 'SUGGESTED')) {
      const channel = (this.params.context.componentParent.prodRange[this.params.colDef['field']]).split('$');
      if (channel.length > 2) {
        this.channelDict = JSON.parse(channel[2])[0];
        this.keys = (Object.keys(this.channelDict)).sort();
        this.actchannelDict = this.actChannelProd[this.params.colDef['field']];
        if (this.actchannelDict) {
          this.actkeys =  (Object.keys(this.actchannelDict)).sort();
          this.enable = true;
        }
      }
    }
  }
}
