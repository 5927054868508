import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../reports/_common/reportbase';
import {ErrorHandler} from '../error/error-handler';
import {GridOptions} from "@ag-grid-enterprise/all-modules";


@Component({
  selector: 'app-outlet-agg-report',
  templateUrl: './outlet_agg_report.component.html',
  styleUrls: ['./outlet_agg_report.component.scss']
})
export class OutletAggReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  ngOnInit() {
    this.sharedService.setHeader('Outlet Aggregate Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      autoGroupColumnDef: {
        headerName: 'Date',
        field: 'date_order',
        minWidth: 350,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true,
        },
        resizable: true,
        pinned: 'left'
      },
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      groupIncludeFooter: true,
      groupIncludeTotalFooter: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: true,
      pagination: false,
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;


    this.configureGrid();
    //this.loadServerData();
  }

  setTitle() {

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getOutletAggregateReport';

    this.columnDefs = [
      {
        headerName: 'REGION',
        headerClass: 'myagheader',
        field: 'region',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: 'CLUSTER',
        headerClass: 'myagheader',
        field: 'cluster',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: 'TERRITORY',
        headerClass: 'myagheader',
        field: 'territory',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: 'OTYPE',
        headerClass: 'myagheader',
        field: 'o_type',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
        enablePivot: true,
        hide: true,
      },
      {
        headerName: 'PROGRAM TYPE',
        headerClass: 'myagheader',
        field: 'p_type',
        sortable: true,
        filter: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
      },
      {
        headerName: 'RETAILER',
        headerClass: 'myagheader',
        field: 'name',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
      },
      {headerName: 'CODE', headerClass: 'myagheader', field: 'code', sortable: true, filter: true, resizable: true},
      {headerName: 'CLASS', headerClass: 'myagheader', field: 'class', sortable: true, filter: true, resizable: true, enablePivot: true,},
      {headerName: 'PRICELIST', headerClass: 'myagheader', field: 'pricelist', sortable: true, filter: true, resizable: true},
      /* { headerName: "OUTSTANDING" , headerClass: 'myagheader', field: "outstanding", sortable: true, filter: true,  resizable: true, aggFunc: 'sum'},*/
      {
        headerName: 'DATE',
        headerClass: 'myagheader',
        field: 'date_order',
        sortable: true,
        filter: true,
        resizable: true,
        rowGroup: true,
        enableRowGroup: true,
      },
      {
        headerName: 'RETURN',
        headerClass: 'myagheader',
        field: 'rtn',
        sortable: true,
        hide: true,
        filter: true,
        resizable: true,
        aggFunc: 'sum',
        width: 100
      },
      {
        headerName: 'RETURN QTY',
        headerClass: 'myagheader',
        field: 'rtn_qty',
        sortable: true,
        filter: true,
        resizable: true,
        aggFunc: 'sum',
        width: 100
      },
      /* { headerName: "UPC", headerClass: 'myagheader', field: "upc", sortable: true, filter: true,  resizable: true, aggFunc: 'sum'},*/
      {
        headerName: 'SALES',
        headerClass: 'myagheader',
        field: 'sales',
        sortable: true,
        filter: true,
        resizable: true,
        aggFunc: 'sum',
        width: 100
      },
      {
        headerName: 'SALES QTY',
        headerClass: 'myagheader',
        field: 'sales_qty',
        sortable: true,
        filter: true,
        resizable: true,
        aggFunc: 'sum',
        width: 100
      },
      {
        headerName: 'Category',
        headerClass: 'myagheader',
        field: 'categ_name',
        sortable: true,
        filter: true,
        resizable: true,
        pivot: true,
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Product',
        headerClass: 'myagheader',
        field: 'product',
        sortable: true,
        filter: true,
        resizable: true,
        enablePivot: true,
        enableRowGroup: true,
      },
    ];
  }

  loadServerData() {
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    /*this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');*/

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            // this.rowData = res.results.data;
            this.apiData = res.results.data;
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.status));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  configureData() {
//debugger
    this.rowData = [];
    for (var key in this.apiData.master) {
      var indrow = {};
      var masterrow: any = {};
      var detailsrow: any = [];

      masterrow = this.apiData.master[key];

      detailsrow = this.apiData.detail.filter(detail => detail.o_id === masterrow.o_id);


      for (var key in detailsrow) {
        var indrow1 = detailsrow[key];
        indrow = {
          ...masterrow,
          ...indrow1
        };

        this.rowData.push(indrow);
      }
    }
    console.log(this.rowData);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(function() {
        /* if ( noOfColumns < 16 && screen.width > 991 ) {
           params.api.sizeColumnsToFit();
         } else {
           params.columnApi.autoSizeColumns();
         }*/
        params.columnApi.autoSizeColumns();
      });
    });

    /* if (screen.width > 991) {
       this.gridApi.sizeColumnsToFit();
     } else {
       this.gridColumnApi.autoSizeColumns();
     }*/
    this.gridColumnApi.autoSizeColumns();
    /* if ( noOfColumns < 16 && screen.width > 991) {
       this.gridApi.sizeColumnsToFit();
     } else {
       this.gridColumnApi.autoSizeColumns();
     }*/

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

}
