import {ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../_services/api.service";
import {ToastrService} from "ngx-toastr";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import * as XLSX from "xlsx-js-style";
import {ErrorObject} from "../../../error/error-model";
import {ErrorHandler} from "../../../error/error-handler";
import {ngxCsv} from "ngx-csv";
import {LocalforageService} from "../../../_services/localforage.service";


export abstract class BaseUpload implements OnInit {


  public fileUploaded: File;
  public validateButton = true;
  public upload_loader = false;
  public worksheet: any;
  public data_from_sheet = [];

  public title = 'UPLOAD DATA';
  public message = 'Default Message';
  public instruction = 'Default Instruction';
  public active_id = 0;
  public upload_type = '';
  public USER: any = {};

  public excel_columns = [];
  public stub_data = [];

  public data_to_validate = [];
  public data_to_upload = [];
  public userContext = {
    access_token: null,
    data: null
  };

  invalidFormatError = false;
  uploadConfirmFlag: boolean;
  errorDataCount = 0;
  totalDataCount = 0;
  validDataCount = 0;

  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  rowData = [];
  public getRowId;
  public gridColumnApi: any;
  public gridApi: any;


  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              protected _localCacheService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.userContext.access_token = localStorage.getItem('resfreshToken');
    this.USER = JSON.parse(localStorage.getItem('user'));

    this.upload_type = dialogdata.upload_type;
    this.title = dialogdata.title;
    this.message = dialogdata.message;
    this.instruction = dialogdata.instruction;
    this.active_id = dialogdata.active_id;

    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowSelection: 'multiple',
      groupSelectsChildren: false,
      enableRangeSelection: false,
      enableCharts: false,
      animateRows: false,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        autoHeight: true,
        wrapText: true
      },
    } as GridOptions;

  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      let data: any;
      // @ts-ignore
      data = new Uint8Array(readFile.result);
      const arr = [];
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.data_from_sheet = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(this.data_from_sheet);
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }


  handleError(error: ErrorObject) {
    this.upload_loader = false;
    this.toastr.error(error.summary);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    // this.preloader = false;
    //this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  getRecords(GET_RECORDS_API_URL, context, callback) {

    this.apiService.post(GET_RECORDS_API_URL, context)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            return callback(null,  res.results.data);
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            return callback('Error',  null);
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            return callback('Error',  null);
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
        this.handleError(ErrorHandler.getErrorObject(error));
        return callback('Error',  null);
      });
  }

  downloadExcel(excel_rows, file_name) {
    const headers = Object.keys(excel_rows[0]);
    const fields = [];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'ProductData',
      useBom: true,
      noDownload: false,
      headers
    };

    return new ngxCsv(excel_rows, file_name, options);
  }


  toLowerCaseProps(obj) {
    return Object.entries(obj).reduce((a, [key, val]) => {
      a[key.toLowerCase().trim().replace(' ', '')] = val;
      return a;
    }, {} ) as unknown;
  }

  getFieldValue(row, field, default_value?: any) {
    if (row[field]) {
      return row[field].toString().trim();
    } else if (default_value) {
      return default_value;
    } else {
      return undefined;
    }
  }

  updateImmutableObject(original: any, newValues: any) {
    // start with new object
    const newObject: any = {};
    // copy in the old values
    Object.keys(original).forEach(key => {
      newObject[key] = original[key];
    });
    // now override with the new values
    Object.keys(newValues).forEach(key => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }

  get_mid(object_id) {
    if (object_id) {
      return this.USER.id + '_' +  object_id + '_' + (new Date()).getTime();
    } else {
      return this.USER.id + '_' + (new Date()).getTime();
    }
  }

}
