<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div  class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                  <div class="row">
                    <div  *ngIf="!editMode" class="button-row">
                      <button
                        class="btn grey-button"
                      >VIEW ORDER
                      </button>
                    </div>
                    <div  *ngIf="!editMode" class="button-row">
<!--                      <button-->
<!--                        class="btn grey-button"-->
<!--                        (click)="editInvoice()"-->
<!--                      >EDIT-->
<!--                      </button>-->
                    </div>
                    <div  *ngIf="!editMode" class="button-row">
<!--                      <button-->
<!--                        class="btn grey-button"-->
<!--                      >CANCEL-->
<!--                      </button>-->
                    </div>
                    <div  *ngIf="!editMode" class="button-row">
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                      >CONFIRM RETURN-->
<!--                      </button>-->
                    </div>
                    <div  *ngIf="editMode" class="button-row">
<!--                      <button-->
<!--                        style="margin-left:0px !important;"-->
<!--                        class="btn grey-button"-->
<!--                        (click)="discard()"-->
<!--                      >-->
<!--                        DISCARD-->
<!--                      </button>-->
                    </div>
                    <div  *ngIf="editMode" class="button-row">
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                        (click)="saveCart()"-->
<!--                      >-->
<!--                        SAVE-->
<!--                      </button>-->
                    </div>
                    <div style="position: absolute;right: 30px;">
                      <div class="button-row" style="display: flex;align-items: center">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; font-size: 12px;">{{rowIndex+1}}/100</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === 100-1,
                         'enabledspan': rowIndex < 100-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-3 pad0">
              <div class="col-md-9 pt-3 pad0">
                <div class="col-md-12 pt-3 pad0">

                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">CUSTOMER</label>
                    <div  style="margin-top: 5px;" class="formselect">
                      <div style="width: 90%">
                        <ng-select *ngIf="editMode && !linkedInvoiceMode"
                                   [items]="customerList"
                                   bindLabel="name"
                                   bindValue="id"
                                   (ngModelChange)="customerChangeEvent($event)"
                                   [(ngModel)]="activeObject['partner_id']">
                          >
                        </ng-select>
                      </div>
                      <div  >
                        <img style="width: 4%;margin-left: 2%;"
                             (click)="showCustomerSelection()" *ngIf="editMode  && !linkedInvoiceMode"
                             src="../../../../assets/clipart3513671.svg"/>
                      </div>
                      <p class="lbl3"
                         *ngIf="!editMode || linkedInvoiceMode">{{activeObject['customer_name']}}</p>
                    </div>
                    <div style="display: inline">
                      <p style="display: inline" *ngIf="activeObject['customer_code']" class="lbl2">Code : {{activeObject['customer_code']}}</p>
                      <p style="display: inline" *ngIf="activeObject['customer_gst_id']" class="lbl2 ml-3">Tax Id : {{activeObject['customer_gst_id']}}</p>
                    </div>
                    <div>
                      <p *ngIf="activeObject['pricelist_name']" class="lbl2">Pricelist : {{activeObject['pricelist_name']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">SUPPLIER</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <select
                        *ngIf="editMode"
                        name="suppname"
                        [(ngModel)]="activeObject['supplier_id']"
                        #suppname="ngModel"
                        style="width: 90%;"
                      >
                        <option
                          *ngFor="let item of supplierList"
                          value="{{ item.id }}"
                        >
                          {{ item.name }}
                      </select>
                      <div *ngIf="!editMode">
                        <p class="lbl3" *ngIf="activeObject['supplier_name']">{{activeObject['supplier_name']}}</p>
                      </div>
                      <div>
                        <p *ngIf="activeObject['supplier_gst_id']" class="lbl2">Tax Id : {{activeObject['supplier_gst_id']}}</p>
                      </div>
                      <div>
                        <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{activeObject['supplier_code']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">SALESMAN</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <ng-select *ngIf="userList && userList.length > 0 && editMode"
                                 [items]="userList"
                                 bindLabel="itemName"
                                 bindValue="id"
                                 (ngModelChange)="salesmanChange($event)"
                                 [(ngModel)]="activeObject['user_id']">
                      >
                      </ng-select>
                      <div>
                        <p class="lbl3" *ngIf="activeObject['salesman'] && !editMode">{{activeObject['salesman']}}</p>
                        <p *ngIf="activeObject['emp_code']" class="lbl2">Code : {{activeObject['emp_code']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BEAT</label>
                    <div style="margin-top: 5px;" class="formselect">
                      <p class="lbl3">{{activeObject['beat_name']}}</p>
                    </div>
                  </div>

                </div>
                <div class="col-md-12 pt-2 pad0" style="margin-top: 12px;">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">BILL TO</label>
                    <div class="formselect"> <!-- *ngIf="billto_adrr_list" -->
<!--                      <select-->
<!--                        *ngIf="billto_adrr_list.length > 1 && editMode"-->
<!--                        name="custaddrss"-->
<!--                        [(ngModel)]="selected_billto_addr_id"-->
<!--                        (ngModelChange)="billaddrssChange($event)"-->
<!--                        #custaddrss="ngModel"-->
<!--                        class="address-sel"-->
<!--                      >-->
<!--                        <option-->
<!--                          *ngFor="let item of billto_adrr_list"-->
<!--                          [ngValue]="item"-->
<!--                        >-->
<!--                          {{ item.itemName }}-->

<!--                      </select>-->
                      <div *ngIf="activeObject">
                        <p class="lbl3" *ngIf="activeObject.customer_address">{{activeObject.customer_address}}</p>
<!--                        <p *ngIf="customer_billto_addr_code" class="lbl2">Code : {{customer_billto_addr_code}}</p>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">SHIP TO</label>
                    <div *ngIf="shipto_adrr_list" class="formselect">
                      <select
                        *ngIf="shipto_adrr_list.length > 0 && editMode"
                        name="suppaddrss"
                        [(ngModel)]="activeObject['shipping_partner_id']"
                        (ngModelChange)="shipaddrssChange($event)"
                        #suppaddrss="ngModel"
                        class="address-sel"
                      >
                        <option
                          *ngFor="let item of shipto_adrr_list"
                          [ngValue]="item"
                        >
                          {{ item.itemname }}

                      </select>
                      <div *ngIf="activeObject.shipto_address && (!editMode) ">
                        <p class="lbl3" *ngIf="activeObject.shipto_address">{{activeObject.shipto_address}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">

                  </div>
                </div>
              </div>
              <div class="col-md-3 pt-3 pad0">
                <div class="col-md-12 pl-0 pr-0" *ngIf="newMode">
                  <label class="lbl">DATE</label>
                  <div>
                    <div>
                      <input
                        class="forminput"
                        type="text"
                        name="date"
                        style="width:60%"
                        [disabled]="dateDisable"
                        [readonly]="true"
                        [(ngModel)]="activeObject['order_date']"
                        #date="ngModel"
                        [owlDateTime]="dt"
                        [owlDateTimeTrigger]="dt"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
                    <!--                    <p class="lbl3" [hidden]="sharedService.dmsParams.new_mode || (sharedService.dmsParams.edit_mode && !invEnable &&-->
                    <!--                             dmsStatus === 'confirm')">{{poDate}}</p>-->
                  </div>
                </div>
                <div class="col-md-12 pl-0 pr-0 shadow-box" *ngIf="!newMode">
                  <label class="lbl4" style="margin-left: 35%;margin-top:3%;">ORDER INFORMATION</label>
                  <div style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Order Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['order_no']}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Order Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['order_date']}}</span></label>
                  </div>
                  <div *ngIf="activeObject['inv_date']" style="margin-top:4%;padding-bottom: 8px;">
                    <label class="lbl4" style="margin-left: 4%;">Invoice Number &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['name']}}</span></label><br/>
                    <label class="lbl4" style="margin-left: 4%;margin-top: 3%;">Invoice Date &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span class="lbl4" style="font-weight: 400;color:black">{{activeObject['date_invoice']}}</span></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0" *ngIf="editMode">
              <div class="col-md-4 pl-0 pr-30">
                <label class="lbl">PRODUCT</label>
                <div class="formselect">
                  <ng-select
                             #productSelector
                             [items]="prodList"
                             bindLabel="product"
                             bindValue="p_id"
                             [virtualScroll]="true"
                             [(ngModel)]="selectedProduct"
                             (ngModelChange)="productSelectorChange()"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">QTY</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="qty"
                      onlyNumber
                      [(ngModel)]="enteredQty"
                      #qty="ngModel"
                      style="width: 100%;"
                      (keydown.enter)="addProductClick()"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30" *ngIf="UOMList">
                <label class="lbl">UOM</label>
                  <div class="formselect">
                    <ng-select
                      #productSelectorUOM
                      [items]="UOMList"
                      bindLabel="itemName"
                      bindValue="id"
                      (ngModelChange)="productUOMChange()"
                      [(ngModel)]="selectedUOM_id"
                    >
                    </ng-select>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label class="lbl">STOCK</label>
                <div>
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="stck"
                      [(ngModel)]="stock"
                      [disabled]="true"
                      style="width: 100%;"
                      #stck="ngModel"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-1 pt-4 pl-0 pr-30">
                <button class="teal-btn2"
                        (click)="addProductClick()"
                >
                  ADD
                </button>
              </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 [context]="context"
                                 [isExternalFilterPresent]="isExternalFilterPresent"
                                 [doesExternalFilterPass]="doesExternalFilterPass"
                                 (rowEditingStarted)="onRowEditingStarted($event)"
                                 (rowEditingStopped)="onRowEditingStopped($event)"
                                 (cellEditingStarted)="onCellEditingStarted($event)"
                                 (cellEditingStopped)="onCellEditingStopped($event)"
                >
                </ag-grid-angular>
<!--                <div *ngIf="preloader">-->
<!--                  <app-loadingstate></app-loadingstate>-->
<!--                </div>-->
<!--                <div *ngIf="emptyState">-->
<!--                  <app-emptystate></app-emptystate>-->
<!--                </div>-->
              </div>
            </div>
            <div  class="col-md-6 mt-2" style="margin-top: 20px; !important">
            </div>
            <div *ngIf="activeObject" class="col-md-6 mt-2">
              <table style="float: right;">
                <tbody>
                <tr>
                  <td ><p class="p-class">Taxable Amount</p></td>
                  <td ><p class="p-class1">{{math.abs(activeObject['amount_untaxed']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                <tr>
                  <td ><p class="p-class">Taxes</p></td>
                  <td ><p class="p-class1">{{math.abs(activeObject['amount_tax']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                <tr style=" border-top: 2px solid #f3f3f3;">
                  <td ><p class="p-class3">GRAND TOTAL</p></td>
                  <td ><p class="p-class4">{{math.abs(activeObject['amount_total']).toFixed(apiService.decimalPolicy)}}</p></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


