import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-territory-user-mapping-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TerritoryUsermappingReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  dynamicProfileFields: any = [];
  dynamicRoleFields: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Territory User mapping Report');

    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    // this.loadInBatches(10000, 'p_id');
    // this.showProgressCustomText = true;



    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.API_URL = '/api/pwa_reports/getTerritoryUserMappingReport';

  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.dynamicRoleFields = new Set();
    this.apiData.headers.forEach(obj => {
      this.columnDefs.push({headerName: obj.role, field: 'r_' + obj.id, enableRowGroup: true});
    });

    this.apiData.master.forEach(obj => {
      if (obj.acl !== null && obj.acl !== undefined) {
        obj.acl.forEach( (rec) => {
          if (obj['r_' + rec.role_id] !== undefined) {
            obj['r_' + rec.role_id] = obj['r_' + rec.role_id] + ', ' + rec.user;
          } else {
            obj['r_' + rec.role_id] = rec.user;
          }
        });
      }
    });
    this.rowData = this.apiData.master;
  }

  configureGrid() {

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'NAME',
      field: 'name',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer'
    };

    this.columnDefs = [
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.state) {
              return params.data.state;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'REGION', field: 'region', enableRowGroup: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.region) {
              return params.data.region;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.cluster) {
              return params.data.cluster;
            } else {
              return 'NA';
            }
          }
        }
      },
      {headerName: 'ID', field: 'id', width: 50},
      {headerName: 'TERRITORY CODE', field: 'code', width: 50},
      {headerName: 'TERRITORY NAME', field: 'name', width: 100},
      {headerName: 'ACTIVE', field: 'active', width: 40},
      {headerName: 'MANAGER', field: 'ter_manager', width: 60},
      {headerName: 'CLUSTER MANAGER', field: 'clu_manager', width: 60},
      {headerName: 'REGION MANAGER', field: 'clu_manager', width: 60},
      {headerName: 'USERS', field: 'user_count', hide: true, width: 100},
      {headerName: 'CREATED ON', field: 'create_date', hide: true, width: 60},
      {headerName: 'CREATED BY', field: 'created_by', hide: true, width: 60},
      {headerName: 'LAST MODIFIED', field: 'write_date', hide: true, width: 60},
      {headerName: 'LAST MODIFIED BY', field: 'modified_by', hide: true, width: 60},

    ];
  }

}
