import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class EmployeeMasterReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Employee Master Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getEmployeeMasterReport';
    const inthis = this;
    this.columnDefs = [

      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_team', width: 100},
      {headerName: 'TEAM', field: 'team_name', width: 100},
      {headerName: 'EMP CODE', field: 'emp_code', menuTabs: [], width: 80},
      {headerName: 'EMP NAME', field: 'user_name', width: 150},
      {headerName: 'ROLE', field: 'role', width: 100},
      {headerName: 'SA CODE', field: 'sales_area_code', menuTabs: [], width: 80},
      {headerName: 'MOBILE', field: 'mobilenumber', width: 100},
      {headerName: 'EMAIL', field: 'user_email', width: 150},
      {headerName: 'REPORTING MANAGER', field: 'reporting_manager', hide: true, width: 150},
      {headerName: 'RM ROLE', field: 'rm_role', hide: true, width: 150},
      {headerName: 'ACTIVE', field: 'status', width: 80},
      {headerName: 'CREATE DATE', field: 'create_date', width: 80},
      {headerName: 'INACTIVATION DATE', field: 'inactivation_date', width: 80},
      {headerName: 'DOJ', field: 'doj', width: 80},
      {headerName: 'DOL', field: 'dol', width: 80},
      {headerName: 'TERRITORY', field: 'territory', width: 80},
      {headerName: 'TER CODE', field: 'ter_code', width: 80},
      {headerName: 'VERTICAL', field: 'vertical', hide: true, width: 80},
      {
        headerName: 'LAST ACTIVITY',
        children: [
          {
            headerName: 'PWA', field: 'last_pwa_login', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.last_pwa_login) {
                  return inthis.datePipe.transform((new Date(params.data.last_pwa_login + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.last_pwa_login;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'PWA VER', field: 'pwa_version', width: 100},
          {
            headerName: 'APP', field: 'last_app_login', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.last_app_login) {
                  return inthis.datePipe.transform((new Date(params.data.last_app_login + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.last_app_login;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'APP VER', field: 'app_version', width: 100},
        ]
      },
    ];

  }

}
