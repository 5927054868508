import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {ApproveRejectCommentsPopupComponent} from "../approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-location-popup',
  templateUrl: './pod_popup.component.html',
  styleUrls: ['./pod_popup.component.scss']

})

export class PODPopupComponent implements OnInit {
  @Output() podverified: EventEmitter<any> = new EventEmitter<any>();
  data;
  photo;
  store_fname;
  container;
  name;
  type;
  API_URL = '';
  date_delivered;
  showApprove = false;
  rejectComments;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              protected datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.data = dialogdata.data;
    console.log(this.data);
    this.container = 'sale_order_gallery';
    if (this.data.config_json && this.data.config_json.hasOwnProperty('pod') && this.data.config_json.pod.hasOwnProperty('type')) {
      this.type = this.data.config_json.pod.type;
    } else {
      this.type = this.data?.POD?.type;
    }

    if (this.type) {
      this.type = this.type.toString().toUpperCase().replace(/_/g, " ");
    }
    if (this.type === 'QR VALIDATED') {
      this.name = this.data['RETAILER NAME'];
    } else {
      this.name = this.data.contact_name;
    }
    if (this.type !== 'OTP') {
      if (this.data.hasOwnProperty('POD') && this.data.POD.hasOwnProperty('image')) {
        this.store_fname = this.data.POD.image;
      }
    }
    if (this.data.date_delivered) {
      this.date_delivered = datePipe.transform((new Date(this.data.date_delivered + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
    }
    if (this.data?.config_json?.pod_reject_comments) {
      this.rejectComments = this.data?.config_json?.pod_reject_comments;
    } else {
      this.rejectComments = null;
    }
    if (this.data.state !== 'cancel' && this.type === 'QR VALIDATED' && !this.rejectComments &&
      (!this.data?.config_json?.pod || (this.data?.config_json?.pod && this.data?.config_json?.pod.hasOwnProperty('image_verified') && this.data?.config_json?.pod?.image_verified === false))) {
      this.showApprove = true;
    }
  }

  ngOnInit(): void {
    this.fetchImage();
  }

  fetchImage() {
    this.API_URL = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
            this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {

          }
        },
        error => {
          console.log(error);

        });
  }

  verifyPOD() {
    this.API_URL = '/api/sale_orders/verify_pod_image';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.data.ID;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            this.toastr.success("POD verified succcessfully");
            this.podverified.emit(true);
          } else {
            this.toastr.error("Failed to verify POD");
          }
          this.closeDialog();
        },
        error => {
          console.log(error);
        });
  }

  openRejectPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "380px",
        panelClass: 'custom-dialog-container',
        data: {
          title : "Reject POD",
          message1 : "Are you sure you want to Reject POD Image?",
          message2 : "Please provide a detailed reason for the rejection",
          type: "reject",
          buttonText: "Reject"
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.rejectPOD( data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          title : "Reject POD",
          message1 : "Are you sure you want to Reject POD Image?",
          message2 : "Please provide a detailed reason for the rejection",
          type: "reject",
          buttonText: "Reject"
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.rejectPOD( data);
      });
    }
  }

  rejectPOD(comments) {
    this.API_URL = '/api/sale_orders/reject_pod_image';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.data.ID;
    paramObject.comments = comments;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            this.toastr.success("POD Rejected!!");
            this.podverified.emit(true);
          } else {
            this.toastr.error("Failed to verify POD");
          }
          this.closeDialog();
        },
        error => {
          console.log(error);
        });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
