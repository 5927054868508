import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from '../../../node_modules/@ag-grid-enterprise/all-modules';
import {ApiService} from '../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {ErrorObject} from "../error/error-model";
import {ErrorHandler} from "../error/error-handler";
import {ColorHelper} from "@swimlane/ngx-charts";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-growth-report',
  templateUrl: './growth-report.component.html',
  styleUrls: ['./growth-report.component.scss']
})
export class GrowthReportComponent implements OnInit {

  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    flag: true,
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0
    },
    customFilter: {}
  };

  API_URL = '';

  rowData = [];
  public myDate;
  public columnDefs = [];
  public searchValue;
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  frameworkComponents: any;
  context: any;
  channeloptions: any;
  categoryoptions: any;
  chartData = [];
  detailsData = [];
  tVSaChartData = [];
  channelChartData = [];
  categoryChartData = [];
  multi: any[];
  view: any[] = [440, 270];
  view1: any[] = [400, 170];
  view2: any[] = [440, 150];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'Population';
  animations = true;
  categoryChartTitle = 'Category Projection';
  colorScheme = {
    domain: ['#F3622D', '#57B757']
  };
  colorScheme1 = {
    domain: [ '#F3622D', '#2e5bff', '#57B757', '#C7B42C']
  };
  chartNames: any[];
  activeEntries: any[];
  colors: ColorHelper;
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private elRef: ElementRef,
              private datePipe: DatePipe) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate;
    this.userData.offset = -1 * (new Date(this.userData.start_date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      autoGroupColumnDef: {
        headerName: 'Salesman',
        field: 'user_name',
        minWidth: 200,
        filter: true,
        resizable: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true,
        },
      },
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      groupIncludeFooter: true,
      groupIncludeTotalFooter: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      pagination: false,
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;
    this.chartNames = ['LMTD Return', 'LMTD', 'MTD'];
    this.colors = new ColorHelper(this.colorScheme, 'ordinal', this.chartNames, this.colorScheme);
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (noOfColumns < 16 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getMTDGrowthReport';
    this.columnDefs = [
      {field: "region", rowGroup: true, hide: true},
      {field: "cluster", rowGroup: true, hide: true},
      {field: "territory", rowGroup: true, hide: true},
      {headerName: "Target",  field: "target", cellStyle: {textAlign: 'right'}, width: 75, aggFunc: 'sum'},
      {headerName: "LMTD Sales",  field: "lmtd_sale_achv", cellStyle: {textAlign: 'right'}, width: 75, aggFunc: 'sum'},
      {headerName: "MTD Sales",  field: "mtd_sale_achv", cellStyle: {textAlign: 'right'}, width: 75, aggFunc: 'sum'},
      {
        headerName: "Growth(%)",  field: "growth",
        cellStyle(params) {
          if (params.value === '') {
            return {textAlign: 'right'};
          } else if (params.value <= -2) {
            return {textAlign: 'right', backgroundColor: '#F8D1C8'};
          } else if (params.value > -2 && params.value <= 0) {
            return {textAlign: 'right', backgroundColor: '#F0E199'};
          } else if (params.value > 0) {
            return {textAlign: 'right', backgroundColor: '#B3DFB0'};
          } else {
            return {textAlign: 'right'};
          }
        },
        width: 50, aggFunc: this.avgAggFunction
      },
      {headerName: "Projection",  field: 'projection', cellStyle: {textAlign: 'right'}, width: 75, aggFunc: 'sum'},
      {
        headerName: "Return%",  field: "return_pct", cellStyle: {textAlign: 'right'}, width: 75,
        aggFunc: this.avgAggFunction
      },
      {
        headerName: "Return CHG%",  field: "return_chg_pct",
        cellStyle(params) {
          if (params.value === '') {
            return {textAlign: 'right'};
          } else if (params.value <= 0) {
            return {textAlign: 'right', backgroundColor: '#F8D1C8'};
          } else if (params.value > 0 && params.value <= 1) {
            return {textAlign: 'right', backgroundColor: '#F0E199'};
          } else if (params.value > 1) {
            return {textAlign: 'right', backgroundColor: '#B3DFB0'};
          } else {
            return {textAlign: 'right'};
          }
        },
       width: 50, aggFunc: this.avgAggFunction
      },
      {headerName: "Outlet",  field: "r_count", cellStyle: {textAlign: 'right'}, width: 50, aggFunc: 'sum'},
      {headerName: "UPC",  field: "mtd_upc", cellStyle: {textAlign: 'right'}, width: 50, aggFunc: 'sum'},
      {
        headerName: "UPC(%)",  field: "pc_pct", cellStyle: {textAlign: 'right'}, width: 50,
       aggFunc: this.avgAggFunction
      },
      {
        headerName: "UPC CHG",  field: "upc_chg", width: 50, aggFunc: 'sum',
        cellStyle(params) {
          if (params.value === '') {
            return {textAlign: 'right'};
          } else if (params.value <= 0) {
            return {textAlign: 'right', backgroundColor: '#F8D1C8'};
          } else if (params.value > 0) {
            return {textAlign: 'right', backgroundColor: '#B3DFB0'};
          } else {
            return {textAlign: 'right'};
          }
        },
      },
    ];
  }

  avgAggFunction(params) {
    let sum = 0;
    let count = 0;
    let avg = 0;
    params.values.forEach(value => {
      const groupNode = value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        sum += value.avg * value.count;
        count += value.count;
      } else {
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }
    avg = Math.round((avg + Number.EPSILON) * 100) / 100;
    const result = {
      count,
      avg,
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  run() {
    this.loadServerData();
  }
  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }
  loadServerData() {
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }
    this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.rowData = res.results.data.master;
            this.detailsData = res.results.data.detail;
            this.constructChart(res.results.data.detail);
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  constructChart(chartValues) {
    this.constructTvsAChart(this.rowData);
    this.constructChannelChart(chartValues);
    this.constructCategoryChart(chartValues);
  }
  constructTvsAChart(chartValues) {
    this.tVSaChartData = [];
    const lmtdData: any = {};
    lmtdData.name = "LMTD";
    lmtdData.value = chartValues.reduce((acc, curr) => {
      return acc = acc + curr.lmtd_sale_achv;
    }, 0);

    this.tVSaChartData.push(lmtdData);

    const targetData: any = {};
    targetData.name = "TARGET";
    targetData.value = chartValues.reduce((acc, curr) => {
      return acc = acc + curr.target;
    }, 0);

    this.tVSaChartData.push(targetData);

    const mtdData: any = {};
    mtdData.name = "MTD";
    mtdData.value = chartValues.reduce((acc, curr) => {
      return acc = acc + curr.mtd_sale_achv;
    }, 0);

    this.tVSaChartData.push(mtdData);

    const projData: any = {};
    projData.name = "PROJECTION";
    projData.value = chartValues.reduce((acc, curr) => {
      return acc = acc + curr.projection;
    }, 0);

    this.tVSaChartData.push(projData);
  }

  constructChannelChart(chartValues) {
    this.channelChartData = [];
    const distinctTypes = [...new Set(chartValues.map(x => x.type))];
    distinctTypes.forEach(type => {

      const typedata: any = {};
      typedata.name = type;
      typedata.series = [];

      const lmtdseriesdata: any = {};
      lmtdseriesdata.name = 'LMTD';

      lmtdseriesdata.value = chartValues.filter(cvalue => cvalue.type === type).reduce((acc, curr) => {
        return acc = acc + curr.lmtd_sale_achv;
      }, 0);

      typedata.series.push(lmtdseriesdata);

      const mtd_sale_achvseriesdata: any = {};
      mtd_sale_achvseriesdata.name = 'MTD';

      mtd_sale_achvseriesdata.value = chartValues.filter(cvalue => cvalue.type === type).reduce((acc, curr) => {
        return acc = acc + curr.mtd_sale_achv;
      }, 0);

      typedata.series.push(mtd_sale_achvseriesdata);

      this.channelChartData.push(typedata);
    });
  }

  legendLabelActivate(item: any): void {
    this.activeEntries = [item];
  }

  legendLabelDeactivate(item: any): void {
    this.activeEntries = [];
  }

  constructCategoryChart(chartValues) {

    this.categoryChartData = [];

    const distinctCategories = [...new Set(chartValues.map(x => x.category))];

    distinctCategories.forEach(category => {
      const categoryData: any = {};
      categoryData.name = category;
      categoryData.series = [];

      const lmtdseriesdata: any = {};
      lmtdseriesdata.name = 'lmtd_sale_achv';

      lmtdseriesdata.value = chartValues.filter(cvalue => cvalue.category === category).reduce((acc, curr) => {
        return acc = acc + curr.lmtd_sale_achv;
      }, 0);

      categoryData.series.push(lmtdseriesdata);

      const mtd_sale_achvseriesdata: any = {};
      mtd_sale_achvseriesdata.name = 'mtd_sale_achv';

      mtd_sale_achvseriesdata.value = chartValues.filter(cvalue => cvalue.category === category).reduce((acc, curr) => {
        return acc = acc + curr.mtd_sale_achv;
      }, 0);

      categoryData.series.push(mtd_sale_achvseriesdata);
      this.categoryChartData.push(categoryData);
    });
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }


  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }


  changeDate(dt) {
    this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  onCellClicked(event) {

  }

  onSelectionChanged(event) {
    this.categoryChartTitle = 'Category Chart';
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows === null || selectedRows.length === 0) {
      this.constructChannelChart(this.detailsData);
      this.constructCategoryChart(this.detailsData);
      this.constructTvsAChart(this.rowData);
    } else {
      this.chartData = [];
      const distinctTerritories = [...new Set(selectedRows.map(x => x.territory))];
      console.log(distinctTerritories.length);

      distinctTerritories.forEach(territory => {
        let indDetailData;
        indDetailData = this.detailsData.filter(cvalue => cvalue.territory === territory);
        this.chartData.push(...indDetailData);
      });

      this.constructTvsAChart(selectedRows);
      this.constructChannelChart(this.chartData);
      this.constructCategoryChart(this.chartData);
    }
  }

  onRowSelected() {

  }

  onSelect(event) {
    let selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows === null || selectedRows.length === 0) {
      selectedRows = this.detailsData;
      const selectedTypeData = selectedRows.filter(cvalue => cvalue.type === event.series);

      this.categoryChartTitle = 'Category Chart - ' + event.series;
      this.constructCategoryChart(selectedTypeData);
      this.constructTvsAChart(this.rowData);
    } else {
      this.constructTvsAChart(selectedRows);
      this.categoryChartData = [];
      const distinctTerritories = [...new Set(selectedRows.map(x => x.territory))];

      distinctTerritories.forEach(territory => {

        let indDetailData;
        indDetailData = this.detailsData.filter(cvalue => cvalue.territory === territory && cvalue.type === event.series);

        this.categoryChartData.push(...indDetailData);
      });
      this.categoryChartTitle = 'Category Chart - ' + event.series;
      this.constructCategoryChart(this.categoryChartData);

    }
  }
  formatNumber(val) {
    if (val > 999999) {
      return val / 1000000 + 'M';
    } else if (val > 999) {
      return val / 1000 + 'K';
    } else {
      return val;
    }
  }
}
