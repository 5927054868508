<div class="wrapper">
    <div class="toast" *ngFor="let message of messages">
        <table class="ui table" (click)="dismiss(message.id)" [@showHide] *ngIf="message.dismissed == false">
            <tr>
                <td class="toast-icon" width='50px'><i
                        class="large inverted check icon"></i></td>
                <td>
                    <div class="toast-message">
                        {{message.content}}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
