import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "@ag-grid-enterprise/core";

if (environment.production) {
  enableProdMode();
  LicenseManager.setLicenseKey("CompanyName=APPOBILE LABS PRIVATE LIMITED,LicensedApplication=Sales app," +
    "LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025390,ExpiryDate=14_February_2023_[v2]_MTY3NjMzMjgwMDAwMA==d8c94294714a6f3d6dcad8e94562971d");
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && environment) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
}).catch(err => console.log(err));

LicenseManager.setLicenseKey("CompanyName=APPOBILE LABS PRIVATE LIMITED,LicensedApplication=Sales app," +
  "LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025390,ExpiryDate=14_February_2023_[v2]_MTY3NjMzMjgwMDAwMA==d8c94294714a6f3d6dcad8e94562971d");
