<!--<div style="margin-top: -15px;">
  <span style="color:#17A2B8;">{{multiFilters.name}} </span>
</div>-->
<!--<div class="date-picker">-->
  <!-- <span style="margin-top: 15px;margin-right: 5px;">Retail Audit Type </span>-->

  <div style="width:250px;" >
   <!-- <select
      class="select-checkbox"
      name="multiFilter"
      [(ngModel)]="selectedData[multiFilters.key]"
      #multiFilter="ngModel"
      (change)="setMultiFilter()"
    >
      <option
        *ngFor="let item of multiFilters.values"
        value="{{ item.id }}"
      >
        {{ item.itemName }}
      </option>
    </select>-->
   <!-- <ng-multiselect-dropdown
      [placeholder]="'custom placeholder'"
      [settings]="dropdownSettings"
      [data]="multiFilters.values"
      [(ngModel)]="selectedData[multiFilters.key]"
      (onSelect)="onItemSelect($event)"
      (onSelectAll)="onSelectAll($event)">
    </ng-multiselect-dropdown>-->
    <app-multi-select-dropdown
      [list]="multiFilters.values"
      [name]="multiFilters.name"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
    </app-multi-select-dropdown>
  </div>
<!--</div>-->

