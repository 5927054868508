<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>{{paymentData.title}}</h2>
    </div>
    <div class="body-section">
      <form novalidate (ngSubmit)="markPaid()" #paymentform="ngForm">
        <div class="row" style="margin-top:30px;">
          <div class="col-md-6  pl-4 pr-5" >
            <div class="row ">
              <div style="width:100%">
                <label> Payment Date</label>
                <div class="forminputdropdown">
                  <input
                    [readonly]="true"
                    name="payDate"
                    [(ngModel)]="paymentData.payDate"
                    #payDate="ngModel"
                    [owlDateTimeTrigger]="dt1"
                    [owlDateTime]="dt1" placeholder="Date">
                  <owl-date-time (afterPickerClosed)="changePayDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>
                </div>
              </div>
            </div>
            <div *ngIf="!paymentData.credit_creation" class="row" style="margin-top:15px;">
              <div style="width:100%">
                <label  >Payment Mode</label>
                <div class="formselect"   >
                  <select
                    name="payment_mode"
                    [(ngModel)]="paymentData.paymentMode"
                    #payment_mode="ngModel"

                    required
                  >
                    <option
                      *ngFor="let item of paymentModes"
                      value="{{ item.name }}"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="paymentData.paymentMode==='cheque'">
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div  >
                  <label>Cheque Number</label>
                  <div >
                    <input
                      class="forminput"
                      type="text"
                      name="chqno"
                      #chqno="ngModel"
                      [(ngModel)]="paymentData.chequeNo"
                    >
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div style="width:100%">
                  <label> Cheque Date</label>
                  <div class="forminputdropdown">
                    <input
                      [readonly]="true"
                      name="chequeDate"
                      [(ngModel)]="paymentData.chequeDate"
                      #chequeDate="ngModel"
                      [owlDateTimeTrigger]="dt2"
                      [owlDateTime]="dt2" placeholder="Date">
                    <owl-date-time (afterPickerClosed)="changeChqDate(dt2)" [pickerType]="'calendar'" #dt2></owl-date-time>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div  >
                  <label>Bank</label>
                  <div >
                    <input
                      class="forminput"
                      type="text"
                      name="bank"
                      #bank="ngModel"
                      [(ngModel)]="paymentData.bank"
                    >
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div  >
                  <label>Branch</label>
                  <div >
                    <input
                      class="forminput"
                      type="text"
                      name="brn"
                      #brn="ngModel"
                      [(ngModel)]="paymentData.branch"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="paymentData.paymentMode==='online'">
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div  >
                  <label>Reference NO</label>
                  <div >
                    <input
                      class="forminput"
                      type="text"
                      name="Reference"
                      #re="ngModel"
                      [(ngModel)]="paymentData.ref"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top:15px;">
              <div>
                <label>Comments</label>
                <div   style="height: 70px;">
                    <textarea
                      class="forminput"
                      style="height: 70px;"
                      type="text"
                      name="Comments"
                      #comment="ngModel"
                      [(ngModel)]="paymentData.comments"
                    >
                         </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6  pl-4 pr-5" >
            <div class="roundredcard">
              <div style="padding: 10px;">
                <div *ngIf="datas && datas.retailer">
                  <label style="color:#7D7D7D">Customer</label>
                  <p >{{datas.retailer}}</p>
                </div>
                <div *ngIf="datas && datas.code" style="margin-top: 10px;">
                  <label style="color:#7D7D7D">Code</label>
                  <p >{{datas.code}}</p>
                </div>
                <div *ngIf="!paymentData.credit_creation && datas && datas.credits > 0" style="margin-top: 10px;">
                  <!--<label style="color:#7D7D7D">Credits</label>-->
                  <input
                    class="forminput"
                    type="checkbox"
                    name="cred_used"
                    [(ngModel)]="datas.credit_used"
                    (ngModelChange)="updateCredAmt()"
                    #crdus="ngModel"
                    style="height: 12px;width: 12px;vertical-align: middle;margin-bottom: 3px;"
                  >
                  <span style="color:#7D7D7D;margin-left: 5px;margin-top: 5px;vertical-align: middle;">Use Credits</span>
                </div>
                <div *ngIf="!paymentData.credit_creation && datas && datas.credits > 0">
                  <input
                    class="forminput"
                    type="text"
                    name="crd"
                    #crd="ngModel"
                    [disabled]="true"
                    [placeholder]="datas.credits"
                    [(ngModel)]="datas.cr_used_credits"
                  >
                </div>
                <div *ngIf="paymentData.credit_creation && datas && datas.credits > 0">
                  <input
                    class="forminput"
                    type="text"
                    name="crd"
                    #crd="ngModel"
                    [disabled]="true"
                    [(ngModel)]="datas.credits"
                  >
                </div>
              </div>
              <hr class="horizontal_dotted_line"/>
              <div style="padding: 10px;">
                <div *ngIf="!paymentData.credit_creation">
                  <p >Outstanding Amount</p>
                  <div class="row">
                    <p style="display:inline;font-size: 20px;color:#E24260"> ₹</p>
                    <p style="display:inline;font-size: 20px;color:#E24260"> {{paymentData.out_amount}}</p>
                  </div>

                </div>
                <div *ngIf="paymentData.credit_creation">
                  <p >Balance</p>
                  <div class="row">
                    <p style="display:inline;font-size: 20px;color:#E24260"> ₹</p>
                    <p style="display:inline;font-size: 20px;color:#E24260"> {{paymentData.out_amount}}</p>
                  </div>

                </div>
                <!--                  <div style="margin-top: 10px;">-->
                <!--                     <span ><i class="fas fa-info-circle" style="color:#9D9D9D;display: inline-block;-->
                <!--  vertical-align: middle;padding-bottom: 2px;cursor:pointer;" ></i></span>-->
                <!--                    <span style="margin-left: 5px;color:#9D9D9D;cursor:pointer;">Credit Limit</span>-->
                <!--                    <span style="margin-left: 5px;color:#9D9D9D;cursor:pointer;">1000</span>-->
                <!--                  </div>-->
              </div>
            </div>


          </div>

          <div class="row" style="margin-top: 5px;">
            <div class="col-md-6  pl-4 pr-5">
              <div class="row" style="margin-top:15px;margin-bottom: 15px;">
                <div  >
                  <label>Amount</label>
                  <div >
                    <input
                      class="forminput"
                      type="text"
                      name="amnt"
                      #amnt="ngModel"
                      [(ngModel)]="paymentData.amount"
                      (ngModelChange)="updateWriteoff()"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6  pl-4 pr-5">
              <div *ngIf="!paymentData.credit_creation && paymentData.amount > paymentData.out_amount" class="row" style="margin-top: 15px;">
                <span style="margin-left: 5px;margin-top: 3px;vertical-align: middle;">Generating Cr Note</span>
              </div>
              <div *ngIf="!paymentData.credit_creation && paymentData.amount > paymentData.out_amount">
                <input
                  [disabled]="true"
                  class="forminput"
                  type="text"
                  [value]="(paymentData.amount-paymentData.out_amount).toFixed(2)"
                >
              </div>
              <div *ngIf="!paymentData.credit_creation && enableWriteOff" class="row" style="margin-top: 15px;">
                <input
                  class="forminput"
                  type="checkbox"
                  name="writeofb"
                  [(ngModel)]="paymentData.writeoff"
                  #writeofb="ngModel"
                  style="height: 12px;width: 12px;vertical-align: middle;"
                >
                <span style="margin-left: 5px;margin-top: 3px;vertical-align: middle;">Write Off</span>
              </div>
              <div *ngIf="!paymentData.credit_creation && paymentData.writeoff && enableWriteOff">
                <input
                  class="forminput"
                  type="text"
                  name="writeof"
                  #writeof="ngModel"
                  [disabled]="true"
                  [(ngModel)]="paymentData.writeoff_amt"
                >
              </div>
              <div *ngIf="!paymentData.credit_creation && !paymentData.writeoff" >
                <input
                  [disabled]="true"
                  style=" padding:10px;
                    border-radius:2px;
  border: 2px solid #FFFFFF;
                  background-color:#FFFFFF;
                       height:35px;
                     font-weight: 400;
                      font-size: 14px;">
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-12  pl-4 pr-5">
          <div class="button-row" style="margin-top: 15px; margin-bottom: 15px;" >
            <button
              mat-button
              style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
              class="btn teal-btn"
              type="submit"
            >
              SAVE
            </button>
            <span >
                     <a class="btn white-btn"
                     (click)="closeDialog()">DISCARD</a>
                </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
