import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';


@Component({
  selector: 'app-shelf-analysis-report',
  templateUrl: '../_common/reportbase.html',

})


// tslint:disable-next-line:component-class-suffix
export class CumulativeSalesReport extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];

  ngOnInit() {
    this.setTitle();
    //this.configureGrid();
    this.API_URL = '/api/pwa_reports/get_cumulative_sales';

    this.sharedService.setHeader('Cumulative Sales Report');
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
  }

  setTitle() {
    this.title = 'Cumulative Sales Report';
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Region', field: 'region', enableRowGroup: true, width: 25},
      {headerName: 'Region Mgr', field: 'region_manager', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'State', field: 'state', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Cluster', field: 'cluster', enableRowGroup: true, width: 30},
      {headerName: 'Cluster Mgr', field: 'cluster_manager', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Team', field: 'team', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Emp Code', field: 'emp_code', hide: true, width: 50},
      {headerName: 'Salesman', field: 'user_name', enableRowGroup: true, width: 50},
      {headerName: 'Beat', field: 'beat', hide: true, enableRowGroup: true, width: 50},
      {headerName: 'Date', field: 'date', enableRowGroup: true, width: 50},
      {headerName: 'Sup Code', field: 'dist_code', hide: true, width: 40},
      {headerName: 'Supplier', field: 'dist_name', enableRowGroup: true, width: 50},
      {headerName: 'Type', field: 'dist_type', enableRowGroup: true, width: 30},
      {headerName: 'CCode', field: 'o_code', hide: true, width: 40},
      {headerName: 'Customer', field: 'o_name', enableRowGroup: true, width: 60},
      {headerName: 'Order No', field: 'o_ref', hide: true, enableRowGroup: true, width: 80},
      {headerName: 'Channel', field: 'channel', enableRowGroup: true, hide: true, width: 50},
      {headerName: 'Type', field: 'o_type', enableRowGroup: true, width: 40},
      {headerName: 'Program', field: 'program', hide: true, enableRowGroup: true, width: 80},
      {headerName: 'Family', field: 'family', enableRowGroup: true, hide: true, width: 50},
      {headerName: 'Brand', field: 'brand', enableRowGroup: true, hide: true, width: 40},
      {headerName: 'PCode', field: 'p_code', hide: true, width: 50},
      {headerName: 'Product', field: 'product', enableRowGroup: true, width: 60},
      {headerName: 'Qty', field: 'qty', width: 40, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'value', field: 'price_subtotal', width: 60, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'Category', field: 'category', enableRowGroup: true, width: 30},
      {headerName: 'Report UOM', field: 'r_qty', width: 50, hide: true, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: 'MRP', field: 'mrp', hide: true, enableRowGroup: true, width: 80, cellStyle: {textAlign: 'right'}},
      {headerName: 'Unscheduled', field: 'unscheduled', hide: true, width: 80},
    ];
  }
}
